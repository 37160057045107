import styled from 'styled-components'

export const TextAreaCustomStyled = styled.div`

    .form-textarea-custom {
        margin-bottom: ${props => props.small ? '3px' : ''};
        
        .label-style {
            font-size: ${props => props.small ? '12px' : ''};
            text-align: ${props => props.labelAlign};
            width: ${props => props.widthLabel ? props.widthLabel : props.small ? '120px' : '150px'};
        }
        
        .textarea-custom {
            font-size: ${props => props.small ? '12px' : ''};
            border-color: ${props => props.isError ? 'red' : ''};
            width: ${props => props.label ? `calc(100% - ${props.widthLabel ? props.widthLabel : props.small ? '120px' : '150px'})` : ''};
        }
    }
`