import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from "react-router-dom"
import { message } from "antd"
import swal from '@sweetalert/with-react';
import { TitleHead, ButtonCustom } from "components"
import { ChangePasswordForm } from "reduxForms"
import { apiServices, API_PATH } from "apiServices"
import './style.css'
import { ROUTH_PATH, functionAlias } from 'routes'
import { getPermissionPage } from 'helpers'
import { get } from 'lodash'

class ChangePasswordContainer extends Component {
    state = {
        permissionPage: getPermissionPage(functionAlias.userMaintain),
    }

    componentDidMount() {
        if (!this.state.permissionPage.authApprove) {
            swal('Error', 'คุณไม่มีสิทธิ์ในการเปลี่ยนรหัสผ่าน', 'error').then(() => {
                this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
            })
            return
        }

        const keySelected = this.props.location.state
        if (keySelected) {
            this.fetchData()
        }
    }
    fetchData = () => {
        const keySelected = this.props.location.state
        this.setState({
            initialValues: {
                userId: keySelected
            },
        })
    }

    handleClickChangePassword = async () => {
        const { reduxForm: { values, syncErrors }, auth } = this.props
        //console.log("values===>", values);
        if (typeof (syncErrors) === 'object') {
            if (syncErrors.password === 'Required' || syncErrors.comfirmpassword === 'Required') {
                message.error("กรุณากรอกข้อมูลให้ครบถ้วน")
            }

            if (syncErrors.password === 'Must be 8 characters or more' || syncErrors.comfirmpassword === 'Must be 8 characters or more') {
                message.error("กรุณากรอกข้อมูล 8 ตัวอักษร หรือ มากกว่า")
            }

            if (syncErrors.password === 'Must have at least 1 number(s)' || syncErrors.comfirmpassword === 'Must have at least 1 number(s)') {
                message.error("กรุณากรอกตัวเลข (0-9) อย่างน้อย 1 ตัว")
            }

            if (syncErrors.password === 'Must have at least 1 upper case letter(s)' || syncErrors.comfirmpassword === 'Must have at least 1 upper case letter(s)') {
                message.error("กรุณากรอกพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว")
            }

            if (syncErrors.password === 'Must have at least 1 lower case letter(s)' || syncErrors.comfirmpassword === 'Must have at least 1 lower case letter(s)') {
                message.error("กรุณากรอกพิมพ์เล็ก (a-z) อย่างน้อย 1 ตัว")
            }

            if (syncErrors.password === 'Must have at least 1 symbol(s)' || syncErrors.comfirmpassword === 'Must have at least 1 symbol(s)') {
                message.error("กรุณากรอกอักขระพิเศษ (,!,@,#,$,%,^,&,*,?,_,~,) อย่างน้อย 1 ตัว")
            }
            this.setState({
                submitViewError: true
            })
        }
        else {
            let params = {
                ...values,
                password: values.password,
                updatedBy: auth.userId
            }
            delete params.comfirmpassword
            // console.log("params==>", params);
            if (values.password == values.comfirmpassword) {
                await apiServices.callApi("post", API_PATH.CHANGE_PASSWORD, params, auth.token).then(res => {
                    // console.log("res==>", res);

                    this.props.history.push(ROUTH_PATH.GET_USER)
                    swal(res.data.message, '', "success");
                }).catch(error => {
                    const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                    swal('Error', message, 'error')
                })
            }
            else {
                message.error("รหัสผ่านไม่ตรงกัน")
            }
        }

    }

    render() {
        const { initialValues, submitViewError } = this.state
        return (
            <div className="change-password-content">
                <TitleHead text="เปลี่ยนรหัสผ่าน" icon="reload" />
                <ChangePasswordForm initialValues={initialValues} submitViewError={submitViewError} />
                <div className="button-layout">
                    <Link to={ROUTH_PATH.DISPLAYUSER}>
                        <ButtonCustom text="ยกเลิก" icon="close" type="danger" />
                    </Link>
                    <ButtonCustom text="ยืนยัน" icon="save" onClick={this.handleClickChangePassword} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    // console.log("stateRedux==>", state)
    return {
        auth: state.auth,
        reduxForm: state.form.ChangePasswordForm
    };
};

export default connect(mapStateToProps)(ChangePasswordContainer)