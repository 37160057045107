import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col } from 'antd'
import {
    InputCustom,
    DatePickerCustom,
    SelectCustom,
    CheckboxCustom,
} from 'components/common'
import './style.css'
import { VALIDATE } from 'helpers'

const widthLabel = '90px'

class QuotationInfoFormRedux extends Component {
    render() {
        const {
            isErrorSubmit,
            isEditMode,
            outlets,
            documentStatuses,
            saleChannels,
            shippingPoints,
            solutions,
            saleEmployees,
            customer,
            changeQuotationInfo,
            submitSearchJobId,
            disabledRequestSpecialPrice,
            disabledAction,
        } = this.props

        return (
            <Row style={{ marginTop: '10px' }}>
                <Col md={24}>
                    <div className="quotation-info-form">
                        <div className="title">
                            ข้อมูลการขาย
                        </div>
                        <Row gutter={5}>
                            <Col sm={24} md={8}>
                                <Field
                                    component={InputCustom}
                                    label="เลขที่เอกสาร"
                                    name="quotationNumber"
                                    widthLabel={widthLabel}
                                    small
                                    disabled
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="สถานะเอกสาร"
                                    name="documentStatus"
                                    widthLabel={widthLabel}
                                    small
                                    options={documentStatuses}
                                    disabled
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={InputCustom}
                                    label="Version"
                                    name="version"
                                    widthLabel={widthLabel}
                                    small
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row gutter={5}>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="ช่องทาง"
                                    name="saleChannelCode"
                                    widthLabel={widthLabel}
                                    small
                                    options={saleChannels}
                                    onChange={(val) => changeQuotationInfo('saleChannelCode', val)}
                                    disabled={disabledAction || !!isEditMode || (!customer.customerId || customer.customerId === 'OTC99')}
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={DatePickerCustom}
                                    label="วันที่เอกสาร"
                                    name="startDate"
                                    widthLabel={widthLabel}
                                    small
                                    disabled
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={DatePickerCustom}
                                    label="วันที่หมดอายุเอกสาร"
                                    name="expireDate"
                                    widthLabel={widthLabel}
                                    small
                                    allowClear={false}
                                    disabled={disabledAction}
                                />
                            </Col>
                        </Row>
                        <Row gutter={5}>
                            <Col sm={24} md={8}>
                                <Field
                                    component={InputCustom}
                                    label="JOB ID"
                                    name="jobId"
                                    widthLabel={widthLabel}
                                    small
                                    disabled={disabledAction}
                                    onChange={(e) => changeQuotationInfo('jobId', e.target.value)}
                                    onSubmit={(e) => submitSearchJobId(e.target.value)}
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="Solution"
                                    name="solutionType"
                                    widthLabel={widthLabel}
                                    small
                                    options={solutions}
                                    validate={VALIDATE.REQUIRE}
                                    isError={isErrorSubmit}
                                    placeholder="กรุณาเลือก"
                                    disabled={disabledAction}
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="เงื่อนไขการส่งมอบ"
                                    name="shippingPoint"
                                    widthLabel={widthLabel}
                                    small
                                    options={shippingPoints}
                                    validate={VALIDATE.REQUIRE}
                                    isError={isErrorSubmit}
                                    placeholder="กรุณาเลือก"
                                    disabled={disabledAction}
                                />
                            </Col>
                        </Row>
                        <Row gutter={5}>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="รหัสพนักงาน"
                                    name="saleEmployeeCode"
                                    widthLabel={widthLabel}
                                    small
                                    options={saleEmployees}
                                    validate={VALIDATE.REQUIRE}
                                    isError={isErrorSubmit}
                                    placeholder="กรุณาเลือก"
                                    disabled={disabledAction}
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="Site ID"
                                    name="outletId"
                                    widthLabel={widthLabel}
                                    small
                                    options={outlets}
                                    onChange={(val) => changeQuotationInfo('outletId', val)}
                                    disabled={disabledAction || !!isEditMode}
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={CheckboxCustom}
                                    text="ขอราคาพิเศษ"
                                    name="requestSpecialPrice"
                                    small
                                    onChange={(e) => changeQuotationInfo('requestSpecialPrice', e.target.checked)}
                                    disabled={disabledRequestSpecialPrice}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row >
        )
    }
}

export default reduxForm({
    form: 'QuotationInfoFormRedux',
    enableReinitialize: true,
})(QuotationInfoFormRedux)
