import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { formatNumber, getPermissionPage, sortDataColumn } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    CheckboxCustom,
    DatePickerCustom,
    InputCustom,
    SelectCustom,
} from 'components/common'
import { Row, Col, Spin, Tabs } from 'antd'
import { ROUTH_PATH, functionAlias } from 'routes'
import { apiServices, API_PATH } from 'apiServices'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import ShipmentCalendar from './ShipmentCalendar'
import ShipmentPrintModal from './ShipmentPrintModal'
import ShipmentDetailModal from './ShipmentDetailModal'
import './style.css'

const { TabPane } = Tabs

const formatDateAPI = 'YYYY-MM-DD HH:mm:ss'

class ReportShipmentContainer extends Component {
    state = {
        columns: [
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                width: 100,
                render: (props, row) => {
                    return (
                        <div className="action-box">
                            <div className="button">
                                <Link to={`${ROUTH_PATH.SHIPMENT_EDIT_LINK}/${row.shipmentNumber}/${row.outletId}`}>
                                    <ButtonCustom
                                        icon="edit"
                                        yellow
                                        small
                                        disabled={!this.state.permissionPage.authUpdate}
                                    />
                                </Link>
                            </div>
                            <div className="button">
                                <ButtonCustom
                                    icon="delete"
                                    type="danger"
                                    small
                                    onClick={() => this.onClickDeleteShipment(row)}
                                    disabled={!this.state.permissionPage.authDelete}
                                />
                            </div>
                            <div className="button">
                                <ButtonCustom
                                    icon="printer"
                                    small
                                    green
                                    onClick={() => this.openModal('print', row)}
                                    disabled={!this.state.permissionPage.authPrint}
                                />
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'ออกรถ',
                dataIndex: 'actualStartDateAction',
                key: 'actualStartDateAction',
                render: (props, row, index) => (
                    <div className="text-center">
                        <ButtonCustom
                            icon="up"
                            small
                            onClick={() => this.onClickActionCar(row, index, 'start')}
                            disabled={row.actualStartDate}
                        />
                    </div>
                )
            },
            {
                title: 'ส่งเสร็จ',
                dataIndex: 'actualEndDateAction',
                key: 'actualEndDateAction',
                render: (props, row, index) => (
                    <div className="text-center">
                        <ButtonCustom
                            icon="down"
                            small
                            onClick={() => this.onClickActionCar(row, index, 'end')}
                            disabled={!row.actualStartDate || row.actualEndDate}
                        />
                    </div>
                )
            },
            {
                title: 'ใบจัดเที่ยว',
                dataIndex: 'shipmentNumber',
                key: 'shipmentNumber',
                sorter: (a, b) => sortDataColumn(a, b, 'shipmentNumber'),
                render: (props, row) => (
                    <ButtonCustom
                        text={props}
                        type="link"
                        small
                        onClick={() => this.openModal('detail', row)}
                        disabled={!this.state.permissionPage.authDisplay}
                    />
                )
            },
            {
                title: 'ร้าน',
                dataIndex: 'outletId',
                key: 'outletId',
                sorter: (a, b) => sortDataColumn(a, b, 'outletId'),
            },
            {
                title: 'พนักงานขับรถ',
                dataIndex: 'driverName',
                key: 'driverName',
                sorter: (a, b) => sortDataColumn(a, b, 'driverName'),
            },
            {
                title: 'รถ',
                dataIndex: 'truckName',
                key: 'truckName',
                sorter: (a, b) => sortDataColumn(a, b, 'truckName'),
            },
            {
                title: 'เส้นทาง',
                dataIndex: 'zoneName',
                key: 'zoneName',
                sorter: (a, b) => sortDataColumn(a, b, 'zoneName'),
            },
            {
                title: 'วันที่',
                dataIndex: 'shipmentDatetime',
                key: 'shipmentDatetime',
                sorter: (a, b) => sortDataColumn(a, b, 'shipmentDatetime'),
                render: (props) => (
                    props && <div>
                        {moment(props).format('DD/MM/YYYY')}
                    </div>
                )
            },
            {
                title: 'เริ่ม',
                dataIndex: 'startTime',
                key: 'startTime',
                sorter: (a, b) => sortDataColumn(a, b, 'startTime'),
                render: (props) => (
                    props && <div>
                        {moment(props).format('HH:mm')}
                    </div>
                )
            },
            {
                title: 'ถึง',
                dataIndex: 'endTime',
                key: 'endTime',
                sorter: (a, b) => sortDataColumn(a, b, 'endTime'),
                render: (props) => (
                    props && <div>
                        {moment(props).format('HH:mm')}
                    </div>
                )
            },
            {
                title: 'สถานะการจัดส่ง',
                dataIndex: 'statusShipmentText',
                key: 'statusShipmentText',
                sorter: (a, b) => sortDataColumn(a, b, 'statusShipmentText'),
                render: (props) => {
                    let style = {}
                    switch (props) {
                        case 'จัดส่งแล้ว':
                            style = { color: 'green' }
                            break
                        case 'กำลังจัดส่ง':
                            style = { color: '#1890ff' }
                            break
                        case 'รอจัดส่ง':
                            style = { color: '#f39c12' }
                            break
                        default:
                    }
                    return <div style={style} className="text-center font-weight-bold">{props}</div>
                }
            },
            {
                title: 'รายการจัดส่ง',
                dataIndex: 'countItem',
                key: 'countItem',
                sorter: (a, b) => sortDataColumn(a, b, 'countItem'),
                render: (props, row) => (
                    <div className="text-right">
                        {/* {formatNumber(props, 2)} */}
                        {formatNumber(row.salesShipmentItems && row.salesShipmentItems.length, 2)}
                    </div>
                )
            },
            {
                title: 'วัน/เวลา ออก',
                dataIndex: 'actualStartDate',
                key: 'actualStartDate',
                align: 'center',
                sorter: (a, b) => sortDataColumn(a, b, 'actualStartDate'),
                render: (props) => (
                    props && <div>
                        {moment(props).format('DD/MM/YYYY HH:mm')}
                    </div>
                )
            },
            {
                title: 'วัน/เวลา เสร็จ',
                dataIndex: 'actualEndDate',
                key: 'actualEndDate',
                align: 'center',
                sorter: (a, b) => sortDataColumn(a, b, 'actualEndDate'),
                render: (props) => (
                    props && <div>
                        {moment(props).format('DD/MM/YYYY HH:mm')}
                    </div>
                )
            },
            {
                title: 'หมายเหตุ',
                dataIndex: 'remark',
                key: 'remark',
                sorter: (a, b) => sortDataColumn(a, b, 'remark'),
            },
        ],
        loading: false,
        isShowModal: false,
        modalType: '',
        rowAction: null,
        shipments: [],
        zones: [],
        trucks: [],
        drivers: [],
        filter: {
            shipmentNumberFrom: '',
            shipmentNumberTo: '',
            startDateTime: '',
            endDateTime: '',
            outletId: '',
            flagWaitingDelivery: true,
        },
        keyword: '',
        pagination: {
            total: 0,
            limit: 10,
        },
        paginationLimitOptions: [
            { label: '10', value: 10 },
            { label: '25', value: 25 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
        ],
        permissionPage: getPermissionPage(functionAlias.shipmentMaintain),
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })

        const { auth } = this.props
        const { filter } = this.state

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({
                    outlets,
                    filter: { ...filter, outletId: outlets[0].value },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        const { filter: { outletId } } = this.state
        // truck
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALES_SHIPMENT_TRUCK}?ActiveStatus=true&OutletId=${outletId}`).then(res => {
            if (res.status === 200) {
                this.setState({ trucks: get(res, 'data.results', []) })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลรถได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // zone
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALES_SHIPMENT_ZONE}?ActiveStatus=true&OutletId=${outletId}`).then(res => {
            if (res.status === 200) {
                this.setState({ zones: get(res, 'data.results', []) })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลโซนได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // driver
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALES_SHIPMENT_DRIVER}?ActiveStatus=true&OutletId=${outletId}`).then(res => {
            if (res.status === 200) {
                this.setState({ drivers: get(res, 'data.results', []) })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลพนักงานขับรถได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    openModal = (modalType, rowAction = null) => {
        this.setState({ isShowModal: true, modalType, rowAction })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '', rowAction: null })
    }

    onChangeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    onChangePageCurrent = (page, type) => {
        const { pagination } = this.state
        const paginationNew = { ...pagination }
        paginationNew.current[type] = page
        this.setState({ pagination: paginationNew })
    }

    onChangePagination = (key, val) => {
        const { pagination } = this.state
        this.setState({ pagination: { ...pagination, [key]: val } })
    }

    onClickDeleteShipment = (row) => {
        swal({
            icon: 'warning',
            text: `คุณต้องการลบใบจัดส่งสินค้าหมายเลข ${row.shipmentNumber} หรือไม่ ?`,
            buttons: {
                confirm: { text: 'ยืนยัน', value: true },
                cancel: 'ปิด',
            },
        }).then((value) => {
            if (value) {
                this.deleteShipment(row)
            }
        })
    }

    deleteShipment = async (row) => {
        this.setState({ loading: true })
        const { auth: { userId } } = this.props
        const body = {
            outletId: row.outletId,
            shipmentNumber: row.shipmentNumber,
            updatedDate: moment(),
            updatedUser: userId,
        }

        await apiServices.callApi('delete', API_PATH.DELETE_SALES_SHIPMENT, body).then(res => {
            if (res.status === 200) {
                swal('Success', get(res, 'data.message'), 'success').then(() => {
                    this.searchShipment()
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการลบข้อมูลจัดส่งสินค้า กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    changeShipmentData = (key, value, index) => {
        this.setState({
            shipments: this.state.shipments.map((shipment, i) => (i === index ? { ...shipment, [key]: value } : shipment)),
        })
    }

    onClickActionCar = (row, index, type = '') => {
        if (!['start', 'end'].includes(type)) return
        swal({
            icon: 'warning',
            text: type === 'start' ? 'คุณต้องการออกรถตอนนี้หรือไม่ ?' : 'คุณต้องการยืนยันการส่งสินค้าเสร็จสิ้นหรือไม่ ?',
            buttons: {
                confirm: { text: 'ยืนยัน', value: true },
                cancel: 'ปิด',
            },
        }).then((value) => {
            if (value) {
                this.updateActualDate(row, index, type)
            }
        })
    }

    updateActualDate = async (row, index, type = '') => {
        this.setState({ loading: true })
        const { auth: { userId } } = this.props
        const now = moment().format(formatDateAPI)
        const body = {
            ...row,
            updatedDate: now,
            updatedUser: userId,
        }
        if (type === 'start') {
            body.actualStartDate = now
        } else if (type === 'end') {
            body.actualEndDate = now
        }

        await apiServices.callApi('put', API_PATH.PUT_UPDATE_SALES_SHIPMENT, body).then(res => {
            if (res.status === 200) {
                swal('Success', get(res, 'data.message'), 'success').then(() => {
                    // this.searchShipment()
                    const dataUpdated = get(res, 'data.results')
                    if (dataUpdated) {
                        const shipments = this.mappingShipmentsData(dataUpdated)
                        this.setState({
                            shipments: this.state.shipments.map((shipment, i) => (i === index ? { ...shipment, ...shipments[0] } : shipment)),
                        })
                    }
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการบันทึกข้อมูลจัดส่งสินค้า กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (filter.endDateTime && filter.startDateTime && (filter.endDateTime).isBefore(filter.startDateTime)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }
        return { isError: false }
    }

    searchShipment = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = {
            outletId: filter.outletId,
            shipmentNumberFrom: filter.shipmentNumberFrom,
            shipmentNumberTo: filter.shipmentNumberTo,
            startDateTime: filter.startDateTime && filter.startDateTime.startOf('day').format(formatDateAPI),
            endDateTime: filter.endDateTime && filter.endDateTime.endOf('day').format(formatDateAPI),
            flagWaitingDelivery: filter.flagWaitingDelivery ? filter.flagWaitingDelivery : '',
            flagDeleted: false,
        }

        await apiServices.callApi('post', API_PATH.POST_GET_SALES_SHIPMENT, body).then(res => {
            let shipments = get(res, 'data.results', [])
            if (res.status === 200) {
                shipments = isEmpty(shipments) ? [] : this.mappingShipmentsData(shipments)
                this.setState({ shipments })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูลจัดส่ง กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    mappingShipmentsData = (shipments) => {
        const { zones, trucks, drivers } = this.state
        return shipments.map(shipment => {
            const truck = trucks.find(truck => truck.truckId === shipment.truckId)
            const zone = zones.find(zone => zone.zoneId === shipment.zoneId)
            const driver = drivers.find(driver => driver.driverId === shipment.driverId)
            return {
                ...shipment,
                truckName: truck ? truck.truckName : shipment.truckId,
                zoneName: zone ? zone.zoneName : shipment.zoneId,
                driverName: driver ? driver.driverName : shipment.driverId,
            }
        })
    }

    exportReport = () => {

    }

    filterShipments = () => {
        let { keyword, shipments } = this.state
        if (isEmpty(shipments)) return []
        if (!keyword) return shipments

        keyword = keyword.toLowerCase()
        return shipments.filter(shipment => (
            (shipment.shipmentNumber && shipment.shipmentNumber.toLowerCase().includes(keyword))
            || (shipment.truckName && shipment.truckName.toLowerCase().includes(keyword))
            || (shipment.zoneName && shipment.zoneName.toLowerCase().includes(keyword))
            || (shipment.statusShipmentText && shipment.statusShipmentText.toLowerCase().includes(keyword))
            || (shipment.remark && shipment.remark.toLowerCase().includes(keyword))
            || (shipment.shipmentDatetime && moment(shipment.shipmentDatetime).format('DD/MM/YYYY').includes(keyword))
            || (shipment.startTime && moment(shipment.startTime).format('HH:mm').includes(keyword))
            || (shipment.endTime && moment(shipment.endTime).format('HH:mm').includes(keyword))
            || (shipment.actualStartDate && moment(shipment.actualStartDate).format('DD/MM/YYYY HH:mm').includes(keyword))
            || (shipment.actualEndDate && moment(shipment.actualEndDate).format('DD/MM/YYYY HH:mm').includes(keyword))
        ))
    }

    render() {
        const {
            columns,
            rowAction,
            trucks,
            outlets,
            loading,
            filter,
            pagination,
            paginationLimitOptions,
            keyword,
            isShowModal,
            modalType,
            permissionPage,
        } = this.state

        const shipments = this.filterShipments()

        return (
            <div className="report-shipment-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="รายงานการจัดส่งสินค้า"
                        icon="car"
                    />
                    <div className="middle-content">
                        <Row gutter={8}>
                            <Col sm={7}>
                                <InputCustom
                                    label="เลขที่จัดส่ง (Shipment)"
                                    small
                                    placeholder="60S0131901010000000"
                                    widthLabel="150px"
                                    onChange={(e) => this.changeFilter('shipmentNumberFrom', e.target.value)}
                                    value={filter.shipmentNumberFrom}
                                />
                            </Col>
                            <Col sm={5}>
                                <InputCustom
                                    label="ถึง"
                                    small
                                    widthLabel="50px"
                                    placeholder="60S0131912312359999"
                                    labelAlign="right"
                                    onChange={(e) => this.changeFilter('shipmentNumberTo', e.target.value)}
                                    value={filter.shipmentNumberTo}
                                />
                            </Col>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="วันที่จัดส่ง"
                                    small
                                    widthLabel="150px"
                                    allowClear={false}
                                    labelAlign="right"
                                    onChange={(val) => this.changeFilter('startDateTime', val)}
                                    value={filter.startDateTime}
                                />
                            </Col>
                            <Col sm={5}>
                                <DatePickerCustom
                                    label="ถึง"
                                    small
                                    widthLabel="50px"
                                    allowClear={false}
                                    labelAlign="right"
                                    onChange={(val) => this.changeFilter('endDateTime', val)}
                                    value={filter.endDateTime}
                                />
                            </Col>
                        </Row>
                        <Row gutter={8} style={{ paddingTop: '8px' }} >
                            <Col sm={7} >
                                <CheckboxCustom
                                    text="แสดงเฉพาะรายการที่ยังไม่มีการจัดส่ง"
                                    small
                                    onChange={(e) => this.changeFilter('flagWaitingDelivery', e.target.checked)}
                                    checked={filter.flagWaitingDelivery}
                                />
                            </Col>
                            <Col sm={5}>
                                <SelectCustom
                                    label="ร้าน"
                                    options={outlets}
                                    small
                                    widthLabel="50px"
                                    labelAlign="right"
                                    onChange={(val) => this.changeFilter('outletId', val)}
                                    value={filter.outletId}
                                />
                            </Col>
                            <Col sm={12} className="search-box">
                                <div className="action-box">
                                    <div className="button">
                                        <Link to={ROUTH_PATH.SHIPMENT_CREATE}>
                                            <ButtonCustom
                                                small
                                                icon="plus"
                                                text="สร้างใบจัดส่ง"
                                                disabled={!permissionPage.authCreate}
                                            />
                                        </Link>
                                    </div>
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.exportReport}
                                            small
                                            icon="file-excel"
                                            green
                                            text="Export Excel"
                                            disabled={isEmpty(shipments) || !permissionPage.authExport}
                                        />
                                    </div>
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.searchShipment}
                                            small
                                            icon="search"
                                            text="ค้นหา"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Tabs type="card" style={{ padding: '15px 0' }}>
                            <TabPane tab="ตารางงาน" key="1">
                                <Row align="middle" type="flex" style={{ paddingTop: '5px' }}>
                                    <Col sm={3}>
                                        <SelectCustom
                                            label="Show"
                                            small
                                            widthLabel="35px"
                                            options={paginationLimitOptions}
                                            value={pagination.limit}
                                            onChange={(val) => this.onChangePagination('limit', val)}
                                        />
                                    </Col>
                                    <Col sm={8} offset={13}>
                                        <InputCustom
                                            label="Search : "
                                            small
                                            widthLabel="70px"
                                            onChange={this.onChangeKeyword}
                                            value={keyword}
                                        />
                                    </Col>
                                </Row>
                                <div className="table-layout" style={{ paddingTop: '10px' }}>
                                    <TableCustom
                                        columns={columns}
                                        data={shipments}
                                        isFullContent
                                        rowKey={(row, index) => index}
                                        small
                                        pagination={{
                                            pageSize: pagination.limit,
                                            showTotal: (total, range) => (`Showing ${range[0]} to ${range[1]} of ${total} entries`),
                                        }}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tab="ปฏิทิน" key="2">
                                <Row>
                                    <Col sm={24}>
                                        <ShipmentCalendar trucks={trucks} outletId={filter.outletId} />
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                    <ShipmentPrintModal
                        isShowModal={isShowModal && modalType === 'print'}
                        onCloseModal={this.closeModal}
                        documents={[]}
                        onPrint={() => { }}
                    />
                    {!isEmpty(rowAction) && <ShipmentDetailModal
                        isShowModal={isShowModal && modalType === 'detail'}
                        onCloseModal={this.closeModal}
                        data={rowAction}
                    />}
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportShipmentContainer)