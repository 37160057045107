import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.css'
import { authData } from 'actions/actionsCreator';
import { Row, Col } from "antd"
import { Link } from 'react-router-dom';
import { apiServices, API_PATH } from "apiServices"
import { ROUTH_PATH } from '../../../../routes/path';
import { functionAlias } from 'routes'
import { Checkbox } from "antd"
import { CustomerKeyWordDropDown, getPermissionPage, sortDataColumn } from '../../../../helpers';
import { get, isEmpty } from 'lodash'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    SelectCustom,
    InputCustom,
} from 'components/common'
import swal from '@sweetalert/with-react'
import moment from 'moment'
import FileSaver from 'file-saver'

class CustomerMasterContainer extends Component {
    state = {
        permissionPage: getPermissionPage(functionAlias.customerMaster),
        keywords: CustomerKeyWordDropDown,
        disabledBtnSearchAndEcport: true,
        loading: false,
        outlets: [],
        filter: {
            outletId: "",
            keyword: "",
            value: "",
        },

        columns: [
            {
                title: 'ร้าน',
                dataIndex: 'customerOutletId',
                key: 'customerOutletId',
                ellipsis: true,
            },
            {
                title: 'รหัสลูกค้า',
                dataIndex: 'customerId',
                key: 'customerId',
                sorter: (a, b) => sortDataColumn(a, b, 'customerId'),
                ellipsis: true,
                render: (data, row) => <div className="button-table-zone">
                    <Link to={ROUTH_PATH.CUSTOMER_UPDATE.replace(":customerid", `${data}`).replace(":outletid", `${row.customerOutletId}`)}>
                        {data}
                    </Link>
                </div>
            },
            {
                title: 'ชื่อลูกค้า',
                dataIndex: 'customerNameTH',
                key: 'customerNameTH',
                sorter: (a, b) => sortDataColumn(a, b, 'customerNameTH'),
                ellipsis: true,
            },
            {
                title: 'โทรศัพท์',
                dataIndex: 'customerPhone',
                key: 'customerPhone',
                sorter: (a, b) => sortDataColumn(a, b, 'customerPhone'),
                ellipsis: true,
            },
            {
                title: 'SCG Family ID',
                dataIndex: 'customerScgFamilyId',
                key: 'customerScgFamilyId',
                sorter: (a, b) => sortDataColumn(a, b, 'customerScgFamilyId'),
                ellipsis: true,
            },
            {
                title: 'เลขบัตรประชาชน/เลขที่ผู้เสียภาษี',
                dataIndex: 'customerTaxId',
                key: 'customerTaxId',
                sorter: (a, b) => sortDataColumn(a, b, 'customerTaxId'),
                ellipsis: true,
            },
            {
                title: 'กลุ่มลูกค้า',
                dataIndex: 'customerGroupDescription',
                key: 'customerGroupDescription',
                sorter: (a, b) => sortDataColumn(a, b, 'customerGroupDescription'),
                ellipsis: true,
            },
            {
                title: 'บ้านเลขที่',
                dataIndex: 'customerAddress',
                key: 'customerAddress',
                ellipsis: true,
                render: (text, row) =>
                    <span>
                        {`${row.customerAddress} ${row.customerSubDistrict} ${row.customerDistrict} ${row.customerProvince} ${row.customerPostalCode}`}
                    </span>
            },
            {
                title: 'สถานะลูกค้า',
                dataIndex: 'customerStatus',
                key: 'customerStatus',
                ellipsis: true,
                render: (value, row) => <div>
                    {
                        <div style={{ textAlign: 'center' }}>
                            <Checkbox checked={value} disabled />
                        </div>
                    }
                </div>
            },
        ]
    };

    componentDidMount = async () => {
        await this.fetchDataDefault();
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth: { arrayOutlet } } = this.props
        let { filter } = this.state

        // outlet - get outlets of sale order detail and authorized user
        const outletIds = arrayOutlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, { outletIds }).then(res => {
            let outlets = get(res, 'data.results', [])
            if (res.status === 200) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                const outletsAuth = outlets.filter(outlet => arrayOutlet.includes(outlet.outletId))
                const defaultOutlet = get(outletsAuth, '0.value');
                this.setState({
                    outlets,
                    filter: {
                        ...filter,
                        outletId: defaultOutlet,
                        keyword: this.state.keywords[1].value
                    }
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false });
    }

    handleClickSearchCustomer = async () => {
        const { filter } = this.state
        let outlets = "";
        if (!filter.outletId) {
            this.props.auth.userOutletModelList && this.props.auth.userOutletModelList.map((outlet, i) => (
                outlets += !outlets ? outlet.outletId : `,${outlet.outletId}`
            ));
        } else {
            outlets = filter.outletId
        }

        let URLAndParams = `${API_PATH.GET_CUSTOMER}?OutletId=${outlets}&${filter.keyword}=${filter.value}&RequireSoldTo=${true}&RequireCustomerGroupDescription=true`;
        let res = await apiServices.callApi("get", URLAndParams, '', '')//auth.token
        //console.log(`Json string GET_CUSTOMER Responses ==>${JSON.stringify(res)}`)
        if (res.status === 200) {
            let dataCustomerList = res.data.results.map((data, i) => (
                {
                    ...data,
                    key: i
                }
            ))
            this.setState({
                dataCustomerList: dataCustomerList,
                loading: false
            })
        }
    }

    handleClickExportCustomer = async () => {
        const { filter } = this.state
        let outlets = "";
        if (!filter.outletId) {
            this.props.auth.userOutletModelList && this.props.auth.userOutletModelList.map((outlet, i) => (
                outlets += !outlets ? outlet.outletId : `,${outlet.outletId}`
            ));
        } else {
            outlets = filter.outletId
        }

        let URLAndParams = `${API_PATH.GET_EXPORT_CUSTOMERS}?OutletId=${outlets}&${filter.keyword}=${filter.value}`;
        this.setState({ loading: true });

        await apiServices.callApi('get', URLAndParams, '', { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `ReportCustomerFile-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง!')
            swal('Error', message, 'error')
        }).finally(() => { this.setState({ loading: false }) })

    }

    handleKeywordChang = (val) => {
        this.setState({ keyword: val });
    }

    handleValueChang = (val) => {
        //console.log(`val = ${val.target.value}`);
        if (val.target.value) {
            this.setState({ disabledBtnSearchAndEcport: false });
        } else {
            this.setState({ disabledBtnSearchAndEcport: true });
        }
        this.setState({ value: val.target.value });
    }

    changeFilterSearch = (key, val) => {
        let { filter } = this.state;
        if (key === "value" && val) {
            this.setState({ disabledBtnSearchAndEcport: false });
        } else {
            this.setState({ disabledBtnSearchAndEcport: true });
        }
        this.setState({ filter: { ...filter, [key]: val } })
    }

    render() {
        const {
            keywords,
            outlets,
            filter,
            permissionPage,
            dataCustomerList,
        } = this.state

        return (
            <div className="customer-master-container">
                <TitleHead text="ค้นหาข้อมูลลูกค้า" icon="search" />
                <div className="top-layout">
                    <Row gutter={10}>
                        <Col sm={4}>
                            <SelectCustom
                                onChange={(val) => this.changeFilterSearch('outletId', val)}
                                name="outletId"
                                options={outlets}
                                value={filter.outletId}
                            />
                        </Col>
                        <Col sm={6}>
                            <SelectCustom
                                onChange={(val) => this.changeFilterSearch('keyword', val)}
                                name="keyword"
                                label="ค้นหาด้วย"
                                options={keywords}
                                value={filter.keyword}
                            />
                        </Col>
                        <Col sm={8}>
                            <InputCustom
                                onChange={(e) => this.changeFilterSearch('value', e.target.value)}
                                name="value"
                                label="ข้อมูล"
                                value={filter.value}
                            />
                        </Col>
                        <Col sm={6}>
                            <div className="button-layout">
                                <span>
                                    <ButtonCustom
                                        text="ค้นหา"
                                        icon="search"
                                        onClick={this.handleClickSearchCustomer}
                                        // disabled={this.state.disabledBtnSearchAndEcport}
                                        disabled={!!!(filter.value)}
                                    />
                                </span>
                                <span>
                                    <ButtonCustom
                                        green
                                        text="export"
                                        icon="export"
                                        onClick={this.handleClickExportCustomer}
                                        // disabled={this.state.disabledBtnSearchAndEcport || !permissionPage.authExport}
                                        disabled={isEmpty(dataCustomerList) || !permissionPage.authExport}
                                    />
                                </span>
                                <Link to={ROUTH_PATH.CUSTOMER_CREATE}>
                                    <ButtonCustom text="สร้างลูกค้า" icon="user-add" type="primary" disabled={!permissionPage.authCreate} />
                                </Link>
                            </div>
                        </Col>
                    </Row>

                </div>
                <div className="table-layout">
                    <TableCustom height="calc(100vh - 314px)" columns={this.state.columns} data={this.state.dataCustomerList} loading={this.state.loading} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => { //get 
    console.log("state===>", state);
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => { // set
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMasterContainer);