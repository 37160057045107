import React, { Component } from 'react'
import { formatNumber, sortDataColumn } from 'helpers'
import { get, isEmpty } from 'lodash'
import {
    TableCustom,
    ModalCustom,
    SelectCustom,
    InputCustom,
    ButtonCustom,
    InputSearchCustom,
    DatePickerCustom,
} from 'components/common'
import { Row, Col, message } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import moment from 'moment'
import swal from '@sweetalert/with-react'

export default class SaleOrderSearchModal extends Component {
    state = {
        columns: [
            {
                title: 'ใบขาย',
                dataIndex: 'soNumber',
                key: 'soNumber',
                align: 'center',
                sorter: (a, b) => sortDataColumn(a, b, 'soNumber'),
            },
            {
                title: 'ที่จัดส่ง',
                dataIndex: 'shipToName',
                key: 'shipToName',
                align: 'center',
                sorter: (a, b) => sortDataColumn(a, b, 'shipToName'),
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
                align: 'center',
                sorter: (a, b) => sortDataColumn(a, b, 'productCode'),
            },
            {
                title: 'สินค้า',
                dataIndex: 'productName',
                key: 'productName',
                align: 'center',
                sorter: (a, b) => sortDataColumn(a, b, 'productName'),
                width: 200,
                render: (props) => (
                    <div className="text-left">
                        {props}
                    </div>
                )
            },
            {
                title: 'คงเหลือ',
                dataIndex: 'remainQty',
                key: 'remainQty',
                align: 'center',
                sorter: (a, b) => sortDataColumn(a, b, 'remainQty'),
                render: (props) => (
                    <div className="text-right">
                        {formatNumber(props, 3)}
                    </div>
                )
            },
            {
                title: 'หน่วย',
                dataIndex: 'unit',
                key: 'unit',
                align: 'center',
                sorter: (a, b) => sortDataColumn(a, b, 'unit'),
            },
            {
                title: 'น้ำหนัก',
                dataIndex: 'netWeight',
                key: 'netWeight',
                align: 'center',
                sorter: (a, b) => sortDataColumn(a, b, 'netWeight'),
                render: (props) => (
                    <div className="text-right">
                        {formatNumber(props, 3)}
                    </div>
                )
            },
        ],
        loading: false,
        saleOrders: [],
        keyword: '',
        soNumber: '',
        dateFrom: moment(),
        dateTo: moment(),
        selectedRowKeys: [],
        pagination: {
            total: 0,
            limit: 10,
        },
        paginationLimitOptions: [
            { label: '10', value: 10 },
            { label: '25', value: 25 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
        ],
    }

    componentDidMount() {
        //this.fetchDataDefault()
    }

    componentDidUpdate(prevProps) {
        if (this.props.outletId !== prevProps.outletId) {
            this.setState({
                saleOrders: [],
                selectedRowKeys: []
            }, () => {
                //this.fetchDataDefault()
            })
        }
    }

    fetchDataDefault = async () => {
        const { outletId } = this.props
        const { soNumber, dateFrom, dateTo } = this.state
        this.setState({ loading: true })

        if (isEmpty(dateFrom) || isEmpty(dateTo)) {
            message.error('กรุณาระบุวันที่จัดส่ง')
            this.setState({ loading: false })
            return
        }

        const withOutStatus = ["BLOCK"]
        const documentStatuses = ["APPROVE", "PAYMENT", "DELIVERY"];
        const documentTypeCodes = ["ZOCS", "ZOCR", "ZOPS"];
        const shippingPoint = "SHPC";
        const body = {
            outletId,
            soNumber,
            documentStatuses,
            documentTypeCodes,
            documentDateFrom: dateFrom,
            documentDateTo: dateTo,
            withOutDocumentStatus: withOutStatus,
            statusItemsWithOutReject: true,
            shippingPoint,
            remainMoreThanZero: true,
        }

        this.setState({ loading: true })
        await apiServices.callApi('post', API_PATH.POST_GET_SALES_SO_ITEMS, body).then(res => {
            let saleOrders = get(res, 'data.results', [])
            if (res.status === 200) {
                saleOrders = saleOrders.map((saleOrder, index) => ({
                    ...saleOrder,
                    key: index,
                }))

                this.setState({ saleOrders })
            } else {
                throw res
            }
        }).catch(error => {
            console.log('error', error.response)

            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูลการขาย กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onChangeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    handleSONumberChange = (e) => {
        this.setState({ soNumber: e.target.value });
    }

    handleDateFromChange = (e) => {
        this.setState({ dateFrom: e });
    }

    handleDateToChange = (e) => {
        this.setState({ dateTo: e });
    }

    onChangePageCurrent = (page, type) => {
        const { pagination } = this.state
        const paginationNew = { ...pagination }
        paginationNew.current[type] = page
        this.setState({ pagination: paginationNew })
    }

    onChangePagination = (key, val) => {
        const { pagination } = this.state
        this.setState({ pagination: { ...pagination, [key]: val } })
    }

    onSelectedRows = (selectedRowKeys) => {
        this.setState({ selectedRowKeys })
    }

    onSelectedSaleOrders = () => {
        const { selectedRowKeys, saleOrders } = this.state
        const saleOrdersSelected = selectedRowKeys.map(key => (saleOrders[key]))
        this.props.onSelected(saleOrdersSelected, saleOrders)
        this.setState({ selectedRowKeys: [] })
    }

    filterSaleOrders = () => {
        let { keyword, saleOrders } = this.state
        if (isEmpty(saleOrders)) return []
        if (!keyword) return saleOrders

        keyword = keyword.toLowerCase()
        return saleOrders.filter(saleOrder => (
            (saleOrder.soNumber && saleOrder.soNumber.toLowerCase().includes(keyword))
            || (saleOrder.shipToName && saleOrder.shipToName.toLowerCase().includes(keyword))
            || (saleOrder.productName && saleOrder.productName.toLowerCase().includes(keyword))
            || (saleOrder.unit && saleOrder.unit.toLowerCase().includes(keyword))
        ))
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
        } = this.props
        const {
            loading,
            columns,
            keyword,
            pagination,
            paginationLimitOptions,
            selectedRowKeys,
            soNumber,
            dateFrom,
            dateTo,
        } = this.state

        const rowSelection = {
            onChange: this.onSelectedRows,
            selectedRowKeys,
        }

        const saleOrders = this.filterSaleOrders()
        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                title="ค้นหาใบสั่งขาย"
            >
                <div>
                    <Row align="middle" type="flex">
                        <Col sm={12}>
                            <div className="font-weight-bold">รายการรอจัดเที่ยว</div>
                        </Col>
                    </Row>
                    <Row gutter={8} align="middle" type="flex" style={{ paddingTop: '10px' }}>
                        {/* <Col sm={4}>
                            <SelectCustom
                                label="Show"
                                small
                                widthLabel="55px"
                                options={paginationLimitOptions}
                                value={pagination.limit}
                                onChange={(val) => this.onChangePagination('limit', val)}
                            />
                        </Col> */}

                        <Col sm={7}>
                            <DatePickerCustom
                                label="วันที่จัดส่ง"
                                small
                                allowClear={false}
                                value={dateFrom}
                                onChange={this.handleDateFromChange}
                            />
                        </Col>
                        <Col sm={7}>
                            <DatePickerCustom
                                label="ถึง"
                                small
                                allowClear={false}
                                labelAlign="right"
                                value={dateTo}
                                onChange={this.handleDateToChange}
                            />
                        </Col>
                        <Col sm={7}>
                            <InputCustom
                                label="เลขที่ใบสั่งขาย"
                                small
                                placeholder="1100000000"
                                labelAlign="right"
                                value={soNumber}
                                onChange={this.handleSONumberChange}
                            />
                        </Col>
                        <Col sm={3}>
                            <div class='text-right'>
                                <ButtonCustom
                                    text="ค้นหา"
                                    icon="search"
                                    blue
                                    small
                                    onClick={this.fetchDataDefault}
                                />
                            </div>
                        </Col>
                        {/* <Col sm={3}>
                            <div className="text-search">ค้นหาใบสั่งขาย</div>
                        </Col>
                        <Col sm={5}>
                            <InputSearchCustom
                                small
                                value={soNumber}
                                onChange={this.handleSearchTextChange}
                                onClickSearch={this.fetchDataDefault}
                                onSubmit={this.fetchDataDefault}
                            />
                         <InputCustom
                                label="Search : "
                                small
                                widthLabel="70px"
                                onChange={this.onChangeKeyword}
                                value={keyword}
                            /> 
                        </Col> */}

                    </Row>
                    <Row align="middle" type="flex" style={{ paddingTop: '10px' }}>
                        <Col sm={24}>
                            <div>
                                <ButtonCustom
                                    text="เลือก"
                                    icon="select"
                                    green
                                    small
                                    onClick={this.onSelectedSaleOrders}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div style={{ paddingTop: '15px' }}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            loading={loading}
                            data={saleOrders}
                            small
                            pagination={{
                                pageSize: pagination.limit,
                                showTotal: (total, range) => (`Showing ${range[0]} to ${range[1]} of ${total} entries`),
                            }}
                            rowSelection={rowSelection}
                        />
                    </div>
                </div>
            </ModalCustom>
        )
    }
}
