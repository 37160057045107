import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Collapse, Icon, Spin, Modal } from "antd"
import { TitleHead, RadioCustom, SelectHero, RangePickerCustom, InputHero, InputSearch, TableCustom, DatePickerCustom, ButtonCustom, ModalCustom } from "components"
import { reportSaleOrderType, showStatusSaleOrder, reportSaleType, reasonRejectType, categoryList, documentType, saleTypes } from "helpers"
import './style.css'
import { authData, searchCriteriaSOReportData } from 'actions/actionsCreator';
import { apiServices, API_PATH } from "apiServices"
import swal from '@sweetalert/with-react'
import { isEmpty, get, find } from 'lodash'
import { map, compact } from 'lodash'
import { Link } from 'react-router-dom'
import { ROUTH_PATH } from 'routes'
import AdvanceSearch from './AdvanceSearch'
import { allTableColumns, modes } from './config'
import moment from 'moment'
import FileSaver from 'file-saver'

const { Panel } = Collapse;

class ReportTrackingSaleOrderContainer extends Component {
    state = {
        loading: false,
        activeCollapse: false,
        dateFormat: 'DD/MM/YYYY',
        dateFormatAPI: 'YYYY/MM/DD',
        widthDefault: '350px',

        customerCriteria: [
            { text: "รหัสลูกค้า", label: "รหัสลูกค้า", value: "customerId" },
            { text: "ชื่อลูกค้า", label: "ชื่อลูกค้า", value: "customerNameTH" },
            { text: "เบอร์โทรศัพท์", label: "เบอร์โทรศัพท์", value: "customerPhone" },
            { text: "เลขบัตรประชาชน", label: "เลขบัตรประชาชน", value: "customerTaxId" },
            { text: "อีเมลล์", label: "อีเมลล์", value: "customerEmail" },
        ],
        columnsCustomer: [
            {
                title: '#',
                key: 'htf',
                align: 'center',
                render: (value, row, index) => (
                    <ButtonCustom small onClick={() => this.handleChooseCustomer(row)} text="เลือก" />
                )
            },
            {
                title: 'รหัสลูกค้า',
                dataIndex: 'customerId',
                key: 'customerId',
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'ชื่อลูกค้า',
                dataIndex: 'customerNameTH',
                key: 'customerNameTH',
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'เบอร์โทร',
                dataIndex: 'customerPhone',
                key: 'customerPhone',
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'เลขบัตรประชาชน',
                dataIndex: 'customerTaxId',
                key: 'customerTaxId',
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'กลุ่มลูกค้า',
                dataIndex: 'customerGroupDescription',
                key: 'customerGroupDescription',
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'อีเมลล์',
                dataIndex: 'customerEmail',
                key: 'customerEmail',
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'ช่องทาง',
                dataIndex: '',
                key: '',
                ellipsis: true,
                align: 'center',
            },
        ],
        customerList: [],
        customerLoading: false,
        customerKeyword: 'customerNameTH',
        customerSearchText: '',

        showStatusSaleOrderSelected: '',
        reportSaleTypeSelected: [],
        saleTypeSelected: [],
        soNumber: '',
        customerId: '',

        pagination: {
            page: 1,
            field: '',
            order: '',
            take: 10,
            isPaging: true,
            skip: 0,
            startPage:0,
            endPage:0,
            totalPage: 0,
            totalRecord: 0,
        },


        documentDateFrom: moment(),
        documentDateTo: moment(),
        documentDateFromTo: [moment(), moment()],

        reportType: "SaleOrder",
        dataList: [],
        columns: []
    }


    componentDidMount() {
        // set default criteria advance search.
        this.props.searchCriteriaSOReportData({});
    }


    changeFormatDate = (value) => {
        if (value) {
            var d = new Date(value),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('-');
        }
        return value;
    }

    setSortTable = (pagination, filters, sorter, extra) => {
        let orderSet = '';
        switch (sorter.order) {
            case 'ascend':
                orderSet = 'asc';
                break;
            case 'descend':
                orderSet = 'desc';
                break;
            default:
                orderSet = '';
                break;
        }
        this.setState({
            pagination: {
                ...this.state.pagination,
                page: pagination.current,
                sort: sorter.field,
                order: orderSet
            }
        })
        setTimeout(() => { this.handleClickSearch() }, 10);
    }

    getBody = () => {
        const { reportType, showStatusSaleOrderSelected, reportSaleTypeSelected, documentDateFrom,
            documentDateTo, soNumber, customerId, dateFormatAPI, saleTypeSelected,
            pagination: { page, sort, order, isPaging } } = this.state
        const { searchCriteriaSOReport, auth } = this.props // criteria from Advance Search.
        // console.log('searchCriteriaSOReport', searchCriteriaSOReport);
        // console.log(JSON.stringify(searchCriteriaSOReport));
        let productCode = []
        if (!!searchCriteriaSOReport.productCodeFrom) {
            productCode.push(searchCriteriaSOReport.productCodeFrom)
        }
        if (!!searchCriteriaSOReport.productCodeTo) {
            productCode.push(searchCriteriaSOReport.productCodeTo)
        }
        // saleEmployeeSelected
        // productCodeFrom
        return {
            searchType: reportType,
            showSaleOrderStatus: showStatusSaleOrderSelected,
            documentTypeCode: reportSaleTypeSelected.join(","),
            documentDateFrom: documentDateFrom.format(dateFormatAPI),
            documentDateTo: documentDateTo.format(dateFormatAPI),
            saleOrderNumber: soNumber.indexOf(':') == -1 ? soNumber : '',
            saleOrderNumberFrom: soNumber.indexOf(':') == -1 ? '' : soNumber.split(':')[0],
            saleOrderNumberTo: soNumber.indexOf(':') == -1 ? '' : soNumber.split(':')[1],
            customerId: customerId,
            saleChannelCode: searchCriteriaSOReport.saleChannelSelected ? searchCriteriaSOReport.saleChannelSelected.join(',') : '',
            shippingPoint: searchCriteriaSOReport.saleShippingPointSelected ? searchCriteriaSOReport.saleShippingPointSelected.join(',') : '',
            rejectId: searchCriteriaSOReport.reasonRejectTypeSelected ? searchCriteriaSOReport.reasonRejectTypeSelected.join(',') : '',
            outerId: searchCriteriaSOReport.outletSelected ? searchCriteriaSOReport.outletSelected : auth.arrayOutlet[0],
            createBy: searchCriteriaSOReport.creatorSelected ? searchCriteriaSOReport.creatorSelected.join(',') : '',
            productClassCode: searchCriteriaSOReport.productCategorySelected ? searchCriteriaSOReport.productCategorySelected.join(',') : '',
            specialOrderFlag: searchCriteriaSOReport.categoryListSelected ? searchCriteriaSOReport.categoryListSelected.join(',') : '',
            saleEmpCode: searchCriteriaSOReport.saleEmployeeSelected ? searchCriteriaSOReport.saleEmployeeSelected : '',
            customerGroupId: searchCriteriaSOReport.customerGroupSelected ? searchCriteriaSOReport.customerGroupSelected.join(',') : '',
            paymentTermCode: searchCriteriaSOReport.paymentTermSelected ? searchCriteriaSOReport.paymentTermSelected.join(',') : '',
            saleType: saleTypeSelected.join(','),
            productCode: productCode ? productCode.join(',') : '',
            pagination: {
                page: page || 1,
                take: 10,
                sort: sort || '',
                order: order || '',
                isPaging: isPaging
            }
            // productCodeTo
        }
    }

    handleClickSearch = async () => {
        this.setState({ loading: true });
        const { reportType } = this.state;
        const body = this.getBody();
        await apiServices.callApi('post', API_PATH.POST_ORDERTRACKING_SEARCH, body).then(res => {
            let dataList = res.data.results.map((data, i) => (
                {
                    ...data,
                    key: i
                }
            ));
            //const pagination = { ...this.state.pagination };
            const pagination = get(res, "data.filter.pagination")
            //pagination.total = res.data.filter.pagination.totalRecord;
            //pagination.current = pagination.page;
            this.setState({
                dataList: dataList,
                columns: allTableColumns[reportType],
                loading: false,
                pagination: pagination
            });
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            this.setState({
                loading: false
            });
        });
    }

    getUrlExportDocument = () => {
        const reportType = this.state.reportType
        let url = ''
        switch (reportType) {
            case 'SaleOrder':
                url = API_PATH.GET_EXPORT_EXCEL
                break
            case 'Summary':
                url = API_PATH.GET_EXPORT_SO_SUMMARY
                break
            case 'SummaryWithDetail':
                url = API_PATH.GET_EXPORT_SO_SUMMARY_DETAIL
                break
            default:
                break
        }

        return url
    }

    handleClickExportExcel = async () => {
        this.setState({ loading: true });
        const body = this.getBody()
        const url = this.getUrlExportDocument()
        await apiServices.callApi('post', url, body, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `SaleOrderReportExcel-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    handleChangeShowStatusSaleOrder = (value) => {
        this.setState({ showStatusSaleOrderSelected: value });
    }

    handleChangeReportSaleOrderType = (val) => {
        this.setState({ reportType: val.target.value });
    }

    handleChangeDocumentDate = (value) => {
        this.setState({ documentDateFromTo: value, documentDateFrom: value[0], documentDateTo: value[1] });
    }

    handleChangeReportSaleType = (value) => {
        if (value.includes('all')) {
            const all = map(reportSaleType, 'value')
            this.setState({ reportSaleTypeSelected: all })
        } else {
            this.setState({ reportSaleTypeSelected: value })
        }
    }

    handleChangeSaleType = (value) => {
        if (value.includes('all')) {
            const all = map(saleTypes, 'value')
            this.setState({ saleTypeSelected: all })
        } else {
            this.setState({ saleTypeSelected: value })
        }
    }

    handleChangeSONumber = (value) => {
        this.setState({ soNumber: value.target.value });
    }

    handleChangeCustomerId = (value) => {
        this.setState({ customerId: value.target.value });
    }

    handleClickShowModal = (value) => {

        const { columnsProduct, productList, pagination, productLoading, productCriteria, productKeyword, productSearchText, selectedRowKeys, selectedRowData, columnsCustomer, customerList, customerLoading, customerCriteria, customerKeyword, customerSearchText } = this.state;
        const rowSelection = {
            selectedRowKeys,
            selectedRowData,
            onChange: this.onSelectChange,
        }

        switch (value) {
            case "productCodeFrom":
                let content1 =
                    <div>
                        <Row gutter={20}>
                            <Col md={3}>
                                ค้นหาด้วย
                            </Col>
                            <Col md={5}>
                                <SelectHero
                                    onChange={this.handleChangeProductCriteria}
                                    name="productCriteria"
                                    options={productCriteria}
                                    value={productKeyword}
                                />
                            </Col>
                            <Col md={8}>
                                <InputHero
                                    onChange={this.handleChangeProductSearchText}
                                    name="value"
                                    value={productSearchText}
                                />
                            </Col>
                            <Col md={2}>
                                <span><ButtonCustom red text="ค้นหา" icon="search" onClick={this.handleClickSearchProduct} /></span>
                            </Col>
                        </Row>
                        <div>
                            <TableCustom rowSelection={rowSelection} rowKey={(row, index) => index} height="calc(100vh - 316px)" columns={columnsProduct} data={productList} loading={productLoading} pagination={pagination} onChange={this.handleTableChange} />
                        </div>
                    </div>;

                this.setState({
                    isModalProduct: true,
                    titleModel: 'ค้นหาสินค้า',
                    widthModal: '900px',
                    modalType: 'productCodeFrom',
                    renderContentModal: content1,
                    isModalFooter: true,
                });
                break;
            case "productCodeTo":
                let content2 =
                    <div>
                        <Row gutter={20}>
                            <Col md={3}>
                                ค้นหาด้วย
                            </Col>
                            <Col md={5}>
                                <SelectHero
                                    onChange={this.handleChangeProductCriteria}
                                    name="productCriteria"
                                    options={productCriteria}
                                    value={productKeyword}
                                />
                            </Col>
                            <Col md={8}>
                                <InputHero
                                    onChange={this.handleChangeProductSearchText}
                                    name="value"
                                    value={productSearchText}
                                />
                            </Col>
                            <Col md={2}>
                                <span><ButtonCustom red text="ค้นหา" icon="search" onClick={this.handleClickSearchProduct} /></span>
                            </Col>
                        </Row>
                        <div>
                            <TableCustom rowSelection={rowSelection} rowKey={(row, index) => index} height="calc(100vh - 316px)" columns={columnsProduct} data={productList} loading={productLoading} pagination={pagination} onChange={this.handleTableChange} />
                        </div>
                    </div>;

                this.setState({
                    isModalProduct: true,
                    titleModel: 'ค้นหาสินค้า',
                    widthModal: '900px',
                    modalType: 'productCodeTo',
                    renderContentModal: content2,
                    isModalFooter: true,
                });
                break;
            case "customer":
                let content3 =
                    <div>
                        <Row gutter={20}>
                            <Col md={3}>
                                ค้นหาด้วย
                            </Col>
                            <Col md={5}>
                                <SelectHero
                                    onChange={this.handleChangeCustomerCriteria}
                                    name="customerCriteria"
                                    options={customerCriteria}
                                    value={customerKeyword}
                                />
                            </Col>
                            <Col md={8}>
                                <InputHero
                                    onChange={this.handleChangeCustomerSearchText}
                                    name="customerValue"
                                    value={customerSearchText}
                                />
                            </Col>
                            <Col md={2}>
                                <span><ButtonCustom red text="ค้นหา" icon="search" onClick={this.handleClickSearchCustomer} /></span>
                            </Col>
                        </Row>
                        <div>
                            <TableCustom height="calc(100vh - 316px)" columns={columnsCustomer} data={customerList} loading={customerLoading} />
                        </div>
                    </div>;

                this.setState({
                    isModalProduct: true,
                    titleModel: 'ค้นหาลูกค้า',
                    widthModal: '900px',
                    modalType: 'customer',
                    renderContentModal: content3,
                    isModalFooter: false,
                });
                break;
            default:
                break;
        }


    }

    handleClickSearchCustomer = async (e) => {
        const { outletSelected, customerKeyword, customerSearchText, modalType } = this.state
        const { searchCriteriaSOReport, auth } = this.props
        this.setState({ customerLoading: true }, () => this.handleClickShowModal(modalType))
        let customerId = customerKeyword == "customerId" ? customerSearchText : '';
        let customerNameTH = customerKeyword == "customerNameTH" ? customerSearchText : '';
        let customerPhone = customerKeyword == "customerPhone" ? customerSearchText : '';
        let customerTaxId = customerKeyword == "customerTaxId" ? customerSearchText : '';
        let customerEmail = customerKeyword == "customerEmail" ? customerSearchText : '';
        let outlet = searchCriteriaSOReport.outletSelected ? searchCriteriaSOReport.outletSelected : auth.arrayOutlet[0];

        let res = await apiServices.callApi("get", `${API_PATH.GET_CUSTOMER}?outletId=${outlet}&customerId=${customerId}&customerNameTH=${customerNameTH}&customerPhone=${customerPhone}&customerTaxId=${customerTaxId}&customerEmail=${customerEmail}`)
        if (res.status === 200) {
            let customerList = res.data.results.map((data, i) => (
                {
                    ...data,
                    key: i
                }
            ));

            this.setState({
                customerList: customerList,
                customerLoading: false,
            })
        }
        this.setState({ customerLoading: false }, () => this.handleClickShowModal(modalType))
    }

    handleChangeCustomerCriteria = (e) => {
        console.log("handleChangeCustomerCriteria ==>", e);
        const { modalType } = this.state;
        this.setState({ customerKeyword: e }, () => this.handleClickShowModal(modalType));
    }

    handleChangeCustomerSearchText = (e) => {
        const { modalType } = this.state;
        this.setState({ customerSearchText: e.target.value }, () => this.handleClickShowModal(modalType));
    }

    handleClickCancelModal = e => {
        this.setState({
            isModalProduct: false,
            productList: [],
            productKeyword: 'keywords',
            productSearchText: '',
            pagination: false,
            customerList: [],
            customerKeyword: 'customerNameTH',
            customerSearchText: '',
            isModalFooter: false,
        });
    }

    handleChooseCustomer = (row) => {
        this.setState({
            customerId: row.customerId,
            isModalProduct: false,
            customerList: [],
            customerKeyword: 'customerNameTH',
            customerSearchText: '',
            pagination: false,
            isModalFooter: false,
        });
    }

    render() {
        const {
            loading,
            titleModel,
            widthModal,
            reportSaleTypeSelected,
            soNumber,
            customerId,
            widthDefault,
            showStatusSaleOrderSelected,
            documentDateFromTo,
            renderContentModal,
            dataList,
            columns,
            reportType,
            saleTypeSelected,
            pagination
        } = this.state

        return (
            <div className="report-tracking-sale-order">
                <Spin spinning={loading}>
                    <TitleHead text="รายงานติดตามสถานะใบสั่งขาย" icon="search" />
                    <div className="top-layout">
                        <Row>
                            <Col md={5}>
                                ประเภทรายงาน
                            </Col>
                            <Col md={19}>
                                <RadioCustom
                                    options={reportSaleOrderType}
                                    onChange={this.handleChangeReportSaleOrderType}
                                    value={reportType}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                แสดงรายงานตามสถานะของเอกสาร
                            </Col>
                            <Col md={3}></Col>
                            <Col md={7}>
                                <SelectHero
                                    widthAll={widthDefault}
                                    onChange={this.handleChangeShowStatusSaleOrder}
                                    optionsDefault={[{ label: "-- เลือก --", value: "" }]}
                                    placeholder="-- เลือก --"
                                    options={showStatusSaleOrder}
                                    value={showStatusSaleOrderSelected}
                                //disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                การเลือกเอกสาร
                            </Col>
                            <Col md={3}>
                                วันที่เอกสาร
                            </Col>
                            <Col md={10}>
                                <RangePickerCustom
                                    widthAll={widthDefault}
                                    onChange={this.handleChangeDocumentDate}
                                    value={documentDateFromTo}
                                    allowClear={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5}>

                            </Col>
                            <Col md={3}>
                                ประเภทเอกสารใบสั่งขาย
                            </Col>
                            <Col md={10}>
                                <SelectHero
                                    onChange={this.handleChangeReportSaleType}
                                    widthAll={widthDefault}
                                    name="status"
                                    options={reportSaleType}
                                    value={reportSaleTypeSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5}>

                            </Col>
                            <Col md={3}>
                                เลขที่เอกสารใบสั่งขาย
                            </Col>
                            <Col md={10}>
                                <InputHero
                                    onChange={this.handleChangeSONumber}
                                    widthAll={widthDefault}
                                    name="soNumber"
                                    placeholder="0000000001:0000000099"
                                    value={soNumber}
                                />
                                ใส่ : เพื่อค้นหาข้อมูลแบบช่วง หรือใส่ , เพื่อค้นหาข้อมูลแบบรายตัว
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={5}>

                            </Col>
                            <Col md={3}>
                                รหัสผู้สั่งซื้อ
                            </Col>
                            <Col md={10}>
                                <InputSearch
                                    onChange={this.handleChangeCustomerId}
                                    widthAll={widthDefault}
                                    placeholder="1000000000"
                                    handleClickSearch={() => this.handleClickShowModal('customer')}
                                    value={customerId}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5}>

                            </Col>
                            <Col md={3}>
                                Type
                            </Col>
                            <Col md={10}>
                                <SelectHero
                                    onChange={this.handleChangeSaleType}
                                    widthAll={widthDefault}
                                    name="saleType"
                                    options={saleTypes}
                                    value={saleTypeSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                        </Row>

                        <Collapse bordered={false} showArrow={false}>
                            <Panel header="ค้นหาขั้นสูง"
                                key="1"
                            >
                                <AdvanceSearch />
                            </Panel>
                        </Collapse>
                        <Row>
                            <Col md={24}>
                                <div className="action-box">
                                    <div className="button-right">
                                        <ButtonCustom onClick={this.handleClickSearch} icon="search" text="ค้นหา" />
                                    </div>
                                    <div className="button-right">
                                        <ButtonCustom onClick={this.handleClickExportExcel} icon="file-excel" green text="Export Excel" />
                                    </div>
                                    <div className="button-left">
                                        <ButtonCustom disabled={true} icon="check-square" yellow text="อนุมัติวงเงินเครดิต" />
                                    </div>
                                    <div className="button-left">
                                        <ButtonCustom disabled={true} icon="plus-circle" blue text="สร้างใบจัดส่ง" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="content">
                        <div className="table-layout">
                            <TableCustom 
                                columns={columns} 
                                scroll={{ x: "max-content", y: "calc(100vh - 250px)" }}
                                data={dataList}
                                rowKey={(row, index) => index}
                                onChange={this.setSortTable.bind(this)}
                                pagination={{ pageSize: pagination.take, total: pagination.totalRecord, current: pagination.page }} 
                            />
                        </div>
                    </div>

                    <ModalCustom
                        title={titleModel}
                        isShowModal={this.state.isModalProduct}
                        handleClickSave={this.handleClickOkModal}
                        handleCloseModal={this.handleClickCancelModal}
                        width={widthModal}
                    >
                        {renderContentModal}
                    </ModalCustom>
                </Spin>
            </div>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth,
        searchCriteriaSOReport: state.searchCriteriaSOReport,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData,
        searchCriteriaSOReportData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportTrackingSaleOrderContainer);