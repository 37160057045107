import storage from 'redux-persist/lib/storage'
import {
    createStore,
    combineReducers
} from 'redux'
import { reducer as formReducer } from 'redux-form'
import { persistStore, persistReducer } from 'redux-persist'

import auth from "./AuthReducer"
import searchCriteriaSOReport from "./SearchCriteriaSOReportReducer"
import reportProductSharings from "./ReportProductSharingReducers"

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['form'],
}

const rootReducer = combineReducers({
    auth,
    searchCriteriaSOReport,
    reportProductSharings,
    form: formReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)
let store = createStore(persistedReducer)
let persistor = persistStore(store)

export { store, persistor }
