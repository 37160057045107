import React, { Component } from 'react'
import moment from 'moment'
import { TableCustom } from 'components/common'
import { formatNumber } from 'helpers'

export default class RevisionTable extends Component {
    state = {
        columns: [
            {
                title: 'ลำดับ',
                align: 'center',
                dataIndex: 'no',
                key: 'no',
                render: (props, row, index) => <span>
                    {index + 1}
                </span>
            },
            {
                title: 'วันที่',
                align: 'center',
                dataIndex: 'changedDatetime',
                key: 'changedDatetime',
                render: (props) => <span>
                    {moment(props).format('DD/MM/YYYY')}
                </span>
            },
            {
                title: 'version',
                align: 'center',
                dataIndex: 'versionNumber',
                key: 'versionNumber',
            },
            {
                title: 'User ID',
                align: 'center',
                dataIndex: 'changedBy',
                key: 'changedBy',
            },
            {
                title: 'ราคารวม(บาท)',
                align: 'center',
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                render: (props) => <div className="text-right">
                    {formatNumber(props, 2)}
                </div>
            },
            {
                title: 'ผู้อนุมัติ',
                align: 'center',
                dataIndex: 'approvedBy',
                key: 'approvedBy',
            },
        ]
    }

    render() {
        const { revisions } = this.props
        const { columns } = this.state

        return (
            <TableCustom
                columns={columns}
                data={revisions}
                rowKey={(row, index) => index}
                small
                pagination={false}
            />
        )
    }
}
