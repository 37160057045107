import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { formatNumber } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    InputSearchCustom,
    InputCustom,
    SelectCustom,
} from 'components/common'
import {
    ProductSearchModal,
} from 'components/feature'
import { Row, Col, Spin, Divider, message } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import PoOutstandingDetailModal from './PoOutstandingDetailModal'
import './style.css'

class ReportCheckStockPriceContainer extends Component {
    state = {
        columns: [
            {
                title: 'ที่เก็บ',
                dataIndex: 'lgort',
                key: 'lgort',
                align: 'center',
            },
            {
                title: 'หน่วยนับ',
                dataIndex: 'exDisplayUnit',
                key: 'exDisplayUnit',
                align: 'center',
            },
            {
                title: 'UR',
                dataIndex: 'labsrt',
                key: 'labsrt',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
            },
            {
                title: 'QI',
                dataIndex: 'insme',
                key: 'insme',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
            },
            {
                title: 'Block',
                dataIndex: 'speme',
                key: 'speme',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
            },
            {
                title: 'จำนวน ATP',
                dataIndex: 'atpQty',
                key: 'atpQty',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
            },
            {
                title: 'ระหว่างจัดส่ง',
                dataIndex: 'trame',
                key: 'trame',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                )
            },
            {
                title: 'PO ค้างรับ',
                dataIndex: 'owemg',
                key: 'owemg',
                align: 'center',
                render: (props, row) => (
                    // props > 0 ?
                    <ButtonCustom
                        className="po-detail-link"
                        type="link"
                        onClick={() => this.openModal('poOutstandingDetail', row)}
                        text={formatNumber(props, 3)}
                    /> 
                    // : formatNumber(props, 3)
                ),
            },
            // {
            //     title: 'PO สั่งพิเศษค้างรับ',
            //     dataIndex: 'owemgS',
            //     key: 'owemgS',
            //     align: 'center',
            //     render: (props) => (
            //         <div className="text-right">{formatNumber(props, 3)}</div>
            //     ),
            // },
            {
                title: 'SO ค้างจ่าย',
                dataIndex: 'zmeng',
                key: 'zmeng',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                ),
            },
            // {
            //     title: 'SO สั่งพิเศษค้างจ่าย',
            //     dataIndex: 'zmengS',
            //     key: 'zmengS',
            //     align: 'center',
            //     render: (props) => (
            //         <div className="text-right">{formatNumber(props, 3)}</div>
            //     ),
            // },
            {
                title: 'ยอดจองสินค้า',
                dataIndex: 'bdmng',
                key: 'bdmng',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                ),
            },
        ],
        columnsUnit: [
            {
                title: 'จำนวน',
                dataIndex: 'denominator',
                key: 'denominator',
                align: 'center',
            },
            {
                title: 'หน่วย',
                dataIndex: 'unit',
                key: 'unit',
                align: 'center',
            },
            {
                title: 'สัดส่วน/หน่วยนับ',
                dataIndex: 'numerator',
                key: 'numerator',
                align: 'center',
            },
            {
                title: 'หน่วยนับ',
                dataIndex: 'baseUnit',
                key: 'baseUnit',
                align: 'center',
            },
            {
                title: 'Barcode หลัก',
                dataIndex: 'barCode',
                key: 'barCode',
                align: 'center',
            },
            {
                title: 'ประเภทหน่วย',
                dataIndex: 'unitType',
                key: 'unitType',
                align: 'center',
            },
        ],
        columnsPo: [
            {
                title: <span>ระหว่างจัดส่ง<br />(In-transit_Stock)</span>,
                dataIndex: 'trame',
                key: 'trame',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                )
            },
            {
                title: <span>PO ค้างรับ<br />(PO Outstanding)</span>,
                dataIndex: 'owemg',
                key: 'owemg',
                align: 'center',
                render: (props, row) => (
                    <ButtonCustom
                        className="po-detail-link"
                        type="link"
                        onClick={() => this.openModal('poOutstandingDetail', row)}
                        text={formatNumber(props, 3)}
                    />
                ),
            },
            {
                title: <span>PO สั่งพิเศษค้างรับ<br />(PO Special Outstanding)</span>,
                dataIndex: 'owemgS',
                key: 'owemgS',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                ),
            },
            {
                title: <span>SO ค้างจ่าย <br />(SO Outstanding)</span>,
                dataIndex: 'zmeng',
                key: 'zmeng',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                ),
            },
            {
                title: <span>SO สั่งพิเศษค้างจ่าย <br />(SO Special Outstanding)</span>,
                dataIndex: 'zmengS',
                key: 'barcode',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                ),
            },
            {
                title: <span>ยอดจองสินค้า <br />(Reservation Outstanding)</span>,
                dataIndex: 'bdmng',
                key: 'bdmng',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                ),
            },
        ],
        loading: false,
        isShowModal: false,
        modalType: '',
        document: {},
        product: {},
        poDetail: {},
        rowAction: null,
        filter: {
            outletId: '',
            barcode: '',
            productCode: '',
            unit: 'baseUnitStock',
        },
        units: [
            { label: 'baseUnitStock', value: 'baseUnitStock' },
            { label: 'saleUnitStock', value: 'saleUnitStock' },
            { label: 'orderUnitStock', value: 'orderUnitStock' },
        ],
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })

        const { auth } = this.props
        const { filter } = this.state

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({
                    outlets,
                    filter: { ...filter, outletId: outlets[0].value },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    openModal = async (modalType, rowAction = null) => {
        this.setState({ loading: true })
        const { filter } = this.state
        if (modalType === 'poOutstandingDetail') {
            //Get PO Detail form SAP.
            const urlGetPODetailSAP = `${API_PATH.GET_PO_AND_STO_DETAIL}?ProductCode=${filter.productCode}&Outlet=${filter.outletId}&DeletionIndicator=true&DeliveryCompletedIndicator=true&IsReleaseIndicator=false`;
            await apiServices.callApi('get', urlGetPODetailSAP)
                .then(res => {
                    let poDetail = get(res, 'data.result')
                    if (res.status === 200 && !isEmpty(poDetail)) {
                        poDetail.eT_PO_ITEM_REPORT.item = poDetail.eT_PO_ITEM_REPORT.item.filter(i => i.stgE_LOC === rowAction.lgort && i.pO_UNIT === rowAction.exDisplayUnit);
                        this.setState({
                            poDetail: poDetail
                        })
                    } else {
                        throw res
                    }
                }).catch(error => {
                    const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
                    swal('Error', message, 'error')
                })
        }

        this.setState({ isShowModal: true, modalType, rowAction, loading: false })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '', rowAction: null, poDetail: null })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (!filter.outletId) return { isError: true, message: 'กรุณาระบุร้าน' }
        if (!filter.barcode && !filter.productCode) return { isError: true, message: 'กรุณาระบุบาร์โค้ดหรือรหัสสินค้า' }
        //if (!filter.productCode) return { isError: true, message: 'กรุณาระบุรหัสสินค้า' }
        return { isError: false }
    }

    searchProduct = async () => {
        const { auth } = this.props
        const { filter } = this.state
        const { barcode, productCode } = filter
        if (!barcode && !productCode) {
            message.error('กรุณาระบุบาร์โค้ดหรือรหัสสินค้า')
            return
        }
        this.setState({ loading: true })

        const body = {
            barcode: filter.barcode,
            productId: filter.productCode,
            outletId: filter.outletId,
            storageLocationIds: auth.arrayStorageLocationId,
            productStatus: true
        }
        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(res => {
            const product = get(res, 'data.results.0')
            if (res.status === 200 && !isEmpty(product)) {
                this.onSelectedProduct(product)
            } else {
                message.error('ไม่พบข้อมูลสินค้า')
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onSelectedProduct = (product) => {
        this.closeModal()
        const { filter } = this.state
        this.setState({
            product,
            filter: {
                ...filter,
                barcode: product.barcode,
                productCode: product.productId,
            }
        })
    }

    searchDocument = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = {
            outletId: filter.outletId,
            barCode: filter.barcode,
            productId: filter.productCode,
        }

        await apiServices.callApi('post', API_PATH.POST_GET_STOCK_PRICE, body).then(res => {
            let document = get(res, 'data.result')
            if (res.status === 200) {
                if (isEmpty(document) || !document.productId) {
                    message.error('ไม่พบข้อมูล')
                } else {
                    this.setState({ document })
                }
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูลจัดส่ง กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            columns,
            columnsUnit,
            columnsPo,
            outlets,
            loading,
            filter,
            product,
            isShowModal,
            modalType,
            document,
            units,
            rowAction,
            poDetail,
        } = this.state
        const stockDetail = document[filter.unit] || {}
        return (
            <div className="report-check-stock-price-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="ตรวจสอบสินค้าคงเหลือและราคา"
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row gutter={20}>
                            <Col sm={6}>
                                <SelectCustom
                                    label="ร้าน"
                                    widthLabel="50px"
                                    options={outlets}
                                    small
                                    onChange={(val) => this.changeFilter('outletId', val)}
                                    value={filter.outletId}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputCustom
                                    label="Barcode"
                                    widthLabel="80px"
                                    small
                                    onChange={(e) => this.changeFilter('barcode', e.target.value)}
                                    onPressEnter={this.searchDocument}
                                    value={filter.barcode}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputSearchCustom
                                    label="รหัสสินค้า"
                                    widthLabel="80px"
                                    onClickSearch={() => this.openModal('product')}
                                    onSubmit={this.searchDocument}
                                    onChange={(e) => this.changeFilter('productCode', e.target.value)}
                                    small
                                    value={filter.productCode}
                                />
                            </Col>
                            <Col sm={3}>
                                <ButtonCustom
                                    text="ค้นหาสินค้า"
                                    icon="search"
                                    small
                                    onClick={this.searchDocument}
                                />
                            </Col>
                        </Row>
                        {!isEmpty(document) && <Fragment>
                            <Row gutter={20} style={{ paddingTop: '20px' }}>
                                <Col sm={24}>
                                    <h4 style={{ color: '#1890ff' }} className="font-weight-bold">ราคาขาย</h4>
                                    <Divider style={{ margin: '10px 0px' }} />
                                </Col>
                                <Col sm={8}>
                                    <span className="font-weight-bold">รหัสสินค้า: </span>
                                    <span>{document.productId}</span>
                                </Col>
                                <Col sm={8}>
                                    <span className="font-weight-bold">ชื่อสินค้า: </span>
                                    <span>{document.productName}</span>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                {/* <Col sm={10}>
                                    <div className="product-price-box">
                                        <Row gutter={20}>
                                            <Col sm={12}>
                                                <span className="font-weight-bold">ราคาสินค้าขายปลีก: </span>
                                            </Col>
                                            <Col sm={12} className="text-right">
                                                <span>{formatNumber(product.retailPrice, 2)} บาท / {product.unitId}</span>
                                            </Col>
                                            <Col sm={24} className="text-right">
                                                <span>4,500.00 บาท / BD</span>
                                            </Col>
                                            <Col sm={24} className="text-right">
                                                <span style={{ textDecoration: 'line-through' }}>4,500.00 บาท</span>
                                                <span style={{ color: 'red' }}> 4,500.00 บาท</span>
                                                <span> / BD</span>
                                            </Col>
                                            <Col sm={24} style={{ color: 'red' }}>
                                                <span className="font-weight-bold">ระยะเวลาโปรโมชั่น: </span>
                                                <span>26/05/2014 - 26/05/2014</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col> */}
                                {!isEmpty(document.productPricesRetail) &&
                                    <Col sm={10}>
                                        <div className="product-price-box">
                                            <Row gutter={20}>
                                                <Col sm={12}>
                                                    <span className="font-weight-bold">ราคาสินค้าขายปลีก: </span>
                                                </Col>
                                                {document.productPricesRetail.map((product, index) => {
                                                    if (product.promotionPrice) {
                                                        return (<Fragment key={index}>
                                                            <Col sm={24} className="text-right">
                                                                <span style={{ textDecoration: 'line-through' }}>{formatNumber(product.basePrice, 2)} บาท</span>
                                                                <span style={{ color: 'red' }}> {formatNumber(product.promotionPrice, 2)} บาท</span>
                                                                <span> / {product.unit}</span>
                                                            </Col>
                                                            <Col sm={24} className="text-right" style={{ color: 'red' }}>
                                                                <span className="font-weight-bold">ระยะเวลาโปรโมชั่น: </span>
                                                                <span>
                                                                    {product.activeDateFrom && moment(product.activeDateFrom).format('DD/MM/YYYY')} - {product.activeDateTo && moment(product.activeDateTo).format('DD/MM/YYYY')}
                                                                </span>
                                                            </Col>
                                                        </Fragment>)
                                                    } else {
                                                        return (<Col sm={index === 0 ? 12 : 24} className="text-right" key={index}>
                                                            <span>{formatNumber(product.basePrice, 2)} บาท / {product.unit}</span>
                                                        </Col>)
                                                    }
                                                })}
                                            </Row>
                                        </div>
                                    </Col>
                                }
                                {!isEmpty(document.productPricesProject) &&
                                    <Col sm={10}>
                                        <div className="product-price-box">
                                            <Row gutter={20}>
                                                <Col sm={12}>
                                                    <span className="font-weight-bold">ราคาสินค้าขายส่ง/โครงการ: </span>
                                                </Col>
                                                {document.productPricesProject.map((product, index) => {
                                                    if (product.promotionPrice) {
                                                        return (<Fragment key={index}>
                                                            <Col sm={24} className="text-right">
                                                                <span style={{ textDecoration: 'line-through' }}>{formatNumber(product.basePrice, 2)} บาท</span>
                                                                <span style={{ color: 'red' }}> {formatNumber(product.promotionPrice, 2)} บาท</span>
                                                                <span> / {product.unit}</span>
                                                            </Col>
                                                            <Col sm={24} className="text-right" style={{ color: 'red' }}>
                                                                <span className="font-weight-bold">ระยะเวลาโปรโมชั่น: </span>
                                                                <span>
                                                                    {product.activeDateFrom && moment(product.activeDateFrom).format('DD/MM/YYYY')} - {product.activeDateTo && moment(product.activeDateTo).format('DD/MM/YYYY')}
                                                                </span>
                                                            </Col>
                                                        </Fragment>)
                                                    } else {
                                                        return (<Col sm={index === 0 ? 12 : 24} className="text-right" key={index}>
                                                            <span>{formatNumber(product.basePrice, 2)} บาท / {product.unit}</span>
                                                        </Col>)
                                                    }
                                                })}
                                            </Row>
                                        </div>
                                    </Col>
                                }
                                <Col sm={24}>
                                    <span className="font-weight-bold">อัพเดทล่าสุด: </span>
                                    <span>{document.updateDate ? moment(document.updateDate).format('DD/MM/YYYY') : '-'}</span>
                                </Col>
                            </Row>
                            <Row className="inventory-box" style={{ paddingTop: '30px' }} gutter={20}>
                                <Col sm={24}>
                                    <h4 style={{ color: '#1890ff' }} className="font-weight-bold">สินค้าคงเหลือ</h4>
                                    <Divider style={{ margin: '10px 0px' }} />
                                </Col>
                                <Col sm={9}>
                                    <SelectCustom
                                        label="หน่วยที่ต้องการแสดง*"
                                        options={units}
                                        small
                                        widthLabel="150px"
                                        onChange={(val) => this.changeFilter('unit', val)}
                                        value={filter.unit}
                                    />
                                </Col>
                                <Col sm={5}>
                                    <span className="font-weight-bold">ประเภทสต็อค: </span>
                                    <span>{stockDetail.matkl}</span>
                                </Col>
                                <Col sm={5}>
                                    <span className="font-weight-bold">ประเภทการเติมสินค้า: </span>
                                    <span>{stockDetail.dismm}</span>
                                </Col>
                            </Row>
                            <div className="table-layout">
                                <TableCustom
                                    height="calc(100vh - 316px)"
                                    columns={columns}
                                    data={stockDetail.slocATPUnits}
                                    isFullContent
                                    rowKey={(row, index) => index}
                                    small
                                    pagination={false}
                                />
                                <div className="text-right" style={{ paddingTop: '10px' }}>Total: {get(stockDetail, 'slocATPUnits', []).length}</div>
                            </div>
                            <div className="table-layout">
                                <TableCustom
                                    height="calc(100vh - 316px)"
                                    columns={columnsUnit}
                                    data={document.convertUnits}
                                    isFullContent
                                    rowKey={(row, index) => index}
                                    small
                                    pagination={false}
                                />
                            </div>
                            {/* <div className="table-layout">
                                <TableCustom
                                height="calc(100vh - 316px)"
                                    columns={columnsPo}
                                    data={document.totalPOStatus}
                                    isFullContent
                                    rowKey={(row, index) => index}
                                    small
                                    pagination={false}
                                />
                            </div> */}
                        </Fragment>}
                    </div>
                    <ProductSearchModal
                        isShowModal={isShowModal && modalType === 'product'}
                        onCloseModal={this.closeModal}
                        onSelected={this.onSelectedProduct}
                        outletId={filter.outletId}
                    />
                    {!isEmpty(rowAction) && <PoOutstandingDetailModal
                        isShowModal={isShowModal && modalType === 'poOutstandingDetail'}
                        onCloseModal={this.closeModal}
                        poDetail={poDetail}
                    />}
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportCheckStockPriceContainer)