/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.css'
import { authData } from 'actions/actionsCreator';
import { TitleHead, ButtonCustom, TableCustom, SelectHero } from "components"
import { InputCustom } from "components/common"
import { apiServices, API_PATH } from "apiServices"
import { Row, Col } from "antd"
import { get } from 'lodash'
import swal from '@sweetalert/with-react'
import { ModalProductDetail } from './ModalProductDetail'
import { ModalOutstandingBalanceDetail } from './ModalOutstandingBalanceDetail'
import { sortDataColumn } from '../../../../helpers';

class ProductContainer extends Component {
    state = {
        productCriteria: [{ text: "ทั้งหมด", label: "ทั้งหมด", value: "keywords" },
        { text: "รหัสสินค้า", label: "รหัสสินค้า", value: "productId" },
        { text: "ชื่อสินค้า", label: "ชื่อสินค้า", value: "productNames" },
        { text: "บาร์โค้ด", label: "บาร์โค้ด", value: "barcode" }],
        outletCriteria: [],
        outletKeyword: "",
        productKeyword: "keywords",
        searchText: '',
        loading: false,
        dataList: [],
        dataSubList: [],
        pagination: {
            endPage: 0,
            isPaging: true,
            page: 1,
            skip: 0,
            startPage: 1,
            take: 10,
            totalPage: 0,
            totalRecord: 0,
        },
        sorter: {
            field: "productId",
            order: "ascend"
        },
        dataSelected: {},
        dataSelectedProduct: [],
        isShowModal: false,
        modalType: '',
        columns: [
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productId',
                key: 'productId',
                sorter: (a, b) => sortDataColumn(a, b, 'productId'),
                ellipsis: true,
                render: (props, row, index) => (
                    <a onClick={() => this.handleShowModal("productDetail", { row })}>{props}</a>
                ),
                defaultSortOrder: 'ascend',
            },
            {
                title: 'ชื่อ',
                dataIndex: 'productNameMarket',
                key: 'productNameMarket',
                sorter: (a, b) => sortDataColumn(a, b, 'productNameMarket'),
                ellipsis: true,
            },
            {
                title: 'ประเภทสต็อค',
                dataIndex: 'stockType',
                key: 'stockType',
                sorter: (a, b) => sortDataColumn(a, b, 'stockType'),
                ellipsis: true
            },
            {
                title: 'คลัง',
                dataIndex: 'storageLocation',
                key: 'storageLocation',
                sorter: (a, b) => sortDataColumn(a, b, 'storageLocation'),
                ellipsis: true,
            },
            {
                title: 'จำนวนที่มี',
                dataIndex: 'atp',
                key: 'atp',
                // sorter: (a, b) => sortDataColumn(a, b, 'atp'),
                sorter: false,
                ellipsis: true,
            },
            {
                title: 'ยอดค้างส่ง',
                dataIndex: 'outstandingBalance',
                key: 'outstandingBalance',
                // sorter: (a, b) => sortDataColumn(a, b, 'outstandingBalance'),
                sorter: false,
                ellipsis: true,
                render: (props, row, index) => ((props < 1 ? props :
                    <a onClick={() => this.handleShowModal("outstandingBalanceDetail", { row })}>{props}</a>)
                ),
            },
            {
                title: 'ยอดที่ยังไม่ยืนยัน',
                dataIndex: 'unconfirmedBalance',
                key: 'unconfirmedBalance',
                // sorter: (a, b) => sortDataColumn(a, b, 'unconfirmedBalance'),
                sorter: false,
                ellipsis: true,
            },
            {
                title: 'UR',
                dataIndex: 'ur',
                key: 'ur',
                // sorter: (a, b) => sortDataColumn(a, b, 'ur'),
                sorter: false,
                ellipsis: true,
            },
            {
                title: 'QI',
                dataIndex: 'qi',
                key: 'qi',
                // sorter: (a, b) => sortDataColumn(a, b, 'qi'),
                sorter: false,
                ellipsis: true,
            },
            {
                title: 'Block',
                dataIndex: 'block',
                key: 'block',
                // sorter: (a, b) => sortDataColumn(a, b, 'block'),
                sorter: false,
                ellipsis: true,
            },
            {
                title: 'หน่วยนับ',
                dataIndex: 'unitId',
                key: 'unitId',
                sorter: (a, b) => sortDataColumn(a, b, 'unitId'),
                ellipsis: true,
            },
            {
                title: 'ราคา',
                dataIndex: 'retailPrice',
                key: 'retailPrice',
                sorter: (a, b) => sortDataColumn(a, b, 'retailPrice'),
                ellipsis: true,
            },
            {
                title: 'หน่วยขาย',
                dataIndex: 'salesUnit',
                key: 'salesUnit',
                sorter: (a, b) => sortDataColumn(a, b, 'salesUnit'),
                ellipsis: true,
            },
            {
                title: 'Barcode',
                dataIndex: 'barcode',
                key: 'barcode',
                sorter: (a, b) => sortDataColumn(a, b, 'barcode'),
                ellipsis: true,
            }
        ],
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { auth } = this.props

        let params1 = {
            outletIds: auth.arrayOutlet,
        }

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, params1).then(res => {
            if (res.status === 200) {
                let outlets = res.data.results;
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }))

                this.setState({
                    outletCriteria: outlets,
                    outletKeyword: outlets[0].value,
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

    }

    handleShowModal = async (modalType, dataSelected) => {
        // productDetail
        // if (modalType === 'saleEmployee') {
        //     this.onSearchSaleEmployee()
        // }
        this.setState({
            modalType, dataSelected: { ...get(dataSelected, 'row'), data: [] },
            loading: true
        })
        // console.log('dataSelected', dataSelected);
        // ถ้าดูจำนวน
        if (modalType === 'productDetail') {
            const { auth } = this.props;
            const product = get(dataSelected, 'row')
            // const params = {
            //     productCode: product.productId,
            //     outletId: product.outletId,
            //     storageLocationId: product.storageLocationId
            // }
            const params = {
                productId: product.productId,
                outletId: product.outletId,
                storageLocationId: product.storageLocationId,
                productStatus: true,
            }
            try {
                let res = await apiServices.callApi("post", `${API_PATH.POST_PRODUCT_ALL_UNIT}?getBy=${auth.userId}`, params)
                if (res.status === 200) {
                    let dataList = res.data.results.map((data, i) => (
                        {
                            ...data,
                            key: i
                        }
                    ))
                    this.setState({
                        dataSelectedProduct: dataList,
                    })
                }
                this.setState({ isShowModal: true, loading: false })
            } catch (error) {
                this.setState({ isShowModal: false, loading: false })
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            }
        }

        // ถ้าดูจำนวน
        if (modalType === 'outstandingBalanceDetail') {
            const { auth } = this.props;
            const product = get(dataSelected, 'row')
            const params = {
                productCode: product.productId,
                outletId: product.outletId,
                storageLocationId: product.storageLocationId
            }
            try {
                let res = await apiServices.callApi("post", `${API_PATH.POST_GET_SALES_ORDER_WERERESERVED_BY_PRODUCT}?getBy=${auth.userId}`, params)
                if (res.status === 200) {
                    let dataList = res.data.results.map((data, i) => (
                        {
                            ...data,
                            key: i,
                            documentDate: this.changeFormatDate(data.documentDate)
                        }
                    ))

                    this.setState({
                        dataSubList: dataList,
                    })
                }
                this.setState({ isShowModal: true, loading: false })
            } catch (error) {
                this.setState({ isShowModal: false, loading: false })
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            }

        }

        // outstandingBalanceDetail
    }

    handleCloseModal = () => {

        this.setState({ isShowModal: false, modalType: '', dataSelected: {} })
    }

    handleClickSearchProduct = async (e) => {
        this.setState({ loading: true })
        const { outletKeyword, productKeyword, searchText } = this.state
        const { auth } = this.props
        let params = {
            keywords: productKeyword == "keywords" ? searchText : '',
            productId: productKeyword == "productId" ? searchText : '',
            productNames: productKeyword == "productNames" ? searchText : '',
            barcode: productKeyword == "barcode" ? searchText : '',
            outletId: outletKeyword,
            storageLocationIds: auth.arrayStorageLocationId,
            isIncludeStockBalance: true,
            // limitMaxRange: 50,
            productStatus: true,
            pagination: this.state.pagination,
            sorter: this.state.sorter,
        }
        try {
            let res = await apiServices.callApi("post", `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, params)
            if (res.status === 200) {
                let dataList = res.data.results.map((data, i) => (
                    {
                        ...data,
                        key: i,
                    }
                ))

                // const pagination = { ...this.state.pagination };
                // pagination.total = res.data.filter.pagination.totalRecord;
                // pagination.current = res.data.filter.pagination.page;
                this.setState({
                    dataList: dataList,
                    pagination: {...res.data.filter.pagination},
                    filter: res.data.filter
                })
            }

        } catch (error) {

            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        } finally {
            this.setState({ isShowModal: false, loading: false })
        }

    }

    handleOutletCriteriaChange = (e) => {
        this.setState({ outletKeyword: e });
    }

    handleProductCriteriaChange = (e) => {
        // console.log("handleProductCriteriaChange ==>", e);
        this.setState({ productKeyword: e });

    }

    handleSearchTextChange = (e) => {
        this.setState({ searchText: e.target.value });
    }

    changeFormatDate = (value) => {
        if (value) {
            var d = new Date(value),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('-');
        }
        return value;
    }

    render() {
        const { columns, dataList, dataSubList, loading, isShowModal, modalType, dataSelected, dataSelectedProduct } = this.state


        return (
            <div className="product-layout">
                <TitleHead text="ค้นหารายการสินค้า" icon="search" />
                <div className="top-layout">
                    <Row gutter={20}>
                        <Col span={9} offset={2}>
                            <SelectHero
                                onChange={this.handleOutletCriteriaChange}
                                name="outletCriteria"
                                label="ค้นหาด้วย"
                                options={this.state.outletCriteria}
                                widthDefault="100px"
                                value={this.state.outletKeyword}
                            />
                        </Col>
                        <Col span={3}>
                            <SelectHero
                                onChange={this.handleProductCriteriaChange}
                                name="productCriteria"
                                options={this.state.productCriteria}
                                value={this.state.productKeyword}
                            />
                        </Col>
                        <Col span={6}>
                            <InputCustom
                                onChange={this.handleSearchTextChange}
                                name="value"
                                value={this.state.searchText}
                                onSubmit={(e) => this.handleClickSearchProduct()}
                            />
                        </Col>
                        <Col span={2}>
                            <div className="button-layout">
                                <span><ButtonCustom red text="ค้นหา" icon="search" onClick={async () => {
                                    await this.setState({
                                        ...this.state,
                                        pagination: {
                                            ...this.state.pagination,
                                            page: 1,
                                            totalRecord:0,
                                            take: 10,
                                            skip: 0
                                        }
                                    })
                                    this.handleClickSearchProduct()
                                }} /></span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="table-layout">
                    <TableCustom 
                        height="calc(100vh - 316px)" 
                        columns={columns}
                        data={dataList}
                        loading={loading}
                        pagination={{
                            current: this.state.pagination.page || 1,
                            total:this.state.pagination.totalRecord || 0,
                            defaultPageSize: 10,
                        }}
                        onChange={async (pagination, filters, sorter) => {
                            // console.log('pagination: ', pagination)
                            // console.log('filters: ', filters)
                            // console.log('sorter: ', sorter)
                            await this.setState({
                                ...this.state,
                                pagination: {
                                    ...this.state.pagination,
                                    page: pagination.current,
                                    totalRecord:pagination.total,
                                    take: pagination.pageSize,
                                    skip: (pagination.current - 1) * pagination.pageSize
                                },
                                sorter: {
                                    field: sorter.field,
                                    order: sorter.order
                                }
                            })
                            await this.handleClickSearchProduct()
                        }}
                    />
                </div>
                <ModalProductDetail
                    isShowModal={isShowModal && modalType === 'productDetail'}
                    handleCloseModal={this.handleCloseModal}
                    data={dataSelectedProduct}
                />
                <ModalOutstandingBalanceDetail
                    isShowModal={isShowModal && modalType === 'outstandingBalanceDetail'}
                    handleCloseModal={this.handleCloseModal}
                    data={dataSubList}
                />
            </div>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);