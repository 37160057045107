import styled from 'styled-components'

export const SelectReduxStyled = styled.div`

.ant-input-affix-wrapper .ant-select {
    border-color: ${props => props.error ? "red" : ''};
}

.ant-select-selection {
    font-size: ${props => props.small ? '12px' : ''};
    border-color: ${props => props.error ? "red" : ''};
}
.ant-select-selection-selected-value {
    display: flex;
    align-items: center;
}
.label-style {
    font-size: ${props => props.small ? '12px' : ''};
    width: ${props => props.small ? props.widthSmall ? props.widthSmall : '120px' : props.widthDefault ? props.widthDefault : '150px'};
}
.input-form {
    margin-bottom: ${props => props.small ? '3px' : ''};
}
.input-style {
    height: ${props => props.small ? '24px' : ''};
}
`