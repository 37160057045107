import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.css'
import { authData } from 'actions/actionsCreator';
import moment from 'moment'
import swal from '@sweetalert/with-react';
import { Row, Col, Spin } from "antd"
import {
  InputSearch,
  DatePickerCustom,
} from "components"
import {
  TitleHead,
  InputCustom,
  TableCustom,
  ButtonCustom,
  TextAreaCustom,
  SelectCustom,
  NumberFormatCustom,
} from "components/common"
import { get, first, isEmpty } from 'lodash';
import { PurchaseOrderSTOModal, StoreLocationModal, } from 'components/feature';
import { apiServices, API_PATH } from "apiServices";
import { functionAlias } from 'routes'
import { getPermissionPage } from 'helpers'

// TODO: check permission print button when added print button
class GoodsIssueSTOContainer extends Component {
  state = {
    loading: false,
    dateFormat: 'DD/MM/YYYY',
    outletOptions: [],
    header: {
      poNo: '',
      docDate: moment(),
      refDocNo: '',
      vendorCode: '',
      vendorName: '',
      remarks: '',
      outletId: '',
      address: '',
    },
    items: [],
    foundPODoc: false,
    dataPOItems: [],
    dataPOHeaders: [],
    dataPOHDTxts: [],
    dataPOAccounts: [],
    dataPOAddrDelivery: [],
    isShowModal: false,
    dataSelected: "",
    dataStorages: [],
    docType: "03TO",// fix value for SAP
    filterStore: {
      productId: '',
      salesUnit: '',
      outletId: '',
    },
    indexRowAction: null,

    modelRequest: {
      IM_PIID: '',
      IM_STO_NO: '',
      IM_GI_DATE: '',
      IT_ITEM_IN: {
        item: [],
      },
      IT_TEXT_IN: {
        item: [],
      }
    },

    columns: [
      {
        title: 'รายการ',
        dataIndex: 'pO_ITEM',
        key: 'pO_ITEM',
        align: 'center',
        render: (value) => (
          <div className="align-left">
            {value}
          </div>
        )
      },
      {
        title: 'รหัสสินค้า',
        dataIndex: 'material',
        key: 'material',
        align: 'center',
        render: (value) => (
          <div className="align-left">
            {value}
          </div>
        )
      },
      {
        title: 'ชื่อสินค้า',
        dataIndex: 'shorT_TEXT',
        key: 'shorT_TEXT',
        align: 'center',
        render: (value) => (
          <div className="align-left">
            {value}
          </div>
        )
      },
      {
        title: 'ที่เก็บ',
        dataIndex: 'stgE_LOC',
        key: 'stgE_LOC',
        align: 'center',
        render: (value, full, row) => (
          <div
            className="link-text"
            onClick={() => this.handleShowModal("storageLocation", { row, full })}
          >
            {isEmpty(value) ? 'blank' : value}
          </div>
        )
      },
      {
        title: 'หน่วยนับ',
        dataIndex: 'pO_UNIT',
        key: 'pO_UNIT',
        align: 'center',
        render: (value) => (
          <div className="align-left">
            {value}
          </div>
        )
      },
      {
        title: 'จำนวน',
        dataIndex: 'quantitY_ORD',
        key: 'quantitY_ORD',
        align: 'center',
        render: (value, full, row) => (
          <NumberFormatCustom
            name="quantitY_ORD"
            displayType={'text'}
            value={value}
          />
        )
      },
      {
        title: 'จำนวนค้างส่ง',
        dataIndex: 'stO_NOT_GI',
        key: 'stO_NOT_GI',
        align: 'center',
        render: (value) => (
          <NumberFormatCustom
            name="stO_NOT_GI"
            displayType={'text'}
            value={value}
          />
        )
      },
      {
        title: 'จำนวนที่จัดส่ง',
        dataIndex: 'giQty',
        key: 'giQty',
        align: 'center',
        render: (value, full, row) => {
          return <div className="align-left">
            <NumberFormatCustom
              name="giQty"
              className="text-bold-align-right"
              value={value}
              onChange={(e) => this.handleChangeItem(full.key, 'giQty', Number(`${e.target.value}`.replace(/,/g, '')))}
            />
          </div>
        },
      },
      {
        title: 'พนักงานจัดส่ง',
        dataIndex: 'sender',
        key: 'sender',
        align: 'center',
        render: (value, full, row) => (
          <InputCustom
            name="sender"
            value={value}
            onChange={(e) => this.handleChangeItem(full.key, 'sender', e.target.value)}
          />
        )
      },
      {
        title: 'ทะเบียนรถ',
        dataIndex: 'car',
        key: 'car',
        align: 'center',
        render: (value, full, row) => (
          <InputCustom
            name="car"
            value={value}
            onChange={(e) => this.handleChangeItem(full.key, 'car', e.target.value)}
          />
        )
      },
      {
        title: 'เลขบัตรประชาชน/ใบขับขี่',
        dataIndex: 'taxId',
        key: 'taxId',
        align: 'center',
        render: (value, full, row) => (
          <NumberFormatCustom
            format="#-####-#####-##-#"
            mask="_"
            name="taxId"
            value={value}
            onChange={(e) => this.handleChangeItem(full.key, 'taxId', e.target.value)}
          />
        )
      },
    ],
    permissionPage: getPermissionPage(functionAlias.inventoryGISTO),
  };

  componentDidMount() {
    this.fetchDataDefault();
  }

  fetchDataDefault = async () => {
    const { auth } = this.props;
    let { outletOptions, header } = this.state;
    let paramsOutlet = {
      outletIds: auth.userOutletModelList.map((data) => (
        data.outletId
      ))
    };

    //ร้านค้า Outlet
    await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet).then(res => {
      let results = get(res, 'data.results');
      outletOptions = results.map(data => ({
        label: `${data.outletId} - ${data.outletName}`,
        value: data.outletId,
      }));

      if (!isEmpty(outletOptions) && outletOptions.length > 0) {
        const defaultOutlet = first(outletOptions);
        header = {
          ...header,
          outletId: defaultOutlet.value,
        }
      }

      this.setState({ outletOptions, header });
    }).catch(error => {
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลร้านค้า!')
      swal('Error', message, 'error')
    });

  }

  handleChangeHeader = (key, val) => {
    const { header } = this.state
    this.setState({ header: { ...header, [key]: val } });
  }

  handleChangeItem = (row, key, val) => {
    let { items } = this.state;
    console.log(`row==>${row}, key==>${key}, val==>${val}`);
    console.log(`Befor items[${row}]`, items[row]);
    items[row] = {
      ...items[row],
      [key]: val
    };
    console.log(`After items[${row}]`, items[row]);
    this.setState({ items });
  }

  handleShowModal = async (modalType, dataSelected) => {
    let { filterStore, header } = this.state

    this.setState({ indexRowAction: get(dataSelected, 'full.key') })

    switch (modalType) {
      case "storageLocation":
        // console.log("dataSelected==>", dataSelected);

        await this.fetchDataStorelocation(
          get(dataSelected, 'full.material'),
          get(dataSelected, 'full.pO_UNIT'),
          get(header, 'outletId')
        );
        break;
      default:
        break;
    }

    this.setState({ modalType, dataSelected, isShowModal: true, filterStore });
  }

  fetchDataStorelocation = async (productId, salesUnit, outletId) => {
    const { auth } = this.props
    let params = {
      productId: productId,
      salesUnit: salesUnit,
      outletId: outletId,
      isIncludeStockATP: true,
      storageLocationIds: auth.arrayStorageLocationId,
      limitMaxRange: 100,
      productStatus: true
    }

    await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, params).then(res => {
      const results = get(res, 'data.results');
      console.log("results==>", results);
      if (isEmpty(results)) {
        swal('Error', `ไม่พบรายการสินค้า ${params.productId} (${params.salesUnit}) ร้าน ${params.outletId}`, 'error')
      }
      const dataStorages = results.map((data, i) => (
        {
          key: i,
          no: i + 1,
          ...data
        }
      ))
      this.setState({ dataStorages, params });

    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล Store location!')
      swal('Error', message, 'error')
    });
  }

  handleCloseModal = () => {
    this.setState({ modalType: '', dataSelected: '', isShowModal: false, indexRowAction: null });
  }

  onSelectedPO = (poDetail) => {
    let { header, items } = this.state;
    let addr = poDetail.eT_PO_ADDRDELIVERY != null ? (poDetail.eT_PO_ADDRDELIVERY.name + ' ' || '') +
      (poDetail.eT_PO_ADDRDELIVERY.streeT_LNG + ' ' || '') +
      (poDetail.eT_PO_ADDRDELIVERY.district + ' ' || '') +
      (poDetail.eT_PO_ADDRDELIVERY.city + ' ' || '') +
      (poDetail.eT_PO_ADDRDELIVERY.postL_COD1 + ' ' || '') +
      (poDetail.eT_PO_ADDRDELIVERY.teL1_NUMBR + ' ' || '')
      : '';

    header = {
      ...header,
      poNo: poDetail.pO_NUMBER,
      docDate: moment(poDetail.doC_DATE),
      refDocNo: poDetail.collecT_NO,
      vendorCode: poDetail.vendor,
      vendorName: poDetail.venD_NAME,
      remarks: get(poDetail, 'eT_PO_HDTXT.item.0.TEXT_LINE', ''),
      address: addr,
    }
    items = poDetail.eT_PO_ITEM.map((item, index) => (
      {
        ...item,
        key: index,
        giQty: item.sTO_NOT_GI
      }
    ));
    console.log('PO items ==>', items)
    this.setState({ header, foundPODoc: true, items });
    this.handleCloseModal();
  }

  onSelectedStoreLocation = (slocSelected) => {
    let { indexRowAction, items } = this.state;
    const storelocation = get(slocSelected, 'dataSelected', '');
    console.log('storelocation ==>', storelocation)

    const _items = items.map((item, index) => (
      {
        ...item,
        stgE_LOC: index === indexRowAction ? storelocation.storageLocationId : item.stgE_LOC
      }));

    this.setState({ items: _items }, () => this.handleCloseModal());
  }

  handleSubmitSearchPurchaseOrderSAPPI = async (e) => {
    e.preventDefault();
    const { header, docType } = this.state;

    if (isEmpty(header.poNo)) {
      return;
    }

    this.setState({ loading: true });

    //Get PO Detail form SAP.
    const urlGetPODetailSAP = `${API_PATH.GET_PO_AND_STO_DETAIL}?PONo=${header.poNo}&Outlet=${header.outletId}&DocType=${docType}&OutletSelName=S_RESWK&IsReleaseIndicator=${true}`;
    //console.log(`urlGetPODetailSAP==> ${urlGetPODetailSAP}`);
    await apiServices.callApi('get', urlGetPODetailSAP).then(res => {
      let result = get(res, 'data.result');
      console.log(`GetPODetail result ==>`, result);
      let poHeaders = get(result, 'eT_PO_HEADER_REPORT.item');
      let poItems = get(result, 'eT_PO_ITEM_REPORT.item');
      let dataPOHDTxts = get(result, 'eT_PO_HDTXT.item');
      let dataPOAccounts = get(result, 'eT_PO_ACCOUNT.item');
      let dataPOAddrDelivery = get(result, 'eT_PO_ADDRDELIVERY.item');

      if (isEmpty(poHeaders) || poHeaders.length === 0) {
        swal('Error', `ไม่พบเอกสารใบรับสินค้าเลขที่: ${header.poNo}`, 'error');
        this.setState({ loading: false });
        return;
      }

      let dataPOItems = poItems.map((data, i) => ({
        key: i,
        row: i,
        ...data
      }));

      let poAddrDelivery = null;

      if (!isEmpty(dataPOAddrDelivery) && dataPOAddrDelivery.length > 0) {
        const poAddrDeliveries = dataPOAddrDelivery.filter(poHeader => poHeader.pO_NUMBER === poHeaders[0].pO_NUMBER);
        poAddrDelivery = first(poAddrDeliveries);
      }

      let dataPOHeaders = poHeaders.map((poHeader, i) => ({
        key: i,
        ...poHeader,
        eT_PO_ITEM: poHeader.eT_PO_ITEM.map((poItem, i) => ({
          ...poItem,
          stockType: '',
          giQty: poItem.quantitY03,
        })),
        eT_PO_ADDRDELIVERY: poAddrDelivery,
      }));

      this.onSelectedPO(first(dataPOHeaders));

      this.setState({ dataPOItems, dataPOHeaders, dataPOHDTxts, dataPOAccounts, dataPOAddrDelivery });
    }).catch(error => {
      console.log(`error catch ==>`, error);
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างค้นหาข้อมูลใบสั่งซื้อ!')
      swal('Error', message, 'error')
    });
    this.setState({ loading: false });
  }

  handleSubmitGISTODelivery = async () => {
    this.setState({ loading: true });
    const { auth } = this.props
    let { modelRequest, header, items } = this.state;

    if (!this.validateCreateGI(items)) {
      this.setState({ loading: false });
      return;
    }

    //console.log('items==>',items);
    const it_ITEM_IN = items.map((item) => ({
      DELIV_I: item.pO_ITEM,
      STGE_LOC: item.stgE_LOC,
      DLV_QTY: item.giQty,
      UNIT: item.pO_UNIT,
      TRACKINGNO: item.trackingno,
      PREQ_NAME: item.preQ_NAME,
    }));

    let it_TEXT_IN = [];

    for (const item of items) {
      let sender = {
        DELIV_I: '',
        TEXT_ID: 'ZM01',
        LANGU: 'EN',
        FORMAT_COL: '',
        TEXT_LINE: item.sender,
      }
      let car = {
        DELIV_I: '',
        TEXT_ID: 'ZM02',
        LANGU: 'EN',
        FORMAT_COL: '',
        TEXT_LINE: item.car,
      }
      let taxId = {
        DELIV_I: '',
        TEXT_ID: 'ZM03',
        LANGU: 'EN',
        FORMAT_COL: '',
        TEXT_LINE: item.taxId,
      }
      it_TEXT_IN.push(sender);
      it_TEXT_IN.push(car);
      it_TEXT_IN.push(taxId);
    }

    modelRequest = {
      ...modelRequest,
      IM_GI_DATE: header.docDate,
      IM_PIID: '',
      IM_STO_NO: header.poNo,
      IT_ITEM_IN: {
        item: it_ITEM_IN
      },
      IT_TEXT_IN: {
        item: it_TEXT_IN
      },
      OUTLET_ID: header.outletId
    };
    console.log('modelRequest==>', modelRequest);

    await apiServices.callApi('post', API_PATH.POST_GI_STO_DELIVERY, modelRequest).then(async res => {
      let results = get(res, 'data.result');
      let message = get(res, 'data.message');
      swal(`${message}`, { buttons: ["พิมพ์", "ปิด"], });
      await this.fetchDataDefault();

    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูลการตัดจ่ายสินค้า STO!')
      swal('Error', message, 'error');
    });
    this.setState({ loading: false });
  }

  validateCreateGI = (grItems) => {
    const itemOverPO = grItems.filter(item => item.giQty > item.quantitY03);
    if (!isEmpty(itemOverPO) && itemOverPO.length > 0) {
      swal('Error', `รายการ ${first(itemOverPO).material}: ${first(itemOverPO).shorT_TEXT} จำนวนรับมากกว่าจำนวนสั่งซื้อ!`, 'error');
      return false;
    }

    return true
  }

  render() {
    const {
      loading,
      dateFormat,
      isShowModal,
      foundPODoc,

      header,
      items,
      columns,
      outletOptions,
      modalType,
      dataStorages,
      docType,
      permissionPage,
    } = this.state;

    return (
      <div className="goods-receive-container">
        <Spin spinning={loading}>
          <TitleHead text="การตัดจ่ายสินค้า STO" icon="stock" />
          <div className="middle-content">
            <Row gutter={20}>
              <Col md={4}>
                ร้าน*
              </Col>
              <Col md={8}>
                <SelectCustom
                  options={outletOptions}
                  pleaseChoose
                  onChange={(value) => this.handleChangeHeader('outletId', value)}
                  value={header.outletId}
                  name="outletId"
                  disabled={foundPODoc}
                />
              </Col>
              <Col md={4}>
                เลขที่ใบส่งสินค้า
              </Col>
              <Col md={8}>
                <InputSearch
                  name='poNo'
                  widthAll="100%"
                  placeholder="4500002629"
                  value={header.poNo}
                  onChange={(e) => this.handleChangeHeader('poNo', e.target.value)}
                  handleClickSearch={() => this.handleShowModal('purchaseOrder', {})}
                  handleSubmitSearch={this.handleSubmitSearchPurchaseOrderSAPPI}
                  disabledButton={foundPODoc}
                  disabled={foundPODoc}
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={4}>
                วันตัดจ่ายสินค้า
              </Col>
              <Col md={8}>
                <DatePickerCustom
                  name="docDate"
                  widthAll="100%"
                  value={header.docDate}
                  format={dateFormat}
                  allowClear={false}
                  onChange={(date) => this.handleChangeHeader('docDate', date)}
                />
              </Col>
              <Col md={4}>
                ผู้ซื้อ
              </Col>
              <Col md={8}>
                <InputCustom
                  name="vendorCode"
                  disabled={true}
                  value={`${header.vendorCode} ${header.vendorName}`}
                />
              </Col>

            </Row>
            <Row gutter={20}>
              <Col md={4}>
                ที่อยู่
              </Col>
              <Col md={20}>
                <a>{header.address}</a>
              </Col>
            </Row>
            <br />
            <Row gutter={20}>
              <Col md={4}>
                หมายเหตุ
              </Col>
              <Col md={20}>
                <TextAreaCustom
                  name="remarks"
                  value={header.remarks}
                  onChange={(e) => this.handleChangeHeader('remarks', e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={24} className="search-box">
                <ButtonCustom
                  icon="search"
                  type="primary"
                  text="ค้นหา"
                  small
                  onClick={this.handleSubmitSearchPurchaseOrderSAPPI}
                  disabled={foundPODoc}
                />
              </Col>
            </Row>

            <div className="table-layout">
              <TableCustom columns={columns} scroll={{ x: "max-content" }} data={items} rowKey="key" small />
            </div>
          </div>

          <div className="bottom-content">
            <div className="left-zone">
              <div className="button">
                <ButtonCustom text='ยกเลิก' icon='close' type='danger' />
              </div>
            </div>
            <div className="right-zone">
              <div className="button">
                <ButtonCustom
                  text='บันทึก'
                  icon='save'
                  type="primary"
                  onClick={this.handleSubmitGISTODelivery}
                  disabled={!permissionPage.authCreate}
                />
              </div>
            </div>
          </div>
        </Spin>

        <PurchaseOrderSTOModal
          isShowModal={modalType === 'purchaseOrder' && isShowModal}
          handleCloseModal={this.handleCloseModal}
          onSelected={this.onSelectedPO}
          outletId={header.outletId}
          docType={docType}
        />

        <StoreLocationModal
          isShowModal={modalType === 'storageLocation' && isShowModal}
          handleCloseModal={this.handleCloseModal}
          onSelected={this.onSelectedStoreLocation}
          requireStock={true}
          dataStorages={dataStorages}
        />
      </div>
    );
  }
}


const mapStateToProps = state => {
  // console.log("state===>", state);
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    authData
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodsIssueSTOContainer);