import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { ROUTH_PATH } from 'routes/path';
import './style.css'
import { authData } from 'actions/actionsCreator';
import moment from 'moment'
import swal from '@sweetalert/with-react';
import { Row, Col, Spin, } from "antd"
import {
  InputSearch,
  DatePickerCustom,
} from "components"
import {
  TitleHead,
  InputCustom,
  TableCustom,
  ButtonCustom,
  TextAreaCustom,
  SelectCustom,
  NumberFormatCustom,
  CheckboxCustom,
} from "components/common"
import { get, first, isEmpty } from 'lodash';
import { PurchaseOrderModal, StoreLocationModal, } from 'components/feature';
import { apiServices, API_PATH } from "apiServices";
import { giStockType, getPermissionPage } from 'helpers';
import { functionAlias } from 'routes';

// TODO: check permission print button when added print button
class GoodsReceiveContainer extends Component {
  state = {
    loading: false,
    dateFormat: 'DD/MM/YYYY',
    outletOptions: [],
    header: {
      poNo: '',
      docDate: moment(),
      refDocNo: '',
      vendorCode: '',
      vendorName: '',
      remarks: '',
      outletId: '',
      posto: false,
    },
    items: [],
    foundPODoc: false,
    dataPOItems: [],
    dataPOHeaders: [],
    dataPOHDTxts: [],
    dataPOAccounts: [],
    isShowModal: false,
    dataSelected: "",
    dataStorages: [],
    filterStore: {
      productId: '',
      salesUnit: '',
      outletId: '',
    },
    defaultStore: '',
    selectedItemRow: 0,

    modelGoodsReceiveRequest: {
      IM_MODE: 'C',
      IM_MOVE_TYPE: '101',
      IM_TYPE_REF_DOC: 'R01',
      IM_PIID: '',
      OUTLET_ID: '',
      IM_GOODSMVT_HEADER: {
        item: [],
      },
      IT_REMARK: {
        item: [],
      },
      IT_GOODSMVT_ITEM: {
        item: [],
      }
    },

    goodReceiveModel: {
      goodsMovementRequest: null,
      purchaseOrderSAPI1Model: null,
    },

    columns: [
      {
        title: 'รหัสสินค้า',
        dataIndex: 'material',
        key: 'material',
        align: 'center',
        render: (value) => (
          <div className="align-left">
            {value}
          </div>
        )
      },
      {
        title: 'บาร์โค้ด',
        dataIndex: 'eaN11',
        key: 'eaN11',
        align: 'center',
        render: (value) => (
          <div className="align-left">
            {value}
          </div>
        )
      },
      {
        title: 'ชื่อสินค้า',
        dataIndex: 'materiaL_TX', //'shorT_TEXT',
        key: 'materiaL_TX',//'shorT_TEXT',
        align: 'center',
        render: (value) => (
          <div className="align-left">
            {value}
          </div>
        )
      },
      {
        title: 'ที่เก็บ',
        dataIndex: 'stgE_LOC',
        key: 'stgE_LOC',
        align: 'center',
        render: (value, full, row) => {
          // if (full.fC_SO === 'ใบสั่งขาย Special(ร้าน)') {
          if (!isEmpty(full.fC_SO)) {
            return isEmpty(value) ? 'blank' : value
          } else {
            return <div
              className="link-text"
              onClick={() => this.handleShowModal("storageLocation", { row, full })}
            >
              {isEmpty(value) ? 'blank' : value}
            </div>
          }
        }
      },
      {
        title: 'หน่วยนับ',
        dataIndex: 'pO_UNIT',
        key: 'pO_UNIT',
        align: 'center',
        render: (value) => (
          <div className="align-left">
            {value}
          </div>
        )
      },
      {
        title: 'จำนวนสั่งซื้อ',
        dataIndex: 'quantitY_ORD',
        key: 'quantitY_ORD',
        align: 'center',
        render: (value, full, row) => (
          <NumberFormatCustom
            name="quantitY_ORD"
            displayType={'text'}
            value={value}
          />
        )
      },
      {
        title: 'จำนวนค้างรับ',
        dataIndex: 'quantitY03',
        key: 'quantitY03',
        align: 'center',
        render: (value) => (
          <NumberFormatCustom
            name="quantitY03"
            displayType={'text'}
            value={value}
          />
        )
      },
      {
        title: 'จำนวนรับครั้งนี้',
        dataIndex: 'receivedQty',
        key: 'receivedQty',
        align: 'center',
        render: (value, full, row) => (
          <div className="align-left">
            <NumberFormatCustom
              name="receivedQty"
              className="text-bold-align-right"
              value={value}
              decimalScale={3}
              onChange={(e) => this.handleChangeItem(full.key, 'receivedQty', Number(`${e.target.value}`.replace(/,/g, '')))}
            />
          </div>
        )
      },
      {
        title: 'ประเภทสินค้า',
        dataIndex: 'stockType',
        key: 'stockType',
        align: 'center',
        render: (value, full, row) => (
          <div className="align-left">
            <SelectCustom
              name="stockType"
              onChange={(value) => this.handleChangeItem(full.key, 'stockType', value)}
              options={giStockType}
              value={value}
            />
          </div>
        )
      },
      {
        title: 'ใบสั่งขาย Special(ร้าน)',
        dataIndex: 'fC_SO',
        key: 'fC_SO',
        align: 'center',
        render: (value) => (
          <div className="align-left">
            {value}
          </div>
        )
      },
    ],
    permissionPage: getPermissionPage(functionAlias.inventoryReceive),
  };

  componentDidMount() {
    this.fetchDataDefault();
  }

  fetchDataDefault = async () => {
    this.setState({
      outletOptions: [],
      header: {
        poNo: '',
        docDate: moment(),
        refDocNo: '',
        vendorCode: '',
        vendorName: '',
        remarks: '',
        outletId: '',
        posto: false,
      },
      items: [],
      foundPODoc: false,
      dataPOItems: [],
      dataPOHeaders: [],
      dataPOHDTxts: [],
      dataPOAccounts: [],
      isShowModal: false,
      dataSelected: "",
      dataStorages: [],
      filterStore: {
        productId: '',
        salesUnit: '',
        outletId: '',
      },
      defaultStore: '',

      modelGoodsReceiveRequest: {
        IM_MODE: 'C',
        IM_MOVE_TYPE: '101',
        IM_TYPE_REF_DOC: 'R01',
        IM_PIID: '',
        IM_GOODSMVT_HEADER: {
          item: [],
        },
        IT_REMARK: {
          item: [],
        },
        IT_GOODSMVT_ITEM: {
          item: [],
        }
      },
    });

    await this.fetchDataOutlet();
    await this.fetchDataDefaultStoreLocation();
  }

  fetchDataOutlet = async () => {
    const { auth } = this.props;
    let { outletOptions, header } = this.state;
    let paramsOutlet = {
      outletIds: auth.userOutletModelList.map((data) => (
        data.outletId
      ))
    };
    //ร้านค้า Outlet
    await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet).then(res => {
      let results = get(res, 'data.results');
      //console.log("results==>", results);
      outletOptions = results.map(data => ({
        label: `${data.outletId} - ${data.outletName}`,
        value: data.outletId,
      }));

      if (!isEmpty(outletOptions) && outletOptions.length > 0) {
        const defaultOutlet = first(outletOptions);
        header = {
          ...header,
          outletId: defaultOutlet.value,
        }
      }
      //console.log("outletOptions==>", outletOptions);
      this.setState({ outletOptions, header });
    }).catch(error => {
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลร้านค้า!')
      swal('Error', message, 'error')
    });
  }

  fetchDataDefaultStoreLocation = async () => {
    let { header, defaultStore } = this.state;

    const paramsStoreLocation = `?ConditionName=GR&Condition1=${header.outletId}&Condition2=SLOC`;
    //Default store location
    await apiServices.callApi('get', `${API_PATH.GET_MASTER_SPECIAL_CONDITION}/${paramsStoreLocation}`, '', '').then(res => {
      let results = get(res, 'data.results');

      if (!isEmpty(results) && results.length > 0) {
        //console.log("paramsStoreLocation results ===>", first(results));
        defaultStore = first(results).value;
      }

      this.setState({ defaultStore });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "ที่เก็บ(Default Store Location)"!')
      swal('Error', message, 'error');
    });
  }

  handleChangeHeader = (key, val) => {
    const { header } = this.state
    this.setState({ header: { ...header, [key]: val } });
  }

  handleChangeItem = (row, key, val) => {
    let { items } = this.state;
    console.log(`row==>${row}, key==>${key}, val==>${val}`);
    console.log(`Befor items[${row}]`, items[row]);
    items[row] = {
      ...items[row],
      [key]: val
    };
    console.log(`After items[${row}]`, items[row]);
    this.setState({ items });
  }

  handleShowModal = async (modalType, dataSelected) => {
    let { filterStore, header } = this.state
    switch (modalType) {
      case "storageLocation":
        console.log("dataSelected==>", dataSelected);

        this.setState({ selectedItemRow: dataSelected.full && dataSelected.full.key }, () =>
          this.fetchDataStorelocation(
            get(dataSelected, 'full.material'),
            get(dataSelected, 'full.pO_UNIT'),
            get(header, 'outletId')
          )
        );
        break;
      default:
        break;
    }

    this.setState({ modalType, dataSelected, isShowModal: true, filterStore });
  }

  fetchDataStorelocation = async (productId, salesUnit, outletId) => {
    const { auth } = this.props
    let params = {
      productId: productId,
      salesUnit: salesUnit,
      outletId: outletId,
      isIncludeStockATP: true,
      storageLocationIds: auth.arrayStorageLocationId,
      limitMaxRange: 100,
      productStatus: true
    }

    //console.log("API params==>", params);
    this.setState({ loading: true });
    //Store location.
    await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, params).then(res => {
      const results = get(res, 'data.results');
      //console.log("results==>", results);
      if (isEmpty(results)) {
        swal('Error', `ไม่พบรายการสินค้า ${params.productId} (${params.salesUnit}) ร้าน ${params.outletId}`, 'error')
      }
      const dataStorages = results.map((data, i) => (
        {
          key: i,
          no: i + 1,
          ...data
        }
      ))
      this.setState({ dataStorages, params });

    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล Store location!')
      swal('Error', message, 'error')
    });

    this.setState({ loading: false });
  }

  handleCloseModal = () => {
    this.setState({ modalType: '', dataSelected: '', isShowModal: false });
  }

  onSelectedPO = (poDetail) => {
    let { header, items, defaultStore } = this.state;

    console.log("poDetail==>", poDetail);
    header = {
      ...header,
      poNo: poDetail.pO_NUMBER,
      //docDate: moment(poDetail.doC_DATE),
      refDocNo: poDetail.collecT_NO,
      vendorCode: poDetail.vendor,
      vendorName: poDetail.venD_NAME,
      remarks: get(poDetail, 'eT_PO_HDTXT.item.0.TEXT_LINE', ''),
    }
    items = poDetail.eT_PO_ITEM.map((data, i) => (
      {
        key: i,
        ...data,
        stgE_LOC: data.stgE_LOC ? data.stgE_LOC : defaultStore,
        receivedQty: Math.abs(data.receivedQty),
        quantitY_ORD: Math.abs(data.quantitY_ORD),
        quantitY03: Math.abs(data.quantitY03),
      }
    ));
    this.setState({ header, foundPODoc: true, items });
    this.handleCloseModal();
  }

  onSelectedStoreLocation = (selected) => {
    //console.log("selected==>",selected);
    //console.log("rowSelected==>",selected.rowSelected);
    //console.log("dataSelected==>",selected.dataSelected.storageLocationId);
    this.changeStoreLocation(selected.rowSelected, selected.dataSelected.storageLocationId);
    this.handleCloseModal();
  }

  changeStoreLocation = (row, storeid) => {
    let { items } = this.state;
    //console.log(`items[${row}]==>`,items[row]);
    items[row] = {
      ...items[row],
      stgE_LOC: storeid
    };
    this.setState({ items });
  }

  handleSubmitSearchPurchaseOrderSAPPI = async (e) => {
    e.preventDefault();
    const { header, defaultStore } = this.state;

    if (isEmpty(header.poNo)) {
      return;
    }
    this.setState({ loading: true });

    //Get PO Detail form SAP.
    const urlGetPODetailSAP = `${API_PATH.GET_PO_AND_STO_DETAIL}?PONo=${header.poNo}&Outlet=${header.outletId}&IsReleaseIndicator=${true}&POSTO=${header.posto}&OutletSelName=S_WERKS`;
    //console.log(`urlGetPODetailSAP==> ${urlGetPODetailSAP}`);
    await apiServices.callApi('get', urlGetPODetailSAP).then(res => {
      let result = get(res, 'data.result');
      console.log(`GetPODetail result ==>`, result);
      let poHeaders = get(result, 'eT_PO_HEADER_REPORT.item');
      let poItems = get(result, 'eT_PO_ITEM_REPORT.item');
      let dataPOHDTxts = get(result, 'eT_PO_HDTXT.item');
      let dataPOAccounts = get(result, 'eT_PO_ACCOUNT.item');

      if (isEmpty(poHeaders) || poHeaders.length === 0) {
        swal('Error', `ไม่พบเอกสารใบรับสินค้าเลขที่: ${header.poNo}`, 'error');
        this.setState({ loading: false });
        return;
      }

      if (isEmpty(poItems) || poItems.length === 0) {
        swal('Error', `ใบรับสินค้าเลขที่: ${header.poNo} รับเข้าสินค้าสมบูรณ์แล้ว`, 'error');
        this.setState({ loading: false });
        return;
      }

      let dataPOItems = poItems.map((data, i) => ({
        key: i,
        row: i,
        ...data,
        stgE_LOC: data.stgE_LOC ? data.stgE_LOC : defaultStore
      }));

      let dataPOHeaders = poHeaders.map((poHeader, i) => ({
        key: i,
        ...poHeader,
        eT_PO_ITEM: poHeader.eT_PO_ITEM.map((poItem, i) => ({
          ...poItem,
          stockType: '',
          receivedQty: poItem.stO_GI - poItem.quantitY03,
          sdDoc: !isEmpty(dataPOAccounts) && dataPOAccounts.length > 0 ? this.checkSoSpecialOrder(poItem, dataPOAccounts) : '',
          stgE_LOC: poItem.stgE_LOC ? poItem.stgE_LOC : defaultStore,
        }))
      }));

      this.onSelectedPO(first(dataPOHeaders));

      this.setState({ dataPOItems, dataPOHeaders, dataPOHDTxts, dataPOAccounts });
    }).catch(error => {
      console.log(`error catch ==>`, error);
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างค้นหาข้อมูลใบสั่งซื้อ!')
      swal('Error', message, 'error')
    });
    this.setState({ loading: false });
  }

  checkSoSpecialOrder = (dataPOItem, dataPOAccounts) => {
    let sdDoc = '';
    console.log(`dataPOItem ==>`, dataPOItem);
    console.log(`dataPOAccounts ==>`, dataPOAccounts);
    const dataPOAccount = dataPOAccounts.filter(poAccount => poAccount.pO_NUMBER === dataPOItem.pO_NUMBER && poAccount.pO_ITEM === dataPOItem.pO_ITEM);
    if (!isEmpty(dataPOAccount) && dataPOAccount.length > 0)
      sdDoc = first(dataPOAccount).SD_DOC;
    return sdDoc;
  }

  handleSubmitGoodsReceive = async () => {
    const { auth } = this.props
    let { modelGoodsReceiveRequest, goodReceiveModel, header, items } = this.state;


    if (!header.refDocNo) {
      swal('Error', `กรุณาระบุเลขที่ใบส่งสินค้า!`, 'error');
      return;
    }

    if (!this.validateCreateGR(items)) {
      return;
    }

    this.setState({ loading: true });
    const im_GOODSMVT_HEADER = {
      DOC_DATE: moment().format('YYYY-MM-DD'),
      PSTNG_DATE: moment().format('YYYY-MM-DD'),
      REF_DOC_NO: header.refDocNo,
      WEB_USER: auth.userId,
    };

    const it_REMARK = [{
      TDLINE: header.remarks,
    }]

    //console.log('items==>',items);
    const it_GOODSMVT_ITEM = items.map((item) => ({
      MATERIAL: item.material,
      PLANT: item.plant,
      STGE_LOC: item.stgE_LOC,
      ENTRY_UOM: item.pO_UNIT,
      ENTRY_QNT: item.receivedQty,
      PO_NUMBER: item.pO_NUMBER,
      PO_ITEM: item.pO_ITEM,
      STCK_TYPE: item.stockType,
    }));

    modelGoodsReceiveRequest = {
      ...modelGoodsReceiveRequest,
      IM_GOODSMVT_HEADER: im_GOODSMVT_HEADER
      ,
      IT_GOODSMVT_ITEM: {
        item: it_GOODSMVT_ITEM
      },
      IT_REMARK: {
        item: it_REMARK
      },
      OUTLET_ID: header.outletId,
    };

    goodReceiveModel = {
      goodsMovementRequest: modelGoodsReceiveRequest,
      eT_PO_ITEMs: items
    }
    //return;
    await apiServices.callApi('post', API_PATH.POST_GOODS_MOVEMENT_UPDATE_NON_STOCK, goodReceiveModel).then(async res => {
      let result = get(res, 'data.result');
      let message = get(res, 'data.message');
      //console.log('results==>', result);
      //console.log('message==>', message);
      this.printForm(result.eX_MATDOC, header.outletId, modelGoodsReceiveRequest.IM_MOVE_TYPE, message)
      await this.fetchDataDefault();
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูลการรับสินค้าจากการซื้อ(GR)!')
      swal('Error', message, 'error');
    });
    this.setState({ loading: false });
  }

  validateCreateGR = (grItems) => {
    const itemOverPO = grItems.filter(item => Number(item.receivedQty) > Number(item.quantitY03));
    if (!isEmpty(itemOverPO) && itemOverPO.length > 0) {
      swal('Error', `รายการ ${first(itemOverPO).material}: ${first(itemOverPO).shorT_TEXT} จำนวนรับมากกว่าจำนวนสั่งซื้อ!`, 'error');
      return false;
    }
    return true
  }

  printForm(matDoc, outlet, movementTypes, message) {
    swal({
      title: 'Success',
      icon: 'success',
      className: 'sweetalert-lg',
      text: `สร้างข้อมูลการรับสินค้าจากการซื้อ(GR)  : ${matDoc} ${message}`,
      buttons: {
        defeat2: {
          text: "พิมพ์ใบรับสินค้า(เต็ม)",
          value: "A4",
        },
        defeat: {
          text: "พิมพ์ใบรับสินค้า(ย่อ)",
          value: "A5",
        },
        /*catch: {
          text: "พิมพ์",
          value: "A5",
        },*/
        cancel: "ปิด",
      },
    }).then(async (value) => {
      let URL_REPORT = API_PATH.POST_GET_REPORT_FORM_GOOD_RECEIVE_A4;
      let newData = {
        "matDoc": matDoc,
        "outlet": outlet,
        "movementTypes": [movementTypes]
      }
      // console.log('value', value);
      switch (value) {
        case 'A4':
          URL_REPORT = API_PATH.POST_GET_REPORT_FORM_GOOD_RECEIVE_A4;
          break;
        default:
          URL_REPORT = API_PATH.POST_GET_REPORT_FORM_GOOD_RECEIVE_A5;
          break;
      }

      let reportRDF = await apiServices.callApi('post', URL_REPORT, newData, { responseType: 'blob' })
      let urlOpen = window.URL.createObjectURL(reportRDF.data)
      window.open(urlOpen)

    })

  }
  render() {
    const {
      loading,
      dateFormat,
      isShowModal,
      foundPODoc,

      header,
      items,
      columns,
      outletOptions,
      modalType,
      dataStorages,
      selectedItemRow,
      permissionPage,
    } = this.state;
    return (
      <div className="goods-receive-container">
        <Spin spinning={loading}>
          <TitleHead text="รายการรับเข้าสินค้า" icon="download" />
          <div className="middle-content">
            <Row gutter={20}>
              <Col md={4}>
                ร้าน*
              </Col>
              <Col md={8}>
                <SelectCustom
                  options={outletOptions}
                  pleaseChoose
                  onChange={(value) => this.handleChangeHeader('outletId', value)}
                  value={header.outletId}
                  name="outletId"
                  disabled={foundPODoc}
                />
              </Col>
              <Col md={4}>
                เลขที่ใบสั่งซื้อ
              </Col>
              <Col md={8}>
                <InputSearch
                  name='poNo'
                  widthAll="100%"
                  placeholder="4500002629"
                  value={header.poNo}
                  onChange={(e) => this.handleChangeHeader('poNo', e.target.value)}
                  handleClickSearch={() => this.handleShowModal('purchaseOrder', {})}
                  handleSubmitSearch={this.handleSubmitSearchPurchaseOrderSAPPI}
                  disabledButton={foundPODoc}
                  disabled={foundPODoc}
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={4}>
                วันที่ออกเอกสาร
              </Col>
              <Col md={8}>
                <DatePickerCustom
                  name="docDate"
                  widthAll="100%"
                  value={header.docDate}
                  format={dateFormat}
                  allowClear={false}
                  onChange={(date) => this.handleChangeHeader('docDate', date)}
                />
              </Col>
              <Col md={4}>
                เลขที่ใบส่งสินค้า(ผู้ขาย)
              </Col>
              <Col md={8}>
                <InputCustom
                  name="refDocNo"
                  placeholder="2000007121"
                  value={header.refDocNo}
                  onChange={(e) => this.handleChangeHeader('refDocNo', e.target.value)}
                />
              </Col>

            </Row>
            <Row gutter={20}>
              <Col md={4}>
                ผู้ขาย
              </Col>
              <Col md={8}>
                <InputCustom
                  name="vendorCode"
                  disabled={true}
                  value={`${header.vendorCode} ${header.vendorName}`}
                />
              </Col>
              <Col md={8}>
                <CheckboxCustom
                  text="PO STO"
                  name="posto"
                  onChange={(e) => this.handleChangeHeader('posto', e.target.checked)}
                  checked={header.posto}
                />
              </Col>
            </Row>

            <Row gutter={20}>
              <Col md={4}>
                หมายเหตุ
              </Col>
              <Col md={20}>
                <TextAreaCustom
                  name="remarks"
                  value={header.remarks}
                  onChange={(e) => this.handleChangeHeader('remarks', e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={24} className="search-box">
                <ButtonCustom
                  icon="search"
                  type="primary"
                  text="ค้นหา"
                  small
                  onClick={this.handleSubmitSearchPurchaseOrderSAPPI}
                  disabled={foundPODoc}
                />
              </Col>
            </Row>

            <div className="table-layout">
              <TableCustom columns={columns} scroll={{ x: "max-content" }} data={items} rowKey="key" small />
            </div>
          </div>

          <div className="bottom-content">
            <div className="left-zone">
              <Link to={ROUTH_PATH.HOME} className="button">
                <ButtonCustom text='ยกเลิก' icon='close' type='danger' />
              </Link>
              <div className="button">
                <ButtonCustom text='ล้าง' icon='redo' type='danger' onClick={this.fetchDataDefault} />
              </div>
            </div>
            <div className="right-zone">
              <div className="button">
                <ButtonCustom
                  text='บันทึก'
                  icon='save'
                  type="primary"
                  onClick={this.handleSubmitGoodsReceive}
                // disabled={!foundPODoc || !permissionPage.authCreate}
                />
              </div>
            </div>
          </div>
        </Spin>

        <PurchaseOrderModal
          isShowModal={modalType === 'purchaseOrder' && isShowModal}
          handleCloseModal={this.handleCloseModal}
          onSelected={this.onSelectedPO}
          outletId={header.outletId}
          docType={'03TO'}
          docTypeSing={'E'}
        />

        <StoreLocationModal
          isShowModal={modalType === 'storageLocation' && isShowModal}
          handleCloseModal={this.handleCloseModal}
          dataStorages={dataStorages}
          onSelected={this.onSelectedStoreLocation}
          rowSelected={selectedItemRow}
          loading={loading}
        />
      </div>
    );
  }
}


const mapStateToProps = state => {
  // console.log("state===>", state);
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    authData
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodsReceiveContainer);