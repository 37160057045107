import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { map } from 'lodash'
import { authData } from 'actions/actionsCreator'
import { TitleHead, ButtonCustom, TableCustom, SelectHero, InputHero, DatePickerCustom } from 'components'
import { Row, Col, Spin } from 'antd'
import './style.css'
import { apiServices, API_PATH } from "apiServices"
import swal from '@sweetalert/with-react'
import { get, find } from 'lodash'
import { Link } from 'react-router-dom'
import { ROUTH_PATH, functionAlias } from 'routes'
import { getPermissionPage } from 'helpers'

class InventoryCountMaintainContainer extends Component {
    state = {
        columns: [
            {
                title: 'เลขที่ใบตรวจนับ',
                dataIndex: 'inventoryCountDocNo',
                key: 'inventoryCountDocNo',
                width: 150,
                align: 'center',
                fixed: 'left',
                render: (value) => (
                    <Link to={`${ROUTH_PATH.INVENTORY_COUNT_EDIT_LINK}/${value}`}>{value}</Link>
                ),
            },
            {
                title: 'docList',
                dataIndex: 'docList',
                key: 'docList',
                width: 120,
                align: 'center',
            },
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                render: (value, row, index) => (
                    <div>
                        {index + 1}
                    </div>
                ),
                width: 80,
                align: 'center',
            },
            {
                title: 'ร้าน',
                dataIndex: 'outletId',
                key: 'outletId',
                width: 100,
                align: 'center',
            },
            {
                title: 'รหัสสินค้า Article',
                dataIndex: 'productCode',
                key: 'productCode',
                width: 150,
                align: 'center',
            },
            {
                title: 'Barcode',
                dataIndex: 'barcode',
                key: 'barcode',
                width: 100,
                align: 'center',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                width: 100,
                align: 'center',
            },
            {
                title: 'ประเภทสต็อค',
                dataIndex: 'stockType',
                key: 'stockType',
                width: 100,
                align: 'center',
            },
            {
                title: 'คลัง',
                dataIndex: 'sloc',
                key: 'sloc',
                width: 100,
                align: 'center',
            },
            {
                title: 'หน่วย Base',
                dataIndex: 'baseUnit',
                key: 'baseUnit',
                width: 100,
                align: 'center',
            },
            {
                title: 'จำนวนในระบบ',
                dataIndex: 'qtySystemStock',
                key: 'qtySystemStock',
                width: 150,
                align: 'center',
            },
            {
                title: 'จำนวนนับจริง',
                dataIndex: 'qtyCounted',
                key: 'qtyCounted',
                width: 100,
                align: 'center',
            },
            {
                title: 'จำนวนผลต่าง',
                dataIndex: 'qtyDiff',
                key: 'qtyDiff',
                width: 100,
                align: 'center',
            },
            {
                title: 'ต้นทุน/หน่วย',
                dataIndex: 'cost',
                key: 'cost',
                width: 100,
                align: 'center',
            },
            {
                title: 'มูลค่ารวมผลต่าง',
                dataIndex: 'amountDiff',
                key: 'amountDiff',
                width: 150,
                align: 'center',
            },
            {
                title: 'Status',
                dataIndex: 'itemStatus',
                key: 'itemStatus',
                width: 100,
                align: 'center',
            },
            {
                title: 'Type',
                dataIndex: 'diffType',
                key: 'diffType',
                width: 100,
                align: 'center',
            },
            {
                title: 'วันที่พิมพ์',
                dataIndex: 'printDate',
                key: 'printDate',
                width: 150,
                align: 'center',
                render: (value, row, index) => {
                    if (value) {
                        var d = new Date(value),
                            month = '' + (d.getMonth() + 1),
                            day = '' + d.getDate(),
                            year = d.getFullYear();

                        if (month.length < 2) month = '0' + month;
                        if (day.length < 2) day = '0' + day;

                        return [day, month, year].join('/');
                    }

                }
            },
            {
                title: 'วันที่ตรวจนับ',
                dataIndex: 'countedDatetime',
                key: 'countedDatetime',
                width: 150,
                align: 'center',
                render: (value, row, index) => {
                    if (value) {
                        var d = new Date(value),
                            month = '' + (d.getMonth() + 1),
                            day = '' + d.getDate(),
                            year = d.getFullYear();

                        if (month.length < 2) month = '0' + month;
                        if (day.length < 2) day = '0' + day;

                        return [day, month, year].join('/');
                    }

                }
            },
            {
                title: 'วันที่ยืนยัน',
                dataIndex: 'confirmDate',
                key: 'confirmDate',
                width: 150,
                align: 'center',
                render: (value, row, index) => {
                    if (value) {
                        var d = new Date(value),
                            month = '' + (d.getMonth() + 1),
                            day = '' + d.getDate(),
                            year = d.getFullYear();

                        if (month.length < 2) month = '0' + month;
                        if (day.length < 2) day = '0' + day;

                        return [day, month, year].join('/');
                    }

                }
            },
            {
                title: 'วันที่โอน Block',
                dataIndex: 'blockDate',
                key: 'blockDate',
                width: 150,
                align: 'center',
                render: (value, row, index) => {
                    if (value) {
                        var d = new Date(value),
                            month = '' + (d.getMonth() + 1),
                            day = '' + d.getDate(),
                            year = d.getFullYear();

                        if (month.length < 2) month = '0' + month;
                        if (day.length < 2) day = '0' + day;

                        return [day, month, year].join('/');
                    }

                }
            },
            {
                title: 'วันที่ PostDiff',
                dataIndex: 'postDiffDate',
                key: 'postDiffDate',
                width: 150,
                align: 'center',
                render: (value, row, index) => {
                    if (value) {
                        var d = new Date(value),
                            month = '' + (d.getMonth() + 1),
                            day = '' + d.getDate(),
                            year = d.getFullYear();

                        if (month.length < 2) month = '0' + month;
                        if (day.length < 2) day = '0' + day;

                        return [day, month, year].join('/');
                    }

                }
            },
            {
                title: 'Class',
                dataIndex: 'classCode',
                key: 'classCode',
                width: 100,
                align: 'center',
            },
        ],
        loading: false,
        outlets: [],
        outlet: '',
        statusList: [],
        typeList: [],
        statusListSelected: [],
        typeListSelected: [],
        inventoryCountDocNo: '',
        docList: '',
        createStartDate: '',
        createEndDate: '',
        countedStartDate: '',
        countedEndDate: '',
        confirmStartDate: '',
        confirmEndDate: '',
        postDiffStartDate: '',
        postDiffEndDate: '',
        widthLabel: '120px',
        dateFormat: 'DD/MM/YYYY',
        selectedRowKeys: [],
        dataList: [],
        selectedRowData: [],
        permissionPage: getPermissionPage(functionAlias.inventoryCountAdmin),
    }

    componentDidMount() {
        this.fatchData();
    }

    fatchData = async () => {
        const { auth } = this.props
        this.setState({
            loading: true
        });

        let params1 = {
            outletIds: auth.arrayOutlet,
        }

        let params2 = {
            languageCode: 'TH',
        }

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, params1).then(res => {
            if (res.status === 200) {
                let outlets = res.data.results;
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }))

                this.setState({
                    outlets,
                    outlet: outlets[0].value,
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('post', API_PATH.GET_MASTER_INVENTORY_COUNT_STATUS, params2).then(res => {
            if (res.status === 200) {
                let statusList = res.data.results;
                statusList = statusList.map(data => ({
                    ...data,
                    value: data.invcntStatusCode,
                    label: `${data.invcntStatusCode} - ${data.invcntStatusDesc}`,
                }))

                this.setState({
                    statusList
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('get', API_PATH.GET_MASTER_INVENTORY_DIFF_TYPE, '').then(res => {
            if (res.status === 200) {
                let typeList = res.data.results;
                typeList = typeList.map(data => ({
                    ...data,
                    value: data.diffTypeCode,
                    label: `${data.diffTypeCode} - ${data.diffTypeDesc}`,
                }))

                this.setState({
                    typeList
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.setState({
            loading: false
        });
    }

    //#region handle

    handleClickSearch = async (e) => {
        const { inventoryCountDocNo, docList, outlet, createStartDate, createEndDate, countedStartDate, countedEndDate, confirmStartDate, confirmEndDate, postDiffStartDate, postDiffEndDate, statusListSelected, typeListSelected } = this.state
        this.setState({
            loading: true
        });

        let params = {
            inventoryCountDocNo: inventoryCountDocNo,
            docList: docList,
            outletId: outlet,
            createdDateFrom: createStartDate,
            createdDateTo: createEndDate,
            countedDatetimeFrom: countedStartDate,
            countedDatetimeTo: countedEndDate,
            confirmDateFrom: confirmStartDate,
            confirmDateTo: confirmEndDate,
            postDiffDateFrom: postDiffStartDate,
            postDiffDateTo: postDiffEndDate,
            itemStatuses: statusListSelected,
            diffTypes: typeListSelected
        }

        await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_ADMIN_SEARCH, params).then(res => {
            let dataList = res.data.results.map((data, i) => (
                {
                    ...data,
                    key: i
                }
            ));

            this.setState({
                dataList: dataList,
                loading: false
            });

            console.log(dataList);
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            this.setState({
                loading: false
            });
        });
    }

    handleChangeStatus = (val) => {
        const { statusList } = this.state
        if (val.includes('all')) {
            const all = map(statusList, 'value')
            this.setState({ statusListSelected: all })
        } else {
            this.setState({ statusListSelected: val })
        }
    }

    handleChangeType = (val) => {
        const { typeList } = this.state
        if (val.includes('all')) {
            const all = map(typeList, 'value')
            this.setState({ typeListSelected: all })
        } else {
            this.setState({ typeListSelected: val })
        }
    }

    handleChangeOutlet = (outlet) => {
        this.setState({ outlet })
    }

    handleChangeCreateStartDate = (val) => {
        this.setState({ createStartDate: val })
    }

    handleChangeCreateEndDate = (val) => {
        this.setState({ createEndDate: val })
    }

    handleChangeCountedStartDate = (val) => {
        this.setState({ countedStartDate: val })
    }

    handleChangeCountedEndDate = (val) => {
        this.setState({ countedEndDate: val })
    }

    handleChangeConfirmStartDate = (val) => {
        this.setState({ confirmStartDate: val })
    }

    handleChangeConfirmEndDate = (val) => {
        this.setState({ confirmEndDate: val })
    }

    handleChangePostDiffStartDate = (val) => {
        this.setState({ postDiffStartDate: val })
    }

    handleChangePostDiffEndDate = (val) => {
        this.setState({ postDiffEndDate: val })
    }

    handleChangeInventoryCountNo = (e) => {
        this.setState({ inventoryCountDocNo: e.target.value })
    }

    handleChangeDocList = (e) => {
        this.setState({ docList: e.target.value })
    }

    handleClickExportExcel = async (e) => {
        const { selectedRowData } = this.state
        this.setState({
            loading: true
        });

        if (selectedRowData.length === 0) {
            this.setState({
                loading: false
            });
            swal('Error', "กรุณาเลือกรายการสินค้าที่ต้องการ Export", 'error')
            return;
        }

        await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_EXPORT_ADMIN_EXCEL, selectedRowData).then(res => {
            if (res.status === 200) {
                window.location.href = API_PATH.GET_INVENTORY_COUNT_DOWNLOAD_EXPORT_ADMIN_EXCEL;
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.setState({
            loading: false
        });
    }

    handleClickReCount = async (e) => {
        const { selectedRowData } = this.state

        this.setState({
            loading: true
        });

        if (selectedRowData.length === 0) {
            swal('Error', "กรุณาเลือกสินค้าที่ต้องการนับใหม่", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        if (find(selectedRowData, (data) => data.itemStatus !== "COUNT")) {
            swal('Error', "กรุณาเลือกเฉพาะรายการสถานะ COUNT", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_RE_COUNT, selectedRowData).then(res => {
            if (res.status === 200) {
                swal('Success', res.data.message, 'success');
                this.handleClickSearch();
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.setState({
            loading: false,
            selectedRowKeys: [],
            selectedRowData: []
        });
    }

    handleClickConfirmPositiveStock = async (e) => {
        const { selectedRowData } = this.state

        this.setState({
            loading: true
        });

        if (selectedRowData.length === 0) {
            swal('Error', "กรุณาเลือกรายการที่ต้องการปรับ Adjusted", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        if (find(selectedRowData, (data) => data.itemStatus !== "COUNT" || data.diffType !== "POSITIVESTOCK")) {
            swal('Error', "กรุณาเลือกเฉพาะรายการสถานะ COUNT และ Type = Positive Stock", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_CONFIRM_POSITIVE_STOCK, selectedRowData).then(res => {
            if (res.status === 200) {
                swal('Success', res.data.message, 'success');
                this.handleClickSearch();
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.setState({
            loading: false,
            selectedRowKeys: [],
            selectedRowData: []
        });
    }

    handleClickConfirmNegativeStock = async (e) => {
        const { selectedRowData } = this.state

        this.setState({
            loading: true
        });

        if (selectedRowData.length === 0) {
            swal('Error', "กรุณาเลือกรายการที่ต้องการปรับ Adjusted", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        if (find(selectedRowData, (data) => data.itemStatus !== "COUNT" || data.diffType !== "NEGATIVESTOCK")) {
            swal('Error', "กรุณาเลือกเฉพาะรายการสถานะ COUNT และ Type = Negative Stock", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_CONFIRM_NEGATIVE_STOCK, selectedRowData).then(res => {
            if (res.status === 200) {
                swal('Success', res.data.message, 'success');
                this.handleClickSearch();
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.setState({
            loading: false,
            selectedRowKeys: [],
            selectedRowData: []
        });
    }

    handleClickAdjusted = async (e) => {
        const { selectedRowData } = this.state

        this.setState({
            loading: true
        });

        if (selectedRowData.length === 0) {
            swal('Error', "กรุณาเลือกรายการที่ต้องการปรับ Adjusted", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        if (find(selectedRowData, (data) => data.itemStatus !== "CONFIRMTOADJUST" && data.itemStatus !== "BLOCKTOADJUST")) {
            swal('Error', "กรุณาเลือกเฉพาะรายการสถานะ Confirm to Adjust/ Block to Adjust", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        let groupStatus = this.groupBy(selectedRowData);
        if (groupStatus.length !== 1) {
            swal('Error', "กรุณาเลือกเฉพาะรายการสถานะ Confirm to Adjust หรือ Block to Adjust เท่านั้น", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_ADJUSTED, selectedRowData).then(res => {
            if (res.status === 200) {
                swal('Success', res.data.message, 'success');
                this.handleClickSearch();
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.setState({
            loading: false,
            selectedRowKeys: [],
            selectedRowData: []
        });
    }

    handleClickCancelConfirm = async (e) => {
        const { selectedRowData } = this.state

        this.setState({
            loading: true
        });

        if (selectedRowData.length === 0) {
            swal('Error', "กรุณาเลือกรายการที่ต้องการยกเลิกการยืนยันใบตรวจนับ", 'error');
            this.setState({ loading: false });
            return;
        }

        if (find(selectedRowData, (data) => data.itemStatus !== "CONFIRMTOADJUST" && data.itemStatus !== "BLOCKTOADJUST")) {
            swal('Error', "กรุณาเลือกเฉพาะรายการสถานะ Confirm to Adjust หรือ Block to Adjust เท่านั้น", 'error');
            this.setState({ loading: false });
            return;
        }

        let groupStatus = this.groupBy(selectedRowData);
        if (groupStatus.length !== 1) {
            swal('Error', "กรุณาเลือกเฉพาะรายการสถานะ Confirm to Adjust หรือ Block to Adjust เท่านั้น", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_CANCEL_CONFIRM, selectedRowData).then(res => {
            if (res.status === 200) {
                swal('Success', res.data.message, 'success');
                this.handleClickSearch();
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.setState({
            loading: false,
            selectedRowKeys: [],
            selectedRowData: []
        });
    }

    //#endregion

    groupBy = (data) => {
        var groupStatus = {};
        for (var i = 0; i < data.length; i++) {
            let status = data[i].itemStatus;
            if (!groupStatus[status]) {
                groupStatus[status] = [];
            }
        }

        data = [];
        for (var statusName in groupStatus) {
            data.push({ itemStatus: statusName });
        }
        return data;
    }

    onSelectChange = (selectedRowKeys, selectedRowData) => {
        this.setState({ selectedRowKeys, selectedRowData })
    }
    handleClickExportPDF = async (e) => {
        console.log(e.target.id)
        const report = e.target.id
        this.setState({
            loading: true
        });
        const { selectedRowData, outlet } = this.state
        let inventoryCountItemIds = selectedRowData.map(item => item.inventoryCountItemId)
        const requestBody = {
            inventoryCountItemIds,
            outletId: outlet
        }
        let result = null
        try {
            if (report === 'INVENTORY_ADJUST') {
                result = await apiServices.callApi('post', API_PATH.POST_INVENTORY_ADJUST_EXPORT_PRINT, requestBody, { responseType: 'blob' })
            } else if (report === 'INVENTORY_COUNT') {
                result = await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_EXPORT_PRINT, requestBody, { responseType: 'blob' })
            }
            const urlOpen = window.URL.createObjectURL(result.data)
            window.open(urlOpen)
        } catch (error) {
            console.log(error)
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }

        this.setState({
            loading: false
        });


    }

    render() {
        const {
            outlets,
            outlet,
            statusList,
            statusListSelected,
            typeList,
            typeListSelected,
            inventoryCountDocNo,
            docList,
            createStartDate,
            createEndDate,
            countedStartDate,
            countedEndDate,
            confirmStartDate,
            confirmEndDate,
            postDiffStartDate,
            postDiffEndDate,
            loading,
            columns,
            dateFormat,
            widthLabel,
            selectedRowKeys,
            dataList,
            permissionPage,
        } = this.state

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        }

        return (
            <div className="inventory-count-maintain-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="จัดการใบตรวจนับ"
                        icon="file-done"
                    />
                    <div className="top-layout">
                        <Row gutter={16}>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="createDateFrom"
                                    label="วันที่สร้างใบตรวจนับ"
                                    value={createStartDate}
                                    format={dateFormat}
                                    small
                                    onChange={this.handleChangeCreateStartDate}
                                />
                            </Col>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="createDateTo"
                                    label="ถึง"
                                    value={createEndDate}
                                    format={dateFormat}
                                    small
                                    onChange={this.handleChangeCreateEndDate}
                                />
                            </Col>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    name="outlet"
                                    label="ร้านค้า"
                                    options={outlets}
                                    value={outlet}
                                    small
                                    onChange={this.handleChangeOutlet}
                                />
                            </Col>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    name="statusList"
                                    label="Status"
                                    options={statusList}
                                    value={statusListSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                    small
                                    onChange={this.handleChangeStatus}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="countDateFrom"
                                    label="วันที่ตรวจนับ"
                                    format={dateFormat}
                                    value={countedStartDate}
                                    small
                                    onChange={this.handleChangeCountedStartDate}
                                />
                            </Col>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="countDateTo"
                                    label="ถึง"
                                    format={dateFormat}
                                    value={countedEndDate}
                                    small
                                    onChange={this.handleChangeCountedEndDate}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputHero
                                    widthDefault={widthLabel}
                                    name="inventoryCountNo"
                                    label="เลขที่ใบตรวจนับ"
                                    value={inventoryCountDocNo}
                                    small
                                    onChange={this.handleChangeInventoryCountNo}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputHero
                                    widthDefault={widthLabel}
                                    name="docList"
                                    label="docList"
                                    value={docList}
                                    small
                                    onChange={this.handleChangeDocList}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="confirmDateFrom"
                                    label="วันที่ยืนยัน"
                                    format={dateFormat}
                                    value={confirmStartDate}
                                    small
                                    onChange={this.handleChangeConfirmStartDate}
                                />
                            </Col>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="confirmDateTo"
                                    label="ถึง"
                                    format={dateFormat}
                                    value={confirmEndDate}
                                    small
                                    onChange={this.handleChangeConfirmEndDate}
                                />
                            </Col>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    name="typeList"
                                    label="Type"
                                    options={typeList}
                                    value={typeListSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                    small
                                    onChange={this.handleChangeType}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="postDiffDateFrom"
                                    label="วันที่ PostDiff"
                                    format={dateFormat}
                                    value={postDiffStartDate}
                                    small
                                    onChange={this.handleChangePostDiffStartDate}
                                />
                            </Col>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="postDiffDateTo"
                                    label="ถึง"
                                    format={dateFormat}
                                    value={postDiffEndDate}
                                    small
                                    onChange={this.handleChangePostDiffEndDate}
                                />
                            </Col>
                            <Col sm={12} className="search-box">
                                <ButtonCustom icon="search" blue text="ค้นหา" onClick={this.handleClickSearch} />
                            </Col>
                        </Row>
                        {permissionPage.authApprove && <>
                            <Row gutter={20}>
                                <Col sm={24}>
                                    <div className="action-box">
                                        <div className="button">
                                            <ButtonCustom onClick={this.handleClickReCount} small icon="redo" type="danger" text="นับใหม่" />
                                        </div>
                                        <div className="button">
                                            <ButtonCustom onClick={this.handleClickExportExcel} small icon="file-excel" green text="Export Excel" />
                                        </div>
                                        <div className="button">
                                            <ButtonCustom small icon="printer" green text="พิมพ์ใบตรวจนับ" onClick={this.handleClickExportPDF} id="INVENTORY_COUNT" />
                                        </div>
                                        <div className="button">
                                            <ButtonCustom small icon="printer" green text="พิมพ์ใบปรับปรุงสต็อค" onClick={this.handleClickExportPDF} id="INVENTORY_ADJUST" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col sm={24}>
                                    <div className="action-box">
                                        <div className="button">
                                            <ButtonCustom onClick={this.handleClickConfirmPositiveStock} small icon="check-circle" type="primary" text="ยืนยันใบตรวจนับ Positive Stock" />
                                        </div>
                                        <div className="button">
                                            <ButtonCustom onClick={this.handleClickConfirmNegativeStock} small icon="check-circle" type="primary" text="ยืนยันใบตรวจนับ Negative Stock" />
                                        </div>
                                        <div className="button">
                                            <ButtonCustom onClick={this.handleClickAdjusted} small icon="check-circle" type="primary" text="ปรับ Adjusted" />
                                        </div>
                                        <div className="button">
                                            <ButtonCustom onClick={this.handleClickCancelConfirm} small icon="close-circle" className="cancel-button" type="danger" text="ยกเลิกการยืนยันใบตรวจนับ" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>}
                    </div>
                    <div className="content">
                        <div className="table-layout">
                            <TableCustom rowSelection={rowSelection} scroll={{ x: "max-content", y: "calc(100vh - 200px)" }} columns={columns} data={dataList} rowKey={(row, index) => index} pagination={false} />
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCountMaintainContainer)