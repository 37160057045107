import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Redirect } from 'react-router-dom';
import { authData } from 'actions/actionsCreator';



class PrivateRoute extends Component {

    constructor(props) {
        super(props);
        const isRefresh = sessionStorage.getItem("refresh") || props.path === '/franchise'
        this.state = {
            isRefresh : isRefresh
        }
        if (!isRefresh) {
            localStorage.clear()
            this.props.authData(null)
        }
        // alway seting session every calling page.
        sessionStorage.setItem("refresh", true);
    }

    render() {
        const { isAliveTokenRedux, auth, component: Component, appContext, ...rest } = this.props
        const { isRefresh } = this.state
        return (
            <>
                <Route {...rest} render={props => (

                    (auth && auth.userId && isRefresh)
                        ?
                        <Component {...props} appContext={appContext} />
                        :
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )}
                />
            </>
        )
    }
}


const mapStateToProps = state => {
    // console.log("stateredux==>", state)
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)