import React, { Component } from 'react'
import { connect } from 'react-redux'
import { API_PATH } from 'apiServices'
import { Row, Col } from 'antd'
import { TableCustom, ButtonCustom, ModalCustom } from 'components/common'

class CnResultSaveModal extends Component {
    state = {
        columns: [
            {
                title: '',
                align: 'center',
                dataIndex: 'url',
                key: 'url',
                width: 60,
                render: (props) => (
                    <ButtonCustom
                        text="พิมพ์"
                        icon="printer"
                        small
                        green
                        onClick={() => this.openForm(props)}
                        disabled={!this.props.permissionPage.authPrint}
                    />
                )
            },
            {
                title: 'เอกสาร',
                align: 'center',
                dataIndex: 'documentTypeDesc',
                key: 'documentTypeDesc',
                render: (props, row) => (
                    <div>{props} {row.title}</div>
                ),
            },
            {
                title: 'เลขที่เอกสาร',
                align: 'center',
                dataIndex: 'documentNo',
                key: 'documentNo',
            },
        ],
        data: [],
    }

    componentDidMount() {
        this.mappingData()
    }

    mappingData = () => {
        const { result: { returnTypeId, billingNo, isApprove, soNumber, documentTypeDescription, returnType } } = this.props
        let data = []
        if (isApprove) {
            data = [
                {
                    documentTypeDesc: `${documentTypeDescription}(ใหญ่)`,
                    url: API_PATH.GET_REPORT_REDUCE_DEBT,
                    documentNo: billingNo,
                },
                {
                    documentTypeDesc: `${documentTypeDescription}(เล็ก)`,
                    url: API_PATH.GET_REPORT_REDUCE_DEBT_A5,
                    documentNo: billingNo,
                }
            ]
            if (returnType && returnType.returnTypeCode === 'voucher') {
                data.push({
                    documentTypeDesc: 'คูปองลดหนี้',
                    url: API_PATH.POST_GET_REPORT_CN_VOUCHER,
                    documentNo: billingNo,
                })
            }

        } else {
            data = [
                {
                    documentTypeDesc: documentTypeDescription,
                    url: API_PATH.GET_REPORT_REDUCE_DEBT_REQUEST,
                    documentNo: soNumber,
                },
            ]
        }
        this.setState({ data })
    }

    openForm = (path) => {
        const { result: { outletId, billingNo, isApprove, soNumber, documentTypeCode } } = this.props
        // const url = `${path}?OutletId=${outletId}&DocumentNo=${soNumber}&BillingNo=${documentNo}&DocumentTypeCode=${documentTypeCode}`
        const url = isApprove ? `${path}?OutletId=${outletId}&BillingNo=${billingNo}&userid=${this.props.auth.userId}` : `${path}?OutletId=${outletId}&DocumentNo=${soNumber}&DocumentTypeCode=${documentTypeCode}&userid=${this.props.auth.userId}`

        window.open(url, '_blank')
    }

    render() {
        const {
            isShowModal,
            closeModal,
            result: { type, message, onClose },
        } = this.props
        const {
            columns,
            data,
        } = this.state
        console.log(this.props)
        const isSuccess = type === 'Success'
        const onCloseModal = isSuccess ? onClose : closeModal
        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                width="600px"
                title={type}
            >
                <div>{isSuccess ? 'เอกสารได้รับการบันทึกเรียบร้อย' : 'ไม่สามารถบันทึกเอกสารได้ กรุณาตรวจสอบข้อมูลเพิ่มเติม'}</div>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        {isSuccess ? <TableCustom
                            columns={columns}
                            data={data}
                            small
                            rowKey={(row, index) => index}
                            pagination={false}
                        /> : message}
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24} className="text-right">
                        <ButtonCustom
                            text="ปิด"
                            onClick={onCloseModal}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
}

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CnResultSaveModal)
