/*  // default data - pattern
    header: {
        documentDate: {},
        orderDate: {},
        documentNo: {},
        documentHeader: {},

        productCodeFrom: {},
        productCodeTo: {},
        outletFrom: {},
        outletTo: {},
        storageLocationFrom: {},
        storageLocationTo: {},
        amount: {},
        unit: {},
        stockSpecial: {},
        costTotal: {},
        reason: {},
        documentFrom: {},
        index1: {},
        documentTo: {},
        index2: {},
        document: {},
        year: {},
    },
    tableColumn: {
        delete: {},
        productCodeFrom: {},
        productName: {},
        amount: {},
        unit: {},
        outletFrom: {},
        storageLocationFrom: {},
        productCodeTo: {},
        outletTo: {},
        storageLocationTo: {},
        reason: {},
        documentFrom: {},
        index1: {},
        documentTo: {},
        index2: {},
    },
*/

import { API_PATH } from 'apiServices'
export const transactionTypeSpec = {
    '311': {
        name: '311',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {},
            productCodeTo: {
                disabled: true,
            },
            outletFrom: {},
            outletTo: {
                disabled: true,
            },
            storageLocationFrom: {},
            storageLocationTo: {},
            amount: {},
            unit: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
        },
        form: {
            url: API_PATH.POST_GET_REPORT_FORM_STOCK_TRANSFER_A5,
            fileName: 'ReportStockTransferA5',
        },
    },
    '312': {
        name: '312',
        mode: 'D',
        action: {
            search: {},
        },
        header: {
            document: {},
            year: {},
        },
        tableColumn: {
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            storageLocationFrom: {},
            storageLocationTo: {},
        },
        form: {
            url: API_PATH.POST_GET_REPORT_FORM_CANCEL_STOCK_TRANSFER_A5,
            fileName: 'ReportCancelStockTransferA5',
        },
    },
    '411': {
        name: '411',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {},
            productCodeTo: {},
            outletFrom: {},
            storageLocationFrom: {},
            storageLocationTo: {
                disabled: true,
            },
            amount: {},
            unit: {},
            stockSpecial: {},
            documentFrom: {},
            index1: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
            documentFrom: {},
            index1: {},
        },
    },
    '412': {
        name: '412',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {},
            productCodeTo: {},
            outletFrom: {},
            storageLocationFrom: {},
            storageLocationTo: {
                disabled: true,
            },
            amount: {},
            unit: {},
            stockSpecial: {},
            documentTo: {},
            index2: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
            documentTo: {},
            index2: {},
        },
    },
    '309': {
        name: '309',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {},
            productCodeTo: {},
            outletFrom: {},
            outletTo: {},
            storageLocationFrom: {},
            storageLocationTo: {
                disabled: true,
            },
            amount: {},
            unit: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
        },
    },
    '413': {
        name: '413',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {},
            productCodeTo: {},
            outletFrom: {},
            outletTo: {
                disabled: true,
            },
            storageLocationFrom: {},
            storageLocationTo: {},
            amount: {},
            unit: {},
            stockSpecial: {},
            documentFrom: {},
            index1: {},
            documentTo: {},
            index2: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
            documentFrom: {},
            index1: {},
            documentTo: {},
            index2: {},
        },
    },
    '321': {
        name: '321',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {},
            productCodeTo: {
                disabled: true,
            },
            outletFrom: {},
            outletTo: {
                disabled: true,
            },
            storageLocationFrom: {},
            storageLocationTo: {
                disabled: true,
            },
            amount: {},
            unit: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
        },
    },
    '322': {
        name: '322',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {
                disabled: true,
            },
            productCodeTo: {},
            outletFrom: {
                disabled: true,
            },
            outletTo: {},
            storageLocationFrom: {},
            storageLocationTo: {
                disabled: true,
            },
            amount: {},
            unit: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
        },
    },
    '323': {
        name: '323',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {},
            productCodeTo: {
                disabled: true,
            },
            outletFrom: {},
            outletTo: {
                disabled: true,
            },
            storageLocationFrom: {},
            storageLocationTo: {},
            amount: {},
            unit: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
        },
    },
    '325': {
        name: '325',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {},
            productCodeTo: {
                disabled: true,
            },
            outletFrom: {},
            outletTo: {
                disabled: true,
            },
            storageLocationFrom: {},
            storageLocationTo: {},
            amount: {},
            unit: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
        },
    },
    '343': {
        name: '343',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {},
            productCodeTo: {
                disabled: true,
            },
            outletFrom: {},
            outletTo: {
                disabled: true,
            },
            storageLocationFrom: {},
            storageLocationTo: {
                disabled: true,
            },
            amount: {},
            unit: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
        },
    },
    '344': {
        name: '344',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {
                disabled: true,
            },
            productCodeTo: {},
            outletFrom: {
                disabled: true,
            },
            outletTo: {},
            storageLocationFrom: {},
            storageLocationTo: {
                disabled: true,
            },
            amount: {},
            unit: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
        },
    },
    '349': {
        name: '349',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {},
            productCodeTo: {
                disabled: true,
            },
            outletFrom: {},
            outletTo: {
                disabled: true,
            },
            storageLocationFrom: {},
            storageLocationTo: {
                disabled: true,
            },
            amount: {},
            unit: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
        },
    },
    '350': {
        name: '350',
        mode: 'C',
        action: {
            add: {},
        },
        header: {
            documentDate: {},
            orderDate: {
                disabled: true,
            },
            documentNo: {},
            documentHeader: {},
            productCodeFrom: {
                disabled: true,
            },
            productCodeTo: {},
            outletFrom: {
                disabled: true,
            },
            outletTo: {},
            storageLocationFrom: {},
            storageLocationTo: {
                disabled: true,
            },
            amount: {},
            unit: {},
        },
        tableColumn: {
            delete: {},
            productCodeFrom: {},
            productName: {},
            amount: {},
            unit: {},
            outletFrom: {},
            storageLocationFrom: {},
            productCodeTo: {},
            outletTo: {},
            storageLocationTo: {},
        },
    },

}