import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, get, find } from 'lodash';
import './style.css'
import { authData } from 'actions/actionsCreator';
import { Row, Col } from "antd"
import { Link } from "react-router-dom"
import { apiServices, API_PATH } from "apiServices"
import { ROUTH_PATH, functionAlias } from 'routes'
import moment from 'moment';

import {
    CheckBoxCustom,
    TitleHead,
    InputHero,
    ButtonCustom,
    TableCustom,
    MultiSelectedCustom,
    SelectHero,
} from "components"
import { QualifierTypes, QualifierStatus, getPermissionPage, sortDataColumn } from 'helpers'
import swal from '@sweetalert/with-react'

class QualifierContainer extends Component {
    state = {
        permissionPage: getPermissionPage(functionAlias.promotionQualifierMaintain),
        dataTableQualifier: [],
        filterQualifierName: '',
        filterProductCode: '',
        filterProductName: '',
        filterQualifierStatus: [0, 1],
        filterQualifierType: ['product', 'category'],
        //  columns Table set here
        columns: [
            {
                title: "เปิดใช้งาน",
                align: 'center',
                dataIndex: 'active',
                key: 'active',
                width: 80,
                render: (value, full, index) => <div style={{ textAlign: 'center' }}>
                    {full.blocked === 0 &&

                        <CheckBoxCustom
                            name="active"
                            onChange={(e) => this.handleChangeCheckApprove(e, index, full)}
                            checked={value}
                            disabled={!this.state.permissionPage.authApprove}
                        />
                    }
                </div>
            },
            {
                title: "ปิดใช้งาน",
                align: 'center',
                dataIndex: 'block',
                key: 'block',
                width: 80,
                render: (value, full, index) => <div style={{ textAlign: 'center' }}>
                    {full.blocked > 0 &&
                        <CheckBoxCustom
                            name="block"
                            onChange={(e) => this.handleChangeCheckUnApprove(e, index, full)}
                            checked={value}
                            disabled={!this.state.permissionPage.authDelete}
                        />
                    }
                </div>
            },
            {
                title: "สถานะ",
                align: 'center',
                dataIndex: 'blocked',
                key: 'blocked',
                width: 80,
                render: (value, full, index) => <div style={{ textAlign: 'center' }}>
                    {full.blocked === 0
                        ?
                        <label style={{ color: '#ff7875' }}>ปิดใช้งาน</label>
                        :
                        <label style={{ color: '#008000b8' }}>เปิดใช้งาน</label>}
                </div>
            },
            {
                title: 'Qualifier Name',
                align: 'left',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => sortDataColumn(a, b, 'name'),
                ellipsis: true,
                render: (value, full, index) =>
                    <Link to={ROUTH_PATH.PROMOTION_QUALIFIER_EDIT.replace(":id", `${full.itemQualifierId}`)}>
                        {value}
                    </Link>
            },
            {
                title: 'ประเภท',
                align: 'center',
                dataIndex: 'qualifierTypeCode',
                key: 'qualifierTypeCode',
                sorter: (a, b) => sortDataColumn(a, b, 'qualifierTypeCode'),
                ellipsis: true,
                render: (value) =>
                    <span>
                        {this.filterQualifierType(value)}
                    </span>


            },
            {
                title: 'Qualifier Description',
                align: 'left',
                dataIndex: 'description',
                key: 'description',
                sorter: (a, b) => sortDataColumn(a, b, 'description'),
                ellipsis: true,
            },
            {
                title: 'วันที่อัพเดท',
                align: 'center',
                dataIndex: 'modifyDate',
                key: 'modifyDate',
                sorter: (a, b) => sortDataColumn(a, b, 'modifyDate'),
                ellipsis: true,
                render: (value) => <span>{moment(value).format("DD/MM/YYYY")/*formatDate(new Date(props))*/}</span>
            },
        ],
    }

    componentDidMount() {
        if (!this.state.permissionPage.authDisplay) {
            swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
                this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
            })
            return
        }
    }

    filterQualifierType(value) {
        let _qualifierType = QualifierTypes.filter((ele) => ele.value === value);
        //console.log("value==>", value, "_qualifierType ===> ", _qualifierType);
        if (!_qualifierType || _qualifierType.length === 0) {
            return value;
        }
        return _qualifierType[0].text;
    }
    handleChangefilterQualifierName = (val) => {
        this.setState({ filterQualifierName: val.target.value });
    }

    handleChangefilterProductCode = (val) => {
        this.setState({ filterProductCode: val.target.value });
    }

    handleChangefilterProductName = (val) => {
        this.setState({ filterProductName: val.target.value });
    }

    handleChangeQualifierStatus = (val) => {
        //console.log("filterQualifierStatus===>", this.state.filterQualifierStatus);
        this.setState({ filterQualifierStatus: val });
    }

    handleChangeQualifierType = (val) => {
        this.setState({ filterQualifierType: val });
    }

    handleChangeCheckApprove = (e, index,full) => {
        e.preventDefault();
        console.log("e===>", e.target.checked);
        let { dataTableQualifier } = this.state;        
        // dataTableQualifier[index].active = e.target.checked;
        // dataTableQualifier[index].block = false;
        dataTableQualifier = dataTableQualifier.map(promotion => {
            if (promotion.itemQualifierId === full.itemQualifierId) {
                promotion = {
                    ...promotion,
                    active: e.target.checked,
                    block: false
                }
            }
            return promotion
        })
        this.setState({ dataTableQualifier });
    }
    handleChangeCheckUnApprove = (e, index, full) => {
        e.preventDefault();
        console.log("e===>", e.target.checked);
        let { dataTableQualifier } = this.state;
        dataTableQualifier = dataTableQualifier.map(promotion => {
            if (promotion.itemQualifierId === full.itemQualifierId) {
                promotion = {
                    ...promotion,
                    active: false,
                    block: e.target.checked
                }
            }
            return promotion
        })

        // dataTableQualifier[index].active = false;
        // dataTableQualifier[index].block = e.target.checked;
        this.setState({ dataTableQualifier });
    }

    handleClickSaveStatus = async () => {
        this.setState({ loading: true });

        try {
            let listQualifierUpdate = [];
            let {
                dataTableQualifier,
            } = this.state;
            console.log(`dataTableQualifier`, dataTableQualifier);

            dataTableQualifier.forEach(qualifier => {
                if (qualifier.active === true) {
                    qualifier.blocked = 1;
                    listQualifierUpdate.push(qualifier);
                } else if (qualifier.block === true) {
                    qualifier.blocked = 0;
                    listQualifierUpdate.push(qualifier);
                }
            });
            console.log(`listQualifierUpdate`, listQualifierUpdate);
            if (listQualifierUpdate && listQualifierUpdate.length > 0) {
                let resUpdateQualifier = await apiServices.callApi('put', API_PATH.PUT_QUALIFIER, listQualifierUpdate);
                console.log(`resUpdateQualifier`, resUpdateQualifier);
            }

        } catch (e) {
            console.log('error', e);
        }

        this.setState({ loading: false });
    }

    handleClickSearchQualifier = async () => {
        this.setState({ loading: true });
        try {
            let {
                filterQualifierName,
                filterProductCode,
                filterProductName,
                dataTableQualifier,
                filterQualifierStatus,
                filterQualifierType,
            } = this.state;
            console.log(`filterQualifierName : ${filterQualifierName}`);
            console.log(`filterProductCode : ${filterProductCode}`);
            console.log(`filterProductName : ${filterProductName}`);
            console.log(`filterQualifierStatus : ${filterQualifierStatus}`);
            console.log(`filterQualifierType : ${filterQualifierType}`);
            let blockeds = filterQualifierStatus.map((data, i) => (
                `&Blockeds=${data}`
            ));

            let qualifierTypeCodes = filterQualifierType.map((data, i) => (
                `&QualifierTypeCodes=${data}`
            ));

            let URLAndParams = `${API_PATH.GET_QUALIFIER}?Name=${filterQualifierName}&ProductCode=${filterProductCode}&ProductName=${filterProductName}${blockeds.join('')}${qualifierTypeCodes.join('')}`;
            console.log(`URL : ${URLAndParams}`);
            let response = await apiServices.callApi("get", URLAndParams, '', '')//auth.token
            console.log("res===>", response);
            let qualifiers = get(response, 'data.results');
            if (response.status === 200 && qualifiers && qualifiers.length > 0) {
                console.log("qualifiers===>", qualifiers);
                dataTableQualifier = qualifiers.map((data, i) => (
                    {
                        ...data,
                        active: false,
                        block: false,
                        key: i
                    }
                ));
                console.log("dataTableQualifier===>", dataTableQualifier);
                this.setState({ dataTableQualifier });
            }

            if (qualifiers && qualifiers.length === 0) {
                this.setState({ dataTableQualifier: [] });
            }
        } catch (e) {
            console.log('error', e);
        }
        this.setState({ loading: false });
    }
    render() {
        const {
            dataTableQualifier,
            columns,
            loading,
            permissionPage,
            filterQualifierName,
            filterProductCode,
            filterProductName,
            filterQualifierStatus,
            filterQualifierType,
        } = this.state;
        return (
            <div className="qualifier-container">
                <TitleHead text="Promotion Qualifier" icon="filter" />
                <Row>
                    <Col md={20} offset={2}>
                        <Row>
                            <Col md={12}>
                                <MultiSelectedCustom
                                    label="สถานะ Qualifier"
                                    name="qualifierStatus"
                                    options={QualifierStatus}
                                    onChange={this.handleChangeQualifierStatus}
                                    value={filterQualifierStatus}
                                />
                            </Col>
                            <Col md={12}>
                                <MultiSelectedCustom
                                    right
                                    name="qualifierType"
                                    label="Qualifier Type"
                                    options={QualifierTypes}
                                    onChange={this.handleChangeQualifierType}
                                    value={filterQualifierType}
                                />
                            </Col>
                            <Col md={24}>
                                <InputHero
                                    onChange={this.handleChangefilterQualifierName}
                                    value={filterQualifierName}
                                    label="ชื่อ qualifier"
                                />
                            </Col>
                            <Col md={8}>
                                <InputHero
                                    onChange={this.handleChangefilterProductCode}
                                    value={filterProductCode}
                                    label="รหัสสินค้า"
                                />
                            </Col>
                            <Col md={16}>
                                <InputHero
                                    right
                                    onChange={this.handleChangefilterProductName}
                                    value={filterProductName}
                                    label="ชื่อสินค้า"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="bottom-content">
                    <div className="left-zone">
                        <div className="button">
                            <ButtonCustom
                                icon="save"
                                green
                                text="บันทึก"
                                onClick={this.handleClickSaveStatus}
                                disabled={!permissionPage.authUpdate}
                            />
                        </div>
                        <div className="button">
                            <Link to={ROUTH_PATH.PROMOTION_QUALIFIER_CREATE}>
                                <ButtonCustom
                                    icon="plus-circle"
                                    blue
                                    text="Create Qualifier"
                                    disabled={!permissionPage.authCreate}
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="right-zone">
                        <div className="button">
                            <ButtonCustom
                                icon="search"
                                type="danger"
                                text="ค้นหา"
                                onClick={this.handleClickSearchQualifier}
                            />
                        </div>
                    </div>
                </div>
                <div className="table-layout">
                    <TableCustom
                        scroll={{ x: "max-content" }}
                        columns={columns}
                        data={dataTableQualifier}
                        small loading={loading}
                        rowKey={(row, index) => index}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(QualifierContainer);