import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.css'
import { authData } from 'actions/actionsCreator';
import { TitleHead, ButtonCustom, TableCustom, SelectHero, InputHero } from "components"
import { Row, Col } from "antd"
import { Link } from 'react-router-dom';
import { apiServices, API_PATH } from "apiServices"
import { functionAlias } from 'routes'
import { ROUTH_PATH } from '../../../../routes/path';
import { Checkbox } from "antd"
import { SaleEmployeeKeyWordDropDown, getPermissionPage, sortDataColumn } from '../../../../helpers';

class SaleEmployeeMasterContainer extends Component {
    state = {
        permissionPage: getPermissionPage(functionAlias.employeeMaintain),
        keywords: SaleEmployeeKeyWordDropDown,
        disabledBtnSearchAndEcport: true,
        loading: false,
        columns: [
            {
                title: 'ร้าน',
                dataIndex: 'saleEmployeeOutletId',
                key: 'saleEmployeeOutletId',
                ellipsis: true,
            },
            {
                title: 'รหัสพนักงาน',
                dataIndex: 'saleEmployeeId',
                key: 'saleEmployeeId',
                sorter: (a, b) => sortDataColumn(a, b, 'saleEmployeeId'),
                ellipsis: true,
                render: (value, full) => <div className="button-table-zone">
                    <Link to={ROUTH_PATH.SALEEMPLOYEE_UPDATE.replace(":id", `${value}`).replace(":outletid", `${full.saleEmployeeOutletId}`)}>
                        {value}
                    </Link>
                </div>
            },
            {
                title: 'ชื่อพนักงาน',
                dataIndex: 'saleEmployeeName',
                key: 'saleEmployeeName',
                sorter: (a, b) => sortDataColumn(a, b, 'saleEmployeeName'),
                ellipsis: true,
            },
            {
                title: 'โทรศัพท์',
                dataIndex: 'saleEmployeePhone',
                key: 'saleEmployeePhone',
                sorter: (a, b) => sortDataColumn(a, b, 'saleEmployeePhone'),
                ellipsis: true,
            },
            {
                title: 'ที่อยู่',
                dataIndex: 'saleEmployeeAddress',
                key: 'saleEmployeeAddress',
                ellipsis: true,
                render: (value, full) => 
                <span>
                    {`${full.saleEmployeeAddress} ${full.saleEmployeeSubDistrict} ${full.saleEmployeeDistrict} ${full.saleEmployeeProvince} ${full.saleEmployeePostalCode} ${full.saleEmployeeCountry}`}
                </span>
            },
            {
                title: 'สถานะพนักงาน',
                dataIndex: 'saleEmployeeActiveStatus',
                key: 'saleEmployeeActiveStatus',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'saleEmployeeActiveStatus'),
                render: (value, row) => <div>
                    {
                        <div style={{ textAlign: 'center' }}>
                            <Checkbox checked={value} disabled />
                        </div>
                    }
                </div>
            },
        ]
    };

    componentDidMount (){
        if(this.state.keywords && this.state.keywords.length > 1){
            this.setState({keyword : this.state.keywords[1].value});
        }
    }

    handleClickSearchSaleEmployee = async () => {
        console.log(`keyword = ${this.state.keyword}`);
        let outlets = "";
        this.props.auth.userOutletModelList && this.props.auth.userOutletModelList.map((outlet, i) => (
            outlets += !outlets ? outlet.outletId : `,${outlet.outletId}`
        ));
        console.log(`outlets : ${outlets}`);
        let URLAndParams =  `${API_PATH.GET_SALE_EMPLOYEE}?OutletId=${outlets}&${this.state.keyword}=${this.state.value}`;
        console.log(`URL : ${URLAndParams}`);
        let res = await apiServices.callApi("get", URLAndParams, '', '')//auth.token
        console.log("res===>", res);
        if (res.status === 200) {
            let dataSaleEmployeeList = res.data.results.map((data, i) => (
                {
                    ...data,
                    key: i
                }
            ))
            this.setState({
                dataSaleEmployeeList: dataSaleEmployeeList,
                loading: false
            })
        }
    }

    handleKeywordChang = (val) => {
        this.setState({keyword : val});
    }

    handleValueChang = (val) => {
        //console.log(`val = ${val.target.value}`);
        if(val.target.value){
            this.setState({disabledBtnSearchAndEcport : false});
        }else{
            this.setState({disabledBtnSearchAndEcport : true});
        }
        this.setState({value : val.target.value});
    }

    render() {
        const { permissionPage } = this.state
        return (
            <div className="sale-employee-master-container">
                <TitleHead text="ค้นหาข้อมูลพนักงาน" icon="search" />
                <div className="top-layout">
                    <Row gutter={10}>
                        <Col sm={6}>
                            <SelectHero
                                onChange={this.handleKeywordChang}
                                name="keyword" 
                                label="ค้นหาด้วย" 
                                options={this.state.keywords}
                                value={this.state.keyword}
                            />
                        </Col>
                        <Col sm={10}>
                            <InputHero 
                                onChange={this.handleValueChang}  
                                name="value"
                                label="ข้อมูล"
                                value={this.state.value}
                            />
                        </Col>
                        <Col sm={8}>
                            <div className="button-layout">
                                <span>
                                    <ButtonCustom red text="ค้นหา" icon="search" onClick={this.handleClickSearchSaleEmployee}  disabled={this.state.disabledBtnSearchAndEcport} />
                                </span>
                                <span>
                                    <ButtonCustom green text="export" icon="export" disabled={this.state.disabledBtnSearchAndEcport || !permissionPage.authExport} />
                                </span>
                                <Link to={ROUTH_PATH.SALEEMPLOYEE_CREATE}>
                                    <ButtonCustom text="สร้างข้อมูลพนักงาน" icon="user-add" type="primary" disabled={!permissionPage.authCreate} />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    
                </div>
                <div className="table-layout">
                    <TableCustom height="calc(100vh - 314px)" columns={this.state.columns} data={this.state.dataSaleEmployeeList} loading={this.state.loading} />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => { //get 
    //console.log("state===>", state);
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => { // set
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SaleEmployeeMasterContainer);