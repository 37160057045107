import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form'
import { Row, Col, Card, Spin, message } from "antd"
import { validationSCGID, validationSCGIDAvailable } from '../../../../helpers/function'
import './style.css'
import {
  TitleHead,
  RadioCustom,
  CheckBoxCustom,
  InputHero,
  ButtonCustom,
  ModalCustom,
  TableCustom,
  SelectHero,
} from "components"

import {
  NumberFormatCustom,
  InputSearchCustom,
} from "components/common"

import {
  CustomerDetailFormRedux,
  CustomerSearchModal,
} from "components/feature"
import {
  SelectCustom,
} from 'components/common'

import moment from 'moment';
import { Link } from 'react-router-dom';
import { ROUTH_PATH } from '../../../../routes/path';
import { apiServices, API_PATH } from "apiServices"
import { PaymentForm, /*SaleOrderCustomerForm*/ } from "reduxForms"
import swal from '@sweetalert/with-react';
import { isEmpty, get, first } from 'lodash';
import { functionAlias } from 'routes'
import { getPermissionPage, isOutletIdAuth } from 'helpers'

const depositOptions = [{ label: 'ชำระทั้งหมด(100%)', value: '1' }, { label: 'ชำระ 2 งวด(50%, 50%)', value: '2' }, { label: 'ชำระ 2 งวด(30%, 70%)', value: '3' }, /*{ label: 'ชำระ 3 งวด(30%, 40%, 30%)', value: '4' },*/ { label: 'ชำระ 2 งวด(70%, 30%)', value: '5' }]
const billingTypeOptions = [{ label: 'เงินสด', value: "ZPMT" }, { label: 'ใบรับเงินมัดจำ', value: "ZDEP" }];
const taxTypeOptions = [{ label: 'ใบกำกับภาษีอย่างย่อ', value: "abb" }, { label: 'ใบกำกับภาษีอย่างเต็ม', value: "full" }];
//const paymentTypeOptions = [{ label: 'บัตรเครดิต', value: "CREDIT" }, { label: 'โอนเงินผ่านธนาคาร', value: "BANK" }, { label: 'คูปอง/แลกคะแนน', value: "COUPON" }, { label: 'โปรโมชั่นจากผู้ผลิต', value: "VENDOR" }];
const bankMachineOptions = [{ label: 'ธ.กรุงเทพ', value: "BBL" }, { label: 'ธ.กสิกร', value: "KBK" }, { label: 'ธ.กรุงศรี', value: "KRS" }, { label: 'ธ.กรุงไทย', value: "KTB" }, { label: 'ธ.ไทยพาณิชย์', value: "SCB" }];
const bankCardOptions = [{ label: 'ธ.กรุงเทพ', value: "BBL" }, { label: 'ธ.กสิกร', value: "KBK" }, { label: 'ธ.กรุงศรี', value: "KRS" }, { label: 'ธ.กรุงไทย', value: "KTB" }, { label: 'ธ.ไทยพาณิชย์', value: "SCB" }, { label: 'อื่นๆ', value: "Other" }];
//const couponTypeOptions = [{ label: 'คูปองจากการรับคืน', value: "CN" }, { label: 'คูปองแทนเงินสด', value: "GIFT_V" }, { label: 'SCG ช้างเพื่อนช่าง', value: "RED_C" }, { label: 'SCG แฟมิลี่ แลกคะแนน', value: "RED_P" }, { label: 'SCG แฟมิลี่ Voucher', value: "RED_V" }, { label: 'SCG แฟมิลี่ บัตรเงินสด', value: "RED_CA" }]
class PaymentContainer extends Component {
  state = {
    /* initial value */
    permissionPage: getPermissionPage(functionAlias.paymentMaintain),
    loading: false,
    disabledSoNumber: false,
    billingType: "ZPMT",
    taxType: "abb",
    autoGI: true,
    btnSaveDisabled: true,
    documentDate: new Date(),
    isShowModal: false,
    selectIndexShipTo: undefined,
    renderContentModal: {},
    dataPaymentList: [],
    SCGIDCoupons: [],
    isUpdateSO: false,
    nDeposit: 1,
    depositType: '',
    percentDepositAmount: 0,
    salesInstallmentLog: {},
    installmentAmounts: [],
    disabledBillingType: false,

    //Numeric
    cash: parseFloat(0).toFixed(2),
    credit: parseFloat(0).toFixed(2),
    coupon: parseFloat(0).toFixed(2),
    promotion: parseFloat(0).toFixed(2),
    transfer: parseFloat(0).toFixed(2),
    witholdingtax: parseFloat(0).toFixed(2),
    cardfee: parseFloat(0).toFixed(2),
    transferfee: parseFloat(0).toFixed(2),
    totalcashreceive: parseFloat(0).toFixed(2),
    diffcash: parseFloat(0).toFixed(2),
    totalAmount: parseFloat(0).toFixed(2),
    totalPaid: parseFloat(0).toFixed(2),
    totalBalance: parseFloat(0).toFixed(2),
    cashPrice: parseFloat(0).toFixed(2),
    /* end initial value */

    /* Table */
    defaultColumns: [
      {
        title: <ButtonCustom text="เพิ่มประเภทการชำระ" green small icon="plus" disabled={true} type="primary" onClick={() => this.handleAddPaymentClickModal()} />,
        dataIndex: 'billingPaymentId',
        key: 'billingPaymentId',
        ellipsis: true,
        width: 150
      },
      {
        title: 'ประเภท',
        dataIndex: 'paymentType',
        key: 'paymentType',
        ellipsis: true,
      },
      {
        title: 'เลขที่บัตร',
        dataIndex: 'paymentNumber',
        key: 'paymentNumber',
        ellipsis: true,
      },
      {
        title: 'ธนาคารเครื่องรูด',
        dataIndex: 'bankMachine',
        key: 'bankMachine',
        ellipsis: true,
      },
      {
        title: 'เลขที่อนุมัติ',
        dataIndex: 'confirmNo',
        key: 'confirmNo',
        ellipsis: true,
      },
      {
        title: 'วันที่หมดอายุ',
        dataIndex: 'expiredDate',
        key: 'expiredDate',
        ellipsis: true,
      },
      {
        title: 'ธนาคาร',
        dataIndex: 'bankCard',
        key: 'bankCard',
        ellipsis: true,
      },
      {
        title: 'ค่าธรรมเนียม',
        dataIndex: 'paymentFee',
        key: 'paymentFee',
        ellipsis: true
      },
      {
        title: 'ผู้ผลิต',
        dataIndex: 'vendor',
        key: 'vendor',
        ellipsis: true
      },
      {
        title: 'วันที่รับโอน',
        dataIndex: 'paymentDate',
        key: 'paymentDate',
        ellipsis: true,
      },
      {
        title: 'จำนวนเงิน',
        dataIndex: 'amount',
        key: 'amount',
        ellipsis: true,
        align: 'right',
      },
    ]
  }

  componentDidMount = async () => {
    const { match } = this.props
    const outletId = get(match, 'params.outletId', '')
    if (!this.state.permissionPage.authDisplay || (outletId && !isOutletIdAuth(outletId))) {
      swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
        this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
      })
      return
    }

    await this.fetchDataDefault();
    if (match && match.params && match.params.soNumber && match.params.outletId) {
      this.setState({ soNumber: match.params.soNumber, valueOutletId: match.params.outletId }, () => this.handleSONumberEnter(match.params.soNumber));
    }
  }

  fetchDataDefault = async () => {
    let bookBankMastersOptions = [];
    let valueOutletId;
    let valueOutletRole;
    let outletOptions = [];
    let masterOutlets = [];
    let dataRegionCountry = [];
    let dataCustomerTitle = [];
    let dataProvice = [];
    this.setState({ loading: true });
    const { auth } = this.props;

    // defualt dropdown of Payment
    let paramsOutlet = {
      outletIds: auth.userOutletModelList.map((data) => (
        data.outletId
      ))
    }

    let resbookbank = await apiServices.callApi("get", API_PATH.GET_MASTER_SALES_BOOK_BANK + `?OutletIds=${paramsOutlet.outletIds}`);
    let resOutlet = await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet);

    if (resbookbank.status === 200) {
      bookBankMastersOptions = resbookbank.data.results.map(data => ({ label: data.bookbankNumber, value: data.bookbankNumber, }));
    }

    if (resOutlet.status === 200) {
      let resAuthOutlets = resOutlet.data.results.filter((ele) => paramsOutlet.outletIds.findIndex(outl => outl.outletId === ele.outletId))
      if (resAuthOutlets && resAuthOutlets.length > 0) {
        outletOptions = resAuthOutlets.map(data => ({ label: `${data.outletId}: ${data.outletName}`, value: data.outletId, outletRole: data.outletRole }));
        masterOutlets = [...resAuthOutlets];
        valueOutletId = outletOptions[0].value;
        valueOutletRole = outletOptions[0].outletRole;
      }
    }

    // defualt dropdown of SaleOrderCustomerForm
    let resCustomerTitle = await apiServices.callApi('get', API_PATH.GET_MASTER_CUSTOMER_TITLE);
    let resRegionCountry = await apiServices.callApi('get', API_PATH.GET_MASTER_REGION_COUNTRY);

    if (resRegionCountry.status === 200) {
      dataRegionCountry = resRegionCountry.data.results.map(data => (
        {
          label: data.countryName,
          value: data.countryId,
        }
      ));

      let resProvince = await apiServices.callApi("get", API_PATH.GET_MASTER_PROVINCE + `?CountryId=${resRegionCountry.data.results[0].countryId}`)
      dataProvice = resProvince.data.results.map(data => (
        {
          label: data.provinceName,
          value: data.provinceCode,
        }
      ));
    }

    if (resCustomerTitle.status === 200) {
      dataCustomerTitle = resCustomerTitle.data.results.map(data => (
        {
          label: data.titleDescription,
          value: data.titleId,
        }
      ))
    }

    let paymentTypeOptions = [];
    const paramsPaymentType = `?ActiveStatus=true&PaymentGroups=banktransfer&PaymentGroups=creditcard&PaymentGroups=coupongroup&PaymentGroups=banktransfer&PaymentGroups=withholdingtax&PaymentGroups=sfinpay`;
    await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALES_PAYMENT}/${paramsPaymentType}`, '', '').then(res => {
      let results = get(res, 'data.results');
      if (!isEmpty(results)) {
        paymentTypeOptions = results.map(data => ({
          ...data,
          label: data.paymentDescription,
          value: data.paymentType,
        }));
      }
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "ประเภทการรับชำระ(Table Master Sales Payment)"!')
      swal('Error', message, 'error');
    });

    let couponTypeOptions = [];
    const paramsPaymentTypeCoupon = `?ActiveStatus=true&PaymentGroup=coupon`;
    await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALES_PAYMENT}/${paramsPaymentTypeCoupon}`, '', '').then(res => {
      let results = get(res, 'data.results');

      if (!isEmpty(results)) {
        couponTypeOptions = results.map(data => ({
          ...data,
          label: data.paymentDescription,
          value: data.paymentType,
        }));
      }
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "ประเภทการรับชำระ(Table Master Sales Payment)"!')
      swal('Error', message, 'error');
    });

    let withHoldingTaxOptions = [];
    const paramsWithHoldingTax = `?ActiveStatus=true`;
    await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALES_WITHOLDING_TAX}/${paramsWithHoldingTax}`, '', '').then(res => {
      let results = get(res, 'data.results');

      if (!isEmpty(results)) {
        withHoldingTaxOptions = results.map(data => ({
          ...data,
          label: data.whtDescription,
          value: data.whtCode,
        }));
      }
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "ประเภทการรับชำระ(Table Master Sales Payment)"!')
      swal('Error', message, 'error');
    });

    this.setState({
      bookBankMastersOptions,
      masterOutlets,
      outletOptions,
      valueOutletId,
      valueOutletRole,
      dataRegionCountry,
      dataCustomerTitle,
      dataProvice,
      bookBankMasters: resbookbank.data.results,
      paymentTypeOptions,
      couponTypeOptions,
      withHoldingTaxOptions,

      loading: false,
      disabledSoNumber: false,
      billingType: "ZPMT",
      taxType: "abb",
      autoGI: true,
      btnSaveDisabled: true,
      documentDate: new Date(),
      isShowModal: false,
      selectIndexShipTo: undefined,
      renderContentModal: {},
      dataPaymentList: [],
      soDetail: {},
      soNumber: '',
      customerId: '',
      columns: undefined,
      disabledBillingType: false,
      depositType: '',

      //Numeric
      cash: parseFloat(0).toFixed(2),
      credit: parseFloat(0).toFixed(2),
      coupon: parseFloat(0).toFixed(2),
      promotion: parseFloat(0).toFixed(2),
      transfer: parseFloat(0).toFixed(2),
      cardfee: parseFloat(0).toFixed(2),
      transferfee: parseFloat(0).toFixed(2),
      totalcashreceive: parseFloat(0).toFixed(2),
      diffcash: parseFloat(0).toFixed(2),
      totalAmount: parseFloat(0).toFixed(2),
      totalPaid: parseFloat(0).toFixed(2),
      totalBalance: parseFloat(0).toFixed(2),
      cashPrice: parseFloat(0).toFixed(2),
      /* end initial value */
    });
  }

  handlebtnAddPaymentEnabled = async () => {
    let { dataPaymentList } = this.state;

    dataPaymentList = [];
    const paramsPaymentTypeCash = `?ActiveStatus=true&PaymentGroups=cash`;
    await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALES_PAYMENT}/${paramsPaymentTypeCash}`, '', '').then(res => {
      let result = get(res, 'data.results.0');
      if (!isEmpty(result)) {
        dataPaymentList.push({
          key: this.GenerateUUID(),
          billingPaymentId: 0,
          paymentType: result.paymentType,
          paymentTypeDescription: result.paymentDescription,
          paymentNumber: null,
          bankMachine: null,
          confirmNo: null,
          expiredDate: null,
          bankCard: null,
          paymentFee: 0,
          vendor: null,
          paymentDate: new Date(),
          amount: 0
        });
      }
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "ประเภทการรับชำระ(Table Master Sales Payment)"!')
      swal('Error', message, 'error');
    });

    if (isEmpty(dataPaymentList)) {
      dataPaymentList.push({
        key: this.GenerateUUID(),
        billingPaymentId: 0,
        paymentType: "cash",
        paymentTypeDescription: "Cash",
        paymentNumber: null,
        bankMachine: null,
        confirmNo: null,
        expiredDate: null,
        bankCard: null,
        paymentFee: null,
        vendor: null,
        paymentDate: new Date(),
        amount: 0
      });
    }

    this.setState({
      dataPaymentList,
      /* Table */
      columns: [
        {
          title: <ButtonCustom text="เพิ่มประเภทการชำระ" green small icon="plus" onClick={() => this.handleAddPaymentClickModal()} />,
          dataIndex: 'billingPaymentId',
          key: 'billingPaymentId',
          ellipsis: true,
          render: (data, full, index) => <div className="button-table-zone">
            {
              full && full.paymentType && full.paymentType !== "cash" &&
              <ButtonCustom text="ลบ" small icon="minus" type="danger" onClick={() => this.handleDeletePaymentClick(index)} />
            }
          </div>,
          width: 150
        },
        {
          title: 'ประเภท',
          dataIndex: 'paymentTypeDescription',
          key: 'paymentTypeDescription',
          ellipsis: true,
        },
        {
          title: 'เลขที่บัตร',
          dataIndex: 'paymentNumber',
          key: 'paymentNumber',
          ellipsis: true,
        },
        {
          title: 'ธนาคารเครื่องรูด',
          dataIndex: 'bankMachine',
          key: 'bankMachine',
          ellipsis: true,
        },
        {
          title: 'เลขที่อนุมัติ',
          dataIndex: 'confirmNo',
          key: 'confirmNo',
          ellipsis: true,
        },
        {
          title: 'วันที่หมดอายุ',
          dataIndex: 'expiredDate',
          key: 'expiredDate',
          ellipsis: true,
          render: (data, full) => <div className="button-table-zone">
            {
              data &&
              moment(data).format("DD/MM/YYYY")
            }
          </div>,
        },
        {
          title: 'ธนาคาร',
          dataIndex: 'bankCard',
          key: 'bankCard',
          ellipsis: true,
        },
        {
          title: 'ค่าธรรมเนียม',
          dataIndex: 'paymentFee',
          key: 'paymentFee',
          ellipsis: true
        },
        {
          title: 'ผู้ผลิต',
          dataIndex: 'vendor',
          key: 'vendor',
          ellipsis: true
        },
        {
          title: 'วันที่ชำระ',
          dataIndex: 'paymentDate',
          key: 'paymentDate',
          ellipsis: true,
          render: (data, full) => <div className="button-table-zone">
            {
              data &&
              moment(data).format("DD/MM/YYYY")
            }
          </div>,
        },
        {
          title: 'จำนวนเงิน',
          dataIndex: 'amount',
          key: 'amount',
          ellipsis: true,
          align: 'right',
          render: (data, full) => <div className="button-table-zone">
            {
              full && full.paymentType && full.paymentType === "cash" ?

                <NumberFormatCustom
                  onChange={this.handleCashChang}
                  className="text-bold-align-right cashPrice"
                  label=""
                  value={data}
                //displayType={'text'}
                />
                /*<InputHero
                  onChange={this.handleCashChang}
                  name="cashPrice"
                  label=""
                  value={data}
                  small
                  widthSmall="40px"
                  textBoldAlignRight={true}
                />*/ :
                data
            }
          </div>,
        },
      ]
    });
  }

  initailBilling = async () => {
    let {
      soDetail,
      customerName,
      customerId,
      scgId,
      diffcash,
      totalAmount,
      totalPaid,
      totalBalance,
      btnAddPaymentDisabled,
      saleBillings,
      valueOutletId
    } = this.state;
    let couponOption = [];
    if (!this.validateSaleBillings()) {
      return;
    }
    await this.getDeposit();
    const validateDeposit = this.validateDeposit();
    if (!validateDeposit) {
      return;
    }

    totalAmount = soDetail.netAmount;//soDetail.totalAmount;
    totalBalance = totalAmount;
    totalPaid = 0;
    diffcash = totalAmount;
    customerName = soDetail.customerName;
    customerId = soDetail.customerId;
    scgId = soDetail.scgid;
    if (!isEmpty(valueOutletId) && !isEmpty(scgId)) {
      const resultSCGID = await validationSCGIDAvailable(scgId);
      if (!isEmpty(resultSCGID))
        couponOption = await this.getSCGIDCoupon(valueOutletId, scgId)
      else
        scgId = '';
    }

    this.handlebtnAddPaymentEnabled();
    this.setState({ totalAmount, totalBalance, totalPaid, diffcash, customerName, customerId, scgId, btnAddPaymentDisabled, disabledSoNumber: true, SCGIDCoupons: couponOption });
    await this.getBookbankOptions();
    await this.initailBillingDeposit();
  }

  initailBillingDeposit = async () => {
    const { salesInstallmentLogs/*, salesInstallmentAmounts*/ } = this.state;
    let { totalBalance } = this.state;
    if (!isEmpty(salesInstallmentLogs) && !isEmpty(first(salesInstallmentLogs).installmentType)) {
      this.handleBillingTypeChange('ZDEP');
      await this.handleChangeDepositType(first(salesInstallmentLogs).installmentType);

      const total = salesInstallmentLogs.reduce((prev, next) => prev + next.billingAmount, 0);
      totalBalance = totalBalance - total;
      this.setState({ disabledBillingType: true, totalPaid: total, totalBalance, diffcash: totalBalance });
    }
  }

  getSaleBillings = async () => {
    const { soDetail } = this.state;
    let resCashReceive = await apiServices.callApi("get", `${API_PATH.GET_CASHRECEIVE}?OutletId=${soDetail.outletId}&SoNumber=${soDetail.soNumber}`);
    //console.log("resCashReceive ===>", resCashReceive);
    if (resCashReceive.status === 200) {
      const saleBillings = get(resCashReceive, 'data.results');
      this.setState({ saleBillings });
      return true;
    }
    return false;
  }

  validateSaleBillings() {
    const { saleBillings } = this.state;
    //console.log("saleBillings ===>", saleBillings);
    if (saleBillings && saleBillings.length > 0) {
      let billingComplete = saleBillings.filter(bill => bill.documentTypeCode === "ZPMT" && !isEmpty(bill.documentStatus) && bill.documentStatus.toLowerCase() !== "cancel");
      if (!isEmpty(billingComplete) && billingComplete.length > 0) {
        swal("warning", `เลขที่ใบสั่งขาย: ${saleBillings[0].soNumber} ได้สร้างเอกสารชำระเงินสดเลขที่: ${saleBillings[0].billingNo} แล้ว!`, "warning");
        return false;
      }
    }

    return true;
  }

  validateDeposit = () => {
    const { soDetail, salesInstallmentLogs } = this.state;
    if (!isEmpty(salesInstallmentLogs)) {
      if (first(salesInstallmentLogs).installmentType === '1') {
        swal("warning", `เลขที่ใบสั่งขาย: ${soDetail.soNumber} ได้สร้างเอกสารเงินมัดจำงวดสุดท้ายแล้ว!`, "warning");
        return false;
      }
      //3 time
      else if (first(salesInstallmentLogs).installmentType === '4' && !isEmpty(salesInstallmentLogs.filter(i => i.installmentNo === '3'))) {
        swal("warning", `เลขที่ใบสั่งขาย: ${soDetail.soNumber} ได้สร้างเอกสารเงินมัดจำงวดสุดท้ายแล้ว!`, "warning");
        return false;
      }
      //2 time
      else if (first(salesInstallmentLogs).installmentType !== '4' && !isEmpty(salesInstallmentLogs.filter(i => i.installmentNo === '2'))) {
        swal("warning", `เลขที่ใบสั่งขาย: ${soDetail.soNumber} ได้สร้างเอกสารเงินมัดจำงวดสุดท้ายแล้ว!`, "warning");
        return false;
      }
    }

    return true;
  }

  getDeposit = async () => {
    const { soDetail, saleBillings } = this.state;
    if (saleBillings && saleBillings.length > 0) {
      let billingDeposit = saleBillings.filter(bill => bill.documentTypeCode === "ZDEP");
      if (!isEmpty(billingDeposit) && billingDeposit.length > 0) {
        await apiServices.callApi('get', `${API_PATH.GET_SALES_INSTALLMENT_LOG}?OutletId=${soDetail.outletId}&SoNumber=${soDetail.soNumber}`, '').then(async res => {
          let results = get(res, "data.results");
          this.setState({ salesInstallmentLogs: results });
        }).catch(error => {
          const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างดึงข้อมูล!')
          swal('Error', message, 'error');
        });

        await apiServices.callApi('get', `${API_PATH.GET_SALES_INSTALLMENT_AMOUNT}?OutletId=${soDetail.outletId}&SoNumber=${soDetail.soNumber}`, '').then(async res => {
          let results = get(res, "data.results");
          this.setState({ salesInstallmentAmounts: results });
        }).catch(error => {
          const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างดึงข้อมูล!')
          swal('Error', message, 'error');
        });

      }
    }
  }

  getBookbankOptions = async () => {
    const { soDetail } = this.state;

    let resbookbank = await apiServices.callApi("get", API_PATH.GET_MASTER_SALES_BOOK_BANK + `?OutletId=${soDetail.outletId}`);
    //console.log("resbookbank ===>", resbookbank);
    if (resbookbank.status === 200) {
      let bookBankMastersOptions = resbookbank.data.results.map(data => ({ label: data.bookbankNumber, value: data.bookbankNumber, }));
      this.setState({ bookBankMastersOptions, bookBankMasters: resbookbank.data.results });
    }
  }

  calculateBilling = () => {
    let {
      //soDetail,
      //customerName,
      //scgId,

      cash,
      credit,
      coupon,
      promotion,
      transfer,
      witholdingtax,
      cardfee,
      transferfee,
      totalcashreceive,
      diffcash,

      totalAmount,
      //totalPaid,
      //totalBalance,

      dataPaymentList,
      billingType,
      totalDepositAmount,
      sfinpay,
    } = this.state;

    cash = parseFloat(0);
    credit = parseFloat(0);
    coupon = parseFloat(0);
    promotion = parseFloat(0);
    transfer = parseFloat(0);
    witholdingtax = parseFloat(0);
    cardfee = parseFloat(0);
    transferfee = parseFloat(0);
    totalcashreceive = parseFloat(0);
    diffcash = parseFloat(0);
    sfinpay = parseFloat(0);
    if (billingType === 'ZDEP') {
      diffcash = parseFloat(totalDepositAmount).toFixed(2) || parseFloat(0).toFixed(2);
    } else {
      diffcash = parseFloat(totalAmount).toFixed(2) || parseFloat(0).toFixed(2);
    }

    totalcashreceive = parseFloat(0);
    dataPaymentList.forEach(item => {
      switch (item.paymentType) {
        case "cash":
          cash = (parseFloat(cash || 0) + parseFloat(item.amount || 0)).toFixed(2);
          diffcash = (parseFloat(diffcash || 0) - parseFloat(item.amount || 0)).toFixed(2);
          totalcashreceive = (parseFloat(totalcashreceive || 0) + parseFloat(item.amount || 0)).toFixed(2);
          break;
        case "VENDOR":
        case "vendor":
          promotion = (parseFloat(promotion || 0) + parseFloat(item.amount || 0)).toFixed(2);
          diffcash = (parseFloat(diffcash || 0) - parseFloat(item.amount || 0)).toFixed(2);
          totalcashreceive = (parseFloat(totalcashreceive || 0) + parseFloat(item.amount || 0)).toFixed(2);
          break;
        /*
        1.8. ค่าธรรมเนียมโอน  =  SUM(master_sales_payment/payment_group = banktransfer and sales_billing_payment/payment_fee)+
                                  (sales_billing_payment/amount wher payment_type = ecommerce_fee) 
        */
        case "bank_transfer":
        case "ecommerce_fee":
        case "ecommerce":
        case "ecommerce_coupon":
          transfer = (parseFloat(transfer || 0) + parseFloat(item.amount || 0)).toFixed(2);
          diffcash = (parseFloat(diffcash || 0) - parseFloat(item.amount || 0)).toFixed(2);
          totalcashreceive = (parseFloat(totalcashreceive || 0) + parseFloat(item.amount || 0)).toFixed(2);

          if (parseFloat(item.transferfee || 0) > 0 || parseFloat(item.paymentFee || 0) > 0) {
            transferfee = (parseFloat(transferfee || 0) + parseFloat(item.transferfee || 0) + parseFloat(item.paymentFee || 0)).toFixed(2);
          }
          break;
        /*
          ค่าธรรมเนียมบัตร  =  SUM(master_sales_payment/payment_group = creditcard and 
                                sales_billing_payment/payment_fee)
        */
        case "credit_card":
          credit = (parseFloat(credit || 0) + parseFloat(item.amount || 0)).toFixed(2);
          diffcash = (parseFloat(diffcash || 0) - parseFloat(item.amount || 0)).toFixed(2);
          totalcashreceive = (parseFloat(totalcashreceive || 0) + parseFloat(item.amount || 0)).toFixed(2);

          if (parseFloat(item.transferfee || 0) || parseFloat(item.paymentFee || 0) > 0) {
            cardfee = (parseFloat(cardfee || 0) + parseFloat(item.transferfee || 0) + parseFloat(item.paymentFee || 0)).toFixed(2);
          }
          break;
        case "credit_card_online":
          credit = (parseFloat(credit || 0) + parseFloat(item.amount || 0)).toFixed(2);
          diffcash = (parseFloat(diffcash || 0) - parseFloat(item.amount || 0)).toFixed(2);
          totalcashreceive = (parseFloat(totalcashreceive || 0) + parseFloat(item.amount || 0)).toFixed(2);
          break;
        case "scg_family_point":
        case "scg_family_voucher":
        case "scg_family_cpc":
        case "scg_family_cashcard":
        case "supplier_coupon":
        case "credit_note":
        case "gift_voucher":
        case "cash_coupon_discount":
          coupon = (parseFloat(coupon || 0) + parseFloat(item.amount || 0)).toFixed(2);
          diffcash = (parseFloat(diffcash || 0) - parseFloat(item.amount || 0)).toFixed(2);
          totalcashreceive = (parseFloat(totalcashreceive || 0) + parseFloat(item.amount || 0)).toFixed(2);
          break;
        case "witholding_tax":
          witholdingtax = (parseFloat(witholdingtax || 0) + parseFloat(item.amount || 0)).toFixed(2);
          diffcash = (parseFloat(diffcash || 0) - parseFloat(item.amount || 0)).toFixed(2);
          totalcashreceive = (parseFloat(totalcashreceive || 0) + parseFloat(item.amount || 0)).toFixed(2);
          break;
        case "s_fin_pay":
          sfinpay = (parseFloat(sfinpay || 0) + parseFloat(item.amount || 0)).toFixed(2);
          diffcash = (parseFloat(diffcash || 0) - parseFloat(item.amount || 0)).toFixed(2);
          totalcashreceive = (parseFloat(totalcashreceive || 0) + parseFloat(item.amount || 0)).toFixed(2);
            break;
        default:
          console.log(`Value in swith not matching ${item.paymentType}`);
          break;
      }
    });
    this.setState({
      cash,
      credit,
      coupon,
      promotion,
      transfer,
      witholdingtax,
      cardfee,
      transferfee,
      totalcashreceive,
      diffcash,
    });
    this.handlebtnSaveEnabledAndDisabled(diffcash);
  }
  handlebtnSaveEnabledAndDisabled = (diffcash) => {
    let { btnSaveDisabled } = this.state;
    diffcash = parseFloat(diffcash);
    if (diffcash === 0) {
      btnSaveDisabled = false;
    } else {
      btnSaveDisabled = true;
    }
    this.setState({ btnSaveDisabled });
  }
  handleCashChang = (val) => {
    let { dataPaymentList } = this.state;
    let value = val.target.value.replace(/,/g, '');
    //Index of CASH is Number Zero.
    first(dataPaymentList).amount = value;
    this.setState({ dataPaymentList });
    this.calculateBilling();
  }
  handleAddPaymentClickModal = () => {
    let { bookBankMastersOptions, bookBankMasters, paymentTypeOptions, couponTypeOptions, withHoldingTaxOptions, SCGIDCoupons } = this.state;
    const initial = {
      paymentType: "",
      PaymentTypeDescription: "",
      cardNumber: "",
      bankMachine: "",
      bankCard: "",
      confirmNo: "",
      paymentFee: 0,
      amount: 0,
      bankAccount: "",
      transferDate: "",
      couponType: "",
      couponNumber: "",
      expiredDate: "",
      vendor: "",
      whtCode: "87",
      paymentNumber: "",
      baseOfTax: "",
      wTaxRate: "",
      paymentDate: "",
    }
    this.setState({
      isShowModal: true,
      titleModal: `เพิ่มประเภทการชำระ`,
      widthModal: '900px',
      renderContentModal: <div>
        <PaymentForm
          couponOptions={SCGIDCoupons}
          paymentTypeOptions={paymentTypeOptions}
          bankMachineOptions={bankMachineOptions}
          bankCardOptions={bankCardOptions}
          bookBankMastersOptions={bookBankMastersOptions}
          bookBankMasters={bookBankMasters}
          couponTypeOptions={couponTypeOptions}
          withHoldingTaxOptions={withHoldingTaxOptions}
          searchVoucher={(val, fun) => { this.handleCheckVoucher(val, fun) }}
          initialValues={initial}
        />
      </div>,
      footerModal: <div className="modal-footer-button">
        <div>
          <ButtonCustom onClick={() => this.handleClickSaveAddPayment()} text="บันทึก" icon="save" />
        </div>
        <div>
          <ButtonCustom onClick={() => this.handleCloseModal()} text="ยกเลิก" type="danger" />
        </div>
      </div>,
    });
  }
  handleDeletePaymentClick = (index) => {
    let { dataPaymentList } = this.state;
    dataPaymentList.splice(index, 1);
    this.setState({ dataPaymentList }, () => this.calculateBilling());
  }
  handleCloseModal = () => {
    this.setState({
      isShowSearchCustomerModal: false,
      isShowModal: false,
      selectIndexShipTo: undefined
    });
    this.props.resetPaymentForm();
  }

  handleCheckVoucher = async (val, { setVoucherAmount, setVoucherDate }) => {
    let { dataPaymentList, valueOutletId, soNumber } = this.state;
    const { PaymentReduxForm: { values, syncErrors } } = this.props;


    if (typeof (syncErrors) === 'object') {
      message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
      this.setState({
        submitViewError: true
      });
      return;
    }
    // saleChannelCode
    // customerId
    // จ้า
    // console.log('values', values);
    try {
      let urlgiftWithParam = `${API_PATH.GET_VALIDATE_GIFT_VOUCHER}?OutletId=${valueOutletId}&VoucherCode=${values.couponNumber}&Status=${true}&ActiveDate=${moment(new Date()).format("YYYY-MM-DD")}&VoucherValue=${values.amount || 0}&CampaignStatus=Approve&SONumber=${soNumber}&SaleChannelCode=${this.state.soDetail.saleChannelCode}&CustomerId=${this.state.soDetail.customerId}`
      // จ้า
      await apiServices.callApi('get', urlgiftWithParam, '', '').then(res => {
        let results = get(res, 'data.results');
        if (isEmpty(results)) {
          setVoucherAmount(0);
          setVoucherDate(null);
          swal('Error', `ไม่พบข้อมูล Gift Voucher เลขที่ "${values.couponNumber}" ร้าน ${valueOutletId}`, 'error');
          return;
        }
        setVoucherAmount(results[0].voucherValue);
        setVoucherDate(moment(results[0].expireDate));

      })
    } catch (error) {
      setVoucherAmount(0);
      setVoucherDate(null);
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "Gift Voucher"!')
      swal('Error', message, 'error');
      return;
    }
  }

  handleClickSaveAddPayment = async () => {
    let { dataPaymentList, valueOutletId } = this.state;
    const { PaymentReduxForm: { values, syncErrors } } = this.props;
    const { bookBankMasters } = this.state;
    let bookBankFilter = [];
    let bank = "";
    if (typeof (syncErrors) === 'object') {
      message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
      this.setState({
        submitViewError: true
      });
      return;
    }

    let paymentType = values.paymentType;
    if (paymentType === "coupon_point") {
      paymentType = values.couponType;
    }
    switch (paymentType) {
      case "VENDOR":
        dataPaymentList.push({
          key: this.GenerateUUID(),
          billingPaymentId: 0,
          paymentType: "VENDOR",
          paymentNumber: null,
          bankMachine: null,
          confirmNo: null,
          expiredDate: null,
          bankCard: null,
          paymentFee: null,
          vendor: values.vendor,
          paymentDate: new Date(),
          amount: !isEmpty(values.amount) ? values.amount.replace(/,/g, '') : parseFloat(0).toFixed(2),
        });
        this.setState({ dataPaymentList }, () => {
          this.calculateBilling();
          this.handleCloseModal();
        });
        break;
      case "bank_transfer":
      case "ecommerce":
        bookBankFilter = bookBankMasters.filter(function (bookBank) {
          return bookBank.bookbankNumber === values.bankAccount;
        });
        bank = "";
        if (bookBankFilter.length > 0) {
          bank = bookBankFilter[0].remark;
        }

        dataPaymentList.push({
          key: this.GenerateUUID(),
          billingPaymentId: 0,
          paymentType: values.paymentType,
          paymentTypeDescription: values.paymentTypeDescription,
          paymentNumber: values.bankAccount,
          bankMachine: null,
          confirmNo: values.confirmNo || null,
          expiredDate: null,
          bankCard: bank,
          paymentFee: null,
          vendor: null,
          paymentDate: values.transferDate,
          amount: !isEmpty(values.amount) ? values.amount.replace(/,/g, '') : parseFloat(0).toFixed(2),
        });

        this.setState({ dataPaymentList }, () => {
          this.calculateBilling();
          this.handleCloseModal();
        });
        break;
      case "ecommerce_coupon":
        bookBankFilter = bookBankMasters.filter(function (bookBank) {
          return bookBank.bookbankNumber === values.bankAccount;
        });
        bank = "";
        if (bookBankFilter.length > 0) {
          bank = bookBankFilter[0].remark;
        }

        dataPaymentList.push({
          key: this.GenerateUUID(),
          billingPaymentId: 0,
          paymentType: values.paymentType,
          paymentTypeDescription: values.paymentTypeDescription,
          paymentNumber: values.couponNumber,
          // paymentNumber: values.bankAccount,
          bankMachine: null,
          // confirmNo: null,
          confirmNo: values.confirmNo || null,
          expiredDate: null,
          bankCard: bank,
          paymentFee: null,
          vendor: null,
          paymentDate: values.transferDate,
          amount: !isEmpty(values.amount) ? values.amount.replace(/,/g, '') : parseFloat(0).toFixed(2),
        });
        this.setState({ dataPaymentList }, () => {
          this.calculateBilling();
          this.handleCloseModal();
        });
        break;
      case "ecommerce_fee":
        bookBankFilter = bookBankMasters.filter(function (bookBank) {
          return bookBank.bookbankNumber === values.bankAccount;
        });
        //console.log(`bookBankFilter ==> `, bookBankFilter);
        bank = "";
        if (bookBankFilter.length > 0) {
          bank = bookBankFilter[0].remark;
        }

        dataPaymentList.push({
          key: this.GenerateUUID(),
          billingPaymentId: 0,
          paymentType: values.paymentType,
          paymentTypeDescription: values.paymentTypeDescription,
          paymentNumber: values.couponNumber,
          // paymentNumber: values.bankAccount,
          bankMachine: null,
          confirmNo: null,
          expiredDate: null,
          bankCard: bank,
          paymentFee: !isEmpty(values.amount) ? values.amount.replace(/,/g, '') : parseFloat(0).toFixed(2),
          vendor: null,
          paymentDate: values.transferDate,
          amount: null
        });
        this.setState({ dataPaymentList }, () => {
          this.calculateBilling();
          this.handleCloseModal();
        });
        break;
      case "credit_card":
      case "credit_card_online":
        dataPaymentList.push({
          key: this.GenerateUUID(),
          billingPaymentId: 0,
          paymentType: values.paymentType,
          paymentTypeDescription: values.paymentTypeDescription,
          paymentNumber: values.cardNumber,
          bankMachine: values.bankMachine,
          confirmNo: values.confirmNo,
          expiredDate: null,
          bankCard: values.bankCard,
          paymentFee: !isEmpty(values.paymentFee) ? values.paymentFee.replace(/,/g, '') : parseFloat(0).toFixed(2),
          vendor: null,
          paymentDate: new Date(),
          amount: !isEmpty(values.amount) ? values.amount.replace(/,/g, '') : parseFloat(0).toFixed(2),
        });
        this.setState({ dataPaymentList }, () => {
          this.calculateBilling();
          this.handleCloseModal();
        });
        break;
      case "supplier_coupon":
      case "scg_family_point":
      case "scg_family_voucher":
      case "scg_family_cpc":
      case "scg_family_cashcard":
      case "cash_coupon_discount":
        dataPaymentList.push({
          key: this.GenerateUUID(),
          billingPaymentId: 0,
          paymentType: paymentType,
          paymentTypeDescription: values.paymentTypeDescription,
          couponNumber: values.couponNumber,
          paymentNumber: values.couponNumber,
          bankMachine: null,
          confirmNo: null,
          expiredDate: values.expiredDate,
          bankCard: null,
          paymentFee: null,
          vendor: null,
          paymentDate: new Date(),
          amount: !isEmpty(values.amount) ? values.amount.replace(/,/g, '') : parseFloat(0).toFixed(2),
        });
        this.setState({ dataPaymentList }, () => {
          this.calculateBilling();
          this.handleCloseModal();
        });
        break;
      case "gift_voucher":
        let urlgiftWithParam = `${API_PATH.GET_VALIDATE_GIFT_VOUCHER}?OutletId=${valueOutletId}&VoucherCode=${values.couponNumber}&Status=${true}&ActiveDate=${moment(new Date()).format("YYYY-MM-DD")}&VoucherValue=${values.amount || 0}&CampaignStatus=Approve&SONumber=${this.state.soNumber}&SaleChannelCode=${this.state.soDetail.saleChannelCode}&CustomerId=${this.state.soDetail.customerId}`

        await apiServices.callApi('get', urlgiftWithParam, '', '').then(res => {
          let results = get(res, 'data.results');
          if (isEmpty(results)) {
            swal('Error', `ไม่พบข้อมูล Gift Voucher เลขที่ "${values.couponNumber}" ร้าน ${valueOutletId}`, 'error');
            return;
          }
          dataPaymentList.push({
            key: this.GenerateUUID(),
            billingPaymentId: 0,
            paymentType: paymentType,
            paymentTypeDescription: values.paymentTypeDescription,
            couponNumber: values.couponNumber,
            paymentNumber: values.couponNumber,
            bankMachine: null,
            confirmNo: null,
            expiredDate: results[0].expireDate,//values.expiredDate,
            bankCard: null,
            paymentFee: null,
            vendor: null,
            paymentDate: new Date(),
            amount: results[0].voucherValue//!isEmpty(values.amount) ? values.amount.replace(/,/g, '') : parseFloat(0).toFixed(2),
          });

          this.setState({ dataPaymentList }, () => {
            this.calculateBilling();
            this.handleCloseModal();
          });

        }).catch(error => {
          const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "Gift Voucher"!')
          swal('Error', message, 'error');
          return;
        });
        break;
      case "credit_note":
        let urlWithParam = `${API_PATH.GET_VALIDATE_CN_VOUCHER}?OutletId=${valueOutletId}&BillingNo=${values.couponNumber}&DocumentStatus=open`
        await apiServices.callApi('get', urlWithParam, '', '').then(res => {
          let result = get(res, 'data.results.0');

          if (parseFloat(result.voucherBalance || 0) >= parseFloat(values.amount)) {
            dataPaymentList.push({
              key: this.GenerateUUID(),
              billingPaymentId: 0,
              paymentType: paymentType,
              paymentTypeDescription: values.paymentTypeDescription,
              couponNumber: values.couponNumber,
              paymentNumber: values.couponNumber,
              bankMachine: null,
              confirmNo: null,
              expiredDate: values.expiredDate,
              bankCard: null,
              paymentFee: null,
              vendor: null,
              paymentDate: new Date(),
              amount: !isEmpty(values.amount) ? values.amount.replace(/,/g, '') : parseFloat(0).toFixed(2),
            });

            this.setState({ dataPaymentList }, () => {
              this.calculateBilling();
              this.handleCloseModal();
            });
          } else {
            swal('Error', `ยอดเงินไม่เพียงพอ คูปอง "${values.couponNumber}" มูลค่า ${result.voucherAmount || 0} ใช้ไปแล้ว ${result.voucherUsed || 0} สามารถใช้ได้ ${result.voucherBalance || 0}`, 'error');
            return;
          }

        }).catch(error => {
          const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "ประเภทเอกสาร"!')
          swal('Error', message, 'error');
          return;
        });
        break;
      case "witholding_tax":
        dataPaymentList.push({
          key: this.GenerateUUID(),
          billingPaymentId: 0,
          paymentType: values.paymentType,
          whtCode: values.whtCode,
          paymentTypeDescription: values.paymentTypeDescription,
          couponNumber: values.couponNumber,
          paymentNumber: values.paymentNumber,
          bankMachine: null,
          confirmNo: null,
          expiredDate: values.expiredDate,
          bankCard: null,
          paymentFee: null,
          vendor: null,
          paymentDate: new Date(),
          amount: !isEmpty(values.amount) ? values.amount.replace(/,/g, '') : parseFloat(0).toFixed(2),
          baseOfTax: values.baseOfTax,
          wtaxRate: values.wTaxRate,
        });
        this.setState({ dataPaymentList }, () => {
          this.calculateBilling();
          this.handleCloseModal();
          this.props.resetPaymentForm();
        });
        break;
      case "s_fin_pay":
          dataPaymentList.push({
            key: this.GenerateUUID(),
            billingPaymentId: 0,
            paymentType: paymentType,
            paymentTypeDescription: values.paymentTypeDescription,
            paymentNumber: null,
            bankMachine: null,
            confirmNo: null,
            expiredDate: null,
            bankCard: null,
            paymentFee: null,
            vendor: null,
            paymentDate: new Date(),
            amount: !isEmpty(values.amount) ? values.amount.replace(/,/g, '') : parseFloat(0).toFixed(2),
          });
          this.setState({ dataPaymentList }, () => {
            this.calculateBilling();
            this.handleCloseModal();
          });
          break;
      default:
        console.log(`Value in swith not matching ${values.paymentType}`);
    }
    // console.log('dataPaymentList',dataPaymentList);

  }

  GenerateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  handleBillingTypeChange = (val) => {
    let { disabledTaxType, taxType } = this.state;
    if (val === "ZPMT") {
      taxType = "abb";
      disabledTaxType = false;
    } else if (val === "ZDEP") {
      taxType = "full";
      disabledTaxType = true;
    }

    this.setState({ billingType: val, disabledTaxType, taxType, depositType: null });
  }
  handleTaxTypeChang = (val) => {
    //console.log("taxType==>", val.target.value);
    this.setState({ taxType: val.target.value });
  }
  handleAutoGIChang = (val) => {
    //console.log("autoGI==>",val.target.checked);
    this.setState({ autoGI: val.target.checked });
  }
  handleSONumberChang = async (val) => {
    //console.log("soNumber==>", val.target.value);
    this.setState({ soNumber: val.target.value });
  }
  handleSONumberEnter = async (val) => {
    this.setState({ loading: true });
    try {
      const { valueOutletId } = this.state;
      //console.log("soNumber==>", val.target.value);
      let params = { soNumber: val, outletId: valueOutletId };
      let res = await apiServices.callApi('post', API_PATH.POST_GET_DETAIL_SALE_ORDER, params)
      //console.log("resDetailSaleOrder === >", res);
      if (res.status === 200) {
        let soDetails = get(res, "data.results");
        if (soDetails && soDetails.length > 0) {
          let soDetail = soDetails[0];

          if (soDetail.documentTypeCode === 'ZOCR') {
            swal("error", "ไม่สามารถชำระเงินด้วย 'ใบสั่งขาย-เงินเชื่อ' ได้!", "error");
            this.setState({ loading: false });
            return false;
          }
          //console.log('soDetail', soDetail);
          this.setState({ soDetail });
          const isPassCondition = await this.getSaleBillings();
          if (isPassCondition === false) {
            console.error("การตรวจสอบ Sale Billing เกิดข้อผิดพลาด");
            swal("error", "การค้นหาเกิดข้อผิดพลาด!", "error");
            return false;
          }
          await this.initailBilling();
          await this.searchSCGID(soDetail.scgid)
        } else {
          swal("error", `ไม่พบหมายเลขใบสั่งขาย : ${params.soNumber}`, "error");
        }
      } else {
        swal("error", res.data.message, "error");
      }
    }
    catch (err) {
      console.log("Catch Error === >", err);
      swal("error", "การค้นหาเกิดข้อผิดพลาด!", "error");
    }
    this.setState({ loading: false });
  }

  handleChangeDepositType = async (val) => {
    const { soDetail } = this.state;
    if (val) {
      let urlWithParam = `${API_PATH.GET_CALCULATE_DEPOSIT_AMOUNTS}?DepositSet=${val}&OutletId=${soDetail.outletId}&SoNumber=${soDetail.soNumber}`
      await apiServices.callApi('get', urlWithParam).then(async res => {
        const salesInstallmentLog = get(res, "data.result.salesInstallmentLog");
        const salesInstallmentAmounts = get(res, "data.result.installmentAmounts");
        this.setState({
          salesInstallmentLog,
          salesInstallmentAmounts,
          percentDepositAmount: salesInstallmentLog.installmentRatio,
          totalDepositAmount: parseFloat(salesInstallmentLog.installmentTotalAmound).toFixed(2), //(Math.floor((salesInstallmentLog.installmentTotalAmound|| 0) * 100) / 100),
          nDeposit: salesInstallmentLog.installmentNo
        }, () => this.calculateBilling())
      }).catch(async error => {
        const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการคำนวนข้อมูลเงินมัดจำ!')
        swal('Error', message, 'error');
      });
    }

    this.setState({ depositType: val });
  }

  handleUpdateSaleOrder = async (soNumber) => {
    const { soDetail } = this.state;
    //console.log("params==>", JSON.stringify(soDetail), soDetail);
    let urlWithParam = `${API_PATH.PUT_SALE_ORDER}?soNumber=${soNumber}`
    return await apiServices.callApi("put", urlWithParam, soDetail)
      .then(res => {
        console.log("Success ==>", `หมายเลขใบสั่งขายสินค้า ${res.data.message} ถูกบันทึกสำเร็จ`);
        //swal("Success", `หมายเลขใบสั่งขายสินค้า ${res.data.message} ถูกบันทึกสำเร็จ`, "success");
        return true;
      })
      .catch(error => {
        console.log("error catch ==>", error);
        //swal("error", error.response.data.message, "error");
        return false;
      })
  }

  handleClickSaveBilling = async () => {
    this.setState({ loading: true });

    const { auth } = this.props;
    let {
      soDetail,
      dataPaymentList,
      taxType,
      billingType,
      isUpdateSO,
      autoGI,

      salesInstallmentLog,
      salesInstallmentAmounts,
      totalDepositAmount,
    } = this.state;

    if (this.validateSaveBilling() === false) {
      this.setState({ loading: false });
      return;
    }
    if (isUpdateSO === true && await this.handleUpdateSaleOrder(soDetail.soNumber) === false) {
      this.setState({ loading: false });
      swal("error", `เกิดข้อผิดพลาดการแก้ไขข้อมูลลูกค้า หมายเลขใบสั่งขายสินค้า: ${soDetail.soNumber}`, "error");
      return;
    }

    let billingInvoice = [
      {
        outletId: soDetail.outletId,
        soNumber: soDetail.soNumber,
        taxInvoiceType: taxType,
        //"taxInvoiceNo": "string",
        documentTypeCode: billingType,
        documentStatus: "OPEN",
        documentDate: new Date(),
        createdDate: new Date(),
        createdUser: auth.userId,
        //"updatedDate": "2020-02-15T08:19:02.892Z",
        //"updatedUser": "string",
        convertFlag: false
      }
    ]

    let billingHead = {
      ...soDetail,
      documentTypeCode: billingType,
      documentStatus: 'open',//soDetail.docStatus,
      documentDate: soDetail.docDate,
      createdUser: soDetail.createdBy,
      updatedDate: soDetail.changedDate,
      updatedUser: soDetail.changedBy,
      scgId: soDetail.scgid,
      returnType: soDetail.returnTypeId,
      discScgIdCode: soDetail.discSCGIDCode,
      discScgIdAmount: soDetail.discSCGIDAmount,
      referenceDocNumber: soDetail.refDocNumber,
      referenceDocSystem: soDetail.refDocSystem,
      salesBillingItem: soDetail.salesSOItemModelList,
      salesBillingInvoice: billingInvoice,
      salesBillingPayment: dataPaymentList,
      salesInstallmentLog: salesInstallmentLog,
      salesInstallmentAmounts: salesInstallmentAmounts,
      autoGI: autoGI
    }

    //deposit
    if (billingType === "ZDEP") {
      const totalDiscAmount = parseFloat(billingHead.totalDiscAmount || 0).toFixed(2) * (parseFloat(salesInstallmentLog.installmentRatio) / 100);
      const totalAmount = (parseFloat(totalDepositAmount) + parseFloat(totalDiscAmount)).toFixed(2);//(Math.floor(((parseFloat(billingHead.totalAmount || 0).toFixed(2) * (parseFloat(salesInstallmentLog.installmentRatio) / 100)) || 0) * 100) / 100)
      const netAmount = parseFloat(totalDepositAmount).toFixed(2);//(Math.floor(((parseFloat(billingHead.netAmount || 0).toFixed(2) * (parseFloat(salesInstallmentLog.installmentRatio) / 100)) || 0) * 100) / 100);
      const vatAmount = ((parseFloat(totalDepositAmount) * 7) / 107).toFixed(2);
      billingHead = {
        ...billingHead,
        documentStatus: "complete",
        discPercentAmount: parseFloat(billingHead.discPercentAmount || 0).toFixed(2) * (parseFloat(salesInstallmentLog.installmentRatio) / 100),
        discAmount: parseFloat(billingHead.discAmount || 0).toFixed(2) * (parseFloat(salesInstallmentLog.installmentRatio) / 100),
        discCouponPercentAmount: parseFloat(billingHead.discCouponPercentAmount || 0).toFixed(2) * (parseFloat(salesInstallmentLog.installmentRatio) / 100),
        discSCGIDAmount: parseFloat(billingHead.discSCGIDAmount || 0).toFixed(2) * (parseFloat(salesInstallmentLog.installmentRatio) / 100),
        discCouponAmount: parseFloat(billingHead.discCouponAmount || 0).toFixed(2) * (parseFloat(salesInstallmentLog.installmentRatio) / 100),
        totalDiscAmount: totalDiscAmount,
        totalAmount: totalAmount,
        vatAmount: vatAmount,//parseFloat(billingHead.vatAmount || 0).toFixed(2) * (parseFloat(salesInstallmentLog.installmentRatio) / 100),
        netAmount: netAmount,
      }
    }

    //console.log("JSON string billingHead === >", JSON.stringify(billingHead));
    //return

    await apiServices.callApi('post', API_PATH.POST_CREATE_CASHRECEIVE, billingHead).then(async res => {
      let resBilling = get(res, "data.results");
      swal('Success', `สร้างรายการชำระเงิน : ${resBilling[0].billingNo} ${res.data.message}`, 'success').then(() => {
        this.props.history.push(`${ROUTH_PATH.SALE_ORDER_EDIT_LINK}/${soDetail.soNumber}/${soDetail.outletId}?print=${billingType}`)
      })
      // await this.fetchDataDefault();
    }).catch(error => {
      if (error.response.status === 400) {
        const message = error.response.data.errors ? Object.values(error.response.data.errors).flat().join() : 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง';
        swal('Error', message, 'error')
      } else {
        const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง!')
        swal('Error', message, 'error')
      }
      /*const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล!')
      swal('Error', message, 'error');*/
    });
    this.setState({ loading: false });
  }

  validateSaveBilling = () => {
    const { taxType, soDetail, billingType, depositType, valueOutletRole } = this.state;

    if (billingType === 'ZDEP' && isEmpty(depositType)) {
      message.error("กรุณาระบุงวดการชำระเงิน!");
      return false;
    }
    if (taxType === 'full') {
      const customerSoldTo = soDetail.salesSOPartnerFunctionModelList.filter(partner => partner.partnerType === "sold_to");
      if (!customerSoldTo || customerSoldTo.length === 0) {
        message.error("ไม่พบข้อมูลลูกค้า!");
        return false;
      }
      const soldTo = customerSoldTo[0];

      //customerType : 1 = บุคคลธรรมดา, 2 = นิติบุคคล
      //บุคคลธรรมดา validate ต้องมีข้อมูล ชื่อ, ที่อยู่, แขวง, เขต, จังหวัด, รหัสไปรษณีย์, เลขที่ประจำตัวผู้เสียภาษี
      //นิติบุคคล validate ต้องมีข้อมูล ชื่อ, ที่อยู่, แขวง, เขต, จังหวัด, รหัสไปรษณีย์, เลขที่ประจำตัวผู้เสียภาษี

      if (!soldTo.customerName) {
        message.error("กรุณาระบุข้อมูล 'ชื่อ' ลูกค้า!");
        return false;
      }
      if (!soldTo.address) {
        message.error("กรุณาระบุข้อมูล 'ที่อยู่' ลูกค้า!");
        return false;
      }
      if (!soldTo.subDistrict) {
        message.error("กรุณาระบุข้อมูล 'แขวง/ตำบล' ลูกค้า!");
        return false;
      }
      if (!soldTo.district) {
        message.error("กรุณาระบุข้อมูล 'เขต/อำเภอ' ลูกค้า!");
        return false;
      }
      if (!soldTo.province) {
        message.error("กรุณาระบุข้อมูล 'จังหวัด' ลูกค้า!");
        return false;
      }
      if (!soldTo.postalCode) {
        message.error("กรุณาระบุข้อมูล 'รหัสไปรษณีย์' ลูกค้า!");
        return false;
      }

      // #1655 COCO, Boutique เมื่อเลือกใบกำกับภาษีอย่างเต็ม ให้ตรวจสอบให้ออกได้เฉพาะลูกค้าที่มี Tax id ทั้งบุคคลธรรมดาและนิติบุคคล
      if ((valueOutletRole === 'COCO' || valueOutletRole === 'Boutique') && !soldTo.taxId) {
        message.error("กรุณาระบุข้อมูล 'เลขที่ประจำตัวผู้เสียภาษี' ลูกค้า!");
        return false;
      } else if (soldTo.customerType && soldTo.customerType == '2' && !soldTo.taxId) {//นิติบุคคล
        message.error("กรุณาระบุข้อมูล 'เลขที่ประจำตัวผู้เสียภาษี' ลูกค้า!");
        return false;
      }
    }

    return true;
  }

  findCustomerSoldto = (soDetail) => {
    const customerSoldTo = soDetail.salesSOPartnerFunctionModelList.filter(partner => partner.partnerType === "sold_to");
    if (!customerSoldTo || customerSoldTo.length === 0) {
      message.error("ไม่พบข้อมูลลูกค้า!");
      return;
    }
    return customerSoldTo[0];
  }

  handleSubmitShowModalCustomer = async () => {

    this.setState({
      isShowModal: true,
      titleModal: "เพิ่ม / แก้ไขข้อมูลลูกค้า",
      widthModal: '900px',
      footer: true,
      footerModal: <div className="modal-footer-button">
        <div>
          <ButtonCustom onClick={() => this.handleClickSaveCustomer()} text="บันทึก" icon="save" />
        </div>
        <div>
          <ButtonCustom onClick={() => this.handleCloseModal()} text="ยกเลิก" type="danger" />
        </div>
      </div>,
    });

    this.handleRenderContentModalCustomer();
  }

  handleRenderContentModalCustomer() {
    let {
      soDetail,
      submitViewErrorCustomer
    } = this.state;

    if (!soDetail || isEmpty(soDetail.salesSOPartnerFunctionModelList)) {
      return;
    }


    const customerSoldTo = soDetail.salesSOPartnerFunctionModelList.filter(partner => partner.partnerType === "sold_to");
    if (!customerSoldTo || customerSoldTo.length === 0) {
      message.error("ไม่พบข้อมูลลูกค้า!");
      return;
    }
    const soldTo = first(customerSoldTo);

    let initialValues =
      soldTo
        ?
        {
          ...soldTo,
          customerTitle: soldTo.title,
          customerNameTH: soldTo.customerName,
          customerType: soldTo.customerType,
          customerTaxId: soldTo.taxId,
          customerTaxBranch: soldTo.taxBranch,
          customerAddress: soldTo.address,
          customerOutletId: soldTo.outletId,
          customerProvince: soldTo.province,
          customerDistrict: soldTo.district,
          customerSubDistrict: soldTo.subDistrict,
          customerPostalCode: soldTo.postalCode,
          customerCountry: soldTo.country,
          customerPhone: soldTo.phone,
        }
        :
        {}
    //console.log("initialValues==>", initialValues);

    this.setState({
      renderContentModal:
        <CustomerDetailFormRedux
          initialValues={initialValues}
          submitViewErrorCustomer={submitViewErrorCustomer}
        />,
    });
  }

  handleClickSaveCustomer() {
    const { CustomerReduxForm: { values, syncErrors } } = this.props;
    let { soDetail } = this.state;
    //console.log('values==>', values);
    //console.log('syncErrors==>', syncErrors);
    //console.log('soDetail==>', soDetail);

    if (typeof (syncErrors) === 'object') {
      message.error("กรุณากรอกข้อมูลให้ครบถ้วน")
      this.setState({
        submitViewErrorCustomer: true
      }, () => this.handleClickModal("customer"))
    }

    if (soDetail && soDetail.salesSOPartnerFunctionModelList && soDetail.salesSOPartnerFunctionModelList.length > 0) {
      soDetail.salesSOPartnerFunctionModelList.forEach(partner => {
        // if (partner.partnerType === "sold_to") {
        partner.title = values.customerTitle;
        partner.customerName = values.customerNameTH;
        partner.customerType = values.customerType;
        partner.taxId = values.customerTaxId;
        partner.taxBranch = values.customerTaxBranch;
        partner.address = values.customerAddress;
        partner.outletId = values.customerOutletId;
        partner.province = values.customerProvince;
        partner.district = values.customerDistrict;
        partner.subDistrict = values.customerSubDistrict;
        partner.postalCode = values.customerPostalCode;
        partner.country = values.customerCountry;
        partner.phone = values.customerPhone;
        //console.log('partner sold_to==>', partner);
        // }
      });
    }
    this.setState({ soDetail, isUpdateSO: true, isShowModal: false, });
  }

  handleChangeSearchCustomerId = (val) => {
    this.setState({ customerId: val });
  }

  handleClickSearchCustomer = () => {
    this.setState({ isShowSearchCustomerModal: true });
  }

  onSearchCustomer = async (typeKeyword, keyword) => {
    //console.log(`typeKeyword ==>${typeKeyword}, keyword==>${keyword}`);
    this.setState({ loading: true });
    const url = `${API_PATH.GET_CUSTOMER}?${typeKeyword}=${keyword}&RequireSoldTo=true`
    await apiServices.callApi('get', url).then(res => {
      const resCustomers = get(res, 'data.results')
      if (resCustomers) {
        this.setState({ customers: resCustomers })
      }
    }).catch(error => {
      const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
      swal('Error', message, 'error')
      console.error(get(error, 'response.data.errors'))
    })
    this.setState({ loading: false })
  }

  onSelectedCustomer = (data) => {
    let { billingDetail, searchCustomerId, soDetail, customerSoldToFullAddress } = this.state;

    soDetail = {
      ...soDetail,
      customerId: data.customerId,
      customerName: data.customerNameTH,
    }

    this.setState({ isShowModal: false });
    //console.log(`soDetail ==>`, soDetail);
    //console.log(`Customer selected`, data);
    if (soDetail && soDetail.salesSOPartnerFunctionModelList && soDetail.salesSOPartnerFunctionModelList.length > 0) {
      soDetail.salesSOPartnerFunctionModelList.forEach(partner => {
        // if (partner.partnerType === "sold_to") {
        partner.customerId = data.customerId;
        partner.title = data.customerTitle;
        partner.customerName = data.customerNameTH;
        partner.customerType = data.customerType;
        partner.taxId = data.customerTaxId;
        partner.taxBranch = data.customerTaxBranch;
        partner.address = data.customerAddress;
        partner.outletId = data.customerOutletId;
        partner.province = data.customerProvince;
        partner.district = data.customerDistrict;
        partner.subDistrict = data.customerSubDistrict;
        partner.postalCode = data.customerPostalCode;
        partner.country = data.customerCountry;
        partner.phone = data.customerPhone;
        //console.log('partner sold_to==>', partner);
        customerSoldToFullAddress = `${partner.customerName ? `${partner.customerName}/` : ''}${partner.subDistrict ? `${partner.subDistrict}/` : ''}${partner.district ? `${partner.district}/` : ''}${partner.province ? `${partner.province}/` : ''}${partner.postalCode ? `${partner.postalCode}` : ''}`;
        this.setState({
          dataCustomerSoldTo: partner,
          customerSoldToFullAddress,
          customerId: data.customerId,
          customerName: data.customerNameTH,
        });
        // }
      });
    }

    billingDetail = {
      ...billingDetail,
      customerId: data.customerId,
      customerName: data.customerNameTH,
    }
    searchCustomerId = data.customerId;

    this.setState({ billingDetail, searchCustomerId, isUpdateSO: true, soDetail, isShowModal: false });

    this.handleCloseModal();
  }
  handleChangeOutletId = (e) => {
    const { masterOutlets } = this.state
    const masterOutlet = masterOutlets.find((item, indexItem) => e === item.outletId)
    this.setState({ valueOutletId: e, valueOutletRole: masterOutlet.outletRole })
  }
  async searchSCGID(SCGId) {
    this.setState({ loading: true })
    let { scgId, valueOutletId } = this.state
    let couponOption = []
    try {
      const value = SCGId || scgId
      if (!isEmpty(value)) {
        const resultSCGID = await validationSCGID(value)
        scgId = resultSCGID.member.scgid
        couponOption = await this.getSCGIDCoupon(valueOutletId, scgId)
        console.log('couponOption', couponOption);
        swal("Success", `ดึงข้อมูล SCG Family ID สำเร็จ`, "success");
      } else {
        scgId = ''
      }
    } catch (error) {
      swal('แจ้งเตือน SCG Family ID', error, 'warning')
      scgId = ''
    }
    this.setState({ scgId: scgId, SCGIDCoupons: couponOption, loading: false })
  }
  handleSCGIDChang = (val) => {
    this.setState({ scgId: val.target.value });
  }
  async getSCGIDCoupon(outletId, scgId) {
    let coupons = []
    await apiServices.callApi('get', `${API_PATH.GET_COUPON_SCG_ID}?outlet=${outletId}`, null, { headersOption: { scgid: scgId } }).then(res => {
      coupons = res.data.result.lists || []
      coupons = coupons.map(data => (
        { ...data, label: `${data.coupon_code} - ${data.discount}`, value: data.coupon_code }
      ))
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
      const status = get(error, 'response.data.status')
      //console.log(`SCG Family ID error.response==> Status:${status}, Message:${message}`)
      //const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
      //const status = get(error, 'response.data.status')
      /*if(!isEmpty(status) && status === "Warning"){
        swal('Warning', `SCGID:${message}`, 'warning')
      }else{
        swal('Error', `SCGID:${message}`, 'error')
      }*/
    })
    return coupons
  }


  render() {
    const {
      loading,
      disabledSoNumber,
      disabledTaxType,
      isShowSearchCustomerModal,
      isShowModal,
      titleModal,
      widthModal,
      heightModal,
      footerModal,
      renderContentModal,
      depositType,
      billingType,
      taxType,
      autoGI,
      soNumber,
      totalAmount,
      customerId,
      customerName,
      totalPaid,
      documentDate,
      totalBalance,
      scgId,
      btnSaveDisabled,

      cash,
      credit,
      coupon,
      promotion,
      transfer,
      witholdingtax,
      cardfee,
      transferfee,
      totalcashreceive,
      diffcash,
      totalDepositAmount,
      percentDepositAmount,
      nDeposit,
      disabledBillingType,

      outletOptions,
      valueOutletId,
      permissionPage,
    } = this.state
    return (
      <div className="payment-content">
        <Spin spinning={loading} style={{ height: '100%' }}>
          <TitleHead text="รับชำระเงิน (Cash Receive)" icon="credit-card" />
          <Row gutter={10}>
            <Col md={12}>
              <Row gutter={10}>
                <Col md={12}>
                  <RadioCustom
                    small
                    onChange={(e) => this.handleBillingTypeChange(e.target.value)}
                    options={billingTypeOptions}
                    value={billingType}
                    name="billingType"
                    disabled={disabledBillingType}
                  />
                </Col>
                <Col md={12}>
                  <CheckBoxCustom
                    text="สร้างรายการตัดจ่ายสินค้า(GI)"
                    checked={autoGI}
                    onChange={this.handleAutoGIChang}
                    name="autoGI"
                  />
                </Col>
              </Row>
              <Row gutter={10}>
                <Col md={24}>
                  <RadioCustom
                    small
                    onChange={this.handleTaxTypeChang}
                    options={taxTypeOptions}
                    value={taxType}
                    name="taxType"
                    disabled={disabledTaxType} />
                </Col>
              </Row>
              <Row gutter={10}>
                <Col md={12}>
                  <InputHero
                    onChange={this.handleSONumberChang}
                    onPressEnter={(e) => this.handleSONumberEnter(e.target.value)}
                    name="soNumber"
                    label="เลขที่ใบสั่งขาย*"
                    value={soNumber}
                    disabled={disabledSoNumber}
                    small
                  />
                </Col>
                <Col md={12}>
                  <SelectHero
                    small
                    options={outletOptions}
                    pleaseChoose
                    onChange={this.handleChangeOutletId}
                    value={valueOutletId}
                    name="outletId"
                    label="ร้าน*"
                    disabled={disabledSoNumber}
                  />
                </Col>
              </Row>
              <Row gutter={10}>
                <Col md={12}>
                  <InputSearchCustom
                    small
                    name="customerId"
                    label="ข้อมูลลูกค้า"
                    placeholder="ข้อมูลลูกค้า"
                    onClickSearch={this.handleClickSearchCustomer}
                    onChange={(e) => this.handleChangeSearchCustomerId(e.target.value)}
                    value={customerId}
                    disabled
                    disabledButton={!disabledSoNumber}
                  />
                </Col>
                <Col md={12}>
                  <InputHero
                    name="documentDate"
                    label="วันที่ออกเอกสาร"
                    value={moment(documentDate).format("DD/MM/YYYY")}
                    disabled={true}
                    small
                  />
                </Col>
              </Row>
              <Row gutter={10}>
                <Col offset={4} md={8}>
                  {disabledSoNumber === true ?
                    <span className="click-detail-customer" onClick={(e) => this.handleSubmitShowModalCustomer(e)}>
                      {
                        !isEmpty(customerName) ?
                          <div className="customsize">{customerName}</div>
                          :
                          <div className="customsize">ลูกค้าทั่วไป</div>
                      }
                    </span>
                    : <div className="customsize">ลูกค้าทั่วไป</div>
                  }
                </Col>
                <Col md={12}>
                  <InputHero
                    name="totalAmount"
                    label="ยอดทั้งสิ้น"
                    value={totalAmount}
                    disabled={true}
                    small
                  />
                </Col>
              </Row>
              <Row gutter={10}>
                <Col md={12}>
                  <InputHero
                    onChange={this.handleSCGIDChang}
                    onBlur={(e) => this.searchSCGID(e.target.value)}
                    name="scgId"
                    label="SCG Family ID"
                    value={scgId}
                    small
                  />
                </Col>
                <Col md={12}>
                  <InputHero
                    name="totalPaid"
                    label="ยอดที่จ่ายแล้ว"
                    value={totalPaid}
                    disabled={true}
                    small
                  />
                </Col>
              </Row>
              <Row gutter={10}>
                <Col offset={12} md={12}>
                  <InputHero
                    name="totalBalance"
                    label="ยอดคงเหลือ"
                    value={parseFloat(totalBalance).toFixed(2)}
                    disabled={true}
                    small
                  />
                </Col>
                <Col md={12}>
                  <SelectCustom
                    small
                    options={depositOptions}
                    placeholderDefault={true}
                    onChange={(val) => this.handleChangeDepositType(val)}
                    value={depositType}
                    name="depositType"
                    label="งวดการชำระเงิน"
                    disabled={billingType !== 'ZDEP' || disabledBillingType}
                  />
                </Col>
                <Col md={8}>
                  <InputHero
                    label={`ยอดชำระงวดนี้(${nDeposit})`}
                    name="percentDepositAmount"
                    value={percentDepositAmount}
                    disabled={true}
                    small
                  />
                </Col>
                <Col md={4}>
                  <InputHero
                    name="totalDepositAmount"
                    value={totalDepositAmount}
                    disabled={true}
                    small
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <Card>
                <Row >
                  <Col md={12}>
                    <NumberFormatCustom
                      label="ยอดเงินสด"
                      value={cash}
                      displayType={'text'}
                    />
                  </Col>
                  <Col md={12}>
                    <NumberFormatCustom
                      label="ค่าธรรมเนียมบัตร"
                      value={cardfee}
                      displayType={'text'}
                    />
                  </Col>

                  <Col md={12}>
                    <NumberFormatCustom
                      label="ยอดเงินบัตรเครดิต"
                      value={credit}
                      displayType={'text'}
                    />
                  </Col>
                  <Col md={12}>
                    <NumberFormatCustom
                      label="ค่าธรรมเนียมโอน"
                      value={transferfee}
                      displayType={'text'}
                    />
                  </Col>

                  <Col md={12}>
                    <NumberFormatCustom
                      label="คูปอง/แลกคะแนน"
                      value={coupon}
                      displayType={'text'}
                    />
                  </Col>
                  <Col md={12}>
                    <NumberFormatCustom
                      label="รวมเงินรับชำระ"
                      value={totalcashreceive}
                      displayType={'text'}
                    />
                  </Col>

                  <Col md={12}>
                    <NumberFormatCustom
                      label="โปรโมชั่นผู้ผลิต"
                      value={promotion}
                      displayType={'text'}
                    />
                  </Col>
                  <Col md={12}>
                    <NumberFormatCustom
                      label="ผลต่าง"
                      value={parseFloat(diffcash).toFixed(2)}
                      displayType={'text'}
                    />
                  </Col>

                  <Col md={12}>
                    <NumberFormatCustom
                      label="โอนเงิน"
                      value={transfer}
                      displayType={'text'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <NumberFormatCustom
                      label="ภาษีหัก ณ ที่จ่าย"
                      value={witholdingtax}
                      displayType={'text'}
                    />
                  </Col>
                </Row>
              </Card>

            </Col>
          </Row>

          <div className="table-layout">
            <TableCustom scroll={{ x: "max-content" }} columns={this.state.columns ? this.state.columns : this.state.defaultColumns} data={this.state.dataPaymentList} pagination={false} />
          </div>

          <div className="page-footer-button">
            <Link to={ROUTH_PATH.CUSTOMER}>
              <ButtonCustom text="ยกเลิก" icon="close" type="danger" />
            </Link>
            <div>
              <ButtonCustom onClick={() => this.handleClickSaveBilling()} disabled={btnSaveDisabled || !permissionPage.authCreate} text="บันทึก" icon="save" />
            </div>
          </div>
        </Spin>

        <ModalCustom
          isShowModal={isShowModal}
          handleCloseModal={this.handleCloseModal}
          title={titleModal}
          width={widthModal}
          height={heightModal}
          footer={footerModal}
        >
          {renderContentModal}
        </ModalCustom>

        <CustomerSearchModal
          isShowModal={isShowSearchCustomerModal}
          handleCloseModal={this.handleCloseModal}
          onSelected={this.onSelectedCustomer}
          outletId={valueOutletId}
        />

      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    PaymentReduxForm: state.form.PaymentForm,
    CustomerReduxForm: state.form.CustomerDetailFormRedux
  };
};

/*const mapDispatchToProps = (dispatch) => ({
});*/

const mapDispatchToProps = (dispatch) => ({
  resetPaymentForm: () => dispatch(reset('PaymentForm')),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);