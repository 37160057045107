import React, { Component } from 'react'
import { Input } from 'antd';
import { InputFormReduxStyled } from "./styled"
import './style.css'

export class InputFormRedux extends Component {
    render() {
        const {
            type,
            onChangeHero,
            placeholder,
            autoFocus,
            name,
            disabled,
            BorderRadius,
            label,
            input,
            password,
            submitViewError,
            meta: { touched, error },
            right,
            small,
            widthSmall,
            widthDefault,
            placeholderDefault,
            onPressEnter,
            labelColor,
            maxLength,
            widthAll,
        } = this.props
        return (
            <InputFormReduxStyled
                error={(touched || submitViewError) && error ? true : false}
                small={small}
                widthSmall={widthSmall}
                widthDefault={widthDefault}
            >
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div
                                className="label-style"
                                style={{
                                    textAlign: right ? 'right' : '', color: labelColor && labelColor
                                }}
                            >
                                {label}
                            </div>
                        }
                        {
                            password ?
                                <React.Fragment>
                                    <Input.Password
                                        name={name}
                                        placeholder={placeholder}
                                        onChange={e => {
                                            input.onChange(e)
                                            onChangeHero && onChangeHero(e)
                                        }}
                                        {...input}
                                        maxLength={maxLength}
                                        style={{
                                            borderRadius: BorderRadius ? BorderRadius : 4,
                                            width: label
                                                ?
                                                small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                                :
                                                ''
                                            ,
                                            borderColor:
                                                (touched || submitViewError) &&
                                                    error ? "red" : ''

                                        }}
                                        disabled={disabled}
                                        className={(touched || submitViewError) && "ant-input-affix-wrapper ant-input-password"}
                                    />

                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Input
                                        className="input-style"
                                        size={small ? "small" : 'default'}
                                        name={name}
                                        type={type}
                                        onChange={e => {
                                            input.onChange(e)
                                            onChangeHero && onChangeHero(e)
                                        }}
                                        {...input}
                                        placeholder={placeholder ? placeholder : placeholderDefault === true ? label : undefined}
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        onPressEnter={onPressEnter}
                                        style={{
                                            borderRadius: BorderRadius ? BorderRadius : 4,
                                            width: label
                                                ?
                                                small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                                :
                                                widthAll ? widthAll : ''
                                            ,
                                            borderColor:
                                                (touched || submitViewError) &&
                                                    error ? "red" : ''
                                        }}
                                        maxLength={maxLength}
                                    />
                                </React.Fragment>
                        }
                    </div>
                    {
                        (touched || submitViewError) ?
                            <div className="error-message-bottom"> {error}  </div>
                            : ''
                    }

                </div>

            </InputFormReduxStyled>
        )
    }
}
