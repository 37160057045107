export const ROUTH_PATH = {
    HOME: '/',
    HOME2: '/franchise',
    ACCESS_DENIED: '/franchise/403',
    NOTFOUND: '/franchise/404',
    PROFILE: '/franchise/profile',
    TEST1: '/franchise/test1',
    TEST2: '/franchise/test2',
    DISPLAYUSER: '/franchise/user',
    ADDUSER: '/franchise/user/create',
    EDITUSER: '/franchise/user/edit/:userId',
    EDITUSER_LINK: '/franchise/user/edit',
    CHANGE_PASSWORD: '/franchise/user/change-password',
    CHANGE_PASSWORD_SEFT: '/franchise/user/change-password-seft',
    // SALE_ORDER: '/franchise/saleorder',
    // SALE_ORDER_UPDATE: '/franchise/saleorder/edit/:soNumber/:outletId',
    // SALE_ORDER_UPDATE_LINK: '/franchise/saleorder/edit',
    SALE_ORDER_CREATE: '/franchise/saleorder/create',
    SALE_ORDER_EDIT: '/franchise/saleorder/edit/:soNumber/:outletId',
    SALE_ORDER_EDIT_LINK: '/franchise/saleorder/edit',
    CUSTOMER: '/franchise/customer/search',
    CUSTOMER_CREATE: '/franchise/customer/create',
    CUSTOMER_UPDATE: '/franchise/customer/edit/:customerid/:outletid',
    SALEEMPLOYEE: '/franchise/saleemployee/search',
    SALEEMPLOYEE_CREATE: '/franchise/saleemployee/create',
    SALEEMPLOYEE_UPDATE: '/franchise/saleemployee/edit/:id/:outletid',
    PRODUCT: '/franchise/product',
    PRODUCT_BARCODE: '/franchise/product/barcode',
    INVENTORY_COUNT_CREATE: '/franchise/inventory-count/create',
    INVENTORY_COUNT_EDIT_LINK: '/franchise/inventory-count/edit',
    INVENTORY_COUNT_EDIT: '/franchise/inventory-count/edit/:id',
    INVENTORY_COUNT_SEARCH: '/franchise/inventory-count/search',
    INVENTORY_COUNT_RESULT: '/franchise/inventory-count/result',
    INVENTORY_COUNT_MAINTAIN: '/franchise/inventory-count/maintain',
    PAYMENT: '/franchise/payment',
    PAYMENT_LINK: '/franchise/payment/:soNumber/:outletId',
    PAYMENT_FULL: '/franchise/paymentfull',
    TRACKING_SALE_ORDER: '/franchise/tracking-sale-order',
    PROMOTION: '/franchise/promotion',
    PROMOTION_MAINTAIN: '/franchise/promotion/maintain',
    PROMOTION_MAINTAIN_EDIT: '/franchise/promotion/maintain/edit/:code',
    PROMOTION_MAINTAIN_EDIT_LINK: '/franchise/promotion/maintain/edit',
    PROMOTION_MAINTAIN_REF: '/franchise/promotion/maintain/ref/:code',
    PROMOTION_MAINTAIN_REF_LINK: '/franchise/promotion/maintain/ref',
    PROMOTION_QUALIFIER_SEARCH: '/franchise/qualifier/search',
    PROMOTION_QUALIFIER_CREATE: '/franchise/qualifier/create',
    PROMOTION_QUALIFIER_EDIT: '/franchise/qualifier/edit/:id',
    PROMOTION_NET_PRICE: '/franchise/promotion/net-price/',
    PROMOTION_NET_PRICE_EDIT: '/franchise/promotion/net-price/edit/:code',
    PROMOTION_NET_PRICE_EDIT_LINK: '/franchise/promotion/net-price/edit',
    PROMOTION_NET_PRICE_REF: '/franchise/promotion/net-price/ref/:code',
    PROMOTION_NET_PRICE_REF_LINK: '/franchise/promotion/net-price/ref',
    QUOTATION: '/franchise/quotation/search',
    QUOTATION_CREATE: '/franchise/quotation/create',
    QUOTATION_UPDATE_LINK: '/franchise/quotation/edit',
    QUOTATION_UPDATE: '/franchise/quotation/edit/:quotationNumber/:outletId',
    ROLE: '/franchise/role',
    ROLE_CREATE: '/franchise/role/create',
    ROLE_EDIT: '/franchise/role/edit/:id',
    ROLE_EDIT_LINK: '/franchise/role/edit',
    REPORT_QUOTATION: '/franchise/report/quotation',
    REPORT_STOCK_MOVEMENT: '/franchise/report/stock-movement',
    REPORT_INVENTORY_BALANCE: '/franchise/report/stock-balance',
    REPORT_SHIPMENT: '/franchise/report/shipment',
    REPORT_TAX_DOCUMENT: '/franchise/report/tax-document',
    REPORT_SPECIAL_STOCK: '/franchise/report/special-stock',
    REPORT_CHECK_STOCK_PRICE: '/franchise/report/check-stock-price',
    REPORT_CHECK_POS: '/franchise/report/check-pos',
    REPORT_CN_DN: '/franchise/report/cn-dn',
    REPORT_COST_GP_PRODUCT: '/franchise/report/cost-gp-product',
    REPORT_PRODUCT_PERCENT_MARGIN: '/franchise/report/product-percent-margin',
    REPORT_POS_INTERFACE_LOG: '/franchise/report/pos-interface-log',
    REPORT_S4_INTERFACE_LOG: '/franchise/report/s4-interface-log',
    REPORT_SALES_LEGACY_SAP: '/franchise/report/sales-legacy-sap',
    REPORT_WEBMARGIN_CSV_BY_BILLING: '/franchise/report/webmargin-csv-by-Billing',
    REPORT_RECEIVE_STOCK_COMPARE_CHAMP: '/franchise/report/receive-stock-compare-champ',
    REPORT_PURCHASE: '/franchise/report/purchase',
    REPORT_INVENTORY_COUNT: '/franchise/report/inventory-count',
    // ReportResivestockCompareChampContainer

    PRODUCT_PERCENT_MARGIN_CREATE: '/franchise/product-percent-margin/create',
    PRODUCT_PERCENT_MARGIN_EDIT: '/franchise/product-percent-margin/edit',
    // PRODUCT_PERCENT_MARGIN_EDIT_LINK: '/franchise/product-percent-margin/edit',
    SHIPMENT_SEARCH: '/franchise/shipment',
    SHIPMENT_CREATE: '/franchise/shipment/create',
    SHIPMENT_EDIT: '/franchise/shipment/edit/:shipmentNumber/:outletId',
    SHIPMENT_EDIT_LINK: '/franchise/shipment/edit',
    GOODS_ISSUE: '/franchise/goods-issue',
    GOODS_RECEIVE: '/franchise/goods-receive',
    GOODS_RECEIVE_WITHOUT_REF: '/franchise/goods-receive-without-ref',
    GOODS_RETURN: '/franchise/goods-return',
    GOODS_TRANSFER: '/franchise/goods-transfer',
    PURCHASE_SEARCH: '/franchise/purchase/search',
    PURCHASE_CREATE: '/franchise/purchase/create',
    PURCHASE_CLEAR_BILLING: '/franchise/purchase/clear-billing',
    PURCHASE_CREATE_PO_SO: '/franchise/purchase/create-po-so',
    GOODS_ISSUE_STO: '/franchise/goods-issue-sto',
    GOODS_ISSUE_REF_RESERVATION: '/franchise/goods-issue-ref-reservation',
    RESERVATION_CREATE: '/franchise/reservation/create',
    RESERVATION_EDIT: '/franchise/reservation/edit/:id/:outletId',
    RESERVATION_EDIT_LINK: '/franchise/reservation/edit',
    CN_DN_CREATE: '/franchise/cn-dn/create',
    CN_DN_EDIT: '/franchise/cn-dn/edit/:cnNumber/:outletId',
    CN_DN_EDIT_LINK: '/franchise/cn-dn/edit',
    CN_DN_MANUAL_INTERFACE: '/franchise/cn-dn/manual-interface',
    MANUAL_RUN_INTERFACE2CHAMP: '/franchise/manual-run-interface2champ',
    MANUAL_INTERFACE_INCOMING_PAYMENT:'/franchise/manual-interface-incoming-payment',
}