import React, { Component } from 'react'
import { Modal } from "antd"
import { ButtonCustom } from "components"
import './style.css'

export class ModalCustom extends Component {
    render() {
        const {
            isShowModal,
            handleOpenModal,
            handleCloseModal,
            children,
            width,
            title,
            footer,
            handleClickSave,
            height,
            textSuccess,
            textCancel,
            className,
            destroyOnClose,
        } = this.props
        return (
            <Modal
                className={className}
                visible={isShowModal}
                onOk={handleOpenModal}
                onCancel={handleCloseModal}
                width={width}
                closable={true}
                destroyOnClose={destroyOnClose}
                footer={
                    footer ?
                        footer === true ?
                            <div className="modal-footer-button">
                                <div className="footer-button">
                                    <ButtonCustom onClick={handleClickSave} text={textSuccess ? textSuccess : "บันทึก"} />
                                </div>
                                <div className="footer-button">
                                    <ButtonCustom onClick={handleCloseModal} text={textCancel ? textCancel : "ยกเลิก"} type="danger" />
                                </div>
                            </div>
                            :
                            footer
                        :
                        false
                }
                bodyStyle={{ padding: 15, height: height, overflow: 'auto' }}
                title={title}
            >
                {children}
            </Modal>
        )
    }
}
