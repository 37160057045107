import { SET_REPORT_PRODUCT_SHARINGS, RESET_REPORT_PRODUCT_SHARINGS } from 'actions/actionsTypes'

const ReportProductSharingReducers = (state = '', action = {}) => {
    switch (action.type) {
        case SET_REPORT_PRODUCT_SHARINGS:
            return action.reports
        case RESET_REPORT_PRODUCT_SHARINGS:
            return []
        default:
            return state
    }
}

export default ReportProductSharingReducers