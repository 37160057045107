import React, { Component } from 'react'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    InputHero,
} from 'components'
import { Row, Col } from 'antd'
import './style.css'

export class ModalSaleEmployee extends Component {
    state = {
        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: '#',
                key: '#',
                render: (value, row) => <ButtonCustom small onClick={() => this.props.onSelected(row)} text="เลือก" />
            },
            {
                title: 'รหัสพนักงานขาย',
                align: 'center',
                dataIndex: 'saleEmployeeId',
                key: 'saleEmployeeId',
            },
            {
                title: 'ชื่อพนักงานขาย',
                align: 'center',
                dataIndex: 'saleEmployeeName',
                key: 'saleEmployeeName',
            },
            {
                title: 'เบอร์ติดต่อ',
                align: 'center',
                dataIndex: 'saleEmployeePhone',
                key: 'saleEmployeePhone',
            },
        ],
        keyword: '',
    }

    changeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    render() {
        let {
            isShowModal,
            handleCloseModal,
            title,
            width,
            loading,
            className,
            data,
        } = this.props
        const {
            columns,
            keyword,
        } = this.state

        if (keyword) {
            data = data.filter(row =>
                row.saleEmployeeName && row.saleEmployeeName.toLowerCase().includes(keyword.toLowerCase())
            )
        }

        return (
            <ModalCustom
                isShowModal={isShowModal}
                handleCloseModal={handleCloseModal}
                title={title || 'ค้นหาพนักงานขาย'}
                width={width || '60%'}
                className={`modal-data ${className}`}
            >
                <Row>
                    <Col sm={10}>
                        <InputHero
                            label="ชื่อพนักงานขาย"
                            small
                            value={keyword}
                            onChange={this.changeKeyword}
                        />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 511px)"
                            columns={columns}
                            data={data}
                            loading={loading}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
