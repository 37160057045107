import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom"
import { Icon, Input, message, Spin } from "antd"
import moment from 'moment'
import XLSX from 'xlsx'
import './style.css'
import { authData } from 'actions/actionsCreator';
import { TitleHead, TableCustom, FooterButton, ButtonCustom, CheckBoxCustom } from "components"
import { PromotionNetPriceForm } from "reduxForms"
import { ROUTH_PATH, functionAlias } from "routes"
import { apiServices, API_PATH } from "apiServices"
import swal from '@sweetalert/with-react';
import { isEmpty, get, first } from 'lodash';
import { getPermissionPage } from 'helpers';

class PromotionNetPriceContainer extends Component {
    state = {
        permissionPage: getPermissionPage(functionAlias.promotionMaintain),
        dataOutlet: [],
        dataChannal: [],
        dataPromotionStatus: [],
        checkedAll: '',
        selectedRowKeys: [],
        isErrorExcelFile: false,
        isDisabledSaveButton: true,
        isLoading: false,
        promotionHistories: [],
        dataNetPriceList: [
            {
                sku: '',
                unit: '',
                promotionPrice: '',
                active: true,
            },
        ],
        columns: [
            {
                title: 'ลบ',
                dataIndex: 'delete',
                key: 'delete',
                align: 'center',
                width: 70,
                render: (props, row, index) => this.state.dataNetPriceList.length - 1 !== index && <div className="delete-icon-row" onClick={() => this.deletePromotionItem(index)}>
                    <Icon type="delete" />
                </div>,
            },
            {
                title: 'Active',
                dataIndex: 'active',
                key: 'active',
                align: 'center',
                width: 100,
                render: (props, row, index) => < div >
                    <CheckBoxCustom checked={props} onChange={(e) => this.changePromotionItem('active', e.target.checked, index)} />
                </div >
            },
            {
                title: 'SKU',
                dataIndex: "sku",
                key: "sku",
                width: 200,
                render: (props, row, index) => <div>
                    <Input
                        value={props}
                        size="small"
                        onChange={(e) => this.changePromotionItem('sku', e.target.value, index)}
                        onPressEnter={() => this.addPromotionItem(row, index)}
                    />
                </div>,
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: "productName",
                key: "productName",
            },
            {
                title: 'หน่วย',
                dataIndex: "unit",
                key: "unit",
                render: (props, row, index) => <div>
                    <Input
                        value={props}
                        size="small"
                        onChange={(e) => this.changePromotionItem('unit', e.target.value, index)}
                        onPressEnter={() => this.addPromotionItem(row, index)}
                    />
                </div>,
                width: 130
            },
            {
                title: 'Promotion Price',
                dataIndex: "promotionPrice",
                key: "promotionPrice",
                render: (props, row, index) => <div>
                    <Input
                        value={props}
                        size="small"
                        onChange={(e) => this.changePromotionItem('promotionPrice', e.target.value, index)}
                        onPressEnter={() => this.addPromotionItem(row, index)}
                    />
                </div>,
                width: 130
            },
            {
                title: 'หมายเหตุ',
                dataIndex: "remarks",
                key: "remarks",
                render: (props) => <span style={{ color: 'red' }}>{props}</span>
            },
            // 
        ],
    }
    componentDidMount() {
        const { match } = this.props
        if (match.params.code) {
            if (match.path === ROUTH_PATH.PROMOTION_NET_PRICE_REF) {
                this.fetchDataRef(match.params.code)
            } else {
                this.fetchData(match.params.code)
            }

        } else {
            this.fetchDataDefaultDropdown()
        }
    }

    fetchDataDefaultDropdown = async () => {
        let resOutlet = await apiServices.callApi("post", API_PATH.GET_MASTER_OUTLET, {})
        let resChannel = await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL)
        let resPromotionStatus = await apiServices.callApi('get', API_PATH.GET_PROMOTION_STATUS)
        if (resOutlet.status === 200 && resChannel.status === 200) {
            let dataOutlet = resOutlet.data.results.map(data => (
                {
                    ...data,
                    text: `${data.outletId} - ${data.outletName}`,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`
                }
            ))
            let dataChannal = resChannel.data.results.map(data => (
                {
                    ...data,
                    text: `${data.saleChannelCode} - ${data.saleChannelDescription}`,
                    value: data.saleChannelCode,
                    label: `${data.saleChannelCode} - ${data.saleChannelDescription}`
                }
            ))
            let dataPromotionStatus = resPromotionStatus.data.results.map(data => ({
                ...data,
                value: data.code,
            }))
            this.setState({
                dataOutlet,
                dataChannal,
                dataPromotionStatus
            })
        }
        else {
            swal("error", "", "error")
        }
    }

    fetchDataRef = async (promotionCode) => {

        this.setState({ isLoading: true })
        this.fetchDataDefaultDropdown()
        await apiServices.callApi("get", `${API_PATH.GET_PROMOTION}?Code=${promotionCode}`).then(res => {
            if (res.status === 200 && res.data.status === 'Success') {
                let dateRange = []
                const result = first(res.data.results)
                if (isEmpty(result)) {
                    swal('Error', 'ไม่พบข้อมูล Promotion', 'error')
                    return
                }
                let newCopyCampaign = {
                    campaignName: result.promotionCampaign.campaignName,
                    channel: result.promotionCampaign.channel,
                    effectiveDate: result.promotionCampaign.effectiveDate,
                    expireDate: result.promotionCampaign.expireDate,
                    outletId: result.promotionCampaign.outletId,
                    status: result.promotionCampaign.status
                }
                delete result.promotionCampaign.code
                delete result.promotionCampaign.campaignId
                delete result.promotionCampaign.discountConditionId

                delete result.promotionDiscountCondition.discountConditionId
                result.promotionNetPrices.map(item => {
                    item.id = 0;
                    item.discountConditionId = 0
                    item.revision = null
                    return item
                })
                let effectiveDate = result.promotionCampaign.effectiveDate
                let expireDate = result.promotionCampaign.expireDate
                let channel = result.promotionDiscountCondition.channel.split(",").map(String)
                let outletId = result.promotionDiscountCondition.outletId.split(",").map(String)

                let dataNetprice = result.promotionNetPrices.map((data) => ({
                    ...data,
                    sku: data.productCode,
                    active: data.status
                }))
                dataNetprice.push({
                    sku: '',
                    unit: '',
                    promotionPrice: '',
                    active: true,
                })
                dateRange.push(moment(effectiveDate), moment(expireDate))
                this.setState({
                    initialValues: {
                        // ...result.promotionCampaign,
                        ...newCopyCampaign,
                        date: dateRange,
                        channel: channel,
                        outletId: outletId,
                    },
                    promotionCode: '',//promotionCode,
                    // promotionHistories,
                    promotionCampaign: newCopyCampaign,//result.promotionCampaign,
                    promotionDiscountCondition: {
                        channel,
                        outletId
                    },
                    // promotionDiscountCondition: result.promotionDiscountCondition,
                    dataNetPriceList: dataNetprice,
                })
            } else {
                swal("error", res.message, "error");
            }
        }).catch(error => {
            swal("error", error.response, "error");
        }).finally(() => { this.setState({ isLoading: false }) })
    }

    fetchData = async (promotionCode) => {
        this.setState({ isLoading: true })
        this.fetchDataDefaultDropdown()

        await apiServices.callApi("get", `${API_PATH.GET_PROMOTION}?Code=${promotionCode}`).then(res => {
            if (res.status === 200 && res.data.status === 'Success') {
                let dateRange = []
                const result = first(res.data.results)
                if (isEmpty(result)) {
                    swal('Error', 'ไม่พบข้อมูล Promotion', 'error')
                    return
                }


                let effectiveDate = result.promotionCampaign.effectiveDate
                let expireDate = result.promotionCampaign.expireDate
                let channel = result.promotionDiscountCondition.channel.split(",").map(String)
                let outletId = result.promotionDiscountCondition.outletId.split(",").map(String)
                let promotionHistories = result.promotionHistories
                let dataNetprice = result.promotionNetPrices.map((data) => ({
                    ...data,
                    sku: data.productCode,
                    active: data.status
                }))
                dataNetprice.push({
                    sku: '',
                    unit: '',
                    promotionPrice: '',
                    active: true,
                })
                dateRange.push(moment(effectiveDate), moment(expireDate))
                this.setState({
                    initialValues: {
                        ...result.promotionCampaign,
                        date: dateRange,
                        channel: channel,
                        outletId: outletId,
                    },
                    promotionCode: promotionCode,
                    promotionHistories,
                    promotionCampaign: result.promotionCampaign,
                    promotionDiscountCondition: result.promotionDiscountCondition,
                    dataNetPriceList: dataNetprice,
                })
            } else {
                swal("error", res.message, "error");
            }
        }).catch(error => {
            swal("error", error.response, "error");
        }).finally(() => { this.setState({ isLoading: false }) })
    }

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    handleChangeSku = (e, index) => {

    }
    handleChangeUnit = (e, index) => {

    }
    handleChangePromotionPrice = (e, index) => {

    }

    changePromotionItem = (key, value, index) => {
        this.setState({
            dataNetPriceList: this.state.dataNetPriceList.map((dataNetPrice, i) => (i === index ? { ...dataNetPrice, [key]: value } : dataNetPrice)),
        })
    }

    addPromotionItem = (row, index) => {
        const dataNetPriceList = [...this.state.dataNetPriceList]
        if (index === dataNetPriceList.length - 1 && row.sku && row.unit && row.promotionPrice) {
            dataNetPriceList.push({
                sku: '',
                unit: '',
                promotionPrice: '',
                active: true,
            })
            this.setState({ dataNetPriceList })
        }
    }

    deletePromotionItem = (index) => {
        const dataNetPriceList = [...this.state.dataNetPriceList]
        dataNetPriceList.splice(index, 1)
        this.setState({ dataNetPriceList })
    }

    // Excel function -----------------
    mapExcelToTable = (dataExcel) => {
        console.log("dataExcel=>", dataExcel);
        let newDataNetPriceList = []
        for (const index in dataExcel) {
            const row = dataExcel[index]
            if (+index === 0) continue
            if (isEmpty(row)) break
            const sku = row[0]
            const unit = row[1]
            const promotionPrice = row[2]
            newDataNetPriceList.push({
                sku,
                unit,
                promotionPrice,
            })
        }
        newDataNetPriceList.push({
            sku: '',
            unit: '',
            promotionPrice: '',
            active: true,
        })
        let dataList = newDataNetPriceList.map((data, i) => ({
            ...data,
            active: true,
        }))
        this.setState({
            dataNetPriceList: dataList,
            isErrorExcelFile: false
        })
    }
    readExcelFile = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
            try {
                let data = e.target.result
                data = new Uint8Array(data)
                const workbook = XLSX.read(data, { type: 'array' })
                const roa = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 })
                this.mapExcelToTable(roa)
            } catch (err) {
                swal('Error', 'กรุณาตรวจสอบความถูกต้องของไฟล์ Excel', 'error')
                console.error(err)
            }
        }
        reader.readAsArrayBuffer(file)
    }

    // create zone
    renderParams = () => {
        const { promotionNetPriceForm: { values }, auth } = this.props
        const { dataNetPriceList, promotionCode, promotionHistories, promotionCampaign, promotionDiscountCondition } = this.state
        let newDataNetPriceList = [...dataNetPriceList]
        const dataLast = newDataNetPriceList[newDataNetPriceList.length - 1]
        if (!dataLast.sku || !dataLast.unit || !dataLast.promotionPrice) {
            newDataNetPriceList.splice(newDataNetPriceList.length - 1, 1)
        }
        let newPromotionHistory = {
            id: 0,
            campaignId: !isEmpty(promotionHistories) ? promotionHistories[0].campaignId : 0,
            version: !isEmpty(promotionHistories) ? promotionHistories[promotionHistories.length - 1].version + 1 : 1,
            modifyDate: moment().format('YYYY-MM-DD'),//new Date(),
            modifyBy: auth.userId
        }
        let params = {
            creatable: !!!promotionCode,
            updatable: !!promotionCode,
            promotionCampaign: {
                ...promotionCampaign,
                campaignName: values.campaignName,
                effectiveDate: moment(values.date[0]).format('YYYY-MM-DD'),//new Date(values.date[0]),
                expireDate: moment(values.date[1]).format('YYYY-MM-DD'),//new Date(values.date[1]),
                outletId: values.outletId.join(),
                channel: values.channel.join(),
                createdBy: auth.userId,
                createdByFullName: auth.firstName + " " + auth.lastName,
                createdDate: moment().format('YYYY-MM-DD'),//new Date(),
                status: values.status
            },
            promotionDiscountCondition: {
                ...promotionDiscountCondition,
                outletId: values.outletId.join(),
                channel: values.channel.join(),
            },
            promotionNetPrices: newDataNetPriceList.map(data => ({
                ...data,
                status: data.active,
                productCode: data.sku,
                productName: data.productName,
                unit: data.unit,
                promotionPrice: data.promotionPrice,
                remarks: data.remarks,
                promotionCode: promotionCode,
            })),
            promotionHistory: newPromotionHistory,
        }
        return params
    }

    handleCheckMessageInLine = () => {
        const { dataNetPriceList } = this.state;
        if (dataNetPriceList.filter(sku => sku.sku !== '').length > 0) {
            swal('error', 'ราคาผิดพลาด', 'error')
            return true
        } else {
            return false
        }
    }

    handleClickCreateNetPrice = async () => {
        // if (this.handleCheckMessageInLine()) {
        //     return
        // }
        let params = this.renderParams()
        // console.log("paramsCreate==>", params);
        // console.log("paramsCreateJSON==>", JSON.stringify(params));
        this.setState({ isLoading: true })
        await apiServices.callApi('post', API_PATH.POST_PROMOTION, params).then(res => {
            console.log("create net price===>", res);
            if (res.status === 200 && res.data.status === 'Success') {
                let promotionCode = get(res, 'data.results.0.promotionCampaign.code')
                swal('success', `${res.data.message}: ${promotionCode}`, 'success')
                // this.fetchData(promotionCode)
                this.props.history.push(ROUTH_PATH.PROMOTION_NET_PRICE_EDIT_LINK + '/' + promotionCode)
            } else {
                swal('error', res.data.message, 'error')
            }
        }).catch(error => {
            swal('error', error.response, 'error')
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    updatePromotion = async () => {
        // if (this.handleCheckMessageInLine()) {
        //     return
        // }
        const params = [this.renderParams()]
        this.setState({ isLoading: true })
        await apiServices.callApi('put', API_PATH.PUT_PROMOTION, params).then(async res => {
            if (res.status === 200 && res.data.status === 'Success') {
                swal('success', res.data.message, 'success')
            } else {
                swal('error', res.data.message, 'error')
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล!')
            swal('Error', message, 'error');
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        });
    }

    handleClickValidateCheckNetPrice = async () => {
        const { promotionNetPriceForm: { syncErrors } } = this.props
        const { isErrorExcelFile } = this.state
        if (typeof (syncErrors) === 'object' || isErrorExcelFile) {
            message.error("กรุณากรอกข้อมูลให้ครบถ้วน")
            this.setState({
                submitViewError: true
            })
        } else {
            let params = this.renderParams()
            //console.log("paramsValidate==>", params);
            //console.log("paramsValidateJSON==>", JSON.stringify(params));
            this.setState({ isLoading: true })
            await apiServices.callApi('post', API_PATH.POST_VALIDATE_PROMOTION_NET_PRICE, params).then(res => {
                //console.log("validate net price===>", res);
                if (res.status === 200 && res.data.status === 'Success') {
                    this.setState({
                        isDisabledSaveButton: false,
                    })
                    swal('success', 'ตรวจสอบสำเร็จ', 'success')
                } else {
                    this.setState({
                        isDisabledSaveButton: true,
                    })
                    swal('error', res.data.message, 'error')
                }
                let dataTable = res.data.result.promotionNetPrices.map(data => ({
                    ...data,
                    active: true,
                    sku: data.productCode
                }))
                dataTable.push({
                    sku: '',
                    unit: '',
                    promotionPrice: '',
                    active: true,
                })
                this.setState({
                    dataNetPriceList: dataTable
                })
            }).catch(error => {
                swal('error', error.response, 'error')
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    render() {
        const {
            columns,
            dataOutlet,
            dataChannal,
            dataNetPriceList,
            dataPromotionStatus,
            submitViewError,
            isErrorExcelFile,
            isDisabledSaveButton,
            isLoading,
            initialValues,
            promotionCode,
            permissionPage,
        } = this.state;
        // console.log('this.state;',this.state);

        return (
            <div className="promotion-net-price">
                <Spin spinning={isLoading} style={{ height: '100%' }}>
                    <TitleHead text="การส่งเสริมการขาย ( Promotion Price ) " icon="gift" />
                    <PromotionNetPriceForm
                        dataOutlet={dataOutlet}
                        dataChannal={dataChannal}
                        readExcelFile={this.readExcelFile}
                        dataPromotionStatus={dataPromotionStatus}
                        submitViewError={submitViewError}
                        isErrorExcelFile={isErrorExcelFile}
                        initialValues={initialValues}
                        promotionCode={promotionCode}
                    />
                    <div className="table-layout">
                        <TableCustom
                            columns={columns}
                            small
                            height="calc(100vh - 510px)"
                            data={dataNetPriceList}
                            rowKey={(props, index) => index}
                            pagination={false}
                        />
                    </div>
                    <FooterButton
                        nodeRight={
                            [
                                {
                                    button: <ButtonCustom
                                        icon="save"
                                        blue
                                        text="บันทึก"
                                        onClick={promotionCode ? this.updatePromotion : this.handleClickCreateNetPrice}
                                        disabled={isDisabledSaveButton || (promotionCode ? !permissionPage.authUpdate : !permissionPage.authCreate)
                                        }
                                    />
                                },
                                {
                                    button: <ButtonCustom
                                        icon="check-circle"
                                        yellow
                                        text="ตรวจสอบ"
                                        onClick={this.handleClickValidateCheckNetPrice}
                                    />
                                },
                                {
                                    button: <Link to={ROUTH_PATH.PROMOTION}>
                                        <ButtonCustom
                                            icon="arrow-right"
                                            type="danger"
                                            text="ยกเลิก"
                                        />
                                    </Link>
                                },
                            ]
                        }
                    />
                </Spin>
            </div>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth,
        promotionNetPriceForm: state.form.PromotionNetPriceForm,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionNetPriceContainer);