import React, { Component } from 'react'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    SelectHero,
} from 'components'
import { ProductCategoryKeyWordDropDown, sortDataColumn } from 'helpers'
import { Row, Col, Input } from 'antd'
import './style.css'
import { apiServices, API_PATH } from 'apiServices'
import { get, isEmpty } from 'lodash'

const { Search } = Input

export class ModalProductCategorySearch extends Component {
    state = {
        thisloading: false,
        dataTableProductCategory:[],
        pagination:{},

        columns: [
            {
                title: "#",
                align: 'center',
                dataIndex: '',
                key: 'htf',
                render: (props, full) => <div style={{ textAlign: 'center' }}>
                    <ButtonCustom onClick={() => this.props.onSelected(full)} small text="เลือก" />
                </div>
            },
            {
                title: "รหัสกลุ่มสินค้า",
                align: 'center',
                dataIndex: 'categoryCode',
                key: 'categoryCode',
                sorter: (a, b) => sortDataColumn(a, b, 'categoryCode'),
                ellipsis: true,
            },
            {
                title: 'ชื่อกลุ่มสินค้า',
                //align: 'center',
                dataIndex: 'descriptionThai',
                key: 'descriptionThai',
                sorter: (a, b) => sortDataColumn(a, b, 'descriptionThai'),
                ellipsis: true,
            },
        ],
        keyword: '',
        keywordType: ProductCategoryKeyWordDropDown[1].value,
    }

    changeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    changeKeywordType = (keywordType) => {
        this.setState({ keywordType })
    }

    onSearchProductCategory = async (typeKeyword, keyword) => {
        this.setState({ thisloading: true });

        const {keywordType} = this.state;
        console.log("keywordType==>", keywordType);
        console.log("keyword==>", keyword);

        let paramGet = keywordType === 'Description' ? `?DescriptionThai=${keyword}&DescriptionEng=${keyword}` : `?CategoryCode=${keyword}`;
        let urlProdutCategory = `${API_PATH.GET_MASTER_PRODUCT_CATEGORY}/${paramGet}`;
        console.log("urlProdutCategory==>", urlProdutCategory);
        let res = await apiServices.callApi("get", urlProdutCategory, '');
        if (res.status === 200 && res.data.results.length > 0) {
            let productCategories = get(res, 'data.results');
            console.log("productCategories==>", productCategories);
            this.setState({
                dataTableProductCategory: productCategories,
            })
        }
        else {
            this.setState({ dataTableProductCategory: null })
        }

        this.setState({ thisloading: false });
    }
    render() {
        const {
            isShowModal,
            handleCloseModal,
            title,
            width,
            onSearch,
            data,
            loading,
            className,
            pagination,
        } = this.props
        const {
            columns,
            keyword,
            keywordType,
            thisloading,
            dataTableProductCategory,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                handleCloseModal={handleCloseModal}
                title={title || 'ค้นหาสินค้า'}
                width={width || '60%'}
                className={`modal-data ${className}`}
            >
                <Row gutter={8}>
                    <Col sm={8}>
                        <SelectHero
                            label="ค้นหาด้วย"
                            name='keywordType'
                            small
                            firstChoose
                            widthDefault='100px'
                            onChange={this.changeKeywordType}
                            options={ProductCategoryKeyWordDropDown}
                            value={keywordType}
                        />
                    </Col>
                    <Col sm={6}>
                        <Search
                            size="small"
                            name="keyword"
                            onSearch={this.onSearchProductCategory.bind(this, keyword)}/*{onSearch.bind(this, keyword)}*/
                            enterButton />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 511px)"
                            columns={columns}
                            data={dataTableProductCategory}
                            loading={loading || thisloading}
                            small
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
