import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom"
import { Row, Col, Collapse, Icon } from "antd"
import swal from '@sweetalert/with-react';
import './style.css'
import {
    TitleHead,
    MultiSelectedCustom,
    InputHero,
    RangePickerCustom,
    ButtonCustom,
    TableCustom,
    CheckBoxCustom
} from "components"
import { PromotionStatusOptions, formatDate, getPermissionPage, sortDataColumn, promotionPattern } from "helpers"
import { authData } from 'actions/actionsCreator';
import { apiServices, API_PATH } from "apiServices"
import { get, isEmpty } from 'lodash'
import { ROUTH_PATH, functionAlias } from 'routes'
import moment from 'moment'
import FileSaver from 'file-saver'
const { Panel } = Collapse;


class PromotionContainer extends Component {
    state = {
        permissionPage: getPermissionPage(functionAlias.promotionMaintain),
        dataTablePromotion: [],
        ConditionAdditionOptions: [],
        filter: {
            PromotionStatus: '',
            CreateDate: '',
            ActiveDate: '',
            PromotionCode: '',
            OutletIds: '',
            ConditionAdditions: '',
        },
        columns: [
            {
                title: "อนุมัติ",
                dataIndex: 'approve',
                key: 'approve',
                render: (props, row) => <div style={{ textAlign: 'center' }}>
                    {row.status !== "Approve" && <CheckBoxCustom
                        name="approve"
                        onChange={(e) => this.handleChangeCheckApprove(e, row.index)}
                        checked={props}
                        disabled={!this.state.permissionPage.authApprove}
                    />}
                </div>
            },
            {
                title: "ยกเลิกอนุมัติ",
                dataIndex: 'unApprove',
                key: 'unApprove',
                sorter: (a, b) => sortDataColumn(a, b, 'unApprove'),
                ellipsis: true,
                render: (props, row) => <div style={{ textAlign: 'center' }}>
                    {
                        row.status !== "Reject" && <CheckBoxCustom
                            name="unApprove"
                            onChange={(e) => this.handleChangeCheckApprove(e, row.index)}
                            checked={props}
                            disabled={!this.state.permissionPage.authDelete}
                        />
                    }

                </div>
            },
            {
                title: 'รหัสโปรโมชั่น',
                dataIndex: 'code',
                key: 'code',
                sorter: (a, b) => sortDataColumn(a, b, 'code'),
                ellipsis: true,
                render: (props, row) => row.isNetPrice ? <Link to={ROUTH_PATH.PROMOTION_NET_PRICE_EDIT_LINK + `/${props}`}>
                    {props}
                </Link> : <Link to={ROUTH_PATH.PROMOTION_MAINTAIN_EDIT_LINK + `/${props}`}>{props}</Link>
            },
            {
                title: 'สถานะ',
                dataIndex: 'status',
                key: 'status',
                sorter: (a, b) => sortDataColumn(a, b, 'status'),
                ellipsis: true,
            },
            {
                title: 'ชื่อโปรโมชั่น',
                dataIndex: 'campaignName',
                key: 'campaignName',
                sorter: (a, b) => sortDataColumn(a, b, 'campaignName'),
                ellipsis: true,
            },
            {
                title: 'วันที่เริ่มใช้งาน',
                dataIndex: 'effectiveDate',
                key: 'effectiveDate',
                sorter: (a, b) => sortDataColumn(a, b, 'effectiveDate'),
                ellipsis: true,
                render: (props) => (!!props?<span>{moment(new Date(props)).format('DD/MM/YYYY')}</span>:'')
                // render: (props) => <span>{moment(new Date(props)).format('DD/MM/YYYY')}</span>
            },
            {
                title: 'วันที่สิ้นสุด',
                dataIndex: 'expireDate',
                key: 'expireDate',
                sorter: (a, b) => sortDataColumn(a, b, 'expireDate'),
                ellipsis: true,
                render: (props) => (!!props?<span>{moment(new Date(props)).format('DD/MM/YYYY')}</span>:'')
                // render: (props) => <span>{moment(new Date(props)).format('DD/MM/YYYY')}</span>
                // render: (props) => <span>{formatDate(new Date(props))}</span>
            },
            {
                title: 'เวอร์ชั่น',
                dataIndex: 'version',
                key: 'version',
                sorter: (a, b) => sortDataColumn(a, b, 'version'),
                ellipsis: true,
            },
            {
                title: 'ชื่อ - นามสกุล ผู้สร้าง',
                dataIndex: 'createdBy',
                key: 'createdBy',
                sorter: (a, b) => sortDataColumn(a, b, 'createdBy'),
                ellipsis: true,
                render: (props) => <div>{props ? props : 'ไม่มีผู้สร้าง'}</div>
            },
            {
                title: 'วันที่สร้าง',
                dataIndex: 'createdDate',
                key: 'createdDate',
                sorter: (a, b) => sortDataColumn(a, b, 'createdDate'),
                ellipsis: true,
                render: (props) => (!!props?<span>{moment(new Date(props)).format('DD/MM/YYYY')}</span>:'')
            },
            {
                title: 'ชื่อ - นามสกุล ผู้อนุมัติ',
                dataIndex: 'approvedBy',
                key: 'approvedBy',
                sorter: (a, b) => sortDataColumn(a, b, 'approvedBy'),
                ellipsis: true,
            },
            {
                title: 'วันที่อนุมัติ',
                dataIndex: 'approvedDate',
                key: 'approvedDate',
                sorter: (a, b) => sortDataColumn(a, b, 'approvedDate'),
                ellipsis: true,
                render: (props) => (!!props?<span>{moment(new Date(props)).format('DD/MM/YYYY')}</span>:'')
                // render: (props) => <span>{moment(new Date(props)).format('DD/MM/YYYY')}</span>
                // render: (props) => <span>{props ? formatDate(new Date(props)) : ''}</span>
            },
            {
                title: 'ชื่อ-นามสกุล ผู้ยกเลิกอนุมัติ',
                dataIndex: 'unapprovedBy',
                key: 'unapprovedBy',
                sorter: (a, b) => sortDataColumn(a, b, 'unapprovedBy'),
                ellipsis: true,
                render: (props) => <div>{props ? props : ''}</div>
            },
            {
                title: 'วันที่ยกเลิกอนุมัติ',
                dataIndex: 'unapprovedDate',
                key: 'unapprovedDate',
                sorter: (a, b) => sortDataColumn(a, b, 'unapprovedDate'),
                ellipsis: true,
                render: (props) => (!!props?<span>{moment(new Date(props)).format('DD/MM/YYYY')}</span>:'')
                // render: (props) => <span>{moment(new Date(props)).format('DD/MM/YYYY')}</span>
                // render: (props) => <span>{props ? formatDate(new Date(props)) : ''}</span>
            },
            {
                title: 'คัดลอก',
                dataIndex: 'copy',
                key: 'copy',
                sorter: (a, b) => sortDataColumn(a, b, 'copy'),
                ellipsis: true,
                render: (props, row) => row.isNetPrice ? <Link to={ROUTH_PATH.PROMOTION_NET_PRICE_REF_LINK + `/${row.code}`}>
                     <ButtonCustom icon="copy" small />
                </Link> : <Link to={ROUTH_PATH.PROMOTION_MAINTAIN_REF_LINK + `/${row.code}`}> <ButtonCustom icon="copy" small /></Link>
                // render: (props, row) => <div style={{ textAlign: 'center' }}>
                //     <Link to={ROUTH_PATH.PROMOTION_MAINTAIN_REF_LINK + `/${row.code}`}>
                //         <ButtonCustom icon="copy" small />
                //     </Link>

                // </div>
            },
        ],
    }

    componentDidMount() {
        if (!this.state.permissionPage.authDisplay) {
            swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
                this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
            })
            return
        }

        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth } = this.props
        let { ConditionAdditionOptions } = this.state
        ConditionAdditionOptions = [
            ...promotionPattern,
            {
                value: 'NetPrice',
                label: 'Promotion Net Price',
                text: 'Promotion Net Price',
            }
        ]

        let paramsOutlet = {
            outletIds: auth.userOutletModelList.map((data) => (
                data.outletId
            ))
        }
        //Call API Dropdown Master Data.
        //ร้านค้า Outlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet).then(res => {
            let outlets = get(res, 'data.results', [])
            if (res.status === 200) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, text: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({
                    Outlets: outlets,
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false, ConditionAdditionOptions })
    }

    getParam = () => {
        const { auth } = this.props
        let { filter } = this.state
        let queryString = ``;
        if (!isEmpty(filter.OutletIds)) {
            filter.OutletIds.map((data) => (
                queryString += isEmpty(queryString) ? `?OutletIds=${data}` : `&OutletIds=${data}`
            ))
        } else {
            auth.userOutletModelList.map((data) => (
                queryString += isEmpty(queryString) ? `?OutletIds=${data.outletId}` : `&OutletIds=${data.outletId}`
            ))
        }

        if (!isEmpty(filter.PromotionStatus)) {
            filter.PromotionStatus.map((data) => (
                queryString += isEmpty(queryString) ? `?PromotionStatus=${data}` : `&PromotionStatus=${data}`
            ))
        }

        if (!isEmpty(filter.CreateDate)) {
            const createDateFrom = filter.CreateDate[0];
            const createDateTo = filter.CreateDate[1];
            queryString += isEmpty(queryString) ? `?CreatedDate=${moment(createDateFrom).format('YYYY-MM-DD')}` : `&CreatedDate=${moment(createDateFrom).format('YYYY-MM-DD')}`
            queryString += isEmpty(queryString) ? `?CreatedDateTo=${moment(createDateTo).format('YYYY-MM-DD')}` : `&CreatedDateTo=${moment(createDateTo).format('YYYY-MM-DD')}`
        }

        if (!isEmpty(filter.ActiveDate)) {
            const ActiveDateFrom = filter.ActiveDate[0];
            const ActiveDateTo = filter.ActiveDate[1];
            queryString += isEmpty(queryString) ? `?StartDate=${moment(ActiveDateFrom).format('YYYY-MM-DD')}` : `&StartDate=${moment(ActiveDateFrom).format('YYYY-MM-DD')}`
            queryString += isEmpty(queryString) ? `?EndDate=${moment(ActiveDateTo).format('YYYY-MM-DD')}` : `&EndDate=${moment(ActiveDateTo).format('YYYY-MM-DD')}`
        }

        if (!isEmpty(filter.PromotionCode)) {
            queryString += isEmpty(queryString) ? `?Code=${filter.PromotionCode}` : `&Code=${filter.PromotionCode}`
        }

        return queryString;
    }

    // search promotion
    handleClickSearchPromotion = async () => {
        this.setState({ loading: true })
        const queryString = this.getParam()
        const url = API_PATH.GET_PROMOTION + queryString
        await apiServices.callApi("get", url).then(res => {
            console.log("res===>", res);
            if (res.status === 200 && res.data.status === 'Success') {
                let dataTablePromotion = res.data.results.map((data, i) => ({
                    index: i,
                    ...data.promotionCampaign,
                    version: data.promotionHistories[data.promotionHistories.length - 1].version,
                    isNetPrice: res.data.results[i].promotionNetPrices.length > 0 ? true : false
                }))
                this.setState({
                    dataTablePromotion: dataTablePromotion,
                    dataOriginalPromotion: res.data.results
                })
            } else {
                swal("error", res.data.message, "error");
            }
        }).catch(error => {
            swal("error", error.response, "error");
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }
    handleClickExportPromotion = async () =>{
        this.setState({ loading: true })
        const queryString = this.getParam()
        const url = API_PATH.GET_EXPORT_EXCEL_PROMOTIONS + queryString
        await apiServices.callApi('get', url, '', { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `ReportExportPromotion-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
        // await apiServices.callApi("get", url).then(res => {
        //     console.log("res===>", res);
        //     if (res.status === 200 && res.data.status === 'Success') {
        //         let dataTablePromotion = res.data.results.map((data, i) => ({
        //             index: i,
        //             ...data.promotionCampaign,
        //             version: data.promotionHistories[data.promotionHistories.length - 1].version,
        //             isNetPrice: res.data.results[i].promotionNetPrices.length > 0 ? true : false
        //         }))
        //         this.setState({
        //             dataTablePromotion: dataTablePromotion,
        //             dataOriginalPromotion: res.data.results
        //         })
        //     } else {
        //         swal("error", res.data.message, "error");
        //     }
        // }).catch(error => {
        //     swal("error", error.response, "error");
        // }).finally(() => {
        //     this.setState({
        //         loading: false
        //     })
        // })
    }

    handleClickSavePromotion = async () => {
        const { auth } = this.props
        const { dataOriginalPromotion } = this.state

        if (isEmpty(dataOriginalPromotion))
            return

        let NewDataOriginalPromotion = [...dataOriginalPromotion]
        let promotionUpdate = []
        NewDataOriginalPromotion.forEach(data => {
            if (data.promotionCampaign.approve === true) {
                promotionUpdate.push(data);
            } else if (data.promotionCampaign.unApprove === true) {
                promotionUpdate.push(data);
            }
        });
        console.log("promotionUpdate==>", promotionUpdate);

        if (dataOriginalPromotion) {
            let params = promotionUpdate.map(data => ({
                ...data,
                updatable: true,
                promotionCampaign: {
                    ...data.promotionCampaign,
                    statusDescription: null,
                    approvedBy: data.promotionCampaign.approve ? this.props.auth.userId : '',
                    approvedDate: data.promotionCampaign.approve ? new Date() : null,
                    unapprovedBy: data.promotionCampaign.unApprove ? this.props.auth.userId : '',
                    unapprovedDate: data.promotionCampaign.unApprove ? new Date() : null,
                    status: data.promotionCampaign.approve ? 'Approve' : 'Reject',
                    unApprove: null,
                    approve: null
                },
                promotionHistory: {
                    id: 0,
                    campaignId: data.promotionHistories[data.promotionHistories.length - 1].campaignId,
                    version: data.promotionHistories[data.promotionHistories.length - 1].version,
                    modifyDate: new Date(),
                    modifyBy: auth.userId
                },
            }))
            
            this.setState({ isLoading: true })
            await apiServices.callApi("put", API_PATH.PUT_PROMOTION, params).then(async res => {
                console.log("res=>", res);
                if (res.data.status === "Error") {
                    swal("error", res.data.message, "error")
                } else {
                    swal("success", res.data.message, "success")
                    await this.handleClickSearchPromotion();
                }
            }).catch(error => {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการแก้ไขข้อมูลการส่งเสริมการขาย(Promotion) กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
        } else {
            swal("", "กรุณาระบุหมายเลขโปรโมชั่นเพื่อทำการบันทึก!", "info");
        }
    }

    //  onchange value
    handleOnchangeCollapse = (event) => {
        let activeCollapse = event.length > 0 ? true : false
        this.setState({
            activeCollapse: activeCollapse
        })
    }

    handleOnChangeFilter = (key, val) => {
        console.log(`key=>${key}, value=>${val}`)
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    handleChangeCheckApprove = (e, index) => {
        console.log("index==>", index);
        const { dataTablePromotion, dataOriginalPromotion } = this.state
        let newDataTablePromotion = [...dataTablePromotion]
        let newDataOriginalPromotion = [...dataOriginalPromotion]
        if (e.target.name === "approve") {
            newDataTablePromotion[index].approve = e.target.checked
            newDataOriginalPromotion[index].promotionCampaign.approve = e.target.checked
        }
        else {
            newDataTablePromotion[index].unApprove = e.target.checked
            newDataOriginalPromotion[index].promotionCampaign.unApprove = e.target.checked
        }
        this.setState({
            dataTablePromotion: newDataTablePromotion,
            dataOriginalPromotion: newDataOriginalPromotion
        })
    }

    render() {
        const {
            permissionPage,
            columns,
            loading,
            dataTablePromotion,
            filter,
            Outlets,
        } = this.state
        return (
            <div className="promotion-content">
                <TitleHead text="การส่งเสริมการขาย (Promotion)" icon="gift" />
                <Row>
                    <Col md={16} offset={4}>
                        <Row>
                            <Col md={24}>
                                <MultiSelectedCustom
                                    small
                                    options={Outlets}
                                    label="รหัสร้าน"
                                    widthSmall="180px"
                                    right
                                    value={filter.OutletIds}
                                    onChange={(value) => this.handleOnChangeFilter('OutletIds', value)}
                                />
                            </Col>
                            <Col md={24}>
                                <MultiSelectedCustom
                                    small
                                    label="สถานะการส่งเสริมการขาย"
                                    right
                                    widthSmall="180px"
                                    options={PromotionStatusOptions}
                                    onChange={(value) => this.handleOnChangeFilter('PromotionStatus', value)}
                                    value={filter.PromotionStatus}
                                />
                            </Col>
                            <Col md={24}>
                                <RangePickerCustom
                                    right
                                    label="วันที่สร้าง"
                                    widthSmall="180px"
                                    small
                                    value={filter.CreateDate}
                                    onChange={(value) => this.handleOnChangeFilter('CreateDate', value)}
                                />
                            </Col>
                            <Col md={24}>
                                <RangePickerCustom
                                    right
                                    label="วันที่เริ่มใช้งาน"
                                    widthSmall="180px"
                                    small
                                    value={filter.ActiveDate}
                                    onChange={(value) => this.handleOnChangeFilter('ActiveDate', value)}
                                />
                            </Col>
                            <Col md={24}>
                                <InputHero
                                    right
                                    onChange={(e) => this.handleOnChangeFilter('PromotionCode', e.target.value)}
                                    label="รหัสการส่งเสริมการขาย"
                                    widthSmall="180px"
                                    small
                                    value={filter.PromotionCode}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/*
                <Row>
                    <Col md={24}>
                        <Collapse onChange={this.handleOnchangeCollapse} bordered={false} showArrow={false}>
                            <Panel header={
                                <div className="zone-discount">
                                    <span className="collape-discount">
                                        ขั้นสูง
                                        </span>
                                    <Icon type={activeCollapse ? 'minus-circle' : "plus-circle"} />
                                </div>
                            }
                                key="1"
                            >
                                <Row gutter={10}>
                                    <Col md={12}>
                                        <MultiSelectedCustom
                                            small
                                            options={Outlets}
                                            label="รหัสร้าน"
                                            widthSmall="180px"
                                            right
                                            value={filter.OutletIds}
                                            onChange={(value) => this.handleOnChangeFilter('OutletIds', value)}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <MultiSelectedCustom
                                            small
                                            options={ConditionAdditions}
                                            onChange={(value) => this.handleOnChangeFilter('ConditionAdditions', value)}
                                            value={filter.ConditionAdditions}
                                            label="เงื่อนไขการเข้าร่วม"
                                            widthSmall="180px"
                                            right
                                        />
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                 */}
                <div className="bottom-content">
                    <div className="left-zone">
                        <div className="button">
                            <ButtonCustom
                                small
                                icon="check-square"
                                yellow
                                text="บันทึก"
                                onClick={this.handleClickSavePromotion}
                                disabled={!permissionPage.authUpdate}
                            />
                        </div>
                        <div className="button">
                            <Link to={ROUTH_PATH.PROMOTION_MAINTAIN}>
                                <ButtonCustom
                                    small
                                    icon="plus-circle"
                                    blue
                                    text="สร้างรายการส่งเสริมการขาย"
                                    disabled={!permissionPage.authCreate}
                                />
                            </Link>
                        </div>
                        <div className="button">
                            <Link to={ROUTH_PATH.PROMOTION_NET_PRICE}>
                                <ButtonCustom small icon="plus-circle" text="สร้าง Promotion Price" disabled={!permissionPage.authCreate} />
                            </Link>
                        </div>
                    </div>
                    <div className="right-zone">
                        <div className="button">
                            <ButtonCustom small icon="file" green text="Export To Excels" disabled={!permissionPage.authExport} 
                            onClick={this.handleClickExportPromotion}/>
                        </div>
                        <div className="button">
                            <ButtonCustom
                                small
                                icon="search"
                                type="danger"
                                text="ค้นหา"
                                onClick={this.handleClickSearchPromotion}
                            />
                        </div>
                    </div>
                </div>
                <div className="table-layout">
                    <TableCustom
                        scroll={{ x: "max-content" }}
                        columns={columns}
                        data={dataTablePromotion}
                        small loading={loading}
                        rowKey={(row, index) => index}
                        height="calc(100vh - 463px)"
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionContainer);