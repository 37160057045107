import styled from 'styled-components'

export const InputSearchStyled = styled.div`

.ant-input {
    font-size: ${props => props.small ? '12px' : ''};
    border-radius: 4px 0px 0px 4px;
    border-color: ${props => props.error ? "red" : ''};
}

.input-form {
    margin-bottom: ${props => props.small ? '3px' : ''};
    
}
.search-input {
    width: ${props => props.small ? 'calc(100% -120px)' : ''}; 
    display: flex;
    .button-search {
        height: ${props => props.small ? '24px' : ''};
        font-size: ${props => props.small ? '12px' : ''};
        border-radius: 0px 4px 4px 0px;
    }
    
}
.label-style {
    font-size: ${props => props.small ? '12px' : ''};
    width: ${props => props.small ? '120px' : ''}
}
`