import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { isEmpty, get } from 'lodash'
import { Row, Col, message } from 'antd'
import swal from '@sweetalert/with-react'
import { apiServices, API_PATH } from 'apiServices'
import {
    InputSearchCustom,
    ModalCustom,
    ButtonCustom,
} from 'components/common'
import {
    CustomerSearchModal,
    CustomerDetailFormRedux,
} from 'components/feature'
import './style.css'

class CnCustomerInfoForm extends Component {
    state = {
        isShowModal: false,
        modalType: '',
        customerIdSearch: '',
    }

    openModal = (modalType) => {
        this.setState({ isShowModal: true, modalType })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '' })
    }

    changeCustomerIdSearch = (customerIdSearch) => {
        this.setState({ customerIdSearch })
    }

    searchCustomer = async (customerId) => {
        if (!customerId) {
            message.error('กรุณากรอกรหัสลูกค้า')
            return
        }

        const { outletId } = this.props

        const url = `${API_PATH.GET_CUSTOMER}?OutletId=${outletId}&customerId=${customerId}&RequireSoldTo=true`
        await apiServices.callApi('get', url).then(res => {
            const customer = get(res, 'data.results.0')
            if (res.status === 200 && !isEmpty(customer)) {
                this.onSelectedCustomer(customer)
            } else {
                message.error('ไม่พบข้อมูลลูกค้า')
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    onSaveCustomer = () => {
        const {
            CustomerDetailFormRedux: { values },
        } = this.props

        const customer = this.mappingFormToCustomer(values)
        const valid = this.props.validateCustomer(customer)
        if (valid.isError) {
            message.error(valid.message)
            return
        }

        this.closeModal()
        this.props.onChangeCustomerInfo(customer)
        this.setState({ customerIdSearch: '' })
    }

    onSelectedCustomer = (customerSelected) => {
        this.closeModal()

        const customer = this.mappingFormToCustomer(customerSelected)
        this.props.onChangeCustomerInfo(customer)
    }

    // map field customer from form to customer props
    mappingFormToCustomer = (customer) => {
        return {
            outletId: customer.customerOutletId,
            customerId: customer.customerId,
            customerType: customer.customerType,
            title: customer.customerTitle,
            customerName: customer.customerNameTH,
            taxId: customer.customerTaxId,
            taxBranch: customer.customerTaxBranch,
            scgFamilyId: customer.customerScgFamilyId,
            address: customer.customerAddress,
            subDistrict: customer.customerSubDistrict,
            district: customer.customerDistrict,
            province: customer.customerProvinceName || customer.customerProvince,
            country: customer.customerCountry,
            postalCode: customer.customerPostalCode,
            phone: customer.customerPhone,
            fax: customer.customerFax,
            email: customer.customerEmail,
        }
    }

    // map field customer from props to customer form
    mappingCustomerToForm = (customer) => {
        return {
            customerOutletId: customer.outletId,
            customerId: customer.customerId,
            customerType: customer.customerType,
            customerTitle: customer.title,
            customerNameTH: customer.customerName,
            customerTaxId: customer.taxId,
            customerTaxBranch: customer.taxBranch,
            customerScgFamilyId: customer.scgFamilyId,
            customerAddress: customer.address,
            customerSubDistrict: customer.subDistrict,
            customerDistrict: customer.district,
            customerProvince: customer.province,
            customerCountry: customer.country,
            customerPostalCode: customer.postalCode,
            customerPhone: customer.phone,
            customerFax: customer.fax,
            customerEmail: customer.email,
        }
    }

    render() {
        const {
            customer,
            //  searchCustomer,
            // changeCustomerIdSearch,
            outletId,
            isErrorSubmitCustomer,
            isWithoutBillingRef,
        } = this.props

        const {
            isShowModal,
            modalType,
            customerIdSearch,
        } = this.state

        const hasCustomer = !isEmpty(customer)
        const isCustomerDefault = !!(customer.customerId && customer.customerId !== 'OTC99')
        const customerForm = this.mappingCustomerToForm(customer)
        const disabledSearchCustomer = !isWithoutBillingRef && (!hasCustomer || isCustomerDefault)

        return (
            <Fragment>
                <Row style={{ marginTop: '10px' }}>
                    <Col md={24}>
                        <div className="info-form-blue-border">
                            <div className="title">
                                ข้อมูลลูกค้า
                            </div>
                            <Row gutter={5}>
                                <Col sm={24} md={13}>
                                    <InputSearchCustom
                                        label="รหัสลูกค้า*"
                                        onClickSearch={() => this.openModal('customer')}
                                        onSubmit={e => this.searchCustomer(e.target.value)}
                                        onChange={e => this.changeCustomerIdSearch(e.target.value)}
                                        small
                                        widthLabel="70px"
                                        value={customerIdSearch || customer.customerId}
                                        disabled={disabledSearchCustomer}
                                        disabledButton={disabledSearchCustomer}
                                    />
                                </Col>
                                <Col sm={24} md={11}>
                                    {hasCustomer && <ButtonCustom
                                        style={{ padding: 0, whiteSpace: 'initial', textAlign: 'left' }}
                                        text={
                                            <span>
                                                {customer.customerName || 'ลูกค้าทั่วไป'}
                                                {customer.address && `/${customer.address || '-'}`}
                                                {customer.province
                                                    && `/${customer.subDistrict || '-'}/${customer.district || '-'}/${customer.province || '-'}/${customer.postalCode || '-'}`
                                                }
                                            </span>
                                        }
                                        type="link"
                                        small
                                        onClick={() => this.openModal('customerEdit')}
                                    />}
                                    {
                                        isErrorSubmitCustomer &&
                                        <div style={{ color: 'red', fontSize: '12px' }}>
                                            * กรุณากรอกข้อมูลลูกค้าให้ครบถ้วน
                                        </div>
                                    }
                                </Col >
                            </Row >
                        </div >
                    </Col >
                </Row >
                <ModalCustom
                    title={`เพิ่ม / แก้ไขข้อมูลลูกค้า${customer.customerId ? '' : ' (One time OTC99)'}`}
                    isShowModal={isShowModal && modalType === 'customerEdit'}
                    onCloseModal={this.closeModal}
                    onClickSave={this.onSaveCustomer}
                    textSuccess="ตกลง"
                    footer
                >
                    <CustomerDetailFormRedux
                        initialValues={customerForm}
                    />
                </ModalCustom>
                <CustomerSearchModal
                    isShowModal={isShowModal && modalType === 'customer'}
                    handleCloseModal={this.closeModal}
                    onSelected={this.onSelectedCustomer}
                    outletId={outletId}
                // scgFlag={scgFlag}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        CustomerDetailFormRedux: state.form.CustomerDetailFormRedux,
    }
}

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(CnCustomerInfoForm)
