import React, { Component } from 'react'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    SelectHero,
} from 'components'
import { CustomerKeyWordDropDown, sortDataColumn } from 'helpers'
import { Row, Col, Input } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { get, isEmpty, first } from 'lodash'
import swal from '@sweetalert/with-react'
import './style.css'

const { Search } = Input

export class CustomerSearchModal extends Component {
    state = {
        loading: false,
        filter: {
            keyword: '',
            keywordType: first(CustomerKeyWordDropDown).value,
        },

        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: '#',
                key: '#',
                render: (value, row) => <ButtonCustom small onClick={() => { this.props.onSelected(row); this.setState({ customers: [] }); }} text="เลือก" />
            },
            {
                title: 'รหัสลูกค้า',
                align: 'center',
                dataIndex: 'customerId',
                key: 'customerId',
                sorter: (a, b) => sortDataColumn(a, b, 'customerId'),
                ellipsis: true,
            },
            {
                title: 'ชื่อลูกค้า',
                align: 'center',
                dataIndex: 'customerNameTH',
                key: 'customerNameTH',
                sorter: (a, b) => sortDataColumn(a, b, 'customerNameTH'),
                ellipsis: true,
            },
            {
                title: 'เบอร์โทรศัพท์',
                align: 'center',
                dataIndex: 'customerPhone',
                key: 'customerPhone',
                sorter: (a, b) => sortDataColumn(a, b, 'customerPhone'),
                ellipsis: true,
            },
            {
                title: 'เลขบัตรประชาชน',
                align: 'center',
                dataIndex: 'customerTaxId',
                key: 'customerTaxId',
                sorter: (a, b) => sortDataColumn(a, b, 'customerTaxId'),
                ellipsis: true,
            },
            {
                title: 'กลุ่มลูกค้า',
                align: 'center',
                dataIndex: 'customerGroup',
                key: 'customerGroup',
                sorter: (a, b) => sortDataColumn(a, b, 'customerGroup'),
                ellipsis: true,
            },
            {
                title: 'อีเมล์',
                align: 'center',
                dataIndex: 'customerEmail',
                key: 'customerEmail',
                sorter: (a, b) => sortDataColumn(a, b, 'customerEmail'),
                ellipsis: true,
            },
        ],
        keyword: '',
        keywordType: CustomerKeyWordDropDown[1].value,
    }

    handleChangeFillet = (key, val) => {
        //console.log(`key==>${key}, val==>${val}`)
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } });
    }

    onSearch = async () => {
        const { filter } = this.state;
        const { outletId, scgFlag } = this.props;
        // console.log(`keywordType ==>${filter.keywordType}, keyword==>${filter.keyword}, outletId==>${outletId}`);
        this.setState({ loading: true });
        const url = `${API_PATH.GET_CUSTOMER}?${filter.keywordType}=${filter.keyword}&RequireSoldTo=true${!isEmpty(scgFlag) ? `&ScgFlag=${scgFlag}` : ''}${outletId ? `&OutletId=${outletId}` : ''}`
        //console.log(`url search customer ==>${url}`);
        await apiServices.callApi('get', url).then(res => {
            const resCustomers = get(res, 'data.results')
            if (resCustomers) {
                this.setState({ customers: resCustomers })
            }
        }).catch(error => {
            const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            console.error(get(error, 'response.data.errors'))
        })
        this.setState({ loading: false })
    }

    render() {
        const {
            isShowModal,
            handleCloseModal,
            title,
            width,
            className,
        } = this.props
        const {
            loading,
            columns,
            filter,
            customers,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                handleCloseModal={() => { handleCloseModal(); this.setState({ customers: [] }) }}
                title={title || 'ค้นหาลูกค้า'}
                width={width || '60%'}
                className={`modal-data ${className}`}
            >
                <Row gutter={8}>
                    <Col sm={8}>
                        <SelectHero
                            label="ค้นหาด้วย"
                            small
                            firstChoose
                            widthDefault='100px'
                            onChange={(value) => this.handleChangeFillet('keywordType', value)}
                            options={CustomerKeyWordDropDown}
                            value={filter.keywordType}
                        />
                    </Col>
                    <Col sm={6}>
                        <Search
                            size="small"
                            onChange={(e) => this.handleChangeFillet('keyword', e.target.value)}
                            onSearch={this.onSearch}
                            enterButton />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(50vh - 50px)"
                            columns={columns}
                            data={customers}
                            loading={loading}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
