import React, { Component } from 'react'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    InputCustom,
} from 'components/common'
import { get, isEmpty } from 'lodash'
import { Row, Col } from 'antd'

export class ProductHierarchyModal extends Component {
    state = {
        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: 'action',
                key: 'action',
                ellipsis: true,
                render: (props, row) => <div className="text-center">
                    <ButtonCustom
                        onClick={() => this.props.onSelected(row)}
                        small
                        text="เลือก" />
                </div>
            },
            {
                title: 'รหัส',
                align: 'center',
                dataIndex: 'code',
                key: 'code',
                render: (props, row) => get(row, `${this.props.productHierarchyKey}Code`, '')
            },
            {
                title: 'รายละเอียด',
                align: 'center',
                dataIndex: 'descriptionThai',
                key: 'descriptionThai',
            },

        ],
        keyword: '',
    }

    componentDidUpdate(prevProps) {
        if (this.props.productHierarchyKey !== prevProps.productHierarchyKey) {
            this.setState({ keyword: '' })
        }
    }

    changeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    filterData = () => {
        let { keyword } = this.state
        const { data } = this.props
        if (isEmpty(data)) return []
        keyword = keyword.toLowerCase()

        return data.filter(row => (
            get(row, `${this.props.productHierarchyKey}Code`, '').toLowerCase().includes(keyword)
            || row.descriptionThai.toLowerCase().includes(keyword)

        ))
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            productHierarchyKey,
        } = this.props
        const {
            columns,
            keyword,
        } = this.state

        const data = this.filterData()

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                width={'700px'}
                title={`ค้นหา ${productHierarchyKey}`}
            >
                <Row>
                    <Col sm={8} offset={16}>
                        <InputCustom
                            label="Search : "
                            widthLabel="70px"
                            onChange={this.changeKeyword}
                            value={keyword}
                            small
                        />
                    </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={data}
                            small
                            rowKey={(row, index) => index}
                            pagination={{ pageSize: 30 }}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
