import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col } from 'antd'
import {
    InputSearch,
    InputFormRedux,
    DatePickerRedux,
    SelectRedux,
    ButtonCustom,
    CheckBoxRedux,
} from 'components'
import { VALIDATE } from 'helpers'

import './style.css'

const style = {
    topZone: {
        marginTop: 10,
    },
    infoDataBox: {
        padding: 5,
    },
    quotationBox: {
        padding: 5,
    },
}

class QuotationCreateForm extends Component {
    state = {
        widthDefault: '90px',
    }

    render() {
        const {
            handleClickCustomerModal,
            handleClickSearch,
            handleClickShippingModal,
            handleChangeFetchEmplpyeeBySite,
            handleClickRequestSpecialPrice,
            handleChangeChannelCode,
            dataOutlet,
            dataChannel,
            dataEmployee,
            dataSelectedCustomer,
            handleChangeSearch,
            valueInputSearch,
            dataSaleShippingPoint,
            dataSolution,
            dataDocStatus,
            handleSubmitSearchCustomer,
            dataSelectedCustomerShipTo,
            submitViewError,
            // errorShipTo,
            // errorCustomer,
            disabledAction,
            handleClickApprove,
            disabledApprove,
            showApprove,
            disabledRequestSpecialPrice,
            handleChangeDateExpire,
            soNumber,
            disabledOutlet,
        } = this.props

        const {
            widthDefault,
        } = this.state
        return (
            <Row style={style.topZone}>
                <Col style={style.infoDataBox} sm={24} md={10}>
                    <div className="customer-data-box">
                        <div className="title-text">
                            ข้อมูลลูกค้า
                        </div>
                        <Row gutter={10} style={{ fontSize: "12px" }}>
                            <Col sm={24} md={24} style={{ display: 'flex' }}>
                                <InputSearch
                                    handleClickSearch={handleClickSearch}
                                    label="รหัสลูกค้า"
                                    small
                                    onChange={handleChangeSearch}
                                    value={valueInputSearch}
                                    handleSubmitSearch={handleSubmitSearchCustomer}
                                    disabled={disabledAction}
                                    disabledButton={disabledAction}
                                />
                                {
                                    dataSelectedCustomer ?
                                        disabledAction ?
                                            <span className="show-detail">
                                                {dataSelectedCustomer.customerNameTH || 'ลูกค้าทั่วไป'}
                                            </span>
                                            :
                                            <span className="click-detail-customer" onClick={() => handleClickCustomerModal('customer', dataSelectedCustomer)}>
                                                {dataSelectedCustomer.customerNameTH || 'ลูกค้าทั่วไป'}
                                            </span>
                                        :
                                        <span className="click-detail-customer" onClick={() => handleClickCustomerModal('customer')}>
                                            ลูกค้าทั่วไป</span>
                                }
                            </Col>
                            <Col style={{ display: 'flex' }} sm={24} md={24}>
                                <InputSearch
                                    label="สถานที่จัดส่ง"
                                    disabled
                                    small
                                    handleClickSearch={handleClickShippingModal}
                                    value={dataSelectedCustomerShipTo && dataSelectedCustomerShipTo.customerId}
                                    disabledButton={disabledAction}
                                />
                                <span className="show-detail">
                                    {
                                        dataSelectedCustomerShipTo &&
                                        `${dataSelectedCustomerShipTo.customerNameTH || '-'}`
                                    }
                                    {
                                        dataSelectedCustomerShipTo && dataSelectedCustomerShipTo.customerId !== 'OTC99' &&
                                        `/${dataSelectedCustomerShipTo.customerSubDistrict || '-'}` +
                                        `/${dataSelectedCustomerShipTo.customerDistrict || '-'}/${dataSelectedCustomerShipTo.customerProvince || '-'}` +
                                        `/${dataSelectedCustomerShipTo.customerPostalCode || '-'}`
                                    }
                                </span>
                            </Col>
                            <Col sm={24} md={12}>
                                <Field
                                    component={InputFormRedux}
                                    label="SCGID"
                                    small
                                    name="sad"
                                    disabled={disabledAction}
                                />
                            </Col>
                            {/* {
                                (dataSelectedCustomer && dataSelectedCustomer.customerId) &&
                                <Col sm={24} md={12}>
                                    <Field
                                        component={InputFormRedux}
                                        label="วงเงินคงเหลือ"
                                        small
                                        name="customerCreditRemain"
                                        disabled
                                    />
                                </Col>
                            }
                            {
                                (dataSelectedCustomer && dataSelectedCustomer.customerId) &&
                                <Col sm={24} md={12}>
                                    <Field
                                        component={InputFormRedux}
                                        label="ทะเบียนรถ"
                                        small
                                        name="carNumber"
                                        disabled={disabledAction}
                                    />
                                </Col>
                            }
                            {
                                (dataSelectedCustomer && dataSelectedCustomer.customerId) &&
                                <Col sm={24} md={12}>
                                    <Field
                                        component={InputFormRedux}
                                        label="วงเงินที่มี"
                                        small
                                        name="customerCreditLimit"
                                        disabled
                                    />
                                </Col>
                            }
                            {
                                (dataSelectedCustomer && dataSelectedCustomer.customerId) &&
                                <Col sm={24} md={12}>
                                    <Field
                                        component={InputFormRedux}
                                        label="วงเงินที่ใช้ไป"
                                        small
                                        name="customerUsedCredit"
                                        disabled
                                    />
                                </Col>
                            } */}
                            {
                                showApprove && <Col sm={12} md={12} style={{ textAlign: 'right', marginTop: '2px' }}>
                                    <ButtonCustom
                                        text="อนุมัติ"
                                        small
                                        onClick={handleClickApprove}
                                        disabled={disabledApprove} />
                                </Col>
                            }
                        </Row>
                    </div>
                </Col>
                <Col style={style.quotationBox} sm={24} md={14}>
                    <div className="customer-data-box">
                        <div className="title-text2">
                            ข้อมูลการขาย
                        </div>
                        <Row gutter={4} style={{ fontSize: "12px" }}>
                            <Col sm={24} md={12} xl={8}>
                                <Field
                                    component={InputFormRedux}
                                    label="เลขที่เอกสาร"
                                    name="soNumber"
                                    small
                                    widthDefault={widthDefault}
                                    widthSmall={widthDefault}
                                    disabled
                                />
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Field
                                    component={SelectRedux}
                                    label="สถานะเอกสาร"
                                    name="docStatus"
                                    small
                                    options={dataDocStatus}
                                    widthSmall={widthDefault}
                                    disabled
                                />
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Field
                                    component={InputFormRedux}
                                    label="Version"
                                    name="version"
                                    small
                                    widthSmall={widthDefault}
                                    disabled
                                />
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Field
                                    component={SelectRedux}
                                    name="saleChannelCode"
                                    label="ช่องทาง"
                                    small
                                    widthSmall={widthDefault}
                                    options={dataChannel}
                                    disabled={disabledAction || !!soNumber}
                                    onChangeHero={handleChangeChannelCode}
                                />
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Field
                                    component={DatePickerRedux}
                                    label="วันที่เอกสาร"
                                    small
                                    widthSmall={widthDefault}
                                    name="dateSaleOrder"
                                    disabled
                                />
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Field
                                    component={DatePickerRedux}
                                    label="วันที่หมดอายุเอกสาร"
                                    small
                                    allowClear={false}
                                    widthSmall={widthDefault}
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    name="dateExpire"
                                    disabled={disabledAction}
                                    onChange={handleChangeDateExpire}
                                />
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Field
                                    component={SelectRedux}
                                    name="jobid"
                                    label="Job ID"
                                    small
                                    widthSmall={widthDefault}
                                    options={[]}
                                    disabled={disabledAction}
                                />
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Field
                                    component={SelectRedux}
                                    name="solutionType"
                                    label="Solution"
                                    small
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    widthSmall={widthDefault}
                                    options={dataSolution}
                                    disabled={disabledAction}
                                    pleaseChoose
                                />
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Field
                                    component={SelectRedux}
                                    name="saleShippingPoint"
                                    label="เงื่อนไขการส่งมอบ"
                                    small
                                    options={dataSaleShippingPoint}
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    widthSmall={widthDefault}
                                    disabled={disabledAction}
                                    pleaseChoose
                                />
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Field
                                    component={SelectRedux}
                                    name="employee"
                                    label="พนักงานขาย"
                                    small
                                    options={dataEmployee}
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    widthSmall={widthDefault}
                                    disabled={disabledAction}
                                    pleaseChoose
                                />
                            </Col>
                            <Col sm={24} md={12} xl={8}>
                                <Field
                                    component={SelectRedux}
                                    name="siteId"
                                    label="Site ID"
                                    small
                                    options={dataOutlet}
                                    onChangeHero={handleChangeFetchEmplpyeeBySite}
                                    widthSmall={widthDefault}
                                    disabled={disabledAction || disabledOutlet}
                                />
                            </Col>
                            <Col sm={24} md={12} xl={8} style={{ textAlign: 'center', lineHeight: '2.2' }}>
                                <Field
                                    component={CheckBoxRedux}
                                    small
                                    disabled={disabledRequestSpecialPrice || disabledAction}
                                    onChange={handleClickRequestSpecialPrice}
                                    name="requestSpecialPrice"
                                    label="ขอราคาพิเศษ"
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row >
        )
    }
}

export default reduxForm({
    form: 'QuotationCreateForm',  // a unique identifier for this form
    enableReinitialize: true
})(QuotationCreateForm)
