import React, { Component, Fragment } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Spin } from 'antd'
import { get } from 'lodash'
import { VALIDATE } from 'helpers'
import {
    InputCustom,
    SelectCustom,
    ButtonCustom,
    CheckboxCustom,
    RadioCustom,
    DatePickerCustom,
} from 'components/common'
import { apiServices, API_PATH } from 'apiServices'
import swal from '@sweetalert/with-react'
import './style.css'
const stockTypeOptions = [{ label: 'รวมทุกที่เก็บ', value: "S" }, { label: 'แยกที่เก็บ', value: "L" }];
const unitOptions = [{ label: 'หน่วยมาตรฐาน', value: "B" }, { label: 'หน่วยสั่งซื้อ', value: "O" }, { label: 'หน่วยขาย', value: "S" }];

class ReportInventoryBalanceForm extends Component {
    state = {
        isShowModal: false,
        loading: false,
        outlets: [],
        productTypes: [],
        specialInventories: [],
        storageLocations: [],
        productBrands: [],
        productCategories: [],
        widthLabel: '140px',
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth, change } = this.props
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                const outletIdDefault = get(outlets, '0.value')
                this.setState({
                    outlets,
                }, () => {
                    change('outlet', outletIdDefault)
                    this.getStorageLocations(outletIdDefault)
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })

        await apiServices.callApi('get', `${API_PATH.GET_MASTER_PRODUCT_TYPE}?ActiveStatus=true`).then(res => {
            let productTypes = get(res, 'data.results')
            if (res.status === 200) {
                productTypes = productTypes.map(data => (
                    { ...data, label: `${data.productTypeCode} - ${data.productTypeDescription}`, value: data.productTypeCode }
                ))
                this.setState({ productTypes })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })

        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SPECIAL_IND}?ActiveStatus=true`).then(res => {
            let specialInventories = get(res, 'data.results')
            if (res.status === 200) {
                specialInventories = specialInventories.map(data => (
                    { ...data, label: `${data.specialIndCode} - ${data.specialIndDescription}`, value: data.specialIndCode }
                ))
                this.setState({ specialInventories: [{ label: 'กรุณาเลือก', value: '' }, ...specialInventories] })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })

        //PurchasingGroups
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_PURCHASING_GROUP}?ActiveStatus=true`).then(res => {
            let purchasingGroups = get(res, 'data.results')
            if (res.status === 200) {
                purchasingGroups = purchasingGroups.map(data => (
                    { ...data, label: `${data.purchasingGroupCode} - ${data.purchasingGroupDescription}`, value: data.purchasingGroupCode }
                ))
                this.setState({ purchasingGroups })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })

        // product brand
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_BRAND).then(res => {
            let productBrands = get(res, 'data.results', [])
            if (res.status === 200) {
                productBrands = productBrands.map(data => (
                    { ...data, label: `${data.brandCode} - ${data.descriptionThai}`, value: data.brandCode }
                ))
                this.setState({ productBrands: [ ...productBrands] })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Brand ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })

        // product category
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_CATEGORY).then(res => {
            let productCategories = get(res, 'data.results', [])
            if (res.status === 200) {
                productCategories = productCategories.map(data => (
                    { ...data, label: `${data.categoryCode} - ${data.descriptionThai}`, value: data.categoryCode }
                ))
                this.setState({ productCategories })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Category ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })

    }

    onChangeOutlet = (outletId) => {
        const { change } = this.props
        this.getStorageLocations(outletId)
        change('storageLocations', [])
    }

    getStorageLocations = async (outletId) => {
        this.setState({ loading: true })
        const params = [outletId];
        const { auth } = this.props

        await apiServices.callApi('post', API_PATH.GET_MASTER_WAREHOUSE_BY_OUTLET_ID, params).then(res => {
            let storageLocations = get(res, 'data.results')
            if (res.status === 200) {
                // filter status = true and auth
                storageLocations = storageLocations.filter(storageLocation =>
                    (storageLocation.storageLocationIdStatus && auth.arrayStorageLocationId.includes(storageLocation.storageLocationId))
                )
                storageLocations = storageLocations.map(data => (
                    {
                        ...data,
                        label: `${data.storageLocationId} - ${data.storageLocationDescriptionThai}`,
                        value: data.storageLocationId
                    }
                ))
                this.setState({ storageLocations })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onKeyPress = (e) => {
        e.key === 'Enter' && e.preventDefault()
    }

    render() {
        const {
            handleSubmit,
            onClickExport,
            permissionPage,
        } = this.props

        const {
            storageLocations,
            specialInventories,
            productTypes,
            outlets,
            loading,
            widthLabel,
            purchasingGroups,
            productBrands,
            productCategories
        } = this.state
        // console.log('productBrands, productCategories', productBrands, productCategories);

        return (
            <form
                onSubmit={handleSubmit}
                onKeyPress={this.onKeyPress}>
                <Spin spinning={loading}>
                    <Row gutter={10}>
                        {/* <Col sm={12} className="input-range-box">
                            <Fragment>
                                <div className="input-range-label">รหัสสินค้า</div>
                                <div className="input-range">
                                    <div style={{ marginRight: '5px', width: '95%' }}>
                                        <Field
                                            component={InputCustom}
                                            name="productCodeFrom"
                                            widthLabel={widthLabel}
                                            small
                                            placeholder='20000001'
                                        />
                                    </div>
                                    <div style={{ marginRight: '5px', width: '5%' }}>
                                        ถึง
                                    </div>
                                    <div style={{ width: '95%' }}>
                                        <Field
                                            component={InputCustom}
                                            name="productCodeTo"
                                            widthLabel='30px'
                                            small
                                            placeholder='20000003'
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        </Col> */}
                        <Col sm={12}>
                            <Field
                                component={InputCustom}
                                name="productCode"
                                label="รหัสสินค้า"
                                widthLabel={widthLabel}
                                small
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={InputCustom}
                                name="barcode"
                                label="บาร์โค้ด"
                                widthLabel={widthLabel}
                                small
                            />
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                component={SelectCustom}
                                name="outlet"
                                label="ร้าน"
                                options={outlets}
                                widthLabel={widthLabel}
                                small
                                onChange={this.onChangeOutlet}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={SelectCustom}
                                name="storageLocations"
                                mode="multiple"
                                isValueAllClear
                                maxTagCount={2}
                                label="ที่เก็บสินค้า"
                                options={storageLocations}
                                optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                widthLabel={widthLabel}
                                small
                                placeholder="ทั้งหมด"
                            />
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                component={SelectCustom}
                                name="productTypes"
                                mode="multiple"
                                isValueAllClear
                                maxTagCount={2}
                                label="ประเภทสินค้า"
                                options={productTypes}
                                optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                widthLabel={widthLabel}
                                small
                                placeholder="ทั้งหมด"
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={SelectCustom}
                                name="purchasingGroup"
                                mode="multiple"
                                isValueAllClear
                                maxTagCount={2}
                                label="กลุ่มการจัดซื้อ"
                                options={purchasingGroups}
                                optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                widthLabel={widthLabel}
                                small
                                placeholder="ทั้งหมด"
                            />
                        </Col>
                    </Row>
                    {/*  */}
                    <Row gutter={10}>
                        <Col sm={12}>
                        <Field
                                component={SelectCustom}
                                name="productBrands"
                                mode="multiple"
                                isValueAllClear
                                maxTagCount={2}
                                label="Brand"
                                options={productBrands}
                                optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                widthLabel={widthLabel}
                                small
                                placeholder="ทั้งหมด"
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={SelectCustom}
                                name="productCategories"
                                mode="multiple"
                                isValueAllClear
                                maxTagCount={2}
                                label="Categories"
                                options={productCategories}
                                optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                widthLabel={widthLabel}
                                small
                                placeholder="ทั้งหมด"
                            />
                        </Col>
                    </Row>
                    {/*  */}
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                component={SelectCustom}
                                name="specialInventory"
                                label="สต็อกพิเศษ"
                                options={specialInventories}
                                widthLabel={widthLabel}
                                small
                                placeholder="กรุณาเลือก"
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={CheckboxCustom}
                                name="productInStockOnly"
                                label="แสดงเฉพาะทีสต๊อกสินค้า"
                                widthLabel={widthLabel}
                                small
                                checked={false}
                            />
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                component={RadioCustom}
                                options={stockTypeOptions}
                                name="stockType"
                                label="การแสดงสต๊อก"
                                widthLabel={widthLabel}
                                small
                                checked={false}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={RadioCustom}
                                options={unitOptions}
                                name="unitOptions"
                                label="หน่วยที่แสดง"
                                widthLabel={widthLabel}
                                small
                                checked={false}
                            />
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col sm={12} className="input-range-box">
                            <Fragment>
                                <div className="input-range-label">วันที่เอกสาร</div>
                                <div className="input-range">
                                    <div style={{ marginRight: '5px', width: '100%' }}>
                                        <Field
                                            component={DatePickerCustom}
                                            name="createDateFrom"
                                            allowClear={false}
                                            small
                                        />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Field
                                            component={DatePickerCustom}
                                            name="createDateTo"
                                            allowClear={false}
                                            small
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        </Col>
                        <Col sm={12}>
                            <div className="action-box">
                                <div className="button">
                                    <ButtonCustom
                                        icon="file-excel"
                                        green
                                        small
                                        text="Export"
                                        onClick={onClickExport}
                                        disabled={!permissionPage.authExport}
                                    />
                                </div>
                                <div className="button">
                                    <ButtonCustom
                                        htmlType="submit"
                                        icon="search"
                                        small
                                        text="ค้นหา"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </form >
        )
    }
}

export default reduxForm({
    form: 'ReportInventoryBalanceForm',
    enableReinitialize: true,
})(ReportInventoryBalanceForm)
