import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, find, last, map, flatten, omit } from 'lodash'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { formatNumber } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    RadioCustom,
    SelectHero,
    DatePickerCustom,
    InputSearch,
    ModalCustomerSearch,
    ModalSaleEmployee,
} from 'components'
import { Row, Col, Spin } from 'antd'
import { ROUTH_PATH } from 'routes'
import { apiServices, API_PATH } from 'apiServices'
import moment from 'moment'
import FileSaver from 'file-saver'
import swal from '@sweetalert/with-react'

import './style.css'

class ReportQuotationContainer extends Component {
    state = {
        columnsHead: [
            {
                title: 'วันที่เอกสาร',
                dataIndex: 'createdDatetime',
                key: 'createdDatetime',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {moment(value).format('DD/MM/YYYY')}
                    </div>
                )
            },
            {
                title: 'ใบเสนอราคา',
                dataIndex: 'quotationNumber',
                key: 'quotationNumber',
                align: 'center',
                render: (value, row) => (
                    <Link to={`${ROUTH_PATH.QUOTATION_UPDATE_LINK}/${row.quotationNumber}/${row.outletId}`}>
                        <ButtonCustom type="link" text={value} />
                    </Link>
                )
            },
            {
                title: 'ลูกค้า',
                dataIndex: 'customer',
                key: 'customer',
                align: 'center',
                render: (value, row) => (
                    <div className="align-left">
                        {row.customerId}: {row.customerName}
                    </div>
                )
            },
            {
                title: 'ยอดเงินรวม',
                dataIndex: 'revision.totalAmount',
                key: 'revision.totalAmount2',
                align: 'center',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'พนักงานขาย',
                dataIndex: 'saleEmpName',
                key: 'saleEmpName',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'User ID',
                dataIndex: 'saleEmpCode',
                key: 'saleEmpCode',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'ร้าน',
                dataIndex: 'outletId',
                key: 'outletId',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'ช่องทาง',
                dataIndex: 'saleChannelCode',
                key: 'saleChannelCode',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            // {
            //     title: 'สถานะ',
            //     dataIndex: 'documentStatus',
            //     key: 'documentStatus',
            //     align: 'center',
            //     render: (value) => {
            //         const status = !isEmpty(this.state.docStatuses) && find(this.state.docStatuses, { statusCode: value })
            //         return (<div className="align-left">
            //             {(status) ? status.statusDescription || value : value}
            //         </div>)
            //     }
            // },
            {
                title: 'สถานะ',
                dataIndex: 'docStatusDescription',
                key: 'docStatusDescription',
                align: 'center',
                // render: (value) => {
                //     const status = !isEmpty(this.state.docStatuses) && find(this.state.docStatuses, { statusCode: value })
                //     return (<div className="align-left">
                //         {(status) ? status.statusDescription || value : value}
                //     </div>)
                // }
            },
            
            {
                title: 'วันที่หมดอายุเอกสาร',
                dataIndex: 'expireDate',
                key: 'expireDate',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {moment(value).format('DD/MM/YYYY')}
                    </div>
                )
            },
            {
                title: 'สถานะเอกสาร',
                dataIndex: 'documentStatus',
                key: 'documentStatus2',
                align: 'center',
                render: (value) => {
                    const status = !isEmpty(this.state.docStatuses) && find(this.state.docStatuses, { statusCode: value })
                    return (status) ? status.statusDescription || value : value
                }
            },
            {
                title: 'Solution',
                dataIndex: 'solutionType',
                key: 'solutionType',
                align: 'center',
                render: (value) => {
                    const solution = !isEmpty(this.state.solutions) && find(this.state.solutions, { solutionId: ++value })
                    return (solution) ? solution.solutionDescription || value : value
                }
            },
            {
                title: 'ช่องทาง',
                dataIndex: 'outletId',
                key: 'outletId2',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'การจัดส่ง',
                dataIndex: 'shippingPoint',
                key: 'shippingPoint',
                align: 'center',
            },
            {
                title: 'ขอราคาพิเศษ',
                dataIndex: 'requestSpecialPrice',
                key: 'requestSpecialPrice',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value && 'X'}
                    </div>
                )
            },
            {
                title: 'ชื่อลูกค้า',
                dataIndex: 'customerName',
                key: 'customerName',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'SCG Family ID',
                dataIndex: 'scgid',
                key: 'scgid',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'ชื่อพนักงานขาย',
                dataIndex: 'saleEmpName',
                key: 'saleEmpName2',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'Job ID',
                dataIndex: 'jobId',
                key: 'jobId',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'หมายเหตุ',
                dataIndex: 'remark',
                key: 'remark',
                align: 'center',
                width: 550,
                render: (value) => (
                    <div className="align-left">
                        {(value || '').replace(/\n|\/n/g, ' ').replace(/\t|\/t/g, ' ')}
                    </div>
                )
            },
            {
                title: 'ส่วนลดท้ายบิล (%)',
                dataIndex: 'revision.discPercent',
                key: 'revision.discPercent',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'ส่วนลดท้ายบิล (จน)',
                dataIndex: 'revision.discPercentAmount',
                key: 'revision.discPercentAmount',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'ส่วนลดคูปอง-ท้ายบิล (%)',
                dataIndex: 'revision.discCouponPercent',
                key: 'revision.discCouponPercent',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'SCG Coupon Code',
                dataIndex: 'revision.discSCGIDCode',
                key: 'revision.discSCGIDCode',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {value}
                    </div>
                )
            },
            {
                title: 'ส่วนลดคูปอง-SCG Family ID',
                dataIndex: 'revision.discSCGIDAmount',
                key: 'revision.discSCGIDAmount',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'ส่วนลดคูปองเพิ่มท้ายบิล (จน)',
                dataIndex: 'revision.discCouponAmount',
                key: 'revision.discCouponAmount',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'ส่วนลด promotion ส่วนกลาง',
                dataIndex: 'revision.discPromotion',
                key: 'revision.discPromotion',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'ส่วนลด',
                dataIndex: 'revision.discAmount',
                key: 'revision.discAmount',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'รวมจำนวนเงิน',
                dataIndex: 'revision.totalAmount',
                key: 'revision.totalAmount',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'ส่วนลด',
                dataIndex: 'revision.totalDiscAmount',
                key: 'revision.totalDiscAmount',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'มูลค่าก่อนภาษี',
                dataIndex: 'excludingVatAmount',
                key: 'excludingVatAmount',
                align: 'center',
                width: 100,
                render: (value, row) => {
                    const amount = get(row, 'revision.totalAmount', 0) - get(row, 'revision.vatAmount', 0)
                    return (<div className="align-right">
                        {formatNumber(amount, 2)}
                    </div>)
                }
            },
            {
                title: 'ภาษีมูลค่าเพิ่ม',
                dataIndex: 'revision.vatAmount',
                key: 'revision.vatAmount',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'จำนวนเงินสุทธิ',
                dataIndex: 'revision.netAmount',
                key: 'revision.netAmount',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
        ],
        columnsItem: [
            {
                title: 'เลขที่เอกสาร',
                dataIndex: 'quotationNumber',
                key: 'quotationNumber',
                align: 'center',
                render: (value, row) => (
                    <Link to={`${ROUTH_PATH.QUOTATION_UPDATE_LINK}/${row.quotationNumber}/${row.outletId}`}>
                        <ButtonCustom type="link" text={value} />
                    </Link>
                )
            },
            {
                title: 'บาร์โค้ด',
                dataIndex: 'barcode',
                key: 'barcode',
                align: 'center',
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
                align: 'center',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                align: 'center',
                width: 300,
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'ที่เก็บ',
                dataIndex: 'slocId',
                key: 'slocId',
                align: 'center',
            },
            {
                title: 'การจัดส่ง',
                dataIndex: 'shippingPoint',
                key: 'shippingPoint',
                align: 'center',
            },
            {
                title: 'จำนวนใบเสนอราคา',
                dataIndex: 'qty',
                key: 'qty',
                align: 'center',
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'จำนวนใบสั่งขาย',
                dataIndex: 'confirmQty',
                key: 'confirmQty',
                align: 'center',
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'หน่วย',
                dataIndex: 'unit',
                key: 'unit',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'ราคา/หน่วย',
                dataIndex: 'pricePerUnit',
                key: 'pricePerUnit',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'ส่วนลดรายการ/หน่วย',
                dataIndex: 'discAmountPerUnit',
                key: 'discAmountPerUnit',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'ส่วนลดรายการ (%)',
                dataIndex: 'discPercent',
                key: 'discPercent',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value)}
                    </div>
                )
            },
            {
                title: 'ส่วนลดรายการ (จน)',
                dataIndex: 'discAmountPerUnitAmount',
                key: 'discAmountPerUnitAmount',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'ส่วนลด Promotion ส่วนกลาง',
                dataIndex: 'discPromotion',
                key: 'discPromotion',
                align: 'center',
                width: 120,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'ของแถม',
                dataIndex: 'freeGoodsFlag',
                key: 'freeGoodsFlag',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-left">
                        {value.toString()}
                    </div>
                )
            },
            {
                title: 'ส่วนลดรวม',
                dataIndex: 'totalDiscAmount',
                key: 'totalDiscAmount',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'จำนวนเงิน',
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                align: 'center',
                width: 100,
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'หมายเหตุรายการ',
                dataIndex: 'itemText',
                key: 'itemText',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'เหตุผลการปฏิเสธ',
                dataIndex: 'rejectId',
                key: 'rejectId',
                align: 'center',
                render: (value) => {
                    const status = !isEmpty(this.state.saleRejects) && find(this.state.saleRejects, { rejectId: value })
                    return (<div className="align-left">
                        {(status) ? status.rejectDescription || value : value}
                    </div>)
                }
            },
        ],
        loading: false,
        quotationNumber: '',
        quotations: [],
        customers: [],
        outlets: [],
        outlet: '',
        solutions: [],
        channels: [],
        users: [],
        saleEmployees: [],
        docStatuses: [],
        saleRejects: [],
        widthLabel: '90px',
        dateFormat: 'DD/MM/YYYY',
        dateFormatAPI: 'YYYY/MM/DD',
        filter: {
            customer: '',
            jobId: '',
            outlet: '',
            channel: '',
            user: '',
            saleEmployee: '',
            docStatus: '',
            startDate: moment(),
            // startDate: moment().subtract(7, 'day'),
            endDate: moment(),
        },
        isShowModal: false,
        modalType: '',
        dataTypes: [
            { label: <div className="data-type-label">Head</div>, value: 'head' },
            { label: <div className="data-type-label">Detail</div>, value: 'detail' }
        ],
        dataType: 'head',
        quotationHeads: [],
        quotationItems: [],
        pagination: {
            limit: 10,
            current: {
                head: 1,
                detail: 1,
            }
        }
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth } = this.props
        const { filter } = this.state

        const resOutlet = await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        })
        const resChannel = await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL)
        const resDocStatus = await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALE_ORDER_STATUS}/QT`)
        const resSolution = await apiServices.callApi('get', `${API_PATH.GET_MASTER_QUOTATION_SOLUTION}/TH`)
        const resSaleReject = await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALE_REJECT}/QT`)

        if (resChannel.status === 200
            && resOutlet.status === 200
            && resDocStatus.status === 200
            && resSolution.status === 200
            && resSaleReject.status === 200) {
            let channels = get(resChannel, 'data.results')
            let outlets = get(resOutlet, 'data.results')
            let docStatuses = get(resDocStatus, 'data.results')
            const solutions = get(resSolution, 'data.results')
            const saleRejects = get(resSaleReject, 'data.results')
            if (channels && outlets && docStatuses) {
                channels = channels.map(data => ({
                    ...data,
                    value: data.saleChannelCode,
                    label: data.saleChannelDescription,
                }))
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }))
                docStatuses = resDocStatus.data.results.map(data => ({
                    ...data,
                    label: data.statusDescription,
                    value: data.statusCode,
                }))
                this.setState({
                    channels,
                    outlets,
                    outlet: outlets[0].value,
                    docStatuses,
                    solutions,
                    saleRejects,
                    filter: { ...filter, outlet: outlets[0].value },
                })
            }
        } else {
            swal('Error', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง', 'error')
        }
        this.setState({ loading: false })
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    handleShowModal = (modalType) => {
        if (modalType === 'saleEmployee') {
            this.onSearchSaleEmployee()
        }
        this.setState({ isShowModal: true, modalType })
    }

    handleCloseModal = () => {
        this.setState({ isShowModal: false, modalType: '' })
    }

    onChangePageCurrent = (page, type) => {
        const { pagination } = this.state
        const paginationNew = { ...pagination }
        paginationNew.current[type] = page
        this.setState({ pagination: paginationNew })
    }

    onSearchCustomer = async (typeKeyword, keyword) => {
        this.setState({ loading: true })
        const url = `${API_PATH.GET_CUSTOMER}?${typeKeyword}=${keyword}&RequireSoldTo=true`
        await apiServices.callApi('get', url).then(res => {
            const customers = get(res, 'data.results')
            if (customers) {
                this.setState({ customers })
            }
        }).catch(error => {
            const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            console.error(get(error, 'response.data.errors'))
        })
        this.setState({ loading: false })
    }

    onSelectedCustomer = (data) => {
        this.changeFilter('customer', data.customerId)
        this.handleCloseModal()
    }

    onSearchSaleEmployee = async () => {
        this.setState({ loading: true })
        const { filter } = this.state
        const url = `${API_PATH.GET_SALE_EMPLOYEE}?OutletId=${filter.outlet}`
        await apiServices.callApi('get', url).then(res => {
            const saleEmployees = get(res, 'data.results')
            if (saleEmployees) {
                this.setState({ saleEmployees })
            }
        }).catch(error => {
            const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            console.error(get(error, 'response.data.errors'))
        })
        this.setState({ loading: false })
    }

    onSelectedSaleEmployee = (data) => {
        this.changeFilter('saleEmployee', data.saleEmployeeId)
        this.handleCloseModal()
    }

    changeQuotationNumber = (e) => {
        this.setState({ quotationNumber: e.target.value })
    }

    changeOutlet = (outlet) => {
        this.setState({ outlet })
    }

    changeDataType = (e) => {
        this.setState({ dataType: e.target.value })
    }

    searchQuotationNumber = async (e) => {
        e.preventDefault()
        const { quotationNumber, outlet } = this.state
        if (!quotationNumber) return

        await apiServices.callApi('get', `${API_PATH.GET_QUOTATION}?quotationnumber=${quotationNumber}&outletId=${outlet}`).then(res => {
            const resQuotation = get(res, 'data.0')
            if (resQuotation) {
                this.props.history.push(`${ROUTH_PATH.QUOTATION_UPDATE_LINK}/${quotationNumber}/${outlet}`)
            } else {
                throw res
            }
        }).catch(error => {
            swal('Error', 'ไม่พบข้อมูลใบเสนอราคา', 'error')
        })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (!filter.startDate || !filter.endDate) return { isError: true, message: 'กรุณาระบุวันที่เอกสาร' }
        if ((filter.endDate).isBefore(filter.startDate)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }
        const duration = moment.duration(filter.endDate.diff(filter.startDate))
        if (duration.as('days') > 31) return { isError: true, message: 'กรุณาระบุวันที่เอกสารไม่เกิน 31 วัน' }
        if (!filter.outlet) return { isError: true, message: 'กรุณาระบุร้านค้า' }
        return { isError: false }
    }

    getBody = () => {
        const { filter, dataType, dateFormatAPI, channels } = this.state
        return {
            startDate: filter.startDate.format(dateFormatAPI),
            endDate: filter.endDate.format(dateFormatAPI),
            customerName: filter.customer,
            jobId: filter.jobId,
            outletID: filter.outlet,
            saleChannels: filter.channel ? [filter.channel] : map(channels, 'value'),
            userId: filter.user,
            saleEmpCode: filter.saleEmployee,
            docStatus: filter.docStatus,
            dataType,
            module: "reportquotation",
        }
    }

    searchQuotation = async () => {
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody()
        //await apiServices.callApi('post', API_PATH.POST_QUOTATION_LIST, body).then(res => {
        await apiServices.callApi('post', API_PATH.POST_GET_REPORT_QUOTATION_LIST, body).then(res => {
            const quotations = get(res, 'data', [])
            if (res.status === 200) {
                if (isEmpty(quotations)) {
                    this.setState({ quotationHeads: [], quotationItems: [] })
                    return
                }

                const shippingPoint = get(quotations, '0.quotationItemModelList.0.shippingPoint')
                const quotationHeads = quotations.map(quotation => {
                    return {
                        ...omit(quotation, ['quotationItemModelList', 'quotationPartnerFunctionModelList', 'quotationRevisionModelList']),
                        shippingPoint,
                        revision: last(quotation.quotationRevisionModelList) || {},
                    }
                })

                this.setState({
                    quotationHeads,
                    quotationItems: flatten(map(quotations, 'quotationItemModelList')),
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    exportData = async () => {
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody()
        await apiServices.callApi('post', API_PATH.POST_EXPORT_QUOTATIONS, body, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `ReportQuotationFile-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            quotationHeads,
            quotationItems,
            quotationNumber,
            customers,
            outlets,
            outlet,
            channels,
            saleEmployees,
            docStatuses,
            modalType,
            loading,
            columnsHead,
            columnsItem,
            dateFormat,
            widthLabel,
            isShowModal,
            filter,
            dataTypes,
            dataType,
            pagination,
        } = this.state

        return (
            <div className="report-quotation-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="ใบเสนอราคา"
                        icon="file-text"
                        nodeRight={
                            <div className="search-quotation-box">
                                <div className="text-search">เลขที่ใบเสนอราคา</div>
                                <div className="text-search" style={{ width: '180px' }}>
                                    <SelectHero
                                        name="outlet"
                                        options={outlets}
                                        value={outlet}
                                        small
                                        onChange={this.changeOutlet}
                                    />
                                </div>
                                <InputSearch
                                    small
                                    value={quotationNumber}
                                    onChange={this.changeQuotationNumber}
                                    handleClickSearch={this.searchQuotationNumber}
                                    handleSubmitSearch={this.searchQuotationNumber} />
                            </div>
                        }
                    />
                    <div className="middle-content">
                        <Row gutter={8}>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="startDate"
                                    label="วันที่เอกสาร"
                                    value={filter.startDate}
                                    format={dateFormat}
                                    small
                                    allowClear={false}
                                    onChange={(date) => this.changeFilter('startDate', date)}
                                />
                            </Col>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="endDate"
                                    label="ถึง"
                                    value={filter.endDate}
                                    format={dateFormat}
                                    small
                                    allowClear={false}
                                    onChange={(date) => this.changeFilter('endDate', date)}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputSearch
                                    handleClickSearch={this.handleShowModal.bind(this, 'customer')}
                                    handleSubmitSearch={(e) => e.preventDefault()}
                                    label="ลูกค้า"
                                    small
                                    onChange={(e) => this.changeFilter('customer', e.target.value)}
                                    value={filter.customer}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputSearch
                                    handleClickSearch={this.handleShowModal.bind(this, 'jobId')}
                                    handleSubmitSearch={(e) => e.preventDefault()}
                                    label="Job ID"
                                    small
                                    onChange={(e) => this.changeFilter('jobId', e.target.value)}
                                    value={filter.jobId}
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    name="outlet"
                                    label="ร้านค้า"
                                    options={outlets}
                                    value={filter.outlet}
                                    small
                                    onChange={(val) => this.changeFilter('outlet', val)}
                                />
                            </Col>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    name="channel"
                                    label="ช่องทาง"
                                    options={channels}
                                    optionsDefault={[{ value: '', label: 'ทั้งหมด' }]}
                                    value={filter.channel}
                                    small
                                    onChange={(val) => this.changeFilter('channel', val)}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputSearch
                                    handleClickSearch={this.handleShowModal.bind(this, 'userId')}
                                    handleSubmitSearch={(e) => e.preventDefault()}
                                    label="User ID"
                                    small
                                    onChange={(e) => this.changeFilter('user', e.target.value)}
                                    value={filter.user}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputSearch
                                    handleClickSearch={this.handleShowModal.bind(this, 'saleEmployee')}
                                    handleSubmitSearch={(e) => e.preventDefault()}
                                    label="พนักงานขาย"
                                    small
                                    onChange={(e) => this.changeFilter('saleEmployee', e.target.value)}
                                    value={filter.saleEmployee}
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    optionsDefault={[{ value: '', label: 'ทั้งหมด' }]}
                                    label="สถานะเอกสาร"
                                    options={docStatuses}
                                    value={filter.docStatus}
                                    small
                                    onChange={(val) => this.changeFilter('docStatus', val)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={8} >
                            <Col sm={6} style={{ paddingTop: '8px' }}>
                                <RadioCustom
                                    small
                                    type="group"
                                    onChange={this.changeDataType}
                                    options={dataTypes}
                                    value={dataType}
                                />
                            </Col>
                            <Col sm={18} className="search-box text-right">
                                <div className="action-box">
                                    <div className="button">
                                        <ButtonCustom
                                            icon="file-excel"
                                            text="Export"
                                            green
                                            small
                                            onClick={this.exportData}
                                        />
                                    </div>
                                    <div className="button">
                                        <ButtonCustom
                                            icon="search"
                                            type="primary"
                                            text="ค้นหา"
                                            small
                                            onClick={this.searchQuotation}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-layout">
                            {dataType === 'detail' ?
                                <TableCustom
                                    columns={columnsItem}
                                    data={quotationItems}
                                    scroll={{ x: "max-content" }}
                                    rowKey={(row, index) => index}
                                    small
                                    pagination={{
                                        pageSize: pagination.limit,
                                        current: pagination.current.detail || 1,
                                        onChange: (page) => this.onChangePageCurrent(page, 'detail'),
                                        showTotal: (total, range) => (`Showing ${range[0]} to ${range[1]} of ${total} entries`),
                                    }}
                                />
                                :
                                <TableCustom
                                    columns={columnsHead}
                                    data={quotationHeads}
                                    scroll={{ x: "max-content" }}
                                    rowKey={(row, index) => index}
                                    small
                                    pagination={{
                                        pageSize: pagination.limit,
                                        current: pagination.current.head || 1,
                                        onChange: (page) => this.onChangePageCurrent(page, 'head'),
                                        showTotal: (total, range) => (`Showing ${range[0]} to ${range[1]} of ${total} entries`),
                                    }}
                                />
                            }
                        </div>
                    </div>
                    <ModalCustomerSearch
                        isShowModal={isShowModal && modalType === 'customer'}
                        handleCloseModal={this.handleCloseModal}
                        loading={loading}
                        onSearch={this.onSearchCustomer}
                        onSelected={this.onSelectedCustomer}
                        data={customers}
                    />
                    <ModalSaleEmployee
                        isShowModal={isShowModal && modalType === 'saleEmployee'}
                        handleCloseModal={this.handleCloseModal}
                        loading={loading}
                        onSelected={this.onSelectedSaleEmployee}
                        data={saleEmployees}
                    />
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportQuotationContainer)