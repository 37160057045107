import React, { Component } from 'react'
import { Checkbox } from "antd"
import './style.css'

export class CheckBoxCustom extends Component {
    render() {
        const {
            name,
            onChange,
            text,
            checked,
            disabled,
        } = this.props
        return (
            <Checkbox onChange={onChange} checked={checked} name={name} disabled={disabled}>{text}</Checkbox>
        )
    }
}
