import React, { Component } from 'react'
import { Checkbox } from 'antd';
import { InputFormReduxStyled } from "./styled"
import './style.css'

const optionsMockup = [
    { label: 'Aa', value: 'Aa' },
    { label: 'Bb', value: 'Bb' },
    { label: 'Cc', value: 'Cc' },
];

export class CheckBoxGroupRedux extends Component {
    render() {
        const {
            options,
            input,
            label,
            right,
            meta: { touched, error },
            submitViewError,
            bold,
            small,
            widthSmall,
            widthDefault,
            value,
        } = this.props
        return (
            <InputFormReduxStyled
                error={(touched || submitViewError) && error ? true : false}
                small={small}
                widthSmall={widthSmall}
                widthDefault={widthDefault}
            >
                    <div className="input-form checkbox-group-redux" >
                        {
                            label &&
                            <div className="label-style" style={{ textAlign: right ? 'right' : '' }}>
                                {bold ? <b>{label}</b> : label}
                            </div>
                        }
                        {
                            <Checkbox.Group
                                options={options ? options : optionsMockup}
                                value={value ? value : []}
                                onChange={e =>
                                    input.onChange(e)
                                }
                            />
                        }
                        <div className="error-message">
                            {(touched || submitViewError) ? error : ''}
                        </div>
                    </div>

            </InputFormReduxStyled>
        )
    }
}