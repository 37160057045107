import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, uniqWith } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { formatNumber, getPermissionPage } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    InputCustom,
    RadioCustom,
} from 'components/common'
import { Row, Col, Spin, Icon, Popover } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { functionAlias } from 'routes'
import FileSaver from 'file-saver'
import moment from 'moment'
import swal from '@sweetalert/with-react'
import ModalProduct from './ModalProduct'

import './style.css'

import barcode40x40 from './images/40x40.png'
import barcode40x40Pro from './images/40x40pro.png'
import barcode75x40 from './images/75x40.png'
import barcode75x40Pro from './images/75x40pro.png'
import barcodeA3_4 from './images/A3-4.png'
import barcodeA3_4Pro from './images/A3-4pro.png'
import barcodeA5_6 from './images/A5-6.png'
import barcodeA5_6Pro from './images/A5-6pro.png'
import barcode_Sticker from './images/Sticker.PNG'

import portrait_a4 from './images/01-portrait-a4.jpg'
import pro_portrait_a4 from './images/02-pro-portrait-a4.jpg'
import landscape_a4 from './images/03-landscape-a4.jpg'
import pro_landscape_a4 from './images/04-pro-landscape-a4.jpg'
import landscape_a5 from './images/05-landscape-a5.jpg'
import pro_landscape_a5 from './images/06-pro-landscape-a5.jpg'
import portrait_a5 from './images/07-portrait-a5.jpg'
import pro_portrait_a5 from './images/08-pro-portrait-a5.jpg'

class ProductBarcodeContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: '',
                    dataIndex: 'delete',
                    key: 'delete',
                    align: 'center',
                    width: 50,
                    render: (props, row, index) => (<ButtonCustom
                        small
                        text="ลบ"
                        type="danger"
                        className="column-delete"
                        onClick={(e) => this.deleteProduct(index)}
                    />)
                },
                {
                    title: 'รหัสสินค้า',
                    dataIndex: 'productId',
                    key: 'productId',
                    align: 'center',
                },
                {
                    title: 'ชื่อสินค้า',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: 'center',
                    width: 220,
                    render: (value) => (
                        <div className="text-left">{value}</div>
                    )
                },
                {
                    title: 'Barcode',
                    dataIndex: 'productBarcode',
                    key: 'productBarcode',
                    align: 'center',
                },
                {
                    title: 'หน่วย',
                    dataIndex: 'unit',
                    key: 'unit',
                    align: 'center',
                },
                {
                    title: 'ราคาขายปกติ',
                    dataIndex: 'normalPrice',
                    key: 'normalPrice',
                    align: 'center',
                    render: (value) => (
                        <div className="text-right">
                            {formatNumber(value || 0, 2)}
                        </div>
                    )
                },
                {
                    title: 'ราคาโปรโมชั่น',
                    dataIndex: 'promotionPrice',
                    key: 'promotionPrice',
                    align: 'center',
                    render: (value) => (
                        <div className="text-right">
                            {formatNumber(value || 0, 2)}
                        </div>
                    )
                },
                {
                    title: 'จำนวนดวงที่ต้องการ',
                    dataIndex: 'requiredAmount',
                    key: 'requiredAmount',
                    align: 'center',
                    width: 150,
                    render: (value, row, index) => (
                        <InputCustom
                            onChange={(e) => this.changeProductData('requiredAmount', Number(e.target.value), index, row)}
                            type="number"
                            value={value}
                        />
                    )
                },
            ],
            barcodeTypes: [
                {
                    text: 'ป้ายราคาปกติ(40 x 40)',
                    value: '40x40',
                    // set label in function setCheckboxContent
                    label: '',
                    labelType: '40x40',
                    priceType: 'Normal',
                    image: barcode40x40,
                    fileNamePrint: 'LabelNormal40x40',
                },
                {
                    text: 'ป้ายราคาโปรโมชั่น(40 x 40)',
                    value: '40x40pro',
                    label: '',
                    labelType: '40x40',
                    priceType: 'Promotion',
                    image: barcode40x40Pro,
                    fileNamePrint: 'LabelPromotion40x40',
                },
                {
                    text: 'ป้ายราคาปกติ(75 x 40)',
                    value: '75x40',
                    label: '',
                    labelType: '75x40',
                    priceType: 'Normal',
                    image: barcode75x40,
                    fileNamePrint: 'LabelNormal75x40',
                },
                {
                    text: 'ป้ายราคาโปรโมชั่น(75 x 40)',
                    value: '75x40pro',
                    label: '',
                    labelType: '75x40',
                    priceType: 'Promotion',
                    image: barcode75x40Pro,
                    fileNamePrint: 'LabelPromotion75x40',
                },
                {
                    text: 'ป้ายราคาปกติ(A3)',
                    value: 'A3',
                    label: '',
                    labelType: 'A3',
                    priceType: 'Normal',
                    image: barcodeA3_4,
                    fileNamePrint: 'LabelNormalA3',
                },
                {
                    text: 'ป้ายราคาโปรโมชั่น(A3)',
                    value: 'A3pro',
                    label: '',
                    labelType: 'A3',
                    priceType: 'Promotion',
                    image: barcodeA3_4Pro,
                    fileNamePrint: 'LabelPromotionA3',
                },
                // {
                //     text: 'ป้ายราคาปกติ(A4)',
                //     value: 'A4',
                //     label: '',
                //     labelType: 'A4',
                //     priceType: 'Normal',
                //     image: barcodeA3_4,
                //     fileNamePrint: 'LabelNormalA4',
                // },
                // {
                //     text: 'ป้ายราคาโปรโมชั่น(A4)',
                //     value: 'A4pro',
                //     label: '',
                //     labelType: 'A4',
                //     priceType: 'Promotion',
                //     image: barcodeA3_4Pro,
                //     fileNamePrint: 'LabelPromotionA4',
                // },
                // ============================

                {
                    text: 'ป้ายราคาปกติแนวตั้ง (A4)',
                    value: 'A4',
                    label: '',
                    labelType: 'A4',
                    priceType: 'Normal',
                    image: portrait_a4,
                    fileNamePrint: 'LabelNormalA4',
                },
                {
                    text: 'ป้ายราคาโปรโมชั่นแนวตั้ง (A4)',
                    value: 'A4pro',
                    label: '',
                    labelType: 'A4',
                    priceType: 'Promotion',
                    image: pro_portrait_a4,
                    fileNamePrint: 'LabelPromotionA4',
                },
                {
                    text: 'ป้ายราคาปกติแนวนอน (A4)',
                    value: 'A4Landscape',
                    label: '',
                    labelType: 'A4Landscape',
                    priceType: 'Normal',
                    image: landscape_a4,
                    fileNamePrint: 'LabelNormalA4',
                },
                {
                    text: 'ป้ายราคาโปรโมชั่นแนวนอน (A4)',
                    value: 'A4Landscapepro',
                    label: '',
                    labelType: 'A4Landscape',
                    priceType: 'Promotion',
                    image: pro_landscape_a4,
                    fileNamePrint: 'LabelPromotionA4',
                },
                // ============================
                // {
                //     text: 'ป้ายราคาปกติ(A5)',
                //     value: 'A5',
                //     label: '',
                //     labelType: 'A5',
                //     priceType: 'Normal',
                //     image: barcodeA5_6,
                //     fileNamePrint: 'LabelNormalA5',
                // },
                // {
                //     text: 'ป้ายราคาโปรโมชั่น(A5)',
                //     value: 'A5pro',
                //     label: '',
                //     labelType: 'A5',
                //     priceType: 'Promotion',
                //     image: barcodeA5_6Pro,
                //     fileNamePrint: 'LabelPromotionA5',
                // },
                // ============================
                {
                    text: 'ป้ายราคาปกติ (A5)',
                    value: 'A5',
                    label: '',
                    labelType: 'A5',
                    priceType: 'Normal',
                    image: landscape_a5,
                    fileNamePrint: 'LabelNormalA5',
                },
                {
                    text: 'ป้ายราคาโปรโมชั่น (A5)',
                    value: 'A5pro',
                    label: '',
                    labelType: 'A5',
                    priceType: 'Promotion',
                    image: pro_landscape_a5,
                    fileNamePrint: 'LabelPromotionA5',
                },
                {
                    text: 'ป้ายราคาปกติแนวนอน (A5)',
                    value: 'A5Landscape',
                    label: '',
                    labelType: 'A5Landscape',
                    priceType: 'Normal',
                    image: portrait_a5,
                    fileNamePrint: 'LabelNormalA5',
                },
                {
                    text: 'ป้ายราคาโปรโมชั่นแนวนอน (A5)',
                    value: 'A5Landscapepro',
                    label: '',
                    labelType: 'A5Landscape',
                    priceType: 'Promotion',
                    image: pro_portrait_a5,
                    fileNamePrint: 'LabelPromotionA5',
                },
                // ============================
                {
                    text: 'ป้ายราคาปกติ(A6)',
                    value: 'A6',
                    label: '',
                    labelType: 'A6',
                    priceType: 'Normal',
                    image: barcodeA5_6,
                    fileNamePrint: 'LabelNormalA6',
                },
                {
                    text: 'ป้ายราคาโปรโมชั่น(A6)',
                    value: 'A6pro',
                    label: '',
                    labelType: 'A6',
                    priceType: 'Promotion',
                    image: barcodeA5_6Pro,
                    fileNamePrint: 'LabelPromotionA6',
                }, {
                    text: 'สติกเกอร์ติดสินค้า',
                    value: 'Sticker',
                    label: '',
                    labelType: 'Sticker',
                    priceType: 'Normal',
                    image: barcode_Sticker,
                    fileNamePrint: 'LabelSticker',
                },
            ],
            barcodeTypeData: {},
            loading: false,
            isShowModal: false,
            products: [],
            barcodeType: '',
            startBarcodeSeq: '',
            outletId: '',
            permissionPage: getPermissionPage(functionAlias.productLabeling),
        }
    }

    componentDidMount() {
        this.setCheckboxContent()
    }

    setCheckboxContent = () => {
        const { barcodeTypes } = this.state
        const data = barcodeTypes.map(barcodeType => ({
            ...barcodeType,
            label: (<div className="checkbox-barcode-type-box">
                <div>{barcodeType.text}</div>
                <div><img src={barcodeType.image} alt={barcodeType.text} /></div>
            </div>)
        }))
        this.setState({ barcodeTypes: data })
    }

    deleteProduct = (index) => {
        const { products } = this.state
        const productsNew = [...products]
        productsNew.splice(index, 1)
        this.setState({ products: productsNew })
    }

    onChangeBarcodeType = (e) => {
        const barcodeType = e.target.value;
        const barcodeTypeData = this.state.barcodeTypes.find(barcodeData => barcodeData.value === barcodeType);
        this.setState({ barcodeType, barcodeTypeData, products: [] })
    }

    onChangeStartBarcodeSeq = (e) => {
        this.setState({ startBarcodeSeq: e.target.value })
    }

    changeProductData = (key, value, index, row) => {
        this.setState({
            // products: this.state.products.map((product, i) => (i === index ? { ...product, [key]: value } : product)),
            products: this.state.products.map((product, i) => {
                if (product.productBarcode === row.productBarcode &&
                    product.productId === row.productId &&
                    product.productName === row.productName &&
                    product.unit === row.unit &&
                    product.index === row.index) {
                    return { ...product, [key]: value }
                } else {
                    return product
                }
            })
        })
    }

    onSelectProducts = (productsSelected, filter) => {
        const { products } = this.state
        let productsUniq = []
        if (!isEmpty(products)) {
            // check dup
            productsUniq = uniqWith([...products, ...productsSelected], (arrVal, otherVal) => (
                arrVal.productId === otherVal.productId && arrVal.productBarcode === otherVal.productBarcode && arrVal.productName === otherVal.productName
            ))
        } else {
            productsUniq = productsSelected
        }

        productsUniq = productsUniq.map(product => ({
            ...product,
            requiredAmount: 1,
            startDateEffect: filter.startDateEffect,
            endDateEffect: filter.endDateEffect,
            startDate: filter.startDate,
            endDate: filter.endDate,
        }))
        this.setState({ products: productsUniq, outletId: filter.outletId })
        this.closeModal()
    }

    validateData = () => {
        const { products, barcodeTypeData } = this.state
        let result = { isError: false, message: '' }
        if (isEmpty(products)) {
            return { isError: true, message: 'กรุณากรอกระบุข้อมูลสินค้าให้ครบถ้วน' }
        }
        if (isEmpty(barcodeTypeData)) {
            return { isError: true, message: 'ไม่พบข้อมูล barcode' }
        }
        return result
    }

    getBody = () => {
        const { products, outletId, barcodeTypeData } = this.state
        const barcodes = products.map(barcode => ({
            ...barcode,
            startDateEffect: barcode.startDateEffect && barcode.startDateEffect.format(),
            endDateEffect: barcode.startDateEffect && barcode.endDateEffect.format(),
            startDate: barcode.startDate && barcode.startDate.format(),
            endDate: barcode.startDate && barcode.endDate.format(),
        }))
        return {
            labelType: barcodeTypeData.labelType,
            priceType: barcodeTypeData.priceType,
            outletId,
            barcodes,
            fileName: barcodeTypeData.fileNamePrint,
            storageLocationIds: this.props.auth.arrayStorageLocationId,
        }
    }

    onClickPrintBarcode = async () => {
        const valid = this.validateData()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody()
        // console.log('body',body);

        await apiServices.callApi('post', API_PATH.POST_GET_LABELING, body, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/pdf' })
                FileSaver.saveAs(blob, `${body.outletId}-${body.fileName}-${moment().format('DD-MM-YYYY')}.pdf`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการพิมพ์ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    openModal = () => {
        this.setState({ isShowModal: true })
    }

    closeModal = () => {
        this.setState({ isShowModal: false })
    }

    render() {
        const {
            loading,
            columns,
            startBarcodeSeq,
            barcodeTypes,
            barcodeType,
            products,
            isShowModal,
            barcodeTypeData,
            permissionPage,
        } = this.state

        return (
            <div className="product-barcode-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="ป้ายราคาสินค้า"
                        icon="barcode"
                    />
                    <div className="middle-content">
                        <div>
                            <div className="font-weight-bold">
                                ประเภทป้ายราคา
                            </div>
                            <div className="barcode-type-box">
                                <RadioCustom
                                    options={barcodeTypes}
                                    value={barcodeType}
                                    onChange={this.onChangeBarcodeType}
                                    onClick={this.openModal}
                                    disabled={!permissionPage.authCreate}
                                />
                            </div>
                        </div>
                        <Row gutter={8} type="flex" align="middle" className="action-box">
                            <Col sm={8}>
                                <InputCustom
                                    label="เริ่มต้นที่ดวง : "
                                    onChange={this.onChangeStartBarcodeSeq}
                                    widthLabel="110px"
                                    value={startBarcodeSeq}
                                    // disabled={!barcodeType}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <ButtonCustom
                                    icon="printer"
                                    text="พิมพ์"
                                    yellow
                                    onClick={this.onClickPrintBarcode}
                                    disabled={isEmpty(products) || !permissionPage.authPrint}
                                />
                            </Col>
                            <Col>
                                <Popover
                                    content={
                                        <div style={{ width: '200px' }}>
                                            หลังจากพิมพ์ Barcode ชื่อที่แสดงบนป้ายราคา/สติ๊กเกอร์ จะแสดงเป็นชื่ออย่างย่อ
                                        </div>
                                    }
                                >
                                    <Icon type="info-circle" />
                                </Popover>
                            </Col>
                            <Col>
                                <ButtonCustom
                                    icon="search"
                                    text="ค้นหาสินค้า"
                                    onClick={this.openModal}
                                    disabled={!barcodeType || !permissionPage.authCreate}
                                />
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                small
                                isFullContent
                                data={products}
                                rowKey={(row, index) => index}
                            />
                        </div>
                    </div>
                </Spin>
                <ModalProduct
                    isShowModal={isShowModal}
                    closeModal={this.closeModal}
                    onSelectProducts={this.onSelectProducts}
                    priceType={barcodeTypeData.priceType}
                    disabledOutletId={!isEmpty(products)}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBarcodeContainer)