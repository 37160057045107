import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Collapse, Icon, Spin, Modal } from "antd"
import { TitleHead, RadioCustom, SelectHero, RangePickerCustom, InputHero, InputSearch, TableCustom, DatePickerCustom, ButtonCustom, ModalCustom } from "components"
import { reportSaleOrderType, showStatusSaleOrder, reportSaleType, reasonRejectType, categoryList, documentType } from "helpers"
import './style.css'
import { authData, searchCriteriaSOReportData } from 'actions/actionsCreator';
import { apiServices, API_PATH } from "apiServices"
import swal from '@sweetalert/with-react'
import { isEmpty, get, find } from 'lodash'
import { map } from 'lodash'
import { Link } from 'react-router-dom'
import { ROUTH_PATH } from 'routes'
import ProductMultiSelectModal from 'components/feature/ProductMultiSelectModal'
const { Panel } = Collapse;

class AdvanceSearch extends Component {
    state = {
        isProductFromModal: false,
        isProductToModal: false,
        dateFormat: 'DD/MM/YYYY',
        saleChannels: [],
        saleShippingPoints: [],
    }

    componentDidMount() {
        this.fatchData();
    }

    changeFormatDate = (value) => {
        if (value) {
            var d = new Date(value),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('-');
        }
        return value;
    }

    fatchData = async () => {
        const { auth, searchCriteriaSOReport } = this.props

        let paramsOutlet = {
            outletIds: auth.arrayOutlet,
        }

        await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL).then(res => {
            if (res.status === 200) {
                let saleChannels = res.data.results;
                saleChannels = saleChannels.map(data => ({
                    ...data,
                    value: data.saleChannelCode,
                    label: `${data.saleChannelCode} - ${data.saleChannelDescription}`,
                }))

                this.setState({ saleChannels });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_SHIPPING_POINT).then(res => {
            if (res.status === 200) {
                let saleShippingPoints = res.data.results;
                saleShippingPoints = saleShippingPoints.map(data => ({
                    ...data,
                    value: data.shippingPointCode,
                    label: `${data.shippingPointCode} - ${data.shippingPointDescription}`,
                }))

                this.setState({ saleShippingPoints });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet).then(res => {
            if (res.status === 200) {
                let outlets = res.data.results;
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }))

                this.setState({ outlets, outletDefault: outlets[0].value });

            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_CATEGORY).then(res => {
            if (res.status === 200) {
                let productCategories = res.data.results;
                productCategories = productCategories.map(data => ({
                    ...data,
                    value: data.categoryCode,
                    label: `${data.categoryCode} - ${data.descriptionThai}`,
                }))

                this.setState({ productCategories });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('get', `${API_PATH.GET_USER}?outletId=${auth.arrayOutlet.join(',')}`).then(res => {
            if (res.status === 200) {
                let creators = res.data.results;
                creators = creators.map(data => ({
                    ...data,
                    value: data.userId,
                    label: `${data.userId} - ${data.firstName}`,
                }));

                this.setState({ creators });
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        const { outletDefault } = this.state
        await apiServices.callApi('get', `${API_PATH.GET_SALE_EMPLOYEE}?outletId=${outletDefault}`).then(res => {
            if (res.status === 200) {
                let saleEmployees = res.data.results;
                saleEmployees = saleEmployees.map(data => ({
                    ...data,
                    value: data.saleEmployeeId,
                    label: `${data.saleEmployeeId} - ${data.saleEmployeeName}`,
                }))

                this.setState({ saleEmployees });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('get', API_PATH.GET_MASTER_CUSTOMER_GROUP).then(res => {
            if (res.status === 200) {
                let customerGroups = res.data.results;
                customerGroups = customerGroups.map(data => ({
                    ...data,
                    value: data.customerGroupId,
                    label: `${data.customerGroupId} - ${data.customerGroupDescription}`,
                }))

                this.setState({ customerGroups });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('get', API_PATH.GET_MASTER_PAYMENT_TERM).then(res => {
            if (res.status === 200) {
                let paymentTerms = res.data.results;
                paymentTerms = paymentTerms.map(data => ({
                    ...data,
                    value: data.paymentTermCode,
                    label: `${data.paymentTermCode} - ${data.paymentTermDescription}`,
                }))

                this.setState({ paymentTerms });

            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.props.searchCriteriaSOReportData({ outletSelected: outletDefault });
    }

    handleChangeSaleChannel = (value) => {
        const { searchCriteriaSOReport } = this.props
        let saleChannelSelected = [];
        if (value.includes('all')) {
            saleChannelSelected = [];
        } else {
            saleChannelSelected = value;
        }
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, saleChannelSelected: saleChannelSelected });
    }

    handleChangeSaleShippingPoint = (value) => {
        const { searchCriteriaSOReport } = this.props

        let saleShippingPointSelected = [];
        if (value.includes('all')) {
            saleShippingPointSelected = [];
        } else {
            saleShippingPointSelected = value;
        }
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, saleShippingPointSelected: saleShippingPointSelected });
    }

    handleChangeReasonReject = (value) => {
        const { searchCriteriaSOReport } = this.props
        let reasonRejectTypeSelected = [];
        if (value.includes('all')) {
            reasonRejectTypeSelected = [];
        } else {
            reasonRejectTypeSelected = value;
        }
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, reasonRejectTypeSelected: reasonRejectTypeSelected });
    }

    handleChangeOutlet = (value) => {
        const { searchCriteriaSOReport } = this.props
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, outletSelected: value });
    }

    handleChangeCreator = (value) => {
        const { searchCriteriaSOReport } = this.props
        let creatorSelected = [];
        if (value.includes('all')) {
            creatorSelected = [];
        } else {
            creatorSelected = value;
        }
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, creatorSelected: creatorSelected });
    }

    handleChangeProductCategory = (value) => {
        const { searchCriteriaSOReport } = this.props
        let productCategorySelected = [];
        if (value.includes('all')) {
            productCategorySelected = [];
        } else {
            productCategorySelected = value;
        }
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, productCategorySelected: productCategorySelected });
    }

    handleChangeCategoryList = (value) => {
        const { searchCriteriaSOReport } = this.props
        let categoryListSelected = [];
        if (value.includes('all')) {
            categoryListSelected = [];
        } else {
            categoryListSelected = value;
        }
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, categoryListSelected: categoryListSelected });
    }

    handleChangeSaleEmployee = (value) => {
        const { searchCriteriaSOReport } = this.props
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, saleEmployeeSelected: value });
    }

    handleChangeCustomerGroup = (value) => {
        const { searchCriteriaSOReport } = this.props
        let customerGroupSelected = [];
        if (value.includes('all')) {
            customerGroupSelected = [];
        } else {
            customerGroupSelected = value;
        }
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, customerGroupSelected: customerGroupSelected });
    }

    handleChangePaymentTerm = (value) => {
        const { searchCriteriaSOReport } = this.props
        let paymentTermSelected = [];
        if (value.includes('all')) {
            paymentTermSelected = [];
        } else {
            paymentTermSelected = value;
        }
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, paymentTermSelected: paymentTermSelected });
    }

    handleChangeCreatedDateFrom = (value) => {
        const { searchCriteriaSOReport } = this.props
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, createdDateFrom: value });
    }

    handleChangeCreatedDateTo = (value) => {
        const { searchCriteriaSOReport } = this.props
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, createdDateTo: value });
    }

    handleChangeDocumentType = (value) => {
        const { searchCriteriaSOReport } = this.props
        let documentTypeSelected = [];
        if (value.includes('all')) {
            documentTypeSelected = [];
        } else {
            documentTypeSelected = value;
        }
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, documentTypeSelected: documentTypeSelected });
    }

    handleChangeProductCodeFrom = (e) => {
        const { searchCriteriaSOReport } = this.props
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, productCodeFrom: e.target.value });
    };

    handleChangeProductCodeTo = (e) => {
        const { searchCriteriaSOReport } = this.props
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, productCodeTo: e.target.value });
    };

    handleShowProductCodeFromModal = (e) => {
        this.setState({ isProductFromModal: true });
    }

    handleCloseProductCodeFromModal = (e) => {
        this.setState({ isProductFromModal: false });
    }

    handleClickOkProductFromModal = (products) => {
        const { searchCriteriaSOReport } = this.props
        let prodArray = products && products.map(data => (
            data.productId
        ));

        let productCodeFrom = prodArray ? prodArray.join(",") : '';
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, productCodeFrom: productCodeFrom });
    }

    handleShowProductCodeToModal = (e) => {
        this.setState({ isProductToModal: true });
    }

    handleCloseProductCodeToModal = (e) => {
        this.setState({ isProductToModal: false });
    }

    handleClickOkProductToModal = (products) => {
        const { searchCriteriaSOReport } = this.props
        let prodArray = products && products.map(data => (
            data.productId
        ));

        let productCodeTo = prodArray ? prodArray.join(",") : '';
        this.props.searchCriteriaSOReportData({ ...searchCriteriaSOReport, productCodeTo: productCodeTo });
    }

    render() {
        const {
            dateFormat,
            saleChannels,
            saleShippingPoints,
            outlets,
            creators,
            saleEmployees,
            customerGroups,
            paymentTerms,
            productCategories,
            isProductFromModal,
            isProductToModal,
        } = this.state

        const {
            searchCriteriaSOReport,
        } = this.props

        return (
            <div className="advanced-search-box">
                <Row gutter={20}>
                    <Col md={12}>
                        <Row gutter={20}>
                            <Col md={10}>
                                ช่องทางการจัดจำหน่าย
                            </Col>
                            <Col md={14}>
                                <SelectHero
                                    onChange={this.handleChangeSaleChannel}
                                    name="saleChannels"
                                    options={saleChannels}
                                    value={searchCriteriaSOReport.saleChannelSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={10}>
                                จุดจัดส่ง/จุดรับสินค้า
                            </Col>
                            <Col md={14}>
                                <SelectHero
                                    onChange={this.handleChangeSaleShippingPoint}
                                    name="saleShippingPoints"
                                    options={saleShippingPoints}
                                    value={searchCriteriaSOReport.saleShippingPointSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={10}>
                                เหตุผลการปฏิเสธ
                            </Col>
                            <Col md={14}>
                                <SelectHero
                                    onChange={this.handleChangeReasonReject}
                                    name="reasonRejectType"
                                    options={reasonRejectType}
                                    value={searchCriteriaSOReport.reasonRejectTypeSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={10}>
                                องค์กรทางการขาย
                            </Col>
                            <Col md={14}>
                                <SelectHero
                                    onChange={this.handleChangeOutlet}
                                    name="status"
                                    options={outlets}
                                    value={searchCriteriaSOReport.outletSelected}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={10}>
                                สร้างโดย
                            </Col>
                            <Col md={14}>
                                <SelectHero
                                    onChange={this.handleChangeCreator}
                                    name="creator"
                                    options={creators}
                                    value={searchCriteriaSOReport.creatorSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={10}>
                                กลุ่มสินค้า
                            </Col>
                            <Col md={14}>
                                <SelectHero
                                    onChange={this.handleChangeProductCategory}
                                    name="productCategories"
                                    options={productCategories}
                                    value={searchCriteriaSOReport.productCategorySelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12}>
                        <Row gutter={20}>
                            <Col md={10}>
                                หมวดรายการในเอกสารใบสั่งขาย
                            </Col>
                            <Col md={14}>
                                <SelectHero
                                    onChange={this.handleChangeCategoryList}
                                    name="categoryList"
                                    options={categoryList}
                                    value={searchCriteriaSOReport.categoryListSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={10}>
                                รหัสพนักงานขาย
                            </Col>
                            <Col md={14}>
                                <SelectHero
                                    onChange={this.handleChangeSaleEmployee}
                                    name="saleEmployees"
                                    options={saleEmployees}
                                    optionsDefault={[{ label: "ทั้งหมด", value: "" }]}
                                    value={searchCriteriaSOReport.saleEmployeeSelected}
                                    placeholder="ทั้งหมด"
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={10}>
                                กลุ่มลูกค้า
                            </Col>
                            <Col md={14}>
                                <SelectHero
                                    onChange={this.handleChangeCustomerGroup}
                                    name="customerGroups"
                                    options={customerGroups}
                                    value={searchCriteriaSOReport.customerGroupSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={10}>
                                เงื่อนไขการชำระ
                            </Col>
                            <Col md={14}>
                                <SelectHero
                                    onChange={this.handleChangePaymentTerm}
                                    name="paymentTerms"
                                    options={paymentTerms}
                                    value={searchCriteriaSOReport.paymentTermSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={10}>
                                สร้างเมื่อ
                            </Col>
                            <Col md={6}>
                                <DatePickerCustom
                                    onChange={this.handleChangeCreatedDateFrom}
                                    name="createdDateFrom"
                                    format={dateFormat}
                                    value={searchCriteriaSOReport.createdDateFrom}
                                />
                            </Col>
                            <Col md={2}>
                                ถึง
                            </Col>
                            <Col md={6}>
                                <DatePickerCustom
                                    onChange={this.handleChangeCreatedDateTo}
                                    name="createdDateTo"
                                    format={dateFormat}
                                    value={searchCriteriaSOReport.createdDateTo}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={10}>
                                รหัสสินค้า
                            </Col>
                            <Col md={6}>
                                <InputSearch
                                    onChange={this.handleChangeProductCodeFrom}
                                    placeholder='10000001'
                                    handleClickSearch={() => this.handleShowProductCodeFromModal()}
                                    value={searchCriteriaSOReport.productCodeFrom}
                                />
                            </Col>
                            <Col md={2}>
                                ถึง
                            </Col>
                            <Col md={6}>
                                <InputSearch
                                    onChange={this.handleChangeProductCodeTo}
                                    placeholder='10000099'
                                    handleClickSearch={() => this.handleShowProductCodeToModal()}
                                    value={searchCriteriaSOReport.productCodeTo}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={10}>
                                การเลือกเอกสารที่มีการระงับหรือการปฏิเสธ
                            </Col>
                            <Col md={14}>
                                <SelectHero
                                    onChange={this.handleChangeDocumentType}
                                    name="documentType"
                                    options={documentType}
                                    value={searchCriteriaSOReport.documentTypeSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ProductMultiSelectModal
                    isShowModal={isProductFromModal}
                    closeModal={this.handleCloseProductCodeFromModal}
                    onClickOkModal={this.handleClickOkProductFromModal}
                />
                <ProductMultiSelectModal
                    isShowModal={isProductToModal}
                    closeModal={this.handleCloseProductCodeToModal}
                    onClickOkModal={this.handleClickOkProductToModal}
                />
            </div>

        );
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        searchCriteriaSOReport: state.searchCriteriaSOReport
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData,
        searchCriteriaSOReportData,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearch);