import React, { Component } from 'react'
import { SelectReduxStyled } from "./styled"
import './style.css'
import { Select, Popover } from 'antd';

const { Option } = Select;

export class SelectRedux extends Component {
    render() {
        const {
            label,
            right,
            small,
            widthSmall,
            options,
            onChangeHero,
            input,
            disabled,
            submitViewError,
            meta: { touched, error },
            placeholder,
            pleaseChoose,
            firstChoose,
            widthDefault,
            widthAll,
        } = this.props;
        return (
            <SelectReduxStyled
                error={(touched || submitViewError) && error ? true : false}
                small={small}
                widthSmall={widthSmall}
                widthDefault={widthDefault}
            >
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div
                                className="label-style"
                                style={{
                                    textAlign: right ? 'right' : '',
                                }}
                            >
                                {label}
                            </div>
                        }
                        <Select
                            style={{
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    widthAll ? widthAll : ''
                                ,
                            }}
                            onChange={e => {
                                input.onChange(e)
                                onChangeHero && onChangeHero(e)
                            }}
                            value={input.value ? input.value : firstChoose && firstChoose === true ? options && options.length > 0 ? options[0].value : undefined : undefined}
                            disabled={disabled}
                            size={small ? 'small' : 'default'}
                            placeholder={placeholder ? placeholder : pleaseChoose && pleaseChoose === true ? "-- กรุณาเลือก --" : undefined}
                        >
                            {options && options.map((data, i) => (
                                <Option key={i} value={data.value}>
                                    <Popover placement="left" title={false} content={data.label}>
                                        {data.label}
                                    </Popover>
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="error-message-bottom">
                        {(touched || submitViewError) ? error : ''}
                    </div>
                </div>
            </SelectReduxStyled>
        )
    }
}
