import React from 'react'
import { Field, reduxForm } from 'redux-form'
import {

} from 'components'

class CustomerForm extends React.Component {
    state = {

    }

    render() {
        const {

        } = this.props
        return (
            <div>
                CustomerForm
           </div>
        )
    }
}

export default reduxForm({
    form: 'CustomerForm',  // a unique identifier for this form
    enableReinitialize: true
})(CustomerForm)
