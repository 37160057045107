import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col } from "antd"
import {
    InputSearch,
    InputFormRedux,
    DatePickerRedux,
    SelectRedux,
} from 'components'
import './style.css'
import { VALIDATE } from 'helpers'

const style = {
    topZone: {
        marginTop: 10
    },
    infoDataBox: {
        padding: 5
    },
    quotationBox: {
        padding: 5,
    }
}

class SaleOrderForm extends React.Component {
    state = {

    }

    render() {
        const {
            handleClickCustomerModal,
            handleClickSearch,
            handleClickShippingModal,
            handleChangeFetchEmplpyeeBySite,
            dataOutlet,
            dataTax,
            dataChannal,
            dataEmployee,
            dataSelectedCustomer,
            handleChangeSearch,
            valueInputSearch,
            dataDocType,
            dataPaymentTerm,
            dataSaleShippingPoint,
            disabledCustomer,
            handleSubmitSearchCustomer,
            dataSelectedCustomerShipTo,
            submitViewError,
            errorShipTo,
            errorCustomer,
            handleChangeSaleEmp,
        } = this.props
        return (
            <Row style={style.topZone}>
                <Col style={style.infoDataBox} sm={24} md={12}>
                    <div className="customer-data-box">
                        <div className="title-text">
                            ข้อมูลลูกค้า
                        </div>
                        <Row gutter={10} style={{ fontSize: "12px" }}>
                            <Col sm={24} md={24} style={{ display: 'flex' }}>
                                <InputSearch
                                    handleClickSearch={handleClickSearch}
                                    label="รหัสลูกค้า"
                                    small
                                    onChange={handleChangeSearch}
                                    value={valueInputSearch}
                                    handleSubmitSearch={handleSubmitSearchCustomer}
                                />
                                {
                                    dataSelectedCustomer ?
                                        <span className="click-detail-customer" onClick={() => handleClickCustomerModal("customer", dataSelectedCustomer)}>
                                            {
                                                dataSelectedCustomer.customerNameTH
                                                    ?
                                                    dataSelectedCustomer.customerNameTH
                                                    :
                                                    'ลูกค้าทั่วไป'
                                            }
                                        </span>
                                        :
                                        <React.Fragment>
                                            <span className="click-detail-customer" onClick={() => handleClickCustomerModal("customer")}>
                                                ลูกค้าทั่วไป

                                                </span>
                                            {
                                                (submitViewError && errorCustomer) &&
                                                <span style={{ color: 'red', paddingLeft: 10 }}>
                                                    * กรุณากรอกข้อมูลลูกค้า
                                                </span>
                                            }
                                        </React.Fragment>

                                }
                            </Col>
                            <Col style={{ display: 'flex' }} sm={24} md={24}>
                                <InputSearch
                                    label="สถานที่จัดส่ง"
                                    disabled
                                    small
                                    handleClickSearch={handleClickShippingModal}
                                    value={dataSelectedCustomerShipTo && dataSelectedCustomerShipTo.customerId}
                                />
                                {
                                    submitViewError && errorShipTo ?
                                        <span style={{ paddingLeft: 10, color: 'red' }}>
                                            * กรุณาเลือกสถานที่จัดส่ง
                                    </span>
                                        :
                                        <span className="show-detail">
                                            {
                                                dataSelectedCustomerShipTo &&
                                                `${dataSelectedCustomerShipTo.customerNameTH}/${dataSelectedCustomerShipTo.customerSubDistrict}` +
                                                `/${dataSelectedCustomerShipTo.customerDistrict}/${dataSelectedCustomerShipTo.customerProvince}` +
                                                `/${dataSelectedCustomerShipTo.customerPostalCode}`
                                            }
                                        </span>
                                }
                            </Col>
                            <Col sm={24} md={(dataSelectedCustomer && dataSelectedCustomer.customerId) ? 12 : 24}>
                                <Field
                                    component={InputFormRedux}
                                    label="SCGID"
                                    small
                                    name="sad"
                                />
                            </Col>
                            {
                                (dataSelectedCustomer && dataSelectedCustomer.customerId) &&
                                <Col sm={24} md={12}>
                                    <Field
                                        component={InputFormRedux}
                                        label="วงเงินคงเหลือ"
                                        small
                                        name="customerCreditRemain"
                                        disabled
                                    />
                                </Col>
                            }

                            <Col sm={24} md={(dataSelectedCustomer && dataSelectedCustomer.customerId) ? 12 : 24}>
                                <Field
                                    component={InputFormRedux}
                                    label="JOB ID   "
                                    small
                                    name="sss"
                                />
                            </Col>
                            {
                                (dataSelectedCustomer && dataSelectedCustomer.customerId) &&
                                <Col sm={24} md={12}>
                                    <Field
                                        component={InputFormRedux}
                                        label="ทะเบียนรถ"
                                        small
                                        name="carNumber"
                                    />
                                </Col>
                            }
                            {
                                (dataSelectedCustomer && dataSelectedCustomer.customerId) &&
                                <Col sm={24} md={12}>
                                    <Field
                                        component={InputFormRedux}
                                        label="วงเงินที่มี"
                                        small
                                        name="customerCreditLimit"
                                        disabled
                                    />
                                </Col>
                            }
                            {
                                (dataSelectedCustomer && dataSelectedCustomer.customerId) &&
                                <Col sm={24} md={12}>
                                    <Field
                                        component={InputFormRedux}
                                        label="วงเงินที่ใช้ไป"
                                        small
                                        name="customerUsedCredit"
                                        disabled
                                    />
                                </Col>
                            }
                        </Row>
                    </div>
                </Col>
                <Col style={style.quotationBox} sm={24} md={12}>

                    <div className="customer-data-box">
                        <div className="title-text2">
                            ข้อมูลการขาย
                            </div>
                        <Row gutter={15} style={{ fontSize: "12px" }}>
                            <Col sm={24} md={12} xl={12}>
                                <Field
                                    component={InputFormRedux}
                                    label="อ้างอิงใบเสนอราคา"
                                    small
                                    name="sssd"
                                    disabled
                                />
                            </Col>
                            <Col sm={24} md={12} xl={12}>
                                <Field
                                    component={DatePickerRedux}
                                    label="วันที่"
                                    small
                                    name="dateSaleOrder"
                                    disabled
                                />
                            </Col>
                            <Col sm={24} md={12} xl={12}>
                                <Field
                                    component={SelectRedux}
                                    name="documentType"
                                    label="ประเภทการขาย"
                                    small
                                    options={dataDocType}
                                    disabled={(dataSelectedCustomer && dataSelectedCustomer.customerCreditAllow)
                                        ? false
                                        : true
                                    }
                                />
                            </Col>
                            <Col sm={24} md={12} xl={12}>
                                <Field
                                    component={SelectRedux}
                                    name="taxType"
                                    label="ประเภทภาษี"
                                    small
                                    options={dataTax}
                                />
                            </Col>
                            <Col sm={24} md={12} xl={12}>
                                <Field
                                    component={SelectRedux}
                                    name="saleChannelCode"
                                    label="ช่องทาง"
                                    small
                                    options={dataChannal}
                                    disabled={disabledCustomer}
                                />
                            </Col>
                            <Col sm={24} md={12} xl={12}>
                                <Field
                                    component={SelectRedux}
                                    name="siteId"
                                    label="Site ID"
                                    small
                                    options={dataOutlet}
                                    onChangeHero={handleChangeFetchEmplpyeeBySite}
                                />
                            </Col>
                            <Col sm={24} md={12} xl={12}>
                                <Field
                                    component={SelectRedux}
                                    name="employee"
                                    label="รหัสพนักงาน"
                                    small
                                    options={dataEmployee}
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    pleaseChoose
                                    onChangeHero={handleChangeSaleEmp}
                                />
                            </Col>
                            <Col sm={24} md={12} xl={12}>
                                <Field
                                    component={SelectRedux}
                                    name="saleShippingPoint"
                                    label="เงื่อนไขการส่งมอบ"
                                    small
                                    options={dataSaleShippingPoint}
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    pleaseChoose
                                />
                            </Col>
                            <Col sm={24} md={12} xl={12}>
                                <Field
                                    component={SelectRedux}
                                    name="creditTerm"
                                    label="Credit Term"
                                    small
                                    options={dataPaymentTerm}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default reduxForm({
    form: 'SaleOrderForm',  // a unique identifier for this form
    enableReinitialize: true
})(SaleOrderForm)
