import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import swal from '@sweetalert/with-react'
import {
	TitleHead,
	ButtonCustom,
	TableCustom,
	RadioCustom,
	InputHero,
	DatePickerCustom,
	SelectHero,
} from 'components'
import { Row, Col, Spin, Dropdown } from 'antd'
import moment from 'moment'
import { apiServices, API_PATH } from 'apiServices'

const maxLengthBillingNumber = 10

export class CNInterfaceContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			columns: [
				{
					title: 'ร้าน',
					align: 'center',
					dataIndex: 'outlet_name',
					key: 'outlet_name',
					ellipsis: true,
				},
				{
					title: 'Billing No',
					align: 'center',
					dataIndex: 'billing_no',
					key: 'billing_no',
					ellipsis: true,
				},
				{
					title: 'ประเภทเอกสาร',
					align: 'center',
					dataIndex: 'document_type_name',
					key: 'document_type_name',
					ellipsis: true
				},
				{
					title: 'สถานะเอกสาร',
					align: 'center',
					dataIndex: 'document_status',
					key: 'document_status',
					ellipsis: true,
				},
				{
					title: 'วันที่เอกสาร',
					align: 'center',
					dataIndex: 'document_date',
					key: 'document_date',
					ellipsis: true,
				},
				{
					title: 'ชื่อลูกค้า',
					align: 'center',
					dataIndex: 'customer_name',
					key: 'customer_name',
					ellipsis: true,
				},
				{
					title: 'จำนวนเงิน',
					align: 'center',
					dataIndex: 'net_amount',
					key: 'net_amount',
					ellipsis: true,
				},
				{
					title: 'เลขที่เอกสารอ้างอิง',
					align: 'center',
					dataIndex: 'reference_doc_number',
					key: 'reference_doc_number',
					ellipsis: true,
				},
				{
					title: 'ระบบอ้างอิง',
					align: 'center',
					dataIndex: 'reference_doc_system',
					key: 'reference_doc_system',
					ellipsis: true,
				},
				{
					title: 'Interface',
					align: 'center',
					dataIndex: 'interface_name',
					key: 'interface_name',
					ellipsis: true,
				},
				{
					title: 'สถานะ Interface',
					align: 'center',
					dataIndex: 'interface_status',
					key: 'interface_status',
					ellipsis: true,
				},
				{
					title: 'ข้อความ Interface',
					dataIndex: 'interface_message',
					key: 'interface_message',
					ellipsis: true,
				},
			],
			modes: [
				{ label: 'Report', value: 'R' },
				{ label: 'Interface', value: 'I' },
				{ label: 'Return Interface', value: 'RERUN' },
			], loading: false,
			dateFormat: 'DD/MM/YYYY',
			docType: [],
			outlets: [],
			cnData: [],
			filter: {
				mode: 'R',
				docType: '',
				billingNumberFrom: '',
				billingNumberTo: '',
				DateFrom: moment().startOf('day'),
				DateTo: null,
				outletId: '',
			},
			pagination: {
				limit: 10,
			},
			loading: false,
			widthLabel: '100px'
		}
		this.onRun = this.onRun.bind(this)
		this.onClear = this.onClear.bind(this)
		this.fetchData = this.fetchData.bind(this)
	}
	async	componentDidMount() {
		await this.fetchData()
	}
	async	fetchData() {
		let { outlets, docType } = this.state
		let paramsOutlet = {
			outletIds: this.props.auth.userOutletModelList.map((data) => (
				data.outletId
			))
		};
		let resOutlet = await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet)
		let resDocType = await apiServices.callApi('get', API_PATH.GET_MASTER_DOCUMENT_TYPE)
		const docFilter = ["ZCN", "ZCNR", "ZCNN", "ZCND", "ZCNP"]
		if (resDocType.status === 200 && resOutlet.status === 200) {
			docType = resDocType.data.results.reduce((result, data) => {
				if (docFilter.includes(data.documentTypeCode)) {
					result.push({ ...data, label: `${data.documentTypeCode} - ${data.documentTypeDescription}`, value: data.documentTypeCode })
				}
				return result
			}, [])
			outlets = resOutlet.data.results.map(data => {
				return {
					...data,
					label: `${data.outletId} - ${data.outletName}`,
					value: data.outletId,
				}
			});
		}
		this.setState({
			docType, outlets
		})
	}
	changeFilter(key, value) {
		const { filter } = this.state
		this.setState({ filter: { ...filter, [key]: value } });
	}

	validateFilter = () => {
		const { filter } = this.state;

		if (!filter.outletId) return { isError: true, message: 'กรุณาระบุร้าน!' }
		if (!filter.docType) return { isError: true, message: 'กรุณาระบุประเภทเอกสาร!' }
		if (!filter.DateFrom && !filter.billingNumberFrom) return { isError: true, message: 'กรุณาระบุวันที่เริ่มต้น!' }


		return { isError: false }
	}

	async onRun() {

		const valid = this.validateFilter()
		if (valid.isError) {
			swal('Warning', valid.message, 'warning')
			return
		}

		this.setState({ loading: true })
		const state = { ...this.state }
		const stateData = state.filter
		const body = {
			outletId: stateData.outletId,
			documentTypeCode: stateData.docType,
			billingNumberFrom: stateData.billingNumberFrom || '',
			billingNumberTo: stateData.billingNumberTo || '',
			documentDateFrom: stateData.DateFrom ? moment(stateData.DateFrom).format('YYYY-MM-DD') : '',
			documentDateTo: stateData.DateTo ? moment(stateData.DateTo).format('YYYY-MM-DD') : ''
		}

		const url = `${API_PATH.POST_CREDIT_NOTE_MANUAL_INTERFACE}?mode=${stateData.mode}`
		await apiServices.callApi('post', url, body).then(res => {
			let documents = _.get(res, 'data.results')
			if (res.status === 200) {
				documents = documents.map(item => {
					const docType = _.find(state.docType, { documentTypeCode: item.document_type_code })
					const outlet = _.find(state.outlets, { outletId: item.outlet_id })
					item.document_type_name = docType ? docType.documentTypeDescription : ''
					item.outlet_name = outlet ? outlet.outletName : item.outlet_id
					item.document_date = moment(item.document_date).format(state.dateFormat)
					return item
				})
				this.setState({ cnData: documents })
				swal('Success', 'ดำเนินรายการเรียบร้อย', 'success')
			} else {
				throw res
			}
		}).catch(error => {
			const message = _.get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง')
			swal('Error', message, 'error')
		}).finally(() => {
			this.setState({ loading: false })
		})

		/*
		const resCN = await apiServices.callApi('post', API_PATH.POST_CREDIT_NOTE_MANUAL_INTERFACE + `?mode=${stateData.mode}`, body)
		if (resCN.status === 200) {
			state.cnData = resCN.data.results.map(item => {
				const docType = _.find(state.docType, { documentTypeCode: item.document_type_code })
				const outlet = _.find(state.outlets, { outletId: item.outlet_id })
				item.document_type_name = docType ? docType.documentTypeDescription : ''
				item.outlet_name = outlet ? outlet.outletName : item.outlet_id
				item.document_date = moment(item.document_date).format(state.dateFormat)
				return item
			})
			this.setState({
				cnData: state.cnData,
				loading: false
			})
		}
		*/

	}
	onClear() {
		const reset = {
			mode: 'R',
			docType: '',
			billingNumberFrom: '',
			billingNumberTo: '',
			DateFrom: moment().startOf('day'),
			DateTo: null,
			outletId: '',
		}
		this.setState({ filter: reset })
	}
	render() {
		const { modes, filter, dateFormat, widthLabel, docType, outlets, columns, cnData, loading } = this.state
		// console.log(this.state)
		return (
			<div>
				<TitleHead
					text="Manual CN Interface"
					icon="file-text"
				/>
				<div className="middle-content">
					<Row style={{ padding: '10px 0' }}>
						<Col sm={24}>
							<RadioCustom
								widthDefault={widthLabel}
								options={modes}
								value={filter.mode}
								label="Mode"
								onChange={(e) => this.changeFilter('mode', e.target.value)}
							/>
						</Col>
					</Row>

					<Row>
						<Col sm={8}>
							<SelectHero
								widthDefault={widthLabel}
								label="ร้าน"
								options={outlets}
								value={filter.outletId}
								onChange={(val) => this.changeFilter('outletId', val)}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={8}>
							<InputHero
								label="Billing Number"
								widthDefault={widthLabel}
								value={filter.billingNumberFrom}
								onChange={(e) => this.changeFilter('billingNumberFrom', e.target.value)}
								maxLength={maxLengthBillingNumber}
							/>
						</Col>
						<Col sm={8}>
							<InputHero
								label="to"
								widthDefault={widthLabel}
								right
								value={filter.billingNumberTo}
								onChange={(e) => this.changeFilter('billingNumberTo', e.target.value)}
								maxLength={maxLengthBillingNumber}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={8}>
							<SelectHero
								widthDefault={widthLabel}
								label="ประเภทเอกสาร"
								options={docType}
								value={filter.docType}
								onChange={(val) => this.changeFilter('docType', val)}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={8}>
							<DatePickerCustom
								label="วันที่"
								widthDefault={widthLabel}
								value={filter.DateFrom}
								format={dateFormat}
								allowClear={false}
								onChange={(date) => this.changeFilter('DateFrom', date)}
							/>
						</Col>
						<Col sm={8}>
							<DatePickerCustom
								label="ถึง"
								right
								widthDefault={widthLabel}
								value={filter.DateTo}
								format={dateFormat}
								allowClear={false}
								onChange={(date) => this.changeFilter('DateTo', date)}
							/>
						</Col>
					</Row>
					<Row gutter={8} type="flex" align="middle" justify="end">
						<Col>
							<ButtonCustom
								icon="clear"
								text="ล้างหน้าจอ"
								yellow
								onClick={this.onClear}
							/>
						</Col>
						<Col>
							<ButtonCustom
								icon="run"
								text="Run"
								primary
								onClick={this.onRun}
							/>
						</Col>
					</Row>
				</div>
				<div>
					<Row style={{ paddingTop: 20 }}>
						<Col sm={24}>
							<TableCustom
								loading={loading}
								height="calc(100vh - 411px)"
								columns={columns}
								data={cnData}
								small
								rowKey={(row, index) => index}
							/>
						</Col>
					</Row>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => {
	// console.log("state===>", state);
	return {
		auth: state.auth
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		authData
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CNInterfaceContainer);