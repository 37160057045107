import { store } from 'reducers/MainReducer'
import { apiServices, API_PATH } from 'apiServices'
import { get, isEmpty } from 'lodash'

// use formatNumber
export const numberFormatComma = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatNumber = (number = 0, decimal) => {
    if (decimal || decimal === 0) {
        return Number(number).toLocaleString(undefined, { minimumFractionDigits: decimal, maximumFractionDigits: decimal })
    } else {
        return Number(number).toLocaleString()
    }
}

export const formatDate = (date) => {
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 101).toString().substring(1);
    var day = (date.getDate() + 100).toString().substring(1);
    return year + "-" + month + "-" + day;
}

export const getPermissionPage = (functionAlias = '') => {
    const state = store.getState()
    const permission = state.auth.userPermissionModelList
        .find(userPermission => userPermission.functionAlias === functionAlias) || {}
    return permission
}

export const isOutletIdAuth = (outletId) => {
    if (!outletId) return false

    const state = store.getState()
    const outletsAuth = state.auth.arrayOutlet
    if (isEmpty(outletsAuth)) return false

    return outletsAuth.includes(outletId.toUpperCase())
}

export const isStorageIdAuth = (storageId) => {
    if (!storageId) return false

    const state = store.getState()
    const storagesAuth = state.auth.arrayStorageLocationId
    if (isEmpty(storagesAuth)) return false

    return storagesAuth.includes(storageId.toUpperCase())
}

export const filterStoragesByStorageAuth = (storages) => {
    if (isEmpty(storages)) return storages

    const state = store.getState()
    const storagesAuth = state.auth.arrayStorageLocationId
    if (isEmpty(storagesAuth)) return []

    return storages.filter(storage => storagesAuth.includes(storage.storageLocationId))
}

export const filterProductsByStorageAuth = (products) => {
    if (isEmpty(products)) return products

    const state = store.getState()
    const storagesAuth = state.auth.arrayStorageLocationId
    if (isEmpty(storagesAuth)) return []

    return products.filter(product => storagesAuth.includes(product.storageLocationId))
}

export const sortDataColumn = (a, b, key = '') => {
    const valA = a[key]
    const valB = b[key]
    if (typeof valA === 'string') {
        return (valA || '').localeCompare((valB || ''))
    } else if (typeof valA === 'number' || typeof valA === 'boolean') {
        return (valA || 0) - (valB || 0)
    }

    return
}

export const validationSCGID = async (scgId) => {
    try {
        const response = await apiServices.callApi('get', API_PATH.GET_SCG_ID, null, { headersOption: { scgid: scgId } })
        if (response.status === 200) {
            return response.data.result
        } else {
            throw response.data.response_msg
        }
    } catch (error) {
        const errorMsg = error.response ? error.response.data.response_msg : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง'
        throw errorMsg
    }
}

export const validationSCGIDAvailable = async (scgId) => {
    await apiServices.callApi('get', API_PATH.GET_SCG_ID, null, { headersOption: { scgid: scgId } }).then(res => {
        if (res.status === 200) {
            return res.data.result
        } else {
            return {}
        }
    }).catch(error => {
        const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
        const status = get(error, 'response.data.status')
        console.log(`SCG Family ID error.response==> Status:${status}, Message:${message}`)
        return {}
    })
}