import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col } from "antd"
import {
    InputFormRedux
} from 'components'
import { VALIDATE } from "helpers"

class ChangePasswordSeftForm extends React.Component {
    state = {

    }

    render() {
        const {
            submitViewError
        } = this.props
        return (
            <Row className="input-layout">
                <Col span={12} offset={6}>
                    <Field
                        right
                        name="currentPassword"
                        component={InputFormRedux}
                        label="รหัสปัจจุบัน"
                        validate={VALIDATE.REQUIRE}
                        submitViewError={submitViewError}
                        password
                    />
                </Col>
                <Col span={12} offset={6}>
                    <Field
                        right
                        name="newPassword"
                        component={InputFormRedux}
                        label="รหัสผ่านใหม่"
                        validate={VALIDATE.REQUIRE, VALIDATE.REQUIRE_COMPLEX}
                        submitViewError={submitViewError}
                        password
                    />
                </Col>
                <Col span={12} offset={6}>
                    <Field
                        right
                        name="confirmPassword"
                        component={InputFormRedux}
                        label="ยืนยันรหัสผ่านใหม่"
                        validate={VALIDATE.REQUIRE, VALIDATE.REQUIRE_COMPLEX}
                        submitViewError={submitViewError}
                        password
                    />
                </Col>
            </Row>
        )
    }
}

export default reduxForm({
    form: 'ChangePasswordSeftForm',  // a unique identifier for this form
    enableReinitialize: true
})(ChangePasswordSeftForm)
