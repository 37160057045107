import React, { Component } from 'react'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    SelectHero,
} from 'components/common'
import { isEmpty, map } from 'lodash'
import { CustomerKeyWordDropDown, sortDataColumn } from 'helpers'
import { Row, Col, Input } from 'antd'
import './style.css'

const { Search } = Input

export class ModalOutstandingBalanceDetail extends Component {
    state = {
        columns: [
            // {
            //     title: '#',
            //     align: 'center',
            //     dataIndex: '#',
            //     key: '#',
            //     render: (value, row) => <ButtonCustom small onClick={() => this.props.onSelected(row)} text="เลือก" />
            // },
            {
                title: 'เลขที่ใบสั่งขาย',
                align: 'center',
                dataIndex: 'soNumber',
                key: 'soNumber',
                sorter: (a, b) => sortDataColumn(a, b, 'soNumber'),
                ellipsis: true,
            },
            {
                title: 'วันที่เอกสาร',
                align: 'center',
                dataIndex: 'documentDate',
                key: 'documentDate',
                sorter: (a, b) => sortDataColumn(a, b, 'documentDate'),
                // render: (value) => {
                //     return this.changeFormatDate(value);
                // },
                ellipsis: true,
            },
            {
                title: 'รหัสผู้สั่งซื้อ',
                align: 'center',
                dataIndex: 'customerId',
                key: 'customerId',
                sorter: (a, b) => sortDataColumn(a, b, 'customerId'),
                ellipsis: true,
            },
            {
                title: 'ชื่อผู้สั่งซื้อ',
                align: 'center',
                dataIndex: 'customerName',
                key: 'customerName',
                sorter: (a, b) => sortDataColumn(a, b, 'customerName'),
                ellipsis: true,
            },
            {
                title: 'เอกสารใบส่งสินค้า',
                align: 'center',
                dataIndex: 'doNumber',
                key: 'doNumber',
                sorter: (a, b) => sortDataColumn(a, b, 'doNumber'),
                ellipsis: true,
            },
            {
                title: 'ปริมาณที่สั่ง',
                align: 'center',
                dataIndex: 'qty',
                key: 'qty',
                sorter: (a, b) => sortDataColumn(a, b, 'qty'),
                ellipsis: true,
            },
            {
                title: 'ปริมาณที่ยืนยัน',
                align: 'center',
                dataIndex: 'confirmQty',
                key: 'confirmQty',
                sorter: (a, b) => sortDataColumn(a, b, 'confirmQty'),
                ellipsis: true,
            },
            {
                title: 'ปริมาณที่คงค้าง',
                align: 'center',
                dataIndex: 'remainQty',
                key: 'remainQty',
                sorter: (a, b) => sortDataColumn(a, b, 'remainQty'),
                ellipsis: true,
            },
            {
                title: 'ปริมาณที่หยิบ',
                align: 'center',
                dataIndex: 'doQty',
                key: 'doQty',
                sorter: (a, b) => sortDataColumn(a, b, 'doQty'),
                ellipsis: true,
            },
        ],
        keyword: '',
        pagination: {},
        keywordType: CustomerKeyWordDropDown[1].value,
    }


    
    changeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    changeKeywordType = (keywordType) => {
        this.setState({ keywordType })
    }
    

    onChangeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }


    filterProducts = () => {
        let { keyword } = this.state
        let { data } = this.props
        if (isEmpty(data)) return []
        // if (isEmpty(keyword)) return data
        keyword = keyword.toLowerCase()        
        return data.filter(user => {
            // const userRoleMasterModels = map(user.userRoleModelList, 'userRoleMasterModel')
            return user.soNumber.toLowerCase().includes(keyword)
                || (user.customerId || '').toLowerCase().includes(keyword)
                || (user.customerName || '').toLowerCase().includes(keyword)
                || (user.doNumber || '').toLowerCase().includes(keyword)
                || (String(user.qty) || '').toLowerCase().includes(keyword)
                || (String(user.confirmQty) || '').toLowerCase().includes(keyword)
                || (String(user.doQty) || '').toLowerCase().includes(keyword)
                || (user.documentDate || '').toLowerCase().includes(keyword)
        })
    }

    render() {
        const {
            isShowModal,
            handleCloseModal,
            title,
            width,
            onSearch,
            data,
            loading,
            className,
        } = this.props
        const {
            columns,
            keywordType,
            pagination,
            keyword
        } = this.state;
        let newProduct = this.filterProducts()
        
        return (
            <ModalCustom
                // isShowModal={true}
                isShowModal={isShowModal}
                onCloseModal={handleCloseModal}
                title={title || 'รายการเอกสารที่มียอดค้างส่ง'}
                width={width || '70%'}
                className={`modal-data ${className}`}
            >
                <Row gutter={8} type="flex" align="middle" justify="end">
                    <Col sm={6}>
                        <Search
                            size="small"
                            onChange={this.onChangeKeyword}
                            value={keyword}
                            // onSearch={onSearch.bind(this, keywordType)}
                            enterButton />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={newProduct}
                            pagination={pagination}
                            loading={loading}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
