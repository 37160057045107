import React, { Component } from 'react'
import { InputSearchStyled } from "./styled"
import { Input, Button } from 'antd';

export class InputSearch extends Component {
    render() {
        const {
            label,
            small,
            handleSubmitSearch,
            handleClickSearch,
            disabled,
            placeholder,
            defaultValue,
            onChange,
            htmlTypeButton,
            value,
            loadingSearchCustomer,
            widthSmall,
            widthDefault,
            typeButton,
            disabledButton,
            widthAll,
            submitViewError,
            meta
        } = this.props;
        let { touched, error } = meta || { touched: false, error: '' }
        let newSubmitViewError = submitViewError || false
        return (
            <InputSearchStyled
                error={(touched || newSubmitViewError) && error ? true : false}
                small={small}>
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div className="label-style" >
                                {label}
                            </div>
                        }
                        <div
                            style={{
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    widthAll ? widthAll : ''
                                ,
                            }}
                        >
                            <form className="search-input" onSubmit={handleSubmitSearch}>
                                <Input
                                    placeholder={placeholder}
                                    disabled={disabled}
                                    defaultValue={defaultValue}
                                    onChange={onChange}
                                    value={value}
                                    size={small ? "small" : 'default'}
                                />
                                <Button
                                    onClick={handleClickSearch}
                                    htmlType={htmlTypeButton}
                                    className="button-search"
                                    type={typeButton || 'primary'}
                                    icon="search"
                                    loading={loadingSearchCustomer}
                                    disabled={disabledButton}
                                />
                            </form>
                            <div className="error-message-bottom">
                                {(touched || newSubmitViewError) ? error : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </InputSearchStyled>
        )
    }
}
