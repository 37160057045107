import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { TitleHead, ButtonCustom, TableCustom, SelectHero, InputHero, DatePickerCustom } from 'components'
import { Row, Col, Spin } from 'antd'
import moment from 'moment'
import './style.css'
import { apiServices, API_PATH } from "apiServices"
import swal from '@sweetalert/with-react'
import { isEmpty, get, find } from 'lodash'
import { Link } from 'react-router-dom'
import { ROUTH_PATH } from 'routes'

class InventoryCountSearchContainer extends Component {
    state = {
        columns: [
            {
                title: 'เลขที่ใบตรวจนับ',
                dataIndex: 'inventoryCountDocNo',
                key: 'inventoryCountDocNo',
                width: 150,
                align: 'center',
                fixed: 'left',
                render: (value, row, index) => (
                    <Link to={ROUTH_PATH.INVENTORY_COUNT_RESULT + "?docNo=" + value}>{value}</Link>
                ),
            },
            {
                title: 'docList',
                dataIndex: 'docList',
                key: 'docList',
                width: 100,
                align: 'center'
            },
            {
                title: 'ร้าน',
                dataIndex: 'outletId',
                key: 'outletId',
                width: 100,
                align: 'center'
            },
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                render: (value, row, index) => (
                    <div>
                        {index + 1}
                    </div>
                ),
                width: 50,
                align: 'center'
            },
            {
                title: 'รหัสสินค้า Article',
                dataIndex: 'productCode',
                key: 'productCode',
                width: 150,
                align: 'center',
            },
            {
                title: 'Barcode',
                dataIndex: 'barcode',
                key: 'barcode',
                width: 150,
                align: 'center'
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                width: 200,
                align: 'center'
            },
            {
                title: 'ประเภทสต็อค',
                dataIndex: 'stockType',
                key: 'stockType',
                width: 100,
                align: 'center'
            },
            {
                title: 'คลัง',
                dataIndex: 'sloc',
                key: 'sloc',
                width: 100,
                align: 'center'
            },
            {
                title: 'หน่วย Base',
                dataIndex: 'baseUnit',
                key: 'baseUnit',
                width: 100,
                align: 'center'
            },
            {
                title: 'Status',
                dataIndex: 'itemStatus',
                key: 'itemStatus',
                width: 100,
                align: 'center'
            },
            {
                title: 'Type',
                dataIndex: 'diffType',
                key: 'diffType',
                width: 100,
                align: 'center'
            },
            {
                title: 'วันที่พิมพ์',
                dataIndex: 'printDate',
                key: 'printDate',
                width: 150,
                align: 'center',
                render: (value, row, index) => {
                    if (value) {
                        var d = new Date(value),
                            month = '' + (d.getMonth() + 1),
                            day = '' + d.getDate(),
                            year = d.getFullYear();

                        if (month.length < 2) month = '0' + month;
                        if (day.length < 2) day = '0' + day;

                        return [day, month, year].join('-');
                    }
                }
            },
            {
                title: 'วันที่ตรวจนับ',
                dataIndex: 'countedDatetime',
                key: 'countedDatetime',
                width: 150,
                align: 'center',
                render: (value, row, index) => {
                    if (value) {
                        var d = new Date(value),
                            month = '' + (d.getMonth() + 1),
                            day = '' + d.getDate(),
                            year = d.getFullYear();

                        if (month.length < 2) month = '0' + month;
                        if (day.length < 2) day = '0' + day;

                        return [day, month, year].join('-');
                    }

                }
            },
            {
                title: 'วันที่ยืนยัน',
                dataIndex: 'confirmDate',
                key: 'confirmDate',
                width: 150,
                align: 'center',
                render: (value, row, index) => {
                    if (value) {
                        var d = new Date(value),
                            month = '' + (d.getMonth() + 1),
                            day = '' + d.getDate(),
                            year = d.getFullYear();

                        if (month.length < 2) month = '0' + month;
                        if (day.length < 2) day = '0' + day;

                        return [day, month, year].join('-');
                    }

                }
            },
            {
                title: 'Class',
                dataIndex: 'classCode',
                key: 'classCode',
                width: 100,
                align: 'center'
            },
        ],
        loading: false,
        inventoryCountDocNo: '',
        docList: '',
        outlets: [],
        outlet: '',
        widthLabel: '80px',
        dateFormat: 'DD/MM/YYYY',
        selectedRowKeys: [],
        statusList: [],
        typeList: [],
        statusListSelected: [],
        typeListSelected: [],
        startDate: '',
        endDate: '',
        dataList: [],
        selectedRowData: []
    }

    componentDidMount() {
        this.fatchData();
    }

    fatchData = async () => {
        const { auth } = this.props
        this.setState({
            loading: true
        });

        let params1 = {
            outletIds: auth.arrayOutlet,
        }

        let params2 = {
            languageCode: 'TH',
        }

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, params1).then(res => {
            if (res.status === 200) {
                let outlets = res.data.results;
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }))

                this.setState({
                    outlets,
                    outlet: outlets[0].value,
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('post', API_PATH.GET_MASTER_INVENTORY_COUNT_STATUS, params2).then(res => {
            if (res.status === 200) {
                let statusList = res.data.results;
                statusList = statusList.map(data => ({
                    ...data,
                    value: data.invcntStatusCode,
                    label: `${data.invcntStatusCode} - ${data.invcntStatusDesc}`,
                }))

                this.setState({
                    statusList
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('get', API_PATH.GET_MASTER_INVENTORY_DIFF_TYPE, '').then(res => {
            if (res.status === 200) {
                let typeList = res.data.results;
                typeList = typeList.map(data => ({
                    ...data,
                    value: data.diffTypeCode,
                    label: `${data.diffTypeCode} - ${data.diffTypeDesc}`,
                }))

                this.setState({
                    typeList
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.setState({
            loading: false
        });
    }

    handleClickSearch = async (e) => {
        const { inventoryCountDocNo, docList, outlet, startDate, endDate, statusListSelected, typeListSelected } = this.state
        this.setState({
            loading: true
        });

        let params = {
            inventoryCountDocNo: inventoryCountDocNo,
            docList: docList,
            outletId: outlet,
            CreatedDateFrom: startDate,
            CreatedDateTo: endDate,
            ItemStatuses: statusListSelected,
            DiffTypes: typeListSelected
        }

        await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_GET_INVENTORY_COUNT_ITEM, params).then(res => {
            let dataList = res.data.results.map((data, i) => (
                {
                    ...data,
                    key: i
                }
            ));

            this.setState({
                dataList: dataList,
                loading: false
            });
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            this.setState({
                loading: false
            });
        });
    }

    handleClickExportExcel = async (e) => {
        const { selectedRowData } = this.state
        this.setState({
            loading: true
        });

        if (selectedRowData.length === 0) {
            this.setState({
                loading: false
            });
            swal('Error', "กรุณาเลือกรายการสินค้าที่ต้องการ Export", 'error')
            return;
        }

        await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_EXPORT_EXCEL, selectedRowData).then(res => {
            if (res.status === 200) {
                window.location.href = API_PATH.GET_INVENTORY_COUNT_DOWNLOAD_EXPORT_EXCEL;
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.setState({
            loading: false
        });
    }

    handleClickReCount = async (e) => {
        const { selectedRowData } = this.state

        this.setState({
            loading: true
        });

        if (selectedRowData.length === 0) {
            swal('Error', "กรุณาเลือกสินค้าที่ต้องการนับใหม่", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        if (find(selectedRowData, (data) => data.itemStatus !== "COUNT")) {
            swal('Error', "กรุณาเลือกเฉพาะรายการสถานะ COUNT", 'error');
            this.setState({
                loading: false
            });
            return;
        }

        await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_RE_COUNT, selectedRowData).then(res => {
            if (res.status === 200) {
                swal('Success', res.data.message, 'success');
                this.handleClickSearch();
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.setState({
            loading: false
        });
    }

    handleChangeOutlet = (outlet) => {
        this.setState({ outlet })
    }

    handleInventoryCountDocNoChange = (e) => {
        console.log("handleInventoryCountDocNoChange ==>", e.target.value);
        this.setState({ inventoryCountDocNo: e.target.value });
    }

    handleDocListChange = (e) => {
        console.log("handleDocListChange ==>", e.target.value);
        this.setState({ docList: e.target.value });
    }

    handleChangeStatus = (val) => {
        // const { statusList } = this.state
        if (val.includes('all')) {
            // const all = map(statusList, 'value')
            this.setState({ statusListSelected: [] })
        } else {
            this.setState({ statusListSelected: val })
        }
    }

    handleChangeType = (val) => {
        // const { types } = this.state
        if (val.includes('all')) {
            // const all = map(types, 'value')
            this.setState({ typeListSelected: [] })
        } else {
            this.setState({ typeListSelected: val })
        }
    }

    handleStartDateChange = (e) => {
        this.setState({ startDate: e })
    }

    handleEndDateChange = (e) => {
        this.setState({ endDate: e })
    }

    onSelectChange = (selectedRowKeys, selectedRowData) => {
        this.setState({ selectedRowKeys, selectedRowData })
        console.log("selectedRowData ==> ", selectedRowData);
    }
    handleClickExportPDF = async (e) => {
        this.setState({
            loading: true
        });
        const { selectedRowData, outlet } = this.state
        let inventoryCountItemIds = selectedRowData.map(item => item.inventoryCountItemId)
        const requestBody = {
            inventoryCountItemIds,
            outletId: outlet
        }
        try {
            const result = await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_EXPORT_PRINT, requestBody, { responseType: 'blob' })
            const urlOpen = window.URL.createObjectURL(result.data)
            window.open(urlOpen)
        } catch (error) {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }
        this.setState({
            loading: false
        });

    }
    render() {
        const {
            outlets,
            outlet,
            statusList,
            statusListSelected,
            typeList,
            typeListSelected,
            loading,
            columns,
            dateFormat,
            widthLabel,
            selectedRowKeys,
            dataList,
            inventoryCountDocNo,
            docList,
            startDate,
            endDate
        } = this.state

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        }

        return (
            <div className="inventory-count-search-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="ค้นหาใบตรวจนับสต็อค"
                        icon="file-search"
                    />
                    <div className="top-layout">
                        <Row gutter={20}>
                            <Col sm={6}>
                                <DatePickerCustom
                                    onChange={this.handleStartDateChange}
                                    widthDefault={widthLabel}
                                    name="startDate"
                                    label="วันที่สร้าง"
                                    format={dateFormat}
                                    value={startDate}
                                />
                            </Col>
                            <Col sm={6}>
                                <DatePickerCustom
                                    onChange={this.handleEndDateChange}
                                    widthDefault={widthLabel}
                                    name="endDate"
                                    label="ถึง"
                                    format={dateFormat}
                                    value={endDate}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputHero
                                    onChange={this.handleInventoryCountDocNoChange}
                                    widthDefault={widthLabel}
                                    name="inventoryCountNo"
                                    label="เลขที่ใบตรวจนับ"
                                    value={inventoryCountDocNo}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputHero
                                    onChange={this.handleDocListChange}
                                    widthDefault={widthLabel}
                                    name="docList"
                                    label="docList"
                                    value={docList}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col sm={6}>
                                {<SelectHero
                                    onChange={this.handleChangeOutlet}
                                    widthDefault={widthLabel}
                                    name="outlet"
                                    label="ร้านค้า"
                                    options={outlets}
                                    value={outlet}
                                />}
                            </Col>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    onChange={this.handleChangeStatus}
                                    name="status"
                                    label="Status"
                                    options={statusList}
                                    value={statusListSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    onChange={this.handleChangeType}
                                    name="type"
                                    label="Type"
                                    options={typeList}
                                    value={typeListSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                            <Col sm={6} className="search-box">
                                <ButtonCustom onClick={this.handleClickSearch} icon="search" type="primary" blue text="ค้นหา" />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col sm={24}>
                                <div className="action-box">
                                    <div className="button">
                                        <ButtonCustom onClick={this.handleClickReCount} icon="redo" red type="danger" text="นับใหม่" />
                                    </div>
                                    <div className="button">
                                        <ButtonCustom onClick={this.handleClickExportExcel} icon="file-excel" green text="Export Excel" />
                                    </div>
                                    <div className="button">
                                        <ButtonCustom icon="printer" onClick={this.handleClickExportPDF} green text="พิมพ์ใบตรวจนับ" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="content">
                        <div className="table-layout">
                            <TableCustom rowSelection={rowSelection} scroll={{ x: "max-content", y: "calc(100vh - 225px)" }} columns={columns} data={dataList} rowKey={(row, index) => index} pagination={false} />
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCountSearchContainer)