import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, sortBy } from 'lodash'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import {
    TitleHead,
    ButtonCustom,
    InputHero,
    CheckBoxCustom,
} from 'components'
import { Row, Col, Spin, message } from 'antd'
import { ROUTH_PATH, functionAlias } from 'routes'
import { apiServices, API_PATH } from 'apiServices'
import { getPermissionPage } from 'helpers'
import swal from '@sweetalert/with-react'
import AuthTable from './AuthTable'

import './style.css'

class RoleCreateContainer extends Component {
    state = {
        columns: [
            {
                title: 'Menu (M)',
                dataIndex: 'authMenu',
                key: 'authMenu',
                align: 'center',
                render: (value, row, index) => (
                    <div className="checkbox-status">
                        <CheckBoxCustom
                            checked={value}
                            onChange={(e) => this.onChangeAuth('authMenu', e.target.checked, row, index)}
                        />
                    </div>
                )
            },
            {
                title: 'Display (V)',
                dataIndex: 'authDisplay',
                key: 'authDisplay',
                align: 'center',
                render: (value, row, index) => (
                    <div className="checkbox-status">
                        <CheckBoxCustom
                            checked={value}
                            onChange={(e) => this.onChangeAuth('authDisplay', e.target.checked, row, index)}
                        />
                    </div>
                )
            },
            {
                title: 'Create (C)',
                dataIndex: 'authCreate',
                key: 'authCreate',
                align: 'center',
                render: (value, row, index) => (
                    <div className="checkbox-status">
                        <CheckBoxCustom
                            checked={value}
                            onChange={(e) => this.onChangeAuth('authCreate', e.target.checked, row, index)}
                        />
                    </div>
                )
            },
            {
                title: 'Update (U)',
                dataIndex: 'authUpdate',
                key: 'authUpdate',
                align: 'center',
                render: (value, row, index) => (
                    <div className="checkbox-status">
                        <CheckBoxCustom
                            checked={value}
                            onChange={(e) => this.onChangeAuth('authUpdate', e.target.checked, row, index)}
                        />
                    </div>
                )
            },
            {
                title: 'Approve (A)',
                dataIndex: 'authApprove',
                key: 'authApprove',
                align: 'center',
                render: (value, row, index) => (
                    <div className="checkbox-status">
                        <CheckBoxCustom
                            checked={value}
                            onChange={(e) => this.onChangeAuth('authApprove', e.target.checked, row, index)}
                        />
                    </div>
                )
            },
            {
                title: 'Delete (D)',
                dataIndex: 'authDelete',
                key: 'authDelete',
                align: 'center',
                render: (value, row, index) => (
                    <div className="checkbox-status">
                        <CheckBoxCustom
                            checked={value}
                            onChange={(e) => this.onChangeAuth('authDelete', e.target.checked, row, index)}
                        />
                    </div>
                )
            },
            {
                title: 'Print (P)',
                dataIndex: 'authPrint',
                key: 'authPrint',
                align: 'center',
                render: (value, row, index) => (
                    <div className="checkbox-status">
                        <CheckBoxCustom
                            checked={value}
                            onChange={(e) => this.onChangeAuth('authPrint', e.target.checked, row, index)}
                        />
                    </div>
                )
            },
            {
                title: 'Export (E)',
                dataIndex: 'authExport',
                key: 'authExport',
                align: 'center',
                render: (value, row, index) => (
                    <div className="checkbox-status">
                        <CheckBoxCustom
                            checked={value}
                            onChange={(e) => this.onChangeAuth('authExport', e.target.checked, row, index)}
                        />
                    </div>
                )
            },
            {
                title: 'Cost (T)',
                dataIndex: 'authCost',
                key: 'authCost',
                align: 'center',
                render: (value, row, index) => (
                    <div className="checkbox-status">
                        <CheckBoxCustom
                            checked={value}
                            onChange={(e) => this.onChangeAuth('authCost', e.target.checked, row, index)}
                        />
                    </div>
                )
            },
        ],
        loading: false,
        tables: [],
        userRole: {},
        userRoleHead: {
            roleName: '',
            roleDescription: '',
            status: false,
        },
        userRoleId: '',
        permissionPage: getPermissionPage(functionAlias.roleMaintain),
    }

    componentDidMount() {
        const { match } = this.props
        const { id } = match.params
        if (id) {
            if (!this.state.permissionPage.authDisplay) {
                swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
                    this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
                })
                return
            }
            this.fetchData(id).then(() => {
                this.fetchDataDefault()
            })
        } else {
            this.fetchDataDefault()
        }
    }

    fetchData = async (id) => {
        this.setState({ loading: true })
        const { userRoleHead } = this.state
        await apiServices.callApi('get', `${API_PATH.GET_USER_ROLE_MASTER_DETAIL}?RoleId=${id}&DeleteFlag=false`).then(res => {
            const userRole = get(res, 'data.results.0')
            if (res.status === 200 && userRole) {
                this.setState({
                    userRole,
                    userRoleId: id,
                    userRoleHead: {
                        ...userRoleHead,
                        roleName: userRole.roleName,
                        roleDescription: userRole.roleDescription,
                        status: userRole.status,
                    },
                })
            } else {
                throw res
            }
        }).catch(error => {
            swal({
                title: 'Error',
                icon: 'error',
                text: 'ไม่พบข้อมูล',
                buttons: {
                    create: { text: 'เพิ่มบทบาทการใช้งาน', value: true },
                    cancel: 'ปิด',
                },
            }).then((value) => {
                if (value) {
                    this.props.history.push(ROUTH_PATH.ROLE_CREATE)
                }
            })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const resFunction = await apiServices.callApi('post', API_PATH.POST_GET_USER_FUNCTION_MASTER, {})
        const resModule = await apiServices.callApi('get', API_PATH.GET_USER_MODULE_MASTER)
        let userFunctions = get(resFunction, 'data.results')
        let userModules = get(resModule, 'data.results')
        if (resFunction.status === 200 && resModule.status === 200 && userFunctions && userModules) {
            userModules = sortBy(userModules, ['sequenceNo'])
            userModules = userModules.map(userModule => {
                const data = userFunctions.filter(userFunction => userFunction.moduleId === userModule.moduleId)
                return {
                    ...userModule,
                    userFunctions: sortBy(data, ['sequenceNo']),
                }
            })
            this.generateTable(userModules)
        }
        this.setState({ loading: false })
    }

    generateTable = (userModules) => {
        const { userRole, userRoleId } = this.state
        const tables = userModules.map(userModule => {
            const rows = userModule.userFunctions.map(userFunction => {
                let data = {}
                if (userRoleId) {
                    data = userRole.userRoleAuthorizationModelList.find(auth => auth.functionId === userFunction.functionId)
                }
                return {
                    ...userFunction,
                    authMenu: get(data, 'authMenu', false),
                    authCreate: get(data, 'authCreate', false),
                    authUpdate: get(data, 'authUpdate', false),
                    authDisplay: get(data, 'authDisplay', false),
                    authApprove: get(data, 'authApprove', false),
                    authDelete: get(data, 'authDelete', false),
                    authPrint: get(data, 'authPrint', false),
                    authExport: get(data, 'authExport', false),
                    authCost: get(data, 'authCost', false),
                }
            })

            return {
                moduleId: userModule.moduleId,
                columns: [
                    {
                        title: () => (
                            <div className="checkbox-status">
                                <CheckBoxCustom
                                    checked={this.getSelectAllModuleValue(userModule.moduleId)}
                                    onChange={(e) => this.onSelectAllModule(e.target.checked, userModule.moduleId)}
                                />
                            </div>
                        ),
                        dataIndex: 'actionColumn',
                        key: 'actionColumn',
                        width: 20,
                        align: 'center',
                        render: (value, row, index) => (
                            <div className="checkbox-status">
                                <CheckBoxCustom
                                    checked={this.getSelectAllFunctionValue(row)}
                                    onChange={(e) => this.onSelectAllFunction(e.target.checked, row, index)}
                                />
                            </div>
                        )
                    },
                    {
                        title: userModule.moduleName,
                        dataIndex: 'functionName',
                        key: 'functionName',
                        width: 250,
                    },
                    ...this.state.columns,
                ],
                rows,
            }
        })
        this.setState({ tables })
    }

    onChangeUserRoleHead = (key, val) => {
        const { userRoleHead } = this.state
        this.setState({ userRoleHead: { ...userRoleHead, [key]: val } })
    }

    onChangeAuth = (key, value, row, index) => {
        const { tables } = this.state
        const newTables = [...tables]
        const indexTable = newTables.findIndex(newTable => newTable.moduleId === row.moduleId)
        newTables[indexTable].rows[index][key] = value

        this.setState({ tables: newTables })
    }

    onSelectAllModule = (value, moduleId) => {
        const { tables } = this.state
        const newTables = [...tables]
        const indexTable = newTables.findIndex(newTable => newTable.moduleId === moduleId)
        let rows = newTables[indexTable].rows
        rows = rows.map(row => {
            const authData = this.getAuthValue(value)
            return {
                ...row,
                ...authData,
            }
        })
        newTables[indexTable].rows = rows
        this.setState({ tables: newTables })
    }

    onSelectAllFunction = (value, row, index) => {
        const { tables } = this.state
        const newTables = [...tables]
        const indexTable = newTables.findIndex(newTable => newTable.moduleId === row.moduleId)
        const authData = this.getAuthValue(value)
        newTables[indexTable].rows[index] = {
            ...newTables[indexTable].rows[index],
            ...authData,
        }
        newTables[indexTable].isSelectAll = value
        this.setState({ tables: newTables })
    }

    getAuthValue = (value) => {
        value = !!value
        return {
            authMenu: value,
            authCreate: value,
            authUpdate: value,
            authDisplay: value,
            authApprove: value,
            authDelete: value,
            authPrint: value,
            authExport: value,
            authCost: value,
        }
    }

    // return true when this module all selected
    getSelectAllModuleValue = (moduleId) => {
        const { tables } = this.state
        const newTables = [...tables]
        const indexTable = newTables.findIndex(newTable => newTable.moduleId === moduleId)
        const rows = newTables[indexTable].rows
        if (isEmpty(rows)) return false
        return !!!rows.find(row => !this.getSelectAllFunctionValue(row))
    }

    // return true when this function row all selected
    getSelectAllFunctionValue = (row) => {
        if (isEmpty(row)) return false
        return !!(row.authMenu && row.authCreate && row.authUpdate
            && row.authDisplay && row.authApprove && row.authDelete
            && row.authPrint && row.authExport && row.authCost)
    }

    checkSelectFunction = (row) => {
        if (isEmpty(row)) return false
        return !!(row.authMenu || row.authCreate || row.authUpdate
            || row.authDisplay || row.authApprove || row.authDelete
            || row.authPrint || row.authExport || row.authCost)
    }

    getBodyUserRole = () => {
        const { auth } = this.props
        const { userRole, userRoleId, userRoleHead, tables } = this.state

        let authorizations = []
        for (let index in tables) {
            const rows = tables[index].rows
            for (let indexRow in rows) {
                const row = rows[indexRow]
                const isSelect = this.checkSelectFunction(row)
                if (isSelect) {
                    authorizations.push(
                        {
                            authorizationId: row.authorizationId || 0,
                            functionId: row.functionId,
                            roleId: userRoleId || 0,
                            authMenu: row.authMenu,
                            authCreate: row.authCreate,
                            authUpdate: row.authUpdate,
                            authDisplay: row.authDisplay,
                            authApprove: row.authApprove,
                            authDelete: row.authDelete,
                            authPrint: row.authPrint,
                            authExport: row.authExport,
                            authCost: row.authCost,
                        }
                    )
                }

            }
        }

        let body = {}
        if (userRoleId) {
            body = {
                ...userRole,
                ...userRoleHead,
                updatedDate: new Date(),
                updatedBy: auth.userId,
                userRoleAuthorizationModelList: authorizations,
            }
        } else {
            body = {
                ...userRoleHead,
                deleteFlag: false,
                createdDate: new Date(),
                createdBy: auth.userId,
                updatedDate: new Date(),
                updatedBy: auth.userId,
                userRoleAuthorizationModelList: authorizations,
            }
        }
        return body
    }

    validateData = () => {
        const { userRoleHead } = this.state
        if (!userRoleHead.roleName) return { isError: true, message: 'กรุณาระบุชื่อสิทธิ์' }
        if (!userRoleHead.roleDescription) return { isError: true, message: 'กรุณาระบุคำอธิบาย' }
        return { isError: false }
    }

    onSubmit = () => {
        const { userRoleId } = this.state
        const valid = this.validateData()
        if (valid.isError) {
            message.error(valid.message)
            return
        }

        if (userRoleId) {
            this.updateUserRole()
        } else {
            this.createUserRole()
        }
    }

    createUserRole = async () => {
        this.setState({ loading: true })
        const body = this.getBodyUserRole()
        await apiServices.callApi('post', API_PATH.POST_USER_ROLE_MASTER, body)
            .then(res => {
                if (res.status === 200) {
                    const id = get(res, 'data.message')
                    swal('Success', 'บันทึกข้อมูลสำเร็จ', 'success').then(() => {
                        this.props.history.push(`${ROUTH_PATH.ROLE_EDIT_LINK}/${id}`)
                    })
                } else {
                    throw res
                }
            }).catch(error => {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            }).finally(() => {
                this.setState({ loading: false })
            })
    }

    updateUserRole = async () => {
        this.setState({ loading: true })
        const { userRoleId } = this.state
        const body = this.getBodyUserRole()
        await apiServices.callApi('put', `${API_PATH.PUT_UPDATE_USER_ROLE_MASTER_WITH_INCLUDE}?roleId=${userRoleId}`, body)
            .then(res => {
                const message = get(res, 'data.message', 'บันทึกข้อมูลสำเร็จ')
                if (res.status === 200) {
                    swal('Success', message, 'success').then(() => {
                        this.fetchData(userRoleId)
                    })
                } else {
                    throw res
                }
            }).catch(error => {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            }).finally(() => {
                this.setState({ loading: false })
            })
    }

    render() {
        const {
            loading,
            tables,
            userRoleId,
            userRoleHead,
        } = this.state
        return (
            <div className="role-create-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text={`${userRoleId ? 'แก้ไขบทบาทการใช้งาน' : 'เพิ่มบทบาทการใช้งาน'}`}
                        icon={`${userRoleId ? 'edit' : 'plus-circle'}`}
                    />
                    <div className="middle-content">
                        <Row align="middle" type="flex">
                            <Col sm={12} offset={3}>
                                <InputHero
                                    label="ชื่อสิทธิ์ *"
                                    widthDefault="90px"
                                    small
                                    maxLength={20}
                                    widthSmall="90px"
                                    onChange={(e) => this.onChangeUserRoleHead('roleName', e.target.value)}
                                    value={userRoleHead.roleName}
                                />
                            </Col>
                            <Col sm={12} offset={3}>
                                <InputHero
                                    label="คำอธิบาย *"
                                    widthDefault="90px"
                                    small
                                    maxLength={50}
                                    widthSmall="90px"
                                    onChange={(e) => this.onChangeUserRoleHead('roleDescription', e.target.value)}
                                    value={userRoleHead.roleDescription}
                                />
                            </Col>
                            <Col sm={12} offset={3}>
                                <div className="status-box input-form-hero">
                                    <div className="input-zone-label">
                                        <div className="label-style">
                                            ใช้งานอยู่
                                         </div>
                                        <CheckBoxCustom
                                            onChange={(e) => this.onChangeUserRoleHead('status', e.target.checked)}
                                            checked={userRoleHead.status}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-layout">
                            {tables && <AuthTable tables={tables} />}
                            {/* {tables && tables.map((table, index) => (
                                <TableCustom
                                    key={index}
                                    isStripedRow
                                    columns={table.columns}
                                    small
                                    scroll={{ x: "max-content" }}
                                    data={table.rows}
                                    rowKey={(row, index) => index}
                                    pagination={false} />
                            ))} */}
                        </div>
                    </div>
                    <div className="bottom-content">
                        <div className="left-zone">
                            <div className="button">
                                <Link to={ROUTH_PATH.ROLE} className="footer-button"  >
                                    <ButtonCustom
                                        text="ยกเลิก"
                                        icon="close"
                                        type="danger"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="right-zone">
                            <div className="button">
                                <ButtonCustom
                                    text="บันทึก"
                                    icon="save"
                                    green
                                    onClick={this.onSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleCreateContainer)