import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// pages
import Login from '../pages/Login';
import DashBoard from "../pages/DashBoard"
import NotFound from '../pages/NotFound';
import promotion from "../pages/DashBoard/containers/PromotionContainer"
// ----------------------------------------
import { authData } from '../actions/actionsCreator';
import PrivateRoute from '../PrivateRoute';
import './style.css';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timeout: 3000
        }
        this.idleTimer = null
    }

    render() {
        return (
            <Switch>
                <PrivateRoute exact path="/" component={DashBoard} appContext={this} />
                <PrivateRoute path="/franchise" component={DashBoard} appContext={this} />
                <Route exact path="/foo" component={promotion} />
                <Route exact path="/login" component={Login} />

                {/* <Route path="/login" component={Login} /> */}
                <Route component={NotFound} />
            </Switch>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App);