import React from 'react'
import { Field, reduxForm, } from 'redux-form'
import { Row, Col, Icon, message, Table, Input, DatePicker, Checkbox } from "antd"
import { isEmpty, first } from 'lodash';
import swal from '@sweetalert/with-react'
import XLSX from 'xlsx'
import moment from 'moment'
import { formatNumber } from 'helpers'
import {
    SelectRedux,
    InputSearch,
    InputFormRedux,
    TableCustom,
    ButtonCustom,
    ModalCustom,
    InputHero,
    SelectHero,
} from 'components'
import { NumberFormatCustom, SelectCustom, InputSearchCustom } from 'components/common'
import {
    promotionPattern, discountAndGiveAway, discountType, promotionType, conditionType, formatDate,
    VALIDATE
} from 'helpers'
import { API_PATH, apiServices } from 'apiServices'
import './style.css'

class PromotionMaintainPatternForm extends React.Component {
    state = {
        identifierName: '',
        productCode: '',
        catagoryTypeKeyword: '',
        searchTypeKeyword: '',
        dataTableQualifierModal: [],
        quantityDiscountList: [],
        mixAndMatchDiscountList: [],
        mixAndMatchPromotionGiftsList: {
            name: '',
            description: '',
            data: []
        },
        dataVoucherList: [],
        indexKeyPattern: 0,
        typeDiscountAndGiveAway: '',
        isCheckMixAndMatch: false,
        discountAndGiveAwayType: '',
        discountMixAndMatch: {
            rewardType: '',
            dTypecode: '',
            dValue: '',
        },
        catagoryTypes: [
            { label: 'รายการสินค้า', value: 'product' },
            { label: 'หมวดหมู่สินค้า', value: 'category' }
        ],
        searchTypes: [
            { label: 'รหัสสินค้า', value: 'ProductId', type: 'product,category' },
            { label: 'ชื่อสินค้า', value: 'ProductName', type: 'product,category' },
            { label: 'ชื่อ Qualifier', value: 'QualifierName', type: 'product' },
            { label: 'ชื่อ Category', value: 'CategoryName', type: 'category' },
        ],
        patternType: '',
        qualifierList: [
            {
                name: '',
                description: ''
            },
            {
                name: '',
                description: ''
            },
            {
                name: '',
                description: ''
            },
            {
                name: '',
                description: ''
            },
            {
                name: '',
                description: ''
            },
        ],
        columnsItemsBundleRule: [
            {
                title: '#',
                dataIndex: 'no',
                key: 'no',
            },
            {
                title: 'บาร์โค้ด',
                dataIndex: "barcode",
                key: "barcode",
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: "productCode",
                key: "productCode",
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: "temName",
                key: "temName",
            },
            {
                title: 'จำนวน',
                dataIndex: "qty",
                key: "qty",
                width: 100,
                render: (props, row, index) => <div>
                    <Input
                        size="small"
                        type="number"
                        disabled={row.disabledItems}
                        onChange={(e) => this.handleChangeNumberQtyBundle(e, index)}
                        value={props}
                    />
                </div>
            },
            {
                title: 'หน่วยนับ',
                dataIndex: "unitCode",
                key: "unitCode",
            },
        ],
        columnsItems: [
            {
                title: '#',
                dataIndex: 'no',
                key: 'no',
            },
            {
                title: 'บาร์โค้ด',
                dataIndex: "barcode",
                key: "barcode",
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: "productCode",
                key: "productCode",
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: "temName",
                key: "temName",
            },
            {
                title: 'จำนวน',
                dataIndex: "qty",
                key: "qty",
                width: 100,
                render: (props, row, index) => <div>
                    <Input
                        size="small"
                        type="number"
                        disabled={row.disabledItems}
                        onChange={(e) => this.handleChangeNumberQty(e, index, row.indexHead)}
                        value={row.disabledItems ? '' : props}

                    // validate={VALIDATE.REQUIRE}
                    // submitViewError={this.props.submitViewError}
                    />
                </div>
            },
            {
                title: 'หน่วยนับ',
                dataIndex: "unitCode",
                key: "unitCode",
            },
        ],
        columnsGift: [
            {
                title: '#',
                dataIndex: 'no',
                key: 'no',
            },
            {
                title: 'บาร์โค้ด',
                dataIndex: "barcode",
                key: "barcode",
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: "productCode",
                key: "productCode",
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: "temName",
                key: "temName",
            },
            {
                title: 'จำนวน',
                dataIndex: "qtys",
                key: "qtys",
                width: 100,
                render: (props, row, index) => <div>
                    <Input
                        size="small"
                        type="number"
                        disabled={row.disabledInput}
                        onChange={(e) => this.handleChangeNumberQtyGift(e, index, row.indexHead)}
                        value={row.disabledInput ? 1 : props}
                    />
                </div>
            },
            {
                title: 'หน่วยนับ',
                dataIndex: "unitCode",
                key: "unitCode",
            },
        ],
        columnsVoucher: [
            {
                title: 'Active',
                align: 'center',
                dataIndex: 'active',
                key: 'active',
                width: 100,
                render: (props, row, index) => <div>
                    <Checkbox
                        checked={props}
                        onChange={(e) => this.handleChangeVoucherActive(e, index)}
                        disabled={row.disabledInput}
                    >
                        Active
                    </Checkbox>
                </div>
            },
            {
                title: 'ลำดับ',
                align: 'center',
                dataIndex: "no",
                key: "no",
                width: 50,
                render: (props, row, index) => <div>{index + 1}</div>
            },
            {
                title: 'เลขที่ Voucher',
                align: 'center',
                dataIndex: "voucherCode",
                key: "voucherCode",
                width: 150,
                render: (props, row, index) => <div>
                    {
                        row.disabledInput ? props : <Input
                            size="small"
                            onChange={(e) => this.handleChangeVoucherCode(e, index)}
                            value={props}
                        />
                    }
                </div>
            },
            {
                title: 'วันที่เริ่มใช้งาน',
                align: 'center',
                dataIndex: "dateEffective",
                key: "dateEffective",
                width: 150,
                render: (props, row, index) => <div>
                    {
                        row.disabledInput ? formatDate(new Date(props)) : <DatePicker
                            size='small'
                            disabled={row.disabledInput}
                            value={props ? moment(new Date(props)) : moment(new Date)}
                            onChange={(e, dateString) => this.handleChangeDateEffective(e, dateString, index)}
                        />
                    }
                </div>
            },
            {
                title: 'วันที่สิ้นสุดการใช้งาน',
                align: 'center',
                dataIndex: "dateExpire",
                key: "dateExpire",
                width: 150,
                render: (props, row, index) => <div>
                    {
                        row.disabledInput ? formatDate(new Date(props)) : <DatePicker
                            size='small'
                            disabled={row.disabledInput}
                            value={props ? moment(new Date(props)) : moment(new Date)}
                            onChange={(e, dateString) => this.handleChangeDateExpire(e, dateString, index)}
                        />
                    }
                </div>
            },
            {
                title: 'มูลค่า',
                align: 'center',
                dataIndex: 'voucherValue',
                key: 'voucherValue',
                width: 150,
                render: (props, row, index) => <div>
                    {
                        row.disabledInput ? <div className="text-right">{formatNumber(props, 2)}</div> : <NumberFormatCustom
                            size="small"
                            onChange={(e) => this.handleChangeVoucherValue(e, index)}
                            value={props}
                            decimalScale={2}
                            className="text-right"
                        />
                    }
                </div>
            },
            {
                title: 'Action',
                align: 'center',
                dataIndex: "unit",
                key: "unit",
                width: 100,
                render: (props, row, index) => <div className="columns-delete-edit">
                    {
                        (!row.active ? '' :
                            row.disabledInput ? <>
                                <span className="delete-edit" onClick={() => this.handleClickDeleteRowVoucher(index)}>
                                    ลบ
                            </span>
                                <span>
                                    |
                            </span>
                                <span className="delete-edit" onClick={() => this.handleClickEditRowVoucher(index)}>
                                    แก้ไข
                            </span>
                            </> : <span className="delete-edit" onClick={() => this.handleClickConfirmRowVoucher(index)}>ยืนยัน</span>)
                    }

                </div>
            },
        ],
        columnsQualifier: [
            {
                title: '',
                dataIndex: '',
                key: '',
                render: (props, row) => <div style={{ textAlign: 'center' }}>
                    <ButtonCustom small text="เลือก" onClick={() => this.handleClickChooseQualifier(row)} />
                </div>,
                width: '10%'
            },
            {
                title: 'ชื่อ',
                dataIndex: 'name',
                key: 'name',
                width: '30%'
            },
            {
                title: 'รูปแบบ',
                dataIndex: 'qualifierTypeCode',
                key: 'qualifierTypeCode',
                render: (prop, row) => <div style={{ textAlign: 'center' }}>
                    {prop === "product" ? 'รายการสินค้า' : 'หมวดหมู่สินค้า'}
                </div>,
                width: '15%'
            },
            {
                title: 'รายละเอียด',
                dataIndex: 'description',
                key: 'description',
                width: '45%'
            }
        ]
    }
    componentDidUpdate(prevProps) {
        if (this.props.patternValue !== prevProps.patternValue) {
            this.handleChangePattern(this.props.patternValue ? this.props.patternValue : promotionPattern[0].value)
        }
        if (this.props.qtyDiscountAndList != prevProps.qtyDiscountAndList) {
            console.log("qtyDiscountAndList==>", this.props.qtyDiscountAndList);

            this.setState({
                quantityDiscountList: this.props.qtyDiscountAndList
            }, () => this.handleChangePattern(this.state.patternType || this.props.patternValue))
        }
        if (this.props.mixAndMatchList != prevProps.mixAndMatchList) {
            console.log("mixAndMatchList==>", this.props.mixAndMatchList);
            this.setState({
                mixAndMatchDiscountList: this.props.mixAndMatchList
            }, () => this.handleChangePattern(this.state.patternType || this.props.patternValue))
        }
        if (this.props.promotionDiscountMixAndMatch != prevProps.promotionDiscountMixAndMatch) {
            console.log("promotionDiscountMixAndMatch==>", this.props.promotionDiscountMixAndMatch);
            this.setState({
                discountMixAndMatch: this.props.promotionDiscountMixAndMatch
            }, () => this.handleChangePattern(this.state.patternType || this.props.patternValue))
        }
        if (this.props.promotionGiftList != prevProps.promotionGiftList) {
            console.log("promotionGiftList==>", this.props.promotionGiftList);
            this.setState({
                mixAndMatchPromotionGiftsList: this.props.promotionGiftList
            }, () => this.handleChangePattern(this.state.patternType || this.props.patternValue))
        }
        if (this.props.voucherList != prevProps.voucherList) {
            console.log("voucherList==>", this.props.voucherList);
            this.setState({
                dataVoucherList: this.props.voucherList
            }, () => this.handleChangePattern(this.state.patternType || this.props.patternValue))
        }
        if (this.props.qualifierData != prevProps.qualifierData) {
            console.log("qualifierData==>", this.props.qualifierData);
            this.setState({
                dataSelectedQualifier: this.props.qualifierData
            }, () => this.handleChangePattern(this.state.patternType || this.props.patternValue))
        }
    }

    componentDidMount() {
        const { patternValue, dataRuleBundles } = this.props;
        // console.log('patternValue', patternValue);
        // console.log('promotionPattern[0].value',promotionPattern[0].value);


        // console.log("dataRuleBundles==>", dataRuleBundles);

        this.handleChangePattern(patternValue ? patternValue : promotionPattern[0].value)
        // this.handleChangeDiscountAndGiveAway("ส่วนลด", 0)
    }

    handleChangePattern = (e) => {
        console.log("e==>", e)
        // console.log('patternValue',patternValue);
        // console.log(this.state);

        // this.state.patternType
        // console.log("dataRuleBundles==>", dataRuleBundles);
        switch (e) {
            case promotionPattern[0].value:
                this.setState({
                    indexKeyPattern: 0,
                    arrayType: false,
                    isCheckMixAndMatch: false,
                    patternType: promotionPattern[0].value,
                })
                break;
            case promotionPattern[1].value:
                this.setState({
                    indexKeyPattern: 1,
                    arrayType: false,
                    isCheckMixAndMatch: false,
                    patternType: promotionPattern[1].value,
                })
                break;
            case promotionPattern[2].value:
                this.setState({
                    indexKeyPattern: 2,
                    arrayType: true,
                    isCheckMixAndMatch: false,
                    patternType: promotionPattern[2].value,
                })
                break;
            case promotionPattern[3].value:
                this.setState({
                    indexKeyPattern: 3,
                    arrayType: true,
                    isCheckMixAndMatch: true,
                    patternType: promotionPattern[3].value,
                })
                break;
            case promotionPattern[4].value:
                this.setState({
                    indexKeyPattern: 4,
                    arrayType: false,
                    isCheckMixAndMatch: false,
                    patternType: promotionPattern[4].value,
                })
                break;
            default:
                break;
        }
    }
    handleChangeDiscountAndGiveAway = (e, i) => {
        console.log("i==>", i, e);
        const { quantityDiscountList, isCheckMixAndMatch, mixAndMatchDiscountList, } = this.state
        if (isCheckMixAndMatch) {
            let NewMixAndMatchDiscountList = [...mixAndMatchDiscountList]
            NewMixAndMatchDiscountList[i].discountAndGiveAwayType = e
            this.setState({
                indexDiscountAndGiveaway: i,
                mixAndMatchDiscountList: NewMixAndMatchDiscountList,
            })
            this.props.getDataPromotionMixAndMatchList(NewMixAndMatchDiscountList)
            switch (e) {
                case "ส่วนลด":
                    this.setState({
                        valueBenefit: e,
                        typeDiscountAndGiveAway: "ส่วนลด"
                    })
                    break;
                case "ของแถม":
                    this.setState({
                        valueBenefit: e,
                        typeDiscountAndGiveAway: "ของแถม",
                    })
                    break;
                default:
                    break;
            }
        } else {
            let NewQuantityDiscountList = [...quantityDiscountList]
            NewQuantityDiscountList[i].discountAndGiveAwayType = e
            this.setState({
                indexDiscountAndGiveaway: i,
                quantityDiscountList: NewQuantityDiscountList,
            })
            this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
            switch (e) {
                case "ส่วนลด":
                    NewQuantityDiscountList[i].promotionGifts = []
                    this.setState({
                        valueBenefit: e,
                        typeDiscountAndGiveAway: "ส่วนลด",
                        quantityDiscountList: NewQuantityDiscountList
                    })
                    break;
                case "ของแถม":

                    this.setState({
                        valueBenefit: e,
                        typeDiscountAndGiveAway: "ของแถม",
                    })
                    break;
                default:
                    break;
            }
        }
    }

    convertDateFromExcel = (dateExcel) => {
        // convert to milliseconds
        // dateExcel is number
        if (dateExcel) {
            const dateExcelMili = (dateExcel - (25569)) * 86400 * 1000
            return moment(dateExcelMili).format('YYYY/MM/DD')
        }
    }

    // Excel function -----------------
    mapExcelToTable = (dataExcel) => {
        // console.log("dataExcel=>", dataExcel);
        const { dataVoucherList } = this.state
        let NewDataVoucherList = [...dataVoucherList]
        let dataImport = []
        for (const index in dataExcel) {
            const row = dataExcel[index]
            if (+index === 0) continue
            if (isEmpty(row)) break
            const voucherCode = row[0]
            const dateEffective = row[1]
            const dateExpire = row[2]
            const voucherValue = row[3]

            NewDataVoucherList.push({
                voucherCode,
                dateEffective: this.convertDateFromExcel(dateEffective),
                dateExpire: this.convertDateFromExcel(dateExpire),
                voucherValue,
            })
        }
        let dataList = NewDataVoucherList.map((data, i) => ({
            ...data,
            no: i + 1,
            disabledInput: true,
            active: true,
        }))
        this.setState({
            dataVoucherList: dataList
        })
        this.props.getDataVoucherList(dataList)
    }
    readExcelFile = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()

        reader.onload = (e) => {
            try {
                let data = e.target.result
                data = new Uint8Array(data)
                const workbook = XLSX.read(data, { type: 'array' })
                const roa = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 })
                this.mapExcelToTable(roa)
            } catch (err) {
                swal('Error', 'กรุณาตรวจสอบความถูกต้องของไฟล์ Excel', 'error')
                console.error(err)
            }
        }
        reader.readAsArrayBuffer(file)
    }
    // ----------------------

    handleClickSearchQualifier = async (qualifierId) => {
        const { identifierName, productCode, indexKeyPattern, quantityDiscountList, indexArrayPromotionPattern,
            catagoryTypeKeyword, searchTypeKeyword } = this.state
        if ((indexKeyPattern === 2 /*Quantity Discounts*/ && (isEmpty(quantityDiscountList) || isEmpty(quantityDiscountList[indexArrayPromotionPattern]))) ||
            (indexKeyPattern === 2 /*Quantity Discounts*/ && !isEmpty(quantityDiscountList) && !isEmpty(quantityDiscountList[indexArrayPromotionPattern]) && isEmpty(quantityDiscountList[indexArrayPromotionPattern].qtyType))) {
            swal('Error', `กรุณาระบุประเภทเงื่อนไขที่ #${indexArrayPromotionPattern + 1}!`, 'error')
            return
        }

        // const isFilterProductOnly = indexKeyPattern === 2 /*Quantity Discounts*/ && !isEmpty(quantityDiscountList) && !isEmpty(quantityDiscountList[indexArrayPromotionPattern]) && quantityDiscountList[indexArrayPromotionPattern].qtyType === 'item_quantity';
        // let urlWithParams = `${API_PATH.GET_QUALIFIER}?name=${identifierName}&ProductCode=${productCode}`
        let urlWithParams = `${API_PATH.GET_QUALIFIER}?QualifierTypeCode=${catagoryTypeKeyword}&${searchTypeKeyword}=${encodeURI(productCode)}`

        this.setState({ loading: true })
        await apiServices.callApi('get', urlWithParams).then(res => {
            // console.log("res=>", res);
            let quantityResults = res.data.results;
            if (!isEmpty(quantityResults)) {
                // console.log('isFilterProductOnly',isFilterProductOnly);

                // if (isFilterProductOnly === true) {
                // quantityResults = quantityResults.filter(qua => qua.qualifierTypeCode === 'product')
                // }
                this.setState({
                    dataTableQualifierModal: quantityResults,
                    loading: false
                })
            }
            else {
                message.error("ไม่พบ qualifier")
                this.setState({
                    dataTableQualifierModal: [],
                    loading: false
                })
            }
            // if(res.)
        }).catch(error => {
            swal("error", error, "error")
        })
    }
    handleClickChooseQualifier = (valueFormTableReal) => {
        const {
            indexKeyPattern,
            quantityDiscountList,
            indexArrayPromotionPattern,
            arrayType,
            isCheckMixAndMatch,
            mixAndMatchDiscountList,
            modalType,
        } = this.state;
        let valueFormTable = JSON.parse(JSON.stringify(valueFormTableReal))

        if (arrayType) {
            if (modalType === "ของแถม") {
                if (isCheckMixAndMatch) {
                    let NewMixAndMatchPromotionGiftsList = {
                        name: valueFormTable.name,
                        description: valueFormTable.description,
                        dQualifierId: valueFormTable.itemQualifierId,
                        data: [...valueFormTable.promotionItemQualifierItem]
                    }
                    this.setState({
                        mixAndMatchPromotionGiftsList: NewMixAndMatchPromotionGiftsList
                    })
                    this.props.getDataPromotionGiftList(NewMixAndMatchPromotionGiftsList)
                }
                else {
                    let NewQuantityDiscountList = [...quantityDiscountList]
                    NewQuantityDiscountList[indexArrayPromotionPattern].promotionGifts = valueFormTable.promotionItemQualifierItem
                    NewQuantityDiscountList[indexArrayPromotionPattern].rewardQualifierName = valueFormTable.name
                    NewQuantityDiscountList[indexArrayPromotionPattern].rewardQualifierDescription = valueFormTable.description
                    this.setState({
                        quantityDiscountList: NewQuantityDiscountList
                    })
                    this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
                }
            } else {
                if (isCheckMixAndMatch) {
                    let NewMixAndMatchDiscountList = [...mixAndMatchDiscountList]
                    // NewMixAndMatchDiscountList[indexArrayPromotionPattern].qty = 1
                    valueFormTable.promotionItemQualifierItem = valueFormTable.promotionItemQualifierItem.map((item, i) => {
                        let qtyTotal = 0;
                        if (!isEmpty(NewMixAndMatchDiscountList[indexArrayPromotionPattern]) && NewMixAndMatchDiscountList[indexArrayPromotionPattern].qtyType === "item_quantity") {
                            qtyTotal = 1;
                        }

                        return { ...item, qty: qtyTotal }
                    })
                    NewMixAndMatchDiscountList[indexArrayPromotionPattern].ruleQualifierId = valueFormTable.itemQualifierId
                    NewMixAndMatchDiscountList[indexArrayPromotionPattern].qualifierName = valueFormTable.name
                    NewMixAndMatchDiscountList[indexArrayPromotionPattern].qualifierDescription = valueFormTable.description
                    NewMixAndMatchDiscountList[indexArrayPromotionPattern].promotionRuleMixAndMatchItemModels = valueFormTable.promotionItemQualifierItem
                    this.setState({
                        mixAndMatchDiscountList: NewMixAndMatchDiscountList
                    })
                    this.props.getDataPromotionMixAndMatchList(NewMixAndMatchDiscountList)
                } else {
                    let NewQuantityDiscountList = [...quantityDiscountList]
                    // valueFormTable.promotionItemQualifierItem = valueFormTable.promotionItemQualifierItem.map((data, i) => ({ ...data, qtySequenceId: indexArrayPromotionPattern, qty: qtyTotal }))
                    valueFormTable.promotionItemQualifierItem = valueFormTable.promotionItemQualifierItem.map((data, i) => {
                        let qtyTotal = 0;
                        if (!isEmpty(NewQuantityDiscountList[indexArrayPromotionPattern]) && NewQuantityDiscountList[indexArrayPromotionPattern].qtyType === "item_quantity") {
                            qtyTotal = 1;
                        }
                        return { ...data, qtySequenceId: indexArrayPromotionPattern, qty: qtyTotal }
                    })

                    NewQuantityDiscountList[indexArrayPromotionPattern].ruleQualifierId = valueFormTable.itemQualifierId
                    NewQuantityDiscountList[indexArrayPromotionPattern].qualifierName = valueFormTable.name
                    NewQuantityDiscountList[indexArrayPromotionPattern].qualifierDescription = valueFormTable.description
                    NewQuantityDiscountList[indexArrayPromotionPattern].promotionRuleDiscountQtyItemModels = valueFormTable.promotionItemQualifierItem
                    this.setState({
                        quantityDiscountList: NewQuantityDiscountList
                    })
                    this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
                }
            }
        }
        else {
            this.setState({
                dataSelectedQualifier: valueFormTable,
            })
            this.props.getQualifier(valueFormTable)
        }
        this.handleCloseModal()
    }
    renderSubTable = (rowDetail) => {
        const { dataTableQualifierModal, catagoryTypeKeyword } = this.state;
        // let 
        let columns = [
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
                width: '20%'
            },
            {
                title: 'ชื่อสินค้า / กลุ่มสินค้า',
                dataIndex: 'temName',
                key: 'temName',
                width: '60%'
            },
            {
                title: 'หน่วยสินค้า',
                dataIndex: 'unitCode',
                key: 'unitCode',
                width: '20%'
            },
        ];
        if (catagoryTypeKeyword === 'category') {
            columns = [
                {
                    title: 'รหัสกลุ่มสินค้า',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    width: '20%'
                },
                {
                    title: 'ชื่อกลุ่มสินค้า',
                    dataIndex: 'temName',
                    key: 'temName',
                    width: '80%'
                },
            ];
        }


        return <Table
            columns={columns}
            dataSource={rowDetail.promotionItemQualifierItem}
            // dataSource={dataTableQualifierModal[index].promotionItemQualifierItem}
            pagination={false}
            rowKey={(row, index) => index}
            size="small"
        />;
        // return index;
    }
    handleClickModal = (value, index, modalType) => {
        this.setState({
            isShowModal: true,
            keyTypePattern: value,
            indexArrayPromotionPattern: index,
            modalType: modalType,
        })
    }
    handleCloseModal = () => {
        this.setState({
            isShowModal: false,
        })
    }
    handlcClickClearValue = () => {
        this.setState({
            dataTableQualifierModal: [],
            productCode: '',
            identifierName: ''
        })
    }

    // function dynamic array quatity discount
    handleClickAddCondition = () => {
        const { quantityDiscountList, isCheckMixAndMatch, mixAndMatchDiscountList } = this.state
        // console.log('isCheckMixAndMatch',isCheckMixAndMatch);

        if (isCheckMixAndMatch) {
            let NewMixAndMatchDiscountList = JSON.parse(JSON.stringify([...mixAndMatchDiscountList]))
            NewMixAndMatchDiscountList.push({})
            this.setState({
                mixAndMatchDiscountList: NewMixAndMatchDiscountList
            })
            this.props.getDataPromotionMixAndMatchList(NewMixAndMatchDiscountList)
        } else {
            const firstQuantityDiscount = first(quantityDiscountList)
            let NewQuantityDiscountList = [...quantityDiscountList]
            if (!isEmpty(firstQuantityDiscount)) {
                // For bug duplicate
                const discountListProduct = JSON.parse(JSON.stringify(firstQuantityDiscount))

                // promotionGifts
                NewQuantityDiscountList.push({
                    ruleQualifierId: firstQuantityDiscount.ruleQualifierId,
                    qtyType: firstQuantityDiscount.qtyType,
                    promotionRuleDiscountQtyItemModels: discountListProduct.promotionRuleDiscountQtyItemModels,
                    // .map(item => {
                    //     item.indexHead = quantityDiscountListCount + 1
                    //     return item
                    // }),
                    qualifierName: firstQuantityDiscount.qualifierName,
                    qualifierDescription: firstQuantityDiscount.qualifierDescription,
                    disabledItems: firstQuantityDiscount.disabledItems,
                    disabledTotalAmount: firstQuantityDiscount.disabledTotalAmount,
                    disabledTotalQuatity: firstQuantityDiscount.disabledTotalQuatity,
                    promotionGifts: discountListProduct.promotionGifts
                })
            } else {
                NewQuantityDiscountList.push({})
            }
            this.setState({
                quantityDiscountList: NewQuantityDiscountList
            })
            this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
        }
    }
    handleClickDeleteCondition = (index) => {
        const { quantityDiscountList, isCheckMixAndMatch, mixAndMatchDiscountList } = this.state
        if (isCheckMixAndMatch) {
            let NewMixAndMatchDiscountList = [...mixAndMatchDiscountList]
            NewMixAndMatchDiscountList.splice(index, 1)
            this.setState({
                mixAndMatchDiscountList: NewMixAndMatchDiscountList
            })
            this.props.getDataPromotionMixAndMatchList(NewMixAndMatchDiscountList)
        } else {
            let NewQuantityDiscountList = [...quantityDiscountList]
            NewQuantityDiscountList.splice(index, 1)
            this.setState({
                quantityDiscountList: NewQuantityDiscountList
            })
            this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
        }
    }
    handleChangeCondition = (e, i) => {
        const { quantityDiscountList, isCheckMixAndMatch, mixAndMatchDiscountList } = this.state
        if (isCheckMixAndMatch) {
            let NewMixAndMatchDiscountList = [...mixAndMatchDiscountList]
            NewMixAndMatchDiscountList[i].qtyType = e
            if (e === conditionType[0].value) {
                NewMixAndMatchDiscountList[i].disabledTotalAmount = true
                NewMixAndMatchDiscountList[i].disabledTotalQuatity = true
                NewMixAndMatchDiscountList[i].disabledItems = false
            }
            if (e === conditionType[1].value) {
                NewMixAndMatchDiscountList[i].disabledTotalAmount = true
                NewMixAndMatchDiscountList[i].disabledTotalQuatity = false
                NewMixAndMatchDiscountList[i].disabledItems = true
            }
            if (e === conditionType[2].value) {
                NewMixAndMatchDiscountList[i].disabledTotalAmount = false
                NewMixAndMatchDiscountList[i].disabledTotalQuatity = true
                NewMixAndMatchDiscountList[i].disabledItems = true
            }
            this.setState({
                mixAndMatchDiscountList: NewMixAndMatchDiscountList
            })
            this.props.getDataPromotionMixAndMatchList(NewMixAndMatchDiscountList)
        } else {
            let NewQuantityDiscountList = [...quantityDiscountList]
            NewQuantityDiscountList[i].qtyType = e
            let startValue = 0;
            if (e === conditionType[0].value) {
                NewQuantityDiscountList[i].disabledTotalAmount = true
                NewQuantityDiscountList[i].disabledTotalQuatity = true
                NewQuantityDiscountList[i].disabledItems = false
                startValue = 1;
            }
            if (e === conditionType[1].value) {
                NewQuantityDiscountList[i].disabledTotalAmount = true
                NewQuantityDiscountList[i].disabledTotalQuatity = false
                NewQuantityDiscountList[i].disabledItems = true
                startValue = 0;
            }
            if (e === conditionType[2].value) {
                NewQuantityDiscountList[i].disabledTotalAmount = false
                NewQuantityDiscountList[i].disabledTotalQuatity = true
                NewQuantityDiscountList[i].disabledItems = true
                startValue = 0;
            }
            if (NewQuantityDiscountList[i].promotionRuleDiscountQtyItemModels) {
                NewQuantityDiscountList[i].promotionRuleDiscountQtyItemModels.map(item => {
                    item.qty = startValue
                    return item
                })
            }

            this.setState({
                quantityDiscountList: NewQuantityDiscountList
            })
            this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
        }
    }
    handleChangeTotalAmount = (e, i) => {
        const { quantityDiscountList, isCheckMixAndMatch, mixAndMatchDiscountList } = this.state
        if (isCheckMixAndMatch) {
            let NewMixAndMatchDiscountList = [...mixAndMatchDiscountList]
            NewMixAndMatchDiscountList[i].totalQuatity = e.target.value
            this.setState({
                mixAndMatchDiscountList: NewMixAndMatchDiscountList
            })
            this.props.getDataPromotionMixAndMatchList(NewMixAndMatchDiscountList)
        } else {
            let NewQuantityDiscountList = [...quantityDiscountList]
            NewQuantityDiscountList[i].totalQuatity = e.target.value
            this.setState({
                quantityDiscountList: NewQuantityDiscountList
            })
            this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
        }
    }
    handleChangeQuatityAmount = (e, i) => {
        const { quantityDiscountList, isCheckMixAndMatch, mixAndMatchDiscountList } = this.state
        if (isCheckMixAndMatch) {
            let NewMixAndMatchDiscountList = [...mixAndMatchDiscountList]
            NewMixAndMatchDiscountList[i].totalQuatity = e.target.value
            this.setState({
                mixAndMatchDiscountList: NewMixAndMatchDiscountList
            })
            this.props.getDataPromotionMixAndMatchList(NewMixAndMatchDiscountList)
        }
        else {
            let NewQuantityDiscountList = [...quantityDiscountList]
            NewQuantityDiscountList[i].totalQuatity = e.target.value
            this.setState({
                quantityDiscountList: NewQuantityDiscountList
            })
            this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
        }
    }
    handleChangeDiscount = (e, index) => {
        const {
            quantityDiscountList,
            typeDiscountAndGiveAway,
            indexDiscountAndGiveaway,
            isCheckMixAndMatch,
            mixAndMatchDiscountList
        } = this.state
        if (isCheckMixAndMatch) {
            let NewMixAndMatchDiscountList = [...mixAndMatchDiscountList]
            NewMixAndMatchDiscountList[indexDiscountAndGiveaway].dTypecode = e
            this.setState({
                mixAndMatchDiscountList: NewMixAndMatchDiscountList
            })
            this.handleChangeDiscountAndGiveAway(typeDiscountAndGiveAway, indexDiscountAndGiveaway)
            this.props.getDataPromotionMixAndMatchList(NewMixAndMatchDiscountList)
        } else {
            let NewQuantityDiscountList = [...quantityDiscountList]
            NewQuantityDiscountList[index].dTypecode = e
            this.setState({
                quantityDiscountList: NewQuantityDiscountList
            })
            this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
        }
    }
    handleChangeDiscountAmount = (e, index) => {
        const {
            quantityDiscountList,
            indexDiscountAndGiveaway,
            isCheckMixAndMatch,
            mixAndMatchDiscountList,
        } = this.state
        if (isCheckMixAndMatch) {
            let NewMixAndMatchDiscountList = [...mixAndMatchDiscountList]
            // console.log(indexDiscountAndGiveaway);
            NewMixAndMatchDiscountList[indexDiscountAndGiveaway].dValue = +e.target.value
            this.setState({
                mixAndMatchDiscountList: NewMixAndMatchDiscountList
            })
            this.props.getDataPromotionMixAndMatchList(NewMixAndMatchDiscountList)
        } else {
            let NewQuantityDiscountList = [...quantityDiscountList]
            // console.log(index);
            NewQuantityDiscountList[index].dValue = +e.target.value
            this.setState({
                quantityDiscountList: NewQuantityDiscountList
            })
            this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
        }
    }

    handleChangeNumberQtyBundle = (e, index) => {
        let { dataSelectedQualifier } = this.state;
        const value = +e.target.value
        dataSelectedQualifier.promotionItemQualifierItem[index].qty = value
        this.setState({
            dataSelectedQualifier
        })
        this.props.getQualifier(dataSelectedQualifier)
    }

    handleChangeNumberQty = (e, index, indexHead) => {
        const {
            quantityDiscountList,
            isCheckMixAndMatch,
            mixAndMatchDiscountList,
        } = this.state;

        if (isCheckMixAndMatch) {
            let NewMixAndMatchDiscountList = [...mixAndMatchDiscountList]
            NewMixAndMatchDiscountList[indexHead].promotionRuleMixAndMatchItemModels[index].qty = +e.target.value
            this.setState({
                mixAndMatchDiscountList: NewMixAndMatchDiscountList
            })
            this.props.getDataPromotionMixAndMatchList(NewMixAndMatchDiscountList)
        }
        else {
            let NewQuantityDiscountList = [...quantityDiscountList]
            NewQuantityDiscountList[indexHead].promotionRuleDiscountQtyItemModels[index].qty = +e.target.value
            this.setState({
                quantityDiscountList: NewQuantityDiscountList
            })
            this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
        }
    }
    handleChangeNumberQtyGift = (e, index, indexHead) => {
        const {
            quantityDiscountList,
            isCheckMixAndMatch,
            mixAndMatchPromotionGiftsList,
        } = this.state

        if (isCheckMixAndMatch) {
            let NewMixAndMatchPromotionGiftsList = { ...mixAndMatchPromotionGiftsList }
            NewMixAndMatchPromotionGiftsList.data[index].qtys = +e.target.value
            this.setState({
                mixAndMatchPromotionGiftsList: NewMixAndMatchPromotionGiftsList
            })
            this.props.getDataPromotionGiftList(NewMixAndMatchPromotionGiftsList)
        }
        else {
            let NewQuantityDiscountList = [...quantityDiscountList]
            NewQuantityDiscountList[indexHead].promotionGifts[index].qtys = +e.target.value
            this.setState({
                quantityDiscountList: NewQuantityDiscountList
            })
            this.props.dataPromotionQuatityDiscount(NewQuantityDiscountList)
        }
    }
    handleChangeMixAndMatchGift = (e) => {
        const { discountMixAndMatch } = this.state
        let NewDiscountMixAndMatch = discountMixAndMatch
        NewDiscountMixAndMatch.rewardType = e
        this.setState({
            discountMixAndMatch: NewDiscountMixAndMatch
        })
        this.props.getDataDiscountMixAndMatch(NewDiscountMixAndMatch)
    }
    handleChangeDiscountMixAndMatch = (e) => {
        const { discountMixAndMatch } = this.state
        let NewDiscountMixAndMatch = discountMixAndMatch
        NewDiscountMixAndMatch.dTypecode = e
        this.setState({
            discountMixAndMatch: NewDiscountMixAndMatch,
        })
        this.props.getDataDiscountMixAndMatch(NewDiscountMixAndMatch)
    }
    handleChangeDiscountAmountMixAndMatch = (e) => {
        const { discountMixAndMatch } = this.state
        let NewDiscountMixAndMatch = discountMixAndMatch
        NewDiscountMixAndMatch.dValue = +e.target.value
        this.setState({
            discountMixAndMatch: NewDiscountMixAndMatch,
        })
        this.props.getDataDiscountMixAndMatch(NewDiscountMixAndMatch)
    }
    // -----------------------------
    // onchange value date
    setNewDataVoucherList = (NewDataVoucherList) => {
        const { indexKeyPattern } = this.state
        this.setState({
            dataVoucherList: NewDataVoucherList
        })
    }
    handleChangeDateEffective = (e, dataString, index) => {
        const { dataVoucherList, } = this.state
        let NewDataVoucherList = [...dataVoucherList]
        NewDataVoucherList[index].dateEffective = dataString
        this.setNewDataVoucherList(NewDataVoucherList)
    }
    handleChangeDateExpire = (e, dataString, index) => {
        const { dataVoucherList, } = this.state
        let NewDataVoucherList = [...dataVoucherList]
        NewDataVoucherList[index].dateExpire = dataString
        this.setNewDataVoucherList(NewDataVoucherList)
    }
    // onchange voucher code
    handleChangeVoucherCode = (e, index) => {
        const { dataVoucherList, } = this.state
        let NewDataVoucherList = [...dataVoucherList]
        NewDataVoucherList[index].voucherCode = e.target.value
        this.setNewDataVoucherList(NewDataVoucherList)
    }

    handleChangeVoucherValue = (e, index) => {
        let newDataVoucherList = [...this.state.dataVoucherList]
        newDataVoucherList[index].voucherValue = Number(`${e.target.value}`.replace(/,/g, ''))
        this.setNewDataVoucherList(newDataVoucherList)
    }

    handleChangeVoucherActive = (e, index) => {
        let newDataVoucherList = [...this.state.dataVoucherList]
        newDataVoucherList[index].active = e.target.checked
        this.setNewDataVoucherList(newDataVoucherList)
    }

    //  onclick edit and delete row voucher
    handleClickEditRowVoucher = (index) => {
        console.log("index==>", index);

        const { dataVoucherList } = this.state
        let NewDataVoucherList = [...dataVoucherList]
        NewDataVoucherList[index].disabledInput = false
        this.setState({
            dataVoucherList: NewDataVoucherList
        })
    }
    handleClickConfirmRowVoucher = (index) => {
        const { dataVoucherList } = this.state
        let NewDataVoucherList = [...dataVoucherList]
        NewDataVoucherList[index].disabledInput = true
        this.setState({
            dataVoucherList: NewDataVoucherList
        })
        this.props.getDataVoucherList(NewDataVoucherList)
    }
    handleClickDeleteRowVoucher = (index) => {
        const { dataVoucherList } = this.state
        let NewDataVoucherList = [...dataVoucherList]
        NewDataVoucherList.splice(index, 1)
        this.setState({
            dataVoucherList: NewDataVoucherList
        })
        this.props.getDataVoucherList(NewDataVoucherList)
    }
    handleClickAddVoucher = () => {
        const { dataVoucherList } = this.state
        let NewDataVoucherList = [...dataVoucherList]
        NewDataVoucherList.push({
            voucherCode: '',
            dateEffective: moment(),
            dateExpire: moment(),
            disabledInput: false,
            voucherValue: '',
            active: true,
        })
        let dataList = NewDataVoucherList.map((data, i) => (
            {
                ...data,
                no: i + 1,
            }
        ))
        this.setState({
            dataVoucherList: dataList
        })
    }
    handleChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    changeFilter = (key, val) => {
        // const { filter } = this.state
        let checkFilter = { [key]: val }
        if (key === 'catagoryTypeKeyword') {
            checkFilter = {
                ...checkFilter,
                searchTypeKeyword: ''
            }
        }
        this.setState({ ...checkFilter })

    }
    // -----------------------------

    render() {
        const {
            isShowModal,
            identifierName,
            productCode,
            dataTableQualifierModal,
            columnsQualifier,
            loading,
            patternType,
            dataSelectedQualifier,
            discountMixAndMatch,
            mixAndMatchPromotionGiftsList,
            quantityDiscountList,
            columnsGift,
            mixAndMatchDiscountList,
            columnsItemsBundleRule,
            columnsItems,
            columnsVoucher,
            dataVoucherList,
            catagoryTypes,
            searchTypes,
            catagoryTypeKeyword,
            searchTypeKeyword
        } = this.state;
        const { submitViewError } = this.props
        // console.log('this.state.dataSelectedQualifier', this.state);
        // promotionPattern[0].value
        
        const checkedVoucherToUse = dataVoucherList.findIndex(voucher => voucher.active === false);
        const checkVoucherToDisbaled = (patternType === "Voucher") && (checkedVoucherToUse !== -1);
        const InputSearchEnc = (props) => {
            return <InputSearch
                {...props}
                label="Qualifier"
                handleClickSearch={() => this.handleClickModal(props.promotionPattern)}
                value={dataSelectedQualifier && dataSelectedQualifier.name}
                disabled={checkVoucherToDisbaled}
                disabledButton={checkVoucherToDisbaled}
            />
        }
        return (
            <div className="promotion-maintain-form">
                <Row type="flex" justify="center" >
                    <Col md={10}>
                        <Field
                            name="pattern"
                            label="รูปแบบโปรโมชั่น"
                            component={SelectRedux}
                            options={promotionPattern}
                            onChangeHero={this.handleChangePattern}
                            validate={VALIDATE.REQUIRE}
                            submitViewError={submitViewError}
                            disabled={checkVoucherToDisbaled}
                        />
                    </Col>
                </Row>
                <div className="promotion-content-form">
                    {
                        patternType === promotionPattern[0].value && <>
                            <Row gutter={10}>
                                <Col md={10}>
                                    <Field
                                        name="dataSelectedQualifier"
                                        label="Qualifier"
                                        promotionPattern={promotionPattern[0].value}
                                        component={InputSearchEnc}
                                        validate={!dataSelectedQualifier ||
                                            (dataSelectedQualifier.promotionItemQualifierItem &&
                                                dataSelectedQualifier.promotionItemQualifierItem.length === 0) ? VALIDATE.REQUIRE : () => { }}
                                        submitViewError={!dataSelectedQualifier && submitViewError}
                                    />
                                    {/* <InputSearch
                                        label="Qualifier"
                                        handleClickSearch={() => this.handleClickModal(promotionPattern[0].value)}
                                        value={dataSelectedQualifier && dataSelectedQualifier.name}
                                        validate={VALIDATE.REQUIRE}
                                        submitViewError={submitViewError}
                                    // validate={(patternType === promotionPattern[0].value ? VALIDATE.REQUIRE : () => { })}
                                    // submitViewError={submitViewError}
                                    /> */}
                                </Col>
                                <Col md={14}>
                                    {dataSelectedQualifier && dataSelectedQualifier.description}
                                </Col>
                            </Row>
                            <Row type="flex" justify="center">
                                <Col style={{ paddingTop: 10 }} md={18}>
                                    <TableCustom
                                        columns={columnsItemsBundleRule}
                                        data={dataSelectedQualifier &&
                                            dataSelectedQualifier.promotionItemQualifierItem &&
                                            dataSelectedQualifier.promotionItemQualifierItem.map((data2, i2) => ({
                                                ...data2,
                                                no: i2 + 1,
                                                qty: 1,
                                                disabledItems: true
                                                // disabledItems: data.disabledItems
                                            }))}
                                        small
                                        rowKey={(row, index) => index}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col md={10}>
                                    <Field
                                        name="dTypeCode"
                                        type="number"
                                        label="ประเภทส่วนลด"
                                        component={SelectRedux}
                                        options={discountType}
                                        validate={VALIDATE.REQUIRE}
                                        // validate={(patternType === promotionPattern[0].value ? VALIDATE.REQUIRE : () => { })}
                                        submitViewError={submitViewError}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col md={10}>
                                    <Field name="valueAmount"/*"dValue"*/ label="จำนวนส่วนลด" component={InputFormRedux} type="number"
                                        validate={VALIDATE.REQUIRE}
                                        submitViewError={submitViewError}
                                    />
                                </Col>
                                <Col>
                                    บาท/ %
                                </Col>
                            </Row>
                        </>
                    }
                    {
                        patternType === promotionPattern[1].value && <>
                            <Row gutter={10}>
                                <Col md={10}>
                                    <Field
                                        name="dataSelectedQualifier"
                                        label="Qualifier"
                                        promotionPattern={promotionPattern[1].value}
                                        component={InputSearchEnc}
                                        validate={!dataSelectedQualifier ? VALIDATE.REQUIRE : () => { }}
                                        submitViewError={!dataSelectedQualifier && submitViewError}
                                    />
                                    {/* <InputSearch
                                        label="Qualifier"
                                        handleClickSearch={this.handleClickModal}
                                        value={dataSelectedQualifier && dataSelectedQualifier.name}
                                    /> */}
                                </Col>
                                <Col md={14}>
                                    {dataSelectedQualifier && dataSelectedQualifier.description}
                                </Col>
                            </Row>
                            <Row gutter={10} type="flex" justify="center">
                                <Col style={{ paddingTop: 10, paddingButtom: 10 }} md={18}>
                                    <TableCustom
                                        columns={columnsItemsBundleRule}
                                        data={dataSelectedQualifier &&
                                            dataSelectedQualifier.promotionItemQualifierItem &&
                                            dataSelectedQualifier.promotionItemQualifierItem.map((data2, i2) => ({
                                                ...data2,
                                                no: i2 + 1,
                                                qty: 1,
                                                disabledItems: true
                                                // disabledItems: data.disabledItems
                                            }))}
                                        small
                                        rowKey={(row, index) => index}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col md={10}>
                                    <Field
                                        name="dPromotionType"
                                        label="ประเภทส่งเสริมการขาย"
                                        component={SelectRedux}
                                        options={promotionType}
                                        validate={VALIDATE.REQUIRE}
                                        submitViewError={submitViewError}
                                    />
                                </Col>
                                <Col md={12}>
                                    <Field name="dTypeCode" label="ประเภทส่วนลด" component={SelectRedux} options={discountType}
                                        validate={VALIDATE.REQUIRE}
                                        submitViewError={submitViewError}
                                    />
                                </Col>
                                <Col md={9}>
                                    <Field name="valueAmount" label="จำนวนส่วนลด" component={InputFormRedux}
                                        min={0}
                                        validate={VALIDATE.REQUIRE}
                                        submitViewError={submitViewError}
                                    />
                                </Col>
                                <Col md={3}>
                                    บาท/ %  ต่อหน่วย
                                </Col>
                                <Col md={12}>
                                    <Field name="value" type="number" label="จำนวนเงินที่เข้าร่วม" component={InputFormRedux} />
                                </Col>
                                <Col md={24}>
                                    <Field name="dMessage" label="ข้อความส่วนลด" component={InputFormRedux} />
                                </Col>
                            </Row>
                        </>
                    }
                    {
                        patternType === promotionPattern[2].value && <>
                            {
                                quantityDiscountList.map((data, index) => (
                                    <div className="block-card" key={index}>
                                        <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>
                                                กำหนดเงื่อนไข #{index + 1}
                                            </span>
                                            {
                                                index + 1 === quantityDiscountList.length &&
                                                <ButtonCustom
                                                    small icon="minus"
                                                    text="ลบเงื่อนไข"
                                                    type="danger"
                                                    onClick={() => this.handleClickDeleteCondition(index)}
                                                />
                                            }
                                        </h3>
                                        <div className="mix-match-disconnt">
                                            <Row gutter={10}>
                                                <Col md={12}>
                                                    <SelectHero
                                                        label="ประเภทเงื่อนไข"
                                                        options={conditionType}
                                                        value={data.qtyType}
                                                        onChange={(e) => this.handleChangeCondition(e, index)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={10}>
                                                <Col md={10}>
                                                    <InputSearch
                                                        label="Qualifier"
                                                        handleClickSearch={
                                                            () => this.handleClickModal(promotionPattern[2].value, index, "normal")
                                                        }
                                                        value={data.qualifierName}
                                                    />
                                                </Col>
                                                <Col md={14}>
                                                    {data.qualifierDescription}
                                                </Col>
                                            </Row>
                                            <Row gutter={10}>
                                                <Col md={12}>
                                                    <InputHero
                                                        label="Total Amount"
                                                        type="number"
                                                        name="totalAmount"
                                                        onChange={(e) => this.handleChangeTotalAmount(e, index)}
                                                        value={data.qtyType === 'total_amount' ? data.totalQuatity : ''}
                                                        disabled={data.disabledTotalAmount}
                                                    />
                                                </Col>
                                                <Col md={10}>
                                                    <InputHero
                                                        label="Total Quantity"
                                                        type="number"
                                                        name="totalQuatity"
                                                        onChange={(e) => this.handleChangeQuatityAmount(e, index)}
                                                        value={data.qtyType === 'total_quantity' ? data.totalQuatity : ''}
                                                        disabled={data.disabledTotalQuatity}
                                                    />
                                                </Col>
                                                <Col md={2}>
                                                    จำนวน
                                                  </Col>
                                            </Row>
                                            <Row type="flex" justify="center">
                                                <Col style={{ paddingTop: 10 }} md={18}>
                                                    <TableCustom
                                                        columns={columnsItems}
                                                        data={data.promotionRuleDiscountQtyItemModels &&
                                                            data.promotionRuleDiscountQtyItemModels.map((data2, i2) => ({
                                                                ...data2,
                                                                indexHead: index,
                                                                no: i2 + 1,
                                                                disabledItems: data.disabledItems
                                                            }))}
                                                        small
                                                        rowKey={(row, index) => index}
                                                        pagination={false}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <h3>
                                            ส่วนลด/ของแถม
                                          </h3>
                                        <div className="mix-match-disconnt">
                                            <Row gutter={10}>
                                                <Col md={12}>
                                                    <SelectHero
                                                        label="ประเภทผลประโยชน์"
                                                        options={discountAndGiveAway}
                                                        onChange={(e) => this.handleChangeDiscountAndGiveAway(e, index)}
                                                        value={data.discountAndGiveAwayType}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {data.discountAndGiveAwayType === "ส่วนลด" &&
                                                    <React.Fragment>
                                                        <Row gutter={10}>
                                                            <Col md={12}>
                                                                <SelectHero
                                                                    name="discountType1"
                                                                    label="ประเภทส่วนลด"
                                                                    options={discountType}
                                                                    onChange={(event) => this.handleChangeDiscount(event, index)}
                                                                    value={data.dTypecode}
                                                                />
                                                            </Col>
                                                            <Col md={10}>
                                                                <InputHero
                                                                    name="discountamount1"
                                                                    label="จำนวนส่วนลด"
                                                                    onChange={(event) => this.handleChangeDiscountAmount(event, index)}
                                                                    value={data.dValue}
                                                                />
                                                            </Col>
                                                            <Col md={2}>
                                                                บาท / %
                                                              </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                }
                                                {
                                                    data.discountAndGiveAwayType === "ของแถม" &&
                                                    <React.Fragment>
                                                        <Row gutter={10}>
                                                            <Col md={10}>
                                                                <InputSearch
                                                                    label="Qualifier"
                                                                    handleClickSearch={() =>
                                                                        this.handleClickModal(
                                                                            promotionPattern[2].value,
                                                                            index,
                                                                            "ของแถม"
                                                                        )
                                                                    }
                                                                    value={data.rewardQualifierName}
                                                                />
                                                            </Col>
                                                            <Col md={14}>
                                                                {data.rewardQualifierDescription}
                                                            </Col>
                                                        </Row>
                                                        <Row type="flex" justify="center">
                                                            <Col style={{ paddingTop: 10 }} md={18}>
                                                                <TableCustom
                                                                    columns={columnsGift}
                                                                    small
                                                                    data={
                                                                        data.promotionGifts &&
                                                                        data.promotionGifts.map((data3, i3) => ({
                                                                            ...data3,
                                                                            indexHead: index,
                                                                            no: i3 + 1,
                                                                        }))}
                                                                    pagination={false}
                                                                    rowKey={(row, index) => index}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                quantityDiscountList.length < 5 && <ButtonCustom
                                    small
                                    icon="plus"
                                    text={`เพิ่มขั้นที่ ${quantityDiscountList.length + 1}`}
                                    onClick={this.handleClickAddCondition}
                                />
                            }
                        </>
                    }
                    {
                        patternType === promotionPattern[3].value && <>
                            {
                                mixAndMatchDiscountList.map((data, index) => (
                                    <div className="block-card" key={index}>
                                        <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>
                                                กำหนดเงื่อนไข #{index + 1}
                                            </span>
                                            {
                                                <ButtonCustom
                                                    small icon="minus"
                                                    text="ลบเงื่อนไข"
                                                    type="danger"
                                                    onClick={() => this.handleClickDeleteCondition(index)}
                                                />
                                            }
                                        </h3>
                                        <div className="mix-match-disconnt">
                                            <Row gutter={10}>
                                                <Col md={12}>
                                                    <SelectHero
                                                        label="ประเภทเงื่อนไข"
                                                        options={conditionType}
                                                        value={data.qtyType}
                                                        onChange={(e) => this.handleChangeCondition(e, index)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={10}>
                                                <Col md={10}>
                                                    <InputSearch
                                                        label="Qualifier"
                                                        handleClickSearch={
                                                            () => this.handleClickModal(promotionPattern[2].value, index, "normal")
                                                        }
                                                        value={data.qualifierName}
                                                    />
                                                </Col>
                                                <Col md={14}>
                                                    {data.qualifierDescription}
                                                </Col>
                                            </Row>
                                            <Row gutter={10}>
                                                <Col md={12}>
                                                    <InputHero
                                                        label="Total Amount"
                                                        type="number"
                                                        name="totalAmount"
                                                        onChange={(e) => this.handleChangeTotalAmount(e, index)}
                                                        value={data.qtyType === 'total_amount' ? data.totalQuatity : ''}
                                                        disabled={data.disabledTotalAmount}
                                                    />
                                                </Col>
                                                <Col md={10}>
                                                    <InputHero
                                                        label="Total Quantity"
                                                        type="number"
                                                        name="totalQuatity"
                                                        onChange={(e) => this.handleChangeQuatityAmount(e, index)}
                                                        value={data.qtyType === 'total_quantity' ? data.totalQuatity : ''}
                                                        disabled={data.disabledTotalQuatity}
                                                    />
                                                </Col>
                                                <Col md={2}>
                                                    จำนวน
                                             </Col>
                                            </Row>
                                            <Row type="flex" justify="center">
                                                <Col style={{ paddingTop: 10 }} md={18}>
                                                    <TableCustom
                                                        columns={columnsItems}
                                                        data={data.promotionRuleMixAndMatchItemModels &&
                                                            data.promotionRuleMixAndMatchItemModels.map((data2, i2) => ({
                                                                ...data2,
                                                                no: i2 + 1,
                                                                indexHead: index,
                                                                disabledItems: data.disabledItems
                                                            }))}
                                                        small
                                                        rowKey={(row, index) => index}
                                                        pagination={false}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                mixAndMatchDiscountList.length < 5 && <ButtonCustom
                                    small
                                    icon="plus"
                                    text={`เพิ่มเงื่อนไขที่ ${mixAndMatchDiscountList.length + 1}`}
                                    onClick={this.handleClickAddCondition}
                                />
                            }
                            <h3>
                                ส่วนลด/ของแถม
                                     </h3>
                            <div className="mix-match-disconnt">
                                <Row gutter={10}>
                                    <Col md={12}>
                                        <SelectHero
                                            label="ประเภทผลประโยชน์"
                                            options={discountAndGiveAway}
                                            onChange={(e) => this.handleChangeMixAndMatchGift(e)}
                                            value={discountMixAndMatch.rewardType}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {discountMixAndMatch.rewardType === "ส่วนลด" &&
                                <React.Fragment>
                                    <Row gutter={10}>
                                        <Col md={12}>
                                            <SelectHero
                                                name="discountType1"
                                                label="ประเภทส่วนลด"
                                                options={discountType}
                                                onChange={(event) => this.handleChangeDiscountMixAndMatch(event)}
                                                value={discountMixAndMatch.dTypecode}
                                            />
                                        </Col>
                                        <Col md={10}>
                                            <InputHero
                                                name="discountamount1"
                                                label="จำนวนส่วนลด"
                                                onChange={(event) => this.handleChangeDiscountAmountMixAndMatch(event)}
                                                value={discountMixAndMatch.dValue}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            บาท / %
                                     </Col>
                                    </Row>
                                </React.Fragment>
                            }
                            {discountMixAndMatch.rewardType === "ของแถม" &&
                                <React.Fragment>
                                    <Row gutter={10}>
                                        <Col md={10}>
                                            <InputSearch
                                                label="Qualifier"
                                                handleClickSearch={() => this.handleClickModal(promotionPattern[2].value, '', "ของแถม")}
                                                value={mixAndMatchPromotionGiftsList.name}
                                            />
                                        </Col>
                                        <Col md={14}>
                                            {mixAndMatchPromotionGiftsList.description}
                                        </Col>
                                    </Row>
                                    <Row type="flex" justify="center">
                                        <Col style={{ paddingTop: 10 }} md={18}>
                                            <TableCustom
                                                columns={columnsGift}
                                                small
                                                data={mixAndMatchPromotionGiftsList.data.map((data, i) => ({
                                                    ...data,
                                                    no: i + 1,
                                                }))}
                                                pagination={false}
                                                rowKey={(row, index) => index}
                                            />
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            }
                        </>
                    }
                    {
                        patternType === promotionPattern[4].value && <>
                            <Row gutter={10}>
                                <Col md={10}>
                                    <Field
                                        name="dataSelectedQualifier"
                                        label="Qualifier"
                                        promotionPattern={promotionPattern[4].value}
                                        component={InputSearchEnc}
                                    // validate={!dataSelectedQualifier ? VALIDATE.REQUIRE : () => { }}
                                    // submitViewError={!dataSelectedQualifier && submitViewError}
                                    />
                                    {/* <InputSearch
                                        label="Qualifier"
                                        handleClickSearch={this.handleClickModal}
                                        value={dataSelectedQualifier && dataSelectedQualifier.name}
                                    /> */}
                                </Col>
                                <Col md={14}>
                                    {dataSelectedQualifier && dataSelectedQualifier.description}
                                </Col>
                            </Row>
                            <Row gutter={10} type="flex" justify="center">
                                <Col style={{ paddingTop: 10, paddingButtom: 10 }} md={18}>
                                    <TableCustom
                                        columns={columnsItemsBundleRule}
                                        data={dataSelectedQualifier &&
                                            dataSelectedQualifier.promotionItemQualifierItem &&
                                            dataSelectedQualifier.promotionItemQualifierItem.map((data2, i2) => ({
                                                ...data2,
                                                no: i2 + 1,
                                                qty: 1,
                                                disabledItems: true
                                                // disabledItems: data.disabledItems
                                            }))}
                                        small
                                        rowKey={(row, index) => index}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                            <Row type="flex" className="upload-box input-form">
                                <Col md={12} className="label-style">
                                    Upload Excel
                                </Col>
                                <Col md={12} style={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <input
                                        className="file-input"
                                        type="file" id="input"
                                        onChange={this.readExcelFile}
                                        onClick={e => (e.target.value = null)}
                                    />
                                    <br />
                                    <a href={API_PATH.GET_DOWNLOAD_TEMPLATE_EXCEL_VOUCHER} className="download-link">
                                        <Icon type="file-excel" /> Download Format Excel
                                    </a>
                                </Col>
                            </Row>
                            <Row type="flex" justify="center">
                                <Col md={18} style={{ textAlign: 'right' }}>
                                    <ButtonCustom onClick={this.handleClickAddVoucher} small text="เพิ่มข้อมูล" icon="plus" />
                                </Col>
                            </Row>
                            <Row type="flex" justify="center">
                                <Col style={{ paddingTop: 10 }} md={20}>
                                    <TableCustom
                                        columns={columnsVoucher}
                                        small
                                        data={dataVoucherList}
                                        rowKey={(row, index) => index}
                                    />
                                </Col>
                            </Row>
                        </>
                    }
                </div>
                {/* จ้า */}
                <ModalCustom
                    isShowModal={isShowModal}
                    handleCloseModal={this.handleCloseModal}
                    title="Search Qualifier"
                    footer={false}
                    width="700px"
                >
                    <Row gutter={10}>
                        <Col md={7}>
                            <SelectCustom
                                label="ประเภท"
                                name='catagoryTypeKeyword'
                                small
                                widthLabel='70px'
                                onChange={(value) => this.changeFilter('catagoryTypeKeyword', value)}
                                options={catagoryTypes}
                                value={catagoryTypeKeyword}
                            />
                        </Col>
                        <Col md={7}>
                            <SelectCustom
                                label="ค้นหาด้วย"
                                name='searchTypeKeyword'
                                small
                                widthLabel='70px'
                                onChange={(value) => this.changeFilter('searchTypeKeyword', value)}
                                options={searchTypes.filter(item => item.type.includes(catagoryTypeKeyword))}
                                value={searchTypeKeyword}
                            />
                        </Col>
                        <Col md={9}>
                            <InputSearchCustom
                                small
                                name="productCode"
                                value={productCode}
                                onChange={this.handleChangeValue.bind(this)}
                                onClickSearch={() => this.handleClickSearchQualifier()}
                                onSubmit={() => this.handleClickSearchQualifier()}
                            />
                        </Col>
                        {/* <Col md={9}>
                            <InputHero
                                small
                                widthSmall="85px"
                                label="ชื่อ Identifier"
                                name="identifierName"
                                value={identifierName}
                                onChange={this.handleChangeValue}
                            />
                        </Col>*/}
                        {/* <Col md={9}>
                            <InputHero
                                small
                                widthSmall="85px"
                                label="รหัสสินค้า"
                                name="productCode"
                                value={productCode}
                                onChange={this.handleChangeValue}
                            />
                        </Col>  */}
                        {/* <Col md={6} style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div>
                                <ButtonCustom text="ล้าง" yellow small onClick={this.handlcClickClearValue} />
                            </div>
                            <div>
                                <ButtonCustom text="ค้นหา" small onClick={this.handleClickSearchQualifier} />
                            </div>
                        </Col> */}
                        <Col md={24}>
                            <TableCustom
                                small
                                rowKey={(row, index) => index}
                                columns={columnsQualifier}
                                // prop === "product"
                                data={(!catagoryTypeKeyword ? dataTableQualifierModal :
                                    dataTableQualifierModal.filter(product => product.qualifierTypeCode === catagoryTypeKeyword))}
                                // expandedRowRender={(record, index) => <span>{this.renderSubTable(index)}</span>}
                                expandedRowRender={(record, index) => <span>{this.renderSubTable(record)}</span>}
                                loading={loading}
                            // height="calc(100vh - 511px)"
                            />
                        </Col>
                    </Row>
                </ModalCustom>
            </div>
        )
    }
}

export default reduxForm({
    form: 'PromotionMaintainPatternForm',  // a unique identifier for this form
    enableReinitialize: true
})(PromotionMaintainPatternForm)
