import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, map } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import queryString from 'query-string'
import {
    formatNumber,
    getPermissionPage,
} from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    DatePickerCustom,
    SelectCustom,
    InputCustom,
} from 'components/common'
import { Row, Col, Spin } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { ROUTH_PATH, functionAlias } from 'routes'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import './style.css'

const formatDateAPI = 'YYYY-MM-DD'
const formatDateColumn = 'DD-MM-YYYY'
const widthLabel = '100px'

class ReportSalesLegacySapContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: 'เลขที่เอกสารใบวางบิล',
                    dataIndex: 'billingNo',
                    key: 'billingNo',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เลขที่ใบกำกับภาษี',
                    dataIndex: 'taxInvoiceNo',
                    key: 'taxInvoiceNo',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เลขที่เอกสารทางการบัญชี',
                    dataIndex: 'accountDoc',
                    key: 'accountDoc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ภาษีขาย',
                    dataIndex: 'taxType',
                    key: 'taxType',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'รหัสผู้รับสินค้า',
                    dataIndex: 'shipTo',
                    key: 'shipTo',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ชื่อผู้รับสินค้า',
                    dataIndex: 'shipToName',
                    key: 'shipToName',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ประเภทใบวางบิล',
                    dataIndex: 'billingDocumentTypeCode',
                    key: 'billingDocumentTypeCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'คำอธิบาย',
                    dataIndex: 'billingDocumentDesc',
                    key: 'billingDocumentDesc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'องค์กรทางการขาย',
                    dataIndex: 'outletId',
                    key: 'outletId',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ช่องทางการจัดจำหน่าย',
                    dataIndex: 'saleChannelCode',
                    key: 'saleChannelCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'วันที่วางบิล',
                    dataIndex: 'billingDocumentDate',
                    key: 'billingDocumentDate',
                    align: 'center',
                    render: (props) => props && <div className="text-left">{moment(props).format(formatDateColumn)}</div>
                },
                {
                    title: 'การกำหนด',
                    dataIndex: 'assignment',
                    key: 'assignment',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'กลุ่มลูกค้า',
                    dataIndex: 'customerGroup',
                    key: 'customerGroup',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'รหัสสมาชิก',
                    dataIndex: 'memberId',
                    key: 'memberId',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'Member ID ร้าน',
                    dataIndex: 'memberIdStore',
                    key: 'memberIdStore',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'รหัสผู้สั่งซื้อ',
                    dataIndex: 'soldTo',
                    key: 'soldTo',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ชื่อผู้สั่งซื้อ',
                    dataIndex: 'soldToName',
                    key: 'soldToName',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'รหัสผู้ชำระเงิน',
                    dataIndex: 'payerCode',
                    key: 'payerCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ชื่อผู้ชำระเงิน',
                    dataIndex: 'payerName',
                    key: 'payerName',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'รหัสพนักงานขาย',
                    dataIndex: 'saleEmpCode',
                    key: 'saleEmpCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ชื่อพนักงานขาย',
                    dataIndex: 'saleEmpName',
                    key: 'saleEmpName',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เงื่อนไขการชำระเงิน',
                    dataIndex: 'paymentTermCode',
                    key: 'paymentTermCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'หมายเหตุของเอกสาร',
                    dataIndex: 'remark',
                    key: 'remark',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'คำอธิบายเงื่อนไข',
                    dataIndex: 'paymentTermDesc',
                    key: 'paymentTermDesc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'รายการเอกสารใบวางบิล',
                    dataIndex: 'billingItem',
                    key: 'billingItem',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'รหัสสินค้า',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'คำอธิบายวัสดุแบบเต็ม',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'คำอธิบายวัสดุแบบสั้น',
                    dataIndex: 'productName1',
                    key: 'productName1',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'กลุ่มสินค้า',
                    dataIndex: 'itemGroup',
                    key: 'itemGroup',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'หมวดรายการ',
                    dataIndex: 'soDocumentTypeCode',
                    key: 'soDocumentTypeCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ปริมาณที่วางบิล',
                    dataIndex: 'billingQty',
                    key: 'billingQty',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'หน่วยนับ',
                    dataIndex: 'billingUnit',
                    key: 'billingUnit',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'จุดจัดส่ง/จุดรับสินค้า',
                    dataIndex: 'shippingPoint',
                    key: 'shippingPoint',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'คำอธิบายจุดจ่าย',
                    dataIndex: 'shippingPointDesc',
                    key: 'shippingPointDesc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'โรงงาน',
                    dataIndex: 'plan_Id',
                    key: 'plan_Id',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ที่เก็บสินค้า',
                    dataIndex: 'slocId',
                    key: 'slocId',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ราคาต่อหน่วย',
                    dataIndex: 'pricePerUnit',
                    key: 'pricePerUnit',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'จำนวนเงินใบวางบิลรวมภาษี',
                    dataIndex: 'sumAmountItem',
                    key: 'sumAmountItem',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'ส่วนลดกำหนดเอง',
                    dataIndex: 'discountManualItem',
                    key: 'discountManualItem',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'ส่วนลดอื่นๆ',
                    dataIndex: 'discountOtherItem',
                    key: 'discountOtherItem',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'ส่วนเพิ่ม (จน.)',
                    dataIndex: 'priceAdditionalItem',
                    key: 'priceAdditionalItem',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'ราคาสุทธิต่อหน่วย',
                    dataIndex: 'netAmountItem',
                    key: 'netAmountItem',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'จำนวนเงินรวมรายการ',
                    dataIndex: 'totalAmountItem',
                    key: 'totalAmountItem',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'จำนวนเงินใบวางบิลรวมภาษี',
                    dataIndex: 'balanceAmountItem',
                    key: 'balanceAmountItem',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'ส่วนลดรวมท้ายบิล (รายการ)',
                    dataIndex: 'totalDiscAmountItem',
                    key: 'totalDiscAmountItem',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'ส่วนลดรวมท้ายบิล',
                    dataIndex: 'totalDiscAmountHead',
                    key: 'totalDiscAmountHead',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'จำนวนเงินรวมรายการทั้งสิ้น',
                    dataIndex: 'sumAmountHead',
                    key: 'sumAmountHead',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'จำนวนเงินรายการไม่รวมภาษี',
                    dataIndex: 'beforeTaxAmountHead',
                    key: 'beforeTaxAmountHead',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'มูลค่าภาษีขาย',
                    dataIndex: 'vatAmount',
                    key: 'vatAmount',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'มูลค่าภาษีขายรวม',
                    dataIndex: 'sumVatAmount',
                    key: 'sumVatAmount',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'สกุลเงิน',
                    dataIndex: 'currency',
                    key: 'currency',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ยอดสุทธิใบวางบิลรวมภาษี',
                    dataIndex: 'totalAmountHead',
                    key: 'totalAmountHead',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'ยอดสุทธิใบวางบิลไม่รวมภาษี',
                    dataIndex: 'sumBeforeTaxAmountHead',
                    key: 'sumBeforeTaxAmountHead',
                    align: 'center',
                    render: (props) => <div className="text-right">{formatNumber(props, 2)}</div>
                },
                {
                    title: 'ยกเลิกใบวางบิล',
                    dataIndex: 'reject',
                    key: 'reject',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เอกสารที่ยกเลิก',
                    dataIndex: 'rejectDoc',
                    key: 'rejectDoc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ตัวบ่งชี้การใช้งาน',
                    dataIndex: 'usage',
                    key: 'usage',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/เงินสด',
                    dataIndex: 'paymentCash',
                    key: 'paymentCash',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/เงินโอนธนาคาร',
                    dataIndex: 'paymentBankTransfer',
                    key: 'paymentBankTransfer',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/บัตรเครดิต',
                    dataIndex: 'paymentCreditCard',
                    key: 'paymentCreditCard',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/คูปองแทนเงินสด',
                    dataIndex: 'paymentGiftVoucher',
                    key: 'paymentGiftVoucher',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/โปรโมชั่นจากผู้ผลิต',
                    dataIndex: 'paymentSupplierCoupon',
                    key: 'paymentSupplierCoupon',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/คูปองจากการรับคืน',
                    dataIndex: 'paymentCreditNoteVoucher',
                    key: 'paymentCreditNoteVoucher',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/SCG แฟมิลี่ แลกคะแนน',
                    dataIndex: 'paymentScgFamilyPoint',
                    key: 'paymentScgFamilyPoint',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/SCG แฟมิลี่ voucher',
                    dataIndex: 'paymentScgFamilyVoucher',
                    key: 'paymentScgFamilyVoucher',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/SCG ช้างเพื่อนช่าง แลกคะแนน',
                    dataIndex: 'paymentScgFamilyCPC',
                    key: 'paymentScgFamilyCPC',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/SCG แฟมิลี่ เงินสด',
                    dataIndex: 'paymentScgFamilyCashcard',
                    key: 'paymentScgFamilyCashcard',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ข้อมูลหัวเรื่อง',
                    dataIndex: 'subject',
                    key: 'subject',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'มูลค่าเดิม',
                    dataIndex: 'sumOldAmount',
                    key: 'sumOldAmount',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เลขที่ใบสั่งขาย',
                    dataIndex: 'soNumber',
                    key: 'soNumber',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'วันที่สร้างใบสั่งขาย',
                    dataIndex: 'soDocumentDate',
                    key: 'soDocumentDate',
                    align: 'center',
                    render: (props) => props && <div className="text-left">{moment(props).format(formatDateColumn)}</div>
                },
                {
                    title: 'เวลาที่สร้างใบสั่งขาย',
                    dataIndex: 'soCreatedDatetime',
                    key: 'soCreatedDatetime',
                    align: 'center',
                    // data: 'Dec 30 1899  5:55PM'
                    render: (props) => props && <div className="text-left">{moment(props, 'MMM DD YYYY H:mmA').format('HH:mm:ss')}</div>
                },
                {
                    title: 'รายการการขาย',
                    dataIndex: 'soItem',
                    key: 'soItem',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ประเภทใบสั่งขาย',
                    dataIndex: 'soDocumentTypeCode1',
                    key: 'soDocumentTypeCode1',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เลขที่ใบส่งสินค้า',
                    dataIndex: 'doNumber',
                    key: 'doNumber',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'วันที่สร้างใบส่งสินค้า',
                    dataIndex: 'doDocumentDate',
                    key: 'doDocumentDate',
                    align: 'center',
                    // data: Jun 23 2020 12:00AM
                    render: (props) => props && <div className="text-left">{moment(props, 'MMM DD YYYY H:mmA').format('HH:mm:ss')}</div>
                },
                {
                    title: 'เวลาที่สร้างใบส่งสินค้า',
                    dataIndex: 'doTime',
                    key: 'doTime',
                    align: 'center',
                    render: (props) => props && <div className="text-left">{moment(props).format('HH:mm:ss')}</div>
                },
                {
                    title: 'เอกสารวัสดุตัดจ่าย',
                    dataIndex: 'giNumber',
                    key: 'giNumber',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'วันที่ตัดจ่ายสินค้า',
                    dataIndex: 'giDocumentDate',
                    key: 'giDocumentDate',
                    align: 'center',
                    render: (props) => props && <div className="text-left">{moment(props).format(formatDateColumn)}</div>
                },
                {
                    title: 'รายการใบส่งสินค้า',
                    dataIndex: 'doItem',
                    key: 'doItem',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'สร้างโดย',
                    dataIndex: 'giCreatedBy',
                    key: 'giCreatedBy',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'สร้างเมื่อ',
                    dataIndex: 'doCreatedDatetime',
                    key: 'doCreatedDatetime',
                    align: 'center',
                    render: (props) => props && <div className="text-left">{moment(props).format(formatDateColumn)}</div>
                },
                {
                    title: 'เลขที่ในใบวางบิลจาก ZFIF003',
                    dataIndex: 'billingNoZFIF003',
                    key: 'billingNoZFIF003',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'วันที่ในใบวางบิลจาก ZFIF003',
                    dataIndex: 'billingDateZFIF003',
                    key: 'billingDateZFIF003',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ยี่ห้อ',
                    dataIndex: 'brand',
                    key: 'brand',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'คำอธิบายยี่ห้อ',
                    dataIndex: 'brandDesc',
                    key: 'brandDesc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'Category',
                    dataIndex: 'categoryCode',
                    key: 'categoryCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'คำอธิบาย Category',
                    dataIndex: 'categoryDesc',
                    key: 'categoryDesc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'Sub Category',
                    dataIndex: 'subcategoryCode',
                    key: 'subcategoryCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'คำอธิบาย Sub Category',
                    dataIndex: 'subcategoryDesc',
                    key: 'subcategoryDesc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'Class',
                    dataIndex: 'classCode',
                    key: 'classCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'คำอธิบาย Class',
                    dataIndex: 'classDesc',
                    key: 'classDesc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'Sub Class',
                    dataIndex: 'subclassCode',
                    key: 'subclassCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'คำอธิบาย Sub Class',
                    dataIndex: 'subclassDesc',
                    key: 'subclassDesc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'Solution',
                    dataIndex: 'solution',
                    key: 'solution',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เอกสารใบวางบิลตั้งต้น',
                    dataIndex: 'initialInvoiceDoc',
                    key: 'initialInvoiceDoc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เอกสารทางการบัญชีตั้งต้น',
                    dataIndex: 'initialAccountingDoc',
                    key: 'initialAccountingDoc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เหตุผลของการสั่ง',
                    dataIndex: 'reason',
                    key: 'reason',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'สถานะการรับชำระเงิน',
                    dataIndex: 'paymentStatus',
                    key: 'paymentStatus',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เลขที่คูปอง',
                    dataIndex: 'paymentGiftVoucherNo',
                    key: 'paymentGiftVoucherNo',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ชื่อคูปอง',
                    dataIndex: 'paymentGiftVoucherDesc',
                    key: 'paymentGiftVoucherDesc',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'SCG Family ID',
                    dataIndex: 'scgId',
                    key: 'scgId',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เลขที่ SCG แฟมิลี่ แลกคะแนน',
                    dataIndex: 'paymentScgFamilyPointNo',
                    key: 'paymentScgFamilyPointNo',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เลขที่ SCG แฟมิลี่ voucher',
                    dataIndex: 'paymentScgFamilyVoucherNo',
                    key: 'paymentScgFamilyVoucherNo',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เลขที่ SCG ช้างเพื่อนช่าง แลกคะแนน',
                    dataIndex: 'paymentScgFamilyCPCNo',
                    key: 'paymentScgFamilyCPCNo',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เลขที่ SCG แฟมิลี่ เงินสด',
                    dataIndex: 'paymentScgFamilyCashcardNo',
                    key: 'paymentScgFamilyCashcardNo',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ผู้ใช้งาน Web',
                    dataIndex: 'createdBy',
                    key: 'createdBy',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'รูปแบการขาย',
                    dataIndex: 'orderChannel',
                    key: 'orderChannel',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เลขที่เอกสาร(รูปแบบการขาย)',
                    dataIndex: 'referenceQuotation',
                    key: 'referenceQuotation',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'Job ID HomeSolution',
                    dataIndex: 'jobId',
                    key: 'jobId',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'ที่อยู่ผู้รับสินค้า',
                    dataIndex: 'shipToAddress',
                    key: 'shipToAddress',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'โรงงานที่ส่งมอบ',
                    dataIndex: 'planid',
                    key: 'planid',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'Matcode',
                    dataIndex: 'matCode',
                    key: 'matCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'Promotion Code',
                    dataIndex: 'promotionCode',
                    key: 'promotionCode',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/คูปอง E-Commerce',
                    dataIndex: 'paymentEcommcerCoupon',
                    key: 'paymentEcommcerCoupon',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'เลขที่ คูปอง E-Commerce',
                    dataIndex: 'paymentEcommcerCouponNo',
                    key: 'paymentEcommcerCouponNo',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/เงินโอน E-Commerce',
                    dataIndex: 'paymentEcommerce',
                    key: 'paymentEcommerce',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/ค่าธรรมเนียมเงินโอน E-Commerce',
                    dataIndex: 'paymentEcommerceFee',
                    key: 'paymentEcommerceFee',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                },
                {
                    title: 'บัตรชำระเงิน/ภาษีถูกหัก ณ ที่จ่าย',
                    dataIndex: 'paymentWitholdingTax',
                    key: 'paymentWitholdingTax',
                    align: 'center',
                    render: (props) => <div className="text-left">{props}</div>
                }
            ],
            loading: false,
            documents: [],
            outlets: [],
            filter: {
                documentDateFrom: moment(),
                documentDateTo: moment(),
                outletIds: [],
                customerCode: '',
                customerName: '',
                saleEmployeeCode: '',
                saleEmployeeName: '',
                productCode: '',
            },
            permissionPage: getPermissionPage(functionAlias.reportSalesLegacySap),
        }
    }

    componentDidMount() {
        if (!this.state.permissionPage.authDisplay) {
            swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
                this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
            })
            return
        }
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })

        const { auth } = this.props

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({ outlets })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (!filter.documentDateTo) return { isError: true, message: 'กรุณาระบุวันที่เอกสาร' }
        if (!filter.documentDateFrom) return { isError: true, message: 'กรุณาระบุถึงวันที่เอกสาร' }
        if ((filter.documentDateTo).isBefore(filter.documentDateFrom)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }
        return { isError: false }
    }

    getBody = (filter) => {
        const { outlets } = this.state
        return {
            billingdateFrom: filter.documentDateFrom && filter.documentDateFrom.format(formatDateAPI),
            billingdateTo: filter.documentDateTo && filter.documentDateTo.format(formatDateAPI),
            outletIds: isEmpty(filter.outletIds) ? map(outlets, 'outletId') : filter.outletIds,
            customerCode: filter.customerCode,
            customerName: filter.customerName,
            saleEmployeeCode: filter.saleEmployeeCode,
            saleEmployeeName: filter.saleEmployeeName,
            productCode: filter.productCode,
        }
    }

    searchDocument = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        const url = `${API_PATH.GET_REPORT_SALES_LEGACY_SAP}?${queryString.stringify(body)}`
        await apiServices.callApi('get', url).then(res => {
            let documents = get(res, 'data.results', [])
            if (res.status === 200) {
                this.setState({ documents })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    exportDocument = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        const body = this.getBody(filter)
        const url = `${API_PATH.GET_EXPORT_SALES_LEGACY_SAP}?${queryString.stringify(body)}`
        window.open(url)
    }

    render() {
        const {
            columns,
            outlets,
            loading,
            filter,
            documents,
            permissionPage,
        } = this.state

        return (
            <div className="report-sales-legacy-sap-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="รายงานใบแจ้งหนี้/ลดหนี้/เพิ่มหนี้ ม.ค. - มิ.ย. 2563"
                        icon="file-sync"
                    />
                    <div className="middle-content">
                        <Row gutter={15}>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="วันที่เอกสาร"
                                    widthLabel={widthLabel}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('documentDateFrom', val)}
                                    value={filter.documentDateFrom}
                                />
                            </Col>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="ถึงวันที่เอกสาร"
                                    widthLabel={widthLabel}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('documentDateTo', val)}
                                    value={filter.documentDateTo}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={7}>
                                <SelectCustom
                                    label="ร้านค้า"
                                    widthLabel={widthLabel}
                                    options={outlets}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    small
                                    onChange={(val) => this.changeFilter('outletIds', val)}
                                    value={filter.outletIds}
                                    placeholder='ทั้งหมด'
                                />
                            </Col>
                            <Col sm={7}>
                                <InputCustom
                                    label="รหัสสินค้า"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('productCode', e.target.value)}
                                    value={filter.productCode}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={7}>
                                <InputCustom
                                    label="รหัสผู้สั่งซื้อ"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('customerCode', e.target.value)}
                                    value={filter.customerCode}
                                />
                            </Col>
                            <Col sm={7}>
                                <InputCustom
                                    label="ชื่อผู้สั่งซื้อ"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('customerName', e.target.value)}
                                    value={filter.customerName}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={7}>
                                <InputCustom
                                    label="รหัสพนักงานขาย"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('saleEmployeeCode', e.target.value)}
                                    value={filter.saleEmployeeCode}
                                />
                            </Col>
                            <Col sm={7}>
                                <InputCustom
                                    label="ชื่อพนักงานขาย"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('saleEmployeeName', e.target.value)}
                                    value={filter.saleEmployeeName}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} className="search-box text-right">
                                <div className="action-box">
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.searchDocument}
                                            small
                                            icon="search"
                                            text="ค้นหา"
                                            disabled={!permissionPage.authCreate}
                                        />
                                    </div>
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.exportDocument}
                                            small
                                            icon="file-excel"
                                            green
                                            text="Export Excel"
                                            disabled={isEmpty(documents) || !permissionPage.authExport}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                data={documents}
                                isFullContent
                                rowKey={(row, index) => index}
                                small
                                pagination={{ pageSize: 30 }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportSalesLegacySapContainer)