// ref database (user_function_master)
export const functionAlias = {
    customerMaster: 'CUST_MA',
    productList: 'PRDC_LIST',
    productLabeling: 'PRDC_LABEL',
    inventoryMovement: 'INVNT_MOV',
    inventoryGI: 'INVNT_GI',
    inventoryReceive: 'INVNT_RECPT',
    inventoryReturn: 'INVNT_RETURN',
    inventoryCountList: 'INVNT_CNT_LIST',
    inventoryCountMaintain: 'INVNT_CNT_MA',
    inventoryCountStock: 'INVNT_CNT_STCK',
    inventoryCountAdmin: 'INVNT_CNT_ADM',
    purchaseRequisitionList: 'PR_LIST',
    purchaseRequisitionMaintain: 'PR_MA',
    quotationList: 'QUOT_LIST',
    quotationMaintain: 'QUOT_MA',
    saleOrderMaintain: 'SO_MA',
    paymentMaintain: 'CSHRECV_MA',
    creditNoteMaintain: 'CNDN_MA',
    promotionMaintain: 'PROMO_MA',
    shipmentMaintain: 'TRANSPT_MA',
    reportQuotation: 'RPT_QUOT',
    reportSaleOrder: 'RPT_SO',
    reportCreditNote: 'RPT_CNDN',
    reportInventoryMovement: 'RPT_INVNT_MOV',
    reportPurchaseRequisition: 'RPT_PR',
    reportInventoryCountStock: 'RPT_INVNT_CNT_STCK',
    reportCostGP: 'RPT_COSTGP',
    reportSpecialTax: 'RPT_SPECIAL_TAX',
    userMaintain: 'USR_MA',
    roleMaintain: 'ROLE_MA',
    promotionQualifierMaintain: 'PROMO_QULFY_MA',
    paymentFullMaintain: 'PAYM_MA',
    employeeMaintain: 'EMPL_MA',
    clearBilling: 'CLEAR_BILL',
    createPurchaseOrder: 'CREA_POSO',
    inventoryGISTO: 'INVNT_GI_STO',
    inventoryGIRefReservation: 'INVNT_GI_REF_RESERV',
    inventoryReceiveWithoutRef: 'INVNT_RECPT_WHOT',
    inventoryReservation: 'INVNT_RESERVAT',
    reportInventoryBalance: 'RPT_INVNT_BAL',
    reportSpecialStock: 'RPT_SPEC_STCK',
    reportCheckStockPrice: 'RPT_CHK_STCK_PR',
    reportCheckPOS: 'RPT_CHK_POS',
    reportPercentMargin: 'RPT_PC_MG',
    manualCreditNoteInterface: 'CNDN_MANUAL',
    reportS4InterfaceLog: 'RPT_S4_INF',
    reportPOSInterfaceLog: 'RPT_POS_INF',
    reportSalesLegacySap: 'RPT_SALES_LEGC_SAP',
    reportResivestockCompareChamp: 'RPT_GR_PO_CHAMP',
    manualInterfaceIncomingPayment:'INF_INCOME_PAY'
}