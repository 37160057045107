import { SEARCH_CRITERIA_SO_REPORT } from 'actions/actionsTypes'

const SearchCriteriaSOReportReducer = (state = false, action) => {
    switch (action.type) {
        case SEARCH_CRITERIA_SO_REPORT:
            return action.searchCriteriaSOReportData;
        default:
            return state;
    }
}

export default SearchCriteriaSOReportReducer