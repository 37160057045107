import axios from 'axios';
import { store } from 'reducers/MainReducer';
import { authData } from 'actions/actionsCreator';
import swal from '@sweetalert/with-react';

const userUrl = window.REACT_APP_USER_API_URL;
const commonUrl = window.REACT_APP_COMMON_API_URL;
const customerUrl = window.REACT_APP_CUSTOMER_API_URL;
const saleEmployeeUrl = window.REACT_APP_SALE_EMPLOYEE_API_URL;
const productUrl = window.REACT_APP_PRODUCT_API_URL;
const saleOrderUrl = window.REACT_APP_SALEORDER_API_URL;
const promotionUrl = window.REACT_APP_PROMOTION_API_URL;
const inventoryCountUrl = window.REACT_APP_INVENTORYCOUNT_API_URL;
const quotationUrl = window.REACT_APP_QUOTATION_API_URL;
const cashReceiveUrl = window.REACT_APP_CASHRECEIVE_API_URL;
const purchasingUrl = window.REACT_APP_PURCHASING_API_URL;
const shipmentUrl = window.REACT_APP_SHIPMENTS_API_URL;
const backendOperationUrl = window.REACT_APP_BACKENDOPERATION_API_URL;
const reportUrl = window.REACT_APP_REPORT_API_URL;
const reportExcelUrl = window.REACT_APP_REPORT_EXCEL_API_URL;
const reportFormUrl = window.REACT_APP_REPORT_FORM_API_URL;
const creditNoteUrl = window.REACT_APP_CREDIT_NOTE_API_URL;
const inboundPOSUrl = window.REACT_APP_INBOUND_POS_INTERFACE_API_URL;
const outboundProductUrl = window.REACT_APP_OUTBOUND_PRODUCT_API_URL;
const outboundMarginUrl = window.REACT_APP_OUTBOUND_MARGIN_API_URL;
// format: ${method}_${serviceName}_${action}
export const API_PATH = {
    // user
    GET_USER: `${userUrl}/GetUser`,
    GET_USER_IS_ALIVE: `${userUrl}/IsAlive`,
    POST_USER_REFRESH: `${userUrl}/Refresh`,
    POST_CREATE_USER: `${userUrl}/CreateUser`,
    UPDATE_USER: `${userUrl}/UpdateUser`,
    CHANGE_PASSWORD: `${userUrl}/AdminResetPassword`,
    CHANGE_PASSWORD_SEFT: `${userUrl}/ChangePassword`,
    GET_USER_ROLE_MASTER_VIEW: `${userUrl}/GetUserRoleMasterView`,
    GET_USER_ROLE_MASTER_DETAIL: `${userUrl}/GetUserRoleMasterDetail`,
    POST_USER_ROLE_MASTER: `${userUrl}/CreateUserRoleMaster`,
    PUT_UPDATE_USER_ROLE_MASTER: `${userUrl}/UpdateUserRoleMaster`,
    PUT_UPDATE_USER_ROLE_MASTER_WITH_INCLUDE: `${userUrl}/UpdateUserRoleMasterWithInclude`,
    POST_GET_USER_FUNCTION_MASTER: `${userUrl}/GetUserFunctionMaster`,
    GET_USER_MODULE_MASTER: `${userUrl}/GetUserModuleMaster`,

    // common
    GET_MASTER_OUTLET: `${commonUrl}/GetMasterOutlet`,
    GET_MASTER_TAX_TYPE: `${commonUrl}/GetMasterTaxType`,
    GET_MASTER_ROLE: `${commonUrl}/GetUserRoleMaster`,
    GET_MASTER_WAREHOUSE_BY_OUTLET_ID: `${commonUrl}/GetMasterWarehouseByOutletIds`,
    GET_MASTER_SALE_CHANNEL: `${commonUrl}/GetMasterSaleChannel`,
    GET_MASTER_DOCUMENT_TYPE: `${commonUrl}/GetMasterDocumentTypes`,
    GET_MASTER_PAYMENT_TERM: `${commonUrl}/GetMasterPaymentTerm`,
    GET_MASTER_SALE_SHIPPING_POINT: `${commonUrl}/GetMasterSalesShippingPoint`,
    GET_MASTER_CUSTOMER_TITLE: `${commonUrl}/GetMasterCustomerTitle`,
    GET_MASTER_REGION_COUNTRY: `${commonUrl}/GetMasterRegionCountry`,
    GET_MASTER_PROVINCE: `${commonUrl}/GetProvinces`,
    GET_MASTER_DISTRICTS: `${commonUrl}/GetDistricts`,
    GET_MASTER_SUB_DISTRICTS: `${commonUrl}/GetSubDistricts`,
    GET_MASTER_CUSTOMER_GROUP: `${commonUrl}/GetMasterCustomerGroup`,
    GET_MASTER_CUSTOMER_HOUSE_TYPE: `${commonUrl}/GetMasterCustomerHouseType`,
    GET_MASTER_INVENTORY_STOCK_TYPE: `${commonUrl}/GetMasterInventoryStockType`,
    GET_MASTER_SALES_BOOK_BANK: `${commonUrl}/GetMasterSalesBookbank`,
    GET_MASTER_QUOTATION_SOLUTION: `${commonUrl}/GetMasterQuotationSolutions`,
    GET_MASTER_SALE_ORDER_STATUS: `${commonUrl}/GetMasterSaleDocStatus`,
    GET_MASTER_INVENTORY_COUNT_STATUS: `${commonUrl}/GetMasterInventoryCountStatus`,
    GET_MASTER_INVENTORY_DIFF_TYPE: `${commonUrl}/GetMasterInventoryDiffType`,
    GET_MASTER_PRODUCT_CATEGORY: `${commonUrl}/GetMasterProductCategory`,
    GET_MASTER_PRODUCT_SUB_CATEGORY: `${commonUrl}/GetMasterProductSubCategory`,
    GET_MASTER_PRODUCT_CLASS: `${commonUrl}/GetMasterProductClass`,
    GET_MASTER_PRODUCT_SUB_CLASS: `${commonUrl}/GetMasterProductSubClass`,
    GET_MASTER_PRODUCT_BRAND: `${commonUrl}/GetMasterProductBrand`,
    GET_MASTER_SALE_REJECT: `${commonUrl}/GetMasterSaleReject`,
    GET_MASTER_TRANSACTION_TYPE: `${commonUrl}/GetMasterTransactionType`,
    GET_MASTER_PRODUCT_TYPE: `${commonUrl}/GetMasterProductType`,
    GET_MASTER_SPECIAL_IND: `${commonUrl}/GetMasterSpecialInd`,
    GET_MASTER_COST_CENTER: `${commonUrl}/GetMasterCostCenter`,
    GET_COMMON_DEFAULT_REMARK: `${commonUrl}/GetDefaultRemark`,
    GET_PROMOTION_MASTER_SUPPORT_BY: `${commonUrl}/GetPromotionMasterSupportType`,
    GET_PROMOTION_STATUS: `${commonUrl}/GetPromotionStatus`,
    GET_MASTER_SPECIAL_CONDITION: `${commonUrl}/GetMasterSpecialCondition`,
    GET_MASTER_PURCHASING_GROUP: `${commonUrl}/GetMasterPurchasingGroup`,
    GET_MASTER_INVENTORY_REASON: `${commonUrl}/GetMasterInventoryReason`,
    GET_MASTER_SALES_SHIPMENT_TRUCK: `${commonUrl}/GetMasterSalesShipmentTruck`,
    GET_MASTER_SALES_SHIPMENT_ZONE: `${commonUrl}/GetMasterSalesShipmentZone`,
    GET_MASTER_SALES_SHIPMENT_DRIVER: `${commonUrl}/GetMasterSalesShipmentDriver`,
    GET_MASTER_SALES_PAYMENT: `${commonUrl}/GetMasterSalesPayment`,
    GET_MASTER_SALES_WITHOLDING_TAX: `${commonUrl}/GetMasterSalesWitholdingTax`,
    GET_VENDOR: `${commonUrl}/GetVender`,
    GET_MASTER_PO_DOC_TYPE: `${commonUrl}/GetMasterPoDocType`,
    GET_MASTER_SALES_RETURN_TYPE: `${commonUrl}/GetMasterSalesReturnType`,
    GET_MASTER_SALE_CN_REASON: `${commonUrl}/GetMasterSalesCnReason`,

    // customer
    GET_CUSTOMER: `${customerUrl}/GetCustomer`,
    POST_CUSTOMER: `${customerUrl}/CreateCustomer`,
    PUT_CUSTOMER: `${customerUrl}/UpdateCustomer`,

    // sale employee
    GET_SALE_EMPLOYEE: `${saleEmployeeUrl}/GetSaleEmployees`,
    POST_SALE_EMPLOYEE: `${saleEmployeeUrl}/CreateSaleEmployee`,
    PUT_SALE_EMPLOYEE: `${saleEmployeeUrl}/UpdateSaleEmployee`,

    // product
    POST_GET_PRODUCT: `${productUrl}/GetProduct`,
    POST_GET_PRODUCTS: `${productUrl}/GetProducts`,
    POST_GET_PRODUCT_UNIT: `${productUrl}/GetProductUnit`,
    POST_GET_PROFIT_SHARINGS: `${productUrl}/GetMasterProfitSharings`,
    GET_EXPORT_MASTER_PROFIT_SHARING_EXCEL: `${productUrl}/ExportMasterProfitSharingExcel`,
    POST_CREATE_MASTER_PROFIT_SHARING: `${productUrl}/CreateMasterProfitSharing`,
    POST_GET_SALES_ORDER_WERERESERVED_BY_PRODUCT: `${productUrl}/GetSalesOrdersWereReservedByProduct`,
    POST_PRODUCT_ALL_UNIT: `${productUrl}/GetProductAllUnit`,

    // sale order
    POST_SALE_ORDER: `${saleOrderUrl}/CreateSaleOrder`,
    PUT_SALE_ORDER: `${saleOrderUrl}/UpdateSaleOrder`,
    // PUT_SALE_ORDER: `http://localhost:62654/api-sale/UpdateSaleOrder`,
    POST_GET_DETAIL_SALE_ORDER: `${saleOrderUrl}/GetSaleOrderDetail`,
    // POST_GET_DETAIL_SALE_ORDER: `http://localhost:62654/api-sale/GetSaleOrderDetail`,
    POST_GET_SALES_SO_HEADS: `${saleOrderUrl}/GetSalesSOHeads`,
    POST_GET_SALES_SO_ITEMS: `${saleOrderUrl}/GetSalesSOItems`,
    GET_EXPORT_EXCEL: `${reportExcelUrl}/ExportSaleOrder`,
    POST_GET_SALES_DOCUMENT_FLOWS: `${saleOrderUrl}/GetSalesDocumentFlows`,
    POST_DELETE_SALES_DOC_FLOWS: `${saleOrderUrl}/DeleteSalesDocFlows`,
    POST_SALE_ORDER_REPROCESS: `${saleOrderUrl}/Reprocess`,
    POST_SALE_ORDER_CONFIRM_SPECIAL_ORDER: `${saleOrderUrl}/ConfirmSpecialOrder`,
    PUT_APPROVE_SALES_ORDERS: `${saleOrderUrl}/ApproveSalesOrders`,
    POST_SALE_ORDER_UPDATE_COUPON_STATUS:`${saleOrderUrl}/UpdateCouponStatus`,
    // promotion
    POST_SALE_ORDER_AMOUNT: `${promotionUrl}/SalesOrderAmount`,
    POST_PROMOTION_TOTAL_AMOUNT: `${promotionUrl}/PromotionTotalAmount`,
    POST_MANUAL_PROMOTION_DISCOUNT_ITEM: `${promotionUrl}/ManualPromotionDiscountItem`,
    GET_PROMOTION: `${promotionUrl}/GetPromotions`,
    PUT_PROMOTION: `${promotionUrl}/UpdatePromotions`,
    POST_PROMOTION: `${promotionUrl}/CreatePromotions`,
    GET_DOWNLOAD_TEMPLATE_EXCEL_VOUCHER: `${promotionUrl}/DownloadTemplateExcelVoucher`,
    GET_DOWNLOAD_TEMPLATE_EXCEL_NET_PRICE: `${promotionUrl}/DownloadTemplateExcelNetPrice`,
    POST_VALIDATE_PROMOTION_NET_PRICE: `${promotionUrl}/ValidatedPromotionNetPrice`,
    GET_QUALIFIER: `${promotionUrl}/GetQualifiers`,
    PUT_QUALIFIER: `${promotionUrl}/UpdatteQualifier`,
    POST_QUALIFIER: `${promotionUrl}/CreateQualifier`,
    GET_QUALIFIER_DOWNLOAD_TEMP_EXCEL: `${promotionUrl}/DownloadTemplateExcel`,
    GET_QUALIFIER_DOWNLOAD_EXCEL_QUALIFIRE: `${promotionUrl}/DownloadTemplateExcelQualifier`,
    // GET_QUALIFIER_DOWNLOAD_TEMP_EXCEL: `${promotionUrl}/DownloadTemplateExcel`,
    // GET_QUALIFIER_DOWNLOAD_TEMP_EXCEL_CATEGORY: `${promotionUrl}/DownloadTemplateExcelCategory`,
    POST_QUALIFIER_IMPORT_EXCEL: `${promotionUrl}/ImportExcel`,
    POST_CHECK_PROMOTION_AVAILABLE : `${promotionUrl}/CheckPromotionAvailable`,

    // inventory count
    GET_INVENTORY_COUNT_GET_PRODUCT: `${inventoryCountUrl}/GetProduct`,
    GET_INVENTORY_COUNT_GET_SLOC: `${inventoryCountUrl}/GetSloc`,
    GET_INVENTORY_COUNT_DOWNLOAD_TEMP_EXCEL: `${inventoryCountUrl}/DownloadTemplateExcel`,
    GET_INVENTORY_COUNT_GET_DETAIL: `${inventoryCountUrl}/GetInventoryCountDetail`,
    POST_INVENTORY_COUNT_VALIDATE: `${inventoryCountUrl}/Validate`,
    POST_INVENTORY_COUNT_CREATE_INVENTORY_COUNT: `${inventoryCountUrl}/Create`,
    POST_INVENTORY_COUNT_IMPORT_EXCEL: `${inventoryCountUrl}/ImportExcel`,
    PUT_INVENTORY_COUNT_UPDATE_INVENTORY_COUNT: `${inventoryCountUrl}/Update`,
    POST_INVENTORY_COUNT_GET_INVENTORY_COUNT_ITEM: `${inventoryCountUrl}/GetInventoryCountItem`,
    POST_INVENTORY_COUNT_EXPORT_EXCEL: `${inventoryCountUrl}/ExportExcel`,
    GET_INVENTORY_COUNT_DOWNLOAD_EXPORT_EXCEL: `${inventoryCountUrl}/DownloadExportExcel`,
    POST_INVENTORY_COUNT_RE_COUNT: `${inventoryCountUrl}/ReCount`,
    POST_INVENTORY_COUNT_RESULT_SEARCH: `${inventoryCountUrl}/ResultSearch`,
    POST_INVENTORY_COUNT_RESULT_SAVE: `${inventoryCountUrl}/ResultSave`,
    POST_INVENTORY_COUNT_EXPORT_ADMIN_EXCEL: `${inventoryCountUrl}/ExportAdminExcel`,
    GET_INVENTORY_COUNT_DOWNLOAD_EXPORT_ADMIN_EXCEL: `${inventoryCountUrl}/DownloadExportAdminExcel`,
    POST_INVENTORY_COUNT_ADMIN_SEARCH: `${inventoryCountUrl}/AdminSearch`,
    POST_INVENTORY_COUNT_CONFIRM_POSITIVE_STOCK: `${inventoryCountUrl}/ConfirmPositiveStock`,
    POST_INVENTORY_COUNT_CONFIRM_NEGATIVE_STOCK: `${inventoryCountUrl}/ConfirmNegativeStock`,
    POST_INVENTORY_COUNT_ADJUSTED: `${inventoryCountUrl}/Adjusted`,
    POST_INVENTORY_COUNT_CANCEL_CONFIRM: `${inventoryCountUrl}/CancelConfirm`,
    POST_INVENTORY_COUNT_EXPORT_PRINT: `${reportFormUrl}/GetReportInventoryCount`,
    POST_INVENTORY_ADJUST_EXPORT_PRINT: `${reportFormUrl}/GetReportInventoryAdjust`,
 
    // quotation
    GET_QUOTATION: `${quotationUrl}/GetQuotation`,
    GET_JOB_BOQ: `${quotationUrl}/GetJobIdFormBOQ`,
    POST_QUOTATION_LIST: `${quotationUrl}/ListOfQuot`,
    POST_QUOTATION_CREATE_QUOTATION: `${quotationUrl}/CreateQuotation`,
    PUT_QUOTATION_UPDATE_QUOTATION: `${quotationUrl}/EditQuotation`,
    GET_VALIDATE_DISCOUNT_AUTH:`${quotationUrl}/ValidateApproveSalesDiscountAuth`,
    GET_REPORT_PRINT_QUOTATION:`${reportFormUrl}/GetReportSalesQuotation`,
    
    // cash receive
    GET_CASHRECEIVE: `${cashReceiveUrl}/GetCashReceives`,
    POST_CREATE_CASHRECEIVE: `${cashReceiveUrl}/CreateCashReceive`,
    POST_CREATE_CASHRECEIVE_FULLTAX: `${cashReceiveUrl}/CreateFullTaxCashReceive`,
    GET_VALIDATE_CN_VOUCHER: `${cashReceiveUrl}/ValidateCreditNoteVoucher`,
    GET_CALCULATE_DEPOSIT_AMOUNTS: `${cashReceiveUrl}/CalculateDepositAmounts`,
    GET_SALES_INSTALLMENT_LOG: `${cashReceiveUrl}/GetSalesInstallmentLog`,
    GET_SALES_INSTALLMENT_AMOUNT: `${cashReceiveUrl}/GetSalesInstallmentAmount`,
    GET_VALIDATE_GIFT_VOUCHER: `${cashReceiveUrl}/ValidateGiftVoucher`,
    POST_MANUALRUN_INTERFACE_INCOMING_PAYMENT: `${cashReceiveUrl}/ManualRunInterfaceIncomingPayment`,

    // purchasing
    GET_PODETAIL_SAP: `${purchasingUrl}/POGetDetailSAP`,
    POST_CLEAR_BILLING: `${purchasingUrl}/ClearBilling`,
    POST_CREATE_PO_FRANCHISE_AND_SO_DIST: `${purchasingUrl}/CreatePOFranchiseandSODist`,

    // shipment
    GET_SALES_DO_ITEM: `${shipmentUrl}/GetSalesDoItem`,
    POST_UPDATE_DO_FOR_GI: `${shipmentUrl}/UpdateDOForGI`,
    POST_GET_SALES_SHIPMENT: `${shipmentUrl}/GetSalesShipment`,
    POST_CREATE_SALES_SHIPMENT: `${shipmentUrl}/CreateSalesShipment`,
    PUT_UPDATE_SALES_SHIPMENT: `${shipmentUrl}/UpdateSalesShipment`,
    DELETE_SALES_SHIPMENT: `${shipmentUrl}/DeleteSalesShipment`,
    POST_REPROCESS: `${shipmentUrl}/Reprocess`,
    POST_GET_SALE_DO_HEAD : `${shipmentUrl}/GetSalesDoHead`,

    // backend operation
    GET_PO_AND_STO_DETAIL: `${backendOperationUrl}/GetPOandSTODetail`,
    POST_GOODS_MOVEMENT: `${backendOperationUrl}/PostGoodsMovement`,
    POST_GET_DAILY_MATERIAL_MOVEMENT: `${backendOperationUrl}/GetDailyMaterialMovementList`,
   
    POST_DAILY_MATERIAL_MOVEMENT: `${backendOperationUrl}/DailyMaterialMovementList`,
    POST_GI_STO_DELIVERY: `${backendOperationUrl}/GISTODelivery`,
    POST_GET_INVENTORY_REQUEST_ITEM: `${backendOperationUrl}/GetInventoryRequestItem`,
    POST_UPDATE_INVENTORY_REQUEST: `${backendOperationUrl}/UpdateInventoryRequest`,
    GET_DAILY_STOCK_BALANCE_LIST: `${backendOperationUrl}/GetDailyStockBalanceList`,
    POST_PROCESS_MASTERIAL_RESERVATION: `${backendOperationUrl}/ProcessMaterialReservation`,
    POST_RESERVATION_AND_INVENTORY_REQUEST: `${backendOperationUrl}/ProcessMaterialReservationAndInventory`,
    POST_GET_INVENTORY_REQUEST_HEAD: `${backendOperationUrl}/GetInventoryRequestHead`,
    POST_GET_PRODUCT_ATP: `${backendOperationUrl}/GetProducATP`,
    POST_GOODS_MOVEMENT_UPDATE_NON_STOCK: `${backendOperationUrl}/PostGoodsMovementUpdateNonStock`,
    

    // report
    POST_ORDERTRACKING_SEARCH: `${reportUrl}/OrderTrackingSearch`,
    POST_DMS_SEARCH: `${reportUrl}/DMSSearch`,
    POST_UPDATE_UPLOAD_STATUS_DMS: `${reportUrl}/UpdateDMSUploadStatus`,
    POST_ORDER_TRACKING_SPECIAL_SEARCH: `${reportUrl}/OrderTrackingSpecialSearch`,
    POST_SALE_ORDER_TRACKING_POS_SEARCH: `${reportUrl}/SaleOrderTrackingPosSearch`,
    POST_GET_DAILY_STOCK_BALANCE_LIST:`${reportUrl}/DailyStockBalanceList`,
    POST_GET_STOCK_PRICE: `${reportUrl}/GetStockPrice`,
    POST_GET_COST_GR_REPORT: `${reportUrl}/GetCostGRReport`,
    POST_GET_CN_DN_TRACKING_REPORT: `${reportUrl}/GetCNDNTrackingReport`,
    POST_GET_POS_INTERFACE_LOG: `${reportUrl}/GetPosInterfaceLog`,
    POST_GET_INTERFACE_S4_LOG_REPORT: `${reportUrl}/GetInterfaceS4LogReport`,
    POST_GET_PRODUCT_FOR_CREATE_LABELING: `${reportUrl}/GetProductForCreateLabeling`,
    GET_REPORT_SALES_LEGACY_SAP: `${reportUrl}/GetSalesLegacySap`,
    POST_GET_RECEIVE_FROM_PURCHASING_OF_CHAMP: `${reportUrl}/GetReceiveFromPurchasingOfChamp`,
    POST_GET_REPORT_QUOTATION_LIST: `${reportUrl}/ListOfQuot`,

    // report excel
    POST_EXPORT_DMS: `${reportExcelUrl}/ExportDMS`,
    POST_EXPORT_QUOTATIONS: `${reportExcelUrl}/exportQuotations`,
    POST_EXPORT_ORDER_TRACKING_SPECIAL: `${reportExcelUrl}/ExportOrderTrackingSpecial`,
    POST_STOCK_MOVEMENT_EXPORT_EXCEL: `${reportExcelUrl}/StockMovementExportExcel`,
    POST_EXPORT_SALE_ORDER_TRACKING_POS: `${reportExcelUrl}/ExportSaleOrderTrackingPos`,
    GET_EXPORT_CUSTOMERS: `${reportExcelUrl}/ExportCustomers`,
    POST_EXPORT_CN_DN_TRACKING_REPORT: `${reportExcelUrl}/ExportCNDNTrackingReport`,
    POST_EXPORT_POS_INTERFACE_LOG: `${reportExcelUrl}/ExportPosInterfaceLog`,
    POST_EXPORT_INTERFACE_S4_LOG: `${reportExcelUrl}/ExportInterfaceS4Log`,
    GET_EXPORT_SALES_LEGACY_SAP: `${reportExcelUrl}/ExportSalesLegacySap`,
    GET_EXPORT_SO_SUMMARY: `${reportExcelUrl}/ExportSOSummary`,
    GET_EXPORT_SO_SUMMARY_DETAIL: `${reportExcelUrl}/ExportSOSummaryDetail`,
    GET_EXPORT_STOCK_BALANCE: `${reportExcelUrl}/ExportStockBalance`,
    GET_Export_MANUALRUN_INTERFACE_INCOMING_PAYMENT: `${reportExcelUrl}/ExportManualRunInterfaceIncomingPayment`,
    GET_EXPORT_EXCEL_PROMOTIONS: `${reportExcelUrl}/ExportPromotions`,
    
    
    // report form
    GET_REPORT_FORM_SALES_ORDER: `${reportFormUrl}/GetReportSalesOrder`,
    GET_REPORT_FORM_TAX_INVOICE: `${reportFormUrl}/GetReportTaxInvoice`,
    GET_REPORT_FORM_DEBT_INVOICE: `${reportFormUrl}/GetReportDebtInvoice`,
    GET_REPORT_FORM_DEPOSIT_INVOICE: `${reportFormUrl}/GetReportDepositInvoice`,
    GET_REPORT_FORM_DEPOSIT_INVOICE_NEW: `${reportFormUrl}/GetReportNewFormTaxInvoice`,
    GET_REPORT_FORM_POS_TAX_INVOICE: `${reportFormUrl}/GetReportPOSTaxInvoice`,
    GET_REPORT_FORM_POS_FULL_TAX_INVOICE: `${reportFormUrl}/GetReportPOSFullTaxInvoice`,
    GET_REPORT_FORM_POS_REDUCE_DEBT: `${reportFormUrl}/GetReportPOSReduceDebt`,
    GET_REPORT_FORM_QUOTATION: `${reportFormUrl}/GetReportQuotation`,
    GET_REPORT_FORM_DELIVERY_ORDER: `${reportFormUrl}/GetReportDeliveryOrder`,
    GET_REPORT_FORM_DELIVERY_ORDER_A5: `${reportFormUrl}/GetReportDeliveryOrderA5`,
    GET_REPORT_FORM_INVENTORY_COUNT: `${reportFormUrl}/GetReportInventoryCount`,
    GET_REPORT_FORM_PREPARE_ORDER: `${reportFormUrl}/GetReportPrepareOrder`,
    GET_REPORT_FORM_PREPARE_ORDER_A5: `${reportFormUrl}/GetReportPrepareOrderA5`,
    GET_REPORT_REDUCE_DEBT: `${reportFormUrl}/GetReportReduceDebt`,
    GET_REPORT_REDUCE_DEBT_A5: `${reportFormUrl}/GetReportReduceDebtA5`,
    GET_REPORT_REDUCE_DEBT_REQUEST: `${reportFormUrl}/GetReportReduceDebtRequest`,
    POST_GET_REPORT_FORM_STOCK_TRANSFER_A5: `${reportFormUrl}/GetReportStockTransferA5`,
    POST_GET_REPORT_FORM_CANCEL_STOCK_TRANSFER_A5: `${reportFormUrl}/GetReportCancelStockTransferA5`,
    POST_GET_REPORT_ARTICLE_TRANSFER_SLIP: `${reportFormUrl}/GetReportArticleTransferSlip`,
    POST_GET_REPORT_CN_VOUCHER: `${reportFormUrl}/GetReportCNVoucher`,
    POST_GET_LABELING: `${reportFormUrl}/GetLabeling`,
    GET_REPORT_REQUISITION_REQUEST : `${reportFormUrl}/GetReportRequisitionRequest`,
    GET_REPORT_REQUISITION : `${reportFormUrl}/GetReportRequisition`,
    GET_REPORT_SHIPMENT : `${reportFormUrl}/GetReportShipment`,
    POST_GET_REPORT_FORM_GOOD_RECEIVE_A5 : `${reportFormUrl}/GetReportGoodReceiveA5`,
    POST_GET_REPORT_FORM_GOOD_RECEIVE_A4 : `${reportFormUrl}/GetReportGoodReceive`,
    GET_DMS_MANUAL_PRINT : `${reportFormUrl}/GetDMSManualPrints`,

    // credit note
    POST_CREATE_CREDIT_NOTE: `${creditNoteUrl}/CreateCreditNote`,
    PUT_UPDATE_CREDIT_NOTE: `${creditNoteUrl}/UpdateCreditNote`,
    // PUT_UPDATE_CREDIT_NOTE: `http://localhost:59386/api-creditnote/UpdateCreditNote`,
    POST_GET_CREDIT_NOTE_DETAIL: `${creditNoteUrl}/GetCreditNoteDetail`,
    POST_CREDIT_NOTE_REPROCESS: `${creditNoteUrl}/Reprocess`,
    POST_CREDIT_NOTE_BILLING_BY_FILTER: `${creditNoteUrl}/GetBillingByFilter`,
    POST_CREDIT_NOTE_CHECK_STATUS_CNDN: `${creditNoteUrl}/CheckStatusCNDN`,
    POST_CREDIT_NOTE_MANUAL_INTERFACE: `${creditNoteUrl}/ManualCNInterface`,
    POST_GET_CREDIT_NOTE_DEPOSIT: `${creditNoteUrl}/GetCreditNoteDeposit`,
    POST_VALIDATE_CREDIT_NOTE_DEPOSIT: `${creditNoteUrl}/CheckZCRDValidateDocTypeZDEP`,

    // inbound pos interface
    POST_REPAIR_TRANSACTION_POS_INTERFACE: `${inboundPOSUrl}/RepairTransactionPOSInterface`,

    // outbound product interface
    POST_OUTBOUND_PRODUCT_RUN_INF009: `${outboundProductUrl}/RunINF009`,
    POST_OUTBOUND_PRODUCT_RUN_INF010: `${outboundProductUrl}/RunINF010`,
    POST_OUTBOUND_PRODUCT_RUN_INF011: `${outboundProductUrl}/RunINF011`,
    POST_OUTBOUND_PRODUCT_RUN_INF012: `${outboundProductUrl}/RunINF012`,
    POST_OUTBOUND_PRODUCT_RUN_INF013: `${outboundProductUrl}/RunINF013`,

    // outbound MarginUrl
    GET_WEBMARGIN_CSV_BY_BILLING: `${outboundMarginUrl}/GetWebMarginCSVbyBilling`,

    // scg family 
    GET_SCG_ID : `${saleOrderUrl}/ValidateSCGID`,
    GET_COUPON_SCG_ID : `${saleOrderUrl}/GetCoupunBySCGID`
}

export class apiServices {
    static callApi = async (method, url, params, { headersOption, ...rest } = {}) => {
        await this.fetchWithCredentials().then(res => {
            console.log("we have new token ==> ", res);
        }).catch(error => {
            swal("Error!", "หมดเวลาการเชื่อมต่อกรุณาเข้าสู่ระบบใหม่!", "error");
            console.log(error);
            localStorage.clear();
            store.dispatch(authData(null)); // clear authentication
            //window.location.href = '/login';
            return Promise.reject(error)
        });

        // if we have new token
        const { auth } = store.getState();
        let mockNewToken = false
        let jwtToken = mockNewToken ? mockNewToken : auth.token
        // console.log("jwtToken", jwtToken);
        return await axios({
            method: method,
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwtToken,
                ...headersOption,
            },
            ...rest
        }).then(response => {
            if (response && response.status === 200)
                return response;
            else
                return Promise.reject(response);// generates an exception
        });
        //return response
    }

    static fetchWithCredentials = async () => {
        return new Promise(function (resolve, reject) {
            const { auth } = store.getState();
            // axios({
            //         method: 'post',
            //         url: API_PATH.POST_USER_REFRESH,
            //         data: { token: auth.token, refreshToken: auth.refreshToken },
            //         headers: {
            //             'Content-Type': 'application/json',
            //             'Authorization': 'Bearer ' + auth.token
            //         },
            //     }).then(function (response) {
            //     //set redux on axios then
            //     let newTokenBoth = {
            //         ...auth,
            //         token: response.data.token,
            //         refreshToken: response.data.refreshToken
            //     }
            //     store.dispatch(authData(newTokenBoth));
            //     resolve(true);
            // }).catch(error => {
            //     // refresh error
            //     reject(error);
            // });

            axios({
                method: 'get',
                url: API_PATH.GET_USER_IS_ALIVE,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + auth.token
                }
            }).then(res => {
                resolve(true);
            }).catch(async (error) => {
                console.log("error==", error);
                axios(
                    {
                        method: 'post',
                        url: API_PATH.POST_USER_REFRESH,
                        data: { token: auth.token, refreshToken: auth.refreshToken },
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                ).then(function (response) {
                    //console.log("responseRefresh==>", response);
                    //set redux on axios then
                    let newTokenBoth = {
                        ...auth,
                        token: response.data.token,
                        refreshToken: response.data.refreshToken
                    }
                    store.dispatch(authData(newTokenBoth));
                    resolve(true);
                    // console.log("newTokenBoth===>", newTokenBoth);
                    // return true
                    // return Promise.reject(newTokenBoth)
                }).catch(error => {
                    // refresh error
                    reject(error);
                })
            })
        });

    }
}
