import React, { Component } from 'react'
import { formatNumber } from 'helpers'
import { isEmpty, get, sumBy } from 'lodash'
import { Row, Col, message } from 'antd'
import {
    TableCustom,
    ModalCustom,
    ButtonCustom,
    InputSearchCustom,
} from 'components/common'
import swal from '@sweetalert/with-react'
import { apiServices, API_PATH } from 'apiServices'

export default class SaleOrderItemModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: 'รายการ',
                    dataIndex: 'soItem',
                    key: 'soItem',
                },
                {
                    title: 'รหัสสินค้า',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    render: (props) => (
                        <div className="text-right">{props}</div>
                    ),
                },
                {
                    title: 'ชื่อสินค้า',
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 180,
                },
                {
                    title: 'จำนวนขาย',
                    dataIndex: 'qty',
                    key: 'qty',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 3)}</div>
                    ),
                },
                {
                    title: 'ราคา/หน่วย',
                    dataIndex: 'netAmountQty',
                    key: 'netAmountQty',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'จำนวนเงินรวม',
                    dataIndex: 'netAmount',
                    key: 'netAmount',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'จำนวนส่งแล้ว',
                    dataIndex: 'doQty',
                    key: 'doQty',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 3)}</div>
                    ),
                },
                {
                    title: 'จำนวนเงินส่งแล้ว',
                    dataIndex: 'amountDoQty',
                    key: 'amountDoQty',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'จำนวนค้างส่ง',
                    dataIndex: 'outQty',
                    key: 'outQty',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 3)}</div>
                    ),
                },
                {
                    title: 'จำนวนเงินค้างส่ง',
                    dataIndex: 'amountOutQty',
                    key: 'amountOutQty',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
            ],
            columnsConfirm: [],
            soNumber: '',
            soDetail: {},
            loading: false,
            isConfirmOrder: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (isEmpty(this.props.soDetail) !== isEmpty(prevProps.soDetail)) {
            this.setState({
                soNumber: this.props.soDetail.soNumber,
                soDetail: this.calulateSoSummary(this.props.soDetail),
                isConfirmOrder: true,
            }, () => this.setColumnsConfirm())
        }

        if (isEmpty(this.props.soDetail) && this.props.outletId !== prevProps.outletId) {
            this.setState({ soNumber: '', soDetail: {}, isConfirmOrder: false })
        }
    }

    changeSoNumber = (e) => {
        this.setState({ soNumber: e.target.value })
    }

    confirmOrder = () => {
        this.setState({ isConfirmOrder: true, soNumber: this.state.soDetail.soNumber })
    }

    // set after get sale order because use sale order data for set title of column
    setColumnsConfirm = () => {
        const columnsConfirm = [
            {
                title: 'รายการ',
                dataIndex: 'soItem',
                key: 'soItem',
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
                render: (props) => (
                    <div className="text-right">{props}</div>
                ),
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                width: 180,
            },
            {
                title: 'จำนวนค้างส่ง',
                dataIndex: 'outQty',
                key: 'outQty',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                ),
            },
            {
                title: 'จำนวนเงินค้างส่ง',
                dataIndex: 'amountOutQty',
                key: 'amountOutQty',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
            },
            {
                title: `เอกสารรับเงิน ${this.state.soDetail.billingNo1 ? `[${this.state.soDetail.billingNo1}]` : ''}`,
                dataIndex: 'billingValue1',
                key: 'billingValue1',
                width: 120,
                align: 'center',
                render: (props) => (
                    props <= 0 ? '-' : <div className="text-right">{formatNumber(props, 3)}</div>
                ),
            },
            {
                title: `เอกสารรับเงิน ${this.state.soDetail.billingNo2 ? `[${this.state.soDetail.billingNo2}]` : ''}`,
                dataIndex: 'billingValue2',
                key: 'billingValue2',
                width: 120,
                align: 'center',
                render: (props) => (
                    props <= 0 ? '-' : <div className="text-right">{formatNumber(props, 2)}</div>
                ),
            },
        ]

        this.setState({ columnsConfirm })
    }

    calulateSoSummary = (soDetail) => {
        const items = soDetail.salesSOItemModels
        soDetail.sumAmountOut = sumBy(items, 'amountOutQty')

        return soDetail
    }

    onSearchSO = async () => {
        const { soNumber } = this.state
        const { outletId } = this.props

        if (!soNumber) {
            message.error('กรุณาระบุเอกสารใบสั่งขาย')
            return
        }
        this.setState({ loading: true })

        const body = {
            soNumber,
            outletId,
        }
        await apiServices.callApi('post', API_PATH.POST_GET_CREDIT_NOTE_DEPOSIT, body).then(res => {
            let soDetail = get(res, 'data.results.0')
            if (res.status === 200) {
                if (soDetail) {
                    soDetail = this.calulateSoSummary(soDetail)
                    this.setState({ soDetail }, () => { this.setColumnsConfirm() })
                } else {
                    message.error('ไม่พบข้อมูล')
                }
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูลใบสั่งขาย กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            onSelected,
            isSelected,
        } = this.props
        const {
            columns,
            columnsConfirm,
            loading,
            soNumber,
            soDetail,
            isConfirmOrder,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                title="กรุณาระบุเอกสารใบสั่งขาย (SO)"
            >
                <Row>
                    <Col sm={8} offset={16}>
                        <InputSearchCustom
                            label="เอกสารใบสั่งขาย"
                            small
                            value={soNumber}
                            onChange={this.changeSoNumber}
                            onClickSearch={this.onSearchSO}
                            onSubmit={this.onSearchSO}
                            disabled={isConfirmOrder}
                            disabledButton={isConfirmOrder}
                        />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            columns={isConfirmOrder ? columnsConfirm : columns}
                            data={soDetail.salesSOItemModels}
                            small
                            isFullContent
                            pagination={false}
                            rowKey={(row, index) => index}
                            loading={loading}
                        />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 40 }}>
                    <Col sm={24} className="text-right">
                        <span>{isConfirmOrder ? 'รวมจำนวนเงินลดหนี้' : 'รวมจำนวนเงินค้างส่ง'}</span>
                        <span style={{ paddingLeft: '50px', textDecoration: 'underline' }}>{formatNumber(soDetail.sumAmountOut, 2)}</span>
                    </Col>
                    <Col sm={24}>
                        <div style={{ paddingTop: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {isConfirmOrder && <ButtonCustom
                                text="ตกลง"
                                onClick={() => isSelected ? onCloseModal() : onSelected(soDetail)}
                            />}
                            {!isConfirmOrder && <ButtonCustom
                                text="ดำเนินการต่อ"
                                onClick={this.confirmOrder}
                                disabled={isEmpty(soDetail)}
                            />}
                            <ButtonCustom
                                style={{ marginLeft: '10px' }}
                                text="ยกเลิก"
                                type="danger"
                                onClick={onCloseModal}
                            />
                        </div>
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
