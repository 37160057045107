import React, { Component } from 'react'
import { Card } from 'antd';
import './style.css'

export class CardCustom extends Component {
    render() {
        const { bgColor } = this.props
        return (
            <Card
                bordered={false}
                style={{
                    width: '100%',
                    background: bgColor,
                    borderRadius: '2px',
                    height: 87,
                    padding: 0
                }}
            >
                <div className="card-top-zone">
                    sadasd
                </div>
                <div className="card-bottom-zone">
                    asdasd
                </div>
            </Card>
        )
    }
}
