import React, { Component } from 'react'
import { Select } from 'antd';
import { MultiSelectedCustomStyled } from "./styled"

const { Option } = Select;

// const children = [];
// for (let i = 10; i < 36; i++) {
//     children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
// }


export class MultiSelectedCustom extends Component {
    render() {
        const {
            label,
            options,
            onChange,
            value,
            widthDefault,
            right,
            small,
            widthSmall,
            widthAll,
        } = this.props
        return (
            <MultiSelectedCustomStyled right={right} widthSmall={widthSmall} small={small} widthDefault={widthDefault}>
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div className="label-style">
                                {label}
                            </div>
                        }
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            value={value ? value : undefined}
                            style={{
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    widthAll ? widthAll : ''
                            }}
                            onChange={onChange}
                            optionLabelProp="label"
                            size={small ? 'small' : 'default'}
                        >
                            {
                                options && options.map((data, i) => (
                                    <Option key={i} value={data.value} label={data.label} >{data.text}</Option>
                                ))
                            }
                        </Select>
                    </div>
                </div>
            </MultiSelectedCustomStyled >
        )
    }
}
