import React, { Component } from 'react'
import { Input } from 'antd';
import { TextAreaReduxStyled } from "./styled"

const { TextArea } = Input;

export class TextAreaRedux extends Component {
    render() {
        const {
            label,
            right,
            BorderRadius,
            small,
            widthSmall,
            widthDefault,
            input,
            onChangeHero,
            meta: { touched, error },
            submitViewError,
            disabled,
            rows
        } = this.props
        return (
            <TextAreaReduxStyled
                error={(touched || submitViewError) && error ? true : false}
                small={small}
                widthSmall={widthSmall}
                widthDefault={widthDefault}
            >
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div
                                className="label-style"
                                style={{
                                    textAlign: right ? 'right' : '',
                                }}
                            >
                                {label}
                            </div>
                        }
                        <TextArea
                            className="input-style"
                            rows={rows ? rows : 4}
                            {...input}
                            onChange={(e) => {
                                input.onChange(e)
                                onChangeHero && onChangeHero(e)
                            }}
                            style={{
                                borderRadius: BorderRadius ? BorderRadius : 4,
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    ''
                                ,
                            }}
                            disabled={disabled}
                        />
                    </div>
                    {
                        (touched || submitViewError) ?
                            <div className="error-message-bottom"> {error}  </div>
                            : ''
                    }

                </div>
            </TextAreaReduxStyled>
        )
    }
}
