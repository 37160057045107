import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button } from "antd"
import { VALIDATE } from "helpers"
import { InputFormRedux } from "components"


class LoginForm extends React.Component {
    state = {

    }

    render() {
        const {
            disabledInput,
            loading,
            authenticate,
            submitViewError
        } = this.props
        return (
            <div>
                <form
                    onSubmit={
                        (event) => authenticate(event)
                        // (e) => this.mockAuthData(e)
                    }
                    className="form-signin" noValidate
                >
                    <Field
                        type="email"
                        component={InputFormRedux}
                        name="username"
                        placeholder="Username"
                        autoFocus
                        disabled={disabledInput}
                        validate={VALIDATE.REQUIRE}
                        submitViewError={submitViewError}
                        BorderRadius="25rem"
                    />
                    <Field
                        type="password"
                        component={InputFormRedux}
                        name="password"
                        placeholder="Password"
                        disabled={disabledInput}
                        validate={VALIDATE.REQUIRE}
                        submitViewError={submitViewError}
                        BorderRadius="25rem"
                    />
                    <Button loading={loading} type="primary" htmlType="submit" block>Sign In</Button>
                    <hr className="my-4" />
                    <div className="text-center"> RFO : Retail Frontend Operation Management</div>
                    <div className="text-center">{window.REACT_APP_ENVIRONMENT} {window.REACT_APP_VERSION}</div>
                </form>
            </div>
        )
    }
}

export default reduxForm({
    form: 'LoginForm',  // a unique identifier for this form
    enableReinitialize: true
})(LoginForm)
