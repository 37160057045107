import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Row, Col, Divider } from "antd"
import { get, isEmpty } from 'lodash'
import swal from '@sweetalert/with-react'
import {
  InputFormRedux,
  SelectRedux,
  RadioRedux,
} from 'components'
import { validationSCGID } from '../../helpers/function'
import {
  CheckboxGroupCustom,
} from 'components/common'

import { VALIDATE } from "helpers"
import './style.css'

class CustomerMaintainForm extends React.Component {
  state = {

  }
  async searchSCGID(e) {
    let customerInfo = this.props.initialValues
    try {
      const value = e.target.value || this.state.scgId
      if (!isEmpty(value)) {
        const resultSCGID = await validationSCGID(value)
        customerInfo.scgId = resultSCGID.member.scgid
        swal("Success", `ดึงข้อมูล SCG Family ID สำเร็จ`, "success");
      } else {
        customerInfo.scgId = ''
      }
    } catch (error) {
      swal('แจ้งเตือน SCG Family ID', error, 'warning')
      customerInfo.scgId = ''
    }
    this.setState({ scgId: customerInfo.scgId })
  }
  render() {
    const {
      submitViewError,
      otherBranchViewError,
      creditAllowOptions,
      customerTypeOptions,
      taxBranchOptions,
      customerStatusOptions,
      dataOutlet,
      dataCustomerTitles,
      dataCustomerGroups,
      dataTaxs,
      dataPaymentTerms,

      dataRegionCountries,
      dataProvices,
      dataDistricts,
      dataSubDistrict,

      handleChangeSelectProvicne,
      handleChangeDistricts,
      handleChangeSubDistricts,
      handleSubmitPostalCode,

      creditAllow,
      soldToCustomerType,
      soldToTaxBranch,
      permissionPage,
    } = this.props

    return (
      <div>
        <Row gutter={10}>
          <Col sm={12}>
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={SelectRedux}
              name="soldToOutlet"
              label="ร้าน"
              options={dataOutlet}
              pleaseChoose={true}
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={12}>
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={SelectRedux}
              name="soldToTitles"
              label="คำนำหน้าชื่อ"
              options={dataCustomerTitles}
              pleaseChoose={true}
            />
          </Col>
          <Col sm={12}>
            <Field
              component={InputFormRedux}
              name="soldToSCGId"
              label="SCG Family ID"
              onBlur={this.searchSCGID.bind(this)}
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={12}>
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={InputFormRedux}
              name="soldToName"
              label="ชื่อ-นามสกุล"
            />
          </Col>
          <Col sm={12}>
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={RadioRedux}
              options={customerTypeOptions}
              name="soldToCustomerType"
              label="ประเภทลูกค้า"
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={12}>
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={InputFormRedux}
              name="soldToAddress"
              label="ที่อยู่"
            />
          </Col>
          <Col sm={8}>
            <Field
              validate={soldToCustomerType === '2' ? VALIDATE.REQUIRE : undefined}
              submitViewError={submitViewError}
              component={RadioRedux}
              options={taxBranchOptions}
              name="soldToTaxBranch"
              label="สาขาภาษี"
              value="00000"
            />
          </Col>
          <Col sm={4}>
            <Field
              validate={soldToCustomerType === '2' && soldToTaxBranch === 'another' ? VALIDATE.REQUIRE : undefined}
              submitViewError={submitViewError}
              component={InputFormRedux}
              name="soldToTaxOtherBranch"
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={12}>
            {/* <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={InputFormRedux}
              name="soldToPostalCode"
              label="รหัสไปรษณีย์"
              onPressEnter={handleSubmitPostalCode}
            /> */}

            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={SelectRedux}
              name="soldToProvince"
              label="จังหวัด"
              pleaseChoose={true}
              options={dataProvices}
              onChangeHero={handleChangeSelectProvicne}
            />
          </Col>
          <Col sm={12}>
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={SelectRedux}
              name="soldToTaxType"
              label="ประเภทภาษี"
              pleaseChoose={true}
              options={dataTaxs}
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={12}>
            {/* <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={SelectRedux}
              name="soldToProvince"
              label="จังหวัด"
              pleaseChoose={true}
              options={dataProvices}
              onChangeHero={handleChangeSelectProvicne}
            /> */}
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={SelectRedux}
              name="soldToDistrict"
              label="เขต/อำเภอ"
              pleaseChoose={true}
              options={dataDistricts}
              onChangeHero={handleChangeDistricts}
            />
          </Col>
          <Col sm={12}>
            <Field
              validate={soldToCustomerType === '2' ? VALIDATE.REQUIRE : undefined}
              submitViewError={submitViewError}
              component={InputFormRedux}
              maxLength={13}
              name="soldToTaxId"
              label="เลขที่ประจำตัวผู้เสียภาษี"
              maxLength={13}
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={12}>
            {/* <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={SelectRedux}
              name="soldToDistrict"
              label="เขต/อำเภอ"
              pleaseChoose={true}
              options={dataDistricts}
              onChangeHero={handleChangeDistricts}
            /> */}
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={SelectRedux}
              name="soldToSubDistrict"
              label="แขวง/ตำบล"
              pleaseChoose={true}
              options={dataSubDistrict}
              onChangeHero={handleChangeSubDistricts}
            />
          </Col>
          <Col sm={12}>
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={SelectRedux}
              name="soldToPaymentTerm"
              label="เงื่อนไขการชำระ"
              pleaseChoose={true}
              options={dataPaymentTerms}
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={12}>
            {/* <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={SelectRedux}
              name="soldToSubDistrict"
              label="แขวง/ตำบล"
              pleaseChoose={true}
              options={dataSubDistrict}
            /> */}
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={InputFormRedux}
              name="soldToPostalCode"
              label="รหัสไปรษณีย์"
              onPressEnter={handleSubmitPostalCode}
            />
          </Col>
          <Col sm={12}>
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={SelectRedux}
              name="soldToCustomerGroup"
              label="กลุ่มลูกค้า"
              pleaseChoose={true}
              options={dataCustomerGroups}
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={12}>
            <Field
              component={SelectRedux}
              name="soldToCountry"
              label="ประเทศ"
              options={dataRegionCountries}
              //value="TH"
              firstChoose={true}
            />
          </Col>
          <Col sm={12}>
            <Field
              submitViewError={submitViewError}
              component={InputFormRedux}
              name="soldToEmail"
              label="อีเมล์"
            />
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={12}>
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={InputFormRedux}
              name="soldToPhone"
              // placeholder="0800000000"
              placeholder="0200000000"
              label="โทรศัพท์"
            />
          </Col>
          <Col sm={12}>
            <Field
              submitViewError={submitViewError}
              component={InputFormRedux}
              name="soldToLineId"
              label="LINE ID"
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col sm={12}>
            <Field
              // validate={VALIDATE.REQUIRE}
              // format="#-####-#####-##-#"
              // mask="_"
              submitViewError={submitViewError}
              component={InputFormRedux}
              name="soldToMobile"
              placeholder="0800000000"
              label="เบอร์โทรศัพท์มือถือ"
            />
          </Col>
          
        </Row>
        <Divider style={{ margin: '10px 0px' }} />
        <Row gutter={10}>
          <Col sm={24}>
            <Field
              isError={false}
              component={CheckboxGroupCustom}
              options={creditAllowOptions}
              name="creditAllow"
              id="creditAllow"
              label="วงเงินเครดิต"
              disabled={!permissionPage.authApprove}
            />
          </Col>
          <Col sm={12}>
            <Field
              validate={get(creditAllow, '0') === true ? VALIDATE.REQUIRE : undefined}
              submitViewError={submitViewError}
              component={InputFormRedux}
              name="creditLimit"
              label="วงเงินที่มี"
              disabled={!permissionPage.authApprove}
            />
          </Col>
          <Col sm={12} className="custon-padding-col">
            <Field
              submitViewError={submitViewError}
              component={InputFormRedux}
              name="creditAvailable"
              label="วงเงินที่ใช้ได้"
              disabled
            />
          </Col>
          <Divider style={{ margin: '10px 0px' }} />
        </Row>
        <Row gutter={10}>
          <Col sm={24}>
            <b>เปลี่ยนแปลงสถานะลูกค้า</b>
          </Col>
          <Col sm={16}>
            <Field
              validate={VALIDATE.REQUIRE}
              submitViewError={submitViewError}
              component={RadioRedux}
              name="customerStatus"
              label="ลบลูกค้าด้านการขายและบัญชี"
              options={customerStatusOptions}
              small
              widthSmall="200px"
              disabled={!permissionPage.authDelete}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

// export default reduxForm({
//   form: 'CustomerMaintainForm',  // a unique identifier for this form
//   enableReinitialize: true
// })(CustomerMaintainForm)

CustomerMaintainForm = reduxForm({
  form: 'CustomerMaintainForm',
  enableReinitialize: true,
})(CustomerMaintainForm)

const selector = formValueSelector('CustomerMaintainForm')
CustomerMaintainForm = connect(state => {
  const {
    creditAllow,
    soldToCustomerType,
    soldToTaxBranch,
  } = selector(state, 'creditAllow', 'soldToCustomerType', 'soldToTaxBranch')
  return {
    creditAllow,
    soldToCustomerType,
    soldToTaxBranch,
  }
})(CustomerMaintainForm)

export default CustomerMaintainForm