import styled from 'styled-components'

export const SelectSearchStyled = styled.div`

    .form-select-search {
        margin-bottom: ${props => props.small ? '3px' : ''};
        
        .label-style {
            padding-right: 15px;
            font-size: ${props => props.small ? '12px' : ''};
            text-align: ${props => props.labelAlign};
            width: ${props => props.widthLabel ? props.widthLabel : props.small ? '120px' : '150px'};
            color: ${props => props.labelColor};
        }

        .select-search {
            font-size: ${props => props.small ? '12px' : ''};
            width: ${props => props.label ? `calc(100% - ${props.widthLabel ? props.widthLabel : props.small ? '120px' : '150px'})` : '100%'};
           
            .ant-select-selection {
                border-color: ${props => props.isError ? 'red' : ''};
            }

            .ant-select-selection--multiple .ant-select-selection__placeholder {
                margin-left: 4px;
                color: ${props => props.isPlaceholderDarkColor ? 'rgba(0, 0, 0, 0.65)' : ''};
            }
        }
    }
`
