import React, { Component } from 'react'
import { isEmpty, get, find } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    SelectHero,
    InputHero,
    InputSearch,
    TextAreaCustom,
    RadioCustom,
    ModalProductSearch,
    ModalSaleUnit,
    ModalProductCategorySearch
} from 'components'
import { Row, Col, Checkbox, Icon, Spin, message } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import XLSX from 'xlsx'
import swal from '@sweetalert/with-react'
import { Link } from 'react-router-dom';
import { ROUTH_PATH } from '../../../../routes/path';
import { QualifierTypes, QualifierStatus, VALIDATE, getPermissionPage } from 'helpers'
import { functionAlias } from 'routes'
import './style.css'

class QualifierMaintainContainer extends Component {
    state = {
        permissionPage: getPermissionPage(functionAlias.promotionQualifierMaintain),
        qualifierId: "Auto",
        qualifierType: 'product',
        isShowModalProduct: false,
        isShowModalSaleUnit: false,
        isShowModalProductCategory: false,
        qualifierStatus: 1,

        tempProduct: {
            key: 0,
            barcode: '',
            productId: ''
        },
        tempProductCategory: {
            key: 0,
            categoryCode: ''
        },

        dataProductTableList: [
            {
                key: 0,
                productId: ''
            }
        ],

        dataProductCategoryTableList: [
            {
                key: 0,
                categoryCode: ''
            }
        ],

        //  columns Table set here
        columnsProduct: [
            {
                title: '',
                align: 'center',
                dataIndex: 'delete',
                key: 'delete',
                width: 50,
                render: (value, full, index) => (this.state.dataProductTableList.length - 1 !== index && <ButtonCustom
                    text="ลบ"
                    small
                    type="danger"
                    onClick={(e) => this.handleDeleteRowTableProduct(e, index)}
                />)
            },
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                width: 50,
                align: 'center',
                render: (value, full, index) => (
                    <div>
                        {index + 1}
                    </div>
                )
            },
            {
                title: 'รหัสสินค้า',
                align: 'center',
                dataIndex: 'productCode',
                key: 'productCode',
                width: 200,
                render: (value) => <div>
                    {
                        value
                            ?
                            value
                            :
                            <InputSearch
                                handleClickSearch={(e) => this.handleClickModalProduct(e)}
                                small
                                onChange={this.handleChangeSearchProduct}
                                handleSubmitSearch={this.handleSubmitSearchProductOnTable}
                                value={this.state.valueSearchProduct}
                            />
                    }
                </div>
            },
            {
                title: 'ชื่อสินค้า',
                //align: 'center',
                dataIndex: 'productName',
                key: 'productName',
            },
            {
                title: 'หน่วยขาย',
                align: 'center',
                dataIndex: 'salesUnit',
                key: 'salesUnit',
                width: 100,
                render: (value, full, index) => (this.state.dataProductTableList.length - 1 !== index && <ButtonCustom
                    text={value}
                    small
                    type="link"
                    onClick={() => this.handleClickModalSaleUnit(index, full)}
                />)
            },
        ],
        columnsProductCategory: [
            {
                title: '',
                align: 'center',
                dataIndex: 'delete',
                key: 'delete',
                width: 45,
                render: (value, full, index) => <div className="delete-table-row" onClick={(e) => this.handleDeleteRowTableProductCategory(e, index)} >
                    {
                        this.state.dataProductCategoryTableList.length - 1 == index
                            ?
                            ''
                            :
                            'ลบ'
                    }
                </div>
            },
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                width: 50,
                render: (value, full, index) => (
                    <div>
                        {index + 1}
                    </div>
                )
            },
            {
                title: 'รหัสกลุ่มสินค้า',
                align: 'center',
                dataIndex: 'categoryCode',
                key: 'categoryCode',
                width: 250,
                render: (value) => <div>
                    {
                        value
                            ?
                            value
                            :
                            <InputSearch
                                handleClickSearch={(e) => this.handleClickModalProductCategory(e)}
                                small
                                onChange={this.handleChangeSearchProductCategory}
                                handleSubmitSearch={this.handleSubmitSearchProductCategoryOnTable}
                                value={this.state.valueSearchProductCategory}
                            />
                    }

                </div>
            },
            {
                title: 'กลุ่มสินค้า',
                //align: 'center',
                dataIndex: 'descriptionThai',
                key: 'descriptionThai',
            }
        ],
        loading: false,
        disabledBtnSave: true,
        stockTypes: [],
        stockType: '',
        outlets: [],
        outlet: '',
    }

    componentDidMount() {
        const { location, match } = this.props;
        console.log("location===>", location);
        console.log("match===>", match);
        if (match.params.id !== undefined) {
            this.fetchDataQualifierDetail(match.params.id);
        }
    }

    fetchDataQualifierDetail = async (id) => {
        this.setState({ loading: true });
        let { qualifierId,
            qualifierType,
            qualifierName,
            qualifierDescription,
            qualifierStatus,
            dataProductTableList,
            dataProductCategoryTableList,
            tempProduct,
            tempProductCategory,
        } = this.state;

        dataProductTableList = [];
        dataProductTableList.push({ tempProduct });
        dataProductCategoryTableList = [];
        dataProductCategoryTableList.push({ tempProductCategory });

        let URLAndParams = `${API_PATH.GET_QUALIFIER}?ItemQualifierId=${id}&Blockeds=0&Blockeds=1`;
        let res = await apiServices.callApi("get", URLAndParams, '', '')//auth.token
        // console.log("Response===>", res);
        if (res.status === 200 && res.data.results.length > 0) {
            let qualifiers = get(res, 'data.results');
            let qualifier = qualifiers[0];
            qualifierId = qualifier.itemQualifierId;
            qualifierType = qualifier.qualifierTypeCode;
            qualifierName = qualifier.name;
            qualifierDescription = qualifier.description;
            qualifierStatus = qualifier.blocked;

            if (qualifier.qualifierTypeCode === 'product' && qualifier.promotionItemQualifierItem.length > 0) {
                dataProductTableList.splice(dataProductTableList.length - 1, 1);

                qualifier.promotionItemQualifierItem.forEach(product => {
                    dataProductTableList.push({
                        ...product,
                        key: product.itemQualifierItemId,
                        productCode: product.productCode,
                        productName: product.temName,
                        salesUnit: product.unitCode,
                    });
                });

                dataProductTableList.push(tempProduct);
                this.setState({ dataProductTableList });
            } else if (qualifier.qualifierTypeCode === 'category' && qualifier.promotionItemQualifierItem.length > 0) {
                dataProductCategoryTableList.splice(dataProductCategoryTableList.length - 1, 1);

                qualifier.promotionItemQualifierItem.forEach(category => {
                    dataProductCategoryTableList.push({
                        ...category,
                        key: category.itemQualifierItemId,
                        categoryCode: category.productCode,
                        descriptionThai: category.temName,
                        salesUnit: null,
                    });
                });

                dataProductCategoryTableList.push({ ...tempProductCategory });
            }
        }

        this.setState({
            loading: false,
            qualifierId,
            qualifierType,
            qualifierName,
            qualifierDescription,
            qualifierStatus,
            dataProductTableList,
            dataProductCategoryTableList,
        });
    };

    handleChangeQualifierType = (val) => {
        let { dataProductTableList, dataProductCategoryTableList, tempProduct, tempProductCategory } = this.state;

        dataProductTableList = [];
        dataProductTableList.push(tempProduct);

        dataProductCategoryTableList = [];
        dataProductCategoryTableList.push(tempProductCategory);

        this.setState({ qualifierType: val, dataProductTableList, dataProductCategoryTableList });
    }
    handleChangeQualifierName = (val) => {
        if (!val.target.value) {
            this.setState({ submitViewErrorQualifierName: true });
        } else {
            this.setState({ submitViewErrorQualifierName: false });
        }
        this.setState({ qualifierName: val.target.value });
    }
    handleChangeQualifierDescription = (val) => {
        console.log("val===>", val);
        this.setState({ qualifierDescription: val.target.value });
    }
    handleChangeQualifierStatus = (val) => {
        this.setState({ qualifierStatus: val.target.value });
    }

    handleClickModalProduct = () => {
        this.setState({
            isShowModalProduct: true,
        });
    }
    handleClickModalProductCategory = (e) => {
        e.preventDefault();
        this.setState({
            isShowModalProductCategory: true,
        });
    }
    handleClickModalSaleUnit = (index, full) => {
        console.log("handleClickModalSaleUnit==>full", full);
        this.setState({
            isShowModalSaleUnit: true,
            saleUnitProductId: full.productCode,
            saleUnitSelectIndex: index,
        });
    }
    handleCloseModal = () => {
        let { isShowModalProduct, isShowModalSaleUnit, isShowModalProductCategory } = this.state;
        if (isShowModalProduct === true) {
            this.setState({ isShowModalProduct: false })
        }
        if (isShowModalSaleUnit === true) {
            this.setState({ isShowModalSaleUnit: false })
        }
        if (isShowModalProductCategory === true) {
            this.setState({ isShowModalProductCategory: false })
        }
    }
    onSelectedProduct = (val) => {
        console.log("SelectedProduct==>", val);
        let { dataProductTableList, tempProduct } = this.state;
        dataProductTableList.splice(dataProductTableList.length - 1, 1);
        dataProductTableList.push({
            productCode: val.productId,
            productName: val.productNameMarket,
            salesUnit: val.salesUnit,
        });
        dataProductTableList.push(tempProduct);
        this.setState({ dataProductTableList });
        this.handleCloseModal();
    }

    handleDeleteRowTableProduct = (e, index) => {
        e.preventDefault();
        this.setState({ loading: true });
        let { dataProductTableList } = this.state;
        dataProductTableList.splice(index, 1);
        this.setState({ loading: false });
    }

    handleDeleteRowTableProductCategory = (e, index) => {
        e.preventDefault();
        this.setState({ loading: true });
        let { dataProductCategoryTableList } = this.state;
        dataProductCategoryTableList.splice(index, 1);
        this.setState({ loading: false });
    }

    onSelectedSaleUnit = (val) => {
        console.log("SelectedSaleUnit==>", val);
        let { dataProductTableList, saleUnitSelectIndex } = this.state;

        dataProductTableList[saleUnitSelectIndex].salesUnit = val.unitId;

        this.setState({ dataProductTableList });
        this.handleCloseModal();
    }
    onSelectedProductCategory = (val) => {
        console.log("SelectedProductCategory==>", val);
        let { dataProductCategoryTableList, tempProductCategory } = this.state;

        dataProductCategoryTableList.splice(dataProductCategoryTableList.length - 1, 1);
        dataProductCategoryTableList.push({ ...val, key: val.categoryId });
        dataProductCategoryTableList.push({ ...tempProductCategory });

        this.handleCloseModal();
    }

    handleChangeSearchProduct = (val) => {
        this.setState({ valueSearchProduct: val.target.value });
    }

    handleChangeSearchProductCategory = (val) => {
        this.setState({ valueSearchProductCategory: val.target.value });
    }

    handleSubmitSearchProductOnTable = async (e) => {
        e.preventDefault();
        const { valueSearchProduct, dataProductTableList, tempProduct } = this.state

        if (!valueSearchProduct) {
            message.error('กรุณากรอกข้อมูลรหัสสินค้า');
            return;
        }

        const { auth } = this.props
        let params = {
            productId: valueSearchProduct,
            barcode: '',
            storageLocationIds: auth.arrayStorageLocationId,
            productStatus: true
        }
        let res = await apiServices.callApi("post", `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, params);
        if (res.status === 200) {
            if (res.data.results.length > 0) {
                let products = get(res, 'data.results');
                products = products.filter((ele, ind) => ind === products.findIndex(prd => prd.productId === ele.productId && prd.salesUnit === ele.salesUnit))
                if (!products || products.length === 0) {
                    message.error('ไม่พบสินค้า')
                    return;
                }
                let product = products[0];
                console.log("product==>", product);
                dataProductTableList.splice(dataProductTableList.length - 1, 1);
                dataProductTableList.push({
                    productCode: product.productId,
                    productName: product.productNameMarket,
                    salesUnit: product.salesUnit,
                });
                dataProductTableList.push(tempProduct);

                this.setState({ dataProductTableList, valueSearchProduct: '' });
            }
            else {
                message.error('ไม่พบสินค้า')
            }
        }
    }

    handleSubmitSearchProductCategoryOnTable = async (e) => {
        e.preventDefault();
        const { valueSearchProductCategory, dataProductCategoryTableList, tempProductCategory } = this.state

        if (!valueSearchProductCategory) {
            message.error('กรุณากรอกข้อมูลรหัสกลุ่มสินค้า');
            return;
        }

        let paramGet = `?CategoryCode=${valueSearchProductCategory}`;
        let urlProdutCategory = `${API_PATH.GET_MASTER_PRODUCT_CATEGORY}/${paramGet}`;
        console.log("urlProdutCategory==>", urlProdutCategory);
        let res = await apiServices.callApi("get", urlProdutCategory, '');
        if (res.status === 200) {
            let productCategories = get(res, 'data.results');
            if (productCategories.length === 0) {
                message.error(`ไม่พบข้อมูลรหัสกลุ่มสินค้า: ${valueSearchProductCategory}`);
                return;
            }
            const productCategory = productCategories[0];
            console.log("productCategory==>", productCategory);

            dataProductCategoryTableList.splice(dataProductCategoryTableList.length - 1, 1);
            dataProductCategoryTableList.push({ ...productCategory, key: productCategory.categoryId });
            dataProductCategoryTableList.push({ ...tempProductCategory });

            this.setState({ dataProductCategoryTableList, valueSearchProductCategory: '' })
        }
    }

    readExcelFile = (event) => {
        try {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    let data = e.target.result
                    data = new Uint8Array(data)
                    const workbook = XLSX.read(data, { type: 'array' })
                    const result = {}
                    workbook.SheetNames.forEach((sheetName) => {
                        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })
                        if (roa.length) result[sheetName] = roa
                        this.importProducts(result)
                    })
                } catch (err) {
                    swal('Error', 'กรุณาตรวจสอบความถูกต้องของไฟล์ Excel', 'error')
                    console.error(err)
                }
            }
            reader.readAsArrayBuffer(file)
        } catch (e) {
            console.log('error', e);
        }
    }

    importProducts = async (data) => {
        console.log("data===>", data);
        if (!data.Qualifier) {
            swal('Error', 'ข้อมูล Excel ไม่ถูกต้อง', 'error')
            return
        }

        this.setState({ loading: true })
        let { tempProduct, tempProductCategory, dataProductTableList, dataProductCategoryTableList, qualifierType } = this.state
        const rows = data.Qualifier;
        const dataImport = []

        for (const index in rows) {
            const row = rows[index];
            if (+index === 0) continue
            if (isEmpty(row)) break
            const productCode = row[0];
            const unitCode = row[1];

            if (qualifierType === 'product' && productCode && unitCode || qualifierType === 'category' && productCode) {
                dataImport.push({
                    productCode,
                    unitCode
                })
            } else {
                swal('Error', 'กรุณาตรวจสอบไฟล์', 'error');
                this.setState({ loading: false });
                return;
            }
        }

        console.log("dataImport===>", dataImport);
        if (isEmpty(dataImport)) {
            swal('Error', 'ไม่มีข้อมูล', 'error')
        } else {
            const url = `${API_PATH.POST_QUALIFIER_IMPORT_EXCEL}?qualifierType=${qualifierType}`;
            const body = [...dataImport];
            const res = await apiServices.callApi('post', url, body);
            console.log("res POST_QUALIFIER_IMPORT_EXCEL ===>", res);
            if (res.status === 200) {
                const productsImport = get(res, 'data.results');
                console.log("productsImport ===>", productsImport);
                if (res.data.status === "Success") {
                    if (productsImport) {
                        if (qualifierType === "product") {
                            dataProductTableList = this.setProducts(dataProductTableList, productsImport, tempProduct);
                        } else if (qualifierType === "category") {
                            dataProductCategoryTableList = this.setProductCategories(dataProductCategoryTableList, productsImport, tempProductCategory);
                        }

                        this.setState({ dataProductTableList, dataProductCategoryTableList });
                    }
                } else {
                    if (productsImport) {
                        if (qualifierType === "product") {
                            dataProductTableList = this.setProducts(dataProductTableList, productsImport, tempProduct);
                        } else if (qualifierType === "category") {
                            dataProductCategoryTableList = this.setProductCategories(dataProductCategoryTableList, productsImport, tempProductCategory);
                        }
                        this.setState({ dataProductTableList, dataProductCategoryTableList });
                    }
                    swal(res.data.status, res.data.message, 'error')
                }
            } else {
                swal('Error', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง', 'error')
            }
        }
        this.setState({ loading: false })
    }

    setProducts(list, importList, lastItem) {
        list.splice(list.length - 1, 1);
        importList.forEach(product => list.push({
            productCode: product.productCode,
            productName: product.temName,
            salesUnit: product.unitCode,
        }));
        list.push(lastItem);
        return list;
    }

    setProductCategories(list, importList, lastItem) {
        list.splice(list.length - 1, 1);
        importList.forEach(product => list.push({
            categoryCode: product.productCode,
            descriptionThai: product.temName,
        }));
        list.push(lastItem);
        return list;
    }

    handleClickSaveQualifier = async () => {
        try {
            let items = [];
            let { qualifierId,
                qualifierType,
                qualifierName,
                qualifierDescription,
                qualifierStatus,
                dataProductTableList,
                dataProductCategoryTableList,
            } = this.state;
            let newdataProductTableList = JSON.parse(JSON.stringify(dataProductTableList)).slice(0, dataProductTableList.length - 1)
            
            if (!qualifierName) {
                this.setState({ submitViewErrorQualifierName: true });
                message.error(`กรุณาระบุ 'Qualifier Name'.`);
                return;
            }
            if (qualifierType === 'product' && dataProductTableList.length <= 1) {
                message.error(`กรุณาระบุรายการสินค้า.`);
                return;
            } else if (qualifierType === 'category' && dataProductCategoryTableList.length <= 1) {
                message.error(`กรุณาระบุรายการกลุ่มสินค้า.`);
                return;
            } else if (qualifierType === 'product' && newdataProductTableList.every(product => product.productCode !== '' && product.salesUnit === '')) {
                message.error(`กรุณาระบุหน่วยขายของสินค้าด้วย.`);
                return;
            }

            let resCreateOrUpdateQualifier = [];
            //Create
            this.setState({ loading: true });
            if (qualifierId === 'Auto') {
                if (qualifierType === 'product') {
                    dataProductTableList.forEach(product => {
                        if (product.productCode) {
                            items.push({
                                "itemQualifierItemId": 0,
                                "itemQualifierId": 0,
                                "productCode": product.productCode,
                                "unitCode": product.salesUnit,
                                "temName": product.productName,
                            });
                        }
                    });
                } else if (qualifierType === 'category') {
                    dataProductCategoryTableList.forEach(product => {
                        if (product.categoryCode) {
                            items.push({
                                "itemQualifierItemId": 0,
                                "itemQualifierId": 0,
                                "productCode": product.categoryCode,
                                "unitCode": null,
                                "temName": product.descriptionThai,
                            });
                        }
                    });
                }

                // return
                let param = {
                    "itemQualifierId": 0,
                    "qualifierTypeCode": qualifierType,
                    "name": qualifierName,
                    "description": qualifierDescription,
                    "modifyDate": new Date(),
                    "blocked": qualifierStatus,
                    "deleteDate": null,
                    "promotionItemQualifierItem": items
                }
                console.log(`param===>`, param);

                resCreateOrUpdateQualifier = await apiServices.callApi('post', API_PATH.POST_QUALIFIER, param);
            }
            //Update
            else {
                if (qualifierType === 'product') {
                    dataProductTableList.forEach(product => {
                        if (product.productCode) {
                            items.push({
                                /*"itemQualifierItemId": 0,
                                "itemQualifierId": 0,*/
                                ...product,
                                "productCode": product.productCode,
                                "unitCode": product.salesUnit,
                                "temName": product.productName,
                            });
                        }
                    });
                } else if (qualifierType === 'category') {
                    dataProductCategoryTableList.forEach(product => {
                        if (product.categoryCode) {
                            items.push({
                                ...product,
                                /*"itemQualifierItemId": 0,
                                "itemQualifierId": 0,*/
                                "productCode": product.categoryCode,
                                "unitCode": null,
                                "temName": product.descriptionThai,
                            });
                        }
                    });
                }

                let param = [{
                    "itemQualifierId": qualifierId,
                    "qualifierTypeCode": qualifierType,
                    "name": qualifierName,
                    "description": qualifierDescription,
                    "modifyDate": new Date(),
                    "blocked": qualifierStatus,
                    "deleteDate": null,
                    "promotionItemQualifierItem": items
                }];
                console.log(`param===>`, param);
                resCreateOrUpdateQualifier = await apiServices.callApi('put', API_PATH.PUT_QUALIFIER, param);
            }
            console.log(`resCreateOrUpdateQualifier===>`, resCreateOrUpdateQualifier);

            if (resCreateOrUpdateQualifier.status === 200 && resCreateOrUpdateQualifier.data.results && resCreateOrUpdateQualifier.data.results.length > 0) {
                const qualifiers = get(resCreateOrUpdateQualifier, 'data.results');
                const qualifier = qualifiers[0];
                swal("Success", `${qualifierId === 'Auto' ? 'สร้าง' : 'แก้ไข'}ข้อมูล Promotion Qualifier : ${qualifier.itemQualifierId} ${resCreateOrUpdateQualifier.data.message}`, "success");
                this.props.history.push({
                    pathname: ROUTH_PATH.PROMOTION_QUALIFIER_EDIT.replace(":id", `${qualifier.itemQualifierId}`),
                    state: qualifier.itemQualifierId
                });
                this.fetchDataQualifierDetail(qualifier.itemQualifierId);
            }
            else {
                swal("error", `${qualifierId === 'Auto' ? 'สร้าง' : 'แก้ไข'}ข้อมูล Promotion Qualifier : ${resCreateOrUpdateQualifier.data.message}`, "error");
            }
        } catch (e) {
            console.log('error', e);
        }
        this.setState({ loading: false });
    }

    render() {
        const {
            loading,
            qualifierId,
            qualifierType,
            qualifierName,
            qualifierDescription,
            qualifierStatus,
            columnsProduct,
            columnsProductCategory,
            dataProductTableList,
            dataProductCategoryTableList,
            saleUnitProductId,

            isShowModalProduct,
            isShowModalSaleUnit,
            isShowModalProductCategory,

            submitViewErrorQualifierName,
            permissionPage,
        } = this.state;
        return (
            <div className="qualifier-maintain-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text={`Maintain Qualifier`}
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row gutter={16}>
                            <Col sm={14}>
                                <InputHero
                                    name="QualifierId"
                                    label="Qualifier Id"
                                    value={qualifierId}
                                    disabled
                                />
                            </Col>
                            <Col sm={10}></Col>
                            <Col sm={14}>
                                <InputHero
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewErrorQualifierName}
                                    name="qualifierName"
                                    label="Qualifier Name"
                                    value={qualifierName}
                                    onChange={this.handleChangeQualifierName}
                                    placeholderDefault={true}
                                />
                            </Col>
                            <Col sm={10}>
                                <SelectHero
                                    name="qualifierType"
                                    label="Qualifier Type"
                                    options={QualifierTypes}
                                    pleaseChoose={true}
                                    value={qualifierType}
                                    onChange={this.handleChangeQualifierType}
                                />
                            </Col>

                            <Col sm={14}>
                                <TextAreaCustom
                                    name="qualifierDescription"
                                    label="Qualifier Description"
                                    rows={4}
                                    value={qualifierDescription}
                                    onChange={this.handleChangeQualifierDescription}
                                    placeholderDefault={true}
                                />
                            </Col>
                            <Col sm={10}>
                                <RadioCustom
                                    name="QualifierStatus"
                                    label="สถานะ Qualifier"
                                    value={qualifierStatus}
                                    onChange={this.handleChangeQualifierStatus}
                                    options={QualifierStatus}
                                    disabled={qualifierId !== 'Auto' && qualifierStatus === 1 ? !permissionPage.authDelete : !permissionPage.authApprove}
                                />
                            </Col>

                            <Col sm={14}>
                                <Row className="upload-box input-form">
                                    <Col className="label-style">
                                        Upload Excel
                                    </Col>
                                    <Col className="input-box">
                                        <input className="file-input" type="file" id="input" onChange={this.readExcelFile} onClick={e => (e.target.value = null)} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={10}>
                                <Row className="upload-box input-form">
                                    <Col className="label-style">
                                        Download Template
                                    </Col>
                                    <Col className="input-box">
                                        <a href={API_PATH.GET_QUALIFIER_DOWNLOAD_EXCEL_QUALIFIRE+'?QualifierType='+qualifierType} className="download-link">
                                            <Icon type="file-excel" /> Download Format Excel
                                        </a>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                        <div className="table-layout">
                            <TableCustom
                                scroll={{ x: "max-content" }}
                                columns={qualifierType === "product" ? columnsProduct : columnsProductCategory}
                                data={qualifierType === "product" ? dataProductTableList : dataProductCategoryTableList}
                                small loading={loading}
                                rowKey={(row, index) => index}
                            />
                        </div>
                    </div>

                    <div className="bottom-content">
                        <div className="left-zone">
                            <div className="button">
                                <Link
                                    to={ROUTH_PATH.PROMOTION_QUALIFIER_SEARCH}
                                    className="footer-button"
                                >
                                    <ButtonCustom
                                        text="ยกเลิก"
                                        icon="close"
                                        type="danger"
                                    />
                                </Link>
                            </div>
                        </div>


                        <div className="right-zone">
                            <div className="button">
                                <ButtonCustom
                                    onClick={() => this.handleClickSaveQualifier()}
                                    text="บันทึก"
                                    icon="save"
                                    disabled={qualifierId === 'Auto' ? !permissionPage.authCreate : !permissionPage.authUpdate}
                                />
                            </div>
                        </div>
                    </div>

                </Spin>

                <ModalProductSearch
                    isShowModal={isShowModalProduct}
                    handleCloseModal={this.handleCloseModal}
                    onSelected={this.onSelectedProduct}
                    withOutStorageLocation={true}
                    userId={this.props.auth.userId}
                />
                <ModalSaleUnit
                    isShowModal={isShowModalSaleUnit}
                    handleCloseModal={this.handleCloseModal}
                    onSelected={this.onSelectedSaleUnit}
                    productId={saleUnitProductId}
                />
                <ModalProductCategorySearch
                    isShowModal={isShowModalProductCategory}
                    handleCloseModal={this.handleCloseModal}
                    onSelected={this.onSelectedProductCategory}
                />
            </div>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(QualifierMaintainContainer);