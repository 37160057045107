import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './style.css';

import {
  ButtonCustom,
  ModalCustom,
  TitleHead,
  FooterButton
} from "components";

import {
  TabCustom,
  TableCustom,
} from "components/common";

import { CustomerMaintainForm, CustomerShipToForm } from "reduxForms";
import { message, Spin } from "antd";
import { apiServices, API_PATH } from "apiServices";
import { change } from "redux-form";
import { ROUTH_PATH } from '../../../../routes/path';
import { functionAlias } from 'routes';
import swal from '@sweetalert/with-react';
import { get, first, isEmpty } from 'lodash';
import { getPermissionPage, isOutletIdAuth } from 'helpers'

class CustomerMaintainContainer extends Component {
  state = {
    loading: true,
    customertitles: [],
    soldto: {},
    shipto: {},
    creditAllowOptions: [
      { label: "ขายเงินเชื่อ", value: true }
    ],
    customerTypeOptions: [
      { label: "บุคคลธรรมดา", value: "1" },
      { label: "นิติบุคคล", value: "2" }
    ],
    taxBranchOptions: [
      { label: "สำนักงานใหญ่", value: "00000" },
      { label: "สาขาที่", value: "another" }
    ],
    customerStatusOptions: [
      { label: "ปกติ", value: "1" },
      { label: "ระงับ(ออกเอกสารขายและทำธุรกรรมอื่นไม่ได้)", value: "0" }
    ],

    tableShiptoColumns: [
      {
        title: <ButtonCustom text="เพิ่มที่อยู่จัดส่ง" green icon="user-add" type="primary" onClick={() => this.handleClickModal(`เพิ่มที่อยู่จัดส่ง`)} />,
        width: 250,
        dataIndex: 'customerId',
        key: 'customerAction',
        render: (value, full, index) =>
          <div className="button-table-zone">
            {
              full.customerRole && full.customerRole === "ship_to" ?
                <div className="inline">
                  <ButtonCustom text="แก้ไข" icon="edit" yellow onClick={() => this.handleClickModal(`เพิ่มที่อยู่จัดส่ง`, index)} />
                  <ButtonCustom text="ลบ" icon="delete" type="danger" onClick={() => this.handleClickDeleteShipTo(index)} />
                </div>
                :
                <ButtonCustom text="รายละเอียด" icon="edit" type="default" onClick={() => this.handleClickModal(`ข้อมูลที่อยู่จัดส่ง`, index)} />

            }
          </div>
      },
      {
        title: 'ShipTo',
        dataIndex: 'customerId',
        key: 'customerId',
        ellipsis: true,
      },
      {
        title: 'ชื่อ-นามสกุล',
        dataIndex: 'customerNameTH',
        key: 'customerNameTH',
        ellipsis: true,
      },
      {
        title: 'ที่อยู่',
        dataIndex: 'customerAddress',
        key: 'customerAddress',
        ellipsis: true,
      },
      {
        title: 'โทรศัพท์',
        dataIndex: 'customerPhone',
        key: 'customerPhone',
        ellipsis: true,
      }
    ],
    otherBranchViewError: false,
    buttonSaveDisabled: false,
    selectIndexShipTo: undefined,
    customerId: undefined,
    dataDeleteCustomerShipToList: [],
    permissionPage: getPermissionPage(functionAlias.customerMaster),
  };

  componentDidMount() {
    const outletId = get(this.props, 'match.params.outletid', '')
    if (outletId && !isOutletIdAuth(outletId)) {
      this.getAlertAccessDenied()
      return
    }

    this.fetchDataDefault();
    const { match } = this.props;
    //console.log("location===>", location);
    //console.log("match===>", match);
    if (match.params.customerid && match.params.outletid) {
      if (!this.state.permissionPage.authDisplay) {
        this.getAlertAccessDenied()
        return
      }
      this.fetchDataCustomerDetail(match.params.customerid, match.params.outletid);
    }
  }

  getAlertAccessDenied = () => {
    swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
      this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
    })
  }

  fetchDataDefault = async () => {
    const { auth } = this.props
    let paramsOutlet = {
      outletIds: auth.userOutletModelList.map((data) => (
        data.outletId
      ))
    }

    //Call API Dropdown Master Data.
    //ร้านค้า Outlet
    await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet).then(res => {
      let results = get(res, 'data.results');
      let dataOutlet = results.map(data => ({
        label: `${data.outletId} - ${data.outletName}`,
        value: data.outletId,
      }));
      this.setState({
        dataOutlet,
        initialValues: {
          soldToOutlet: dataOutlet[0].value,
          customerStatus: this.state.customerStatusOptions[0].value,
        }
      });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลร้านค้า!')
      swal('Error', message, 'error')
    });

    //คำนำหน้าชื่อ Title
    await apiServices.callApi('get', API_PATH.GET_MASTER_CUSTOMER_TITLE).then(res => {
      let results = get(res, 'data.results');
      let dataCustomerTitles = results.map(data => ({
        label: data.titleDescription,
        value: data.titleId
      }));
      this.setState({ dataCustomerTitles });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลคำนำหน้าชื่อ!')
      swal('Error', message, 'error')
    });

    //ประเทศ Country
    await apiServices.callApi('get', API_PATH.GET_MASTER_REGION_COUNTRY).then(async res => {
      let results = get(res, 'data.results');
      let dataRegionCountries = results.map(data => ({
        label: data.countryName,
        value: data.countryId
      }));
      this.setState({ dataRegionCountries });

      //จังหวัด Province
      await apiServices.callApi("get", API_PATH.GET_MASTER_PROVINCE + `?CountryId=${first(results).countryId}`).then(async res => {
        let results = get(res, 'data.results');
        let dataProvices = results.map(data => ({
          label: data.provinceName,
          value: data.provinceCode
        }));
        this.setState({ dataProvices, dataShiptoProvices: dataProvices });
      }).catch(error => {
        const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลจังหวัด!')
        swal('Error', message, 'error')
      });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลประเทศ!')
      swal('Error', message, 'error')
    });

    //กลุ่มลูกค้า Province
    await apiServices.callApi('get', API_PATH.GET_MASTER_CUSTOMER_GROUP).then(async res => {
      let results = get(res, 'data.results');
      let dataCustomerGroups = results.map(data => ({
        label: data.customerGroupDescription,
        value: data.customerGroupId
      }));
      this.setState({ dataCustomerGroups });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลกลุ่มลูกค้า!')
      swal('Error', message, 'error')
    });

    //ประเภทที่พักอาศัย
    await apiServices.callApi('get', API_PATH.GET_MASTER_CUSTOMER_HOUSE_TYPE).then(async res => {
      let results = get(res, 'data.results');
      let dataCustomerHouseTypes = results.map(data => ({
        label: data.customerHouseTypeDescription,
        value: data.customerHouseTypeId
      }));
      this.setState({ dataCustomerHouseTypes });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลประเภทที่พักอาศัย!')
      swal('Error', message, 'error')
    });

    //ประเภทภาษี
    await apiServices.callApi('get', API_PATH.GET_MASTER_TAX_TYPE).then(async res => {
      let results = get(res, 'data.results');
      let dataTaxs = results.map(data => ({
        label: data.taxDescription,
        value: data.taxTypeId
      }));

      const defaultTax = dataTaxs.find(dataTax => dataTax.value === 'V7')
      this.setState({ 
        dataTaxs,
        initialValues: {
          ...this.state.initialValues,
          soldToTaxType: defaultTax.value,
        }
      });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลประเภทที่พักอาศัย!')
      swal('Error', message, 'error')
    });

    //เงื่อนไขการชำระ
    await apiServices.callApi('get', API_PATH.GET_MASTER_PAYMENT_TERM).then(async res => {
      let results = get(res, 'data.results');
      let dataPaymentTerms = results.map(data => ({
        label: data.paymentTermDescription,
        value: data.paymentTermCode
      }));
      this.setState({ dataPaymentTerms });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลประเภทที่พักอาศัย!')
      swal('Error', message, 'error')
    });

    this.setState({ loading: false });
  };

  fetchDataCustomerDetail = async (customerid, outletid) => {
    this.setState({ loading: true });
    let URLAndParams = `${API_PATH.GET_CUSTOMER}?CustomerId=${customerid}&OutletId=${outletid}&RequireSoldToAndShipTo=true`;
    await apiServices.callApi("get", URLAndParams, '', '' /*auth.token*/).then(async res => {
      let results = get(res, 'data.results');
      let dataCustomerShipList = results.map(data => ({
        ...data,
        key: data.customerId
      }));
      this.setState({ dataCustomerShipList });

      await this.handleChangeSelectProvicne(first(results).customerProvince, false);
      await this.handleChangeDistricts(first(results).customerDistrict, false);
      //console.log("results==>",results)

      const customerTaxBranch = first(results).customerTaxBranch
      const soldToTaxBranch = !customerTaxBranch || customerTaxBranch === '00000' ? customerTaxBranch : 'another'
      // TODO: refactor!!
      let customersoldTo = {
        soldToOutlet: first(results).customerOutletId,
        soldToTitles: first(results).customerTitle,
        soldToSCGId: first(results).customerScgFamilyId,
        soldToName: first(results).customerNameTH,
        soldToCustomerType: first(results).customerType,
        soldToAddress: first(results).customerAddress,
        soldToTaxBranch,
        soldToTaxOtherBranch: first(results).customerTaxBranch,
        soldToTaxType: first(results).customerTaxType,
        soldToTaxId: first(results).customerTaxId,
        soldToPaymentTerm: first(results).customerPaymentTerm,
        soldToCustomerGroup: first(results).customerGroup,
        soldToCountry: first(results).customerCountry,
        soldToEmail: first(results).customerEmail,
        soldToPhone: first(results).customerPhone,
        soldToMobile: first(results).customerMobile,
        soldToLineId: first(results).customerLineId,
        creditAllow: [first(results).customerCreditAllow],
        creditLimit: first(results).customerCreditLimit,
        creditUsage: first(results).customerUsedCredit,
        customerStatus: first(results).customerStatus === true ? "1" : "0",
        soldToProvince: first(results).customerProvince,
        soldToDistrict: first(results).customerDistrict,
        soldToSubDistrict: first(results).customerSubDistrict,
        soldToPostalCode: first(results).customerPostalCode,
        soldToScgFlag: first(results).customerScgFlag,
        creditAvailable: first(results).customerAvailableCredit,
      };

      //console.log("customersoldTo===>", customersoldTo);
      this.setState({ initialValues: customersoldTo, customerId: customerid, buttonSaveDisabled: customersoldTo.soldToScgFlag });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลลูกค้า!')
      swal('Error', message, 'error')
    });

    this.setState({ loading: false });
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
      selectIndexShipTo: undefined
    })
  }

  handleClickSaveShipTo = () => {
    const { CustomerShipToReduxForm: { values, syncErrors }, auth } = this.props;
    console.log(values);
    console.log(auth);
    if (typeof (syncErrors) === 'object') {
      message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
      this.setState({
        submitViewError: true
      });
      return;
    }
    let tempDataCustomerShipList = this.state.dataCustomerShipList;
    let indexShipTo = this.state.selectIndexShipTo;

    if (indexShipTo !== undefined) {

      tempDataCustomerShipList[indexShipTo].customerTitle = values.shipToTitles;
      tempDataCustomerShipList[indexShipTo].customerNameTH = values.shipToName;
      tempDataCustomerShipList[indexShipTo].customerAddress = values.shipToAddress;
      tempDataCustomerShipList[indexShipTo].customerSubDistrict = values.shipToSubDistrict;
      tempDataCustomerShipList[indexShipTo].customerDistrict = values.shipToDistrict;
      tempDataCustomerShipList[indexShipTo].customerProvince = values.shipToProvince;
      tempDataCustomerShipList[indexShipTo].customerCountry = values.shipToCountry ? values.shipToCountry : "TH";
      tempDataCustomerShipList[indexShipTo].customerPostalCode = values.shipToPostalCode;
      tempDataCustomerShipList[indexShipTo].customerPhone = values.shipToPhone;
      tempDataCustomerShipList[indexShipTo].customerHouseType = values.shipToHouseType;
      tempDataCustomerShipList[indexShipTo].customerUpdatedDate = new Date();
      tempDataCustomerShipList[indexShipTo].customerUpdatedBy = auth.userId;
      console.log(`dataCustomerShipList===>${tempDataCustomerShipList}`);
      this.setState({ dataCustomerShipList: tempDataCustomerShipList });

    } else {
      let customerShipTo = {
        key: values.shipToName,
        "customerOutletId": null,
        "customerId": null,
        "customerRole": "ship_to",
        "customerType": null,
        "customerTitle": values.shipToTitles,
        "customerNameTH": values.shipToName,
        "customerNameEN": null,
        "customerTaxId": values.shipToTaxId,
        "customerTaxBranch": values.shipToCustomerType == "1" ? values.shipToTaxBranch : values.shipToTaxOtherBranch,
        "customerScgFamilyId": values.shipToSCGId,
        "customerAddress": values.shipToAddress,
        "customerSubDistrict": values.shipToSubDistrict,
        "customerDistrict": values.shipToDistrict,
        "customerProvince": values.shipToProvince,
        "customerCountry": values.shipToCountry ? values.shipToCountry : "TH",
        "customerPostalCode": values.shipToPostalCode,
        "customerPhone": values.shipToPhone,
        "customerMobile": values.soldToMobile,
        "customerFax": null,
        "customerEmail": null,
        "customerCreditAllow": false,
        "customerCreditLimit": 0,
        "customerUsedCredit": 0,
        "customerStatus": true,
        "customerHouseType": values.shipToHouseType,
        "customerPaymentTerm": null,
        "customerGroup": null,
        "customerGroupDescription": null,
        "customerLineId": null,
        "customerTaxType": null,
        "customerCreatedDate": new Date(),
        "customerCreatedBy": auth.userId,
        "customerUpdatedDate": null,
        "customerUpdatedBy": null
      };
      if (!tempDataCustomerShipList) {
        tempDataCustomerShipList = [];
      }
      tempDataCustomerShipList.push(customerShipTo);
      this.setState({ dataCustomerShipList: tempDataCustomerShipList });
      //dispatch(reset('myForm'));
    }

    this.handleCloseModal()
  }

  handleClickSaveCustomer = async () => {
    let customers = [];
    this.setState({ loading: true });
    try {
      const { CustomerMaintainReduxForm: { values, syncErrors }, auth } = this.props;
      const { customerId, dataDeleteCustomerShipToList } = this.state;

      if (typeof (syncErrors) === 'object') {
        message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
        this.setState({
          submitViewError: true,
          loading: false
        });
        return;
      }

      // note: remove because validated from redux form
      /*
      //Additional validation
      if (values.soldToTaxBranch === "another" && !values.soldToTaxOtherBranch) {
        message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
        this.setState({
          otherBranchViewError: true,
          loading: false
        });
        return;
      }
      */

      let customerSoldTo = {
        "customerOutletId": values.soldToOutlet,
        "customerId": customerId,
        "customerRole": "sold_to",
        "customerType": values.soldToCustomerType,
        "customerTitle": values.soldToTitles,
        "customerNameTH": values.soldToName,
        "customerNameEN": "",
        "customerTaxId": values.soldToTaxId,
        "customerTaxBranch": values.soldToTaxBranch === "another" ? values.soldToTaxOtherBranch : values.soldToTaxBranch,
        "customerScgFamilyId": values.soldToSCGId,
        "customerAddress": values.soldToAddress,
        "customerSubDistrict": values.soldToSubDistrict,
        "customerDistrict": values.soldToDistrict,
        "customerProvince": values.soldToProvince,
        "customerCountry": values.soldToCountry ? values.soldToCountry : "TH",
        "customerPostalCode": values.soldToPostalCode,
        "customerPhone": values.soldToPhone,
        "customerMobile": values.soldToMobile,
        "customerFax": "",
        "customerEmail": values.soldToEmail,
        "customerCreditAllow": values.creditAllow && values.creditAllow.length > 0 && values.creditAllow[0],
        "customerCreditLimit": values.creditLimit,
        "customerUsedCredit": values.creditUsage,
        "customerStatus": values.customerStatus === "1" ? true : false,
        "customerHouseType": "",
        "customerPaymentTerm": values.soldToPaymentTerm,
        "customerGroup": values.soldToCustomerGroup,
        "customerGroupDescription": "",
        "customerLineId": values.soldToLineId,
        "customerTaxType": values.soldToTaxType,
        "customerCreatedDate": customerId !== undefined ? null : new Date(),
        "customerCreatedBy": customerId !== undefined ? null : auth.userId,
        "customerUpdatedDate": customerId !== undefined ? new Date() : null,
        "customerUpdatedBy": customerId !== undefined ? auth.userId : null,
      };
      //console.log("customerSoldTo==>", customerSoldTo);
      customers.push(customerSoldTo);
      if (this.state.dataCustomerShipList && this.state.dataCustomerShipList.length > 0) {
        this.state.dataCustomerShipList.forEach(shipto => {
          shipto.customerOutletId = values.soldToOutlet;
          if (shipto.customerRole === "ship_to") {
            if (!shipto.customerUpdatedBy) {
              shipto.customerUpdatedDate = null;
            }
            customers.push(shipto);
          }
        });
      }

      if (dataDeleteCustomerShipToList && dataDeleteCustomerShipToList > 0) {
        dataDeleteCustomerShipToList.forEach(shipto => {
          shipto.customerOutletId = values.soldToOutlet;
          customers.push(shipto);
        });
      }
      //console.log(`Json string CREATE_OR_UPDATE_CUSTOMER Request ==>${JSON.stringify(customers)}`)
      await apiServices.callApi(customerId !== undefined ? 'put' : 'post', customerId !== undefined ? API_PATH.PUT_CUSTOMER : API_PATH.POST_CUSTOMER, customers).then(async res => {
        //console.log(`Json string CREATE_OR_UPDATE_CUSTOMER Responses ==>${JSON.stringify(res)}`)
        let results = get(res, 'data.results');
        let message = get(res, 'data.message');
        swal("Success", `${customerId !== undefined ? 'แก้ไข' : 'สร้าง'}ข้อมูลลูกค้า : ${first(results).customerId} ${message}`, "success");
        this.props.history.push({
          pathname: ROUTH_PATH.CUSTOMER_UPDATE.replace(":customerid", `${first(results).customerId}`).replace(":outletid", `${first(results).customerOutletId}`),
          state: first(results).customerId
        });
        await this.fetchDataCustomerDetail(first(results).customerId, first(results).customerOutletId);
      }).catch(error => {
        const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูลลูกค้า!')
        swal('Error', message, 'error')
      });

    } catch (e) {
      console.log('error', e);
    }
    this.setState({ loading: false });
  }

  handleClickDeleteShipTo = (index) => {
    const { auth } = this.props;
    let { dataDeleteCustomerShipToList, dataCustomerShipList } = this.state;
    dataCustomerShipList[index].customerStatus = false;
    dataCustomerShipList[index].customerUpdatedDate = new Date();
    dataCustomerShipList[index].customerUpdatedBy = auth.userId;

    dataCustomerShipList.splice(index, 1);

    dataDeleteCustomerShipToList.push({ ...dataCustomerShipList[index] });
    this.setState({ dataDeleteCustomerShipToList, dataCustomerShipList });
  }
  handleClickModal = async (title, index) => {
    let shipto = {};
    if (index !== undefined) {
      shipto = await this.setModalShipto(index);
    }

    this.setState({
      isShowModal: true,
      titleModal: title,
      widthModal: '900px',
      selectIndexShipTo: index,
      footerModal: <div className="modal-footer-button">
        <div className="footer-button">
          <ButtonCustom onClick={() => this.handleClickSaveShipTo()} disabled={shipto.customerRole === "sold_to"} text="บันทึก" icon="save" />
        </div>
        <div className="footer-button">
          <ButtonCustom onClick={() => this.handleCloseModal()} text="ยกเลิก" type="danger" />
        </div>
      </div>,
    });
  }

  setModalShipto = async (index) => {
    let shipto = this.state.dataCustomerShipList[index];
    console.log(`shipto===>`, shipto);

    await this.handleChangeSelectShipToProvicne(shipto.customerProvince);
    await this.handleChangeSelectShipToDistrict(shipto.customerDistrict);

    let initialValuesShipto = {
      shipToId: shipto.customerId,
      shipToTitles: shipto.customerTitle,
      shipToName: shipto.customerNameTH,
      shipToAddress: shipto.customerAddress,
      shipToPostalCode: shipto.customerPostalCode,
      shipToSubDistrict: shipto.customerSubDistrict,
      shipToDistrict: shipto.customerDistrict,
      shipToProvince: shipto.customerProvince,
      shipToCountry: shipto.customerCountry,
      shipToHouseType: shipto.customerHouseType,
      shipToPhone: shipto.customerPhone,
    };

    this.setState({ initialValuesShipto });

    return shipto;
  }

  handleChangeSelectProvicne = async (value, isResetValue = true) => {
    this.setState({ loading: true });
    const { setDistrict, setSubDistrict, setPortalCode } = this.props

    //เขต/อำเภอ
    await apiServices.callApi('get', API_PATH.GET_MASTER_DISTRICTS + `?ProvinceCode=${value}`).then(async res => {
      let results = get(res, 'data.results');
      let dataDistricts = results.map(e => ({
        label: e.district,
        value: e.district,
      }));
      this.setState({ dataDistricts });
      if (isResetValue) {
        setDistrict(null);
        setSubDistrict(null);
        setPortalCode(null);
      }
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "เขต/อำเภอ"!')
      swal('Error', message, 'error')
    });

    this.setState({ loading: false });
  }

  handleChangeDistricts = async (value, isResetValue = true) => {
    this.setState({ loading: true });
    const { setSubDistrict, setPortalCode } = this.props

    //แขวง/ตำบล
    await apiServices.callApi('get', API_PATH.GET_MASTER_SUB_DISTRICTS + `?District=${value}`).then(async res => {
      let results = get(res, 'data.results');
      let dataSubDistricts = results.map(e => ({
        ...e,
        label: e.subDistrict,
        value: e.subDistrict,
      }));
      this.setState({ dataSubDistricts });
      if (isResetValue) {
        setSubDistrict(null);
      }
      setPortalCode(first(results).postalCode);
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "แขวง/ตำบล"!')
      swal('Error', message, 'error')
    });

    this.setState({ loading: false });
  }

  handleChangeSubDistricts = async (value) => {
    this.setState({ loading: true });
    const { setSubDistrict, setPortalCode } = this.props
    const { dataSubDistricts } = this.state
    const result = dataSubDistricts.find(sundis => sundis.value === value)
    
    if(!isEmpty(result)){
      setPortalCode(result.postalCode)
    }

    this.setState({ loading: false });
  }

  handleSubmitPostalCode = async (event) => {
    const postalCode = event.target.value
    if (!postalCode) return

    this.setState({ loading: true })
    const url = `${API_PATH.GET_MASTER_SUB_DISTRICTS}?PostalCode=${postalCode}`
    await apiServices.callApi('get', url).then(async res => {
      const list = get(res, 'data.results', []);
      const data = list[0]
      if (data) {
        let dataSubDistricts = list.map(address => ({
          label: address.subDistrict,
          value: address.subDistrict,
        }));
        this.setState({ dataSubDistricts });
        this.props.setProvince(data.provinceCode);
        this.props.setDistrict(data.district);
        this.props.setSubDistrict(data.subDistrict);
        this.handleChangeSelectProvicne(data.provinceCode, false)
      }
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "แขวง/ตำบล"!')
      swal('Error', message, 'error')
    });
    this.setState({ loading: false });
  }

  handleChangeSelectShipToProvicne = async (value) => {
    this.setState({ loading: true });
    const { setDistrictShipto, setSubDistrictShipto, setPortalCodeShipto } = this.props

    //เขต/อำเภอ
    await apiServices.callApi('get', API_PATH.GET_MASTER_DISTRICTS + `?ProvinceCode=${value}`).then(async res => {
      let results = get(res, 'data.results');
      let dataShiptoDistricts = results.map(e => ({
        label: e.district,
        value: e.district,
      }));
      this.setState({ dataShiptoDistricts });
      setDistrictShipto(null);
      setSubDistrictShipto(null);
      setPortalCodeShipto(null);
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "เขต/อำเภอ"!')
      swal('Error', message, 'error')
    });
    this.setState({ loading: false });
  }

  handleChangeSelectShipToDistrict = async (value) => {
    this.setState({ loading: true });
    const { setSubDistrictShipto, setPortalCodeShipto } = this.props

    // แขวง/ตำบล
    await apiServices.callApi('get', API_PATH.GET_MASTER_SUB_DISTRICTS + `?District=${value}`).then(async res => {
      let results = get(res, 'data.results');
      let dataShiptoSubDistricts = results.map(e => ({
        ...e,
        label: e.subDistrict,
        value: e.subDistrict,
      }));
      this.setState({ dataShiptoSubDistricts });
      setSubDistrictShipto(null);
      setPortalCodeShipto(first(results).postalCode);
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "แขวง/ตำบล"!')
      swal('Error', message, 'error')
    });

    this.setState({ loading: false });
  }

  handleChangeSelectShipToSubDistrict = async (value) => {
    this.setState({ loading: true });
    const { setPortalCodeShipto } = this.props
    const { dataShiptoSubDistricts } = this.state

    const result = dataShiptoSubDistricts.find(subdis => subdis.value === value)
    if(!isEmpty(result)){
      setPortalCodeShipto(result.postalCode);
    }

    this.setState({ loading: false });
  }

  render() {
    const {
      loading,
      submitViewError,
      otherBranchViewError,
      initialValues,
      initialValuesShipto,
      creditAllowOptions,
      customerTypeOptions,
      taxBranchOptions,
      customerStatusOptions,
      tableColumns,
      isShowModal,
      titleModal,
      footerModal,
      heightModal,
      widthModal,
      dataOutlet,
      dataRegionCountries,
      dataProvices,
      dataDistricts,
      dataSubDistricts,
      dataCustomerTitles,
      dataCustomerGroups,
      dataCustomerHouseTypes,
      dataTaxs,
      dataPaymentTerms,
      buttonSaveDisabled,
      permissionPage,
      customerId,

      /* Shipto */
      tableShiptoColumns,
      dataShiptoProvices,
      dataShiptoDistricts,
      dataShiptoSubDistricts,
      dataCustomerShipList
    } = this.state
    return (
      <div className="customer-master-container">
        <Spin spinning={loading} style={{ height: '100%' }}>
          <TitleHead text="จัดการข้อมูลลูกค้า" icon="search" />

          <TabCustom
            tabContent={
              [
                {
                  titleTab: 'ข้อมูลทั่วไป',
                  nodeContent:
                    <CustomerMaintainForm
                      loading={loading}
                      submitViewError={submitViewError}
                      otherBranchViewError={otherBranchViewError}
                      creditAllowOptions={creditAllowOptions}
                      customerTypeOptions={customerTypeOptions}
                      taxBranchOptions={taxBranchOptions}
                      customerStatusOptions={customerStatusOptions}
                      tableColumns={tableColumns}
                      dataOutlet={dataOutlet}
                      dataCustomerTitles={dataCustomerTitles}
                      dataCustomerGroups={dataCustomerGroups}
                      dataTaxs={dataTaxs}
                      dataPaymentTerms={dataPaymentTerms}

                      dataRegionCountries={dataRegionCountries}
                      dataProvices={dataProvices}
                      dataDistricts={dataDistricts}
                      dataSubDistrict={dataSubDistricts}

                      handleChangeSelectProvicne={this.handleChangeSelectProvicne}
                      handleChangeDistricts={this.handleChangeDistricts}
                      handleChangeSubDistricts={this.handleChangeSubDistricts}
                      handleSubmitPostalCode={this.handleSubmitPostalCode}

                      initialValues={initialValues}
                      permissionPage={permissionPage}
                    />
                },
                {
                  titleTab: 'ที่อยู่จัดส่ง',
                  nodeContent:
                    <div>
                      <TableCustom height="calc(100vh - 414px)" columns={tableShiptoColumns} data={dataCustomerShipList} /*loading={this.state.loading}*/ />
                    </div>
                }
              ]
            }
          />

          <FooterButton
            nodeLeft={
              [
                {
                  button: <Link to={ROUTH_PATH.CUSTOMER} className="footer-button">
                    <ButtonCustom text="ยกเลิก" icon="close" type="danger" />
                  </Link>
                }
              ]
            }
            nodeRight={
              [
                {
                  button: <ButtonCustom
                    disabled={buttonSaveDisabled || (customerId ? !permissionPage.authUpdate : !permissionPage.authCreate)}
                    onClick={() => this.handleClickSaveCustomer()}
                    text="บันทึก"
                    icon="save"
                  />
                },
              ]
            }
          />

          <ModalCustom
            isShowModal={isShowModal}
            handleCloseModal={this.handleCloseModal}
            title={titleModal}
            width={widthModal}
            height={heightModal}
            footer={footerModal}
          >
            <CustomerShipToForm
              loading={loading}
              submitViewError={submitViewError}
              dataCustomerTitles={dataCustomerTitles}
              dataCustomerHouseTypes={dataCustomerHouseTypes}
              dataRegionCountries={dataRegionCountries}
              dataShiptoProvices={dataShiptoProvices}
              dataShiptoDistricts={dataShiptoDistricts}
              dataShiptoSubDistricts={dataShiptoSubDistricts}

              handleChangeSelectShipToProvicne={this.handleChangeSelectShipToProvicne}
              handleChangeSelectShipToDistrict={this.handleChangeSelectShipToDistrict}
              handleChangeSelectShipToSubDistrict={this.handleChangeSelectShipToSubDistrict}

              initialValues={initialValuesShipto}
            />
          </ModalCustom>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log("state===>", state);
  return {
    auth: state.auth,
    CustomerMaintainReduxForm: state.form.CustomerMaintainForm,
    CustomerShipToReduxForm: state.form.CustomerShipToForm
  };
};

const mapDispatchToProps = (dispatch) => ({
  setProvince: (data) => dispatch(change(`CustomerMaintainForm`, `soldToProvince`, data)),
  setDistrict: (data) => dispatch(change(`CustomerMaintainForm`, `soldToDistrict`, data)),
  setSubDistrict: (data) => dispatch(change(`CustomerMaintainForm`, `soldToSubDistrict`, data)),
  setPortalCode: (data) => dispatch(change(`CustomerMaintainForm`, `soldToPostalCode`, data)),

  setDistrictShipto: (data) => dispatch(change(`CustomerMaintainForm`, `shipToDistrict`, data)),
  setSubDistrictShipto: (data) => dispatch(change(`CustomerMaintainForm`, `shipToSubDistrict`, data)),
  setPortalCodeShipto: (data) => dispatch(change(`CustomerShipToForm`, `shipToPostalCode`, data)),
});

/*const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
      authData
  }, 
    dispatch
  )
};*/

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMaintainContainer);