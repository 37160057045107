import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Typography, Row, Col, Icon } from 'antd'
import './style.css'
import { Link } from 'react-router-dom'
import { authData } from 'actions/actionsCreator'
import { CardCustom } from 'components/common'
import { ROUTH_PATH, functionAlias, dashboardRoute } from 'routes'
import { formatNumber, saleTypes, getPermissionPage, isOutletIdAuth, sortDataColumn } from 'helpers'

const { Title } = Typography

class HomeContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuList: this.getMenuList(),
        }
    }

    getMenuList = () => {
        const { auth: { userPermissionModelList } } = this.props

        const menuList = [
            {
                title: 'ใบสั่งขาย',
                subTitle: 'สร้างใบสั่งขาย',
                url: ROUTH_PATH.SALE_ORDER_CREATE,
                icon: 'shopping-cart',
                color: '#00a65a',
                functionAlias: functionAlias.saleOrderMaintain,
            },
            {
                title: 'สินค้า',
                subTitle: 'ค้นหาสินค้า',
                url: ROUTH_PATH.PRODUCT,
                icon: 'database',
                color: '#0073b7',
                functionAlias: functionAlias.productList,
            },
            {
                title: 'ลูกค้า',
                subTitle: 'จัดการลูกค้า',
                url: ROUTH_PATH.CUSTOMER,
                icon: 'user',
                color: '#f39c12',
                functionAlias: functionAlias.customerMaster,
            },
            {
                title: 'สถานะใบสั่งขาย',
                subTitle: 'สถานะใบสั่งขาย',
                url: ROUTH_PATH.TRACKING_SALE_ORDER,
                icon: 'file-text',
                color: '#dd4b39',
                functionAlias: functionAlias.reportSaleOrder,
            },
            {
                title: 'การขนส่ง/จัดเที่ยวรถ',
                subTitle: 'การขนส่ง/จัดเที่ยวรถ',
                url: ROUTH_PATH.SHIPMENT_SEARCH,
                icon: 'car',
                color: '#ff851b',
                functionAlias: functionAlias.shipmentMaintain,
            },
            {
                title: 'ตรวจนับสินค้า',
                subTitle: 'ตรวจนับสต็อค',
                url: ROUTH_PATH.INVENTORY_COUNT_RESULT,
                icon: 'inbox',
                color: '#605ca8',
                functionAlias: functionAlias.inventoryCountStock,
            },
            {
                title: 'รับชำระเงิน',
                subTitle: 'รับชำระเงิน',
                url: ROUTH_PATH.PAYMENT,
                icon: 'credit-card',
                color: '#ab1c7d',
                functionAlias: functionAlias.paymentMaintain,
            },
        ]

        // check permission menu
        return menuList.filter(menu => (userPermissionModelList.find(userPermission =>
            (userPermission.functionAlias === menu.functionAlias && userPermission.authMenu)
        )))
    }

    render() {
        const { menuList } = this.state

        return (
            <div className="home-container">
                <Title level={3} >Welcome to OOM</Title >
                <div>Outlet Operation Management System</div>
                <div className="middle-content">
                    <Row gutter={[20, 20]}>
                        {menuList.map((menu, index) => (<Col xs={12} sm={6} key={index}>
                            <Link to={{ pathname: menu.url, state: 'selectedKeys' }}>
                                <CardCustom className="menu-item" style={{ background: menu.color }}>
                                    <div className="menu-content">
                                        <Icon type={menu.icon} className="menu-content-icon" style={{ color: menu.color }} />
                                        <div style={{ fontSize: '16px', paddingTop: '10px' }} className="font-weight-bold">{menu.title}</div>
                                        <div style={{ fontSize: '13px' }}>{menu.subTitle}</div>
                                    </div>
                                </CardCustom>
                            </Link>
                        </Col>))}
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)