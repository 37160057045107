import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.css'
import { ButtonCustom, TitleHead, FooterButton } from "components"
import { SaleEmployeerMaintainForm } from "reduxForms"
import { message, Spin } from "antd"
import { apiServices, API_PATH } from "apiServices"
import { change } from "redux-form"
import { Link } from 'react-router-dom';
import { ROUTH_PATH } from '../../../../routes/path';
import { functionAlias } from 'routes'
import swal from '@sweetalert/with-react';
import { get } from 'lodash';
import { getPermissionPage, isOutletIdAuth } from 'helpers'

class SaleEmployeeMaintainContainer extends Component {
  state = {
    permissionPage: getPermissionPage(functionAlias.employeeMaintain),
    loading: true,
    customertitles: [],
    taxBranchOptions: [{ label: "สำนักงานใหญ่", value: "00000" },
    { label: "สาขาที่", value: "another" }],
    customerStatusOptions: [{ label: "ปกติ", value: "1" },
    { label: "ระงับ(ออกเอกสารขายและทำธุรกรรมอื่นไม่ได้)", value: "0" }],
    otherBranchViewError: false,
    selectIndexShipTo: undefined,
    saleEmployeeId: undefined,
    saleEmployee: {},
    dataDeleteSaleEmployeerShipToList: [],
  };

  componentDidMount() {
    const outletId = get(this.props, 'match.params.outletid')
    if (outletId && !isOutletIdAuth(outletId)) {
        this.getAlertAccessDenied()
        return
    }

    this.fetchDataDefault();
    const { location, match } = this.props;
    console.log("location===>", location);
    console.log("match===>", match);
    if (match.params.id && outletId) {
      if (!this.state.permissionPage.authDisplay) {
        this.getAlertAccessDenied()
        return
      }

      this.fetchDataSaleEmployeerDetail(match.params.id, match.params.outletid);
    }
  }

  getAlertAccessDenied = () => {
    swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
      this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
    })
  }

  fetchDataDefault = async () => {
    const { auth, setCountry } = this.props
    let paramsOutlet = {
      outletIds: auth.userOutletModelList.map((data) => (
        data.outletId
      ))
    }

    setCountry("TH");
    // defualt dropdown of SaleEmployeerForm
    let resOutlet = await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet);
    let resCustomerTitle = await apiServices.callApi('get', API_PATH.GET_MASTER_CUSTOMER_TITLE);
    let resRegionCountry = await apiServices.callApi('get', API_PATH.GET_MASTER_REGION_COUNTRY);
    let resSaleEmployeerGroup = await apiServices.callApi('get', API_PATH.GET_MASTER_CUSTOMER_GROUP);
    let resSaleEmployeerHouseType = await apiServices.callApi('get', API_PATH.GET_MASTER_CUSTOMER_HOUSE_TYPE);
    let resTax = await apiServices.callApi('get', API_PATH.GET_MASTER_TAX_TYPE);
    let resPaymentTerm = await apiServices.callApi('get', API_PATH.GET_MASTER_PAYMENT_TERM);
    let resChannel = await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL);

    /*console.log("resOutlet ===>",resOutlet);
    console.log("resCustomerTitle ===>",resCustomerTitle);
    console.log("resRegionCountry ===>",resRegionCountry);
    console.log("resSaleEmployeerGroup ===>",resSaleEmployeerGroup);
    console.log("resSaleEmployeerHouseType ===>",resSaleEmployeerHouseType);
    console.log("resTax ===>",resTax);
    console.log("resPaymentTerm ===>",resPaymentTerm);
    console.log("resChannel ===>",resChannel);*/

    if (resOutlet.status === 200) {
      let dataOutlet = resOutlet.data.results.map(data => ({ label: data.outletId + " " + "-" + " " + data.outletName, value: data.outletId, }));
      this.setState({ dataOutlet });
    }

    if (resCustomerTitle.status === 200) {
      let dataCustomerTitles = resCustomerTitle.data.results.map(data => ({ label: data.titleDescription, value: data.titleId }));
      this.setState({ dataCustomerTitles });
    }

    if (resRegionCountry.status === 200) {
      let dataRegionCountries = resRegionCountry.data.results.map(data => ({ label: data.countryName, value: data.countryId }));
      this.setState({ dataRegionCountries });

      let resProvince = await apiServices.callApi("get", API_PATH.GET_MASTER_PROVINCE + `?CountryId=${resRegionCountry.data.results[0].countryId}`);
      //console.log("resProvince ===>",resProvince);
      if (resProvince.status === 200) {
        let dataProvices = resProvince.data.results.map(data => ({ label: data.provinceName, value: data.provinceCode }));
        this.setState({ dataProvices, dataShiptoProvices: dataProvices });
      }
    }

    if (resSaleEmployeerGroup.status === 200) {
      let dataSaleEmployeerGroups = resSaleEmployeerGroup.data.results.map(data => ({ label: data.customerGroupDescription, value: data.customerGroupId }));
      this.setState({ dataSaleEmployeerGroups });
    }

    if (resSaleEmployeerHouseType.status === 200) {
      let dataSaleEmployeerHouseTypes = resSaleEmployeerHouseType.data.results.map(data => ({ label: data.customerHouseTypeDescription, value: data.customerHouseTypeId }));
      this.setState({ dataSaleEmployeerHouseTypes });
    }

    if (resTax.status === 200) {
      let dataTaxs = resTax.data.results.map(data => ({ label: data.taxDescription, value: data.taxTypeId }));
      this.setState({ dataTaxs });
    }

    if (resPaymentTerm.status === 200) {
      let dataPaymentTerms = resPaymentTerm.data.results.map(data => ({ label: data.paymentTermDescription, value: data.paymentTermCode }));
      this.setState({ dataPaymentTerms });
    }

    if (resChannel.status === 200) {
      let dataChannels = resChannel.data.results.map(data => ({ label: data.saleChannelDescription, text: `${data.saleChannelCode}: ${data.saleChannelDescription}`, value: data.saleChannelCode }));
      //console.log("dataChannels ===>",dataChannels);
      this.setState({ dataChannels });
    }
    this.setState({ loading: false });
  };

  fetchDataSaleEmployeerDetail = async (id, outletid) => {
    this.setState({ loading: true });
    let { initialValues } = this.state;
    let URLAndParams = `${API_PATH.GET_SALE_EMPLOYEE}?OutletId=${outletid}&SaleEmployeeId=${id}`;
    let res = await apiServices.callApi("get", URLAndParams, '', '');
    //console.log("resSaleEmployeerDetail===>", res);
    if (res.status === 200 && res.data.results.length > 0) {
      await this.handleChangeSelectProvicne(res.data.results[0].saleEmployeeProvince);
      await this.handleChangeDistricts(res.data.results[0].saleEmployeeDistrict);

      initialValues = {
        ...initialValues,
        ...res.data.results[0],
        saleEmployeeChannelCode: res.data.results[0].saleEmployeeChannelCode.split(',')
      };
      this.setState({ initialValues, saleEmployeeId: id });
      //console.log("initialValues===>", initialValues);
      this.setState({ initialValues });
    }
    this.setState({ loading: false });
  };

  handleCloseModal = () => {
    this.setState({
      selectIndexShipTo: undefined
    })
  }

  handleClickSaveShipTo = () => {
    const { SaleEmployeerShipToReduxForm: { values, syncErrors }, auth } = this.props;
    console.log(values);
    console.log(auth);
    if (typeof (syncErrors) === 'object') {
      message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
      this.setState({
        submitViewError: true
      });
      return;
    }

    let tempDataSaleEmployeerShipList = this.state.dataSaleEmployeerShipList;
    let indexShipTo = this.state.selectIndexShipTo;

    if (indexShipTo !== undefined) {

      tempDataSaleEmployeerShipList[indexShipTo].customerTitle = values.shipToTitles;
      tempDataSaleEmployeerShipList[indexShipTo].customerNameTH = values.shipToName;
      tempDataSaleEmployeerShipList[indexShipTo].customerAddress = values.shipToAddress;
      tempDataSaleEmployeerShipList[indexShipTo].customerSubDistrict = values.shipToSubDistrict;
      tempDataSaleEmployeerShipList[indexShipTo].customerDistrict = values.shipToDistrict;
      tempDataSaleEmployeerShipList[indexShipTo].customerProvince = values.shipToProvince;
      tempDataSaleEmployeerShipList[indexShipTo].customerCountry = values.shipToCountry ? values.shipToCountry : "TH";
      tempDataSaleEmployeerShipList[indexShipTo].customerPostalCode = values.shipToPostalCode;
      tempDataSaleEmployeerShipList[indexShipTo].customerPhone = values.shipToPhone;
      tempDataSaleEmployeerShipList[indexShipTo].customerHouseType = values.shipToHouseType;
      tempDataSaleEmployeerShipList[indexShipTo].customerUpdatedDate = new Date();
      tempDataSaleEmployeerShipList[indexShipTo].customerUpdatedBy = auth.userId;
      console.log(`dataSaleEmployeerShipList===>${tempDataSaleEmployeerShipList}`);
      this.setState({ dataSaleEmployeerShipList: tempDataSaleEmployeerShipList });

    } else {
      let customerShipTo = {
        key: values.shipToName,
        "customerOutletId": null,
        "customerId": null,
        "customerRole": "ship_to",
        "customerType": null,
        "customerTitle": values.shipToTitles,
        "customerNameTH": values.shipToName,
        "customerNameEN": null,
        "customerTaxId": values.shipToTaxId,
        "customerTaxBranch": values.shipToSaleEmployeerType == "1" ? values.shipToTaxBranch : values.shipToTaxOtherBranch,
        "customerScgFamilyId": values.shipToSCGId,
        "customerAddress": values.shipToAddress,
        "customerSubDistrict": values.shipToSubDistrict,
        "customerDistrict": values.shipToDistrict,
        "customerProvince": values.shipToProvince,
        "customerCountry": values.shipToCountry ? values.shipToCountry : "TH",
        "customerPostalCode": values.shipToPostalCode,
        "customerPhone": values.shipToPhone,
        "customerFax": null,
        "customerEmail": null,
        "customerCreditAllow": false,
        "customerCreditLimit": 0,
        "customerUsedCredit": 0,
        "customerStatus": true,
        "customerHouseType": values.shipToHouseType,
        "customerPaymentTerm": null,
        "customerGroup": null,
        "customerGroupDescription": null,
        "customerLineId": null,
        "customerTaxType": null,
        "customerCreatedDate": new Date(),
        "customerCreatedBy": auth.userId,
        "customerUpdatedDate": null,
        "customerUpdatedBy": null
      };
      if (!tempDataSaleEmployeerShipList) {
        tempDataSaleEmployeerShipList = [];
      }
      tempDataSaleEmployeerShipList.push(customerShipTo);
      this.setState({ dataSaleEmployeerShipList: tempDataSaleEmployeerShipList });
    }

    this.handleCloseModal()
  }

  handleClickSaveSaleEmployeer = async () => {
    let saleEmploy = [];
    let resCreateOrUpdateSaleEmployeer = {};
    this.setState({ loading: true });

    try {
      const { SaleEmployeerMaintainReduxForm: { values, syncErrors }, auth } = this.props;
      const { saleEmployeeId } = this.state;
      //console.log("values===>", values);
      //console.log("auth===>", auth);

      if (typeof (syncErrors) === 'object') {
        message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
        this.setState({
          submitViewError: true,
          loading: false
        });
        return;
      }

      //Additional validation
      if (values.soldToTaxBranch === "another" && !values.soldToTaxOtherBranch) {
        message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
        this.setState({
          otherBranchViewError: true,
          loading: false
        });
        return;
      }

      if (saleEmployeeId && saleEmployeeId > 0) {
        saleEmploy = { ...values, saleEmployeeChannelCode: values.saleEmployeeChannelCode.join(',') };
        console.log(`saleEmploy===>`, JSON.stringify(saleEmploy));
        resCreateOrUpdateSaleEmployeer = await apiServices.callApi('put', API_PATH.PUT_SALE_EMPLOYEE, saleEmploy);
      } else {
        saleEmploy = { ...values, saleEmployeeCreatedDate: new Date(), saleEmployeeCreatedBy: auth.userId, saleEmployeeChannelCode: values.saleEmployeeChannelCode.join(',') };
        console.log(`saleEmploy===>`, JSON.stringify(saleEmploy));
        resCreateOrUpdateSaleEmployeer = await apiServices.callApi('post', API_PATH.POST_SALE_EMPLOYEE, saleEmploy);
      }
      console.log(`resCreateOrUpdateSaleEmployeer===>`, resCreateOrUpdateSaleEmployeer);

      if (resCreateOrUpdateSaleEmployeer.status === 200 && resCreateOrUpdateSaleEmployeer.data.results && resCreateOrUpdateSaleEmployeer.data.results.length > 0) {
        let saleEmployees = get(resCreateOrUpdateSaleEmployeer, "data.results");
        swal("Success", `${saleEmployeeId && saleEmployeeId > 0 ? 'แก้ไข' : 'สร้าง'}ข้อมูลพนักงาน : ${saleEmployees[0].saleEmployeeId} ${resCreateOrUpdateSaleEmployeer.data.message}`, "success");
        this.props.history.push({
          pathname: ROUTH_PATH.SALEEMPLOYEE_UPDATE.replace(":id", `${saleEmployees[0].saleEmployeeId}`).replace(":outletid", `${saleEmployees[0].saleEmployeeOutletId}`),
        });
        await this.fetchDataSaleEmployeerDetail(saleEmployees[0].saleEmployeeId);
      }
      else {
        swal("error", `${saleEmployeeId && saleEmployeeId > 0 ? 'แก้ไข' : 'สร้าง'}ข้อมูลพนักงาน : ${resCreateOrUpdateSaleEmployeer.data.message}`, "error");
      }
    } catch (e) {
      console.log('error', e);
    }
    this.setState({ loading: false });
  }
  handleClickDeleteShipTo = (index) => {
    const { auth } = this.props;
    let { dataDeleteSaleEmployeerShipToList, dataSaleEmployeerShipList } = this.state;
    dataSaleEmployeerShipList[index].customerStatus = false;
    dataSaleEmployeerShipList[index].customerUpdatedDate = new Date();
    dataSaleEmployeerShipList[index].customerUpdatedBy = auth.userId;

    dataSaleEmployeerShipList.splice(index, 1);

    dataDeleteSaleEmployeerShipToList.push({ ...dataSaleEmployeerShipList[index] });
    this.setState({ dataDeleteSaleEmployeerShipToList, dataSaleEmployeerShipList });
  }
  handleClickModal = async (title, index) => {
    let shipto = {};
    if (index !== undefined) {
      shipto = await this.setModalShipto(index);
    }

    this.setState({
      titleModal: title,
      widthModal: '900px',
      selectIndexShipTo: index,
      footerModal: <div className="modal-footer-button">
        <div className="footer-button">
          <ButtonCustom onClick={() => this.handleClickSaveShipTo()} disabled={shipto.customerRole === "sold_to"} text="บันทึก" icon="save" />
        </div>
        <div className="footer-button">
          <ButtonCustom onClick={() => this.handleCloseModal()} text="ยกเลิก" type="danger" />
        </div>
      </div>,
    });
  }

  setModalShipto = async (index) => {
    let shipto = this.state.dataSaleEmployeerShipList[index];
    console.log(`shipto===>`, shipto);

    await this.handleChangeSelectShipToProvicne(shipto.customerProvince);
    await this.handleChangeSelectShipToDistrict(shipto.customerDistrict);

    let initialValuesShipto = {
      shipToId: shipto.customerId,
      shipToTitles: shipto.customerTitle,
      shipToName: shipto.customerNameTH,
      shipToAddress: shipto.customerAddress,
      shipToPostalCode: shipto.customerPostalCode,
      shipToSubDistrict: shipto.customerSubDistrict,
      shipToDistrict: shipto.customerDistrict,
      shipToProvince: shipto.customerProvince,
      shipToCountry: shipto.customerCountry,
      shipToHouseType: shipto.customerHouseType,
      shipToPhone: shipto.customerPhone,
    };

    this.setState({ initialValuesShipto });

    return shipto;
  }

  handleChangeSelectProvicne = async (value) => {
    this.setState({ loading: true });
    const { setDistrict, setSubDistrict, setPortalCode } = this.props
    let resDistrcts = await apiServices.callApi('get', API_PATH.GET_MASTER_DISTRICTS + `?ProvinceCode=${value}`);
    //console.log("resDistrcts==>", resDistrcts);
    if (resDistrcts.status === 200) {
      let dataDistricts = resDistrcts.data.results.map(e => (
        {
          label: e.district,
          value: e.district,
        }
      ))
      //console.log("dataDist==>", dataDistricts);
      this.setState({ dataDistricts });
      setDistrict(null);
      setSubDistrict(null);
      setPortalCode(null);
      //this.handleClickModal("customer", dataSelectedSaleEmployeer)
    }
    this.setState({ loading: false });
  }

  handleChangeDistricts = async (value) => {
    this.setState({ loading: true });
    const { setSubDistrict, setPortalCode } = this.props
    let resSubDistrcts = await apiServices.callApi('get', API_PATH.GET_MASTER_SUB_DISTRICTS + `?District=${value}`);
    //console.log("resSubDistrcts==>", resSubDistrcts);
    if (resSubDistrcts.status === 200 && resSubDistrcts.data.results && resSubDistrcts.data.results.length > 0) {
      let dataSubDistricts = resSubDistrcts.data.results.map(e => (
        {
          label: e.subDistrict,
          value: e.subDistrict,
        }
      ))
      this.setState({ dataSubDistricts });
      setSubDistrict(null);
      setPortalCode(resSubDistrcts.data.results[0].postalCode);
    }
    this.setState({ loading: false });
  }

  handleChangeSelectShipToProvicne = async (value) => {
    this.setState({ loading: true });
    const { setDistrictShipto, setSubDistrictShipto, setPortalCodeShipto } = this.props
    let resDistrcts = await apiServices.callApi('get', API_PATH.GET_MASTER_DISTRICTS + `?ProvinceCode=${value}`);
    console.log("resDistrcts==>", resDistrcts);
    if (resDistrcts.status === 200) {
      let dataShiptoDistricts = resDistrcts.data.results.map(e => (
        {
          label: e.district,
          value: e.district,
        }
      ))
      console.log("dataShiptoDistricts==>", dataShiptoDistricts);
      this.setState({ dataShiptoDistricts });
      setDistrictShipto(null);
      setSubDistrictShipto(null);
      setPortalCodeShipto(null);
    }
    this.setState({ loading: false });
  }

  handleChangeSelectShipToDistrict = async (value) => {
    this.setState({ loading: true });
    const { setSubDistrictShipto, setPortalCodeShipto } = this.props
    let resSubDistrcts = await apiServices.callApi('get', API_PATH.GET_MASTER_SUB_DISTRICTS + `?District=${value}`);
    console.log("resSubDistrcts==>", resSubDistrcts);
    if (resSubDistrcts.status === 200 && resSubDistrcts.data.results.length > 0) {
      let dataShiptoSubDistricts = resSubDistrcts.data.results.map(e => (
        {
          label: e.subDistrict,
          value: e.subDistrict,
        }
      ));
      this.setState({ dataShiptoSubDistricts });
      setSubDistrictShipto(null);
      setPortalCodeShipto(resSubDistrcts.data.results[0].postalCode);
    }
    this.setState({ loading: false });
  }

  render() {
    const {
      loading,
      submitViewError,
      otherBranchViewError,
      initialValues,
      creditAllowOptions,
      customerTypeOptions,
      taxBranchOptions,
      tableColumns,
      dataOutlet,
      dataRegionCountries,
      dataProvices,
      dataDistricts,
      dataSubDistricts,
      dataCustomerTitles,
      dataSaleEmployeerGroups,
      dataTaxs,
      dataPaymentTerms,
      dataChannels,
      permissionPage,
      saleEmployeeId,
    } = this.state
    return (
      <div className="sale-employee-maintain-container">
        <Spin spinning={loading} style={{ height: '100%' }}>
          <TitleHead text="จัดการข้อมูลพนักงาน" icon="search" />
          <SaleEmployeerMaintainForm
            loading={loading}
            submitViewError={submitViewError}
            otherBranchViewError={otherBranchViewError}
            creditAllowOptions={creditAllowOptions}
            customerTypeOptions={customerTypeOptions}
            taxBranchOptions={taxBranchOptions}
            tableColumns={tableColumns}
            dataOutlet={dataOutlet}
            dataCustomerTitles={dataCustomerTitles}
            dataSaleEmployeerGroups={dataSaleEmployeerGroups}
            dataTaxs={dataTaxs}
            dataPaymentTerms={dataPaymentTerms}
            dataChannels={dataChannels}

            dataRegionCountries={dataRegionCountries}
            dataProvices={dataProvices}
            dataDistricts={dataDistricts}
            dataSubDistrict={dataSubDistricts}

            handleChangeSelectProvicne={this.handleChangeSelectProvicne}
            handleChangeDistricts={this.handleChangeDistricts}
            initialValues={initialValues}
            permissionPage={permissionPage}
          />

          <FooterButton
            nodeLeft={
              [
                {
                  button: <Link to={ROUTH_PATH.SALEEMPLOYEE}>
                      <ButtonCustom text="ยกเลิก" icon="close" type="danger" />
                    </Link>
                }
              ]
            }
            nodeRight={
              [
                {
                  button: <ButtonCustom onClick={() => this.handleClickSaveSaleEmployeer()} text="บันทึก" icon="save" 
                    disabled={(saleEmployeeId ? !permissionPage.authUpdate : !permissionPage.authCreate)} />
                },
              ]
            }
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log("state===>", state);
  return {
    auth: state.auth,
    SaleEmployeerMaintainReduxForm: state.form.SaleEmployeerMaintainForm,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDistrict: (data) => dispatch(change(`SaleEmployeerMaintainForm`, `saleEmployeeDistrict`, data)),
  setSubDistrict: (data) => dispatch(change(`SaleEmployeerMaintainForm`, `saleEmployeeSubDistrict`, data)),
  setPortalCode: (data) => dispatch(change(`SaleEmployeerMaintainForm`, `saleEmployeePostalCode`, data)),
  setCountry: (data) => dispatch(change(`SaleEmployeerMaintainForm`, `saleEmployeeCountry`, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaleEmployeeMaintainContainer);