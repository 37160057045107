import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { formatNumber, getPermissionPage } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    InputCustom,
    SelectCustom,
} from 'components/common'
import { Row, Col, Spin } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import swal from '@sweetalert/with-react'
import { functionAlias } from 'routes'

import './style.css'

const widthLabel = '70px'

class ReportCostGpProductContainer extends Component {
    state = {
        columns: [
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productId',
                key: 'productId',
                align: 'center',
            },
            {
                title: 'บาร์โค้ด',
                dataIndex: 'barcode',
                key: 'barcode',
                align: 'center',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productNameMarket',
                key: 'productNameMarket',
                align: 'center',
                width: 250,
                render: (props) => (
                    <div className="text-left">{props}</div>
                ),
            },
            {
                title: 'หมวด',
                dataIndex: 'classCode',
                key: 'classCode',
                align: 'center',
                render: (props, row) => (
                    <div>{row.classDescription ? `${props} - ${row.classDescription}` : props}</div>
                ),
            },
            {
                title: 'ยี่ห้อ',
                dataIndex: 'brandCode',
                key: 'brandCode',
                align: 'center',
                render: (props, row) => (
                    <div>{row.brandDescription ? `${props} - ${row.brandDescription}` : props}</div>
                ),
            },
            {
                title: 'ทุนขาย',
                dataIndex: 'retailCost',
                key: 'retailCost',
                align: 'center',
                render: (props) => (
                    this.state.permissionPage.authApprove && <div className="text-right">{formatNumber(props, 2)}</div>
                ),
            },
            {
                title: 'ราคาสินค้าขายปลีก',
                dataIndex: 'retailPrice',
                key: 'retailPrice',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
            },
            {
                title: '%GP ปลีก(ก่อนแบ่ง)',
                dataIndex: 'retailGRPercent',
                key: 'retailGRPercent',
                align: 'center',
                render: (props) => (
                    this.state.permissionPage.authApprove && <div className="text-right">{formatNumber(props, 0)}</div>
                ),
            },
            {
                title: 'ราคาสินค้าขายส่ง/โครงการ',
                dataIndex: 'projectPrice',
                key: 'projectPrice',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
            },
            {
                title: '%GP ส่ง(ก่อนแบ่ง)',
                dataIndex: 'projectGRPercent',
                key: 'projectGRPercent',
                align: 'center',
                render: (props) => (
                    this.state.permissionPage.authApprove && <div className="text-right">{formatNumber(props, 0)}</div>
                ),
            },
        ],
        loading: false,
        documents: [],
        filter: {
            outletId: '',
            barcode: '',
            productCode: '',
            productName: '',
        },
        permissionPage: getPermissionPage(functionAlias.reportCostGP),
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })

        const { auth } = this.props
        const { filter } = this.state

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({
                    outlets,
                    filter: { ...filter, outletId: outlets[0].value },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (!filter.outletId) return { isError: true, message: 'กรุณาระบุร้าน' }
        return { isError: false }
    }

    calculatePercentGP = (price, cost) => {
        return ((Number(price || 0) - Number(cost || 0)) / Number(price || 0)) * 100
    }

    searchDocument = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = {
            outletId: filter.outletId,
            barcode: filter.barcode,
            productId: filter.productCode,
            productName: filter.productName,
        }

        await apiServices.callApi('post', API_PATH.POST_GET_COST_GR_REPORT, body).then(res => {
            if (res.status === 200) {
                const message = get(res, 'data.message')
                if (message === 'Success') {
                    this.setState({ documents: get(res, 'data.results') })
                } else {
                    swal('Warning', message, 'warning')
                }
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            columns,
            outlets,
            loading,
            filter,
            documents,
        } = this.state

        return (
            <div className="report-cost-gp-product-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="ค้นหาสินค้า"
                        icon="search"
                    />
                    <div className="middle-content">
                        <Row gutter={20}>
                            <Col sm={6}>
                                <SelectCustom
                                    label="ร้าน"
                                    widthLabel={widthLabel}
                                    options={outlets}
                                    small
                                    onChange={(val) => this.changeFilter('outletId', val)}
                                    value={filter.outletId}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputCustom
                                    label="บาร์โค้ด"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('barcode', e.target.value)}
                                    onPressEnter={this.searchDocument}
                                    value={filter.barcode}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputCustom
                                    label="รหัสสินค้า"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('productCode', e.target.value)}
                                    onPressEnter={this.searchDocument}
                                    value={filter.productCode}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputCustom
                                    label="ชื่อสินค้า"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('productName', e.target.value)}
                                    onPressEnter={this.searchDocument}
                                    value={filter.productName}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20} style={{ paddingTop: '15px' }}>
                            <Col sm={24} className="text-right">
                                <ButtonCustom
                                    text="ค้นหาสินค้า"
                                    icon="search"
                                    small
                                    onClick={this.searchDocument}
                                />
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom
                            height="calc(100vh - 336px)"
                                columns={columns}
                                data={documents}
                                isFullContent
                                rowKey={(row, index) => index}
                                small
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportCostGpProductContainer)