import React from 'react';
import moment from 'moment';
import { formatNumber } from 'helpers';

export const allTableColumns = {
    "Normal": [
        {
            title: 'Outlet ID',
            dataIndex: 'outletId',
            key: 'outletId',
            align: 'center',
        },
        {
            title: 'Billing Date',
            dataIndex: 'billingDate',
            key: 'billingDate',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {moment(new Date(value)).format('DD/MM/YYYY')}
                </div>)
        },
        {
            title: 'Billing No',
            dataIndex: 'billingNo',
            key: 'billingNo',
            align: 'center',
        },
        {
            title: 'Billing Item',
            dataIndex: 'billingItem',
            key: 'billingItem',
            align: 'center',
        },
        {
            title: 'SO Number',
            dataIndex: 'soNumber',
            key: 'soNumber',
            align: 'center',
        },
        {
            title: 'Document Type Code',
            dataIndex: 'documentTypeCode',
            key: 'documentTypeCode',
            align: 'center',
        },
        {
            title: 'Sale Channel Code',
            dataIndex: 'saleChannelCode',
            key: 'saleChannelCode',
            align: 'center',
        },
        {
            title: 'Barcode',
            dataIndex: 'barcode',
            key: 'barcode',
            align: 'center',
        },
        {
            title: 'Product Code',
            dataIndex: 'productCode',
            key: 'productCode',
            align: 'center',
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
            //align: 'center',
        },
        {
            title: 'Sloc Id',
            dataIndex: 'slocId',
            key: 'slocId',
            align: 'center',
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 3)}
                </div>
            ),
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
            align: 'center',
        },
        {
            title: 'Free Goods Flag',
            dataIndex: 'freeGoodsFlag',
            key: 'freeGoodsFlag',
            align: 'center',
            render: (value) => (
                <div>
                    {`${value}`}
                </div>
            ),
        },
        {
            title: 'Special Order Flag',
            dataIndex: 'specialOrderFlag',
            key: 'specialOrderFlag',
            align: 'center',
            render: (value) => (
                <div>
                    {`${value}`}
                </div>
            ),
        },
        {
            title: 'Sales Price Per Unit',
            dataIndex: 'salesPricePerUnit',
            key: 'salesPricePerUnit',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 2)}
                </div>
            ),
        },
        {
            title: 'Cost Per Unit',
            dataIndex: 'costPerUnit',
            key: 'costPerUnit',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 2)}
                </div>
            ),
        },
        {
            title: 'Total Disc Amount',
            dataIndex: 'totalDiscAmount',
            key: 'totalDiscAmount',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 2)}
                </div>
            ),
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            align: 'center',
            render: (value) => (
                <div className="align-right">
                    {formatNumber(value || 0, 2)}
                </div>
            ),
        },
        {
            title: 'Margin',
            dataIndex: 'margin',
            key: 'margin',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 2)}
                </div>
            ),
        },
        {
            title: 'Percent Sharing',
            dataIndex: 'percentSharing',
            key: 'percentSharing',
            align: 'center',
        },
        {
            title: 'PO Price Per Unit',
            dataIndex: 'poPricePerUnit',
            key: 'poPricePerUnit',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 2)}
                </div>
            ),
        },
        {
            title: 'PO Number',
            dataIndex: 'poNumber',
            key: 'poNumber',
            align: 'center',
        },
        {
            title: 'PO Item',
            dataIndex: 'poItem',
            key: 'poItem',
            align: 'center',
        },
        {
            title: 'GR Number',
            dataIndex: 'grNumber',
            key: 'grNumber',
            align: 'center',
        },
        {
            title: 'GR Item',
            dataIndex: 'grItem',
            key: 'grItem',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            align: 'center',
        },
    ],
    "Special": [
        {
            title: 'Outlet ID',
            dataIndex: 'outletId',
            key: 'outletId',
            align: 'center',
        },
        {
            title: 'Billing Date',
            dataIndex: 'billingDate',
            key: 'billingDate',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {moment(new Date(value)).format('DD/MM/YYYY')}
                </div>)
        },
        {
            title: 'Billing No',
            dataIndex: 'billingNo',
            key: 'billingNo',
            align: 'center',
        },
        {
            title: 'Billing Item',
            dataIndex: 'billingItem',
            key: 'billingItem',
            align: 'center',
        },
        {
            title: 'SO Number',
            dataIndex: 'soNumber',
            key: 'soNumber',
            align: 'center',
        },
        {
            title: 'Document Type Code',
            dataIndex: 'documentTypeCode',
            key: 'documentTypeCode',
            align: 'center',
        },
        {
            title: 'Sale Channel Code',
            dataIndex: 'saleChannelCode',
            key: 'saleChannelCode',
            align: 'center',
        },
        {
            title: 'Barcode',
            dataIndex: 'barcode',
            key: 'barcode',
            align: 'center',
        },
        {
            title: 'Product Code',
            dataIndex: 'productCode',
            key: 'productCode',
            align: 'center',
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
            //align: 'center',
        },
        {
            title: 'Sloc Id',
            dataIndex: 'slocId',
            key: 'slocId',
            align: 'center',
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 3)}
                </div>
            ),
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
            align: 'center',
        },
        {
            title: 'Free Goods Flag',
            dataIndex: 'freeGoodsFlag',
            key: 'freeGoodsFlag',
            align: 'center',
            render: (value) => (
                <div>
                    {`${value}`}
                </div>
            ),
        },
        {
            title: 'Special Order Flag',
            dataIndex: 'specialOrderFlag',
            key: 'specialOrderFlag',
            align: 'center',
            render: (value) => (
                <div>
                    {`${value}`}
                </div>
            ),
        },
        {
            title: 'Sales Price Per Unit',
            dataIndex: 'salesPricePerUnit',
            key: 'salesPricePerUnit',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 2)}
                </div>
            ),
        },
        {
            title: 'Cost Per Unit',
            dataIndex: 'costPerUnit',
            key: 'costPerUnit',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 2)}
                </div>
            ),
        },
        {
            title: 'Total Disc Amount',
            dataIndex: 'totalDiscAmount',
            key: 'totalDiscAmount',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 2)}
                </div>
            ),
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            align: 'center',
            render: (value) => (
                <div className="align-right">
                    {formatNumber(value || 0, 2)}
                </div>
            ),
        },
        {
            title: 'Margin',
            dataIndex: 'margin',
            key: 'margin',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 2)}
                </div>
            ),
        },
        {
            title: 'Percent Sharing',
            dataIndex: 'percentSharing',
            key: 'percentSharing',
            align: 'center',
        },
        {
            title: 'PO Price Per Unit',
            dataIndex: 'poPricePerUnit',
            key: 'poPricePerUnit',
            align: 'center',
            render: (value) => (
                value && <div className="align-right">
                    {formatNumber(value, 2)}
                </div>
            ),
        },
        {
            title: 'PO Number',
            dataIndex: 'poNumber',
            key: 'poNumber',
            align: 'center',
        },
        {
            title: 'PO Item',
            dataIndex: 'poItem',
            key: 'poItem',
            align: 'center',
        },
        {
            title: 'GR Number',
            dataIndex: 'grNumber',
            key: 'grNumber',
            align: 'center',
        },
        {
            title: 'GR Item',
            dataIndex: 'grItem',
            key: 'grItem',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            align: 'center',
        },
        //Add on
        {
            title: 'เลขที่ PR (SCG)',
            dataIndex: 'prNumber',
            key: 'prNumber',
            align: 'center',
        },
        {
            title: 'รายการ PR (SCG)',
            dataIndex: 'prItem',
            key: 'prItem',
            align: 'center',
        },
        {
            title: 'เลขที่ STO (SCG)',
            dataIndex: 'sapPoNumber',
            key: 'sapPoNumber',
            align: 'center',
        },
        {
            title: 'รายการ STO (SCG)',
            dataIndex: 'sapPoItem',
            key: 'sapPoItem',
            align: 'center',
        },
        {
            title: 'message id',
            dataIndex: 'epurMessageId',
            key: 'epurMessageId',
            align: 'center',
        },
        {
            title: 'SAP PO: Status',
            dataIndex: 'epurSapPoStatus',
            key: 'epurSapPoStatus',
            align: 'center',
        },
        {
            title: 'SAP PO: E Message',
            dataIndex: 'epurErrorMessage',
            key: 'epurErrorMessage',
            align: 'center',
        },
    ],
}