import React, { Component } from 'react'
import { Checkbox } from 'antd';
import { InputFormReduxStyled } from "./styled"
import './style.css'

// const optionsMockup = [
//     { label: 'Aa', value: 'Aa' },
//     { label: 'Bb', value: 'Bb' },
//     { label: 'Cc', value: 'Cc' },
// ];

export class CheckBoxRedux extends Component {
    render() {
        const {
            input,
            label,
            meta: { touched, error },
            submitViewError,
            small,
            disabled,
        } = this.props
        return (
            <InputFormReduxStyled
                error={(touched || submitViewError) && error ? true : false}
                small={small}
            >
                <div className="input-form checkbox-redux">
                    {
                        <Checkbox
                            disabled={disabled}
                            onChange={e => input.onChange(e)}
                            checked={input.value}>
                            {label}
                        </Checkbox>
                    }
                    <div className="error-message">
                        {(touched || submitViewError) ? error : ''}
                    </div>
                </div>

            </InputFormReduxStyled>
        )
    }
}