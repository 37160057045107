import styled from 'styled-components'

export const NumberFormatCustomStyled = styled.div`

    .ant-input-affix-wrapper .ant-input {
        border-color: ${props => props.error ? 'red' : ''};
    }
    .label-style {
        font-size: ${props => props.small ? '12px' : ''};
        width: ${props => props.small ? props.widthSmall ? props.widthSmall : '120px' : props.widthDefault ? props.widthDefault : '150px'};
    }
    .input-form {
        margin-bottom: ${props => props.small ? '3px' : ''};
    }
    .input-style {
        font-size: ${props => props.small ? '12px' : ''};
    }
`