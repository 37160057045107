import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Collapse, Icon, Spin, Modal } from "antd"
import { sortDataColumn } from 'helpers'
import { TitleHead, RadioCustom, SelectHero, RangePickerCustom, InputHero, InputSearch, TableCustom, DatePickerCustom, ButtonCustom, ModalCustom } from "components"
import { authData, searchCriteriaSOReportData } from 'actions/actionsCreator';
import { apiServices, API_PATH } from "apiServices"
import swal from '@sweetalert/with-react'
import { isEmpty, get, find } from 'lodash'
import { map } from 'lodash'
import { Link } from 'react-router-dom'
import { ROUTH_PATH } from 'routes'
const { Panel } = Collapse;

class ProductMultiSelectModal extends Component {
    state = {
        productCriteria: [{ text: "ทั้งหมด", label: "ทั้งหมด", value: "keywords" },
        { text: "รหัสสินค้า", label: "รหัสสินค้า", value: "productId" },
        { text: "ชื่อสินค้า", label: "ชื่อสินค้า", value: "productNames" },
        { text: "บาร์โค้ด", label: "บาร์โค้ด", value: "barcode" }],
        productKeyword: "keywords",
        productSearchText: '',
        columnsProduct: [
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productId',
                key: 'productId',
                sorter: (a, b) => sortDataColumn(a, b, 'productId'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productNameEng',
                key: 'productNameEng',
                sorter: (a, b) => sortDataColumn(a, b, 'productNameEng'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'คลัง',
                dataIndex: 'storageLocation',
                key: 'storageLocation',
                sorter: (a, b) => sortDataColumn(a, b, 'storageLocation'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'จำนวนที่มี',
                dataIndex: 'atp',
                key: 'atp',
                sorter: (a, b) => sortDataColumn(a, b, 'atp'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'หน่วยนับ',
                dataIndex: 'unitId',
                key: 'unitId',
                sorter: (a, b) => sortDataColumn(a, b, 'unitId'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'ราคา',
                dataIndex: 'retailPrice',
                key: 'retailPrice',
                sorter: (a, b) => sortDataColumn(a, b, 'retailPrice'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'หน่วยขาย',
                dataIndex: 'salesUnit',
                key: 'salesUnit',
                sorter: (a, b) => sortDataColumn(a, b, 'salesUnit'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'Barcode',
                dataIndex: 'barcode',
                key: 'barcode',
                sorter: (a, b) => sortDataColumn(a, b, 'barcode'),
                ellipsis: true,
                align: 'center',
            }
        ],
        productList: [],
        loading: false,
        selectedRowKeys: [],
        selectedRowData: [],
    }

    componentDidMount() {

    }

    groupBy = (data) => {
        var groupStatus = {};
        for (var i = 0; i < data.length; i++) {
            let status = data[i].productId;
            if (!groupStatus[status]) {
                groupStatus[status] = [];
            }
        }

        let newData = [];
        for (var statusName in groupStatus) {
            let x = data.find(el => (el.productId == statusName));
            newData.push(x);
        }
        return newData;
    }

    handleClickSearchProduct = async (e) => {
        const { outletSelected, productKeyword, productSearchText } = this.state
        this.setState({ loading: true })
        const { auth } = this.props
        let params = {
            keywords: productKeyword == "keywords" ? productSearchText : '',
            productId: productKeyword == "productId" ? productSearchText : '',
            productNames: productKeyword == "productNames" ? productSearchText : '',
            barcode: productKeyword == "barcode" ? productSearchText : '',
            outlet: outletSelected,
            storageLocationIds: auth.arrayStorageLocationId,
            limitMaxRange: 1000,
            productStatus: true
        }

        let res = await apiServices.callApi("post", `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, params)
        if (res.status === 200) {
            let productList = res.data.results.map((data, i) => (
                {
                    ...data,
                    key: i
                }
            ));
            var distincProduct = this.groupBy(productList);

            this.setState({
                productList: distincProduct,
            })
        }
        this.setState({ loading: false })
    }

    handleChangeProductCriteria = (e) => {
        this.setState({ productKeyword: e });
    }

    handleChangeProductSearchText = (e) => {
        this.setState({ productSearchText: e.target.value });
    }

    onSelectChange = (selectedRowKeys, selectedRowData) => {
        this.setState({ selectedRowKeys, selectedRowData })
    }

    handleClearSelectedRowData = () => {
        this.setState({
            selectedRowKeys: [],
            selectedRowData: [],
        });
    }

    render() {
        const {
            isShowModal,
            closeModal,
            onClickOkModal,
        } = this.props;

        const {
            loading,
            columnsProduct,
            productList,
            selectedRowKeys,
            selectedRowData,
            productCriteria,
            productKeyword,
            productSearchText
        } = this.state;

        const rowSelection = {
            selectedRowKeys,
            selectedRowData,
            onChange: this.onSelectChange,
        }
        //console.log('selectedRowKeys', selectedRowKeys)
        return (
            <ModalCustom
                title="ค้นหาสินค้า"
                isShowModal={isShowModal}
                handleClickSave={() => {
                    onClickOkModal(selectedRowData)
                    closeModal()
                    this.handleClearSelectedRowData()
                }}
                handleCloseModal={() => {
                    closeModal()
                    this.handleClearSelectedRowData()
                }}
                width="70%"
                footer={true}
                textSuccess="ตกลง"
            >
                <div>
                    <Row gutter={20}>
                        <Col md={3}>
                            ค้นหาด้วย
                        </Col>
                        <Col md={5}>
                            <SelectHero
                                onChange={this.handleChangeProductCriteria}
                                name="productCriteria"
                                options={productCriteria}
                                value={productKeyword}
                            />
                        </Col>
                        <Col md={8}>
                            <InputHero
                                onChange={this.handleChangeProductSearchText}
                                name="value"
                                value={productSearchText}
                            />
                        </Col>
                        <Col md={2}>
                            <span><ButtonCustom red text="ค้นหา" icon="search" onClick={this.handleClickSearchProduct} /></span>
                        </Col>
                    </Row>
                    <div>
                        <TableCustom rowSelection={rowSelection} height="calc(100vh - 316px)" columns={columnsProduct} data={productList} loading={loading} />
                    </div>
                </div>
            </ModalCustom>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth,
        searchCriteriaSOReport: state.searchCriteriaSOReport,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData,
        searchCriteriaSOReportData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductMultiSelectModal);