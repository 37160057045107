import React, { Component } from 'react'
import { get, isEmpty } from 'lodash';
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    SelectCustom,
    InputSearchCustom,
} from 'components/common'
import swal from '@sweetalert/with-react'
import { Row, Col } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { sortDataColumn } from 'helpers'

export class VendorSearchModal extends Component {
    state = {
        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: '',
                key: 'htf',
                ellipsis: true,
                render: (props, row) => <div className="text-center">
                    <ButtonCustom
                        onClick={() =>
                            this.props.onSelected(row)}
                        small
                        text="เลือก" />
                </div>
            },
            {
                title: 'รหัสผู้ขาย',
                align: 'center',
                dataIndex: 'vendorId',
                key: 'vendorId',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'vendorId'),
            },
            {
                title: 'ผู้ขาย(ภาษาไทย)',
                align: 'left',
                dataIndex: 'vendorNameThai',
                key: 'vendorNameThai',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'vendorNameThai'),
            },
            {
                title: 'ผู้ขาย(ภาษาอังกฤษ)',
                align: 'left',
                dataIndex: 'vendorNameEng',
                key: 'vendorNameEng',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'vendorNameEng'),
            },
            {
                title: 'เลขประจำตัวผู้เสียภาษี',
                align: 'center',
                dataIndex: 'taxId',
                key: 'taxId',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'taxId'),
            },
            {
                title: 'สาขา',
                align: 'left',
                dataIndex: 'taxBranch',
                key: 'taxBranch',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'taxBranch'),
            },
            {
                title: 'ทีอยู่',
                align: 'left',
                dataIndex: 'address',
                key: 'address',
                ellipsis: false,
                width: 200,
            },
        ],
        loading: false,
        venders: [],
        filter:{
            keyword: '',
            keywordType: 'VendorNameThai',
        },
        keywordTypes: [
            { label: 'ชื่อภาษาไทย', value: 'VendorNameThai' },
            { label: 'ชื่อภาษาอังกฤษ', value: 'VendorNameEng' },
            { label: 'เลขประจำตัวผู้เสียภาษี', value: 'TaxId' },
            { label: 'รหัสผู้ขาย', value: 'VendorId' },
        ],
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    searchVendor = async () => {
        let { filter, venders } = this.state
        
        if (!filter.keyword) {
            return
        }

        this.setState({ loading: true })
        
        await apiServices.callApi('get', `${API_PATH.GET_VENDOR}?${filter.keywordType}=${filter.keyword}`, "").then(res => {
            let results = get(res, 'data.results')

            if(isEmpty(results)){
                this.setState({ venders : [] })
                return
            }
            
            venders = results.map((data, index) => (
                { ...data, key: index }
            ))
            this.setState({ venders })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิกพลาดไม่สามารถค้นหาข้อมูลผู้ขายได้ กรุณาลองใหม่อีกครั้ง!')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            width,
            className,
            title,
        } = this.props
        const {
            columns,
            filter,
            keywordTypes,
            loading,
            venders,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                width={width}
                title={title || 'ค้นหาผู้ขาย'}
                className={`${className || ''}`}
            >
                <Row gutter={8}>
                    <Col sm={8}>
                        <SelectCustom
                            label="ค้นหาด้วย"
                            name='keywordType'
                            small
                            widthLabel='100px'
                            onChange={(value) => this.changeFilter('keywordType', value)}
                            options={keywordTypes}
                            value={filter.keywordType}
                        />
                    </Col>
                    <Col sm={6}>
                        <InputSearchCustom
                            small
                            value={filter.keyword}
                            onChange={(e) => this.changeFilter('keyword', e.target.value)}
                            onClickSearch={this.searchVendor}
                            onSubmit={this.searchVendor}
                        />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={venders}
                            loading={loading}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
