import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from "redux-form"
import { Link } from "react-router-dom"
import { message, Spin } from "antd"
import { TitleHead, TabCustom, ButtonCustom, FooterButton } from "components"
import { PromotionMaintainInfoForm, PromotionMaintainConditionForm, PromotionMaintainPatternForm } from "reduxForms"
import { promotionPattern, conditionType, getPermissionPage } from "helpers"
import './style.css'
import { API_PATH, apiServices } from 'apiServices';
import swal from '@sweetalert/with-react';
import moment from "moment"
import { ROUTH_PATH, functionAlias } from 'routes';
import { isEmpty, get, first } from 'lodash';

class PromotionMaintainContainer extends Component {
    state = {
        permissionPage: getPermissionPage(functionAlias.promotionMaintain),
        dataSupportBy: [],
        mixAndMatchList: [],
        voucherList: [],
        initialValuesInfo: {},
        initialValuesCondition: {},
        initialValuesPattern: {},
        qualifierData: {},
        mixAndMatchReward: {},
        rewardMixAndMatch: {},
        promotionCampaign: {},
        promotionDiscountCondition: {},
        promotionRuleBundles: {},
        promotionHistories: [],
        promotionRuleDiscountLines: {},
        qtyDiscountAndList: [],
        promotionGiftList: {
            data: []
        },
        isLoading: false,
        dataOutlet: [],
        dataCustomerGroup: [],
        dataChannal: []
    }

    componentDidMount() {
        const { match } = this.props;
        this.fetchDataDefaultDropdown()
        if (match.params.code) {
            // เข้าในการกรณีต้องการ copy ข้อมูล
            if (match.path === ROUTH_PATH.PROMOTION_MAINTAIN_REF) {
                this.fetchDataForRef(match.params.code)
            } else {
                this.fetchData(match.params.code)
            }

        } else {
            this.fetchDataDefualt()
        }
    }
    fetchDataDefaultDropdown = async () => {
        let resCustomerGroup = await apiServices.callApi("get", API_PATH.GET_MASTER_CUSTOMER_GROUP)
        let resOutlet = await apiServices.callApi("post", API_PATH.GET_MASTER_OUTLET, {})
        let resChannel = await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL)
        let resPromotionStatus = await apiServices.callApi('get', API_PATH.GET_PROMOTION_STATUS)
        if (resCustomerGroup.status === 200 &&
            resOutlet.status === 200 &&
            resChannel.status === 200 &&
            resPromotionStatus.status === 200) {
            let dataOutlet = resOutlet.data.results.map(data => (
                {
                    ...data,
                    text: `${data.outletId} - ${data.outletName}`,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }
            ))
            let dataCustomerGroup = resCustomerGroup.data.results.map((data => (
                {
                    ...data,
                    text: data.customerGroupDescription,
                    value: data.customerGroupId,
                    label: data.customerGroupDescription
                }
            )))
            let dataChannal = resChannel.data.results.map(data => (
                {
                    ...data,
                    text: `${data.saleChannelCode} - ${data.saleChannelDescription}`,
                    value: data.saleChannelCode,
                    label: `${data.saleChannelCode} - ${data.saleChannelDescription}`,
                }
            ))
            let dataPromotionStatus = resPromotionStatus.data.results.map(data => ({
                ...data,
                value: data.code,
            }))
            this.setState({
                dataOutlet: dataOutlet,
                dataCustomerGroup: dataCustomerGroup,
                dataChannal: dataChannal,
                dataPromotionStatus: dataPromotionStatus
            })
        }
        else {
            swal("error", "", "error")
        }
    }
    fetchDataDefualt = () => {
        const { setPromotionPattern } = this.props
        setPromotionPattern(promotionPattern[0].value)
    }
    /**
     *  สำหรับดึงข้อมูลจาก Ref มาตั้งต้น
     *
     * @memberof PromotionMaintainContainer
     */
    fetchDataForRef = async (promotionCode) => {
        this.setState({ isLoading: true })
        await apiServices.callApi("get", `${API_PATH.GET_PROMOTION}?Code=${promotionCode}`).then(res => {
            if (res.status === 200) {
                let dateRange = []
                let result = first(res.data.results)
                let effectiveDate = result.promotionCampaign.effectiveDate
                let expireDate = result.promotionCampaign.expireDate

                let customerGroupId = result.promotionDiscountCondition.customerGroupId ?
                    result.promotionDiscountCondition.customerGroupId.split(",").map(String) : ''
                let channel = result.promotionDiscountCondition.channel.split(",").map(String)
                let outletId = result.promotionDiscountCondition.outletId.split(",").map(String)
                dateRange.push(moment(effectiveDate), moment(expireDate))

                let arrQD = result.promotionRuleDiscountQtyHeads
                let arrMM = result.promotionRuleMixAndMatchHeads
                let arrProGift = result.promotionGifts
                let arrVoucer = result.promotionVouchers
                let promotionRuleDiscountLine = result.promotionRuleDiscountLines[0]

                let qtyDiscountAndList = arrQD.map((data) => ({
                    ...data,
                    itemQualifierId: data.ruleQualifierId,
                    qualifierName: data.ruleQualifierName,
                    qualifierDescription: data.ruleQualifierDescription,
                    totalQuatity: data.totalValue,
                    discountAndGiveAwayType: data.rewardType,
                    disabledTotalAmount: this.checkDisaledInput(conditionType[2].value, data.qtyType),
                    disabledTotalQuatity: this.checkDisaledInput(conditionType[1].value, data.qtyType),
                    disabledItems: this.checkDisaledInput(conditionType[0].value, data.qtyType),
                    rewardQualifierName: get(data, 'promotionGifts[0].dQualifierName', null),
                    rewardQualifierDescription: get(data, 'promotionGifts[0].dQualifierDescription', []),
                    promotionGifts: data.promotionGifts.map(data2 => ({
                        ...data2,
                        itemQualifierId: data2.dQualifierId,
                        productCode: data2.articleNo,
                        temName: data2.articleName,
                        qtys: data2.qty,
                    })),
                    promotionRuleDiscountQtyItemModels: data.promotionRuleDiscountQtyItemModels.map(data3 => ({
                        ...data3,
                        productCode: data3.articleNo,
                        temName: data3.articleName,
                        qty: data3.qty,
                    }))
                }))
                // console.log('qtyDiscountAndList =>',qtyDiscountAndList);
                let mixAndMatchList = arrMM.map((data) => ({
                    ...data,
                    ruleQualifierId: data.dQualifierId,
                    qtyType: data.mxmType,
                    totalQuatity: data.totalValue,
                    discountAndGiveAwayType: data.rewardType,
                    disabledTotalAmount: this.checkDisaledInput(conditionType[2].value, data.mxmType),
                    disabledTotalQuatity: this.checkDisaledInput(conditionType[1].value, data.mxmType),
                    disabledItems: this.checkDisaledInput(conditionType[0].value, data.mxmType),
                    qualifierName: data.dQualifierName,
                    qualifierDescription: data.dQualifierDescription,
                    promotionRuleMixAndMatchItemModels: data.promotionRuleMixAndMatchItemModels.map((data2) => ({
                        ...data2,
                        productCode: data2.articleNo,
                        temName: data2.articleName,
                        qty: data2.qty,
                        qtys: data2.qty,
                    }))
                }))

                let mapDataGift = arrProGift.map((data) => ({
                    ...data,
                    no: data.sequenceId,
                    productCode: data.articleNo,
                    temName: data.articleName,
                    qtys: data.qty,
                }))
                // console.log('mapDataGift', mapDataGift);

                let dataListGift = mapDataGift.length > 0 ? {
                    name: mapDataGift[0].dQualifierName,
                    description: mapDataGift[0].dQualifierDescription,
                    data: mapDataGift
                } : { data: [] }
                let voucherList = arrVoucer.map(data => ({
                    ...data,
                    dateEffective: data.effectiveDate,
                    dateExpire: data.expireDate,
                    active: data.status,
                    qualifierId: data.qualifierId,
                    voucherValue: data.voucherValue,
                    disabledInput: true,
                }))
                delete result.promotionCampaign.campaignId
                delete result.promotionCampaign.discountConditionId
                delete result.promotionDiscountCondition.discountConditionId
                this.setState({
                    promotionCode: null,//promotionCode,
                    initialValuesCondition: {
                        ...result.promotionDiscountCondition,
                        // ...Object.assign(result.promotionDiscountCondition, {
                        //     discountConditionId: null,
                        // }),
                        recycling: result.promotionDiscountCondition.recycling ? 1 : 2,
                        // recycling: result.promotionDiscountCondition.recycling,//result.recycling ? 1 : 2,
                        customerGroupId: customerGroupId,
                        channel: channel,
                        outletId: outletId,
                    },
                    initialValuesInfo: {
                        ...result.promotionCampaign,
                        effectiveDate: dateRange,
                    },
                    initialValuesPattern: {
                        ...result.promotionRuleBundles[0],
                        ...result.promotionRuleDiscountLines[0],
                        pattern: this.checkPattern(result),
                        valueAmount: !isEmpty(promotionRuleDiscountLine) ?
                            (promotionRuleDiscountLine.dPromotionType === 'Bill' ? promotionRuleDiscountLine.dTotal : promotionRuleDiscountLine.dValue) :
                            (
                                !isEmpty(result.promotionRuleBundles[0]) ? result.promotionRuleBundles[0].dValue : ``
                            ),
                    },
                    qtyDiscountAndList: qtyDiscountAndList,
                    mixAndMatchList: mixAndMatchList,
                    promotionDiscountMixAndMatch: {
                        ...result.promotionRuleMixAndMatchHeads[0]
                    },
                    promotionGiftList: dataListGift,
                    voucherList: voucherList,
                    qualifierData: this.getQualifierForPattarn(result),
                    promotionCampaign: result.promotionCampaign,
                    promotionDiscountCondition: result.promotionDiscountCondition,
                    promotionRuleBundles: result.promotionRuleBundles[0],
                    promotionHistories: result.promotionHistories,
                    promotionRuleDiscountLines: result.promotionRuleDiscountLines[0],
                })
            }
        }).catch(error => {
            swal("error", error.response, "error");
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }
    fetchData = async (promotionCode) => {
        this.setState({ isLoading: true })
        await apiServices.callApi("get", `${API_PATH.GET_PROMOTION}?Code=${promotionCode}`).then(res => {
            if (res.status === 200) {
                let dateRange = []
                const result = first(res.data.results)
                let effectiveDate = result.promotionCampaign.effectiveDate
                let expireDate = result.promotionCampaign.expireDate

                let customerGroupId = result.promotionDiscountCondition.customerGroupId ?
                    result.promotionDiscountCondition.customerGroupId.split(",").map(String) : ''
                let channel = result.promotionDiscountCondition.channel.split(",").map(String)
                let outletId = result.promotionDiscountCondition.outletId.split(",").map(String)
                dateRange.push(moment(effectiveDate), moment(expireDate))

                // result.promotionRuleBundles[0] = Object.assign({ ...result.promotionRuleBundles[0] }, {
                //     ...result.promotionRuleBundles[0],
                //     promotionItemQualifierItem: result.promotionRuleBundles[0].promotionItemQualifierItem,
                // })

                let arrQD = result.promotionRuleDiscountQtyHeads
                let arrMM = result.promotionRuleMixAndMatchHeads
                let arrProGift = result.promotionGifts

                let arrVoucer = result.promotionVouchers
                let promotionRuleDiscountLine = result.promotionRuleDiscountLines[0]

                let qtyDiscountAndList = arrQD.map((data) => ({
                    ...data,
                    itemQualifierId: data.ruleQualifierId,
                    qualifierName: data.ruleQualifierName,
                    qualifierDescription: data.ruleQualifierDescription,
                    totalQuatity: data.totalValue,
                    discountAndGiveAwayType: data.rewardType,
                    disabledTotalAmount: this.checkDisaledInput(conditionType[2].value, data.qtyType),
                    disabledTotalQuatity: this.checkDisaledInput(conditionType[1].value, data.qtyType),
                    disabledItems: this.checkDisaledInput(conditionType[0].value, data.qtyType),
                    rewardQualifierName: get(data, 'promotionGifts[0].dQualifierName', null),
                    rewardQualifierDescription: get(data, 'promotionGifts[0].dQualifierDescription', []),
                    promotionGifts: data.promotionGifts.map(data2 => ({
                        ...data2,
                        itemQualifierId: data2.dQualifierId,
                        productCode: data2.articleNo,
                        temName: data2.articleName,
                        qtys: data2.qty,
                    })),
                    promotionRuleDiscountQtyItemModels: data.promotionRuleDiscountQtyItemModels.map(data3 => ({
                        ...data3,
                        productCode: data3.articleNo,
                        temName: data3.articleName,
                        qty: data3.qty,
                    }))
                }))
                let mixAndMatchList = arrMM.map((data) => ({
                    ...data,
                    ruleQualifierId: data.dQualifierId,
                    qtyType: data.mxmType,
                    totalQuatity: data.totalValue,
                    discountAndGiveAwayType: data.rewardType,
                    disabledTotalAmount: this.checkDisaledInput(conditionType[2].value, data.mxmType),
                    disabledTotalQuatity: this.checkDisaledInput(conditionType[1].value, data.mxmType),
                    disabledItems: this.checkDisaledInput(conditionType[0].value, data.mxmType),
                    qualifierName: data.dQualifierName,
                    qualifierDescription: data.dQualifierDescription,
                    promotionRuleMixAndMatchItemModels: data.promotionRuleMixAndMatchItemModels.map((data2) => ({
                        ...data2,
                        productCode: data2.articleNo,
                        temName: data2.articleName,
                        qty: data2.qty,
                        qtys: data2.qty,
                    }))
                }))
                // console.log('result.recycling ? 1 : 2',result.promotionDiscountCondition.recycling ? 1 : 2);

                let mapDataGift = arrProGift.map((data) => ({
                    ...data,
                    no: data.sequenceId,
                    productCode: data.articleNo,
                    temName: data.articleName,
                    dQualifierId: data.dQualifierId,
                    qtys: data.qty,
                }))
                let dataListGift = mapDataGift.length > 0 ? {
                    dQualifierId: mapDataGift[0].dQualifierId,
                    name: mapDataGift[0].dQualifierName,
                    description: mapDataGift[0].dQualifierDescription,
                    data: mapDataGift
                } : { data: [] }

                let voucherList = arrVoucer.map(data => ({
                    ...data,
                    dateEffective: data.effectiveDate,
                    dateExpire: data.expireDate,
                    active: data.status,
                    qualifierId: data.qualifierId,
                    voucherValue: data.voucherValue,
                    disabledInput: true,
                }))
                this.setState({
                    promotionCode: promotionCode,
                    initialValuesCondition: {
                        ...result.promotionDiscountCondition,
                        recycling: result.promotionDiscountCondition.recycling ? 1 : 2,
                        customerGroupId: customerGroupId,
                        channel: channel,
                        outletId: outletId,
                    },
                    initialValuesInfo: {
                        ...result.promotionCampaign,
                        effectiveDate: dateRange,
                    },
                    initialValuesPattern: {
                        ...result.promotionRuleBundles[0],
                        ...result.promotionRuleDiscountLines[0],
                        pattern: this.checkPattern(result),
                        valueAmount: !isEmpty(promotionRuleDiscountLine) ?
                            (promotionRuleDiscountLine.dPromotionType === 'Bill' ? promotionRuleDiscountLine.dTotal : promotionRuleDiscountLine.dValue) :
                            (
                                !isEmpty(result.promotionRuleBundles[0]) ? result.promotionRuleBundles[0].dValue : ``
                            ),
                    },
                    qtyDiscountAndList: qtyDiscountAndList,
                    mixAndMatchList: mixAndMatchList,
                    promotionDiscountMixAndMatch: {
                        ...result.promotionRuleMixAndMatchHeads[0]
                    },
                    promotionGiftList: dataListGift,
                    voucherList: voucherList,
                    qualifierData: this.getQualifierForPattarn(result),
                    promotionCampaign: result.promotionCampaign,
                    promotionDiscountCondition: result.promotionDiscountCondition,
                    promotionRuleBundles: result.promotionRuleBundles[0],
                    promotionHistories: result.promotionHistories,
                    promotionRuleDiscountLines: result.promotionRuleDiscountLines[0],
                })
            }
        }).catch(error => {
            swal("error", error.response, "error");
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }
    getQualifierForPattarn = (data) => {
        // console.log('data.promotionRuleBundles[0].', data);
        if (!isEmpty(data.promotionRuleBundles)) {
            let qualifier = {
                itemQualifierId: data.promotionRuleBundles[0].dRuleQualifierId,
                name: data.promotionRuleBundles[0].dQualifierName,
                description: data.promotionRuleBundles[0].dQualifierDescription,
                promotionItemQualifierItem: data.promotionRuleBundles[0].promotionRuleQualifierItem
            }
            return qualifier
        }
        if (!isEmpty(data.promotionRuleDiscountLines)) {
            let qualifier = {
                itemQualifierId: data.promotionRuleDiscountLines[0].dRuleQualifierId,
                name: data.promotionRuleDiscountLines[0].dRuleQualifierName,
                description: data.promotionRuleDiscountLines[0].dRuleQualifierDescription,
                promotionItemQualifierItem: data.promotionRuleDiscountLines[0].promotionRuleQualifierItem
            }
            return qualifier
        }
        if (!isEmpty(data.promotionRuleMixAndMatchHeads) && !isEmpty(data.promotionGifts)) {
            let qualifier = {
                itemQualifierId: data.promotionGifts[0].dQualifierId,
                name: data.promotionGifts[0].dQualifierName,
                description: data.promotionGifts[0].dQualifierDescription,
            }
            return qualifier
        }
        if (!isEmpty(data.promotionVouchers)) {
            let qualifier = {
                itemQualifierId: data.promotionVouchers[0].qualifierId,
                name: data.promotionVouchers[0].qualifierName,
                description: data.promotionVouchers[0].qualifierDescription,
                promotionItemQualifierItem: data.promotionVouchers[0].promotionRuleQualifierItem
            }
            return qualifier
        }
    }

    checkPattern = (data) => {
        //console.log("checkPattern==", data);
        if (data.promotionRuleBundles.length > 0 || data.promotionRuleBundles === null) {
            this.setState({
                patternValue: promotionPattern[0].value,
                dataRuleBundles: data.promotionRuleBundles[0]
            })
            return promotionPattern[0].value
        }
        if (data.promotionRuleDiscountLines.length > 0 || data.promotionRuleDiscountLines === null) {
            this.setState({ patternValue: promotionPattern[1].value })
            return promotionPattern[1].value
        }
        if (data.promotionRuleDiscountQtyHeads.length > 0 || data.promotionRuleDiscountQtyHeads === null) {
            this.setState({ patternValue: promotionPattern[2].value })
            return promotionPattern[2].value
        }
        if (data.promotionRuleMixAndMatchHeads.length > 0 || data.promotionRuleMixAndMatchHeads === null) {
            this.setState({ patternValue: promotionPattern[3].value })
            return promotionPattern[3].value
        }
        if (data.promotionVouchers.length > 0 || data.promotionVouchers === null) {
            this.setState({ patternValue: promotionPattern[4].value })
            return promotionPattern[4].value
        }
    }

    checkDisaledInput = (e, rewardType) => {
        //console.log("eee==>", e);
        let results = e === rewardType ? false : true
        return results
    }

    validateQtyDiscount = () => {
        const { qtyDiscountAndList } = this.state
        //console.log("qtyDiscountAndList==>", qtyDiscountAndList);
        let newValidateQtyDiscount = [...qtyDiscountAndList]
        let response = { isError: false, message: '' }
        for (let data of newValidateQtyDiscount) {
            if (!data.qtyType) {
                response = { isError: true, message: 'กรุณาเลือกประเภทเงื่อนไข' }
                break;
            }
            if (!data.ruleQualifierId) {
                response = { isError: true, message: 'กรุณาเลือก qualifier' }
                break;
            }
            if (!data.ruleQualifierId) {
                response = { isError: true, message: 'กรุณาเลือก qualifier' }
                break;
            }
        }
        return response;
    }

    qtyDiscountAndList = (qtyDiscountAndArray, PromotionMaintainPatternForm) => {
        let checkError = false;
        if (PromotionMaintainPatternForm !== promotionPattern[2].value) {
            return checkError
        }
        // console.log('qtyDiscountAndList');

        for (const iterator of qtyDiscountAndArray) {
            // ประเภทเงื่อนไข
            if (iterator.qtyType === "item_quantity") {
                // ต้องมีลำดำ
                console.log(isEmpty(iterator.promotionRuleDiscountQtyItemModels));

                if (!isEmpty(iterator.promotionRuleDiscountQtyItemModels)) {
                    // checkError = true;
                    // console.log('else item_quantity');
                    // if (iterator.promotionRuleMixAndMatchItemModels.length > 0) {
                    for (const iteratorIn of iterator.promotionRuleDiscountQtyItemModels) {
                        if (iteratorIn.qty < 1) {
                            console.log('qty');
                            checkError = true
                        }
                    }
                } else {
                    // ค่า อาเรย์ว่าง
                    console.log('ค่า อาเรย์ว่าง 1');

                    checkError = true
                }
            }
            else {
                // qtyType: "total_quantity"
                //  totalQuatity มากกว่า 0
                // qtyType: "total_amount"
                //  totalQuatity มากกว่า 0
                if (!iterator.totalQuatity) {
                    // ไม่ได้กรอก  Total Amount หรือ Total Quantity
                    return true
                } else if (iterator.totalQuatity < 1) {
                    // Total Amount หรือ Total Quantity น้อกว่า
                    checkError = true
                }
            }
            // discountAndGiveAwayType: "ส่วนลด"
            // totalQuatity
            // dTypecode
            // ประเภทส่วนลด
            if (!iterator.discountAndGiveAwayType) {
                // console.log('!iterator.discountAndGiveAwayType');
                return true
            } else {
                // console.log('discountAndGiveAwayType true');
                if (iterator.discountAndGiveAwayType === "ส่วนลด") {
                    if (!iterator.dTypecode) {
                        // ไม่มีประเภทส่วนลด
                        return true
                    } else {
                        if (!iterator.dValue) {
                            // ไม่มีกรอกจำนวนส่วนลด
                            return true
                        } else if (iterator.dValue < 1) {
                            // จำนวนส่วนลดน้อยกว่า 1
                            return true
                        }
                    }
                } else if (iterator.discountAndGiveAwayType === "ของแถม") {
                    // console.log('discountAndGiveAwayType === "ของแถม"');
                    if (!isEmpty(iterator.promotionGifts)) {
                        // เลือกของแถมแล้ว
                        for (const iteratorIn of iterator.promotionGifts) {
                            // วนของแถม
                            if (!iteratorIn.qtys) {
                                // ไม่ได้กรอกเลขของแถม
                                return true
                            } else {
                                if (iteratorIn.qtys < 1) {
                                    // มีจำนวนของแถมน้อยกว่า
                                    // console.log('iteratorIn.qtys < 1');
                                    return true
                                }
                            }
                        }
                    } else {
                        // ยังไม่เลือกของแถม
                        checkError = true
                    }
                }
            }
        }
        return checkError
    }

    mixAndMatchList = (promotionDiscountMixAndMatch, promotionGiftList, mixAndMatchArray, PromotionMaintainPatternForm) => {
        let checkError = false;
        if (PromotionMaintainPatternForm !== promotionPattern[3].value) {            
            return checkError
        }
        // console.log('mixAndMatchList');
        // console.log('promotionDiscountMixAndMatch', promotionDiscountMixAndMatch);
        // console.log('promotionGiftList', promotionGiftList);

        // เช็คเงือนไข
        for (const iterator of mixAndMatchArray) {
            // promotionRuleMixAndMatchItemModels ต้องมี
            // ประเภทเงื่อนไข qtyType
            // qtyType:  "item_quantity" กรอกกเลขใน item
            // promotionRuleMixAndMatchItemModels[]qty
            // ประเภทเงื่อนไข
            if (iterator.qtyType === "item_quantity") {
                // ต้องมีลำดำ
                if (iterator.promotionRuleMixAndMatchItemModels && iterator.promotionRuleMixAndMatchItemModels.length > 0) {
                    for (const iteratorIn of iterator.promotionRuleMixAndMatchItemModels) {
                        if (!iteratorIn.qty) {
                            // ไม่ได้กรอก  จำนวน
                            return true
                        } else if (iteratorIn.qty < 1) {
                            // จำนวนน้อยกว่า 
                            checkError = true
                        }
                    }
                } else {
                    checkError = true
                }
            }
            else {
                // qtyType: "total_quantity"
                //  totalQuatity มากกว่า 0
                // qtyType: "total_amount"
                //  totalQuatity มากกว่า 0
                if (!iterator.totalQuatity) {
                    // ไม่ได้กรอก  Total Amount หรือ Total Quantity
                    return true
                } else if (iterator.totalQuatity < 1) {
                    // Total Amount หรือ Total Quantity น้อกว่า
                    checkError = true
                }
            }
            // console.log('iterator', iterator);


        }
        // ประเภทส่วนลด
        if (!promotionDiscountMixAndMatch) {
            // console.log('ไม่เลือก ประเภทส่วนลด');

            return true
        }
        if (!promotionDiscountMixAndMatch.rewardType) {
            // console.log('!promotionDiscountMixAndMatch.rewardType');
            return true
        } else {
            // console.log('discountAndGiveAwayType true');
            if (promotionDiscountMixAndMatch.rewardType === "ส่วนลด") {
                if (!promotionDiscountMixAndMatch.dTypecode) {
                    // ไม่มีประเภทส่วนลด
                    // console.log('ไม่มีประเภทส่วนลด');

                    return true
                } else {
                    if (!promotionDiscountMixAndMatch.dValue) {
                        // ไม่มีกรอกจำนวนส่วนลด
                        // console.log('ไม่มีกรอกจำนวนส่วนลด');

                        return true
                    } else if (promotionDiscountMixAndMatch.dValue < 1) {
                        // จำนวนส่วนลดน้อยกว่า 1
                        // console.log('จำนวนส่วนลดน้อยกว่า 1');

                        return true
                    }
                }
            } else if (promotionDiscountMixAndMatch.rewardType === "ของแถม") {
                // console.log('discountAndGiveAwayType === "ของแถม"');
                if (!isEmpty(promotionGiftList.data)) {
                    // เลือกของแถมแล้ว
                    // console.log('เลือกของแถมแล้ว');

                    for (const promotionDiscountMixAndMatch of promotionGiftList.data) {
                        // วนของแถม
                        // console.log('วนของแถม');

                        if (!promotionDiscountMixAndMatch.qtys) {
                            // ไม่ได้กรอกเลขของแถม
                            // console.log('ไม่ได้กรอกเลขของแถม');

                            return true
                        } else {
                            if (promotionDiscountMixAndMatch.qtys < 1) {
                                // มีจำนวนของแถมน้อยกว่า
                                // console.log('มีจำนวนของแถมน้อยกว่า');
                                return true
                            }
                        }
                    }
                } else {
                    // ยังไม่เลือกของแถม
                    // console.log('ยังไม่เลือกของแถม');
                    checkError = true
                }
            }
        }
        // console.log('checkError',checkError);
        
        // เช็ด ส่วนลด/ของแถม
        return checkError
    }


    // ไม่สามารถเปลี่ยน รูปแบบโปรโมชั่น ได้บางรูปแบบเนื่องจากขาด id ในการบันทึกที่ต้องสร้างจากหลังบ้านในตอนบันทึกแรก
    // เช่นไม่สามารถเปลี่ยน จาก mixAndMatchList มาเป็น Bundles ได้ เนื่องจากไม่มี 
    //  bundleId นั้นเอง เพราะต้องสร้างจากหลังบ้านเท่านั้น
    // ------------------- create promotion-------------------------
    handleClickSavePromotion = async () => {
        const {
            PromotionMaintainInfoForm: { values, syncErrors },
            PromotionMaintainConditionForm,
            PromotionMaintainPatternForm,
            auth,
            match
        } = this.props;

        const {
            mixAndMatchList,
            voucherList,
            promotionGiftList,
            promotionDiscountMixAndMatch,
            qualifierData,
            qtyDiscountAndList,
            promotionCampaign,
            promotionCode,
            promotionRuleBundles,
            promotionHistories,
            promotionRuleDiscountLines,
        } = this.state;

        const isItem_quantity = (QtyType, value) => {
            if (QtyType === 'item_quantity') {
                return value
            } else {
                return 0
            }
        }
        const isTotal = (QtyType, value) => {
            if (QtyType === 'total_quantity' || QtyType === 'total_amount') {
                return value
            } else {
                return 0
            }
        }
        // this.mixAndMatchList(promotionDiscountMixAndMatch, promotionGiftList, mixAndMatchList, PromotionMaintainPatternForm.values.pattern)
        // console.log((PromotionMaintainPatternForm.values.pattern === promotionPattern[2].value && qtyDiscountAndList
        //     && qtyDiscountAndList.length === 0 || this.qtyDiscountAndList(qtyDiscountAndList, PromotionMaintainPatternForm.values.pattern)));

        // console.log((PromotionMaintainPatternForm.values.pattern === promotionPattern[3].value && mixAndMatchList
        //     && mixAndMatchList.length === 0 || this.mixAndMatchList(promotionDiscountMixAndMatch, promotionGiftList, mixAndMatchList, PromotionMaintainPatternForm.values.pattern)));
        // console.log((PromotionMaintainPatternForm.values.pattern === promotionPattern[4].value && voucherList
        //     && voucherList.length === 0));
        // return
        let validQty = this.validateQtyDiscount()
        if (typeof (syncErrors) === 'object' ||
            typeof (PromotionMaintainConditionForm.syncErrors) === 'object' ||
            typeof (PromotionMaintainPatternForm.syncErrors) === 'object' ||
            validQty.isError ||
            (PromotionMaintainPatternForm.values.pattern === promotionPattern[2].value && qtyDiscountAndList
                && qtyDiscountAndList.length === 0 || this.qtyDiscountAndList(qtyDiscountAndList, PromotionMaintainPatternForm.values.pattern))
            ||
            (PromotionMaintainPatternForm.values.pattern === promotionPattern[3].value && mixAndMatchList
                && mixAndMatchList.length === 0 || this.mixAndMatchList(promotionDiscountMixAndMatch, promotionGiftList, mixAndMatchList, PromotionMaintainPatternForm.values.pattern))
            ||
            (PromotionMaintainPatternForm.values.pattern === promotionPattern[4].value && voucherList
                && voucherList.length === 0)
            // (checkPromotionPattern.indexOf(PromotionMaintainPatternForm.values.pattern) !== -1 &&
            // qualifierData.promotionItemQualifierItem && qualifierData.promotionItemQualifierItem.length === 0)
        ) {
            if (validQty.isError) {
                message.error(validQty.message)
            } else {
                message.error("กรุณากรอกข้อมูลให้ครบถ้วน")
            }
            this.setState({
                submitViewError: true
            })
        } else {
            // return
            if (promotionCode && match.path === ROUTH_PATH.PROMOTION_MAINTAIN_EDIT) {

                let patternConditionFormValue = PromotionMaintainConditionForm.values
                let newPromotionHistories = [...promotionHistories]
                // console.log('patternConditionFormValue.recycling', patternConditionFormValue.recycling);
                // console.log(patternConditionFormValue.recycling === 1 ? true : false);

                newPromotionHistories.push({
                    id: 0,
                    campaignId: !isEmpty(newPromotionHistories) ? newPromotionHistories[0].campaignId : 0,
                    version: !isEmpty(newPromotionHistories) ? newPromotionHistories[newPromotionHistories.length - 1].version + 1 : 1,
                    modifyDate: moment(values.effectiveDate[1]).format('YYYY-MM-DD'),// new Date(),
                    modifyBy: auth.userId
                })

                let params = [{
                    updatable: true,
                    promotionCampaign: {
                        ...values,
                        campaignId: promotionCampaign.campaignId,
                        discountConditionId: promotionCampaign.discountConditionId,
                        perlimitValue: +values.perlimitValue,
                        effectiveDate: moment(values.effectiveDate[0]).format('YYYY-MM-DD'),//new Date(values.effectiveDate[0]),
                        expireDate: moment(values.effectiveDate[1]).format('YYYY-MM-DD'),//new Date(values.effectiveDate[1]),
                    },
                    promotionHistory: {
                        id: 0,
                        campaignId: newPromotionHistories[0].campaignId,
                        version: newPromotionHistories[newPromotionHistories.length - 1].version,
                        modifyDate: moment().format('YYYY-MM-DD'),//new Date(),
                        modifyBy: auth.userId
                    },
                    promotionDiscountCondition: {
                        ...patternConditionFormValue,
                        outletId: patternConditionFormValue.outletId.join(),
                        customerGroupId: patternConditionFormValue.customerGroupId ? patternConditionFormValue.customerGroupId.join() : null,
                        // customerGroupId: patternConditionFormValue.customerGroupId.join(),
                        szLastUpdLocal: moment().format('YYYY-MM-DD'),//new Date(),
                        recycling: patternConditionFormValue.recycling === 1 ? true : false,
                        channel: patternConditionFormValue.channel.join(),
                    },
                    promotionRuleBundles: PromotionMaintainPatternForm.values.pattern === promotionPattern[0].value ? [{

                        bundleId: promotionRuleBundles.bundleId,
                        campaignId: promotionRuleBundles.campaignId,
                        dRuleQualifierId: qualifierData.itemQualifierId,
                        dTypeCode: PromotionMaintainPatternForm.values.dTypeCode,
                        dValue: +PromotionMaintainPatternForm.values.valueAmount || +PromotionMaintainPatternForm.values.valueAmount,
                        promotionRuleQualifierItemModels: (isEmpty(qualifierData.promotionItemQualifierItem) ? [] :
                            qualifierData.promotionItemQualifierItem.map((data2, i2) => ({
                                id: 0,//data2.id,
                                mxmId: 0,
                                ruleQualifierId: data2.itemQualifierId,
                                itemQualifierItemId: data2.itemQualifierItemId,
                                articleNo: data2.productCode,
                                articleName: data2.temName,
                                qty: data2.qty,
                                unitCode: data2.unitCode,
                            })))
                    }] : null,
                    promotionRuleDiscountLines: PromotionMaintainPatternForm.values.pattern === promotionPattern[1].value ? [{
                        discountLineId: promotionRuleDiscountLines.discountLineId,
                        campaignId: promotionRuleDiscountLines.campaignId,
                        dRuleQualifierId: qualifierData.itemQualifierId,
                        dPromotionType: PromotionMaintainPatternForm.values.dPromotionType,
                        dTotal: PromotionMaintainPatternForm.values.dPromotionType === 'Bill' ?
                            +PromotionMaintainPatternForm.values.valueAmount : 0,
                        dTypeCode: PromotionMaintainPatternForm.values.dTypeCode,
                        dValue: PromotionMaintainPatternForm.values.dPromotionType === 'Product' ?
                            +PromotionMaintainPatternForm.values.valueAmount : 0,
                        dMessage: PromotionMaintainPatternForm.values.dMessage,
                        value: PromotionMaintainPatternForm.values.value,
                        promotionRuleQualifierItemModels: (isEmpty(qualifierData.promotionItemQualifierItem) ? [] :
                            qualifierData.promotionItemQualifierItem.map((data2, i2) => ({
                                id: 0,//data2.id,
                                mxmId: 0,
                                ruleQualifierId: data2.itemQualifierId,
                                itemQualifierItemId: data2.itemQualifierItemId,
                                articleNo: data2.productCode,
                                articleName: data2.temName,
                                qty: data2.qty,
                                unitCode: data2.unitCode,
                            })))
                    }] : null,
                    promotionRuleDiscountQtyHeads: PromotionMaintainPatternForm.values.pattern === promotionPattern[2].value ?
                        qtyDiscountAndList.map((data, i) => ({
                            discountQtyId: data.discountQtyId,
                            campaignId: data.campaignId,
                            ruleQualifierId: data.ruleQualifierId,
                            dQualifierId: get(data, 'promotionGifts[0].itemQualifierId', null),
                            qtyType: data.qtyType,
                            totalValue: isTotal(data.qtyType, +data.totalQuatity),
                            sequenceId: data.sequenceId || i + 1,
                            rewardType: data.discountAndGiveAwayType,
                            dTypecode: data.dTypecode,
                            dValue: data.dValue,
                            promotionGifts: data.discountAndGiveAwayType === "ของแถม" ? data.promotionGifts.map((data2, i2) => ({
                                id: data2.id,
                                campaignId: data2.campaignId,
                                dQualifierId: data2.itemQualifierId,
                                articleNo: data2.productCode,
                                itemQualifierItemId: data2.itemQualifierItemId,
                                qty: data2.qtys,
                                unitCode: data2.unitCode,
                                sequenceId: data2.sequenceId || i2 + 1
                            })) : [],
                            promotionRuleDiscountQtyItemModels: data.promotionRuleDiscountQtyItemModels.map((data3, i3) => ({
                                id: data3.id,
                                discountQtyId: data3.discountQtyId,
                                itemQualifierItemId: data3.itemQualifierItemId,
                                articleNo: data3.productCode,
                                qty: isItem_quantity(data.qtyType, data3.qty),// qty: data3.qty,
                                unitCode: data3.unitCode,
                                qtySequenceId: data3.qtySequenceId || i3 + 1,
                            }))
                        })) : null,
                    promotionRuleMixAndMatchHeads: PromotionMaintainPatternForm.values.pattern === promotionPattern[3].value ?
                        mixAndMatchList.map((data, i) => ({
                            dQualifierId: data.ruleQualifierId,
                            mxmType: data.qtyType,
                            totalValue: +data.totalQuatity,
                            sequenceId: i + 1,
                            rewardType: promotionDiscountMixAndMatch.rewardType,
                            dTypecode: promotionDiscountMixAndMatch.dTypecode,
                            dValue: promotionDiscountMixAndMatch.dValue,
                            promotionRuleMixAndMatchItemModels: data.promotionRuleMixAndMatchItemModels.map((data2, i2) => ({
                                articleNo: data2.productCode,
                                ruleQualifierId: data.ruleQualifierId,
                                itemQualifierItemId: data2.itemQualifierItemId,
                                unitCode: data2.unitCode,
                                qty: data2.qty,
                                qtySequenceId: i2 + 1
                            }))
                        })) : null,

                    promotionGifts: PromotionMaintainPatternForm.values.pattern === promotionPattern[3].value ?
                        promotionGiftList.data.map((data, i) => ({
                            dQualifierId: promotionGiftList.dQualifierId,
                            itemQualifierItemId: data.itemQualifierItemId,
                            articleNo: data.productCode,
                            articleName: data.temName,
                            temName: data.temName,
                            dQualifierDescription: data.dQualifierDescription,
                            dQualifierName: data.dQualifierName,
                            unitCode: data.unitCode,
                            qty: data.qtys,
                            sequenceId: data.sequenceId || i + 1,
                        })) : null,
                    promotionVouchers: PromotionMaintainPatternForm.values.pattern === promotionPattern[4].value ?
                        voucherList.map((data) => ({
                            voucherCode: data.voucherCode,
                            effectiveDate: data.dateEffective,
                            expireDate: data.dateExpire,
                            status: data.active,
                            voucherValue: data.voucherValue,
                            qualifierId: qualifierData.itemQualifierId,
                            promotionRuleQualifierItemModels: (isEmpty(qualifierData.promotionItemQualifierItem) ? [] :
                                qualifierData.promotionItemQualifierItem.map((data2, i2) => ({
                                    id: 0,//data2.id,
                                    mxmId: 0,
                                    ruleQualifierId: data2.itemQualifierId,
                                    itemQualifierItemId: data2.itemQualifierItemId,
                                    articleNo: data2.productCode,
                                    articleName: data2.temName,
                                    qty: data2.qty,
                                    unitCode: data2.unitCode,
                                })))
                        })) : null
                }]
                this.setState({ isLoading: true })
                await apiServices.callApi("put", API_PATH.PUT_PROMOTION, params).then(res => {
                    if (res.data.status === "Error") {
                        swal("error", res.data.message, "error")
                    } else {
                        this.fetchData(promotionCode)
                        swal("success", res.data.message, "success")
                    }
                }).catch(error => {
                    if (error.response.status === 400) {
                        const message = error.response.data.errors ? Object.values(error.response.data.errors).flat().join() : 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง';
                        swal('Error', message, 'error')
                    } else {
                        const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง!')
                        swal('Error', message, 'error')
                    }
                }).finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
            } else {
                // console.log('promotionGiftList',promotionGiftList);
                let patternConditionFormValue = PromotionMaintainConditionForm.values
                let params = {
                    creatable: true,
                    promotionCampaign: {
                        ...values,
                        perlimitValue: +values.perlimitValue,
                        effectiveDate: moment(values.effectiveDate[0]).format('YYYY-MM-DD'),// new Date(values.effectiveDate[0]),
                        expireDate: moment(values.effectiveDate[1]).format('YYYY-MM-DD'),//  new Date(values.effectiveDate[1]),
                        createdBy: auth.userId,
                        createdByFullName: auth.firstName + " " + auth.lastName,
                        createdDate: moment().format('YYYY-MM-DD'),// new Date(),
                    },
                    promotionDiscountCondition: {
                        ...patternConditionFormValue,
                        outletId: patternConditionFormValue.outletId.join(),
                        customerGroupId: patternConditionFormValue.customerGroupId ? patternConditionFormValue.customerGroupId.join() : null,
                        szLastUpdLocal: moment().format('YYYY-MM-DD'),//  new Date(),
                        recycling: patternConditionFormValue.recycling === 1 ? true : false,
                        channel: patternConditionFormValue.channel.join(),
                    },
                    promotionRuleBundles: PromotionMaintainPatternForm.values.pattern === promotionPattern[0].value ? [{
                        dRuleQualifierId: qualifierData.itemQualifierId,
                        dTypeCode: PromotionMaintainPatternForm.values.dTypeCode,
                        dValue: +PromotionMaintainPatternForm.values.dValue || +PromotionMaintainPatternForm.values.valueAmount,
                        promotionRuleQualifierItemModels: (isEmpty(qualifierData.promotionItemQualifierItem) ? [] :
                            qualifierData.promotionItemQualifierItem.map((data2, i2) => ({
                                id: 0,//data2.id,
                                mxmId: 0,
                                ruleQualifierId: data2.itemQualifierId,
                                itemQualifierItemId: data2.itemQualifierItemId,
                                articleNo: data2.productCode,
                                articleName: data2.temName,
                                qty: data2.qty,
                                unitCode: data2.unitCode,
                            })))
                    }] : null,
                    promotionRuleDiscountLines: PromotionMaintainPatternForm.values.pattern === promotionPattern[1].value ? [{
                        dRuleQualifierId: qualifierData.itemQualifierId,
                        dPromotionType: PromotionMaintainPatternForm.values.dPromotionType,
                        dTotal: PromotionMaintainPatternForm.values.dPromotionType === 'Bill' ?
                            +PromotionMaintainPatternForm.values.valueAmount : 0,
                        dTypeCode: PromotionMaintainPatternForm.values.dTypeCode,
                        dValue: PromotionMaintainPatternForm.values.dPromotionType === 'Product' ?
                            +PromotionMaintainPatternForm.values.valueAmount : 0,
                        dMessage: PromotionMaintainPatternForm.values.dMessage,
                        value: PromotionMaintainPatternForm.values.value,
                        promotionRuleQualifierItemModels: (isEmpty(qualifierData.promotionItemQualifierItem) ? [] :
                            qualifierData.promotionItemQualifierItem.map((data2, i2) => ({
                                id: 0,//data2.id,
                                mxmId: 0,
                                ruleQualifierId: data2.itemQualifierId,
                                itemQualifierItemId: data2.itemQualifierItemId,
                                articleNo: data2.productCode,
                                articleName: data2.temName,
                                qty: data2.qty,
                                unitCode: data2.unitCode,
                            })))
                    }] : null,
                    promotionRuleDiscountQtyHeads: PromotionMaintainPatternForm.values.pattern === promotionPattern[2].value ?
                        qtyDiscountAndList.map((data, i) => ({
                            ruleQualifierId: data.ruleQualifierId,
                            dQualifierId: get(data, 'promotionGifts[0].itemQualifierId', null),
                            qtyType: data.qtyType,
                            totalValue: isTotal(data.qtyType, +data.totalQuatity),
                            sequenceId: i + 1,
                            rewardType: data.discountAndGiveAwayType,
                            dTypecode: data.dTypecode,
                            dValue: data.dValue,
                            promotionGifts: data.discountAndGiveAwayType === "ของแถม" ? data.promotionGifts.map((data2, i2) => ({
                                dQualifierId: data2.itemQualifierId,
                                articleNo: data2.productCode,
                                itemQualifierItemId: data2.itemQualifierItemId,
                                qty: data2.qtys,
                                unitCode: data2.unitCode,
                                sequenceId: i + 1
                            })) : [],
                            promotionRuleDiscountQtyItemModels: data.promotionRuleDiscountQtyItemModels.map((data3, i3) => ({
                                itemQualifierItemId: data3.itemQualifierItemId,
                                articleNo: data3.productCode,
                                qty: isItem_quantity(data.qtyType, data3.qty),
                                unitCode: data3.unitCode,
                                qtySequenceId: i + 1//i3 + 1
                            }))
                        })) : null,
                    promotionRuleMixAndMatchHeads: PromotionMaintainPatternForm.values.pattern === promotionPattern[3].value ?
                        mixAndMatchList.map((data, i) => ({
                            dQualifierId: data.ruleQualifierId,
                            mxmType: data.qtyType,
                            totalValue: +data.totalQuatity,
                            sequenceId: i + 1,
                            rewardType: promotionDiscountMixAndMatch.rewardType,
                            dTypecode: promotionDiscountMixAndMatch.dTypecode,
                            dValue: promotionDiscountMixAndMatch.dValue,
                            promotionRuleMixAndMatchItemModels: data.promotionRuleMixAndMatchItemModels.map((data2, i2) => ({
                                articleNo: data2.productCode,
                                ruleQualifierId: data.ruleQualifierId,
                                itemQualifierItemId: data2.itemQualifierItemId,
                                qty: data2.qty,
                                unitCode: data2.unitCode,
                                qtySequenceId: i2 + 1
                            }))
                        })) : null,
                    promotionGifts: PromotionMaintainPatternForm.values.pattern === promotionPattern[3].value ?
                        promotionGiftList.data.map((data, i) => ({
                            // dQualifierId: data.itemQualifierId,
                            dQualifierId: promotionGiftList.dQualifierId || data.dQualifierId,
                            itemQualifierItemId: data.itemQualifierItemId,
                            articleNo: data.productCode,
                            articleName: data.temName,
                            dQualifierDescription: data.dQualifierDescription,
                            dQualifierName: data.dQualifierName,
                            unitCode: data.unitCode,
                            qty: data.qtys,
                            sequenceId: i + 1
                        })) : null,
                    promotionVouchers: PromotionMaintainPatternForm.values.pattern === promotionPattern[4].value ?
                        voucherList.map((data) => ({
                            voucherCode: data.voucherCode,
                            effectiveDate: data.dateEffective,
                            expireDate: data.dateExpire,
                            status: data.active,
                            qualifierId: qualifierData.itemQualifierId,
                            voucherValue: data.voucherValue,
                            promotionRuleQualifierItemModels: (isEmpty(qualifierData.promotionItemQualifierItem) ? [] :
                                qualifierData.promotionItemQualifierItem.map((data2, i2) => ({
                                    id: 0,//data2.id,
                                    mxmId: 0,
                                    ruleQualifierId: data2.itemQualifierId,
                                    itemQualifierItemId: data2.itemQualifierItemId,
                                    articleNo: data2.productCode,
                                    articleName: data2.temName,
                                    qty: data2.qty,
                                    unitCode: data2.unitCode,
                                })))
                        })) : null
                }
                this.setState({ isLoading: true })
                await apiServices.callApi("post", API_PATH.POST_PROMOTION, params).then(async (res) => {
                    if (res.data.status === "Error") {
                        swal("error", res.data.message, "error")
                    } else {
                        let promotionCode = get(res, 'data.results.0.promotionCampaign.code')
                        swal('success', `${res.data.message}: ${promotionCode}`, 'success')
                        this.props.history.push(ROUTH_PATH.PROMOTION_MAINTAIN_EDIT_LINK + '/' + promotionCode)
                    }
                }).catch(error => {
                    if (error.response.status === 400) {
                        const message = error.response.data.errors ? Object.values(error.response.data.errors).flat().join() : 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง';
                        swal('Error', message, 'error')
                    } else {
                        const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง!')
                        swal('Error', message, 'error')
                    }
                }).finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
            }
        }
    }
    //  get data from PromotionMaintainPatternForm
    getQualifier = (data) => {
        this.setState({
            qualifierData: data
        })
    }
    dataPromotionQuatityDiscount = (data) => {
        this.setState({
            qtyDiscountAndList: data
        })
    }
    getDataPromotionMixAndMatchList = (data) => {
        this.setState({
            mixAndMatchList: data
        })
    }
    getDataVoucherList = (data) => {
        this.setState({
            voucherList: data
        })
    }

    getDataPromotionGiftList = (data) => {
        this.setState({
            promotionGiftList: data
        })
    }
    getDataDiscountMixAndMatch = (data) => {
        this.setState({
            promotionDiscountMixAndMatch: data
        })
    }

    // -------------------------------------------------------------
    handleChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleChangeGetSupprotBy = async (e) => {
        let urlWithParams = `${API_PATH.GET_PROMOTION_MASTER_SUPPORT_BY}?SupportType=${e}`
        await apiServices.callApi("get", urlWithParams).then(res => {
            //console.log("res==>", res);
            let dataSupportBy = res.data.results.map(data => ({
                label: data.supportBy,
                value: data.supportBy
            }))
            this.setState({
                dataSupportBy: dataSupportBy
            })
        }).catch(error => {
            swal("error", error.response, 'error')
        })
    }

    render() {
        const {
            dataSelectedQualifier,
            submitViewError,
            dataSupportBy,
            isLoading,
            initialValuesInfo,
            initialValuesCondition,
            initialValuesPattern,
            patternValue,
            dataRuleBundles,
            dataOutlet,
            dataCustomerGroup,
            dataChannal,
            promotionCode,
            qtyDiscountAndList,
            promotionDiscountMixAndMatch,
            promotionGiftList,
            mixAndMatchList,
            voucherList,
            qualifierData,
            dataPromotionStatus,
            permissionPage,
        } = this.state;
        // console.log('P',this.props.location);

        return (
            <div className="promotion-maintain-content">
                <Spin spinning={isLoading} style={{ height: '100%' }}>
                    <TitleHead text={`ข้อมูลการส่งเสริมการขาย: (${promotionCode ? promotionCode : 'auto'})`} icon="gift" />
                    <TabCustom
                        height="calc(100% - 86px)"
                        tabContent={[
                            {
                                titleTab: 'ข้อมูลทั่วไป',
                                nodeContent: <PromotionMaintainInfoForm
                                    submitViewError={submitViewError}
                                    dataSupportBy={dataSupportBy}
                                    handleChangeGetSupprotBy={this.handleChangeGetSupprotBy}
                                    dataPromotionStatus={dataPromotionStatus}
                                    initialValues={initialValuesInfo}
                                    locationPath={this.props.location}
                                />
                            },
                            {
                                titleTab: 'กำหนดรูปแบบโปรโมชั่น',
                                nodeContent: <PromotionMaintainPatternForm
                                    dataSelectedQualifier={dataSelectedQualifier}
                                    submitViewError={submitViewError}
                                    // get from promotion pattern form
                                    dataPromotionQuatityDiscount={this.dataPromotionQuatityDiscount}
                                    getDataVoucherList={this.getDataVoucherList}
                                    getDataPromotionGiftList={this.getDataPromotionGiftList}
                                    getDataDiscountMixAndMatch={this.getDataDiscountMixAndMatch}
                                    getDataPromotionMixAndMatchList={this.getDataPromotionMixAndMatchList}
                                    getQualifier={this.getQualifier}
                                    //  initial value
                                    initialValues={initialValuesPattern}
                                    patternValue={patternValue}
                                    dataRuleBundles={dataRuleBundles}
                                    qtyDiscountAndList={qtyDiscountAndList}
                                    mixAndMatchList={mixAndMatchList}
                                    promotionDiscountMixAndMatch={promotionDiscountMixAndMatch}
                                    promotionGiftList={promotionGiftList}
                                    voucherList={voucherList}
                                    qualifierData={qualifierData}
                                // ---------
                                />
                            },
                            {
                                titleTab: 'เงื่อนไข',
                                nodeContent: <PromotionMaintainConditionForm
                                    submitViewError={submitViewError}
                                    initialValues={initialValuesCondition}
                                    dataOutlet={dataOutlet}
                                    dataCustomerGroup={dataCustomerGroup}
                                    dataChannal={dataChannal}
                                />
                            },
                        ]}
                    />
                    <FooterButton
                        nodeLeft={
                            [
                                {
                                    button: <ButtonCustom icon="reload" yellow text="ล้างหน้าจอ" />
                                },
                            ]
                        }
                        nodeRight={
                            [
                                {
                                    button: <ButtonCustom
                                        icon="save"
                                        blue
                                        text="บันทึก"
                                        onClick={this.handleClickSavePromotion}
                                        disabled={promotionCode ? !permissionPage.authUpdate : !permissionPage.authCreate}
                                    />
                                },
                                {
                                    button: <Link to={ROUTH_PATH.PROMOTION}>
                                        <ButtonCustom
                                            icon="arrow-right"
                                            type="danger"
                                            text="ยกเลิก"
                                        />
                                    </Link>
                                },
                            ]
                        }
                    />
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth,
        PromotionMaintainInfoForm: state.form.PromotionMaintainInfoForm,
        PromotionMaintainConditionForm: state.form.PromotionMaintainConditionForm,
        PromotionMaintainPatternForm: state.form.PromotionMaintainPatternForm
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPromotionPattern: (data) => dispatch(change(`PromotionMaintainPatternForm`, `pattern`, data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(PromotionMaintainContainer);