import { AUTH } from 'actions/actionsTypes'

const AuthReducer = (state = false, action) => {
    switch (action.type) {
        case AUTH:
            return action.authData;
        default:
            return state;
    }
}

export default AuthReducer