import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { NumberFormatCustomStyled } from './styled'
import './style.css';

export class NumberFormatCustom extends Component {
    render() {
        const {
            label,
            value,
            displayType,
            thousandSeparator,
            prefix,
            format,
            inputmode,
            mask,
            allowEmptyFormatting,
            className,
            onChange,

            //Styled
            isError,
            small,
            widthSmall,
            widthDefault,
            right,
            labelColor,
            borderRadius,
            widthAll,
            decimalScale,
            disabled,

            ...rest
        } = this.props

        return (
            <NumberFormatCustomStyled
                error={isError}
                small={small}
                widthSmall={widthSmall}
                widthDefault={widthDefault}
            >
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div
                                className="label-style"
                                style={{ textAlign: right ? 'right' : '', color: labelColor || '' }}
                            >
                                {label}
                            </div>
                        }
                        <NumberFormat
                            {...rest}
                            className={`${displayType !== 'text' && 'ant-input ant-input-sm'} input-style ${className || ''}`}
                            onChange={e => {
                                onChange && onChange(e)
                            }}

                            value={value}
                            displayType={displayType}
                            thousandSeparator={thousandSeparator ? thousandSeparator : true}
                            prefix={prefix}
                            format={format}
                            //inputmode={inputmode}
                            mask={mask}
                            disabled={disabled}
                            allowEmptyFormatting={allowEmptyFormatting}
                            decimalScale={decimalScale}
                            style={{
                                borderRadius: borderRadius || 4,
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    widthAll ? widthAll : ''
                                ,
                                borderColor: isError ? 'red' : '',
                            }}
                        />
                    </div>
                </div>
            </NumberFormatCustomStyled>
        )
    }
}
