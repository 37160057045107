/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, uniqBy, map, find } from 'lodash'
import moment from 'moment'
import swal from '@sweetalert/with-react'
import queryString from 'query-string'
import { reset } from 'redux-form'
import { Row, Col, Spin, message } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { ROUTH_PATH, functionAlias } from 'routes'
import { formatNumber, getPermissionPage, isOutletIdAuth } from 'helpers'
import CnSaleInfoFormRedux from './CnSaleInfoFormRedux'
import CnCustomerInfoForm from './CnCustomerInfoForm'
import BillingItemModal from './BillingItemModal'
import SaleOrderItemModal from './SaleOrderItemModal'
import CnResultSaveModal from './CnResultSaveModal'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    SelectCustom,
    InputCustom,
    InputSearchCustom,
    NumberFormatCustom,
    TextAreaCustom,
} from 'components/common'
import {
    ProductSearchModal,
    StoreLocationModal,
    SaleUnitModal,
} from 'components/feature'
import {
    SaleSummary,
} from 'components/form'

import './style.css'

const documentStatuses = [
    { label: 'เปิด', value: 'open' },
    { label: 'ไม่อนุมัติ', value: 'reject' },
    { label: 'อนุมัติแล้ว', value: 'approve' },
    { label: 'สมบูรณ์', value: 'complete' },
]

const stockTypes = [
    { label: 'UR - พร้อมขาย', value: 'UR' },
    { label: 'QI - รอตรวจสอบ', value: 'QI' },
    { label: 'BS - ระงับ', value: 'BS' },
]

const cnItemStatuses = [
    { label: 'ยืนยัน', value: 1 },
    { label: 'ยกเลิก', value: 0 },
]

const initialProduct = {
    barcode: '',
    productCode: '',
}

const customerDefault = {
    customerId: 'OTC99',
    customerNameTH: 'ลูกค้าทั่วไป',
    customerCreditAllow: false,
    customerCreditLimit: 0,
    customerUsedCredit: 0,
}

/*
28/05/2020
=== summary ===

cn 2 types
1. with billing ref
    - input ref number
    - check duplicate billing, if duplicated -> show error modal
    - get billing detail from api, if billing not found -> goto without billing ref type (2)
    - when billing found, get sale order detail from api for customer data
    - check document type code, read more -> checkDocumentTypeCode()
    - if invalid -> show error modal
    - else valid
    1.1 type = ZPMT, get billing document type code = ZINT from api
        show modal for select billing items and check duplicate billing when selected
        if valid, will show detail
    1.2 type <> ZPMT show detail

2. without billing ref
    - if billing not found can add products and input in form

*/

class CNDNMaintainContainer extends Component {
    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    getInitialState = () => {
        const queryStringValue = queryString.parse(this.props.location.search)
        const permissionPage = getPermissionPage(functionAlias.creditNoteMaintain)
        return {
            // จ้า
            columns: [
                {
                    title: '',
                    dataIndex: 'delele',
                    key: 'delele',
                    align: 'center',
                    width: 45,
                    render: (props, row, index) => {
                        if (this.state.isWithoutBillingRef && this.state.products.length - 1 === index) {
                            return null
                        }
                        const documentType = get(this.state, 'saleInfo.documentTypeCode', '')
                        if (documentType === 'ZCRD') {
                            return null
                        }
                        // console.log('documentType',documentType);
                        // 
                        // documentTypeCode
                        return <ButtonCustom
                            text="ลบ"
                            small
                            type="danger"
                            onClick={() => this.deleteProduct(index)}
                            disabled={row.saleSOItemId}
                        />
                    },
                },
                {
                    title: 'รายการ',
                    dataIndex: 'index',
                    key: 'index',
                    align: 'center',
                    width: 60,
                    render: (props, row, index) => (
                        <div>{index + 1}</div>
                    ),
                },
                {
                    title: 'บาร์โค้ด',
                    dataIndex: 'barcode',
                    key: 'barcode',
                    align: 'center',
                    width: 140,
                    render: (props, row, index) => (
                        (this.state.isWithoutBillingRef && this.state.products.length - 1 === index) ?
                            <InputCustom
                                small
                                onPressEnter={() => this.searchProduct(row)}
                                onChange={(e) => this.changeProductData('barcode', e.target.value, index)}
                            /> : props
                    )
                },
                {
                    title: 'รหัสสินค้า',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    align: 'center',
                    width: 140,
                    render: (props, row, index) => (
                        (this.state.isWithoutBillingRef && this.state.products.length - 1 === index) ?
                            <InputSearchCustom
                                small
                                onSubmit={() => this.searchProduct(row)}
                                onChange={(e) => this.changeProductData('productCode', e.target.value, index)}
                                onClickSearch={() => this.openModal('product')}
                            /> : props
                    )
                },
                {
                    title: 'ชื่อสินค้า',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: 'center',
                    width: 180,
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'คลัง',
                    dataIndex: 'slocId',
                    key: 'slocId',
                    align: 'center',
                    render: (props, row, index) => (
                        (this.state.isWithoutBillingRef && this.state.products.length - 1 !== index) ? <ButtonCustom
                            text={props}
                            small
                            type="link"
                            onClick={() => this.getStoreLocations(row, index)}
                        /> : props
                    ),
                },
                {
                    title: 'หน่วยขาย',
                    dataIndex: 'unit',
                    key: 'unit',
                    align: 'center',
                    render: (props, row, index) => (
                        (this.state.isWithoutBillingRef && this.state.products.length - 1 !== index) ? <ButtonCustom
                            text={props}
                            small
                            type="link"
                            onClick={() => this.getUnit(row, index)}
                        /> : props
                    ),
                },
                {
                    title: 'จำนวนตามใบกำกับเดิม',
                    dataIndex: 'referenceBillQty',
                    key: 'referenceBillQty',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => (
                        (!this.state.isWithoutBillingRef || (this.state.isWithoutBillingRef && this.state.products.length - 1 !== index)) && <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeProductData('referenceBillQty', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                            value={props}
                            decimalScale={3}
                            disabled={!this.state.isWithoutBillingRef}
                        />
                    ),
                },
                {
                    title: 'จำนวนที่ยังไม่รับคืน',
                    dataIndex: 'returnQty',
                    key: 'returnQty',
                    align: 'center',
                    render: (props, row) => (
                        row.productName && <div className="text-right">{formatNumber(props, 3)}</div>
                    ),
                },
                {
                    title: 'จำนวน',
                    dataIndex: 'qty',
                    key: 'qty',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => {
                        const documentType = get(this.state, 'saleInfo.documentTypeCode', '')
                        if (documentType === 'ZCRE' || this.state.isWithoutBillingRef) {
                            if (this.state.isWithoutBillingRef && this.state.products.length - 1 === index) {
                                return null
                            }
                            return <NumberFormatCustom
                                className="text-right"
                                small
                                onChange={(e) => this.changeProductData('qty', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                                onKeyPress={(e) => e.key === 'Enter' && this.changeQtyProductData(row, index)}
                                value={props}
                                decimalScale={3}
                            />
                        } else {
                            return <div className="text-right">{formatNumber(props, 3)}</div>
                        }
                    },
                },
                {
                    title: 'ราคา/หน่วย ตามใบกำกับเดิม',
                    dataIndex: 'referenceBillPricePerUnit',
                    key: 'referenceBillPricePerUnit',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => (
                        (!this.state.isWithoutBillingRef || (this.state.isWithoutBillingRef && this.state.products.length - 1 !== index)) && <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeProductData('referenceBillPricePerUnit', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                            value={props}
                            decimalScale={3}
                            disabled={!this.state.isWithoutBillingRef}
                        />
                    ),
                },
                {
                    title: 'ราคา/หน่วย',
                    // pricePerUnitNet include discount, is not pricePerUnit
                    dataIndex: 'pricePerUnitNet',
                    key: 'pricePerUnitNet',
                    align: 'center',
                    width: 100,
                    render: (props, row, index) => {
                        const documentType = get(this.state, 'saleInfo.documentTypeCode', '')
                        return (documentType === 'ZCR' && row.productName) || (this.state.isWithoutBillingRef && this.state.products.length - 1 !== index) ? <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeProductData('pricePerUnitNet', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                            onKeyPress={(e) => e.key === 'Enter' && this.changePricePerUnitProductData(row, index)}
                            value={props}
                            decimalScale={2}
                        /> : row.productName && <div className="text-right">{formatNumber(props, 2)}</div>
                    },
                },
                {
                    title: 'จำนวนเงิน',
                    dataIndex: 'netAmount',
                    key: 'netAmount',
                    align: 'center',
                    width: 100,
                    render: (props, row) => (
                        row.productName && <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'ประเภทสต็อก',
                    dataIndex: 'returnItemCateg',
                    key: 'returnItemCateg',
                    align: 'center',
                    width: 150,
                    render: (props, row, index) => {
                        const documentType = get(this.state, 'saleInfo.documentTypeCode', '')
                        return !(this.state.isWithoutBillingRef && this.state.products.length - 1 === index)
                            && documentType === 'ZCRE' && <SelectCustom
                                options={stockTypes}
                                small
                                onChange={(val) => this.changeProductData('returnItemCateg', val, index)}
                                value={props}
                            />
                    }
                },
                {
                    title: 'สถานะรายการ',
                    dataIndex: 'cnConfirmFlag',
                    key: 'cnConfirmFlag',
                    align: 'center',
                    width: 150,
                    render: (props, row, index) => (
                        (!this.state.isWithoutBillingRef || (this.state.isWithoutBillingRef && this.state.products.length - 1 !== index)) && <SelectCustom
                            options={cnItemStatuses}
                            small
                            onChange={(val) => this.changeStatusProductData(val, index)}
                            value={props}
                            // disabled={!row.saleSOItemId || (row.saleSOItemId && !row.cnConfirmFlagInitial && !props)}
                            disabled={isEmpty(this.state.cnDetail) || (!isEmpty(this.state.cnDetail) &&
                                this.state.cnDetail.documentStatus.toLocaleLowerCase() !== 'open') ||
                                (get(this.state, 'saleInfo.documentTypeCode', '') === 'ZCRD')}
                        />
                    )
                },
            ],
            loading: false,
            products: [],
            cnSearch: {
                cnNumber: '',
                outletId: '',
            },
            saleInfo: {
                referenceDocNumber: '',
                documentDate: moment(),
                saleEmpCode: '',
                taxType: '',
                documentTypeCode: '',
                outletId: '',
                returnReasonId: '',
                saleChannelCode: '',
                documentStatus: documentStatuses[0].value,
                returnTypeId: '',
            },
            customerInfo: {
                // customerId: '',
                // customerDetail: '',
            },
            customerIdSearch: customerDefault.customerId,
            customer: customerDefault,
            outlets: [],
            saleEmployees: [],
            taxTypes: [],
            documentTypes: [],
            documentStatuses: !permissionPage.authDelete ? documentStatuses.filter(documentStatus => documentStatus.value !== 'reject') : documentStatuses,
            saleChannels: [],
            returnTypes: [],
            returnReasons: [],
            stockTypes,
            remark: '',
            totalSummary: {
                totalAmount: 0,
                netAmount: 0,
                vatAmount: 0,
                totalDiscAmount: 0,
                discPercent: 0,
                discPercentAmount: 0,
                discAmount: 0,
                discCouponPercent: 0,
                discCouponPercentAmount: 0,
                discSCGIDAmount: 0,
                discSCGIDCode: '',
                discCouponAmount: 0,
                discPromotionInternal: 0,
                discPromotionExternal: 0,
                referenceTaxInvoice: '',
                referenceNetAmount: 0,
                totalExcVatAmount: 0,
            },
            cnNumber: get(this.props, 'match.params.cnNumber'),
            outletId: get(this.props, 'match.params.outletId'),
            cnDetail: {},
            billingDetail: {},
            isErrorSubmitCnSaleInfo: false,
            isErrorSubmitCustomer: false,
            isWithoutBillingRef: false,
            isShowModal: false,
            modalType: '',
            indexRowAction: null,
            storeLocations: [],
            units: [],
            billingItems: [],
            queryStringValue,
            permissionPage,
            resultSave: {},
            isMoreRef: false,
            documentFlows: [],
            isDisabledSave: false,
            reprocessDatetime: undefined,
        }
    }

    componentDidMount() {
        const outletId = get(this.props, 'match.params.outletId', '')
        if (outletId && !isOutletIdAuth(outletId)) {
            this.getAlertAccessDenied()
            return
        }

        this.fetchDataDefault()
    }

    componentDidUpdate(prevProps) {
        const params = this.props.match.params
        const paramsPrev = prevProps.match.params
        if (params.cnNumber !== paramsPrev.cnNumber || params.outletId !== paramsPrev.outletId) {
            if (params.cnNumber && params.outletId) {
                if (!this.state.permissionPage.authDisplay) {
                    this.getAlertAccessDenied()
                    return
                }

                this.setState({ cnNumber: params.cnNumber, outletId: params.outletId }, () => this.fetchDataDefault())
            } else {
                this.fetchDataDefault()
            }
        }
    }

    getAlertAccessDenied = () => {
        swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
            this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
        })
    }

    resetState = () => {
        this.setState(this.getInitialState())
    }

    openModal = (modalType) => {
        this.setState({ isShowModal: true, modalType })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '' })
    }

    getCnDetail = async (cnNumber, outletId) => {
        await apiServices.callApi('post', API_PATH.POST_GET_CREDIT_NOTE_DETAIL, { soNumber: cnNumber, outletId }).then(async (res) => {
            const detail = get(res, 'data.results.0')
            if (res.status === 200 && !isEmpty(detail)) {
                detail.customers = detail.salesSOPartnerFunctionModels
                detail.products = detail.salesSOItemModels

                detail.billingNo1 = get(detail, 'salesSOItemModels.0.billingNo1')
                detail.billingNo2 = get(detail, 'salesSOItemModels.0.billingNo2')

                this.setState({
                    cnDetail: detail,
                    remark: detail.remark,
                    isWithoutBillingRef: detail.legacyFlag,
                    isMoreRef: detail.refDocNumber.includes(','),
                    reprocessDatetime: detail.reprocessDatetime,
                }, () => {
                    this.onSelectedDocument(detail)
                })
            } else {
                swal('Error', 'ไม่พบข้อมูลเอกสาร', 'error')
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    getSaleOrderDetail = async (soNumber, outletId) => {
        let saleOrderDetail = {}
        await apiServices.callApi('post', API_PATH.POST_GET_DETAIL_SALE_ORDER, { soNumber, outletId }).then(async (res) => {
            const detail = get(res, 'data.results.0')
            if (res.status === 200 && !isEmpty(detail)) {
                saleOrderDetail = detail
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลใบสั่งขายสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        return saleOrderDetail
    }

    getCustomers = async (customerId, outletId) => {
        let customers = []
        const url = `${API_PATH.GET_CUSTOMER}?OutletId=${outletId}&customerId=${customerId}`

        await apiServices.callApi('get', url).then(async (res) => {
            const results = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(results)) {
                customers = results
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        return customers
    }

    mappingSaleOrderCustomers = (customers) => {
        return customers.map(customer => ({
            outletId: customer.customerOutletId,
            partnerType: customer.customerRole,
            customerType: customer.customerType,
            customerId: customer.customerId,
            customerName: customer.customerNameTH,
            title: customer.customerTitle,
            taxId: customer.customerTaxId,
            taxBranch: customer.customerTaxBranch,
            scgFamilyId: customer.customerScgFamilyId || '',
            address: customer.customerAddress,
            subDistrict: customer.customerSubDistrict,
            district: customer.customerDistrict,
            province: customer.customerProvince,
            country: customer.customerCountry,
            postalCode: customer.customerPostalCode,
            phone: customer.customerPhone,
            fax: customer.customerFax || '',
            email: customer.customerEmail || '',
        }))
    }

    // get data default for dropdown
    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth: { arrayOutlet } } = this.props
        const { cnNumber, outletId } = this.state
        const isEditMode = cnNumber && outletId

        // sale order detail
        if (isEditMode) {
            await this.getCnDetail(cnNumber, outletId)
            // await this.getDocFlow()
        }

        const { cnSearch, saleInfo } = this.state

        // outlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                const outletDefault = get(outlets, '0.value', '')
                this.setState({
                    outlets,
                    cnSearch: {
                        ...cnSearch,
                        outletId: outletDefault,
                    },
                    saleInfo: (isEditMode) ? saleInfo : { ...saleInfo, outletId: outletDefault },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // tax type
        await apiServices.callApi('get', API_PATH.GET_MASTER_TAX_TYPE).then(res => {
            let taxTypes = get(res, 'data.results', [])
            if (res.status === 200) {
                taxTypes = taxTypes.map(data => (
                    { ...data, label: `${data.taxDescription} %`, value: data.taxTypeId }
                ))
                const { saleInfo } = this.state
                this.setState({
                    taxTypes,
                    saleInfo: (isEditMode) ? saleInfo : { ...saleInfo, taxType: taxTypes[0].value },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทภาษีได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // sale channel
        await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL).then(res => {
            let saleChannels = get(res, 'data.results', [])
            if (res.status === 200) {
                saleChannels = saleChannels.map(data => (
                    { ...data, label: `${data.saleChannelCode} - ${data.saleChannelDescription}`, value: data.saleChannelCode }
                ))
                const { saleInfo } = this.state
                this.setState({
                    saleChannels,
                    saleInfo: (isEditMode) ? saleInfo : { ...saleInfo, saleChannelCode: saleChannels[0].value },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทการขายได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // sale employee
        await this.getEmployees()

        // cn reason
        await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CN_REASON).then(res => {
            let returnReasons = get(res, 'data.results', [])
            if (res.status === 200) {
                returnReasons = returnReasons.map(data => (
                    { ...data, label: data.reasonDescription, value: data.returnReasonId }
                ))
                this.setState({ returnReasons })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลเหตุผลลดหนี้ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // return type
        await apiServices.callApi('get', API_PATH.GET_MASTER_SALES_RETURN_TYPE).then(res => {
            let returnTypes = get(res, 'data.results', [])
            if (res.status === 200) {
                returnTypes = returnTypes.map(data => (
                    { ...data, label: `${data.returnTypeCode} - ${data.returnTypeName}`, value: data.returnTypeId }
                ))
                this.setState({ returnTypes })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทการคืนเงินได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.getDocumentTypes(this.state.saleInfo.outletId)

        this.setState({ loading: false })
    }

    // getDocFlow = async () => {
    //     const { outletId, cnNumber: soNumber } = this.state
    //     // console.log('outletId, soNumber', outletId, soNumber);

    //     const body = {
    //         outletId,
    //         soNumber,
    //     }
    //     await apiServices.callApi('post', API_PATH.POST_GET_SALES_DOCUMENT_FLOWS, body).then(async (res) => {
    //         let documentFlows = get(res, 'data.results', [])
    //         if (res.status === 200) {
    //             const docFlowCheck = documentFlows.map(async (item, data) => {
    //                 if (item.documentTypeCode === 'ZDO') {
    //                     const GIres = await apiServices.callApi('post', API_PATH.POST_GET_SALE_DO_HEAD, { outletId, doNumber: item.documentNo })
    //                     const giStatus = get(GIres, 'data.results.0.giStatus', false)
    //                     if (!giStatus) {
    //                         item.documentTypeCode = 'SHIPMENT'
    //                         item.documentTypeDesc = 'ใบจัดสินค้า'
    //                     }
    //                 }
    //                 return item
    //             })
    //             documentFlows = await Promise.all(docFlowCheck)
    //             this.setState({ documentFlows }, () => {
    //                 // this.disabledEditData()
    //             })
    //         }
    //     }).catch(error => {
    //         // console.log(error)
    //         const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูล Document Flow กรุณาลองใหม่อีกครั้ง')
    //         swal('Error', message, 'error')
    //     })
    // }

    getStoreLocations = async (product, index) => {
        this.setState({ loading: true })
        const { auth } = this.props
        const body = {
            productId: product.productCode,
            salesUnit: product.unit,
            outletId: product.outletId,
            isIncludeStockATP: true,
            storageLocationIds: auth.arrayStorageLocationId,
            limitMaxRange: 100,
            productStatus: true
        }
        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(res => {
            let storeLocations = get(res, 'data.results')
            if (res.status === 200) {
                storeLocations = uniqBy(storeLocations, 'storageLocationId')
                this.setState({
                    storeLocations,
                    indexRowAction: index,
                }, () => {
                    this.openModal('storeLocation')
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลคลังได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onSelectedStoreLocation = (data) => {
        const { indexRowAction } = this.state
        const productSelected = data.dataSelected
        this.setState({
            products: this.state.products.map((product, i) => (
                i === indexRowAction ? {
                    ...product,
                    slocId: productSelected.storageLocationId,
                } : product
            )),
            indexRowAction: null,
        })
        this.closeModal()
    }

    getUnit = async (product, index) => {
        this.setState({ loading: true })
        const body = {
            productId: product.productCode,
            storageLocationId: product.slocId,
            outletId: product.outletId,
            limitMaxRange: 100,
            productStatus: true
        }
        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${this.props.auth.userId}`, body).then(res => {
            let units = get(res, 'data.results')
            units = units.map(unit => ({
                ...unit,
                pricePerUnit: this.calculatePrice(unit)
            }))
            if (res.status === 200) {
                this.setState({
                    units,
                    indexRowAction: index,
                }, () => {
                    this.openModal('unit')
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลหน่วยสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onSelectedUnit = (productSelected) => {
        const { indexRowAction } = this.state
        this.setState({
            products: this.state.products.map((product, i) => (
                i === indexRowAction ? {
                    ...product,
                    unit: productSelected.unitId,
                    barcode: productSelected.barcode,
                    pricePerUnit: productSelected.pricePerUnit,
                    totalAmount: productSelected.pricePerUnit * product.qty,
                    pricePerUnitNet: productSelected.pricePerUnit,
                } : product
            )),
            indexRowAction: null,
        }, () => this.calculateTotalSummary())
        this.closeModal()
    }

    deleteProduct = (index) => {
        const products = [...this.state.products]
        products.splice(index, 1)
        this.setState({ products }, () => {
            this.calculateTotalSummary()
        })
    }

    searchProduct = async (product) => {
        const { CnSaleInfoFormRedux: { values } } = this.props
        const { barcode, productCode } = product
        if (!barcode && !productCode) {
            message.error('กรุณาระบุบาร์โค้ดหรือรหัสสินค้า')
            return
        }
        this.setState({ loading: true })
        const { auth } = this.props
        const body = {
            barcode,
            productId: productCode,
            outletId: values.outletId,
            storageLocationIds: auth.arrayStorageLocationId,
            limitMaxRange: 100,
            productStatus: true
        }

        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(res => {
            const products = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(products)) {
                let productSelected = products.find(product => product.unitId === product.salesUnit)
                if (!productSelected) productSelected = products[0]
                this.onSelectedProduct(productSelected)
            } else {
                message.error('ไม่พบข้อมูลสินค้า')
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    calculatePrice = (product) => {
        const { CnSaleInfoFormRedux: { values } } = this.props
        return +values.saleChannelCode === 10 ? product.retailPrice : product.projectPrice
    }

    calculateCost = (product) => {
        const { CnSaleInfoFormRedux: { values } } = this.props
        return +values.saleChannelCode === 10 ? product.retailCost : product.retailCost
    }

    // case without billing ref
    onSelectedProduct = (product) => {
        this.closeModal()
        const { products } = this.state
        const newProducts = [...products]
        newProducts.splice(-1, 1)

        const qty = 1
        const pricePerUnit = this.calculatePrice(product)
        const productMapped = {
            barcode: product.barcode,
            productCode: product.productId,
            productName: product.productNameMarket,
            stockType: product.stockType,
            slocId: product.storageLocationId,
            unit: product.unitId,
            outletId: product.outletId,
            qty,
            freeGoodsFlag: product.freeGoodsFlag,
            costPerUnit: this.calculateCost(product),
            pricePerUnit,
            pricePerUnitNet: pricePerUnit,
            netAmount: pricePerUnit * qty,
            totalAmount: pricePerUnit * qty,
            referenceBillQty: qty,
            returnQty: qty,
            // referenceBillValue: pricePerUnit,
            referenceBillPricePerUnit: pricePerUnit,
            projectCost: product.projectCost,
            projectPrice: product.projectPrice,
            retailCost: product.retailCost,
            retailPrice: product.retailPrice,
            cnConfirmFlag: 1,
        }

        this.setState({
            products: [...newProducts, productMapped, initialProduct],
        }, () => {
            this.calculateTotalSummary()
        })
    }

    getEmployees = async () => {
        const { cnDetail, saleInfo } = this.state
        const isEditMode = !isEmpty(cnDetail)

        await apiServices.callApi('get', `${API_PATH.GET_SALE_EMPLOYEE}?OutletId=${saleInfo.outletId}`).then(res => {
            let saleEmployees = get(res, 'data.results', [])
            if (res.status === 200) {
                saleEmployees = saleEmployees.map(data => (
                    { ...data, label: `${data.saleEmployeeId} - ${data.saleEmployeeName}`, value: data.saleEmployeeId }
                ))
                this.setState({
                    saleEmployees,
                    saleInfo: (isEditMode) ? saleInfo : { ...saleInfo, saleEmpCode: saleEmployees[0].value },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลพนักงานขายได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    getDocumentTypes = (outletId) => {
        const { cnDetail, saleInfo, outlets } = this.state
        const isEditMode = !isEmpty(cnDetail)

        let documentTypes = []
        const outlet = outlets.find(outlet => outlet.outletId === outletId)
        if (outlet) {
            switch (outlet.outletRole.toLowerCase()) {
                case 'franchise':
                    documentTypes = [
                        { label: 'ZCRE - ใบขอคืนสินค้า', value: 'ZCRE', documentTypeDescription: 'ใบขอคืนสินค้า' },
                        { label: 'ZCRR - ใบขอลดหนี้ค้างจ่าย', value: 'ZCRR', documentTypeDescription: 'ใบขอลดหนี้ค้างจ่าย' },
                        { label: 'ZCRD - ใบขอลดหนี้เงินมัดจำ', value: 'ZCRD', documentTypeDescription: 'ใบขอลดหนี้เงินมัดจำ' },
                    ]
                    break
                case 'coco':
                case 'boutique':
                    documentTypes = [
                        { label: 'ZCR - ใบขอลดหนี้ราคา', value: 'ZCR', documentTypeDescription: 'ใบขอลดหนี้ราคา' },
                        { label: 'ZCRE - ใบขอคืนสินค้า', value: 'ZCRE', documentTypeDescription: 'ใบขอคืนสินค้า' },
                        { label: 'ZCRR - ใบขอลดหนี้ค้างจ่าย', value: 'ZCRR', documentTypeDescription: 'ใบขอลดหนี้ค้างจ่าย' },
                        { label: 'ZCRD - ใบขอลดหนี้เงินมัดจำ', value: 'ZCRD', documentTypeDescription: 'ใบขอลดหนี้เงินมัดจำ' },
                    ]
                    break
                default:
            }
        }

        this.setState({
            documentTypes,
            saleInfo: (isEditMode || isEmpty(documentTypes)) ? saleInfo : { ...saleInfo, documentTypeCode: documentTypes[0].value }
        })
    }

    changeSaleInfo = (key, value) => {
        const { isWithoutBillingRef, products } = this.state
        const { CnSaleInfoFormRedux: { values } } = this.props

        switch (key) {
            case 'outletId':
                // if outlet id changed - clear employee, fetch new employees
                this.setState({ saleInfo: { ...values, [key]: value } }, () => {
                    this.getEmployees()
                })

                // clear products
                if (isWithoutBillingRef) {
                    this.setState({ products: [initialProduct] })
                }
                break
            case 'saleChannelCode':
                // change price products
                this.setState({ saleInfo: { ...values, [key]: value } }, () => {
                    if (isWithoutBillingRef) {
                        this.setState({
                            products: products.map(product => {
                                const pricePerUnit = this.calculatePrice(product)
                                return ({
                                    ...product,
                                    pricePerUnit,
                                    pricePerUnitNet: pricePerUnit,
                                    costPerUnit: this.calculateCost(product),
                                    // totalAmount: pricePerUnit * product.qty,
                                    netAmount: pricePerUnit * product.qty,
                                })
                            }),
                        }, () => {
                            this.calculateTotalSummary()
                        })
                    }
                })
                break
            case 'documentTypeCode':
                this.setState({ saleInfo: { ...values, [key]: value, returnReasonId: '', returnTypeId: '' } }, () => {
                    if (value === 'ZCRR') {
                        this.openModal('saleOrderItem')
                    }
                })
                break
            default:
                this.setState({ saleInfo: { ...values, [key]: value } })
        }
    }

    onSelectedDocument = (document) => {
        const { cnDetail, totalSummary, isWithoutBillingRef, isMoreRef } = this.state
        const { CnSaleInfoFormRedux: { values } } = this.props

        const customer = document.customers.find(partner => partner.partnerType === 'sold_to') || {}
        const isEditMode = !isEmpty(cnDetail)

        const productsMapped = document.products
            .sort(({ billingItem: previousItem }, { billingItem: currentItem }) => previousItem - currentItem)
            .map(item => {
                // include discount
                let pricePerUnitNet = 0
                if (isEditMode && !item.cnConfirmFlag) {
                    pricePerUnitNet = ((item.pricePerUnit * item.referenceBillQty) - item.totalDiscAmount) / item.referenceBillQty
                } else if ((isEditMode && cnDetail.documentTypeCode === 'ZCRD') || (!isEditMode && values.documentTypeCode === 'ZCRD')) {
                    pricePerUnitNet = item.pricePerUnit
                } else {
                    pricePerUnitNet = item.netAmount / item.qty
                }

                const refQty = isEditMode ? item.qty : values.documentTypeCode === 'ZCRR' ? item.referenceBillQty : item.qty
                return ({
                    ...item,
                    referenceBillQty: isEditMode ? item.referenceBillQty : refQty,
                    // referenceBillValue: isEditMode ? item.referenceBillValue : item.pricePerUnit,
                    referenceBillPricePerUnit: isEditMode ? item.referenceBillValue / item.referenceBillQty : item.pricePerUnit,
                    pricePerUnitNet,
                    netAmount: pricePerUnitNet * item.qty,
                    referenceDiscAmountPerUnit: item.discAmount ? item.discAmount / refQty : 0,
                    referencePromotionInternalPerUnit: item.discPromotionInternal ? item.discPromotionInternal / refQty : 0,
                    referencePromotionExternalPerUnit: item.discPromotionExternal ? item.discPromotionExternal / refQty : 0,
                    referenceDiscAmount: isEditMode ? item.referenceDiscAmount : item.discAmount,
                    referenceDiscPromotionInternal: isEditMode ? item.referenceDiscPromotionInternal : item.discPromotionInternal,
                    referenceDiscPromotionExternal: isEditMode ? item.referenceDiscPromotionExternal : item.discPromotionExternal,
                    // can change from input screen
                    cnConfirmFlag: isEditMode ? +item.cnConfirmFlag : 1,
                    // cannot change from input screen
                    cnConfirmFlagInitial: isEditMode ? +item.cnConfirmFlag : 1,
                    returnQty: item.returnQty || 0,
                })
            })

        this.setState({
            saleInfo: {
                ...values,
                referenceDocNumber: isEditMode ? document.refDocNumber : document.billingNo,
                documentDate: isEditMode ? moment(document.documentDate) : moment(),
                saleEmpCode: document.saleEmpCode,
                taxType: document.taxType,
                documentTypeCode: isEditMode ? document.documentTypeCode : values.documentTypeCode,
                outletId: isEditMode ? document.outletId : values.outletId,
                saleChannelCode: document.saleChannelCode,
                documentStatus: isEditMode ? document.documentStatus : 'open',
                returnReasonId: isEditMode ? document.returnReasonId : values.returnReasonId,
                returnTypeId: isEditMode ? document.returnTypeId : '',//values.returnTypeId, //clear returnTypeId when change doccument
            },
            customerInfo: customer,
            products: isWithoutBillingRef ? [...productsMapped, initialProduct] : productsMapped,
            totalSummary: {
                ...totalSummary,
                discPercent: Number(document.discPercent || 0),
                discPercentAmount: Number(document.discPercentAmount || 0),
                discAmount: Number(document.discAmount || 0),
                discCouponPercent: Number(document.discCouponPercent || 0),
                discCouponPercentAmount: Number(document.discCouponPercentAmount || 0),
                discSCGIDAmount: Number(document.discSCGIDAmount || 0),
                discSCGIDCode: document.discSCGIDCode || '',
                discCouponAmount: Number(document.discCouponAmount || 0),
                discPromotionInternal: Number(document.discPromotionInternal || 0),
                discPromotionExternal: Number(document.discPromotionExternal || 0),
                totalDiscAmount: Number(document.totalDiscAmount || 0),
                //  referenceTaxInvoice: isEditMode ? document.referenceTaxInvoice :map(document.salesBillingInvoice, 'taxInvoiceNo'),
                referenceTaxInvoice: isEditMode ? document.referenceTaxInvoice : document.salesBillingInvoice && map(document.salesBillingInvoice, 'taxInvoiceNo').join(),
                referenceNetAmount: isEditMode ? document.referenceNetAmount : document.netAmount || 0,
            },
            billingDetail: document,
            // isMoreRef: isEditMode ? document.refDocNumber === 'อ้างอิงมากกว่า 1 ใบ' : isMoreRef
            isMoreRef,
        }, () => {
            this.calculateTotalSummary()
        })
    }

    onChangeCustomerInfo = (customer) => {
        this.setState({
            customerInfo: {
                ...this.state.customerInfo,
                ...customer,
            },
        }, () => {
            const valid = this.validateCustomer(this.state.customerInfo)
            this.setState({ isErrorSubmitCustomer: valid.isError })
        })
    }

    onSubmitDiscount = (discountSummary) => {
        this.setState({
            totalSummary: { ...this.state.totalSummary, ...discountSummary }
        }, () => this.calculateTotalSummary())
    }

    changeRemark = (e) => {
        this.setState({ remark: e.target.value })
    }

    changeProductData = (key, value, index) => {
        switch (key) {
            case 'referenceBillQty':
                this.setState({
                    products: this.state.products.map((product, i) => (i === index ? { ...product, [key]: value, returnQty: value } : product)),
                })
                break
            default:
                this.setState({
                    products: this.state.products.map((product, i) => (i === index ? { ...product, [key]: value } : product)),
                })
        }
    }

    changeStatusProductData = (status, index) => {
        const products = this.state.products.map((product, i) =>
            i === index ? { ...product, cnConfirmFlag: status } : product
        )
        // const rejectedAll = products.filter(product => product.cnConfirmFlag === 0)
        const rejectedAll = products.every(product => product.cnConfirmFlag === 0)
        if (rejectedAll) {
            this.changeSaleInfo('documentStatus', 'reject')
        }

        this.setState({ products }, () =>
            this.calculateTotalSummary()
        )
    }

    changeQtyProductData = (row) => {
        const { totalSummary } = this.state
        this.setState({
            // products: this.state.products.map((product, i) =>
            //     (i === index ? {
            //         ...product,
            //         netAmount: row.pricePerUnitNet * row.qty,
            //         // netAmount: row.qty * (row.referenceBillQty / row.totalDiscAmount),
            //     } : product)
            // ),
            // clear percent amount 
            totalSummary: {
                ...totalSummary,
                discPercentAmount: 0,
                discCouponPercentAmount: 0,
            }
        }, () => {
            this.calculateTotalSummary()
            if (row.qty > row.returnQty) {
                swal('Warning', 'กรุณาระบุจำนวนต้องไม่มากกว่าจำนวนที่ยังไม่รับคืน', 'warning')
            } else {
                message.success('แก้ไขจำนวนสินค้าสำเร็จ')
            }
        })
    }

    changePricePerUnitProductData = (row, index) => {
        const { totalSummary } = this.state
        this.setState({
            products: this.state.products.map((product, i) =>
                (i === index ? {
                    ...product,
                    netAmount: row.pricePerUnitNet * row.qty,
                    // netAmount: row.qty * (row.referenceBillQty / row.totalDiscAmount),
                } : product)),
            // clear percent amount 
            totalSummary: {
                ...totalSummary,
                discPercentAmount: 0,
                discCouponPercentAmount: 0,
            }
        }, () => {
            this.calculateTotalSummary()
            message.success('แก้ไขราคาสำเร็จ')
        })
    }

    getStatusCnDn = async (refBillingNumber, outletId, docType, refBillingItem) => {
        let result = {}
        const body = {
            refBillingNumber,
            outletId,
            docType,
            refBillingItem,
        }
        await apiServices.callApi('post', API_PATH.POST_CREDIT_NOTE_CHECK_STATUS_CNDN, body).then(async (res) => {
            if (res.status === 200) {
                result = get(res, 'data')
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลใบสั่งขายสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        return result
    }

    searchReferenceDocNumber = async (refDocNumber) => {
        if (!refDocNumber) {
            message.error('กรุณากรอกเลขที่เอกสาร')
            return
        }
        this.setState({ loading: true })
        const { saleInfo } = this.state

        // check duplicate cn
        const resStatus = await this.getStatusCnDn(refDocNumber, saleInfo.outletId, saleInfo.documentTypeCode)
        if (resStatus.status && (resStatus.status.toLocaleLowerCase() === 'found' || resStatus.status.toLocaleLowerCase() === 'nottoday')) {
            swal('Error', resStatus.message, 'error')
            this.setState({ loading: false })
            return
        }

        const param = [
            `OutletId=${saleInfo.outletId}`,
            `BillingNo=${refDocNumber}`,
        ]
        await apiServices.callApi('get', `${API_PATH.GET_CASHRECEIVE}?${param.join('&')}`).then(async res => {
            const document = get(res, 'data.results.0')
            if (res.status === 200 && !isEmpty(document)) {
                let partners = []
                if (document.conversionFlag) {
                    if (document.customerId && document.outletId) {
                        if (document.customerId === 'OTC99') {
                            partners = this.mappingSaleOrderCustomers([{
                                customerOutletId: document.outletId,
                                customerRole: 'sold_to',
                                customerId: document.customerId,
                                customerNameTH: 'ลูกค้าทั่วไป',
                            }])
                        } else {
                            let customers = await this.getCustomers(document.customerId, document.outletId)
                            if (!isEmpty(customers)) {
                                customers = this.mappingSaleOrderCustomers(customers)
                            }
                            partners = customers
                        }
                    }
                } else {
                    const saleOrderDetail = await this.getSaleOrderDetail(document.soNumber, document.outletId)
                    partners = !isEmpty(saleOrderDetail) && saleOrderDetail.salesSOPartnerFunctionModelList
                }

                if (isEmpty(partners)) {
                    swal('Error', 'ไม่พบข้อมูลลูกค้า', 'error')
                    return
                }
                // const saleOrderDetail = await this.getSaleOrderDetail(document.soNumber, document.outletId)
                document.customers = partners
                document.products = document.salesBillingItem
                this.setState({
                    isWithoutBillingRef: false,
                    products: [],
                }, () => {
                    this.checkDocumentTypeCode(document)
                })
            } else {
                swal('Error', 'ไม่พบข้อมูล', 'error')
                this.setState({
                    isWithoutBillingRef: true,
                    products: [initialProduct],
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลใบวางบิลได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    checkDocumentTypeCode = async (document) => {
        let isValid = false
        const { CnSaleInfoFormRedux: { values } } = this.props
        const docTypeCode = document.documentTypeCode
        const docTypeCodeSale = values.documentTypeCode
        const billingNo = document.billingNo
        const outletId = values.outletId
        if (docTypeCode === 'ZDEP' && (docTypeCodeSale === 'ZCRR' || docTypeCodeSale === 'ZCRD' || docTypeCodeSale === 'ZCRE')) {
            isValid = true
        } else if (docTypeCode === 'ZPMT' && (docTypeCodeSale === 'ZCRR' || docTypeCodeSale === 'ZCRE' || docTypeCodeSale === 'ZCR')) {
            isValid = true
        } else if (docTypeCode === 'ZIN2' && (docTypeCodeSale === 'ZCRE' || docTypeCodeSale === 'ZCR')) {
            isValid = true
        }
        if (isValid) {
            if ((docTypeCode === 'ZPMT' || (docTypeCode === 'ZDEP' && docTypeCodeSale === 'ZCRE')) && !document.conversionFlag) {
                this.getBillingItems(document)
            } else if (docTypeCode === 'ZDEP' && docTypeCodeSale === 'ZCRD') {
                await apiServices.callApi('post', API_PATH.POST_VALIDATE_CREDIT_NOTE_DEPOSIT, { refBillingNumber: billingNo, outletId: outletId, docType: docTypeCode }).then(async (res) => {
                    const status = get(res, 'data.status')
                    if (res.status === 200 && status === "Success") {
                        this.onSelectedDocument(document)
                    }
                }).catch(error => {
                    const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดไม่สามารถตรวจสอบข้อมูลเอกสารใบรับเงินมัดจำได้ กรุณาลองใหม่อีกครั้ง')
                    swal('Error', message, 'error')
                })

            } else {
                this.onSelectedDocument(document)
            }
        } else {
            swal('Error', 'ไม่สามารถเลือกประเภทเอกสารดังกล่าวได้ กรุณาเลือกใหม่อีกครั้ง', 'error')
        }
    }

    getBillingItems = async (document) => {
        this.setState({ loading: true })
        const body = {
            outletId: document.outletId,
            soNumber: document.soNumber,
            documentTypeCode: 'ZINT',
        }
        const url = API_PATH.POST_CREDIT_NOTE_BILLING_BY_FILTER
        await apiServices.callApi('post', url, body).then(res => {
            let billingItems = get(res, 'data.results')
            if (res.status === 200) {
                if (!isEmpty(billingItems)) {
                    billingItems = billingItems.map(item => {
                        if (item.salesBillingHeadModel) {
                            item.salesBillingHeadModel.salesBillingInvoice = document.salesBillingInvoice
                            item.salesBillingHeadModel.netAmount = document.netAmount
                        }
                        return item
                    })
                    this.setState({ billingItems })
                    this.openModal('billingItem')
                } else {
                    swal('Error', 'ไม่พบข้อมูลการทำรายการตัดจ่ายสินค้าของใบวางบิลนี้', 'error')
                    this.setState({
                        isWithoutBillingRef: true,
                        products: [initialProduct],
                    })
                }
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูล Sale Order กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onSelectedBillingItem = async (items) => {
        const { saleInfo } = this.state
        const data = get(items, '0')
        if (isEmpty(data)) return
        // check duplicate cn
        const resStatus = await this.getStatusCnDn(data.billingNo, data.outletId, saleInfo.documentTypeCode)
        if (resStatus.status && resStatus.status.toLocaleLowerCase() === 'found') {
            swal('Error', resStatus.message, 'error')
            return
        }

        this.setState({ loading: true })

        const document = items[0].salesBillingHeadModel
        const saleOrderDetail = await this.getSaleOrderDetail(document.soNumber, document.outletId)

        // filter selected items in salesBillingItem
        const selectedItems = document.salesBillingItem.filter(billingItem => {
            return !!items.find(item => billingItem.billingItemId === item.billingItemId)
        })
        document.products = selectedItems
        document.customers = saleOrderDetail.salesSOPartnerFunctionModelList

        this.onSelectedDocument(document)
        this.closeModal()
        this.setState({ loading: false })
    }

    getBodySaleOrder = () => {
        const {
            billingDetail,
            saleEmployees,
            totalSummary,
            isWithoutBillingRef,
            cnDetail,
            products,
            saleInfo,
        } = this.state
        const {
            auth: { userId },
            CnSaleInfoFormRedux: { values },
        } = this.props

        const newProducts = [...products]
        if (isWithoutBillingRef) {
            newProducts.splice(-1, 1)
        }
        const isEditMode = !isEmpty(cnDetail)
        const docTypeCodeSale = isEditMode ? cnDetail.documentTypeCode : values.documentTypeCode

        const productsModel = newProducts.map((product, index) => ({
            ...product,
            saleSOItemId: product.saleSOItemId || 0,
            outletId: product.outletId,
            soNumber: billingDetail.soNumber || '',
            soItem: docTypeCodeSale === 'ZCRR' ? product.soItem : product.billingItem || index + 1,
            barcode: product.barcode,
            productCode: product.productCode,
            productName: product.productName,
            stockType: product.stockType,
            slocId: product.slocId,
            qty: product.qty,
            confirmQty: product.confirmQty || 0,
            unit: product.unit,
            freeGoodsFlag: false,
            specialOrderFlag: product.specialOrderFlag !== 'normal',
            // ZCR can edit price per unit
            pricePerUnit: saleInfo.documentTypeCode === 'ZCR' ? product.pricePerUnitNet : product.pricePerUnit,
            // pricePerUnit: product.pricePerUnitNet,
            discAmount: product.referenceDiscAmountPerUnit ? product.referenceDiscAmountPerUnit * product.qty : 0,
            discPromotionInternal: product.referencePromotionInternalPerUnit ? product.referencePromotionInternalPerUnit * product.qty : 0,
            discPromotionExternal: product.referencePromotionExternalPerUnit ? product.referencePromotionExternalPerUnit * product.qty : 0,
            costPerUnit: product.costPerUnit,
            totalAmount: product.pricePerUnit * product.qty,
            quotationNumber: product.quotationNumber || '',
            quotationItem: product.quotationItem || 0,
            rejectId: product.cnConfirmFlag === 0 ? 1 : null,
            itemText: product.itemText || '',
            promotionCode: '',
            receivedQty: 0,
            refDocNumber: product.refDocNumber || '',
            refDocItem: product.refDocItem || '',
            remainQty: 0,
            billingDepositNo: docTypeCodeSale === 'ZCRD' ? isEditMode ? billingDetail.refDocNumber : billingDetail.billingNo : '',
        }))

        const saleEmployee = saleEmployees.find(saleEmployee => saleEmployee.saleEmployeeId === billingDetail.saleEmpCode)
        const hasBillingDetail = isEditMode || !isWithoutBillingRef

        return {
            salesSOHeadId: billingDetail.salesSOHeadId || 0,
            outletId: hasBillingDetail ? billingDetail.outletId : values.outletId,
            soNumber: billingDetail.soNumber || '',
            docTypeCode: docTypeCodeSale,
            documentStatus: 'OPEN',
            documentDate: billingDetail.documentDate,
            saleChannelCode: hasBillingDetail ? billingDetail.saleChannelCode : values.saleChannelCode,
            createdDate: billingDetail.createdDate || moment(),
            createdBy: billingDetail.createdBy || userId,
            changedDate: moment(),
            changedBy: userId,
            jobId: billingDetail.jobId,
            taxType: hasBillingDetail ? billingDetail.taxType : values.taxType,
            scgid: billingDetail.scgId,
            paymentTermCode: billingDetail.paymentTermCode,
            customerId: billingDetail.customerId,
            customerName: billingDetail.customerNameTH,
            saleEmpCode: billingDetail.saleEmployeeCode,
            saleEmpName: saleEmployee ? saleEmployee.saleEmployeeName : '',
            ...totalSummary,
            promotionCode: '',
            refDocNumber: billingDetail.refDocNumber || '',
            refDocSystem: billingDetail.refDocSystem || '',
            salesSOItemModelList: productsModel,
            salesSOPartnerFunctionModelList: null,
            ignorePromotionItemFlag: docTypeCodeSale === 'ZCR',
        }
    }

    getBody = () => {
        const {
            billingDetail,
            saleEmployees,
            remark,
            totalSummary,
            cnDetail,
            customerInfo,
            isWithoutBillingRef,
            products,
            saleInfo,
        } = this.state
        const {
            auth: { userId },
            CnSaleInfoFormRedux: { values },
        } = this.props

        const isEditMode = !isEmpty(cnDetail)
        const newProducts = [...products]
        if (isWithoutBillingRef) {
            newProducts.splice(-1, 1)
        }
        const salesSOItemModels = newProducts.map((row, index) => {
            const product = {
                // saleSOItemId: 0,
                outletId: values.outletId,
                // soNumber: '',
                soItem: isEditMode ? row.soItem : index + 1,
                barcode: row.barcode,
                productCode: row.productCode,
                productName: row.productName,
                stockType: row.stockType,
                slocId: row.slocId,
                shippingPoint: 'SHPR',
                // deliveryDate: null,
                qty: row.qty,
                // confirmQty: 0,
                unit: row.unit,
                freeGoodsFlag: row.freeGoodsFlag,
                // specialOrderFlag: row.specialOrderFlag,
                // pricePerUnit: row.pricePerUnit,
                // ZCR can edit price per unit
                pricePerUnit: saleInfo.documentTypeCode === 'ZCR' ? row.pricePerUnitNet : row.pricePerUnit,
                // pricePerUnit: row.pricePerUnitNet,
                costPerUnit: row.costPerUnit,
                discAmountPerUnit: row.discAmountPerUnit,
                discAmountPerUnitAmount: row.discAmountPerUnitAmount,
                discPercent: row.discPercent,
                discPercentAmount: row.discPercentAmount,
                discAmount: row.discAmount,
                discPromotionInternal: row.discPromotionInternal,
                discPromotionExternal: row.discPromotionExternal,
                totalDiscAmount: row.totalDiscAmount,
                totalAmount: row.totalAmount,
                vatAmount: row.vatAmount,
                netAmount: row.netAmount,
                // quotationNumber: '',
                // quotationItem: 0,
                // rejectId: 0,
                // itemText: '',
                promotionCode: row.promotionCode,
                // receivedQty: 0,
                refDocNumber: isEditMode ? row.refDocNumber : isWithoutBillingRef ? values.referenceDocNumber : row.billingNo,
                refDocItem: isEditMode ? row.refDocItem : isWithoutBillingRef ? index + 1 : row.billingItem,
                // remainQty: 0,
                // hanaReservationNo: '',
                // hanaReservationItem: '',
                returnItemCateg: row.returnItemCateg,
                referenceBillQty: row.referenceBillQty,
                referenceBillValue: row.referenceBillPricePerUnit * row.referenceBillQty,
                referenceDiscAmount: row.referenceDiscAmount,
                referenceDiscPromotionInternal: row.referenceDiscPromotionInternal,
                referenceDiscPromotionExternal: row.referenceDiscPromotionExternal,
                // discPromotionInternalHeader: 0,
                // discPromotionExternalHeader: 0,
                cnConfirmFlag: row.cnConfirmFlag,
                netAmountQty: row.netAmountQty,
                doQty: row.doQty,
                amountDoQty: row.amountDoQty,
                outQty: row.outQty,
                amountOutQty: row.amountOutQty,
                billingNo1: row.billingNo1,
                billingItem1: row.billingItem1,
                billingValue1: row.billingValue1,
                diff1: row.diff1,
                billingNo2: row.billingNo2,
                billingItem2: row.billingItem2,
                billingValue2: row.billingValue2,
                diff2: row.diff2,
                zcrrSoNumber: row.zcrrSoNumber,
                returnQty: row.returnQty,
            }
            if (isEditMode) {
                product.saleSOItemId = cnDetail.saleSOItemId
                product.soNumber = cnDetail.soNumber
            }
            return product
        })

        const partnerFunctionModel = {
            // salesSOPartnerFunctionId: 0,
            outletId: values.outletId,
            // soNumber: partner.soNumber,
            partnerType: isWithoutBillingRef ? 'sold_to' : customerInfo.partnerType,
            customerRole: customerInfo.partnerType,
            customerType: customerInfo.customerType,
            customerId: customerInfo.customerId,
            customerName: customerInfo.customerName,
            title: customerInfo.title,
            taxId: customerInfo.taxId,
            taxBranch: customerInfo.taxBranch,
            scgFamilyId: customerInfo.scgFamilyId,
            address: customerInfo.address,
            subDistrict: customerInfo.subDistrict,
            district: customerInfo.district,
            province: customerInfo.province,
            country: customerInfo.country,
            postalCode: customerInfo.postalCode,
            phone: customerInfo.phone,
            fax: customerInfo.fax,
            email: customerInfo.email,
        }
        if (isEditMode) {
            partnerFunctionModel.salesSOPartnerFunctionId = customerInfo.salesSOPartnerFunctionId
            partnerFunctionModel.soNumber = customerInfo.soNumber
        }
        const salesSOPartnerFunctionModels = [partnerFunctionModel]

        const saleEmployee = saleEmployees.find(saleEmployee => saleEmployee.saleEmployeeId === values.saleEmpCode)

        const now = moment().format()
        const body = {
            // ...newSaleOrderDetail,
            // salesSOHeadId: 0,
            outletId: values.outletId,
            // soNumber: billingDetail.soNumber,
            documentTypeCode: values.documentTypeCode,
            documentStatus: values.documentStatus,
            documentDate: values.documentDate.format(),
            saleChannelCode: values.saleChannelCode,
            createdDate: isEditMode ? cnDetail.createdDate : now,
            createdBy: isEditMode ? cnDetail.createdBy : userId,
            changedDate: now,
            changedBy: userId,
            jobId: billingDetail.jobId,
            taxType: isWithoutBillingRef ? values.taxType : billingDetail.taxType,
            scgid: billingDetail.scgid,
            paymentTermCode: billingDetail.paymentTermCode,
            customerId: isWithoutBillingRef ? customerInfo.customerId : billingDetail.customerId,
            customerName: isWithoutBillingRef ? customerInfo.customerName : billingDetail.customerName,
            shipToId: billingDetail.shipToId,
            shipToName: billingDetail.shipToName,
            saleEmpCode: values.saleEmpCode,
            saleEmpName: saleEmployee ? saleEmployee.saleEmployeeName : '',
            remark,
            returnTypeId: values.returnTypeId,
            returnReasonId: values.returnReasonId,
            ...totalSummary,
            discPromotionInternal: 0,
            discPromotionExternal: 0,
            // promotionCode:billingDetail.promotionCode,
            // approvedDate:billingDetail.approvedDate,
            // approvedBy: billingDetail.approvedBy,
            refDocNumber: isEditMode ? billingDetail.refDocNumber : isWithoutBillingRef ? values.referenceDocNumber : billingDetail.billingNo,
            // refDocSystem:billingDetail.approvedBy,
            // hanaReservationNo:billingDetail.approvedBy,
            // referenceTaxInvoice: isEditMode ? billingDetail.referenceTaxInvoice : totalSummary.referenceTaxInvoice,
            // referenceNetAmount: isEditMode ? billingDetail.referenceNetAmount : totalSummary.referenceNetAmount,
            referenceTaxInvoice: totalSummary.referenceTaxInvoice.replace(/\s+/g, ''),
            referenceNetAmount: totalSummary.referenceNetAmount,
            // interfaceStatus:'',
            // interfaceMessage:'',
            memberId: billingDetail.memberId,
            salesSOItemModels,
            salesSOPartnerFunctionModels,
            legacyFlag: isEditMode ? cnDetail.legacyFlag : isWithoutBillingRef,
            conversionFlag: billingDetail.conversionFlag,
        }
        if (isEditMode) {
            body.salesSOHeadId = cnDetail.salesSOHeadId
            body.soNumber = cnDetail.soNumber
        }
        return body
    }

    validateCustomer = (customer) => {
        let result = { isError: false, message: '' }
        if (!customer.title) {
            return { isError: true, message: 'กรุณากรอกคำนำหน้าชื่อ' }
        }
        if (!customer.taxId) {
            return { isError: true, message: `กรุณากรอกเลข${Number(customer.customerType) === 2 ? 'นิติบุคคล' : 'ประจำตัวประชาชน'}` }
        }
        if (Number(customer.customerType) === 2 && !customer.taxBranch) {
            return { isError: true, message: 'กรุณากรอกสาขา' }
        }
        if (!customer.address) {
            return { isError: true, message: 'กรุณากรอกที่อยู่' }
        }
        if (!customer.province) {
            return { isError: true, message: 'กรุณากรอกจังหวัด' }
        }
        if (!customer.subDistrict) {
            return { isError: true, message: 'กรุณากรอกแขวง/ตำบล' }
        }
        if (!customer.district) {
            return { isError: true, message: 'กรุณากรอกเขต/อำเภอ' }
        }
        if (!customer.postalCode) {
            return { isError: true, message: 'กรุณากรอกรหัสไปรษณีย์' }
        }
        if (!customer.country) {
            return { isError: true, message: 'กรุณากรอกประเทศ' }
        }
        return result
    }

    validateData = async () => {
        const { CnSaleInfoFormRedux: { syncErrors, values } } = this.props
        const { totalSummary, customerInfo, products, isWithoutBillingRef, cnDetail } = this.state
        const newProducts = [...products]
        const isEditMode = !isEmpty(cnDetail)
        if (isWithoutBillingRef) {
            newProducts.splice(-1, 1)
        }

        let result = { isError: false, message: '' }

        const referenceDocNumberLength = values.referenceDocNumber.length
        console.log('values', values);
        console.log(';referenceDocNumberLength', referenceDocNumberLength);
        if (isWithoutBillingRef && referenceDocNumberLength !== 10) {
            return { isError: true, message: 'กรุณาระบุอ้างอิงใบวางบิล และความยาวตัวเลขไม่เกิน 10 ตัวอักษร' }
        }

        if (!isWithoutBillingRef && referenceDocNumberLength > 50 || referenceDocNumberLength <= 0) {
            return { isError: true, message: 'กรุณาระบุอ้างอิงใบวางบิล และความยาวตัวเลขไม่เกิน 50 ตัวอักษร' }
        }

        const validCustomer = this.validateCustomer(customerInfo)
        if (validCustomer.isError) {
            this.setState({ isErrorSubmitCustomer: true })
            return { isError: true, message: 'กรุณากรอกข้อมูลลูกค้าให้ครบถ้วน' }
        }

        if (syncErrors) {
            this.setState({ isErrorSubmitCnSaleInfo: !!syncErrors })
            return { isError: true, message: 'กรุณากรอกข้อมูลการขายให้ครบถ้วน' }
        }

        //Add condition ZOCR/ZIN2
        if (values.documentTypeCode === 'ZCRE' && values.returnTypeId != 2 &&
        values.refDocNumber && values.refDocNumber.startsWith("34", 0) ) { //documentTypeCode === "ZIN2" startsWith 34xxxxxxxx
            this.setState({ isErrorSubmitCnSaleInfo: !!syncErrors })
            return { isError: true, message: 'เอกสารประเภทการขาย(เงินเชื่อ) กรุณาระบุประเภทการคืนเงินเป็น "creditnote - ลดหนี้ครั้งถัดไป"!' }
        }

        for (const product of newProducts) {

            if (!product.qty || product.qty <= 0) {
                result = { isError: true, message: 'กรุณาระบุจำนวน' }
                break
            }
            // if (product.qty > product.referenceBillQty) {
            //     result = { isError: true, message: 'กรุณาระบุจำนวนที่ต้องการคืนให้น้อยกว่าจำนวนตามใบกำกับเดิม' }
            //     break
            // }
            if (product.qty > product.returnQty) {
                result = { isError: true, message: 'กรุณาระบุจำนวนต้องไม่มากกว่าจำนวนที่ยังไม่รับคืน' }
                break
            }
            if (product.cnConfirmFlag !== 1 && (!product.pricePerUnitNet || product.pricePerUnitNet <= 0)) {
                result = { isError: true, message: 'กรุณาระบุราคา/หน่วย' }
                break
            }
            if (product.referenceBillQty <= 0) {
                result = { isError: true, message: 'กรุณาระบุจำนวนตามใบกำกับเดิม' }
                break
            }
            /*&& product.freeGoodsFlag === false*/
            if (product.referenceBillPricePerUnit <= 0 && product.freeGoodsFlag === false) {
                result = { isError: true, message: 'กรุณาระบุราคา/หน่วยตามใบกำกับเดิม' }
                break
            }
            if (!product.returnItemCateg && values.documentTypeCode === 'ZCRE') {
                result = { isError: true, message: 'กรุณาระบุประเภทสต็อก' }
                break
            }
        }

        if (newProducts.find(product => product.cnConfirmFlag === 1) && totalSummary.netAmount <= 0) {
            return { isError: true, message: 'จำนวนเงินสุทธิต้องมากกว่า 0' }
        }

        if (totalSummary.referenceNetAmount <= 0) {
            return { isError: true, message: 'มูลค่าตามใบกำกับเดิม (รวมภาษีมูลค่าเพิ่ม) ต้องมากกว่า 0' }
        }

        if (!totalSummary.referenceTaxInvoice) {
            return { isError: true, message: 'กรุณาระบุเลขที่ใบกำกับเดิม' }
        }
        
        const refDocItem = newProducts.map((product, index) => (
            product.billingItem
        ))
        // check duplicate cn
        const resStatus = await this.getStatusCnDn(values.referenceDocNumber, values.outletId, values.documentTypeCode, refDocItem.join(','))
        if (resStatus.status && (resStatus.status.toLocaleLowerCase() === 'found' || resStatus.status.toLocaleLowerCase() === 'nonezero') && !isEditMode) {
            return { isError: true, message: resStatus.message }
        }

        return result
    }

    createCn = async () => {
        await this.setState({ loading: true, isDisabledSave: true })
        await this.calculateTotalSummary()
        let valid = await this.validateData()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            this.setState({ loading: false, isDisabledSave: false })
            return
        }

        const body = this.getBody()
        // console.log('body', body)
        // return

        const url = API_PATH.POST_CREATE_CREDIT_NOTE
        await apiServices.callApi('post', url, body).then(res => {
            const data = get(res, 'data', {})
            if (res.status === 200) {
                this.handleModalSave('create', data)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false, isDisabledSave: false })
        })
    }

    updateCn = async () => {
        await this.setState({ loading: true, isDisabledSave: true })
        await this.calculateTotalSummary()
        const valid = await this.validateData()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            this.setState({ loading: true, isDisabledSave: false })
            return
        }

        const body = this.getBody()
        const { cnNumber } = this.state
        const url = `${API_PATH.PUT_UPDATE_CREDIT_NOTE}?soNumber=${cnNumber}`
        console.log('body', body);
        // return

        await apiServices.callApi('put', url, body).then(res => {
            const data = get(res, 'data', {})
            if (res.status === 200) {
                this.handleModalSave('edit', data)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: true, isDisabledSave: false })
        })
    }

    handleModalSave = (type = '', data) => {
        const { CnSaleInfoFormRedux: { values } } = this.props
        const { returnTypes } = this.state
        const cnNumber = data.message
        const isCreate = type === 'create'
        const urlEdit = `${ROUTH_PATH.CN_DN_EDIT_LINK}/${cnNumber}/${values.outletId}`
        if (values.documentStatus === 'approve' || values.documentStatus === 'open') {
            const documentTypeData = this.state.documentTypes.find(documentType => documentType.value === values.documentTypeCode)
            const documentTypeDescription = documentTypeData && documentTypeData.documentTypeDescription

            this.setState({
                resultSave: {
                    type: 'Success',
                    billingNo: data.billingNo,
                    soNumber: data.soNumber,
                    returnTypeId: values.returnTypeId,
                    returnType: find(returnTypes, { returnTypeId: values.returnTypeId }),
                    outletId: values.outletId,
                    isApprove: values.documentStatus === 'approve',
                    documentTypeCode: values.documentTypeCode,
                    documentTypeDescription,
                    onClose: () => {
                        if (isCreate) {
                            this.props.history.push(urlEdit)
                        } else {
                            this.closeModal()
                            this.fetchDataDefault()
                        }
                    },
                }
            }, () => this.openModal('resultSave'))
        } else {
            if (isCreate) {
                swal('Success', `สร้างเอกสารสำเร็จ เลขที่เอกสาร : ${cnNumber}`, 'success').then(() => {
                    this.props.history.push(urlEdit)
                })
            } else {
                swal('Success', `เลขที่เอกสาร ${cnNumber} ถูกบันทึกสำเร็จ`, 'success').then(() => {
                    this.fetchDataDefault()
                })
            }
        }
    }

    reprocessCn = async () => {
        this.setState({ loading: true })
        const valid = this.validateData()
        if (valid.isError) {
            this.setState({ loading: false })
            swal('Warning', valid.message, 'warning')
            return
        }
        
        const body = this.getBody()
        const { cnNumber } = this.state
        const url = API_PATH.POST_CREDIT_NOTE_REPROCESS
        await apiServices.callApi('post', url, body).then(res => {
            if (res.status === 200) {
                swal('Success', `เลขที่เอกสาร ${cnNumber} ถูกบันทึกสำเร็จ`, 'success').then(() => {
                    this.fetchDataDefault()
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    changeCnSearch = (key, value) => {
        const { cnSearch } = this.state
        this.setState({ cnSearch: { ...cnSearch, [key]: value } })
    }

    searchCnDetail = async () => {
        const { cnSearch: { cnNumber, outletId } } = this.state
        if (!cnNumber || !outletId) {
            message.error('กรุณาระบุเลขที่เอกสารและร้านค้า')
            return
        }

        this.props.history.push(`${ROUTH_PATH.CN_DN_EDIT_LINK}/${cnNumber}/${outletId}`)
    }

    calculateTotalSummary = async () => {
        this.setState({ loading: true })
        const body = this.getBodySaleOrder()
        await apiServices.callApi('post', API_PATH.POST_SALE_ORDER_AMOUNT, body).then(res => {
            const saleSummary = get(res, 'data.results.0')
            if (res.status === 200 && saleSummary) {
                const products = this.state.products.map((product, index) => {
                    const productSummary = saleSummary.salesSOItemModelList.find((item, indexSum) => index === indexSum)
                    if (productSummary) {
                        return {
                            ...product,
                            discAmount: productSummary.discAmount,
                            discAmountPerUnit: productSummary.discAmountPerUnit,
                            discAmountPerUnitAmount: productSummary.discAmountPerUnitAmount,
                            discPercent: productSummary.discPercent,
                            discPercentAmount: productSummary.discPercentAmount,
                            discPromotionInternal: productSummary.discPromotionInternal,
                            discPromotionExternal: productSummary.discPromotionExternal,
                            totalAmount: productSummary.totalAmount,
                            totalDiscAmount: productSummary.totalDiscAmount,
                            vatAmount: productSummary.vatAmount,
                            netAmount: productSummary.netAmount,
                        }
                    } else {
                        return product
                    }
                })
                this.setState({
                    products,
                    totalSummary: {
                        ...this.state.totalSummary,
                        totalAmount: saleSummary.totalAmount,
                        totalDiscAmount: saleSummary.totalDiscAmount,
                        netAmount: saleSummary.netAmount,
                        vatAmount: saleSummary.vatAmount,
                        discPercent: saleSummary.discPercent,
                        discPercentAmount: saleSummary.discPercentAmount,
                        discAmount: saleSummary.discAmount,
                        discCouponPercent: saleSummary.discCouponPercent,
                        discCouponPercentAmount: saleSummary.discCouponPercentAmount,
                        discSCGIDAmount: saleSummary.discSCGIDAmount,
                        discSCGIDCode: saleSummary.discSCGIDCode,
                        discCouponAmount: saleSummary.discCouponAmount,
                        discPromotionInternal: saleSummary.discPromotionInternal,
                        discPromotionExternal: saleSummary.discPromotionExternal,
                        referenceTaxInvoice: this.state.totalSummary.referenceTaxInvoice,
                        totalExcVatAmount: saleSummary.totalExcVatAmount,
                    },

                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการคำนวณราคา กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    clearAllData = async () => {
        this.props.resetCnSaleInfoFormRedux()
        this.props.resetCustomerDetailForm()
        this.resetState()
        this.fetchDataDefault()
    }

    changeTotalSummary = (key, value) => {
        const { totalSummary } = this.state
        this.setState({ totalSummary: { ...totalSummary, [key]: value } })
    }

    renderSectionBottomSummary = () => {
        const {
            isWithoutBillingRef,
            totalSummary,
        } = this.state
        const refNetAmount = formatNumber(totalSummary.referenceNetAmount || 0, 2)

        let renderTaxInvoiceWithRef = null
        if (!isWithoutBillingRef && totalSummary.referenceTaxInvoice) {
            const taxInvoices = totalSummary.referenceTaxInvoice.split(',')
            const lastIndex = taxInvoices.length - 1
            renderTaxInvoiceWithRef = taxInvoices.map((tax, index) => (
                <div key={index}>{`${tax}${lastIndex !== index ? ',' : ''}`}</div>
            ))
        }

        return (
            <>
                <Row className="font-weight-bold text-right">
                    <Col sm={11}>
                        <div>
                            มูลค่าตามใบกำกับเดิม (รวมภาษีมูลค่าเพิ่ม)
                        </div>
                    </Col>
                    <Col sm={11}>
                        <div>
                            {isWithoutBillingRef ? <NumberFormatCustom
                                className="text-right font-weight-bold"
                                small
                                label=" "
                                widthSmall="100px"
                                onChange={(e) => this.changeTotalSummary('referenceNetAmount', Number(`${e.target.value}`.replace(/,/g, '')))}
                                value={refNetAmount}
                                decimalScale={2}
                            /> : refNetAmount}
                        </div>
                    </Col>
                </Row>
                <Row className="font-weight-bold text-right">
                    <Col sm={11}>
                        <div>
                            เลขที่ใบกำกับเดิม
                        </div>
                    </Col>
                    <Col sm={11}>
                        <div style={{ wordBreak: 'break-all' }}>
                            {isWithoutBillingRef ? <InputCustom
                                className="text-right font-weight-bold"
                                small
                                label=" "
                                widthLabel="100px"
                                onChange={(e) => this.changeTotalSummary('referenceTaxInvoice', e.target.value)}
                                value={totalSummary.referenceTaxInvoice}
                            /> : renderTaxInvoiceWithRef}
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    filterReturnReasons = (returnReasons) => {
        return returnReasons.filter(returnReason => returnReason.docType === this.state.saleInfo.documentTypeCode)
    }

    filterReturnTypes = (returnTypes) => {
        const { billingDetail } = this.state
        if (billingDetail.documentTypeCode && (billingDetail.documentTypeCode === "ZPMT" || billingDetail.documentTypeCode === "ZDEP" || billingDetail.documentTypeCode === "ZINT")) {
            return returnTypes = returnTypes.filter(returnType => returnType.returnTypeCode !== 'creditnote')
        }

        if (billingDetail.documentTypeCode && billingDetail.documentTypeCode === "ZIN2") {
            return returnTypes = returnTypes.filter(returnType => returnType.returnTypeCode === 'creditnote')
        }

        return returnTypes
    }

    closeModalSaleOrderItem = () => {
        const data = this.state.documentTypes.find(documentType => documentType.value !== 'ZCRR')
        if (data && isEmpty(this.state.billingDetail)) {
            this.changeSaleInfo('documentTypeCode', data.value)
        }
        this.closeModal()
    }

    // TODO: refactor, hurry!
    onSelectedSaleOrderItem = async (saleOrderDetail) => {
        const { saleInfo } = this.state
        if (!saleOrderDetail.billingNo1) {
            message.error('กรุณาระบุเลขอ้างอิง')
            return
        }

        const billingNo1 = saleOrderDetail.billingNo1
        const billingNo2 = saleOrderDetail.billingNo2
        const isMoreRef = !!billingNo2

        const resStatus = await this.getStatusCnDn(billingNo1, saleInfo.outletId, saleInfo.documentTypeCode)
        if (resStatus.status && (resStatus.status.toLocaleLowerCase() === 'found' || resStatus.status.toLocaleLowerCase() === 'nottoday')) {
            swal('Error', resStatus.message, 'error')
            this.setState({ loading: false })
            return
        }

        // const moreRefValue = 'อ้างอิงมากกว่า 1 ใบ'
        const billingNo = isMoreRef ? `${billingNo1}${billingNo2 ? `,${billingNo2}` : ''}` : saleOrderDetail.billingNo1
        const salesBillingInvoice = []

        const taxInvoiceNo1 = saleOrderDetail.taxInvoiceNo1
        const taxInvoiceNo2 = saleOrderDetail.taxInvoiceNo2
        if (taxInvoiceNo1) salesBillingInvoice.push({ taxInvoiceNo: taxInvoiceNo1 })
        if (taxInvoiceNo2) salesBillingInvoice.push({ taxInvoiceNo: taxInvoiceNo2 })

        let items = saleOrderDetail.salesSOItemModels.filter((item) => (item.outQty > 0))
        if (isEmpty(items)) {
            swal('Error', 'รายการสินค้าจำนวนค้างส่งครบแล้ว', 'error')
            return
        }

        items = items.map((item) => {
            const billingItem1 = item.billingItem1
            const billingItem2 = item.billingItem2
            return {
                ...item,
                billingNo: billingNo,
                billingItem: `${billingItem1}${billingItem2 ? `,${billingItem2}` : ''}`,
                zcrrSoNumber: saleOrderDetail.soNumber,
                qty: item.outQty,
                netAmount: item.amountOutQty,
                referenceBillQty: item.qty,
            }
        })

        const document = {
            ...saleOrderDetail,
            products: items,
            customers: saleOrderDetail.salesSOPartnerFunctionModels,
            salesBillingInvoice,
            billingNo,
        }

        delete document.salesSOItemModels
        delete document.salesSOPartnerFunctionModels

        this.closeModal()
        this.setState({ isWithoutBillingRef: false, isMoreRef },
            () => this.onSelectedDocument(document)
        )
    }

    render() {
        const {
            columns,
            products,
            loading,
            cnNumber,
            outlets,
            documentTypes,
            taxTypes,
            saleChannels,
            saleEmployees,
            documentStatuses,
            returnTypes,
            returnReasons,
            cnSearch,
            customerInfo,
            saleInfo,
            totalSummary,
            remark,
            isErrorSubmitCnSaleInfo,
            isErrorSubmitCustomer,
            cnDetail,
            billingDetail,
            isWithoutBillingRef,
            isShowModal,
            modalType,
            storeLocations,
            units,
            billingItems,
            permissionPage,
            resultSave,
            isMoreRef,
            isDisabledSave,
            reprocessDatetime,
        } = this.state
        const isEditMode = !isEmpty(cnDetail)
        const returnReasonsFilterd = this.filterReturnReasons(returnReasons)
        const returnTypesFilterd = this.filterReturnTypes(returnTypes)

        return (
            <div className="cn-dn-maintain-container">
                <Spin spinning={loading}>
                    <TitleHead
                        icon="file"
                        text={`ใบขอรับคืน/ใบขอเพิ่มหนี้/ใบขอลดหนี้${cnNumber ? ` : เลขที่ ${cnNumber}` : ''}`}
                        nodeRight={
                            <div className="search-sale-order-box">
                                <div className="text-search">ค้นหาเลขที่เอกสาร</div>
                                <div className="text-search" style={{ width: '180px' }}>
                                    <SelectCustom
                                        options={outlets}
                                        value={cnSearch.outletId}
                                        small
                                        onChange={(val) => this.changeCnSearch('outletId', val)}
                                        disabled={!permissionPage.authDisplay}
                                    />
                                </div>
                                <InputSearchCustom
                                    small
                                    value={cnSearch.cnNumber}
                                    onChange={(e) => this.changeCnSearch('cnNumber', e.target.value)}
                                    onClickSearch={this.searchCnDetail}
                                    onSubmit={this.searchCnDetail}
                                    disabled={!permissionPage.authDisplay}
                                    disabledButton={!permissionPage.authDisplay}
                                />
                            </div>
                        }
                    />
                    <div className="middle-content">
                        <Row gutter={8}>
                            <Col sm={24} md={8}>
                                <CnCustomerInfoForm
                                    customer={customerInfo}
                                    validateCustomer={this.validateCustomer}
                                    onChangeCustomerInfo={this.onChangeCustomerInfo}
                                    isErrorSubmitCustomer={isErrorSubmitCustomer}
                                    isEditMode={isEditMode}
                                    outletId={billingDetail.outletId}
                                    isWithoutBillingRef={isWithoutBillingRef}
                                />
                            </Col>
                            <Col sm={24} md={16}>
                                <CnSaleInfoFormRedux
                                    initialValues={saleInfo}
                                    outlets={outlets}
                                    taxTypes={taxTypes}
                                    saleEmployees={saleEmployees}
                                    documentTypes={documentTypes}
                                    saleChannels={saleChannels}
                                    documentStatuses={cnDetail.documentStatus === 'open' ? documentStatuses.filter(documentStatus => documentStatus.value !== 'complete') : documentStatuses}
                                    returnTypes={returnTypesFilterd}
                                    returnReasons={returnReasonsFilterd}
                                    isErrorSubmit={isErrorSubmitCnSaleInfo}
                                    isEditMode={isEditMode}
                                    billingDetail={billingDetail}
                                    searchReferenceDocNumber={this.searchReferenceDocNumber}
                                    changeSaleInfo={this.changeSaleInfo}
                                    permissionPage={permissionPage}
                                    isMoreRef={isMoreRef}
                                    onOpenModalReferenceDocNumber={() => this.openModal('saleOrderItem')}
                                    cnDetail={cnDetail}
                                    // เช็คปุมสถานะ ว่าจะปิดการกดเมือ ทุก row เป็นยกเลิก
                                    isStatusCancelAll={products.every(product => product.cnConfirmFlag === 0)}
                                    isWithoutBillingRef={isWithoutBillingRef}
                                />
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                data={products}
                                isFullContent
                                rowKey={(row, index) => index}
                                small
                                pagination={false}
                            />
                        </div>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={24} md={11}>
                                <TextAreaCustom
                                    small
                                    label="หมายเหตุลูกค้า"
                                    onChange={this.changeRemark}
                                    value={remark}
                                />
                            </Col>
                            <Col sm={24} md={13}>
                                <SaleSummary
                                    totalSummary={totalSummary}
                                    onSubmitDiscount={this.onSubmitDiscount}
                                    sectionBottomSummary={this.renderSectionBottomSummary()}
                                />
                            </Col>
                        </Row>
                    </div>
                    {/* {isEditMode && !isEmpty(documentFlows) && 
                    <Row style={{ marginTop: '20px' }}>
                        <Col sm={24} md={24}>
                            <DocumentFlowTable
                                documentFlows={documentFlows}
                                getDocFlow={this.getDocFlow}
                                openPrint={queryStringValue.print}
                                permissionPage={permissionPage}
                                customer={customer}
                                getSaleOrderDetail={this.getSaleOrderDetail}
                                soNumber={cnNumber}
                                outletId={outletId}
                            // initiatData={this.initiatData}
                            />
                        </Col>
                    </Row>
                     } */}
                    <div className="bottom-content">
                        <div className="left-zone">
                            <div className="button">
                                <ButtonCustom
                                    text="ยกเลิก"
                                    icon="close"
                                    type="danger"
                                    onClick={this.clearAllData}
                                />
                            </div>
                        </div>
                        <div className="right-zone">
                            <div className="button">
                                <ButtonCustom
                                    text="Reprocess"
                                    icon="redo"
                                    yellow
                                    onClick={this.reprocessCn}
                                    disabled={!cnDetail.isReprocess || !permissionPage.authUpdate || loading || (reprocessDatetime && moment(reprocessDatetime) > moment(new Date()).subtract(10, "minutes")) }
                                />
                            </div>
                            <div className="button">
                                <ButtonCustom
                                    text="บันทึก"
                                    icon="save"
                                    onClick={(isEditMode) ? this.updateCn : this.createCn}
                                    disabled={(isEditMode ? !permissionPage.authUpdate : !permissionPage.authCreate)
                                        || (isWithoutBillingRef ? products.length <= 1 : isEmpty(products))
                                        || cnDetail.documentStatus === 'approve'
                                        || cnDetail.documentStatus === 'complete'
                                        || cnDetail.documentStatus === 'reject'
                                        || isDisabledSave}
                                />
                            </div>
                        </div>
                    </div>
                    <ProductSearchModal
                        isShowModal={isShowModal && modalType === 'product'}
                        onCloseModal={this.closeModal}
                        onSelected={this.onSelectedProduct}
                        outletId={saleInfo.outletId}
                        channelCode={saleInfo.saleChannelCode || '10'}
                        isIncludeStockATP
                    />
                    <StoreLocationModal
                        isShowModal={isShowModal && modalType === 'storeLocation'}
                        handleCloseModal={this.closeModal}
                        dataStorages={storeLocations}
                        onSelected={this.onSelectedStoreLocation}
                    />
                    <SaleUnitModal
                        isShowModal={isShowModal && modalType === 'unit'}
                        onCloseModal={this.closeModal}
                        units={units}
                        onSelected={this.onSelectedUnit}
                    />
                    <SaleOrderItemModal
                        isShowModal={isShowModal && modalType === 'saleOrderItem'}
                        onCloseModal={this.closeModalSaleOrderItem}
                        outletId={saleInfo.outletId}
                        onSelected={this.onSelectedSaleOrderItem}
                        soDetail={cnDetail}
                        // show detail only
                        isSelected={!isEmpty(billingDetail)}
                    />
                    {isShowModal && modalType === 'billingItem' && <BillingItemModal
                        isShowModal={isShowModal && modalType === 'billingItem'}
                        onCloseModal={this.closeModal}
                        items={billingItems}
                        onSelected={this.onSelectedBillingItem}
                    />}
                    {isShowModal && modalType === 'resultSave' && <CnResultSaveModal
                        isShowModal={isShowModal && modalType === 'resultSave'}
                        onCloseModal={this.closeModal}
                        result={resultSave}
                        permissionPage={permissionPage}
                    />}
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        CnSaleInfoFormRedux: state.form.CnSaleInfoFormRedux,
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetCnSaleInfoFormRedux: () => dispatch(reset('CnSaleInfoFormRedux')),
    resetCustomerDetailForm: () => dispatch(reset('CustomerDetailFormRedux')),
})


export default connect(mapStateToProps, mapDispatchToProps)(CNDNMaintainContainer)
