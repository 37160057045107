import React, { Component } from 'react'
import { DatePicker } from 'antd';
import { DatePickerCustomStyled } from "./styled"
import './style.css'

export class DatePickerCustom extends Component {
    render() {
        const {
            onChange,
            placeholder,
            label,
            right,
            small,
            widthSmall,
            widthDefault,
            defaultValue,
            format,
            value,
            allowClear,
            disabled,
            disabledDate,
            widthAll,
        } = this.props
        return (
            <DatePickerCustomStyled
                small={small}
                widthSmall={widthSmall}
                widthDefault={widthDefault}
            >
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div className="label-style" style={{ textAlign: right ? 'right' : '' }}>
                                {label}
                            </div>
                        }
                        <DatePicker
                            onChange={onChange}
                            format={format}
                            defaultValue={defaultValue}
                            placeholder={placeholder ? placeholder : 'Select date'}
                            style={{
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    widthAll ? widthAll : ''
                            }}
                            value={value}
                            allowClear={allowClear}
                            disabled={disabled}
                            disabledDate={disabledDate}
                        />
                    </div>
                </div>
            </DatePickerCustomStyled>
        )
    }
}
