import React, { Component } from 'react'
import { isEqual, find } from 'lodash'
import {
    ModalCustom,
    ButtonCustom,
    NumberFormatCustom,
    SelectCustom
} from 'components/common'
import { apiServices, API_PATH } from 'apiServices'
import { Row, Col, Button, message } from 'antd'
import { reset } from 'redux-form'
import { connect } from 'react-redux'
export class SaleDiscountModal extends Component {
    state = {
        discountSummary: this.props.totalSummary,
        scgIdCouponList: []
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.totalSummary, prevProps.totalSummary)) {
            this.setState({ discountSummary: this.props.totalSummary })
        }
    }

    changeDiscountSummary = (key, value) => {
        const { discountSummary } = this.state
        value = Number(`${value}`.replace(/,/g, ''))
        this.setState(
            { discountSummary: { ...discountSummary, [key]: value } },
            () => { this.calculateDiscountSummary() }
        )
    }

    calculateDiscountSummary = () => {
        const { totalSummary } = this.props
        const { discountSummary } = this.state
        this.setState({
            discountSummary: {
                discPercent: Number(discountSummary.discPercent || 0),
                discPercentAmount: (Number(discountSummary.discPercent || 0) * Number(totalSummary.totalAmount || 0)) / 100,
                discAmount: Number(discountSummary.discAmount || 0),
                discCouponPercent: Number(discountSummary.discCouponPercent || 0),
                discCouponPercentAmount: (Number(discountSummary.discCouponPercent || 0) * Number(totalSummary.totalAmount || 0)) / 100,
                discSCGIDAmount: Number(discountSummary.discSCGIDAmount || 0),
                discSCGIDCode: discountSummary.discSCGIDCode,
                discCouponAmount: Number(discountSummary.discCouponAmount || 0),
                discPromotion: Number(discountSummary.discPromotion || 0),
            },
        }, () => { this.calculateTotalDiscAmountSummary() })
    }

    calculateTotalDiscAmountSummary = () => {
        const { discountSummary } = this.state
        this.setState({
            discountSummary: {
                ...discountSummary,
                totalDiscAmount:
                    discountSummary.discPercentAmount + discountSummary.discAmount
                    + discountSummary.discCouponPercentAmount + discountSummary.discSCGIDAmount
                    + discountSummary.discCouponAmount + discountSummary.discPromotion,
            },
        })
    }
    async getSCGIDCoupon() {
        let scgId = ''
        let outletId = ''
        if (this.props.type === 'SaleOrder') {
            scgId = this.props.SaleCustomerInfoFormRedux.values.scgId
            outletId = this.props.SaleOrderInfoFormRedux.values.outletId
        } else if (this.props.type === 'Quotation') {
            scgId = this.props.SaleCustomerInfoFormRedux.values.scgId
            outletId = this.props.QuotationInfoFormRedux.values.outletId
        }

        if (!scgId) {
            message.error('กรุณากรอก SCG Family ID')
            return
        }

        const responseCoupon = await apiServices.callApi('get', `${API_PATH.GET_COUPON_SCG_ID}?outlet=${outletId}`, null, { headersOption: { scgid: scgId } })
        let coupons = []
        if (responseCoupon.status === 200) {
            coupons = responseCoupon.data.result.lists || []
            coupons = coupons.map(data => (
                { ...data, label: `${data.coupon_code} - ${data.discount}`, value: data.coupon_code }
            ))
        }
        this.setState(({ scgIdCouponList: coupons }))
    }
    onSelectCoupon(value) {
        let { discountSummary, scgIdCouponList } = this.state
        const Coupon = find(scgIdCouponList, { coupon_code: value })
        discountSummary.discSCGIDAmount = Coupon ? Number(Coupon.discount) : 0
        discountSummary.discSCGIDCode = value
        this.setState({ discountSummary }, this.calculateTotalDiscAmountSummary)
    }
    render() {
        const {
            isShowModal,
            onCloseModal,
            onSubmitDiscount,
        } = this.props
        const {
            discountSummary,
            scgIdCouponList
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                title="ส่วนลดท้ายบิล"
                width="550px"
            >
                <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลดท้ายบิล (%)
                    </Col>
                    <Col sm={5}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeDiscountSummary('discPercent', e.target.value)}
                            value={parseFloat(`${discountSummary.discPercent || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            isAllowed={(values) => {
                                const { formattedValue, floatValue } = values
                                return formattedValue === '' || floatValue <= 100
                            }}
                            disabled
                        />
                    </Col>
                    <Col sm={8}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            value={parseFloat(`${discountSummary.discPercentAmount || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลดท้ายบิล (จน.)
                    </Col>
                    <Col sm={8} offset={5}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeDiscountSummary('discAmount', e.target.value)}
                            value={parseFloat(`${discountSummary.discAmount || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลดคูปอง-ท้ายบิล (%)
                    </Col>
                    <Col sm={5}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeDiscountSummary('discCouponPercent', e.target.value)}
                            value={parseFloat(`${discountSummary.discCouponPercent || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            isAllowed={(values) => {
                                const { formattedValue, floatValue } = values
                                return formattedValue === '' || floatValue <= 100
                            }}
                            disabled
                        />
                    </Col>
                    <Col sm={8}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            value={parseFloat(`${discountSummary.discCouponPercentAmount || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลดคูปอง SCG Family ID
                    </Col>
                    <Col sm={12}>
                        <Button type="primary" size="small" onClick={this.getSCGIDCoupon.bind(this)}>Get Coupon</Button>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={5} offset={9}>
                        จำนวนเงิน
                    </Col>
                    <Col sm={8}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            value={parseFloat(`${discountSummary.discSCGIDAmount || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={5} offset={9}>
                        Coupon Code
                    </Col>
                    <Col sm={8}>
                        <SelectCustom
                            className="text-right"
                            small
                            options={scgIdCouponList}
                            disabled={scgIdCouponList.length === 0}
                            value={discountSummary.discSCGIDCode}
                            onChange={(val) => this.onSelectCoupon(val)}
                        />
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลดคูปองเพิ่มท้ายบิล (จน.)
                    </Col>
                    <Col sm={13}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeDiscountSummary('discCouponAmount', e.target.value)}
                            value={parseFloat(`${discountSummary.discCouponAmount || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลด Promotion ส่วนกลาง
                    </Col>
                    <Col sm={13}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeDiscountSummary('discPromotion', e.target.value)}
                            value={parseFloat(`${discountSummary.discPromotion || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                {/* <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลด Promotion
                    </Col>
                    <Col sm={13}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeDiscountSummary('discPromotion', e.target.value)}
                            value={parseFloat(`${discountSummary.discPromotion || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row> */}
                <Row gutter={8}>
                    <Col sm={9}>
                        Total
                    </Col>
                    <Col sm={13}>
                        <NumberFormatCustom
                            className="text-right font-weight-bold"
                            small
                            value={parseFloat(`${discountSummary.totalDiscAmount || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row style={{ paddingTop: '20px' }}>
                    <Col sm={24} className="text-right">
                        <ButtonCustom
                            text="อนุมัติ"
                            small
                            onClick={() => { onSubmitDiscount(discountSummary) }}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth: state.auth,
        QuotationInfoFormRedux: state.form.QuotationInfoFormRedux,
        SaleOrderInfoFormRedux: state.form.SaleOrderInfoFormRedux,
        SaleCustomerInfoFormRedux: state.form.SaleCustomerInfoFormRedux,
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetQuotationInfoForm: () => dispatch(reset('QuotationInfoFormRedux')),
    resetCustomerDetailForm: () => dispatch(reset('CustomerDetailFormRedux')),
    resetSaleOrderInfoForm: () => dispatch(reset('SaleOrderInfoFormRedux')),
})

export default connect(mapStateToProps, mapDispatchToProps)(SaleDiscountModal)
