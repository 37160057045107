import React, { Component } from 'react'
import { get, map, isEmpty, uniq } from 'lodash'
import moment from 'moment'
import {
    DatePickerCustom,
} from 'components/common'
import { Row, Col, Spin } from 'antd'
import swal from '@sweetalert/with-react'
import { apiServices, API_PATH } from 'apiServices'
import Calendar from './Calendar'

const formatDateAPI = 'YYYY-MM-DD HH:mm:ss'

export default class ShipmentCalendar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            shipments: [],
            calendarData: [],
            filter: {
                date: null,
            },
        }
    }

    mappingData = () => {
        const { shipments, filter } = this.state
        const { trucks } = this.props

        const calendarData = []
        for (let i = 0; i < 7; i++) {
            let resources = []
            let events = []

            const date = moment(filter.date).add(i, 'days')
            const shipmentsFilter = shipments.filter(shipment => (moment(shipment.startTime).startOf('day')).isSame(date.startOf('day')))
            const truckIds = uniq(map(shipmentsFilter, 'truckId'))

            if (!isEmpty(truckIds)) {
                resources = truckIds.map(truckId => {
                    const truck = trucks.find(truck => truck.truckId === truckId)
                    return {
                        id: truckId,
                        name: truck ? truck.truckName : truckId,
                    }
                })
                events = shipmentsFilter.map((shipment, index) => ({
                    id: index,
                    start: moment(shipment.startTime),
                    end: moment(shipment.endTime),
                    resourceId: shipment.truckId,
                    title: shipment.shipmentNumber,
                }))
            }
            calendarData.push({ resources, events, date })
        }

        this.setState({ calendarData })
    }

    searchShipment = async (date) => {
        this.changeFilter('date', date)

        this.setState({ loading: true })
        const body = {
            outletId: this.props.outletId,
            startDateTime: date.startOf('day').format(formatDateAPI),
            endDateTime: moment(date).add(7, 'days').endOf('day').format(formatDateAPI),
        }

        await apiServices.callApi('post', API_PATH.POST_GET_SALES_SHIPMENT, body).then(res => {
            let shipments = get(res, 'data.results')
            if (res.status === 200) {
                this.setState({ shipments }, () => {
                    this.mappingData()
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูลจัดส่ง กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    render() {
        const {
            loading,
            filter,
            calendarData,
        } = this.state

        return (
            <div className="calendar-box">
                <Spin spinning={loading}>
                    <Row>
                        <Col sm={8}>
                            <DatePickerCustom
                                label="วันที่ทำรายการ"
                                small
                                allowClear={false}
                                onChange={this.searchShipment}
                                value={filter.date}
                            />
                        </Col>
                    </Row>
                    {!isEmpty(calendarData) && calendarData.map((data, index) =>
                        <Calendar data={data} key={index} />)
                    }
                </Spin>
            </div>
        )
    }
}
