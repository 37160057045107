import React, { Component } from 'react'
import { Select } from 'antd';
import { MultiSelectedReduxStyled } from "./styled"

const { Option } = Select;

// const children = [];
// for (let i = 10; i < 36; i++) {
//     children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
// }


export class MultiSelectedRedux extends Component {
    render() {
        const {
            label,
            input,
            options,
            submitViewError,
            meta: { touched, error },
            onChangeHero,
            widthDefault,
            small,
            widthSmall,
            right,
            maxTagCount,
            widthAll,
        } = this.props
        return (
            <MultiSelectedReduxStyled error={(touched || submitViewError) && error ? true : false} right={right} widthSmall={widthSmall} small={small} widthDefault={widthDefault}>
                <div className="input-form">
                    <div className="input-zone-label">
                        <div className="label-style">
                            {label}
                        </div>
                        <Select
                            mode="multiple"
                            // maxTagCount={maxTagCount ? maxTagCount : 2}
                            maxTagCount={maxTagCount}
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            value={input.value ? input.value : undefined}
                            style={{
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    widthAll ? widthAll : ''
                            }}
                            onChange={e => {
                                input.onChange(e)
                                onChangeHero && onChangeHero(e)
                            }}
                            optionLabelProp="label"
                        // value={input.value ? input.value : false}
                        >
                            {
                                options && options.map((data, i) => (
                                    <Option key={i} value={data.value} label={data.label} >{data.text}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div className="error-message-bottom">
                        {(touched || submitViewError) ? error : ''}
                    </div>
                </div>
            </MultiSelectedReduxStyled >
        )
    }
}
