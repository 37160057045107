import React, { Component } from 'react'
import { Tabs } from 'antd'
import { TabCustomStyled } from './styled'

const { TabPane } = Tabs

export class TabCustom extends Component {
    render() {
        const {
            onChange,
            tabContent,
            height,
        } = this.props

        return (
            <TabCustomStyled height={height}>
                <Tabs onChange={onChange}>
                    {tabContent && tabContent.map((data, i) => (
                        <TabPane
                            forceRender
                            tab={data.titleTab}
                            key={i}
                        >
                            {data.nodeContent}
                        </TabPane>
                    ))}
                </Tabs>
            </TabCustomStyled>
        )
    }
}
