import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { getPermissionPage } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    RadioCustom,
    InputCustom,
    DatePickerCustom,
    SelectCustom,
} from 'components/common'
import { Row, Col, Spin } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { functionAlias } from 'routes'
import swal from '@sweetalert/with-react'
import moment from 'moment'

import './style.css'
import { interfaceData } from './config'

const interfaceTypes = [
    { label: 'Interface INF009', value: 'INF009' },
    { label: 'Interface INF010', value: 'INF010' },
    { label: 'Interface INF011', value: 'INF011' },
    { label: 'Interface INF012', value: 'INF012' },
    { label: 'Interface INF013', value: 'INF013' },
]

class ManualRunInterface2ChampContainer extends Component {
    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth } = this.props

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, { outletIds: auth.arrayOutlet }).then(res => {
            let outlets = get(res, 'data.results', [])
            if (res.status === 200) {
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }))
                this.setState({
                    outlets,
                    filter: {
                        ...this.state.filter,
                        outletId: outlets[0].value,
                    },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Outlet ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    getInitialState = () => {
        const permissionPage = getPermissionPage(functionAlias.createPurchaseOrder)
        const defaultInterfaceType = 'INF009'
        return {
            columns: get(interfaceData, `${defaultInterfaceType}.columns`),
            modes: [
                { label: 'Report', value: 'Report' },
                { label: 'Interface', value: 'Interface' },
            ],
            types: interfaceTypes,
            typeData: get(interfaceData, defaultInterfaceType),
            loading: false,
            dateFormat: 'DD/MM/YYYY',
            widthLabel: '125px',
            reports: [],
            outlets: [],
            filter: {
                type: defaultInterfaceType,
                mode: 'Report',
                // customCode - custom by type selected
                customCode: '',
                documentNumber: '',
                dateFrom: moment(),
                dateTo: null,
                yearFrom: moment().format('YYYY'),
                yearTo: '',
                outletId: '',
            },
            pagination: {
                limit: 10,
            },
            permissionPage,
        }
    }

    resetState = () => {
        this.setState(this.getInitialState())
    }

    clearAllData = () => {
        this.resetState()
        this.fetchDataDefault()
    }

    changeFilter = (key, value) => {
        const { filter } = this.state
        switch (key) {
            case 'yearFrom':
            case 'yearTo':
                value = value.substring(0, 4)
                this.setState({ filter: { ...filter, [key]: value } })
                break
            case 'type':
                const typeData = get(interfaceData, value, {})
                this.setState({
                    filter: { ...filter, [key]: value },
                    columns: typeData.columns,
                    typeData,
                    reports: [],
                })
                break
            default:
                this.setState({ filter: { ...filter, [key]: value } })
                break
        }
    }

    validateFilter = () => {
        const { filter } = this.state
        if (!filter.mode) return { isError: true, message: 'กรุณาระบุ Mode' }
        if (['INF012', 'INF013'].includes(filter.type) && !filter.yearFrom) return { isError: true, message: 'กรุณาระบุ Year' }
        if (['INF012', 'INF013'].includes(filter.type) && !filter.dateFrom) return { isError: true, message: 'กรุณาระบุ Date' }
        if (filter.dateTo && (filter.dateTo).isBefore(filter.dateFrom)) return { isError: true, message: 'รูปแบบ Date ไม่ถูกต้อง' }
        return { isError: false }
    }

    getBody = () => {
        const { filter } = this.state

        let body = {}
        switch (filter.type) {
            case 'INF009':
                body = {
                    Mode: filter.mode,
                    OutletId: filter.outletId,
                    CustomerCode: filter.customCode,
                    DocumentNumber: filter.documentNumber,
                }
                break
            case 'INF010':
                body = {
                    Mode: filter.mode,
                    VendorCode: filter.customCode,
                }
                break
            case 'INF011':
                body = {
                    Mode: filter.mode,
                    OutletId: filter.outletId,
                    ProductCode: filter.customCode,
                }
                break
            case 'INF012':
                body = {
                    Mode: filter.mode,
                    OutletId: filter.outletId,
                    VendorCode: filter.customCode,
                    DocumentNumber: filter.documentNumber,
                    YearFrom: filter.yearFrom,
                    YearTo: filter.yearTo,
                    DateFrom: filter.dateFrom,
                    DateTo: filter.dateTo,
                }
                break
            case 'INF013':
                body = {
                    Mode: filter.mode,
                    OutletId: filter.outletId,
                    CustomerCode: filter.customCode,
                    DocumentNumber: filter.documentNumber,
                    YearFrom: filter.yearFrom,
                    YearTo: filter.yearTo,
                    DateFrom: filter.dateFrom,
                    DateTo: filter.dateTo,
                }
                break
            default:
                break
        }

        return body
    }

    runInterface = async () => {
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const { filter, typeData } = this.state
        const body = this.getBody()

        await apiServices.callApi('post', typeData.api, body).then(res => {
            if (res.status === 200) {
                if (filter.mode === 'Report') {
                    this.setState({ reports: get(res, 'data.results', []) })
                } else {
                    this.setState({ reports: [] })
                    swal('Success', get(res, 'data.message'), 'success')
                }
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    exportDocument = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        await apiServices.callApi('post', API_PATH.POST_EXPORT_INTERFACE_S4_LOG, body, { responseType: 'arraybuffer' }).then(res => {
            // if (res.status === 200) {
            //     const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            //     FileSaver.saveAs(blob, `ReportS4InterfaceLogFile-${moment().format('DD-MM-YYYY')}.xlsx`)
            // } else {
            //     throw res
            // }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            loading,
            columns,
            modes,
            types,
            typeData,
            outlets,
            reports,
            filter,
            dateFormat,
            pagination,
            widthLabel,
            permissionPage,
        } = this.state

        return (
            <div className="manual-run-interface-champ-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="Manual Run Interface2CHAMP"
                        icon="file-sync"
                    />
                    <div className="middle-content">
                        <Row>
                            <Col sm={24}>
                                <RadioCustom
                                    options={types}
                                    value={filter.type}
                                    label="Type"
                                    widthLabel={widthLabel}
                                    onChange={(e) => this.changeFilter('type', e.target.value)}
                                    small
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24}>
                                <RadioCustom
                                    options={modes}
                                    value={filter.mode}
                                    label="Mode"
                                    widthLabel={widthLabel}
                                    onChange={(e) => this.changeFilter('mode', e.target.value)}
                                    small
                                />
                            </Col>
                        </Row>
                        {['INF009', 'INF011', 'INF012', 'INF013'].includes(filter.type) && <Row gutter={20}>
                            <Col sm={12}>
                                <SelectCustom
                                    widthLabel={widthLabel}
                                    label="Outlet ID"
                                    options={outlets}
                                    value={filter.outletId}
                                    onChange={(val) => this.changeFilter('outletId', val)}
                                    small
                                />
                            </Col>
                        </Row>}
                        <Row gutter={20}>
                            <Col sm={12}>
                                <InputCustom
                                    label={typeData.labelCustomCode}
                                    widthLabel={widthLabel}
                                    value={filter.customCode}
                                    onChange={(e) => this.changeFilter('customCode', e.target.value)}
                                    small
                                />
                                <div style={{ fontSize: '12px', paddingLeft: widthLabel }}>
                                    ใส่ : เพื่อค้นหาข้อมูลแบบช่วง หรือใส่ , เพื่อค้นหาข้อมูลแบบรายตัว
                                </div>
                            </Col>
                        </Row>
                        {['INF012', 'INF013'].includes(filter.type) && <Row gutter={20}>
                            <Col sm={12}>
                                <InputCustom
                                    label="Document Number"
                                    widthLabel={widthLabel}
                                    value={filter.documentNumber}
                                    onChange={(e) => this.changeFilter('documentNumber', e.target.value)}
                                    small
                                />
                                <div style={{ fontSize: '12px', paddingLeft: widthLabel }}>
                                    ใส่ : เพื่อค้นหาข้อมูลแบบช่วง หรือใส่ , เพื่อค้นหาข้อมูลแบบรายตัว
                                </div>
                            </Col>
                        </Row>}
                        {['INF012', 'INF013'].includes(filter.type) && <>
                            <Row gutter={20} style={{ marginTop: '5px' }}>
                                <Col sm={7}>
                                    <InputCustom
                                        label="Year"
                                        widthLabel={widthLabel}
                                        value={filter.yearFrom}
                                        onChange={(e) => this.changeFilter('yearFrom', e.target.value)}
                                        type="number"
                                        small
                                    />
                                </Col>
                                <Col sm={5}>
                                    <InputCustom
                                        label="to"
                                        widthLabel="40px"
                                        value={filter.yearTo}
                                        onChange={(e) => this.changeFilter('yearTo', e.target.value)}
                                        type="number"
                                        small
                                    />
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col sm={7}>
                                    <DatePickerCustom
                                        widthLabel={widthLabel}
                                        label="Date"
                                        value={filter.dateFrom}
                                        format={dateFormat}
                                        allowClear={false}
                                        onChange={(date) => this.changeFilter('dateFrom', date)}
                                        small
                                    />
                                </Col>
                                <Col sm={5}>
                                    <DatePickerCustom
                                        widthLabel="40px"
                                        label="to"
                                        value={filter.dateTo}
                                        format={dateFormat}
                                        allowClear={false}
                                        onChange={(date) => this.changeFilter('dateTo', date)}
                                        small
                                    />
                                </Col>
                            </Row>
                        </>}
                        <Row gutter={8} type="flex" align="middle" justify="end">
                            <Col>
                                <ButtonCustom
                                    icon="file-excel"
                                    text="Export"
                                    green
                                    small
                                    disabled={!permissionPage.authDisplay || !permissionPage.authExport || (filter.mode === 'Report' && isEmpty(reports))}
                                    onClick={this.exportDocument}
                                />
                            </Col>
                            <Col>
                                <ButtonCustom
                                    icon="reload"
                                    text="ล้างหน้าจอ"
                                    yellow
                                    small
                                    onClick={this.clearAllData}
                                />
                            </Col>
                            <Col>
                                <ButtonCustom
                                    icon="caret-right"
                                    text="Run"
                                    small
                                    onClick={this.runInterface}
                                    disabled={!permissionPage.authDisplay || !permissionPage.authCreate}
                                />
                            </Col>
                        </Row>
                        {(filter.mode === 'Report') && <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                small
                                scroll={{ x: "max-content" }}
                                data={reports}
                                rowKey={(row, index) => index}
                                pagination={{
                                    pageSize: pagination.limit,
                                    showTotal: (total, range) => (`Showing ${range[0]} to ${range[1]} of ${total} entries`),
                                }}
                            />
                        </div>}
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualRunInterface2ChampContainer)