import React, { Component } from 'react'
import { DatePicker } from 'antd';
import { RangePickerCustomStyled } from "./styled"

const { RangePicker } = DatePicker;

export class RangePickerCustom extends Component {
    render() {
        const {
            label,
            defaultRadius,
            onChange,
            value,
            right,
            widthDefault,
            small,
            widthSmall,
            widthAll,
            allowClear,
        } = this.props
        return (
            <RangePickerCustomStyled right={right} widthSmall={widthSmall} small={small} widthDefault={widthDefault}>
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div className="label-style" >{label}</div>
                        }
                        <RangePicker
                            size={small ? 'small' : 'default'}
                            onChange={onChange}
                            value={value}
                            allowClear={allowClear}
                            style={{
                                borderRadius: defaultRadius ? 4 : '',
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    widthAll ? widthAll : ''
                            }}
                        />
                    </div>
                </div>
            </RangePickerCustomStyled >
        )
    }
}
