import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authData } from 'actions/actionsCreator';
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    SelectCustom,
    InputSearchCustom,
} from 'components/common';
import swal from '@sweetalert/with-react';
import { Row, Col } from 'antd';
import { apiServices, API_PATH } from 'apiServices';
import { get, first, isEmpty, } from 'lodash';
import { sortDataColumn } from 'helpers';
const keywordTypes = [
    { label: 'รหัสสินค้า', value: 'ProductCode' },
    { label: 'ชื่อสินค้า', value: 'ProductName' },
    { label: 'barcode', value: 'ProductBarCode' },
];

class ProductStockSearchModal extends Component {
    state = {
        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: '',
                key: 'htf',
                ellipsis: true,
                render: (props, row) => <div className="text-center">
                    <ButtonCustom
                        onClick={() =>
                            this.props.onSelected({
                                ...row,
                                price: (+this.props.channelCode === 20) ? row.projectPrice : row.retailPrice
                            })}
                        small
                        text="เลือก" />
                </div>
            },
            {
                title: 'ร้าน',
                align: 'center',
                dataIndex: 'outlt',//'werks',
                key: 'outlt',//'werks',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'outlt'),
            },
            {
                title: 'รหัสสินค้า',
                align: 'center',
                dataIndex: 'matnr',
                key: 'matnr',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'matnr'),
            },
            {
                title: 'ชื่อสินค้า',
                align: 'left',
                dataIndex: 'maktg',
                key: 'maktg',
                width: 240,
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'maktg'),
            },
            {
                title: 'คลัง',
                align: 'center',
                dataIndex: 'lgort',
                key: 'lgort',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'lgort'),
            },
            {
                title: 'จำนวนที่มี',
                align: 'center',
                dataIndex: 'atpqty',
                key: 'atpqty',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'atpqty'),
            },
            {
                title: 'พร้อมขาย',
                align: 'center',
                dataIndex: 'labst',
                key: 'labst',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'labst'),
            },
            {
                title: 'รอตรวจสอบ',
                align: 'center',
                dataIndex: 'insme',
                key: 'insme',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'insme'),
            },
            {
                title: 'ระงับ',
                align: 'center',
                dataIndex: 'speme',
                key: 'speme',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'speme'),
            },
            {
                title: 'หน่วยนับ',
                align: 'center',
                dataIndex: 'eX_DISPLAY_UNIT',
                key: 'eX_DISPLAY_UNIT',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'eX_DISPLAY_UNIT'),
            },
            {
                title: 'บาร์โค้ด',
                align: 'center',
                dataIndex: 'eaN11',
                key: 'eaN11',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'eaN11'),
            },
        ],
        loading: false,
        products: [],
        filter: {
            keywordType: keywordTypes[1].value,
            keyword: '',
            outletId: '',
        },
        outletOptions: [],
    }

    componentDidUpdate = async (prevProps) => {
        let { outletOptions } = this.state;
        if (isEmpty(outletOptions) || outletOptions.length === 0) {
            await this.fetchDataOutlet();
        }

        if (this.props.defaultOutlet !== prevProps.defaultOutlet) {
            this.setState({ products: [] })
            await this.fetchDataOutlet()
        }
    }

    fetchDataOutlet = async () => {
        const { auth, defaultOutlet } = this.props;
        let { outletOptions, filter } = this.state;
        let paramsOutlet = {
            outletIds: auth.userOutletModelList.map((data) => (
                data.outletId
            ))
        };
        //ร้านค้า Outlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet).then(res => {
            let results = get(res, 'data.results');
            //console.log("results==>", results);
            outletOptions = results.map(data => ({
                label: `${data.outletId} - ${data.outletName}`,
                value: data.outletId,
            }));

            if (!isEmpty(outletOptions) && outletOptions.length > 0 && isEmpty(defaultOutlet)) {
                const firstOutlet = first(outletOptions);
                filter = {
                    ...filter,
                    outletId: firstOutlet.value,
                }
            }else{
                filter = {
                    ...filter,
                    outletId: defaultOutlet,
                }
            }
            this.setState({ outletOptions, filter });
        }).catch(error => {
            const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลร้านค้า!')
            swal('Error', message, 'error')
        });
    }

    handleChangeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } });
    }

    onSearchProduct = async () => {
        this.setState({ loading: true })

        const { filter } = this.state
        const { auth } = this.props
        //GroupBy = 'L:แยกที่เก็บ'
        //DisplayUnit = 'B:หน่วยมาตรฐาน'

        const storeLocationQueryString = auth.arrayStorageLocationId.map(slocId => `Storelocations=${slocId}`).join('&')
        const url = `${API_PATH.GET_DAILY_STOCK_BALANCE_LIST}/?${filter.keywordType}=${filter.keyword}&Outlet=${filter.outletId}&GroupBy=L&DisplayUnit=B&${storeLocationQueryString}`

        await apiServices.callApi('get', url, '', '').then(res => {
            const products = get(res, 'data.result.eX_STOCK_BAL.item', []);

            this.setState({ products });
            
        }).catch(error => {
            const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            console.error(get(error, 'response.data.errors'))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            width,
            loading,
            className,
            title,
            disabledOutlet,
        } = this.props
        const {
            columns,
            filter,
            loading: thisLoading,
            products,
            outletOptions,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                width={width}
                title={title || 'ค้นหาสินค้า'}
                className={`${className || ''}`}
            >
                <Row gutter={8}>
                    <Col sm={6}>
                        <SelectCustom
                            label='ร้าน*'
                            small
                            widthLabel='50px'
                            options={outletOptions}
                            onChange={(value) => this.handleChangeFilter('outletId', value)}
                            value={filter.outletId}
                            name="outletId"
                            disabled={disabledOutlet ? disabledOutlet : true}
                        />
                    </Col>
                    <Col sm={8}>
                        <SelectCustom
                            label="ค้นหาด้วย"
                            name='keywordType'
                            small
                            widthLabel='100px'
                            onChange={(value) => this.handleChangeFilter('keywordType', value)}
                            options={keywordTypes}
                            value={filter.keywordType}
                        />
                    </Col>
                    <Col sm={8}>
                        <InputSearchCustom
                            name='keyword'
                            small
                            value={filter.keyword}
                            onChange={(e) => this.handleChangeFilter('keyword', e.target.value)}
                            onClickSearch={this.onSearchProduct}
                            onSubmit={this.onSearchProduct}
                        />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={products}
                            loading={loading || thisLoading}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}

const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
      auth: state.auth
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
      authData
    }, dispatch);
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProductStockSearchModal);