import React, { Component } from 'react'
import { formatNumber } from 'helpers'
import { isEmpty } from 'lodash'
import { message } from 'antd'
import {
    TableCustom,
    ModalCustom,
    ButtonCustom,
} from 'components/common'

export default class BillingItemModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: 'Billing No.',
                    dataIndex: 'billingNo',
                    key: 'billingNo',
                    align: 'center',
                },
                {
                    title: 'รายการ',
                    dataIndex: 'billingItem',
                    key: 'billingItem',
                    align: 'center',
                },
                {
                    title: 'บาร์โค้ด',
                    dataIndex: 'barcode',
                    key: 'barcode',
                    align: 'center',
                },
                {
                    title: 'รหัสสินค้า',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    align: 'center',
                },
                {
                    title: 'ชื่อสินค้า',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: 'center',
                    width: 200,
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'หน่วยขาย',
                    dataIndex: 'unit',
                    key: 'unit',
                    align: 'center',
                },
                {
                    title: 'จำนวน',
                    dataIndex: 'returnQty',
                    key: 'returnQty',
                    align: 'center',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 3)}</div>
                    ),
                },
                {
                    title: 'ราคา/หน่วย',
                    dataIndex: 'netAmount',
                    key: 'netAmountPerUnit',
                    align: 'center',
                    render: (props, row) => (
                        <div className="text-right">{formatNumber((props || 0) / row.qty, 2)}</div>
                    ),
                },
                {
                    title: 'จำนวนเงิน',
                    dataIndex: 'netAmount',
                    key: 'netAmount',
                    align: 'center',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
            ],
            selectedRowKeys: [],
        }
    }

    onSelectedRows = (selectedRowKeys, selectedRows) => {
        if (isEmpty(this.state.selectedRowKeys) || isEmpty(selectedRowKeys)) {
            this.setState({ selectedRowKeys })
        } else {
            const firstRow = selectedRows[0]
            const itemDiffBillingNo = selectedRows.find((item) => item.billingNo !== firstRow.billingNo)
            if (!itemDiffBillingNo) {
                this.setState({ selectedRowKeys })
            } else {
                message.error('กรุณาเลือกรายการที่มี Billing No เดียวกัน')
            }
        }
    }

    onConfirm = () => {
        const { selectedRowKeys } = this.state
        if (isEmpty(selectedRowKeys)) {
            message.error('กรุณาเลือกรายการที่จะนำไปขอลดหนี้')
            return
        }
        const items = this.props.items.filter((item, index) => selectedRowKeys.includes(index))
        this.props.onSelected(items)
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            items,
        } = this.props
        const {
            columns,
            selectedRowKeys,
        } = this.state

        const rowSelection = {
            columnTitle: 'เลือก',
            onChange: this.onSelectedRows,
            selectedRowKeys,
            getCheckboxProps: record => ({
                disabled: record.returnQty <= 0,
            }),
        }

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                title="ผลการค้นหา : โปรดเลือกรายการที่จะนำไปขอลดหนี้"
            >
                <div>
                    <div style={{ paddingTop: '15px' }}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={items}
                            small
                            isFullContent
                            pagination={false}
                            rowKey={(row, index) => index}
                            rowSelection={rowSelection}
                        />
                    </div>
                    <div style={{ paddingTop: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <ButtonCustom
                            text="ยกเลิก"
                            type="danger"
                            onClick={onCloseModal}
                        />
                        <ButtonCustom
                            text="ตกลง"
                            style={{ marginLeft: '10px' }}
                            green
                            onClick={this.onConfirm}
                        />
                    </div>
                </div>
            </ModalCustom>
        )
    }
}
