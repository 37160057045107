import React, { Component, Fragment } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { isEmpty, get } from 'lodash'
import { Row, Col, Input } from 'antd'
import {
    InputCustom,
    InputSearchCustom,
    ModalCustom,
    ButtonCustom,
} from 'components/common'
import {
    CustomerSearchModal,
    CustomerShippingModal,
    CustomerDetailFormRedux,
} from 'components/feature'
import swal from '@sweetalert/with-react'
import './style.css'
import { validationSCGID } from '../../../helpers/function'
const widthLabelDefault = '120px'

class SaleCustomerInfoFormRedux extends Component {
    state = {
        isErrorSubmitCustomerDetail: false,
        isShowModal: false,
        modalType: '',
        scgId: ''
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerInfo && prevProps.customerInfo &&
            this.props.customerInfo.scgId !== prevProps.customerInfo.scgId) {
            this.setState({ scgId: this.props.customerInfo.scgId })
        }
    }

    openModal = (modalType) => {
        this.setState({ isShowModal: true, modalType })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '' })
    }

    onSaveCustomer = () => {
        const {
            customer,
            CustomerDetailFormRedux: { values },
        } = this.props
        this.closeModal()
        if (values && values.customerProvince) {
            values.customerProvince = values.customerProvinceName
        }
        this.props.changeCustomerInfo({ ...customer, ...values, })
    }

    onSelectedCustomer = (customer) => {
        this.closeModal()
        this.props.onSelectedCustomer(customer)
    }

    onSelectedShipping = (shipping) => {
        this.closeModal()
        this.props.onSelectedShipping(shipping)
    }

    async searchSCGID(e) {
        let customerInfo = this.props.initialValues
        try {
            const value = e.target.value || this.state.scgId
            if (!isEmpty(value)) {
                const resultSCGID = await validationSCGID(value)
                customerInfo.scgId = resultSCGID.member.scgid
                swal("Success", `ดึงข้อมูล SCG Family ID สำเร็จ`, "success");
            } else {
                customerInfo.scgId = ''
            }
        } catch (error) {
            swal('แจ้งเตือน SCG Family ID', error, 'warning')
            customerInfo.scgId = ''
        }
        // this.props.changeCustomerInfo(customerInfo)
        this.props.change('scgId', customerInfo.scgId)
        this.setState({ scgId: customerInfo.scgId })
    }
    UNSAFE_componentWillReceiveProps(props) {
        if (props.customerInfo && props.customerInfo.scgId) {
            this.setState({ scgId: props.customerInfo.scgId })
        }
    }
    render() {
        const {
            isShowModal,
            modalType,
            isErrorSubmitCustomerDetail,
            scgId
        } = this.state

        const {
            outletId,
            isErrorSubmitCustomer,
            isErrorSubmitCustomerShipping,
            searchCustomer,
            changeCustomerIdSearch,
            approveQuotation,
            approveCreditNote,
            customer,
            customerInfo,
            customerShipping,
            customerShippings,
            customerIdSearch,
            showApproveBtn,
            disabledApproveBtn,
            disabledAction,
            disableSCGID,
            type,
            scgFlag,
            initialValues
        } = this.props
        const hasCustomerData = customer && customer.customerId && customer.customerId !== 'OTC99'
        const widthLabel = this.props.widthLabel || widthLabelDefault

        return (
            <Row style={{ marginTop: '10px' }}>
                <Col md={24}>
                    <div className="sale-order-info-form">
                        <div className="title">
                            ข้อมูลลูกค้า
                        </div>
                        <Row gutter={15}>
                            <Col sm={24} md={12}>
                                <InputSearchCustom
                                    label="รหัสลูกค้า"
                                    onClickSearch={() => this.openModal('customer')}
                                    onSubmit={e => searchCustomer(e.target.value)}
                                    onChange={e => changeCustomerIdSearch(e.target.value)}
                                    small
                                    widthLabel={widthLabel}
                                    value={customerIdSearch}
                                    disabled={disabledAction}
                                    disabledButton={disabledAction}
                                />
                            </Col>
                            <Col sm={24} md={12}>
                                <ButtonCustom
                                    style={{ padding: 0, whiteSpace: 'initial', textAlign: 'left' }}
                                    text={
                                        <span>
                                            {customer.customerNameTH || 'ลูกค้าทั่วไป'}
                                            {customer.customerProvince
                                                && `/${customer.customerSubDistrict || '-'}/${customer.customerDistrict || '-'}/${customer.customerProvince || '-'}/${customer.customerPostalCode || '-'}`
                                            }
                                        </span>
                                    }
                                    type="link"
                                    small
                                    onClick={() => this.openModal('customerEdit')}
                                    disabled={disabledAction}
                                />
                                {isErrorSubmitCustomer &&
                                    <span style={{ color: 'red' }}>
                                        * กรุณากรอกข้อมูลลูกค้า
                                    </span>
                                }
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} md={12}>
                                <InputSearchCustom
                                    label="สถานที่จัดส่ง"
                                    small
                                    widthLabel={widthLabel}
                                    onClickSearch={() => this.openModal('customerShipping')}
                                    value={customerShipping.customerId === 'OTC99' ? 'ลูกค้าทั่วไป' : customerShipping.customerId}
                                    disabled
                                    disabledButton={disabledAction}
                                />
                            </Col>
                            <Col sm={24} md={12}>
                                {isErrorSubmitCustomerShipping ?
                                    <span style={{ color: 'red' }}>
                                        * กรุณาเลือกสถานที่จัดส่ง
                                    </span>
                                    :
                                    !isEmpty(customerShipping) && <span>
                                        {customerShipping.customerNameTH || '-'}
                                        {customerShipping.customerId !== 'OTC99'
                                            && `/${customerShipping.customerSubDistrict || '-'}/${customerShipping.customerDistrict || '-'}/${customerShipping.customerProvince || '-'}/${customerShipping.customerPostalCode || '-'}`
                                        }
                                    </span>
                                }
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} md={12}>
                                <Field
                                    component={InputCustom}
                                    label="SCG Family ID"
                                    name="scgId"
                                    widthLabel={widthLabel}
                                    input={{ value: scgId, onBlur: this.searchSCGID.bind(this), onChange: (e) => { this.setState({ scgId: e.target.value }) } }}
                                    small
                                    disabled={disableSCGID}
                                />
                            </Col>
                            <Col sm={24} md={12}>
                                <ButtonCustom text="ดึงข้อมูล" size="small" onClick={this.searchSCGID.bind(this)} disabled={disableSCGID}></ButtonCustom>
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            {type === 'quotation' && showApproveBtn &&
                                <Col sm={24} md={24} className="text-right">
                                    <ButtonCustom
                                        text="อนุมัติ"
                                        small
                                        onClick={approveQuotation}
                                        disabled={disabledApproveBtn}
                                    />
                                </Col>
                            }
                            {type !== 'quotation' && hasCustomerData &&
                                <Col sm={24} md={12}>
                                    <Field
                                        component={InputCustom}
                                        label="วงเงินคงเหลือ"
                                        name="customerCreditRemain"
                                        small
                                        widthLabel={widthLabel}
                                        disabled
                                    />
                                </Col>
                            }
                        </Row>
                        {type !== 'quotation' &&
                            <Fragment>
                                <Row gutter={15}>
                                    <Col sm={24} md={12}>
                                        <Field
                                            component={InputCustom}
                                            label="JOB ID"
                                            name="jobId"
                                            small
                                            widthLabel={widthLabel}
                                            disabled={disabledAction}
                                        />
                                    </Col>
                                    {type === 'salesorder' && showApproveBtn &&
                                        <Col sm={24} md={12} className="text-right">
                                            <ButtonCustom
                                                text="อนุมัติ"
                                                small
                                                onClick={approveCreditNote}
                                                disabled={disabledApproveBtn}
                                            />
                                        </Col>
                                    }
                                </Row>
                                <Row gutter={15}>
                                    {hasCustomerData &&
                                        <Col sm={24} md={12}>
                                            <Field
                                                component={InputCustom}
                                                label="วงเงินที่มี"
                                                name="customerCreditLimit"
                                                small
                                                widthLabel={widthLabel}
                                                disabled
                                            />
                                        </Col>
                                    }
                                    {hasCustomerData &&
                                        <Col sm={24} md={12}>
                                            <Field
                                                component={InputCustom}
                                                label="วงเงินที่ใช้ไป"
                                                name="customerUsedCredit"
                                                small
                                                widthLabel={widthLabel}
                                                disabled
                                            />
                                        </Col>
                                    }
                                </Row>
                            </Fragment>
                        }
                    </div>
                    <ModalCustom
                        title={`เพิ่ม / แก้ไขข้อมูลลูกค้า${customer.customerId ? '' : ' (One time OTC99)'}`}
                        isShowModal={isShowModal && modalType === 'customerEdit'}
                        onCloseModal={this.closeModal}
                        onClickSave={this.onSaveCustomer}
                        textSuccess="ตกลง"
                        footer
                    >
                        <CustomerDetailFormRedux
                            initialValues={customer}
                            submitViewErrorCustomer={isErrorSubmitCustomerDetail}
                        />
                    </ModalCustom>
                    <CustomerSearchModal
                        isShowModal={isShowModal && modalType === 'customer'}
                        handleCloseModal={this.closeModal}
                        onSelected={this.onSelectedCustomer}
                        outletId={outletId}
                        scgFlag={scgFlag}
                    />
                    <CustomerShippingModal
                        isShowModal={isShowModal && modalType === 'customerShipping'}
                        onCloseModal={this.closeModal}
                        onSelected={this.onSelectedShipping}
                        shippings={customerShippings}
                    />
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = state => {
    return {
        CustomerDetailFormRedux: state.form.CustomerDetailFormRedux,
    }
};

SaleCustomerInfoFormRedux = connect(mapStateToProps)(SaleCustomerInfoFormRedux)

export default reduxForm({
    form: 'SaleCustomerInfoFormRedux',
    enableReinitialize: true,
})(SaleCustomerInfoFormRedux)
