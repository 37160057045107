import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { formatNumber, getPermissionPage, sortDataColumn } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    SelectHero,
    InputHero,
    CheckBoxCustom,
} from 'components'
import { Row, Col, Spin } from 'antd'
import { ROUTH_PATH, functionAlias } from 'routes'
import { apiServices, API_PATH } from 'apiServices'
import swal from '@sweetalert/with-react'

import './style.css'

class RoleContainer extends Component {
    state = {
        columns: [
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: 60,
                render: (value, row) => {
                    return (
                        <div className="button-box">
                            <div className="button">
                                <Link to={`${ROUTH_PATH.ROLE_EDIT_LINK}/${row.roleId}`}>
                                    <ButtonCustom
                                        icon="edit"
                                        disabled={!this.state.permissionPage.authUpdate}
                                    />
                                </Link>
                            </div>
                            <div className="button">
                                <ButtonCustom
                                    icon="delete"
                                    type="danger"
                                    onClick={this.onClickDeleteUserRole.bind(this, row)}
                                    disabled={!this.state.permissionPage.authDelete}
                                />
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'ชื่อสิทธิ์',
                dataIndex: 'roleName',
                key: 'roleName',
                sorter: (a, b) => sortDataColumn(a, b, 'roleName'),
            },
            {
                title: 'คำอธิบาย',
                dataIndex: 'roleDescription',
                key: 'roleDescription',
                sorter: (a, b) => sortDataColumn(a, b, 'roleDescription'),
            },
            {
                title: 'สมาชิก',
                dataIndex: 'countUser',
                key: 'countUser',
                align: 'right',
                width: 120,
                sorter: (a, b) => sortDataColumn(a, b, 'countUser'),
                render: (value) => (
                    <div className="align-right">
                        {formatNumber(value || 0)}
                    </div>
                )
            },
            {
                title: 'ใช้งานอยู่',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: 120,
                sorter: (a, b) => sortDataColumn(a, b, 'status'),
                render: (value) => (
                    <div className="checkbox-status">
                        <CheckBoxCustom
                            checked={value}
                        />
                    </div>
                )
            },
        ],
        loading: false,
        userRoles: [],
        keyword: '',
        pagination: {
            total: 0,
            limit: 10,
        },
        paginationLimitOptions: [
            { label: '10', value: 10 },
            { label: '25', value: 25 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
        ],
        permissionPage: getPermissionPage(functionAlias.roleMaintain),
    }

    componentDidMount() {
        if (!this.state.permissionPage.authDisplay) {
            swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
                this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
            })
            return
        }

        this.fetchData()
    }

    fetchData = async () => {
        this.setState({ loading: true })
        const { pagination } = this.state
        await apiServices.callApi('get', `${API_PATH.GET_USER_ROLE_MASTER_VIEW}?DeleteFlag=false`).then(res => {
            const userRoles = get(res, 'data.results')
            if (res.status === 200 && userRoles) {
                const total = get(res, 'data.filter.pagination.totalRecord', 0)
                this.setState({ userRoles, pagination: { ...pagination, total } })
            }
        }).catch(error => {
            const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onChangeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    onChangePagination = (key, val) => {
        const { pagination } = this.state
        this.setState({ pagination: { ...pagination, [key]: val } })
    }

    onClickDeleteUserRole = (row) => {
        swal({
            icon: 'warning',
            text: 'ต้องการลบบทบาทการใช้งานนี้หรือไม่?',
            buttons: {
                confirm: { text: 'ยืนยัน', value: true },
                cancel: 'ยกเลิก',
            },
        }).then((value) => {
            if (value) {
                this.deleteUserRole(row)
            }
        })
    }

    deleteUserRole = async (row) => {
        this.setState({ loading: true })
        const { auth } = this.props
        const body = {
            ...row,
            deleteFlag: true,
            updatedDate: new Date(),
            updatedBy: auth.userId,
        }
        await apiServices.callApi('put', `${API_PATH.PUT_UPDATE_USER_ROLE_MASTER}?roleId=${row.roleId}`, body).then(res => {
            const message = get(res, 'data.message', 'ลบข้อมูลเรียบร้อยแล้ว')
            if (res.status === 200) {
                swal('Success', message, 'success')
                this.fetchData()
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    filterUserRoles = () => {
        let { keyword, userRoles } = this.state
        if (isEmpty(userRoles)) return []
        keyword = keyword.toLowerCase()
        return userRoles.filter(role => (
            (role.roleName || '').toLowerCase().includes(keyword)
            || (role.roleDescription || '').toLowerCase().includes(keyword)
            || (role.countUser).toString().includes(keyword)
        ))
    }

    render() {
        const {
            loading,
            columns,
            keyword,
            pagination,
            paginationLimitOptions,
            permissionPage,
        } = this.state
        const roles = this.filterUserRoles()
        return (
            <div className="role-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="บทบาทการใช้งาน"
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row>
                            <Col>
                                <ButtonCustom
                                    icon="plus-circle"
                                    text="เพิ่มบทบาทการใช้งาน"
                                    onClick={() => this.props.history.push(ROUTH_PATH.ROLE_CREATE)}
                                    disabled={!permissionPage.authCreate}
                                />
                            </Col>
                        </Row>
                        <Row align="middle" type="flex" style={{ paddingTop: '5px' }}>
                            <Col sm={3}>
                                <SelectHero
                                    widthSmall="35px"
                                    label="Show"
                                    labelEnd="entries"
                                    labelEndAlign="right"
                                    options={paginationLimitOptions}
                                    value={pagination.limit}
                                    small
                                    onChange={(val) => this.onChangePagination('limit', val)}
                                />
                            </Col>
                            <Col sm={8} offset={13}>
                                <InputHero
                                    label="Search : "
                                    widthDefault="70px"
                                    onChange={this.onChangeKeyword}
                                    value={keyword}
                                />
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                small
                                scroll={{ x: "max-content" }}
                                data={roles}
                                rowKey={(row, index) => index}
                                pagination={{
                                    pageSize: pagination.limit,
                                    showTotal: (total, range) => (`Showing ${range[0]} to ${range[1]} of ${total} entries`),
                                }} />
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleContainer)