import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { TitleHead, ButtonCustom, TableCustom, SelectHero, InputHero, DatePickerCustom } from 'components'
import { Row, Col, Spin } from 'antd'
import './style.css'
import { apiServices, API_PATH } from "apiServices"
import swal from '@sweetalert/with-react'
import { get } from 'lodash'
import { Link } from 'react-router-dom'
import { ROUTH_PATH } from 'routes'
import queryString from 'query-string'

class InventoryCountResultContainer extends Component {
    state = {
        columns: [
            {
                title: 'เลขที่ใบตรวจนับ',
                dataIndex: 'inventoryCountDocNo',
                key: 'inventoryCountDocNo',
                width: 150,
                align: 'center',
                fixed: 'left',
            },
            {
                title: 'docList',
                dataIndex: 'docList',
                key: 'docList',
                width: 100,
                align: 'center',
            },
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                render: (value, row, index) => (
                    <div>
                        {index + 1}
                    </div>
                ),
                width: 100,
                align: 'center',
            },
            {
                title: 'รหัสสินค้า Article',
                dataIndex: 'productCode',
                key: 'productCode',
                width: 150,
                align: 'center',
            },
            {
                title: 'Barcode',
                dataIndex: 'barcode',
                key: 'barcode',
                width: 100,
                align: 'center',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                width: 100,
                align: 'center',
            },
            {
                title: 'คลัง',
                dataIndex: 'sloc',
                key: 'sloc',
                width: 100,
                align: 'center',
            },
            {
                title: 'จำนวนที่นับได้ #1',
                dataIndex: 'inputQtyCounted1',
                key: 'inputQtyCounted1',
                width: 150,
                align: 'center',
                render: (value, row, index) => {
                    return <InputHero type={"number"} value={value} disabled={row.masterInventoryCountStatusModel.count !== "Y"} onChange={this.handleInputQtyCountChange1.bind(this, index)} />
                },
            },
            {
                title: 'หน่วย #1',
                dataIndex: 'inputUnitCounted1',
                key: 'inputUnitCounted1',
                width: 100,
                align: 'center',
                render: (value, row, index) => {
                    const units = row.unitModels.map(data => ({
                        label: data.text,
                        value: data.text,
                    }));

                    return <SelectHero
                        onChange={this.handleChangeUnit1.bind(this, index)}
                        options={units}
                        value={value || ''}
                        optionsDefault={[{ label: '- เลือก -', value: '' }]}
                        disabled={row.masterInventoryCountStatusModel.count !== "Y"}
                    />
                },
            },
            {
                title: 'จำนวนที่นับได้ #2',
                dataIndex: 'inputQtyCounted2',
                key: 'inputQtyCounted2',
                width: 150,
                align: 'center',
                render: (value, row, index) => {
                    return <InputHero type={"number"} value={value} disabled={row.masterInventoryCountStatusModel.count !== "Y"} onChange={this.handleInputQtyCountChange2.bind(this, index)} />
                },
            },
            {
                title: 'หน่วย #2',
                dataIndex: 'inputUnitCounted2',
                key: 'inputUnitCounted2',
                width: 100,
                align: 'center',
                render: (value, row, index) => {
                    const units = row.unitModels.map(data => ({
                        label: data.text,
                        value: data.text,
                    }));

                    return <SelectHero
                        onChange={this.handleChangeUnit2.bind(this, index)}
                        options={units}
                        value={value || ''}
                        optionsDefault={[{ label: '- เลือก -', value: '' }]}
                        disabled={row.masterInventoryCountStatusModel.count !== "Y"}
                    />
                },
            },
            {
                title: 'Status',
                dataIndex: 'itemStatus',
                key: 'itemStatus',
                width: 100,
                align: 'center',
            },
            {
                title: 'วันที่บันทึกตรวจ',
                dataIndex: 'countedDatetime',
                key: 'countedDatetime',
                render: (value, row, index) => {
                    if (value) {
                        var d = new Date(value),
                            month = '' + (d.getMonth() + 1),
                            day = '' + d.getDate(),
                            year = d.getFullYear();

                        if (month.length < 2) month = '0' + month;
                        if (day.length < 2) day = '0' + day;

                        return [day, month, year].join('-');
                    }
                },
                width: 150,
                align: 'center',
            },
            {
                title: 'ประเภทสต็อค',
                dataIndex: 'stockType',
                key: 'stockType',
                width: 100,
                align: 'center',
            },
            {
                title: 'Class',
                dataIndex: 'classCode',
                key: 'classCode',
                width: 100,
                align: 'center',
            },
            {
                title: 'ร้าน',
                dataIndex: 'outletId',
                key: 'outletId',
                width: 100,
                align: 'center',
            },
        ],
        loading: false,
        inventoryCountDocNo: '',
        docList: '',
        outlets: [],
        outlet: '',
        products: [],
        types: [],
        widthLabel: '80px',
        dateFormat: 'DD/MM/YYYY',
        statusList: [],
        typeList: [],
        statusListSelected: [],
        typeListSelected: [],
        startDate: '',
        endDate: '',
        dataList: []
    }

    componentDidMount() {
        this.fatchData();
        this.handleGetQueryStringSearch();
    }

    fatchData = async () => {
        const { auth } = this.props
        this.setState({
            loading: true
        });

        let params1 = {
            outletIds: auth.arrayOutlet,
        }

        let params2 = {
            languageCode: 'TH',
        }

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, params1).then(res => {
            if (res.status === 200) {
                let outlets = res.data.results;
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }))

                this.setState({
                    outlets,
                    outlet: outlets[0].value,
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('post', API_PATH.GET_MASTER_INVENTORY_COUNT_STATUS, params2).then(res => {
            if (res.status === 200) {
                let statusList = res.data.results;
                statusList = statusList.map(data => ({
                    ...data,
                    value: data.invcntStatusCode,
                    label: `${data.invcntStatusCode} - ${data.invcntStatusDesc}`,
                }))

                this.setState({
                    statusList
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('get', API_PATH.GET_MASTER_INVENTORY_DIFF_TYPE, '').then(res => {
            if (res.status === 200) {
                let typeList = res.data.results;
                typeList = typeList.map(data => ({
                    ...data,
                    value: data.diffTypeCode,
                    label: `${data.diffTypeCode} - ${data.diffTypeDesc}`,
                }))

                this.setState({
                    typeList
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        this.setState({
            loading: false
        });
    }

    handleGetQueryStringSearch = () => {
        const values = queryString.parse(this.props.location.search)
        if (Object.keys(values).length > 0){
             this.setState({
                inventoryCountDocNo: values.docNo
            }, () => this.handleClickSearch());
        }
    }

    handleClickSearch = async (e) => {
        const { inventoryCountDocNo, docList, outlet, startDate, endDate, statusListSelected, typeListSelected } = this.state
        this.setState({
            loading: true
        });

        let params = {
            inventoryCountDocNo: inventoryCountDocNo,
            docList: docList,
            outletId: outlet,
            countedDatetimeFrom: startDate,
            countedDatetimeTo: endDate,
            itemStatuses: statusListSelected,
            diffTypes: typeListSelected
        }

        await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_RESULT_SEARCH, params).then(res => {
            let dataList = res.data.results.map((data, i) => (
                {
                    ...data,
                    key: i
                }
            ));

            this.setState({
                dataList,
                loading: false
            });

            console.log(dataList);
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            this.setState({
                loading: false
            });
        });
    }

    handleChangeOutlet = (outlet) => {
        this.setState({ outlet })
    }

    handleChangeStatus = (val) => {
        const { statusList } = this.state
        if (val.includes('all')) {
            const all = map(statusList, 'value')
            this.setState({ statusListSelected: all })
        } else {
            this.setState({ statusListSelected: val })
        }
    }

    handleChangeType = (val) => {
        const { typeList } = this.state
        if (val.includes('all')) {
            const all = map(typeList, 'value')
            this.setState({ typeListSelected: all })
        } else {
            this.setState({ typeListSelected: val })
        }
    }

    handleChangeStartDate = (val) => {
        this.setState({ startDate: val })
    }

    handleChangeEndDate = (val) => {
        this.setState({ endDate: val })
    }

    handleChangeUnit1 = (index, val) => {
        let array = [...this.state.dataList]
        array[index].inputUnitCounted1 = val;
        this.setState({ dataList: array })
    }

    handleChangeUnit2 = (index, val) => {
        let array = [...this.state.dataList]
        array[index].inputUnitCounted2 = val;
        this.setState({ dataList: array })
    }

    handleInputQtyCountChange1 = (index, val) => {
        let array = [...this.state.dataList]
        array[index].inputQtyCounted1 = val.target.value;
        this.setState({ dataList: array })
    }

    handleInputQtyCountChange2 = (index, val) => {
        let array = [...this.state.dataList]
        array[index].inputQtyCounted2 = val.target.value
        this.setState({ dataList: array })
    }

    handleChangeInventoryCountNo = (e) => {
        this.setState({ inventoryCountDocNo: e.target.value })
    }

    handleChangeDocList = (e) => {
        this.setState({ docList: e.target.value })
    }

    handleClickSave = async (e) => {
        const { auth } = this.props
        this.setState({
            loading: true
        });
        let array = [...this.state.dataList]
        let sv = true;
        for (let el of array) {
            if (el.inputQtyCounted1 || el.inputUnitCounted1 || el.inputQtyCounted2 || el.inputUnitCounted2) {
                if (this.validateRequireField(el.inputQtyCounted1, el.inputUnitCounted1) && this.validateRequireField(el.inputQtyCounted2, el.inputUnitCounted2)) {
                    el.itemStatus = "COUNT";
                    el.QtyCounted = this.convertInputQtyToQtyCounted(el);
                    el.countedDatetime = new Date();
                    el.countedBy = auth.userId;
                    el.updatedDate = new Date();
                    el.updatedBy = auth.userId;
                } else {
                    swal('Error', "กรุณาใส่จำนวนและหน่วยนับให้ครบ", 'error')
                    sv = false;
                    break;
                }
            }
        }

        if (sv) {
            await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_RESULT_SAVE, array).then(res => {
                if (res.status === 200) {
                    swal('Success', res.data.message, 'success')
                    this.handleClickSearch();
                }
            }).catch(error => {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            });
        }
        this.setState({
            loading: false
        });
    }

    validateRequireField = (value, unit) => {
        if ((!value && unit) || (value && !unit)) {
            return false;
        }
        return true;
    }

    convertInputQtyToQtyCounted = (data) => {
        let qtyCounted = 0;
        let qtyCounted1 = 0;
        let qtyCounted2 = 0;

        let dataRow = data;
        let unitbase = dataRow.baseUnit;
        //var selectedbase = dataRow.UnitCodes.find(x => x.Text == unitbase);

        let unit1 = dataRow.inputUnitCounted1;
        let unit2 = dataRow.inputUnitCounted2;

        if (unit1 && unit2) {
            let selected1 = dataRow.unitModels.find(x => x.text === unit1);
            // Input 1
            // ถ้าหน่วยเดียวกัน Rate1 จะเท่ากัน
            if (selected1.numerator > selected1.denominator) {
                let InputQtyCounted1 = parseFloat(dataRow.inputQtyCounted1 || 0);
                let input2 = parseFloat(InputQtyCounted1 * selected1.numerator).toFixed(2);
                qtyCounted1 = input2;
            } else if (selected1.numerator < selected1.denominator) {
                let InputQtyCounted1 = parseFloat(dataRow.inputQtyCounted1 || 0);
                let input2 = parseFloat(InputQtyCounted1 / selected1.denominator).toFixed(2);
                qtyCounted1 = input2;
            } else {
                qtyCounted1 = parseFloat(dataRow.inputQtyCounted1 || 0);
            }

            let selected2 = dataRow.unitModels.find(x => x.text === unit2);
            // Input 2
            // ถ้าหน่วยเดียวกัน Rate1 จะเท่ากัน
            if (selected2.numerator > selected2.denominator) {
                let InputQtyCounted1 = parseFloat(dataRow.inputQtyCounted2 || 0);
                let input2 = parseFloat(InputQtyCounted1 * selected2.numerator).toFixed(2);
                qtyCounted2 = input2;
            } else if (selected2.numerator < selected2.denominator) {
                let InputQtyCounted1 = parseFloat(dataRow.inputQtyCounted2 || 0);
                let input2 = parseFloat(InputQtyCounted1 / selected2.denominator).toFixed(2);
                qtyCounted2 = input2;
            } else {
                qtyCounted2 = parseFloat(dataRow.inputQtyCounted2 || 0);
            }
        } else if (unit1) {
            let selected1 = dataRow.unitModels.find(x => x.text === unit1);
            // Input 1
            // ถ้าหน่วยเดียวกัน Rate1 จะเท่ากัน
            if (selected1.numerator > selected1.denominator) {
                let InputQtyCounted1 = parseFloat(dataRow.inputQtyCounted1 || 0);
                let input2 = parseFloat(InputQtyCounted1 * selected1.numerator).toFixed(2);
                qtyCounted1 = input2;
            } else if (selected1.numerator < selected1.denominator) {
                let InputQtyCounted1 = parseFloat(dataRow.inputQtyCounted1 || 0);
                let input2 = parseFloat(InputQtyCounted1 / selected1.denominator).toFixed(2);
                qtyCounted1 = input2;
            } else {
                qtyCounted1 = parseFloat(dataRow.inputQtyCounted1 || 0);
            }
        } else if (unit2) {
            let selected2 = dataRow.unitModels.find(x => x.text === unit2);
            // Input 2
            // ถ้าหน่วยเดียวกัน Rate1 จะเท่ากัน
            if (selected2.numerator > selected2.denominator) {
                let InputQtyCounted1 = parseFloat(dataRow.inputQtyCounted2 || 0);
                let input2 = parseFloat(InputQtyCounted1 * selected2.numerator).toFixed(2);
                qtyCounted2 = input2;
            } else if (selected2.numerator < selected2.denominator) {
                let InputQtyCounted1 = parseFloat(dataRow.inputQtyCounted2 || 0);
                let input2 = parseFloat(InputQtyCounted1 / selected2.denominator).toFixed(2);
                qtyCounted2 = input2;
            } else {
                qtyCounted2 = parseFloat(dataRow.inputQtyCounted2 || 0);
            }
        }

        qtyCounted = parseFloat(qtyCounted1) + parseFloat(qtyCounted2);

        return parseFloat(qtyCounted);
    }

    render() {
        const {
            outlets,
            outlet,
            statusList,
            statusListSelected,
            typeList,
            typeListSelected,
            loading,
            columns,
            dateFormat,
            widthLabel,
            dataList,
            startDate,
            endDate,
            inventoryCountDocNo,
            docList
        } = this.state

        return (
            <div className="inventory-count-result-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="ตรวจนับสต็อค"
                        icon="check-square"
                    />
                    <div className="middle-content">
                        <Row gutter={20}>
                            <Col sm={6}>
                                <DatePickerCustom
                                    onChange={this.handleChangeStartDate}
                                    widthDefault={widthLabel}
                                    name="startDate"
                                    label="วันที่ตรวจนับ"
                                    format={dateFormat}
                                    value={startDate}
                                />
                            </Col>
                            <Col sm={6}>
                                <DatePickerCustom
                                    onChange={this.handleChangeEndDate}
                                    widthDefault={widthLabel}
                                    name="endDate"
                                    label="ถึง"
                                    format={dateFormat}
                                    value={endDate}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputHero
                                    onChange={this.handleChangeInventoryCountNo}
                                    widthDefault={widthLabel}
                                    name="inventoryCountNo"
                                    label="เลขที่ใบตรวจนับ"
                                    value={inventoryCountDocNo}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputHero
                                    onChange={this.handleChangeDocList}
                                    widthDefault={widthLabel}
                                    name="docList"
                                    label="docList"
                                    value={docList}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col sm={6}>
                                {<SelectHero
                                    onChange={this.handleChangeOutlet}
                                    widthDefault={widthLabel}
                                    name="outlet"
                                    label="ร้านค้า"
                                    options={outlets}
                                    value={outlet}
                                />}
                            </Col>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    onChange={this.handleChangeStatus}
                                    name="status"
                                    label="Status"
                                    options={statusList}
                                    value={statusListSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    onChange={this.handleChangeType}
                                    name="type"
                                    label="Type"
                                    options={typeList}
                                    value={typeListSelected}
                                    mode="multiple"
                                    optionsDefault={[{ label: "ทั้งหมด", value: "all" }]}
                                    placeholder="ทั้งหมด"
                                    allowClear
                                    maxTagCount={1}
                                />
                            </Col>
                            <Col sm={6} className="search-box">
                                <ButtonCustom onClick={this.handleClickSearch} icon="search" type="primary" blue text="ค้นหา" />
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom columns={columns} scroll={{ x: "max-content", y: "calc(100vh - 250px)" }} data={dataList} rowKey={(row, index) => index} pagination={false} />
                        </div>
                    </div>
                    <div className="bottom-content">
                        <div className="action-box">
                            <div className="button">
                                <Link to={ROUTH_PATH.INVENTORY_COUNT_SEARCH}><ButtonCustom icon="arrow-left" type="danger" text="ยกเลิก" /></Link>
                            </div>
                            <div className="button">
                                <ButtonCustom onClick={this.handleClickSave} icon="save" text="บันทึก" />
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCountResultContainer)