import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { isEmpty, get, find } from 'lodash'
import FileSaver from 'file-saver'
import { apiServices, API_PATH } from 'apiServices'
import { functionAlias } from 'routes'
import { getPermissionPage } from 'helpers'
import {
	TitleHead,
	TableCustom,
} from 'components/common'
import { Spin } from 'antd'
import moment from 'moment'
import ReportTaxDocumentForm from './ReportTaxDocumentForm'
import swal from '@sweetalert/with-react'
import * as _ from 'lodash'
import './style.css'

class ReportTaxDocumentsContainer extends Component {
	state = {
		selectedRowKeys: [],
		columns: [
			// {

			// 	title: "เลือก",
			// 	align: 'center',
			// 	dataIndex: 'seletedDoc',
			// 	key: 'seletedDoc',
			// 	render: (value, full, index) => <div style={{ textAlign: 'center' }}>
			// 		<CheckBoxCustom
			// 			name="seletedDoc"
			// 			onChange={(e) => this.handleSeletedDoc(e, index)}
			// 			checked={value}
			// 		/>
			// 	</div>
			// },
			{
				title: 'รหัสร้าน',
				dataIndex: 'outletId',
				key: 'outletId',
			},
			{
				title: 'ร้าน',
				dataIndex: 'outletName',
				key: 'outletName',
			},
			{
				title: 'สาขา',
				dataIndex: 'outletTaxBranch',
				key: 'outletTaxBranch',
			},
			{
				title: 'เลขที่ใบกำกับภาษี',
				dataIndex: 'taxInvoiceNo',
				key: 'taxInvoiceNo',
			},
			/*{
				title: 'เลขที่เอกสารบัญชี',
				dataIndex: 'docNo',
				key: 'docNo',
			},*/
			{
				title: 'เลขที่เอกสาร',
				dataIndex: 'docNo',
				key: 'docNo',
			},
			{
				title: 'ประเภทเอกสาร',
				dataIndex: 'documentTypeName',
				key: 'documentTypeName',
			},
			{
				title: 'วันที่เอกสาร',
				dataIndex: 'documentDate',
				key: 'documentDate',
				align: 'center',
				render: (value) => (
					<div className="align-left">
						{moment(value).format('DD/MM/YYYY')}
					</div>
				)
			},
			{
				title: 'เวอร์ชั่นที่พิมพ์ล่าสุด',
				dataIndex: 'printCount',
				key: 'printCount'
			},
			{
				title: 'วันที่พิมพ์ล่าสุด',
				dataIndex: 'printedDate',
				key: 'printedDate',
				align: 'center',
				render: (value) => (
					<div className="align-left">
						{moment(value).format('DD/MM/YYYY')}
					</div>
				)
			},
			{
				title: 'จำนวนเงินสุทธิ',
				dataIndex: 'netAmount',
				key: 'netAmount',
			},
			{
				title: 'จำนวนเงินของ CN',
				dataIndex: 'netAmountCN',
				key: 'netAmountCN',
			},
			{
				title: 'Cancel Flag',
				dataIndex: 'documentStatus',
				key: 'documentStatus',
			},
			{
				title: 'Upload Flag',
				dataIndex: 'uploadStatus',
				key: 'uploadStatus',
			}
		],
		loading: false,
		reports: [],
		selectedDoc: [],
		exports: [],
		docTypeFilter: 'ZDEP,ZPMT,ZCN,ZCNR,ZCNN,ZDN,ZCND',
		permissionPage: getPermissionPage(functionAlias.reportSpecialTax),
	}

	getParams = (filter) => {

		const { auth } = this.props
		let params = {
			documentTypeCode: filter.docTypes && filter.docTypes.length > 0 ? filter.docTypes.join(",") : this.state.docTypeFilter,//filter.documentTypeCode,

			// documentTypeCode: filter.docTypes && filter.docTypes.length < 1 ? filter.docTypes.join(",") : this.state.docTypeFilter,//filter.documentTypeCode,
			documentDateFrom: moment(filter.docDateFrom).format('YYYY-MM-DD'),
			documentDateTo: moment(filter.docDateTo).format('YYYY-MM-DD'),
			outletId: filter.outlets.length > 0 ? filter.outlets.join(",") : auth.arrayOutlet.join(","),
			documentNo: filter.docNo.indexOf(':') == -1 ? filter.docNo : '',
			documentNoFrom: filter.docNo.indexOf(':') == -1 ? '' : filter.docNo.split(':')[0],
			documentNoTo: filter.docNo.indexOf(':') == -1 ? '' : filter.docNo.split(':')[1],
		};
		return params;
	}

	handleSeletedDoc(e, index) {
		e.preventDefault();
		// console.log("e ===> ", e.target.checked);
		let { reports } = this.state;
		reports[index].seletedDoc = e.target.checked;
		this.setState({ reports });
	}

	exportReport = async (filter) => {
		const params = this.getParams(filter)
		this.setState({ loading: true })
		await apiServices.callApi('post', API_PATH.POST_EXPORT_DMS, params, { responseType: 'arraybuffer' }).then(res => {
			if (res.status === 200) {
				const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
				FileSaver.saveAs(blob, `ReportDMSFile-${moment().format('DD-MM-YYYY')}.xlsx`)
			} else {
				throw res
			}
		}).catch(error => {
			const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
			swal('Error', message, 'error')
		}).finally(() => {
			this.setState({ loading: false })
		})
	}

	printReport = async (filter) => {
		let { reports, selectedRowKeys } = this.state;
		// let seletedDocs = reports.filter(x => x.seletedDoc === true);
		let seletedDocs = reports.filter(x => selectedRowKeys.indexOf(x.key) >= 0)

		if (seletedDocs && seletedDocs.length > 0) {
			const params = this.getParams(filter)
			params.DocumentSeletedIds = seletedDocs.map((x) => x.printDocumentId);
			// debugger;
			this.setState({ loading: true })
			await apiServices.callApi('post', API_PATH.GET_DMS_MANUAL_PRINT, params, { responseType: 'arraybuffer' }).then(res => {
				if (res.status === 200) {
					const blob = new Blob([res.data], { type: 'application/pdf' })
					FileSaver.saveAs(blob, `test-${moment().format('DD-MM-YYYY')}.pdf`)
				} else {
					throw res
				}
			}).catch(error => {
				const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการพิมพ์เอกสาร กรุณาลองใหม่อีกครั้ง')
				swal('Error', message, 'error')
			}).finally(() => {
				this.setState({ loading: false , selectedRowKeys: []})
			})
		}
		else {
			swal('Error', 'กรุณาเลือกเอกสารเพื่อปริ้นสถานะ', 'error')
		}
	}

	searchReport = async (filter) => {
		var reports = [];
		let params = this.getParams(filter);
		this.setState({ loading: true })
		await apiServices.callApi('post', API_PATH.POST_DMS_SEARCH, params).then(res => {
			let dataList = res.data.results.map((data, i) => (
				{
					...data,
					seletedDoc: false,
					key: i
				}
			));
			reports = dataList;
		}).catch(error => {
			const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
			swal('Error', message, 'error')
		}).finally(() => {
			this.setState({ loading: false, reports, selectedRowKeys: [] });
		});
	}

	updateUploadStatusReport = async (filter) => {
		let { reports, selectedRowKeys } = this.state;
		// let seletedDocs = reports.filter(x => x.seletedDoc === true);

		let seletedDocs = reports.filter(x => selectedRowKeys.indexOf(x.key) >= 0)
		if (seletedDocs && seletedDocs.length > 0) {
			this.setState({ loading: true })
			let params = this.getParams(filter);
			params.DocumentSeletedIds = seletedDocs.map((x) => x.printDocumentId);
			await apiServices.callApi('post', API_PATH.POST_UPDATE_UPLOAD_STATUS_DMS, params).then(res => {
				swal(res.data.message, '', "success")
			}).catch(error => {
				const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
				swal('Error', message, 'error')
			}).finally(() => {
				this.setState({ loading: false, reports, selectedRowKeys: [] });
			});
		}
		else {
			swal('Error', 'กรุณาเลือกเอกสารเพื่อปรับสถานะ', 'error')
		}

	}

	validateFilter = (filter) => {
		if (filter.outlets && isEmpty(filter.outlets)) return { isError: true, message: 'กรุณาระบุร้านค้า' }
		if (filter.docTypes && isEmpty(filter.docTypes)) return { isError: true, message: 'กรุณาระบุประเภทเอกสาร' }
		if (filter.docDateFrom && filter.docDateTo && (filter.docDateTo).isBefore(filter.docDateFrom)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }

		return { isError: false }
	}
	selectRow = (record) => {
		const selectedRowKeys = [...this.state.selectedRowKeys];
		if (selectedRowKeys.indexOf(record.key) >= 0) {
			selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
		} else {
			selectedRowKeys.push(record.key);
		}
		this.setState({ selectedRowKeys });
	}
	onSelectedRowKeysChange = (selectedRowKeys) => {
		this.setState({ selectedRowKeys });
	}

	render() {
		const {
			auth
		} = this.props

		const {
			columns,
			reports,
			loading,
			permissionPage,
			selectedRowKeys
		} = this.state;
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectedRowKeysChange,
			// columnWidth: '200px'
			// columnTitle: 'เลือกทั้งหมด'
		};

		return (
			<div className="report-tax-document-container">
				<Spin spinning={loading}>
					<TitleHead
						text="รายงานพิมพ์เอกสารทางภาษี"
						icon="hdd"
					/>
					<div className="top-layout">
						<ReportTaxDocumentForm
							auth={auth}
							onSubmit={this.searchReport}
							onClickExport={this.exportReport}
							onClickPrint={this.printReport}
							onClickUpdateUploadStatus={this.updateUploadStatusReport}
							permissionPage={permissionPage}
						/>
					</div>
					<div className="middle-content">
						<div className="table-layout">
							<TableCustom
								height="calc(100vh - 366px)"
								rowSelection={rowSelection}
								onRow={(record) => ({
									onClick: () => {
										this.selectRow(record);
									},
								})}
								columns={columns}
								data={reports}
								isFullContent
								rowKey={(row, index) => index}
								small
								pagination={false}
							/>
						</div>
					</div>
				</Spin>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		auth: state.auth
	}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		authData
	}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportTaxDocumentsContainer)