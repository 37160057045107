import React from 'react'
import { connect } from "react-redux";
import { Field, reduxForm, change } from 'redux-form'
import { Row, Col } from "antd"
import { VALIDATE } from "helpers"
import { SelectRedux, InputFormRedux, DatePickerRedux } from 'components'
import { first, find } from 'lodash';
import { InputCustom, InputSearchCustom } from 'components/common';

class PaymentForm extends React.Component {
    state = {
        formCredit: false,
        formBank: false,
        formCoupon: false,
        formVendor: false,
        formHoldingTax: false,
        formSFinPay: false,
        baseOfTax: 0,
        whtTaxRate: 0,
        SCGIDcoupons: []
    }

    handlePaymentTypeChang = (val) => {
        const { paymentTypeOptions, setPaymentTypeDescription, setCouponType, setBankAccount } = this.props;

        //console.log("PaymentType==>", val);
        //console.log("paymentTypeOptions==>", paymentTypeOptions);
        const paymentTypes = paymentTypeOptions.filter(p => p.value === val);
        //console.log("paymentGroup==>", first(paymentTypes).paymentGroup);
        switch (first(paymentTypes).paymentGroup.toLowerCase()) {
            case "creditcard":
                this.setState({
                    paymentType: val,
                    formCredit: true,
                    formBank: false,
                    formCoupon: false,
                    formVendor: false,
                    formHoldingTax: false,
                    formSFinPay: false,
                });
                break;
            case "banktransfer":
                this.setState({
                    paymentType: val,
                    formCredit: false,
                    formBank: true,
                    formCoupon: false,
                    formVendor: false,
                    formHoldingTax: false,
                    formSFinPay: false,
                });
                if (val === "ecommerce") {
                    setBankAccount('123456789');
                }
                break;
            case "coupongroup":
                this.setState({
                    paymentType: val,
                    formCredit: false,
                    formBank: false,
                    formCoupon: true,
                    formVendor: false,
                    formHoldingTax: false,
                    formSFinPay: false,
                });
                break;
            case "vendor":
                this.setState({
                    paymentType: val,
                    formCredit: false,
                    formBank: false,
                    formCoupon: false,
                    formVendor: true,
                    formHoldingTax: false,
                    formSFinPay: false,
                });
                break;
            case "withholdingtax":
                this.setState({
                    paymentType: val,
                    formCredit: false,
                    formBank: false,
                    formCoupon: false,
                    formVendor: false,
                    formHoldingTax: true,
                    formSFinPay: false,
                });
                break;
            case "sfinpay":
                this.setState({
                    paymentType: val,
                    formCredit: false,
                    formBank: false,
                    formCoupon: false,
                    formVendor: false,
                    formHoldingTax: false,
                    formSFinPay: true,
                });
                break;
            default:
                break;
        }

        setCouponType('');
        //console.log("PaymentTypeDescription==>", first(paymentTypes).label);
        setPaymentTypeDescription(first(paymentTypes).label);
        
        this.setState({ paymentType: val });
    }

    handleWithHoldingTaxChang = (val) => {
        const { setPaymentTypeDescription, withHoldingTaxOptions } = this.props;

        //console.log("PaymentType==>", val);
        //console.log("withHoldingTaxOptions==>", withHoldingTaxOptions);
        const withHoldingTaxs = withHoldingTaxOptions.filter(p => p.value === val);
        //console.log("PaymentTypeDescription==>", first(withHoldingTaxs).label);
        setPaymentTypeDescription(first(withHoldingTaxs).label);
    }

    handleBookBankChang = (val) => {
        let { bankAccountDetail } = this.state;
        const { bookBankMasters } = this.props;
        //console.log(`val ==> ${val}`);
        //console.log(`bookBankMasters ==> `, bookBankMasters);
        let bookBankFilter = bookBankMasters.filter(function (bookBank) {
            return bookBank.bookbankNumber === val;
        });
        //console.log(`bookBankFilter ==> `, bookBankFilter);
        if (bookBankFilter.length > 0) {
            bankAccountDetail = `เลขบัญชี: ${bookBankFilter[0].bookbankNumber}, ธนาคาร: ${bookBankFilter[0].remark}, รหัสธนาคาร: ${bookBankFilter[0].bankCode}, รหัสสาขา: ${bookBankFilter[0].bankBranchCode}`
            this.setState({ bankAccountDetail });
        }
    }

    handleCouponTypeChang = (val) => {
        let { bankAccountDetail } = this.state;
        const { bookBankMasters, couponTypeOptions, setPaymentTypeDescription } = this.props;
        //console.log(`val ==> ${val}`);
        //console.log(`bookBankMasters ==> `, bookBankMasters);
        let bookBankFilter = bookBankMasters.filter(function (bookBank) {
            return bookBank.bookbankNumber === val;
        });
        //console.log(`bookBankFilter ==> `, bookBankFilter);
        if (bookBankFilter.length > 0) {
            bankAccountDetail = `เลขบัญชี: ${bookBankFilter[0].bookbankNumber}, ธนาคาร: ${bookBankFilter[0].remark}, รหัสธนาคาร: ${bookBankFilter[0].bankCode}, รหัสสาขา: ${bookBankFilter[0].bankBranchCode}`
            this.setState({ bankAccountDetail });
        }

        //console.log("PaymentType==>", val);
        //console.log("couponTypeOptions==>", couponTypeOptions);
        const couponTypes = couponTypeOptions.filter(p => p.value === val);
        //console.log("PaymentTypeDescription==>", first(couponTypes).label);
        const couponsSCGType = val.replace('scg_family_', '')
        const couponsSCGID = this.filterSCGFamilyCouponType(couponsSCGType)
        this.setState({ SCGIDcoupons: couponsSCGID })
        setPaymentTypeDescription(first(couponTypes).label);
    }
    selectSCGFamilyCoupon(val) {
        const coupon = find(this.state.SCGIDcoupons, { coupon_code: val })
        if (coupon) {
            const amount = coupon.discount
            this.props.setAmount(amount)
        }
    }
    filterSCGFamilyCouponType(type) {
        let coupons = this.props.couponOptions
        coupons = coupons.filter(item => {
            if (item.transaction_type === type) {
                return true
            } else {
                return false
            }
        })
        return coupons
    }

    handleChangeBaseOfTax = (val) => {
        const { setAmount } = this.props;
        let { baseOfTax, whtTaxRate } = this.state;
        baseOfTax = parseFloat(val || 0);
        //console.log('handleChangeBaseOfTax==>', val);
        setAmount(((parseFloat(baseOfTax) * parseFloat(whtTaxRate)) / 100).toFixed(2) || 0);
        this.setState({ baseOfTax });
    }

    handleChangeWhtTaxRate = (val) => {
        const { setAmount } = this.props;
        let { baseOfTax, whtTaxRate } = this.state;
        whtTaxRate = parseFloat(val || 0);
        //console.log('handleChangeBaseOfTax==>', val);
        setAmount(((parseFloat(baseOfTax) * parseFloat(whtTaxRate)) / 100).toFixed(2) || 0);
        this.setState({ whtTaxRate });
    }

    render() {
        const {
            formCredit,
            formBank,
            formCoupon,
            formVendor,
            formHoldingTax,
            formSFinPay,
            bankAccountDetail,
            paymentType,
            SCGIDcoupons
        } = this.state
        const {
            submitViewError,
            paymentTypeOptions,
            bankMachineOptions,
            bankCardOptions,
            bookBankMastersOptions,
            couponTypeOptions,
            withHoldingTaxOptions,
        } = this.props
        //console.log('this.props', this.props);

        const amountTaxDisable = (!this.state.baseOfTax) && (!this.state.whtTaxRate);
        return (
            <div>
                <Row gutter={10}>
                    <Col sm={12}>
                        <Field
                            validate={VALIDATE.REQUIRE}
                            submitViewError={submitViewError}
                            component={SelectRedux}
                            name="paymentType"
                            label="ประเภทการชำระ"
                            options={paymentTypeOptions}
                            pleaseChoose={true}
                            onChange={this.handlePaymentTypeChang}
                        />
                        <Field
                            component={InputFormRedux}
                            name="paymentTypeDescription"
                            type="hidden"
                        />
                    </Col>
                </Row>

                {formCredit === true &&
                    <div>
                        <Row gutter={10}>
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={InputFormRedux}
                                    name="cardNumber"
                                    label="เลขที่บัตร"
                                    placeholder="XXXX XXXX XXXX XXXX"
                                />
                            </Col>
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={SelectRedux}
                                    name="bankMachine"
                                    label="เครื่องรูด"
                                    options={bankMachineOptions}
                                    pleaseChoose={true}
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={SelectRedux}
                                    name="bankCard"
                                    label="ธนาคารบัตร"
                                    options={bankCardOptions}
                                    pleaseChoose={true}
                                />
                            </Col>
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.NUMBER}
                                    submitViewError={submitViewError}
                                    component={InputFormRedux}
                                    name="confirmNo"
                                    label="เลขที่อนุมัติ"
                                    placeholderDefault={true}
                                    type="number"
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col sm={12}>
                                <Field
                                    // validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={InputFormRedux}
                                    name="paymentFee"
                                    label="ค่าธรรมเนียม"
                                    placeholderDefault={true}
                                />
                            </Col>
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={InputFormRedux}
                                    name="amount"
                                    label="จำนวนเงิน"
                                    placeholderDefault={true}
                                />
                            </Col>
                        </Row>
                    </div>
                }
                {/* ecommerce_fee ecommerce_coupon */}
                {formBank === true &&
                    <Row gutter={10}>
                        <Col sm={12}>
                            {paymentType === 'ecommerce' ?
                                <Field
                                    component={InputFormRedux}
                                    name="bankAccount"
                                    label="เลขที่บัญชี"
                                    disabled={true}
                                />
                                : (paymentType === 'ecommerce_fee' || paymentType === 'ecommerce_coupon' ?
                                    <Field
                                        validate={VALIDATE.REQUIRE}
                                        submitViewError={submitViewError}
                                        component={SCGIDcoupons.length > 0 ? SelectRedux : InputCustom}
                                        name="couponNumber"
                                        options={SCGIDcoupons}
                                        label="เลขที่คูปอง"
                                        onChange={(val) => { this.selectSCGFamilyCoupon(val) }}
                                        placeholderDefault={true}
                                    />
                                    : <Field
                                        validate={VALIDATE.REQUIRE}
                                        submitViewError={submitViewError}
                                        component={SelectRedux}
                                        name="bankAccount"
                                        label="เลขที่บัญชี"
                                        options={bookBankMastersOptions}
                                        pleaseChoose={true}
                                        onChange={this.handleBookBankChang()}
                                    />
                                )
                            }

                        </Col>
                        <Col sm={12}>
                            {bankAccountDetail}
                        </Col>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={DatePickerRedux}
                                name="transferDate"
                                label="วันที่โอน"
                                allowClear={false}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="amount"
                                label="จำนวนเงิน"
                                placeholderDefault={true}
                            />
                        </Col>
                        {paymentType === 'ecommerce' ?
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={InputFormRedux}
                                    name="confirmNo"
                                    label="เลขที่อนุมัติ"
                                    placeholderDefault={true}
                                />
                            </Col> : ''}
                        {paymentType === 'ecommerce_coupon' && <Col sm={12}> <Field
                            validate={VALIDATE.MIN_15}
                            submitViewError={submitViewError}
                            component={InputFormRedux}
                            name="confirmNo"
                            label="เลขที่อนุมัติ"
                            placeholderDefault={true}
                            maxLength = {20}
                            // type="number"
                        /> </Col>}
                    </Row>
                }

                {formCoupon === true &&
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={SelectRedux}
                                name="couponType"
                                label="ประเภทคูปอง"
                                options={couponTypeOptions}
                                pleaseChoose={true}
                                onChange={(val) => this.handleCouponTypeChang(val)}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={SCGIDcoupons.length > 0 ? SelectRedux : InputSearchCustom}
                                name="couponNumber"
                                options={SCGIDcoupons}
                                label="เลขที่คูปอง"
                                onChange={(val) => { this.selectSCGFamilyCoupon(val) }}
                                onSubmit={(val) => { this.props.searchVoucher(val, this.props) }}
                                onClickSearch={(val) => { this.props.searchVoucher(val, this.props) }}
                                placeholderDefault={true}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                // validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={DatePickerRedux}
                                name="expiredDate"
                                label="วันหมดอายุ"
                            // onChange={(val) => console.log('onChange')}
                            // onChangeHero={(val) => console.log('onChangeHero')}
                            />
                        </Col>

                        <Col sm={12}>
                            <Field
                                // validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="amount"
                                label="จำนวนเงิน"
                                placeholderDefault={true}
                            />
                        </Col>

                    </Row>
                }

                {formVendor === true &&
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="vendor"
                                label="ผู้ผลิต"
                                placeholderDefault={true}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="amount"
                                label="จำนวนเงิน"
                                placeholderDefault={true}
                            />
                        </Col>
                    </Row>
                }

                {formHoldingTax === true &&
                    <div>
                        <Row gutter={10}>
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={SelectRedux}
                                    name="whtCode"
                                    label="ประเภทภาษีถูกหัก ณ ที่จ่าย"
                                    options={withHoldingTaxOptions}
                                    pleaseChoose={true}
                                />
                            </Col>
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={InputFormRedux}
                                    name="paymentNumber"
                                    label="เลขที่ภาษีถูกหัก ณ ที่จ่าย"
                                    placeholderDefault={true}
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={InputFormRedux}
                                    name="baseOfTax"
                                    label="ฐานภาษี (ก่อน vat)"
                                    placeholderDefault={true}
                                    onChange={(e) => this.handleChangeBaseOfTax(e.target.value)}
                                />
                            </Col>
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={InputFormRedux}
                                    name="wTaxRate"
                                    label="อัตราภาษี(%)"
                                    placeholderDefault={true}
                                    onChange={(e) => this.handleChangeWhtTaxRate(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={InputFormRedux}
                                    name="amount"
                                    label="ยอดภาษี"
                                    placeholderDefault={true}
                                    disabled={amountTaxDisable}
                                />
                            </Col>
                            <Col sm={12}>
                                <Field
                                    validate={VALIDATE.REQUIRE}
                                    submitViewError={submitViewError}
                                    component={DatePickerRedux}
                                    name="paymentDate"
                                    label="วันที่ชำระ"
                                    placeholderDefault={true}
                                />
                            </Col>
                        </Row>
                    </div>
                }

                {formSFinPay === true &&
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="amount"
                                label="จำนวนเงิน"
                                placeholderDefault={true}
                            />
                        </Col>
                    </Row>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        CustomerDetailFormRedux: state.form.CustomerDetailFormRedux,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setAmount: (data) => dispatch(change(`PaymentForm`, `amount`, data)),
    setCouponType: (data) => dispatch(change(`PaymentForm`, `couponType`, data)),
    setPaymentTypeDescription: (data) => dispatch(change(`PaymentForm`, `paymentTypeDescription`, data)),
    setBankAccount: (data) => dispatch(change(`PaymentForm`, `bankAccount`, data)),
    setVoucherAmount: (data) => dispatch(change(`PaymentForm`, `amount`, data)),
    setVoucherDate: (data) => dispatch(change(`PaymentForm`, `expiredDate`, data)),
});

export default reduxForm({
    form: 'PaymentForm',  // a unique identifier for this form
    enableReinitialize: true
})
    (
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(PaymentForm)
    );

/*export default reduxForm({
    form: 'PaymentForm',  // a unique identifier for this form
    enableReinitialize: true
})(PaymentForm)*/
