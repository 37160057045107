import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { get, isEmpty } from 'lodash'
import swal from '@sweetalert/with-react'
import FileSaver from 'file-saver'
import {
	TitleHead,
	ButtonCustom,
	TableCustom,
	RadioCustom,
	InputHero,
	DatePickerCustom,
	SelectHero,
} from 'components'
import { Row, Col, Spin, Dropdown } from 'antd'
import moment from 'moment'
import { apiServices, API_PATH } from 'apiServices'

const maxLengthBillingNumber = 10

export class WebMarginCSVBYBillingContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			columns: [
				{
					title: 'ร้าน',
					align: 'center',
					dataIndex: 'outlet_name',
					key: 'outlet_name',
					ellipsis: true,
				},
				{
					title: 'Billing No',
					align: 'center',
					dataIndex: 'billing_no',
					key: 'billing_no',
					ellipsis: true,
				},
				{
					title: 'ประเภทเอกสาร',
					align: 'center',
					dataIndex: 'document_type_name',
					key: 'document_type_name',
					ellipsis: true
				},
				{
					title: 'สถานะเอกสาร',
					align: 'center',
					dataIndex: 'document_status',
					key: 'document_status',
					ellipsis: true,
				},
				{
					title: 'วันที่เอกสาร',
					align: 'center',
					dataIndex: 'document_date',
					key: 'document_date',
					ellipsis: true,
				},
				{
					title: 'ชื่อลูกค้า',
					align: 'center',
					dataIndex: 'customer_name',
					key: 'customer_name',
					ellipsis: true,
				},
				{
					title: 'จำนวนเงิน',
					align: 'center',
					dataIndex: 'net_amount',
					key: 'net_amount',
					ellipsis: true,
				},
				{
					title: 'เลขที่เอกสารอ้างอิง',
					align: 'center',
					dataIndex: 'reference_doc_number',
					key: 'reference_doc_number',
					ellipsis: true,
				},
				{
					title: 'ระบบอ้างอิง',
					align: 'center',
					dataIndex: 'reference_doc_system',
					key: 'reference_doc_system',
					ellipsis: true,
				},
				{
					title: 'Interface',
					align: 'center',
					dataIndex: 'interface_name',
					key: 'interface_name',
					ellipsis: true,
				},
				{
					title: 'สถานะ Interface',
					align: 'center',
					dataIndex: 'interface_status',
					key: 'interface_status',
					ellipsis: true,
				},
				{
					title: 'ข้อความ Interface',
					dataIndex: 'interface_message',
					key: 'interface_message',
					ellipsis: true,
				},
			],
			modes: [
				{ label: 'Report', value: 'R' },
				{ label: 'Interface', value: 'I' },
				{ label: 'Return Interface', value: 'RERUN' },
			], loading: false,
			dateFormat: 'DD/MM/YYYY',
			docType: [],
			outlets: [],
			cnData: [],
			filter: {
				mode: 'R',
				docType: '',
				BillingNumber: '',
				billingNumberTo: '',
				documentDateFrom: moment().startOf('day'),
				documentDateTo: moment().startOf('day'),
				outletId: '',
			},
			pagination: {
				limit: 10,
			},
			loading: false,
			widthLabel: '100px'
		}
		this.onExport = this.onExport.bind(this)
		this.onRun = this.onRun.bind(this)
		this.onClear = this.onClear.bind(this)
		this.fetchData = this.fetchData.bind(this)
	}
	async	componentDidMount() {
		await this.fetchData()
	}
	async	fetchData() {
		let { outlets, docType } = this.state
		let paramsOutlet = {
			outletIds: this.props.auth.userOutletModelList.map((data) => (
				data.outletId
			))
		};
		let resOutlet = await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet)
		let resDocType = await apiServices.callApi('get', API_PATH.GET_MASTER_DOCUMENT_TYPE)
		const docFilter = ["ZCN", "ZCNR", "ZCNN", "ZCND", "ZCNP"]
		if (resDocType.status === 200 && resOutlet.status === 200) {
			docType = resDocType.data.results.reduce((result, data) => {
				if (docFilter.includes(data.documentTypeCode)) {
					result.push({ ...data, label: `${data.documentTypeCode} - ${data.documentTypeDescription}`, value: data.documentTypeCode })
				}
				return result
			}, [])
			outlets = resOutlet.data.results.map(data => {
				return {
					...data,
					label: `${data.outletId} - ${data.outletName}`,
					value: data.outletId,
				}
			});
		}
		this.setState({
			docType, outlets
		})
	}

	changeFilter(key, value) {
		const { filter } = this.state;
		let changevalue = { [key]: value }
		if (key === 'BillingNumber') {
			changevalue = {
				...changevalue,
				documentDateFrom: null,
				documentDateTo: null
			}
		}		
		this.setState({ filter: { ...filter,...changevalue } });
	}

	validateFilter = () => {
		const { filter } = this.state;

		if (!filter.outletId) return { isError: true, message: 'กรุณาระบุร้าน!' }
		// if (!filter.docType) return { isError: true, message: 'กรุณาระบุประเภทเอกสาร!' }
		// if (!filter.documentDateFrom && !filter.BillingNumber) return { isError: true, message: 'กรุณาระบุวันที่เริ่มต้น!' }

		return { isError: false }
	}

	async onRun() {

		const valid = this.validateFilter()
		if (valid.isError) {
			swal('Warning', valid.message, 'warning')
			return
		}

		this.setState({ loading: true })
		const state = { ...this.state }
		const stateData = state.filter
		const body = {
			outletId: stateData.outletId,
			documentTypeCode: stateData.docType,
			BillingNumber: stateData.BillingNumber || '',
			// billingNumberTo: stateData.billingNumberTo || '',
			documentDateFrom: stateData.documentDateFrom ? moment(stateData.documentDateFrom).format('YYYY-MM-DD') : '',
			documentDateTo: stateData.documentDateTo ? moment(stateData.documentDateTo).format('YYYY-MM-DD') : ''
		}
		// console.log('body', body);


		const url = `${API_PATH.POST_CREDIT_NOTE_MANUAL_INTERFACE}?mode=${stateData.mode}`
		await apiServices.callApi('post', url, body).then(res => {
			let documents = _.get(res, 'data.results')
			if (res.status === 200) {
				documents = documents.map(item => {
					const docType = _.find(state.docType, { documentTypeCode: item.document_type_code })
					const outlet = _.find(state.outlets, { outletId: item.outlet_id })
					item.document_type_name = docType ? docType.documentTypeDescription : ''
					item.outlet_name = outlet ? outlet.outletName : item.outlet_id
					item.document_date = moment(item.document_date).format(state.dateFormat)
					return item
				})
				this.setState({ cnData: documents })
			} else {
				throw res
			}
		}).catch(error => {
			const message = _.get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง')
			swal('Error', message, 'error')
		}).finally(() => {
			this.setState({ loading: false })
		})

		/*
		const resCN = await apiServices.callApi('post', API_PATH.POST_CREDIT_NOTE_MANUAL_INTERFACE + `?mode=${stateData.mode}`, body)
		if (resCN.status === 200) {
			state.cnData = resCN.data.results.map(item => {
				const docType = _.find(state.docType, { documentTypeCode: item.document_type_code })
				const outlet = _.find(state.outlets, { outletId: item.outlet_id })
				item.document_type_name = docType ? docType.documentTypeDescription : ''
				item.outlet_name = outlet ? outlet.outletName : item.outlet_id
				item.document_date = moment(item.document_date).format(state.dateFormat)
				return item
			})
			this.setState({
				cnData: state.cnData,
				loading: false
			})
		}
		*/

	}
	onClear() {
		const reset = {
			// mode: 'R',
			// docType: '',
			BillingNumber: '',
			documentDateFrom: moment().startOf('day'),
			documentDateTo: moment().startOf('day'),
			outletId: '',
		}
		this.setState({ filter: reset })
	}

	async onExport() {
		const { filter } = this.state
		const valid = this.validateFilter()
		if (valid.isError) {
			swal('Warning', valid.message, 'warning')
			return
		}
		if (!filter.documentDateFrom && !filter.BillingNumber) {
			swal('Error', 'กรุณากรอกเลข Billing Number', 'error')
			return
		}


		this.setState({ loading: true })
		// const body = this.state.filter
		const params = {
			OutletIds: [
				filter.outletId
			],
			BillingNumber: filter.BillingNumber || '',
			documentDateFrom: filter.documentDateFrom ? moment(filter.documentDateFrom).format('YYYY-MM-DD') : '',
			documentDateTo: filter.documentDateTo ? moment(filter.documentDateTo).format('YYYY-MM-DD') : ''
		}

		await apiServices.callApi('post', API_PATH.GET_WEBMARGIN_CSV_BY_BILLING, params, {
			headersOption: '',
			responseType: 'arraybuffer'
		}).then(async res => {
			if (res.status === 200) {
				let data = new Blob([res.data], {
					type: 'application/x-zip-compressed'
				});
				FileSaver.saveAs(data, `ReportExportManualWebMarginCSVBYBilling-${moment().format('DD-MM-YYYY')}.zip`)
			} else {
				throw res
			}
		}).catch(error => {
			const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
			swal('Error', message, 'error')
		}).finally(() => {
			this.setState({ loading: false })
		})

	}

	render() {
		const { modes, filter, dateFormat, widthLabel, docType, outlets, columns, cnData, loading } = this.state
		const { permissionPage } = this.props;
		// GET_WEBMARGIN_CSV_BY_BILLING
		// console.log(this.state)
		return (
			<div>
				<Spin spinning={loading}>
					<TitleHead
						text="Create Web Margin Text file"
						icon="file-text"
					/>
					<div className="middle-content">
						{/* <Row style={{ padding: '10px 0' }}>
						<Col sm={24}>
							<RadioCustom
								widthDefault={widthLabel}
								options={modes}
								value={filter.mode}
								label="Mode"
								onChange={(e) => this.changeFilter('mode', e.target.value)}
							/>
						</Col>
					</Row> */}


						<Row>
							<Col sm={8}>
								<InputHero
									label="Billing Number"
									widthDefault={widthLabel}
									value={filter.BillingNumber}
									onChange={(e) => this.changeFilter('BillingNumber', e.target.value)}
								// maxLength={maxLengthBillingNumber}
								/>
							</Col>
						</Row>

						<Row>
							<Col sm={8}>
								<SelectHero
									widthDefault={widthLabel}
									label="ร้าน"
									options={outlets}
									value={filter.outletId}
									onChange={(val) => this.changeFilter('outletId', val)}
								/>
							</Col>
						</Row>

						<Row>
							<Col sm={8}>
								<DatePickerCustom
									label="วันที่"
									widthDefault={widthLabel}
									value={filter.documentDateFrom}
									format={dateFormat}
									allowClear={false}
									onChange={(date) => this.changeFilter('documentDateFrom', date)}
								/>
							</Col>
							<Col sm={8}>
								<DatePickerCustom
									label="ถึง"
									right
									widthDefault={widthLabel}
									value={filter.documentDateTo}
									format={dateFormat}
									allowClear={false}
									onChange={(date) => this.changeFilter('documentDateTo', date)}
								/>
							</Col>
						</Row>
						<Row gutter={8} type="flex" align="middle" justify="end">
							<Col>
								<ButtonCustom
									icon="file-excel"
									text="Export"
									green
									onClick={this.onExport}
								// disabled={!permissionPage.authExport}
								// disabled={!permissionPage.authExport || filter.mode !== 'Report' || (filter.mode === 'Report' && isEmpty(infIncomingPayment))}
								/>
							</Col>
							<Col>
								<ButtonCustom
									icon="clear"
									text="ล้างหน้าจอ"
									yellow
									onClick={this.onClear}
								/>
							</Col>
							{/* <Col>
							<ButtonCustom
								icon="run"
								text="Run"
								primary
								onClick={this.onRun}
							/>
						</Col> */}
						</Row>
					</div>
					{/* <div>
					<Row style={{ paddingTop: 20 }}>
						<Col sm={24}>
							<TableCustom
								loading={loading}
								height="calc(100vh - 411px)"
								columns={columns}
								data={cnData}
								small
								rowKey={(row, index) => index}
							/>
						</Col>
					</Row>
				</div> */}
				</Spin>
			</div>
		)
	}
}
const mapStateToProps = state => {
	// console.log("state===>", state);
	return {
		auth: state.auth
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		authData
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WebMarginCSVBYBillingContainer);