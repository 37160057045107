import React, { Component } from 'react'
import { Layout, Menu, Icon, Dropdown } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, Route, Switch, withRouter, useLocation } from 'react-router-dom';

import swal from '@sweetalert/with-react';
import IdleTimer from 'react-idle-timer';
import { dashboardRoute, forceRoute, homePageRoute } from "../../routes";
import { authData } from "../../actions/actionsCreator";
import logo from "./images/logo_scg.png";
import logoIcon from "./images/logo-icon.png";
import './style.css';
import { Breadcrumb, Tooltip } from 'antd';
import AccessDeniedContainer from "./containers/AccessDeniedContainer";
import NotFoundContainer from "../../pages/NotFound";
import { ROUTH_PATH } from '../../routes/path';
import { formatNumber, saleTypes, getPermissionPage, isOutletIdAuth, sortDataColumn } from 'helpers'
import { functionAlias } from '../../routes';

const { Header, Sider, Content, Footer } = Layout;

const { SubMenu } = Menu;

class DashBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            logoChange: false,
            iconToggle: false,
            selectedKeys: [],
            openKeys: [],
            timeout: 1800000,
            page: {}
        }
        this.isTimedOut = false
        this.idleTimer = null
        this.myRef = React.createRef();
        this.getCurrentPage = this.getCurrentPage.bind(this)
    }

    componentWillMount() {
        this.combineRouteAndSubMenu()
    }

    componentWillReceiveProps(nextProps) {
        this.getCurrentMenu(nextProps.location.pathname)
    }

    combineRouteAndSubMenu = () => {
        let mainMenu = this.props.auth.mainMenu
        let subMenu = this.props.auth.subMenu
        //console.log("subMenu===>", subMenu);

        let dashboard = dashboardRoute
        // console.log("dashboard==>", dashboard);
        //  combine route and submenu together

        // filter sub menu with check permission
        subMenu = (this.props.auth.userPermissionModelList || []).filter(userPermission => userPermission.authMenu)

        let routeWithMenu = subMenu && subMenu.reduce((result, data) => {
            let findRouteIndex = dashboard.findIndex(e => e.alias === data.functionAlias)
            if (findRouteIndex === -1) {
                result.push({ ...data, route: {} })
            }
            else {
                let route = dashboard.find(e2 => e2.alias === data.functionAlias)
                // console.log("route==>", route);

                result.push({ ...data, route: route })
            }
            return result
        }, [])
        // console.log("routeWithMenu=>", routeWithMenu);

        // combine route with menu and main menu together
        let menuSidebarWithRoute = mainMenu.reduce((result, data) => {
            let findSubIndex = routeWithMenu.findIndex(e => e.moduleId === data.moduleId)
            if (findSubIndex === -1) {
                result.push({ ...data, subMenu: [] })
            }
            else {
                let subMenu = routeWithMenu.filter(e2 => e2.moduleId == data.moduleId)
                result.push({ ...data, subMenu: subMenu })
            }
            return result
        }, [])
        // console.log("menuSidebarWithRoute==>", menuSidebarWithRoute);
        let mainMenuIcon = [
            { moduleId: 1, mainIcon: "team" },
            { moduleId: 2, mainIcon: "database" },
            { moduleId: 3, mainIcon: "inbox" },
            { moduleId: 4, mainIcon: "shopping" },
            { moduleId: 5, mainIcon: "exception" },
            { moduleId: 6, mainIcon: "shopping-cart" },
            { moduleId: 7, mainIcon: "profile" },
            { moduleId: 8, mainIcon: "appstore" },
            { moduleId: 9, mainIcon: "user" },
        ]

        let addIconInMenu = menuSidebarWithRoute.reduce((result, data) => {
            let findIconIndex = mainMenuIcon.findIndex(e => e.moduleId === data.moduleId)
            if (findIconIndex === -1) {
                result.push({ ...data, iconSet: {} })
            }
            else {
                let mainIcon = mainMenuIcon.find(e2 => e2.moduleId === data.moduleId)
                result.push({ ...data, iconSet: mainIcon })
            }
            return result
        }, [])

        let contentRoute = routeWithMenu.reduce((result, data) => {
            if (Object.keys(data.route).length !== 0) {
                result.push({ route: data.route })
            }
            return result
        }, [])

        let forceWithMainRoute = forceRoute.reduce((result, data) => {
            let findRouteIndex = contentRoute.findIndex(e => e.route.Id === data.Id);

            if (findRouteIndex >= 0) {
                result.push({ ...data })
            }
            else {
                return result
            }
            return result
        }, [])
        // console.log("forceWithMainRoute=>", forceWithMainRoute);

        let mapRoute = forceWithMainRoute.map(data => (
            {
                route: data
            }
        ))
        contentRoute.push(...mapRoute)
        // console.log("contentRoute==>", contentRoute);
        // console.log("addIconInMenu==>", addIconInMenu);
        this.setState({
            menuSidebarWithRoute: addIconInMenu,
            contentRoute: contentRoute
        })

    }

    componentDidMount() {
        this.getCurrentPage()
        this.getCurrentMenu(this.props.location.pathname)
    }

    getCurrentPage() {
        const { contentRoute, page, menuSidebarWithRoute } = this.state;
        if (this.myRef) {
            const { current } = this.myRef;
            if (current) {
                setTimeout(() => {
                    const { props } = current;
                    const contentPageRoute = contentRoute.find(item => item.route.path === props.computedMatch.path);
                    const dashboardPage = dashboardRoute.find(item => item.path === props.computedMatch.path);
                    const iconMain = menuSidebarWithRoute.find(item => {
                        if (item.subMenu.find(it => contentPageRoute && contentPageRoute.route && it.functionAlias === contentPageRoute.route.alias)) {
                            return item
                        }
                    })
                    let iconSub = '';
                    if (iconMain && iconMain.subMenu) {
                        let foundIcon = iconMain.subMenu.find(item => item.functionAlias === contentPageRoute.route.alias);
                        iconSub = foundIcon.route.icon
                    }
                    if (contentPageRoute && page.path !== contentPageRoute.route.path) {
                        this.setState({
                            page: {
                                ...contentPageRoute.route,
                                ...dashboardPage,
                                iconMain: iconMain.iconSet.mainIcon || '',
                                module: iconMain.moduleName,
                                icon: iconSub
                            }
                        })
                    }
                    if (!contentPageRoute) {
                        this.setState({
                            page: {}
                        })
                    }
                }, 1)
            }
        }
    }

    getCurrentMenu(pathName) {
        const dashboard = !!dashboardRoute.find(i => i.path === pathName) ? dashboardRoute.find(i => i.path === pathName) : dashboardRoute.find(i => pathName.includes(i.path));
        const openKey = dashboard ? getPermissionPage(dashboard.alias).moduleId : pathName === '/' ? 0 : '';
        const selectedKey = dashboard ? getPermissionPage(dashboard.alias).functionId : pathName === '/' ? 0 : '';
        this.setState({
            selectedKeys: [`${selectedKey}`],
            openKeys: [`sub${openKey}`],
        })
    }

    rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7', 'sub8', 'sub9',];

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
            logoChange: !this.state.logoChange,
            iconToggle: !this.state.iconToggle
        });
    };

    onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    };

    onClickMenu = (item) => {
        if (item.key === '0')
            this.setState({
                openKeys: [],
            });
    }

    handleClickUser = async (value) => {
        // console.log("valueeee==>", value);
        const UserUrl = window.REACT_APP_USER_API_URL;
        switch (value && value.key) {
            case '0':
                this.props.history.push(ROUTH_PATH.CHANGE_PASSWORD_SEFT)
                break;
            case '1':
                localStorage.clear()
                this.props.authData(null)
                /*
                // console.log("userId==>", this.props.auth.userId);
                await axios({
                    method: 'post',
                    url: `${UserUrl}/Logout`,
                    data: JSON.stringify(this.props.auth.userId),
                    headers: { 'Content-Type': 'application/json', }
                }).then(response => {
                    // console.log("response==>", response);
                    if (response.data.status === "Success") {
                        localStorage.clear();
                        this.props.authData(null)
                    }
                }).catch(error => {
                    // console.log("errorLogout==>", error);
                })
                */
                break;
            default:
                break;
        }
    }

    menu = () => {
        return (
            <Menu onClick={this.handleClickUser}>
                <Menu.Item key={0}>
                    <Icon type="reload" />
                    <span>เปลี่ยนรหัสผ่าน</span>
                </Menu.Item>
                <Menu.Item key={1}>
                    <Icon type="logout" />
                    <span>Logout</span>
                </Menu.Item>
            </Menu>
        )
    }

    onAction = () => {
        this.isTimedOut = false
    }

    onActive = () => {
        this.isTimedOut = true
    }

    onIdle = () => {
        if (this.isTimedOut) {
            this.handleClickUser({ key: '1' })
            swal('Timeout', 'หมดเวลาการใช้งาน', 'error');
        } else {
            this.idleTimer.reset()
            this.onActive()
        }
    }

    /*
    onAction = (e) => {
        // console.log('user did something', e)
        this.setState({ isTimedOut: false })
    }

    onActive = (e) => {
        // console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    onIdle = (e) => {
        // console.log('user is idle', e)
        const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
            this.handleClickUser()
            swal("Timeout", "หมดเวลาการใช้งาน", "error");
        } else {
            this.idleTimer.reset();
            this.setState({ isTimedOut: true })
        }
    }
    */

    render() {
        const { collapsed, openKeys, logoChange, menuSidebarWithRoute, contentRoute, iconToggle, page, selectedKeys } = this.state
        const { auth, } = this.props;
        if (this.myRef) {
            const { current } = this.myRef;
            if (current) {
                // ต้องใส่ไว้ไม่งันมันจะดึงค่าก่อนมามาแทน
                setTimeout(() => {
                    this.getCurrentPage()
                }, 1)
            }
        }


        return (
            <Layout className="layout_project">
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.state.timeout} />
                <Sider trigger={null} collapsible collapsed={collapsed} width={235} >
                    <div className="logo">
                        <img className="img-style" src={logoChange ? logoIcon : logo} />
                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={selectedKeys}
                        selectedKeys={selectedKeys}
                        openKeys={openKeys}
                        onOpenChange={this.onOpenChange}
                        style={{
                            width: '100%',
                            overflow: 'auto',
                            height: 'calc(100% - 50px)'
                        }}
                        onClick={this.onClickMenu}
                    // inlineIndent={10}
                    >
                        <Menu.Item key={0}>
                            <Link to={ROUTH_PATH.HOME}>
                                <Icon type="home" />
                                <span>หน้าหลัก</span>
                            </Link>
                        </Menu.Item>
                        {
                            menuSidebarWithRoute.map((data) => (
                                <SubMenu
                                    key={`sub${data.moduleId}`}
                                    title={
                                        <span>
                                            {data.iconSet && <Icon type={data.iconSet.mainIcon} />}
                                            <span>{data.moduleName}</span>
                                        </span>
                                    }

                                >
                                    {
                                        data.subMenu.map((sub) => (
                                            <Menu.Item key={sub.functionId}>
                                                <Tooltip
                                                    title={sub.functionName}
                                                    placement="right"
                                                    overlayClassName="menu-tooltip"
                                                >
                                                    <Link to={sub.route.path ? sub.route.path : '/franchise/false'}>
                                                        {sub.route.icon && <Icon type={sub.route.icon} />}
                                                        <span>{sub.functionName}</span>
                                                    </Link>
                                                </Tooltip>
                                            </Menu.Item>
                                        ))
                                    }
                                </SubMenu>
                            ))
                        }
                    </Menu>
                </Sider>
                <Layout style={{ background: "#d2d6de", minWidth: '1060px' }}>
                    <Header
                        style={{
                            background: '#ee2524',
                            padding: '0 16px',
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
                        }}
                    >
                        <div className="left-header">
                            <div className="icon-menu" onClick={this.toggle}>
                                <Icon
                                    className="trigger"
                                    type={iconToggle ? 'menu-unfold' : 'menu-fold'}
                                />
                            </div>
                            <Breadcrumb style={{ paddingLeft: 10 }} separator="">
                                <Breadcrumb.Item style={{ color: 'white' }}>
                                    <Link style={{ color: 'white' }} to="/">
                                        <Icon type="home" />
                                        <span style={{ paddingLeft: 5 }}>หน้าหลัก</span>
                                    </Link>
                                </Breadcrumb.Item>
                                {Object.entries(page).length !== 0 ? <Breadcrumb.Separator style={{ color: 'white' }} /> : ''}

                                {Object.entries(page).length !== 0 ? <Breadcrumb.Item style={{ color: 'white' }}>
                                    <Icon type={page.iconMain} />
                                    <span>{page.module}</span>
                                </Breadcrumb.Item> : ''}
                                {Object.entries(page).length !== 0 ? <Breadcrumb.Separator style={{ color: 'white' }} /> : ''}
                                {Object.entries(page).length !== 0 ? <Breadcrumb.Item style={{ color: 'white' }}>
                                    <Icon type={page.icon} />
                                    <span>{page.name}</span>
                                </Breadcrumb.Item> : ''}

                                {/* <Breadcrumb.Item style={{ color: 'white' }}>
                                    <Icon type="user" />
                                    <span>Application List</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Separator style={{ color: 'white' }} />
                                <Breadcrumb.Item style={{ color: 'white' }}>Application</Breadcrumb.Item> */}
                            </Breadcrumb>
                        </div>

                        <div>
                            <Dropdown overlay={this.menu} placement="bottomRight" trigger={['click']}>
                                <div className="logout-style">
                                    <Icon type="user" />
                                    <span className="user-text">{auth.userId}</span>
                                </div>
                            </Dropdown>
                        </div>
                    </Header>
                    <Content
                        style={{
                            margin: 10,
                            padding: 12,
                            background: '#fff',
                            minHeight: 280,
                            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                            borderRadius: 5,
                            overflow: 'auto'
                        }}
                    >
                        <Switch >
                            {
                                homePageRoute.map((data, i) => (
                                    <Route exact={data.exact} key={i} ref={this.myRef} path={data.path} component={data.component} />
                                ))
                            }
                            {
                                contentRoute.map((data, i) => (
                                    <Route exact={data.route.exact} ref={this.myRef} key={i} path={data.route.path} component={data.route.component} />
                                ))
                            }

                            {/* {
                                dashboardRoute.map((data, i) => (
                                    <Route exact={data.exact} key={i} path={data.path} component={data.component} />
                                ))
                            }
                            {
                                forceRoute.map((data, i) => (
                                    <Route key={i} path={data.path} component={data.component} />
                                ))
                            } */}

                            <Route ref={this.myRef} component={NotFoundContainer} />
                        </Switch>
                    </Content>
                    <Footer
                        style={{
                            height: '43px',
                            padding: '10px 15px',
                            background: 'white'
                        }}
                    >
                        SCG (Production) © 2020 - RFO : Retail Frontend Operation Management {window.REACT_APP_ENVIRONMENT} {window.REACT_APP_VERSION}
                    </Footer>
                </Layout>
            </Layout >
        );
    }
}


const mapStateToProps = state => {
    // console.log("stateDashboard===>", state);
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashBoard))