import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col } from "antd"
import { useLocation } from 'react-router-dom'
import {
    InputFormRedux,
    SelectRedux,
    RangePickerRedux,
    TextAreaRedux,
} from 'components'
import { promotionPiority, promotionExclusive, promotionLimit, VALIDATE, supportType, supportValue } from "helpers"
import './style.css'
import { ROUTH_PATH } from 'routes';

class PromotionMaintainInfoForm extends React.Component {
    state = {

    }

    render() {
        const {
            submitViewError,
            dataSupportBy,
            handleChangeGetSupprotBy,
            dataPromotionStatus,
        } = this.props;
        // let location = useLocation();

        let pathname = this.props.locationPath.pathname;
        let dataPromotionStatusNew = []
        // เช็คว่าเป็นลิงค์าสำหรับการสร้างหรือไม่
        // ถ้าสร้างจะมีแสดงแค่ 2 อัน        
        if (pathname === ROUTH_PATH.PROMOTION_MAINTAIN) {
            dataPromotionStatusNew = dataPromotionStatus ? dataPromotionStatus.filter(item => item.code === 'Opening' || item.code === 'Planning'  || item.code === 'Approve') : []
        } else {
            dataPromotionStatusNew = dataPromotionStatus
        }

        return (
            <div className="promotion-maintain-form">
                <Row style={{ height: 'calc(100% - 53px)' }}>
                    <Col md={24}>
                        <Field
                            component={InputFormRedux}
                            name="campaignName"
                            label="ชื่อการส่งเสริมการขาย"
                            right
                            widthDefault="200px"
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            component={SelectRedux}
                            name="priority"
                            label="ลำดับความสำคัญ"
                            right
                            widthDefault="200px"
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                            options={promotionPiority}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            component={SelectRedux}
                            name="status"
                            label="สถานะ"
                            right
                            widthDefault="200px"
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                            options={dataPromotionStatusNew}
                            // options={dataPromotionStatus}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            component={RangePickerRedux}
                            name="effectiveDate"
                            label="วันที่เริ่มใช้งาน"
                            right
                            widthDefault="200px"
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            component={SelectRedux}
                            name="exclusive"
                            label="ความพิเศษ"
                            right
                            widthDefault="200px"
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                            options={promotionExclusive}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            component={SelectRedux}
                            name="perlimitType"
                            label="ประเภทการจำกัดจำนวน"
                            right
                            widthDefault="200px"
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                            options={promotionLimit}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            component={InputFormRedux}
                            name="perlimitValue"
                            label="จำนวน"
                            right
                            widthDefault="200px"
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                            type="number"
                        />
                    </Col>
                    <Col md={24}>
                        <Field
                            component={TextAreaRedux}
                            name="targetSales"
                            label="รายละเอียดการส่งเสริมการขาย"
                            right
                            widthDefault="200px"
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            component={SelectRedux}
                            name="supportType"
                            label="เจ้าของการส่งเสริมการขาย"
                            right
                            widthDefault="200px"
                            onChangeHero={handleChangeGetSupprotBy}
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                            options={supportType}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            component={SelectRedux}
                            name="supportBy"
                            label="หน่วยงานที่รับผิดชอบค่าใช้จ่าย"
                            right
                            widthDefault="200px"
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                            options={dataSupportBy}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            component={SelectRedux}
                            name="supportValue"
                            label="มูลค่าที่รับผิดชอบ"
                            right
                            widthDefault="200px"
                            options={supportValue}
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default reduxForm({
    form: 'PromotionMaintainInfoForm',  // a unique identifier for this form
    enableReinitialize: true
})(PromotionMaintainInfoForm)
