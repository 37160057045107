import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, } from "antd"
import { InputSearch, TableCustom, DatePickerCustom, ButtonCustom, ModalCustom } from "components"
import { SelectCustom, InputSearchCustom } from "components/common"
import { CustomerSearchModal } from '../CustomerSearchModal'
import { authData } from 'actions/actionsCreator';
import { apiServices, API_PATH } from "apiServices"
import swal from '@sweetalert/with-react'
import { get, first, isEmpty } from 'lodash';
import { sortDataColumn } from 'helpers'
import moment from 'moment'
import './style.css'

class ReservationSearchModal extends Component {
    state = {
        columns: [
            {
                title: '#',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                render: (value, row, index) => (
                    <ButtonCustom small onClick={() => {
                        this.props.onSelected(row)
                        this.handleClearCriteria()
                    }} text="เลือก" />
                )
            },
            {
                title: 'วันที่เอกสาร',
                dataIndex: 'inventoryRequestHeadModel.documentDate',
                key: 'documentDate',
                // sorter: (a, b) => sortDataColumn(a, b, 'documentDate'),
                ellipsis: true,
                align: 'center',
                render: (value) => {
                    return this.changeFormatDate(value);
                },
            },
            {
                title: 'ใบขอเบิกใช้',
                dataIndex: 'stockRequestNo',
                key: 'stockRequestNo',
                sorter: (a, b) => sortDataColumn(a, b, 'stockRequestNo'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
                sorter: (a, b) => sortDataColumn(a, b, 'productCode'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                sorter: (a, b) => sortDataColumn(a, b, 'productName'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'จำนวน',
                dataIndex: 'qty',
                key: 'qty',
                sorter: (a, b) => sortDataColumn(a, b, 'qty'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'จำนวนค้างเบิก',
                dataIndex: 'oamount',
                key: 'oamount',
                sorter: (a, b) => sortDataColumn(a, b, 'oamount'),
                ellipsis: true,
                align: 'center',
                render: (value, full, row) => {
                    return full.qty - full.qtyGI;
                },
            },
            {
                title: 'หน่วย',
                dataIndex: 'baseUnit',
                key: 'baseUnit',
                sorter: (a, b) => sortDataColumn(a, b, 'baseUnit'),
                ellipsis: true,
                align: 'center',
            }
        ],
        dataList: [],
        loading: false,
        widthDefault: '100%',
        dateFormat: 'DD/MM/YYYY',

        documentDateFrom: moment(),
        documentDateTo: moment(),
    }

    componentDidMount() {

    }

    changeFormatDate = (value) => {
        if (value) {
            var d = new Date(value),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('-');
        }
        return value;
    }

    handleChangeDocumentDateFrom = (value) => {
        this.setState({ documentDateFrom: value });
    }

    handleChangeDocumentDateTo = (value) => {
        this.setState({ documentDateTo: value });
    }

    handleClearCriteria = () => {
        this.setState({ documentDateFrom: moment(), documentDateTo: moment(), dataList: [] });
    }

    handleClickSearchStockRequest = async (e) => {
        const { outletSelected, } = this.props;
        const { shipToId, documentDateFrom, documentDateTo } = this.state
        this.setState({ loading: true });
        let params = {
            documentDateFrom: documentDateFrom,
            documentDateTo: documentDateTo,
            outletId: outletSelected,
        };
        await apiServices.callApi('post', API_PATH.POST_GET_INVENTORY_REQUEST_ITEM, params).then(res => {
            let dataList = res.data.results.map((data, i) => ({
                ...data,
                key: i,
            }));
            this.setState({
                dataList
            });
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });
        this.setState({ loading: false });
    }

    render() {
        const {
            isShowModal,
            closeModal,
            outlets,
            outletSelected,
        } = this.props;

        const {
            loading,
            columns,
            dataList,
            widthDefault,
            dateFormat,
            documentDateFrom,
            documentDateTo,
        } = this.state;

        return (
            <ModalCustom
                title="ค้นหาใบเบิกใช้"
                isShowModal={isShowModal}
                handleCloseModal={() => {
                    closeModal()
                    this.handleClearCriteria()
                }}
                width="70%"
                footer={false}
            >
                <div className='delivery-order-search-modal'>
                    <Row gutter={20}>
                        <Col md={3}>
                            ร้าน
                        </Col>
                        <Col md={5}>
                            <SelectCustom
                                name="outlet"
                                options={outlets}
                                value={outletSelected}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col md={3}>
                            วันที่เอกสาร
                        </Col>
                        <Col md={5}>
                            <DatePickerCustom
                                onChange={this.handleChangeDocumentDateFrom}
                                widthAll={widthDefault}
                                name="documentDateFrom"
                                format={dateFormat}
                                value={documentDateFrom}
                            />
                        </Col>
                        <Col md={2}>
                            ถึง
                        </Col>
                        <Col md={5}>
                            <DatePickerCustom
                                onChange={this.handleChangeDocumentDateTo}
                                widthAll={widthDefault}
                                name="documentDateTo"
                                format={dateFormat}
                                value={documentDateTo}
                            />
                        </Col>
                        <Col md={2}>
                            <span><ButtonCustom blue text="ค้นหา" icon="search" onClick={this.handleClickSearchStockRequest} /></span>
                        </Col>
                    </Row>
                    <div className='content'>
                        <TableCustom height="calc(100vh - 316px)" columns={columns} data={dataList} loading={loading} pagination={true} />
                    </div>
                </div>

            </ModalCustom>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationSearchModal);