import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, uniqBy, map } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    SelectCustom,
    DatePickerCustom,
    InputSearchCustom,
    NumberFormatCustom,
    InputCustom,
    CheckboxCustom,
    SelectSearch,
} from 'components/common'
import {
    ProductStockSearchModal,
} from 'components/feature'
import { Row, Col, Spin, message } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { ROUTH_PATH, functionAlias } from 'routes'
import moment from 'moment'
import swal from '@sweetalert/with-react'
import queryString from 'query-string'
import { getPermissionPage, isOutletIdAuth } from 'helpers'
import './style.css'

const initialProduct = {
    productCode: '',
}

const initialHeader = {
    reservationId: '',
    movementType: 201,
    outletId: '',
    documentDate: moment(),
    costCenter: '',
    remark: '',
    createdBy: '',
}

const initialReservationSearch = {
    reservationId: '',
    outletId: '',
}

class ReservationCreateContainer extends Component {
    state = {
        columns: [
            {
                title: '#',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                render: (value, row, index) => (
                    row.productName && (!this.state.reservationId || (this.state.reservationId && index > this.state.reservationData.products.length - 1))
                    && <ButtonCustom
                        icon="delete"
                        small
                        type="danger"
                        onClick={this.deleteProduct.bind(this, index)}
                    />
                )
            },
            {
                title: 'รายการ',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                render: (value, row, index) => (
                    <div>{index + 1}</div>
                )
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
                width: 140,
                render: (value, row, index) => (
                    (this.state.products.length - 1 === index) ?
                        <InputSearchCustom
                            small
                            onSubmit={this.onSearchProduct}
                            onChange={(e) => this.changeProductData('productCode', e.target.value, index)}
                            onClickSearch={this.showModal}
                        /> : value
                )
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                width: 180,
            },
            {
                title: 'หน่วยนับ',
                dataIndex: 'unit',
                key: 'unit',
                width: 100,
                render: (value, row, index) => (
                    row.productName && <SelectCustom
                        options={this.getUnitOptions(row)}
                        small
                        onChange={(val) => this.changeProductData('unit', val, index)}
                        value={value}
                        disabled={!!row.delete}
                    />
                )
            },
            {
                title: 'ที่เก็บ',
                dataIndex: 'storeLocation',
                key: 'storeLocation',
                width: 200,
                render: (value, row, index) => (
                    row.productName && <SelectCustom
                        options={this.getStoreLocationOptions(row)}
                        small
                        onChange={(val) => this.changeProductData('storeLocation', val, index)}
                        value={value}
                        disabled={!!row.delete}
                    />
                )
            },
            {
                title: 'ปริมาณ',
                dataIndex: 'qty',
                key: 'qty',
                width: 130,
                render: (value, row, index) => (
                    row.productName && <NumberFormatCustom
                        small
                        onChange={(e) => this.changeProductData('qty', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                        value={value}
                        decimalScale={3}
                        disabled={!!row.delete || (row.qtyGi && row.qtyGi > 0)}
                    />
                )
            },
            {
                title: 'วันที่ต้องการ',
                dataIndex: 'requestDate',
                key: 'requestDate',
                width: 130,
                render: (value, row, index) => (
                    row.productName && <DatePickerCustom
                        allowClear={false}
                        small
                        onChange={(val) => this.changeProductData('requestDate', val, index)}
                        value={value}
                        disabled={!!row.delete}
                    />
                )
            },
            {
                title: 'GL Account',
                dataIndex: 'glAccount',
                key: 'glAccount',
                width: 130,
                render: (value, row, index) => (
                    row.productName && <InputCustom
                        small
                        onChange={(e) => this.changeProductData('glAccount', e.target.value, index)}
                        value={value}
                        disabled={!!row.delete}
                    />
                )
            },
            {
                title: 'ลบ',
                dataIndex: 'delete',
                key: 'delete',
                align: 'center',
                render: (value, row, index) => (
                    row.productName && <div className="text-center">
                        <CheckboxCustom
                            checked={!!value}
                            onChange={(e) => this.changeProductData('delete', e.target.checked, index)}
                            disabled={!this.state.reservationId || !!get(this.state, 'reservationData.products[index].delete')}
                        />
                    </div>
                )
            },
            {
                title: 'หมายเหตุรายการ',
                dataIndex: 'remark',
                key: 'remark',
                render: (value, row, index) => (
                    row.productName && <InputCustom
                        small
                        onChange={(e) => this.changeProductData('remark', e.target.value, index)}
                        value={value}
                        disabled={!!row.delete}
                    />
                )
            },
        ],
        loading: false,
        reservationId: '',
        products: [
            initialProduct,
        ],
        // fix
        movementTypes: [{ label: '201 - เบิกใช้ในร้าน', value: 201 }],
        outlets: [],
        costCenters: [],
        storeLocationDefault: '',
        widthLabel: '150px',
        header: initialHeader,
        reservationData: {
            products: []
        },
        isShowModal: false,
        disabledBtnSave: true,
        reservationSearch: initialReservationSearch,
        permissionPage: getPermissionPage(functionAlias.inventoryReservation),
    }

    componentDidMount() {
        const outletId = get(this.props, 'match.params.outletId')
        if (outletId && !isOutletIdAuth(outletId)) {
            this.getAlertAccessDenied()
            return
        }

        this.fetchDataDefault()
    }

    componentDidUpdate(prevProps) {
        const params = this.props.match.params
        const paramsPrev = prevProps.match.params
        if (params.id !== paramsPrev.id || params.outletId !== paramsPrev.outletId) {
            this.fetchDataDefault()
        }
    }

    getAlertAccessDenied = () => {
        swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
            this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
        })
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        await this.getOutlets()
        await this.getCostCenters()
        await this.getStoreLocationDefault()
        const reservationId = get(this.props, 'match.params.id', '')
        if (reservationId) {
            await this.getReservation(reservationId, get(this.props, 'match.params.outletId'))
        } else {
            this.setState({ header: { ...this.state.header, createdBy: this.props.auth.userId } })
        }

        this.setState({ loading: false, reservationId })
    }

    searchReservation = () => {
        const { reservationSearch: { reservationId, outletId } } = this.state
        if (!reservationId) {
            message.error('กรุณากรอกเลขที่ขอใบเอกสาร')
            return
        }
        if (!outletId) {
            message.error('กรุณาเลือกร้าน')
            return
        }
        this.getReservation(reservationId, outletId)
    }

    getReservation = async (reservationId, outletId) => {
        const url = API_PATH.POST_GET_INVENTORY_REQUEST_HEAD
        const body = {
            stockRequestNo: reservationId,
            outletId,
        }
        await apiServices.callApi('post', url, body).then(async res => {
            const reservation = get(res, 'data.results.0')

            if (res.status === 200 && !isEmpty(reservation)) {
                if (reservationId !== get(this.props, 'match.params.id')) {
                    this.props.history.push(`${ROUTH_PATH.RESERVATION_EDIT_LINK}/${reservationId}/${outletId}`)
                    return
                }
                const header = {
                    reservationId: reservation.stockRequestNo,
                    movementType: 201,
                    outletId: reservation.outletId,
                    documentDate: moment(reservation.documentDate),
                    costCenter: reservation.costCenter,
                    remark: reservation.remark,
                    createdBy: reservation.createdBy,
                }

                const reservationProducts = get(reservation, 'inventoryRequestItemModels', [])
                let products = []
                if (!isEmpty(reservationProducts)) {
                    let stockBalances = await this.getStockBalancesByProductCodes(map(reservationProducts, 'productCode'))
                    stockBalances = this.convertStockBalancesToColumnField(stockBalances)
                    products = reservationProducts.map(product => {
                        const productStockBalances = stockBalances.filter(stockBalance => stockBalance.productCode === product.productCode)
                        return {
                            ...product,
                            unit: product.baseUnit,
                            remark: product.itemRemark,
                            storeLocation: product.sloc,
                            stockBalances: productStockBalances,
                            requestDate: product.requestDate ? moment(product.requestDate) : null,
                            delete: product.deleteFlag ? 'X' : '',
                        }
                    })
                }

                this.setState({
                    products: [...products, initialProduct],
                    header,
                    reservationData: {
                        products,
                        header,
                    },
                })
            } else {
                swal({
                    title: 'Error',
                    icon: 'error',
                    text: 'ไม่พบข้อมูลใบเบิกใช้',
                    buttons: {
                        create: { text: 'สร้างใบเบิกใช้', value: true },
                        cancel: 'ปิด',
                    },
                }).then((value) => {
                    if (value) {
                        this.resetReservationSearch()
                        this.props.history.push(ROUTH_PATH.RESERVATION_CREATE)
                    }
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลใบเบิกใช้ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    getOutlets = async () => {
        const { auth } = this.props
        const { header } = this.state

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({
                    outlets,
                    header: { ...header, outletId: outlets[0].value },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    getCostCenters = async () => {
        const { header } = this.state
        const url = `${API_PATH.GET_MASTER_COST_CENTER}?ActiveStatus=true`

        await apiServices.callApi('get', url).then(res => {
            let costCenters = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(costCenters)) {
                costCenters = costCenters.map(data => (
                    { ...data, label: `${data.costCenterCode} - ${data.costCenterDescription}`, value: data.costCenterCode }
                ))
                this.setState({
                    costCenters,
                    header: { ...header, costCenter: costCenters[0].value },
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล cost center ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    getStockBalancesByProductCodes = async (productCodes) => {
        const { header } = this.state
        const { auth } = this.props
        if (!Array.isArray(productCodes)) {
            productCodes = [productCodes]
        }
        const productCodesQueryString = productCodes.map(code => `ProductCodes=${code}`).join('&')
        const storeLocationQueryString = auth.arrayStorageLocationId.map(slocId => `Storelocations=${slocId}`).join('&')
        
        const url = `${API_PATH.GET_DAILY_STOCK_BALANCE_LIST}?Outlet=${header.outletId}&${productCodesQueryString}&GroupBy=L&DisplayUnit=B&${storeLocationQueryString}`
        let stockBalances = []
        await apiServices.callApi('get', url).then(res => {
            if (res.status === 200) {
                stockBalances = get(res, 'data.result.eX_STOCK_BAL.item', [])
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล stock balances ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
        return stockBalances
    }

    getStoreLocationDefault = async () => {
        const { header } = this.state
        const url = `${API_PATH.GET_MASTER_SPECIAL_CONDITION}?ConditionName=GR&Condition1=${header.outletId}&Condition2=SLOC`
        await apiServices.callApi('get', url).then(res => {
            const storeLocationDefault = get(res, 'data.results.0.value', '')
            if (res.status === 200) {
                this.setState({ storeLocationDefault })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Default Store Location ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    resetHeader = () => {
        this.setState({ header: initialHeader })
    }

    resetReservationSearch = () => {
        this.setState({ reservationSearch: initialReservationSearch })
    }

    changeHeader = (key, value) => {
        const { header } = this.state
        switch (key) {
            case 'remark':
                value = value.substring(0, 255)
                break
            case 'reservationId':
                value = value.substring(0, 10)
                break
            default:
        }

        this.setState({ header: { ...header, [key]: value } })
    }

    changeReservationSearch = (key, value) => {
        const { reservationSearch } = this.state
        this.setState({ reservationSearch: { ...reservationSearch, [key]: value } })
    }

    changeHeaderOutletId = (value) => {
        const { header } = this.state
        this.setState({
            header: { ...header, outletId: value },
            products: [initialProduct],
        }, () => {
            this.getStoreLocationDefault()
        })
    }

    showModal = () => {
        this.setState({ isShowModal: true })
    }

    closeModal = () => {
        this.setState({ isShowModal: false })
    }

    deleteProduct = (index) => {
        const newProducts = [...this.state.products]
        newProducts.splice(index, 1)
        this.setState({ products: newProducts })
    }

    changeProductCode = (index, event) => {
        const productCode = event.target.value
        this.setState({
            products: this.state.products.map((product, i) => (i === index ? { ...product, productCode } : product)),
            disabledBtnSave: true,
        })
    }

    changeProductData = (key, value, index) => {
        switch (key) {
            case 'glAccount':
                value = value.substring(0, 10)
                break
            case 'remark':
                value = value.substring(0, 50)
                break
            case 'delete':
                value = value ? 'X' : ''
                this.resetProductData(index)
                break
            default:
        }

        this.setState({
            products: this.state.products.map((product, i) => (i === index ? { ...product, [key]: value } : product)),
        })
    }

    resetProductData = (index) => {
        const { reservationData, products } = this.state
        const reservationProduct = reservationData.products[index]
        if (reservationProduct) {
            products[index] = reservationProduct
            this.setState({ products })
        }
    }

    onSelectedProduct = async (product) => {
        this.setState({ loading: true })
        this.closeModal()
        const { products } = this.state
        const newProducts = [...products]
        newProducts.splice(-1, 1)

        product = this.convertSAPFieldToColumnField(product)
        let stockBalances = await this.getStockBalancesByProductCodes(product.productCode)
        stockBalances = this.convertStockBalancesToColumnField(stockBalances)
        product.stockBalances = stockBalances

        this.setState({
            products: [...newProducts, product, initialProduct],
            loading: false,
        })
    }

    getUnitOptions = (row) => {
        let options = [{ label: row.unit, value: row.unit }]
        if (!isEmpty(row.stockBalances)) {
            const unitStoreLocations = row.stockBalances.filter(stockBalance => stockBalance.unit && stockBalance.storeLocation === row.storeLocation)
            if (!isEmpty(unitStoreLocations)) {
                options = unitStoreLocations.map(data => (
                    { label: data.unit, value: data.unit }
                ))
                options = uniqBy(options, 'value')
            }
        }
        return options
    }

    getStoreLocationOptions = (row) => {
        let options = [{ label: `${row.storeLocation} - ${row.storeLocationDesc || ''}`, value: row.storeLocation }]
        if (!isEmpty(row.stockBalances)) {
            const unitStoreLocations = row.stockBalances.filter(stockBalance => stockBalance.storeLocation && stockBalance.unit === row.unit)
            if (!isEmpty(unitStoreLocations)) {
                options = unitStoreLocations.map(data => (
                    { label: `${data.storeLocation} - ${data.storeLocationDesc || ''}`, value: data.storeLocation }
                ))
                options = uniqBy(options, 'value')
            }
        }
        return options
    }

    onSearchProduct = async (e) => {
        this.setState({ loading: true })
        const { header } = this.state
        const { auth } = this.props
        const productCode = e.target.value

        const storeLocationQueryString = auth.arrayStorageLocationId.map(slocId => `Storelocations=${slocId}`).join('&')
        const url = `${API_PATH.GET_DAILY_STOCK_BALANCE_LIST}/?ProductCode=${productCode}&Outlet=${header.outletId}&GroupBy=L&DisplayUnit=B&${storeLocationQueryString}`

        await apiServices.callApi('get', url).then(res => {
            const productSAP = get(res, 'data.result.eX_STOCK_BAL.item.0')
            if (res.status === 200 && productSAP) {
                this.onSelectedProduct(productSAP)
            } else {
                message.error('ไม่พบสินค้า')
            }
        }).catch(error => {
            const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            console.error(get(error, 'response.data.errors'))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    validateData = () => {
        const { header, products } = this.state
        const newProducts = [...products]
        newProducts.splice(-1, 1)

        let result = { isError: false, message: '' }
        if (!header.outletId) return { isError: true, message: 'กรุณาระบุร้าน' }
        if (!header.costCenter) return { isError: true, message: 'กรุณา cost center' }

        for (const product of newProducts) {
            if (!product.productCode) {
                result = { isError: true, message: 'กรุณาระบุรหัสสินค้า' }
                break
            }
            if (!product.qty) {
                result = { isError: true, message: 'กรุณาระบุปริมาณ' }
                break
            }
            if (!product.unit) {
                result = { isError: true, message: 'กรุณาระบุหน่วยนับ' }
                break
            }
            if (!product.storeLocation) {
                result = { isError: true, message: 'กรุณาระบุที่เก็บ' }
                break
            }
            if (!product.requestDate) {
                result = { isError: true, message: 'กรุณาระบุวันที่ต้องการ' }
                break
            }
        }

        return result
    }

    convertSAPFieldToColumnField = (product) => {
        const { storeLocationDefault } = this.state
        return {
            productCode: product.matnr,
            productName: product.maktg,
            storeLocation: product.lgort || storeLocationDefault,
            storeLocationDesc: product.lgobe || '',
            unit: product.eX_DISPLAY_UNIT,
            requestDate: moment(),
            delete: '',
            qty: '',
            remark: '',
            glAccount: '',
        }
    }

    convertStockBalancesToColumnField = (stockBalances) => {
        return stockBalances.map(stockBalance => ({
            productCode: stockBalance.matnr,
            productName: stockBalance.maktg,
            barcode: stockBalance.eaN11,
            unit: stockBalance.eX_DISPLAY_UNIT,
            storeLocation: stockBalance.lgort,
            storeLocationDesc: stockBalance.lgobe,
        }))
    }

    getParams = (mode) => {
        const { header, products } = this.state
        const newProducts = [...products]
        newProducts.splice(-1, 1)

        const userId = header.createdBy.substring(0, 12)

        const imHeader = {
            reservation: header.reservationId || '',
            reS_DATE: moment(header.documentDate).format('YYYY-MM-DD'),
            movE_TYPE: `${header.movementType}`,
            gR_RCPT: userId,
            costcenter: header.costCenter,
        }

        const imItems = newProducts.map((product, index) => ({
            reS_ITEM: (header.reservationId) ? product.itemNo || '' : index + 1,
            material: product.productCode,
            entrY_QNT: parseFloat(`${product.qty}`.replace(/,/g, '')).toFixed(3),
            entrY_UOM: product.unit,
            plant: header.outletId,
            stgE_LOC: product.storeLocation,
            batch: '',
            fixeD_QUAN: '',
            reQ_DATE: moment(product.requestDate).format('YYYY-MM-DD'),
            gL_ACCOUNT: product.glAccount,
            movement: 'X',
            withdrawn: '',
            deletE_IND: product.delete,
            gR_RCPT: userId,
            unloaD_PT: '',
            iteM_TEXT: product.remark,
            fcorder: '',
            fcorderitem: '',
            gI_QTY: '',
            cofirM_QTY: ''
        }))

        return {
            iM_ATPCHECK: 'X',
            iM_MODE: mode,
            iM_PIID: '',
            iM_RESERVATIONHEADER: imHeader,
            iT_REMARK: {
                item: [
                    {
                        tdline: header.remark
                    }
                ]
            },
            iT_RESERVATIONITEMS: {
                item: imItems,
            },
            eT_REMARK: {
                item: null
            },
            eT_RESERVATIONITEMS: {
                item: null
            },
            eT_RETURN: {
                item: null
            },
        }
    }

    onSubmit = async () => {
        const valid = this.validateData()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const mode = this.state.reservationId ? 'C' : 'A'
        const body = this.getParams(mode)

        await apiServices.callApi('post', API_PATH.POST_RESERVATION_AND_INVENTORY_REQUEST, body).then(res => {
            if (res.status === 200) {
                if (mode === 'C') {
                    swal('Success', get(res, 'data.message') || 'บันทึกข้อมูลสำเร็จ', 'success').then(() => {
                        this.resetHeader()
                        this.setState({ products: [initialProduct] })
                        this.fetchDataDefault()
                    })
                } else {
                    const result = get(res, 'data.databaseResult', {})
                    swal({
                        title: 'Success',
                        icon: 'success',
                        text: 'เอกสารได้รับการบันทึกเรียบร้อย',
                        buttons: this.state.permissionPage.authPrint ? {
                            create: {
                                text: `พิมพ์`,
                                value: {
                                    "DocumentNo": result.stock_request_no,
                                    "OutletId": result.outlet_id
                                }
                            },
                            cancel: 'ปิด',
                        } : { cancel: 'ปิด' },
                    }).then(async (value) => {
                        if (value) {
                            const reportRDF = await apiServices.callApi('get', `${API_PATH.GET_REPORT_REQUISITION_REQUEST}?${queryString.stringify(value)}`, null, { responseType: 'blob' })
                            // console.log(reportRDF)
                            const urlOpen = window.URL.createObjectURL(reportRDF.data)
                            window.open(urlOpen)
                            // this.props.history.push(`${ROUTH_PATH.RESERVATION_EDIT_LINK}/${result.stock_request_no}/${result.outlet_id}`)
                        } else {
                            this.resetHeader()
                            this.setState({ products: [initialProduct] })
                            this.fetchDataDefault()
                        }
                    })
                }
            } else {
                throw res
            }
        }).catch(error => {
            swal('Error', 'ไม่สามารถบันทึกเอกสารได้ กรุณาตรวจสอบข้อมูลเพิ่มเติม', 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            movementTypes,
            outlets,
            costCenters,
            products,
            header,
            loading,
            columns,
            widthLabel,
            isShowModal,
            reservationSearch,
            reservationId,
            permissionPage,
        } = this.state

        return (
            <div className="reservation-create-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text={`${reservationId ? 'แก้ไข' : 'สร้าง'}ใบเบิกใช้`}
                        icon="file-search"
                        nodeRight={
                            <div className="search-reservation-box">
                                <div className="text-search">เลขที่ใบเบิกใช้</div>
                                <div className="text-search" style={{ width: '180px' }}>
                                    <SelectCustom
                                        options={outlets}
                                        value={reservationSearch.outletId}
                                        small
                                        onChange={(val) => this.changeReservationSearch('outletId', val)}
                                    />
                                </div>
                                <InputSearchCustom
                                    small
                                    value={reservationSearch.reservationId}
                                    onChange={(e) => this.changeReservationSearch('reservationId', e.target.value)}
                                    onClickSearch={this.searchReservation}
                                    onSubmit={this.searchReservation}
                                />
                            </div>
                        }
                    />
                    <div className="middle-content">
                        <Row gutter={8}>
                            <Col sm={8}>
                                <InputCustom
                                    label="เลขที่ขอใบเอกสาร"
                                    small
                                    widthLabel={widthLabel}
                                    onChange={(e) => this.changeHeader('reservationId', e.target.value)}
                                    // onPressEnter={this.searchReservation}
                                    value={header.reservationId}
                                    // disabled={!reservationId}
                                    disabled
                                />
                            </Col>
                            <Col sm={8}>
                                <SelectCustom
                                    label="ร้าน"
                                    options={outlets}
                                    small
                                    widthLabel={widthLabel}
                                    placeholder="กรุณาเลือก"
                                    onChange={(val) => this.changeHeaderOutletId(val)}
                                    value={header.outletId}
                                    disabled={!!this.state.reservationId}
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={8}>
                                <SelectCustom
                                    label="ประเภทการเคลื่อนย้าย"
                                    options={movementTypes}
                                    small
                                    widthLabel={widthLabel}
                                    placeholder="กรุณาเลือก"
                                    onChange={(val) => this.changeHeader('movementType', val)}
                                    value={header.movementType}
                                    disabled
                                />
                            </Col>
                            <Col sm={8}>
                                <DatePickerCustom
                                    label="วันที่เอกสาร"
                                    allowClear={false}
                                    small
                                    widthLabel={widthLabel}
                                    onChange={(val) => this.changeHeader('documentDate', val)}
                                    value={header.documentDate}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={8}>
                                <InputCustom
                                    label="ผู้ขอเบิก"
                                    small
                                    widthLabel={widthLabel}
                                    // onChange={(e) => this.changeHeader('userId', e.target.value)}
                                    // value={header.userId}
                                    value={header.createdBy}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={8}>
                                <SelectSearch
                                    label="Cost Center"
                                    options={costCenters}
                                    small
                                    widthLabel={widthLabel}
                                    placeholder="กรุณาเลือก"
                                    onChange={(val) => this.changeHeader('costCenter', val)}
                                    value={header.costCenter}
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={8}>
                                <InputCustom
                                    label="หมายเหตุ"
                                    small
                                    widthLabel={widthLabel}
                                    onChange={(e) => this.changeHeader('remark', e.target.value)}
                                    value={header.remark}
                                />
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom columns={columns} isFullContent data={products} rowKey={(row, index) => index} small pagination={false} />
                        </div>

                    </div>
                    <div className="bottom-content">
                        <ButtonCustom
                            text="บันทึก"
                            icon="save"
                            green
                            onClick={this.onSubmit}
                            disabled={products.length <= 1 || !permissionPage.authCreate}
                        />
                    </div>
                    <ProductStockSearchModal
                        isShowModal={isShowModal}
                        onCloseModal={this.closeModal}
                        onSelected={this.onSelectedProduct}
                        defaultOutlet={header.outletId}
                    />
                </Spin>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationCreateContainer)