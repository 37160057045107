import React, { Component } from 'react'
import { DatePicker } from 'antd';
import { DatePickerReduxStyled } from "./styled"
import moment from 'moment';

export class DatePickerRedux extends Component {
    render() {
        const dateFormat = 'DD/MM/YYYY';
        const {
            input,
            label,
            defaultRadius,
            submitViewError,
            meta: { touched, error },
            small,
            disabled,
            format,
            placeholder,
            placeholderDefault,
            defaultNow,
            widthSmall,
            allowClear,
            widthAll,
        } = this.props
        return (
            <DatePickerReduxStyled error={(touched || submitViewError) && error ? true : false} small={small} widthSmall={widthSmall}>
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div className="label-style">{label}</div>
                        }
                        <DatePicker
                            size={small ? 'small' : 'default'}
                            disabled={disabled}
                            onChange={(e) => input.onChange(e)}
                            value={input.value ? input.value : defaultNow ? moment(new Date(), dateFormat) : null}
                            format={format ? format : dateFormat}
                            placeholder={placeholder ? placeholder : placeholderDefault === true ? label : undefined}
                            style={{
                                borderRadius: defaultRadius ? 4 : '',
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - 150px})`
                                    :
                                    widthAll ? widthAll : ''
                            }}
                            allowClear={allowClear}
                        />
                    </div>
                    {
                        (touched || submitViewError) ?
                            <div className="error-message-bottom"> {error}  </div>
                            : ''
                    }
                </div>
            </DatePickerReduxStyled>
        )
    }
}
