import React, { Component } from 'react'
import { Row, Col, message } from 'antd'
import { formatNumber, saleTypes } from 'helpers'
import { get } from 'lodash'
import { apiServices, API_PATH } from 'apiServices'
import {
    ModalCustom,
    SelectCustom,
    TableCustom,
    NumberFormatCustom,
    CheckboxCustom,
} from 'components/common'

import swal from '@sweetalert/with-react'

export default class SaleOrderCreateModal extends Component {
    state = {
        columns: [
            {
                title: 'ลำดับ',
                dataIndex: 'no',
                key: 'no',
                align: 'center',
                render: (props, row, index) => (
                    <div>{index + 1}</div>
                )
            },
            {
                title: 'บาร์โค้ด',
                dataIndex: 'barcode',
                key: 'barcode',
                align: 'center',
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
                align: 'center',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                align: 'center',
                render: (props) => (
                    <div className="text-left">{props}</div>
                )
            },
            {
                title: 'ประเภทสต็อค',
                dataIndex: 'stockType',
                key: 'stockType',
                align: 'center',
            },
            {
                title: 'จำนวนใบเสนอราคา',
                dataIndex: 'qtyQuotation',
                key: 'qtyQuotation',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                )
            },
            {
                title: 'คลัง',
                dataIndex: 'slocId',
                key: 'slocId',
                align: 'center',
            },

            {
                title: 'จำนวน',
                dataIndex: 'qty',
                key: 'qty',
                align: 'center',
                width: 100,
                render: (props, row, index) => (
                    <NumberFormatCustom
                        className="text-right"
                        small
                        onChange={(e) => this.changeProductData('qty', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                        onBlur={(e) => this.changeQtyProductData(row, index)}
                        onKeyPress={(e) => e.key === 'Enter' && this.changeQtyProductData(row, index)}
                        value={props}
                        decimalScale={3}
                    />
                ),
            },
            {
                title: 'หน่วยขาย',
                dataIndex: 'unit',
                key: 'unit',
                align: 'center',
            },
            {
                title: 'ราคา/หน่วย',
                dataIndex: 'pricePerUnit',
                key: 'pricePerUnit',
                align: 'center',
                width: 100,
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                )
            },
            {
                title: 'ส่วนลด',
                //dataIndex: 'discount',
                //key: 'discount',
                dataIndex: 'totalDiscAmount',
                key: 'totalDiscAmount',
                align: 'center',
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                )
            },
            {
                title: 'ของแถม',
                dataIndex: 'freeGoodsFlag',
                key: 'freeGoodsFlag',
                align: 'center',
                render: (value) => (
                    <div className="text-center">
                        <CheckboxCustom checked={value} disabled />
                    </div>
                )
            },
            {
                title: 'จำนวนเงิน',
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                align: 'center',
                width: 100,
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                )
            },
        ],
        documentTypes: [],
        saleTypes,
        saleOrderDetail: {
            documentType: '',
            saleType: saleTypes[0].value,
        },
        productsSO: this.props.products,
        disabledSuccess: false,
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    componentDidUpdate(prevProps) {
        if (this.props.outletId !== prevProps.outletId) {
            this.fetchDataDefault()
        }
    }

    fetchDataDefault = async () => {
        // document type
        const { outletId } = this.props
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_DOCUMENT_TYPE}?SubModule=so`).then(res => {
            let documentTypes = get(res, 'data.results', [])
            if (res.status === 200) {
                if (outletId === 'A020') {
                    const documentType = documentTypes.find(type => type.documentTypeCode === 'ZOCS')
                    if (documentType) documentTypes = [documentType]
                }
                documentTypes = documentTypes.map(data => (
                    { ...data, label: `${data.documentTypeCode} - ${data.documentTypeDescription}`, value: data.documentTypeCode }
                ))

                const { saleOrderDetail } = this.state
                this.setState({
                    documentTypes,
                    saleOrderDetail: { ...saleOrderDetail, documentType: documentTypes[0].value },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทการขายได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    changeData = (key, val) => {
        const { saleOrderDetail } = this.state
        this.setState({ saleOrderDetail: { ...saleOrderDetail, [key]: val } })
    }

    changeDisabledSuccess = () => {
        const {
            isShowModal,
            onCloseModal,
            onClickSave,
        } = this.props
        const {
            columns,
            saleOrderDetail,
            documentTypes,
            saleTypes,
            productsSO,
            disabledSuccess,
        } = this.state
        this.setState({ disabledSuccess: true })
        onClickSave(productsSO, saleOrderDetail)
    }

    changeProductData = (key, value, index) => {
        this.setState({
            productsSO: this.state.productsSO.map((product, i) => (i === index ? { ...product, [key]: value } : product)),
        })
    }

    changeQtyProductData = (row, index) => {
        this.setState({
            productsSO: this.state.productsSO.map((product, i) => (i === index ? { ...product, totalAmount: row.pricePerUnit * row.qty } : product)),
        }, () => {
            message.success('แก้ไขจำนวนสินค้าสำเร็จ')
        })
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            onClickSave,
        } = this.props
        const {
            columns,
            saleOrderDetail,
            documentTypes,
            saleTypes,
            productsSO,
            disabledSuccess,
        } = this.state

        return (
            <ModalCustom
                title="ข้อมูลเพิ่มเติม"
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                width="80%"
                footer
                onClickSave={() => this.changeDisabledSuccess()}
                textSuccess="ยืนยัน"
                disabledSuccess={disabledSuccess}
            >
                <Row>
                    <Col sm={10}>
                        <SelectCustom
                            label="ประเภทการขาย"
                            small
                            onChange={val => this.changeData('documentType', val)}
                            options={documentTypes}
                            value={saleOrderDetail.documentType}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={10}>
                        <SelectCustom
                            label="ประเภทสต็อค"
                            small
                            onChange={val => this.changeData('saleType', val)}
                            options={saleTypes}
                            value={saleOrderDetail.saleType}
                        />
                    </Col>
                </Row>
                <TableCustom
                    style={{ paddingTop: '20px' }}
                    columns={columns}
                    data={productsSO}
                    isFullContent
                    rowKey={(row, index) => index}
                    small
                    pagination={false}
                />
            </ModalCustom>
        )
    }
}
