import React, { Component } from 'react'
import { Input } from 'antd';
import { InputHeroStyled } from "./styled"
import './style.css'

export class InputHero extends Component {
    render() {
        const {
            type,
            onChange,
            onBlur,
            onPressEnter,
            placeholder,
            placeholderDefault,
            autoFocus,
            name,
            disabled,
            BorderRadius,
            label,
            right,
            textBoldAlignRight,
            textAlignRight,
            small,
            widthSmall,
            value,
            widthDefault,
            maxLength,
            submitViewError,
            widthAll,
            onKeyUp
        } = this.props
        return (
            <InputHeroStyled
                small={small}
                widthSmall={widthSmall}
                error={submitViewError ? true : false}
                widthDefault={widthDefault}
            >
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div className="label-style" style={{ textAlign: right ? 'right' : '' }}>
                                {label}
                            </div>
                        }

                        <Input
                            className={`input-style ${textBoldAlignRight && "text-bold-align-right"} ${textAlignRight && "text-align-right"}`}
                            value={value}
                            size={small ? "small" : 'default'}
                            name={name}
                            type={type}
                            onBlur={onBlur}
                            onChange={onChange}
                            onKeyUp={onKeyUp}
                            onPressEnter={onPressEnter}
                            placeholder={placeholder ? placeholder : placeholderDefault === true ? label : undefined}
                            autoFocus={autoFocus}
                            disabled={disabled}
                            maxLength={maxLength}
                            style={{
                                borderRadius: BorderRadius ? BorderRadius : 4,
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    widthAll ? widthAll : ''
                                ,
                                borderColor:
                                    submitViewError ? "red" : ''
                            }}
                        />
                    </div>
                </div>
            </InputHeroStyled>
        )
    }
}
