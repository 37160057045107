import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { message } from "antd"
import './style.css';
import logo from './images/logo.png';
import axios from 'axios';
import swal from '@sweetalert/with-react';
import { authData } from 'actions/actionsCreator';
import { LoginForm } from 'reduxForms';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
        // alway seting session every calling page.
        sessionStorage.setItem("refresh", true);
    }

    checkPassUser = () => {
        const { username, password } = this.state
        if (username && password) {
            this.setState({
                disabled: false
            })
        }
        else {
            this.setState({
                disabled: true
            })
        }
    }
    
    handleInputChange = (evt) => {
        // this.checkPassUser()
        const target = evt.target;
        const value = target.value
        const name = target.name;
        this.setState({
            [name]: value
        })
    }

    authenticate = async (event) => {
        event.preventDefault();
        const self = this;
        const { reduxForm: { values, syncErrors } } = this.props
        //console.log("values===>", values);

        const UserUrl = window.REACT_APP_USER_API_URL;
        if (typeof (syncErrors) === 'object') {
            message.error("กรุณากรอกข้อมูลให้ครบถ้วน")
            this.setState({
                submitViewError: true
            })
        }
        else {
            self.setState({ success: false, loading: true, disabledInput: true });
            const source = {
                username: values.username,
                password: values.password
            }

            if (!(source.username || source.password)) {
                self.setState({ loading: false, disabledInput: false })
                swal("Error!", "Please insert username and password.", "error");
                return;
            }

            await axios({
                method: 'post',
                url: UserUrl + '/Authenticate',
                data: source,
                headers: { 'Content-Type': 'application/json', }
            }).then(res => {
                self.setState({ loading: false, disabledInput: false });
                if (res.data.status === "Success") {
                    console.log("Login successfull");
                    self.props.authData(res.data.results[0]);
                    const { from } = self.props.location.state || { from: { pathname: "/" } };
                    self.props.history.push(from);
                } else {
                    swal(res.data.message);
                }
            });
        }
    }

    // mockAuthData = (e) => {
    //     console.log("Asdasdasd", e);

    //     e.preventDefault()
    //     const test = {
    //         userId: "mock_user"
    //     }
    //     this.props.authData(test)
    //     this.props.history.push("/")
    // }

    render() {
        const { disabledInput, loading, submitViewError } = this.state
        return (
            <div className="login-layout">
                <div className="panel-zone">
                    <img src={logo} alt="logo" style={{ width: '250px' }} />
                    <LoginForm
                        authenticate={this.authenticate}
                        disabledInput={disabledInput}
                        loading={loading}
                        submitViewError={submitViewError}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    //console.log("stateRedux==>", state);

    return {
        auth: state.auth,
        reduxForm: state.form.LoginForm
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);