import React, { Component } from 'react'
import swal from '@sweetalert/with-react'
import { connect } from 'react-redux'
import { apiServices, API_PATH } from 'apiServices'
import { get } from 'lodash'
import { Row, Col } from 'antd'
import {
    DatePickerCustom,
    InputCustom,
    SelectCustom,
    InputSearchCustom,
} from 'components/common'
import {
    ProductHierarchyModal,
} from 'components/form'

const widthLabel = '140px'

class AdvancedSearch extends Component {
    state = {
        specialOrderFlags: [
            { label: 'สินค้าปกติ', value: 'normal' },
            { label: 'สินค้าสั่งพิเศษ', value: 'speacial' }
        ],
        // customerGroups: [],
        outlets: [],
        paymentTermCodes: [],
        productClasses: [],
        productBrands: [],
        // returnTypes: [],
        // TODO: get from api
        returnTypes: [
            { label: 'cash - คืนเป็นเงินสด', value: 1 },
            { label: 'credit_note - ลดหนี้ครั้งถัดไป', value: 2 },
            { label: 'voucher - คืนเป็น Voucher', value: 3 },
        ],
        paymentTypes: [],
        isShowModal: false,
        modalType: '',
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        const { auth } = this.props

        // outlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results', [])
            if (res.status === 200) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({ outlets })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // payment term
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_PAYMENT_TERM}?ActiveStatus=true`).then(res => {
            let paymentTermCodes = get(res, 'data.results', [])
            if (res.status === 200) {
                paymentTermCodes = paymentTermCodes.map(data => (
                    { ...data, label: `${data.paymentTermCode} - ${data.paymentTermDescription}`, value: data.paymentTermCode }
                ))
                this.setState({ paymentTermCodes })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทการคืนเงินได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product class
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_CLASS).then(res => {
            let productClasses = get(res, 'data.results', [])
            if (res.status === 200) {
                this.setState({ productClasses })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Class ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // return type
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALES_RETURN_TYPE}?ActiveStatus=true`).then(res => {
            let returnTypes = get(res, 'data.results', [])
            if (res.status === 200) {
                returnTypes = returnTypes.map(data => (
                    { ...data, label: `${data.returnTypeCode} - ${data.returnTypeName}`, value: data.returnTypeCode }
                ))
                this.setState({ returnTypes })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทการคืนเงินได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product brand
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_BRAND).then(res => {
            let productBrands = get(res, 'data.results', [])
            if (res.status === 200) {
                productBrands = productBrands.map(data => (
                    { ...data, label: `${data.brandCode} - ${data.descriptionThai}`, value: data.brandCode }
                ))
                this.setState({ productBrands })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Brand ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // payment types
        await apiServices.callApi('get', API_PATH.GET_MASTER_SALES_PAYMENT).then(res => {
            let paymentTypes = get(res, 'data.results', [])
            if (res.status === 200) {
                paymentTypes = paymentTypes.map(data => (
                    { ...data, label: `${data.paymentDescription}`, value: data.paymentType }
                ))
                this.setState({ paymentTypes })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Class ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    openModal = (modalType) => {
        this.setState({ isShowModal: true, modalType })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '' })
    }

    onSelectedProductClass = (data) => {
        this.props.changeAdvancedFilter('productClass',
            {
                productClass: data.classCode,
                productClassDescription: data.descriptionThai,
            }
        )
        this.closeModal()
    }

    render() {
        const {
            changeAdvancedFilter,
            advancedfilter,
            customerGroups,
            saleChannelCodes,
        } = this.props
        const {
            specialOrderFlags,
            outlets,
            paymentTermCodes,
            returnTypes,
            productClasses,
            productBrands,
            isShowModal,
            modalType,
            paymentTypes,
        } = this.state
        console.log('advancedfilter', advancedfilter)

        return (
            <div className="report-cn-dn-advanced-search-container">
                <Row gutter={15}>
                    <Col sm={20} offset={4}>
                        <Row gutter={15}>
                            <Col sm={10}>
                                <SelectCustom
                                    label="ช่องทางการจัดจำหน่าย"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(val) => changeAdvancedFilter('saleChannelCodes', val)}
                                    value={advancedfilter.saleChannelCodes}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    options={saleChannelCodes}
                                    optionsDefault={[{ label: 'ทั้งหมด111', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                />
                            </Col>
                            <Col sm={10}>
                                <SelectCustom
                                    label="หมวดรายการ"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(val) => changeAdvancedFilter('specialOrderFlags', val)}
                                    value={advancedfilter.specialOrderFlags}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    options={specialOrderFlags}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={10}>
                                <InputCustom
                                    label="ใบส่งสินค้า"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(val) => changeAdvancedFilter('doNumber', val)}
                                    value={advancedfilter.doNumber}
                                />
                            </Col>
                            <Col sm={10}>
                                <SelectCustom
                                    label="กลุ่มลูกค้า"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(val) => changeAdvancedFilter('customerGroups', val)}
                                    value={advancedfilter.customerGroups}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    options={customerGroups}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={10}>
                                <SelectCustom
                                    label="ร้าน"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(val) => changeAdvancedFilter('outletIds', val)}
                                    value={advancedfilter.outletIds}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    options={outlets}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                />
                            </Col>
                            <Col sm={10}>
                                <SelectCustom
                                    label="เงื่อนไขการชำระ"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(val) => changeAdvancedFilter('paymentTermCodes', val)}
                                    value={advancedfilter.paymentTermCodes}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    options={paymentTermCodes}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={10}>
                                <InputCustom
                                    label="สร้างโดย"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => changeAdvancedFilter('createdBy', e.target.value)}
                                    value={advancedfilter.createdBy}
                                />
                            </Col>
                            <Col sm={10}>
                                <Row gutter={5}>
                                    <Col xs={16}>
                                        <DatePickerCustom
                                            label="สร้างเมื่อ"
                                            widthLabel={widthLabel}
                                            small
                                            onChange={(val) => changeAdvancedFilter('createdDateFrom', val)}
                                            value={advancedfilter.createdDateFrom}
                                        />
                                    </Col>
                                    <Col xs={8}>
                                        <DatePickerCustom
                                            label="ถึง"
                                            widthLabel="20px"
                                            small
                                            onChange={(val) => changeAdvancedFilter('createdDateTo', val)}
                                            value={advancedfilter.createdDateTo}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={10}>
                                <SelectCustom
                                    label="ประเภทการคืนเงิน"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(val) => changeAdvancedFilter('returnTypes', val)}
                                    value={advancedfilter.returnTypes}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    options={returnTypes}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                />
                            </Col>
                            <Col sm={10}>
                                <InputCustom
                                    label="เลขที่คูปอง"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => changeAdvancedFilter('paymentNumber', e.target.value)}
                                    value={advancedfilter.paymentNumber}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={10}>
                                <InputCustom
                                    label="รหัสผู้สั่งซื้อ"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => changeAdvancedFilter('customerId', e.target.value)}
                                    value={advancedfilter.customerId}
                                />
                            </Col>
                            <Col sm={10}>
                                <InputCustom
                                    label="รหัสสินค้า"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => changeAdvancedFilter('productCode', e.target.value)}
                                    value={advancedfilter.productCode}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={10}>
                                <InputSearchCustom
                                    label="Class"
                                    widthLabel={widthLabel}
                                    onClickSearch={() => this.openModal('productClass')}
                                    small
                                    disabled
                                    value={advancedfilter.productClass ? `${advancedfilter.productClass} - ${advancedfilter.productClassDescription}` : ''}
                                />
                            </Col>
                            <Col sm={10}>
                                <SelectCustom
                                    label="Brand"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(val) => changeAdvancedFilter('productBrands', val)}
                                    value={advancedfilter.productBrands}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    options={productBrands}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={10}>
                                <SelectCustom
                                    label="Payment Type"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(val) => changeAdvancedFilter('paymentTypes', val)}
                                    value={advancedfilter.paymentTypes}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    options={paymentTypes}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ProductHierarchyModal
                    isShowModal={isShowModal && modalType === 'productClass'}
                    onCloseModal={this.closeModal}
                    onSelected={this.onSelectedProductClass}
                    data={productClasses}
                    productHierarchyKey="class"
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
}

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch)
