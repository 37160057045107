import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { formatNumber, getPermissionPage } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    RadioCustom,
    InputHero,
    DatePickerCustom,
    SelectHero,
} from 'components'
import { Row, Col, Spin } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { functionAlias } from 'routes'
import swal from '@sweetalert/with-react'

import './style.css'
import moment from 'moment'

const maxLengthBillingNumber = 10

class PurchaseClearBillingContainer extends Component {
    state = {
        columns: [
            {
                title: 'Outlet ID',
                dataIndex: 'outletId',
                key: 'outletId',
                align: 'center',
            },
            {
                title: 'Billing Date',
                dataIndex: 'billingDate',
                key: 'billingDate',
                align: 'center',
            },
            {
                title: 'Billing Number',
                dataIndex: 'billingNumber',
                key: 'billingNumber',
                align: 'center',
            },
            {
                title: 'Billing Type',
                dataIndex: 'billingType',
                key: 'billingType',
                align: 'center',
            },
            {
                title: 'Billing Amount',
                dataIndex: 'billingAmount',
                key: 'billingAmount',
                align: 'center',
                render: (value) => (
                    value && <div className="align-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'Billing Status',
                dataIndex: 'billingStatus',
                key: 'billingStatus',
                align: 'center',
            },
            {
                title: 'SO Number',
                dataIndex: 'soNumber',
                key: 'soNumber',
                align: 'center',
            },
            {
                title: 'SO Status',
                dataIndex: 'soStatus',
                key: 'soStatus',
                align: 'center',
            },
        ],
        modes: [
            { label: 'Report', value: 'report' },
            { label: 'Clear Billing', value: 'clearBilling' },
        ],
        loading: false,
        dateFormat: 'DD/MM/YYYY',
        reports: [],
        outlets: [],
        filter: {
            mode: 'report',
            billingNumberFrom: '',
            billingNumberTo: '',
            billingDateFrom: moment(),
            billingDateTo: null,
            outletId: '',
        },
        pagination: {
            limit: 10,
        },
        permissionPage: getPermissionPage(functionAlias.clearBilling),
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth } = this.props

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, { outletIds: auth.arrayOutlet }).then(res => {
            let outlets = get(res, 'data.results', [])
            if (res.status === 200) {
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }))
                this.setState({
                    outlets,
                    // outlet: outlets[0].value,
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    changeFilter = (key, value) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: value } })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (!filter.mode) return { isError: true, message: 'กรุณาระบุ Mode' }
        if (!filter.billingNumberFrom) return { isError: true, message: 'กรุณาระบุ Billing Number' }
        if (!filter.billingDateFrom) return { isError: true, message: 'กรุณาระบุ Billing Date' }
        if (filter.billingDateTo && (filter.billingDateTo).isBefore(filter.billingDateFrom)) return { isError: true, message: 'รูปแบบ Billing Date ไม่ถูกต้อง' }
        if (!filter.outletId) return { isError: true, message: 'กรุณาระบุ Outlet' }
        return { isError: false }
    }

    onClickRunBtn = async () => {
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const { filter } = this.state
        const mode = filter.mode
        const isReportMode = mode === 'report'
        const body = {
            mode: filter.mode,
            billingNumberFrom: filter.billingNumberFrom,
            billingNumberTo: filter.billingNumberTo,
            billingDateFrom: filter.billingDateFrom,
            billingDateTo: (isReportMode) ? filter.billingDateTo || '' : '',
            outletId: filter.outletId,
        }

        await apiServices.callApi('post', API_PATH.POST_CLEAR_BILLING, body).then(res => {
            if (res.status === 200) {
                if (res.data.status === 'Warning') {
                    swal('Warning', res.data.message, 'warning')
                } else if (isReportMode) {
                    // display report
                    this.setState({ reports: get(res, 'data.results') })
                } else {
                    swal('Success', get(res, 'data.message'), 'success')
                }
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            loading,
            columns,
            modes,
            reports,
            filter,
            dateFormat,
            pagination,
            widthLabel,
            outlets,
            permissionPage,
        } = this.state

        return (
            <div className="purchase-billing-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="Program Auto Create PO Franchise/ SO SCG Dist."
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row style={{ padding: '10px 0' }}>
                            <Col sm={12}>
                                <RadioCustom
                                    options={!permissionPage.authCreate ? modes.filter(mode => mode.value !== 'clearBilling') : modes}
                                    value={filter.mode}
                                    label="Mode"
                                    widthDefault={widthLabel}
                                    onChange={(e) => this.changeFilter('mode', e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={8}>
                                <InputHero
                                    label="Billing Number"
                                    widthDefault={widthLabel}
                                    value={filter.billingNumberFrom}
                                    onChange={(e) => this.changeFilter('billingNumberFrom', e.target.value)}
                                    maxLength={maxLengthBillingNumber}
                                />
                            </Col>
                            <Col sm={8}>
                                <InputHero
                                    label="to"
                                    widthDefault={widthLabel}
                                    right
                                    value={filter.billingNumberTo}
                                    onChange={(e) => this.changeFilter('billingNumberTo', e.target.value)}
                                    maxLength={maxLengthBillingNumber}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={8}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    label="Billing Date"
                                    value={filter.billingDateFrom}
                                    format={dateFormat}
                                    allowClear={false}
                                    onChange={(date) => this.changeFilter('billingDateFrom', date)}
                                />
                            </Col>
                            {filter.mode === 'report' && <Col sm={8}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    label="to"
                                    right
                                    value={filter.billingDateTo}
                                    format={dateFormat}
                                    allowClear={false}
                                    onChange={(date) => this.changeFilter('billingDateTo', date)}
                                />
                            </Col>}
                        </Row>
                        <Row>
                            <Col sm={8}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    label="Outlet ID"
                                    options={outlets}
                                    value={filter.outletId}
                                    onChange={(val) => this.changeFilter('outletId', val)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={8} type="flex" align="middle" justify="end">
                            <Col>
                                <ButtonCustom
                                    icon="file-excel"
                                    text="Export"
                                    green
                                    disabled={!permissionPage.authExport || filter.mode !== 'report' || (filter.mode === 'report' && isEmpty(reports))}
                                />
                            </Col>
                            <Col>
                                <ButtonCustom
                                    icon="caret-right"
                                    text="Run"
                                    onClick={this.onClickRunBtn}
                                />
                            </Col>
                        </Row>
                        {filter.mode === 'report' && <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                small
                                scroll={{ x: "max-content" }}
                                data={reports}
                                rowKey={(row, index) => index}
                                pagination={{
                                    pageSize: pagination.limit,
                                    showTotal: (total, range) => (`Showing ${range[0]} to ${range[1]} of ${total} entries`),
                                }}
                            />
                        </div>}
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseClearBillingContainer)