import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, map, compact } from 'lodash'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { setReportProductSharings } from 'actions/actionsCreator'
import { getPermissionPage } from 'helpers'
import { ROUTH_PATH, functionAlias } from 'routes'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    CheckboxCustom,
    SelectCustom,
    InputSearchCustom,
} from 'components/common'
import {
    ProductSearchModal,
} from 'components/feature'
import {
    ProductHierarchyModal,
} from 'components/form'
import { Row, Col, Spin, message, Divider } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import './style.css'

const widthLabel = '100px'

class ReportProductPercentMarginContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: 'ร้าน',
                    dataIndex: 'outletId',
                    key: 'outletId',
                    align: 'center',
                },
                {
                    title: 'ช่องทาง',
                    dataIndex: 'saleChannelCode',
                    key: 'saleChannelCode',
                    align: 'center',
                },
                {
                    title: 'Category',
                    dataIndex: 'category',
                    key: 'category',
                    align: 'center',
                    render: (props, row) => (
                        props && <div>{props} - {row.categoryDescription}</div>
                    ),
                },
                {
                    title: 'Sub Category',
                    dataIndex: 'subCategoryCode',
                    key: 'subCategoryCode',
                    align: 'center',
                    render: (props, row) => (
                        props && <div>{props} - {row.subCategoryDescription}</div>
                    ),
                },
                {
                    title: 'Class',
                    dataIndex: 'classCode',
                    key: 'classCode',
                    align: 'center',
                    render: (props, row) => (
                        props && <div>{props} - {row.classDescription}</div>
                    ),
                },
                {
                    title: 'Sub Class',
                    dataIndex: 'subClassCode',
                    key: 'subClassCode',
                    align: 'center',
                    render: (props, row) => (
                        props && <div>{props} - {row.subClassDescription}</div>
                    ),
                },
                {
                    title: 'สินค้า',
                    dataIndex: 'productId',
                    key: 'productId',
                    align: 'center',
                },
                {
                    title: 'ชื่อสินค้า',
                    dataIndex: 'productNameMarket',
                    key: 'productNameMarket',
                    align: 'center',
                    width: 250,
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'Percent Sharing',
                    dataIndex: 'percentSharing',
                    key: 'percentSharing',
                    align: 'center',
                    width: 80,
                    render: (props) => (
                        <div className="text-right">{props}</div>
                    ),
                },
                {
                    title: 'ยกเลิก',
                    dataIndex: 'deleteFlag',
                    key: 'deleteFlag',
                    align: 'center',
                    width: 50,
                    render: (props) => (
                        <CheckboxCustom
                            style={{ paddingLeft: '10px' }}
                            checked={props}
                            disabled
                        />
                    ),
                },
                {
                    title: 'Updated Date',
                    dataIndex: 'changedDatetime',
                    key: 'changedDatetime',
                    align: 'center',
                    render: (props) => (
                        props && <div>{moment(props).format('DD/MM/YYYY')}</div>
                    ),
                },
                {
                    title: 'Updated By',
                    dataIndex: 'changedBy',
                    key: 'changedBy',
                    align: 'center',
                },
            ],
            loading: false,
            documents: [],
            product: {},
            outlets: [],
            saleChannels: [],
            productCategories: [],
            productSubCategories: [],
            productClasses: [],
            productSubClasses: [],
            // filter: {
            //     outletIds: [],
            //     saleChannelCodes: [],
            //     categoryCodes: [],
            //     subCategoryCodes: [],
            //     classCodes: [],
            //     subClassCodes: [],
            //     productId: '',
            // },
            // TODO: refactor I'm hurrying
            filter: {
                outletIds: [],
                saleChannelCodes: [],
                categoryCodes: '',
                subCategoryCodes: '',
                classCodes: '',
                subClassCodes: '',
                productId: '',
            },
            pagination: {
                total: 0,
                limit: 10,
            },
            paginationLimitOptions: [
                { label: '10', value: 10 },
                { label: '20', value: 20 },
                { label: '50', value: 50 },
                { label: '100', value: 100 },
            ],
            productHierarchies: [],
            productHierarchyKey: '',
            permissionPage: getPermissionPage(functionAlias.reportPercentMargin),
        }
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })

        const { auth } = this.props
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({ outlets })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // sale channel
        await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL).then(res => {
            let saleChannels = get(res, 'data.results', [])
            if (res.status === 200) {
                saleChannels = saleChannels.map(data => (
                    { ...data, label: `${data.saleChannelCode} - ${data.saleChannelDescription}`, value: data.saleChannelCode }
                ))
                this.setState({ saleChannels })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทการขายได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product category
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_CATEGORY).then(res => {
            let productCategories = get(res, 'data.results', [])
            if (res.status === 200) {
                productCategories = productCategories.map(data => (
                    { ...data, label: `${data.categoryCode} - ${data.descriptionThai}`, value: data.categoryCode }
                ))
                this.setState({ productCategories })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Category ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product sub category
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_SUB_CATEGORY).then(res => {
            let productSubCategories = get(res, 'data.results', [])
            if (res.status === 200) {
                productSubCategories = productSubCategories.map(data => (
                    { ...data, label: `${data.subCategoryCode} - ${data.descriptionThai}`, value: data.subCategoryCode }
                ))
                this.setState({ productSubCategories })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Sub Category ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product class
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_CLASS).then(res => {
            let productClasses = get(res, 'data.results', [])
            if (res.status === 200) {
                productClasses = productClasses.map(data => (
                    { ...data, label: `${data.classCode} - ${data.descriptionThai}`, value: data.classCode }
                ))
                this.setState({ productClasses })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Class ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product sub class
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_SUB_CLASS).then(res => {
            let productSubClasses = get(res, 'data.results', [])
            if (res.status === 200) {
                productSubClasses = productSubClasses.map(data => (
                    { ...data, label: `${data.subClassCode} - ${data.descriptionThai}`, value: data.subClassCode }
                ))
                this.setState({ productSubClasses })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Sub Class ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    onChangePagination = (key, val) => {
        const { pagination } = this.state
        this.setState({ pagination: { ...pagination, [key]: val } })
    }

    searchProduct = async () => {
        const { auth } = this.props
        const { filter, outlets } = this.state
        const { productId } = filter
        if (!productId) {
            message.error('กรุณาระบุรหัสสินค้า')
            return
        }
        this.setState({ loading: true })

        const outletIds = isEmpty(filter.outletIds) ? map(outlets, 'outletId') : filter.outletIds
        let product = null
        // ** note **
        // api request call inside loop because api get product not supported list of outlet id
        // and Hurry!
        try {
            for (const outletId of outletIds) {
                const body = {
                    productId: filter.productId,
                    outletId,
                    storageLocationIds: auth.arrayStorageLocationId,
                    productStatus: true
                }
                const res = await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body)
                product = get(res, 'data.results.0')
                if (res.status === 200) {
                    if (!isEmpty(product)) {
                        this.onSelectedProduct(product)
                        break
                    }
                }
            }
        } catch (error) {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }
        if (!product) {
            message.error('ไม่พบข้อมูลสินค้า')
        }
        this.setState({ loading: false })
    }

    onSelectedProduct = (product) => {
        this.closeModal()
        const { filter } = this.state
        this.setState({
            product,
            filter: {
                ...filter,
                productId: product.productId,
            }
        })
    }

    changeFilter = (key, value) => {
        const { filter, product } = this.state
        switch (key) {
            case 'productId':
                this.setState({ filter: { ...filter, productId: value } }, () => {
                    // clear product
                    if (!isEmpty(product)) {
                        this.setState({ product: {} })
                    }
                })
                break
            case 'outletId':
                // clear product
                this.setState({
                    filter: {
                        ...filter,
                        outletId: value,
                        productId: '',
                    },
                    product: {},
                })
                break
            case 'categoryCodes':
                this.setState({
                    filter: {
                        ...filter,
                        categoryCodes: value,
                        subCategoryCodes: '',
                        classCodes: '',
                        subClassCodes: '',
                    },
                })
                break
            case 'subCategoryCodes':
                this.setState({
                    filter: {
                        ...filter,
                        subCategoryCodes: value,
                        classCodes: '',
                        subClassCodes: '',
                    },
                })
                break
            case 'classCodes':
                this.setState({
                    filter: {
                        ...filter,
                        classCodes: value,
                        subClassCodes: '',
                    },
                })
                break
            default:
                this.setState({ filter: { ...filter, [key]: value } })
        }
    }

    openModal = (modalType) => {
        this.setState({ isShowModal: true, modalType })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '' })
    }

    getBody = (filter) => {
        const { outlets } = this.state
        // TODO: refactor
        /*
        return {
            outletIds: isEmpty(filter.outletIds) ? map(outlets, 'outletId') : filter.outletIds,
            saleChannelCodes: filter.saleChannelCodes,
            categoryCodes: filter.categoryCodes,
            subCategoryCodes: filter.subCategoryCodes,
            classCodes: filter.classCodes,
            subClassCodes: filter.subClassCodes,
            productId: filter.productId,
        }
        */

        return {
            outletIds: isEmpty(filter.outletIds) ? map(outlets, 'outletId') : filter.outletIds,
            saleChannelCodes: filter.saleChannelCodes,
            categoryCodes: filter.categoryCodes ? [filter.categoryCodes] : [],
            subCategoryCodes: filter.subCategoryCodes ? [filter.subCategoryCodes] : [],
            classCodes: filter.classCodes ? [filter.classCodes] : [],
            subClassCodes: filter.subClassCodes ? [filter.subClassCodes] : [],
            productId: filter.productId,
        }
    }

    searchDocument = async () => {
        const {
            filter,
            productCategories,
            productSubCategories,
            productClasses,
            productSubClasses,
        } = this.state
        this.setState({ loading: true })
        const body = this.getBody(filter)

        await apiServices.callApi('post', API_PATH.POST_GET_PROFIT_SHARINGS, body).then(res => {
            let documents = get(res, 'data.results', [])
            if (res.status === 200) {
                // filter deleted
                documents = documents.filter(document => !document.deleteFlag)

                documents = documents.map(document => {
                    const categoryData = productCategories.find(category => category.categoryCode === document.category)
                    const subCategoryData = productSubCategories.find(subCategory => subCategory.subCategoryCode === document.subCategoryCode)
                    const classData = productClasses.find(classData => classData.classCode === document.classCode)
                    const subClassData = productSubClasses.find(subClassData => subClassData.subClassCode === document.subClassCode)

                    return {
                        ...document,
                        productName: document.productNameMarket,
                        categoryCode: document.category,
                        categoryDescription: categoryData && categoryData.descriptionThai,
                        subCategoryDescription: subCategoryData && subCategoryData.descriptionThai,
                        classDescription: classData && classData.descriptionThai,
                        subClassDescription: subClassData && subClassData.descriptionThai,
                    }
                })
                this.props.setReportProductSharings(documents)
                this.setState({ documents })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    exportDocument = async () => {
        const { filter } = this.state
        const body = this.getBody(filter)
        const queryStringArray = Object.keys(body).map(key => {
            const values = body[key]
            if (Array.isArray(values) && !isEmpty(values)) {
                return values.map(value => `${key}=${encodeURIComponent(value)}`).join('&')
            } else if (!isEmpty(values)) {
                return `${key}=${encodeURIComponent(values)}`
            }
            return ''
        })

        // remove falsey values from array
        const queryString = compact(queryStringArray).join('&')
        const url = `${API_PATH.GET_EXPORT_MASTER_PROFIT_SHARING_EXCEL}?${queryString}`
        window.open(url, '_blank')
    }

    openModalProductHierarchy = (type = '') => {
        const {
            productCategories,
            filter,
        } = this.state
        const {
            categoryCodes,
            subCategoryCodes,
            classCodes,
        } = filter

        let productHierarchies = []
        let textFilter = ''
        // switch (type) {
        //     case 'categoryCodes':
        //         productHierarchies = productCategories
        //         break
        //     case 'subCategoryCodes':
        //         productHierarchies = productSubCategories
        //         break
        //     case 'classCodes':
        //         productHierarchies = productClasses
        //         break
        //     case 'subClassCodes':
        //         productHierarchies = productSubClasses
        //         break
        //     default:
        //         break
        // }
        switch (type) {
            case 'categoryCodes':
                productHierarchies = productCategories
                break
            case 'subCategoryCodes':
                productHierarchies = this.filterSubCatagories((categoryCodes).substring(0, 2))
                break
            case 'classCodes':
                if (subCategoryCodes) textFilter = (subCategoryCodes).substring(0, 4)
                else if (categoryCodes) textFilter = (categoryCodes).substring(0, 2)
                productHierarchies = this.filterClasses(textFilter)
                break
            case 'subClassCodes':
                if (classCodes) textFilter = (classCodes).substring(0, 6)
                else if (subCategoryCodes) textFilter = (subCategoryCodes).substring(0, 4)
                else if (categoryCodes) textFilter = (categoryCodes).substring(0, 2)
                productHierarchies = this.filterSubClasses(textFilter)
                break
            default:
                break
        }

        this.setState({
            productHierarchies,
            productHierarchyKey: type,
        }, () => {
            this.openModal('productHierarchy')
        })
    }

    filterSubCatagories = (text) => {
        return this.state.productSubCategories.filter(subCategory =>
            new RegExp('^' + text, 'gi').test(subCategory.subCategoryCode)
        )
    }

    filterClasses = (text) => {
        return this.state.productClasses.filter(classData =>
            new RegExp('^' + text, 'gi').test(classData.classCode)
        )
    }

    filterSubClasses = (text) => {
        return this.state.productSubClasses.filter(subClassData =>
            new RegExp('^' + text, 'gi').test(subClassData.subClassCode)
        )
    }

    closeModalProductHierarchy = () => {
        this.setState({
            productHierarchies: [],
            productHierarchyKey: '',
        })
        this.closeModal()
    }

    onSelectedProductHierarchy = (data) => {
        const { productHierarchyKey } = this.state
        // TODO: refactor T^T

        this.changeFilter(productHierarchyKey, data[(productHierarchyKey || '').replace(/s$/, '')])
        this.closeModalProductHierarchy()
    }

    render() {
        const {
            columns,
            outlets,
            loading,
            filter,
            isShowModal,
            modalType,
            documents,
            saleChannels,
            productCategories,
            productSubCategories,
            productClasses,
            productSubClasses,
            product,
            pagination,
            paginationLimitOptions,
            permissionPage,
            productHierarchies,
            productHierarchyKey,
        } = this.state

        // TODO: refactor
        const cateDesc = productCategories.find(productCategory => productCategory.categoryCode === filter.categoryCodes)
        const subCateDesc = productSubCategories.find(productSubCategory => productSubCategory.subCategoryCode === filter.subCategoryCodes)
        const classDesc = productClasses.find(productClass => productClass.classCode === filter.classCodes)
        const subClassDesc = productSubClasses.find(productSubClass => productSubClass.subClassCode === filter.subClassCodes)

        return (
            <div className="report-product-percent-margin-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="รายงาน Percent Margin"
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row gutter={15}>
                            <Col sm={8}>
                                <SelectCustom
                                    label="ร้าน"
                                    widthLabel={widthLabel}
                                    options={outlets}
                                    small
                                    onChange={(val) => this.changeFilter('outletIds', val)}
                                    value={filter.outletIds}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={2}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => get(option, 'props.children.props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                />
                            </Col>
                            <Col sm={8}>
                                <SelectCustom
                                    label="ช่องทาง"
                                    widthLabel={widthLabel}
                                    options={saleChannels}
                                    small
                                    onChange={(val) => this.changeFilter('saleChannelCodes', val)}
                                    value={filter.saleChannelCodes}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={2}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => get(option, 'props.children.props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={8}>
                                {/* <SelectCustom
                                    label="Category"
                                    widthLabel={widthLabel}
                                    options={productCategories}
                                    small
                                    onChange={(val) => this.changeFilter('categoryCodes', val)}
                                    value={filter.categoryCodes}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={2}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => get(option, 'props.children.props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                /> */}
                                <InputSearchCustom
                                    label="Category"
                                    widthLabel={widthLabel}
                                    onClickSearch={() => this.openModalProductHierarchy('categoryCodes')}
                                    small
                                    disabled
                                    value={`${cateDesc ? `${filter.categoryCodes} - ${cateDesc.descriptionThai}` : filter.categoryCodes}`}
                                />
                            </Col>
                            <Col sm={8}>
                                {/* <SelectCustom
                                    label="Sub Category"
                                    widthLabel={widthLabel}
                                    options={productSubCategories}
                                    small
                                    onChange={(val) => this.changeFilter('subCategoryCodes', val)}
                                    value={filter.subCategoryCodes}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={2}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => get(option, 'props.children.props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                /> */}
                                <InputSearchCustom
                                    label="Sub Category"
                                    widthLabel={widthLabel}
                                    onClickSearch={() => this.openModalProductHierarchy('subCategoryCodes')}
                                    small
                                    disabled
                                    value={`${subCateDesc ? `${filter.subCategoryCodes} - ${subCateDesc.descriptionThai}` : filter.subCategoryCodes}`}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={8}>
                                {/* <SelectCustom
                                    label="Class"
                                    widthLabel={widthLabel}
                                    options={productClasses}
                                    small
                                    onChange={(val) => this.changeFilter('classCodes', val)}
                                    value={filter.classCodes}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={2}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => get(option, 'props.children.props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                /> */}
                                <InputSearchCustom
                                    label="Class"
                                    widthLabel={widthLabel}
                                    onClickSearch={() => this.openModalProductHierarchy('classCodes')}
                                    small
                                    disabled
                                    value={`${classDesc ? `${filter.classCodes} - ${classDesc.descriptionThai}` : filter.classCodes}`}
                                />
                            </Col>
                            <Col sm={8}>
                                {/* <SelectCustom
                                    label="Sub Class"
                                    widthLabel={widthLabel}
                                    options={productSubClasses}
                                    small
                                    onChange={(val) => this.changeFilter('subClassCode', val)}
                                    value={filter.subClassCode}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={2}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    placeholder="ทั้งหมด"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => get(option, 'props.children.props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                /> */}
                                <InputSearchCustom
                                    label="Sub Class"
                                    widthLabel={widthLabel}
                                    onClickSearch={() => this.openModalProductHierarchy('subClassCodes')}
                                    small
                                    disabled
                                    value={`${subClassDesc ? `${filter.subClassCodes} - ${subClassDesc.descriptionThai}` : filter.subClassCodes}`}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={8}>
                                <InputSearchCustom
                                    label="รหัสสินค้า"
                                    widthLabel={widthLabel}
                                    onClickSearch={() => this.openModal('product')}
                                    onSubmit={this.searchProduct}
                                    onChange={(e) => this.changeFilter('productId', e.target.value)}
                                    small
                                    value={filter.productId}
                                />
                            </Col>
                            <Col sm={8}>
                                <div style={{ fontSize: '12px', marginTop: '5px' }}>
                                    {product.productNameMarket}
                                </div>
                            </Col>
                            <Col sm={24} className="search-box text-right">
                                <div className="action-box">
                                    <div className="button">
                                        <Link to={ROUTH_PATH.PRODUCT_PERCENT_MARGIN_CREATE}>
                                            <ButtonCustom
                                                text="สร้าง"
                                                small
                                                icon="plus-circle"
                                                disabled={!permissionPage.authCreate}
                                            />
                                        </Link>
                                    </div>
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.searchDocument}
                                            small
                                            icon="search"
                                            text="ค้นหา"
                                            disabled={!permissionPage.authDisplay}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Divider style={{ margin: '20px 0' }} />
                        <Row gutter={15}>
                            <Col sm={3}>
                                <SelectCustom
                                    label="แสดง"
                                    widthLabel="40px"
                                    labelEnd="entries"
                                    options={paginationLimitOptions}
                                    value={pagination.limit}
                                    small
                                    onChange={(val) => this.onChangePagination('limit', val)}
                                />
                            </Col>
                            <Col sm={1}>
                                <div style={{ fontSize: '12px', marginTop: '5px' }}>
                                    รายการ
                                </div>
                            </Col>
                            <Col sm={20} className="search-box text-right">
                                <div className="action-box">
                                    <div className="button">
                                        <ButtonCustom
                                            text="แก้ไข"
                                            small
                                            icon="edit"
                                            yellow
                                            onClick={() => { this.props.history.push(ROUTH_PATH.PRODUCT_PERCENT_MARGIN_EDIT) }}
                                            disabled={!permissionPage.authUpdate || isEmpty(documents)}
                                        />
                                    </div>
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.exportDocument}
                                            small
                                            icon="file-excel"
                                            green
                                            text="Export Excel"
                                            disabled={isEmpty(documents) || !permissionPage.authExport}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                data={documents}
                                isFullContent
                                rowKey={(row, index) => index}
                                small
                                pagination={{
                                    pageSize: pagination.limit,
                                    showTotal: (total, range) => (`Showing ${range[0]} to ${range[1]} of ${total} entries`),
                                }}
                            />
                        </div>
                    </div>
                    <ProductSearchModal
                        isShowModal={isShowModal && modalType === 'product'}
                        onCloseModal={this.closeModal}
                        onSelected={this.onSelectedProduct}
                        outletId={isEmpty(filter.outletIds) ? map(outlets, 'outletId').join(',') : filter.outletIds}
                    />
                    <ProductHierarchyModal
                        isShowModal={isShowModal && modalType === 'productHierarchy'}
                        onCloseModal={this.closeModalProductHierarchy}
                        onSelected={this.onSelectedProductHierarchy}
                        data={productHierarchies}
                        productHierarchyKey={(productHierarchyKey || '').replace(/Codes$/, '')}
                    />
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        reportProductSharings: state.reportProductSharings
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setReportProductSharings,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportProductPercentMarginContainer)