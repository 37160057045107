import React, { Component } from 'react'
import { isEmpty, get, find, first } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { TitleHead, ButtonCustom, TableCustom, SelectHero, InputHero } from 'components'
import { Row, Col, Checkbox, Icon, Spin, message } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { ROUTH_PATH, functionAlias } from 'routes'
import { getPermissionPage } from 'helpers'
import XLSX from 'xlsx'
import swal from '@sweetalert/with-react'
import './style.css'

const initialProduct = {
    active: true,
    barcode: '',
    productCode: '',
    itemStatus: '',
}

const maxLengthProductCode = 8
const maxLengthSloc = 4
const maxLengthDocList = 25

class InventoryCountCreateContainer extends Component {
    state = {
        columns: [
            {
                title: 'ลบ',
                dataIndex: 'delete',
                key: 'delete',
                render: (value, row, index) => (
                    row.itemStatus && <ButtonCustom icon="delete" small type="link" className="column-delete" onClick={this.deleteProduct.bind(this, index)} />
                )
            },
            {
                title: 'Active',
                dataIndex: 'active',
                key: 'active',
                render: (value, row, index) => (
                    <div style={{ textAlign: 'center' }} key={index}>
                        <Checkbox checked={value} onClick={this.changeProductActive.bind(this, index)} />
                    </div>
                )
            },
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                render: (value, row, index) => (
                    <div>
                        {index + 1}
                    </div>
                )
            },
            {
                title: 'รหัสสินค้า *',
                dataIndex: 'productCode',
                key: 'productCode',
                render: (value, row, index) => (
                    <InputHero
                        type="number"
                        value={value}
                        onChange={this.changeProductCode.bind(this, index)}
                        onPressEnter={this.searchProduct.bind(this, index)}
                    />
                )
            },
            {
                title: 'บาร์โค้ด',
                dataIndex: 'barcode',
                key: 'barcode',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                render: (value) => (
                    <div className="column-product-name">
                        {value}
                    </div>
                ),
            },
            {
                title: 'คลัง *',
                dataIndex: 'sloc',
                key: 'sloc',
                render: (value, row, index) => (
                    row.itemStatus
                    && !(row.isError && row.errorType === 'PN')
                    && <InputHero
                        value={value}
                        disabled={row.masterInventoryCountStatusModel && row.masterInventoryCountStatusModel.create !== 'Y'}
                        maxLength={maxLengthSloc}
                        onChange={this.changeSloc.bind(this, index)}
                        onPressEnter={this.submitSloc.bind(this, index, row)}
                    />
                )
            },
            {
                title: 'จำนวนที่มีในระบบ',
                dataIndex: 'qtySystemStock',
                key: 'qtySystemStock',
                render: (value, row) => {
                    if (!row.productName || value === null || (row.isError && (row.errorType === 'PN' || row.errorType === 'PA'))) {
                        return null
                    } else {
                        const num = parseFloat((`${value}`).replace(/,/g, '')).toFixed(3)
                        return <div className="text-right">{(+num).toLocaleString(undefined, { minimumFractionDigits: 3 })}</div>
                    }
                }
            },
            {
                title: 'หน่วย Base',
                dataIndex: 'baseUnit',
                key: 'baseUnit',
            },
            {
                title: 'DocList',
                dataIndex: 'docList',
                key: 'docList',
                render: (value, row, index) => (
                    <InputHero
                        value={value}
                        disabled={row.masterInventoryCountStatusModel && row.masterInventoryCountStatusModel.create !== 'Y'}
                        maxLength={maxLengthDocList}
                        onChange={this.changeDocList.bind(this, index)}
                        onPressEnter={this.searchProduct.bind(this, index)}
                    />
                )
            },
            {
                title: 'Item Status',
                dataIndex: 'itemStatus',
                key: 'itemStatus',
            },
            {
                title: 'หมายเหตุ',
                dataIndex: 'remarks',
                key: 'remarks',
                render: (value, row) => {
                    if (!value) return null
                    if (row.isError && row.isWarning) {
                        const list = row.remarks.split('||')
                        return (<div>
                            <div className="column-remark text-red">{list[0]}</div>
                            <div className="column-remark text-orange">{list[1]}</div>
                        </div>)
                    } else if (row.isError) {
                        return (<div className="column-remark text-red">{value}</div>)
                    } else if (row.isWarning) {
                        return (<div className="column-remark text-orange">{value}</div>)
                    }
                }
            },
        ],
        loading: false,
        disabledBtnSave: true,
        stockTypes: [],
        stockType: '',
        outlets: [],
        outlet: '',
        products: [
            initialProduct,
        ],
        permissionPage: getPermissionPage(functionAlias.inventoryCountAdmin),
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    deleteProduct = (index) => {
        const { products } = this.state
        const productsNew = [...products]
        productsNew.splice(index, 1)
        this.setState({ products: productsNew })
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth } = this.props
        let body = {
            outletIds: auth.arrayOutlet,
        }
        let resStockType = await apiServices.callApi('get', API_PATH.GET_MASTER_INVENTORY_STOCK_TYPE, body)
        let resOutlet = await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, body)
        if (resStockType.status === 200 && resOutlet.status === 200) {
            let stockTypes = get(resStockType, 'data.results')
            if (stockTypes) {
                stockTypes = stockTypes.map(data => ({
                    ...data,
                    value: data.stockTypesCode,
                    label: `${data.stockTypesCode} - ${data.stockTypesDesc}`,
                }))
                this.setState({
                    stockTypes: stockTypes,
                    stockType: stockTypes[0].value,
                })
            }

            let outlets = get(resOutlet, 'data.results')
            if (outlets) {
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }))
                this.setState({
                    outlets,
                    outlet: outlets[0].value,
                })
            }
        } else {
            swal('Error', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง', 'error')
        }
        this.setState({ loading: false })
    }

    readExcelFile = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
            try {
                let data = e.target.result
                data = new Uint8Array(data)
                const workbook = XLSX.read(data, { type: 'array' })
                const result = {}
                /*
                workbook.SheetNames.forEach((sheetName) => {
                    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })
                    if (roa.length) result[sheetName] = roa
                    this.importProducts(result)
                })
                */
                const sheetName = workbook.SheetNames[0]
                const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })
                if (roa.length) result[sheetName] = roa
                this.importProducts(result)
            } catch (err) {
                swal('Error', 'กรุณาตรวจสอบความถูกต้องของไฟล์ Excel', 'error')
                console.error(err)
            }
        }
        reader.readAsArrayBuffer(file)
    }

    importProducts = async (data) => {
        if (!data.InventoryCount) {
            swal('Error', 'ข้อมูล Excel ไม่ถูกต้อง', 'error')
            return
        }

        this.setState({ loading: true })
        const { products, outlet } = this.state
        const rows = data.InventoryCount
        const dataImport = []

        for (const index in rows) {
            const row = rows[index]
            if (+index === 0) continue
            if (isEmpty(row)) break
            const productCode = row[0]
            const docList = row[1]

            if (productCode && docList) {
                dataImport.push({
                    productCode,
                    docList
                })
            } else {
                swal('Error', 'กรุณาตรวจสอบไฟล์', 'error')
                return
            }
        }

        if (isEmpty(dataImport)) {
            swal('Error', 'ไม่มีข้อมูล', 'error')
        } else {
            const url = `${API_PATH.POST_INVENTORY_COUNT_IMPORT_EXCEL}?outletId=${outlet}`
            const body = dataImport
            await apiServices.callApi('post', url, body).then(res => {
                const productsImport = get(res, 'data.results')
                if (productsImport) {
                    let productsNew = [...products, ...productsImport]
                    const indexInitial = productsNew.findIndex(p => !p.itemStatus)
                    if (indexInitial !== -1) {
                        productsNew.splice(indexInitial, 1)
                    }
                    this.setState({ products: [...productsNew, initialProduct] })
                }
            }).catch(error => {
                console.log(`error catch ==>`, error);
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง!')
                swal('Error', message, 'error')
            });
        }
        this.setState({ loading: false })
    }

    changeStockType = (stockType) => {
        this.setState({ stockType, disabledBtnSave: true })
    }

    changeOutlet = (outlet) => {
        this.setState({ outlet, disabledBtnSave: true })
    }

    changeProductCode = (index, event) => {
        let productCode = event.target.value
        productCode = productCode.substring(0, maxLengthProductCode)
        this.setState({
            products: this.state.products.map((el, i) => (i === index ? { ...el, productCode } : el)),
            disabledBtnSave: true,
        })
    }

    changeDocList = (index, event) => {
        const docList = event.target.value
        this.setState({
            products: this.state.products.map((el, i) => (i === index ? { ...el, docList } : el)),
            disabledBtnSave: true,
        })
    }

    changeSloc = (index, event) => {
        this.setState({
            products: this.state.products.map((el, i) => (i === index ? { ...el, sloc: event.target.value } : el)),
            disabledBtnSave: true,
        })
    }

    submitSloc = async (index, row) => {
        if (!row.sloc) {
            message.error('กรุณาระบุคลัง')
            return
        }
        const productSloc = await this.fetchProductSloc(row.productCode, row.sloc)
        if (!isEmpty(productSloc)) {
            this.setState({
                products: this.state.products.map((el, i) => (i === index ? {
                    ...el,
                    // ...productSloc,
                    sloc: productSloc.sloc,
                    qtySystemStock: productSloc.qtySystemStock,
                    isWarning: productSloc.isWarning,
                    isError: productSloc.isError,
                    remarks: productSloc.remarks,
                    errorType: productSloc.errorType,
                } : el)),
                disabledBtnSave: true,
            })
        } else {
            message.error('ไม่พบข้อมูลคลังในสินค้านี้')
        }
    }

    fetchProductSloc = async (productCode, sloc) => {
        const { outlet } = this.state
        this.setState({ loading: true })
        const url = `${API_PATH.GET_INVENTORY_COUNT_GET_SLOC}?productId=${productCode}&sloc=${sloc}&outlet=${outlet}`
        try {
            this.setState({ loading: true })
            const res = await apiServices.callApi('get', url)
            if (res.status === 200) {
                return get(res, 'data.results.0')
            } else {
                throw new Error(res)
            }
        } catch (error) {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง!')
            swal('Error', message, 'error')
        } finally {
            this.setState({ disabledBtnSave: true, loading: false })
        }
    }

    changeProductActive = (index) => {
        this.setState({
            products: this.state.products.map((el, i) => (i === index ? { ...el, active: !el.active } : el))
        })
    }

    validateSearchProduct = (row) => {
        const regexProductCode = /^[0-9\b]+$/
        if (!regexProductCode.test(row.productCode)) return { isError: true, message: `กรุณาระบุรหัสสินค้าเฉพาะตัวเลข ${maxLengthProductCode} หลัก` }
        if (row.productCode.length !== maxLengthProductCode) return { isError: true, message: `กรุณาระบุรหัสสินค้าให้ครบ ${maxLengthProductCode} หลัก` }
        if (!row.docList) return { isError: true, message: `กรุณาระบุ docList` }
        return { isError: false }
    }

    searchProduct = async (index) => {
        const { products } = this.state
        const row = products[index]
        const valid = this.validateSearchProduct(row)
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
        } else {
            await this.fetchProduct(row.productCode, row.docList, index)
        }
    }

    fetchProduct = async (productCode, docList, index) => {
        const { products, outlet } = this.state
        this.setState({ loading: true })
        const url = `${API_PATH.GET_INVENTORY_COUNT_GET_PRODUCT}?productId=${productCode}&docList=${docList}&outlet=${outlet}`

        await apiServices.callApi('get', url).then(res => {
            let results = get(res, 'data.results', []);
            if (results.length === 0)
                swal('Error', `ไม่พบข้อมูลสินค้า: ${productCode}`, 'error');
            const product = first(results);
            const productsMapped = products.map((el, i) => (i === index ? { ...el, ...product } : el))
            const checkInitial = productsMapped.find(p => !p.itemStatus)
            if (!checkInitial) {
                this.setState({ products: [...productsMapped, initialProduct] })
            } else {
                this.setState({ products: [...productsMapped] })
            }
        }).catch(error => {
            console.log(`error catch ==>`, error);
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง!')
            swal('Error', message, 'error')
        });

        this.setState({ disabledBtnSave: true, loading: false })
    }

    validateProducts = () => {
        const { products, outlet, stockType } = this.state
        if (!outlet) return { isError: true, message: 'กรุณาระบุร้านค้า' }
        if (!stockType) return { isError: true, message: 'กรุณาระบุประเภทสต็อค' }
        if (find(products, (product) => product.itemStatus && !product.sloc && !product.isError)) return { isError: true, message: 'กรุณาระบุคลัง' }
        if (products.length <= 1 && find(products, (product) => !product.itemStatus)) return { isError: true, message: 'กรุณาระบุรายการสินค้า' }

        if (find(products, (product) => product.itemStatus && product.productCode.length !== maxLengthProductCode)) return { isError: true, message: `กรุณาระบุรหัสสินค้าให้ครบ ${maxLengthProductCode} หลัก` }
        if (find(products, (product) => product.itemStatus && !product.docList)) return { isError: true, message: `กรุณาระบุ docList` }
        return { isError: false }
    }

    validateInventoryCount = async () => {
        const { products, outlet } = this.state
        const valid = this.validateProducts()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const productsNew = [...products]
        const indexInitial = productsNew.findIndex(p => !p.itemStatus)
        if (indexInitial !== -1) {
            productsNew.splice(indexInitial, 1)
        }
        const url = `${API_PATH.POST_INVENTORY_COUNT_VALIDATE}?outlet=${outlet}&mode=Create`
        const body = [...productsNew];
        const res = await apiServices.callApi('post', url, body)
        if (res.status === 200) {
            const products = get(res, 'data.results')
            if (products) {
                const isError = !!find(products, { isError: true })
                this.setState({
                    products: [...products, initialProduct],
                    disabledBtnSave: isError,
                })
            } else {
                swal('Error', 'ไม่มีข้อมูลสินค้า', 'error')
            }
        } else {
            swal('Error', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง', 'error')
        }
        this.setState({ loading: false })
    }

    createInventoryCount = async () => {
        this.setState({ loading: true })
        const { outlet, stockType, products } = this.state
        const { auth } = this.props
        const indexInitial = products.findIndex(p => !p.itemStatus)
        const productsNew = [...products]
        if (indexInitial !== -1) {
            productsNew.splice(indexInitial, 1)
        }

        const inventoryCountItemModels = productsNew.map((product) => ({
            ...product,
            stockType,
            createdDate: new Date().toISOString(),
        }))

        const url = API_PATH.POST_INVENTORY_COUNT_CREATE_INVENTORY_COUNT
        const body = {
            inventoryCountId: 0,
            outletId: outlet,
            createdBy: auth.userId,
            inventoryCountItemModels,
        }

        //console.log("body==>",JSON.stringify(body));
        await apiServices.callApi('post', url, body).then(res => {
            const InventoryCountDocNo = get(res, 'data.message')
            const InventoryCountItemIds = get(res, 'data.inventoryCountItemIds')
            // swal('Success', 'บันทึกข้อมูลสำเร็จ', 'success').then(() => {
            //     this.props.history.push(`${ROUTH_PATH.INVENTORY_COUNT_EDIT_LINK}/${id}`)
            // })
            swal({
                className: 'inventory-count-create-container-modal',
                title: 'Success',
                icon: 'success',
                text: 'ใบตรวจนับเลขที่ ' + InventoryCountDocNo || 'บันทึกข้อมูลสำเร็จ',
                // buttons:
                // {
                //     print: { text: 'พิมพ์ใบตรวจนับ', value: 'print', className: 'green-button', closeModal: false },
                //     ok: 'ปิด',
                // },
                buttons: this.state.permissionPage.authPrint ?
                    {
                        print: { text: 'พิมพ์ใบตรวจนับ', value: 'print', className: 'green-button', closeModal: false },
                        ok: 'ปิด',
                    } :
                    { ok: 'ปิด' },
            }).then(async (value) => {
                if (value === 'print') {
                    swal.stopLoading()
                    const requestBody = {
                        inventoryCountItemIds: InventoryCountItemIds,
                        outletId: outlet
                    }
                    let resultReport = await apiServices.callApi('post', API_PATH.POST_INVENTORY_COUNT_EXPORT_PRINT, requestBody, { responseType: 'blob' })
                    const urlOpen = window.URL.createObjectURL(resultReport.data)
                    window.open(urlOpen, '_blank')
                    this.props.history.push(`${ROUTH_PATH.INVENTORY_COUNT_EDIT_LINK}/${InventoryCountDocNo}`)
                } else {
                    swal.close()
                    this.props.history.push(`${ROUTH_PATH.INVENTORY_COUNT_EDIT_LINK}/${InventoryCountDocNo}`)
                }
            })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
        this.setState({ loading: false })
    }

    render() {
        const {
            stockTypes,
            stockType,
            outlets,
            outlet,
            columns,
            products,
            loading,
            disabledBtnSave,
        } = this.state
        console.log('this.state.permissionPage.authPrint', this.state.permissionPage);

        return (
            <div className="inventory-count-create-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="สร้างใบตรวจนับสต็อค"
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row gutter={16}>
                            <Col sm={11}>
                                <InputHero
                                    name="inventoryCountSheetNo"
                                    label="เลขที่ใบตรวจนับ"
                                    disabled
                                />
                            </Col>
                            <Col sm={11}>
                                {outlets.length > 0 && <SelectHero
                                    onChange={this.changeOutlet}
                                    name="outlet"
                                    label="ร้านค้า *"
                                    options={outlets}
                                    value={outlet}
                                />}
                            </Col>
                            <Col sm={11}>
                                <SelectHero
                                    onChange={this.changeStockType}
                                    name="stockType"
                                    label="ประเภทสต็อค *"
                                    options={stockTypes}
                                    value={stockType}
                                />
                            </Col>
                            <Col sm={11}>
                                <Row className="upload-box input-form">
                                    <Col className="label-style">
                                        Upload Excel
                                    </Col>
                                    <Col className="input-box">
                                        <input className="file-input" type="file" id="input" onChange={this.readExcelFile} onClick={e => (e.target.value = null)} />
                                        <br />
                                        <a href={API_PATH.GET_INVENTORY_COUNT_DOWNLOAD_TEMP_EXCEL} className="download-link">
                                            <Icon type="file-excel" /> Download Format Excel
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom columns={columns} scroll={{ x: "max-content" }} data={products} rowKey={(row, index) => index} small pagination={false} />
                        </div>
                    </div>
                    <div className="bottom-content">
                        <div className="action-box">
                            <div className="button">
                                <ButtonCustom icon="arrow-left" type="danger" text="ยกเลิก" />
                            </div>
                            <div className="button">
                                <ButtonCustom icon="check-circle" yellow text="ตรวจสอบ" disabled={loading} onClick={this.validateInventoryCount} />
                            </div>
                            <div className="button">
                                <ButtonCustom icon="save" green text="บันทึก" disabled={loading || disabledBtnSave} onClick={this.createInventoryCount} />
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCountCreateContainer)