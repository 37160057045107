import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col } from "antd"
import {
    RadioRedux,
    InputFormRedux,
    MultiSelectedRedux
} from 'components'
import { conditionPermission, VALIDATE } from "helpers"

class PromotionMaintainConditionForm extends React.Component {
    state = {

    }

    render() {
        const {
            submitViewError,
            dataOutlet,
            dataCustomerGroup,
            dataChannal,
        } = this.props
        return (
            <Row>
                <Col offset={4} md={16}>
                    <Field
                        label="สิทธิเข้าร่วม"
                        name='recycling'
                        component={RadioRedux}
                        options={conditionPermission}
                        submitViewError={submitViewError}
                        validate={VALIDATE.REQUIRE}
                    />
                </Col>
                <Row>
                    <Col offset={4} md={12}>
                        <Field
                            label="โค้ดส่วนลด"
                            name='codeRequired'
                            component={InputFormRedux}
                            submitViewError={submitViewError}
                            //validate={VALIDATE.REQUIRE}
                        />
                    </Col>
                </Row>
                <Col offset={4} md={12}>
                    <Field
                        label="ช่องทางการจัดจำหน่าย"
                        name="channel"
                        component={MultiSelectedRedux}
                        options={dataChannal}
                        submitViewError={submitViewError}
                        validate={VALIDATE.REQUIRE}
                    />
                </Col>
                <Col offset={4} md={12}>
                    <Field
                        label="กลุ่มลูกค้า"
                        name="customerGroupId"
                        component={MultiSelectedRedux}
                        options={dataCustomerGroup}
                        submitViewError={submitViewError}
                        // validate={VALIDATE.REQUIRE}
                    />
                </Col>
                <Col offset={4} md={12}>
                    <Field
                        label="ร้านค้า"
                        name="outletId"
                        component={MultiSelectedRedux}
                        options={dataOutlet}
                        submitViewError={submitViewError}
                        validate={VALIDATE.REQUIRE}
                    />
                </Col>
            </Row>
        )
    }
}

export default reduxForm({
    form: 'PromotionMaintainConditionForm',  // a unique identifier for this form
    enableReinitialize: true
})(PromotionMaintainConditionForm)
