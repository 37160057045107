import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Row, Col } from 'antd'
import {
    InputCustom,
    DatePickerCustom,
    SelectCustom,
} from 'components/common'
import './style.css'
import { VALIDATE, saleTypes } from 'helpers'

class SaleOrderInfoFormRedux extends Component {
    render() {
        const {
            isErrorSubmit,
            isEditMode,
            outlets,
            documentTypes,
            taxTypes,
            saleChannels,
            shippingPoints,
            paymentTerms,
            saleEmployees,
            customer,
            changeSaleOrderInfo,
            disabledDocumentTypes,
            disabledAction,

            // inside
            documentTypeCode,
        } = this.props

        return (
            <Row style={{ marginTop: '10px' }}>
                <Col md={24}>
                    <div className="sale-order-info-form">
                        <div className="title">
                            ข้อมูลการขาย
                        </div>
                        <Row gutter={15}>
                            <Col sm={24} md={12}>
                                <Field
                                    component={InputCustom}
                                    label="อ้างอิงใบเสนอราคา"
                                    name="quotationNumber"
                                    small
                                    disabled
                                />
                            </Col>
                            <Col sm={24} md={12}>
                                <Field
                                    component={DatePickerCustom}
                                    label="วันที่"
                                    name="documentDate"
                                    small
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} md={12}>
                                <Field
                                    component={SelectCustom}
                                    label="ประเภทการขาย"
                                    name="documentTypeCode"
                                    small
                                    options={documentTypes}
                                    disabled={!customer.customerCreditAllow || disabledDocumentTypes || disabledAction}
                                    onChange={(val) => changeSaleOrderInfo('documentTypeCode', val)}
                                />
                            </Col>
                            <Col sm={24} md={12}>
                                <Field
                                    component={SelectCustom}
                                    label="ประเภทภาษี"
                                    name="taxType"
                                    small
                                    options={taxTypes}
                                    disabled={disabledAction}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} md={12}>
                                <Field
                                    component={SelectCustom}
                                    label="ช่องทาง"
                                    name="saleChannelCode"
                                    small
                                    options={saleChannels}
                                    disabled={!!isEditMode || (!customer.customerId || customer.customerId === 'OTC99') || disabledAction}
                                    onChange={(val) => changeSaleOrderInfo('saleChannelCode', val)}
                                />
                            </Col>
                            <Col sm={24} md={12}>
                                <Field
                                    component={SelectCustom}
                                    label="Site ID"
                                    name="outletId"
                                    small
                                    options={outlets}
                                    disabled={!!isEditMode || disabledAction}
                                    onChange={(val) => changeSaleOrderInfo('outletId', val)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} md={12}>
                                <Field
                                    component={SelectCustom}
                                    label="รหัสพนักงาน"
                                    name="saleEmployeeCode"
                                    small
                                    options={saleEmployees}
                                    validate={VALIDATE.REQUIRE}
                                    isError={isErrorSubmit}
                                    placeholder="กรุณาเลือก"
                                    disabled={disabledAction}
                                />
                            </Col>
                            <Col sm={24} md={12}>
                                <Field
                                    component={SelectCustom}
                                    label="เงื่อนไขการส่งมอบ"
                                    name="shippingPoint"
                                    small
                                    options={shippingPoints}
                                    validate={VALIDATE.REQUIRE}
                                    isError={isErrorSubmit}
                                    placeholder="กรุณาเลือก"
                                    disabled={disabledAction}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} md={12}>
                                <Field
                                    component={SelectCustom}
                                    label="Credit Term"
                                    name="paymentTermCode"
                                    small
                                    options={paymentTerms}
                                    disabled={documentTypeCode === 'ZOCS'}
                                    onChange={(val) => changeSaleOrderInfo('paymentTermCode', val)}
                                />
                            </Col>
                            <Col sm={24} md={12}>
                                <Field
                                    component={SelectCustom}
                                    label="Type"
                                    name="specialOrderFlag"
                                    small
                                    options={saleTypes}
                                    disabled={!!isEditMode || disabledAction}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row >
        )
    }
}

// export default reduxForm({
//     form: 'SaleOrderInfoFormRedux',
//     enableReinitialize: true,
// })(SaleOrderInfoFormRedux)

SaleOrderInfoFormRedux = reduxForm({
    form: 'SaleOrderInfoFormRedux',
    enableReinitialize: true,
})(SaleOrderInfoFormRedux)

const selector = formValueSelector('SaleOrderInfoFormRedux')
SaleOrderInfoFormRedux = connect(state => {
    const {
        documentTypeCode,
        paymentTermCode,
    } = selector(state, 'documentTypeCode', 'paymentTermCode')
    return {
        documentTypeCode,
        paymentTermCode,
    }
})(SaleOrderInfoFormRedux)

export default SaleOrderInfoFormRedux