import styled from 'styled-components'

export const ButtonCustomStyled = styled.div`

    .ant-btn-primary, .ant-btn-danger, .ant-btn-default {
        background: ${props => props.green ? 'green' : ''};
        border: ${props => props.green ? '1px solid green' : ''};
        background: ${props => props.yellow ? '#f39c12' : ''};
        border: ${props => props.yellow ? '1px solid #f39c12' : ''};
        background: ${props => props.blue ? ' #3c8dbc' : ''};
        border: ${props => props.blue ? '1px solid  #3c8dbc' : ''};
        font-size: ${props => props.small ? '12px' : ''};
    }
    .ant-btn-primary:hover {
        border-color: ${props => props.green ? '#008000b8' : ''};
        background: ${props => props.green ? '#008000b8' : ''};
        border-color: ${props => props.yellow ? '#f39c12ab' : ''};
        background: ${props => props.yellow ? '#f39c12ab' : ''};
        border-color: ${props => props.blue ? '#3c8dbcc4' : ''};
        background: ${props => props.blue ? '#3c8dbcc4' : ''};
    }
    .ant-btn.disabled, .ant-btn[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: none;
    }
`