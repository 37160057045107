import styled from 'styled-components'

export const TextAreaCustomStyled = styled.div`


.label-style .textarea-form {
    font-size: ${props => props.small ? '12px' : ''};
    width: ${props => props.small ? '120px' : ''}
}
.textarea-style .textarea-form {
    font-size: ${props => props.small ? '12px' : ''};
}
.textarea-style {
    font-size: ${props => props.small ? '12px' : ''};
}
.textarea-form {
    margin-bottom: ${props => props.small ? '3px' : ''};
}

.textarea-form .textarea-zone-label {
    display: flex;
    width: 100%;
}
`