import React, { Component } from 'react'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
} from 'components/common'
import { Row, Col } from 'antd'

export default class ShipmentPrintModal extends Component {
    state = {
        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: 'action',
                key: 'action',
                render: (props, row) =>
                    <div className="text-center">
                        <ButtonCustom
                            small
                            green
                            onClick={this.props.onPrint.bind(this, row)}
                            text="พิมพ์"
                        />
                    </div>
            },
            {
                title: 'เอกสาร',
                align: 'center',
                dataIndex: 'document',
                key: 'document',
            },
            {
                title: 'เลขที่เอกสาร',
                align: 'center',
                dataIndex: 'documentNo',
                key: 'documentNo',
            },
        ],
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            documents,
        } = this.props
        const {
            columns,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                title="เอกสารระบบ"
            >
                <Row>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={documents}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
