import React, { Component } from 'react'
import { Button } from "antd"
import { ButtonCustomStyled } from "./styled"

export class ButtonCustom extends Component {
    render() {
        const { type, text, fullWidth, icon, green, yellow, blue, onClick, disabled, small, ghost, className,id } = this.props
        return (
            <ButtonCustomStyled green={green} yellow={yellow} blue={blue} small={small} >
                <Button
                    // style={{
                    //     background: green ? 'green' : '',
                    //     border: green ? '1px solid green' : ''
                    // }}
                    id={id}
                    icon={icon}
                    type={type ? type : 'primary'}
                    ghost={ghost}
                    block={fullWidth}
                    onClick={onClick}
                    className={`button-style ${className}`}
                    disabled={disabled}
                    size={small ? 'small' : 'default'}
                >
                    {text}
                </Button>
            </ButtonCustomStyled>
        )
    }
}
