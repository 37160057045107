import React, { Component } from 'react'
import { Icon, Divider } from "antd"
import './style.css'

export class TitleHead extends Component {
    render() {
        const { text, icon, nodeRight } = this.props
        return (
            <div className="text-title" >
                <h3 style={{
                    display: nodeRight ? "flex" : '',
                    justifyContent: nodeRight ? 'space-between' : '',
                }}>
                    <div>
                        <Icon type={icon} />
                        <span className="text-style">
                            {text}
                        </span>
                    </div>
                    {
                        nodeRight &&
                        nodeRight
                    }
                </h3>

                <Divider style={{ margin: '10px 0px' }} />
            </div>
        )
    }
}
