import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { TableCustom } from 'components'

import './style.css'

class AuthTable extends Component {
    render() {
        const {
            tables,
        } = this.props
        return (
            tables.map((table, index) => (
                <TableCustom
                    key={index}
                    isStripedRow
                    columns={table.columns}
                    small
                    scroll={{ x: "max-content" }}
                    data={table.rows}
                    rowKey={(row, index) => index}
                    pagination={false} />
            ))
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthTable)