import React, { Component } from 'react'
import { SelectSearchStyled } from './styled'
import { Select,  } from 'antd'
import { uniqBy } from 'lodash'

const { Option } = Select

export class SelectSearch extends Component {
    renderOptions = () =>{
        let opts = []
        if(this.props.optionsDefault){
            opts.push(...this.props.optionsDefault)
        }
        if(this.props.options){
            opts.push(...this.props.options)
        }
        return (
            uniqBy(opts, 'value').map((data, i) => (
                <Option key={i} value={data.value} style={{ fontSize: this.props.small ? '12px' : '' }}>
                    {data.label}
                </Option>
            ))
        )
    }

    render() {
        const {
            label,
            right,
            small,
            className,
            options,
            optionsDefault,
            disabled,
            errorMsg,
            isError,
            onChange,
            onSearch,
            isPopover,
            placeholder,
            placeholderDefault,
            firstChoose,
            labelAlign,
            widthLabel,
            mode,
            isValueAllClear,
            input = {},
            meta = {},
            value,
            ...rest
        } = this.props
        const { touched, error } = meta

        // touched and error from redux form
        // error and errorMsg from props
        const isErrorForm = (touched || isError) && (error || errorMsg)

        return (
            <SelectSearchStyled
                isError={isErrorForm}
                small={small}
                label={label}
                widthLabel={widthLabel}
                labelAlign={labelAlign}
                isPlaceholderDarkColor={placeholder === 'ทั้งหมด'}
            >
                <div className="input-form form-select-search">
                    <div className="input-zone-label">
                        {
                            label && <div className="label-style">
                                {label}
                            </div>
                        }
                        <Select
                            {...rest}
                            {...input}
                            mode={mode}
                            className={`input-style select-search ${className || ''}`}
                            showSearch
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) => option.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={input.value ? input.value : value}
                            disabled={disabled}
                            size={small ? 'small' : 'default'}
                            placeholder={placeholder ? placeholder : placeholderDefault ? '-- กรุณาเลือก --' : ''}
                            dropdownMatchSelectWidth={false}
                        >
                            {this.renderOptions()}
                        </Select>
                    </div>
                    {
                        isErrorForm && <div className="error-message-bottom">
                            {error || errorMsg}
                        </div>
                    }
                </div>
            </SelectSearchStyled>
        )
    }
}
