import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col } from "antd"
import {
    InputFormRedux,
    MultiSelectedRedux,
    RangePickerRedux,
} from 'components'
import { VALIDATE } from "helpers"
import { apiServices, API_PATH } from "apiServices"
import swal from '@sweetalert/with-react';
import { get } from 'lodash'

class CreateUserForm extends React.Component {
    state = {
        outletList: [],
        taxTypeList: [],
        roleList: [],
        channelList: [],
        wareHouseList: [],
        strPassword: '',
        charPassword: '',
        minPasswordLength: 8,
        baseScore: 0,
        score: 0,
        num: {
            Excess: 0,
            Upper: 0,
            Numbers: 0,
            Symbols: 0,
        },
        bonus: {
            Excess: 3,
            Upper: 4,
            Numbers: 5,
            Symbols: 5,
            Combo: 0,
            FlatLower: 0,
            FlatNumber: 0,
        },
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {}).then(res => {
            let dataOutlet = res.data.results.map(data => (
                {
                    ...data,
                    text: data.outletId + ' ' + '-' + ' ' + data.outletName,
                    value: data.outletId,
                    label: data.outletId + ' ' + '-' + ' ' + data.outletName
                }
            ))
            this.setState({
                outletList: dataOutlet,
            })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('get', API_PATH.GET_MASTER_TAX_TYPE).then(res => {
            let dataTax = res.data.results.map(data => (
                {
                    ...data,
                    text: data.taxDescription,
                    value: data.taxTypeId,
                    label: data.taxDescription
                }
            ))
            this.setState({
                taxTypeList: dataTax,
            })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('get', API_PATH.GET_MASTER_ROLE).then(res => {
            let dataRole = res.data.results.map(data => (
                {
                    ...data,
                    text: data.roleName,
                    value: data.roleId,
                    label: data.roleName
                }
            ))
            let masterRole = res.data.results.map(data => (
                {
                    ...data,
                    text: data.roleName,
                    value: data.roleId,
                    label: data.roleName
                }
            ))
            this.setState({
                roleList: dataRole,
            })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL).then(res => {
            let dataChannal = res.data.results.map(data => (
                {
                    ...data,
                    text: data.saleChannelCode + ' ' + '-' + ' ' + data.saleChannelDescription,
                    value: data.saleChannelCode,
                    label: data.saleChannelCode + ' ' + '-' + ' ' + data.saleChannelDescription
                }
            ))

            this.setState({
                channelList: dataChannal
            })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });

        const { initialValues } = this.props;
        if (initialValues && initialValues.outlet.length > 0) {
            this.handleChangeGetWareHouse(initialValues.outlet);
        }
    }

    handleChangeGetWareHouse = async (value) => {
        if (value.length > 0) {
            console.log("success===>", value);
            let res = await apiServices.callApi("post", API_PATH.GET_MASTER_WAREHOUSE_BY_OUTLET_ID, value)
            console.log("res=>", res);
            if (res.status === 200) {
                let dataWareHouse = res.data.results.map(data => (
                    {
                        ...data,
                        text: data.storageLocationId + ' ' + '-' + ' ' + data.storageLocationDescriptionThai,
                        value: data.storageLocationId,
                        label: data.storageLocationId + ' ' + '-' + ' ' + data.storageLocationDescriptionThai
                    }
                ))
                this.setState({
                    wareHouseList: dataWareHouse
                })
            }
            else {
                this.setState({
                    wareHouseList: []
                })
            }

        }
        else {
            this.setState({
                wareHouseList: [],
                initialValues: {
                    storageLocationId: [],
                    storageLocationIdMovement: [],
                }
            })
        }
    }

    checkVal = (value) => {
        const {
            minPasswordLength,
            baseScore,
        } = this.state;

        if (value.length >= minPasswordLength) {
            baseScore = 50;
            //this.analyzeString();
            //calcComplexity();
        }
        else {
            baseScore = 0;
        }

        //outputResult();
    }

    render() {
        const {
            submitViewError,
            disabledInput,
        } = this.props

        const {
            outletList,
            taxTypeList,
            roleList,
            channelList,
            wareHouseList,
        } = this.state;

        return (
            <Row className="input-layout" gutter={16}>
                <Col md={24}>
                    <Row gutter={20}>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="userId"
                                label="รหัสผู้ใช้งาน"
                            />
                        </Col>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={RangePickerRedux}
                                name="validDate"
                                label="วันที่มีผล"
                            />
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="firstName"
                                label="ชื่อ"

                            />
                        </Col>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="lastName"
                                label="นามสกุล"

                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col md={24} xl={12}>
                            {
                                disabledInput
                                    ?
                                    <Field
                                        component={InputFormRedux}
                                        password
                                        name="password"
                                        label="รหัสผ่าน"

                                        disabled={disabledInput}
                                    />
                                    :
                                    <Field
                                        validate={[VALIDATE.REQUIRE, VALIDATE.REQUIRE_COMPLEX]}
                                        submitViewError={submitViewError}
                                        component={InputFormRedux}
                                        password
                                        name="password"
                                        label="รหัสผ่าน"

                                    />
                            }

                        </Col>
                        <Col md={24} xl={12}>
                            {
                                disabledInput
                                    ?
                                    <Field
                                        component={InputFormRedux}
                                        password
                                        name="confirmpassword"
                                        label="ยืนยันรหัสผ่าน"

                                        disabled={disabledInput}
                                    />
                                    :
                                    <Field
                                        validate={[VALIDATE.REQUIRE, VALIDATE.REQUIRE_COMPLEX]}
                                        submitViewError={submitViewError}
                                        component={InputFormRedux}
                                        password
                                        name="confirmpassword"
                                        label="ยืนยันรหัสผ่าน"
                                        disabled={disabledInput}
                                    />
                            }

                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col md={24} xl={12}>
                            <Field
                                validate={[VALIDATE.REQUIRE, VALIDATE.EMAIL]}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="email"
                                label="Email"

                            />
                        </Col>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={MultiSelectedRedux}
                                name="outlet"
                                label="บริษัท"
                                options={outletList}
                                onChangeHero={this.handleChangeGetWareHouse}
                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="roleDescription"
                                label="ตำแหน่ง"

                            />
                        </Col>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="phone"
                                label="โทรศัพท์"

                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={MultiSelectedRedux}
                                name="storageLocationIdMovement"
                                label="คลัง (สิทธิ์การเคลื่อนไหวสต็อค)"
                                options={wareHouseList}
                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={MultiSelectedRedux}
                                name="storageLocationId"
                                label="คลัง (สิทธิ์การแสดงเท่านั้น)"
                                options={wareHouseList}
                            />
                        </Col>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={MultiSelectedRedux}
                                name="channel"
                                label="ช่องทางการจัดจำหน่าย"
                                options={channelList}
                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={MultiSelectedRedux}
                                name="tax"
                                label="ประเภทภาษี"
                                options={taxTypeList}
                                maxTagCount={4}
                            />
                        </Col>
                        <Col md={24} xl={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={MultiSelectedRedux}
                                name="role"
                                label="บทบาทการใช้งาน"
                                options={roleList}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default reduxForm({
    form: 'CreateUserForm',  // a unique identifier for this form
    enableReinitialize: true
})(CreateUserForm)
