import React, { Component } from 'react'
import { Radio } from 'antd'
import { RadioCustomStyled } from './styled'

export class RadioCustom extends Component {
    render() {
        const {
            type,
            value,
            className,
            onChange,
            onClick,
            options,
            name,
            disabled,
            isError,
            errorMsg,
            small,
            label,
            labelAlign,
            widthLabel,
            labelColor,
            input = {},
            meta = {},

            // rest parameters
            ...rest
        } = this.props
        const { touched, error } = meta

        // touched and error from redux form
        // error and errorMsg from props
        const isErrorForm = (touched || isError) && (error || errorMsg)

        return (
            <RadioCustomStyled
                isError={isErrorForm}
                small={small}
                labelColor={labelColor}
                label={label}
                widthLabel={widthLabel}
                labelAlign={labelAlign}
            >
                <div className="input-form form-radio-custom">
                    <div className="input-zone-label">
                        {
                            label && <div className="label-style">
                                {label}
                            </div>
                        }
                        {
                            <Radio.Group
                                // {...input}
                                {...rest}
                                className={`input-style radio-custom ${className || ''}`}
                                value={input.value ? input.value : value}
                                disabled={disabled}
                                onChange={e => {
                                    onChange && onChange(e)
                                    input.onChange && input.onChange(e)
                                }}
                                size={small ? 'small' : 'default'}
                            >
                                {
                                    options && options.map((data, i) => (
                                        type === 'button' ?
                                            <Radio.Button key={i} value={data.value} onClick={onClick}>{data.label}</Radio.Button>
                                            :
                                            <Radio key={i} value={data.value} onClick={onClick}>{data.label}</Radio>
                                    ))
                                }
                            </Radio.Group>
                        }
                    </div>
                    {
                        isErrorForm && <div className="error-message-bottom">
                            {error || errorMsg}
                        </div>
                    }
                </div>
            </RadioCustomStyled>
        )
    }
}
