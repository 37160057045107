import React, { Component } from 'react'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    SelectHero,
} from 'components/common'
import { CustomerKeyWordDropDown, sortDataColumn } from 'helpers'
import { Row, Col, Input } from 'antd'
import { get } from 'lodash'
import './style.css'

const { Search } = Input

export class ModalProductDetail extends Component {
    state = {
        columns: [
            // {
            //     title: '#',
            //     align: 'center',
            //     dataIndex: '#',
            //     key: '#',
            //     render: (value, row) => <ButtonCustom small onClick={() => this.props.onSelected(row)} text="เลือก" />
            // },
            // {
            //     title: 'รหัสลูกค้า',
            //     align: 'center',
            //     dataIndex: 'customerId',
            //     key: 'customerId',
            //     sorter: (a, b) => sortDataColumn(a, b, 'customerId'),
            //     ellipsis: true,
            // },
            {
                title: 'จำนวนที่มี',
                align: 'center',
                dataIndex: 'atp',
                key: 'atp',
                sorter: (a, b) => sortDataColumn(a, b, 'atp'),
                ellipsis: true,
            },
            {
                title: 'จำนวน',
                align: 'center',
                dataIndex: 'denominator',
                key: 'denominator',
                sorter: (a, b) => sortDataColumn(a, b, 'denominator'),
                ellipsis: true,
            },
            {
                title: 'หน่วย',
                align: 'center',
                dataIndex: 'unitId',
                key: 'unitId',
                sorter: (a, b) => sortDataColumn(a, b, 'unitId'),
                ellipsis: true,
                // return:(props,full)
            },
            {
                title: 'สัดส่วน/หน่วย',
                align: 'center',
                dataIndex: 'numerator',
                key: 'numerator',
                sorter: (a, b) => sortDataColumn(a, b, 'numerator'),
                ellipsis: true,
                return: (props, full) => {
                    return (
                        <div>{props}</div>
                    )
                }
            },
            {
                title: 'หน่วยนับ',
                align: 'center',
                dataIndex: 'baseUnit',
                key: 'baseUnit',
                sorter: (a, b) => sortDataColumn(a, b, 'baseUnit'),
                ellipsis: true,
            },

            {
                title: 'ราคา/หน่วย',
                align: 'center',
                dataIndex: 'retailPrice',
                key: 'retailPrice',
                sorter: (a, b) => sortDataColumn(a, b, 'retailPrice'),
                ellipsis: true,
            },
            {
                title: 'Barcode',
                align: 'center',
                dataIndex: 'barcode',
                key: 'barcode',
                sorter: (a, b) => sortDataColumn(a, b, 'barcode'),
                ellipsis: true,
            }
        ],
        keyword: '',
        keywordType: CustomerKeyWordDropDown[1].value,
    }

    changeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    changeKeywordType = (keywordType) => {
        this.setState({ keywordType })
    }

    render() {
        const {
            isShowModal,
            handleCloseModal,
            title,
            width,
            onSearch,
            data,
            loading,
            className,
        } = this.props
        const {
            columns,
            keywordType,
        } = this.state;
        let dataProduct = get(data, '[0]');
        if (!dataProduct) return (<ModalCustom
            isShowModal={isShowModal}
            onCloseModal={handleCloseModal}
            title={title || 'รายละเอียดสินค้า'}
            width={width || '60%'}
            className={`modal-data ${className}`}
        >Loading</ModalCustom>)


        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={handleCloseModal}
                title={title || 'รายละเอียดสินค้า'}
                width={width || '60%'}
                className={`modal-data ${className}`}
            >
                <Row gutter={8}>
                    <Col sm={4}>
                        <b> รหัสสินค้า</b>
                    </Col>
                    <Col sm={8}>
                        {dataProduct.productId || ''}
                    </Col>
                    <Col sm={4}>
                        <b> สินค้า</b>
                    </Col>
                    <Col sm={8}>
                        {dataProduct.productNameSap || ''}
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={4}>
                        <b>หน่วยเก็บสต็อก</b>
                    </Col>
                    <Col sm={8}>
                        {dataProduct.stockType || ''}
                    </Col>
                    <Col sm={4}>
                        <b>หน่วยซื้อ</b>
                    </Col>
                    <Col sm={8}>
                        {dataProduct.orderUnit || ''}
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={4}>
                        <b> หน่วยขาย</b>
                    </Col>
                    <Col sm={8}>
                        {dataProduct.salesUnit}
                    </Col>
                    <Col sm={4}>
                        <b>หมวด</b>
                    </Col>
                    <Col sm={8}>
                        {dataProduct.category}
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={4}>
                        <b>ยีห้อ</b>
                    </Col>
                    <Col sm={8}>
                    {dataProduct.brand}
                    </Col>
                    <Col sm={4}>
                        <b> รหัสสินค้า SCG</b>
                    </Col>
                    <Col sm={8}>
                        {dataProduct.oldMaterialNo}
                    </Col>
                    {/* <Col sm={4}>
                        <b>ยีห้อ(ย่อย)</b>
                    </Col>
                    <Col sm={8}>
                    {dataProduct.subClass}
                    </Col> */}
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            // height="calc(100vh - 511px)"
                            columns={columns}
                            data={data}
                            // loading={loading}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
