import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty, map } from 'lodash'
import { Checkbox, Row, Col } from 'antd'
import { TitleHead, ButtonCustom, TableCustom, InputHero } from 'components'
import { apiServices, API_PATH } from "apiServices"
import './style.css'
import { Link } from 'react-router-dom';
import { ROUTH_PATH, functionAlias } from 'routes'
import { getPermissionPage, sortDataColumn } from 'helpers'
import swal from '@sweetalert/with-react'

class DisplayUserContainer extends Component {
    state = {
        loading: true,
        columns: [
            {
                dataIndex: 'button',
                render: (props, row) => <div className="button-table-zone">
                    <div>
                        <ButtonCustom onClick={() => this.handleClickEditUser(row.userId)} icon="edit" disabled={!this.state.permissionPage.authUpdate} />
                    </div>
                    <div className="reset-password">
                        <ButtonCustom onClick={() => this.handleClickChangPassword(row.userId)} icon="reload" type="danger" disabled={!this.state.permissionPage.authApprove} />
                    </div>
                </div>
            },
            {
                title: 'รหัสผู้ใช้งาน',
                dataIndex: 'userId',
                key: 'userId',
                sorter: (a, b) => sortDataColumn(a, b, 'userId'),
                ellipsis: true,
            },
            {
                title: 'ชื่อ-นามสกุล',
                dataIndex: 'firstName',
                key: 'firstName',
                sorter: (a, b) => sortDataColumn(a, b, 'firstName'),
                render: (text, row) => <span>{text + " " + row.lastName}</span>,
                ellipsis: true,
            },
            {
                title: 'บริษัท',
                dataIndex: 'userOutletModelList',
                key: 'userOutletModelList',
                sorter: (a, b) => sortDataColumn(a, b, 'userOutletModelList'),
                ellipsis: true,
                render: props => <span>
                    {props && props.map((data, i) => (
                        <span key={i}>
                            {data.outletId}
                            {
                                props.length === i + 1 ? '' : ' ' + ',' + ' '
                            }
                        </span>
                    ))}
                </span>
            },
            {
                title: 'ตำแหน่ง',
                dataIndex: 'roleDescription',
                key: 'roleDescription',
                sorter: (a, b) => sortDataColumn(a, b, 'roleDescription'),
                ellipsis: true,
            },
            {
                title: 'หน้าที่',
                dataIndex: 'userRoleModelList',
                key: 'userRoleModelList',
                sorter: (a, b) => sortDataColumn(a, b, 'userRoleModelList'),
                ellipsis: true,
                render: props => <span>
                    {props && props.map((data, i) => (
                        <span key={i}>
                            {
                                data.userRoleMasterModel.roleName
                            }
                            {
                                props.length === i + 1 ? '' : ' ' + ',' + ' '
                            }
                        </span>
                    ))}
                </span>
            },
            {
                title: 'ช่องทางการจัดจำหน่าย',
                dataIndex: 'saleChannelCode',
                key: 'saleChannelCode',
                sorter: (a, b) => sortDataColumn(a, b, 'saleChannelCode'),
                ellipsis: true,
            },
            {
                title: 'เบอร์ติดต่อ',
                dataIndex: 'phone',
                key: 'phone',
                sorter: (a, b) => sortDataColumn(a, b, 'phone'),
                ellipsis: true,
            },
            {
                title: 'ใช้งานอยู่',
                dataIndex: 'validFrom',
                key: 'validFrom',
                render: (props, row) => <div>
                    {
                        props ?
                            (new Date() >= new Date(props) && new Date() <= new Date(row.validTo))
                                ?
                                <div style={{ textAlign: 'center' }}>
                                    <Checkbox defaultChecked={true} disabled />
                                </div>
                                :
                                <div style={{ textAlign: 'center' }}>
                                    <Checkbox defaultChecked={false} disabled />
                                </div>
                            : ''
                    }
                </div>
            },
        ],
        keyword: '',
        permissionPage: getPermissionPage(functionAlias.userMaintain),
    }

    componentDidMount() {
        if (!this.state.permissionPage.authDisplay) {
            swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
                this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
            })
            return
        }

        this.fetchData()
    }
    fetchData = async () => {
        const { auth } = this.props
        let res = await apiServices.callApi("get", API_PATH.GET_USER, '', auth.token)
        // console.log("res===>", res);
        if (res.status === 200) {
            this.setState({
                dataUserList: res.data.results,
                loading: false
            })
        }
    }

    handleClickEditUser = (key) => {
        // console.log("keySelected===>", key);
        this.props.history.push(`${ROUTH_PATH.EDITUSER_LINK}/${key}`)
        // window.location.href = "/dashboard/user/create-user?UserId=" + key;
    }
    handleClickChangPassword = (key) => {
        this.props.history.push({
            pathname: ROUTH_PATH.CHANGE_PASSWORD,
            state: key
        })
    }

    onChangeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    filterUsers = () => {
        let { keyword, dataUserList } = this.state
        if (isEmpty(dataUserList)) return []
        keyword = keyword.toLowerCase()

        return dataUserList.filter(user => {
            const userRoleMasterModels = map(user.userRoleModelList, 'userRoleMasterModel')
            return user.userId.toLowerCase().includes(keyword)
                || (`${user.firstName || ''} ${user.lastName || ''}`).toLowerCase().includes(keyword)
                || (user.roleDescription || '').toLowerCase().includes(keyword)
                || (user.saleChannelCode || '').toLowerCase().includes(keyword)
                || (user.phone || '').toLowerCase().includes(keyword)
                || (map(user.userOutletModelList, 'outletId').join(' , ').toLowerCase()).includes(keyword)
                || (map(userRoleMasterModels, 'roleName').join(' , ').toLowerCase()).includes(keyword)
        })
    }

    render() {
        const { columns, loading, keyword, permissionPage } = this.state
        const dataUserList = this.filterUsers()
        return (
            <div className="display-user-content">
                <TitleHead text="ผู้ใช้งานระบบ" icon="user" />

                <div className="top-layout">
                    <Link to={ROUTH_PATH.ADDUSER}>
                        <ButtonCustom text="เพิ่มผู้ใช้งานระบบ" icon="plus-circle" disabled={!permissionPage.authCreate} />
                    </Link>
                    <span className="button-layout">
                        <ButtonCustom green text="export" icon="export" disabled={!permissionPage.authExport} />
                    </span>
                </div>
                <Row>
                    <Col sm={8} offset={16}>
                        <InputHero
                            label="Search : "
                            widthDefault="70px"
                            onChange={this.onChangeKeyword}
                            value={keyword}
                        />
                    </Col>
                </Row>
                <div className="table-layout">
                    <TableCustom rowKey={(row, index) => index} height="calc(100vh - 304px)" columns={columns} data={dataUserList} loading={loading} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth
    };
};



export default connect(mapStateToProps)(DisplayUserContainer)