import React, { Component } from 'react'
import { Input, Button, Icon } from 'antd'
import { InputSearchCustomStyled } from './styled'

export class InputSearchCustom extends Component {
    render() {
        const {
            label,
            small,
            disabled,
            placeholder,
            defaultValue,
            onChange,
            onSubmit,
            onClickClearValue,
            onClickSearch,
            htmlTypeButton,
            widthLabel,
            labelAlign,
            disabledButton,
            input = {},
            ...rest
        } = this.props

        return (
            <InputSearchCustomStyled
                small={small}
                label={label}
                widthLabel={widthLabel}
                labelAlign={labelAlign}
            >
                <div className="input-form form-input-search-custom">
                    <div className="input-zone-label">
                        {
                            label && <div className="label-style">
                                {label}
                            </div>
                        }
                        <div className="input-search-custom">
                            <Input
                                {...input}
                                {...rest}
                                placeholder={placeholder}
                                disabled={disabled}
                                defaultValue={defaultValue}
                                onChange={e => {
                                    onChange && onChange(e)
                                    input.onChange && input.onChange(e)
                                }}
                                onPressEnter={onSubmit ? onSubmit : null}
                                size={small ? 'small' : 'default'}
                                suffix={onClickClearValue && <Icon type="close" onClick={onClickClearValue} style={{ cursor: 'pointer' }} />}
                            />
                            <Button
                                onClick={onClickSearch}
                                htmlType={htmlTypeButton}
                                className="button-search-custom"
                                type="primary"
                                icon="search"
                                disabled={disabledButton}
                            />
                        </div>
                    </div>
                </div>
            </InputSearchCustomStyled>
        )
    }
}
