import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, } from "antd"
import { TableCustom, DatePickerCustom, ButtonCustom, ModalCustom } from "components"
import { SelectCustom, InputSearchCustom } from "components/common"
import { CustomerSearchModal } from '../CustomerSearchModal'
import { authData } from 'actions/actionsCreator';
import { apiServices, API_PATH } from "apiServices"
import swal from '@sweetalert/with-react'
import { get } from 'lodash'
import { sortDataColumn } from 'helpers'
import './style.css'

class DeliveryOrderSearchModal extends Component {
    state = {
        columns: [
            {
                title: '#',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                render: (value, row, index) => (
                    <ButtonCustom small onClick={() => {
                        this.props.handleClickSelectedDOModal(row)
                        this.handleClearCriteria()
                    }} text="เลือก" />
                )
            },
            {
                title: 'วันที่เอกสาร',
                dataIndex: 'salesDoHeadModel.documentDate',
                key: 'documentDate',
                // TODO: recheck sorter
                // sorter: (a, b) => sortDataColumn(a, b, 'salesDoHeadModel.documentDate'),
                ellipsis: true,
                align: 'center',
                render: (value) => {
                    return this.changeFormatDate(value);
                },
            },
            {
                title: 'ใบส่งสินค้า',
                dataIndex: 'doNumber',
                key: 'doNumber',
                sorter: (a, b) => sortDataColumn(a, b, 'doNumber'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'ชื่อผู้สั่งซื้อ',
                dataIndex: 'salesDoHeadModel.shipToName',
                key: 'shipToName',
                // TODO: recheck sorter
                // sorter: (a, b) => sortDataColumn(a, b, 'shipToName'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
                sorter: (a, b) => sortDataColumn(a, b, 'productCode'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                sorter: (a, b) => sortDataColumn(a, b, 'productName'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: ' ที่เก็บ',
                dataIndex: 'slocId',
                key: 'slocId',
                sorter: (a, b) => sortDataColumn(a, b, 'slocId'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'จำนวน',
                dataIndex: 'doQty',
                key: 'doQty',
                sorter: (a, b) => sortDataColumn(a, b, 'doQty'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'หน่วย',
                dataIndex: 'doUnit',
                key: 'doUnit',
                sorter: (a, b) => sortDataColumn(a, b, 'doUnit'),
                ellipsis: true,
                align: 'center',
            }
        ],
        dataList: [],
        loading: false,
        widthDefault: '100%',
        dateFormat: 'DD/MM/YYYY',

        shipToId: '',
        documentDateFrom: '',
        documentDateTo: '',

        isCustomerSearchModal: false,
    }

    componentDidMount() {

    }

    changeFormatDate = (value) => {
        if (value) {
            var d = new Date(value),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('-');
        }
        return value;
    }

    handleChangeShipToId = (e) => {
        this.setState({ shipToId: e.target.value });
    }

    handleChangeDocumentDateFrom = (value) => {
        this.setState({ documentDateFrom: value });
    }

    handleChangeDocumentDateTo = (value) => {
        this.setState({ documentDateTo: value });
    }

    handleClearCriteria = () => {
        this.setState({ shipToId: '', documentDateFrom: '', documentDateTo: '', dataList: [] });
    }

    handleClickSearchDO = async (e) => {
        const { shipToId, documentDateFrom, documentDateTo } = this.state
        const { outletSelected } = this.props;
        this.setState({ loading: true });
        let params = {
            shipToId: shipToId,
            documentDateFrom: documentDateFrom,
            documentDateTo: documentDateTo,
            outletId: outletSelected
        };
        await apiServices.callApi('post', API_PATH.GET_SALES_DO_ITEM, params).then(res => {
            if (res.status === 200) {
                let dataList = res.data.results;

                this.setState({
                    dataList
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });
        this.setState({ loading: false });
    }

    handleClickCustomerearch = () => {
        this.setState({ isCustomerSearchModal: true });
    }

    handleClickCloseCustomerSearch = () => {
        this.setState({ isCustomerSearchModal: false });
    }

    handleClickSelectedCustomerSearch = (selected) => {
        this.setState({ isCustomerSearchModal: false , shipToId: selected.customerId}, () => this.handleClickSearchDO());
    }

    render() {
        const {
            isShowModal,
            closeModal,
            outlets,
            outletSelected,
        } = this.props;

        const {
            loading,
            columns,
            dataList,
            widthDefault,
            dateFormat,
            shipToId,
            documentDateFrom,
            documentDateTo,
            isCustomerSearchModal,
        } = this.state;

        return (
            <ModalCustom
                title="ค้นหาใบส่งสินค้า"
                isShowModal={isShowModal}
                handleCloseModal={() => {
                    closeModal()
                    this.handleClearCriteria()
                }}
                width="70%"
                footer={false}
            >
                <div className='delivery-order-search-modal'>
                    <Row gutter={20}>
                        <Col md={3}>
                            รหัสผู้สั่งซื้อ
                        </Col>
                        <Col md={5}>
                            <InputSearchCustom
                                onChange={this.handleChangeShipToId}
                                name="shipToId"
                                value={shipToId}
                                onClickSearch={this.handleClickCustomerearch}
                            />
                        </Col>
                        <Col md={2}>
                            ร้าน
                        </Col>
                        <Col md={5}>
                            <SelectCustom
                                name="outlet"
                                options={outlets}
                                value={outletSelected}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col md={3}>
                            วันที่เอกสาร
                        </Col>
                        <Col md={5}>
                            <DatePickerCustom
                                onChange={this.handleChangeDocumentDateFrom}
                                widthAll={widthDefault}
                                name="documentDateFrom"
                                format={dateFormat}
                                value={documentDateFrom}
                            />
                        </Col>
                        <Col md={2}>
                            ถึง
                        </Col>
                        <Col md={5}>
                            <DatePickerCustom
                                onChange={this.handleChangeDocumentDateTo}
                                widthAll={widthDefault}
                                name="documentDateTo"
                                format={dateFormat}
                                value={documentDateTo}
                            />
                        </Col>
                        <Col md={2}>
                            <span><ButtonCustom blue text="ค้นหา" icon="search" onClick={this.handleClickSearchDO} /></span>
                        </Col>
                    </Row>
                    <div className='content'>
                        <TableCustom height="calc(100vh - 316px)" columns={columns} data={dataList} loading={loading} pagination={true} />
                    </div>

                    <CustomerSearchModal
                        isShowModal={isCustomerSearchModal}
                        handleCloseModal={this.handleClickCloseCustomerSearch}
                        onSelected={this.handleClickSelectedCustomerSearch}
                    />
                </div>

            </ModalCustom>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOrderSearchModal);