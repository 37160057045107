import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { ROUTH_PATH } from 'routes/path';
import './style.css'
import { authData } from 'actions/actionsCreator';
import moment from 'moment'
import swal from '@sweetalert/with-react';
import { Row, Col, Spin, message, } from "antd"
import {
  TitleHead,
  InputCustom,
  TableCustom,
  ButtonCustom,
  TextAreaCustom,
  SelectCustom,
  NumberFormatCustom,
  DatePickerCustom,
  InputSearchCustom,
} from "components/common"
import { get, first, isEmpty, compact, } from 'lodash';
import { StoreLocationModal, ProductStockSearchModal, } from 'components/feature';
import { apiServices, API_PATH } from "apiServices";
import { giStockType, getPermissionPage } from "helpers";
import { transactionTypeSpec, tempProduct, } from './config'
import { functionAlias } from 'routes';

// TODO: check permission print button when added print button
class GoodsReceiveWithoutRefContainer extends Component {
  state = {
    loading: false,
    dateFormat: 'DD/MM/YYYY',
    outletOptions: [],
    outletRoles: [],
    items: [],
    isDisabled: false,
    isFoundMatDoc: false,
    isShowModal: false,
    dataSelected: "",
    dataStorages: [],
    defaultStore: '',
    selectedItemRow: 0,
    transactionTypes: [],
    header: {
      ...transactionTypeSpec[501].header,
      docDate: moment(),
      docType: '',
    },
    tableColumns: transactionTypeSpec[501].tableColumns,
    columns: [],
    filter: {
      productId: '',
    },

    modelGoodsReceiveRequest: {
      IM_MODE: 'C',
      IM_MOVE_TYPE: '501',
      IM_TYPE_REF_DOC: 'R01',
      IM_PIID: '',
      IM_GOODSMVT_HEADER: {
        item: [],
      },
      IT_REMARK: {
        item: [],
      },
      IT_GOODSMVT_ITEM: {
        item: [],
      }
    },
    permissionPage: getPermissionPage(functionAlias.inventoryReceiveWithoutRef),
  };

  componentDidMount() {
    this.fetchDataDefault();
  }

  fetchDataDefault = async () => {
    this.setState({ loading: true });
    await this.fetchDataOutlet();
    await this.fetchDataDefaultStoreLocation();
    await this.fetchDataDocType();
    this.setColumns();
    this.setState({ loading: false });
  }

  setColumns = () => {
    const { tableColumns, outletRoles, header } = this.state;
    let columns = [];
    let items = [];
    console.log("outletId : ", header.outletId);
    console.log("test : ", outletRoles);
    console.log("bool : ", outletRoles.filter(item => item.outletId === header.outletId && item.outletRole === 'Boutique').length);
    if (!isEmpty(tableColumns)) {
      columns = [
        tableColumns.zeile && {
          title: 'รายการ',
          width: 60,
          dataIndex: 'zeile',
          key: 'zeile',
          align: 'center',
          render: (value, full, row) =>
            <p>{value || row + 1}</p>
        },
        tableColumns.matnr && {
          title: 'รหัสสินค้า',
          width: 150,
          dataIndex: 'matnr',
          key: 'matnr',
          render: (value, full, row) =>
            isEmpty(full.matnr) ?
              <InputSearchCustom
                name='matnr'
                placeholder="200000029"
                value={this.state.filter.productId}
                onClickSearch={() => this.handleShowModal('productStock', {})}
                small={true}
                onChange={(e) => this.handleChangeFilter('productId', e.target.value)}
                onSubmit={() => this.onSearchProduct()}
              />
              : value
        },
        tableColumns.eaN11 && {
          title: 'บาร์โค้ด',
          width: 140,
          dataIndex: 'eaN11',
          key: 'eaN11',
          align: 'center',
        },
        tableColumns.maktg && {
          title: 'ชื่อสินค้า',
          width: 300,
          dataIndex: 'maktg',
          key: 'maktg',
          align: 'left',
        },
        tableColumns.lgort && {
          title: 'ที่เก็บ',
          width: 120,
          dataIndex: 'lgort',
          key: 'lgort',
          align: 'center',
          render: (value, full, row) =>
            !isEmpty(full.matnr) &&
            <SelectCustom
              options={this.generateStoreLocationOptions(full)}
              pleaseChoose
              onChange={(value) => this.handleChangeItem(row, 'lgort', value)}
              value={value}
              name="lgort"
              small={true}
            //disabled={full.stockBalances && full.stockBalances.length === 0}
            />
        },
        tableColumns.erfme && {
          title: 'หน่วยนับ',
          dataIndex: 'erfme',
          key: 'erfme',
          align: 'center',
          render: (value, full, row) =>
            !isEmpty(full.matnr) &&
            <SelectCustom
              options={this.generateUnitOptions(full)}
              pleaseChoose
              onChange={(value) => this.handleChangeItem(row, 'erfme', value)}
              value={value}
              name="erfme"
              small={true}
            />
        },
        tableColumns.erfmg && {
          title: 'จำนวนรับ',
          width: 100,
          dataIndex: 'erfmg',
          key: 'erfmg',
          render: (value, full, row) =>
            !isEmpty(full.matnr) &&
            <NumberFormatCustom
              name="erfmg"
              className="text-align-right"
              // value={parseFloat(`${value}`.replace(/,/g, '')).toFixed(2)}
              // onChange={(e) => this.handleChangeItem(row, 'erfmg', e.target.value)}
              decimalScale={2}
              value={value}
              onChange={(e) => this.handleChangeItem(row, 'erfmg', Number(`${e.target.value}`.replace(/,/g, '')))}
            />
        },
        tableColumns.erfmg && {
          title: 'มูลค่ารวม',
          width: 100,
          dataIndex: 'dmbtr',
          key: 'dmbtr',
          render: (value, full, row) =>
            !isEmpty(full.matnr) &&
            <NumberFormatCustom
              name="dmbtr"
              className="text-align-right"
              decimalScale={2}
              value={value}
              disabled={!(outletRoles.filter(item => item.outletId === header.outletId && item.outletRole === 'Boutique').length > 0)}
              onChange={(e) => this.handleChangeItem(row, 'dmbtr', Number(`${e.target.value}`.replace(/,/g, '')))}
            />
        },
        tableColumns.insmk && {
          title: 'ประเภทสินค้า',
          dataIndex: 'insmk',
          key: 'insmk',
          render: (value, full, row) =>
            !isEmpty(full.matnr) &&
            <SelectCustom
              options={giStockType}
              pleaseChoose
              onChange={(val) => this.handleChangeItem(row, 'insmk', val)}
              name="insmk"
              small={true}
              value={value}
            />
        },
      ]
    }
    // remove falsey values in array
    columns = compact(columns);
    items.push(tempProduct);
    this.setState({ columns, items });
  }

  generateStoreLocationOptions = (full) => {
    if (!full.stockBalances || full.stockBalances.length === 0) {
      return [{
        label: full.lgort,
        value: full.lgort,
      }];
    }
    let filterstockBalancesByUnit = full.stockBalances.filter(stock => stock.eX_DISPLAY_UNIT === full.erfme);
    if (filterstockBalancesByUnit && filterstockBalancesByUnit.length === 0) {
      return [{
        label: full.lgort,
        value: full.lgort,
      }];
    }

    return filterstockBalancesByUnit.map(stock => ({
      label: `${stock.lgort}:${stock.lgobe}`,
      value: stock.lgort,
    }));
  }

  generateUnitOptions = (full) => {
    if (!full.stockBalances || full.stockBalances.length === 0) {
      return [{
        label: full.erfme,
        value: full.erfme,
      }];
    }
    let filterUnitsByStore = full.stockBalances.filter(stock => stock.lgort === full.lgort);
    if (filterUnitsByStore && filterUnitsByStore.length === 0) {
      return [{
        label: full.lgort,
        value: full.lgort,
      }];
    }

    return filterUnitsByStore.map(stock => ({
      label: stock.erfme || stock.eX_DISPLAY_UNIT,
      value: stock.erfme,
    }))
  }

  clearState = () => {
    this.setState({
      outletOptions: [],
      header: {
        ...transactionTypeSpec[501].header,
        docDate: moment(),
        docType: '',
      },
      items: [],
      filter: {
        productId: '',
      },
      isDisabled: false,
      isFoundMatDoc: false,
      isShowModal: false,
      dataSelected: "",
      dataStorages: [],
      defaultStore: '',

      modelGoodsReceiveRequest: {
        IM_MODE: 'C',
        IM_MOVE_TYPE: '501',
        IM_TYPE_REF_DOC: 'R01',
        IM_PIID: '',
        IM_GOODSMVT_HEADER: {
          item: [],
        },
        IT_REMARK: {
          item: [],
        },
        IT_GOODSMVT_ITEM: {
          item: [],
        }
      },
    }, async () => {
      await this.fetchDataOutlet();
      await this.fetchDataDefaultStoreLocation();
      await this.fetchDataDocType();
      this.setColumns();
    });
  }

  fetchDataDocType = async () => {
    let { transactionTypes } = this.state;
    //ประเภทเอกสาร Transaction Type
    const codes = Object.keys(transactionTypeSpec).map(key => (`TransactionCodes=${key}`)).join('&')
    const url = `${API_PATH.GET_MASTER_TRANSACTION_TYPE}?ActiveStatus=true&${codes}`;
    await apiServices.callApi('get', url, '', '').then(res => {
      let results = get(res, 'data.results');
      transactionTypes = results.map(data => ({
        label: `${data.transactionCode} - ${data.transactionDescription}`,
        value: data.transactionCode,
      }));

      this.setState({
        transactionTypes: [{ label: 'กรุณาเลือก', value: '' }, ...transactionTypes]
      })
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "ประเภทเอกสาร"!')
      swal('Error', message, 'error')
    });
  }

  fetchDataOutlet = async () => {
    const { auth } = this.props;
    let { outletOptions, header, outletRoles } = this.state;
    let paramsOutlet = {
      outletIds: auth.userOutletModelList.map((data) => (
        data.outletId
      ))
    };
    //ร้านค้า Outlet
    await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet).then(res => {
      let results = get(res, 'data.results');
      //console.log("results==>", results);
      outletOptions = results.map(data => ({
        label: `${data.outletId} - ${data.outletName}`,
        value: data.outletId,
      }));

      outletRoles = results.map(data => ({
        outletId: data.outletId,
        outletRole: data.outletRole
      }));

      if (!isEmpty(outletOptions) && outletOptions.length > 0) {
        const defaultOutlet = first(outletOptions);
        header = {
          ...header,
          outletId: defaultOutlet.value,
        }
      }
      //console.log("outletOptions==>", outletOptions);
      this.setState({ outletOptions, header, outletRoles });
    }).catch(error => {
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลร้านค้า!')
      swal('Error', message, 'error')
    });
  }

  fetchDataDefaultStoreLocation = async () => {
    let { header, defaultStore } = this.state;

    const paramsStoreLocation = `?ConditionName=GR&Condition1=${header.outletId}&Condition2=SLOC`;
    //Default store location
    await apiServices.callApi('get', `${API_PATH.GET_MASTER_SPECIAL_CONDITION}/${paramsStoreLocation}`, '', '').then(res => {
      let results = get(res, 'data.results');

      if (!isEmpty(results) && results.length > 0) {
        //console.log("paramsStoreLocation results ===>", first(results));
        defaultStore = first(results).value;
      }

      this.setState({ defaultStore });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "ที่เก็บ(Default Store Location)"!')
      swal('Error', message, 'error');
    });
  }

  fetchDataStorelocation = async (productId, salesUnit, outletId) => {
    const { auth } = this.props
    let params = {
      productId: productId,
      salesUnit: salesUnit,
      outletId: outletId,
      storageLocationIds: auth.arrayStorageLocationId,
      productStatus: true
    }
    //console.log("API params==>", params);
    this.setState({ loading: true });
    //Store location.
    await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, params).then(res => {
      const results = get(res, 'data.results');
      //console.log("results==>", results);
      if (isEmpty(results)) {
        swal('Error', `ไม่พบรายการสินค้า ${params.productId} (${params.salesUnit}) ร้าน ${params.outletId}`, 'error')
      }
      const dataStorages = results.map((data, i) => (
        {
          key: i,
          no: i + 1,
          ...data
        }
      ))
      this.setState({ dataStorages, params });

    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล Store location!')
      swal('Error', message, 'error')
    });

    this.setState({ loading: false });
  }

  handleChangeHeader = (key, val) => {
    const { header } = this.state
    this.setState({ header: { ...header, [key]: val } });
  }

  handleChangeItem = (row, key, val) => {
    let { items } = this.state;
    console.log(`row==>${row}, key==>${key}, val==>${val}`);
    console.log(`Befor items[${row}]`, items[row]);
    items[row] = {
      ...items[row],
      [key]: val
    };
    console.log(`After items[${row}]`, items[row]);
    this.setState({ items });
  }

  handleChangeFilter = (key, val) => {
    const { filter } = this.state
    this.setState({ filter: { ...filter, [key]: val } });
  }

  handleShowModal = async (modalType, dataSelected) => {
    let { header } = this.state
    switch (modalType) {
      case "storageLocation":
        console.log("dataSelected==>", dataSelected);

        this.setState({ selectedItemRow: dataSelected.row }, () =>
          this.fetchDataStorelocation(
            get(dataSelected, 'full.material'),
            get(dataSelected, 'full.pO_UNIT'),
            get(header, 'outletId')
          )
        );
        break;
      default:
        break;
    }

    this.setState({ modalType, dataSelected, isShowModal: true, });
  }

  handleCloseModal = () => {
    this.setState({ modalType: '', dataSelected: '', isShowModal: false });
  }

  onSelectedStoreLocation = (selected) => {
    this.changeStoreLocation(selected.rowSelected, selected.dataSelected.storageLocationId);
    this.handleCloseModal();
  }

  onSelectedProductStock = async (selected) => {
    this.setState({ loading: true });
    this.handleCloseModal();
    await this.setProductStockItem([selected]);
    this.setState({ loading: false, });

  }

  setProductStockItem = async (products) => {
    let { items } = this.state;
    items.splice(items.length - 1, 1);
    let allProductStockBalance = await this.getAllProductStockBalance(products);
    if (allProductStockBalance && allProductStockBalance.length > 0) {
      products.forEach(product => {
        product = {
          ...product,
          key: items.length,
          erfmg: product.erfmg || 0,
          erfme: product.erfme || product.eX_DISPLAY_UNIT,
          insmk: product.insmk || '',
          stockBalances: allProductStockBalance.filter(item => item.matnr === product.matnr),
        };
        items.push(product);
      });
    } else {
      products.forEach(product => {
        product = {
          ...product,
          key: items.length,
          erfmg: product.erfmg || 0,
          erfme: product.erfme || product.eX_DISPLAY_UNIT,
          insmk: product.insmk || '',
          stockBalances: [],
        };
        items.push(product);
      });
    }
    items.push(tempProduct);
    this.setState({ items, isDisabled: true });
  }

  getAllProductStockBalance = async (products) => {
    const { header } = this.state;
    const { auth } = this.props;
    //GroupBy = 'L:แยกที่เก็บ'
    //DisplayUnit = 'B:หน่วยมาตรฐาน'
    const param = products.map(product => (`ProductCodes=${product.matnr}`)).join('&');
    const storeLocationQueryString = auth.arrayStorageLocationId.map(slocId => `Storelocations=${slocId}`).join('&')
    const url = `${API_PATH.GET_DAILY_STOCK_BALANCE_LIST}/?Outlet=${header.outletId}&${param}&GroupBy=L&DisplayUnit=B&${storeLocationQueryString}`
    await apiServices.callApi('get', url, '', '').then(res => {
      products = get(res, 'data.result.eX_STOCK_BAL.item', []);
    }).catch(error => {
      const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
      swal('Error', message, 'error')
      console.error(get(error, 'response.data.errors'))
    });
    return products;
  }

  onSearchProduct = async () => {
    this.setState({ loading: true })
    const { filter, header } = this.state;
    const { auth } = this.props

    //GroupBy = 'L:แยกที่เก็บ'
    //DisplayUnit = 'B:หน่วยมาตรฐาน'

    const storeLocationQueryString = auth.arrayStorageLocationId.map(slocId => `Storelocations=${slocId}`).join('&')
    const url = `${API_PATH.GET_DAILY_STOCK_BALANCE_LIST}/?ProductCode=${filter.productId}&Outlet=${header.outletId}&GroupBy=L&DisplayUnit=B&${storeLocationQueryString}`

    await apiServices.callApi('get', url, '', '').then(async res => {
      const products = get(res, 'data.result.eX_STOCK_BAL.item');
      if (products && products.length > 0) {
        await this.setProductStockItem([first(products)]);
      }
    }).catch(error => {
      const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
      swal('Error', message, 'error')
      console.error(get(error, 'response.data.errors'))
    }).finally(() => {
      this.setState({ loading: false, filter: { productId: '' } })
    })
  }

  changeStoreLocation = (row, storeid) => {
    let { items } = this.state;
    items[row] = {
      ...items[row],
      stgE_LOC: storeid
    };
    this.setState({ items });
  }

  handleSubmitSearchMatDocSAPPI = async (e) => {
    e.preventDefault();
    let { header, defaultStore } = this.state;

    if (isEmpty(header.matDoc)) {
      message.error('ไม่พบข้อมูลหมายเลขเอกสารรับสินค้า');
      return;
    }

    if (isEmpty(header.matDocYear)) {
      message.error('ไม่พบข้อมูลปีของเอกสาร');
      return;
    }

    this.setState({ loading: true });

    //Get PO Detail form SAP.
    // const urlGetMatDocSAP = `${API_PATH.POST_GET_DAILY_MATERIAL_MOVEMENT}?MatDoc=${header.matDoc}&MatDocYear=${header.matDocYear}&Outlet=${header.outletId}`;
    const urlGetMatDocSAP = API_PATH.POST_GET_DAILY_MATERIAL_MOVEMENT
    const body = {
      matDoc: header.matDoc,
      matDocYear: header.matDocYear,
      outlet: header.outletId,
    }
    await apiServices.callApi('post', urlGetMatDocSAP, body).then(async res => {
      let result = get(res, 'data.result');
      console.log(`GetMatDocDetail result ==>`, result);
      const remarkItems = get(result, 'eT_REMARK.item', []);
      const matDocItems = get(result, 'eT_MAT_MOVEMENT.item', []);

      if (matDocItems.length === 0) {
        swal('Error', `ไม่พบเอกสารรับสินค้าเลขที่: ${header.matDoc} ปี: ${header.matDocYear}`, 'error');
        this.setState({ loading: false });
        return;
      }

      if (remarkItems.length > 0) {
        header = {
          ...header,
          remarks: remarkItems.map(remark => remark.tdline).join('\n'),
          refDocNo: first(matDocItems).xblnr,
        }
      }

      let dataItems = matDocItems.map((data, i) => ({
        key: i,
        row: i,
        ...data,
        maktg: data.maktg || data.maktx,
        lgort: data.lgort || defaultStore,
      }));

      await this.setProductStockItem(dataItems);
      this.setState({ header, loading: false, isFoundMatDoc: true, isDisabled: true });
    }).catch(error => {
      console.log(`error catch ==>`, error);
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างค้นหาข้อมูลใบสั่งซื้อ!')
      swal('Error', message, 'error');
      this.setState({ loading: false });
    });
  }

  handleSubmitGoodsReceive = async () => {
    const { auth } = this.props;
    let { modelGoodsReceiveRequest, header, items, tableColumns, selectedRowKeys, } = this.state;

    let products = items.filter(item => !isEmpty(item.matnr))

    if (!this.validateCreateGR(products)) {
      return;
    }

    if (tableColumns.isSelection === true) {
      products = products.filter(i => selectedRowKeys.includes(i.key));
    }

    this.setState({ loading: true });

    const im_GOODSMVT_HEADER = {
      PSTNG_DATE: moment().format('YYYY-MM-DD'),
      DOC_DATE: header.refDocNo !== undefined && moment().format('YYYY-MM-DD'),
      REF_DOC_NO: header.refDocNo,
      WEB_USER: auth.userId,
    };

    const it_REMARK = [{
      TDLINE: header.remarks,
    }]
    console.log("products==>", products);
    const it_GOODSMVT_ITEM = products.map((item) => ({
      MATERIAL: item.matnr,
      PLANT: item.werks,
      STGE_LOC: item.lgort,
      ENTRY_UOM: item.erfme,
      ENTRY_QNT: item.erfmg,
      AMOUNT_LC: item.dmbtr,
      STCK_TYPE: item.insmk,
    }));

    modelGoodsReceiveRequest = {
      ...modelGoodsReceiveRequest,
      IM_MODE: header.mode,
      IM_MOVE_TYPE: header.moveType,
      IM_TYPE_REF_DOC: header.typeRefDoc,

      IM_CC_MATDOC: header.matDoc,
      IM_CC_DOCYEAR: header.matDocYear,

      IM_GOODSMVT_HEADER: im_GOODSMVT_HEADER,
      IT_GOODSMVT_ITEM: {
        item: it_GOODSMVT_ITEM
      },
      IT_REMARK: {
        item: it_REMARK
      }
    };
    let modelGoodsReceiveRequests = {
      goodsMovementRequest: modelGoodsReceiveRequest
    };
    console.log('Object modelGoodsReceiveRequest==>', modelGoodsReceiveRequest);
    console.log('String modelGoodsReceiveRequest==>', JSON.stringify(modelGoodsReceiveRequest));

    await apiServices.callApi('post', API_PATH.POST_GOODS_MOVEMENT, modelGoodsReceiveRequests).then(res => {
      let result = get(res, 'data.result');
      let message = get(res, 'data.message');
      swal("Success", `สร้างข้อมูลการรับสินค้า(GR without ref): ${result.eX_MATDOC} ปี(${result.eX_DOCYEAR}) ${message}`, "success");
      this.clearState();
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูลการรับสินค้า(GR without ref)!')
      swal('Error', message, 'error');
    });
    this.setState({ loading: false });
  }

  validateCreateGR = (grItems) => {
    const { header, tableColumns, selectedRowKeys, isFoundMatDoc } = this.state;

    if (header.docType === "502" && (isFoundMatDoc === false || !header.matDoc)) {
      swal('Error', `กรุณาระบุข้อมูลเอกสารการรับสินค้า!`, 'error');
      return;
    }

    if (!header.refDocNo) {
      swal('Error', `กรุณาระบุเอกสารบันทึกภายใน!`, 'error');
      return false;
    }

    if (isEmpty(grItems) || grItems.length === 0) {
      swal('Error', `กรุณาระบุรายการสินค้า!`, 'error');
      return false;
    }

    if (isEmpty(grItems) || grItems.length === 0) {
      swal('Error', `กรุณาระบุรายการสินค้า!`, 'error');
      return false;
    }

    if (tableColumns.isSelection === true && (!selectedRowKeys || selectedRowKeys.length === 0)) {
      swal('Error', `กรุณาระบุเลือกรายการรับเข้า!`, 'error');
      return false;
    }

    if (tableColumns.isSelection === true) {
      grItems = grItems.filter(i => selectedRowKeys.includes(i.key));
    }

    const wrongItems = grItems.filter(item => Number(item.erfmg) <= 0);

    if (!isEmpty(wrongItems) && wrongItems.length > 0) {
      swal('Error', `กรุณาระบุจำนวนรับ รายการ ${first(wrongItems).matnr}: ${first(wrongItems).maktg}!`, 'error');
      return false;
    }

    return true
  }

  changeTransactionType = (val) => {
    let { header } = this.state;
    this.clearState();

    if (isEmpty(val)) {
      this.setState({ header: { ...header, docType: '' } });
      return;
    }

    header = transactionTypeSpec[val].header;
    header = {
      ...header,
      docType: val,
      docDate: moment(),
    };

    this.setState({
      header,
      tableColumns: transactionTypeSpec[val].tableColumns
    }, () => {
      this.setColumns()
    })
  }

  onSelectChange = (selectedRowKeys, selectedRowData) => {
    this.setState({ selectedRowKeys, selectedRowData });
  }

  render() {
    const {
      loading,
      dateFormat,
      isShowModal,
      isDisabled,
      transactionTypes,

      header,
      items,
      columns,
      outletOptions,
      modalType,
      dataStorages,
      selectedItemRow,
      tableColumns,
      selectedRowKeys,
      permissionPage,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => ({
        disabled: isEmpty(record.matnr),
        // Column configuration not to be checked
        matnr: record.matnr,
      }),
    }

    return (
      <div className="goods-receive-without-ref-container">
        <Spin spinning={loading}>
          <TitleHead text="รายการรับเข้าสินค้า(GR Without Ref)" icon="download" />
          <div className="middle-content">
            <Row gutter={20}>
              <Col md={12}>
                <SelectCustom
                  label='ประเภทเอกสาร'
                  options={transactionTypes}
                  value={header.docType}
                  onChange={(value) => this.changeTransactionType(value)}
                />
              </Col>

              <Col md={12}>
                <SelectCustom
                  label='ร้าน*'
                  options={outletOptions}
                  pleaseChoose
                  onChange={(value) => this.handleChangeHeader('outletId', value)}
                  value={header.outletId}
                  name="outletId"
                  disabled={isDisabled}
                />
              </Col>
            </Row>

            {header.matDoc !== undefined && header.matDocYear !== undefined &&
              < Row gutter={20}>
                <Col md={12}>
                  <InputCustom
                    label='เอกสารรับสินค้า'
                    name='matDoc'
                    placeholder="1000000146"
                    value={header.matDoc}
                    onChange={(e) => this.handleChangeHeader('matDoc', e.target.value)}
                  //disabled={isDisabled}
                  />
                </Col>

                <Col md={9}>
                  <InputCustom
                    label='ปีของเอกสาร'
                    name='matDocYear'
                    placeholder={moment().format('YYYY')}
                    value={header.matDocYear}
                    onChange={(e) => this.handleChangeHeader('matDocYear', e.target.value)}
                  />
                </Col>
                <Col sm={3} className="search-box">
                  <ButtonCustom
                    icon="search"
                    type="primary"
                    text="ค้นหา"
                    small
                    onClick={this.handleSubmitSearchMatDocSAPPI}
                  />
                </Col>
              </Row>
            }

            <Row gutter={20}>
              <Col md={12}>
                <InputCustom
                  label='เอกสารบันทึกภายใน'
                  name='refDocNo'
                  placeholder="2000001982"
                  value={header.refDocNo}
                  onChange={(e) => this.handleChangeHeader('refDocNo', e.target.value)}
                />
              </Col>

              <Col md={12}>
                <DatePickerCustom
                  label='วันที่ออกเอกสาร'
                  name="docDate"
                  value={header.docDate}
                  format={dateFormat}
                  allowClear={false}
                  disabled={true}
                  onChange={(date) => this.handleChangeHeader('docDate', date)}
                />
              </Col>
            </Row>

            <Row gutter={20}>
              <Col md={24}>
                <TextAreaCustom
                  label='หมายเหตุ'
                  name="remarks"
                  value={header.remarks}
                  onChange={(e) => this.handleChangeHeader('remarks', e.target.value)}
                />
              </Col>
            </Row>

            <div className="table-layout">
              <TableCustom
                columns={columns}
                scroll={{ x: "max-content" }}
                data={items}
                rowKey={(row, index) => index}
                small
                rowSelection={tableColumns.isSelection === true ? rowSelection : undefined}
              />
            </div>
          </div>

          <div className="bottom-content">
            <div className="left-zone">
              <Link to={ROUTH_PATH.HOME} className="button">
                <ButtonCustom text='ยกเลิก' icon='close' type='danger' />
              </Link>
              <div className="button">
                <ButtonCustom text='ล้าง' icon='redo' type='danger' onClick={this.clearState} />
              </div>
            </div>
            <div className="right-zone">
              <div className="button">
                <ButtonCustom
                  text='บันทึก'
                  icon='save'
                  type="primary"
                  onClick={this.handleSubmitGoodsReceive}
                  disabled={!isDisabled || items.length === 1 || isEmpty(header.docType) || !permissionPage.authCreate}
                />
              </div>
            </div>
          </div>
        </Spin>

        <StoreLocationModal
          isShowModal={modalType === 'storageLocation' && isShowModal}
          handleCloseModal={this.handleCloseModal}
          dataStorages={dataStorages}
          onSelected={this.onSelectedStoreLocation}
          rowSelected={selectedItemRow}
          loading={loading}
        />

        <ProductStockSearchModal
          isShowModal={modalType === 'productStock' && isShowModal}
          onCloseModal={this.handleCloseModal}
          onSelected={this.onSelectedProductStock}
          defaultOutlet={header.outletId}
        />
      </div >
    );
  }
}


const mapStateToProps = state => {
  // console.log("state===>", state);
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    authData
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodsReceiveWithoutRefContainer);