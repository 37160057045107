import React, { Component } from 'react'
import { Input } from 'antd'
import { TextAreaCustomStyled } from './styled'

export class TextAreaCustom extends Component {
    render() {
        const {
            label,
            labelAlign,
            widthLabel,
            small,
            input,
            onChange,
            disabled,
            rows,
            errorMsg,
            meta = {},
            ...rest
        } = this.props
        const { touched, error } = meta

        // touched && error from redux form
        // errorMsg from props
        const isError = (touched && error) || errorMsg

        return (
            <TextAreaCustomStyled
                isError={isError}
                small={small}
                label={label}
                widthLabel={widthLabel}
                labelAlign={labelAlign}
            >
                <div className="input-form form-textarea-custom">
                    <div className="input-zone-label">
                        {
                            label && <div className="label-style">
                                {label}
                            </div>
                        }
                        <Input.TextArea
                            {...input}
                            {...rest}
                            className="input-style textarea-custom"
                            disabled={disabled}
                            rows={rows || 4}
                            onChange={(e) => {
                                onChange && onChange(e)
                                input && input.onChange(e)
                            }}
                        />
                    </div>
                    {
                        isError && <div className="error-message-bottom">
                            {error || errorMsg}
                        </div>
                    }
                </div>
            </TextAreaCustomStyled>
        )
    }
}
