import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { isEmpty } from 'lodash'
import { Row, Col } from 'antd'
import {
    InputSearchCustom,
    InputCustom,
    ButtonCustom,
    DatePickerCustom,
    SelectCustom,
} from 'components/common'
import './style.css'
import { VALIDATE } from 'helpers'

const widthLabel = '110px'

class CnSaleInfoFormRedux extends Component {
    state = {
        referenceDocNumber: '',
    }

    changeReferenceDocNumber = (value) => {
        const { change } = this.props
        change('referenceDocNumber', value)
        this.setState({ referenceDocNumber: value })
    }

    render() {
        const {
            isErrorSubmit,
            isEditMode,
            initialValues,
            outlets,
            documentTypes,
            taxTypes,
            saleChannels,
            saleEmployees,
            returnTypes,
            returnReasons,
            documentStatuses,
            billingDetail,
            changeSaleInfo,
            searchReferenceDocNumber,
            permissionPage,
            onOpenModalReferenceDocNumber,
            isMoreRef,
            cnDetail,
            isStatusCancelAll,
            isWithoutBillingRef,
        } = this.props
        const { referenceDocNumber } = this.state
        const hasBillingDetail = !isEmpty(billingDetail)
        const docStatus = isEditMode && cnDetail.documentStatus.toLowerCase()
        const disabledDocStatus = !isEditMode || !permissionPage.authApprove
            || docStatus === 'reject' || docStatus === 'complete' || docStatus === 'approve'
            || isStatusCancelAll

        return (
            <Row style={{ marginTop: '10px' }}>
                <Col md={24}>
                    <div className="info-form-blue-border">
                        <div className="title">
                            ข้อมูลการขาย
                        </div>
                        <Row gutter={10}>
                            <Col sm={24} md={8}>
                                {isMoreRef ? <div style={{ fontSize: '12px' }} className="input-form">
                                    <div className="input-zone-label">
                                        <div className="label-style" style={{ width: widthLabel }}>อ้างอิงใบวางบิล*</div>
                                        <ButtonCustom
                                            type="link"
                                            small
                                            text="อ้างอิงมากกว่า 1 ใบ"
                                            onClick={onOpenModalReferenceDocNumber}
                                        />
                                    </div>
                                </div> : <InputSearchCustom
                                        label="อ้างอิงใบวางบิล*"
                                        onClickSearch={() => searchReferenceDocNumber(referenceDocNumber)}
                                        onSubmit={() => searchReferenceDocNumber(referenceDocNumber)}
                                        onChange={e => this.changeReferenceDocNumber(e.target.value)}
                                        small
                                        type="number"
                                        widthLabel={widthLabel}
                                        disabled={isEditMode || hasBillingDetail}
                                        disabledButton={isEditMode || hasBillingDetail}
                                        value={hasBillingDetail ? initialValues.referenceDocNumber : referenceDocNumber}
                                    />}
                                <div className="d-none">
                                    <Field
                                        component={InputCustom}
                                        name="referenceDocNumber"
                                        widthLabel={widthLabel}
                                    />
                                </div>
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={DatePickerCustom}
                                    label="วันที่รับคืน"
                                    name="documentDate"
                                    widthLabel={widthLabel}
                                    small
                                    disabled
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="รหัสพนักงาน*"
                                    name="saleEmpCode"
                                    widthLabel={widthLabel}
                                    small
                                    options={saleEmployees}
                                    validate={VALIDATE.REQUIRE}
                                    isError={isErrorSubmit}
                                    placeholder="กรุณาเลือก"
                                    disabled={isEditMode}
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="ประเภทภาษี"
                                    name="taxType"
                                    widthLabel={widthLabel}
                                    small
                                    options={taxTypes}
                                    placeholder="กรุณาเลือก"
                                    disabled={isEditMode || hasBillingDetail}
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="ประเภทเอกสาร"
                                    name="documentTypeCode"
                                    widthLabel={widthLabel}
                                    small
                                    disabled={isEditMode || hasBillingDetail}
                                    options={documentTypes}
                                    onChange={(val) => changeSaleInfo('documentTypeCode', val)}
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="ร้าน"
                                    name="outletId"
                                    widthLabel={widthLabel}
                                    small
                                    options={outlets}
                                    disabled={isEditMode || hasBillingDetail}
                                    onChange={(val) => changeSaleInfo('outletId', val)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="สาเหตุลดหนี้*"
                                    name="returnReasonId"
                                    widthLabel={widthLabel}
                                    small
                                    options={returnReasons}
                                    validate={VALIDATE.REQUIRE}
                                    isError={isErrorSubmit}
                                    placeholder="กรุณาเลือก"
                                    disabled={isEditMode}
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="ช่องทาง"
                                    name="saleChannelCode"
                                    widthLabel={widthLabel}
                                    small
                                    options={saleChannels}
                                    disabled={!isWithoutBillingRef || isEditMode}
                                    onChange={(val) => changeSaleInfo('saleChannelCode', val)}
                                />
                            </Col>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="สถานะ"
                                    name="documentStatus"
                                    widthLabel={widthLabel}
                                    small
                                    options={documentStatuses}
                                    disabled={disabledDocStatus}
                                    onChange={(val) => changeSaleInfo('documentStatus', val)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col sm={24} md={8}>
                                <Field
                                    component={SelectCustom}
                                    label="ประเภทการคืนเงิน*"
                                    name="returnTypeId"
                                    widthLabel={widthLabel}
                                    small
                                    options={returnTypes}
                                    validate={VALIDATE.REQUIRE}
                                    isError={isErrorSubmit}
                                    placeholder="กรุณาเลือก"
                                    disabled={isEditMode}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row >
        )
    }
}

export default reduxForm({
    form: 'CnSaleInfoFormRedux',
    enableReinitialize: true,
})(CnSaleInfoFormRedux)
