import styled from 'styled-components'

export const TableCustomStyled = styled.div`
    .table-custom {
        .ant-table-content {
            height: ${props => props.height ? props.height : ''};
            overflow: auto;
            font-size: ${props => props.small ? '12px' : ''};
        }

        .ant-empty-normal {
            margin: 0px;
        }

        .table-striped-rows tr:nth-child(2n) td {
            background-color: #fbfbfb;
        }

        .table-striped-rows thead {
            background-color: #f1f1f1;
        }
    }
`