import React, { Component } from 'react'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
} from 'components'
import { Row, Col, Input } from 'antd'
import './style.css'
import { apiServices, API_PATH } from 'apiServices'
import { get, isEmpty } from 'lodash'

export class ModalSaleUnit extends Component {
    state = {
        thisloading: false,
        dataTableProduct: [],
        pagination: {},
        isFetchDataSaleUnit: false,

        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: 'no',
                key: 'no',
                ellipsis: true,
                render: (value, full) => <div style={{ textAlign: 'center' }}>
                    <ButtonCustom onClick={() => this.props.onSelected(full)} small text="เลือก" />
                </div>
            },
            {
                title: 'จำนวน',
                align: 'center',
                dataIndex: 'numerator',
                key: 'numerator',
                ellipsis: true,
            },
            {
                title: 'หน่วยนับ',
                align: 'center',
                dataIndex: 'unitId',
                key: 'unitId',
                ellipsis: true,
            },
            {
                title: 'สัดส่วน/หน่วยนับ',
                align: 'center',
                dataIndex: 'denominator',
                key: 'denominator',
                ellipsis: true,
            },
            {
                title: 'หน่วยนับ',
                align: 'center',
                dataIndex: 'baseUnit',
                key: 'baseUnit',
                ellipsis: true,
            },
            {
                title: 'ราคา/หน่วย',
                align: 'center',
                dataIndex: 'pricePerUnit',
                key: 'pricePerUnit',
                ellipsis: true,
            },
        ],

        keyword: '',
        keywordType: '',
    }

    fetchDataSaleUnit = async (productId) => {
        this.setState({thisloading: true, isFetchDataSaleUnit: true, dataTableProduct: [], lastProductId: productId});
        let params = {
            productId
        }
        //console.log("params==>", params);
        let resSaleUnit = await apiServices.callApi("post", API_PATH.POST_GET_PRODUCT_UNIT, params)
        console.log("resSaleUnit==>", resSaleUnit);
        if (resSaleUnit.status === 200) {
            let dataSaleUnit = get(resSaleUnit, 'data.results');
            //console.log("dataSaleUnit==>", dataSaleUnit);
            dataSaleUnit = dataSaleUnit.map((data, i) => (
                {
                    key: i,
                    no: i + 1,
                    ...data
                }
            ));
            this.setState({dataSaleUnit});
        }
        this.setState({thisloading: false});
    }
    render() {
        const {
            isShowModal,
            handleCloseModal,
            title,
            width,
            loading,
            className,
            productId,
        } = this.props
        const {
            columns,
            thisloading,
            dataSaleUnit,
            isFetchDataSaleUnit,
            lastProductId
        } = this.state

        if(lastProductId != productId && productId){
            this.setState({isFetchDataSaleUnit : false});
        }
        if(!isFetchDataSaleUnit && productId){
            this.fetchDataSaleUnit(productId);
        }

        return (
            <ModalCustom
                isShowModal={isShowModal}
                handleCloseModal={handleCloseModal}
                title={title || 'ค้นหาสินค้า'}
                width={width || '60%'}
                className={`modal-data ${className}`}
            >
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 511px)"
                            small
                            columns={columns}
                            data={dataSaleUnit}
                            loading={loading || thisloading}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
