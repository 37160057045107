import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './reducers/MainReducer'
import { BrowserRouter as Router } from "react-router-dom"
import 'antd/dist/antd.css'
import './index.css';

const renderApp = () => (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Router>
                <App />
            </Router>
        </PersistGate>
    </Provider>
);

const rootElement = document.querySelector("#root");

if (rootElement) {
    ReactDOM.render(renderApp(), rootElement);
}