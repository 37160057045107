import React, { Component } from 'react'
import { Row, Col, Spin } from 'antd'
import './style.css'
import moment from 'moment'
import { apiServices, API_PATH } from "apiServices";
import swal from '@sweetalert/with-react';
import { get, first, isEmpty } from 'lodash';
import {
  ModalCustom,
  InputCustom,
  TableCustom,
  ButtonCustom,
  DatePickerCustom,
} from "components/common"

class PurchaseOrderModal extends Component {
  state = {
    loading: false,
    dateFormat: 'DD/MM/YYYY',

    filter: {
      docDateFrom: moment(),
      docDateTo: moment(),
      venderNo: '',
      venderDocNo: '',
      productBarcode: '',
      productCode: '',
      productName: '',
    },
    dataPOItems: [],
    dataPOHeaders: [],
    dataPOHDTxts: [],
    dataPOAccounts: [],

    columns: [
      {
        title: '#',
        align: 'center',
        dataIndex: '#',
        key: '#',
        width: 70,
        render: (value, full, index) =>
          full.row > 0 && this.state.dataPOItems[full.row - 1].pO_NUMBER === full.pO_NUMBER ? '' : <ButtonCustom small onClick={() => this.props.onSelected(this.findPOHeader(full))} text="เลือก" />

      },
      {
        title: 'วันที่เอกสาร',
        align: 'center',
        dataIndex: 'docDate',
        key: 'docDate',
        width: 90,
        render: (value, full, index) =>
          full.row > 0 && this.state.dataPOItems[full.row - 1].pO_NUMBER === full.pO_NUMBER ? '' : moment(full.eT_PO_HEADER.doC_DATE).format('DD/MM/YYYY')
      },
      {
        title: 'ใบสั่งซื้อ',
        align: 'center',
        dataIndex: 'pO_NUMBER',
        key: 'pO_NUMBER',
        width: 100,
        render: (value, full, index) =>
          full.row > 0 && this.state.dataPOItems[full.row - 1].pO_NUMBER === full.pO_NUMBER ? '' : full.pO_NUMBER
      },
      {
        title: 'เอกสารผู้ขาย',
        align: 'center',
        dataIndex: 'cOLLECT_NO',
        key: 'cOLLECT_NO',
        width: 80,
        render: (value, full, index) =>
          full.eT_PO_HEADER.cOLLECT_NO
      },
      {
        title: 'ผู้ขาย',
        align: 'left',
        dataIndex: 'vendName',
        key: 'vendName',
        width: 120,
        render: (value, full, index) =>
          full.eT_PO_HEADER.venD_NAME
      },
      {
        title: 'รหัสสินค้า',
        align: 'center',
        dataIndex: 'material',
        key: 'material',
        width: 80,
      },
      {
        title: 'ชื่อสินค้า',
        align: 'left',
        dataIndex: 'materiaL_TX',//'shorT_TEXT',
        key: 'materiaL_TX',//'shorT_TEXT',
        width: 150,
      },
      {
        title: 'จำนวนที่สั่ง',
        align: 'right',
        dataIndex: 'quantitY_ORD',
        key: 'quantitY_ORD',
        width: 80,
      },
      {
        title: 'จำนวนที่เหลือ',
        align: 'right',
        dataIndex: 'quantitY03',
        key: 'quantitY03',
        width: 80,
      },
      {
        title: 'หน่วย',
        align: 'center',
        dataIndex: 'pO_UNIT',
        key: 'pO_UNIT',
        width: 70,
      },
    ],
  }

  findPOHeader(poItem) {
    const { dataPOHeaders, dataPOHDTxts, dataPOAccounts } = this.state;
    let poHeader = {};
    if (!isEmpty(dataPOHeaders) && dataPOHeaders.length > 0) {
      const poHeaders = dataPOHeaders.filter(poHeader => poHeader.pO_NUMBER === poItem.pO_NUMBER);
      poHeader = first(poHeaders);
    }

    if (!isEmpty(dataPOHDTxts) && dataPOHDTxts.length > 0) {
      const poHDTxts = dataPOHDTxts.filter(hdTxt => hdTxt.pO_NUMBER === poItem.pO_NUMBER);
      const poHDTxt = first(poHDTxts);
      poHeader = { ...poHeader, eT_PO_HDTXT: poHDTxt };
    }

    if (!isEmpty(dataPOAccounts) && dataPOAccounts.length > 0) {
      const poAccounts = dataPOAccounts.filter(poHeader => poHeader.pO_NUMBER === poItem.pO_NUMBER);
      const poAccount = first(poAccounts);
      poHeader = { ...poHeader, eT_PO_ACCOUNT: poAccount };
    }

    console.log("poHeader===>", poHeader);
    return poHeader;
  }

  handleChangeHeader = (key, val) => {
    console.log(`key: ${key}, val: ${val}`);
    const { filter } = this.state;
    this.setState({ filter: { ...filter, [key]: val } });
  }

  searchPurchaseOrderSAPPI = async () => {
    const { filter } = this.state;
    const { outletId, docType, docTypeSing } = this.props;
    this.setState({ loading: true });

    //Get PO Detail form SAP.
    const urlGetPODetailSAP = `${API_PATH.GET_PO_AND_STO_DETAIL}?DocDateFrom=${moment(filter.docDateFrom).format('YYYY-MM-DD')}&DocDateTo=${moment(filter.docDateTo).format('YYYY-MM-DD')}&VenderNo=${filter.venderNo}&VenderDocNo=${filter.venderDocNo}&ProductBarcode=${filter.productBarcode}&ProductCode=${filter.productCode}&ProductName=${filter.productName}&Outlet=${outletId}&IsReleaseIndicator=${true}&DocType=${docType || ''}&DocTypeSing=${docTypeSing || ''}&OutletSelName=S_WERKS`;
    console.log(`urlGetPODetailSAP==> ${urlGetPODetailSAP}`);
    await apiServices.callApi('get', urlGetPODetailSAP).then(res => {
      let result = get(res, 'data.result');
      console.log(`GetPODetail result ==>`, result);
      let poHeaders = get(result, 'eT_PO_HEADER_REPORT.item');
      let poItems = get(result, 'eT_PO_ITEM_REPORT.item');
      let dataPOHDTxts = get(result, 'eT_PO_HDTXT.item');
      let dataPOAccounts = get(result, 'eT_PO_ACCOUNT.item');

      let dataPOItems = poItems.map((data, i) => ({
        key: i,
        row: i,
        ...data
      }));

      let dataPOHeaders = poHeaders.map((poHeader, i) => ({
        key: i,
        ...poHeader,
        eT_PO_ITEM: poHeader.eT_PO_ITEM.map((poItem, i) => ({
          ...poItem,
          stockType: '',
          receivedQty: poItem.stO_GI - poItem.quantitY03,
          sdDoc: !isEmpty(dataPOAccounts) && dataPOAccounts.length > 0 ? this.checkSoSpecialOrder(poItem, dataPOAccounts) : ''
        }))
      }));

      //console.log(`dataPOItems ==>`,dataPOItems);
      this.setState({ dataPOItems, dataPOHeaders, dataPOHDTxts, dataPOAccounts });
    }).catch(error => {
      console.log(`error catch ==>`, error);
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างค้นหาข้อมูลใบสั่งซื้อ!')
      swal('Error', message, 'error')
    });
    this.setState({ loading: false });
  }

  checkSoSpecialOrder = (dataPOItem, dataPOAccounts) => {
    let sdDoc = '';
    console.log(`dataPOItem ==>`, dataPOItem);
    console.log(`dataPOAccounts ==>`, dataPOAccounts);
    const dataPOAccount = dataPOAccounts.filter(poAccount => poAccount.pO_NUMBER === dataPOItem.pO_NUMBER && poAccount.pO_ITEM === dataPOItem.pO_ITEM);
    if (!isEmpty(dataPOAccount) && dataPOAccount.length > 0)
      sdDoc = first(dataPOAccount).SD_DOC;
    return sdDoc;
  }

  render() {
    const {
      isShowModal,
      handleCloseModal,
      title,
      width,
      className,
    } = this.props
    const {
      loading,
      dateFormat,
      filter,

      dataPOItems,
      columns,
    } = this.state

    return (
      <ModalCustom
        isShowModal={isShowModal}
        onCloseModal={handleCloseModal}
        title={title || 'ค้นรายการเอกสารใบรับสินค้า'}
        width={width || '70%'}
        className={`modal-data ${className}`}
        destroyOnClose={true}
      >
        <Spin spinning={loading}>
          <Row gutter={8}>
            <Col sm={8}>
              <DatePickerCustom
                label="วันที่เอกสาร"
                name="docDateFrom"
                widthLabel='100px'
                value={filter.docDateFrom}
                format={dateFormat}
                small
                onChange={(date) => this.handleChangeHeader('docDateFrom', date)}
              />
            </Col>
            <Col sm={8}>
              <DatePickerCustom
                label="ถึง"
                name="docDateTo"
                widthLabel='100px'
                value={filter.docDateTo}
                format={dateFormat}
                small
                onChange={(date) => this.handleChangeHeader('docDateTo', date)}
              />
            </Col>
            <Col sm={8}>
              <InputCustom
                label="รหัสผู้ขาย"
                name="venderNo"
                small
                widthLabel='100px'
                value={filter.venderNo}
                onChange={(e) => this.handleChangeHeader('venderNo', e.target.value)}
              />
            </Col>
          </Row>

          <Row gutter={8}>
            <Col sm={8}>
              <InputCustom
                label="เอกสารผู้ขาย"
                name="venderDocNo"
                small
                widthLabel='100px'
                value={filter.venderDocNo}
                onChange={(e) => this.handleChangeHeader('venderDocNo', e.target.value)}
              />
            </Col>
            <Col sm={8}>
              <InputCustom
                label="บาร์โค้ด"
                name="productBarcode"
                small
                widthLabel='100px'
                value={filter.productBarcode}
                onChange={(e) => this.handleChangeHeader('productBarcode', e.target.value)}
              />
            </Col>
            <Col sm={8}>
              <InputCustom
                label="รหัสสินค้า"
                name="productCode"
                small
                widthLabel='100px'
                value={filter.productCode}
                onChange={(e) => this.handleChangeHeader('productCode', e.target.value)}
              />
            </Col>
          </Row>

          <Row gutter={8}>
            <Col sm={8}>
              <InputCustom
                label="ชื่อสินค้า"
                name="productName"
                small
                widthLabel='100px'
                value={filter.productName}
                onChange={(e) => this.handleChangeHeader('productName', e.target.value)}
              />
            </Col>
            <Col sm={16} style={{ textAlign: 'right', paddingTop: '10px' }}>
              <ButtonCustom
                icon="search"
                type="primary"
                text="ค้นหา"
                small
                onClick={this.searchPurchaseOrderSAPPI} />
            </Col>
          </Row>

          <Row style={{ paddingTop: 20 }}>
            <Col sm={24}>
              <TableCustom
                columns={columns}
                data={dataPOItems}
                rowKey={(row, index) => index}
                scroll={{ x: "max-content", y: "calc(100vh - 400px)" }}
              />
            </Col>
          </Row>
        </Spin>
      </ModalCustom>
    )
  }
}

export default (PurchaseOrderModal);
