import React, { Component } from 'react'
import { uniqBy, isEmpty, get } from 'lodash'
import { authData } from 'actions/actionsCreator'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    SelectHero,
} from 'components'
import swal from '@sweetalert/with-react'
import { ProductKeyWordDropDown, sortDataColumn } from 'helpers'
import { Row, Col, Input } from 'antd'
import './style.css'
import { apiServices, API_PATH } from 'apiServices'
import { formatNumber } from 'helpers'

const { Search } = Input

class ModalProductSearch extends Component {
    state = {
        thisloading: false,
        dataTableProduct: [],
        pagination: {},
        columns: [
            {
                title: "#",
                align: 'center',
                dataIndex: '',
                key: 'htf',
                render: (props, row) => <div style={{ textAlign: 'center' }}>
                    <ButtonCustom
                        onClick={() =>
                            this.props.onSelected({
                                ...row,
                                price: (+this.props.channelCode === 20) ? row.projectPrice : row.retailPrice
                            })}
                        small
                        text="เลือก" />
                </div>
            },
            {
                title: "รหัสสินค้า",
                align: 'center',
                dataIndex: 'productId',
                key: 'productId',
                sorter: (a, b) => sortDataColumn(a, b, 'productId'),
                ellipsis: true,
            },
            {
                title: 'ชื่อสินค้า',
                align: 'center',
                dataIndex: 'productNameMarket',
                key: 'productNameMarket',
                sorter: (a, b) => sortDataColumn(a, b, 'productNameMarket'),
                ellipsis: true,
            },
            {
                title: 'คลัง',
                align: 'center',
                dataIndex: 'storageLocationId',
                key: 'storageLocationId',
                sorter: (a, b) => sortDataColumn(a, b, 'storageLocationId'),
                ellipsis: true,
            },
            {
                title: 'จำนวนที่มี',
                align: 'center',
                dataIndex: 'atp',
                key: 'atp',
                sorter: (a, b) => sortDataColumn(a, b, 'atp'),
                ellipsis: true,
                render: (props) => <div className="text-right">
                    {formatNumber(props)}
                </div>
            },
            {
                title: 'หน่วยนับ',
                align: 'center',
                dataIndex: 'baseUnit',
                key: 'baseUnit',
                sorter: (a, b) => sortDataColumn(a, b, 'baseUnit'),
                ellipsis: true,
            },
            {
                title: 'ราคา',
                align: 'center',
                dataIndex: 'price',
                key: 'price',
                sorter: (a, b) => sortDataColumn(a, b, 'price'),
                ellipsis: true,
                render: (props, row) => {
                    const price = (+this.props.channelCode === 20) ? row.projectPrice : row.retailPrice
                    return (price || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
            },
            {
                title: 'หน่วยขาย',
                align: 'center',
                dataIndex: 'salesUnit',
                key: 'salesUnit',
                sorter: (a, b) => sortDataColumn(a, b, 'salesUnit'),
                ellipsis: true,
            },
            {
                title: 'บาร์โค้ด',
                align: 'center',
                dataIndex: 'barcode',
                key: 'barcode',
                sorter: (a, b) => sortDataColumn(a, b, 'barcode'),
                ellipsis: true,
            },
            {
                title: 'Other Barcode',
                align: 'center',
                dataIndex: 'otherbarode',
                key: 'otherbarode',
                sorter: (a, b) => sortDataColumn(a, b, 'otherbarode'),
                ellipsis: true,
            },
        ],
        keyword: '',
        keywordType: 'productNameEng',
    }

    componentDidUpdate(prevProps) {
        if (this.props.outletId !== prevProps.outletId) {
            this.setState({ dataTableProduct: [] })
        }
    }

    changeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    changeKeywordType = (keywordType) => {
        this.setState({ keywordType })
    }

    onSearchProduct = async () => {
        this.setState({ thisloading: true });
        const { keywordType, keyword } = this.state
        const { withOutStorageLocation, outletId, userId, auth } = this.props

        const body = {
            productId: keywordType === 'productId' ? keyword : '',
            productNames: keywordType === 'productNameEng' ? keyword : '',
            barcode: keywordType === 'barcode' ? keyword : '',
            keywords: keyword,
            outletId,
            storageLocationIds: auth.arrayStorageLocationId,
            limitMaxRange: 100,
            productStatus: true
        }
        let products = []
        await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body).then(res => {
            products = get(res, 'data.results', [])
            if (res.status === 200) {
                if (withOutStorageLocation) {
                    products = products.filter((product, index) => (
                        index === products.findIndex(prd => prd.productId === product.productId && prd.salesUnit === product.salesUnit)
                    ))
                }
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูลสินค้า กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // get apt
        if (!isEmpty(products)) {
            const productsUniq = uniqBy(products, 'productId')
            const bodyATP = productsUniq.map(product => ({
                productCode: product.productId,
                outlet: outletId,
                storageLocationIds: auth.arrayStorageLocationId,
            }))
            await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT_ATP}?userId=${userId}`, bodyATP).then(res => {
                let productsATP = get(res, 'data.result.eT_DETAIL_OUT.item', [])
                if (res.status === 200 && !isEmpty(productsATP)) {
                    products = products.map(product => {
                        const data = productsATP.find(productATP =>
                            product.productId === productATP.materiaL_LONG
                            && product.storageLocationId === productATP.storE_LOC
                        )
                        return data && data.qty ? { ...product, atp: data.qty } : product
                    })
                }
            }).catch(error => {
                const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดในการดึงข้อมูลสินค้า ATP กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            })
        }
        this.setState({ thisloading: false, dataTableProduct: products })
    }

    render() {
        const {
            isShowModal,
            handleCloseModal,
            title,
            width,
            loading,
            className,
        } = this.props
        const {
            columns,
            keyword,
            keywordType,
            thisloading,
            dataTableProduct,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                handleCloseModal={handleCloseModal}
                title={title || 'ค้นหาสินค้า'}
                width={width || '60%'}
                className={`modal-data ${className}`}
            >
                <Row gutter={8}>
                    <Col sm={8}>
                        <SelectHero
                            label="ค้นหาด้วย"
                            name='keywordType'
                            small
                            firstChoose
                            widthDefault='100px'
                            onChange={this.changeKeywordType}
                            options={ProductKeyWordDropDown}
                            value={keywordType}
                        />
                    </Col>
                    <Col sm={6}>
                        <Search
                            size="small"
                            name='keyword'
                            onSearch={this.onSearchProduct}
                            onChange={this.changeKeyword}
                            enterButton />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 511px)"
                            columns={columns}
                            data={dataTableProduct}
                            loading={loading || thisloading}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalProductSearch)