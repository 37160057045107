import styled from 'styled-components'

export const CheckboxGroupCustomStyled = styled.div`

    .form-checkbox-group-custom {
        margin-bottom: ${props => props.small ? '3px' : ''};
        
        .label-style {
            font-size: ${props => props.small ? '12px' : ''};
            text-align: ${props => props.labelAlign};
            width: ${props => props.widthLabel ? props.widthLabel : props.small ? '120px' : '150px'};
            color: ${props => props.labelColor};
        }

        .ant-checkbox-group-item {
            font-size: ${props => props.small ? '12px' : ''};
        }
    }
`