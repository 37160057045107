import {
  AUTH,
  SEARCH_CRITERIA_SO_REPORT,
  SET_REPORT_PRODUCT_SHARINGS,
  RESET_REPORT_PRODUCT_SHARINGS,
} from './actionsTypes'

export const authData = authData => ({
  type: AUTH,
  authData
})

export const searchCriteriaSOReportData = searchCriteriaSOReportData => ({
  type: SEARCH_CRITERIA_SO_REPORT,
  searchCriteriaSOReportData
})

export const setReportProductSharings = reports => ({
  type: SET_REPORT_PRODUCT_SHARINGS,
  reports,
})

export const resetReportProductSharings = () => ({
  type: RESET_REPORT_PRODUCT_SHARINGS,
})