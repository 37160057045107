import React, { Component } from 'react'
import { Button } from 'antd'
import { ButtonCustomStyled } from './styled'

export class ButtonCustom extends Component {
    render() {
        const {
            className,
            onClick,
            type,
            text,
            icon,
            disabled,
            fullWidth,
            small,
            green,
            yellow,
            blue,
            ghost,
            ...rest
        } = this.props

        return (
            <ButtonCustomStyled small={small} green={green} yellow={yellow} blue={blue}>
                <Button
                    className={`button-custom ${className || ''}`}
                    onClick={onClick}
                    type={type ? type : 'primary'}
                    icon={icon}
                    disabled={disabled}
                    size={small ? 'small' : 'default'}
                    ghost={ghost}
                    block={fullWidth}
                    {...rest}
                >
                    {text}
                </Button>
            </ButtonCustomStyled>
        )
    }
}
