import React, { Component } from 'react'
import { Radio } from 'antd';
import { InputFormReduxStyled } from "./styled"
import './style.css'

const optionsMockup = [
    { label: 'Aa', value: true },
    { label: 'Bb', value: false }
];

export class RadioRedux extends Component {
    render() {
        const {
            input,
            options,
            label,
            right,
            meta: { touched, error },
            submitViewError,
            bold,
            small,
            widthSmall,
            widthDefault,
            disabled,
        } = this.props
        return (
            <InputFormReduxStyled
                error={(touched || submitViewError) && error ? true : false}
                small={small}
                widthSmall={widthSmall}
                widthDefault={widthDefault}
            >
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div className="label-style" style={{ textAlign: right ? 'right' : '' }}>
                                {bold ? <b>{label}</b> : label}
                            </div>
                        }
                        {
                            <Radio.Group
                                value={input.value ? input.value : undefined}
                                onChange={(e) => input.onChange(e)}
                                disabled={disabled}
                            >
                                {
                                    options ? options.map((data, i) => (
                                        <Radio key={i} value={data.value}>{data.label}</Radio>
                                    ))
                                        :
                                        optionsMockup.map((data, i) => (
                                            <Radio key={i} value={data.value}>{data.label}</Radio>
                                        ))
                                }
                            </Radio.Group>
                        }
                    </div>
                    <div className="error-message-bottom">
                        {(touched || submitViewError) ? error : ''}
                    </div>
                </div>
            </InputFormReduxStyled>
        )
    }
}
