import React, { Component } from 'react'
import {
    ModalCustom,
    TableCustom
} from 'components/common'
import { formatNumber, sortDataColumn } from 'helpers'
import { Row, Col } from 'antd'
import moment from 'moment'

export default class PoOutstandingDetailModal extends Component {
    state = {
        columns: [
            {
                title: 'Account Assignment',
                align: 'center',
                dataIndex: 'acctasscat',
                key: 'acctasscat',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'acctasscat'),
            },
            {
                title: 'วัสดุ',
                align: 'center',
                dataIndex: 'material',
                key: 'material',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'material'),
            },
            {
                title: 'บาร์โค้ด',
                align: 'center',
                dataIndex: 'eaN11',
                key: 'eaN11',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'eaN11'),
            },
            {
                title: 'ชื่อสินค้า',
                align: 'center',
                dataIndex: 'materiaL_TX',
                key: 'materiaL_TX',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'materiaL_TX'),
            },
            {
                title: 'หน่วยสั่งซื้อ',
                align: 'center',
                dataIndex: 'pO_UNIT',
                key: 'pO_UNIT',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'pO_UNIT'),
            },
            {
                title: 'ปริมาณสั่งซื้อ',
                align: 'center',
                dataIndex: 'quantitY_ORD',
                key: 'quantitY_ORD',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'quantitY_ORD'),
            },
            {
                title: 'ปริมาณค้างรับ',
                align: 'center',
                dataIndex: 'quantitY03',
                key: 'quantitY03',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'quantitY03'),
            },
            {
                title: 'Site',
                align: 'center',
                dataIndex: 'plant',
                key: 'plant',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'plant'),
            },
            {
                title: 'ที่เก็บสินค้า',
                align: 'center',
                dataIndex: 'stgE_LOC',
                key: 'stgE_LOC',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'stgE_LOC'),
            },
            {
                title: 'เอกสารการจัดซื้อ',
                align: 'center',
                dataIndex: 'pO_NUMBER',
                key: 'pO_NUMBER',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'pO_NUMBER'),
            },
            {
                title: 'ลำดับในเอกสารการจัดซื้อ',
                align: 'center',
                dataIndex: 'pO_ITEM',
                key: 'pO_ITEM',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'pO_ITEM'),
            },
            {
                title: 'วันที่เอกสาร',
                align: 'center',
                dataIndex: 'eT_PO_HEADER.doC_DATE',
                key: 'eT_PO_HEADER.doC_DATE',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'eT_PO_HEADER.doC_DATE'),
                render: (props) => (
                    moment(props).format('DD/MM/YYYY')   
                ),
            },
            {
                title: 'รหัสผู้ขาย',
                align: 'center',
                dataIndex: 'eT_PO_HEADER.vendor',
                key: 'eT_PO_HEADER.vendor',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'eT_PO_HEADER.vendor'),
            },
            {
                title: 'ชื่อผู้ขาย',
                align: 'center',
                dataIndex: 'eT_PO_HEADER.venD_NAME',
                key: 'eT_PO_HEADER.venD_NAME',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'eT_PO_HEADER.venD_NAME'),
            },
            {
                title: 'วันที่่ส่งมอบ',
                align: 'center',
                dataIndex: 'eT_PO_SCHEDULE.deliverY_DATE',
                key: 'eT_PO_SCHEDULE.deliverY_DATE',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'eT_PO_SCHEDULE.deliverY_DATE'),
                render: (props) => (
                    moment(props).format('DD/MM/YYYY')   
                ),
            },
        ],
        loading: false
    }
    render() {
        const {
            isShowModal,
            onCloseModal,
            poDetail,
        } = this.props
        const {
            columns,
        } = this.state
        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                title="PO ค้างรับ"
                width="70%"
            >
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={poDetail.eT_PO_ITEM_REPORT.item}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom >
        )
    }
}
