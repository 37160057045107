import React, { Component } from 'react'
import { get, flatten, isEmpty } from 'lodash'
import { authData } from 'actions/actionsCreator'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    SelectCustom,
    InputSearchCustom,
} from 'components/common'
import swal from '@sweetalert/with-react'
import { Row, Col } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { formatNumber, sortDataColumn } from 'helpers'

class ProductSearchModal extends Component {
    state = {
        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: '',
                key: 'htf',
                ellipsis: true,
                render: (props, row) => <div className="text-center">
                    <ButtonCustom
                        onClick={() =>
                            this.props.onSelected({
                                ...row,
                                price: (+this.props.channelCode === 20) ? row.projectPrice : row.retailPrice
                            })}
                        small
                        text="เลือก" />
                </div>
            },
            {
                title: 'รหัสสินค้า',
                align: 'center',
                dataIndex: 'productId',
                key: 'productId',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'productId'),
            },
            {
                title: 'ชื่อสินค้า',
                align: 'center',
                dataIndex: 'productNameMarket',
                key: 'productNameMarket',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'productNameMarket'),
            },
            {
                title: 'คลัง',
                align: 'center',
                dataIndex: 'storageLocationId',
                key: 'storageLocationId',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'storageLocationId'),
            },
            {
                title: 'จำนวนที่มี',
                align: 'center',
                dataIndex: 'qty',
                key: 'qty',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'qty'),
                render: (props) => <div className="text-right">
                    {formatNumber(props, 3)}
                </div>
            },
            {
                title: 'หน่วยนับ',
                align: 'center',
                dataIndex: 'unitId',//'baseUnit',
                key: 'unitId',//'baseUnit',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'unitId'),
            },
            {
                title: 'ราคา',
                align: 'center',
                dataIndex: 'price',
                key: 'price',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'price'),
                render: (props, row) => {
                    const price = (+this.props.channelCode === 20) ? row.projectPrice : row.retailPrice
                    return formatNumber(price || 0, 2)
                }
            },
            {
                title: 'หน่วยขาย',
                align: 'center',
                dataIndex: 'salesUnit',
                key: 'salesUnit',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'salesUnit'),
            },
            {
                title: 'บาร์โค้ด',
                align: 'center',
                dataIndex: 'barcode',
                key: 'barcode',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'barcode'),
            },
            {
                title: 'Other Barcode',
                align: 'center',
                dataIndex: 'otherbarode',
                key: 'otherbarode',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'otherbarode'),
            },
        ],
        loading: false,
        products: [],
        keyword: '',
        keywordType: 'productNameEng',
        keywordTypes: [
            { label: 'รหัสสินค้า', value: 'productId' },
            { label: 'ชื่อสินค้า', value: 'productNameEng' },
            { label: 'barcode', value: 'barcode' },
        ],
    }

    componentDidUpdate(prevProps) {
        if (this.props.outletId !== prevProps.outletId) {
            this.setState({ products: [] })
        }
    }

    changeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    changeKeywordType = (keywordType) => {
        this.setState({ keywordType })
    }

    onSearchProduct = async () => {
        this.setState({ loading: true })

        const { keywordType, keyword } = this.state
        const { outletId, isIncludeStockATP, auth, withOutStorageLocation } = this.props

        // ** note **
        // api request call inside loop because api get product not supported list of outlet id
        // and Hurry!
        const outletIds = (outletId || '').split(',')

        Promise.all(outletIds.map((outletId) => {
            const body = {
                productId: keywordType === 'productId' ? keyword : '',
                productNames: keywordType === 'productNameEng' ? keyword : '',
                barcode: keywordType === 'barcode' ? keyword : '',
                outletId,
                isIncludeStockATP,
                storageLocationIds: auth.arrayStorageLocationId,
                limitMaxRange: 50,
                productStatus: true,
            }

            return apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, body);
        })).then((data) => {
            let products = get(data[0], 'data.results', [])
            if (data[0].status === 200 && !isEmpty(products)) {
                products = flatten(products)

                if (withOutStorageLocation) {
                    products = products.filter((product, index) => (
                        index === products.findIndex(prd => prd.productId === product.productId && prd.salesUnit === product.salesUnit)
                    ))
                }

                this.setState({ products })
            } else if (data[0].status === 200 && (data[0].data.status === "NotAvaliable" || data[0].data.status === "NotFound")) {
                const message = get(data[0], 'data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูลสินค้า กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            } else {
                swal('Error', `ไม่พบข้อมูลสินค้า`, 'error')
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูลสินค้า กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            width,
            loading,
            className,
            title,
        } = this.props
        const {
            columns,
            keyword,
            keywordType,
            keywordTypes,
            loading: thisLoading,
            products,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                width={width}
                title={title || 'ค้นหาสินค้า'}
                className={`${className || ''}`}
            >
                <Row gutter={8}>
                    <Col sm={8}>
                        <SelectCustom
                            label="ค้นหาด้วย"
                            name='keywordType'
                            small
                            widthLabel='100px'
                            onChange={this.changeKeywordType}
                            options={keywordTypes}
                            value={keywordType}
                        />
                    </Col>
                    <Col sm={6}>
                        <InputSearchCustom
                            small
                            value={keyword}
                            onChange={this.changeKeyword}
                            onClickSearch={this.onSearchProduct}
                            onSubmit={this.onSearchProduct}
                        />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={products}
                            loading={loading || thisLoading}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearchModal)