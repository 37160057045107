import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { get } from 'lodash'
import { formatNumber, getPermissionPage } from 'helpers'
import { functionAlias } from 'routes'
import { apiServices, API_PATH } from 'apiServices'
import FileSaver from 'file-saver'
import {
    TitleHead,
    TableCustom,
} from 'components/common'
import { Spin } from 'antd'
import moment from 'moment'
import ReportStockMovementFromRedux from './ReportStockMovementFromRedux'
import swal from '@sweetalert/with-react'
import queryString from 'query-string'
import {
    ButtonCustom,
} from 'components/common'
import './style.css'

const formatDateAPI = 'YYYY-MM-DD HH:mm:ss'

class ReportInventoryMovementContainer extends Component {
    state = {
        columns: [
            {
                title: 'วันที่เอกสาร',
                dataIndex: 'bldat',
                key: 'bldat',
                width: 100,
                render: (value) => (
                    value && <div>
                        {moment(value).format('DD/MM/YYYY')}
                    </div>
                )
            },
            {
                title: 'วันที่บันทึก (Posting Date)',
                dataIndex: 'budat',
                key: 'budat',
                width: 155,
                render: (value) => (
                    value && <div>
                        {moment(value).format('DD/MM/YYYY')}
                    </div>
                )
            },
            {
                title: 'บาร์โค้ด',
                dataIndex: 'eaN11',
                key: 'eaN11',
                width: 130,
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'matnr',
                key: 'matnr',
                width: 120,
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'maktx',
                key: 'maktx',
                width: 240,
            },
            {
                title: 'ที่เก็บสินค้า',
                dataIndex: 'lgort',
                key: 'lgort',
                width: 100,
            },
            {
                title: 'หน่วยนับพื้นฐาน',
                dataIndex: 'meins',
                key: 'meins',
                width: 130,
            },
            {
                title: 'จำนวน(หน่วยนับพื้นฐาน)',
                dataIndex: 'menge',
                key: 'menge',
                width: 150,
                render: (value, row) => (
                    <div className="text-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'มูลค่าสินค้า',
                dataIndex: 'dmbtr',
                key: 'dmbtr',
                width: 100,
                render: (value, row) => (
                    <div className="text-right">
                        {formatNumber(value, 2)}
                    </div>
                )
            },
            {
                title: 'สกุลเงิน',
                dataIndex: 'waers',
                key: 'waers',
                width: 100,
            },
            {
                title: 'ประเภทการเคลื่อนย้าย',
                dataIndex: 'bwart',
                key: 'bwart',
                width: 190,
            },
            // {
            //     title: 'คำอธิบายรหัสการเคลื่อนคล้าย',
            //     dataIndex: 'bwarT_T',
            //     key: 'bwarT_T',
            // },
            {
                title: 'คำอธิบายรหัสการเคลื่อนคล้าย',
                dataIndex: 'transactionDescription',
                key: 'transactionDescription',
                width: 200,
            },

            {
                title: 'รหัสลูกค้า',
                dataIndex: 'kunnr',
                key: 'kunnr',
                width: 150,
            },
            {
                title: 'ชื่อลูกค้า',
                dataIndex: 'kunnR_T',
                key: 'kunnR_T',
                width: 150,
            },
            {
                title: 'เอกสารอ้างอิง',
                dataIndex: 'xblnr',
                key: 'xblnr',
                width: 150,
            },
            {
                title: 'ใบสั่งขาย',
                dataIndex: 'kdauf',
                key: 'kdauf',
                width: 150,
            },
            {
                title: 'ประเภทสต็อก',
                dataIndex: 'insmk',
                key: 'insmk',
                width: 100,
                render: (value) => {
                    if (value === '2') {
                        return 'QI'
                    } else if (value === '3') {
                        return 'BL'
                    } else if (value === '') {
                        return 'UR'
                    } else {
                        return ''
                    }
                }
            },
            {
                title: 'สต็อกพิเศษ',
                dataIndex: 'sobkz',
                key: 'sobkz',
                width: 150,
            },
            {
                title: 'เอกสารวัสดุ',
                dataIndex: 'mblnr',
                key: 'mblnr',
                width: 100,
                render: (value, full, index) => (
                    ['101', '201', '311'].includes(full.bwart) ?
                        <ButtonCustom
                            text={value}
                            small
                            type="link"
                            onClick={() => this.printReport(full)}
                        /> :
                        value
                ),
                //  this.switchReport(value, full, index)
                //  (
                //     full.bwart === '101' ?
                //         <ButtonCustom
                //             text={value}
                //             small
                //             type="link"
                //             onClick={() => this.printReport(full)}
                //         /> :
                //         value
                // ),
            },
            {
                title: 'ใบสั่งซื้อ',
                dataIndex: 'ebeln',
                key: 'ebeln',
                width: 150,
            },
            {
                title: 'รายการใบสั่งซื้อ',
                dataIndex: 'ebelp',
                key: 'ebelp',
                width: 150,
            },
            {
                title: 'เหตุผลการเคลื่อนย้าย',
                dataIndex: 'grund',
                key: 'grund',
                width: 150,
            },
            {
                title: 'ผู้รับสินค้า',
                dataIndex: 'wempf',
                key: 'wempf',
                width: 150,
            },

            {
                title: 'ใบส่งสินค้า',
                dataIndex: 'vbelN_IM',
                key: 'vbelN_IM',
                width: 150,
            },
            {
                title: 'รายการใบส่งสินค้า',
                dataIndex: 'vbelP_IM',
                key: 'vbelP_IM',
                width: 150,
            },
            {
                title: 'ปีเอกสารวัสดุ',
                dataIndex: 'mjahr',
                key: 'mjahr',
                width: 100,
            },
            {
                title: 'รายการที่ของเอกสารวัสดุ',
                dataIndex: 'zeile',
                key: 'zeile',
                width: 150,
            },
            {
                title: 'ข้อความเอกสาร(Doc. Header Text)',
                dataIndex: 'bktxt',
                key: 'bktxt',
                width: 150,
            },


            {
                title: 'หน่วยนับตามเอกสาร',
                dataIndex: 'erfme',
                key: 'erfme',
                width: 150,
            },
            {
                title: 'จำนวน(หน่วยนับตามเอกสาร)',
                dataIndex: 'erfmg',
                key: 'erfmg',
                width: 150,
                render: (value, row) => (
                    <div className="text-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'รายการใบสั่งขาย',
                dataIndex: 'kdpos',
                key: 'kdpos',
                width: 150,
            },
            {
                title: 'Cost Center',
                dataIndex: 'kostl',
                key: 'kostl',
                width: 150,
            },
            {
                title: 'หมายเหตุรายการ',
                dataIndex: 'sgtxt',
                key: 'sgtxt',
                width: 150,
            },
            {
                title: 'วันที่สร้าง',
                dataIndex: 'cpudt',
                key: 'cpudt',
                width: 120,
                render: (value) => (
                    value && <div>
                        {moment(value).format('DD/MM/YYYY')}
                    </div>
                )
            },
            {
                title: 'เวลาที่สร้าง',
                dataIndex: 'cputm',
                key: 'cputm',
                width: 120,
            },
            {
                title: 'เลขที่เอกสารยกเลิก',
                dataIndex: 'smbln',
                key: 'smbln',
                width: 150,
                render: (value, row) => (
                    value && <a onClick={() => this.exportReportByProduct(row)}>
                        {value}
                    </a>
                )
            },
            {
                title: 'ปีที่เอกสารยกเลิก',
                dataIndex: 'sjahr',
                key: 'sjahr',
                width: 120,
            },
            {
                title: 'รายการเลขที่เอกสารยกเลิก',
                dataIndex: 'smblp',
                key: 'smblp',
                width: 150,
            },
            {
                title: 'แฟลกการยกเลิก',
                dataIndex: 'reversE_FLAG',
                key: 'reversE_FLAG',
                width: 150,
            },
            {
                title: 'รหัสผู้ขาย',
                dataIndex: 'lifnr',
                key: 'lifnr',
                width: 150,
            },
            {
                title: 'ชื่อผู้ขาย',
                dataIndex: 'lifnR_T',
                key: 'lifnR_T',
                width: 150,
            },
            {
                title: 'ใบจองสินค้า',
                dataIndex: 'rsnum',
                key: 'rsnum',
                width: 150,
            },
            {
                title: 'รายการใบจองสินค้า',
                dataIndex: 'rspos',
                key: 'rspos',
                width: 150,
            },
            {
                title: 'รหัสร้าน',
                dataIndex: 'werks',
                key: 'werks',
                width: 100,
            },
            {
                title: 'ร้าน',
                dataIndex: 'werkS_T',
                key: 'werkS_T',
                width: 100,
            },
        ],
        loading: false,
        reports: [],
        initialValues: {
            productCode: '',
            barcode: '',
            outlet: '',
            storageLocations: [],
            movementTypes: [],
            productTypes: [],
            specialInventory: '',
            sellerId: '',
            customerId: '',
            documentDateFrom: '',
            documentDateTo: '',
            createDateFrom: '',
            createDateTo: '',
            prNumber: '',
            soNumber: '',
            documentMaterial: '',
            documentMaterialYear: '',
        },
        permissionPage: getPermissionPage(functionAlias.reportInventoryMovement),
    }

    getBody = (filter) => {
        return {
            productCode: filter.productCode,
            productBarCode: filter.barcode,
            matDoc: filter.documentMaterial,
            matDocYear: filter.documentMaterialYear,
            outlet: filter.outlet,
            storelocations: filter.storageLocations,
            movementTypes: filter.movementTypes,
            productTypes: filter.productTypes,
            docDateFrom: filter.documentDateFrom && filter.documentDateFrom.startOf('day').format(formatDateAPI),
            docDateTo: filter.documentDateTo && filter.documentDateTo.endOf('day').format(formatDateAPI),
            createDateFrom: filter.createDateFrom && filter.createDateFrom.startOf('day').format(formatDateAPI),
            createDateTo: filter.createDateTo && filter.createDateTo.endOf('day').format(formatDateAPI),
            specialStock: filter.specialInventory,
            vender: filter.sellerId,
            customer: filter.customerId,
            purchaseOrder: filter.prNumber,
            salesOrder: filter.soNumber,
        }
    }

    validateFilter = (filter) => {
        if (filter.documentDateTo && !filter.documentDateFrom) return { isError: true, message: 'กรุณาระบุวันที่เอกสารเริ่มต้น' }
        if (filter.documentDateFrom && filter.documentDateTo && (filter.documentDateTo).isBefore(filter.documentDateFrom)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }
        if (filter.createDateTo && !filter.createDateFrom) return { isError: true, message: 'กรุณาระบุวันที่สร้างเริ่มต้น' }
        if (filter.createDateFrom && filter.createDateTo && (filter.createDateTo).isBefore(filter.createDateFrom)) return { isError: true, message: 'รูปแบบวันที่สร้างไม่ถูกต้อง' }

        return { isError: false }
    }


    exportReportByProduct = async (data) => {
        console.log('data', data);
        const { ReportStockMovementFromRedux: { values: filter } } = this.props
        const valid = this.validateFilter(filter)
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }
        this.setState({ loading: true })
        const body = this.getBody(filter)
        let newPram = {
            matDoc: data.smbln,
            outlet: body.outlet,
            movementTypes: [
                //   "101"
                data.bwart
            ]
        }
        // console.log('body', body);
        await apiServices.callApi('post', API_PATH.POST_GET_REPORT_FORM_GOOD_RECEIVE_A5, newPram, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `ReportGetReportGoodReceiveA5File-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    exportReport = async () => {
        const { ReportStockMovementFromRedux: { values: filter } } = this.props
        const valid = this.validateFilter(filter)
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        await apiServices.callApi('post', API_PATH.POST_STOCK_MOVEMENT_EXPORT_EXCEL, body, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `ReportStockMovementFile-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    searchReport = async (filter) => {
        const valid = this.validateFilter(filter)
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        const url = API_PATH.POST_GET_DAILY_MATERIAL_MOVEMENT
        await apiServices.callApi('post', url, body).then(res => {
            let reports = get(res, 'data.result.eT_MAT_MOVEMENT.item', [])
            if (res.status === 200) {
                reports = reports.filter(v => v.lgort != '');
                reports = reports.map((report, index) => ({
                    ...report,
                    key: index,
                }))
                this.setState({ reports })
            }
        }).catch(error => {
            const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    printReport = async (data) => {
        const { ReportStockMovementFromRedux: { values: filter } } = this.props
        const body = this.getBody(filter)
        this.setState({ loading: true })
        let URL_REPORT = ''
        let param = {}
        let reportRDF = ''
        let urlOpen = ''
        switch (data.bwart) {
            case '101':
                URL_REPORT = API_PATH.POST_GET_REPORT_FORM_GOOD_RECEIVE_A4;
                break;
            case '201':
                URL_REPORT = API_PATH.GET_REPORT_REQUISITION;
                break;
            case '311':
                URL_REPORT = API_PATH.POST_GET_REPORT_FORM_STOCK_TRANSFER_A5
                break;
            default:
                console.log(`Movement type data.bwart:${data.bwart} is not matching.`)
        }
        param = {
            matDoc: data.mblnr,
            outlet: body.outlet,
            movementTypes: [data.bwart]
        }
        reportRDF = await apiServices.callApi('post', URL_REPORT, param, { responseType: 'blob' })
        urlOpen = window.URL.createObjectURL(reportRDF.data)
        window.open(urlOpen)

        this.setState({ loading: false });
    }

    render() {
        const {
            auth
        } = this.props

        const {
            columns,
            reports,
            loading,
            initialValues,
            permissionPage,
        } = this.state

        return (
            <div className="report-stock-movement-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="รายงานเคลื่อนไหวสินค้า"
                        icon="file-text"
                    />
                    <div className="top-layout">
                        <ReportStockMovementFromRedux
                            auth={auth}
                            initialValues={initialValues}
                            onSubmit={this.searchReport}
                            onClickExport={this.exportReport}
                            permissionPage={permissionPage}
                        />
                    </div>
                    <div className="middle-content">
                        <div className="table-layout">
                            <TableCustom
                                // height="calc(100vh - 316px)"
                                scroll={{ x: "max-content", y: "calc(100vh - 250px)" }} 
                                columns={columns}
                                data={reports}
                                // isFullContent
                                // small
                                // pagination={{ pageSize: 30 }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        ReportStockMovementFromRedux: state.form.ReportStockMovementFromRedux,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportInventoryMovementContainer)