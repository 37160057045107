import React from 'react'
import { Row, Col, Spin } from "antd"
import { Field, reduxForm } from 'redux-form'
import { VALIDATE } from "helpers"
import {
  InputFormRedux,
  SelectRedux,
} from 'components'

class CustomerShipToForm extends React.Component {
  state = {

  }
  render() {
    const {
      loading,
      submitViewError,
      dataCustomerTitles,
      dataCustomerHouseTypes,

      dataRegionCountries,
      dataShiptoProvices,
      dataShiptoDistricts,
      dataShiptoSubDistricts,

      handleChangeSelectShipToProvicne,
      handleChangeSelectShipToDistrict,
      handleChangeSelectShipToSubDistrict
    } = this.props
    return (
      <div>
        <Spin spinning={loading} style={{ height: '100%' }}>
          <Row gutter={10}>
            <Col sm={12}>
              <Field
                component={InputFormRedux}
                name="shipToId"
                label="รหัสข้อมูลการจัดส่ง"
                disabled
                placeholder="2xxxxxxxxxx"
              />
            </Col>
            <Col sm={12}>
              <Field
                validate={VALIDATE.REQUIRE}
                submitViewError={submitViewError}
                component={SelectRedux}
                name="shipToTitles"
                label="คำนำหน้าชื่อ"
                options={dataCustomerTitles}
                pleaseChoose={true}
              />
            </Col>
            </Row>
            <Row gutter={10}>
            <Col sm={12}>
              <Field
                validate={VALIDATE.REQUIRE}
                submitViewError={submitViewError}
                component={InputFormRedux}
                name="shipToName"
                label="ชื่อ-นามสกุล"
                placeholderDefault={true}
              />
            </Col>
            <Col sm={12}>
              <Field
                validate={VALIDATE.REQUIRE}
                submitViewError={submitViewError}
                component={InputFormRedux}
                name="shipToAddress"
                label="ที่อยู่"
                placeholderDefault={true}
              />
            </Col>
            </Row>
            <Row gutter={10}>
            <Col sm={12}>
              <Field
                validate={VALIDATE.REQUIRE}
                submitViewError={submitViewError}
                component={InputFormRedux}
                name="shipToPostalCode"
                label="รหัสไปรษณี"
                placeholderDefault={true}
              />
            </Col>
            <Col sm={12}>
              <Field
                validate={VALIDATE.REQUIRE}
                submitViewError={submitViewError}
                component={SelectRedux}
                name="shipToSubDistrict"
                label="แขวง/ตำบล"
                pleaseChoose={true}
                options={dataShiptoSubDistricts}
                onChangeHero={handleChangeSelectShipToSubDistrict}
              />
            </Col>
            </Row>
            <Row gutter={10}>
            <Col sm={12}>
              <Field
                validate={VALIDATE.REQUIRE}
                submitViewError={submitViewError}
                component={SelectRedux}
                name="shipToDistrict"
                label="เขต/อำเภอ"
                pleaseChoose={true}
                options={dataShiptoDistricts}
                onChangeHero={handleChangeSelectShipToDistrict}
              />
            </Col>
            <Col sm={12}>
              <Field
                validate={VALIDATE.REQUIRE}
                submitViewError={submitViewError}
                component={SelectRedux}
                name="shipToProvince"
                label="จังหวัด"
                pleaseChoose={true}
                options={dataShiptoProvices}
                onChangeHero={handleChangeSelectShipToProvicne}
              />
            </Col>
            </Row>
            <Row gutter={10}>
            <Col sm={12}>
              <Field
                component={SelectRedux}
                name="shipToCountry"
                label="ประเทศ"
                options={dataRegionCountries}
                //value="TH"
                firstChoose={true}
              />
            </Col>
            <Col sm={12}>
              <Field
                // validate={VALIDATE.REQUIRE}
                submitViewError={submitViewError}
                component={SelectRedux}
                name="shipToHouseType"
                label="ประเภท"
                options={dataCustomerHouseTypes}
                pleaseChoose={true}
              />
            </Col>
            </Row>
            <Row gutter={10}>
            <Col sm={12}>
              <Field
                validate={VALIDATE.REQUIRE}
                submitViewError={submitViewError}
                component={InputFormRedux}
                name="shipToPhone"
                label="โทรศัพท์"
                placeholderDefault={true}
              />
            </Col>
          </Row>
        </Spin>
      </div>
    )
  }
}

export default reduxForm({
  form: 'CustomerShipToForm',  // a unique identifier for this form
  enableReinitialize: true
})(CustomerShipToForm)
