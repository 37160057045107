import styled from 'styled-components'

export const InputSearchCustomStyled = styled.div`

    .form-input-search-custom {
        margin-bottom: ${props => props.small ? '3px' : ''};
        
        .ant-input {
            font-size: ${props => props.small ? '12px' : ''};
            border-radius: 4px 0px 0px 4px;
        }
        
        .label-style {
            font-size: ${props => props.small ? '12px' : ''};
            text-align: ${props => props.labelAlign};
            width: ${props => props.widthLabel ? props.widthLabel : props.small ? '120px' : '150px'};
        }

        .input-search-custom {
            border-radius: 4px 0px 0px 4px;
            width: ${props => props.label ? `calc(100% - ${props.widthLabel ? props.widthLabel : props.small ? '120px' : '150px'})` : '100%'};
            display: flex;

            .button-search-custom {
                font-size: ${props => props.small ? '12px' : ''};
                height: ${props => props.small ? '24px' : ''};
                border-radius: 0px 4px 4px 0px;
            }
        }
    }
`