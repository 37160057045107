import styled from 'styled-components'

export const DatePickerReduxStyled = styled.div`

.ant-calendar-picker-input.ant-input {
    border-color: ${props => props.error ? 'red' : ''};
}
.label-style {
    font-size: ${props => props.small ? '12px' : ''};
    width: ${props => props.small ? props.widthSmall ? props.widthSmall : '120px' : ''}
}
.ant-calendar-picker-input.ant-input {
    font-size: ${props => props.small ? '12px' : ''};
}
.input-form {
    margin-bottom: ${props => props.small ? '3px' : ''};
}
`