import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Row, Col, Divider, message, Spin } from 'antd'
import { get, isEmpty, uniqBy } from 'lodash'
import {
    ProductStockSearchModal,
} from 'components/feature'
import {
    InputSearchCustom,
    InputCustom,
    DatePickerCustom,
    SelectCustom,
    ButtonCustom,
} from 'components/common'
import { apiServices, API_PATH } from 'apiServices'
import { VALIDATE } from 'helpers'
import swal from '@sweetalert/with-react'

import './style.css'

class GoodsTransferForm extends Component {
    state = {
        isShowModal: false,
        loading: false,
        outlets: [],
        units: [],
        storageLocations: [],
        keyTrigger: '',
        storageLocationDefault: '',
        storageLocationFrom: ''
    }

    componentDidUpdate(prevProps) {
        if (get(this.props, 'transactionTypeData.name') !== get(prevProps, 'transactionTypeData.name')) {
            this.fetchDataDefault()
        }
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = () => {
        this.getOutlets()
        this.getStorageLocations()
    }

    getOutlets = async () => {
        this.setState({ loading: true })
        const { auth, change } = this.props
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                const outletIdDefault = get(outlets, '0.value')
                this.setState({
                    outlets,
                }, () => {
                    const { transactionTypeData = {} } = this.props
                    const { header } = transactionTypeData

                    header.outletFrom && change('outletFrom', outletIdDefault)
                    header.outletTo && change('outletTo', outletIdDefault)
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onOpenModal = (keyTrigger = '') => {
        this.setState({ isShowModal: true, keyTrigger })
    }

    onCloseModal = () => {
        this.setState({ isShowModal: false, keyTrigger: '' })
    }

    onKeyPress = (e) => {
        e.key === 'Enter' && e.preventDefault()
    }

    onChangeProductCode = (key, e) => {
        const { change, transactionTypeData = {} } = this.props
        const { header } = transactionTypeData
        const code = e.target.value
        if ((!header.productCodeFrom || !header.productCodeTo) || ((get(header, 'productCodeFrom.disabled') || get(header, 'productCodeTo.disabled')))) {
            change('productCodeFrom', code)
            change('productCodeTo', code)
            change('storageLocationTo', '')
            change('storageLocationFrom', '')
            change('productNameFrom', '')
            change('productNameTo', '')
            change('unit', '')
        } else {
            change(key, code)
            change('unit', '')
            if (key === 'productCodeFrom') {
                if (!get(header, 'storageLocationFrom.disabled')) change('storageLocationFrom', '')
                change('productCodeFrom', '')
                change('productNameFrom', '')
            } else if (key === 'productCodeTo') {
                if (!get(header, 'storageLocationTo.disabled')) change('storageLocationTo', '')
                change('productCodeTo', '')
                change('productNameTo', '')
            }
        }
    }

    convertSAPFieldToColumnField = (product) => {
        return {
            productId: product.matnr,
            productName: product.maktg,
            storageLocationId: product.lgort || '',
            // unit: product.eX_DISPLAY_UNIT,
        }
    }

    onSelectedProduct = (product, key) => {
        const { keyTrigger } = this.state
        const { transactionTypeData: { header } } = this.props

        if (!key) {
            key = keyTrigger
        }
        product = this.convertSAPFieldToColumnField(product)
        this.setProductData(product, key)

        const isRelatedField = get(header, 'productCodeFrom.disabled') || get(header, 'productCodeTo.disabled')
        if (isRelatedField) {
            const relatedField = key === 'productCodeFrom' ? 'productCodeTo' : 'productCodeFrom'
            this.setProductData(product, relatedField)
        }

        this.onCloseModal()
        this.getUnits(product.productId)
    }

    setProductData = (product, key) => {
        const { change } = this.props
        if (key === 'productCodeFrom') {
            change('productCodeFrom', product.productId)
            change('productNameFrom', product.productName)
            change('storageLocationFrom', product.storeLocation)
        } else if (key === 'productCodeTo') {
            change('productCodeTo', product.productId)
            change('productNameTo', product.productName)
            change('storageLocationTo', product.storeLocation)
        }
    }

    // getStorageLocationDefault = async (outletId) => {
    //     const { header } = this.state
    //     const url = `${API_PATH.GET_MASTER_SPECIAL_CONDITION}?ConditionName=GR&Condition1=${outletId}&Condition2=SLOC`
    //     await apiServices.callApi('get', url).then(res => {
    //         const storageLocationDefault = get(res, 'data.results.0.value', '')
    //         if (res.status === 200) {
    //             this.setState({ storageLocationDefault })
    //         }
    //     }).catch(error => {
    //         const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Default Store Location ได้ กรุณาลองใหม่อีกครั้ง')
    //         swal('Error', message, 'error')
    //     })
    // }

    onSearchProduct = async (key) => {
        const { [key]: productCode, outletFrom, outletTo, auth } = this.props
        if (!productCode) {
            message.error('กรุณากรอกรหัสสินค้า')
            return
        }

        let outletId = ''
        if (key === 'productCodeFrom') {
            outletId = outletFrom
        } else if (key === 'productCodeTo') {
            outletId = outletTo || outletFrom
        }

        this.setState({ loading: true })

        // const storeLocationQueryString = auth.arrayStorageLocationId.map(slocId => (`&Storelocations=${slocId}`)).join('')
        const storeLocationQueryString = auth.arrayStorageLocationId.map(slocId => `Storelocations=${slocId}`).join('&')
        const url = `${API_PATH.GET_DAILY_STOCK_BALANCE_LIST}/?ProductCode=${productCode}&Outlet=${outletId}&GroupBy=L&DisplayUnit=B&${storeLocationQueryString}`
        await apiServices.callApi('get', url).then(res => {
            const productSAP = get(res, 'data.result.eX_STOCK_BAL.item.0')
            if (res.status === 200) {
                if (productSAP) {
                    this.onSelectedProduct(productSAP, key)
                } else {
                    message.error('ไม่พบสินค้า')
                }
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.title', 'ไม่สามารถดึงข้อมูล Product ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            console.error(get(error, 'response.data.errors'))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onChangeOutlet = (key, outletId) => {
        const { change } = this.props
        const { transactionTypeData = {}, productCodeFrom, productCodeTo } = this.props
        const { header } = transactionTypeData

        if ((!header.outletFrom || !header.outletTo) || ((get(header, 'outletFrom.disabled') || get(header, 'outletTo.disabled')))) {
            // related field
            change('outletFrom', outletId)
            change('outletTo', outletId)
            change('storageLocationTo', '')
            change('storageLocationFrom', '')

            change('productCodeFrom', '')
            change('productCodeTo', '')
            change('productNameFrom', '')
            change('productNameTo', '')
            change('unit', '')
        } else {
            change(key, outletId)
            if (key === 'outletFrom') {
                change('storageLocationFrom', '')
                change('productCodeFrom', '')
                change('productNameFrom', '')
                if (!productCodeTo) {
                    change('unit', '')
                }
            } else if (key === 'outletTo') {
                change('storageLocationTo', '')
                change('productCodeTo', '')
                change('productNameTo', '')
                if (!productCodeFrom) {
                    change('unit', '')
                }
            }
        }
    }

    onChangeStorageLocation = (key, storageLocationId) => {
        const { change } = this.props
        const { transactionTypeData = {} } = this.props
        const { header } = transactionTypeData
        
        if ((!header.storageLocationFrom || !header.storageLocationTo) || ((get(header, 'storageLocationFrom.disabled') || get(header, 'storageLocationTo.disabled')))) {
            // related field
            change('storageLocationFrom', storageLocationId)
            change('storageLocationTo', storageLocationId)
        } else {
            // ถ้าเป็นคลังเริ่มต้น
            if (key === 'storageLocationFrom') {
                this.setState({ storageLocationFrom: storageLocationId })
            }

            change(key, storageLocationId)
        }
    }

    getStorageLocations = async () => {
        this.setState({ loading: true })
        const { auth } = this.props

        await apiServices.callApi('post', API_PATH.GET_MASTER_WAREHOUSE_BY_OUTLET_ID, auth.arrayOutlet).then(res => {
            let storageLocations = get(res, 'data.results')
            if (res.status === 200) {
                // filter status = true and auth
                storageLocations = storageLocations.filter(storageLocation =>
                    (storageLocation.storageLocationIdStatus && auth.arrayStorageLocationId.includes(storageLocation.storageLocationId))
                )
                storageLocations = storageLocations.map(data => (
                    {
                        ...data,
                        label: `${data.storageLocationId} - ${data.storageLocationDescriptionThai}`,
                        value: data.storageLocationId
                    }
                ))
                this.setState({ storageLocations })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Store Location ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    getUnits = async (productId) => {
        this.setState({ loading: true })
        const { change } = this.props
        const { units } = this.state
        const params = { productId }

        await apiServices.callApi('post', API_PATH.POST_GET_PRODUCT_UNIT, params).then(res => {
            let dataUnits = get(res, 'data.results')
            if (res.status === 200) {
                if (dataUnits) {
                    dataUnits = dataUnits.map(data => (
                        {
                            ...data,
                            label: data.unitId,
                            value: data.unitId,
                        }
                    ))

                    let newUnits = [...units]
                    newUnits = dataUnits.concat(newUnits)
                    newUnits = uniqBy(newUnits, 'unitId')
                    this.setState({ units: newUnits })
                    change('unit', newUnits[0].value)
                }
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Unit ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            isShowModal,
            units,
            storageLocations,
            outlets,
            loading,
            keyTrigger,
            storageLocationFrom
        } = this.state

        const {
            handleSubmit,
            transactionTypeData = {},
            productCodeFrom,
            productCodeTo,
            outletFrom,
            outletTo,
        } = this.props

        const {
            header
        } = transactionTypeData
        const productUnits = units.filter(unit => unit.productId === productCodeFrom || unit.productId === productCodeTo)

        return (
            <form
                onSubmit={handleSubmit}
                onKeyPress={this.onKeyPress}
            >
                <Spin spinning={loading}>
                    {!isEmpty(header) &&
                        <Fragment>
                            <Row gutter={15}>
                                <Col sm={10}>
                                    {header.documentDate &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={DatePickerCustom}
                                            name="documentDate"
                                            label="วันที่เอกสาร"
                                            allowClear={false}
                                            small
                                            disabled={header.documentDate.disabled}
                                        />
                                    }
                                </Col>
                                <Col sm={10}>
                                    {header.orderDate &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={DatePickerCustom}
                                            name="orderDate"
                                            label="วันที่ผ่านรายการ"
                                            allowClear={false}
                                            small
                                            disabled={header.orderDate.disabled}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col sm={10}>
                                    {header.documentNo &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={InputCustom}
                                            name="documentNo"
                                            label="ใบวัสดุ"
                                            small
                                            disabled={header.documentNo.disabled}
                                        />
                                    }
                                </Col>
                                <Col sm={10}>
                                    {header.documentHeader &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={InputCustom}
                                            name="documentHeader"
                                            label="Doc Header Text"
                                            small
                                            disabled={header.documentHeader.disabled}
                                        />
                                    }
                                </Col>
                            </Row>
                        </Fragment>
                    }
                    <Divider style={{ margin: '15px 0' }} />
                    {!isEmpty(header) &&
                        <Fragment>
                            <Row gutter={15}>
                                <Col sm={10} className="input-range-box">
                                    {header.productCodeFrom &&
                                        <Fragment>
                                            <div className="input-range-label">รหัสสินค้าต้นทาง</div>
                                            <div className="input-range">
                                                <div style={{ marginRight: '5px', width: '100%' }}>
                                                    <Field
                                                        component={InputSearchCustom}
                                                        name="productCodeFrom"
                                                        small
                                                        onClickSearch={this.onOpenModal.bind(this, 'productCodeFrom')}
                                                        onChange={this.onChangeProductCode.bind(this, 'productCodeFrom')}
                                                        onSubmit={this.onSearchProduct.bind(this, 'productCodeFrom')}
                                                        disabled={header.productCodeFrom.disabled}
                                                        disabledButton={header.productCodeFrom.disabled}
                                                    />
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <Field
                                                        validate={VALIDATE.REQUIRE}
                                                        component={InputCustom}
                                                        name="productNameFrom"
                                                        small
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                </Col>
                                <Col sm={10} className="input-range-box">
                                    {header.productCodeTo &&
                                        <Fragment>
                                            <div className="input-range-label">รหัสสินค้าปลายทาง</div>
                                            <div className="input-range">
                                                <div style={{ marginRight: '5px', width: '100%' }}>
                                                    <Field
                                                        component={InputSearchCustom}
                                                        name="productCodeTo"
                                                        small
                                                        onClickSearch={this.onOpenModal.bind(this, 'productCodeTo')}
                                                        onChange={this.onChangeProductCode.bind(this, 'productCodeTo')}
                                                        onSubmit={this.onSearchProduct.bind(this, 'productCodeTo')}
                                                        disabled={header.productCodeTo.disabled}
                                                        disabledButton={header.productCodeTo.disabled}
                                                    />
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <Field
                                                        validate={VALIDATE.REQUIRE}
                                                        component={InputCustom}
                                                        name="productNameTo"
                                                        small
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col sm={10}>
                                    {header.outletFrom &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={SelectCustom}
                                            name="outletFrom"
                                            label="ร้านต้นทาง"
                                            options={outlets}
                                            small
                                            disabled={header.outletFrom.disabled}
                                            onChange={this.onChangeOutlet.bind(this, 'outletFrom')}
                                        />
                                    }
                                </Col>
                                <Col sm={10}>
                                    {header.outletTo &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={SelectCustom}
                                            name="outletTo"
                                            label="ร้านปลายทาง"
                                            options={outlets}
                                            small
                                            disabled={header.outletTo.disabled}
                                            onChange={this.onChangeOutlet.bind(this, 'outletTo')}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col sm={10}>
                                    {header.storageLocationFrom &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={SelectCustom}
                                            name="storageLocationFrom"
                                            label="ที่เก็บต้นทาง"
                                            options={storageLocations.filter(storageLocation => storageLocation.outletId === (outletFrom || outletTo))}
                                            small
                                            placeholderDefault={!header.storageLocationFrom.disabled}
                                            disabled={header.storageLocationFrom.disabled}
                                            onChange={this.onChangeStorageLocation.bind(this, 'storageLocationFrom')}
                                        />
                                    }
                                </Col>
                                <Col sm={10}>
                                     {header.storageLocationTo &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={SelectCustom}
                                            name="storageLocationTo"
                                            label="ที่เก็บปลายทาง"
                                            options={storageLocations.filter(storageLocation => {
                                                // ถ้าเป็นการโอนสินค้าระหว่างคลัง จะไม่สามารถเลือกคลังเดียวกันได้
                                                if (transactionTypeData.name === '311') {
                                                    return storageLocation.outletId === (outletTo || outletFrom) &&
                                                    storageLocation.value !== storageLocationFrom
                                                }else {
                                                    return storageLocation.outletId === (outletTo || outletFrom)
                                                }
                                                
                                            })}
                                            small
                                            placeholderDefault={!header.storageLocationTo.disabled}
                                            disabled={header.storageLocationTo.disabled}
                                            onChange={this.onChangeStorageLocation.bind(this, 'storageLocationTo')}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col sm={10}>
                                    {header.amount &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={InputCustom}
                                            type="number"
                                            name="amount"
                                            label="จำนวน"
                                            small
                                            disabled={header.amount.disabled}
                                        />
                                    }
                                </Col>
                                <Col sm={10}>
                                    {header.unit &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={SelectCustom}
                                            name="unit"
                                            label="หน่วยนับ"
                                            options={productUnits}
                                            small
                                            disabled={header.unit.disabled}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col sm={10}>
                                    {header.costTotal &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={InputCustom}
                                            name="costTotal"
                                            label="ต้นทุนรวม"
                                            small
                                            disabled={header.costTotal.disabled}
                                        />
                                    }
                                </Col>
                                <Col sm={10}>
                                    {header.reason &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={SelectCustom}
                                            name="reason"
                                            label="เหตุผล"
                                            options={[]}
                                            small
                                            disabled={header.reason.disabled}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col sm={10}>
                                    {header.documentFrom &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={InputCustom}
                                            name="documentFrom"
                                            label="เอกสารต้นทาง"
                                            small
                                            disabled={header.documentFrom.disabled}
                                        />
                                    }
                                </Col>
                                <Col sm={10}>
                                    {header.index1 &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={InputCustom}
                                            name="index1"
                                            label="ลำดับที่ #1"
                                            small
                                            disabled={header.index1.disabled}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col sm={10}>
                                    {header.documentTo &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={InputCustom}
                                            name="documentTo"
                                            label="เอกสารปลายทาง"
                                            small
                                            disabled={header.documentTo.disabled}
                                        />
                                    }
                                </Col>
                                <Col sm={10}>
                                    {header.index2 &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={InputCustom}
                                            name="index2"
                                            label="ลำดับที่ #2"
                                            small
                                            disabled={header.index2.disabled}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col sm={10}>
                                    {header.document &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={InputCustom}
                                            name="document"
                                            label="เอกสารวัสดุ"
                                            small
                                            disabled={header.document.disabled}
                                        />
                                    }
                                </Col>
                                <Col sm={10}>
                                    {header.year &&
                                        <Field
                                            validate={VALIDATE.REQUIRE}
                                            component={InputCustom}
                                            name="year"
                                            label="ปี"
                                            small
                                            disabled={header.year.disabled}
                                        />
                                    }
                                </Col>
                            </Row>
                        </Fragment>
                    }
                    <Row className="text-right">
                        <Col sm={24}>
                            {transactionTypeData.action.add && <ButtonCustom
                                icon="plus"
                                text="เพิ่ม"
                                small
                                htmlType="submit"
                            />}
                            {transactionTypeData.action.search && <ButtonCustom
                                icon="search"
                                text="ค้นหา"
                                small
                                htmlType="submit"
                            />}
                        </Col>
                    </Row>
                    {/* <ProductSearchModal
                        isShowModal={isShowModal}
                        onCloseModal={this.onCloseModal}
                        onSelected={this.onSelectedProduct}
                        outletId={keyTrigger === 'productCodeFrom' || !header.outletTo ? outletFrom : outletTo}
                    /> */}
                    <ProductStockSearchModal
                        isShowModal={isShowModal}
                        onCloseModal={this.onCloseModal}
                        onSelected={this.onSelectedProduct}
                        defaultOutlet={(keyTrigger === 'productCodeFrom' || !header.outletTo) ? outletFrom : outletTo}
                    />
                </Spin>
            </form>
        )
    }
}

GoodsTransferForm = reduxForm({
    form: 'GoodsTransferForm',
    enableReinitialize: true,
})(GoodsTransferForm)

const selector = formValueSelector('GoodsTransferForm')
GoodsTransferForm = connect(state => {
    const {
        productCodeFrom,
        productCodeTo,
        outletFrom,
        outletTo,
    } = selector(state, 'productCodeFrom', 'productCodeTo', 'outletFrom', 'outletTo')
    return {
        productCodeFrom,
        productCodeTo,
        outletFrom,
        outletTo,
    }
})(GoodsTransferForm)

export default GoodsTransferForm