import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { allTableColumns } from './config'
import { getPermissionPage } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    RadioCustom,
    InputHero,
    DatePickerCustom,
    SelectHero,
} from 'components'
import { Row, Col, Spin } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { functionAlias } from 'routes'
import swal from '@sweetalert/with-react'

import './style.css'
import moment from 'moment'

const maxLengthBillingNumber = 10

class PurchaseCreatePOSOContainer extends Component {
    constructor(props) {
        super(props)
        const permissionPage = getPermissionPage(functionAlias.createPurchaseOrder)
        this.state = {
            modes: this.getModes(permissionPage),
            modeInAutoCreates: [
                { label: 'Auto PO - SO ', value: 'AutoCreatePOSO' },
                { label: 'PO Only', value: 'AutoCreatePO' },
            ],
            reRunInterfaces: [
                { label: 'Fix', value: 'X' },
                { label: 'Create', value: '' },
            ],
            types: [
                { label: 'Normal', value: 'normal' },
                { label: 'Special', value: 'special' },
            ],
            loading: false,
            dateFormat: 'DD/MM/YYYY',
            widthLabel: '110px',
            reports: [],
            outlets: [],
            filter: {
                type: 'normal',
                mode: 'report',
                modeIn: 'AutoCreatePOSO',
                reRunInterface: 'X',
                billingNumberFrom: '',
                billingNumberTo: '',
                soNumberFrom: '',
                soNumberTo: '',
                billingDateFrom: moment(),
                billingDateTo: null,
                soDateFrom: moment(),
                soDateTo: null,
                outletId: '',
            },
            pagination: {
                limit: 10,
            },
            permissionPage,
        }
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth } = this.props

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, { outletIds: auth.arrayOutlet }).then(res => {
            let outlets = get(res, 'data.results', [])
            if (res.status === 200) {
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }))
                this.setState({
                    outlets,
                    // outlet: outlets[0].value,
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    getModes = (permissionPage) => {
        // const modes = [
        //     { label: 'Report', value: 'report' },
        //     { label: 'Auto Create', value: 'autoCreate' },
        //     { label: 'Rerun Interface', value: 'rerunInterface' },
        // ]
        const modes = []
        if (permissionPage.authDisplay) modes.push({ label: 'Report', value: 'report' })
        if (permissionPage.authCreate) modes.push({ label: 'Auto Create', value: 'autoCreate' })
        if (permissionPage.authUpdate) modes.push({ label: 'Rerun Interface', value: 'rerunInterface' })
        if (permissionPage.authUpdate) modes.push({ label: 'Rerun GR', value: 'RerunGR' })
        if (permissionPage.authUpdate) modes.push({ label: ' Rerun SAP PO', value: 'rerunSAPPO' })
        return modes
    }

    changeFilter = (key, value) => {
        let { filter, columns } = this.state

        if (key === 'mode' && value === 'rerunSAPPO') {
            filter = { ...filter, type: 'special' };
        }

        this.setState({ filter: { ...filter, [key]: value }, columns })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (!filter.mode) return { isError: true, message: 'กรุณาระบุ Mode' }
        if (filter.type === 'normal' && !filter.billingDateFrom) return { isError: true, message: 'กรุณาระบุ Billing Date' }
        if (filter.mode === 'rerunSAPPO' && !filter.soNumberFrom) return { isError: true, message: 'กรุณาระบุ SO Number' }
        if (filter.type === 'special' && !filter.soDateFrom) return { isError: true, message: 'กรุณาระบุ Document Date' }
        if (filter.mode === "report" && filter.billingDateTo && (filter.billingDateTo).isBefore(filter.billingDateFrom)) return { isError: true, message: 'รูปแบบ Billing Date ไม่ถูกต้อง' }
        if (!filter.outletId) return { isError: true, message: 'กรุณาระบุ Outlet' }
        return { isError: false }
    }

    onClickRunBtn = async () => {
        const { auth } = this.props

        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const { filter } = this.state
        const isReportMode = filter.mode === 'report'
        const isAutoCreateMode = filter.mode === 'autoCreate'
        const isTypeNormal = filter.type === 'normal'
        const isTypeSpecial = filter.type === 'special'
        const isRerunGRMode = filter.mode === 'RerunGR'
        const isRerunSAPPOMode = filter.mode === 'rerunSAPPO'
        const body = {
            type: filter.type,
            // mode: filter.mode, //moment(filter.billingNumberFrom).format("YYYY-MM-DD")
            mode: (filter.mode === "autoCreate" ? filter.modeIn : filter.mode),
            fix: (filter.mode === "rerunInterface" ? filter.reRunInterface : ''),
            billingNumberFrom: (isTypeNormal || (isTypeSpecial && isRerunGRMode)) ? filter.billingNumberFrom || '' : '',
            billingNumberTo: (isTypeNormal || (isTypeSpecial && isRerunGRMode)) ? filter.billingNumberTo || '' : '',
            billingDateFrom: (isTypeNormal || (isTypeSpecial && isRerunGRMode)) ? (!isEmpty(filter.billingDateFrom) ? moment(filter.billingDateFrom).format("YYYY-MM-DD") : '') || '' : '',
            billingDateTo: (isTypeNormal && isReportMode) ? (!isEmpty(filter.billingDateTo) ? moment(filter.billingDateTo).format("YYYY-MM-DD") : '') || '' : '',
            soNumberFrom: (isTypeSpecial && !isRerunGRMode) ? filter.soNumberFrom || '' : '',
            soNumberTo: (isTypeSpecial && !isRerunGRMode) ? filter.soNumberTo || '' : '',
            soDateFrom: (isTypeSpecial && !isRerunGRMode) ? (!isEmpty(filter.soDateFrom) ? moment(filter.soDateFrom).format("YYYY-MM-DD") : '') || '' : '',
            soDateTo: (isTypeSpecial && isReportMode) ? (!isEmpty(filter.soDateTo) ? moment(filter.soDateTo).format("YYYY-MM-DD") : '') || '' : '',
            outletId: filter.outletId,
            username: auth.userId,
        }

        await apiServices.callApi('post', API_PATH.POST_CREATE_PO_FRANCHISE_AND_SO_DIST, body).then(res => {
            if (res.status === 200) {
                //console.log('res', res)
                if (isAutoCreateMode) {
                    swal('Success', get(res, 'data.message'), 'success')
                    // display report
                    this.setState({ reports: get(res, 'data.results') })
                } else if (isReportMode) {
                    // display report
                    this.setState({ reports: get(res, 'data.results') })
                } else {
                    swal('Success', get(res, 'data.message'), 'success')
                }
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            loading,
            columns,
            modes,
            modeInAutoCreates,
            reRunInterfaces,
            types,
            outlets,
            reports,
            filter,
            dateFormat,
            pagination,
            widthLabel,
            permissionPage,
        } = this.state

        return (
            <div className="purchase-create-po-so-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="Program Auto Create PO Franchise / SO SCG Dist."
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row style={{ padding: '10px 0' }}>
                            <Col sm={24}>
                                <RadioCustom
                                    options={types}
                                    value={filter.type}
                                    label="Type"
                                    widthDefault={widthLabel}
                                    onChange={(e) => this.changeFilter('type', e.target.value)}
                                    disabled={filter.mode === 'rerunSAPPO'}
                                />
                            </Col>
                        </Row>
                        <Row style={{ padding: '10px 0' }}>
                            <Col sm={24}>
                                <RadioCustom
                                    options={modes}
                                    value={filter.mode}
                                    label="Mode"
                                    widthDefault={widthLabel}
                                    onChange={(e) => this.changeFilter('mode', e.target.value)}
                                />
                            </Col>
                        </Row>
                        {filter.mode === "autoCreate" ? <Row style={{ padding: '10px 0' }}>
                            <Col sm={24}>
                                <RadioCustom
                                    options={modeInAutoCreates}
                                    value={filter.modeIn}
                                    label="Auto Create type"
                                    widthDefault={widthLabel}
                                    onChange={(e) => this.changeFilter('modeIn', e.target.value)}
                                />
                            </Col>
                        </Row> : ''}
                        {filter.mode === "rerunInterface" ? <Row style={{ padding: '10px 0' }}>
                            <Col sm={24}>
                                <RadioCustom
                                    options={reRunInterfaces}
                                    value={filter.reRunInterface}
                                    label="Rerun Interface type"
                                    widthDefault={widthLabel}
                                    onChange={(e) => this.changeFilter('reRunInterface', e.target.value)}
                                />
                            </Col>
                        </Row> : ''}
                        {filter.type === 'normal' || filter.mode === "RerunGR" ?
                            <Row>
                                <Col sm={9}>
                                    <InputHero
                                        label="Billing Number"
                                        widthDefault={widthLabel}
                                        value={filter.billingNumberFrom}
                                        onChange={(e) => this.changeFilter('billingNumberFrom', e.target.value)}
                                        maxLength={maxLengthBillingNumber}
                                    />
                                </Col>
                                <Col sm={9}>
                                    <InputHero
                                        label="to"
                                        widthDefault={widthLabel}
                                        right
                                        value={filter.billingNumberTo}
                                        onChange={(e) => this.changeFilter('billingNumberTo', e.target.value)}
                                        maxLength={maxLengthBillingNumber}
                                    />
                                </Col>
                            </Row> :
                            <Row>
                                <Col sm={9}>
                                    <InputHero
                                        label="SO Number"
                                        widthDefault={widthLabel}
                                        value={filter.soNumberFrom}
                                        onChange={(e) => this.changeFilter('soNumberFrom', e.target.value)}
                                        maxLength={maxLengthBillingNumber}
                                    />
                                </Col>
                                {filter.mode !== "rerunSAPPO" &&
                                    <Col sm={9}>
                                        <InputHero
                                            label="to"
                                            widthDefault={widthLabel}
                                            right
                                            value={filter.soNumberTo}
                                            onChange={(e) => this.changeFilter('soNumberTo', e.target.value)}
                                            maxLength={maxLengthBillingNumber}
                                        />
                                    </Col>
                                }
                            </Row>
                        }
                        {filter.type === 'normal' || filter.mode === "RerunGR" ?
                            <Row>
                                <Col sm={9}>
                                    <DatePickerCustom
                                        widthDefault={widthLabel}
                                        label="Billing Date"
                                        value={filter.billingDateFrom}
                                        format={dateFormat}
                                        allowClear={false}
                                        onChange={(date) => this.changeFilter('billingDateFrom', date)}
                                    />
                                </Col>
                                {filter.mode === 'report' && <Col sm={9}>
                                    <DatePickerCustom
                                        widthDefault={widthLabel}
                                        label="to"
                                        right
                                        value={filter.billingDateTo}
                                        format={dateFormat}
                                        allowClear={false}
                                        onChange={(date) => this.changeFilter('billingDateTo', date)}
                                    />
                                </Col>}
                            </Row> :
                            <Row>
                                <Col sm={9}>
                                    <DatePickerCustom
                                        widthDefault={widthLabel}
                                        label="SO Date"
                                        value={filter.soDateFrom}
                                        format={dateFormat}
                                        allowClear={false}
                                        onChange={(date) => this.changeFilter('soDateFrom', date)}
                                    />
                                </Col>
                                {filter.mode === 'report' && <Col sm={9}>
                                    <DatePickerCustom
                                        widthDefault={widthLabel}
                                        label="to"
                                        right
                                        value={filter.soDateTo}
                                        format={dateFormat}
                                        allowClear={false}
                                        onChange={(date) => this.changeFilter('soDateTo', date)}
                                    />
                                </Col>}
                            </Row>
                        }

                        <Row>
                            <Col sm={9}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    label="Outlet ID"
                                    options={outlets}
                                    value={filter.outletId}
                                    onChange={(val) => this.changeFilter('outletId', val)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={8} type="flex" align="middle" justify="end">
                            <Col>
                                <ButtonCustom
                                    icon="file-excel"
                                    text="Export"
                                    green
                                    disabled={!permissionPage.authExport || filter.mode !== 'report' || (filter.mode === 'report' && isEmpty(reports))}
                                />
                            </Col>
                            <Col>
                                <ButtonCustom
                                    icon="caret-right"
                                    text="Run"
                                    onClick={this.onClickRunBtn}
                                />
                            </Col>
                        </Row>
                        {(filter.mode === 'report' || filter.mode === 'autoCreate'|| filter.mode === 'rerunSAPPO') && <div className="table-layout">
                            <TableCustom
                                columns={filter.type === 'special' ? [...allTableColumns['Special']] : [...allTableColumns['Normal']]}
                                small
                                scroll={{ x: "max-content" }}
                                data={reports}
                                rowKey={(row, index) => index}
                                pagination={{
                                    pageSize: pagination.limit,
                                    showTotal: (total, range) => (`Showing ${range[0]} to ${range[1]} of ${total} entries`),
                                }}
                            />
                        </div>}
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseCreatePOSOContainer)