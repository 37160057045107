import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.css'
import { authData } from 'actions/actionsCreator';
import moment from 'moment'
import swal from '@sweetalert/with-react';
import { Row, Col, Spin } from "antd"
import {
    DatePickerCustom,
} from "components"
import {
    TitleHead,
    TableCustom,
    ButtonCustom,
    TextAreaCustom,
    SelectCustom,
    NumberFormatCustom,
    InputSearchCustom,
} from "components/common"
import { get, first, isEmpty } from 'lodash';
import { ReservationSearchModal, } from 'components/feature';
import { apiServices, API_PATH } from "apiServices";
import { functionAlias } from 'routes'
import { getPermissionPage, sortDataColumn } from 'helpers'

class GoodsIssueRefReservationContainer extends Component {
    state = {
        loading: false,
        dateFormat: 'DD/MM/YYYY',
        outletOptions: [],
        header: {
            srNo: '',
            docDate: moment(),
            remarks: '',
            outletId: '',
        },
        dataList: [],
        foundSRDoc: false,
        isShowModal: false,

        modelRequest: {
            IM_MODE: 'C',
            IM_MOVE_TYPE: '201',
            IM_TYPE_REF_DOC: 'R09',
            IM_GOODSMVT_HEADER: {
                item: [],
            },
            IT_GOODSMVT_ITEM: {
                item: [],
            },
            IT_REMARK: {
                item: [],
            },
        },
        permissionPage: getPermissionPage(functionAlias.inventoryGIRefReservation),
        columns: [
            {
                title: 'รายการ',
                dataIndex: 'itemNo',
                key: 'itemNo',
                sorter: (a, b) => sortDataColumn(a, b, 'itemNo'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
                sorter: (a, b) => sortDataColumn(a, b, 'productCode'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                sorter: (a, b) => sortDataColumn(a, b, 'productName'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'หน่วยนับ',
                dataIndex: 'baseUnit',
                key: 'baseUnit',
                sorter: (a, b) => sortDataColumn(a, b, 'baseUnit'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'จำนวน',
                dataIndex: 'qty',
                key: 'qty',
                sorter: (a, b) => sortDataColumn(a, b, 'qty'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'จำนวนค้างเบิก',
                dataIndex: 'oamount',
                key: 'oamount',
                sorter: (a, b) => sortDataColumn(a, b, 'oamount'),
                ellipsis: true,
                align: 'center',
                render: (value, full, row) => {
                    return full.qty - full.qtyGI;
                },
            },
            {
                title: 'ที่จัดเก็บ',
                dataIndex: 'sloc',
                key: 'sloc',
                sorter: (a, b) => sortDataColumn(a, b, 'sloc'),
                ellipsis: true,
                align: 'center',
            },
            {
                title: 'จำนวนที่เบิก',
                dataIndex: 'requestQty',
                key: 'requestQty',
                align: 'center',
                width: '100px',
                render: (value, full, row) => (
                    <NumberFormatCustom
                        name="requestQty"
                        value={value}
                        onChange={(e) => this.handleChangeItem(row, 'requestQty', Number(`${e.target.value}`.replace(/,/g, '')))}
                    />
                )
            },
        ],
    };

    componentDidMount() {
        this.fetchDataDefault();
    }

    fetchDataDefault = async () => {
        const { auth } = this.props;
        let { outletOptions, header } = this.state;
        let paramsOutlet = {
            outletIds: auth.userOutletModelList.map((data) => (
                data.outletId
            ))
        };

        //ร้านค้า Outlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet).then(res => {
            let results = get(res, 'data.results');
            outletOptions = results.map(data => ({
                label: `${data.outletId} - ${data.outletName}`,
                value: data.outletId,
            }));

            if (!isEmpty(outletOptions) && outletOptions.length > 0) {
                const defaultOutlet = first(outletOptions);
                header = {
                    ...header,
                    outletId: defaultOutlet.value,
                }
            }

            this.setState({ outletOptions, header });
        }).catch(error => {
            const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลร้านค้า!')
            swal('Error', message, 'error')
        });

    }

    handleChangeHeader = (key, val) => {
        const { header } = this.state
        this.setState({ header: { ...header, [key]: val } });
    }

    handleChangeItem = (row, key, val) => {
        let { dataList } = this.state;
        console.log(`row==>${row}, key==>${key}, val==>${val}`);
        console.log(`Befor items[${row}]`, dataList[row]);
        dataList[row] = {
            ...dataList[row],
            [key]: val
        };
        console.log(`After items[${row}]`, dataList[row]);
        this.setState({ dataList });
    }

    handleShowModal = () => {
        this.setState({ isShowModal: true });
    }

    handleCloseModal = () => {
        this.setState({ isShowModal: false });
    }

    setHeaderPanel = (head) => {
        let { header } = this.state;
        header = {
            ...header,
            srNo: head.stockRequestNo,
            docDate: moment(),
            remarks: head.remark,
        }

        this.setState({ header, foundSRDoc: true });
    }

    onSelected = (item) => {
        const { header } = this.state
        this.setState({ header: { ...header, srNo: item.stockRequestNo } }, () => this.handleSubmitSearch());
        this.handleCloseModal();
    }

    handleSubmitSearch = async (e) => {
        const { header } = this.state;

        if (isEmpty(header.srNo)) {
            return;
        }

        this.setState({ loading: true });

        let params = {
            stockRequestNo: header.srNo,
            outletId: header.outletId,
        };

        await apiServices.callApi('post', API_PATH.POST_GET_INVENTORY_REQUEST_ITEM, params).then(res => {
        
            let dataList = res.data.results.map((data, i) => ({
                ...data,
                key: i,
            }));
            this.setState({
                dataList
            });
            if (dataList != null && dataList.length > 0) {
                this.setHeaderPanel(dataList[0].inventoryRequestHeadModel);
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });
        this.setState({ loading: false });
    }

    handleSubmit = async () => {
        this.setState({ loading: true });
        const { auth } = this.props
        let { header, dataList } = this.state;

        if (!this.validateCreateGI(dataList)) {
            this.setState({ loading: false });
            return;
        }

        const params = {
            outletId: header.outletId,
            stockRequestNo: header.srNo,
            documentDate: header.docDate,
            updatedBy: auth.userId,
            remark: header.remarks,
            InventoryRequestItemModels: dataList,
        }

        await apiServices.callApi('post', API_PATH.POST_UPDATE_INVENTORY_REQUEST, params).then(async res => {
            const matDoc = get(res,'data.result.eX_MATDOC',null)
            swal("Success", `เลขที่เอกสาร ได้รับการบันทึกเรียบร้อย`, "success");
            swal({
                title: 'Success',
                icon: 'success',
                text: `เลขที่เอกสาร ${header.srNo} ได้รับการบันทึกเรียบร้อย`,
                buttons: this.state.permissionPage.authPrint ? {
                    create: {
                        text: `พิมพ์`,
                        value: {
                            "matDoc": matDoc,
                            "outlet": header.outletId,
                            "movementTypes": [dataList[0].inventoryRequestHeadModel.transactionType]                           
                        }
                    },
                    cancel: 'ปิด',
                } : { cancel: 'ปิด' },
            }).then(async (value) => {
                if (value) {                    
                    const reportRDF = await apiServices.callApi('post', `${API_PATH.GET_REPORT_REQUISITION}`, value, { responseType: 'blob' })
                    console.log(reportRDF)
                    const urlOpen = window.URL.createObjectURL(reportRDF.data)
                    window.open(urlOpen)
                }
            })
            this.handleSubmitSearch();
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล!')
            swal('Error', `ไม่สามารถบันทึกเอกสารได้ กรุณาตรวจสอบข้อมูลเพิ่มเติม`, 'error');
        });
        this.setState({ loading: false });
    }

    validateCreateGI = (grItems) => {
        const itemOverQty = grItems.filter(item => item.requestQty > (item.qty - item.qtyGI));
        if (!isEmpty(itemOverQty) && itemOverQty.length > 0) {
            swal('Error', `รายการ ${first(itemOverQty).productCode}: ${first(itemOverQty).productName} จำนวนที่เบิกมากกว่าจำนวนที่มี!`, 'error');
            return false;
        }

        return true
    }

    handleKeydownDONumber = () => {
        this.handleSubmitSearch();
    }

    render() {
        const {
            loading,
            dateFormat,
            isShowModal,
            foundSRDoc,
            header,
            dataList,
            columns,
            outletOptions,
            permissionPage,
        } = this.state;

        return (
            <div className="goods-receive-container">
                <Spin spinning={loading}>
                    <TitleHead text="ตัดจ่ายใบเบิกใช้" icon="export" />
                    <div className="middle-content">
                        <Row gutter={20}>
                            <Col md={4}>
                                ร้าน*
                            </Col>
                            <Col md={8}>
                                <SelectCustom
                                    options={outletOptions}
                                    pleaseChoose
                                    onChange={(value) => this.handleChangeHeader('outletId', value)}
                                    value={header.outletId}
                                    name="outletId"
                                    disabled={foundSRDoc}
                                />
                            </Col>
                            <Col md={4}>
                                เลขที่ใบขอเบิกใช้
                            </Col>
                            <Col md={8}>
                                <InputSearchCustom
                                    name='srNo'
                                    placeholder="20000015267"
                                    value={header.srNo}
                                    onChange={(e) => this.handleChangeHeader('srNo', e.target.value)}
                                    onClickSearch={() => this.handleShowModal()}
                                    //handleSubmitSearch={this.handleSubmitSearchPurchaseOrderSAPPI}
                                    disabledButton={foundSRDoc}
                                    disabled={foundSRDoc}
                                    onSubmit={this.handleKeydownDONumber}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={4}>
                                วันตัดจ่ายสินค้า
                            </Col>
                            <Col md={8}>
                                <DatePickerCustom
                                    name="docDate"
                                    widthAll="100%"
                                    value={header.docDate}
                                    format={dateFormat}
                                    allowClear={false}
                                    onChange={(date) => this.handleChangeHeader('docDate', date)}
                                    // disabledDate={true}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={20}>
                            <Col md={4}>
                                หมายเหตุ
                            </Col>
                            <Col md={20}>
                                <TextAreaCustom
                                    name="remarks"
                                    value={header.remarks}
                                    onChange={(e) => this.handleChangeHeader('remarks', e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24} className="search-box">
                                <ButtonCustom
                                    icon="search"
                                    type="primary"
                                    text="ค้นหา"
                                    small
                                    onClick={this.handleSubmitSearch}
                                    disabled={foundSRDoc}
                                />
                            </Col>
                        </Row>

                        <div className="table-layout">
                            <TableCustom columns={columns} scroll={{ x: "max-content" }} data={dataList} rowKey={(row, index) => index} pagination={false} small />
                        </div>
                    </div>

                    <div className="bottom-content">
                        <div className="left-zone">
                            <div className="button">
                                <ButtonCustom text='ยกเลิก' icon='close' type='danger' />
                            </div>
                        </div>
                        <div className="right-zone">
                            <div className="button">
                                <ButtonCustom
                                    text='บันทึก'
                                    icon='save'
                                    type="primary"
                                    onClick={this.handleSubmit}
                                    disabled={!permissionPage.authCreate}
                                />
                            </div>
                        </div>
                    </div>
                </Spin>

                <ReservationSearchModal
                    isShowModal={isShowModal}
                    closeModal={this.handleCloseModal}
                    onSelected={this.onSelected}
                    outlets={outletOptions}
                    outletSelected={header.outletId}
                />

            </div>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodsIssueRefReservationContainer);