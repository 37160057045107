import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, map } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import FileSaver from 'file-saver'
import {
    formatNumber,
    getPermissionPage,
} from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    DatePickerCustom,
    InputCustom,
    SelectCustom,
    CheckboxCustom,
} from 'components/common'
import {
    CustomerSearchModal,
} from 'components/feature'
import { Row, Col, Spin, message } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { ROUTH_PATH, functionAlias } from 'routes'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import './style.css'

const formatDateAPI = 'YYYY-MM-DD HH:mm:ss'
const widthLabel = '100px'

class ReportCheckPOSContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: 'Status',
                    // dataIndex: 'interfaceStatus',
                    // key: 'interfaceStatus',
                    dataIndex: 'status',
                    key: 'status',
                    align: 'center',
                    render: (props) => {
                        let className = ''
                        if (props === 'E') {
                            className = 'is-error'
                        } else if (props === 'S') {
                            className = 'is-success'
                        }
                        return (
                            props && <div className={`interface-status-circle ${className ? className : ''}`} />
                        )
                    },
                },
                {
                    title: 'ร้าน',
                    dataIndex: 'outletId',
                    key: 'outletId',
                    align: 'center',
                },
                {
                    title: 'เลขที่ใบเสร็จรับเงินอย่างย่อ',
                    dataIndex: 'refDocNo',
                    key: 'refDocNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'รายการ',
                    dataIndex: 'index',
                    key: 'index',
                    align: 'center',
                    render: (props, row, index) => (
                        <div className="text-left">{index + 1}</div>
                    ),
                },
                {
                    title: 'วันที่รายการ',
                    dataIndex: 'createdDatetime',
                    key: 'createdDatetime',
                    align: 'center',
                    render: (props) => (
                        props && <div className="text-left">
                            {moment(props).format('DD/MM/YYYY')}
                        </div>
                    ),
                },
                {
                    title: 'รหัสสินค้า',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'Barcode',
                    dataIndex: 'barcode',
                    key: 'barcode',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'คำอธิบายสินค้า',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'ปริมาณขาย',
                    dataIndex: 'qty',
                    key: 'qty',
                    align: 'center',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'หน่วยขาย',
                    dataIndex: 'unit',
                    key: 'unit',
                    align: 'center',
                },
                {
                    title: 'ราคารวม',
                    dataIndex: 'totalAmount',
                    key: 'totalAmount',
                    align: 'center',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'ปริมาณสต็อค',
                    dataIndex: 'stockATP',
                    key: 'stockATP',
                    align: 'center',
                    render: (props) => (
                        props && <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'จำนวนที่สต็อคตัดได้',
                    dataIndex: 'stockATP',
                    key: 'stockATP2',
                    align: 'center',
                    render: (props) => (
                        props && <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'ผลต่างสต็อค',
                    dataIndex: 'stockDiff',
                    key: 'stockDiff',
                    align: 'center',
                    render: (props, row) => (
                        <div className="text-right">{formatNumber((row.stockATP || 0) - (row.qty || 0), 2)}</div>
                    ),
                },
                {
                    title: 'หน่วยสต็อค',
                    dataIndex: 'stockUnit',
                    key: 'stockUnit',
                    align: 'center',
                },
                {
                    title: 'ข้อผิดพลาด',
                    dataIndex: 'interfaceMessage',
                    key: 'interfaceMessage',
                    align: 'center',
                    width: 550,
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
            ],
            loading: false,
            documents: [],
            outlets: [],
            filter: {
                documentDateFrom: moment(),
                documentDateTo: moment(),
                outletIds: [],
                documentNoFrom: '',
                documentNoTo: '',
                documentIsError: true,
            },
            permissionPage: getPermissionPage(functionAlias.reportCheckPOS),
        }
    }

    componentDidMount() {
        if (!this.state.permissionPage.authDisplay) {
            swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
                this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
            })
            return
        }
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })

        const { auth } = this.props

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({ outlets })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    onSelectedCustomer = (customer) => {
        this.setState({
            filter: {
                ...this.state.filter,
                customerId: customer.customerId,
                customerName: customer.customerNameTH,
            },
        })
        this.closeModal()
        this.changeCustomerIdSearch(customer.customerId)
    }

    searchCustomer = async (customerId) => {
        if (!customerId) {
            message.error('กรุณากรอกรหัสลูกค้า')
            return
        }

        this.setState({ loading: true })
        const { filter: { outletIds } } = this.state
        const url = `${API_PATH.GET_CUSTOMER}?OutletId=${outletIds.join(',')}&customerId=${customerId}&RequireSoldTo=true`

        await apiServices.callApi('get', url).then(res => {
            const customer = get(res, 'data.results.0')
            if (res.status === 200 && !isEmpty(customer)) {
                this.onSelectedCustomer(customer)
            } else {
                message.error('ไม่พบข้อมูลลูกค้า')
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (filter.documentDateTo && filter.documentDateFrom && (filter.documentDateTo).isBefore(filter.documentDateFrom)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }
        return { isError: false }
    }

    getBody = (filter) => {
        const { outlets } = this.state
        return {
            documentDateFrom: filter.documentDateFrom && filter.documentDateFrom.startOf('day').format(formatDateAPI),
            documentDateTo: filter.documentDateTo && filter.documentDateTo.endOf('day').format(formatDateAPI),
            outletIds: isEmpty(filter.outletIds) ? map(outlets, 'outletId') : filter.outletIds,
            documentNoFrom: filter.documentNoFrom,
            documentNoTo: filter.documentNoTo,
            errorCheck: filter.documentIsError,
        }
    }

    searchDocument = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        await apiServices.callApi('post', API_PATH.POST_SALE_ORDER_TRACKING_POS_SEARCH, body).then(res => {
            let documents = get(res, 'data.results', [])
            if (res.status === 200) {
                this.setState({ documents })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    exportDocument = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        await apiServices.callApi('post', API_PATH.POST_EXPORT_SALE_ORDER_TRACKING_POS, body, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `ReportSaleOrderTrackingPOSFile-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    reprocessDocuments = async () => {
        const { documents } = this.state
        this.setState({ loading: true })
        const body = documents.map(document => ({
            SoNumber: document.soNumber,
            OutletId: document.outletId,
            RefDocNo: document.refDocNo,
        }))
        await apiServices.callApi('post', API_PATH.POST_REPAIR_TRANSACTION_POS_INTERFACE, body).then(res => {
            if (res.status === 200) {
                swal('Success', 'Reprocess สำเร็จ', 'success')
                this.setState({ documents: [] })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Reprocess กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            columns,
            outlets,
            loading,
            filter,
            isShowModal,
            modalType,
            documents,
            permissionPage,
        } = this.state

        return (
            <div className="report-check-pos-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="โปรแกรมตรวจสอบ POS Interface"
                        icon="check-square"
                    />
                    <div className="middle-content">
                        <Row gutter={15}>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="วันที่เอกสาร"
                                    widthLabel={widthLabel}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('documentDateFrom', val)}
                                    value={filter.documentDateFrom}
                                />
                            </Col>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="ถึงวันที่เอกสาร"
                                    widthLabel={widthLabel}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('documentDateTo', val)}
                                    value={filter.documentDateTo}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={7}>
                                <InputCustom
                                    label="จากเลขที่เอกสาร"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('documentNoFrom', e.target.value)}
                                    value={filter.documentNoFrom}
                                />
                            </Col>
                            <Col sm={7}>
                                <InputCustom
                                    label="ถึงเลขที่เอกสาร"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('documentNoTo', e.target.value)}
                                    value={filter.documentNoTo}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={7}>
                                <SelectCustom
                                    label="ร้านค้า"
                                    widthLabel={widthLabel}
                                    options={outlets}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={4}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    small
                                    onChange={(val) => this.changeFilter('outletIds', val)}
                                    value={filter.outletIds}
                                    placeholder='ทั้งหมด'
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={7}>
                                <CheckboxCustom
                                    text="เลือกเฉพาะรายการที่ ERROR เท่านั้น"
                                    small
                                    onChange={(e) => this.changeFilter('documentIsError', e.target.checked)}
                                    checked={filter.documentIsError}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} className="search-box text-right">
                                <div className="action-box">
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.searchDocument}
                                            small
                                            icon="search"
                                            text="ค้นหา"
                                            disabled={!permissionPage.authCreate}
                                        />
                                    </div>
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.exportDocument}
                                            small
                                            icon="file-excel"
                                            green
                                            text="Export Excel"
                                            disabled={isEmpty(documents) || !permissionPage.authExport}
                                        />
                                    </div>
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.reprocessDocuments}
                                            small
                                            text="ประมวลผลใหม่"
                                            icon="redo"
                                            yellow
                                            disabled={isEmpty(documents) || !permissionPage.authCreate}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom
                                height="calc(100vh - 316px)"
                                columns={columns}
                                data={documents}
                                isFullContent
                                rowKey={(row, index) => index}
                                small
                                pagination={{ pageSize: 30 }}
                            />
                        </div>
                    </div>
                    <CustomerSearchModal
                        isShowModal={isShowModal && modalType === 'customer'}
                        handleCloseModal={this.closeModal}
                        onSelected={this.onSelectedCustomer}
                        outletId={filter.outletIds.join(',')}
                    />
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportCheckPOSContainer)