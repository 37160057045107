import React, { Component } from 'react'
import { SelectHeroStyled } from "./styled"
import { Select } from 'antd';

const { Option } = Select;

export class SelectHero extends Component {
    render() {
        const { label,
            right,
            small,
            widthSmall,
            options,
            defaultvalue,
            onChange,
            value,
            widthDefault,
            mode,
            placeholder,
            pleaseChoose,
            firstChoose,
            optionsDefault,
            allowClear,
            maxTagCount,
            disabled,
            labelEnd,
            labelEndAlign,
            widthAll,
        } = this.props
        return (
            <SelectHeroStyled small={small} widthSmall={widthSmall} widthDefault={widthDefault} mode={mode}>
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div className="label-style" style={{ textAlign: right ? 'right' : '' }} >
                                {label}
                            </div>
                        }
                        <Select
                            defaultValue={defaultvalue ? defaultvalue : firstChoose && firstChoose === true ? options && options.length > 0 ? options[0].value : undefined : undefined}
                            style={{
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    widthAll ? widthAll : ''
                            }}
                            onChange={onChange}
                            size={small ? 'small' : 'default'}
                            /*value={value ? value : firstChoose && firstChoose === true ? options && options.length > 0 ? options[0].value : undefined : undefined}*/
                            value={value}
                            placeholder={placeholder ? placeholder : pleaseChoose && pleaseChoose === true ? "-- กรุณาเลือก --" : undefined}
                            mode={mode}
                            allowClear={allowClear}
                            maxTagCount={maxTagCount}
                            disabled={disabled}
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                optionsDefault && optionsDefault.map((option, i) => (
                                    <Option key={i} value={option.value} label={option.label} >{option.label}</Option>
                                ))
                            }
                            {
                                options && options.map((option, i) => (
                                    <Option key={i} value={option.value} label={option.label} >{option.label}</Option>
                                ))
                            }
                        </Select>
                        {
                            labelEnd &&
                            <div className="label-style" style={{ paddingLeft: '5px', textAlign: labelEndAlign || '' }} >
                                {labelEnd}
                            </div>
                        }
                    </div>
                </div>
            </SelectHeroStyled >
        )
    }
}
