import React, { Component } from 'react'
import { Row, Col, Spin } from 'antd'
import './style.css'
import moment from 'moment'
import { apiServices, API_PATH } from "apiServices";
import swal from '@sweetalert/with-react';
import { get, first, isEmpty } from 'lodash';
import {
  ModalCustom,
  InputCustom,
  TableCustom,
  ButtonCustom,
  DatePickerCustom,
} from "components/common"

class GoodsReturnSearchModal extends Component {
  state = {
    loading: false,
    dateFormat: 'DD/MM/YYYY',

    filter: {
      docDateFrom: moment(),
      docDateTo: moment(),
      venderNo: '',
    },
    dataItems: [],

    columns: [
      {
        title: '#',
        align: 'center',
        dataIndex: '#',
        key: '#',
        width: 100,
        render: (value, full, index) =>
          full.row > 0 && this.state.dataItems[full.row - 1].mblnr === full.mblnr ? '' : <ButtonCustom small onClick={() => this.props.onSelected(full)} text="เลือก" />
      },
      {
        title: 'วันที่เอกสาร',
        align: 'center',
        dataIndex: 'bldat',
        key: 'bldat',
        width: 80,
        render: (value, full, index) =>
          full.row > 0 && this.state.dataItems[full.row - 1].mblnr === full.mblnr ? '' : moment(full.bldat).format('DD/MM/YYYY')
      },
      {
        title: 'ใบรับสินค้า',
        align: 'center',
        dataIndex: 'mblnr',
        key: 'mblnr',
        width: 85,
        render: (value, full, index) =>
          full.row > 0 && this.state.dataItems[full.row - 1].mblnr === full.mblnr ? '' : full.mblnr
      },
      {
        title: 'ชื่อผู้สั่งซื้อ',
        align: 'center',
        dataIndex: 'kunnR_T',
        key: 'kunnR_T',
        width: 100,
        render: (value, full, index) =>
          full.row > 0 && this.state.dataItems[full.row - 1].mblnr === full.mblnr ? '' : full.kunnR_T
      },
      {
        title: 'รหัสสินค้า',
        align: 'center',
        dataIndex: 'matnr',
        key: 'matnr',
        width: 80,
      },
      {
        title: 'ชื่อสินค้า',
        align: 'left',
        dataIndex: 'maktx',
        key: 'maktx',
        width: 200,
      },
      {
        title: 'ที่เก็บ',
        align: 'center',
        dataIndex: 'lgort',
        key: 'lgort',
        width: 70,
      },
      {
        title: 'จำนวน',
        align: 'right',
        dataIndex: 'menge',
        key: 'menge',
        width: 100,
      },
      {
        title: 'หน่วย',
        align: 'center',
        dataIndex: 'meins',
        key: 'meins',
        width: 70,
      },
    ],
  }

  handleChangeHeader = (key, val) => {
    console.log(`key: ${key}, val: ${val}`);
    const { filter } = this.state;
    this.setState({ filter: { ...filter, [key]: val } });
  }

  searchPurchaseOrderSAPPI = async () => {
    const { filter } = this.state;
    const { outletId, docType, } = this.props;
    this.setState({ loading: true });
    let dataItems = [];
    //Get PO Detail form SAP.
    // const urlSAP = `${API_PATH.POST_GET_DAILY_MATERIAL_MOVEMENT}?&Outlet=${outletId}&DocDateFrom=${moment(filter.docDateFrom).format('YYYY-MM-DD')}&DocDateTo=${moment(filter.docDateTo).format('YYYY-MM-DD')}&DocType=${docType}`;
    const urlSAP = API_PATH.POST_GET_DAILY_MATERIAL_MOVEMENT
    const body = {
      outlet: outletId,
      docDateFrom: moment(filter.docDateFrom).format('YYYY-MM-DD'),
      docDateTo: moment(filter.docDateTo).format('YYYY-MM-DD'),
      docType,
    }
    console.log(`urlSAP==> ${urlSAP}`);
    await apiServices.callApi('post', urlSAP, body).then(res => {
      let result = get(res, 'data.result');
      let matMovement = get(result, 'eT_MAT_MOVEMENT.item');

      if(!isEmpty(matMovement)){
        dataItems = matMovement.map((data, i) => ({
          key: i,
          row: i,
          ...data
        }));
      }
      //console.log(`dataItems ==>`,dataItems);
      this.setState({ dataItems, });
    }).catch(error => {
      console.log(`error catch ==>`, error);
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างค้นหาข้อมูลใบสั่งซื้อ!')
      swal('Error', message, 'error')
    });
    this.setState({ loading: false });
  }

  checkSoSpecialOrder = (dataPOItem, dataPOAccounts) => {
    let sdDoc = '';
    console.log(`dataPOItem ==>`, dataPOItem);
    console.log(`dataPOAccounts ==>`, dataPOAccounts);
    const dataPOAccount = dataPOAccounts.filter(poAccount => poAccount.pO_NUMBER === dataPOItem.pO_NUMBER && poAccount.pO_ITEM === dataPOItem.pO_ITEM);
    if (!isEmpty(dataPOAccount) && dataPOAccount.length > 0)
      sdDoc = first(dataPOAccount).SD_DOC;
    return sdDoc;
  }

  render() {
    const {
      isShowModal,
      handleCloseModal,
      title,
      width,
      className,
    } = this.props
    const {
      loading,
      dateFormat,
      filter,

      dataItems,
      columns,
    } = this.state

    return (
      <ModalCustom
        isShowModal={isShowModal}
        onCloseModal={handleCloseModal}
        title={title || 'ค้นรายการเอกสารใบรับสินค้า'}
        width={width || '70%'}
        className={`modal-data ${className}`}
        destroyOnClose={true}
      >
        <Spin spinning={loading}>
          <Row gutter={8}>
            <Col sm={16}>
              <InputCustom
                label="รหัสผู้จำหน่าย"
                name="venderNo"
                small
                widthLabel='100px'
                value={filter.venderNo}
                onChange={(e) => this.handleChangeHeader('venderNo', e.target.value)}
              />
            </Col>
          </Row>

          <Row gutter={8}>
            <Col sm={8}>
              <DatePickerCustom
                label="วันที่เอกสาร"
                name="docDateFrom"
                widthLabel='100px'
                value={filter.docDateFrom}
                format={dateFormat}
                small
                onChange={(date) => this.handleChangeHeader('docDateFrom', date)}
              />
            </Col>
            <Col sm={8}>
              <DatePickerCustom
                label="ถึง"
                name="docDateTo"
                widthLabel='100px'
                value={filter.docDateTo}
                format={dateFormat}
                small
                onChange={(date) => this.handleChangeHeader('docDateTo', date)}
              />
            </Col>
            <Col sm={8} style={{ textAlign: 'right', paddingTop: '10px' }}>
              <ButtonCustom
                icon="search"
                type="primary"
                text="ค้นหา"
                small
                onClick={this.searchPurchaseOrderSAPPI} />
            </Col>
          </Row>

          <Row style={{ paddingTop: 20 }}>
            <Col sm={24}>
              <TableCustom
                columns={columns}
                data={dataItems}
                rowKey={(row, index) => index}
                scroll={{ x: "max-content", y: "calc(100vh - 400px)" }}
              />
            </Col>
          </Row>
        </Spin>
      </ModalCustom>
    )
  }
}

export default (GoodsReturnSearchModal);
