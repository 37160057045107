import React, { Component } from 'react'
import { Modal } from 'antd'
import { ButtonCustom } from 'components/common'
import './style.css'

export class ModalCustom extends Component {
    render() {
        const {
            isShowModal,
            onClickSave,
            onCloseModal,
            children,
            width,
            title,
            footer,
            textSuccess,
            textCancel,
            className,
            disabledSuccess,
            ...rest
        } = this.props

        return (
            <Modal
                {...rest}
                wrapClassName={`modal-custom ${className || ''}`}
                visible={isShowModal}
                onCancel={onCloseModal}
                width={width || '65%'}
                closable
                title={title}
                footer={
                    footer ?
                        footer === true ?
                            <div className="modal-custom-footer">
                                <div className="footer-button">
                                    <ButtonCustom
                                        onClick={onClickSave}
                                        text={textSuccess ? textSuccess : 'บันทึก'}
                                        disabled={disabledSuccess ? disabledSuccess : false}
                                    />
                                </div>
                                <div className="footer-button">
                                    <ButtonCustom
                                        onClick={onCloseModal}
                                        text={textCancel ? textCancel : 'ยกเลิก'}
                                        type="danger"
                                    />
                                </div>
                            </div>
                            :
                            footer
                        :
                        false
                }
            >
                {children}
            </Modal>
        )
    }
}
