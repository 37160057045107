import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, sumBy, map } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { formatNumber } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    DatePickerCustom,
    InputCustom,
    SelectCustom,
    RadioCustom,
    CheckboxGroupCustom,
} from 'components/common'
import { Row, Col, Spin, Collapse } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import FileSaver from 'file-saver'
import moment from 'moment'
import swal from '@sweetalert/with-react'
import AdvancedSearch from './AdvancedSearch'
import './style.css'

const { Panel } = Collapse
const widthLabel = '140px'
const formatDateAPI = 'YYYY-MM-DD HH:mm:ss'

class ReportCnDnContainer extends Component {
    state = {
        columns: [  ],
        loading: false,
        documents: [],
        reportTypes: [
            { label: 'รายงานใบแจ้งหนี้/ใบลดหนี้/ใบเพิ่มหนี้', value: 'cnReport' },
            { label: 'รายงานแบบสรุป', value: 'cnSummary' },
            { label: 'รายงานพร้อมรายการย่อย', value: 'cnSummaryWithDetail' },
        ],
        documentTypeCodes: [
            { label: 'ZDEP - ใบรับเงินมัดจำ', value: 'ZDEP' },
            { label: 'ZPMT - ใบเสร็จรับเงิน', value: 'ZPMT' },
            { label: 'ZIN2 Billing - เงินเชื่อ', value: 'ZIN2' },
        ],
        documentStatuses: [
            { label: 'ใบวางบิลที่ยกเลิก', value: 'cancel' },
            { label: 'ใบวางบิลที่ไม่มีการยกเลิก', value: 'notCancel' },
        ],
        saleChannelCodes: [
            { label: '10 - ค้าปลีก', value: 10 },
            { label: '20 - ขายโปรเจค', value: 20 }
        ],
        docTypeFilter: 'ZDEP,ZPMT,ZIN2,ZCN,ZCND,ZCNN,ZCNP,ZPNR,ZDN,ZCNR',
        filter: {
            reportType: 'cnReport',
            documentDateFrom: moment(),
            documentDateTo: moment(),
            documentTypeCodes: [],
            taxInvoiceNo: '',
            billingNo:'',
            soNumber: '',
            documentStatuses: [],
        },
        advancedfilter: {
            saleChannelCodes: [],
            specialOrderFlags: [],
            doNumber: '',
            customerGroups: [],
            outletIds: [],
            paymentTermCodes: [],
            createdBy: '',
            createdDateFrom: null,
            createdDateTo: null,
            returnTypes: [],
            paymentNumber: '',
            customerId: '',
            productCode: '',
            productClass: '',
            productClassDescription: '',
            productBrands: [],
            paymentTyps: [],
        },
        documentTypes: [],
        customerGroups: [],
        pagination: {
            pageSize: 10,
            current: 1,
        },
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        const { auth } = this.props
        this.setState({ loading: true })

        // outlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results', [])
            if (res.status === 200) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({ outlets })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // customer group
        await apiServices.callApi('get', API_PATH.GET_MASTER_CUSTOMER_GROUP).then(res => {
            let customerGroups = get(res, 'data.results', [])
            if (res.status === 200) {
                customerGroups = customerGroups.map(data => (
                    { ...data, label: `${data.customerGroupId} - ${data.customerGroupDescription}`, value: data.customerGroupId }
                ))
                this.setState({ customerGroups })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลกลุ่มลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
        let docTypes = this.state.docTypeFilter
        let newDoc = docTypes.split(',').map(item => 'DocumentTypeCodes=' + item).join('&')

        // document type
        await apiServices.callApi('get', API_PATH.GET_MASTER_DOCUMENT_TYPE + '?' + newDoc).then(res => {
            let documentTypes = get(res, 'data.results', [])
            documentTypes = documentTypes.map(item => {
                item = {
                    ...item,
                    label: `${item.documentTypeCode} - ${item.documentTypeDescription}`,
                    value: item.documentTypeCode
                }
                return item
            })
            // console.log('documentTypes', documentTypes);

            if (res.status === 200) {
                this.setState({ documentTypes })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลประเภทการขายได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    getColumn = (type) => {
        console.log('type',type);
        if (type === 'cnReport') {
            return [
                {
                    title: 'เลขที่เอกสารใบวางบิล',
                    dataIndex: 'billingNo',
                    key: 'billingNo',
                    sumText: 'Total:',
                    width: 170,
                },
                {
                    title: 'ภาษีขาย',
                    dataIndex: 'taxType',
                    key: 'taxType',
                    width: 100
                },
                {
                    title: 'คำอธิบายประเภทเอกสาร',
                    dataIndex: 'documentTypeDescription',
                    key: 'documentTypeDescription',
                    width: 200,
                },
                {
                    title: 'ช่องทางการจัดจำหน่าย',
                    dataIndex: 'saleChannelCode',
                    key: 'saleChannelCode',
                    width: 170,
                    render: (props) => {
                        if (!props) return null
                        const data = this.getSaleChannelCodeData(props)
                        return data ? data.label : props
                    },
                },
                {
                    title: 'วันที่วางบิล',
                    dataIndex: 'documentDate',
                    key: 'documentDate',
                    width: 100,
                    render: (props) => (
                        props && <div>{moment(props).format('DD/MM/YYYY')} </div>
                    ),
                },
                {
                    title: 'เงื่อนไขการชำระเงิน',
                    dataIndex: 'paymentTermCode',
                    key: 'paymentTermCode',
                    width: 150,
                    render: (props, row) => (
                        <div>{`${props} - ${row.paymentTermDescription}`}</div>
                    ),
                },
                {
                    title: 'ยอดสุทธิใบวางบิลรวมภาษี',
                    dataIndex: 'netAmountHead',
                    key: 'netAmountHead',
                    width: 170,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'สร้างโดย(UserWeb)',
                    dataIndex: 'createdBy',
                    key: 'createdBy',
                    width: 150,
                },
                {
                    title: 'สร้างเมื่อ',
                    dataIndex: 'createdDateTime',
                    key: 'createdDateTime',
                    width: 100,
                    render: (props) => (
                        props && <div>{moment(props).format('DD/MM/YYYY')}</div>
                    ),
                },
                {
                    title: 'สถานะการรับชำระเงิน',
                    dataIndex: 'documentStatus',
                    key: 'documentStatus',
                    width: 150,
                },
                {
                    title: 'PO Number',
                    dataIndex: 'poNumber',
                    key: 'poNumber',
                    width: 150,
                },
                {
                    title: 'SO Number',
                    dataIndex: 'infI14SoNo',
                    key: 'infI14SoNo',
                    width: 150,
                },
                {
                    title: 'FI_Doc',
                    dataIndex: 'fiDoc',
                    key: 'fiDoc',
                    width: 150,
                },
                {
                    title: 'DN_Number',
                    dataIndex: 'dnNumber',
                    key: 'dnNumber',
                    width: 150,
                },
                {
                    title: 'Billing_Number',
                    dataIndex: 'infI14BillNo',
                    key: 'infI14BillNo',
                    width: 190,
                },
                {
                    title: 'Billing_acct_doc',
                    dataIndex: 'infI14BillAcctDoc',
                    key: 'infI14BillAcctDoc',
                    width: 190,
                },
            ]
        } else if (type === 'cnSummary') {
            return [
                {
                    title: 'เลขที่เอกสารใบวางบิล',
                    dataIndex: 'billingNo',
                    key: 'billingNo',
                    sumText: 'Total:',
                    width: 130,
                },
                {
                    title: 'ร้าน',
                    dataIndex: 'outletId',
                    key: 'outletId',
                    width: 70,
                },
                {
                    title: 'ใบกำกับภาษี',
                    dataIndex: 'taxInvoiceNo',
                    key: 'taxInvoiceNo',
                    width: 150,
                },
                {
                    title: 'คำอธิบายประเภทเอกสาร',
                    dataIndex: 'documentTypeDescription',
                    key: 'documentTypeDescription',
                    width: 150,
                },
                {
                    title: 'กลุ่มลูกค้า',
                    dataIndex: 'customerGroupId',
                    key: 'customerGroupId',
                    width: 120,
                    render: (props, row) => (
                        row?.customerGroupDescription ? <div>{`${props} - ${row.customerGroupDescription}`}</div> : <div>{props}</div>
                    ),
                },
                {
                    title: 'ช่องทางการจัดจำหน่าย',
                    dataIndex: 'saleChannelCode',
                    key: 'saleChannelCode',
                    width: 150,
                    render: (props) => {
                        if (!props) return null
                        const data = this.getSaleChannelCodeData(props)
                        return data ? data.label : props
                    },
                },
                {
                    title: 'วันที่วางบิล',
                    dataIndex: 'documentDate',
                    key: 'documentDate',
                    width: 100,
                    render: (props) => (
                        props && <div>{moment(props).format('DD/MM/YYYY')}</div>
                    ),
                },
                {
                    title: 'รหัสผู้ชำระเงิน',
                    dataIndex: 'customerId',
                    key: 'customerId',
                    width: 100,
                },
                {
                    title: 'ชื่อผู้ชำระเงิน',
                    dataIndex: 'customerName',
                    key: 'customerName',
                    width: 100,
                },
                {
                    title: 'ชื่อพนักงานขาย',
                    dataIndex: 'saleEMPName',
                    key: 'saleEMPName',
                    width: 150,
                },
                {
                    title: 'ยอดสุทธิใบวางบิลรวมภาษี',
                    dataIndex: 'netAmountHead',
                    key: 'netAmountHead',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/เงินสด',
                    dataIndex: 'cashAmount',
                    key: 'cashAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/เงินโอนธนาคาร',
                    dataIndex: 'bankTransferAmount',
                    key: 'bankTransferAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/บัตรเครดิต',
                    dataIndex: 'creditCardAmount',
                    key: 'creditCardAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/คูปองแทนเงินสด',
                    dataIndex: 'giftVoucherAmount',
                    key: 'giftVoucherAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/โปรโมชั่นจากผู้ผลิต',
                    dataIndex: 'supplierCoupon',
                    key: 'supplierCoupon',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/คูปองจากการรับคืน',
                    dataIndex: 'creditNoteAmount',
                    key: 'creditNoteAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/SCG แฟมิลี่ แลกคะแนน',
                    dataIndex: 'scgFamilyPointAmount',
                    key: 'scgFamilyPointAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/SCG แฟมิลี่ voucher',
                    dataIndex: 'scgFamilyVoucherAmount',
                    key: 'scgFamilyVoucherAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/SCG ช้างเพื่อนช่าง แลกคะแนน',
                    dataIndex: 'scgFamilyCPCAmount',
                    key: 'scgFamilyCPCAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/SCG แฟมิลี่ เงินสด',
                    dataIndex: 'scgFamilyCashCard',
                    key: 'scgFamilyCashCard',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/SCG Home',
                    dataIndex: 'ecommerceAmount',
                    key: 'ecommerceAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/ค่าธรรมเนียม SCG Home',
                    dataIndex: 'ecommerceFeeAmount',
                    key: 'ecommerceFeeAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/คูปอง SCG Home',
                    dataIndex: 'ecommerceCouponAmount',
                    key: 'ecommerceCouponAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'บัตรชำระเงิน/ภาษีหัก ณ ที่จ่าย',
                    dataIndex: 'witholdingTaxAmount',
                    key: 'witholdingTaxAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                // {
                //     title: 'เลขที่คูปอง',
                //     dataIndex: 'paymentNo',
                //     key: 'paymentNo',
                // },
                // ---------------------------------
                {
                    title: 'Bank Transfer',
                    dataIndex: 'bankTransferPayment',
                    key: 'bankTransferPayment',
                    width: 150,
                },
                {
                    title: 'Credit Card',
                    dataIndex: 'creditCardPayment',
                    key: 'creditCardPayment',
                    width: 150,
                },
                {
                    title: 'คูปอง/แลกคะแนน',
                    dataIndex: 'couponPointPayment',
                    key: 'couponPointPayment',
                    width: 150,
                },
                {
                    title: 'SCG Family Point',
                    dataIndex: 'scgFamilyPointPayment',
                    key: 'scgFamilyPointPayment',
                    width: 150,
                },
                {
                    title: 'SCG Family Voucher',
                    dataIndex: 'scgFamilyVoucherPayment',
                    key: 'scgFamilyVoucherPayment',
                    width: 150,
                },
                {
                    title: 'SCG Family CPC',
                    dataIndex: 'scgFamilyCpcPayment',
                    key: 'scgFamilyCpcPayment',
                    width: 150,
                },
                {
                    title: 'SCG Family Cashcard',
                    dataIndex: 'scgFamilyCashcardPayment',
                    key: 'scgFamilyCashcardPayment',
                    width: 150,
                },
                {
                    title: 'Supplier Coupon',
                    dataIndex: 'supplierCouponPayment',
                    key: 'supplierCouponPayment',
                    width: 150,
                },
                {
                    title: 'Credit Note Voucher',
                    dataIndex: 'creditNotePayment',
                    key: 'creditNotePayment',
                    width: 150,
                },
                {
                    title: 'eCommerce',
                    dataIndex: 'ecommercePayment',
                    key: 'ecommercePayment',
                    width: 150,
                },
                {
                    title: 'eCommerce Fee',
                    dataIndex: 'ecommerceFeePayment',
                    key: 'ecommerceFeePayment',
                    width: 150,
                },
                {
                    title: 'eCommerce Coupon',
                    dataIndex: 'ecommerceCouponPayment',
                    key: 'ecommerceCouponPayment',
                    width: 150,
                },
                {
                    title: 'Gift Voucher',
                    dataIndex: 'giftVoucherPayment',
                    key: 'giftVoucherPayment',
                    width: 150,
                },
                {
                    title: 'Witholding Tax',
                    dataIndex: 'witholdingTaxPayment',
                    key: 'witholdingTaxPayment',
                    width: 150,
                },
                {
                    title: 'Credit Card Online',
                    dataIndex: 'creditCardOnlinePayment',
                    key: 'creditCardOnlinePayment',
                    width: 150,
                },
                {
                    title: 'Cash Coupon Discount',
                    dataIndex: 'cashCouponDiscountPayment',
                    key: 'cashCouponDiscountPayment',
                    width: 150,
                },
                {
                    title: 'Sfinpay',
                    dataIndex: 'sfinpayAmount',
                    key: 'sfinpayAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                // ---------------------------------
                {
                    title: 'เลขที่อนุมัติ',
                    dataIndex: 'confirmNo',
                    key: 'confirmNo',
                    width: 150,
                },
                {
                    title: 'เลขที่เอกสารใบสั่งขาย',
                    dataIndex: 'soNumber',
                    key: 'soNumber',
                    width: 150,
                },
            ]
        } else if (type === 'cnSummaryWithDetail') {
            return [
                {
                    title: 'เลขที่เอกสารใบวางบิล',
                    dataIndex: 'billingNo',
                    key: 'billingNo',
                    sumText: 'Total:',
                    width: 150
                },
                {
                    title: 'เลขที่ใบกำกับภาษี',
                    dataIndex: 'taxInvoiceNo',
                    key: 'taxInvoiceNo',
                    width: 150,
                },
                {
                    title: 'ภาษีขาย',
                    dataIndex: 'taxType',
                    key: 'taxType',
                    width: 150,
                },
                {
                    title: 'คำอธิบายประเภทเอกสาร',
                    dataIndex: 'documentTypeDescription',
                    key: 'documentTypeDescription',
                    width: 150,
                },
                {
                    title: 'ช่องทางการจัดจำหน่าย',
                    dataIndex: 'saleChannelCode',
                    key: 'saleChannelCode',
                    width: 150,
                    render: (props) => {
                        if (!props) return null
                        const data = this.getSaleChannelCodeData(props)
                        return data ? data.label : props
                    },
                },
                {
                    title: 'วันที่วางบิล',
                    dataIndex: 'documentDate',
                    key: 'documentDate',
                    width: 150,
                    render: (props) => (
                        props && <div>{moment(props).format('DD/MM/YYYY')}</div>
                    ),
                },
                {
                    title: 'รหัสผู้ชำระเงิน',
                    dataIndex: 'customerId',
                    key: 'customerId',
                    width: 150,
                },
                {
                    title: 'ชื่อผู้ชำระเงิน',
                    dataIndex: 'customerName',
                    key: 'customerName',
                    width: 150,
                },
                {
                    title: 'ชื่อพนักงานขาย',
                    dataIndex: 'saleEMPName',
                    key: 'saleEMPName',
                    width: 150,
                },
                {
                    title: 'เงื่อนไขการชำระเงิน',
                    dataIndex: 'paymentTermCode',
                    key: 'paymentTermCode',
                    width: 150,
                    render: (props, row) => (
                        <div>{`${props} - ${row.paymentTermDescription}`}</div>
                    ),
                },
                {
                    title: 'รหัสสินค้า',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    width: 150,
                },
                {
                    title: 'คำอธิบายวัสดุแบบเต็ม',
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 250,
                },
                {
                    title: 'รหัสกลุ่มสินค้า',
                    dataIndex: 'categoryCode',
                    key: 'categoryCode',
                    width: 100,
                },
                {
                    title: 'ชื่อกลุ่มสินค้า',
                    dataIndex: 'categoryDesc',
                    key: 'categoryDesc',
                    width: 150,
                },
                {
                    title: 'รหัสยี่ห้อ',
                    dataIndex: 'brandCode',
                    key: 'brandCode',
                    width: 150,
                },
                {
                    title: 'ชื่อยี่ห้อ',
                    dataIndex: 'brandDesc',
                    key: 'brandDesc',
                    width: 150,
                },
                {
                    title: 'ที่เก็บสินค้า',
                    dataIndex: 'slocId',
                    key: 'slocId',
                    width: 100,
                },
                {
                    title: 'ปริมาณที่วางบิล',
                    dataIndex: 'qty',
                    key: 'qty',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 3)}</div>
                    ),
                },
                {
                    title: 'หน่วยนับ',
                    dataIndex: 'unit',
                    key: 'unit',
                    width: 100,
                },
                {
                    title: 'ราคาต่อหน่วย',
                    dataIndex: 'pricePerUnit',
                    key: 'pricePerUnit',
                    width: 100,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'ส่วนลดกำหนดเอง',
                    dataIndex: 'discAmount',
                    key: 'discAmount',
                    width: 100,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'ส่วนลดอื่นๆ',
                    dataIndex: 'totalDiscOtherAmount',
                    key: 'totalDiscOtherAmount',
                    width: 100,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'ส่วนลดโปรโมชั่น',
                    dataIndex: 'totalDiscPromotionAmount',
                    key: 'totalDiscPromotionAmount',
                    width: 100,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'ราคาสุทธิต่อหน่วย',
                    dataIndex: 'netPricePerUnit',
                    key: 'netPricePerUnit',
                    width: 120,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                },
                {
                    title: 'จำนวนเงินรวมรายการ',
                    dataIndex: 'totalAmount',
                    key: 'totalAmount',
                    width: 130,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'ส่วนลดรวมท้ายบิล (รายการ)',
                    dataIndex: 'totalDiscAmount',
                    key: 'totalDiscAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'จำนวนเงินรายการไม่รวมภาษี',
                    dataIndex: 'totalAmountExcVAT',
                    key: 'totalAmountExcVAT',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'มูลค่าภาษีขาย',
                    dataIndex: 'vatAmount',
                    key: 'vatAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'จำนวนเงินรวมรายการทั้งสิ้น',
                    dataIndex: 'netAmount',
                    key: 'netAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'เลขที่เอกสารใบสั่งขาย',
                    dataIndex: 'soNumber',
                    key: 'soNumber',
                    width: 150,
                },
                {
                    title: 'จำนวนเงินรวม',
                    dataIndex: 'soHeadTotalAmount',
                    key: 'soHeadTotalAmount',
                    width: 150,
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 2)}</div>
                    ),
                    sumField: true,
                },
                {
                    title: 'เลขที่ใบส่งสินค้า',
                    dataIndex: 'doNumber',
                    key: 'doNumber',
                    width: 150,
                },
                {
                    title: 'สร้างโดย(User Web)',
                    dataIndex: 'createdBy',
                    key: 'createdBy',
                    width: 150,
                },
                {
                    title: 'สร้างเมื่อ',
                    dataIndex: 'createdDateTime',
                    key: 'createdDateTime',
                    width: 150,
                    render: (props) => (
                        props && <div>{moment(props).format('DD/MM/YYYY')}</div>
                    ),
                },
                {
                    title: 'สถานะการรับชำระเงิน',
                    dataIndex: 'documentStatus',
                    key: 'documentStatus',
                    width: 150,
                },
                {
                    title: 'ระบบภายนอก',
                    dataIndex: 'referenceDocSystem',
                    key: 'referenceDocSystem',
                    width: 150,
                },
                {
                    title: 'เลขที่เอกสาร(ระบบภายนอก)',
                    dataIndex: 'referenceDocNumber',
                    key: 'referenceDocNumber',
                    width: 150,
                },
            ]
        }
    }

    getSaleChannelCodeData = (code) => {
        return this.state.saleChannelCodes.find(saleChannelCode => saleChannelCode.value === +code)
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    changeAdvancedFilter = (key, val) => {
        const { advancedfilter } = this.state
        switch (key) {
            case 'productClass':
                this.setState({
                    advancedfilter: {
                        ...advancedfilter,
                        [key]: val.productClass,
                        productClassDescription: val.productClassDescription,
                    },
                })
                break
            default:
                this.setState({ advancedfilter: { ...advancedfilter, [key]: val } })
        }
    }

    validateFilter = () => {
        const { filter } = this.state
        if (filter.documentDateTo && filter.documentDateFrom && (filter.documentDateTo).isBefore(filter.documentDateFrom)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }
        if (filter.createdDateTo && filter.createdDateFrom && (filter.createdDateTo).isBefore(filter.createdDateFrom)) return { isError: true, message: 'รูปแบบวันที่สร้างเมื่อไม่ถูกต้อง' }

        return { isError: false }
    }

    getBody = () => {
        const { filter, advancedfilter, docTypeFilter } = this.state
        const { auth } = this.props

        return {
            reportType: filter.reportType,
            documentDateFrom: filter.documentDateFrom && filter.documentDateFrom.startOf('day').format(formatDateAPI),
            documentDateTo: filter.documentDateTo && filter.documentDateTo.endOf('day').format(formatDateAPI),
            documentTypeCodes: isEmpty(filter.documentTypeCodes) ? docTypeFilter.split(',') : filter.documentTypeCodes,
            documentStatuses: filter.documentStatuses,
            saleOrderNumber: filter.soNumber,
            taxInvoiceNo: filter.taxInvoiceNo,
            billingNo:filter.billingNo,

            customerId: advancedfilter.customerId,
            saleChannelCodes: advancedfilter.saleChannelCodes,
            // saleChannelCode: advancedfilter.saleChannelCodes[0],
            doNumber: advancedfilter.doNumber,
            specialOrderFlags: advancedfilter.specialOrderFlags,
            outerIds: isEmpty(advancedfilter.outletIds) ? auth.arrayOutlet : advancedfilter.outletIds,
            createBy: advancedfilter.createdBy,
            productClassCode: advancedfilter.productClass,
            productBrandCodes: advancedfilter.productBrands,
            customerGroupIds: advancedfilter.customerGroups,
            paymentTermCodes: advancedfilter.paymentTermCodes,
            // paymentTermCode: advancedfilter.paymentTermCodes[0],
            paymentNumber: advancedfilter.paymentNumber,
            createDateFrom: advancedfilter.createdDateFrom,
            createDateTo: advancedfilter.createdDateTo,
            productCode: advancedfilter.productCode,
            returnTypes: advancedfilter.returnTypes,
            paymentTypes: advancedfilter.paymentTypes,
        }
    }

    searchDocument = async () => {
        const { filter, pagination } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody()
        await apiServices.callApi('post', API_PATH.POST_GET_CN_DN_TRACKING_REPORT, body).then(res => {
            let documents = get(res, 'data.results')
            if (res.status === 200) {
                this.setState({
                    documents,
                    columns: this.getColumn(filter.reportType),
                    pagination: { ...pagination, current: 1 }
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    exportDocument = async () => {
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody()
        await apiServices.callApi('post', API_PATH.POST_EXPORT_CN_DN_TRACKING_REPORT, body, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `ReportCNDNFile-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }
    // custom summary bottom row because Antd table summary version 3 not support
    renderTable = ({ className, style, children }) => {
        return (<table className={className} style={style}>
            {children}
            {/* ตรงนี้จะอยู่ล่าง*/}
            {!isEmpty(this.state.documents) && <tfoot className="table-summary">
                <tr>
                    {this.state.columns.map((column, i) => {
                        let sum = 0
                        if (column.sumField) {
                            const { documents, pagination } = this.state
                            const { current, pageSize } = pagination
                            let docs = [...documents]
                            // docs = docs.slice((current - 1) * pageSize, (current * pageSize))
                            sum = sumBy(docs, column.dataIndex)
                        }
                        return <td style={{ padding: '8px' }} key={i} className="font-weight-bold">
                            {column.sumText && <div>{column.sumText}</div>}
                            {column.sumField && <div className="text-right">{formatNumber(sum, 2)}</div>}
                        </td>
                    })}
                </tr>
            </tfoot>}
            {/* ตรงนี้จะอยู่บน */}
            {!isEmpty(this.state.documents) && <tfoot className="table-summary">
                <tr>
                    {this.state.columns.map((column, i) => {
                        let sum = 0
                        if (column.sumField) {
                            const { documents, pagination } = this.state
                            const { current, pageSize } = pagination
                            let docs = [...documents]
                            docs = docs.slice((current - 1) * pageSize, (current * pageSize))
                            sum = sumBy(docs, column.dataIndex)
                        }
                        return <td style={{ padding: '8px' }} key={i} className="font-weight-bold">
                            {column.sumText && <div>Total of Page :</div>}
                            {column.sumField && <div className="text-right">{formatNumber(sum, 2)}</div>}
                        </td>
                    })}
                </tr>
            </tfoot>}
        </table>)
    }

    render() {
        const {
            columns,
            reportTypes,
            documentTypeCodes,
            documentStatuses,
            loading,
            filter,
            advancedfilter,
            documents,
            pagination,
            customerGroups,
            saleChannelCodes,
            documentTypes
        } = this.state

        return (
            <div className="report-cn-dn-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="รายงานใบแจ้งหนี้/ใบลดหนี้/ใบเพิ่มหนี้"
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row gutter={15}>
                            <Col sm={4}>
                                ประเภทรายงาน
                            </Col>
                            <Col sm={20}>
                                <RadioCustom
                                    small
                                    onChange={(e) => this.changeFilter('reportType', e.target.value)}
                                    value={filter.reportType}
                                    options={reportTypes}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15} style={{ marginTop: '15px' }}>
                            <Col sm={4}>
                                การเลือกเอกสาร
                            </Col>
                            <Col sm={20}>
                                <Row gutter={15}>
                                    <Col sm={10}>
                                        <DatePickerCustom
                                            label="วันที่เอกสาร"
                                            widthLabel={widthLabel}
                                            small
                                            onChange={(val) => this.changeFilter('documentDateFrom', val)}
                                            value={filter.documentDateFrom}
                                            allowClear={false}
                                        />
                                    </Col>
                                    <Col sm={10}>
                                        <DatePickerCustom
                                            label="ถึง"
                                            widthLabel={widthLabel}
                                            small
                                            onChange={(val) => this.changeFilter('documentDateTo', val)}
                                            value={filter.documentDateTo}
                                            allowClear={false}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={15}>
                                    <Col sm={10}>
                                        {/* จ้า */}
                                        <SelectCustom
                                            label="ประเภทเอกสารใบวางบิล"
                                            widthLabel={widthLabel}
                                            small
                                            onChange={(val) => this.changeFilter('documentTypeCodes', val)}
                                            value={filter.documentTypeCodes}
                                            mode="multiple"
                                            isValueAllClear
                                            maxTagCount={3}
                                            options={documentTypes}
                                            // options={documentTypeCodes}
                                            optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                            placeholder="ทั้งหมด"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={15}>
                                    <Col sm={24}>
                                        <div style={{ fontSize: '12px', paddingLeft: widthLabel, paddingTop: '10px' }}>
                                            ใส่ : เพื่อค้นหาข้อมูลแบบช่วง หรือใส่ , เพื่อค้นหาข้อมูลแบบรายตัว
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={15}>
                                    <Col sm={10}>
                                        <InputCustom
                                            label="Billing No"
                                            widthLabel={widthLabel}
                                            small
                                            onChange={(e) => this.changeFilter('billingNo', e.target.value)}
                                            value={filter.billingNo}
                                            placeholder="0000000001:0000000099"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={15}>
                                    <Col sm={10}>
                                        <InputCustom
                                            label="เลขที่ใบกำกับภาษี"
                                            widthLabel={widthLabel}
                                            small
                                            onChange={(e) => this.changeFilter('taxInvoiceNo', e.target.value)}
                                            value={filter.taxInvoiceNo}
                                            placeholder="0000000001:0000000099"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={15}>
                                    <Col sm={10}>
                                        <InputCustom
                                            label="เลขที่เอกสารใบสั่งขาย"
                                            widthLabel={widthLabel}
                                            small
                                            onChange={(e) => this.changeFilter('soNumber', e.target.value)}
                                            value={filter.soNumber}
                                            placeholder="0000000001:0000000099"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={15}>
                                    <Col sm={10}>
                                        <CheckboxGroupCustom
                                            widthLabel={widthLabel}
                                            small
                                            onChange={(val) => this.changeFilter('documentStatuses', val)}
                                            value={filter.documentStatuses}
                                            options={documentStatuses}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24}>
                                <Collapse bordered={false}>
                                    <Panel header="ค้นหาขั้นสูง" key="1"  >
                                        <AdvancedSearch
                                            advancedfilter={advancedfilter}
                                            changeAdvancedFilter={this.changeAdvancedFilter}
                                            customerGroups={customerGroups}
                                            saleChannelCodes={saleChannelCodes}
                                        />
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} className="search-box text-right">
                                <div className="action-box">
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.exportDocument}
                                            small
                                            icon="file-excel"
                                            green
                                            text="Export Excel"
                                            disabled={isEmpty(documents)}
                                        />
                                    </div>
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.searchDocument}
                                            small
                                            icon="search"
                                            text="ค้นหา"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom
                                // height="calc(100vh - 316px)"
                                scroll={{ x: "max-content", y: "calc(100vh - 250px)" }}
                                columns={columns}
                                data={documents}
                                // isFullContent
                                // small
                                rowKey={(row, index) => index}
                                components={{ table: this.renderTable }}
                                onChange={(pagination) => {
                                    this.setState({ pagination })
                                }}
                                pagination={{
                                    current: pagination.current,
                                    pageSize: pagination.pageSize,
                                }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportCnDnContainer)