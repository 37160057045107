import React, { Component } from 'react'
import './style.css'
import { formatNumber, sortDataColumn } from 'helpers'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
} from 'components/common'
import { Row, Col } from 'antd'

export class JobIdBOQSearchModal extends Component {
    state = {
        columns: [
            {
                title: 'รหัสสินค้า',
                align: 'center',
                dataIndex: 'productNumber',
                key: 'productNumber',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'productNumber'),
            },
            {
                title: 'ชื่อสินค้า',
                align: 'left',
                dataIndex: 'description',
                key: 'description',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'description'),
            },
            {
                title: 'จำนวน',
                align: 'rigth',
                dataIndex: 'qty',
                key: 'qty',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'qty'),
                render: (props) => <div className="text-right">
                    {formatNumber(props, 3)}
                </div>
            },
            {
                title: 'หน่วย',
                align: 'center',
                dataIndex: 'unit',//'baseUnit',
                key: 'unit',//'baseUnit',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'unit'),
            },
            {
                title: 'ราคา/หน่วย',
                align: 'rigth',
                dataIndex: 'price',
                key: 'price',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'price'),
                render: (props) => <div className="text-right">
                    {formatNumber(props, 3)}
                </div>
            },
            {
                title: 'สถานะ',
                align: 'center',
                dataIndex: 'statusDescription',
                key: 'statusDescription',
                ellipsis: true,
                sorter: (a, b) => sortDataColumn(a, b, 'statusDescription'),
                render: (value, full) => <div style={{color: full.status === true ? "green" : "red"}}>
                    {value}
                </div>

            },
        ],
        loading: false,
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            onSubmitJobIdModal,
            width,
            loading,
            className,
            title,
            products,
        } = this.props

        const {
            columns,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                width={width}
                title={title || 'รายการสินค้า (BOQ)'}
                className={`${className || ''}`}
                footer={<span><ButtonCustom blue text="ตกลง" onClick={onSubmitJobIdModal} /></span>}
            >
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={products}
                            loading={loading}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
