import React, { Component } from 'react'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
} from 'components/common'
import { Row, Col } from 'antd'
import { sortDataColumn } from 'helpers'

export class CustomerShippingModal extends Component {
    state = {
        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: 'action',
                key: 'action',
                render: (props, row) =>
                    <div className="text-center">
                        <ButtonCustom
                            small
                            onClick={this.props.onSelected.bind(this, row)}
                            text="เลือก"
                        />
                    </div>
            },
            {
                title: 'Ship To',
                align: 'center',
                dataIndex: 'customerId',
                key: 'customerId',
                sorter: (a, b) => sortDataColumn(a, b, 'customerId'),
                ellipsis: true,
            },
            {
                title: 'ชื่อ - นามสกุล',
                align: 'center',
                dataIndex: 'customerNameTH',
                key: 'customerNameTH',
                sorter: (a, b) => sortDataColumn(a, b, 'customerNameTH'),
                ellipsis: true,
            },
            {
                title: 'ที่อยู่',
                align: 'center',
                dataIndex: 'customerAddress',
                key: 'customerAddress',
                ellipsis: true,
                render: (props, row) => (
                    props && <span>
                        {`${props} ${row.customerSubDistrict} ${row.customerDistrict} ${row.customerProvince} ${row.customerPostalCode}`}
                    </span>
                )
            },
            {
                title: 'Site งาน / ที่อยู่',
                align: 'center',
                dataIndex: 'customerOutletId',
                key: 'customerOutletId',
                sorter: (a, b) => sortDataColumn(a, b, 'customerOutletId'),
                ellipsis: true,
            },
            {
                title: 'เบอร์มือถือ',
                align: 'center',
                dataIndex: 'customerPhone',
                key: 'customerPhone',
                sorter: (a, b) => sortDataColumn(a, b, 'customerPhone'),
                ellipsis: true,
            },
        ],
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            title,
            className,
            shippings,
            width,
            loading,
        } = this.props
        const {
            columns,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                width={width}
                title={title || 'ค้นหาสถานที่จัดส่ง'}
                className={`${className || ''}`}
            >
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={shippings}
                            loading={loading}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
