import React, { Component, Fragment } from 'react'
import { Row, Col, Spin } from 'antd'
import { get } from 'lodash'
import {
    InputCustom,
    SelectCustom,
    ButtonCustom,
    DatePickerCustom,
} from 'components/common'
import { apiServices, API_PATH } from 'apiServices'
import swal from '@sweetalert/with-react'
import './style.css'
import moment from 'moment'
import * as _ from 'lodash'
export default class ReportTaxDocumentForm extends Component {
    state = {
        isShowModal: false,
        loading: false,
        outlets: [],
        docTypes: [],
        widthLabel: '140px',
        dateFormatAPI: 'YYYY-MM-DD',
        docTypeFilter: 'ZDEP,ZPMT,ZCN,ZCNR,ZCNN,ZDN,ZCND,ZCNP',
        value: {
            outlets: [],
            docTypes: [],
            docNo: '',
            docDateFrom: moment().startOf('month'),
            docDateTo: moment()
        }
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth } = this.props
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({
                    outlets: outlets
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
        let docTypes = this.state.docTypeFilter
        let newDoc = docTypes.split(',').map(item=> 'DocumentTypeCodes='+item).join('&')
        // console.log('newDoc',newDoc);
        
        /// Doc type 
        await apiServices.callApi('get', API_PATH.GET_MASTER_DOCUMENT_TYPE + '?' + newDoc)
            .then(res => {
                let docTypes = get(res, 'data.results')
                if (res.status === 200) {
                    docTypes = docTypes.map(data => (
                        { ...data, label: `${data.documentTypeCode} - ${data.documentTypeDescription}`, value: data.documentTypeCode }
                    ))
                    this.setState({
                        docTypes: docTypes
                    })
                } else {
                    throw res
                }
            })
            .catch(error => {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            }).finally(() => {
                this.setState({ loading: false })
            })

    }

    onKeyPress = (e) => {
        e.key === 'Enter' && e.preventDefault()
    }
    onDocDateFromChange(e) {
        let value = { ...this.state.value, docDateFrom: e }
        this.setState({ value: value })
    }
    onDocDateToChange(e) {
        let value = { ...this.state.value, docDateTo: e }
        this.setState({ value: value })
    }

    handleChangeOutlet = (e) => {
        let value = this.state.value
        value.outlets = e
        this.setState({ value: value })
    }
    handleChangeDocType = (e) => {
        let value = this.state.value
        value.docTypes = e
        this.setState({ value: value })
    }
    handleInputChange(e) {
        let value = this.state.value
        value.docNo = e.target.value
        this.setState({ value: value })
    }
    handleSubmit(e) {
        this.props.onSubmit(this.state.value);
        e.preventDefault()
    }
    render() {
        const {
            onClickPrint,
            onClickExport,
            permissionPage,
            onClickUpdateUploadStatus,
        } = this.props

        const {
            docTypes,
            loading,
            outlets,
            value,
            widthLabel,
        } = this.state
        return (
            <Spin spinning={loading}>
                <Row gutter={10}>
                    <Col sm={12} className="input-range-box">
                        <Fragment>
                            <div className="input-range-label">วันที่เอกสาร</div>
                            <div className="input-range">
                                <div style={{ marginRight: '5px', width: '95%' }}>
                                    <DatePickerCustom
                                        value={value.docDateFrom}
                                        name="docDateFrom"
                                        small
                                        onChange={this.onDocDateFromChange.bind(this)}
                                    />
                                </div>
                                <div style={{ marginRight: '5px', width: '5%' }}>
                                    ถึง
                                    </div>
                                <div style={{ width: '95%' }}>
                                    <DatePickerCustom
                                        value={value.docDateTo}
                                        name="docDateTo"
                                        small
                                        onChange={this.onDocDateToChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col sm={12}>
                        <SelectCustom
                            mode='multiple'
                            maxTagCount={2}
                            options={outlets}
                            label="ร้านค้า"
                            isValueAllClear
                            optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                            widthLabel={widthLabel}
                            onChange={this.handleChangeOutlet}
                            value={this.state.value.outlets}
                            placeholder="ทั้งหมด"
                            small
                        />
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col sm={12}>
                        <SelectCustom
                            mode='multiple'
                            maxTagCount={2}
                            label="ประเภทเอกสาร"
                            options={docTypes}
                            isValueAllClear
                            optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                            widthLabel={widthLabel}
                            onChange={this.handleChangeDocType.bind(this)}
                            value={this.state.value.docTypes}
                            placeholder="ทั้งหมด"
                            small
                        />
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col sm={12}>
                        <InputCustom
                            label="เลขที่เอกสาร"
                            widthLabel={widthLabel}
                            small
                            value={value.docNo}
                            onChange={this.handleInputChange.bind(this)}
                        />
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col sm={12}>
                        <div className="action-box">
                            <div className="button">
                                <ButtonCustom
                                    yellow
                                    small
                                    text="Upload PDF"
                                    onClick={onClickUpdateUploadStatus.bind(this, value)}
                                    disabled={!permissionPage.authApprove}
                                />
                            </div>
                            <div className="button">
                                <ButtonCustom
                                    icon="file-excel"
                                    green
                                    small
                                    text="Export"
                                    onClick={onClickExport.bind(this, value)}
                                    disabled={!permissionPage.authExport}
                                />
                            </div>
                            <div className="button">
                                <ButtonCustom
                                    icon="printer"
                                    yellow
                                    small
                                    text="พิมพ์"
                                    onClick={onClickPrint.bind(this, value)}
                                    disabled={!permissionPage.authPrint}
                                />
                            </div>
                            <div className="button">
                                <ButtonCustom
                                    htmlType="submit"
                                    icon="search"
                                    small
                                    text="ค้นหา"
                                    onClick={this.handleSubmit.bind(this)}
                                    disabled={!permissionPage.authDisplay}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Spin>
        )
    }
}

// export default reduxForm({
//     form: 'ReportTaxDocumentForm',
//     enableReinitialize: true,
// })(ReportTaxDocumentForm)
