import React, { Component } from 'react'
import { SelectCustomStyled } from './styled'
import { Select, Popover } from 'antd'

const { Option } = Select

export class SelectCustom extends Component {
    render() {
        const {
            label,
            right,
            small,
            className,
            options,
            optionsDefault,
            disabled,
            errorMsg,
            isError,
            onChange,
            isPopover,
            placeholder,
            placeholderDefault,
            firstChoose,
            labelAlign,
            widthLabel,
            mode,
            isValueAllClear,
            input = {},
            meta = {},
            value,
            ...rest
        } = this.props
        const { touched, error } = meta

        // touched and error from redux form
        // error and errorMsg from props
        const isErrorForm = (touched || isError) && (error || errorMsg)

        return (
            <SelectCustomStyled
                isError={isErrorForm}
                small={small}
                label={label}
                widthLabel={widthLabel}
                labelAlign={labelAlign}
                isPlaceholderDarkColor={placeholder === 'ทั้งหมด'}
            >
                <div className="input-form form-select-custom">
                    <div className="input-zone-label">
                        {
                            label && <div className="label-style">
                                {label}
                            </div>
                        }
                        <Select
                            {...rest}
                            {...input}
                            mode={mode}
                            className={`input-style select-custom ${className || ''}`}
                            onChange={e => {
                                if (mode === 'multiple' && isValueAllClear && e.includes('all')) {
                                    // set value to empty array when mode mutiple, isValueAllClear = true and value of select is all
                                    e = []
                                }
                                onChange && onChange(e)
                                input.onChange && input.onChange(e)
                            }}
                            value={input.value ? input.value : value}
                            disabled={disabled}
                            size={small ? 'small' : 'default'}
                            placeholder={placeholder ? placeholder : placeholderDefault ? '-- กรุณาเลือก --' : ''}
                            dropdownMatchSelectWidth={false}
                        >
                            {optionsDefault && optionsDefault.map((data, i) => (
                                <Option key={i} value={data.value} style={{ fontSize: small ? '12px' : '' }}>
                                    {/* <Popover placement="left" title={false} content={data.label}>
                                        {data.label}
                                    </Popover> */}
                                    {data.label}
                                </Option>
                            ))}
                            {options && options.map((data, i) => (
                                <Option key={i} value={data.value} style={{ fontSize: small ? '12px' : '' }}>
                                    {/* <Popover placement="left" title={false} content={data.label}>
                                        {data.label}
                                    </Popover> */}
                                    {data.label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    {
                        isErrorForm && <div className="error-message-bottom">
                            {error || errorMsg}
                        </div>
                    }
                </div>
            </SelectCustomStyled>
        )
    }
}
