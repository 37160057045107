import React, { Component } from 'react'
import { Input } from 'antd';
import { TextAreaCustomStyled } from "./styled"

const { TextArea } = Input;

export class TextAreaCustom extends Component {
    render() {
        const { label, right, BorderRadius, small, widthSmall, widthDefault, value, rows, placeholder, placeholderDefault, onChange, disabled } = this.props
        return (
            <TextAreaCustomStyled small={small}>
                <div className="textarea-form">
                    <div className="textarea-zone-label">
                        {
                            label &&
                            <div
                                className="label-style"
                                style={{
                                    textAlign: right ? 'right' : '',
                                    width: widthDefault ? widthDefault : '150px',
                                }}
                            >
                                {label}
                            </div>
                        }
                        <TextArea
                            className="textarea-style"
                            value={value}
                            rows={rows || 4}
                            disabled={disabled}
                            onChange={(e) => {
                                onChange(e)
                            }}
                            placeholder={placeholder ? placeholder : placeholderDefault === true ? label : undefined}
                            style={{
                                borderRadius: BorderRadius ? BorderRadius : 4,
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    ''
                                ,
                            }}
                        />
                    </div>
                </div>
            </TextAreaCustomStyled>
        )
    }
}
