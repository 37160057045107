import styled from 'styled-components'

export const TableCustomStyled = styled.div`

.ant-table-content {
    height: ${props => props.height ? props.height : ''};
    overflow: auto;
}
.ant-empty-normal {
    margin: 0px;
}
th {
    font-size: ${props => props.small ? '12px' : ''};
}
td {
    font-size: ${props => props.small ? '12px' : ''};
}
td input{
    font-size: ${props => props.small ? '12px' : ''};
}
.table-striped-rows tr:nth-child(2n) td {
    background-color: #fbfbfb;
}
.table-striped-rows thead {
    background-color: #f1f1f1;
}
`