import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, compact, sortBy } from 'lodash'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import {
    TitleHead,
    SelectCustom,
    TableCustom,
    ButtonCustom,
} from 'components/common'
import { Row, Col, Spin } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { ROUTH_PATH, functionAlias } from 'routes'
import { formatNumber, getPermissionPage } from 'helpers'
import { transactionTypeSpec } from './config'
import FileSaver from 'file-saver'
import moment from 'moment'
import GoodsTransferForm from './GoodsTransferForm'
import swal from '@sweetalert/with-react'

import './style.css'

const IM_TYPE_REF_DOC = 'R10'

class GoodsTransferContainer extends Component {
    state = {
        columns: [],
        loading: false,
        transactionTypes: [],
        transactionType: '',
        transactionTypeData: null,
        documents: [],
        initialValues: {},
        permissionPage: getPermissionPage(functionAlias.inventoryMovement),
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const transactionCodes = Object.keys(transactionTypeSpec).map(key => (`TransactionCodes=${key}`)).join('&')

        const url = `${API_PATH.GET_MASTER_TRANSACTION_TYPE}?ActiveStatus=true&${transactionCodes}`
        await apiServices.callApi('get', url).then(res => {
            let transactionTypes = get(res, 'data.results')
            if (res.status === 200) {
                transactionTypes = transactionTypes.map(data => (
                    { ...data, label: `${data.transactionCode} - ${data.transactionDescription}`, value: data.transactionCode }
                ))
                this.setState({
                    transactionTypes: [{ label: 'กรุณาเลือก', value: '' }, ...transactionTypes]
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    getInitialValues = () => {
        return {
            documentDate: moment(),
            orderDate: moment(),
            documentNo: '',
            documentHeader: '',
            productCodeFrom: '',
            productCodeTo: '',
            outletFrom: '',
            outletTo: '',
            storageLocationFrom: '',
            storageLocationTo: '',
            amount: '',
            unit: '',
            costTotal: '',
            reason: '',
            documentFrom: '',
            index1: '',
            documentTo: '',
            index2: '',
            document: '',
            year: '',
        }
    }

    resetInitialValues = () => {
        this.setState({ initialValues: this.getInitialValues() })
    }

    setColumns() {
        const { transactionTypeData = {} } = this.state
        const tableColumn = transactionTypeData.tableColumn
        let columns = []
        if (!isEmpty(tableColumn)) {
            columns = [
                tableColumn.delete && {
                    title: '#',
                    dataIndex: 'delele',
                    key: 'delele',
                    align: 'center',
                    width: 45,
                    render: (props, row, index) => (
                        <ButtonCustom
                            text="ลบ"
                            small
                            type="danger"
                            onClick={this.deleteDocument.bind(this, index)}
                        />
                    ),
                },
                tableColumn.productCodeFrom && {
                    title: 'รหัสสินค้า (ต้นทาง)',
                    dataIndex: 'productCodeFrom',
                    key: 'productCodeFrom',
                },
                tableColumn.productName && {
                    title: 'ชื่อสินค้า',
                    dataIndex: 'productNameFrom',
                    key: 'productNameFrom',
                    width: 240,
                },
                tableColumn.amount && {
                    title: 'จำนวน',
                    dataIndex: 'amount',
                    key: 'amount',
                    render: (props) => (
                        <div>{formatNumber(props, 3)}</div>
                    ),
                },
                tableColumn.unit && {
                    title: 'หน่วยนับ',
                    dataIndex: 'unit',
                    key: 'unit',
                },
                tableColumn.outletFrom && {
                    title: 'ร้านต้นทาง',
                    dataIndex: 'outletFrom',
                    key: 'outletFrom',
                },
                tableColumn.storageLocationFrom && {
                    title: 'ที่เก็บต้นทาง',
                    dataIndex: 'storageLocationFrom',
                    key: 'storageLocationFrom',
                },
                tableColumn.productCodeTo && {
                    title: 'รหัสสินค้า (ปลายทาง)',
                    dataIndex: 'productCodeTo',
                    key: 'productCodeTo',
                },
                tableColumn.outletTo && {
                    title: 'ร้านปลายทาง',
                    dataIndex: 'outletTo',
                    key: 'outletTo',
                },
                tableColumn.storageLocationTo && {
                    title: 'ที่เก็บปลายทาง',
                    dataIndex: 'storageLocationTo',
                    key: 'storageLocationTo',
                },
                tableColumn.reason && {
                    title: 'เหตุผล',
                    dataIndex: 'reason',
                    key: 'reason',
                },
                tableColumn.documentFrom && {
                    title: 'เอกสารต้นทาง',
                    dataIndex: 'documentFrom',
                    key: 'documentFrom',
                },
                tableColumn.index1 && {
                    title: 'ลำดับ #1',
                    dataIndex: 'index1',
                    key: 'index1',
                },
                tableColumn.documentTo && {
                    title: 'เอกสารปลายทาง',
                    dataIndex: 'documentTo',
                    key: 'documentTo',
                },
                tableColumn.index2 && {
                    title: 'ลำดับ #2',
                    dataIndex: 'index2',
                    key: 'index2',
                },
            ]
        }
        // remove falsey values in array
        columns = compact(columns)
        this.setState({ columns })
    }

    changeTransactionType = (val) => {
        this.resetInitialValues()
        this.setState({
            transactionType: val,
            transactionTypeData: transactionTypeSpec[val],
            documents: [],
        }, () => {
            this.setColumns()
        })
    }

    deleteDocument = (index) => {
        const documents = [...this.state.documents]
        documents.splice(index, 1)
        this.setState({ documents })
    }

    searchDocument = async (document) => {
        this.setState({ loading: true })

        // fix doctype
        const docType = 311
        const body = {
            matDoc: document.document,
            matDocYear: document.year,
            docType: docType,
        }
        const url = API_PATH.POST_GET_DAILY_MATERIAL_MOVEMENT
        await apiServices.callApi('post', url, body).then(res => {
            if (res.status === 200) {
                let documents = get(res, 'data.result.eT_MAT_MOVEMENT.item', [])
                documents = sortBy(documents, 'zeile')
                const documentsMapped = []
                for (let index in documents) {
                    const document = documents[index]
                    const nextIndex = +(index) + 1
                    if (document.zeile % 2 === 1) {
                        documentsMapped.push({
                            productCodeFrom: document.matnr,
                            productNameFrom: document.maktx,
                            amount: document.erfmg,
                            unit: document.erfme,
                            storageLocationFrom: document.lgort,
                            storageLocationTo: documents[nextIndex] && documents[nextIndex].lgort,
                        })
                    } else {
                        continue
                    }
                }
                this.setState({ documents: documentsMapped })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถค้นหาข้อมูล Document ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    addDocument = (document) => {
        const { documents } = this.state
        const { GoodsTransferForm: { values } } = this.props

        const reset = {
            productCodeFrom: '',
            productNameFrom: '',
            productCodeTo: '',
            productNameTo: '',
            storageLocationFrom: '',
            storageLocationTo: '',
            amount: '',
            unit: '',
            costTotal: '',
            reason: '',
            documentFrom: '',
            index1: '',
            documentTo: '',
            index2: '',
            document: '',
            year: '',
        }

        this.setState({ documents: [...documents, document], initialValues: { ...values, ...reset } })
    }

    getParams = () => {
        const { documents, transactionType, transactionTypeData } = this.state
        const { auth, GoodsTransferForm: { values } } = this.props

        const header = {
            doC_DATE: moment(values.documentDate).format('YYYY-MM-DD'),
            pstnG_DATE: moment(values.orderDate).format('YYYY-MM-DD'),
            reF_DOC_NO: values.documentNo,
            headeR_TXT: values.documentHeader,
            weB_USER: auth.userId,
        }

        const switchFieldSlocFrom = get(transactionTypeData, 'header.storageLocationFrom.switchField')
        const switchFieldSlocTo = get(transactionTypeData, 'header.storageLocationTo.switchField')

        const itItems = documents.map(document => (
            {
                matdoC_ITM: '',
                material: document.productCodeFrom,
                plant: document.outletFrom,
                stgE_LOC: switchFieldSlocFrom ? document[switchFieldSlocFrom] : document.storageLocationFrom,
                speC_STOCK: '',
                entrY_QNT: document.amount,
                entrY_UOM: document.unit,
                pO_NUMBER: '',
                pO_ITEM: '',
                nO_MORE_GR: '',
                iteM_TEXT: '',
                reserV_NO: '',
                reS_ITEM: '',
                movE_MAT: document.productCodeTo,
                movE_PLANT: document.outletTo,
                movE_STLOC: switchFieldSlocTo ? document[switchFieldSlocTo] : document.storageLocationTo,
                reF_DOC_YR: '',
                reF_DOC: '',
                reF_DOC_IT: '',
                vaL_SALES_ORD: document.documentFrom,
                vaL_S_ORD_ITEM: document.index1,
                saleS_ORD: document.documentTo,
                s_ORD_ITEM: document.index2,
                amounT_LC: '',
                movE_REAS: document.reason,
                stcK_TYPE: '',
                gL_ACCOUNT: '',
                costcenter: ''
            }
        ))

        const params = {
            goodsMovementRequest: {
                iM_CC_DOCYEAR: values.year,
                iM_CC_MATDOC: values.document,
                iM_GOODSMVT_HEADER: header,
                iM_MODE: transactionTypeData.mode,
                iM_MOVE_TYPE: transactionType,
                iM_PIID: '',
                iM_TYPE_REF_DOC: IM_TYPE_REF_DOC,
                eT_GOODSMVT_ITEM: null,
                eT_REMARK: null,
                eT_RETURN: null,
                iT_GOODSMVT_ITEM: {
                    item: itItems,
                },
                iT_REMARK: null,
            }
        }
        return params
    }

    createDocument = async () => {
        this.setState({ loading: true })
        const body = this.getParams()
        const { transactionType, transactionTypeData } = this.state
        const { GoodsTransferForm: { values } } = this.props

        await apiServices.callApi('post', API_PATH.POST_GOODS_MOVEMENT, body).then(res => {
            if (res.status === 200) {
                swal({
                    title: 'Success',
                    icon: 'success',
                    text: get(res, 'data.message') || 'บันทึกข้อมูลสำเร็จ',
                    buttons: this.state.permissionPage.authPrint ?
                        { print: { text: 'พิมพ์', value: 'print', className: 'green-button' }, ok: 'OK' } :
                        { ok: 'OK' },
                }).then((value) => {
                    if (value === 'print') {
                        const documentNumber = get(res, 'data.result.eX_MATDOC')
                        // const url = `${API_PATH.POST_GET_REPORT_FORM_STOCK_TRANSFER_A5}?DocumentNo=${documentNumber}`
                        // window.open(url, '_blank')
                        const outletId = transactionType === '312' ? get(res, 'data.result.outletId') : get(values, 'outletTo')
                        this.exportReport(documentNumber, outletId, transactionType, transactionTypeData)
                    }
                })

                this.resetInitialValues()
                this.setState({ transactionTypeData: null, transactionType: '', documents: [] })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    exportReport = async (documentNumber, outletId, transactionType, transactionTypeData) => {
        const body = {
            matDoc: documentNumber,
            outlet: outletId,
            movementTypes: [
                transactionType,
            ]
        }
        this.setState({ loading: true })
        const isSpecialForm = !!transactionTypeData.form
        const url = isSpecialForm ? transactionTypeData.form.url : API_PATH.POST_GET_REPORT_ARTICLE_TRANSFER_SLIP
        const fileName = isSpecialForm ? transactionTypeData.form.fileName : 'ReportArticleTransferSlip'

        await apiServices.callApi('post', url, body, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/pdf' })
                FileSaver.saveAs(blob, `${fileName}-${moment().format('DD-MM-YYYY')}.pdf`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    getActionSubmitByTranferType = (document) => {
        const { transactionTypeData } = this.state

        if (transactionTypeData.action.add) {
            this.addDocument(document)
        } else if (transactionTypeData.action.search) {
            this.searchDocument(document)
        }
    }

    render() {
        const { auth } = this.props
        const {
            columns,
            documents,
            transactionTypes,
            transactionType,
            transactionTypeData,
            loading,
            initialValues,
            permissionPage,
        } = this.state

        return (
            <div className="goods-transfer-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="รายการโอนย้ายสินค้า : เลขที่ (auto)"
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row gutter={15}>
                            <Col sm={10}>
                                <SelectCustom
                                    label="ประเภทการโอน"
                                    options={transactionTypes}
                                    value={transactionType}
                                    small
                                    onChange={this.changeTransactionType}
                                />
                            </Col>
                        </Row>
                        {transactionTypeData &&
                            <GoodsTransferForm
                                auth={auth}
                                initialValues={initialValues}
                                onSubmit={this.getActionSubmitByTranferType}
                                transactionTypeData={transactionTypeData}
                            />
                        }
                        {transactionTypeData && !isEmpty(columns) &&
                            <div className="table-layout">
                                <TableCustom
                                    columns={columns}
                                    data={documents}
                                    isFullContent
                                    rowKey={(row, index) => index}
                                    small
                                    pagination={false}
                                />
                            </div>
                        }
                    </div>
                    {transactionTypeData && <div className="bottom-content">
                        <div className="left-zone">
                            <div className="button">
                                <Link to={ROUTH_PATH.HOME} className="footer-button">
                                    <ButtonCustom
                                        text="ยกเลิก"
                                        icon="close"
                                        type="danger"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="right-zone">
                            <div className="button">
                                <ButtonCustom
                                    text="บันทึก"
                                    icon="save"
                                    green
                                    onClick={this.createDocument}
                                    disabled={isEmpty(documents) || !permissionPage.authCreate}
                                />
                            </div>
                        </div>
                    </div>}
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        GoodsTransferForm: state.form.GoodsTransferForm,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodsTransferContainer)
