import styled from 'styled-components'

export const SelectHeroStyled = styled.div`

.ant-select-selection {
    font-size: ${props => props.small ? '12px' : ''};
}
.ant-select-selection-selected-value {
    display: flex;
    align-items: center;
    font-size: ${props => props.small ? '12px' : ''};
}
.ant-select-selection--single .ant-select-selection__rendered {
    font-size: ${props => props.small ? '12px' : ''};
}
.label-style {
    font-size: ${props => props.small ? '12px' : ''};
    width: ${props => props.small ? props.widthSmall ? props.widthSmall : '120px' : props.widthDefault ? props.widthDefault : '150px'};
}
.input-form {
    margin-bottom: ${props => props.small ? '3px' : ''};
}
.input-style {
    height: ${props => props.small ? '24px' : ''};
}
.ant-select {
    width: 100%;
}
.input-form .input-zone-label {
    align-items: ${props => props.mode === 'multiple' ? 'initial' : ''};
    line-height: ${props => props.mode === 'multiple' ? '2.2' : ''};
}
`