import React, { Component } from 'react';
import { Result, Button } from 'antd';
import { Link } from "react-router-dom"
import { ROUTH_PATH } from "routes/path"
import './style.css'

class AccessDeniedContainer extends Component {


    render() {
        return (
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={<Link to={ROUTH_PATH.HOME}><Button type="primary">Back Home</Button></Link>}
            />
        );
    }
}


export default AccessDeniedContainer;