import styled from 'styled-components'

export const TabCustomStyled = styled.div`
    height: ${props => props.height ? props.height : '100%'};

    .ant-tabs {
        height: 100%;
    }

    .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
    .ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
        height: calc(100% - 60px);
    }

`