import React, { Component } from 'react'
import { connect } from "react-redux"
import swal from '@sweetalert/with-react';
import { message } from "antd"
import { Link } from 'react-router-dom';
import moment from "moment"
import { TitleHead, ButtonCustom } from "components"
import { CreateUserForm } from "reduxForms"
import { apiServices, API_PATH } from "apiServices"
import './style.css'
import { get } from 'lodash'
import { ROUTH_PATH, functionAlias } from 'routes'
import { getPermissionPage } from 'helpers'
import { authData } from 'actions/actionsCreator';
import { bindActionCreators } from 'redux';

class CreateUserContainer extends Component {
    state = {
        permissionPage: getPermissionPage(functionAlias.userMaintain),
        masterRole: [],
    }

    componentDidMount() {
        if (!this.state.permissionPage.authDisplay) {
            swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
                this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
            })
            return
        }
        this.getMasterRole();
        const keySelected = this.props.match.params.userId
        if (keySelected) {
            this.fetchData()
        }
    }

    getMasterRole = async () => {
        await apiServices.callApi('get', API_PATH.GET_MASTER_ROLE).then(res => {
            let dataRole = res.data.results.map(data => (
                {
                    ...data,
                    text: data.roleName,
                    value: data.roleId,
                    label: data.roleName
                }
            ))
            this.setState({
                masterRole: res.data.results,
            })
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });
    }

    fetchData = async () => {
        const { auth } = this.props
        const keySelected = this.props.match.params.userId
        let res = await apiServices.callApi('get', API_PATH.GET_USER + '?UserId=' + keySelected, '', auth.token)
        // console.log("resGetUserDetail==>", res);
        if (res.status === 200) {
            const data = res.data.results[0]
            let validDate = []
            let validFrom = res.data.results[0].validFrom
            let validTo = res.data.results[0].validTo
            validDate.push(moment(validFrom), moment(validTo))
            // console.log("validDate==>", validDate);
            let channel = res.data.results[0].saleChannelCode.split(",").map(Number)
            // console.log("channel==>", channel);
            let convertToString = channel.map(data => (
                String(data)
            ))
            let tax = data.taxTypeId ? data.taxTypeId.split(',') : []

            let storageLocationId = res.data.results[0].storageLocationId.split(",").map(String);
            let storageLocationIdMovement = res.data.results[0].storageLocationIdMovement.split(",").map(String);
            this.setState({
                keySelected: keySelected,
                initialValues: {
                    ...res.data.results[0],
                    validDate: validDate,
                    outlet: res.data.results[0].userOutletModelList.map((data, i) => (
                        data.outletId
                    )),
                    role: res.data.results[0].userRoleModelList.map((data, i) => (
                        data.roleId
                    )),
                    channel: convertToString,
                    tax: tax,
                    storageLocationId: storageLocationId,
                    storageLocationIdMovement: storageLocationIdMovement,
                },
                disabledInput: keySelected ? true : false
            })
        }

    }

    handleClickCreateUser = async () => {
        const { reduxForm: { values, syncErrors }, auth } = this.props
        const { keySelected, masterRole, disabledInput } = this.state
        console.log("syncErrors==>", syncErrors);
        if (typeof (syncErrors) === 'object') {

            if (syncErrors.userId === 'Required' || syncErrors.password === 'Required'
                || syncErrors.firstName === 'Required' || syncErrors.lastName === 'Required'
                || syncErrors.roleDescription === 'Required' || syncErrors.phone === 'Required'
                || syncErrors.email === 'Required' || syncErrors.channel === "Required"
                || syncErrors.tax === 'Required' || syncErrors.storageLocationId === 'Required'
                || syncErrors.validDate === 'Required' || syncErrors.role === 'Required'
                || syncErrors.outlet === 'Required' || syncErrors.storageLocationIdMovement === 'Required') {
                message.error("กรุณากรอกข้อมูลให้ครบถ้วน")
            }

            if (syncErrors.email === 'use e-mail only') {
                message.error("กรุณากรอก e-mail เท่านั้น")
            }

            if (syncErrors.password === 'Must be 8 characters or more' || syncErrors.confirmpassword === 'Must be 8 characters or more') {
                message.error("กรุณากรอกข้อมูล 8 ตัวอักษร หรือ มากกว่า")
            }

            if (syncErrors.password === 'Must have at least 1 number(s)' || syncErrors.confirmpassword === 'Must have at least 1 number(s)') {
                message.error("กรุณากรอกตัวเลข (0-9) อย่างน้อย 1 ตัว")
            }

            if (syncErrors.password === 'Must have at least 1 upper case letter(s)' || syncErrors.confirmpassword === 'Must have at least 1 upper case letter(s)') {
                message.error("กรุณากรอกพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว")
            }

            if (syncErrors.password === 'Must have at least 1 lower case letter(s)' || syncErrors.confirmpassword === 'Must have at least 1 lower case letter(s)') {
                message.error("กรุณากรอกพิมพ์เล็ก (a-z) อย่างน้อย 1 ตัว")
            }

            if (syncErrors.password === 'Must have at least 1 symbol(s)' || syncErrors.confirmpassword === 'Must have at least 1 symbol(s)') {
                message.error("กรุณากรอกอักขระพิเศษ (,!,@,#,$,%,^,&,*,?,_,~,) อย่างน้อย 1 ตัว")
            }

            this.setState({
                submitViewError: true
            })
        } else {
            if (!disabledInput && values.password !== values.confirmpassword) {
                message.error("รหัสผ่านไม่ตรงกัน")
                return
            }

            let params = {
                userId: values.userId,
                password: values.password,
                firstName: values.firstName,
                lastName: values.lastName,
                roleDescription: values.roleDescription,
                phone: values.phone,
                email: values.email,
                saleChannelCode: values.channel && values.channel.join(),
                taxTypeId: values.tax && values.tax.join(),
                storageLocationIdMovement: values.storageLocationIdMovement && values.storageLocationIdMovement.join(),
                storageLocationId: values.storageLocationId && values.storageLocationId.join(),
                validFrom: values.validDate && new Date(values.validDate[0]),
                validTo: values.validDate && new Date(values.validDate[1]),
                createdDate: new Date(),
                createdBy: auth.userId,
                updatedDate: new Date(),
                updatedBy: auth.userId,
                userRoleModelList: values.role && values.role.map((data) => (
                    {
                        roleId: data
                    }
                ))
                ,
                userOutletModelList: values.outlet && values.outlet.map((data) => (
                    {
                        outletId: data
                    }
                ))
            }

            //console.log("values===>", values, "params====>", params);
            if (keySelected) {
                // api update
                delete params.password
                delete params.createdDate
                delete params.createdBy
                console.log("api update");
                await apiServices.callApi("put", API_PATH.UPDATE_USER + '/' + keySelected, params, auth.token).then(res => {
                    let newAuth = {
                        ...auth,
                        userId: values.userId,
                        password: values.password,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        roleDescription: values.roleDescription,
                        phone: values.phone,
                        email: values.email,
                        saleChannelCode: values.channel && values.channel.join(),
                        taxTypeId: values.tax && values.tax.join(),
                        storageLocationIdMovement: values.storageLocationIdMovement && values.storageLocationIdMovement.join(),
                        storageLocationId: values.storageLocationId && values.storageLocationId.join(),
                        validFrom: values.validDate && new Date(values.validDate[0]),
                        validTo: values.validDate && new Date(values.validDate[1]),
                        createdDate: new Date(),
                        createdBy: auth.userId,
                        updatedDate: new Date(),
                        updatedBy: auth.userId,
                        userRoleModelList: values.role && values.role.map((data) => (
                            {
                                roleId: data
                            }
                        ))
                        ,
                        userOutletModelList: values.outlet && values.outlet.map((data) => (
                            {
                                outletId: data
                            }
                        )),
                        arrayRole: values.role && values.role.map((data) => (
                            masterRole.find(i => i.roleId == data).roleName
                        )),
                        arrayOutlet: values.outlet,
                        arraySaleChannelCode: values.channel,
                        arrayStorageLocationId: values.storageLocationId,
                        arrayStorageLocationIdMovement: values.storageLocationIdMovement,
                    }
                    this.props.authData(newAuth);
                    this.props.history.push(ROUTH_PATH.GET_USER)
                    swal(res.data.message, 'บันทึกเรียบร้อย', "success");
                }).catch(error => {
                    const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                    swal('Error', message, 'error')
                })
            }
            else {
                delete params.updatedBy
                delete params.updatedDate
                if (params.password === values.confirmpassword) {
                    await apiServices.callApi("post", API_PATH.POST_CREATE_USER, params, auth.token).then(res => {
                        this.props.history.push(ROUTH_PATH.GET_USER)
                        swal(res.data.message, '', "success");
                    }).catch(error => {
                        const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                        swal('Error', message, 'error')
                    })
                }
                else {
                    message.error("กรุณายืนยันพาสเวิร์ด")
                }
            }
        }
    }

    render() {
        const {
            submitViewError,
            initialValues,
            disabledInput,
            permissionPage,
            keySelected,
        } = this.state

        return (
            <div className="create-user-content">
                <TitleHead text="ผู้ใช้งานระบบ" icon="user-add" />
                <CreateUserForm
                    submitViewError={submitViewError}
                    initialValues={initialValues}
                    disabledInput={disabledInput}
                />
                <div className="button-layout">
                    <Link to={ROUTH_PATH.DISPLAYUSER}>
                        <ButtonCustom text="ยกเลิก" icon="close" type="danger" />
                    </Link>
                    <ButtonCustom text="บันทึก" icon="save" green onClick={this.handleClickCreateUser} disabled={keySelected ? !permissionPage.authUpdate : !permissionPage.authCreate} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    // console.log("stateRedux==>", state)
    return {
        auth: state.auth,
        keySelectedRedux: state.keySelected,
        reduxForm: state.form.CreateUserForm
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserContainer)