import React, { Component } from 'react'
import './style.css'

export class LabelOutputCustom extends Component {
    render() {
        const {
            label,
            right,
            value,
            widthDefault,
        } = this.props
        return (
            <div className="input-form">
                <div className="input-zone-label">
                    {
                        label &&
                        <div className="label-style" style={{ textAlign: right ? 'right' : '', width: widthDefault ? widthDefault : '150px' }}>
                            {label}
                        </div>
                    }

                    <label className="input-style">{value}</label>
                </div>
            </div>
        )
    }
}
