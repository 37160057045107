import React, { Component } from 'react'
import { Radio } from 'antd';
import { RadioCustomStyled } from "./styled"

export class RadioCustom extends Component {
    render() {
        const {
            options,
            onChange,
            onClick,
            value,
            label,
            right,
            widthDefault,
            small,
            widthSmall,
            widthAll,
            type,
            disabled,
        } = this.props
        return (
            <RadioCustomStyled small={small} widthSmall={widthSmall} widthDefault={widthDefault}>
                <div className="radio-custom-form">
                    <div className="radio-zone-label">
                        {
                            label &&
                            <div
                                className="label-style"
                                style={{ textAlign: right ? 'right' : '', width: widthDefault ? widthDefault : '150px' }}
                            >
                                {label}
                            </div>
                        }
                        <Radio.Group
                            className={`radio-style`}
                            onChange={onChange}
                            value={value}
                            size={small ? 'small' : 'default'}
                            style={{
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    widthAll ? widthAll : ''
                            }}
                            disabled={disabled}
                        >
                            {
                                options && options.map((data, i) => (
                                    type === 'group' ?
                                        <Radio.Button key={i} value={data.value} onClick={onClick}>{data.label}</Radio.Button>
                                        :
                                        <Radio key={i} value={data.value} onClick={onClick}>{data.label}</Radio>
                                ))
                            }
                        </Radio.Group>
                    </div>
                </div>
            </RadioCustomStyled>
        )
    }
}
