import React, { Component } from 'react'
import { isEqual, isEmpty } from 'lodash'
import {
    ModalCustom,
    ButtonCustom,
    NumberFormatCustom,
} from 'components/common'
import { Row, Col } from 'antd'

/*
props 
product = {
    qty,
    pricePerUnit,
    discPercent,
    discPercentAmount,
    discAmountPerUnit,
    discAmountPerUnitAmount,
    discAmount,
    discPromotionInternal,
    discPromotionExternal,
    totalDiscAmount,
}
*/

export class SaleDiscountItemModal extends Component {
    state = {
        productSummary: this.props.product,
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.product, prevProps.product)) {
            this.setState({ productSummary: this.props.product })
        }
    }

    changeDiscountSummary = (key, value) => {
        const { productSummary } = this.state
        value = Number(`${value}`.replace(/,/g, ''))
        this.setState(
            { productSummary: { ...productSummary, [key]: value } },
            () => { this.calculateDiscountSummary() }
        )
    }

    calculateDiscountSummary = () => {
        const { productSummary } = this.state
        const { product } = this.props
        const qty = Number(product.qty || 0)
        const pricePerUnit = Number(product.pricePerUnit || 0)

        this.setState({
            productSummary: {
                ...productSummary,
                discPercent: Number(productSummary.discPercent || 0),
                discPercentAmount: (Number(productSummary.discPercent || 0) * pricePerUnit / 100) * qty,
                discAmountPerUnit: Number(productSummary.discAmountPerUnit || 0),
                discAmountPerUnitAmount: Number(productSummary.discAmountPerUnit || 0) * qty,
                discAmount: Number(productSummary.discAmount || 0),
                discPromotionInternal: Number(productSummary.discPromotionInternal || 0),
                discPromotionExternal: Number(productSummary.discPromotionExternal || 0),
            },
        }, () => { this.calculateTotalDiscAmountSummary() })
    }

    calculateTotalDiscAmountSummary = () => {
        const { productSummary } = this.state
        this.setState({
            productSummary: {
                ...productSummary,
                totalDiscAmount:
                    productSummary.discPercentAmount + productSummary.discAmountPerUnitAmount
                    + productSummary.discAmount + productSummary.discPromotionInternal + productSummary.discPromotionExternal
            },
        })
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            onSubmitDiscount,
        } = this.props
        const {
            productSummary,
        } = this.state
        const haveDiscPromotion = !isEmpty(productSummary.promotionCode) || productSummary.discPromotion > 0 || productSummary.discPromotionInternal > 0
        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                title="ส่วนลดรายการ"
                width="550px"
            >
                <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลดรายการ (%)
                    </Col>
                    <Col sm={5}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeDiscountSummary('discPercent', e.target.value)}
                            value={parseFloat(`${productSummary.discPercent || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            isAllowed={(values) => {
                                const { formattedValue, floatValue } = values
                                return formattedValue === '' || floatValue <= 100
                            }}
                            disabled={haveDiscPromotion}
                        />
                    </Col>
                    <Col sm={8}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            value={parseFloat(`${productSummary.discPercentAmount || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลดรายการ/หน่วย
                    </Col>
                    <Col sm={5}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeDiscountSummary('discAmountPerUnit', e.target.value)}
                            value={parseFloat(`${productSummary.discAmountPerUnit || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled={haveDiscPromotion}
                        />
                    </Col>
                    <Col sm={8}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            value={parseFloat(`${productSummary.discAmountPerUnitAmount || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลดรายการ (จน.)
                    </Col>
                    <Col sm={13}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeDiscountSummary('discAmount', e.target.value)}
                            value={parseFloat(`${productSummary.discAmount || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled={haveDiscPromotion}
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลด Promotion ส่วนกลาง
                    </Col>
                    <Col sm={13}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeDiscountSummary('discPromotionInternal', e.target.value)}
                            value={parseFloat(`${productSummary.discPromotionInternal || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={9}>
                        ส่วนลด Promotion
                    </Col>
                    <Col sm={13}>
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onChange={(e) => this.changeDiscountSummary('discPromotionExternal', e.target.value)}
                            value={parseFloat(`${productSummary.discPromotionExternal || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col sm={9}>
                        Total
                    </Col>
                    <Col sm={13}>
                        <NumberFormatCustom
                            className="text-right font-weight-bold"
                            small
                            value={parseFloat(`${productSummary.totalDiscAmount || 0}`.replace(/,/g, '')).toFixed(2)}
                            decimalScale={2}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        บาท
                    </Col>
                </Row>
                <Row style={{ paddingTop: '20px' }}>
                    <Col sm={24} className="text-right">
                        <ButtonCustom
                            text="อนุมัติ"
                            small
                            onClick={() => { onSubmitDiscount(productSummary) }}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
