import HomeContainer from "pages/DashBoard/containers/HomeContainer"
import DisplayUserContainer from "pages/DashBoard/containers/DisplayUserContainer"
import CreateUserContainer from "pages/DashBoard/containers/CreateUserContainer"
import ChangePasswordContainer from "pages/DashBoard/containers/ChangePasswordContainer"
import ChangePasswordSeftContainer from "pages/DashBoard/containers/ChangePasswordSeftContainer"
import SaleOrderMaintainContainer from "pages/DashBoard/containers/SaleOrderMaintainContainer"
import CustomerMasterContainer from "pages/DashBoard/containers/CustomerMasterContainer"
import CustomerMaintainContainer from "pages/DashBoard/containers/CustomerMaintainContainer"
import SaleEmployeeMasterContainer from "pages/DashBoard/containers/SaleEmployeeMasterContainer"
import SaleEmployeeMaintainContainer from "pages/DashBoard/containers/SaleEmployeeMaintainContainer"
import ProductContainer from "pages/DashBoard/containers/ProductContainer"
import ProductBarcodeContainer from "pages/DashBoard/containers/ProductBarcodeContainer"
import InventoryCountCreateContainer from "pages/DashBoard/containers/InventoryCountCreateContainer"
import InventoryCountEditContainer from "pages/DashBoard/containers/InventoryCountEditContainer"
import InventoryCountSearchContainer from "pages/DashBoard/containers/InventoryCountSearchContainer"
import InventoryCountResultContainer from "pages/DashBoard/containers/InventoryCountResultContainer"
import InventoryCountMaintainContainer from "pages/DashBoard/containers/InventoryCountMaintainContainer"
import PaymentContainer from "pages/DashBoard/containers/PaymentContainer"
import PaymentFullContainer from "pages/DashBoard/containers/PaymentFullContainer"
import PromotionContainer from "pages/DashBoard/containers/PromotionContainer"
import ReportTrackingSaleOrderContainer from "pages/DashBoard/containers/ReportTrackingSaleOrderContainer"
import ReportQuotationContainer from "pages/DashBoard/containers/ReportQuotationContainer"
import ReportStockMovementContainer from "pages/DashBoard/containers/ReportStockMovementContainer"
import ReportInventoryBalanceContainer from "pages/DashBoard/containers/ReportInventoryBalanceContainer"
import ReportShipmentContainer from "pages/DashBoard/containers/ReportShipmentContainer"
import ReportTaxDocumentsContainer from "pages/DashBoard/containers/ReportTaxDocumentsContainer"
import ReportSpecialStockContainer from "pages/DashBoard/containers/ReportSpecialStockContainer"
import ReportCheckStockPriceContainer from "pages/DashBoard/containers/ReportCheckStockPriceContainer"
import ReportCheckPOSContainer from "pages/DashBoard/containers/ReportCheckPOSContainer"
import ReportCnDnContainer from "pages/DashBoard/containers/ReportCnDnContainer"
import ReportCostGpProductContainer from "pages/DashBoard/containers/ReportCostGpProductContainer"
import ReportProductPercentMarginContainer from "pages/DashBoard/containers/ReportProductPercentMarginContainer"
import ProductPercentMarginMaintainContainer from "pages/DashBoard/containers/ProductPercentMarginMaintainContainer"
import ReportS4InterfaceLogContainer from "pages/DashBoard/containers/ReportS4InterfaceLogContainer"
import ReportPOSInterfaceLogContainer from "pages/DashBoard/containers/ReportPOSInterfaceLogContainer"
import ReportSalesLegacySapContainer from "pages/DashBoard/containers/ReportSalesLegacySapContainer"
import PromotionMaintainContainer from "pages/DashBoard/containers/PromotionMaintainContainer"
import QuotationContainer from "pages/DashBoard/containers/QuotationContainer"
import QuotationMaintainContainer from "pages/DashBoard/containers/QuotationMaintainContainer"
import QualifierMaintainContainer from "pages/DashBoard/containers/QualifierMaintainContainer"
import QualifierContainer from "pages/DashBoard/containers/QualifierContainer"
import RoleContainer from "pages/DashBoard/containers/RoleContainer"
import RoleCreateContainer from "pages/DashBoard/containers/RoleCreateContainer"
import PromotionNetPriceContainer from "pages/DashBoard/containers/PromotionNetPriceContainer"
import GoodsIssueContainer from "pages/DashBoard/containers/GoodsIssueContainer"
import GoodsReceiveContainer from "pages/DashBoard/containers/GoodsReceiveContainer"
import GoodsReceiveWithoutRefContainer from "pages/DashBoard/containers/GoodsReceiveWithoutRefContainer"
import GoodsReturnContainer from "pages/DashBoard/containers/GoodsReturnContainer"
import GoodsTransferContainer from "pages/DashBoard/containers/GoodsTransferContainer"
import PurchaseClearBillingContainer from "pages/DashBoard/containers/PurchaseClearBillingContainer"
import PurchaseCreatePOSOContainer from "pages/DashBoard/containers/PurchaseCreatePOSOContainer"
import GoodsIssueSTOContainer from "pages/DashBoard/containers/GoodsIssueSTOContainer"
import GoodsIssueRefReservationContainer from "pages/DashBoard/containers/GoodsIssueRefReservationContainer"
import ReservationCreateContainer from "pages/DashBoard/containers/ReservationCreateContainer"
import ShipmentMaintainContainer from "pages/DashBoard/containers/ShipmentMaintainContainer"
import CnDnMaintainContainer from "pages/DashBoard/containers/CnDnMaintainContainer"
import CnDnManualInterfaceContainer from "pages/DashBoard/containers/CNInterfaceContainer"
import ManualRunInterface2ChampContainer from "pages/DashBoard/containers/ManualRunInterface2ChampContainer"
import ManualInterfaceIncomingPaymentContainer from "pages/DashBoard/containers/ManualInterfaceIncomingPaymentContainer"
import WebMarginCSVBYBillingContainer from 'pages/DashBoard/containers/WebMarginCSVBYBillingContainer'
import ReportReceivestockCompareChampContainer from "pages/DashBoard/containers/ReportReceivestockCompareChampContainer"
import NotFound from "pages/NotFound"
import AccessDeniedContainer from "pages/DashBoard/containers/AccessDeniedContainer"
import { ROUTH_PATH } from "./path"
import { functionAlias } from "./functionAlias"

export const homePageRoute = [
    {
        name: 'Home',
        icon: 'home',
        path: ROUTH_PATH.HOME,
        component: HomeContainer,
        exact: true
    },
    {
        name: 'Home',
        icon: 'home',
        path: ROUTH_PATH.HOME2,
        component: HomeContainer,
        exact: true
    },
]

export const forceRoute = [
    {
        alias: "USR_MA",
        path: ROUTH_PATH.ADDUSER,
        name: "ผู้ใช้งานระบบ",
        component: CreateUserContainer
    },
    {
        alias: "USR_MA",
        path: ROUTH_PATH.EDITUSER,
        name: "ผู้ใช้งานระบบ",
        component: CreateUserContainer
    },
    {
        alias: "USR_MA",
        path: ROUTH_PATH.CHANGE_PASSWORD,
        name: "ผู้ใช้งานระบบ",
        component: ChangePasswordContainer,
    },
    {
        alias: "USR_MA",
        path: ROUTH_PATH.CHANGE_PASSWORD_SEFT,
        name: "ผู้ใช้งานระบบ",
        component: ChangePasswordSeftContainer,
    },
    {
        alias: "CUST_MA",
        path: ROUTH_PATH.CUSTOMER_CREATE,
        name: "จัดการลูกค้า",
        component: CustomerMaintainContainer,
    },
    {
        alias: "CUST_MA",
        path: ROUTH_PATH.CUSTOMER_UPDATE,
        name: "จัดการลูกค้า",
        component: CustomerMaintainContainer,
    },
    {
        alias: "INVNT_CNT_MA",
        path: ROUTH_PATH.INVENTORY_COUNT_EDIT,
        name: "สร้าง/แก้ไขใบตรวจนับ",
        component: InventoryCountEditContainer,
        exact: true
    },
    {
        alias: "INVNT_CNT_MA",
        path: ROUTH_PATH.INVENTORY_COUNT_EDIT,
        name: "สร้าง/แก้ไขใบตรวจนับ",
        component: InventoryCountEditContainer,
        exact: true
    },
    {
        alias: "QUOT_MA",
        path: ROUTH_PATH.QUOTATION_UPDATE,
        name: "สร้าง/แก้ไขใบเสนอราคา",
        component: QuotationMaintainContainer,
        exact: true
    },
    {
        alias: "SO_MA",
        path: ROUTH_PATH.SALE_ORDER_EDIT,
        name: "ใบสั่งขาย",
        component: SaleOrderMaintainContainer,
    },
    {
        alias: "PROMO_MA",
        path: ROUTH_PATH.PROMOTION_MAINTAIN,
        component: PromotionMaintainContainer,
        exact: true
    },
    {
        alias: "PROMO_MA",
        path: ROUTH_PATH.PROMOTION_MAINTAIN_EDIT,
        component: PromotionMaintainContainer,
    },
    {
        alias: "PROMO_MA",
        path: ROUTH_PATH.PROMOTION_MAINTAIN_REF,
        component: PromotionMaintainContainer,
    },
    {
        alias: "PROMO_MA",
        path: ROUTH_PATH.PROMOTION_NET_PRICE,
        component: PromotionNetPriceContainer,
        exact: true,
    },
    {
        alias: "PROMO_MA",
        path: ROUTH_PATH.PROMOTION_NET_PRICE_EDIT,
        component: PromotionNetPriceContainer,
        exact: true,
    },
    {
        alias: "PROMO_MA",
        path: ROUTH_PATH.PROMOTION_NET_PRICE_REF,
        component: PromotionNetPriceContainer,
        exact: true,
    },
    {
        alias: "PROMO_QULFY_MA",
        path: ROUTH_PATH.PROMOTION_QUALIFIER_CREATE,
        component: QualifierMaintainContainer,
    },
    {
        alias: "PROMO_QULFY_MA",
        path: ROUTH_PATH.PROMOTION_QUALIFIER_EDIT,
        component: QualifierMaintainContainer,
    },
    {
        alias: "EMPL_MA",
        path: ROUTH_PATH.SALEEMPLOYEE_CREATE,
        component: SaleEmployeeMaintainContainer,
    },
    {
        alias: "EMPL_MA",
        path: ROUTH_PATH.SALEEMPLOYEE_UPDATE,
        component: SaleEmployeeMaintainContainer,
    },
    {
        alias: "ROLE_MA",
        path: ROUTH_PATH.ROLE_CREATE,
        component: RoleCreateContainer,
    },
    {
        alias: "ROLE_MA",
        path: ROUTH_PATH.ROLE_EDIT,
        component: RoleCreateContainer,
    },
    {
        alias: "INVNT_RESERVAT",
        path: ROUTH_PATH.RESERVATION_EDIT,
        component: ReservationCreateContainer,
    },
    {
        alias: "CSHRECV_MA",
        path: ROUTH_PATH.PAYMENT_LINK,
        component: PaymentContainer,
    },
    {
        alias: "TRANSPT_MA",
        path: ROUTH_PATH.SHIPMENT_CREATE,
        component: ShipmentMaintainContainer,
    },
    {
        alias: "TRANSPT_MA",
        path: ROUTH_PATH.SHIPMENT_EDIT,
        component: ShipmentMaintainContainer,
    },
    {
        alias: "CNDN_MA",
        path: ROUTH_PATH.CN_DN_EDIT,
        name: "ใบขอรับคืน/ลดหนี้/เพิ่มหนี้",
        component: CnDnMaintainContainer,
    },
    {
        alias: "RPT_PC_MG",
        path: ROUTH_PATH.PRODUCT_PERCENT_MARGIN_CREATE,
        component: ProductPercentMarginMaintainContainer,
    },
    {
        alias: "RPT_PC_MG",
        path: ROUTH_PATH.PRODUCT_PERCENT_MARGIN_EDIT,
        component: ProductPercentMarginMaintainContainer,
    },
    {
        alias: "SO_MA",
        path: ROUTH_PATH.ACCESS_DENIED,
        component: AccessDeniedContainer,
    },
]

export const dashboardRoute = [
    {
        alias: "CUST_MA",
        module: "ลูกค้า",
        name: "จัดการลูกค้า",
        icon: "team",
        path: ROUTH_PATH.CUSTOMER,
        component: CustomerMasterContainer,
    },
    {
        alias: "EMPL_MA",
        module: "พนักงานขาย",
        name: "จัดการพนักงานขาย",
        icon: "user",
        path: ROUTH_PATH.SALEEMPLOYEE,
        component: SaleEmployeeMasterContainer,
    },
    {
        alias: "PRDC_LIST",
        module: "สินค้าคงคลัง",
        name: "รายการสินค้า",
        icon: "build",
        path: ROUTH_PATH.PRODUCT,
        component: ProductContainer,
        exact: true,
    },
    {
        alias: "PRDC_LABEL",
        module: "สินค้าคงคลัง",
        name: "ป้ายราคาสินค้า",
        icon: "barcode",
        path: ROUTH_PATH.PRODUCT_BARCODE,
        component: ProductBarcodeContainer,
    },
    {
        alias: "INVNT_CNT_MA",
        module: "ตรวจนับสต็อค",
        name: "สร้าง/แก้ไขใบตรวจนับ",
        icon: "file-text",
        path: ROUTH_PATH.INVENTORY_COUNT_CREATE,
        component: InventoryCountCreateContainer,
    },
    {
        alias: "INVNT_CNT_MA",
        module: "ตรวจนับสต็อค",
        name: "สร้าง/แก้ไขใบตรวจนับ",
        icon: "file-text",
        path: ROUTH_PATH.INVENTORY_COUNT_EDIT_LINK,
    },
    {
        alias: "INVNT_CNT_LIST",
        module: "ตรวจนับสต็อค",
        name: "ค้นหาใบตรวจนับ",
        icon: "file-search",
        path: ROUTH_PATH.INVENTORY_COUNT_SEARCH,
        component: InventoryCountSearchContainer,
    },
    {
        alias: "INVNT_CNT_STCK",
        module: "ตรวจนับสต็อค",
        name: "ตรวจนับสต็อค",
        icon: "check-square",
        path: ROUTH_PATH.INVENTORY_COUNT_RESULT,
        component: InventoryCountResultContainer,
    },
    {
        alias: "INVNT_CNT_ADM",
        module: "ตรวจนับสต็อค",
        name: "Admin ใบตรวจนับ",
        icon: "file-done",
        path: ROUTH_PATH.INVENTORY_COUNT_MAINTAIN,
        component: InventoryCountMaintainContainer,
    },
    {
        alias: "SO_MA",
        module: "การขาย",
        name: "สร้าง/แก้ไขใบสั่งขาย",
        icon: "shopping-cart",
        path: ROUTH_PATH.SALE_ORDER_CREATE,
        component: SaleOrderMaintainContainer,
    },
    {
        alias: "SO_MA",
        module: "การขาย",
        name: "สร้าง/แก้ไขใบสั่งขาย",
        icon: "shopping-cart",
        path: ROUTH_PATH.SALE_ORDER_EDIT_LINK,
    },
    {
        alias: "CSHRECV_MA",
        module: "การขาย",
        name: "รับชำระเงินสด",
        icon: "credit-card",
        path: ROUTH_PATH.PAYMENT,
        component: PaymentContainer,
        exact: true,
    },
    {
        alias: "PAYM_MA",
        module: "การขาย",
        name: "ออกใบกำกับอย่างเต็ม",
        icon: "file-text",
        path: ROUTH_PATH.PAYMENT_FULL,
        component: PaymentFullContainer,
    },
    {
        alias: "CNDN_MA",
        module: "การขาย",
        name: "ใบขอรับคืน/ลดหนี้/เพิ่มหนี้",
        icon: "file",
        path: ROUTH_PATH.CN_DN_CREATE,
        component: CnDnMaintainContainer,
        exact: true,
    },
    {
        alias: "CNDN_MA",
        module: "การขาย",
        name: "ใบขอรับคืน/ลดหนี้/เพิ่มหนี้",
        icon: "file",
        path: ROUTH_PATH.CN_DN_EDIT_LINK,
        exact: true,
    },
    /*{
        alias: "RPT_CNDN",
        module: "การขาย",
        name: "ใบขอรับคืน",
        icon: "file",
        path: ROUTH_PATH.CN_INTERFACE,
        component: CNInterfaceContainer,
        exact: true,
    },*/
    {
        alias: "PROMO_MA",
        module: "การขาย",
        name: "การส่งเสริมการขาย(Promotion)",
        icon: "gift",
        path: ROUTH_PATH.PROMOTION,
        component: PromotionContainer,
        exact: true
    },
    {
        alias: "PROMO_QULFY_MA",
        module: "การขาย",
        name: "Promotion Qualifier",
        icon: "filter",
        path: ROUTH_PATH.PROMOTION_QUALIFIER_SEARCH,
        component: QualifierContainer,
    },
    {
        alias: "QUOT_LIST",
        module: "ใบเสนอราคา",
        name: "ติดตามใบเสนอราคา",
        icon: "unordered-list",
        path: ROUTH_PATH.QUOTATION,
        component: QuotationContainer,
    },
    {
        alias: "QUOT_MA",
        module: "ใบเสนอราคา",
        name: "สร้าง/แก้ไขใบเสนอราคา",
        icon: "file-text",
        path: ROUTH_PATH.QUOTATION_CREATE,
        component: QuotationMaintainContainer,
    },
    {
        alias: "QUOT_MA",
        module: "ใบเสนอราคา",
        name: "สร้าง/แก้ไขใบเสนอราคา",
        icon: "file-text",
        path: ROUTH_PATH.QUOTATION_UPDATE_LINK,
    },
    {
        alias: "RPT_QUOT",
        module: "รายงาน",
        name: "รายงานใบเสนอราคา",
        icon: "file-text",
        path: ROUTH_PATH.REPORT_QUOTATION,
        component: ReportQuotationContainer,
    },
    {
        alias: "RPT_SO",
        module: "รายงาน",
        name: "รายงานติดตามสถานะใบสั่งขาย",
        icon: "exception",
        path: ROUTH_PATH.TRACKING_SALE_ORDER,
        component: ReportTrackingSaleOrderContainer,
    },
    {
        alias: "RPT_INVNT_MOV",
        module: "รายงาน",
        name: "รายงานเคลื่อนไหวสินค้า",
        icon: "file-sync",
        path: ROUTH_PATH.REPORT_STOCK_MOVEMENT,
        component: ReportStockMovementContainer,
    },
    {
        alias: "RPT_SPEC_STCK",
        module: "รายงาน",
        name: "รายงานสต็อกพิเศษ",
        icon: "file-text",
        path: ROUTH_PATH.REPORT_SPECIAL_STOCK,
        component: ReportSpecialStockContainer,
    },
    {
        alias: "RPT_CHK_STCK_PR",
        module: "รายงาน",
        name: "รายงานตรวจสอบสินค้าคงเหลือและราคา",
        icon: "file-search",
        path: ROUTH_PATH.REPORT_CHECK_STOCK_PRICE,
        component: ReportCheckStockPriceContainer,
    },
    {
        alias: "RPT_CHK_POS",
        module: "รายงาน",
        name: "โปรแกรมตรวจสอบ POS Interface",
        icon: "check-square",
        path: ROUTH_PATH.REPORT_CHECK_POS,
        component: ReportCheckPOSContainer,
    },
    {
        alias: "RPT_PC_MG",
        module: "รายงาน",
        name: "รายงาน Percent Margin",
        icon: "percentage",
        path: ROUTH_PATH.REPORT_PRODUCT_PERCENT_MARGIN,
        component: ReportProductPercentMarginContainer,
    },
    {
        alias: "USR_MA",
        module: "จัดการระบบ",
        name: "ผู้ใช้งานระบบ",
        icon: "user",
        path: ROUTH_PATH.DISPLAYUSER,
        component: DisplayUserContainer,
        exact: true
    },
    {
        alias: "ROLE_MA",
        module: "จัดการระบบ",
        name: "สิทธิ์ของระบบ",
        icon: "appstore",
        path: ROUTH_PATH.ROLE,
        component: RoleContainer,
        exact: true,
    },
    {
        alias: "INVNT_GI",
        module: "สินค้าคงคลัง",
        name: "การตัดจ่ายสินค้า",
        icon: "stock",
        path: ROUTH_PATH.GOODS_ISSUE,
        component: GoodsIssueContainer,
        exact: true,
    },
    {
        alias: "INVNT_RECPT",
        module: "สินค้าคงคลัง",
        name: "การรับเข้าสินค้า",
        icon: "download",
        path: ROUTH_PATH.GOODS_RECEIVE,
        component: GoodsReceiveContainer,
        exact: true,
    },
    {
        alias: "INVNT_RECPT_WHOT",
        module: "สินค้าคงคลัง",
        name: "GR without Ref",
        icon: "download",
        path: ROUTH_PATH.GOODS_RECEIVE_WITHOUT_REF,
        component: GoodsReceiveWithoutRefContainer,
        exact: true,
    },
    {
        alias: "INVNT_RETURN",
        module: "สินค้าคงคลัง",
        name: "การยกเลิกการรับเข้าสินค้า",
        icon: "close-square",
        path: ROUTH_PATH.GOODS_RETURN,
        component: GoodsReturnContainer,
        exact: true,
    },
    {
        alias: "INVNT_MOV",
        module: "สินค้าคงคลัง",
        name: "โอนย้ายสินค้า",
        icon: "swap",
        path: ROUTH_PATH.GOODS_TRANSFER,
        component: GoodsTransferContainer,
    },
    {
        alias: "CLEAR_BILL",
        module: "ใบเสนอซื้อ",
        name: "Clear Billing",
        icon: "form",
        path: ROUTH_PATH.PURCHASE_CLEAR_BILLING,
        component: PurchaseClearBillingContainer,
    },
    {
        alias: "CREA_POSO",
        module: "ใบเสนอซื้อ",
        name: "Create PO SO",
        icon: "file-add",
        path: ROUTH_PATH.PURCHASE_CREATE_PO_SO,
        component: PurchaseCreatePOSOContainer,
    },
    {
        alias: "CNDN_MANUAL",
        module: "ใบเสนอซื้อ",
        name: "Manual CN Interface",
        icon: "file",
        path: ROUTH_PATH.CN_DN_MANUAL_INTERFACE,
        component: CnDnManualInterfaceContainer,
        exact: true,
    },
    // จ้า
    {
        alias: "MARGIN_CSV_MANUAL",
        module: "ใบเสนอซื้อ",
        name: "Manual Create Textfile",
        icon: "file",
        path: ROUTH_PATH.REPORT_WEBMARGIN_CSV_BY_BILLING,
        component: WebMarginCSVBYBillingContainer,
        exact: true,
    },
    {
        alias: "INF_INCOME_PAY",
        module: "Manual Interface Incoming Payment",
        name: "Manual Interface Incoming Payment",
        icon: "file",
        path: ROUTH_PATH.MANUAL_INTERFACE_INCOMING_PAYMENT,
        component: ManualInterfaceIncomingPaymentContainer,
        exact: true,
    },
    
    {
        alias: "INVNT_GI_STO",
        module: "สินค้าคงคลัง",
        name: "การตัดจ่ายสินค้า STO",
        icon: "stock",
        path: ROUTH_PATH.GOODS_ISSUE_STO,
        component: GoodsIssueSTOContainer,
        exact: true,
    },
    {
        alias: "INVNT_GI_REF_RESERV",
        module: "สินค้าคงคลัง",
        name: "ตัดจ่ายใบเบิกใช้",
        icon: "export",
        path: ROUTH_PATH.GOODS_ISSUE_REF_RESERVATION,
        component: GoodsIssueRefReservationContainer,
        exact: true,
    },
    {
        alias: "INVNT_RESERVAT",
        module: "สินค้าคงคลัง",
        name: "สร้างใบเบิกใช้",
        icon: "file-search",
        path: ROUTH_PATH.RESERVATION_CREATE,
        component: ReservationCreateContainer,
        exact: true,
    },
    {
        alias: "RPT_INVNT_BAL",
        module: "รายงาน",
        name: "รายงานสินค้าคงเหลือ",
        icon: "hdd",
        path: ROUTH_PATH.REPORT_INVENTORY_BALANCE,
        component: ReportInventoryBalanceContainer,
        exact: true,
    },
    {
        alias: "TRANSPT_MA",
        module: "รายงาน",
        name: "การขนส่ง/จัดเที่ยวรถ",
        icon: "car",
        path: ROUTH_PATH.SHIPMENT_SEARCH,
        component: ReportShipmentContainer,
        exact: true,
    },
    {
        alias: "RPT_SPECIAL_TAX",
        module: "รายงาน",
        name: "รายงานเอกสารทางภาษี",
        icon: "file-text",
        path: ROUTH_PATH.REPORT_TAX_DOCUMENT,
        component: ReportTaxDocumentsContainer,
        exact: true,
    },
    {
        alias: "RPT_CNDN",
        module: "รายงาน",
        name: "รายงานใบแจ้งหนี้/ลดหนี้/เพิ่มหนี้",
        icon: "file",
        path: ROUTH_PATH.REPORT_CN_DN,
        component: ReportCnDnContainer,
        exact: true,
    },
    {
        alias: "RPT_COSTGP",
        module: "รายงาน",
        name: "รายงานต้นทุน/GP สินค้า",
        icon: "file",
        path: ROUTH_PATH.REPORT_COST_GP_PRODUCT,
        component: ReportCostGpProductContainer,
        exact: true,
    },
    {
        alias: "RPT_POS_INF",
        module: "รายงาน",
        name: "รายงานตรวจสอบ POS Interface Log",
        icon: "file",
        path: ROUTH_PATH.REPORT_POS_INTERFACE_LOG,
        component: ReportPOSInterfaceLogContainer,
        exact: true,
    },
    {
        alias: "RPT_S4_INF",
        module: "รายงาน",
        name: "รายงานตรวจสอบ S4 Interface Log",
        icon: "file-text",
        path: ROUTH_PATH.REPORT_S4_INTERFACE_LOG,
        component: ReportS4InterfaceLogContainer,
        exact: true,
    },
    {
        alias: "RPT_SALES_LEGC_SAP",
        module: "รายงาน",
        name: "รายงานใบแจ้งหนี้/ลดหนี้/เพิ่มหนี้ ม.ค. - มิ.ย. 2563",
        icon: "file-sync",
        path: ROUTH_PATH.REPORT_SALES_LEGACY_SAP,
        component: ReportSalesLegacySapContainer,
        exact: true,
    },
    {
        alias: "INF_CHAMP_MANUAL",
        module: "ใบสั่งขาย",
        name: "Manual Run Interface2CHAMP",
        icon: "file-sync",
        path: ROUTH_PATH.MANUAL_RUN_INTERFACE2CHAMP,
        component: ManualRunInterface2ChampContainer,
        exact: true,
    },
    {
        alias: "RPT_GR_PO_CHAMP",
        module: "รายงาน",
        name: "รายงานรับสินค้าจากการซื้อเทียบกับCHAMP",
        icon: "file-text",
        path: ROUTH_PATH.REPORT_RECEIVE_STOCK_COMPARE_CHAMP,
        component: ReportReceivestockCompareChampContainer,
        exact: true,
    },
    {
        alias: "PR_LIST",
        module: "ใบเสนอซื้อ",
        name: "ติดตามใบขอเสนอซื้อ",
        icon: "unordered-list",
        path: ROUTH_PATH.PURCHASE_SEARCH,
        component: NotFound,
        exact: true,
    },
    {
        alias: "PR_MA",
        module: "ใบเสนอซื้อ",
        name: "สร้างใบขอเสนอซื้อ",
        icon: "file-text",
        path: ROUTH_PATH.PURCHASE_CREATE,
        component: NotFound,
        exact: true,
    },
    {
        alias: "RPT_PR",
        module: "รายงาน",
        name: "รายงานใบขอเสนอซื้อ",
        icon: "file-text",
        path: ROUTH_PATH.REPORT_PURCHASE,
        component: NotFound,
        exact: true,
    },
    {
        alias: "RPT_INVNT_CNT_STCK",
        module: "รายงาน",
        name: "รายงานตรวจนับสต็อค",
        icon: "check-square",
        path: ROUTH_PATH.REPORT_INVENTORY_COUNT,
        component: NotFound,
        exact: true,
    },
]

export * from "./path"
export * from "./functionAlias"