import React, { Component } from 'react'
import swal from '@sweetalert/with-react'
import moment from 'moment'
import { connect } from 'react-redux'
import { apiServices, API_PATH } from 'apiServices'
import { get, findLast } from 'lodash'
import { Row, Col, Spin } from 'antd'
import { TableCustom, ButtonCustom, ModalCustom } from 'components/common'
import { formatNumber } from 'helpers'

const configForm = {
    ZPMT: {
        url: API_PATH.GET_REPORT_FORM_TAX_INVOICE,
        taxType: {
            full: {
                title: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างเต็ม',
            },
            abb: {
                title: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ',
            },
        }
    },
    ZIN2: {
        url: API_PATH.GET_REPORT_FORM_DEBT_INVOICE,
    },
    ZDEP: {
        /*
        isMultiForm: true,
        detail: [
            {
                url: API_PATH.GET_REPORT_FORM_DEPOSIT_INVOICE,
                title: '- ใบมัดจำใบกำกับ',
            },
            {
                url: API_PATH.GET_REPORT_FORM_DEPOSIT_INVOICE_NEW,
                title: '- ใบเสร็จรับเงิน (มัดจำ)',
            },
        ],
        */
        url: API_PATH.GET_REPORT_FORM_DEPOSIT_INVOICE_NEW,
    },
    ZINP: {
        isMultiForm: true,
        detail: [
            {
                url: API_PATH.GET_REPORT_FORM_POS_FULL_TAX_INVOICE,
                title: '(เต็ม)',
            },
            {
                url: API_PATH.GET_REPORT_FORM_POS_TAX_INVOICE,
                title: '(ย่อ)',
            },
        ],
    },
    ZCRP: {
        url: API_PATH.GET_REPORT_FORM_POS_REDUCE_DEBT,
    },
    ZQT: {
        url: API_PATH.GET_REPORT_FORM_QUOTATION,
    },
    ZOCS: {
        url: API_PATH.GET_REPORT_FORM_SALES_ORDER,
    },
    ZOPS: {
        url: API_PATH.GET_REPORT_FORM_SALES_ORDER,
    },
    ZOCR: {
        url: API_PATH.GET_REPORT_FORM_SALES_ORDER,
    },
    ZDO: {
        isMultiForm: true,
        detail: [
            {
                url: API_PATH.GET_REPORT_FORM_DELIVERY_ORDER,
                title: '(เต็ม)',
            },
            {
                url: API_PATH.GET_REPORT_FORM_DELIVERY_ORDER_A5,
                title: '(ย่อ)',
            },
        ],
    },
    INVC: {
        url: API_PATH.GET_REPORT_FORM_INVENTORY_COUNT,
    },
    SHIPMENT: {
        isMultiForm: true,
        detail: [
            {
                url: API_PATH.GET_REPORT_FORM_PREPARE_ORDER,
                title: '(เต็ม)',
            },
            {
                url: API_PATH.GET_REPORT_FORM_PREPARE_ORDER_A5,
                title: '(ย่อ)',
            },
        ],
    },
    ZCR: {
        url: API_PATH.GET_REPORT_REDUCE_DEBT_REQUEST,
    },
    ZCRE: {
        url: API_PATH.GET_REPORT_REDUCE_DEBT_REQUEST,
    },
    ZCRR: {
        url: API_PATH.GET_REPORT_REDUCE_DEBT_REQUEST,
    },
    ZCRD: {
        url: API_PATH.GET_REPORT_REDUCE_DEBT_REQUEST,
    },
    ZDR: {
        url: API_PATH.GET_REPORT_REDUCE_DEBT_REQUEST,
    },
    ZCN: {
        isMultiForm: true,
        detail: [
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT,
                title: '(ใหญ่)',
            },
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT_A5,
                title: '(เล็ก)',
            },
        ],
    },
    ZCNR: {
        isMultiForm: true,
        detail: [
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT,
                title: '(ใหญ่)',
            },
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT_A5,
                title: '(เล็ก)',
            },
        ],
    },
    ZCNN: {
        isMultiForm: true,
        detail: [
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT,
                title: '(ใหญ่)',
            },
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT_A5,
                title: '(เล็ก)',
            },
        ],
    },
    ZCND: {
        isMultiForm: true,
        detail: [
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT,
                title: '(ใหญ่)',
            },
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT_A5,
                title: '(เล็ก)',
            },
        ],
    },
    ZDN: {
        isMultiForm: true,
        detail: [
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT,
                title: '(ใหญ่)',
            },
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT_A5,
                title: '(เล็ก)',
            },
        ],
    },
    ZCNP: {
        isMultiForm: true,
        detail: [
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT,
                title: '(ใหญ่)',
            },
            {
                url: API_PATH.GET_REPORT_REDUCE_DEBT_A5,
                title: '(เล็ก)',
            },
        ],
    },
    CNVOUCHER: {
        url: API_PATH.POST_GET_REPORT_CN_VOUCHER,
    }
}

class DocumentFlowTable extends Component {
    state = {
        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: 'action',
                key: 'action',
                width: 100,
                render: (props, row) => (
                    <div className="action-box">
                        {get(row, 'buttonDelete.visible') && <div className="button">
                            <ButtonCustom
                                text="ลบ"
                                icon="delete"
                                type="danger"
                                small
                                disabled={row.buttonDelete.disabled || !this.props.permissionPage.authDelete}
                                onClick={() => this.onClickDeleteDocumentFlow(row)}
                            />
                        </div>}
                        {get(row, 'buttonPrint.visible') && <div className="button">
                            <ButtonCustom
                                text="พิมพ์"
                                icon="printer"
                                small
                                green
                                disabled={row.buttonPrint.disabled || !this.props.permissionPage.authPrint}
                                onClick={() => this.onClickPrintForm(row)}
                            />
                        </div>}
                    </div>
                )
            },
            {
                title: 'เอกสารที่เกี่ยวข้อง/ประเภทเอกสาร',
                align: 'center',
                dataIndex: 'documentTypeCode',
                key: 'documentTypeCode',
                render: (props, row) => (
                    props && <div>{props} {row.documentTypeDesc}</div>
                ),
            },
            {
                title: 'เอกสารที่เกี่ยวข้อง/เลขที่เอกสาร',
                align: 'center',
                dataIndex: 'documentNo',
                key: 'documentNo',
                width: 200,
            },
            {
                title: 'สร้างเมื่อ',
                align: 'center',
                dataIndex: 'documentDate',
                key: 'documentDate',
                render: (props) => (
                    props && <div>{moment(props).format('DD/MM/YYYY HH:mm:ss')}</div>
                ),
            },
            {
                title: 'จำนวนเงินสุทธิ',
                align: 'center',
                dataIndex: 'documentAmount',
                key: 'documentAmount',
                render: (props) => (
                    <div className="text-right">{props && formatNumber(props, 2)}</div>
                ),
            },
            {
                title: 'สถานะ',
                align: 'center',
                dataIndex: 'documentStatus',
                key: 'documentStatus',
            },
        ],
        columnsDetail: [
            {
                title: '#',
                align: 'center',
                dataIndex: 'url',
                key: 'url',
                width: 60,
                render: (props) => (
                    <ButtonCustom
                        text="พิมพ์"
                        icon="printer"
                        small
                        green
                        onClick={() => this.openForm(props)}
                        disabled={!this.props.permissionPage.authPrint}
                    />
                )
            },
            {
                title: 'เอกสาร',
                align: 'center',
                dataIndex: 'documentTypeDesc',
                key: 'documentTypeDesc',
                render: (props, row) => (
                    <div>{props} {row.title}</div>
                ),
            },
            {
                title: 'เลขที่เอกสาร',
                align: 'center',
                dataIndex: 'documentNo',
                key: 'documentNo',
            },
            {
                title: 'สถานะ',
                align: 'center',
                dataIndex: 'documentStatus',
                key: 'documentStatus',
            },
        ],
        loading: false,
        isShowModal: false,
        dataAction: null,
    }

    componentDidMount() {
        const { documentFlows, openPrint: typePrint } = this.props
        if (typePrint === 'ZPMT') {
            const documentFlowSO = findLast(documentFlows, { 'documentTypeCode': 'ZPMT' })
            if (documentFlowSO) {
                this.onClickPrintForm(documentFlowSO, true)
            }
        } else if (typePrint === 'ZDEP') {
            const documentFlowDeposit = findLast(documentFlows, { 'documentTypeCode': 'ZDEP' })
            if (documentFlowDeposit) {
                this.onClickPrintForm(documentFlowDeposit, true)
            }
        }
    }

    openModal = (dataAction = null) => {
        this.setState({ isShowModal: true, dataAction })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, dataAction: null })
    }

    onClickPrintForm = async (row, showModal = false) => {
        const docTypeCode = row.documentTypeCode.toUpperCase()
        const data = configForm[docTypeCode]
        if (data) {
            if (docTypeCode === 'ZPMT') {
                const cashReceive = await this.getCashReceive(row)
                if (cashReceive && cashReceive.salesBillingInvoice) {
                    const detail = cashReceive.salesBillingInvoice.map((invoice) => {
                        const taxInvoiceType = (invoice.taxInvoiceType || '').toLowerCase()
                        const taxInvoiceData = data.taxType[taxInvoiceType] || {}
                        return {
                            url: this.getUrlPrint(data.url, { ...row, taxInvoiceType }),
                            // fix text in title
                            documentTypeDesc: '',
                            documentNo: row.documentNo,
                            documentStatus: row.documentStatus,
                            title: taxInvoiceData.title,
                        }
                    })
                    this.openModal(detail)
                }
            } else if (showModal) {
                this.openModal([{
                    ...row,
                    url: this.getUrlPrint(data.url, row),
                }])
            } else if (['ZCN', 'ZCNR', 'ZCNN', 'ZCND', 'ZDN', 'ZCNP'].includes(docTypeCode) && row.cnVoucherFlag) {
                // credit note and voucher
                const detail = data.detail.map((value) => ({
                    ...value,
                    url: this.getUrlPrint(value.url, row),
                    documentTypeDesc: row.documentTypeDesc,
                    documentNo: row.documentNo,
                    documentStatus: row.documentStatus,
                }))
                detail.push({
                    url: this.getUrlPrint(configForm.CNVOUCHER.url, row),
                    documentTypeDesc: 'คูปองลดหนี้',
                    documentNo: row.documentNo,
                    documentStatus: row.documentStatus,
                })
                this.openModal(detail)
            } else if (!data.isMultiForm) {
                const url = this.getUrlPrint(data.url, row)
                this.openForm(url)
            } else {
                const detail = data.detail.map((value) => ({
                    ...value,
                    url: this.getUrlPrint(value.url, row),
                    documentTypeDesc: row.documentTypeDesc,
                    documentNo: row.documentNo,
                    documentStatus: row.documentStatus,
                }))
                this.openModal(detail)
            }
        }
    }

    getUrlPrint = (url, row) => {
        const qsAdded = row.documentTypeCode === 'ZPMT' ? `&TaxInvoiceType=${row.taxInvoiceType}` : ''
        return `${url}?userid=${this.props.auth.userId}&OutletId=${row.outletId}&DocumentNo=${row.documentNo}&BillingNo=${row.documentNo}&DocumentTypeCode=${row.documentTypeCode}${qsAdded}`
    }

    // openForm = (url) => {
    //     window.open(url, '_blank')
    // }
    openForm = async (url) => {
        let reportRDF = await apiServices.callApi('get', url, {}, { responseType: 'blob' })
        let urlOpen = window.URL.createObjectURL(reportRDF.data)
        window.open(urlOpen);
    }

    onClickDeleteDocumentFlow = async (row) => {
        const body = {
            documentTypeCode: row.documentTypeCode === 'SHIPMENT' ? 'ZDO' : row.documentTypeCode,
            soNumber: row.soNumber,
            documentNumber: row.documentNo,
            outletId: row.outletId,
            changedBy: this.props.auth.userId,
        }

        this.setState({ loading: true })
        const url = API_PATH.POST_DELETE_SALES_DOC_FLOWS
        await apiServices.callApi('post', url, body).then(res => {
            if (res.status === 200) {
                swal('Success', `ลบข้อมูลสำเร็จ`, 'success').then(() => {
                    const { getSaleOrderDetail, getDocFlow, soNumber, outletId, initiatData } = this.props
                    window.location.reload();
                    // getSaleOrderDetail(soNumber, outletId)
                    // getDocFlow()
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    getCashReceive = async (row) => {
        this.setState({ loading: true })
        try {
            const url = `${API_PATH.GET_CASHRECEIVE}?OutletId=${row.outletId}&BillingNo=${row.documentNo}`
            const result = await apiServices.callApi('get', url)
            if (result.status === 200) {
                this.setState({ loading: false })
                return get(result, 'data.results.0')
            } else {
                throw result
            }
        } catch (error) {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูล Cash Receive กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }
        this.setState({ loading: false })
    }

    render() {
        const {
            documentFlows,
        } = this.props
        const {
            columns,
            columnsDetail,
            dataAction,
            isShowModal,
            loading,
        } = this.state
        return (
            <Spin spinning={loading}>
                <TableCustom
                    columns={columns}
                    data={documentFlows}
                    rowKey={(row, index) => index}
                    small
                    pagination={false}
                />
                {dataAction && <ModalCustom
                    isShowModal={isShowModal}
                    onCloseModal={this.closeModal}
                    width="600px"
                    title="เอกสารระบบ"
                >
                    <Row style={{ paddingTop: 20 }}>
                        <Col sm={24}>
                            <TableCustom
                                columns={columnsDetail}
                                data={dataAction}
                                small
                                rowKey={(row, index) => index}
                                pagination={false}
                            />
                        </Col>
                    </Row>
                </ModalCustom>}
            </Spin>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
}

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentFlowTable)
