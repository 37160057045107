import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { get, isEmpty } from 'lodash'
import { formatNumber, getPermissionPage } from 'helpers'
import { apiServices, API_PATH } from 'apiServices'
import { functionAlias } from 'routes'
import {
    TitleHead,
    TableCustom,
} from 'components/common'
import { Spin } from 'antd'
import moment from 'moment'
import ReportInventoryBalanceForm from './ReportInventoryBalanceForm'
import swal from '@sweetalert/with-react'
import FileSaver from 'file-saver'
import './style.css'

class ReportInventoryBalanceContainer extends Component {
    state = {
        columns: [
            {
                title: 'รหัสสินค้า',
                dataIndex: 'matnr',
                key: 'matnr',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'maktg',
                key: 'maktg',
            },
            {
                title: 'รหัสประเภทสินค้า',
                dataIndex: 'mtart',
                key: 'mtart',
            },
            {
                title: 'ประเภทสินค้า',
                dataIndex: 'mtbez',
                key: 'mtbez',
            },
            {
                title: 'Category',
                dataIndex: 'categ',
                key: 'categ',
            },
            {
                title: 'รหัสร้าน',
                dataIndex: 'werks',
                key: 'werks',
            },
            {
                title: 'ร้าน',
                dataIndex: 'namE1',
                key: 'namE1',
            },
            {
                title: 'รหัสที่เก็บสินค้า',
                dataIndex: 'lgort',
                key: 'lgort',
            },
            {
                title: 'ที่เก็บสินค้า',
                dataIndex: 'lgobe',
                key: 'lgobe',
            },
            {
                title: 'หน่วยนับ',
                dataIndex: 'eX_DISPLAY_UNIT',
                key: 'eX_DISPLAY_UNIT',
            },
            {
                title: 'พร้อมขาย (UR)',
                dataIndex: 'labst',
                key: 'labst',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'มูลค่าสินค้าพร้อมขาย (UR)',
                dataIndex: 'labsT_V',
                key: 'labsT_V',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'รอตรวจสอบ (QI)',
                dataIndex: 'insme',
                key: 'insme',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'มูลค่ารอตรวจสอบ (QI)',
                dataIndex: 'insmE_V',
                key: 'insmE_V',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'ระงับ (Block)',
                dataIndex: 'speme',
                key: 'speme',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'มูลค่าระงับ (Block)',
                dataIndex: 'spemE_V',
                key: 'spemE_V',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'ระหว่างจัดส่ง (In-transit_Stock)',
                dataIndex: 'trame',
                key: 'trame',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'มูลค่าระหว่างจัดส่ง (In-transit_Stock)',
                dataIndex: 'tramE_V',
                key: 'tramE_V',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'สต๊อกพิเศษ',
                dataIndex: 'sobkz',
                key: 'sobkz',
            },
            {
                title: 'มูลค้าสต๊อกพิเศษ',
                dataIndex: 'kzbws',
                key: 'kzbws',
                render: (value) => (
                    this.state.permissionPage.authApprove && <div>{value}</div>
                )
            },
            {
                title: 'เอกสารขาย',
                dataIndex: 'vbeln',
                key: 'vbeln',
            },
            {
                title: 'รายการเอกสารขาย',
                dataIndex: 'posnr',
                key: 'posnr',
            },
            {
                title: 'สต๊อก ATP',
                dataIndex: 'atpqty',
                key: 'atpqty',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'PO Outstanding',
                dataIndex: 'owemg',
                key: 'owemg',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'PO Special Outstanding',
                dataIndex: 'owemG_S',
                key: 'owemG_S',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'SO Outstanding',
                dataIndex: 'zmeng',
                key: 'zmeng',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'SO Special Outstanding',
                dataIndex: 'zmenG_S',
                key: 'zmenG_S',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'Reservation Outstanding',
                dataIndex: 'bdmng',
                key: 'bdmng',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'ต้นทุนเฉลี่ย',
                dataIndex: 'verpr',
                key: 'verpr',
                render: (value, row) => (
                    this.state.permissionPage.authApprove && <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'มูลค่ารวมสต๊อก',
                dataIndex: 'lbkum',
                key: 'lbkum',
                render: (value, row) => (
                    this.state.permissionPage.authApprove && <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'บาร์โค้ด',
                dataIndex: 'eaN11',
                key: 'eaN11',
            },
            {
                title: 'Max Stock Level',
                dataIndex: 'mabst',
                key: 'mabst',
                render: (value, row) => (
                    <div className="align-right">
                        {formatNumber(value, 3)}
                    </div>
                )
            },
            {
                title: 'รหัสการจัดเก็บสต๊อก',
                dataIndex: 'mtpos',
                key: 'mtpos',
            },
            {
                title: 'การจัดเก็บสต๊อก',
                dataIndex: 'bezei',
                key: 'bezei',
            },
            {
                title: 'รหัส MRP',
                dataIndex: 'dismm',
                key: 'dismm',
            },
            {
                title: 'ประเภท MRP Type Desc',
                dataIndex: 'dibez',
                key: 'dibez',
            },
        ],
        loading: false,
        reports: [],
        initialValues: {
            // productCodeFrom: '',
            // productCodeTo: '',
            productCode: '',
            barcode: '',
            outlet: '',
            storageLocations: [],
            productTypes: [],
            purchasingGroup: [],
            productBrands: [],
            productCategories: [],
            specialInventory: '',
            productInStockOnly: true,
            stockType: 'L',
            unitOptions: 'S',
            createDateFrom: moment(),
            createDateTo: moment(),
        },
        permissionPage: getPermissionPage(functionAlias.reportInventoryBalance),
    }

    getParams = (filter) => {
        const { auth } = this.props;

        return {
            productCode: filter.productCode,
            productBarCode: filter.barcode,
            outlet: filter.outlet,
            storelocations: filter.storageLocations,
            productTypes: filter.productTypes,
            specialStock: filter.specialInventory,
            purchasingGroups: filter.purchasingGroup,
            productBrands:filter.productBrands,
            productCategories: filter.productCategories,
            groupBy: filter.stockType,
            displayUnit: filter.unitOptions,
            isStockedProduct: filter.productInStockOnly ? filter.productInStockOnly : false,
            createDateFrom: moment(filter.createDateFrom).format('YYYY-MM-DD'),
            createDateTo: moment(filter.createDateTo).format('YYYY-MM-DD')
        }
    }

    exportReport = async () => {
        const { ReportInventoryBalanceForm: { values: filter } } = this.props
        const valid = this.validateFilter(filter);
        if (valid.isError) {
            swal('Warning', valid.message, 'warning');
            return;
        }
        this.setState({ loading: true })

        const body = this.getParams(filter);

        this.setState({ loading: true })
        await apiServices.callApi('post', API_PATH.GET_EXPORT_STOCK_BALANCE, body, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `ReportStockBalanceFile-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    searchReport = async (filter) => {
        const valid = this.validateFilter(filter);
        if (valid.isError) {
            swal('Warning', valid.message, 'warning');
            return;
        }
        this.setState({ loading: true })

        var reports = [];
        const body = this.getParams(filter);

        await apiServices.callApi('post', API_PATH.POST_GET_DAILY_STOCK_BALANCE_LIST, body).then(res => {
            reports = get(res, 'data.result.eX_STOCK_BAL.item', []);
            console.log('reports', reports);
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง');
            swal('Error', message, 'error');
        }).finally(() => {
            this.setState({ loading: false, reports });
        });
    }

    validateFilter = (filter) => {
        if (filter.productCodeTo && !filter.productCodeFrom) return { isError: true, message: 'กรุณาระบุรหัสสินค้าเริ่มต้น' }
        if (filter.documentDateTo && !filter.documentDateFrom) return { isError: true, message: 'กรุณาระบุวันที่เอกสารเริ่มต้น' }
        if (filter.documentDateFrom && filter.documentDateTo && (filter.documentDateTo).isBefore(filter.documentDateFrom)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }
        if (filter.createDateTo && !filter.createDateFrom) return { isError: true, message: 'กรุณาระบุวันที่สร้างเริ่มต้น' }
        if (filter.createDateFrom && filter.createDateTo && (filter.createDateTo).isBefore(filter.createDateFrom)) return { isError: true, message: 'รูปแบบวันที่สร้างไม่ถูกต้อง' }

        return { isError: false }
    }

    render() {
        const {
            auth
        } = this.props

        const {
            columns,
            reports,
            loading,
            initialValues,
            permissionPage,
        } = this.state

        return (
            <div className="report-inventory-balance-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="รายงานสินค้าคงเหลือ"
                        icon="hdd"
                    />
                    <div className="top-layout">
                        <ReportInventoryBalanceForm
                            auth={auth}
                            initialValues={initialValues}
                            onSubmit={this.searchReport}
                            onClickExport={this.exportReport}
                            permissionPage={permissionPage}
                        />
                    </div>
                    <div className="middle-content">
                        <div className="table-layout">
                            <TableCustom
                                height="calc(100vh - 316px)"
                                columns={columns}
                                data={reports}
                                isFullContent
                                rowKey={(row, index) => index}
                                small
                                pagination={{ pageSize: 30 }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        ReportInventoryBalanceForm: state.form.ReportInventoryBalanceForm,
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportInventoryBalanceContainer)