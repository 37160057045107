import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEmpty } from 'lodash'
// import * as _ from 'lodash'
import { getPermissionPage, formatNumber } from 'helpers'
import FileSaver from 'file-saver'
import { functionAlias } from 'routes'
import './style.css'
import { authData } from 'actions/actionsCreator';
import {
    TitleHead,
    RadioCustom,
    ButtonCustom,
    TableCustom,
    DatePickerCustom,
    InputCustom,
    SelectCustom,
    // InputSearchCustom,
} from 'components/common'
import moment from 'moment'
import swal from '@sweetalert/with-react'
import { apiServices, API_PATH } from 'apiServices'

import { Row, Col, Spin, Dropdown } from 'antd'

class ManualInterfaceIncomingPaymentContainer extends Component {

    constructor(props) {
        super(props)
        this.state = this.getInitialState()

        this.onExport = this.onExport.bind(this)
        this.onRun = this.onRun.bind(this)
        this.onClear = this.onClear.bind(this)
    }

    async	componentDidMount() {
        await this.fetchData()
    }
    async	fetchData() {
        let { outlets } = this.state
        let paramsOutlet = {
            outletIds: this.props.auth.userOutletModelList.map((data) => (
                data.outletId
            ))
        };
        let resOutlet = await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet)
        if (resOutlet.status === 200) {
            outlets = resOutlet.data.results.map(data => {
                return {
                    ...data,
                    label: `${data.outletId} - ${data.outletName}`,
                    value: data.outletId,
                }
            });
        }
        this.setState({ outlets })
    }

    getInitialState = () => {
        const permissionPage = getPermissionPage(functionAlias.manualInterfaceIncomingPayment)
        // console.log('permissionPage',permissionPage);

        return {
            columns: [
                {
                    title: 'Outlet ID',
                    align: 'center',
                    dataIndex: 'outletId',
                    key: 'outletId',
                    ellipsis: true,
                },
                {
                    title: 'Billing Date',
                    align: 'center',
                    dataIndex: 'documentDate',
                    key: 'documentDate',
                    ellipsis: true,
                    render: (value) => (
                        value && <div className="align-right">
                            {moment(new Date(value)).format('DD/MM/YYYY')}
                        </div>)
                },
                {
                    title: 'Billing No',
                    align: 'center',
                    dataIndex: 'billingNo',
                    key: 'billingNo',
                    ellipsis: true,
                },
                {
                    title: 'Tax No',
                    align: 'center',
                    dataIndex: 'taxInvoiceNo',
                    key: 'taxInvoiceNo',
                    ellipsis: true,
                },
                {
                    title: 'SO Number',
                    align: 'center',
                    dataIndex: 'soNumber',
                    key: 'soNumber',
                    ellipsis: true,
                },
                {
                    title: 'PO Number',
                    align: 'center',
                    dataIndex: 'poNumber',
                    key: 'poNumber',
                    ellipsis: true,
                },
                {
                    title: 'Tax Type',
                    align: 'center',
                    dataIndex: 'taxInvoiceType',
                    key: 'taxInvoiceType',
                    ellipsis: true,
                },
                {
                    title: 'Vat Amount',
                    align: 'center',
                    dataIndex: 'vatAmount',
                    key: 'vatAmount',
                    ellipsis: true,
                    render: (value) => (
                        <div className="align-right">
                            {value !== null ? formatNumber(value, 2) : 0.00}
                        </div>
                    ),
                },
                {
                    title: 'Net Amount',
                    align: 'center',
                    dataIndex: 'netAmount',
                    key: 'netAmount',
                    ellipsis: true,
                    render: (value) => (
                        <div className="align-right">
                            {value !== null ? formatNumber(value, 2) : 0.00}
                        </div>)
                },
                {
                    title: 'Cash',
                    align: 'center',
                    dataIndex: 'cashAmount',
                    key: 'cashAmount',
                    ellipsis: true,
                    render: (value) => (
                        <div className="align-right">
                            {value !== null ? formatNumber(value, 2) : 0.00}
                        </div>)
                },
                {
                    title: 'Bank Transfer',
                    align: 'center',
                    dataIndex: 'bankTransferAmount',
                    key: 'bankTransferAmount',
                    ellipsis: true,
                    render: (value) => (
                        <div className="align-right">
                            {value !== null ? formatNumber(value, 2) : 0.00}
                        </div>)
                },
                {
                    title: 'Credit Card',
                    align: 'center',
                    dataIndex: 'creditCardAmount',
                    key: 'creditCardAmount',
                    ellipsis: true,
                    render: (value) => (
                        <div className="align-right">
                            {value !== null ? formatNumber(value, 2) : 0.00}
                        </div>)
                },
                {
                    title: 'Credit Card Fee',
                    align: 'center',
                    dataIndex: 'paymentFee',
                    key: 'paymentFee',
                    ellipsis: true,
                    render: (value) => (
                        <div className="align-right">
                            {value !== null ? formatNumber(value, 2) : 0.00}
                        </div>)
                },
                {
                    title: 'Coupon Group',
                    align: 'center',
                    dataIndex: 'couponGroupAmount',
                    key: 'couponGroupAmount',
                    ellipsis: true,
                    render: (value) => (
                        <div className="align-right">
                            {value !== null ? formatNumber(value, 2) : 0.00}
                        </div>)
                },
                {
                    title: 'WHT',
                    align: 'center',
                    dataIndex: 'withholdingTaxAmount',
                    key: 'withholdingTaxAmount',
                    ellipsis: true,
                    render: (value) => (
                        <div className="align-right">
                            {value !== null ? formatNumber(value, 2) : 0.00}
                        </div>)
                },
                {
                    title: 'Status',
                    align: 'center',
                    dataIndex: 'interfaceStatus',
                    key: 'interfaceStatus',
                    ellipsis: true,
                },
                {
                    title: 'Message',
                    align: 'center',
                    dataIndex: 'interfaceMessage',
                    key: 'interfaceMessage',
                    ellipsis: true,
                },
            ],
            modes: [
                { label: 'Report', value: 'Report' },
                { label: 'Interface', value: 'Interface' },
            ],
            loading: false,
            dateFormat: 'DD/MM/YYYY',
            docType: [],
            outlets: [],
            infIncomingPayment: [],
            reports: [],
            filter: {
                mode: 'Report',
                billingNo: '',
                yearFrom: String(moment().year()),
                yearTo: '',
                dateFrom: moment().startOf('day'),
                dateTo: null,
                outletId: '',
            },
            pagination: {
                limit: 10,
            },
            loading: false,
            widthLabel: '100px',
            permissionPage
        }
    }

    changeFilter(key, value) {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: value } });
    }

    validateFilter = () => {
        const { filter } = this.state
        if (!filter.outletId) return { isError: true, message: 'กรุณาระบุร้าน!' }
        if (!filter.billingNo) return { isError: true, message: 'กรุณาระบุ Document No!' }
        // if (!filter.dateFrom) return { isError: true, message: 'กรุณาระบุวันที่เริ่มต้น!' }
        return { isError: false }
    }

    getBody = (filter) => {
        // const { filter } = this.state
        const body = {
            mode: filter.mode,
            outletId: filter.outletId,
            billingNo: filter.billingNo,
            yearFrom: filter.yearFrom,
            yearTo: filter.yearTo,
            dateFrom: filter.dateFrom ? moment(filter.dateFrom).format('YYYY-MM-DD') : '',
            dateTo: filter.dateTo ? moment(filter.dateTo).format('YYYY-MM-DD') : '',
        }
        return body
    }

    async onExport() {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        await apiServices.callApi('post', API_PATH.GET_Export_MANUALRUN_INTERFACE_INCOMING_PAYMENT, body, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `ReportExportManualRunInterfaceIncomingPayment-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })

    }

    async onRun() {
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const state = { ...this.state }
        const stateData = state.filter
        const isReportMode = stateData.mode === 'Report'

        const body = this.getBody(stateData)

        const url = `${API_PATH.POST_MANUALRUN_INTERFACE_INCOMING_PAYMENT}?mode=${stateData.mode}`
        await apiServices.callApi('post', url, body).then(res => {
            let documents = get(res, 'data.results')
            if (res.status === 200) {
                if (isReportMode) {
                } else {
                    swal('Success', get(res, 'data.message'), 'success')
                }
                this.setState({ infIncomingPayment: documents })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onClear() {
        const reset = {
            mode: 'Report',
            billingNo: '',
            yearFrom: moment().year(),
            yearTo: '',
            dateFrom: moment().startOf('day'),
            dateTo: null,
            outletId: '',
        }
        this.setState({ filter: reset, infIncomingPayment: [] })
    }

    render() {
        const {
            modes, filter, dateFormat, widthLabel, outlets,
            columns, infIncomingPayment, loading, permissionPage,
            reports, pagination
        } = this.state;

        return (
            <div className="manual-inf-incoming-payment-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="Manual Interface Incoming Payment"
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row style={{ padding: '10px 0' }}>
                            <Col sm={12}>
                                <RadioCustom
                                    widthDefault={widthLabel}
                                    options={modes}
                                    value={filter.mode}
                                    label="Mode"
                                    onChange={(e) => this.changeFilter('mode', e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <SelectCustom
                                    widthDefault={widthLabel}
                                    label="Outlet ID"
                                    options={outlets}
                                    value={filter.outletId}
                                    onChange={(val) => this.changeFilter('outletId', val)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={18} align="middle" type="flex">
                            <Col sm={16}>
                                <InputCustom
                                    label="Document Number"
                                    // widthDefault="70px"
                                    name="billingNo"
                                    onChange={(e) => this.changeFilter('billingNo', e.target.value)}
                                    value={filter.billingNo}
                                />
                                <div style={{ fontSize: '12px', paddingLeft: '245px', paddingBottom: '14px' }}>
                                    ใส่ : เพื่อค้นหาข้อมูลแบบช่วง หรือใส่ , เพื่อค้นหาข้อมูลแบบรายตัว
                            </div>
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col sm={8}>
                                <InputCustom
                                    onChange={(e) => this.changeFilter('yearFrom', e.target.value)}
                                    label="Year"
                                    name="yearFrom"
                                    value={filter.yearFrom}
                                />
                            </Col>
                            <Col sm={8}>
                                <InputCustom
                                    onChange={(e) => this.changeFilter('yearTo', e.target.value)}
                                    label="ถึง"
                                    name="yearTo"
                                    value={filter.yearTo}
                                />
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col sm={8}>
                                <DatePickerCustom
                                    label="Date"
                                    widthDefault={widthLabel}
                                    value={filter.dateFrom}
                                    format={dateFormat}
                                    allowClear={true}
                                    onChange={(date) => this.changeFilter('dateFrom', date)}
                                />
                            </Col>
                            <Col sm={8}>
                                <DatePickerCustom
                                    label="ถึง"
                                    widthDefault={widthLabel}
                                    value={filter.dateTo}
                                    format={dateFormat}
                                    allowClear={true}
                                    onChange={(date) => this.changeFilter('dateTo', date)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={18} type="flex" align="middle" justify="end">
                            <Col>
                                <ButtonCustom
                                    icon="file-excel"
                                    text="Export"
                                    green
                                    onClick={this.onExport}
                                    // disabled={!permissionPage.authExport || filter.mode !== 'Report'}
                                    disabled={!permissionPage.authExport || filter.mode !== 'Report' || (filter.mode === 'Report' && isEmpty(infIncomingPayment))}
                                />
                            </Col>
                            <Col>
                                <ButtonCustom
                                    icon="reload"
                                    text="ล้างหน้าจอ"
                                    yellow
                                    onClick={this.onClear}
                                />
                            </Col>
                            <Col>
                                <ButtonCustom
                                    icon="caret-right"
                                    text="Run"
                                    primary="true"
                                    onClick={this.onRun}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="table-layout">
                        <Row style={{ paddingTop: 20 }}>
                            <Col sm={24}>
                                <TableCustom
                                    // loading={loading}
                                    height="calc(100vh - 411px)"
                                    columns={columns}
                                    data={infIncomingPayment}
                                    small
                                    rowKey={(row, index) => index}
                                    pagination={{
                                        pageSize: pagination.limit,
                                        showTotal: (total, range) => (`Showing ${range[0]} to ${range[1]} of ${total} entries`),
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </Spin>
            </div>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualInterfaceIncomingPaymentContainer);