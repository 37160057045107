import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col } from "antd"
import {
    InputFormRedux,
    SelectRedux,
    SelectHero
} from 'components'
import { VALIDATE, customerType } from "helpers"

class SaleOrderCustomerForm extends React.Component {
    state = {

    }

    render() {
        const {
            dataCustomerTitle,
            dataRegionCountry,
            dataProvice,
            dataDistricts,
            dataSubDistrict,
            handleChangeSelectProvicne,
            handleChangeDistricts,
            submitViewErrorCustomer,
            dataSelectedCustomer,
            handleChangeCustomerType,
            valueCustomerType
        } = this.props
        return (
            <Row gutter={10} type="flex" justify="start">
                {
                    console.log("dataSelectedCustomer==>", dataSelectedCustomer)
                }
                <Col md={12}>
                    <Field
                        component={SelectRedux}
                        name="customerTitle"
                        label="คำนำหน้าชื่อ"
                        small
                        widthSmall="127px"
                        options={dataCustomerTitle}
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={InputFormRedux}
                        name="customerNameTH"
                        label={<span style={{ color: 'red' }}>ชื่อลูกค้า</span>}
                        small
                        widthSmall="127px"
                        validate={VALIDATE.REQUIRE}
                        submitViewError={submitViewErrorCustomer}
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={SelectRedux}
                        name="customerType"
                        label="ประเภทลูกค้า"
                        small
                        widthSmall="127px"
                        options={customerType}
                        onChangeHero={handleChangeCustomerType}
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={InputFormRedux}
                        name="customerTaxId"
                        label={valueCustomerType === '1' ? "เลขประจำตัวประชาชน" : "เลขนิติบุคคล"}
                        small
                        widthSmall="127px"
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={InputFormRedux}
                        name="customerTaxBranch"
                        label="สาขาที่"
                        small
                        widthSmall="127px"
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={InputFormRedux}
                        name="customerAddress"
                        label="ที่อยู่"
                        small
                        widthSmall="127px"
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={InputFormRedux}
                        name="customerOutletId"
                        label="Site งาน/ที่อยู่"
                        small
                        widthSmall="127px"
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={SelectRedux}
                        name="customerProvince"
                        label="จังหวัด"
                        small
                        widthSmall="127px"
                        options={dataProvice}
                        onChangeHero={handleChangeSelectProvicne}
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={SelectRedux}
                        name="customerDistrict"
                        label="เขต/อำเภอ"
                        small
                        widthSmall="127px"
                        options={dataDistricts}
                        onChangeHero={handleChangeDistricts}
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={SelectRedux}
                        name="customerSubDistrict"
                        label="แขวง/ตำบล"
                        small
                        widthSmall="127px"
                        options={dataSubDistrict}
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={InputFormRedux}
                        name="customerPostalCode"
                        label="รหัสไปรษณีย์"
                        small
                        widthSmall="127px"
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={SelectRedux}
                        name="customerCountry"
                        label={<span style={{ color: 'red' }}>ประเทศ</span>}
                        small
                        widthSmall="127px"
                        options={dataRegionCountry}
                    />
                </Col>
                <Col md={12}>
                    <Field
                        component={InputFormRedux}
                        name="customerPhone"
                        label="โทรศัพท์"
                        small
                        widthSmall="127px"
                    />
                </Col>
            </Row>
        )
    }
}

export default reduxForm({
    form: 'SaleOrderCustomerForm',  // a unique identifier for this form
    enableReinitialize: true
})(SaleOrderCustomerForm)
