export const transactionTypeSpec = {
    501: {
        header: {
            //fix value SAP
            mode: 'C',
            moveType: '501',
            typeRefDoc: 'R10',

            docType: '',
            outletId: '',
            refDocNo: '',
            poNo: '',
            docDate: {},
            remarks: '',
        },
        tableColumns: {
            isSelection: true,
            zeile: {},//รายการ
            matnr: {},//รหัสสินค้า productCode
            eaN11: {},//บาร์โค้ด
            maktg: {},//ชื่อสินค้า productName
            lgort: {},//storageLocation ที่เก็บ
            erfme: {},//unit หน่วยนับ
            erfmg: {},//จำนวนรับ
            insmk: {},//ประเภทสินค้า
        }
    },
    502: {
        header: {
            //fix value SAP
            mode: 'D',
            moveType: '',
            typeRefDoc: '',

            docType: '',
            outletId: '',
            refDocNo: '',
            poNo: '',
            docDate: {},
            matDoc: '',
            matDocYear: '',
            remarks: '',
        },
        tableColumns: {
            isSelection: false,
            zeile: {},//รายการ
            matnr: {},//รหัสสินค้า productCode
            eaN11: {},//บาร์โค้ด
            maktg: {},//ชื่อสินค้า productName
            lgort: {},//storageLocation
            erfme: {},//unit หน่วยนับ
            erfmg: {},//จำนวนรับ
            insmk: {},//ประเภทสินค้า
        }
    },
}

export const tempProduct = {
    zeile: '',//รายการ
    matnr: '',//รหัสสินค้า productCode
    eaN11: '',//บาร์โค้ด
    productName: '',//ชื่อสินค้า productName
    lgort: '',//storageLocation ที่เก็บ
    erfme: '',//unit หน่วยนับ
    erfmg: '',//จำนวนรับ
    insmk: '',//ประเภทสินค้า
}