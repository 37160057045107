import React, { Component } from 'react'
import { Row, Col } from 'antd'
import {
    ModalCustom,
    SelectCustom,
    InputCustom,
} from 'components/common'

export default class RejectionModal extends Component {
    state = {
        rejection: this.props.data
    }

    changeData = (key, val) => {
        const { rejection } = this.state
        this.setState({ rejection: { ...rejection, [key]: val } })
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            onClickSave,
            rejections,
            permissionPage,
        } = this.props
        const {
            rejection
        } = this.state

        return (
            <ModalCustom
                title="ข้อมูลเพิ่มเติม"
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                width="500px"
                footer
                onClickSave={() => onClickSave(rejection)}
            >
                <Row>
                    <Col sm={24}>
                        <SelectCustom
                            label="เหตุผลการปฏิเสธ"
                            small
                            onChange={val => this.changeData('rejectId', val)}
                            optionsDefault={[{ label: 'กรุณาเลือก', value: 0 }]}
                            options={rejections}
                            value={rejection.rejectId}
                            disabled={!permissionPage.authDelete}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={24}>
                        <InputCustom
                            label="หมายเหตุรายการ"
                            small
                            onChange={e => this.changeData('itemText', e.target.value)}
                            value={rejection.itemText}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
