import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { formatNumber } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    DatePickerCustom,
    InputCustom,
    SelectCustom,
    InputSearchCustom,
} from 'components/common'
import {
    CustomerSearchModal,
} from 'components/feature'
import { Row, Col, Spin, message } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import FileSaver from 'file-saver'
import moment from 'moment'
import swal from '@sweetalert/with-react'
import { sortDataColumn } from '../../../../helpers';

import './style.css'
const widthDefault = 100;
const widthLabel = '100px'
const formatDateAPI = 'YYYY-MM-DD HH:mm:ss'

class ReportSpecialStockContainer extends Component {
    state = {
        columns: [
            {
                title: 'ชื่อพนักงานขาย',
                dataIndex: 'saleEMPName',
                key: 'saleEMPName',
                sorter: (a, b) => sortDataColumn(a, b, 'saleEMPName'),
                width: widthDefault,
            },
            {
                title: 'Job ID',
                dataIndex: 'jobId',
                key: 'jobId',//'jobId',
                sorter: (a, b) => sortDataColumn(a, b, 'jobId'),
                width: widthDefault,
            },
            {
                title: 'เลขที่ใบสั่งขาย',
                dataIndex: 'soNumber',
                key: 'soNumber',
                sorter: (a, b) => sortDataColumn(a, b, 'soNumber'),
                width: widthDefault,
            },
            {
                title: 'สถานะใบสั่งขาย',
                dataIndex: 'documentStatus',
                key: 'documentStatus',
                sorter: (a, b) => sortDataColumn(a, b, 'documentStatus'),
                width: widthDefault,
            },
            {
                title: 'เหตุผลการ Reject',
                // dataIndex: 'rejectDescription',
                // key: 'rejectDescription',
                dataIndex: 'rejectName',
                key: 'rejectName',
                sorter: (a, b) => sortDataColumn(a, b, 'rejectName'),
                width: widthDefault,
            },
            {
                title: 'ชื่อลูกค้า',
                dataIndex: 'customerName',
                key: 'customerName',
                sorter: (a, b) => sortDataColumn(a, b, 'customerName'),
                width: widthDefault,
            },
            {
                title: 'รายการ',
                dataIndex: 'soItem',
                key: 'soItem',
                sorter: (a, b) => sortDataColumn(a, b, 'soItem'),
                width: widthDefault,
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
                sorter: (a, b) => sortDataColumn(a, b, 'productCode'),
                width: widthDefault,
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                sorter: (a, b) => sortDataColumn(a, b, 'productName'),
                width: 350,
            },
            {
                title: 'ร้านค้า',
                dataIndex: 'outletId',
                key: 'outletId',
                sorter: (a, b) => sortDataColumn(a, b, 'outletId'),
                width: widthDefault,
            },
            {
                title: 'เงื่อนไขการส่งมอบ',
                dataIndex: 'shippingPointDescription',
                key: 'shippingPointDescription',
                sorter: (a, b) => sortDataColumn(a, b, 'shippingPointDescription'),
                width: widthDefault,
            },
            {
                title: 'ที่เก็บสินค้า',
                dataIndex: 'slocId',
                key: 'slocId',
                sorter: (a, b) => sortDataColumn(a, b, 'slocId'),
                width: widthDefault,
            },
            {
                title: 'วันที่ส่งมอบ',
                dataIndex: 'deliveryDate',
                key: 'deliveryDate',
                sorter: (a, b) => sortDataColumn(a, b, 'deliveryDate'),
                render: (props) => (
                    props && <div className="text-right">
                        {moment(props).format('DD/MM/YYYY')}
                    </div>
                ),
                width: widthDefault,
            },
            {
                title: 'ปริมาณที่สั่ง',
                dataIndex: 'qty',
                key: 'qty',
                sorter: (a, b) => sortDataColumn(a, b, 'qty'),
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                ),
                width: widthDefault,
            },
            {
                title: 'หน่วยนับการขาย',
                dataIndex: 'unit',
                key: 'unit',
                sorter: (a, b) => sortDataColumn(a, b, 'unit'),
                width: widthDefault,
            },
            {
                title: 'ราคาขายต่อหน่วย',
                dataIndex: 'pricePerUnit',
                key: 'pricePerUnit',
                sorter: (a, b) => sortDataColumn(a, b, 'pricePerUnit'),
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
                width: widthDefault,
            },
            {
                title: 'ยอดส่วนลด inc VAT',
                dataIndex: 'totalDiscAmount',
                key: 'totalDiscAmount',
                sorter: (a, b) => sortDataColumn(a, b, 'totalDiscAmount'),
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
                width: widthDefault,
            },
            {
                title: 'ยอดก่อนส่วนลด inc VAT',
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                sorter: (a, b) => sortDataColumn(a, b, 'totalAmount'),
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
                width: widthDefault,
            },
            {
                title: 'ยอด VAT หลังส่วนลด',
                dataIndex: 'vatAmount',
                key: 'vatAmount',
                sorter: (a, b) => sortDataColumn(a, b, 'vatAmount'),
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
                width: widthDefault,
            },
            {
                title: 'ยอดหักส่วนลด inc VAT',
                dataIndex: 'netAmount',
                key: 'netAmount',
                sorter: (a, b) => sortDataColumn(a, b, 'netAmount'),
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 2)}</div>
                ),
                width: widthDefault,
            },
            {
                title: 'เลขที่ PO',
                dataIndex: 'poNumber',
                key: 'poNumber',
                sorter: (a, b) => sortDataColumn(a, b, 'poNumber'),
                width: widthDefault,
            },
            {
                title: 'รายการ PO',
                dataIndex: 'poItem',
                key: 'poItem',
                sorter: (a, b) => sortDataColumn(a, b, 'poItem'),
                width: widthDefault,
            },
            {
                title: 'ยกเลิก PO',
                dataIndex: 'poRejectId',
                key: 'poRejectId',
                sorter: (a, b) => sortDataColumn(a, b, 'poRejectId'),
                width: widthDefault,
            },
            {
                title: 'เลขที่ใบสั่งขาย (SCG)',
                dataIndex: 'soNo',
                key: 'soNo',
                sorter: (a, b) => sortDataColumn(a, b, 'soNo'),
                width: widthDefault,
            },
            {
                title: 'รายการใบสั่งขาย (SCG)',
                dataIndex: 'soItemNo',
                key: 'soItemNo',
                sorter: (a, b) => sortDataColumn(a, b, 'soItemNo'),
                width: widthDefault,
            },
            {
                title: 'สถานะ reject ใบสั่งขาย (SCG)',
                dataIndex: 'soRejectStatus',
                key: 'soRejectStatus',
                sorter: (a, b) => sortDataColumn(a, b, 'soRejectStatus'),
                width: widthDefault,
            },
            {
                title: 'เลขที่ PR (SCG)',
                dataIndex: 'purweb_pi_no',
                key: 'purweb_pi_no',
                sorter: (a, b) => sortDataColumn(a, b, 'purweb_pi_no'),
                width: widthDefault,
            },
            {
                title: 'รายการ PR (SCG)',
                dataIndex: 'purweb_pi_item_no',
                key: 'purweb_pi_item_no',
                sorter: (a, b) => sortDataColumn(a, b, 'purweb_pi_item_no'),
                width: widthDefault,
            },
            {
                title: 'เลขที่ PO (SCG)',
                dataIndex: 'i1PONo',
                key: 'i1PONo',
                width: widthDefault,
            },
            {
                title: 'รายการ PO (SCG)',
                dataIndex: 'i1POItem',
                key: 'i1POItem',
                width: widthDefault,
            },
            {
                title: 'จำนวน PO (SCG)',
                dataIndex: 'i1QuantityORD',
                key: 'i1QuantityORD',
                width: widthDefault,
            },
            {
                title: 'หน่วย PO (SCG)',
                dataIndex: 'i1POUnit',
                key: 'i1POUnit',
                width: widthDefault,
            },
            {
                title: 'ผู้สร้าง PO (SCG)',
                dataIndex: 'i1CreateBy',
                key: 'i1CreateBy',
                width: widthDefault,
            },
            {
                title: 'Text Header PO (SCG)',
                dataIndex: 'i1TextLine',
                key: 'i1TextLine',
                width: widthDefault,
            },
            {
                title: 'จำนวนรับเข้า PO (SCG)',
                dataIndex: 'i1Quantity02',
                key: 'i1Quantity02',
                width: widthDefault,
            },
            {
                title: 'จำนวนค้างรับเข้า PO (SCG)',
                dataIndex: 'i1Quantity03',
                key: 'i1Quantity03',
                width: widthDefault,
            },
            {
                title: 'STO ที่ยังไม่ GI',
                dataIndex: 'i1NOTGI',
                key: 'i1NOTGI',
                width: widthDefault,
            },
            {
                title: 'STO ที่ GI ไปแล้ว',
                dataIndex: 'i1STOGI',
                key: 'i1STOGI',
                width: widthDefault,
            },
            {
                title: 'ใบมัดจำ',
                dataIndex: 'billingNo',
                key: 'billingNo',
                width: widthDefault,
            },
            {
                title: 'บิล/รายการบิล',
                dataIndex: 'billingItems',
                key: 'billingItems',
                width: widthDefault,
            },
            {
                title: 'GI See',
                dataIndex: 'sumSeeItemQTY',
                key: 'sumSeeItemQTY',
                sorter: (a, b) => sortDataColumn(a, b, 'sumSeeItemQTY'),
                width: widthDefault,
            },
            {
                title: 'GI Sor',
                dataIndex: 'sumSorItemQTY',
                key: 'sumSorItemQTY',
                sorter: (a, b) => sortDataColumn(a, b, 'sumSorItemQTY'),
                width: widthDefault,
            },
            {
                title: 'ของแถม',
                dataIndex: 'freeGoodsFlag',
                key: 'freeGoodsFlag',
                sorter: (a, b) => sortDataColumn(a, b, 'freeGoodsFlag'),
                render: (props) => (
                    props && `${props}`
                ),
                width: widthDefault,
            },
            {
                title: 'สั่งพิเศษ',
                dataIndex: 'specialOrderFlag',
                key: 'specialOrderFlag',
                sorter: (a, b) => sortDataColumn(a, b, 'specialOrderFlag'),
                render: (props) => (
                    props && `${props}`
                ),
                width: widthDefault,
            },
            {
                title: 'ที่อยู่ (Ship to)',
                dataIndex: 'shipTo',
                key: 'shipTo',
                sorter: (a, b) => sortDataColumn(a, b, 'shipTo'),
                width: 250,
            },
            {
                title: 'หมายเหตุในใบสั่งขาย',
                dataIndex: 'remark',
                key: 'remark',
                sorter: (a, b) => sortDataColumn(a, b, 'remark'),
                width: 800,
            },
        ],
        loading: false,
        documents: [],
        filter: {
            outletId: '',
            soNumber: '',
            customerId: '',
            customerName: '',
            jobId: '',
            documentDateFrom: moment(),
            documentDateTo: moment(),
            productCode: '',
        },
        pagination: {
            page: 1,
            field: '',
            order: '',
            take: 10,
            isPaging: true,
            skip: 0,
            startPage:0,
            endPage:0,
            totalPage: 0,
            totalRecord: 0,
        },
        customerIdSearch: '',
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })

        const { auth } = this.props
        let { filter } = this.state

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({
                    outlets,
                    filter: { ...filter, outletId: outlets[0].value },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    onSelectedCustomer = (customer) => {
        this.setState({
            filter: {
                ...this.state.filter,
                customerId: customer.customerId,
                customerName: customer.customerNameTH,
            },
        })
        this.closeModal()
        this.changeCustomerIdSearch(customer.customerId)
    }

    resetCustomer = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                customerId: '',
                customerName: '',
                customerIdSearch: '',
            },
        })
    }

    searchCustomer = async (customerId) => {
        if (!customerId) {
            message.error('กรุณากรอกรหัสลูกค้า')
            return
        }

        this.setState({ loading: true })
        const { filter: { outletId } } = this.state
        const url = `${API_PATH.GET_CUSTOMER}?OutletId=${outletId}&customerId=${customerId}&RequireSoldTo=true`
        await apiServices.callApi('get', url).then(res => {
            const customer = get(res, 'data.results.0')
            if (res.status === 200 && !isEmpty(customer)) {
                this.onSelectedCustomer(customer)
            } else {
                message.error('ไม่พบข้อมูลลูกค้า')
                this.resetCustomer()
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    changeFilter = (key, val) => {
        let { filter, pagination } = this.state
        switch (key) {
            case 'soNumber':
                val = val.substring(0, 10)
                break
            case 'jobId':
                val = val.substring(0, 10)
                break
            case 'productCode':
                val = val.substring(0, 255)
                break
            default:
        }
        pagination.page = 1 //clear current page
        this.setState({ filter: { ...filter, [key]: val }, pagination })
    }

    changeCustomerIdSearch = (customerId) => {
        this.setState({ customerIdSearch: customerId })
        if (customerId === '') {
            this.resetCustomer()
        }
    }

    openModal = (modalType, rowAction = null) => {
        this.setState({ isShowModal: true, modalType, rowAction })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '', rowAction: null })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (!filter.outletId) return { isError: true, message: 'กรุณาระบุร้าน' }
        if (filter.documentDateTo && filter.documentDateFrom && (filter.documentDateTo).isBefore(filter.documentDateFrom)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }
        return { isError: false }
    }

    setSortTable = (pagination, filters, sorter, extra) => {
        let orderSet = '';
        switch (sorter.order) {
            case 'ascend':
                orderSet = 'asc';
                break;
            case 'descend':
                orderSet = 'desc';
                break;
            default:
                orderSet = '';
                break;
        }
        this.setState({
            pagination: {
                ...this.state.pagination,
                page: pagination.current,
                sort: sorter.columnKey || sorter.field,
                order: orderSet
            }
        })
        setTimeout(async () => { await this.searchDocument() }, 10);
    }

    getBody = (filter) => {
        const { pagination: { page, sort, order } } = this.state;
        return {
            outletId: filter.outletId,
            soNumber: filter.soNumber,
            customerId: filter.customerId,
            jobId: filter.jobId,
            productCode: filter.productCode,
            documentDateFrom: filter.documentDateFrom && filter.documentDateFrom.startOf('day').format(formatDateAPI),
            documentDateTo: filter.documentDateTo && filter.documentDateTo.endOf('day').format(formatDateAPI),
            pagination: {
                page: page || 1,
                take: 10,
                sort: sort || '',
                order: order || '',
                isPaging: true,
            }
        }
    }

    searchDocument = async () => {
        let { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        await apiServices.callApi('post', API_PATH.POST_ORDER_TRACKING_SPECIAL_SEARCH, body).then(res => {
            let documents = get(res, 'data.results', [])
            if (res.status === 200) {
                let pagination = get(res, "data.filter.pagination")
                this.setState({
                    documents,
                    pagination: { ...pagination }
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    exportDocument = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        await apiServices.callApi('post', API_PATH.POST_EXPORT_ORDER_TRACKING_SPECIAL, body, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `ReportOrderTrackingSpecialFile-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            columns,
            outlets,
            loading,
            filter,
            isShowModal,
            modalType,
            documents,
            customerIdSearch,
            pagination,
        } = this.state

        return (
            <div className="report-special-stock-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="รายงานสต็อคพิเศษ"
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row gutter={15}>
                            <Col sm={7}>
                                <SelectCustom
                                    label="ร้าน"
                                    widthLabel={widthLabel}
                                    options={outlets}
                                    small
                                    onChange={(val) => this.changeFilter('outletId', val)}
                                    value={filter.outletId}
                                />
                            </Col>
                            <Col sm={7}>
                                <InputCustom
                                    label="เลขที่ใบสั่งขาย"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('soNumber', e.target.value)}
                                    value={filter.soNumber}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15} align="middle" type="flex">
                            <Col sm={7}>
                                <InputSearchCustom
                                    label="ลูกค้า"
                                    widthLabel={widthLabel}
                                    onClickSearch={() => this.openModal('customer')}
                                    onSubmit={() => this.searchCustomer(customerIdSearch)}
                                    onChange={e => this.changeCustomerIdSearch(e.target.value)}
                                    small
                                    value={customerIdSearch}
                                />
                            </Col>
                            <Col sm={7}>
                                <div style={{ fontSize: '12px' }}>
                                    {filter.customerName}
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={7}>
                                <InputCustom
                                    label="Job ID"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('jobId', e.target.value)}
                                    value={filter.jobId}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="วันที่เอกสาร"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(val) => this.changeFilter('documentDateFrom', val)}
                                    value={filter.documentDateFrom}
                                />
                            </Col>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="ถึง"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(val) => this.changeFilter('documentDateTo', val)}
                                    value={filter.documentDateTo}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={14}>
                                <InputCustom
                                    label="รหัสสินค้า"
                                    widthLabel={widthLabel}
                                    small
                                    onChange={(e) => this.changeFilter('productCode', e.target.value)}
                                    value={filter.productCode}
                                />
                                <div style={{ fontSize: '12px', paddingLeft: widthLabel }}>
                                    ใส่ : เพื่อค้นหาข้อมูลแบบช่วง หรือใส่ , เพื่อค้นหาข้อมูลแบบรายตัว
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} className="search-box text-right">
                                <div className="action-box">
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.exportDocument}
                                            small
                                            icon="file-excel"
                                            green
                                            text="Export Excel"
                                            disabled={isEmpty(documents)}
                                        />
                                    </div>
                                    <div className="button">
                                        <ButtonCustom
                                            //onClick={this.searchDocument()}
                                            onClick={async () => await this.searchDocument()}
                                            small
                                            icon="search"
                                            text="ค้นหา"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom
                                //height="100%"
                                columns={columns}
                                scroll={{ x: "max-content", y: "calc(100vh - 250px)" }}
                                data={documents}
                                rowKey={(row, index) => index}
                                pagination={{ pageSize: pagination.take, total: pagination.totalRecord, current: pagination.page }}
                                onChange={this.setSortTable.bind(this)}
                            />
                        </div>
                    </div>
                    <CustomerSearchModal
                        isShowModal={isShowModal && modalType === 'customer'}
                        handleCloseModal={this.closeModal}
                        onSelected={this.onSelectedCustomer}
                        outletId={filter.outletId}
                    />
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportSpecialStockContainer)