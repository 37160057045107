import styled from 'styled-components'

export const RadioCustomStyled = styled.div`

.radio-custom-form {
    margin-bottom: ${props => props.small ? '3px' : ''};
}

.label-style .radio-custom-form {
    font-size: ${props => props.small ? '12px' : ''};
    width: ${props => props.small ? props.widthSmall ? props.widthSmall : '120px' : ''}
}

.radio-custom-form, .ant-radio-wrapper {
    font-size: ${props => props.small ? '12px' : ''};
}

.radio-custom-form .radio-zone-label {
    display: flex;
    align-items: center;
    width: 100%;
}

.ant-radio-button-wrapper {
    font-size: ${props => props.small ? '12px' : ''};
}
`