import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Icon } from "antd"
import {
    InputFormRedux,
    InputHero,
    RangePickerRedux,
    MultiSelectedRedux,
    SelectRedux,
} from 'components'
import { API_PATH } from "apiServices"
import { VALIDATE } from "helpers"

class PromotionNetPriceForm extends React.Component {
    state = {

    }

    render() {
        const {
            dataOutlet,
            dataChannal,
            readExcelFile,
            dataPromotionStatus,
            submitViewError,
            isErrorExcelFile,
            promotionCode,
        } = this.props
        return (
            <div>
                <Row gutter={10} type="flex" justify="start">
                    <Col md={12}>
                        <Field
                            component={InputFormRedux}
                            name="campaignName"
                            label="ชื่อการส่งเสริมการขาย"
                            widthDefault="200px"
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                        />
                    </Col>
                    <Col md={12}>
                        <InputHero
                            name="campaignNameasd"
                            label="รหัสการส่งเสริมการขาย"
                            widthDefault="200px"
                            disabled
                            value={promotionCode ? promotionCode : "Auto"}
                        />
                    </Col>
                    <Col md={24}>
                        <Field
                            component={RangePickerRedux}
                            name="date"
                            label="วันที่เริ่มใช้งาน"
                            widthDefault="200px"
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            name="outletId"
                            component={MultiSelectedRedux}
                            label="ร้าน"
                            widthDefault="200px"
                            options={dataOutlet}
                            maxTagCount={1}
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            name="channel"
                            component={MultiSelectedRedux}
                            label="ช่องทาง"
                            widthDefault="200px"
                            options={dataChannal}
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                        />
                    </Col>
                    <Col md={12}>
                        <Field
                            name="status"
                            component={SelectRedux}
                            label="สถานะ"
                            widthDefault="200px"
                            options={dataPromotionStatus}
                            submitViewError={submitViewError}
                            validate={VALIDATE.REQUIRE}
                        />
                    </Col>
                    <Col md={12}>
                        <InputHero
                            name="version"
                            label="เวอร์ชั่น"
                            widthDefault="200px"
                            disabled
                            value={1}
                        />
                    </Col>
                </Row>
                <Row type="flex" className="upload-box input-form">
                    <Col md={12} className="label-style">
                        Upload Excel
                                  </Col>
                    <Col md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <input
                            className="file-input"
                            type="file" id="input"
                            onChange={readExcelFile}
                            onClick={e => (e.target.value = null)}
                        />
                        {
                            (isErrorExcelFile && submitViewError) && <span style={{ color: 'red' }}>* กรุณาเลือก Excel file</span>
                        }
                        <br />
                        <a href={API_PATH.GET_DOWNLOAD_TEMPLATE_EXCEL_NET_PRICE} className="download-link">
                            <Icon type="file-excel" /> Download Format Excel
                                  </a>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default reduxForm({
    form: 'PromotionNetPriceForm',  // a unique identifier for this form
    enableReinitialize: true
})(PromotionNetPriceForm)
