import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.css'
import { authData } from 'actions/actionsCreator';
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    InputHero,
    SelectHero,
    ModalCustom,
} from "components"

import {
    CustomerSearchModal,
    CustomerDetailFormRedux,
} from "components/feature"

import {
    TextAreaCustom,
    InputSearchCustom,
} from "components/common"

import { Spin, Row, Col, message } from "antd"
import { apiServices, API_PATH } from 'apiServices'
import { get, isEmpty, first } from 'lodash'
import swal from '@sweetalert/with-react'
import moment from 'moment';
import { ROUTH_PATH } from '../../../../routes/path';
import { Link } from 'react-router-dom';
import { functionAlias } from 'routes'
import { getPermissionPage } from 'helpers'

class PaymentFullContainer extends Component {
    state = {
        permissionPage: getPermissionPage(functionAlias.paymentFullMaintain),
        loading: false,
        customers: [],
        disabledSearchCustomer: true,
        dataProductTableList: [],
        initialValues: {
            customerId: '',
            customerAddress: '',
            phone: '',
            taxId: '',
            taxBranch: '',
            documentDate: '',
            soNumber: '',
        },
        isUpdateSO: false,

        //  columns Table set here
        columnsProduct: [
            {
                title: '#',
                align: 'center',
                dataIndex: 'no',
                key: 'no',
                render: (value, full, index) =>
                    <span>
                        {index + 1}
                    </span>
            },
            {
                title: 'บาร์โค้ด',
                align: 'center',
                dataIndex: 'barcode',
                key: 'barcode',
                render: (value) => <div>
                    {value}
                </div>
            },
            {
                title: 'รหัสสินค้า',
                align: 'center',
                dataIndex: 'productCode',
                key: 'productCode',
                render: (value) => <div>
                    {value}
                </div>
            },
            {
                title: 'ชื่อสินค้า',
                align: 'center',
                dataIndex: 'productName',
                key: 'productName',
                render: (value) => <div>
                    {value}
                </div>
            },
            {
                title: 'คลัง',
                align: 'center',
                dataIndex: 'slocId',
                key: 'slocId',
                render: (value) => <div>
                    {value}
                </div>
            },
            {
                title: 'จำนวน',
                align: 'center',
                dataIndex: 'qty',
                key: 'qty',
                render: (value) => <div>
                    {value}
                </div>
            },
            {
                title: 'หน่วยขาย',
                align: 'center',
                dataIndex: 'unit',
                key: 'unit',
                render: (value) => <div>
                    {value}
                </div>
            },
            {
                title: 'ราคา/หน่วย',
                align: 'center',
                dataIndex: 'pricePerUnit',
                key: 'pricePerUnit',
                render: (value) => <div>
                    {value}
                </div>
            },
            {
                title: 'ส่วนลดรวม',
                align: 'center',
                dataIndex: 'discAmount',
                key: 'discAmount',
                render: (value) => <div>
                    {value || 0}
                </div>
            },
            {
                title: 'จำนวนเงิน',
                align: 'center',
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                width: 100,
                render: (value) => <div>
                    {value || 0}
                </div>
            },
        ],
    };

    componentDidMount() {
        if (!this.state.permissionPage.authDisplay) {
            swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
                this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
            })
            return
        }
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth } = this.props

        let resOutlet = await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        })
        if (resOutlet.status === 200) {
            let outlets = get(resOutlet, 'data.results');
            let outlet = '';
            if (outlets) {
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }));

                if (!isEmpty(outlets)) {
                    outlet = outlets[0].value;
                }

                this.setState({ outlets, outlet });
                //console.log(`outlets===>${outlets}, outlet===>${outlet}`);
            }
        } else {
            swal('Error', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง', 'error');
        }
        this.setState({ loading: false })
    }

    handleCloseModal = () => {
        this.setState({ isShowModal: false, isShowSearchCustomerModal: false });
    }

    onSearchCustomer = async (typeKeyword, keyword) => {
        //console.log(`typeKeyword ==>${typeKeyword}, keyword==>${keyword}`);
        this.setState({ loading: true });
        const url = `${API_PATH.GET_CUSTOMER}?${typeKeyword}=${keyword}&RequireSoldTo=true`
        await apiServices.callApi('get', url).then(res => {
            const resCustomers = get(res, 'data.results')
            if (resCustomers) {
                this.setState({ customers: resCustomers })
            }
        }).catch(error => {
            const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            console.error(get(error, 'response.data.errors'))
        })
        this.setState({ loading: false })
    }

    onSelectedCustomer = (data) => {
        let { billingDetail, searchCustomerId, soDetail } = this.state;

        soDetail = {
            ...soDetail,
            customerId: data.customerId,
            customerName: data.customerNameTH,
        }

        this.setState({ isShowSearchCustomerModal: false });
        //console.log(`soDetail ==>`, soDetail);
        //console.log(`Customer selected`, data);
        if (soDetail && soDetail.salesSOPartnerFunctionModelList && soDetail.salesSOPartnerFunctionModelList.length > 0) {

            soDetail.salesSOPartnerFunctionModelList.forEach(partner => {
                if (partner.partnerType === "sold_to") {
                    partner.title = data.customerTitle;
                    partner.customerName = data.customerNameTH;
                    partner.customerType = data.customerType;
                    partner.taxId = data.customerTaxId;
                    partner.taxBranch = data.customerTaxBranch;
                    partner.address = data.customerAddress;
                    partner.outletId = data.customerOutletId;
                    partner.province = data.customerProvince;
                    partner.district = data.customerDistrict;
                    partner.subDistrict = data.customerSubDistrict;
                    partner.postalCode = data.customerPostalCode;
                    partner.country = data.customerCountry;
                    partner.phone = data.customerPhone;
                    //console.log('partner sold_to==>', partner);
                    //customerSoldToFullAddress = `${partner.customerName ? `${partner.customerName}/` : ''}${partner.subDistrict ? `${partner.subDistrict}/` : ''}${partner.district ? `${partner.district}/` : ''}${partner.province ? `${partner.province}/` : ''}${partner.postalCode ? `${partner.postalCode}` : ''}`;
                    this.setState({ dataCustomerSoldTo: partner });
                }
            });
        }

        billingDetail = {
            ...billingDetail,
            customerId: data.customerId,
            customerName: data.customerNameTH,
        }
        searchCustomerId = data.customerId;

        this.setState({ billingDetail, searchCustomerId, isUpdateSO: true, soDetail, isShowModal: false });

        this.handleCloseModal();
    }

    handleClickSearchCustomer = (model) => {
        this.setState({ isShowSearchCustomerModal: true });
    }

    /*handleEnterBillingNo = async (data) => {
        console.log(`data===>`, data.target.value);
    }*/

    handleChangeOutlet = (val) => {
        //console.log(`outlet===>`, val);
        this.setState({ outlet: val });
    }

    handleChangeBillingNo = (val) => {
        this.setState({ searchBillingNo: val.target.value });
    }

    handleChangeSearchCustomerId = (val) => {
        this.setState({ searchCustomerId: val });
    }

    handleEnterBillingNo = async (val) => {
        const { outlet, outlets } = this.state;
        const billingNo = val.target.value;
        this.setState({ loading: true });
        const urlParam = `${API_PATH.GET_CASHRECEIVE}?OutletId=${outlet}&BillingNo=${billingNo}`;
        //console.log("resBilurlParamlingDetail === >", urlParam);
        let res = await apiServices.callApi('get', urlParam, "")
        //console.log("resBillingDetail === >", res);
        if (res.status === 200) {
            const billingDetailresults = get(res, 'data.results');
            if (!billingDetailresults || billingDetailresults.length === 0) {
                swal('Error', `ไม่พบเลขที่ใบเสร็จ: ${billingNo}`, 'error');
                this.setState({ loading: false });
                return;
            }
            const billingDetail = first(billingDetailresults);
            const findOutletRole = outlets.find(item => item.outletId === outlet);
            if (billingDetail &&
                billingDetail.salesBillingInvoice &&
                first(billingDetail.salesBillingInvoice).documentDate && moment(new Date(first(billingDetail.salesBillingInvoice).documentDate)).format('DD/MM/YYYY')
                && moment(new Date(first(billingDetail.salesBillingInvoice).documentDate)).format('DD/MM/YYYY') !=
                moment(new Date()).format('DD/MM/YYYY')
                && findOutletRole && findOutletRole.outletRole != "Franchise") {

                swal('Error', `เลขที่ใบเสร็จ: ${billingNo} ไม่สามารถออกใบเสร็จข้ามวันได้!`, 'error');
                this.setState({ loading: false });
                return;
            }


            //console.log("billingDetail=== >", billingDetail);

            const billingInvoiceFull = billingDetail.salesBillingInvoice.filter(inv => inv.taxInvoiceType === 'full');
            if (!isEmpty(billingInvoiceFull)) {
                swal('Error', `เลขที่ใบเสร็จ: ${billingNo} ออกใบกำกับอย่างเต็มแล้ว!`, 'error');
                this.setState({ loading: false });
                return;
            }

            this.setState({ billingDetail });
            this.initailBilling(billingDetail);

            let params = { soNumber: billingDetail.soNumber, outletId: billingDetail.outletId };
            let resSoDetail = await apiServices.callApi('post', API_PATH.POST_GET_DETAIL_SALE_ORDER, params)
            if (resSoDetail.status === 200) {
                let soDetail = resSoDetail.data.results[0];
                //console.log("soDetail=== >", soDetail);
                this.setState({ soDetail });
                this.initailSalesOrder(soDetail);
            }
        }
        this.setState({ loading: false });
    }

    initailBilling(billingDetail) {
        let { dataProductTableList } = this.state;
        billingDetail.salesBillingItem = billingDetail.salesBillingItem.map(data => ({
            ...data,
            key: data.id,
        }));
        dataProductTableList = billingDetail.salesBillingItem;
        const billingDocDate = moment(new Date(billingDetail.documentDate)).format('DD/MM/YYYY');
        const documentDate = billingDocDate;
        const soNumber = billingDetail.soNumber;
        this.setState({ dataProductTableList, documentDate, soNumber });
    }

    initailSalesOrder(soDetail) {
        this.setState({ disabledSearchCustomer: false });
        const searchCustomerId = soDetail.customerId;
        const partners = soDetail.salesSOPartnerFunctionModelList.filter((ele) => ele.partnerType === "sold_to");
        if (!isEmpty(partners)) {
            //console.log(`dataCustomerSoldTo===>`, first(partners));
            this.setState({ dataCustomerSoldTo: first(partners) });
        }

        this.setState({ searchCustomerId });
    }

    validateCustomer = () => {
        const { taxType, soDetail, outlets, outlet } = this.state;
        const masterOutlet = outlets.find((item, indexItem) => outlet === item.outletId)

        const customerSoldTo = soDetail.salesSOPartnerFunctionModelList.filter(partner => partner.partnerType === "sold_to");

        if (!customerSoldTo || customerSoldTo.length === 0) {
            message.error("ไม่พบข้อมูลลูกค้า!");
            return false;
        }
        const soldTo = customerSoldTo[0];

        if (!soldTo.customerName) {
            message.error("กรุณาระบุข้อมูล 'ชื่อ' ลูกค้า!");
            return false;
        }
        if (!soldTo.address) {
            message.error("กรุณาระบุข้อมูล 'ที่อยู่' ลูกค้า!");
            return false;
        }
        if (!soldTo.subDistrict) {
            message.error("กรุณาระบุข้อมูล 'แขวง/ตำบล' ลูกค้า!");
            return false;
        }
        if (!soldTo.district) {
            message.error("กรุณาระบุข้อมูล 'เขต/อำเภอ' ลูกค้า!");
            return false;
        }
        if (!soldTo.province) {
            message.error("กรุณาระบุข้อมูล 'จังหวัด' ลูกค้า!");
            return false;
        }
        if (!soldTo.postalCode) {
            message.error("กรุณาระบุข้อมูล 'รหัสไปรษณีย์' ลูกค้า!");
            return false;
        }

        // #1655 COCO, Boutique เมื่อเลือกใบกำกับภาษีอย่างเต็ม ให้ตรวจสอบให้ออกได้เฉพาะลูกค้าที่มี Tax id ทั้งบุคคลธรรมดาและนิติบุคคล
        if (!isEmpty(masterOutlet) && (masterOutlet.outletRole === 'COCO' || masterOutlet.outletRole === 'Boutique') && !soldTo.taxId) {
            message.error("กรุณาระบุข้อมูล 'เลขที่ประจำตัวผู้เสียภาษี' ลูกค้า!");
            return false;
        } else if (soldTo.customerType && soldTo.customerType == '2' && !soldTo.taxId) {//นิติบุคคล
            message.error("กรุณาระบุข้อมูล 'เลขที่ประจำตัวผู้เสียภาษี' ลูกค้า!");
            return false;
        }
        return true;
    }

    handleClickSaveFullInvoice = async () => {
        this.setState({ loading: true });
        const { auth } = this.props;

        let {
            soDetail,
            billingDetail,
            isUpdateSO,
        } = this.state;

        if (this.validateCustomer() === false) {
            this.setState({ loading: false });
            return;
        }

        const isPassCondtionSo = isUpdateSO === true ? await this.handleUpdateSaleOrder(soDetail.soNumber) : true;
        if (isPassCondtionSo === false) {
            swal("error", `เกิดข้อผิดพลาดการแก้ไขข้อมูลลูกค้า หมายเลขใบสั่งขายสินค้า: ${soDetail.soNumber}`, "error");
            return;
        }

        billingDetail = {
            ...billingDetail,
            changedBy: auth.userId,
            changedDatetime: new Date(),
        }

        if (billingDetail.salesBillingInvoice && billingDetail.salesBillingInvoice.length > 0) {
            billingDetail.salesBillingInvoice.forEach(billInv => {
                if (billInv.taxInvoiceType === "abb") {
                    billInv.updatedDate = new Date();
                    billInv.updatedUser = auth.userId;
                    billInv.convertFlag = false;
                }
            });
        }

        let billingInvoiceFull = {
            "outletId": soDetail.outletId,
            "soNumber": soDetail.soNumber,
            "taxInvoiceType": 'full',
            "documentTypeCode": billingDetail.documentTypeCode,
            "documentStatus": "1",
            "documentDate": new Date(),
            "createdDate": new Date(),
            "createdUser": auth.userId,
            "convertFlag": true
        };

        billingDetail.salesBillingInvoice.push(billingInvoiceFull);

        //console.log("billingDetail === >", JSON.stringify(billingDetail));

        await apiServices.callApi('post', API_PATH.POST_CREATE_CASHRECEIVE_FULLTAX, billingDetail).then(async res => {
            let resBilling = get(res, "data.results.0");
            swal("Success", `สร้างรายการชำระเงิน : ${resBilling.billingNo} ${res.data.message}`, "success");
            this.setState({
                dataCustomerSoldTo: {},
                billingDetail: {},
                searchBillingNo: '',
                dataProductTableList: [],
                disabledSearchCustomer: true,
                searchCustomerId: '',
            }, async () => await this.fetchDataDefault());
        }).catch(error => {
            if (error.response.status === 400) {
                const message = error.response.data.errors ? Object.values(error.response.data.errors).flat().join() : 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง';
                swal('Error', message, 'error')
            } else {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            }
        })
        this.setState({ loading: false });
    }

    handleUpdateSaleOrder = async (soNumber) => {
        const { soDetail } = this.state;
        //console.log("params==>", JSON.stringify(soDetail), soDetail);
        let urlWithParam = `${API_PATH.PUT_SALE_ORDER}?soNumber=${soNumber}`;
        return await apiServices.callApi("put", urlWithParam, soDetail)
            .then(res => {
                //console.log("Success ==>", `หมายเลขใบสั่งขายสินค้า ${res.data.message} ถูกบันทึกสำเร็จ`);
                return true;
            })
            .catch(error => {
                //console.log("error catch ==>", error);
                return false;
            })
    }

    handleShowModalCustomer = async () => {
        let { soDetail } = this.state;
        if (!soDetail || isEmpty(soDetail.salesSOPartnerFunctionModelList)) {
            return;
        }

        const customerSoldTo = soDetail.salesSOPartnerFunctionModelList.filter(partner => partner.partnerType === "sold_to");
        if (!customerSoldTo || customerSoldTo.length === 0) {
            message.error("ไม่พบข้อมูลลูกค้า!");
            return;
        }
        const soldTo = first(customerSoldTo);

        let initialValues =
            soldTo
                ?
                {
                    ...soldTo,
                    customerTitle: soldTo.title,
                    customerNameTH: soldTo.customerName,
                    customerType: soldTo.customerType,
                    customerTaxId: soldTo.taxId,
                    customerTaxBranch: soldTo.taxBranch,
                    customerAddress: soldTo.address,
                    customerOutletId: soldTo.outletId,
                    customerProvince: soldTo.province,
                    customerDistrict: soldTo.district,
                    customerSubDistrict: soldTo.subDistrict,
                    customerPostalCode: soldTo.postalCode,
                    customerCountry: soldTo.country,
                    customerPhone: soldTo.phone,
                }
                :
                {}
        //console.log("initialValues==>", initialValues);

        this.setState({
            isShowModal: true,
            initialValuesCustomerDetail: initialValues,
            submitViewErrorCustomer: false,
        });
    }

    handleClickSaveCustomer() {
        const { CustomerReduxForm: { values, syncErrors } } = this.props;
        let { soDetail } = this.state;
        //console.log('values==>', values);
        //console.log('syncErrors==>', syncErrors);
        //console.log('soDetail==>', soDetail);

        if (typeof (syncErrors) === 'object') {
            message.error("กรุณากรอกข้อมูลให้ครบถ้วน")
            this.setState({
                submitViewErrorCustomer: true
            }, () => this.handleClickModal("customer"))
        }

        if (soDetail && soDetail.salesSOPartnerFunctionModelList && soDetail.salesSOPartnerFunctionModelList.length > 0) {
            soDetail.salesSOPartnerFunctionModelList.forEach(partner => {
                if (partner.partnerType === "sold_to") {
                    partner.title = values.customerTitle;
                    partner.customerName = values.customerNameTH;
                    partner.customerType = values.customerType;
                    partner.taxId = values.customerTaxId;
                    partner.taxBranch = values.customerTaxBranch;
                    partner.address = values.customerAddress;
                    partner.outletId = values.customerOutletId;
                    partner.province = values.customerProvince;
                    partner.district = values.customerDistrict;
                    partner.subDistrict = values.customerSubDistrict;
                    partner.postalCode = values.customerPostalCode;
                    partner.country = values.customerCountry;
                    partner.phone = values.customerPhone;
                    //console.log('partner sold_to==>', partner);
                }
            });
        }
        this.setState({ soDetail, isUpdateSO: true, isShowModal: false, });
    }

    render() {
        const {
            loading,
            columnsProduct,
            dataProductTableList,
            isShowSearchCustomerModal,
            isShowModal,
            disabledSearchCustomer,
            outlets,
            outlet,
            dataCustomerSoldTo,
            searchBillingNo,
            searchCustomerId,
            billingDetail,

            errorCustomer,
            initialValuesCustomerDetail,
            submitViewErrorCustomer,
            permissionPage,
        } = this.state

        return (
            <div className="payment-full-content">
                <Spin spinning={loading} style={{ height: '100%' }}>
                    <TitleHead
                        text={
                            <span className="title-zone">
                                ออกใบกำกับอย่างย่อเป็นอย่างเต็ม
                        </span>
                        }
                        icon="file-text"
                    />
                    <div className="middle-content">
                        <Row gutter={10} style={{ fontSize: "12px" }}>
                            <Col sm={14} md={16} lg={16}>
                                <Row>
                                    <Col sm={24} style={{ display: 'flex' }}>
                                        <InputSearchCustom
                                            label="รหัสลูกค้า"
                                            small
                                            //onSubmit={() => this.searchProduct()}
                                            onChange={(e) => this.handleChangeSearchCustomerId(e.target.value)}
                                            onClickSearch={() => this.handleClickSearchCustomer()}
                                            disabled //={disabledSearchCustomer}
                                            disabledButton={disabledSearchCustomer}
                                            name="customerId"
                                            value={searchCustomerId}
                                        />
                                        {
                                            disabledSearchCustomer === false ?
                                                !isEmpty(dataCustomerSoldTo) ?
                                                    <span className="click-detail-customer" onClick={(e) => this.handleShowModalCustomer(e)}>
                                                        {
                                                            dataCustomerSoldTo.customerName
                                                                ?
                                                                dataCustomerSoldTo.customerName
                                                                :
                                                                'ลูกค้าทั่วไป'
                                                        }
                                                    </span>
                                                    :
                                                    <React.Fragment>
                                                        <span className="click-detail-customer" onClick={(e) => this.handleShowModalCustomer(e)}>
                                                            ลูกค้าทั่วไป
                                                        </span>
                                                        {
                                                            errorCustomer &&
                                                            <span style={{ color: 'red', paddingLeft: 10 }}>
                                                                * กรุณากรอกข้อมูลลูกค้า
                                                </span>
                                                        }
                                                    </React.Fragment>
                                                : ""
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={24}>
                                        <TextAreaCustom
                                            small
                                            disabled
                                            name="customerAddress"
                                            label="ที่อยู่ลูกค้า"
                                            rows={2}
                                            //widthDefault="120px"
                                            value={!isEmpty(dataCustomerSoldTo) ?
                                                `${dataCustomerSoldTo.customerName ? `${dataCustomerSoldTo.customerName}/` : ''}${dataCustomerSoldTo.subDistrict ? `${dataCustomerSoldTo.subDistrict}/` : ''}${dataCustomerSoldTo.district ? `${dataCustomerSoldTo.district}/` : ''}${dataCustomerSoldTo.province ? `${dataCustomerSoldTo.province}/` : ''}${dataCustomerSoldTo.postalCode ? `${dataCustomerSoldTo.postalCode}` : ''}`
                                                : ''
                                                /*!isEmpty(CustomerSoldToFullAddress) ?
                                                    (dataCustomerSoldTo.customerName ? `${dataCustomerSoldTo.customerName}/` : '' +
                                                        dataCustomerSoldTo.subDistrict ? `${dataCustomerSoldTo.subDistrict}/` : '' +
                                                            dataCustomerSoldTo.district ? `${dataCustomerSoldTo.district}/` : '' +
                                                                dataCustomerSoldTo.province ? `${dataCustomerSoldTo.province}/` : '' +
                                                                    dataCustomerSoldTo.postalCode ? `${dataCustomerSoldTo.postalCode}` : '')
                                                    : ''*/
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <InputHero
                                            label="โทร"
                                            small
                                            name="phone"
                                            value={dataCustomerSoldTo && dataCustomerSoldTo.phone}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <InputHero
                                            label="เลขประจำตัวผู้เสียภาษี"
                                            small
                                            name="taxId"
                                            disabled
                                            value={dataCustomerSoldTo && dataCustomerSoldTo.taxId}
                                        />
                                    </Col>
                                    <Col sm={12}>
                                        <InputHero
                                            label="สาขา"
                                            small
                                            name="taxBranch"
                                            right
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={10} md={8} lg={8} style={{ display: 'flex' }}>
                                <Row>
                                    <Col sm={24}>
                                        <SelectHero
                                            name="outlet"
                                            label="ร้านค้า"
                                            options={outlets}
                                            value={outlet}
                                            firstChoose
                                            small
                                            onChange={(val) => this.handleChangeOutlet(val)}
                                        />
                                    </Col>

                                    <Col sm={24}>
                                        <InputHero
                                            label="เลขที่ใบเสร็จเดิม*"
                                            labelColor="red"
                                            small
                                            name="billingNo"
                                            value={searchBillingNo}
                                            onChange={this.handleChangeBillingNo}
                                            onPressEnter={this.handleEnterBillingNo}
                                        />
                                    </Col>
                                    <Col sm={24}>
                                        <InputHero
                                            label="วันที่ใบเสร็จเดิม"
                                            small
                                            name="documentDate"
                                            value={billingDetail &&
                                                billingDetail.salesBillingInvoice &&
                                                first(billingDetail.salesBillingInvoice).documentDate && moment(new Date(first(billingDetail.salesBillingInvoice).documentDate)).format('DD/MM/YYYY')}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={24}>
                                        <InputHero
                                            label="เลขที่ใบสั่งขาย"
                                            small
                                            name="soNumber"
                                            value={billingDetail && billingDetail.soNumber}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row style={{ paddingTop: 10 }} type="flex" justify="end">
                            <Col sm={24}>
                                <div className="table-layout">
                                    <TableCustom
                                        columns={columnsProduct}
                                        data={dataProductTableList}
                                        rowKey={(row, index) => index}
                                        //pagination={false} 
                                        small
                                        scroll={{ x: "max-content" }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="bottom-content">
                        <div className="left-zone">
                            <div className="button">
                                <Link
                                    to={ROUTH_PATH.HOME}
                                    className="footer-button"
                                >
                                    <ButtonCustom
                                        text="ยกเลิก"
                                        icon="close"
                                        type="danger"
                                    />
                                </Link>
                            </div>
                        </div>


                        <div className="right-zone">
                            <div className="button">
                                <ButtonCustom
                                    onClick={() => this.handleClickSaveFullInvoice()}
                                    text="บันทึก"
                                    icon="save"
                                    disabled={!permissionPage.authCreate}
                                />
                            </div>
                        </div>
                    </div>
                </Spin>

                <CustomerSearchModal
                    isShowModal={isShowSearchCustomerModal}
                    handleCloseModal={this.handleCloseModal}
                    onSelected={this.onSelectedCustomer}
                    outletId={outlet}
                />

                <ModalCustom
                    isShowModal={isShowModal}
                    handleCloseModal={this.handleCloseModal}
                    title="เพิ่ม / แก้ไขข้อมูลลูกค้า"
                    width='900px'
                    footer={<div className="modal-footer-button">
                        <div>
                            <ButtonCustom onClick={() => this.handleClickSaveCustomer()} text="บันทึก" icon="save" />
                        </div>
                        <div>
                            <ButtonCustom onClick={() => this.handleCloseModal()} text="ยกเลิก" type="danger" />
                        </div>
                    </div>}
                >
                    <CustomerDetailFormRedux
                        initialValues={initialValuesCustomerDetail}
                        submitViewErrorCustomer={submitViewErrorCustomer}
                    />
                </ModalCustom>
            </div>
        );
    }
}


const mapStateToProps = state => {
    // console.log("state===>", state);
    return {
        auth: state.auth,
        CustomerReduxForm: state.form.CustomerDetailFormRedux,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFullContainer);