import React, { Component, Fragment } from 'react'
import { formatNumber } from 'helpers'
import { Row, Col, message, Button } from 'antd'
import {
    ButtonCustom,
} from 'components/common'
import {
    SaleDiscountModal,
} from 'components/form'

export class SaleSummary extends Component {
    state = {
        isShowModal: false,
    }

    openModal = () => {
        this.setState({ isShowModal: true })
    }

    closeModal = () => {
        this.setState({ isShowModal: false })
    }

    onSubmitDiscount = (totalSummary) => {
        if (totalSummary.totalDiscAmount > this.props.totalSummary.totalAmount) {
            message.error('ส่วนลดต้องน้อยกว่าจำนวนเงินสุทธิ')
            return
        }
        this.closeModal()
        this.props.onSubmitDiscount(totalSummary)
    }

    render() {
        const {
            totalSummary,
            sectionBottomSummary,
            type,
            disable
        } = this.props
        const { isShowModal } = this.state
        const { totalAmount, totalExcVatAmount, totalDiscAmount, netAmount, vatAmount } = totalSummary

        return (
            <Fragment>
                <Row className="font-weight-bold text-right">
                    <Col className="text-right" sm={11}>
                        <div>
                            รวมจำนวนเงิน
                        </div>
                        <div>
                            ส่วนลด(บาท)
                        </div>
                        <div>
                            มูลค่าก่อนภาษี
                        </div>
                        <div>
                            ภาษีมูลค่าเพิ่ม
                        </div>
                        <div>
                            จำนวนเงินสุทธิ
                        </div>
                    </Col>
                    <Col className="text-right" sm={11}>
                        <div>
                            {formatNumber(totalAmount || 0, 2)}
                        </div>
                        <div>
                            {formatNumber(totalDiscAmount || 0, 2)}
                        </div>
                        <div>
                            {formatNumber(totalExcVatAmount || 0, 2) /*formatNumber(totalAmount - vatAmount || 0, 2)*/}
                        </div>
                        <div>
                            {formatNumber(vatAmount || 0, 2)}
                        </div>
                        <div>
                            {formatNumber(netAmount || 0, 2)}
                        </div>
                    </Col>
                    {this.props.onSubmitDiscount && <Col className="text-right" sm={2} style={{ marginTop: '1rem' }}>
                        <div>
                            <ButtonCustom
                                type="primary"
                                icon="search"
                                small
                                onClick={this.openModal}
                            />
                        </div>
                    </Col>}
                </Row>
                {sectionBottomSummary}
                {this.props.onSubmitDiscount && <SaleDiscountModal
                    type={type}
                    totalSummary={totalSummary}
                    isShowModal={isShowModal}
                    onCloseModal={this.closeModal}
                    onSubmitDiscount={this.onSubmitDiscount}
                />}
            </Fragment>
        )
    }
}
