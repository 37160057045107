import React, { Component, Fragment } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Spin } from 'antd'
import { get } from 'lodash'
import { VALIDATE } from 'helpers'
import {
    ProductSearchModal,
} from 'components/feature'
import {
    InputCustom,
    DatePickerCustom,
    SelectCustom,
    ButtonCustom,
} from 'components/common'
import { apiServices, API_PATH } from 'apiServices'
import swal from '@sweetalert/with-react'

import './style.css'

class ReportStockMovementFromRedux extends Component {
    state = {
        isShowModal: false,
        loading: false,
        outlets: [],
        productTypes: [],
        specialInventories: [],
        storageLocations: [],
        movementTypes: [],
        widthLabel: '130px',
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth, change } = this.props
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                const outletIdDefault = get(outlets, '0.value')
                this.setState({
                    outlets,
                }, () => {
                    change('outlet', outletIdDefault)
                    this.getStorageLocations(outletIdDefault)
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        await apiServices.callApi('get', `${API_PATH.GET_MASTER_PRODUCT_TYPE}?ActiveStatus=true`).then(res => {
            let productTypes = get(res, 'data.results')
            if (res.status === 200) {
                productTypes = productTypes.map(data => (
                    { ...data, label: `${data.productTypeCode} - ${data.productTypeDescription}`, value: data.productTypeCode }
                ))
                this.setState({ productTypes })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SPECIAL_IND}?ActiveStatus=true`).then(res => {
            let specialInventories = get(res, 'data.results')
            if (res.status === 200) {
                specialInventories = specialInventories.map(data => (
                    { ...data, label: `${data.specialIndCode} - ${data.specialIndDescription}`, value: data.specialIndCode }
                ))
                this.setState({ specialInventories: [{ label: 'กรุณาเลือก', value: '' }, ...specialInventories] })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        await apiServices.callApi('get', `${API_PATH.GET_MASTER_TRANSACTION_TYPE}?ActiveStatus=true`).then(res => {
            let movementTypes = get(res, 'data.results')
            if (res.status === 200) {
                movementTypes = movementTypes.map(data => (
                    { ...data, label: `${data.transactionCode} - ${data.transactionDescription}`, value: data.transactionCode }
                ))
                this.setState({ movementTypes })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    onOpenModal = () => {
        this.setState({ isShowModal: true })
    }

    onCloseModal = () => {
        this.setState({ isShowModal: false })
    }

    onKeyPress = (e) => {
        e.key === 'Enter' && e.preventDefault()
    }

    onChangeOutlet = (outletId) => {
        const { change } = this.props
        this.getStorageLocations(outletId)
        change('storageLocations', [])
    }

    getStorageLocations = async (outletId) => {
        this.setState({ loading: true })
        const params = [outletId]
        const { auth } = this.props

        await apiServices.callApi('post', API_PATH.GET_MASTER_WAREHOUSE_BY_OUTLET_ID, params).then(res => {
            let storageLocations = get(res, 'data.results')
            if (res.status === 200) {
                // filter status = true and auth
                storageLocations = storageLocations.filter(storageLocation => 
                    (storageLocation.storageLocationIdStatus && auth.arrayStorageLocationId.includes(storageLocation.storageLocationId))
                )
                storageLocations = storageLocations.map(data => (
                    {
                        ...data,
                        label: `${data.storageLocationId} - ${data.storageLocationDescriptionThai}`,
                        value: data.storageLocationId
                    }
                ))
                this.setState({ storageLocations });
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง');
            swal('Error', message, 'error');
        }).finally(() => {
            this.setState({ loading: false });
        })
    }

    render() {
        const {
            handleSubmit,
            onClickExport,
            outletFrom,
            permissionPage,
        } = this.props

        const {
            isShowModal,
            storageLocations,
            specialInventories,
            productTypes,
            movementTypes,
            outlets,
            loading,
            widthLabel,
        } = this.state

        return (
            <form
                onSubmit={handleSubmit}
                onKeyPress={this.onKeyPress}>
                <Spin spinning={loading}>
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                component={InputCustom}
                                name="productCode"
                                label="รหัสสินค้า"
                                widthLabel={widthLabel}
                                small
                            />
                            <div style={{ fontSize: '12px', paddingLeft: widthLabel }}>
                                ใส่ : เพื่อค้นหาข้อมูลแบบช่วง หรือใส่ , เพื่อค้นหาข้อมูลแบบรายตัว
                            </div>
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={InputCustom}
                                name="barcode"
                                label="บาร์โค้ด"
                                widthLabel={widthLabel}
                                small
                            />
                            <div style={{ fontSize: '12px', paddingLeft: widthLabel }}>
                                ใส่ : เพื่อค้นหาข้อมูลแบบช่วง หรือใส่ , เพื่อค้นหาข้อมูลแบบรายตัว
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={10} style={{ paddingTop: '10px' }}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                component={SelectCustom}
                                name="outlet"
                                label="ร้าน"
                                options={outlets}
                                widthLabel={widthLabel}
                                small
                                onChange={this.onChangeOutlet}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={SelectCustom}
                                name="storageLocations"
                                mode="multiple"
                                isValueAllClear
                                maxTagCount={2}
                                label="ที่เก็บสินค้า"
                                options={storageLocations}
                                optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                widthLabel={widthLabel}
                                small
                                placeholder="ทั้งหมด"
                            />
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                component={SelectCustom}
                                name="movementTypes"
                                mode="multiple"
                                isValueAllClear
                                maxTagCount={2}
                                label="ประเภทการเคลื่อนย้าย"
                                options={movementTypes}
                                optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                widthLabel={widthLabel}
                                small
                                placeholder="ทั้งหมด"
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={SelectCustom}
                                name="specialInventory"
                                label="สต็อกพิเศษ"
                                options={specialInventories}
                                widthLabel={widthLabel}
                                small
                                placeholder="กรุณาเลือก"
                            />
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                component={SelectCustom}
                                name="productTypes"
                                mode="multiple"
                                isValueAllClear
                                maxTagCount={2}
                                label="ประเภทสินค้า"
                                options={productTypes}
                                optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                widthLabel={widthLabel}
                                small
                                placeholder="ทั้งหมด"
                            />
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                component={InputCustom}
                                name="sellerId"
                                label="รหัสผู้ขาย"
                                widthLabel={widthLabel}
                                small
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={InputCustom}
                                name="customerId"
                                label="รหัสลูกค้า"
                                widthLabel={widthLabel}
                                small
                            />
                        </Col>
                        <Col sm={12} className="input-range-box">
                            <Fragment>
                                <div className="input-range-label">วันที่เอกสาร</div>
                                <div className="input-range">
                                    <div style={{ marginRight: '5px', width: '100%' }}>
                                        <Field
                                            component={DatePickerCustom}
                                            name="documentDateFrom"
                                            small
                                        />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Field
                                            component={DatePickerCustom}
                                            name="documentDateTo"
                                            small
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        </Col>
                        <Col sm={12} className="input-range-box">
                            <Fragment>
                                <div className="input-range-label">วันที่สร้าง</div>
                                <div className="input-range">
                                    <div style={{ marginRight: '5px', width: '100%' }}>
                                        <Field
                                            component={DatePickerCustom}
                                            name="createDateFrom"
                                            small
                                        />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Field
                                            component={DatePickerCustom}
                                            name="createDateTo"
                                            small
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={InputCustom}
                                name="prNumber"
                                label="ใบสั่งซื้อ"
                                widthLabel={widthLabel}
                                small
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={InputCustom}
                                name="soNumber"
                                label="ใบสั่งขาย"
                                widthLabel={widthLabel}
                                small
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={InputCustom}
                                name="documentMaterial"
                                label="เอกสารวัสดุ"
                                widthLabel={widthLabel}
                                small
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={InputCustom}
                                name="documentMaterialYear"
                                label="ปีเอกสารวัสดุ"
                                widthLabel={widthLabel}
                                small
                            />
                        </Col>
                    </Row>
                    <Row gutter={20} className="text-right">
                        <Col sm={24}>
                            <div className="action-box">
                                <div className="button">
                                    <ButtonCustom
                                        icon="file-excel"
                                        green
                                        small
                                        text="Export"
                                        onClick={onClickExport}
                                        disabled={!permissionPage.authExport}
                                    />
                                </div>
                                <div className="button">
                                    <ButtonCustom
                                        htmlType="submit"
                                        icon="search"
                                        small
                                        text="ค้นหา"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <ProductSearchModal
                        isShowModal={isShowModal}
                        onCloseModal={this.onCloseModal}
                        onSelected={this.onSelectedProduct}
                        outletId={outletFrom}
                    />
                </Spin>
            </form >
        )
    }
}

export default reduxForm({
    form: 'ReportStockMovementFromRedux',
    enableReinitialize: true,
})(ReportStockMovementFromRedux)
