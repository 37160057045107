import React, { Component } from 'react'
import { DatePicker } from 'antd';
import { RangePickerReduxStyled } from "./styled"

const { RangePicker } = DatePicker;

export class RangePickerRedux extends Component {
    render() {
        const {
            input,
            label,
            right,
            defaultRadius,
            submitViewError,
            meta: { touched, error },
            widthSmall,
            widthDefault,
            small,
            widthAll,
        } = this.props
        return (
            <RangePickerReduxStyled error={(touched || submitViewError) && error ? true : false}>
                <div className="input-form">
                    <div className="input-zone-label">
                        {
                            label &&
                            <div
                                className="label-style"
                                style={{
                                    textAlign: right ? 'right' : '',
                                    width: widthDefault ? widthDefault : '150px',
                                }}
                            >
                                {label}
                            </div>
                        }
                        <RangePicker
                            onChange={(e) => input.onChange(e)}
                            value={input.value ? input.value : null}
                            style={{
                                borderRadius: defaultRadius ? 4 : '',
                                width: label
                                    ?
                                    small ? `calc(100% - ${widthSmall ? widthSmall : '120px'})` : `calc(100% - ${widthDefault ? widthDefault : '150px'})`
                                    :
                                    widthAll ? widthAll : ''
                                ,
                            }} />
                    </div>
                    <div className="error-message-bottom">
                        {(touched || submitViewError) ? error : ''}
                    </div>
                </div>
            </RangePickerReduxStyled>
        )
    }
}
