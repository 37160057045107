import React from 'react'
import moment from 'moment'
import { API_PATH } from 'apiServices'

export const interfaceData = {
    INF009: {
        api: API_PATH.POST_OUTBOUND_PRODUCT_RUN_INF009,
        apiExport: API_PATH.POST_OUTBOUND_PRODUCT_RUN_INF009,
        labelCustomCode: 'Customer Code',
        columns: [
            {
                title: 'LOADDATE',
                dataIndex: 'loaddate',
                key: 'loaddate',
                render: (value) => (
                    value && <div>
                        {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                ),
            },
            {
                title: 'COMPANY',
                dataIndex: 'company',
                key: 'company',
            },
            {
                title: 'BRANCH',
                dataIndex: 'branch',
                key: 'branch',
            },
            {
                title: 'RECORDTYPE',
                dataIndex: 'recordtype',
                key: 'recordtype',
            },
            {
                title: 'CODE',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'NAME1',
                dataIndex: 'name1',
                key: 'name1',
            },
            {
                title: 'BILLADDRESS',
                dataIndex: 'billaddress',
                key: 'billaddress',
            },
            {
                title: 'TELEPHONE',
                dataIndex: 'telephone',
                key: 'telephone',
            },
            {
                title: 'FAX',
                dataIndex: 'fax',
                key: 'fax',
            },
            {
                title: 'ACCOUNTCODE',
                dataIndex: 'accountcode',
                key: 'accountcode',
            },
            {
                title: 'IDCARDNO',
                dataIndex: 'idcardno',
                key: 'idcardno',
            },
            {
                title: 'TAXNO',
                dataIndex: 'taxno',
                key: 'taxno',
            },
            {
                title: 'SALECODE',
                dataIndex: 'salecode',
                key: 'salecode',
            },
            {
                title: 'GROUPCODE',
                dataIndex: 'groupcode',
                key: 'groupcode',
            },
            {
                title: 'PERSONTYPE',
                dataIndex: 'persontype',
                key: 'persontype',
            },
            {
                title: 'ACTIVESTATUS',
                dataIndex: 'activestatus',
                key: 'activestatus',
            },
            {
                title: 'BRANCHVATNO',
                dataIndex: 'branchvatno',
                key: 'branchvatno',
            },
            {
                title: 'OOMDTSEXPORT',
                dataIndex: 'oomdtsexport',
                key: 'oomdtsexport',
            },
        ],
    },
    INF010: {
        api: API_PATH.POST_OUTBOUND_PRODUCT_RUN_INF010,
        apiExport: API_PATH.POST_OUTBOUND_PRODUCT_RUN_INF010,
        labelCustomCode: 'Vendor Code',
        columns: [
            {
                title: 'LOADDATE',
                dataIndex: 'loaddate',
                key: 'loaddate',
                render: (value) => (
                    value && <div>
                        {moment(value).format('YYYY-MM-DD')}
                    </div>
                )
            },
            {
                title: 'COMPANY',
                dataIndex: 'company',
                key: 'company',
            },
            {
                title: 'BRANCH',
                dataIndex: 'branch',
                key: 'branch',
            },
            {
                title: 'RECORDTYPE',
                dataIndex: 'recordtype',
                key: 'recordtype',
            },
            {
                title: 'CODE',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'NAME1',
                dataIndex: 'name1',
                key: 'name1',
            },
            {
                title: 'ADDRESS',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: 'TELEPHONE',
                dataIndex: 'telephone',
                key: 'telephone',
            },
            {
                title: 'FAX',
                dataIndex: 'fax',
                key: 'fax',
            },
            {
                title: 'ACCOUNTCODE',
                dataIndex: 'accountcode',
                key: 'accountcode',
            },
            {
                title: 'IDCARDNO',
                dataIndex: 'idcardno',
                key: 'idcardno',
            },
            {
                title: 'TAXNO',
                dataIndex: 'taxno',
                key: 'taxno',
            },
            {
                title: 'PERSONTYPE',
                dataIndex: 'persontype',
                key: 'persontype',
            },
            {
                title: 'ISBRANCH',
                dataIndex: 'isbranch',
                key: 'isbranch',
            },
            {
                title: 'ACTIVESTATUS',
                dataIndex: 'activestatus',
                key: 'activestatus',
            },
            {
                title: 'BRANCHVATNO',
                dataIndex: 'branchvatno',
                key: 'branchvatno',
            },
            {
                title: 'OOMDTSEXPORT',
                dataIndex: 'oomdtsexport',
                key: 'oomdtsexport',
            },
        ],
    },
    INF011: {
        api: API_PATH.POST_OUTBOUND_PRODUCT_RUN_INF011,
        apiExport: API_PATH.POST_OUTBOUND_PRODUCT_RUN_INF011,
        labelCustomCode: 'Product Code',
        columns: [
            {
                title: 'LOADDATE',
                dataIndex: 'loaddate',
                key: 'loaddate',
                render: (value) => (
                    value && <div>
                        {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                ),
            },
            {
                title: 'COMPANY',
                dataIndex: 'company',
                key: 'company',
            },
            {
                title: 'BRANCH',
                dataIndex: 'branch',
                key: 'branch',
            },
            {
                title: 'RECORDTYPE',
                dataIndex: 'recordtype',
                key: 'recordtype',
            },
            {
                title: 'CODE',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'NAME1',
                dataIndex: 'name1',
                key: 'name1',
            },
            {
                title: 'STOCKTYPE',
                dataIndex: 'stocktype',
                key: 'stocktype',
            },
            {
                title: 'COSTTYPE',
                dataIndex: 'costtype',
                key: 'costtype',
            },
            {
                title: 'UNITTYPE',
                dataIndex: 'unittype',
                key: 'unittype',
            },
            {
                title: 'ACTIVESTATUS',
                dataIndex: 'activestatus',
                key: 'activestatus',
            },
            {
                title: 'TAXTYPE',
                dataIndex: 'taxtype',
                key: 'taxtype',
            },
            {
                title: 'DEFSTKUNITCODE',
                dataIndex: 'defstkunitcode',
                key: 'defstkunitcode',
            },
            {
                title: 'DEFBUYUNITCODE',
                dataIndex: 'defbuyunitcode',
                key: 'defbuyunitcode',
            },
            {
                title: 'DEFSALEUNITCODE',
                dataIndex: 'defsaleunitcode',
                key: 'defsaleunitcode',
            },
            {
                title: 'GROUPCODE',
                dataIndex: 'groupcode',
                key: 'groupcode',
            },
            {
                title: 'TYPECODE',
                dataIndex: 'typecode',
                key: 'typecode',
            },
            {
                title: 'CATEGORYCODE',
                dataIndex: 'categorycode',
                key: 'categorycode',
            },
            {
                title: 'FORMATCODE',
                dataIndex: 'formatcode',
                key: 'formatcode',
            },
            {
                title: 'COLORCODE',
                dataIndex: 'colorcode',
                key: 'colorcode',
            },
            {
                title: 'BRANDCODE',
                dataIndex: 'brandcode',
                key: 'brandcode',
            },
            {
                title: 'MYGRADE',
                dataIndex: 'mygrade',
                key: 'mygrade',
            },
            {
                title: 'DEFBUYWHCODE',
                dataIndex: 'defbuywhcode',
                key: 'defbuywhcode',
            },
            {
                title: 'DEFBUYSHELF',
                dataIndex: 'defbuyshelf',
                key: 'defbuyshelf',
            },
            {
                title: 'DEFSALEWHCODE',
                dataIndex: 'defsalewhcode',
                key: 'defsalewhcode',
            },
            {
                title: 'DEFSALESHELF',
                dataIndex: 'defsaleshelf',
                key: 'defsaleshelf',
            },
            {
                title: 'DEFBADWHCODE',
                dataIndex: 'defbadwhcode',
                key: 'defbadwhcode',
            },
            {
                title: 'DEFBADSHELF',
                dataIndex: 'defbadshelf',
                key: 'defbadshelf',
            },
            {
                title: 'DEFFGWHCODE',
                dataIndex: 'deffgwhcode',
                key: 'deffgwhcode',
            },
            {
                title: 'DEFFGSHELF',
                dataIndex: 'deffgshelf',
                key: 'deffgshelf',
            },
            {
                title: 'DEFREPAIRWHCODE',
                dataIndex: 'defrepairwhcode',
                key: 'defrepairwhcode',
            },
            {
                title: 'DEFREPAIRSHELF',
                dataIndex: 'defrepairshelf',
                key: 'defrepairshelf',
            },
            {
                title: 'DEFRAWWHCODE',
                dataIndex: 'defrawwhcode',
                key: 'defrawwhcode',
            },
            {
                title: 'DEFRAWSHELF',
                dataIndex: 'defrawshelf',
                key: 'defrawshelf',
            },
            {
                title: 'UNITCODE1',
                dataIndex: 'unitcode1',
                key: 'unitcode1',
            },
            {
                title: 'UNITCODE2',
                dataIndex: 'unitcode2',
                key: 'unitcode2',
            },
            {
                title: 'UNITCODE3',
                dataIndex: 'unitcode3',
                key: 'unitcode3',
            },
            {
                title: 'UNITCODE4',
                dataIndex: 'unitcode4',
                key: 'unitcode4',
            },
            {
                title: 'UNITCODE5',
                dataIndex: 'unitcode5',
                key: 'unitcode5',
            },
            {
                title: 'UNITCODE6',
                dataIndex: 'unitcode6',
                key: 'unitcode6',
            },
            {
                title: 'UNITCODE7',
                dataIndex: 'unitcode7',
                key: 'unitcode7',
            },
            {
                title: 'UNITCODE1_RATE1',
                dataIndex: 'unitcode1Rate1',
                key: 'unitcode1Rate1',
            },
            {
                title: 'UNITCODE1_RATE2',
                dataIndex: 'unitcode1Rate2',
                key: 'unitcode1Rate2',
            },
            {
                title: 'UNITCODE2_RATE1',
                dataIndex: 'unitcode2Rate1',
                key: 'unitcode2Rate1',
            },
            {
                title: 'UNITCODE2_RATE2',
                dataIndex: 'unitcode2Rate2',
                key: 'unitcode2Rate2',
            },
            {
                title: 'UNITCODE3_RATE1',
                dataIndex: 'unitcode3Rate1',
                key: 'unitcode3Rate1',
            },
            {
                title: 'UNITCODE3_RATE2',
                dataIndex: 'unitcode3Rate2',
                key: 'unitcode3Rate2',
            },
            {
                title: 'UNITCODE4_RATE1',
                dataIndex: 'unitcode4Rate1',
                key: 'unitcode4Rate1',
            },
            {
                title: 'UNITCODE4_RATE2',
                dataIndex: 'unitcode4Rate2',
                key: 'unitcode4Rate2',
            },
            {
                title: 'UNITCODE5_RATE1',
                dataIndex: 'unitcode5Rate1',
                key: 'unitcode5Rate1',
            },
            {
                title: 'UNITCODE5_RATE2',
                dataIndex: 'unitcode5Rate2',
                key: 'unitcode5Rate2',
            },
            {
                title: 'UNITCODE6_RATE1',
                dataIndex: 'unitcode6Rate1',
                key: 'unitcode6Rate1',
            },
            {
                title: 'UNITCODE6_RATE2',
                dataIndex: 'unitcode6Rate2',
                key: 'unitcode6Rate2',
            },
            {
                title: 'UNITCODE7_RATE1',
                dataIndex: 'unitcode7Rate1',
                key: 'unitcode7Rate1',
            },
            {
                title: 'UNITCODE7_RATE2',
                dataIndex: 'unitcode7Rate2',
                key: 'unitcode7Rate2',
            },
            {
                title: 'WHCODE',
                dataIndex: 'whcode',
                key: 'whcode',
            },
            {
                title: 'SHCODE',
                dataIndex: 'shcode',
                key: 'shcode',
            },
            {
                title: 'BARCODE1',
                dataIndex: 'barcode1',
                key: 'barcode1',
            },
            {
                title: 'BARCODE1_UNIT',
                dataIndex: 'barcode1Unit',
                key: 'barcode1Unit',
            },
            {
                title: 'BARCODE2',
                dataIndex: 'barcode2',
                key: 'barcode2',
            },
            {
                title: 'BARCODE2_UNIT',
                dataIndex: 'barcode2Unit',
                key: 'barcode2Unit',
            },
            {
                title: 'BARCODE3',
                dataIndex: 'barcode3',
                key: 'barcode3',
            },
            {
                title: 'BARCODE3_UNIT',
                dataIndex: 'barcode3Unit',
                key: 'barcode3Unit',
            },
            {
                title: 'BARCODE4',
                dataIndex: 'barcode4',
                key: 'barcode4',
            },
            {
                title: 'BARCODE4_UNIT',
                dataIndex: 'barcode4Unit',
                key: 'barcode4Unit',
            },
            {
                title: 'BARCODE5',
                dataIndex: 'barcode5',
                key: 'barcode5',
            },
            {
                title: 'BARCODE5_UNIT',
                dataIndex: 'barcode5Unit',
                key: 'barcode5Unit',
            },
            {
                title: 'BARCODE6',
                dataIndex: 'barcode6',
                key: 'barcode6',
            },
            {
                title: 'BARCODE6_UNIT',
                dataIndex: 'barcode6Unit',
                key: 'barcode6Unit',
            },
            {
                title: 'BARCODE7',
                dataIndex: 'barcode7',
                key: 'barcode7',
            },
            {
                title: 'BARCODE7_UNIT',
                dataIndex: 'barcode7Unit',
                key: 'barcode7Unit',
            },
            {
                title: 'SALEPRICE1_UNIT',
                dataIndex: 'saleprice1Unit',
                key: 'saleprice1Unit',
            },
            {
                title: 'SALEPRICE1_FROMQTY',
                dataIndex: 'saleprice1Fromqty',
                key: 'saleprice1Fromqty',
            },
            {
                title: 'SALEPRICE1_TOQTY',
                dataIndex: 'saleprice1Toqty',
                key: 'saleprice1Toqty',
            },
            {
                title: 'SALEPRICE1_FROMDATE',
                dataIndex: 'saleprice1Fromdate',
                key: 'saleprice1Fromdate',
                render: (value) => (
                    value && <div>
                        {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                ),
            },
            {
                title: 'SALEPRICE1_TODATE',
                dataIndex: 'saleprice1Todate',
                key: 'saleprice1Todate',
                render: (value) => (
                    value && <div>
                        {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                ),
            },
            {
                title: 'SALEPRICE1_SALETYPE',
                dataIndex: 'saleprice1Saletype',
                key: 'saleprice1Saletype',
            },
            {
                title: 'SALEPRICE1_SENDCON',
                dataIndex: 'saleprice1Sendcon',
                key: 'saleprice1Sendcon',
            },
            {
                title: 'SALEPRICE1_TAXTYPE',
                dataIndex: 'saleprice1Taxtype',
                key: 'saleprice1Taxtype',
            },
            {
                title: 'SALEPRICE1_1',
                dataIndex: 'saleprice11',
                key: 'saleprice11',
            },
            {
                title: 'SALEPRICE2_UNIT',
                dataIndex: 'saleprice2Unit',
                key: 'saleprice2Unit',
            },
            {
                title: 'SALEPRICE2_FROMQTY',
                dataIndex: 'saleprice2Fromqty',
                key: 'saleprice2Fromqty',
            },
            {
                title: 'SALEPRICE2_TOQTY',
                dataIndex: 'saleprice2Toqty',
                key: 'saleprice2Toqty',
            },
            {
                title: 'SALEPRICE2_FROMDATE',
                dataIndex: 'saleprice2Fromdate',
                key: 'saleprice2Fromdate',
            },
            {
                title: 'SALEPRICE2_TODATE',
                dataIndex: 'saleprice2Todate',
                key: 'saleprice2Todate',
            },
            {
                title: 'SALEPRICE2_SALETYPE',
                dataIndex: 'saleprice2Saletype',
                key: 'saleprice2Saletype',
            },
            {
                title: 'SALEPRICE2_SENDCON',
                dataIndex: 'saleprice2Sendcon',
                key: 'saleprice2Sendcon',
            },
            {
                title: 'SALEPRICE2_TAXTYPE',
                dataIndex: 'saleprice2Taxtype',
                key: 'saleprice2Taxtype',
            },
            {
                title: 'SALEPRICE2_2',
                dataIndex: 'saleprice22',
                key: 'saleprice22',
            },
            {
                title: 'SALEPRICE3_UNIT',
                dataIndex: 'saleprice3Unit',
                key: 'saleprice3Unit',
            },
            {
                title: 'SALEPRICE3_FROMQTY',
                dataIndex: 'saleprice3Fromqty',
                key: 'saleprice3Fromqty',
            },
            {
                title: 'SALEPRICE3_TOQTY',
                dataIndex: 'saleprice3Toqty',
                key: 'saleprice3Toqty',
            },
            {
                title: 'SALEPRICE3_FROMDATE',
                dataIndex: 'saleprice3Fromdate',
                key: 'saleprice3Fromdate',
            },
            {
                title: 'SALEPRICE3_TODATE',
                dataIndex: 'saleprice3Todate',
                key: 'saleprice3Todate',
            },
            {
                title: 'SALEPRICE3_SALETYPE',
                dataIndex: 'saleprice3Saletype',
                key: 'saleprice3Saletype',
            },
            {
                title: 'SALEPRICE3_SENDCON',
                dataIndex: 'saleprice3Sendcon',
                key: 'saleprice3Sendcon',
            },
            {
                title: 'SALEPRICE3_TAXTYPE',
                dataIndex: 'saleprice3Taxtype',
                key: 'saleprice3Taxtype',
            },
            {
                title: 'SALEPRICE3_3',
                dataIndex: 'saleprice33',
                key: 'saleprice33',
            },
            {
                title: 'SALEPRICE4_UNIT',
                dataIndex: 'saleprice4Unit',
                key: 'saleprice4Unit',
            },
            {
                title: 'SALEPRICE4_FROMQTY',
                dataIndex: 'saleprice4Fromqty',
                key: 'saleprice4Fromqty',
            },
            {
                title: 'SALEPRICE4_TOQTY',
                dataIndex: 'saleprice4Toqty',
                key: 'saleprice4Toqty',
            },
            {
                title: 'SALEPRICE4_FROMDATE',
                dataIndex: 'saleprice4Fromdate',
                key: 'saleprice4Fromdate',
            },
            {
                title: 'SALEPRICE4_TODATE',
                dataIndex: 'saleprice4Todate',
                key: 'saleprice4Todate',
            },
            {
                title: 'SALEPRICE4_SALETYPE',
                dataIndex: 'saleprice4Saletype',
                key: 'saleprice4Saletype',
            },
            {
                title: 'SALEPRICE4_SENDCON',
                dataIndex: 'saleprice4Sendcon',
                key: 'saleprice4Sendcon',
            },
            {
                title: 'SALEPRICE4_TAXTYPE',
                dataIndex: 'saleprice4Taxtype',
                key: 'saleprice4Taxtype',
            },
            {
                title: 'SALEPRICE4_4',
                dataIndex: 'saleprice44',
                key: 'saleprice44',
            },
            {
                title: 'SALEPRICE5_UNIT',
                dataIndex: 'saleprice5Unit',
                key: 'saleprice5Unit',
            },
            {
                title: 'SALEPRICE5_FROMQTY',
                dataIndex: 'saleprice5Fromqty',
                key: 'saleprice5Fromqty',
            },
            {
                title: 'SALEPRICE5_TOQTY',
                dataIndex: 'saleprice5Toqty',
                key: 'saleprice5Toqty',
            },
            {
                title: 'SALEPRICE5_FROMDATE',
                dataIndex: 'saleprice5Fromdate',
                key: 'saleprice5Fromdate',
            },
            {
                title: 'SALEPRICE5_TODATE',
                dataIndex: 'saleprice5Todate',
                key: 'saleprice5Todate',
            },
            {
                title: 'SALEPRICE5_SALETYPE',
                dataIndex: 'saleprice5Saletype',
                key: 'saleprice5Saletype',
            },
            {
                title: 'SALEPRICE5_SENDCON',
                dataIndex: 'saleprice5Sendcon',
                key: 'saleprice5Sendcon',
            },
            {
                title: 'SALEPRICE5_TAXTYPE',
                dataIndex: 'saleprice5Taxtype',
                key: 'saleprice5Taxtype',
            },
            {
                title: 'SALEPRICE5_5',
                dataIndex: 'saleprice55',
                key: 'saleprice55',
            },
            {
                title: 'SALEPRICE6_UNIT',
                dataIndex: 'saleprice6Unit',
                key: 'saleprice6Unit',
            },
            {
                title: 'SALEPRICE6_FROMQTY',
                dataIndex: 'saleprice6Fromqty',
                key: 'saleprice6Fromqty',
            },
            {
                title: 'SALEPRICE6_TOQTY',
                dataIndex: 'saleprice6Toqty',
                key: 'saleprice6Toqty',
            },
            {
                title: 'SALEPRICE6_FROMDATE',
                dataIndex: 'saleprice6Fromdate',
                key: 'saleprice6Fromdate',
            },
            {
                title: 'SALEPRICE6_TODATE',
                dataIndex: 'saleprice6Todate',
                key: 'saleprice6Todate',
            },
            {
                title: 'SALEPRICE6_SALETYPE',
                dataIndex: 'saleprice6Saletype',
                key: 'saleprice6Saletype',
            },
            {
                title: 'SALEPRICE6_SENDCON',
                dataIndex: 'saleprice6Sendcon',
                key: 'saleprice6Sendcon',
            },
            {
                title: 'SALEPRICE6_TAXTYPE',
                dataIndex: 'saleprice6Taxtype',
                key: 'saleprice6Taxtype',
            },
            {
                title: 'SALEPRICE6_6',
                dataIndex: 'saleprice66',
                key: 'saleprice66',
            },
            {
                title: 'SALEPRICE7_UNIT',
                dataIndex: 'saleprice7Unit',
                key: 'saleprice7Unit',
            },
            {
                title: 'SALEPRICE7_FROMQTY',
                dataIndex: 'saleprice7Fromqty',
                key: 'saleprice7Fromqty',
            },
            {
                title: 'SALEPRICE7_TOQTY',
                dataIndex: 'saleprice7Toqty',
                key: 'saleprice7Toqty',
            },
            {
                title: 'SALEPRICE7_FROMDATE',
                dataIndex: 'saleprice7Fromdate',
                key: 'saleprice7Fromdate',
            },
            {
                title: 'SALEPRICE7_TODATE',
                dataIndex: 'saleprice7Todate',
                key: 'saleprice7Todate',
            },
            {
                title: 'SALEPRICE7_SALETYPE',
                dataIndex: 'saleprice7Saletype',
                key: 'saleprice7Saletype',
            },
            {
                title: 'SALEPRICE7_SENDCON',
                dataIndex: 'saleprice7Sendcon',
                key: 'saleprice7Sendcon',
            },
            {
                title: 'SALEPRICE7_TAXTYPE',
                dataIndex: 'saleprice7Taxtype',
                key: 'saleprice7Taxtype',
            },
            {
                title: 'SALEPRICE7_7',
                dataIndex: 'saleprice77',
                key: 'saleprice77',
            },
            {
                title: 'OOMDTSEXPORT',
                dataIndex: 'oomdtsexport',
                key: 'oomdtsexport',
            },
        ]
    },
    INF012: {
        api: API_PATH.POST_OUTBOUND_PRODUCT_RUN_INF012,
        apiExport: API_PATH.POST_OUTBOUND_PRODUCT_RUN_INF012,
        labelCustomCode: 'Vendor Code',
        columns: [
            {
                title: 'ROWORDER',
                dataIndex: 'roworder',
                key: 'roworder',
            },
            {
                title: 'LOADDATE',
                dataIndex: 'loaddate',
                key: 'loaddate',
                render: (value) => (
                    value && <div>
                        {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                ),
            },
            {
                title: 'COMPANY',
                dataIndex: 'company',
                key: 'company',
            },
            {
                title: 'BRANCH',
                dataIndex: 'branch',
                key: 'branch',
            },
            {
                title: 'RECORDTYPE',
                dataIndex: 'recordtype',
                key: 'recordtype',
            },
            {
                title: 'DOCTYPE',
                dataIndex: 'doctype',
                key: 'doctype',
            },
            {
                title: 'CANCELFLAG',
                dataIndex: 'cancelflag',
                key: 'cancelflag',
            },
            {
                title: 'DOCNO',
                dataIndex: 'docno',
                key: 'docno',
            },
            {
                title: 'DOCDATE',
                dataIndex: 'docdate',
                key: 'docdate',
                render: (value) => (
                    value && <div>
                        {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                ),
            },
            {
                title: 'TAXNO',
                dataIndex: 'taxno',
                key: 'taxno',
            },
            {
                title: 'MYDESCRIPTION',
                dataIndex: 'mydescription',
                key: 'mydescription',
            },
            {
                title: 'APCODE',
                dataIndex: 'apcode',
                key: 'apcode',
            },
            {
                title: 'SUMOFITEMAMOUNT',
                dataIndex: 'sumofitemamount',
                key: 'sumofitemamount',
            },
            {
                title: 'BEFORETAXAMOUNT',
                dataIndex: 'beforetaxamount',
                key: 'beforetaxamount',
            },
            {
                title: 'TAXAMOUNT',
                dataIndex: 'taxamount',
                key: 'taxamount',
            },
            {
                title: 'TOTALAMOUNT',
                dataIndex: 'totalamount',
                key: 'totalamount',
            },
            {
                title: 'EXCEPTTAXAMOUNT',
                dataIndex: 'excepttaxamount',
                key: 'excepttaxamount',
            },
            {
                title: 'ZEROTAXAMOUNT',
                dataIndex: 'zerotaxamount',
                key: 'zerotaxamount',
            },
            {
                title: 'NETDEBTAMOUNT',
                dataIndex: 'netdebtamount',
                key: 'netdebtamount',
            },
            {
                title: 'LINENUMBER',
                dataIndex: 'linenumber',
                key: 'linenumber',
            },
            {
                title: 'ITEMCODE',
                dataIndex: 'itemcode',
                key: 'itemcode',
            },
            {
                title: 'ITEMNAME',
                dataIndex: 'itemname',
                key: 'itemname',
            },
            {
                title: 'UNITCODE',
                dataIndex: 'unitcode',
                key: 'unitcode',
            },
            {
                title: 'WHCODE',
                dataIndex: 'whcode',
                key: 'whcode',
            },
            {
                title: 'SHELFCODE',
                dataIndex: 'shelfcode',
                key: 'shelfcode',
            },
            {
                title: 'POREFNO',
                dataIndex: 'porefno',
                key: 'porefno',
            },
            {
                title: 'POREFLINENUM',
                dataIndex: 'poreflinenum',
                key: 'poreflinenum',
            },
            {
                title: 'QTY',
                dataIndex: 'qty',
                key: 'qty',
            },
            {
                title: 'PRICE',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'DISCOUNTWORD',
                dataIndex: 'discountword',
                key: 'discountword',
            },
            {
                title: 'DISCOUNTAMOUNT',
                dataIndex: 'discountamount',
                key: 'discountamount',
            },
            {
                title: 'AMOUNT',
                dataIndex: 'amount',
                key: 'amount',
            },
            {
                title: 'NETAMOUNT',
                dataIndex: 'netamount',
                key: 'netamount',
            },
            {
                title: 'GRREFNO',
                dataIndex: 'grrefno',
                key: 'grrefno',
            },
            {
                title: 'GRREFLINENUM',
                dataIndex: 'grreflinenum',
                key: 'grreflinenum',
            },
            {
                title: 'SUMAMOUNT1',
                dataIndex: 'sumamount1',
                key: 'sumamount1',
            },
            {
                title: 'SUMAMOUNT2',
                dataIndex: 'sumamount2',
                key: 'sumamount2',
            },
            {
                title: 'ADJUSTTYPE',
                dataIndex: 'adjusttype',
                key: 'adjusttype',
            },
            {
                title: 'ADJMARK',
                dataIndex: 'adjmark',
                key: 'adjmark',
            },
            {
                title: 'ADJCOST',
                dataIndex: 'adjcost',
                key: 'adjcost',
            },
            {
                title: 'SUMOFITEMQTY',
                dataIndex: 'sumofitemqty',
                key: 'sumofitemqty',
            },
            {
                title: 'TRANSFERTYPE',
                dataIndex: 'transfertype',
                key: 'transfertype',
            },
            {
                title: 'ISSUEREFNO',
                dataIndex: 'issuerefno',
                key: 'issuerefno',
            },
            {
                title: 'ISSUEREFLINENUM',
                dataIndex: 'issuereflinenum',
                key: 'issuereflinenum',
            },
            {
                title: 'OOMDTSEXPORT',
                dataIndex: 'oomdtsexport',
                key: 'oomdtsexport',
            },
        ],
    },
    INF013: {
        api: API_PATH.POST_OUTBOUND_PRODUCT_RUN_INF013,
        apiExport: API_PATH.POST_OUTBOUND_PRODUCT_RUN_INF013,
        labelCustomCode: 'Customer Code',
        columns: [
            {
                title: 'LOADDATE',
                dataIndex: 'loaddate',
                key: 'loaddate',
                render: (value) => (
                    value && <div>
                        {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                ),
            },
            {
                title: 'COMPANY',
                dataIndex: 'company',
                key: 'company',
            },
            {
                title: 'BRANCH',
                dataIndex: 'branch',
                key: 'branch',
            },
            {
                title: 'RECORDTYPE',
                dataIndex: 'recordtype',
                key: 'recordtype',
            },
            {
                title: 'DOCTYPE',
                dataIndex: 'doctype',
                key: 'doctype',
            },
            {
                title: 'CANCELFLAG',
                dataIndex: 'cancelflag',
                key: 'cancelflag',
            },
            {
                title: 'DOCNO',
                dataIndex: 'docno',
                key: 'docno',
            },
            {
                title: 'DOCDATE',
                dataIndex: 'docdate',
                key: 'docdate',
                render: (value) => (
                    value && <div>
                        {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                ),
            },
            {
                title: 'BILLTYPE',
                dataIndex: 'billtype',
                key: 'billtype',
            },
            {
                title: 'POSSTATUS',
                dataIndex: 'posstatus',
                key: 'posstatus',
            },
            {
                title: 'ARCODE',
                dataIndex: 'arcode',
                key: 'arcode',
            },
            {
                title: 'ARNAME',
                dataIndex: 'arname',
                key: 'arname',
            },
            {
                title: 'ARADDRESS',
                dataIndex: 'araddress',
                key: 'araddress',
            },
            {
                title: 'ARISBRANCH',
                dataIndex: 'arisbranch',
                key: 'arisbranch',
            },
            {
                title: 'ARBRANCHNO',
                dataIndex: 'arbranchno',
                key: 'arbranchno',
            },
            {
                title: 'ARTAXNO',
                dataIndex: 'artaxno',
                key: 'artaxno',
            },
            {
                title: 'TAXTYPE',
                dataIndex: 'taxtype',
                key: 'taxtype',
            },
            {
                title: 'TAXNO',
                dataIndex: 'taxno',
                key: 'taxno',
            },
            {
                title: 'TAXDATE',
                dataIndex: 'taxdate',
                key: 'taxdate',
                render: (value) => (
                    value && <div>
                        {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                ),
            },
            {
                title: 'TAXRATE',
                dataIndex: 'taxrate',
                key: 'taxrate',
            },
            {
                title: 'CREDITDAY',
                dataIndex: 'creditday',
                key: 'creditday',
            },
            {
                title: 'DUEDATE',
                dataIndex: 'duedate',
                key: 'duedate',
                render: (value) => (
                    value && <div>
                        {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                ),
            },
            {
                title: 'DELIVERYDAY',
                dataIndex: 'deliveryday',
                key: 'deliveryday',
            },
            {
                title: 'DELIVERYDATE',
                dataIndex: 'deliverydate',
                key: 'deliverydate',
            },
            {
                title: 'SALECODE',
                dataIndex: 'salecode',
                key: 'salecode',
            },
            {
                title: 'SALEAREACODE',
                dataIndex: 'saleareacode',
                key: 'saleareacode',
            },
            {
                title: 'DEPARTCODE',
                dataIndex: 'departcode',
                key: 'departcode',
            },
            {
                title: 'PROJECTCODE',
                dataIndex: 'projectcode',
                key: 'projectcode',
            },
            {
                title: 'ALLOCATECODE',
                dataIndex: 'allocatecode',
                key: 'allocatecode',
            },
            {
                title: 'MYDESCRIPTION',
                dataIndex: 'mydescription',
                key: 'mydescription',
            },
            {
                title: 'DELIVERYADDR',
                dataIndex: 'deliveryaddr',
                key: 'deliveryaddr',
            },
            {
                title: 'CONTACTCODE',
                dataIndex: 'contactcode',
                key: 'contactcode',
            },
            {
                title: 'TRANSPORTCODE',
                dataIndex: 'transportcode',
                key: 'transportcode',
            },
            {
                title: 'SUMOFITEMAMOUNT',
                dataIndex: 'sumofitemamount',
                key: 'sumofitemamount',
            },
            {
                title: 'DISCOUNTWORD',
                dataIndex: 'discountword',
                key: 'discountword',
            },
            {
                title: 'DISCOUNTAMOUNT1',
                dataIndex: 'discountamount1',
                key: 'discountamount1',
            },
            {
                title: 'AFTERDISCOUNT',
                dataIndex: 'afterdiscount',
                key: 'afterdiscount',
            },
            {
                title: 'BEFORETAXAMOUNT',
                dataIndex: 'beforetaxamount',
                key: 'beforetaxamount',
            },
            {
                title: 'TAXAMOUNT',
                dataIndex: 'taxamount',
                key: 'taxamount',
            },
            {
                title: 'TOTALAMOUNT',
                dataIndex: 'totalamount',
                key: 'totalamount',
            },
            {
                title: 'ZEROTAXAMOUNT',
                dataIndex: 'zerotaxamount',
                key: 'zerotaxamount',
            },
            {
                title: 'EXCEPTTAXAMOUNT',
                dataIndex: 'excepttaxamount',
                key: 'excepttaxamount',
            },
            {
                title: 'SUMCASHAMOUNT',
                dataIndex: 'sumcashamount',
                key: 'sumcashamount',
            },
            {
                title: 'SUMCHQAMOUNT',
                dataIndex: 'sumchqamount',
                key: 'sumchqamount',
            },
            {
                title: 'SUMCREDITAMOUNT',
                dataIndex: 'sumcreditamount',
                key: 'sumcreditamount',
            },
            {
                title: 'SUMBANKAMOUNT',
                dataIndex: 'sumbankamount',
                key: 'sumbankamount',
            },
            {
                title: 'SUMOFWTAX',
                dataIndex: 'sumofwtax',
                key: 'sumofwtax',
            },
            {
                title: 'NETDEBTAMOUNT',
                dataIndex: 'netdebtamount',
                key: 'netdebtamount',
            },
            {
                title: 'OTHERINCOME',
                dataIndex: 'otherincome',
                key: 'otherincome',
            },
            {
                title: 'OTHEREXPENSE',
                dataIndex: 'otherexpense',
                key: 'otherexpense',
            },
            {
                title: 'EXCESSAMOUNT1',
                dataIndex: 'excessamount1',
                key: 'excessamount1',
            },
            {
                title: 'EXCESSAMOUNT2',
                dataIndex: 'excessamount2',
                key: 'excessamount2',
            },
            {
                title: 'LINENUMBER',
                dataIndex: 'linenumber',
                key: 'linenumber',
            },
            {
                title: 'SOREFNO',
                dataIndex: 'sorefno',
                key: 'sorefno',
            },
            {
                title: 'ITEMCODE',
                dataIndex: 'itemcode',
                key: 'itemcode',
            },
            {
                title: 'ITEMNAME',
                dataIndex: 'itemname',
                key: 'itemname',
            },
            {
                title: 'UNITCODE',
                dataIndex: 'unitcode',
                key: 'unitcode',
            },
            {
                title: 'WHCODE',
                dataIndex: 'whcode',
                key: 'whcode',
            },
            {
                title: 'SHELFCODE',
                dataIndex: 'shelfcode',
                key: 'shelfcode',
            },
            {
                title: 'QTY',
                dataIndex: 'qty',
                key: 'qty',
            },
            {
                title: 'PRICE',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'DISCOUNTAMOUNT2',
                dataIndex: 'discountamount2',
                key: 'discountamount2',
            },
            {
                title: 'AMOUNT',
                dataIndex: 'amount',
                key: 'amount',
            },
            {
                title: 'NETAMOUNT',
                dataIndex: 'netamount',
                key: 'netamount',
            },
            {
                title: 'REFNO',
                dataIndex: 'refno',
                key: 'refno',
            },
            {
                title: 'SUMOLDAMOUNT',
                dataIndex: 'sumoldamount',
                key: 'sumoldamount',
            },
            {
                title: 'SUMTRUEAMOUNT',
                dataIndex: 'sumtrueamount',
                key: 'sumtrueamount',
            },
            {
                title: 'SUMOFDIFFAMOUNT',
                dataIndex: 'sumofdiffamount',
                key: 'sumofdiffamount',
            },
            {
                title: 'RETURNMONEY',
                dataIndex: 'returnmoney',
                key: 'returnmoney',
            },
            {
                title: 'RETURNSTATUS',
                dataIndex: 'returnstatus',
                key: 'returnstatus',
            },
            {
                title: 'DEBTLIMITSTATE',
                dataIndex: 'debtlimitstate',
                key: 'debtlimitstate',
            },
            {
                title: 'PETTYCASHAMOUNT',
                dataIndex: 'pettycashamount',
                key: 'pettycashamount',
            },
            {
                title: 'CASETYPE',
                dataIndex: 'casetype',
                key: 'casetype',
            },
            {
                title: 'ISCNDEPOSIT',
                dataIndex: 'iscndeposit',
                key: 'iscndeposit',
            },
            {
                title: 'INVLINENUMBER',
                dataIndex: 'invlinenumber',
                key: 'invlinenumber',
            },
            {
                title: 'INVOICEDISC',
                dataIndex: 'invoicedisc',
                key: 'invoicedisc',
            },
            {
                title: 'OLDAMOUNT',
                dataIndex: 'oldamount',
                key: 'oldamount',
            },
            {
                title: 'TRUEAMOUNT',
                dataIndex: 'trueamount',
                key: 'trueamount',
            },
            {
                title: 'DIFFAMOUNT',
                dataIndex: 'diffamount',
                key: 'diffamount',
            },
            {
                title: 'INVBILLTYPE',
                dataIndex: 'invbilltype',
                key: 'invbilltype',
            },
            {
                title: 'DISCQTY',
                dataIndex: 'discqty',
                key: 'discqty',
            },
            {
                title: 'BILLQTY',
                dataIndex: 'billqty',
                key: 'billqty',
            },
            {
                title: 'INVOICENO',
                dataIndex: 'invoiceno',
                key: 'invoiceno',
            },
            {
                title: 'PAYMENTLINE',
                dataIndex: 'paymentline',
                key: 'paymentline',
            },
            {
                title: 'PAYMENTTYPE',
                dataIndex: 'paymenttype',
                key: 'paymenttype',
            },
            {
                title: 'CHQNUMBER',
                dataIndex: 'chqnumber',
                key: 'chqnumber',
            },
            {
                title: 'BANKCODE',
                dataIndex: 'bankcode',
                key: 'bankcode',
            },
            {
                title: 'BANKBRANCHCODE',
                dataIndex: 'bankbranchcode',
                key: 'bankbranchcode',
            },
            {
                title: 'CHQDUEDATE',
                dataIndex: 'chqduedate',
                key: 'chqduedate',
            },
            {
                title: 'PAYAMOUNT',
                dataIndex: 'payamount',
                key: 'payamount',
            },
            {
                title: 'RECEIVEDATE',
                dataIndex: 'receivedate',
                key: 'receivedate',
            },
            {
                title: 'BOOKNO',
                dataIndex: 'bookno',
                key: 'bookno',
            },
            {
                title: 'PAYMENTDATE',
                dataIndex: 'paymentdate',
                key: 'paymentdate',
            },
            {
                title: 'BANKTRANFERDATE',
                dataIndex: 'banktranferdate',
                key: 'banktranferdate',
            },
            {
                title: 'CREDITTYPE',
                dataIndex: 'credittype',
                key: 'credittype',
            },
            {
                title: 'CHQTOTALAMOUNT',
                dataIndex: 'chqtotalamount',
                key: 'chqtotalamount',
            },
            {
                title: 'CONFIRMNO',
                dataIndex: 'confirmno',
                key: 'confirmno',
            },
            {
                title: 'CHARGEAMOUNT',
                dataIndex: 'chargeamount',
                key: 'chargeamount',
            },
            {
                title: 'PAYREFNO',
                dataIndex: 'payrefno',
                key: 'payrefno',
            },
            {
                title: 'DEPOSITLINENUMBER',
                dataIndex: 'depositlinenumber',
                key: 'depositlinenumber',
            },
            {
                title: 'DEPOSITNO',
                dataIndex: 'depositno',
                key: 'depositno',
            },
            {
                title: 'DEPOSITAMOUNT',
                dataIndex: 'depositamount',
                key: 'depositamount',
            },
            {
                title: 'DEPOSITBALANCE',
                dataIndex: 'depositbalance',
                key: 'depositbalance',
            },
            {
                title: 'OOMDTSEXPORT',
                dataIndex: 'oomdtsexport',
                key: 'oomdtsexport',
            },
            {
                title: 'TAXID',
                dataIndex: 'taxid',
                key: 'taxid',
            },
            {
                title: 'BRANCHVATNO',
                dataIndex: 'branchvatno',
                key: 'branchvatno',
            },
            {
                title: 'ARTICLEDOC',
                dataIndex: 'articledoc',
                key: 'articledoc',
            },
            {
                title: 'COUPON_CODE',
                dataIndex: 'couponCode',
                key: 'couponCode',
            },
            {
                title: 'JOBID_HS',
                dataIndex: 'jobidHs',
                key: 'jobidHs',
            },
            {
                title: 'REDEEMNO',
                dataIndex: 'redeemno',
                key: 'redeemno',
            },
            {
                title: 'VOUCHERNO',
                dataIndex: 'voucherno',
                key: 'voucherno',
            },
            {
                title: 'SCGID',
                dataIndex: 'scgid',
                key: 'scgid',
            },
            {
                title: 'CAUSECODE',
                dataIndex: 'causecode',
                key: 'causecode',
            },
            {
                title: 'DISTCHANNEL',
                dataIndex: 'distchannel',
                key: 'distchannel',
            },
            {
                title: 'CUSTGROUPCODE',
                dataIndex: 'custgroupcode',
                key: 'custgroupcode',
            },
            {
                title: 'ORDERCHANNEL',
                dataIndex: 'orderchannel',
                key: 'orderchannel',
            },
            {
                title: 'CHANNEL_DOCNO',
                dataIndex: 'channelDocno',
                key: 'channelDocno',
            }
        ],
    }
}