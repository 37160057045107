import React, { Component } from 'react'
import { Table } from 'antd'
import { TableCustomStyled } from './styled'
import './style.css?version=20210325'

export class TableCustom extends Component {
    render() {
        const {
            data,
            columns,
            loading,
            height,
            small,
            pagination,
            onChange,
            rowKey,
            rowSelection,
            scroll,
            expandedRowRender,
            isStripedRow,
            isFullContent,

            ...rest
        } = this.props

        return (
            <TableCustomStyled height={height} small={small}>
                <Table
                    {...rest}
                    className={`table-custom ${isStripedRow ? 'table-striped-rows' : ''}`}
                    rowKey={rowKey}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    onChange={onChange}
                    rowSelection={rowSelection}
                    bordered
                    tableLayout="auto"
                    size={small ? 'small' : 'middle'}
                    pagination={pagination}
                    scroll={isFullContent ? { x: 'max-content', ...scroll } : scroll}
                    expandedRowRender={expandedRowRender}
                />
            </TableCustomStyled>
        )
    }
}