import React, { Component } from 'react'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    SelectHero,
} from 'components'
import { CustomerKeyWordDropDown, sortDataColumn } from 'helpers'
import { Row, Col, Input } from 'antd'
import './style.css'

const { Search } = Input

export class ModalCustomerSearch extends Component {
    state = {
        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: '#',
                key: '#',
                render: (value, row) => <ButtonCustom small onClick={() => this.props.onSelected(row)} text="เลือก" />
            },
            {
                title: 'รหัสลูกค้า',
                align: 'center',
                dataIndex: 'customerId',
                key: 'customerId',
                sorter: (a, b) => sortDataColumn(a, b, 'customerId'),
                ellipsis: true,
            },
            {
                title: 'ชื่อลูกค้า',
                align: 'center',
                dataIndex: 'customerNameTH',
                key: 'customerNameTH',
                sorter: (a, b) => sortDataColumn(a, b, 'customerNameTH'),
                ellipsis: true,
            },
            {
                title: 'เบอร์โทรศัพท์',
                align: 'center',
                dataIndex: 'customerPhone',
                key: 'customerPhone',
                sorter: (a, b) => sortDataColumn(a, b, 'customerPhone'),
                ellipsis: true,
            },
            {
                title: 'เลขบัตรประชาชน',
                align: 'center',
                dataIndex: 'customerTaxId',
                key: 'customerTaxId',
                sorter: (a, b) => sortDataColumn(a, b, 'customerTaxId'),
                ellipsis: true,
            },
            {
                title: 'กลุ่มลูกค้า',
                align: 'center',
                dataIndex: 'customerGroup',
                key: 'customerGroup',
                sorter: (a, b) => sortDataColumn(a, b, 'customerGroup'),
                ellipsis: true,
            },
            {
                title: 'อีเมล์',
                align: 'center',
                dataIndex: 'customerEmail',
                key: 'customerEmail',
                sorter: (a, b) => sortDataColumn(a, b, 'customerEmail'),
                ellipsis: true,
            },
        ],
        keyword: '',
        keywordType: CustomerKeyWordDropDown[1].value,
    }

    changeKeyword = (e) => {
        this.setState({ keyword: e.target.value })
    }

    changeKeywordType = (keywordType) => {
        this.setState({ keywordType })
    }

    render() {
        const {
            isShowModal,
            handleCloseModal,
            title,
            width,
            onSearch,
            data,
            loading,
            className,
        } = this.props
        const {
            columns,
            keywordType,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                handleCloseModal={handleCloseModal}
                title={title || 'ค้นหาลูกค้า'}
                width={width || '60%'}
                className={`modal-data ${className}`}
            >
                <Row gutter={8}>
                    <Col sm={8}>
                        <SelectHero
                            label="ค้นหาด้วย"
                            small
                            firstChoose
                            widthDefault='100px'
                            onChange={this.changeKeywordType}
                            options={CustomerKeyWordDropDown}
                            value={keywordType}
                        />
                    </Col>
                    <Col sm={6}>
                        <Search
                            size="small"
                            onSearch={onSearch.bind(this, keywordType)}
                            enterButton />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 511px)"
                            columns={columns}
                            data={data}
                            loading={loading}
                            small
                            rowKey={(row, index) => index}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
