import React, { Component } from 'react'
import { Row, Col } from 'antd'
import Scheduler, { SchedulerData, ViewTypes } from 'react-big-scheduler'
import withDragDropContext from './withDnDContext'
import 'react-big-scheduler/lib/css/style.css'

class Calendar extends Component {
    constructor(props) {
        super(props)
        const { data: { resources, events, date } } = this.props
        const schedulerData = new SchedulerData(date.format('YYYY-MM-DD'), ViewTypes.Day, false, false, {
            startResizable: false,
            endResizable: false,
            movable: false,
            creatable: false,
            displayWeekend: false,
            nonAgendaDayCellHeaderFormat: 'HH:mm',
            dayCellWidth: 60,
            defaultEventBgColor: '#1890ff',
            dayStartFrom: 7,
            dayStopTo: 17,
            dayResourceTableWidth: 160,
            headerEnabled: false,
            agendaMaxEventWidth: 100,
            eventItemLineHeight: 35,
            nonWorkingTimeHeadColor: '#000000a6',
            nonWorkingTimeHeadBgColor: '#ffffff',
            nonWorkingTimeBodyBgColor: '#ffffff',
            resourceName: 'รถ',
        })

        schedulerData.localeMoment.locale('en')
        schedulerData.setResources(resources)
        schedulerData.setEvents(events)

        this.state = { viewModel: schedulerData }
    }

    nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
        return (
            <th key={item.time} className={`header3-text`} style={style}>
                {
                    formattedDateItems.map((formattedItem, index) => (
                        <Row key={index}>
                            <Col sm={12} className="text-center">
                                <div style={{ borderRight: '1px #ccc solid' }}>
                                    {`${formattedItem.substring(0, 2)}:00`}
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div>
                                    {`${formattedItem.substring(0, 2)}:30`}
                                </div>
                            </Col>
                        </Row>
                    ))
                }
            </th>
        )
    }

    eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
        return (
            <div style={{ width: '300px' }}>
                <Row type="flex" align="middle">
                    <Col sm={2}>
                        <div className="status-dot" style={{ backgroundColor: statusColor }} />
                    </Col>
                    <Col sm={22} className="overflow-text">
                        <span className="header2-text" title={title}>{title}</span>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col sm={22} offset={2}>
                        <span className="header2-text">วันที่ {start.format('DD/MM/YYYY')}</span>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col sm={22} offset={2}>
                        <span className="header1-text">{start.format('HH:mm')} - {end.format('HH:mm')}</span>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        const { data } = this.props
        const { viewModel } = this.state
        return (
            <div className="calendar-box">
                <div className="calendar-title font-weight-bold">
                    {data.date.format('DD/MM/YYYY')}
                </div>
                <Scheduler
                    schedulerData={viewModel}
                    nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolver}
                    prevClick={() => { }}
                    nextClick={() => { }}
                    onViewChange={() => { }}
                    onSelectDate={() => { }}
                    toggleExpandFunc={this.toggleExpandFunc}
                    eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
                />
            </div>
        )
    }
}

export default withDragDropContext(Calendar)