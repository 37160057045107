import styled from 'styled-components'

export const DatePickerRangeCustomStyled = styled.div`

    .form-date-picker-range-custom {
        margin-bottom: ${props => props.small ? '3px' : ''};
        
        .label-style {
            font-size: ${props => props.small ? '12px' : ''};
            text-align: ${props => props.labelAlign};
            width: ${props => props.widthLabel ? props.widthLabel : props.small ? '120px' : '150px'};
            color: ${props => props.labelColor};
        }

        .date-picker-range-custom {
            width: ${props => props.label ? `calc(100% - ${props.widthLabel ? props.widthLabel : props.small ? '120px' : '150px'})` : '100%'};
           
            .ant-calendar-picker-input {
                font-size: ${props => props.small ? '12px' : ''};
                border-color: ${props => props.isError ? 'red' : ''};
            }
        }
    }
`
