import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, sumBy } from 'lodash'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { formatNumber, getPermissionPage, isOutletIdAuth, sortDataColumn } from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    CheckboxCustom,
    DatePickerCustom,
    InputCustom,
    SelectCustom,
    InputSearchCustom,
    NumberFormatCustom,
} from 'components/common'
import { Row, Col, Spin, Badge, message } from 'antd'
import { ROUTH_PATH, functionAlias } from 'routes'
import { apiServices, API_PATH } from 'apiServices'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import SaleOrderSearchModal from './SaleOrderSearchModal'
import './style.css'
import queryString from 'query-string'
class ShipmentMaintainContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: 'ใบขาย',
                    dataIndex: 'soNumber',
                    key: 'soNumber',
                    align: 'center',
                    sorter: (a, b) => sortDataColumn(a, b, 'soNumber'),
                },
                {
                    title: 'ที่จัดส่ง',
                    dataIndex: 'shipToName',
                    key: 'shipToName',
                    align: 'center',
                    sorter: (a, b) => sortDataColumn(a, b, 'shipToName'),
                },
                {
                    title: 'รหัสสินค้า',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    align: 'center',
                    sorter: (a, b) => sortDataColumn(a, b, 'productCode'),
                },
                {
                    title: 'สินค้า',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: 'center',
                    width: 250,
                    sorter: (a, b) => sortDataColumn(a, b, 'productName'),
                    render: (props) => (
                        <div className="text-left">
                            {props}
                        </div>
                    )
                },
                {
                    title: 'Item',
                    dataIndex: 'soItem',
                    key: 'soItem',
                    align: 'center',
                    sorter: (a, b) => sortDataColumn(a, b, 'soItem'),
                },
                {
                    title: 'จำนวน',
                    dataIndex: 'stockQty',
                    key: 'stockQty',
                    align: 'center',
                    sorter: (a, b) => sortDataColumn(a, b, 'stockQty'),
                    render: (props) => (
                        <div className="text-right">
                            {formatNumber(props, 3)}
                        </div>
                    )
                },
                {
                    title: 'คงเหลือ',
                    dataIndex: 'remainQty',
                    key: 'remainQty',
                    align: 'center',
                    sorter: (a, b) => sortDataColumn(a, b, 'remainQty'),
                    render: (props) => (
                        <div className="text-right">
                            {formatNumber(props, 3)}
                        </div>
                    )
                },
                {
                    title: 'จำนวนที่ต้องการ',
                    dataIndex: 'qty',
                    key: 'qty',
                    align: 'center',
                    width: 100,
                    sorter: (a, b) => sortDataColumn(a, b, 'qty'),
                    render: (props, row, index) => (
                        <NumberFormatCustom
                            className="text-right"
                            small
                            onBlur={() => this.checkOrderQty(row)}
                            onChange={(e) => this.changeSaleOrderData('qty', Number(`${e.target.value}`.replace(/,/g, '')), index)}
                            value={props}
                            decimalScale={3}
                            disabled={row.shipmentItemId}
                        />
                    ),
                },
                {
                    title: 'หน่วย',
                    dataIndex: 'unit',
                    key: 'unit',
                    align: 'center',
                    sorter: (a, b) => sortDataColumn(a, b, 'unit'),
                },
                {
                    title: 'น้ำหนัก',
                    dataIndex: 'weight',
                    key: 'weight',
                    align: 'center',
                    sorter: (a, b) => sortDataColumn(a, b, 'weight'),
                    render: (props) => (
                        <div className="text-right">
                            {formatNumber(props, 3)}
                        </div>
                    )
                },
            ],
            loading: false,
            isShowModal: false,
            modalType: '',
            saleOrders: [],
            selectedRowKeys: [],
            filter: {
                soNumber: '',
                shipmentDatetime: moment(),
                startTime: '',
                endTime: '',
                flagReserveCar: false,
                remark: '',
                truckId: '',
                zoneId: '',
                outletId: '',
                driverId: '',
            },
            trucks: [],
            zones: [],
            drivers: [],
            times: this.generateTimes(),
            shipmentDetail: {},
            shipmentNumber: get(this.props, 'match.params.shipmentNumber'),
            outletId: get(this.props, 'match.params.outletId'),
            permissionPage: getPermissionPage(functionAlias.shipmentMaintain),
        }
    }

    componentDidMount() {
        const outletId = get(this.props, 'match.params.outletId')
        if (outletId && !isOutletIdAuth(outletId)) {
            this.getAlertAccessDenied()
            return
        }

        this.fetchDataDefault()
    }

    componentDidUpdate(prevProps) {
        const params = this.props.match.params
        const paramsPrev = prevProps.match.params
        if (params.shipmentNumber !== paramsPrev.shipmentNumber || params.outletId !== paramsPrev.outletId) {
            this.setState({
                shipmentNumber: params.shipmentNumber,
                outletId: params.outletId,
            }, () => {
                this.fetchDataDefault()
            })
        }
    }

    getAlertAccessDenied = () => {
        swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
            this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
        })
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })

        const { auth } = this.props
        const { filter } = this.state

        // outlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({
                    outlets,
                    filter: { ...filter, outletId: outlets[0].value },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        const { shipmentNumber, outletId } = this.state
        if (shipmentNumber && outletId) {
            if (!this.state.permissionPage.authDisplay) {
                this.getAlertAccessDenied()
                return
            }

            await this.getShipmentDetail(shipmentNumber, outletId)
        }

        await this.getDeliveryData()

        this.setState({ loading: false })
    }

    getDeliveryData = async () => {
        const { filter: { outletId } } = this.state

        // truck
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALES_SHIPMENT_TRUCK}?ActiveStatus=true&OutletId=${outletId}`).then(res => {
            let trucks = get(res, 'data.results', [])
            if (res.status === 200) {
                trucks = trucks.map(data => (
                    { ...data, label: data.truckName, value: data.truckId }
                ))
                this.setState({ trucks })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลรถได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // zone
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALES_SHIPMENT_ZONE}?ActiveStatus=true&OutletId=${outletId}`).then(res => {
            let zones = get(res, 'data.results', [])
            if (res.status === 200) {
                zones = zones.map(data => (
                    { ...data, label: data.zoneName, value: data.zoneId }
                ))
                this.setState({ zones })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลโซนได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // driver
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALES_SHIPMENT_DRIVER}?ActiveStatus=true&OutletId=${outletId}`).then(res => {
            let drivers = get(res, 'data.results', [])
            if (res.status === 200) {
                drivers = drivers.map(data => (
                    { ...data, label: data.driverName, value: data.driverId }
                ))
                this.setState({ drivers })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลพนักงานขับรถได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    getShipmentDetail = async (shipmentNumber, outletId) => {
        const body = { shipmentNumber, outletId }
        await apiServices.callApi('post', API_PATH.POST_GET_SALES_SHIPMENT, body).then(async (res) => {
            const shipmentDetail = get(res, 'data.results.0')
            if (res.status === 200 && !isEmpty(shipmentDetail)) {
                const selectedRowKeys = shipmentDetail.salesShipmentItems.map((order, index) => (index))
                this.setState({
                    filter: {
                        soNumber: '',
                        shipmentDatetime: moment(shipmentDetail.shipmentDatetime),
                        startTime: moment(shipmentDetail.startTime).format('HH:mm'),
                        endTime: moment(shipmentDetail.endTime).format('HH:mm'),
                        flagReserveCar: shipmentDetail.flagReserveCar,
                        remark: shipmentDetail.remark,
                        truckId: shipmentDetail.truckId,
                        zoneId: shipmentDetail.zoneId,
                        outletId: shipmentDetail.outletId,
                        driverId: shipmentDetail.driverId,
                    },
                    saleOrders: shipmentDetail.salesShipmentItems,
                    shipmentDetail,
                    selectedRowKeys,
                })
            } else {
                swal({
                    title: 'Error',
                    icon: 'error',
                    text: 'ไม่พบข้อมูลการจัดส่งสินค้า',
                    buttons: {
                        create: { text: 'สร้างการจัดส่งสินค้า', value: true },
                        cancel: 'ปิด',
                    },
                }).then((value) => {
                    if (value) {
                        this.props.history.push(ROUTH_PATH.SHIPMENT_CREATE)
                    }
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลการจัดส่งสินค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
    }

    // generate times dropdown 8:00 - 17:30, minute step = 30
    generateTimes = () => {
        const times = []
        for (let hour = 8; hour <= 17; hour++) {
            const hourConv = `${hour}`.padStart(2, '0')
            const time = `${hourConv}:00`
            const timeHalf = `${hourConv}:30`

            times.push({ label: time, value: time })
            times.push({ label: timeHalf, value: timeHalf })
        }
        return times
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    changeFilterOutletId = (val) => {
        const { filter } = this.state
        this.setState({
            filter: {
                ...filter,
                outletId: val,
                zoneId: '',
                driverId: '',
                truckId: '',
            },
            saleOrders: [],
            selectedRowKeys: [],
        }, () => { this.getDeliveryData() })
    }

    changeFilterFlagReserveCar = (val) => {
        // if flagReserveCar is false, will clear remark value
        const { filter } = this.state
        this.setState({
            filter: {
                ...filter,
                flagReserveCar: val,
                remark: val ? filter.remark : '',
            }
        })
    }

    openModal = (modalType) => {
        this.setState({ isShowModal: true, modalType })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '' })
    }

    changeSaleOrderData = (key, value, index) => {
        this.setState({
            saleOrders: this.state.saleOrders.map((saleOrder, i) => (i === index ? { ...saleOrder, [key]: value } : saleOrder)),
        })
    }

    validateSaleOrders = (rows, sources) => {
        let { saleOrders } = this.state
        let result = { isError: false, message: '' }
        saleOrders = saleOrders.filter(item => !item.shipmentItemId)
        let remainZero = 0;
        for (const row of rows) {
            if (row.remainQty <= 0) {
                remainZero++;
            }

            if (saleOrders.find(saleOrder => saleOrder.soNumber === row.soNumber && saleOrder.soItem === row.soItem)) {
                result = { isError: true, message: `ไม่สามารถเพิ่มใบสั่งขายซ้ำหมายเลข ${row.soNumber}, item: ${row.soItem} ลงในตารางได้` }
                break
            }
        }

        if (rows.length === remainZero) {
            result = { isError: true, message: `หมายเลข ${rows[0].soNumber} ไม่มีจำนวนคงเหลือ` }
        }
        const groupedSources = this.groupBy(sources);
        const groupedRows = this.groupBy(rows);
        for (const groupedRow of groupedRows){
            const groupedSource = groupedSources.find(i => i.group === groupedRow.group);
            const allGoods = groupedRow.data.filter(i => !i.freeGoodsFlag && !i.productExcPoFlag).length === groupedSource.data.filter(i => !i.freeGoodsFlag && !i.productExcPoFlag).length && !!groupedSource.data.find((saleOrder, index) => saleOrder.freeGoodsFlag && (!groupedRow.data.find(a => a.soItem === saleOrder.soItem)));
            if (allGoods){
                return { isError: true, message: `หมายเลข ${groupedRow.group} กรุณาเลือกของแถมร่วมกับของขาย` }
            }
        }
        return result
    }

    groupBy = (myArray) => {
        var groups = {};
        for (var i = 0; i < myArray.length; i++) {
            var groupName = myArray[i].soNumber;
            if (!groups[groupName]) {
                groups[groupName] = [];
            }
            groups[groupName].push(myArray[i]);
        }
        myArray = [];
        for (var groupName in groups) {
            myArray.push({ group: groupName, data: groups[groupName] });
        }
        return myArray;
    }

    onSelectedSaleOrders = (rows, sources) => {
        if (isEmpty(rows)) {
            message.error('กรุณาเลือกใบสั่งขาย')
            return
        }

        const valid = this.validateSaleOrders(rows, sources)
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.closeModal()
        const { saleOrders } = this.state
        const newSaleOrders = [...saleOrders]
        rows = rows.filter(row => row.remainQty > 0);

        rows = rows.map(row => ({
            ...row,
            stockQty: row.qty,
            weight: row.netWeight,
            qty: row.remainQty,
        }))

        const data = [...newSaleOrders, ...rows]
        const selectedRowKeys = data.map((order, index) => (index))
        this.setState({
            saleOrders: data,
            selectedRowKeys,
        })
    }

    checkOrderQty = (row) => {
        if (row.qty > row.remainQty) {
            swal('Warning', 'จำนวนที่ต้องการเกินจำนวนคงเหลือ', 'warning')
            return
        }
    }

    getSelectedRows = () => {
        const { saleOrders, selectedRowKeys } = this.state
        return saleOrders.filter((saleOrder, index) => selectedRowKeys.includes(index))
    }

    validateData = () => {
        const { filter, saleOrders, selectedRowKeys } = this.state
        const selectedRows = this.getSelectedRows()

        let result = { isError: false, message: '' }

        if (!filter.shipmentDatetime) return { isError: true, message: 'กรุณาระบุวันที่จัดส่ง' }
        if (!filter.startTime) return { isError: true, message: 'กรุณาระบุเวลาเริ่มต้น' }
        if (!filter.endTime) return { isError: true, message: 'กรุณาระบุเวลาสิ้นสุด' }
        if (filter.startTime === filter.endTime) return { isError: true, message: 'รูปแบบเวลาจัดส่งไม่ถูกต้อง' }
        if (!filter.truckId) return { isError: true, message: 'กรุณาระบุรถ' }
        if (!filter.zoneId) return { isError: true, message: 'กรุณาระบุเส้นทาง' }
        if (!filter.outletId) return { isError: true, message: 'กรุณาระบุร้าน' }
        if (!filter.driverId) return { isError: true, message: 'กรุณาระบุพนักงานขับรถ' }

        const formatTime = 'HH:mm'
        if ((moment(filter.endTime, formatTime)).isBefore(moment(filter.startTime, formatTime))) return { isError: true, message: 'รูปแบบเวลาจัดส่งไม่ถูกต้อง' }

        for (const row of selectedRows) {
            if (!row.qty || row.qty <= 0) {
                result = { isError: true, message: 'กรุณาระบุจำนวนที่ต้องการ' }
                break
            }

            if (!row.shipmentItemId && row.qty > row.remainQty) {
                result = { isError: true, message: 'จำนวนที่ต้องการเกินจำนวนคงเหลือ' }
                break
            }
        }
        // free goods can't be alonely submited.
        const notAlone = selectedRows.filter(i => !i.freeGoodsFlag);
        // if all goods ceate do then free goods alway have to gi.
        const allGoods = selectedRows.filter(i => i.remainQty == i.qty && !i.freeGoodsFlag && !i.productExcPoFlag).length === saleOrders.filter(i => !i.freeGoodsFlag && !i.productExcPoFlag).length && !!saleOrders.find((saleOrder, index) => saleOrder.freeGoodsFlag && (!selectedRowKeys.includes(index) || saleOrder.remainQty != saleOrder.qty));
        if (notAlone.length === 0 || allGoods) {
            return { isError: true, message: 'กรุณาเลือกของแถมร่วมกับของขาย' }
        }
        // service and free goods can't be joinly submited.
        const notJoin = !!selectedRows.find(i => i.freeGoodsFlag) && !!selectedRows.find(i => i.productExcPoFlag) && !!!selectedRows.find(i => !i.freeGoodsFlag && !i.productExcPoFlag);
        if (notJoin) {
            return { isError: true, message: 'กรุณาเลือกของแถมร่วมกับของขายที่เป็นสินค้า' }
        }

        return result
    }

    getBody = () => {
        const { auth: { userId } } = this.props
        const { filter, shipmentDetail } = this.state
        const selectedRows = this.getSelectedRows()
        const formatDateAPI = 'YYYY-MM-DD HH:mm:ss'
        const now = moment().format(formatDateAPI)
        const isEditMode = !isEmpty(shipmentDetail)

        const salesShipmentItems = selectedRows.map(saleOrder => ({
            shipmentItemId: saleOrder.shipmentItemId || 0,
            outletId: saleOrder.outletId,
            shipmentNumber: isEditMode ? shipmentDetail.shipmentNumber : '',
            soNumber: saleOrder.soNumber,
            soItem: saleOrder.soItem,
            doNumber: saleOrder.doNumber || '',
            doItem: saleOrder.doItem || 0,
            shipToId: saleOrder.shipToId,
            shipToName: saleOrder.shipToName,
            productCode: saleOrder.productCode,
            productName: saleOrder.productName,
            qty: saleOrder.qty,
            shippingPoint: saleOrder.shippingPoint || '',
            unit: saleOrder.unit,
            weight: saleOrder.weight,
            createdDate: saleOrder.createdDate || now,
            createdUser: saleOrder.createdUser || userId,
            updatedDate: now,
            updatedUser: userId,
        }))

        const startTimeArray = filter.startTime.split(':')
        const endTimeArray = filter.endTime.split(':')

        return {
            shipmentId: isEditMode ? shipmentDetail.shipmentId : 0,
            outletId: filter.outletId,
            shipmentNumber: shipmentDetail.shipmentNumber || '',
            shipmentDatetime: moment(filter.shipmentDatetime).format(formatDateAPI),
            startTime: moment(filter.shipmentDatetime).set({ h: startTimeArray[0], m: startTimeArray[1], s: 0 }).format(formatDateAPI),
            endTime: moment(filter.shipmentDatetime).set({ h: endTimeArray[0], m: endTimeArray[1], s: 0 }).format(formatDateAPI),
            // startTime: moment(moment(filter.shipmentDatetime).format('DD/MM/YYYY') + ' 09:30', 'DD/MM/YYYY HH:mm'),
            // endTime: moment(moment(filter.shipmentDatetime).format('DD/MM/YYYY') + ' 10:30', 'DD/MM/YYYY HH:mm'),
            saleChannelCode: isEditMode ? shipmentDetail.saleChannelCode : '',
            flagReserveCar: filter.flagReserveCar,
            remark: filter.remark,
            weight: this.calulateTotalWeight(),
            actualStartDate: shipmentDetail.actualStartDate || null,
            actualEndDate: shipmentDetail.actualStartDate || null,
            createdDate: shipmentDetail.createdDate || now,
            createdUser: isEditMode ? shipmentDetail.createdUser : userId,
            updatedDate: now,
            updatedUser: userId,
            truckId: filter.truckId,
            driverId: filter.driverId,
            zoneId: filter.zoneId,
            statusShipmentId: isEditMode ? shipmentDetail.statusShipmentId : '',
            flagDeleted: isEditMode ? shipmentDetail.flagDeleted : false,
            salesShipmentItems,
        }
    }

    createShipment = async () => {
        const valid = this.validateData()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody()
        //console.log('body', JSON.stringify(body))
        //return
        await apiServices.callApi('post', API_PATH.POST_CREATE_SALES_SHIPMENT, body).then(res => {
            const shipmentNumber = get(res, 'data.results.0.shipmentNumber')
            if (res.status === 200) {
                swal({
                    title: 'Success',
                    icon: 'success',
                    text: `บันทึกเอกสารจัดส่งสินค้า ${shipmentNumber} สำเร็จ`,
                    buttons: {
                        create: {
                            text: `พิมพ์`,
                            value: {
                                "DocumentNo": shipmentNumber,
                                "OutletId": body.outletId,
                                "userid": this.props.auth.userId
                            }
                        },
                        cancel: 'ปิด',
                    },
                }).then(async (value) => {
                    if (value) {
                        // const reportRDF = await apiServices.callApi('get', `${API_PATH.GET_REPORT_REQUISITION}?${queryString.stringify(value)}`)
                        // // console.log(reportRDF)
                        // // const urlOpen = window.URL.createObjectURL(reportRDF.data)
                        const url = `${API_PATH.GET_REPORT_SHIPMENT}?${queryString.stringify(value)}`
                        window.open(url)
                    }
                    this.props.history.push(`${ROUTH_PATH.SHIPMENT_EDIT_LINK}/${shipmentNumber}/${this.state.filter.outletId}`)
                })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการสร้างข้อมูลจัดส่งสินค้า กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    updateShipment = async () => {
        const valid = this.validateData()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody()
        // console.log('body', body)
        // return
        await apiServices.callApi('put', API_PATH.PUT_UPDATE_SALES_SHIPMENT, body).then(res => {
            if (res.status === 200) {
                swal('Success', get(res, 'data.message'), 'success').then(() => {
                    this.fetchDataDefault()
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการบันทึกข้อมูลจัดส่งสินค้า กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    searchSaleOrder = async () => {
        const { saleOrders, filter } = this.state
        const { soNumber, outletId } = filter
        if (!soNumber) {
            message.error('กรุณากรอกเลขใบสั่งขาย')
        }
        const withOutStatus = ["BLOCK"];
        const documentStatuses = ["APPROVE", "PAYMENT", "DELIVERY"];
        const documentTypeCodes = ["ZOCS", "ZOCR", "ZOPS"];
        const body = {
            soNumber,
            outletId,
            documentTypeCodes,
            documentStatuses,
            withOutDocumentStatus: withOutStatus,
            statusItemsWithOutReject: true,
        }

        this.setState({ loading: true })
        await apiServices.callApi('post', API_PATH.POST_GET_SALES_SO_ITEMS, body).then(res => {
            const data = get(res, 'data.results', [])
            if (res.status === 200 && !isEmpty(data)) {
                if (saleOrders.find(saleOrder => saleOrder.soNumber === soNumber)) {
                    message.error(`ไม่สามารถเพิ่มใบสั่งขายซ้ำหมายเลข ${soNumber} ลงในตารางได้`)
                } else {
                    this.onSelectedSaleOrders(data, data)
                    this.changeFilter('soNumber', '')
                }
            } else {
                message.error(`ไม่พบข้อมูลใบสั่งขาย: ${soNumber}`)
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการดึงข้อมูลการขาย กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    calulateTotalWeight = () => {
        const selectedRows = this.getSelectedRows()

        if (isEmpty(selectedRows)) return 0
        return sumBy(selectedRows, (saleOrder) => Number(saleOrder.qty) * saleOrder.weight)
    }

    onSelectedRows = (selectedRowKeys) => {
        this.setState({ selectedRowKeys })
    }

    render() {
        const {
            columns,
            saleOrders,
            loading,
            filter,
            trucks,
            zones,
            outlets,
            drivers,
            times,
            isShowModal,
            modalType,
            selectedRowKeys,
            shipmentDetail,
            permissionPage,
            shipmentNumber,
        } = this.state

        const netWeight = this.calulateTotalWeight()
        const isEditMode = !isEmpty(shipmentDetail)
        const rowSelection = {
            onChange: this.onSelectedRows,
            selectedRowKeys,
            getCheckboxProps: record => ({
                disabled: !!record.shipmentItemId
            })
        }

        return (
            <div className="shipment-maintain-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text={`การจัดส่งสินค้า : เลขที่ ${isEditMode ? shipmentNumber : '(Auto)'}`}
                        icon="car"
                        nodeRight={
                            <div className="search-sale-order-box">
                                <div className="text-search">ค้นหาใบสั่งขาย</div>
                                <InputSearchCustom
                                    small
                                    value={filter.soNumber}
                                    onChange={(e) => this.changeFilter('soNumber', e.target.value)}
                                    onClickSearch={() => this.openModal('saleOrder')}
                                    onSubmit={this.searchSaleOrder}
                                />
                            </div>
                        }
                    />
                    <div className="item-count-box">
                        รายการที่เลือก  <Badge
                            showZero
                            style={{ background: '#777', marginLeft: '7px' }}
                            count={selectedRowKeys.length}
                        />
                    </div>
                    <div className="middle-content">
                        <Row gutter={8}>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="วันที่จัดส่ง *"
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('shipmentDatetime', val)}
                                    value={filter.shipmentDatetime}
                                />
                            </Col>
                            <Col sm={7}>
                                <SelectCustom
                                    label="เวลาเริ่มต้น *"
                                    options={times}
                                    small
                                    onChange={(val) => this.changeFilter('startTime', val)}
                                    value={filter.startTime || undefined}
                                    placeholderDefault
                                />
                            </Col>
                            <Col sm={7}>
                                <SelectCustom
                                    label="เวลาสิ้นสุด *"
                                    options={times}
                                    small
                                    onChange={(val) => this.changeFilter('endTime', val)}
                                    value={filter.endTime || undefined}
                                    placeholderDefault
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={7}>
                                <SelectCustom
                                    label="ร้าน *"
                                    options={outlets}
                                    small
                                    onChange={(val) => this.changeFilterOutletId(val)}
                                    value={filter.outletId}
                                    disabled={isEditMode}
                                />
                            </Col>
                            <Col sm={7}>
                                <SelectCustom
                                    label="พนักงานขับรถ *"
                                    options={drivers}
                                    small
                                    onChange={(val) => this.changeFilter('driverId', val)}
                                    value={filter.driverId || undefined}
                                    placeholderDefault
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={7}>
                                <SelectCustom
                                    label="รถ *"
                                    options={trucks}
                                    small
                                    onChange={(val) => this.changeFilter('truckId', val)}
                                    value={filter.truckId || undefined}
                                    placeholderDefault
                                />
                            </Col>
                            <Col sm={7}>
                                <SelectCustom
                                    label="เส้นทาง *"
                                    options={zones}
                                    small
                                    onChange={(val) => this.changeFilter('zoneId', val)}
                                    value={filter.zoneId || undefined}
                                    placeholderDefault
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={7}>
                                <CheckboxCustom
                                    text="จองรถสำหรับช่องทางอื่น"
                                    small
                                    onChange={(e) => this.changeFilterFlagReserveCar(e.target.checked)}
                                    checked={filter.flagReserveCar}
                                />
                            </Col>
                            <Col sm={14}>
                                <InputCustom
                                    label="หมายเหตุ"
                                    small
                                    onChange={(e) => this.changeFilter('remark', e.target.value)}
                                    value={filter.remark}
                                    disabled={!filter.flagReserveCar}
                                />
                            </Col>
                        </Row>
                        <div className="table-layout" style={{ paddingTop: '30px' }}>
                            <TableCustom
                                columns={columns}
                                data={saleOrders}
                                isFullContent
                                rowKey={(row, index) => index}
                                small
                                pagination={false}
                                rowSelection={rowSelection}
                            />
                        </div>
                        <Row gutter={8} style={{ paddingTop: '30px' }}>
                            <Col sm={6} offset={18}>
                                <InputCustom
                                    className="text-right"
                                    label="น้ำหนักรวมทั้งหมด (กก.)"
                                    small
                                    labelAlign="right"
                                    widthLabel="140px"
                                    value={formatNumber(netWeight, 3)}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="bottom-content">
                        <div className="left-zone">
                            <div className="button">
                                <Link to={ROUTH_PATH.HOME} className="footer-button">
                                    <ButtonCustom
                                        text="ยกเลิก"
                                        icon="close"
                                        type="danger"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="right-zone">
                            <div className="button">
                                <ButtonCustom
                                    text="บันทึก"
                                    icon="save"
                                    onClick={isEditMode ? this.updateShipment : this.createShipment}
                                    disabled={isEmpty(selectedRowKeys) || (isEditMode ? !permissionPage.authUpdate : !permissionPage.authCreate)}
                                />
                            </div>
                        </div>
                    </div>
                    {filter.outletId && <SaleOrderSearchModal
                        isShowModal={isShowModal && modalType === 'saleOrder'}
                        onCloseModal={this.closeModal}
                        onSelected={this.onSelectedSaleOrders}
                        outletId={filter.outletId}
                    />}
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentMaintainContainer)