import styled from 'styled-components'

export const MultiSelectedCustomStyled = styled.div`

    .label-style {
        text-align: ${props => props.right ? 'right' : ''};
        font-size: ${props => props.small ? '12px' : ''};
        width: ${props => props.small ? props.widthSmall ? props.widthSmall : '120px' : props.widthDefault ? props.widthDefault : '150px'};
    }
    .ant-select-selection--multiple .ant-select-selection__choice__content {
        font-size: ${props => props.small ? '12px' : ''};
    }
`