import React, { Component } from 'react'
import { Checkbox } from 'antd'
import { CheckboxCustomStyled } from './styled'

export class CheckboxCustom extends Component {
    render() {
        const {
            checked,
            className,
            onChange,
            options,
            name,
            disabled,
            isError,
            errorMsg,
            text,
            small,
            label,
            labelAlign,
            widthLabel,
            labelColor,
            input = {},
            meta = {},

            // rest parameters
            ...rest
        } = this.props
        const { touched, error } = meta

        // touched and error from redux form
        // error and errorMsg from props
        const isErrorForm = (touched || isError) && (error || errorMsg)
        return (
            <CheckboxCustomStyled
                isError={isErrorForm}
                small={small}
                labelColor={labelColor}
                label={label}
                widthLabel={widthLabel}
                labelAlign={labelAlign}
            >
                <div className="input-form form-checkbox-custom">
                    <div className="input-zone-label">
                        {
                            label && <div className="label-style">
                                {label}
                            </div>
                        }
                        {
                            <Checkbox
                                {...rest}
                                className={`checkbox-custom ${className || ''}`}
                                onChange={e => {
                                    onChange && onChange(e)
                                    input.onChange && input.onChange(e)
                                }}
                                checked={input.value || checked}
                                name={name}
                                disabled={disabled}
                            >
                                <span className="text-label">{text}</span>
                            </Checkbox>
                        }
                    </div>
                    {
                        isErrorForm && <div className="error-message-bottom">
                            {error || errorMsg}
                        </div>
                    }
                </div>
            </CheckboxCustomStyled>
        )
    }
}
