import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, map } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import FileSaver from 'file-saver'
import {
    formatNumber,
    getPermissionPage,
} from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    DatePickerCustom,
    SelectCustom,
    InputCustom
} from 'components/common'
import { Row, Col, Spin } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { ROUTH_PATH, functionAlias } from 'routes'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import './style.css'

const formatDateAPI = 'YYYY-MM-DD'
const widthLabel = '100px'

class ReportReceivestockCompareChampContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: 'ร้าน',
                    dataIndex: 'outletId',
                    key: 'outletId',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'เลขที่เอกสาร',
                    dataIndex: 'documentNo',
                    key: 'documentNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'วันที่',
                    dataIndex: 'documentDate',
                    key: 'documentDate',
                    align: 'center',
                    render: (props) => (
                        props && <div className="text-left">
                            {moment(props).format('YYYY-MM-DD')}
                        </div>
                    ),
                },
                {
                    title: 'เลขที่ใบจ่าย',
                    dataIndex: 'dnNo',
                    key: 'dnNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'รหัส',
                    dataIndex: 'productCode',
                    key: 'productCode',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'ฺBarcode',
                    dataIndex: 'barcode',
                    key: 'barcode',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'สินค้า',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'จำนวน',
                    dataIndex: 'qty',
                    key: 'qty',
                    align: 'center',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 3)}</div>
                    ),
                },
                {
                    title: 'หน่วย',
                    dataIndex: 'unit',
                    key: 'unit',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },

            ],
            loading: false,
            documents: [],
            outlets: [],
            documentStatuses: [
                { label: 'Complete - สร้างเอกสารสำเร็จ', value: 'S' },
                { label: 'Error - ไม่สามารถสร้างเอกสารได้', value: 'E' },
            ],
            filter: {
                docDateFrom: moment(),
                docDateTo: moment(),
                outletIds: [],
                docNumber: '',
            },
            permissionPage: getPermissionPage(functionAlias.reportResivestockCompareChamp),
        }
    }

    componentDidMount() {
        console.log('this.state.permissionPage', this.state.permissionPage);
        if (!this.state.permissionPage.authDisplay) {
            swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
                this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
            })
            return
        }
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })

        const { auth } = this.props

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({ outlets })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (filter.docDateTo && filter.docDateFrom && (filter.docDateTo).isBefore(filter.docDateFrom)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }
        return { isError: false }
    }

    getBody = (filter) => {
        const { outlets } = this.state
        return {
            docDateFrom: filter.docDateFrom && filter.docDateFrom.startOf('day').format(formatDateAPI),
            docDateTo: filter.docDateTo && filter.docDateTo.endOf('day').format(formatDateAPI),
            outletIds: isEmpty(filter.outletIds) ? map(outlets, 'outletId') : filter.outletIds,
            docNumber: filter.docNumber,
        }
    }

    searchDocument = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        // console.log('body',body);
        
        await apiServices.callApi('post', API_PATH.POST_GET_RECEIVE_FROM_PURCHASING_OF_CHAMP, body).then(res => {
            let documents = get(res, 'data.results', [])
            if (res.status === 200) {
                this.setState({ documents })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    // exportDocument = async () => {
    //     const { filter } = this.state
    //     const valid = this.validateFilter()
    //     if (valid.isError) {
    //         swal('Warning', valid.message, 'warning')
    //         return
    //     }

    //     this.setState({ loading: true })
    //     const body = this.getBody(filter)
    //     await apiServices.callApi('post', API_PATH.POST_EXPORT_INTERFACE_S4_LOG, body, { responseType: 'arraybuffer' }).then(res => {
    //         if (res.status === 200) {
    //             const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    //             FileSaver.saveAs(blob, `ReportS4InterfaceLogFile-${moment().format('DD-MM-YYYY')}.xlsx`)
    //         } else {
    //             throw res
    //         }
    //     }).catch(error => {
    //         const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
    //         swal('Error', message, 'error')
    //     }).finally(() => {
    //         this.setState({ loading: false })
    //     })
    // }

    render() {
        const {
            columns,
            outlets,
            loading,
            filter,
            documents,
            permissionPage,
            documentStatuses,
        } = this.state

        return (
            <div className="report-s4-interface-log-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="รายงานรับสินค้าจากการซื้อเทียบกับCHAMP"
                        icon="file"
                    />
                    <div className="middle-content">
                        <Row gutter={15}>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="วันที่เอกสาร"
                                    widthLabel={widthLabel}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('docDateFrom', val)}
                                    value={filter.docDateFrom}
                                />
                            </Col>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="ถึงวันที่เอกสาร"
                                    widthLabel={widthLabel}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('docDateTo', val)}
                                    value={filter.docDateTo}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={7}>
                                <SelectCustom
                                    label="ร้านค้า"
                                    widthLabel={widthLabel}
                                    options={outlets}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    small
                                    onChange={(val) => this.changeFilter('outletIds', val)}
                                    value={filter.outletIds}
                                    placeholder='ทั้งหมด'
                                />
                            </Col>
                            <Col sm={7}>
                                <InputCustom
                                    label="เลขที่ใบจ่าย"
                                    name="docNumber"
                                    small
                                    widthLabel='100px'
                                    value={filter.docNumber}
                                    onChange={(e) => this.changeFilter('docNumber', e.target.value)}
                                />
                                {/* <SelectCustom
                                    label="สถานะเอกสาร"
                                    widthLabel={widthLabel}
                                    options={documentStatuses}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    small
                                    onChange={(val) => this.changeFilter('documentStatuses', val)}
                                    value={filter.documentStatuses}
                                    placeholder='ทั้งหมด'
                                /> */}
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} className="search-box text-right">
                                <div className="action-box">
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.searchDocument}
                                            small
                                            icon="search"
                                            text="ค้นหา"
                                            disabled={!permissionPage.authCreate}
                                        />
                                    </div>
                                    <div className="button">
                                        {/* <ButtonCustom
                                            onClick={this.exportDocument}
                                            small
                                            icon="file-excel"
                                            green
                                            text="Export Excel"
                                            disabled={isEmpty(documents) || !permissionPage.authExport}
                                        /> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                data={documents}
                                isFullContent
                                rowKey={(row, index) => index}
                                small
                                pagination={{ pageSize: 30 }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportReceivestockCompareChampContainer)