import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.css'
import { authData } from 'actions/actionsCreator';
import { Row, Col, Spin } from "antd"
import { InputSearchCustom, SelectCustom } from 'components/common'
import { TitleHead, InputHero, TableCustom, DatePickerCustom, ButtonCustom, ModalCustom, TextAreaCustom, CheckBoxCustom } from "components"

import {
    NumberFormatCustom,
} from 'components/common'

import CustomerDetailFormRedux from '../../../../components/feature/CustomerDetailFormRedux'
import DeliveryOrderSearchModal from '../../../../components/feature/DeliveryOrderSearchModal'
import { apiServices, API_PATH } from "apiServices"
import swal from '@sweetalert/with-react'
import { isEmpty, get } from 'lodash'
import moment from 'moment'
import { functionAlias } from 'routes'
import { getPermissionPage } from 'helpers'

class GoodsIssueContainer extends Component {
    state = {
        loading: false,
        widthDefault: '100%',
        isShipmentModal: false,
        isDOModal: false,
        isReprocess: true,
        initialValues: {},
        submitViewErrorCustomer: false,
        dateFormat: 'DD/MM/YYYY',
        dataList: [],
        columns: [
            {
                title: '#',
                dataIndex: 'number',
                key: 'number',
                align: 'center',
                render: (props, row, index) => (
                    <div>{props || index + 1}</div>
                ),
                width: 30,
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
                align: 'center',
                width: 80,
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                align: 'center',
                width: 150,
            },
            {
                title: 'หน่วยนับ',
                dataIndex: 'doUnit',
                key: 'doUnit',
                align: 'center',
                width: 50,
            },
            {
                title: 'จำนวนสั่งซื้อ',
                dataIndex: 'doQty',
                key: 'doQty',
                align: 'center',
                width: 80,
            },
            {
                title: 'ของแถม',
                dataIndex: 'freeGoodsFlag',
                key: 'freeGoodsFlag',
                align: 'center',
                width: 40,
                render: (value, row, index) => {
                    return <CheckBoxCustom checked={value} disabled={true} />
                }
            },
            {
                title: 'ที่จัดเก็บ',
                dataIndex: 'slocId',
                key: 'slocId',
                align: 'center',
                width: 80,
            },
            {
                title: 'จำนวนที่ส่งครั้งนี้',
                dataIndex: 'giQty',
                key: 'giQty',
                align: 'center',
                width: 50,
                render: (value, row, index) => {
                    //return <InputHero type='number' disabled={row.salesDoHeadModel.giStatus || row.salesDoHeadModel.documentStatus.toUpperCase() == "CANCEL"} value={row.salesDoHeadModel.giStatus ? row.doQty : value} onChange={this.handleInputChangeGIQty.bind(this, index, row)} />
                    return <NumberFormatCustom
                        className="text-right"
                        small
                        onChange={this.handleInputChangeGIQty.bind(this, index, row)}
                        value={row.salesDoHeadModel.giStatus ? row.doQty : value}
                        decimalScale={3}
                        disabled={row.salesDoHeadModel.giStatus || row.salesDoHeadModel.documentStatus.toUpperCase() == "CANCEL"}
                    />
                }
            },
        ],

        doNumber: '',
        soNumber: '',
        documentDate: null,
        shipToName: '',
        remark: '',
        address: '',
        salesPartnerFunction: {},
        isDisabled: false,

        outlets: [],
        outletSelected: '',
        permissionPage: getPermissionPage(functionAlias.inventoryGI),
    }

    componentDidMount() {
        this.fatchData();
    }

    fatchData = async () => {
        const { auth } = this.props
        let paramOutlet = {
            outletIds: auth.arrayOutlet,
        };

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramOutlet).then(res => {
            if (res.status === 200) {
                let outlets = res.data.results;
                outlets = outlets.map(data => ({
                    ...data,
                    value: data.outletId,
                    label: `${data.outletId} - ${data.outletName}`,
                }))

                this.setState({
                    outlets,
                    outletSelected: outlets[0].value,
                });
            }

        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });
    }


    changeFormatDate = (value) => {
        if (value) {
            var d = new Date(value),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('/');
        }
        return value;
    }

    handleShowShipmentModal = () => {
        const { initialValues } = this.state
        //console.log('initialValues ==> ', initialValues);
        this.setState({ isShipmentModal: true });
    }

    handleCloseShipmentModal = () => {
        this.setState({ isShipmentModal: false });
    }

    handleClickOkShipmentModal = () => {
        const { salesPartnerFunction } = this.state
        const { CustomerReduxForm: { values, syncErrors } } = this.props;
        let address = (values.customerNameTH || '') + ' ' +
            (values.customerAddress || '') + ' ' +
            (values.customerSubDistrict || '') + ' ' +
            (values.customerDistrict || '') + ' ' +
            (values.customerProvinceName || '') + ' ' +
            (values.customerPostalCode || '');

        let salesPartnerFunctionModel = {
            ...salesPartnerFunction,
            title: values.customerTitle,
            customerName: values.customerNameTH,
            customerType: values.customerType,
            taxId: values.customerTaxId,
            taxBranch: values.customerTaxBranch,
            address: values.customerAddress,
            outletId: values.customerOutletId,
            province: values.customerProvinceName,
            district: values.customerDistrict,
            subDistrict: values.customerSubDistrict,
            postalCode: values.customerPostalCode,
            country: values.customerCountry,
            phone: values.customerPhone,
        };

        this.setState({ salesPartnerFunction: salesPartnerFunctionModel, address, isShipmentModal: false });
    }

    handleClickDOSearch = () => {
        this.setState({ isDOModal: true });
    }

    handleClickCloseDOModal = () => {
        this.setState({ isDOModal: false });
    }

    handleClickSelectedDOModal = (selected) => {
        this.setState({ isDOModal: false, doNumber: selected.doNumber }, () => this.handleClickSearch());
    }

    handleChangeDONumber = (val) => {
        this.setState({ doNumber: val.target.value });
    }

    handleKeydownDONumber = (val) => {
        this.handleClickSearch();
    }

    handleChangeShipToName = (val) => {
        this.setState({ shipToName: val.target.value });
    }

    handleChangeDocumentDate = (val) => {
        this.setState({ documentDate: val });
    }

    handleChangeRemark = (val) => {
        this.setState({ remark: val.target.value });
    }

    handleChangeOutlet = (val) => {
        this.setState({ outletSelected: val });
    }

    handleClickSearch = async () => {
        const { doNumber, outletSelected } = this.state
        if (doNumber != '') {
            this.setState({ loading: true });
            let params = {
                doNumber: doNumber,
                outletId: outletSelected
            }

            await apiServices.callApi('post', API_PATH.GET_SALES_DO_ITEM, params).then(res => {
                if (res.status === 200) {
                    let dataList = res.data.results.map((data, i) => (
                        {
                            ...data,
                            key: i
                        }
                    ));
                    if (dataList.length > 0) {
                        let address = (dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.customerName || '') + ' ' +
                            (dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.address || '') + ' ' +
                            (dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.subDistrict || '') + ' ' +
                            (dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.district || '') + ' ' +
                            (dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.province || '') + ' ' +
                            (dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.postalCode || '');

                        let initialValues = {
                            customerTitle: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.title,
                            customerNameTH: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.customerName,
                            customerType: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.customerType,
                            customerTaxId: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.taxId,
                            customerTaxBranch: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.taxBranch,
                            customerAddress: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.address,
                            customerOutletId: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.outletId,
                            customerProvince: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.province,
                            customerDistrict: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.district,
                            customerSubDistrict: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.subDistrict,
                            customerPostalCode: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.postalCode,
                            customerCountry: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.country,
                            customerPhone: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel.phone
                        };
                        this.setState({
                            shipToName: dataList[0].salesDoHeadModel.shipToName,
                            soNumber: dataList[0].salesDoHeadModel.soNumber,
                            documentDate: dataList[0].salesDoHeadModel.giDate != null ? moment(dataList[0].salesDoHeadModel.giDate) : moment(),
                            remark: dataList[0].salesDoHeadModel.remark,
                            address: address,
                            salesPartnerFunction: dataList[0].salesDoHeadModel.salesSOPartnerFunctionModel,
                            initialValues: initialValues,
                            isDisabled: dataList[0].salesDoHeadModel.giStatus || dataList[0].salesDoHeadModel.documentStatus.toUpperCase() == "CANCEL",
                            dataList,
                            isReprocess: !dataList[0].salesDoHeadModel.isReprocess,
                        });
                    }
                }

            }).catch(error => {
                const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
                swal('Error', message, 'error')
            });

            this.setState({ loading: false });
        } else {
            swal('Error', 'กรุณากรอกเลขที่ใบส่งสินค้า', 'error')
        }
    }

    handleClickSaveDO = async () => {
        await this.setState({ loading: true, isDisabled: true });
        const { auth } = this.props
        const { dataList, doNumber, soNumber, outletSelected, documentDate, shipToName, remark, salesPartnerFunction } = this.state
        let valid = await this.validateDO();
        if (valid.Error) {
            swal('Error', valid.Message, 'error')
            this.setState({ loading: false, isDisabled: false });
            return;
        }

        const params = {
            doNumber: doNumber,
            outletId: outletSelected,
            soNumber: soNumber,
            shipToName: shipToName,
            documentDate: documentDate,
            remark: remark,
            updatedUser: auth.userId,
            giStatus: true,
            isDelayed: true,
            salesSOPartnerFunctionModel: salesPartnerFunction,
            salesDoItemModels: dataList
        };

        await apiServices.callApi('post', API_PATH.POST_UPDATE_DO_FOR_GI, params).then(res => {
            if (res.status === 200) {
                this.openModalUpdatedSuccess(get(res, 'data', {}))
                this.handleClickSearch();
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => { this.setState({ loading: false, isDisabled: false }) })
    }

    validateDO = async () => {
        const { auth } = this.props
        const { dataList, doNumber, soNumber, outletSelected, documentDate, shipToName, remark, salesPartnerFunction } = this.state

        if (dataList.length === 0) {
            return { Error: true, Message: 'กรุณาค้นหาใบส่งสินค้า' };
        }
        // checking permission that having sloc
        for (const i of dataList) {
            if (!auth.arrayStorageLocationIdMovement.includes(i.slocId) && i.giQty > 0 && !isEmpty(i.slocId) && !i.freeGoodsFlag) {
                return { Error: true, Message: `ท่านไม่มีสิทธิ์ตัดจ่ายในที่จัดเก็บ ${i.slocId} ของเอกสารใบส่งสินค้า ${i.doNumber}` };
            }
            if (i.giQty < 0) {
                return { Error: true, Message: 'จำนวนที่ส่งครั้งนี้ต้องไม่เป็นจำนวนเต็มลบ' };
            }
        }
        // where all giQty value more than 0.
        const moreZero = dataList.filter(i => i.giQty > 0 && (auth.arrayStorageLocationIdMovement.includes(i.slocId) || isEmpty(i.slocId) || i.freeGoodsFlag));
        if (moreZero.length === 0) {
            return { Error: true, Message: 'กรุณากรอกจำนวนที่ส่งครั้งนี้ "มากกว่า 0"' };
        }
        // free goods can't be alonely submited.
        const notAlone = dataList.filter(i => i.giQty > 0 && !i.freeGoodsFlag);
        // if gi all goods also free goods have to gi.
        const allGoods = dataList.filter(i => i.doQty == i.giQty && !i.freeGoodsFlag && !i.productExcPoFlag).length === dataList.filter(i => !i.freeGoodsFlag && !i.productExcPoFlag).length && !!dataList.find(i => i.freeGoodsFlag && i.doQty != i.giQty);
        if (notAlone.length === 0 || allGoods) {
            return { Error: true, Message: 'กรุณาตัดจ่ายของแถมร่วมกับของขาย' };
        }
        // service and free goods can't be joinly submited.
        const notJoin = !!dataList.find(i => i.giQty > 0 && i.freeGoodsFlag) && !!dataList.find(i => i.giQty > 0 && i.productExcPoFlag) && !!!dataList.find(i => i.giQty > 0 && !i.freeGoodsFlag && !i.productExcPoFlag);
        if (notJoin) {
            return { Error: true, Message: 'กรุณาตัดจ่ายของแถมร่วมกับของขายที่เป็นสินค้า' };
        }

        return { Error: false, Message: '' };
    }

    openModalUpdatedSuccess(data) {
        swal({
            className: 'goods-issue-modal',
            title: 'Success',
            icon: 'success',
            text: data.message || 'บันทึกข้อมูลสำเร็จ',
            buttons: this.state.permissionPage.authPrint ?
                {
                    printA4: { text: 'พิมพ์ใบส่งสินค้า (เต็ม)', value: 'A4', className: 'green-button', closeModal: false },
                    printA5: { text: 'พิมพ์ใบส่งสินค้า (ย่อ)', value: 'A5', className: 'green-button', closeModal: false },
                    ok: 'ปิด',
                } :
                { ok: 'ปิด' },
        }).then((value) => {
            if (data && (value === 'A4' || value === 'A5')) {
                swal.stopLoading()
                this.openModalUpdatedSuccess(data)
                const path = value === 'A4' ? API_PATH.GET_REPORT_FORM_DELIVERY_ORDER : API_PATH.GET_REPORT_FORM_DELIVERY_ORDER_A5
                const url = `${path}?outletId=${data.outletId}&DocumentNo=${data.documentNo}&BillingNo=${data.billingNo}&DocumentTypeCode=${data.documentTypeCode}&userid=${this.props.auth.userId}`
                window.open(url, '_blank')
            } else {
                swal.close()
            }
        })
    }

    handleClickReprocess = async () => {
        this.setState({ loading: true });
        const { auth } = this.props
        const { dataList, doNumber, soNumber, outletSelected, documentDate, shipToName, remark, salesPartnerFunction } = this.state

        if (dataList.length == 0) {
            swal('Error', 'กรุณาค้นหาใบส่งสินค้า', 'error');
            this.setState({ loading: false });
            return;
        }

        const params = {
            doNumber: doNumber,
            outletId: outletSelected,
            soNumber: soNumber,
            shipToName: shipToName,
            documentDate: documentDate,
            remark: remark,
            updatedUser: auth.userId,
            giStatus: true,
            salesSOPartnerFunctionModel: salesPartnerFunction,
            salesDoItemModels: dataList,
        };

        await apiServices.callApi('post', API_PATH.POST_REPROCESS, params).then(res => {
            if (res.status === 200) {
                swal('Success', res.data.message, 'success')
                this.handleClickSearch();
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        });
        this.setState({ loading: false });
    }

    handleClickCancel = () => {
        this.props.history.push('/');
    }

    handleInputChangeGIQty = (index, row, val) => {
        let array = [...this.state.dataList]
        const giQty = Number(`${val.target.value}`.replace(/,/g, '')); //val.target.value;
        if (giQty <= row.doQty) {
            array[index].giQty = giQty;
            this.setState({ dataList: array })
        } else {
            swal('Error', 'กรุณากรอกจำนวนที่ส่งครั้งนี้ให้ "น้อยกว่าหรือเท่ากับ" จำนวนสั่งซื้อ', 'error');
        }
    }

    render() {
        const {
            loading,
            widthDefault,
            isShipmentModal,
            isDOModal,
            initialValues,
            submitViewErrorCustomer,
            doNumber,
            documentDate,
            shipToName,
            remark,
            address,
            dateFormat,
            outlets,
            outletSelected,
            isDisabled,
            isReprocess,
            dataList,
            columns,
            permissionPage,
        } = this.state;

        return (
            <div className="goods-issue-container">
                <Spin spinning={loading}>
                    <TitleHead text="รายการตัดจ่ายสินค้า" icon="stock" />
                    <div className="top-layout">
                        <Row gutter={20}>
                            <Col md={4}>
                                เลขที่ใบส่งสินค้า
                            </Col>
                            <Col md={8}>
                                <InputSearchCustom
                                    onChange={this.handleChangeDONumber}
                                    onClickSearch={this.handleClickDOSearch}
                                    value={doNumber}
                                    placeholder='2000014820'
                                    onSubmit={this.handleKeydownDONumber}
                                />
                            </Col>
                            <Col md={4}>
                                ร้านค้า
                            </Col>
                            <Col md={8}>
                                <SelectCustom
                                    onChange={this.handleChangeOutlet}
                                    name="outlet"
                                    options={outlets}
                                    value={outletSelected}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={4}>
                                ผู้รับสินค้า
                            </Col>
                            <Col md={8}>
                                <InputHero
                                    onChange={this.handleChangeShipToName}
                                    widthAll={widthDefault}
                                    value={shipToName}
                                />
                            </Col>
                            <Col md={4}>
                                วันที่ออกเอกสาร
                            </Col>
                            <Col md={8}>
                                <DatePickerCustom
                                    onChange={this.handleChangeDocumentDate}
                                    widthAll={widthDefault}
                                    value={documentDate}
                                    format={dateFormat}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col md={4}>
                                ที่อยู่
                            </Col>
                            <Col md={20}>
                                <a onClick={this.handleShowShipmentModal}>{address}</a>
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={20}>
                            <Col md={4}>
                                หมายเหตุ
                            </Col>
                            <Col md={20}>
                                <TextAreaCustom
                                    onChange={this.handleChangeRemark}
                                    value={remark}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={24}>
                                <div className='action-box'>
                                    <ButtonCustom onClick={this.handleClickSearch} text='ค้นหา' blue icon='search' />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='content'>
                        <TableCustom scroll={{ x: "max-content", y: "calc(100vh - 250px)" }} columns={columns} data={dataList} pagination={false} />
                    </div>
                    <div className="bottom-layout">
                        <div className='action-button'>
                            <div className='button-right'>
                                <ButtonCustom disabled={isDisabled || !permissionPage.authCreate} onClick={this.handleClickSaveDO} text='บันทึก' icon='save' />
                            </div>
                            <div className='button-right'>
                                <ButtonCustom disabled={isReprocess} onClick={this.handleClickReprocess} text='Reprocess' type='warning' icon='redo' />
                            </div>
                            <div className='button-right'>
                                <ButtonCustom onClick={this.handleClickCancel} text='ยกเลิก' icon='close' type='danger' />
                            </div>
                        </div>
                    </div>
                    <ModalCustom
                        title="แก้ไขข้อมูลที่อยู่การจัดส่ง"
                        isShowModal={isShipmentModal}
                        handleCloseModal={this.handleCloseShipmentModal}
                        handleClickSave={this.handleClickOkShipmentModal}
                        width="50%"
                        textSuccess="ตกลง"
                        footer={true}
                        destroyOnClose={true}
                    >
                        <CustomerDetailFormRedux
                            initialValues={initialValues}
                            submitViewErrorCustomer={submitViewErrorCustomer}
                        />
                    </ModalCustom>

                    <DeliveryOrderSearchModal
                        isShowModal={isDOModal}
                        closeModal={this.handleClickCloseDOModal}
                        handleClickSelectedDOModal={this.handleClickSelectedDOModal}
                        outlets={outlets}
                        outletSelected={outletSelected}
                    />
                </Spin>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        CustomerReduxForm: state.form.CustomerDetailFormRedux
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodsIssueContainer);