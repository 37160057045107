
export const VALIDATE = {
    REQUIRE: value => (value || typeof value === 'number' ? undefined : 'Required'),
    NUMBER: value => value ? isNaN(Number(value)) ? 'Must be a number' : undefined : 'Must be a number',
    EMAIL: value =>
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'use e-mail only' : undefined,
    MIN_LENGTH: value =>
        value ? value.length < 6 ? `Must be 6 characters or more` : undefined : `Must be 6 characters or more`,
    MAX_LENGTH: max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined,
    MIN_8: value =>
        value ? value.length < 8 ? `Must be 8 characters or more` : undefined : `Must be 8 characters or more`,
    MIN_15: value =>
        value ? value.length < 15 ? `Must be 15 characters or more` : undefined : `Must be 15 characters or more`,
    REQUIRE_NUMBER: value =>
        value ? !value.match(/[0-9]/g) ? `Must have at least 1 number(s)` : undefined : `Must have at least 1 number(s)`,
    REQUIRE_UPPER: value =>
        value ? !value.match(/[A-Z]/g) ? `Must have at least 1 upper case letter(s)` : undefined : `Must have at least 1 upper case letter(s)`,
    REQUIRE_LOWER: value =>
        value ? !value.match(/[a-z]/g) ? `Must have at least 1 lower case letter(s)` : undefined : `Must have at least 1 lower case letter(s)`,
    REQUIRE_SYMBOL: value =>
        value ? !value.match(/(.*[(,),!,@,#,$,%,^,&,*,?,_,~])/) ? `Must have at least 1 symbol(s)` : undefined : `Must have at least 1 symbol(s)`,
    REQUIRE_COMPLEX: value =>
        value ? (!value.match(/[0-9]/g) ? `Must have at least 1 number(s)` : !value.match(/[A-Z]/g) ? `Must have at least 1 upper case letter(s)` : !value.match(/[a-z]/g) ? `Must have at least 1 lower case letter(s)` : !value.match(/(.*[(,),!,@,#,$,%,^,&,*,?,_,~])/) ? `Must have at least 1 symbol(s)` : value.length < 8 ? `Must be 8 characters or more` : undefined) : 'Required',
}

export const CustomerKeyWordDropDown = [
    { text: "รหัสลูกค้า", label: "รหัสลูกค้า", value: "CustomerId" },
    { text: "ชื่อลูกค้า", label: "ชื่อลูกค้า", value: "CustomerNameTH" },
    { text: "เบอร์โทรศัพท์", label: "เบอร์โทรศัพท์", value: "CustomerPhone" },
    // { text: "มือถือ", label: "มือถือ", value: "CustomerMobile" },
    { text: "เลขประจำตัวบัตรประชาชน", label: "เลขประจำตัวบัตรประชาชน", value: "CustomerTaxId" },
    { text: "รหัสกลุ่มลูกค้า", label: "รหัสกลุ่มลูกค้า", value: "CustomerGroupId" },
    { text: "อีเมล์", label: "อีเมล์", value: "CustomerEmail" }
]

export const SaleEmployeeKeyWordDropDown = [
    { text: "รหัสพนักงาน", label: "รหัสพนักงาน", value: "SaleEmployeeId" },
    { text: "ชื่อพนักงาน", label: "ชื่อพนักงาน", value: "SaleEmployeeName" },
    { text: "เบอร์โทรศัพท์", label: "เบอร์โทรศัพท์", value: "SaleEmployeePhone" },
]

export const ProductKeyWordDropDown = [
    { text: "รหัสสินค้า", label: "รหัสสินค้า", value: "productId" },
    { text: "ชื่อสินค้า", label: "ชื่อสินค้า", value: "productNameEng" },
    { text: "barcode", label: "barcode", value: "barcode" },
]

export const ProductCategoryKeyWordDropDown = [
    { text: "รหัสกลุ่มสินค้า", label: "รหัสกลุ่มสินค้า", value: "CategoryCode" },
    { text: "ชื่อกลุ่มสินค้า", label: "ชื่อกลุ่มสินค้า", value: "Description" },
]

export const PromotionStatus = [
    { text: "Reject (ไม่อนุมัติหรือยกเลิก)", label: "Reject (ไม่อนุมัติหรือยกเลิก)", value: "0" },
    { text: "Approve (อนุมัติ)", label: "Approve (อนุมัติ)", value: "1" },
    { text: "Opening (สร้าง/รอการอนุมัติ)", label: "Opening (สร้าง/รอการอนุมัติ)", value: "2" },
    { text: "Planning (แก้ไข/รอการอนุมัติ)", label: "Planning (แก้ไข/รอการอนุมัติ)", value: "3" },
]

export const PromotionStatusOptions = [
    { text: "Reject (ไม่อนุมัติหรือยกเลิก)", label: "Reject (ไม่อนุมัติหรือยกเลิก)", value: "Reject" },
    { text: "Approve (อนุมัติ)", label: "Approve (อนุมัติ)", value: "Approve" },
    { text: "Opening (สร้าง/รอการอนุมัติ)", label: "Opening (สร้าง/รอการอนุมัติ)", value: "Opening" },
    { text: "Planning (แก้ไข/รอการอนุมัติ)", label: "Planning (แก้ไข/รอการอนุมัติ)", value: "Planning" },
]

export const QualifierTypes = [
    { text: 'รายการสินค้า (Article)', label: "รายการสินค้า", value: 'product' },
    { text: 'หมวดหมู่สินค้า (Category)', label: "หมวดหมู่สินค้า", value: 'category' }
];

export const QualifierStatus = [
    { text: "ปิดใช้งาน (Blocked)", label: "ปิดใช้งาน", value: 0 },
    { text: "เปิดใช้งาน (Active)", label: "เปิดใช้งาน", value: 1 },
];

export const customerType = [
    {
        label: "บุลคลธรรมดา",
        text: "บุลคลธรรมดา",
        value: "1",
    },
    {
        label: "นิติบุคคล",
        text: "นิติบุคคล",
        value: "2",
    },
]

export const reportSaleOrderType = [
    {
        value: 'SaleOrder',
        label: 'รายงานใบสั่งขาย'
    },
    {
        value: 'Summary',
        label: 'รายงานแบบสรุป'
    },
    {
        value: 'SummaryWithDetail',
        label: 'รายงานพร้อมรายการย่อย'
    },
]

export const showStatusSaleOrder = [
    {
        value: 'CashUnCutReceipt',
        label: 'SO เงินสด : ออกใบเสร็จแล้วแต่ยังไม่ตัดสต็อก'
    },
    {
        value: 'CashUnCutUnReceipt',
        label: 'SO เงินสด : ยังไม่ตัดสต็อกและยังไม่ออกใบเสร็จ'
    },
    {
        value: 'CreditCutUnInvoice',
        label: 'SO เงินเชื่อ : ตัดสต็อกแล้วแต่ยังไม่ทำใบแจ้งหนี้'
    },
    {
        value: 'CreditUnCutUnInvoice',
        label: 'SO เงินเชื่อ : ยังไม่ตัดสต็อกและไม่ทำใบแจ้งหนี้'
    },
]

export const promotionPattern = [
    {
        value: 'BundleRule',
        label: 'Bundle Rule',
        text: 'Bundle Rule',
    },
    {
        value: 'LineDiscount',
        label: 'Line Discount',
        text: 'Line Discount',
    },
    {
        value: 'QuantityDiscounts',
        label: 'Quantity Discounts',
        text: 'Quantity Discounts',
    },
    {
        value: 'MixMatchDiscounts',
        label: 'Mix & Match Discounts',
        text: 'Mix & Match Discounts',
    },
    {
        value: 'Voucher',
        label: 'Voucher',
        text: 'Voucher',
    },
]

export const discountAndGiveAway = [
    {
        value: 'ส่วนลด',
        label: 'ส่วนลด'
    },

    {
        value: 'ของแถม',
        label: 'ของแถม'
    },
]

export const promotionPiority = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
]

export const promotionExclusive = [
    { value: 1, label: "โปรโมชั่นนี้สามารถใช้รวมกับโปรโมชั่นอื่น ๆ" },
    { value: 2, label: "โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่นอื่น ๆ ได้" },
]

export const promotionLimit = [
    { value: 1, label: "ไม่จำกัดจำนวน" },
    { value: 2, label: "จำนวนครั้งที่โปรโมชั่นใช้งานได้กับรายการสินค้า" },
    { value: 3, label: "จำนวนครั้งที่โปรโมชั่นใช้งานได้กับใบสั่งซื้อ" },
    { value: 4, label: "จำนวนครั้งที่โปรโมชั่นใช้งานได้ต่อสมาชิก" },
    { value: 5, label: "จำนวนครั้งวงเงินโดยรวมของใบสั่งซื้อ ที่โปรโมชั่นนี้สามารถแลกได้" },
]

export const conditionPermission = [
    { label: 'ไม่จำกัด', value: 1 },
    { label: 'จำกัดหนึ่งสิทธิ์ต่อใบสั่งซื้อ', value: 2 },
]

export const discountType = [
    { label: 'บาท', value: 'Baht' },
    { label: '%', value: 'Percent' },
]

export const promotionType = [
    { label: 'ส่วนลดท้ายบิล', value: "Bill" },
    { label: 'ส่วนลดเฉพาะรายการสินค้า', value: "Product" },
]

export const conditionType = [
    { label: 'Item Quantity', value: 'item_quantity' },
    { label: 'Total Quantity', value: 'total_quantity' },
    { label: 'Total Amount', value: 'total_amount' },
]

export const supportType = [
    { label: 'Internal', value: 'internal' },
    { label: 'External', value: 'external' },
]

export const supportValue = [
    { label: 'บางส่วน', value: 'partial' },
    { label: 'ทั้งหมด', value: 'full' },
]

export const saleTypes = [
    { label: 'Normal', value: 'normal' },
    { label: 'Special', value: 'special' },
]

export const quotationStockType = [
    { label: 'Normal', value: 'normal' },
    { label: 'Special', value: 'special' },
]

export const reportSaleType = [
    { label: 'ใบสั่งขายเงินสด', value: 'ZOCS' },
    { label: 'ใบสั่งขาย-เงินเชื่อ', value: 'ZOCR' },
    { label: 'ใบขอลดหนี้ราคา', value: 'ZCR' },
    { label: 'ใบขอคืนสินค้า', value: 'ZCRE' },
    { label: 'ใบขอลดหนี้ค้างจ่าย', value: 'ZCRR' },
    { label: 'ใบขอเพิ่มหนี้ราคา', value: 'ZDR' },
]

export const reasonRejectType = [
    { label: '00-ที่กำหนดโดยระบบ(ภายใน)', value: 'A00' },
    { label: '01-วันที่ส่งมอบช้าเกินไป', value: 'A01' },
    { label: '02-คุณภาพต่ำ', value: 'A02' },
    { label: '03-แพงเกินไป', value: 'A03' },
    { label: '04-คู่แข่งขันดีกว่า', value: 'A04' },
    { label: '05-การรับประกัน', value: 'A05' },
    { label: '06-สลับลำดับการจอง', value: 'A06' },
    { label: '10-ความต้องการที่ไม่มีเหตุผล', value: 'A10' },
    { label: '11-ลูกค้าที่ได้รับการทดแทน', value: 'A11' },
    { label: '50-รายการกำลังถูกตรวจสอบ', value: 'A50' },
    { label: 'Z1-คืนสินค้าเข้ามาในระบบ', value: 'Z1' },
    { label: 'Z2-ไม่ผ่านการอนุมัติ', value: 'Z2' },
    { label: 'Z3-ยกเลิกจากการเปลี่ยนประเภทเอกสาร', value: 'Z3' },
]

export const categoryList = [
    { label: 'สินค้าขาย-Cash', value: 'ZCAN' },
    { label: 'BOM ราคาที่รายการ', value: 'ZCAP' },
    { label: 'BOM ราคาที่ header', value: 'ZCAS' },
    { label: 'ค่าบริการ (Special)', value: 'ZCBD' },
    { label: 'ส.ประกอบสินค้า(Spcl)', value: 'ZCBE' },
    { label: 'สินค้าแถม(Special)', value: 'ZCBF' },
    { label: 'สินค้าขาย(Special)', value: 'ZCBN' },
    { label: 'สินค้าแถม-Cash', value: 'ZCNN' },
    { label: 'เงินมัดจำ', value: 'ZDEP' },
    { label: 'เงินมัดจำหักล้าง', value: 'ZDER' },
    { label: 'รายการใบลดหนี้', value: 'ZG2N' },
    { label: 'รายการใบเพิ่มหนี้', value: 'ZL2N' },
    { label: 'ค่าบริการ', value: 'ZQAD' },
    { label: 'ส.ประกอบสินค้า', value: 'ZQAE' },
    { label: 'สินค้าขาย-QT', value: 'ZQAN' },
    { label: 'สินค้าแถม-QT', value: 'ZQNN' },
    { label: 'สินค้าขาย-คืนSpecial', value: 'ZRAB' },
    { label: 'สินค้าคืน', value: 'ZRAN' },
    { label: 'BOM ราคาที่รายการ', value: 'ZRAP' },
    { label: 'BOM ราคาที่ header', value: 'ZRAS' },
    { label: 'เงินมัดจำ-คืน', value: 'ZRED' },
    { label: 'Return Sales Order', value: 'ZREN' },
    { label: 'สินค้าแถม-คืนSpecial', value: 'ZRNB' },
    { label: 'สินค้าแถม-คืน', value: 'ZRNN' },
    { label: 'Special Order', value: 'ZTAB' },
    { label: 'ค่าบริการ', value: 'ZTAD' },
    { label: 'ส.ประกอบสินค้า-เชื่อ', value: 'ZTAE' },
    { label: 'สินค้าขาย-เชื่อ', value: 'ZTAN' },
    { label: 'ค่าบริการ (Special)', value: 'ZTBD' },
    { label: 'ส.ประกอบสินค้า(Spcl)', value: 'ZTBE' },
    { label: 'สินค้าแถม(Special)', value: 'ZTBF' },
    { label: 'สินค้าขาย(Special)', value: 'ZTBN' },
    { label: 'สินค้าแถม-เชื่อ', value: 'ZTNN' },
]

export const documentType = [
    { label: 'ใบสั่งขายที่มีการปฏิเสธ', value: 'Reject' },
    { label: 'ใบสั่งขายที่มีการระงับ', value: 'Block' },
]

export const giStockType = [
    {
        value: '',
        label: 'Unrestricted-use(พร้อมขาย)'
    },
    {
        value: '2',
        label: 'Quality inspection (รอตรวจสอบ)'
    },
    {
        value: '3',
        label: 'Blocked (ระงับ)'
    },
]