import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { ROUTH_PATH } from 'routes/path';
import './style.css'
import { allTableColumns, modes } from './config'
import { authData } from 'actions/actionsCreator';
import moment from 'moment'
import { Row, Col, Spin, message } from "antd"
import { apiServices, API_PATH } from "apiServices";
import swal from '@sweetalert/with-react';
import { get, first, isEmpty, compact } from 'lodash';
import { functionAlias } from 'routes'
import { getPermissionPage } from 'helpers'
import {
  TitleHead,
  InputCustom,
  TableCustom,
  ButtonCustom,
  TextAreaCustom,
  SelectCustom,
  InputSearchCustom,
  DatePickerCustom,
  NumberFormatCustom,
} from "components/common"

import {
  GoodsReturnSearchModal,
  StoreLocationModal,
  PurchaseOrderSTOModal,
  SaleUnitModal,
} from "components/feature"

const poDocType = '03TO' // fix value for SAP

// TODO: check permission print button when added print button
class GoodsReturnContainer extends Component {
  state = {
    loading: false,
    isShowModal: false,
    dateFormat: 'DD/MM/YYYY',
    foundMatDoc: false,
    header: {
      docType: '',
      matDoc: '',
      docDate: moment(),
      doNoRef: '',
      vendorCode: '',
      vendorName: '',
      remarks: '',
      matDocYear: '',
    },
    items: [],
    transactionTypes: [],
    outletOptions: [],
    modalType: '',
    tableColumns: allTableColumns[102],
    columns: [],
    selectedRowKeys: [],
    selectedRowData: [],
    dataReasons: [],
    dataUnits: [],
    dataStorages: [],
    indexRowAction: null,
    permissionPage: getPermissionPage(functionAlias.inventoryReturn),
  };

  componentDidMount() {
    this.fetchDataDefault()
  }

  fetchDataDefault = async () => {
    const { auth } = this.props;
    let { header, transactionTypes, outletOptions } = this.state;
    //console.log("allTableColumns==>", allTableColumns);

    this.setState({ loading: true });
    //ประเภทเอกสาร Transaction Type
    const codes = Object.keys(allTableColumns).map(key => (`TransactionCodes=${key}`)).join('&')
    const url = `${API_PATH.GET_MASTER_TRANSACTION_TYPE}?ActiveStatus=true&${codes}`;
    await apiServices.callApi('get', url, '', '').then(res => {
      let results = get(res, 'data.results');
      transactionTypes = results.map(data => ({
        label: `${data.transactionCode} - ${data.transactionDescription}`,
        value: data.transactionCode,
      }));

      this.setState({
        transactionTypes: [{ label: 'กรุณาเลือก', value: '' }, ...transactionTypes]
      })
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "ประเภทเอกสาร"!')
      swal('Error', message, 'error')
    });

    //ร้านค้า Outlet
    let paramsOutlet = {
      outletIds: auth.userOutletModelList.map((data) => (
        data.outletId
      ))
    };
    await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, paramsOutlet).then(res => {
      let results = get(res, 'data.results');
      outletOptions = results.map(data => ({
        label: `${data.outletId} - ${data.outletName}`,
        value: data.outletId,
      }));

      if (!isEmpty(outletOptions) && outletOptions.length > 0) {
        const defaultOutlet = first(outletOptions);
        header = {
          ...header,
          outletId: defaultOutlet.value,
        }
      }

      this.setState({ outletOptions, header });
    }).catch(error => {
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลร้านค้า!')
      swal('Error', message, 'error')
    });

    await this.fetchDataDefaultStoreLocation();
    this.setColumns();
    this.setState({ loading: false });
  }

  clearInformation = () => {
    this.setState({
      loading: false,
      isShowModal: false,
      dateFormat: 'DD/MM/YYYY',
      foundMatDoc: false,
      header: {
        docType: '',
        matDoc: '',
        docDate: moment(),
        doNoRef: '',
        vendorCode: '',
        vendorName: '',
        remarks: '',
        matDocYear: '',
      },
      items: [],
      transactionTypes: [],
      outletOptions: [],
      modalType: '',
      tableColumns: allTableColumns[102],
      columns: [],
      selectedRowKeys: [],
      selectedRowData: [],
    }, () => this.fetchDataDefault());
  }

  setColumns() {
    const { tableColumns, header } = this.state;
    let columns = [];

    if (!isEmpty(tableColumns)) {
      if (header.docType === '161') {
        this.setPOColumns()
        return
      }

      columns = [
        {
          title: '#',
          key: 'index',
          align: 'center',
          width: 45,
          render: (props, row, index) =>
            <p>{index + 1}</p>
        },
        tableColumns.matnr && {
          title: 'รหัสสินค้า',
          dataIndex: 'matnr',
          key: 'matnr',
          align: 'center',
        },
        tableColumns.maktx && {
          title: 'ชื่อสินค้า',
          dataIndex: 'maktx',
          key: 'maktx',
        },
        tableColumns.lgort && {
          title: 'ที่เก็บ',
          dataIndex: 'lgort',
          key: 'lgort',
          align: 'center',
        },
        tableColumns.meins && {
          title: 'หน่วยนับ',
          dataIndex: 'meins',
          key: 'meins',
          align: 'center',
        },
        tableColumns.menge && {
          title: 'จำนวนสั่งซื้อ',
          dataIndex: 'menge',
          key: 'menge',
          align: 'right',
          render: (value, full, row) => parseFloat(value).toFixed(2)
        },
        tableColumns.returnQty && {
          title: 'จำนวนส่งคืนครั้งนี้',
          dataIndex: 'returnQty',
          key: 'returnQty',
          render: (value, full, row) =>
            <NumberFormatCustom
              name="returnQty"
              className="text-bold-align-right"
              // value={parseFloat(value).toFixed(2)}
              // onChange={(e) => this.handleChangeItem(row, 'returnQty', e.target.value)}
              decimalScale={2}
              value={value}
              onChange={(e) => this.handleChangeItem(row, 'returnQty', Number(`${e.target.value}`.replace(/,/g, '')))}
            />
        },
        tableColumns.reason && {
          title: 'เหตุผล',
          dataIndex: 'reason',
          key: 'reason',
          width: 200,
          render: (value, full, row) =>
            <SelectCustom
              options={this.state.dataReasons}
              pleaseChoose
              onChange={(value) => this.handleChangeItem(row, 'reason', value)}
              name="reason"
              value={value}
              placeholderDefault
            />
        },
      ]
    }
    // remove falsey values in array
    columns = compact(columns);
    this.setState({ columns });
  }

  setPOColumns() {
    const { tableColumns } = this.state;
    let columns = [
      {
        title: '#',
        key: 'index',
        align: 'center',
        width: 45,
        render: (props, row, index) => (<p>{index + 1}</p>)
      },
      tableColumns.material && {
        title: 'รหัสสินค้า',
        dataIndex: 'material',
        key: 'material',
        align: 'center',
      },
      tableColumns.eaN11 && {
        title: 'บาร์โค้ด',
        dataIndex: 'eaN11',
        key: 'eaN11',
        align: 'center',
      },
      tableColumns.materiaL_TX && {
        title: 'ชื่อสินค้า',
        dataIndex: 'materiaL_TX',
        key: 'materiaL_TX',
      },
      tableColumns.stgE_LOC && {
        title: 'ที่เก็บ',
        dataIndex: 'stgE_LOC',
        key: 'stgE_LOC',
        align: 'center',
        render: (props, row, index) => (
          <ButtonCustom
            text={props || '-'}
            small
            type="link"
            onClick={() => this.openModalStoreLocation(row, index)}
          />
        ),
      },
      tableColumns.pO_UNIT && {
        title: 'หน่วยนับ',
        dataIndex: 'pO_UNIT',
        key: 'pO_UNIT',
        align: 'center',
      },
      tableColumns.quantitY_ORD && {
        title: 'จำนวนส่งคืน',
        dataIndex: 'quantitY_ORD',
        key: 'quantitY_ORD',
        align: 'right',
        render: (props) => parseFloat(props).toFixed(3)
      },
      tableColumns.quantitY03 && {
        title: 'จำนวนคงค้าง',
        dataIndex: 'quantitY03',
        key: 'quantitY03',
        align: 'right',
        render: (props) => parseFloat(props).toFixed(3)
      },
      tableColumns.returnQty && {
        title: 'จำนวนส่งคืนครั้งนี้',
        dataIndex: 'returnQty',
        key: 'returnQty',
        align: 'right',
        render: (props, row, index) => (
          <NumberFormatCustom
            name="returnQty"
            className="text-bold-align-right"
            decimalScale={2}
            value={props}
            onChange={(e) => this.handleChangeItem(index, 'returnQty', Number(`${e.target.value}`.replace(/,/g, '')))}
          />
        )
      },
    ]
    // remove falsey values in array
    columns = compact(columns);
    this.setState({ columns });
  }

  handleChangeItem = (row, key, val) => {
    let { items } = this.state;
    //console.log(`row==>${row}, key==>${key}, val==>${val}`);
    //console.log(`Befor items[${row}]`, items[row]);
    items[row] = {
      ...items[row],
      [key]: val
    };
    console.log(`After items[${row}]`, items[row]);
    this.setState({ items });
  }

  fetchDataDefaultStoreLocation = async () => {
    let { header, defaultStore } = this.state;

    const paramsStoreLocation = `?ConditionName=GR&Condition1=${header.outletId}&Condition2=SLOC`;
    //Default store location
    await apiServices.callApi('get', `${API_PATH.GET_MASTER_SPECIAL_CONDITION}/${paramsStoreLocation}`, '', '').then(res => {
      let results = get(res, 'data.results');

      if (!isEmpty(results) && results.length > 0) {
        //console.log("paramsStoreLocation results ===>", first(results));
        defaultStore = first(results).value;
      }

      this.setState({ defaultStore });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "ที่เก็บ(Default Store Location)"!')
      swal('Error', message, 'error');
    });
  }

  fetchDataInventoryReason = async () => {
    let { header } = this.state;

    const paramsInventoryReason = `?MovementType=${header.docType}&ActiveStatus=${true}`;
    await apiServices.callApi('get', `${API_PATH.GET_MASTER_INVENTORY_REASON}/${paramsInventoryReason}`, '', '').then(res => {
      let results = get(res, 'data.results');
      let dataReasons = [];
      if (!isEmpty(results)) {
        dataReasons = results.map(data => ({
          label: `${data.reasonCode} - ${data.reasonDescription}`,
          value: data.reasonCode,
        }));
      }
      this.setState({ dataReasons });
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "เหตุผล(Table Master Inventory Reason)"!')
      swal('Error', message, 'error');
    });
  }

  handleShowModal = async (modalType, dataSelected) => {
    let { filterStore, header } = this.state
    switch (modalType) {
      case "storageLocation":
        //console.log("dataSelected==>", dataSelected);

        await this.fetchDataStorelocation(
          get(dataSelected, 'full.material'),
          get(dataSelected, 'full.pO_UNIT'),
          get(header, 'outletId')
        );
        break;
      default:
        break;
    }

    this.setState({ modalType, dataSelected, isShowModal: true, filterStore });
  }

  fetchDataStorelocation = async (productId, salesUnit, outletId) => {
    const { auth } = this.props
    let params = {
      productId: productId,
      salesUnit: salesUnit,
      outletId: outletId,
      isIncludeStockATP: true,
      storageLocationIds: auth.arrayStorageLocationId,
      limitMaxRange: 100,
      productStatus: true
    }
    //console.log("API params==>", params);
    //Store location.
    await apiServices.callApi('post', `${API_PATH.POST_GET_PRODUCT}?getBy=${auth.userId}`, params).then(res => {
      const results = get(res, 'data.results');
      //console.log("results==>", results);
      if (isEmpty(results)) {
        swal('Error', `ไม่พบรายการสินค้า ${params.productId} (${params.salesUnit}) ร้าน ${params.outletId}`, 'error')
      }
      const dataStorages = results.map((data, i) => (
        {
          key: i,
          no: i + 1,
          ...data
        }
      ))
      this.setState({ dataStorages, params });

    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล Store location!')
      swal('Error', message, 'error')
    });
  }

  onSelected = async (itemDetail) => {
    let { header } = this.state;
    //console.log("itemDetail==>", itemDetail);

    this.setState({
      header: {
        ...header,
        matDoc: itemDetail.mblnr,
        docDate: moment(itemDetail.bldat),
        doNoRef: itemDetail.xblnr,
        vendorCode: itemDetail.lifnr,
        vendorName: itemDetail.lifnR_T,
        matDocYear: moment(itemDetail.bldat).format('YYYY'),
      }
    }, () => this.handleSubmitSearchDocSAPPI());
    this.handleCloseModal();
  }

  handleSubmitSearchDocSAPPI = async () => {
    let { header, defaultStore, items, selectedRowKeys, selectedRowData } = this.state;
    //console.log("header==>", header);
    //console.log("defaultStore==>", defaultStore);
    if (isEmpty(header.matDoc) || isEmpty(header.docType)) {
      return;
    }

    if (isEmpty(header.matDocYear)) {
      swal('Error', `กรุณาระบุปีเอกสารวัสดุ`, 'error');
      return;
    }

    this.setState({ loading: true });
    //Get Mat Doc Detail form SAP.
    // const urlDataFormSAP = `${API_PATH.POST_GET_DAILY_MATERIAL_MOVEMENT}?MatDoc=${header.matDoc}&Outlet=${header.outletId}&DocType=${header.docType}&MatDocYear=${header.matDocYear}`;
    const urlDataFormSAP = API_PATH.POST_GET_DAILY_MATERIAL_MOVEMENT
    const body = {
      matDoc: header.matDoc,
      outlet: header.outletId,
      docType: header.docType,
      matDocYear: header.matDocYear,
    }
    await apiServices.callApi('post', urlDataFormSAP, body).then(res => {
      let result = get(res, 'data.result');
      console.log(`result ==>`, result);
      items = get(result, 'eT_MAT_MOVEMENT.item');
      let remark = get(result, 'eT_REMARK.item');

      if (isEmpty(items) || items.length === 0) {
        swal('Error', `ไม่พบเอกสารวัสดุเลขที่: ${header.matDoc}`, 'error');
        this.setState({ loading: false });
        return;
      }

      items = items.map((data, i) => (
        {
          key: i,
          ...data,
          lgort: data.lgort ? data.lgort : defaultStore,
          returnQty: Math.abs(data.menge),
        }
      ))

      header = {
        ...header,
        matDoc: first(items).mblnr,
        docDate: moment(first(items).bldat),
        doNoRef: first(items).xblnr,
        vendorCode: first(items).lifnr,
        vendorName: first(items).lifnR_T,
        matDocYear: moment(first(items).bldat).format('YYYY'),
        postingDate: moment(first(items).budat),
      }

      if (!isEmpty(remark) && remark.length > 0) {
        header = {
          ...header,
          remarks: remark.join('\n'),
        }
      }
      selectedRowKeys = items.map((data, i) => i);

      this.setState({ items, header, selectedRowKeys, selectedRowData: items, foundMatDoc: true });
    }).catch(error => {
      //console.log(`error catch ==>`, error);
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างค้นหาข้อมูลใบสั่งซื้อ!')
      swal('Error', message, 'error')
    });

    this.setState({ loading: false });
  }

  handleSubmitSearchPOSAPPI = async () => {
    const { header } = this.state;
    if (isEmpty(header.matDoc)) {
      message.error('กรุณาระบุเลขที่ใบสั่งซื้อ');
      return
    }
    this.setState({ loading: true });

    const url = `${API_PATH.GET_PO_AND_STO_DETAIL}?PONo=${header.matDoc}&Outlet=${header.outletId}&IsReleaseIndicator=${true}&OutletSelName=S_RESWK&DocType=${poDocType}`;
    await apiServices.callApi('get', url).then(res => {
      const items = get(res, 'data.result.eT_PO_ITEM.item');
      if (res.status === 200) {
        if (items) {
          const header = get(res, 'data.result.eT_PO_HEADER.item.0', {});
          this.onSelectedPO({
            eT_PO_ITEM: items,
            pO_NUMBER: header.pO_NUMBER,
            doC_DATE: header.doC_DATE,
            eT_PO_HDTXT: get(header, 'eT_PO_ITTXT.eT_PO_HDTXT'),
            vendor: header.vendor,
            venD_NAME: header.venD_NAME,
          });
        } else {
          message.error('ไม่พบข้อมูล');
        }
      }
    }).catch(error => {
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างค้นหาข้อมูลใบสั่งซื้อ!');
      swal('Error', message, 'error');
    });
    this.setState({ loading: false });
  }

  onSelectedPO = (poSelected) => {
    const { header } = this.state
    let items = get(poSelected, 'eT_PO_ITEM');
    if (items) {
      let selectedRowKeys = []
      items = items.map((item, index) => {
        selectedRowKeys.push(index)
        return {
          ...item,
          key: index,
          returnQty: Math.abs(item.quantitY03),
        }
      });

      this.setState({
        items,
        selectedRowKeys,
        selectedRowData: items,
        header: {
          ...header,
          matDoc: poSelected.pO_NUMBER,
          docDate: poSelected.doC_DATE ? moment(poSelected.doC_DATE) : null,
          remarks: poSelected.eT_PO_HDTXT || '',
          vendorCode: poSelected.vendor,
          vendorName: poSelected.venD_NAME,
        },
        foundMatDoc: true,
      });
    }

    this.handleCloseModal();
  }

  openModalStoreLocation = async (row, index) => {
    const { header } = this.state
    this.setState({ loading: true })
    await this.fetchDataStorelocation(row.material, row.pO_UNIT, header.outletId)
    this.setState({
      modalType: 'storageLocation',
      indexRowAction: index,
      isShowModal: true,
      loading: false,
    })
  }

  // openModalUnit = async (row, index) => {
  //   const { header } = this.state
  //   this.setState({ loading: true })
  //   await this.fetchDataUnit(row.material, row.stgE_LOC, header.outletId)
  //   this.setState({ 
  //     modalType: 'unit',
  //     indexRowAction: index,
  //     isShowModal: true,
  //     loading: false,
  //   })
  // }

  onSelectedStoreLocation = (data) => {
    const { indexRowAction } = this.state
    const productSelected = data.dataSelected
    this.setState({
      items: this.state.items.map((product, i) => (
        i === indexRowAction ? {
          ...product,
          stgE_LOC: productSelected.storageLocationId,
        } : product
      )),
      indexRowAction: null,
    })
    this.handleCloseModal()
  }

  // onSelectedUnit = (data) => {
  //   const { indexRowAction } = this.state
  //   this.setState({
  //     items: this.state.items.map((product, i) => (
  //       i === indexRowAction ? {
  //         ...product,
  //         pO_UNIT: data.unitId,
  //       } : product
  //     )),
  //     indexRowAction: null,
  //   })
  //   this.handleCloseModal()
  // }

  handleCloseModal = () => {
    this.setState({ modalType: '', dataSelected: '', isShowModal: false });
  }

  handleChangeHeader = (key, val) => {
    let { header, columns } = this.state;
    this.setState({ header: { ...header, [key]: val }, columns });
  }

  changeTransactionType = (val) => {
    let { header } = this.state;

    const isPOType = header.docType === '161' || val === '161'
    if (isPOType) {
      // reset header
      header = {
        ...header,
        docType: val,
        matDoc: '',
        docDate: moment(),
        doNoRef: '',
        vendorCode: '',
        vendorName: '',
        remarks: '',
        matDocYear: '',
      };
    } else {
      header = {
        ...header,
        docType: val,
      };
    }

    this.setState({
      header,
      tableColumns: allTableColumns[val]
    }, async () => {
      // reset data
      if (isPOType) {
        this.setState({
          items: [],
          selectedRowKeys: [],
          selectedRowData: [],
          foundMatDoc: false,
        });
      }
      this.setColumns();
      await this.fetchDataInventoryReason();
    })
  }

  onSelectChange = (selectedRowKeys, selectedRowData) => {
    this.setState({ selectedRowKeys, selectedRowData })
    //console.log("selectedRowKeys ==> ", selectedRowKeys);
    //console.log("selectedRowData ==> ", selectedRowData);
  }

  validateData = () => {
    const { items, selectedRowKeys, tableColumns, header } = this.state;
    const selectItems = items.filter(item => selectedRowKeys.includes(item.key));

    let result = { isError: false, message: '' };
    if (header.docType === '161') {
      if (!header.refDocNo) {
        return { isError: true, message: 'กรุณาระบุเลขที่ใบส่งสินค้า (ผู้ขาย)' };
      }

      if (!header.docDate) {
        return { isError: true, message: 'กรุณาระบุวันที่ออกเอกสาร' };
      }
    }

    for (const item of selectItems) {
      if (tableColumns.returnQty && item.returnQty <= 0) {
        result = { isError: true, message: 'จำนวนส่งคืนต้องมากกว่า 0' };
        break;
      }

      if (header.docType === '161') {
        if (tableColumns.stgE_LOC && !item.stgE_LOC) {
          result = { isError: true, message: 'กรุณาระบุที่เก็บ' };
          break;
        }
        if (tableColumns.returnQty && item.returnQty > Number(item.quantitY03)) {
          result = { isError: true, message: 'จำนวนส่งคืนต้องไม่มากกว่าจำนวนจำนวนคงค้าง' };
          break;
        }
      } else {
        if (tableColumns.returnQty && item.returnQty > Number(item.menge)) {
          result = { isError: true, message: 'จำนวนส่งคืนต้องไม่มากกว่าจำนวนสั่งซื้อ' };
          break;
        }
      }
    }

    return result;
  }

  getBodyPO = () => {
    const { auth } = this.props
    const { items, selectedRowKeys, header } = this.state
    let selectItems = items.filter(i => selectedRowKeys.includes(i.key));
    selectItems = selectItems.map((data, i) => ({
      MATERIAL: data.material,
      PLANT: data.plant,
      STGE_LOC: data.stgE_LOC,
      ENTRY_UOM: data.pO_UNIT,
      ENTRY_QNT: data.returnQty,
      PO_NUMBER: data.pO_NUMBER,
      PO_ITEM: data.pO_ITEM,
    }))

    return {
      IM_MODE: 'C',
      IM_MOVE_TYPE: '161',
      IM_TYPE_REF_DOC: 'R01',
      IM_PIID: '',
      iM_GOODSMVT_HEADER: {
        doC_DATE: header.docDate.format('YYYY-MM-DD'),
        pstnG_DATE: moment().format('YYYY-MM-DD'),
        reF_DOC_NO: header.refDocNo,
        weB_USER: auth.userId,
      },
      IT_REMARK: {
        item: [{
          tdline: header.remarks,
        }],
      },
      IT_GOODSMVT_ITEM: {
        item: selectItems,
      },
    }
  }

  handleSubmitSave = async () => {
    const { auth } = this.props
    let { header, selectedRowData, selectedRowKeys, items } = this.state;
    let { paramHeader, paramItems } = [];

    const selectItems = items.filter(i => selectedRowKeys.includes(i.key));

    if (isEmpty(selectItems)) {
      swal('Error', 'กรุณาระบุรายการสินค้า!', 'error');
      return;
    }

    if (header.docType === "122" && !isEmpty(selectItems.filter(i => isEmpty(i.reason)))) {
      swal('Error', 'กรุณาระบุเหตุผล!', 'error');
      return;
    }
    if (moment(header.postingDate).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
      swal('Error', 'ไม่สามารถยกเลิกการรับสินค้าข้ามวันได้ กรุณาติดต่อทีม Operation ของ SCG เพื่อทำการยกเลิก', 'error');
      return;
    }

    const valid = this.validateData();
    if (valid.isError) {
      swal('Warning', valid.message, 'warning');
      return;
    }

    this.setState({ loading: true });
    if (header.docType === '161') {
      paramHeader = this.getBodyPO()
    } else {
      const ebelnList = items.filter(i => !isEmpty(i.ebeln));
      paramHeader = {
        IM_CC_DOCYEAR: header.matDocYear,
        IM_CC_MATDOC: header.matDoc,
        IM_GOODSMVT_HEADER: {
          DOC_DATE: moment().format('YYYY-MM-DD'),
          PSTNG_DATE: moment().format('YYYY-MM-DD'),
          REF_DOC_NO: '',
          HEADER_TXT: '',
          WEB_USER: auth.userId,
        },
        IM_MODE: modes[header.docType].mode,
        IM_MOVE_TYPE: modes[header.docType].isRequiredDocType ? header.docType : "",
        IM_TYPE_REF_DOC: modes[header.docType].isRequiredDocType ? ebelnList && ebelnList.length > 0 ? "R01" : "R02" : ""
      }

      if (selectedRowData.length !== items.length || header.docType == 122) { // Full
        paramItems = selectItems.map((data, i) => ({
          MATDOC_ITM: data.linE_ID,
          MATERIAL: data.matnr,
          PLANT: data.werks,
          STGE_LOC: data.lgort,
          SPEC_STOCK: 'E',
          PO_NUMBER: data.ebeln,
          PO_ITEM: data.ebelp,
          ENTRY_QNT: data.returnQty,
          ENTRY_UOM: data.meins,
          MOVE_REAS: data.reason,
        }))
        paramHeader = {
          ...paramHeader,
          IT_GOODSMVT_ITEM: {
            item: paramItems
          }
        }
      }
    }

    console.log("paramHeader==>", JSON.stringify(paramHeader));

    await apiServices.callApi('post', API_PATH.POST_GOODS_MOVEMENT, { goodsMovementRequest: paramHeader }).then(async res => {
      let result = get(res, 'data.result');
      let message = get(res, 'data.message');
      //console.log('results==>', result);
      //console.log('message==>', message);
      swal("Success", `บันทึกข้อมูลเอกสาร : ${result.eX_MATDOC} ${message}`, "success");
      this.clearInformation();
    }).catch(error => {
      const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล!');
      swal('Error', message, 'error');
    });
    this.setState({ loading: false });
  }

  render() {
    const {
      loading,
      dateFormat,
      foundMatDoc,
      isShowModal,

      modalType,
      header,
      items,
      columns,
      transactionTypes,
      outletOptions,
      dataStorages,
      dataUnits,
      selectedRowKeys,
      permissionPage,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }

    return (
      <div className="goods-return-container">
        <Spin spinning={loading}>
          <TitleHead text="รายการยกเลิกการรับสินค้าเข้า" icon="close-square" />
          <div className="middle-content">
            <Row gutter={20}>
              <Col md={4}>
                ประเภทเอกสาร
              </Col>
              <Col md={8}>
                <SelectCustom
                  options={transactionTypes}
                  value={header.docType}
                  onChange={(value) => this.changeTransactionType(value)}
                />
              </Col>

              {header.docType !== '161' && <>
                <Col md={4}>
                  ร้าน*
                </Col>
                <Col md={8}>
                  <SelectCustom
                    options={outletOptions}
                    pleaseChoose
                    onChange={(value) => this.handleChangeHeader('outletId', value)}
                    value={header.outletId}
                    name="outletId"
                  />
                </Col>
              </>}
            </Row>
            {header.docType === '161' && <>
              <Row gutter={20}>
                <Col md={4}>
                  ร้าน*
                </Col>
                <Col md={8}>
                  <SelectCustom
                    options={outletOptions}
                    pleaseChoose
                    onChange={(value) => this.handleChangeHeader('outletId', value)}
                    value={header.outletId}
                    name="outletId"
                  />
                </Col>
                <Col md={4}>
                  เลขที่ใบสั่งซื้อ
                </Col>
                <Col md={8}>
                  <InputSearchCustom
                    name='matDoc'
                    placeholder="4500002629"
                    value={header.matDoc}
                    onChange={(e) => this.handleChangeHeader('matDoc', e.target.value)}
                    onClickSearch={() => this.handleShowModal('purchaseOrder')}
                    onSubmit={() => this.handleSubmitSearchPOSAPPI()}
                    disabledButton={foundMatDoc}
                    disabled={foundMatDoc}
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col md={4}>
                  วันที่ออกเอกสาร
                </Col>
                <Col md={8}>
                  <DatePickerCustom
                    name="docDate"
                    value={header.docDate}
                    format={dateFormat}
                    allowClear={false}
                    disabled
                    onChange={(date) => this.handleChangeHeader('docDate', date)}
                  />
                </Col>
                <Col md={4}>
                  เลขที่ใบส่งสินค้า (ผู้ขาย)
                </Col>
                <Col md={8}>
                  <InputCustom
                    name="refDocNo"
                    placeholder="2000007121"
                    value={header.refDocNo}
                    onChange={(e) => this.handleChangeHeader('refDocNo', e.target.value)}
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col md={4}>
                  ผู้ขาย
                </Col>
                <Col md={8}>
                  <InputCustom
                    disabled
                    value={`${header.vendorCode} ${header.vendorName}`}
                  />
                </Col>
              </Row>
            </>}
            {header.docType !== '161' && <>
              <Row gutter={20}>
                <Col md={4}>
                  เอกสารวัสดุ
              </Col>
                <Col md={8}>
                  <InputSearchCustom
                    name='matDoc'
                    placeholder="4500002629"
                    value={header.matDoc}
                    onChange={(e) => this.handleChangeHeader('matDoc', e.target.value)}
                    onClickSearch={() => this.handleShowModal('goodsReturn', {})}
                    onSubmit={() => this.handleSubmitSearchDocSAPPI()}
                    disabledButton={foundMatDoc || header.docType === ''}
                    disabled={foundMatDoc}
                  />
                </Col>
                <Col md={4}>
                  ปี
              </Col>
                <Col md={8}>
                  <InputCustom
                    name="matDocYear"
                    value={header.matDocYear}
                    onChange={(e) => this.handleChangeHeader('matDocYear', e.target.value)}
                    maxLength={4}
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col md={4}>
                  เลขที่ใบส่งสินค้า(ผู้จำหน่าย)
              </Col>
                <Col md={8}>
                  <InputCustom
                    name="refDocNo"
                    //placeholder="2000007121"
                    value={header.refDocNo}
                    disabled={true}
                  />
                </Col>
                <Col md={4}>
                  วันที่ออกเอกสาร
              </Col>
                <Col md={8}>
                  <DatePickerCustom
                    name="docDate"
                    value={header.docDate}
                    format={dateFormat}
                    allowClear={false}
                    onChange={(date) => this.handleChangeHeader('docDate', date)}
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col md={4}>
                  รหัสผู้จำหน่าย
              </Col>
                <Col md={20}>
                  <InputCustom
                    name="vendorCode"
                    disabled={true}
                    value={`${header.vendorCode} ${header.vendorName}`}
                  />
                </Col>
              </Row>
            </>}
            <Row gutter={20}>
              <Col md={4}>
                หมายเหตุ
              </Col>
              <Col md={20}>
                <TextAreaCustom
                  name="remarks"
                  value={header.remarks}
                  onChange={(e) => this.handleChangeHeader('remarks', e.target.value)}
                  disabled={header.docType !== '161'}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={24} className="search-box">
                <ButtonCustom icon="search" type="primary" text="ค้นหา"
                  small
                  onClick={() => header.docType === '161' ? this.handleSubmitSearchPOSAPPI() : this.handleSubmitSearchDocSAPPI()}
                  disabled={foundMatDoc || header.docType === ''}
                />
              </Col>
            </Row>
            <div className="table-layout">
              <TableCustom
                rowSelection={rowSelection}
                selections={true}
                columns={columns}
                scroll={{ x: "max-content" }}
                data={items}
                rowKey={(row, index) => index}
                pagination={false}
                small />
            </div>
          </div>

          <div className="bottom-content">
            <div className="left-zone">
              <Link to={ROUTH_PATH.HOME} className="button">
                <ButtonCustom text='ยกเลิก' icon='close' type='danger' />
              </Link>
              <div className="button">
                <ButtonCustom text='ล้าง' icon='redo' type='danger' onClick={this.clearInformation} />
              </div>

            </div>
            <div className="right-zone">
              <div className="button">
                <ButtonCustom
                  text='บันทึก'
                  icon='save'
                  type="primary"
                  onClick={this.handleSubmitSave}
                  disabled={!foundMatDoc || !permissionPage.authCreate}
                />
              </div>
            </div>
          </div>

        </Spin>

        <GoodsReturnSearchModal
          isShowModal={modalType === 'goodsReturn' && isShowModal}
          handleCloseModal={this.handleCloseModal}
          onSelected={this.onSelected}
          outletId={header.outletId}
          docType={header.docType}
        />
        <PurchaseOrderSTOModal
          isShowModal={modalType === 'purchaseOrder' && isShowModal}
          handleCloseModal={this.handleCloseModal}
          onSelected={this.onSelectedPO}
          outletId={header.outletId}
          docType={poDocType}
          isShowReleaseIndicator
        />
        <StoreLocationModal
          isShowModal={modalType === 'storageLocation' && isShowModal}
          handleCloseModal={this.handleCloseModal}
          dataStorages={dataStorages}
          onSelected={this.onSelectedStoreLocation}
        />
        {/* <SaleUnitModal
          isShowModal={modalType === 'unit' && isShowModal}
          onCloseModal={this.handleCloseModal}
          units={dataUnits}
          onSelected={this.onSelectedUnit}
        /> */}
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    authData
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodsReturnContainer);