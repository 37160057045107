import React, { Component } from 'react'
import {
    TableCustom,
    ModalCustom,
} from 'components/common'
import { formatNumber } from 'helpers'
import { Row, Col } from 'antd'
import moment from 'moment'

export default class ShipmentDetailModal extends Component {
    state = {
        columns: [
            {
                title: 'ใบขาย',
                dataIndex: 'soNumber',
                key: 'soNumber',
            },
            {
                title: 'ใบจัด/ใบส่ง/ใบจ่าย',
                dataIndex: 'shipmentNumber',
                key: 'shipmentNumber',
            },
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productCode',
                key: 'productCode',
            },
            {
                title: 'สินค้า',
                dataIndex: 'productName',
                key: 'productName',
                width: 200,
            },
            {
                title: 'จำนวน',
                dataIndex: 'qty',
                key: 'qty',
                render: (props) => (
                    <div className="text-right">
                        {formatNumber(props, 3)}
                    </div>
                )
            },
            {
                title: 'หน่วย',
                dataIndex: 'unit',
                key: 'unit',
            },
        ],
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            data,
        } = this.props
        const {
            columns,
        } = this.state

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                title="รายละเอียดจัดส่งสินค้า"
            >
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={4}>
                        <div className="font-weight-bold">เลขที่จัดส่ง (Shipment) :</div>
                    </Col>
                    <Col sm={8}>
                        <div>{data.shipmentNumber}</div>
                    </Col>
                    <Col sm={4}>
                        <div className="font-weight-bold">วันที่จัดส่ง :</div>
                    </Col>
                    <Col sm={8}>
                        <div>{data.shipmentDatetime ? moment(data.shipmentDatetime).format('DD/MM/YYYY') : '-'}</div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <div className="font-weight-bold">เริ่ม :</div>
                    </Col>
                    <Col sm={8}>
                        <div>{data.startTime ? moment(data.startTime).format('HH:mm') : '-'}</div>
                    </Col>
                    <Col sm={4}>
                        <div className="font-weight-bold">ถึง :</div>
                    </Col>
                    <Col sm={8}>
                        <div>{data.endTime ? moment(data.endTime).format('HH:mm') : '-'}</div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <div className="font-weight-bold">วัน/เวลา ออก :</div>
                    </Col>
                    <Col sm={8}>
                        <div>{data.actualStartDate ? moment(data.actualStartDate).format('DD/MM/YYYY HH:mm') : '-'}</div>
                    </Col>
                    <Col sm={4}>
                        <div className="font-weight-bold">วัน/เวลา เสร็จ :</div>
                    </Col>
                    <Col sm={8}>
                        <div>{data.actualEndDate ? moment(data.actualEndDate).format('DD/MM/YYYY HH:mm') : '-'}</div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <div className="font-weight-bold">ร้าน :</div>
                    </Col>
                    <Col sm={8}>
                        <div>{data.outletId}</div>
                    </Col>
                    <Col sm={4}>
                        <div className="font-weight-bold">พนักงานขับรถ :</div>
                    </Col>
                    <Col sm={8}>
                        <div>{data.driverName}</div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <div className="font-weight-bold">รถ :</div>
                    </Col>
                    <Col sm={8}>
                        <div>{data.truckName}</div>
                    </Col>
                    <Col sm={4}>
                        <div className="font-weight-bold">โซน :</div>
                    </Col>
                    <Col sm={8}>
                        <div>{data.zoneName}</div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <div className="font-weight-bold">Remark :</div>
                    </Col>
                    <Col sm={20}>
                        <div>{data.remark}</div>
                    </Col>
                </Row>
                <Row style={{ paddingTop: 20 }}>
                    <Col sm={24}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={data.salesShipmentItems}
                            small
                            rowKey={(row, index) => index}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </ModalCustom>
        )
    }
}
