import React, { Component } from 'react'
import { Card } from 'antd'

export class CardCustom extends Component {
    render() {
        const {
            children,
            ...rest
        } = this.props

        return (
            <Card
                {...rest}
            >
                {children}
            </Card>
        )
    }
}
