import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import { formatNumber } from 'helpers'
import { Row, Col } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import {
    ButtonCustom,
    InputSearchCustom,
    TableCustom,
    DatePickerCustom,
    SelectCustom,
    InputCustom,
    RadioCustom,
    ModalCustom,
} from 'components/common'
import {
    ProductHierarchyModal,
} from 'components/form'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import './style.css'
const formatDateAPI = 'YYYY-MM-DD HH:mm:ss'

class ModalProduct extends Component {
    state = {
        columns: [
            {
                title: 'รหัสสินค้า',
                dataIndex: 'productId',
                key: 'productId',
                align: 'center',
            },
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'productName',
                key: 'productName',
                align: 'center',
                width: 220,
                render: (value) => (
                    <div className="text-left">{value}</div>
                )
            },
            {
                title: 'Barcode หลัก',
                dataIndex: 'productBarcode',
                key: 'productBarcode',
                align: 'center',
                render: (value) => (
                    <div className="text-left">{value}</div>
                )
            },
            {
                title: 'หน่วยขาย',
                dataIndex: 'unit',
                key: 'unit',
                align: 'center',
            },
            {
                title: 'ราคาขายปกติ',
                dataIndex: 'normalPrice',
                key: 'normalPrice',
                align: 'center',
                render: (value) => {
                    if (!value) return null
                    return <div className="text-right">
                        {formatNumber(value, 2)}
                    </div>
                }
            },
            {
                title: 'ราคาโปรโมชั่น',
                dataIndex: 'promotionPrice',
                key: 'promotionPrice',
                align: 'center',
                render: (value) => {
                    if (!value) return null
                    return <div className="text-right">
                        {formatNumber(value, 2)}
                    </div>
                }
            },
            {
                title: 'วันที่เริ่ม',
                dataIndex: 'startDate',
                key: 'startDate',
                align: 'center',
                render: (value) => (
                    value && <div>{moment(value).format('DD/MM/YYYY')}</div>
                )
            },
            {
                title: 'วันที่สิ้นสุด',
                dataIndex: 'endDate',
                key: 'endDate',
                align: 'center',
                render: (value) => (
                    value && <div>{moment(value).format('DD/MM/YYYY')}</div>
                )
            },
        ],
        loading: false,
        products: [],
        selectedRowKeys: [],
        outlets: [],
        productBrands: [],
        productCategories: [],
        productSubCategories: [],
        productClasses: [],
        productHierarchies: [],
        productHierarchyKey: '',
        priceTypes: [
            { label: 'ราคาปกติ', value: 'Normal' },
            { label: 'ราคาโปรโมชั่น', value: 'Promotion' },
        ],
        isShowModal: false,
        modalType: '',
        dateFormat: 'DD/MM/YYYY',
        filter: {
            outletId: '',
            barcode: '',
            productName: '',
            productCodeFrom: '',
            productCode: '',
            productCodeTo: '',
            productBrand: '',
            categoryCode: '',
            subCategoryCode: '',
            classCode: '',
            priceType: '',
            startDate: '',
            endDate: '',
            startDateEffect: moment(),
            endDateEffect: moment(),
            changeLabelDate: '',
        },
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    componentDidUpdate(prevProps) {
        if (this.props.priceType !== prevProps.priceType) {
            this.setState({
                products: [],
                selectedRowKeys: [],
                filter: this.props.priceType === 'Promotion' ? {
                    ...this.state.filter,
                    startDateEffect: moment(),
                    endDateEffect: moment(),
                } : this.state.filter,
            })
        }
    }

    // get data default for dropdown
    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth } = this.props
        const { arrayOutlet } = auth

        // outlet
        const outletIds = arrayOutlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, { outletIds }).then(res => {
            let outlets = get(res, 'data.results', [])
            if (res.status === 200) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                const { filter } = this.state
                this.setState({
                    outlets,
                    filter: { ...filter, outletId: get(outlets, '0.value') },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product brand
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_BRAND).then(res => {
            let productBrands = get(res, 'data.results', [])
            if (res.status === 200) {
                productBrands = productBrands.map(data => (
                    { ...data, label: `${data.brandCode} - ${data.descriptionThai}`, value: data.brandCode }
                ))
                this.setState({ productBrands: [{ label: 'ทั้งหมด', value: '' }, ...productBrands] })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Brand ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product category
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_CATEGORY).then(res => {
            let productCategories = get(res, 'data.results', [])
            if (res.status === 200) {
                productCategories = productCategories.map(data => (
                    { ...data, label: `${data.categoryCode} - ${data.descriptionThai}`, value: data.categoryCode }
                ))
                this.setState({ productCategories })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Category ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product sub category
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_SUB_CATEGORY).then(res => {
            let productSubCategories = get(res, 'data.results', [])
            if (res.status === 200) {
                productSubCategories = productSubCategories.map(data => (
                    { ...data, label: `${data.subCategoryCode} - ${data.descriptionThai}`, value: data.subCategoryCode }
                ))
                this.setState({ productSubCategories })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Sub Category ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // product class
        await apiServices.callApi('get', API_PATH.GET_MASTER_PRODUCT_CLASS).then(res => {
            let productClasses = get(res, 'data.results', [])
            if (res.status === 200) {
                productClasses = productClasses.map(data => (
                    { ...data, label: `${data.classCode} - ${data.descriptionThai}`, value: data.classCode }
                ))
                this.setState({ productClasses })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูล Class ได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        switch (key) {
            case 'outletId':
                this.setState({
                    filter: {
                        ...filter,
                        [key]: val,
                    },
                    products: [],
                })
                break
            case 'barcode':
            case 'productName':
            case 'productCodeFrom':
            case 'productCode':
            case 'productCodeTo':
                // set date is null
                let filterDate = { startDate: null, endDate: null }
                filterDate = (this.props.priceType === 'Promotion') ? filterDate : { ...filterDate, startDateEffect: null, endDateEffect: null, changeLabelDate: null }
                this.setState({
                    filter: {
                        ...filter,
                        ...filterDate,
                        [key]: val,
                    }
                })
                break
            case 'categoryCode':
                this.setState({
                    filter: {
                        ...filter,
                        [key]: val,
                        subCategoryCode: '',
                        classCode: '',
                    },
                })
                break
            case 'subCategoryCode':
                this.setState({
                    filter: {
                        ...filter,
                        [key]: val,
                        classCode: '',
                    },
                })
                break
            case 'changeLabelDate':
                //filterDate = { startDate: null, endDate: null }
                //filterDate = (this.props.priceType === 'Promotion') ? filterDate : { ...filterDate, startDateEffect: null, endDateEffect: null }
                this.setState({
                    // filter: {
                    //     ...filter,
                    //     ...filterDate,
                    //     [key]: val,
                    // }
                    filter: {
                        ...filter,
                        barcode: '',
                        productName: '',
                        productCodeFrom: '',
                        productCode: '',
                        productCodeTo: '',
                        productBrand: '',
                        categoryCode: '',
                        subCategoryCode: '',
                        classCode: '',
                        priceType: '',
                        startDate: '',
                        endDate: '',
                        startDateEffect: '',
                        endDateEffect: '',
                        [key]: val,
                    }
                })
                break
            default:
                this.setState({ filter: { ...filter, [key]: val } })
        }
    }

    getBody = () => {
        const { filter } = this.state
        const { auth, priceType } = this.props

        return {
            outletId: filter.outletId,
            barcode: filter.barcode,
            productName: filter.productName,
            productIdFrom: filter.productCodeFrom,
            productId: filter.productCode,
            productIdTo: filter.productCodeTo,
            brand: filter.productBrand,
            productCategory: filter.categoryCode,
            productSubCategory: filter.subCategoryCode,
            productClass: filter.classCode,
            startDate: filter.startDate && filter.startDate.startOf('day').format(formatDateAPI),
            endDate: filter.endDate && filter.endDate.startOf('day').format(formatDateAPI),
            startDateEffect: filter.startDateEffect && filter.startDateEffect.startOf('day').format(formatDateAPI),
            endDateEffect: filter.endDateEffect && filter.endDateEffect.startOf('day').format(formatDateAPI),
            changeLabelDate: filter.changeLabelDate && filter.changeLabelDate.startOf('day').format(formatDateAPI),
            priceType,
            storageLocationIds: auth.arrayStorageLocationId,
        }
    }

    validateFilter = () => {
        const { filter } = this.state
        if (!filter.outletId) return { isError: true, message: 'กรุณาระบุร้าน' }
        if (filter.startDate && filter.endDate && (filter.endDate).isBefore(filter.startDate)) return { isError: true, message: 'รูปแบบวันที่ปรับราคาไม่ถูกต้อง' }
        if (filter.startDateEffect && filter.endDateEffect && (filter.endDateEffect).isBefore(filter.startDateEffect)) return { isError: true, message: 'รูปแบบวันที่ราคามีผลไม่ถูกต้อง' }
        return { isError: false }
    }

    onClickSearchProduct = async () => {
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        const body = this.getBody()
        this.setState({ loading: true })
        await apiServices.callApi('post', API_PATH.POST_GET_PRODUCT_FOR_CREATE_LABELING, body).then(res => {
            let products = get(res, 'data.results')
            if (res.status === 200) {
                // add index for assigned a unique key in table
                products = products.map((product, index) => ({ ...product, index }))
                this.setState({ products, selectedRowKeys: [] })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูลสินค้า กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onChangeSelection = (selectedRowKeys, selectedRowData) => {
        this.setState({ selectedRowKeys })
    }

    /*
    // logic old web
    disabledDate = (current) => {
        const { filter } = this.state
        return (current && current > moment(filter.startDate).add(1, 'day'))
            || (current <= moment(filter.startDate).subtract(1, 'day'))
    }

    disabledDateEffect = (current) => {
        const { filter } = this.state
        return (current && current >= moment(filter.startDateEffect).add(30, 'day'))
            || (current < moment(filter.startDateEffect))
    }
    */

    openModal = (modalType) => {
        this.setState({ isShowModal: true, modalType })
    }

    closeModal = () => {
        this.setState({ isShowModal: false, modalType: '' })
    }

    openModalProductHierarchy = (type = '') => {
        const { productCategories, filter } = this.state
        const { categoryCode, subCategoryCode } = filter

        let productHierarchies = []
        let textFilter = ''

        switch (type) {
            case 'categoryCode':
                productHierarchies = productCategories
                break
            case 'subCategoryCode':
                productHierarchies = this.filterSubCatagories((categoryCode).substring(0, 2))
                break
            case 'classCode':
                if (subCategoryCode) textFilter = (subCategoryCode).substring(0, 4)
                else if (categoryCode) textFilter = (categoryCode).substring(0, 2)
                productHierarchies = this.filterClasses(textFilter)
                break
            default:
                break
        }

        this.setState({
            productHierarchies,
            productHierarchyKey: type,
        }, () => {
            this.openModal('productHierarchy')
        })
    }

    filterSubCatagories = (text) => {
        return this.state.productSubCategories.filter(subCategory =>
            new RegExp('^' + text, 'gi').test(subCategory.subCategoryCode)
        )
    }

    filterClasses = (text) => {
        return this.state.productClasses.filter(classData =>
            new RegExp('^' + text, 'gi').test(classData.classCode)
        )
    }

    closeModalProductHierarchy = () => {
        this.setState({
            productHierarchies: [],
            productHierarchyKey: '',
        })
        this.closeModal()
    }

    onSelectedProductHierarchy = (data) => {
        const { productHierarchyKey } = this.state
        this.changeFilter(productHierarchyKey, data[productHierarchyKey])
        this.closeModalProductHierarchy()
    }

    onSelectProducts = () => {
        const { selectedRowKeys, filter, products } = this.state
        const productsSelected = products.filter((product, index) => selectedRowKeys.includes(index));
        this.props.onSelectProducts(productsSelected, filter)
        this.setState({ selectedRowKeys: [] })
    }

    render() {
        const {
            isShowModal,
            closeModal,
            disabledOutletId,
        } = this.props
        const {
            loading,
            columns,
            products,
            filter,
            dateFormat,
            outlets,
            productBrands,
            priceTypes,
            productHierarchies,
            productCategories,
            productSubCategories,
            productClasses,
            isShowModal: isShowModalState,
            modalType,
            productHierarchyKey,
            selectedRowKeys,
        } = this.state

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onChangeSelection,
            // เลือกทั้งตาราง
            onSelectAll: (data) => {
                if (data) {
                    this.setState({ selectedRowKeys: Array.from(Array(products.length), (d, i) => i) })
                }else{
                    this.setState({ selectedRowKeys: []})
                }
            }
        }

        const cateDesc = productCategories.find(productCategory => productCategory.categoryCode === filter.categoryCode)
        const subCateDesc = productSubCategories.find(productSubCategory => productSubCategory.subCategoryCode === filter.subCategoryCode)
        const classDesc = productClasses.find(productClass => productClass.classCode === filter.classCode)

        return (
            <ModalCustom
                className="product-barcode-modal"
                isShowModal={isShowModal}
                onCloseModal={closeModal}
                title="ค้นหาสินค้า"
                width="70%"
                footer={<Row gutter={10} type="flex" justify="end">
                    <Col>
                        <ButtonCustom
                            icon="close"
                            text="ปิด"
                            type="danger"
                            small
                            onClick={closeModal}
                        />
                    </Col>
                    <Col>
                        <ButtonCustom
                            icon="plus"
                            text="เลือกรายการ"
                            small
                            onClick={(e) => this.onSelectProducts()}
                            disabled={isEmpty(selectedRowKeys)}
                        />
                    </Col>
                </Row>}
            >
                <Row gutter={20}>
                    <Col sm={24} md={10}>
                        <SelectCustom
                            label="Site *"
                            options={outlets}
                            small
                            onChange={(val) => this.changeFilter('outletId', val)}
                            value={filter.outletId}
                            disabled={disabledOutletId}
                        />
                    </Col>
                    <Col sm={24} md={10} >
                        <InputCustom
                            label="Barcode"
                            small
                            onChange={(e) => this.changeFilter('barcode', e.target.value)}
                            placeholder="Barcode"
                            value={filter.barcode}
                        />
                    </Col>
                    <Col sm={24} md={10}>
                        <InputCustom
                            label="ชื่อสินค้า"
                            small
                            onChange={(e) => this.changeFilter('productName', e.target.value)}
                            placeholder="ชื่อสินค้า"
                            value={filter.productName}
                        />
                    </Col>
                    <Col sm={24} md={10}>
                        <InputCustom
                            label="รหัสสินค้า"
                            small
                            onChange={(e) => this.changeFilter('productCode', e.target.value)}
                            placeholder="รหัสสินค้า"
                            value={filter.productCode}
                        />
                        <div style={{ fontSize: '12px', paddingBottom: '4px' }}>
                            ใส่ : เพื่อค้นหาข้อมูลแบบช่วง หรือใส่ , เพื่อค้นหาข้อมูลแบบรายตัว
                        </div>
                    </Col>
                    <Col sm={24} md={10}>
                        <SelectCustom
                            label="Brand"
                            options={productBrands}
                            small
                            onChange={(val) => this.changeFilter('productBrand', val)}
                            value={filter.productBrand}
                        />
                    </Col>
                    <Col sm={24} md={10}>
                        <InputSearchCustom
                            label="Category"
                            onClickSearch={() => this.openModalProductHierarchy('categoryCode')}
                            small
                            disabled
                            onClickClearValue={filter.categoryCode ? () => this.changeFilter('categoryCode', '') : null}
                            value={`${cateDesc ? `${filter.categoryCode} - ${cateDesc.descriptionThai}` : filter.categoryCode}`}
                        />
                    </Col>
                    <Col sm={24} md={10}>
                        <InputSearchCustom
                            label="Sub Category"
                            onClickSearch={() => this.openModalProductHierarchy('subCategoryCode')}
                            small
                            disabled
                            onClickClearValue={filter.subCategoryCode ? () => this.changeFilter('subCategoryCode', '') : null}
                            value={`${subCateDesc ? `${filter.subCategoryCode} - ${subCateDesc.descriptionThai}` : filter.subCategoryCode}`}
                        />
                    </Col>
                    <Col sm={24} md={10}>
                        <InputSearchCustom
                            label="Class"
                            onClickSearch={() => this.openModalProductHierarchy('classCode')}
                            small
                            disabled
                            onClickClearValue={filter.classCode ? () => this.changeFilter('classCode', '') : null}
                            value={`${classDesc ? `${filter.classCode} - ${classDesc.descriptionThai}` : filter.classCode}`}
                        />
                    </Col>
                    <Col sm={24} md={10} className="input-range-box">
                        <div className="input-range-label">วันที่ปรับราคาตั้งแต่</div>
                        <div className="input-range">
                            <div className="input-range-item" style={{ marginRight: '5px' }}>
                                <DatePickerCustom
                                    format={dateFormat}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('startDate', val)}
                                    value={filter.startDate}
                                />
                            </div>
                            <div className="input-range-item">
                                <DatePickerCustom
                                    format={dateFormat}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('endDate', val)}
                                    value={filter.endDate}
                                // disabledDate={this.disabledDate}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} md={10} className="input-range-box">
                        <div className="input-range-label">วันที่ราคามีผลตั้งแต่ *</div>
                        <div className="input-range">
                            <div className="input-range-item" style={{ marginRight: '5px' }}>
                                <DatePickerCustom
                                    format={dateFormat}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('startDateEffect', val)}
                                    value={filter.startDateEffect}

                                />
                            </div>
                            <div className="input-range-item">
                                <DatePickerCustom
                                    format={dateFormat}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('endDateEffect', val)}
                                    value={filter.endDateEffect}
                                // disabledDate={this.disabledDateEffect}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} md={10}>
                        <RadioCustom
                            small
                            label="ประเภทราคา"
                            options={priceTypes}
                            // value from barcode type selected
                            value={this.props.priceType}
                            disabled={!!this.props.priceType}
                        />
                    </Col>
                    <Col sm={24} md={10}>
                        <DatePickerCustom
                            format={dateFormat}
                            small
                            allowClear={false}
                            label="วันที่ต้องเปลี่ยนป้ายราคา"
                            onChange={(val) => this.changeFilter('changeLabelDate', val)}
                            value={filter.changeLabelDate}
                        />
                    </Col>
                    <Col sm={24} md={10} className="info-box">

                    </Col>
                    <Col sm={24} md={10} className="info-box">
                        <ButtonCustom
                            icon="search"
                            text="ค้นหาสินค้า"
                            small
                            onClick={this.onClickSearchProduct}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={24}>
                        <div className="table-layout">
                            <TableCustom
                                rowSelection={rowSelection}
                                height="calc(100vh - 511px)"
                                columns={columns}
                                data={products}
                                loading={loading}
                                isFullContent
                                rowKey="index"
                                small
                            />
                        </div>
                    </Col>
                </Row>
                <ProductHierarchyModal
                    isShowModal={isShowModalState && modalType === 'productHierarchy'}
                    onCloseModal={this.closeModalProductHierarchy}
                    onSelected={this.onSelectedProductHierarchy}
                    data={productHierarchies}
                    productHierarchyKey={(productHierarchyKey || '').replace(/Code$/, '')}
                />
            </ModalCustom>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalProduct)