import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, find, sumBy, map } from 'lodash'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    SelectHero,
    DatePickerCustom,
    InputSearch,
    ModalCustomerSearch,
    ModalSaleEmployee,
} from 'components'
import { Row, Col, Spin } from 'antd'
import { ROUTH_PATH } from 'routes'
import { apiServices, API_PATH } from 'apiServices'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import './style.css'

class QuotationContainer extends Component {
    state = {
        columns: [
            {
                title: 'วันที่เอกสาร',
                dataIndex: 'createdDatetime',
                key: 'createdDatetime',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {moment(value).format('DD/MM/YYYY')}
                    </div>
                )
            },
            {
                title: 'ใบเสนอราคา',
                dataIndex: 'quotationNumber',
                key: 'quotationNumber',
                align: 'center',
                render: (value, row) => (
                    <Link to={`${ROUTH_PATH.QUOTATION_UPDATE_LINK}/${row.quotationNumber}/${row.outletId}`}>
                        <ButtonCustom type="link" text={value} />
                    </Link>
                )
            },
            {
                title: 'ลูกค้า',
                dataIndex: 'customerName',
                key: 'customerName',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'ยอดเงินรวม',
                dataIndex: 'netAmount',
                key: 'netAmount',
                align: 'center',
                render: (value) => {
                    return (
                        <div className="align-right">
                            {(value || 0)
                                .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </div>
                    )
                }
            },
            {
                title: 'พนักงานขาย',
                dataIndex: 'saleEmpName',
                key: 'saleEmpName',
                align: 'center',
                render: (value) => (
                    <div className="align-left">
                        {value}
                    </div>
                )
            },
            {
                title: 'User ID',
                dataIndex: 'saleEmpCode',
                key: 'saleEmpCode',
                align: 'center',
            },
            {
                title: 'ร้าน',
                dataIndex: 'outletId',
                key: 'outletId',
                align: 'center',
            },
            {
                title: 'ช่องทาง',
                dataIndex: 'saleChannelCode',
                key: 'saleChannelCode',
                align: 'center',
            },
            {
                title: 'สถานะ',
                dataIndex: 'documentStatus',
                key: 'documentStatus',
                align: 'center',
                render: (value) => {
                    const status = !isEmpty(this.state.docStatuses) && find(this.state.docStatuses, { statusCode: value })
                    return (status) ? status.statusDescription || value : value
                }
            },
        ],
        loading: false,
        quotationNumber: '',
        quotations: [],
        customers: [],
        outlets: [],
        outlet: '',
        channels: [],
        users: [],
        saleEmployees: [],
        docStatuses: [],
        widthLabel: '90px',
        dateFormat: 'DD/MM/YYYY',
        dateFormatAPI: 'YYYY/MM/DD',
        filter: {
            customer: '',
            jobId: '',
            outlet: '',
            channel: '',
            user: '',
            saleEmployee: '',
            docStatus: '',
            startDate: moment().subtract(7, 'day'),
            endDate: moment(),
        },
        isShowModal: false,
        modalType: '',
    }

    componentDidMount() {
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })
        const { auth } = this.props
        const { filter } = this.state

        // outlet
        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, { outletIds: auth.arrayOutlet }).then(res => {
            let outlets = get(res, 'data.results', [])
            if (res.status === 200) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                const outletIdDefault = get(outlets, '0.value', '')
                this.setState({
                    outlets,
                    outlet: outletIdDefault,
                    filter: { ...filter, outlet: outletIdDefault },
                })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // sale channel
        await apiServices.callApi('get', API_PATH.GET_MASTER_SALE_CHANNEL).then(res => {
            let saleChannels = get(res, 'data.results', [])
            if (res.status === 200) {
                saleChannels = saleChannels.map(data => (
                    { ...data, label: `${data.saleChannelCode} - ${data.saleChannelDescription}`, value: data.saleChannelCode }
                ))
                this.setState({ channels: saleChannels })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลช่องทางได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        // document status
        await apiServices.callApi('get', `${API_PATH.GET_MASTER_SALE_ORDER_STATUS}/QT`).then(res => {
            let docStatuses = get(res, 'data.results', [])
            if (res.status === 200) {
                docStatuses = docStatuses.map(data => (
                    { ...data, label: data.statusDescription, value: data.statusCode }
                ))
                this.setState({ docStatuses })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลสถานะเอกสารได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    handleShowModal = (modalType) => {
        if (modalType === 'saleEmployee') {
            this.onSearchSaleEmployee()
        }
        this.setState({ isShowModal: true, modalType })
    }

    handleCloseModal = () => {
        this.setState({ isShowModal: false, modalType: '' })
    }

    onSearchCustomer = async (typeKeyword, keyword) => {
        this.setState({ loading: true })
        const url = `${API_PATH.GET_CUSTOMER}?${typeKeyword}=${keyword}&RequireSoldTo=true`
        await apiServices.callApi('get', url).then(res => {
            const customers = get(res, 'data.results')
            if (customers) {
                this.setState({ customers })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลลูกค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
        this.setState({ loading: false })
    }

    onSelectedCustomer = (data) => {
        this.changeFilter('customer', data.customerId)
        this.handleCloseModal()
    }

    onSearchSaleEmployee = async () => {
        this.setState({ loading: true })
        const { outlets } = this.state
        const url = `${API_PATH.GET_SALE_EMPLOYEE}?OutletId=${outlets[0].outletId}`
        await apiServices.callApi('get', url).then(res => {
            const saleEmployees = get(res, 'data.results')
            if (saleEmployees) {
                this.setState({ saleEmployees })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลพนักงานขายได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })
        this.setState({ loading: false })
    }

    onSelectedSaleEmployee = (data) => {
        this.changeFilter('saleEmployee', data.saleEmployeeId)
        this.handleCloseModal()
    }

    changeQuotationNumber = (e) => {
        this.setState({ quotationNumber: e.target.value })
    }

    changeOutlet = (outlet) => {
        this.setState({ outlet })
    }

    searchQuotationNumber = async (e) => {
        e.preventDefault()
        const { quotationNumber, outlet } = this.state
        if (!quotationNumber) return

        await apiServices.callApi('get', `${API_PATH.GET_QUOTATION}?quotationnumber=${quotationNumber}&outletId=${outlet}`).then(res => {
            const resQuotation = get(res, 'data.0')
            if (resQuotation) {
                this.props.history.push(`${ROUTH_PATH.QUOTATION_UPDATE_LINK}/${quotationNumber}/${outlet}`)
            } else {
                throw res
            }
        }).catch(error => {
            swal('Error', 'ไม่พบข้อมูลใบเสนอราคา', 'error')
        })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (!filter.startDate || !filter.endDate) return { isError: true, message: 'กรุณาระบุวันที่เอกสาร' }
        if ((filter.endDate).isBefore(filter.startDate)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }
        const duration = moment.duration(filter.endDate.diff(filter.startDate))
        if (duration.as('days') > 31) return { isError: true, message: 'กรุณาระบุวันที่เอกสารไม่เกิน 31 วัน' }
        if (!filter.outlet) return { isError: true, message: 'กรุณาระบุร้านค้า' }
        return { isError: false }
    }

    searchQuotation = async () => {
        const { dateFormatAPI, filter, channels } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = {
            startDate: filter.startDate.format(dateFormatAPI),
            endDate: filter.endDate.format(dateFormatAPI),
            customerName: filter.customer,
            jobId: filter.jobId,
            outletID: filter.outlet,
            saleChannels: filter.channel ? [filter.channel] : map(channels, 'value'),
            userId: filter.user,
            saleEmpCode: filter.saleEmployee,
            docStatus: filter.docStatus,
            module: "quotation",
        }
        //await apiServices.callApi('post', API_PATH.POST_QUOTATION_LIST, body).then(res => {
        await apiServices.callApi('post', API_PATH.POST_GET_REPORT_QUOTATION_LIST, body).then(res => {
            if (res.status === 200) {
                const quotations = get(res, 'data', [])
                this.setState({ quotations })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.title', 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
            console.error(get(error, 'response.data.errors'))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            quotations,
            quotationNumber,
            customers,
            outlets,
            outlet,
            channels,
            saleEmployees,
            docStatuses,
            modalType,
            loading,
            columns,
            dateFormat,
            widthLabel,
            isShowModal,
            filter,
        } = this.state

        return (
            <div className="quotation-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="ใบเสนอราคา"
                        icon="file-text"
                        nodeRight={
                            <div className="search-quotation-box">
                                <div className="text-search">เลขที่ใบเสนอราคา</div>
                                <div className="text-search" style={{ width: '180px' }}>
                                    <SelectHero
                                        name="outlet"
                                        options={outlets}
                                        value={outlet}
                                        small
                                        onChange={this.changeOutlet}
                                    />
                                </div>
                                <InputSearch
                                    small
                                    value={quotationNumber}
                                    onChange={this.changeQuotationNumber}
                                    handleClickSearch={this.searchQuotationNumber}
                                    handleSubmitSearch={this.searchQuotationNumber} />
                            </div>
                        }
                    />
                    <div className="middle-content">
                        <Row gutter={8}>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="startDate"
                                    label="วันที่เอกสาร"
                                    value={filter.startDate}
                                    format={dateFormat}
                                    small
                                    allowClear={false}
                                    onChange={(date) => this.changeFilter('startDate', date)}
                                />
                            </Col>
                            <Col sm={6}>
                                <DatePickerCustom
                                    widthDefault={widthLabel}
                                    name="endDate"
                                    label="ถึง"
                                    value={filter.endDate}
                                    format={dateFormat}
                                    small
                                    allowClear={false}
                                    onChange={(date) => this.changeFilter('endDate', date)}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputSearch
                                    handleClickSearch={this.handleShowModal.bind(this, 'customer')}
                                    handleSubmitSearch={(e) => e.preventDefault()}
                                    label="ลูกค้า"
                                    small
                                    onChange={(e) => this.changeFilter('customer', e.target.value)}
                                    value={filter.customer}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputSearch
                                    handleClickSearch={this.handleShowModal.bind(this, 'jobId')}
                                    handleSubmitSearch={(e) => e.preventDefault()}
                                    label="Job ID"
                                    small
                                    onChange={(e) => this.changeFilter('jobId', e.target.value)}
                                    value={filter.jobId}
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    name="outlet"
                                    label="ร้านค้า"
                                    options={outlets}
                                    value={filter.outlet}
                                    small
                                    onChange={(val) => this.changeFilter('outlet', val)}
                                />
                            </Col>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    name="channel"
                                    label="ช่องทาง"
                                    options={channels}
                                    optionsDefault={[{ value: '', label: 'ทั้งหมด' }]}
                                    value={filter.channel}
                                    small
                                    onChange={(val) => this.changeFilter('channel', val)}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputSearch
                                    handleClickSearch={this.handleShowModal.bind(this, 'userId')}
                                    handleSubmitSearch={(e) => e.preventDefault()}
                                    label="User ID"
                                    small
                                    onChange={(e) => this.changeFilter('user', e.target.value)}
                                    value={filter.user}
                                />
                            </Col>
                            <Col sm={6}>
                                <InputSearch
                                    handleClickSearch={this.handleShowModal.bind(this, 'saleEmployee')}
                                    handleSubmitSearch={(e) => e.preventDefault()}
                                    label="พนักงานขาย"
                                    small
                                    onChange={(e) => this.changeFilter('saleEmployee', e.target.value)}
                                    value={filter.saleEmployee}
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col sm={6}>
                                <SelectHero
                                    widthDefault={widthLabel}
                                    optionsDefault={[{ value: '', label: 'ทั้งหมด' }]}
                                    label="สถานะเอกสาร"
                                    options={docStatuses}
                                    value={filter.docStatus}
                                    small
                                    onChange={(val) => this.changeFilter('docStatus', val)}
                                />
                            </Col>
                            <Col sm={18} className="search-box">
                                <ButtonCustom icon="search" type="primary" text="ค้นหา" small onClick={this.searchQuotation} />
                            </Col>
                        </Row>
                        <div className="table-layout">
                        {/* scroll={{ x: "max-content" }} */}
                            <TableCustom columns={columns}  height="calc(100vh - 346px)" data={quotations} rowKey={(row, index) => index} small />
                        </div>
                    </div>
                    <ModalCustomerSearch
                        isShowModal={isShowModal && modalType === 'customer'}
                        handleCloseModal={this.handleCloseModal}
                        loading={loading}
                        onSearch={this.onSearchCustomer}
                        onSelected={this.onSelectedCustomer}
                        data={customers}
                    />
                    <ModalSaleEmployee
                        isShowModal={isShowModal && modalType === 'saleEmployee'}
                        handleCloseModal={this.handleCloseModal}
                        loading={loading}
                        onSelected={this.onSelectedSaleEmployee}
                        data={saleEmployees}
                    />
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationContainer)