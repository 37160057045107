import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TableCustom, ButtonCustom, ModalCustom } from "components"
import { authData } from 'actions/actionsCreator';
import { formatNumber, filterStoragesByStorageAuth } from 'helpers'
import './style.css'

class StoreLocationModal extends Component {
    state = {
        //loading: false,
        params: {
            productId: '',
            salesUnit: '',
            outletId: '',
        },

        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: 'no',
                key: 'no',
                ellipsis: true,
                render: (value, full, row) => <div style={{ textAlign: 'center' }}>
                    <ButtonCustom onClick={() => this.props.onSelected(this.handleDataSelected(full))} small text="เลือก" />
                </div>
            },
            {
                title: 'คลัง',
                align: 'center',
                dataIndex: 'storageLocationId',
                key: 'storageLocationId',
                ellipsis: true,
            },
            {
                title: 'จำนวน',
                align: 'center',
                dataIndex: 'qty',
                key: 'qty',
                ellipsis: true,
                render: (props) => (
                    <div className="text-right">{formatNumber(props, 3)}</div>
                ),
            },
            {
                title: 'หน่วย',
                align: 'center',
                dataIndex: 'unitId',
                key: 'unitId',
                ellipsis: true,
            },
        ],
    }

    handleDataSelected = (dataSelected) => {
        const {rowSelected} = this.props;
        return {rowSelected, dataSelected};
    }

    render() {
        const {
            isShowModal,
            handleCloseModal,
            dataStorages,
            loading,
            requireStock
        } = this.props;
        const {
            columns,
        } = this.state;
        // กันคอมโพเน้นเก่าพัง
        let checkRequireStock = requireStock || false;
        // qty
        let storagesFiltered = filterStoragesByStorageAuth(dataStorages)
        // เลือกเฉพาะคลังที่มีสินค้า
        if(checkRequireStock){
            storagesFiltered = storagesFiltered.filter(store=> store.qty > 0)
        }
        
        return (
            <ModalCustom
                title="คลัง(Store Location)"
                isShowModal={isShowModal}
                handleCloseModal={handleCloseModal}
                width="50%"
                footer={false}
                destroyOnClose={true}
            >
                <div className='store-location-search-modal'>
                    <div className='content'>
                        <TableCustom
                            height="calc(100vh - 311px)"
                            columns={columns}
                            data={storagesFiltered}
                            loading={loading}
                            rowKey={(row, i) => i} />
                    </div>
                </div>
            </ModalCustom>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreLocationModal);