import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";

import { Row, Col } from "antd";
import {
  InputFormRedux,
  SelectRedux,
} from 'components';

import { VALIDATE, customerType } from "helpers";
import { apiServices, API_PATH } from "apiServices";
import swal from '@sweetalert/with-react';
import { get, first, isEmpty } from 'lodash';

class CustomerDetailFormRedux extends React.Component {
  state = {
    dataCustomerTitles: [],
    dataDistricts: [],
    district: null,
    subDistrict: null,
    portalCode: null,
    valueCustomerType: first(customerType).value,
  }

  componentDidMount() {
    this.fetchDataDefault();
  }

  fetchDataDefault = async () => {
    const { initialValues, setProvince, setProvinceName, setDistrict, setSubDistrict } = this.props;
    let dataProvices = [];
    //console.log("fetchDataDefault initialValues==>", initialValues);
    //คำนำหน้าชื่อ Title
    await apiServices.callApi('get', API_PATH.GET_MASTER_CUSTOMER_TITLE).then(res => {
      let results = get(res, 'data.results');
      let dataCustomerTitles = results.map(data => ({
        label: data.titleDescription,
        value: data.titleId
      }));
      this.setState({ dataCustomerTitles });
    }).catch(error => {
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลคำนำหน้าชื่อ!')
      swal('Error', message, 'error')
    });

    //ประเทศ Country
    await apiServices.callApi('get', API_PATH.GET_MASTER_REGION_COUNTRY).then(async res => {
      let results = get(res, 'data.results');
      let dataRegionCountries = results.map(data => ({
        label: data.countryName,
        value: data.countryId
      }));
      this.setState({ dataRegionCountries, country: first(dataRegionCountries) });

      //จังหวัด Province
      await apiServices.callApi("get", API_PATH.GET_MASTER_PROVINCE + `?CountryId=${first(results).countryId}`).then(async res => {
        let results = get(res, 'data.results');
        dataProvices = results.map(data => ({
          label: data.provinceName,
          value: data.provinceCode
        }));
        this.setState({ dataProvices });
      }).catch(error => {
        const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลจังหวัด!')
        swal('Error', message, 'error')
      });
    }).catch(error => {
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูลประเทศ!')
      swal('Error', message, 'error')
    });

    if (!isEmpty(initialValues) && !isEmpty(initialValues.customerProvince)) {
      let provinceCode = initialValues.customerProvince;
      let provinceName = initialValues.customerProvince;
      if (!isEmpty(dataProvices)) {
        const dataselectProvice = dataProvices.filter(provice => provice.label === initialValues.customerProvince);
        if (!isEmpty(dataselectProvice) && dataselectProvice.length > 0) {
          provinceCode = first(dataselectProvice).value;
        } else {
          const prov = dataProvices.filter(provice => provice.value === initialValues.customerProvince);
          provinceName = first(prov).label;
        }
      }
      await this.handleChangeSelectProvicne(provinceCode);
      setProvince(provinceCode);
      setProvinceName(provinceName);
    }

    if (!isEmpty(initialValues) && !isEmpty(initialValues.customerDistrict)) {
      await this.handleChangeDistricts(initialValues.customerDistrict);
      setDistrict(initialValues.customerDistrict);
    }

    if (!isEmpty(initialValues) && !isEmpty(initialValues.customerSubDistrict)) {
      setSubDistrict(initialValues.customerSubDistrict);
    }
  }

  handleChangeSelectProvicne = async (value) => {
    this.setState({ loading: true });
    const { dataProvices } = this.state;
    const { setProvinceName, setDistrict, setSubDistrict, setPortalCode } = this.props;

    const prov = dataProvices.find(provice => provice.value === value);
    let provinceName = prov.label;
    setProvinceName(provinceName);

    //เขต/อำเภอ
    await apiServices.callApi('get', API_PATH.GET_MASTER_DISTRICTS + `?ProvinceCode=${value}`).then(async res => {
      let results = get(res, 'data.results');
      let dataDistricts = results.map(e => ({
        label: e.district,
        value: e.district,
      }));
      this.setState({ dataDistricts });
      setDistrict(null);
      setSubDistrict(null);
      setPortalCode(null);
    }).catch(error => {
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "เขต/อำเภอ"!')
      swal('Error', message, 'error')
    });

    this.setState({ loading: false });
  }

  handleChangeDistricts = async (value) => {
    this.setState({ loading: true });
    const { setSubDistrict, setPortalCode } = this.props;
    //แขวง/ตำบล
    await apiServices.callApi('get', API_PATH.GET_MASTER_SUB_DISTRICTS + `?District=${value}`).then(async res => {
      let results = get(res, 'data.results');
      let dataSubDistricts = results.map(e => ({
        ...e,
        label: e.subDistrict,
        value: e.subDistrict,
      }));
      this.setState({ dataSubDistricts });
      setSubDistrict(null);
      setPortalCode(first(results).postalCode);
    }).catch(error => {
      const message = get(error, 'data.message', 'เกิดข้อผิดพลาดระหว่างการร้องขอชุดข้อมูล "แขวง/ตำบล"!')
      swal('Error', message, 'error')
    });

    this.setState({ loading: false });
  }

  handleChangeSubDistricts = async (value) => {
    this.setState({ loading: true });
    const { setPortalCode } = this.props;
    const {dataSubDistricts} = this.state;

    const result = dataSubDistricts.find(subdis => subdis.value === value)
    if(!isEmpty(result)){
      setPortalCode(result.postalCode);
    }

    this.setState({ loading: false });
  }

  handleChangeCustomerType = (e) => {
    //console.log('handleChangeCustomerType==>', e);
    this.setState({
      valueCustomerType: e
    });
  }

  render() {
    const {
      submitViewErrorCustomer,
    } = this.props

    const {
      //Options
      dataCustomerTitles,
      dataRegionCountries,
      dataProvices,
      dataDistricts,
      dataSubDistricts,

      //Data
      // district,
      subDistrict,
      portalCode,
      country,
      valueCustomerType,

    } = this.state

    return (
      <Row gutter={10} type="flex" justify="start">
        <Col md={12}>
          <Field
            component={SelectRedux}
            name="customerTitle"
            label="คำนำหน้าชื่อ"
            small
            widthSmall="127px"
            options={dataCustomerTitles}
          />
        </Col>
        <Col md={12}>
          <Field
            component={InputFormRedux}
            name="customerNameTH"
            label={<span style={{ color: 'red' }}>ชื่อลูกค้า</span>}
            small
            widthSmall="127px"
            validate={VALIDATE.REQUIRE}
            submitViewError={submitViewErrorCustomer}
          />
        </Col>
        <Col md={12}>
          <Field
            component={SelectRedux}
            name="customerType"
            label="ประเภทลูกค้า"
            small
            widthSmall="127px"
            options={customerType}
            onChangeHero={this.handleChangeCustomerType}
          />
        </Col>
        <Col md={12}>
          <Field
            component={InputFormRedux}
            name="customerTaxId"
            label={valueCustomerType === '1' ? "เลขประจำตัวประชาชน" : "เลขนิติบุคคล"}
            small
            widthSmall="127px"
            maxLength={13}
          />
        </Col>
        <Col md={12}>
          <Field
            component={InputFormRedux}
            name="customerTaxBranch"
            label="สาขาที่"
            small
            widthSmall="127px"
          />
        </Col>
        <Col md={12}>
          <Field
            component={InputFormRedux}
            name="customerAddress"
            label="ที่อยู่"
            small
            widthSmall="127px"
          />
        </Col>
        <Col md={12}>
          <Field
            component={InputFormRedux}
            name="customerOutletId"
            label="Site งาน/ที่อยู่"
            small
            widthSmall="127px"
          />
        </Col>
        <Col md={12}>
          <Field
            component={SelectRedux}
            name="customerProvince"
            label="จังหวัด"
            small
            widthSmall="127px"
            options={dataProvices}
            onChangeHero={this.handleChangeSelectProvicne}
          />
          <Field
            component={InputFormRedux}
            name="customerProvinceName"
            type="hidden"
          />
        </Col>
        <Col md={12}>
          <Field
            component={SelectRedux}
            name="customerDistrict"
            label="เขต/อำเภอ"
            small
            widthSmall="127px"
            options={dataDistricts}
            onChangeHero={this.handleChangeDistricts}
          />
        </Col>
        <Col md={12}>
          <Field
            component={SelectRedux}
            name="customerSubDistrict"
            label="แขวง/ตำบล"
            small
            widthSmall="127px"
            options={dataSubDistricts}
            value={subDistrict}
            onChangeHero={this.handleChangeSubDistricts}
          />
        </Col>
        <Col md={12}>
          <Field
            component={InputFormRedux}
            name="customerPostalCode"
            label="รหัสไปรษณีย์"
            small
            widthSmall="127px"
            value={portalCode}
          />
        </Col>
        <Col md={12}>
          <Field
            component={SelectRedux}
            name="customerCountry"
            label={<span style={{ color: 'red' }}>ประเทศ</span>}
            small
            widthSmall="127px"
            options={dataRegionCountries}
            value={country}
          />
        </Col>
        <Col md={12}>
          <Field
            component={InputFormRedux}
            name="customerPhone"
            label="โทรศัพท์"
            small
            widthSmall="127px"
          />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    CustomerDetailFormRedux: state.form.CustomerDetailFormRedux,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setProvince: (data) => dispatch(change(`CustomerDetailFormRedux`, `customerProvince`, data)),
  setProvinceName: (data) => dispatch(change(`CustomerDetailFormRedux`, `customerProvinceName`, data)),
  setDistrict: (data) => dispatch(change(`CustomerDetailFormRedux`, `customerDistrict`, data)),
  setSubDistrict: (data) => dispatch(change(`CustomerDetailFormRedux`, `customerSubDistrict`, data)),
  setPortalCode: (data) => dispatch(change(`CustomerDetailFormRedux`, `customerPostalCode`, data)),
});

export default reduxForm({
  form: 'CustomerDetailFormRedux',  // a unique identifier for this form
  enableReinitialize: true
})
  (
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CustomerDetailFormRedux)
  );
