import React, { Component } from 'react'
import {
    TableCustom,
    ModalCustom,
    ButtonCustom,
    CheckboxCustom,
} from 'components/common'

export default class ConfirmProductModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: 'ร้าน',
                    dataIndex: 'outletId',
                    key: 'outletId',
                    align: 'center',
                    width: 150,
                    render: (props, row) => (
                        props && `${props} - ${row.outletName}`
                    ),
                },
                {
                    title: 'ช่องทาง',
                    dataIndex: 'saleChannelCode',
                    key: 'saleChannelCode',
                    align: 'center',
                    width: 150,
                    render: (props, row) => (
                        props && `${props} - ${row.saleChannelDescription}`
                    ),
                },
                {
                    title: 'Category',
                    dataIndex: 'categoryCode',
                    key: 'categoryCode',
                    align: 'center',
                    width: 150,
                    render: (props, row) => (
                        props && `${props} - ${row.categoryDescription}`
                    ),
                },
                {
                    title: 'Sub Category',
                    dataIndex: 'subCategoryCode',
                    key: 'subCategoryCode',
                    align: 'center',
                    width: 150,
                    render: (props, row) => (
                        props && `${props} - ${row.subCategoryDescription}`
                    ),
                },
                {
                    title: 'Class',
                    dataIndex: 'classCode',
                    key: 'classCode',
                    align: 'center',
                    width: 150,
                    render: (props, row) => (
                        props && `${props} - ${row.classDescription}`
                    ),
                },
                {
                    title: 'Sub Class',
                    dataIndex: 'subClassCode',
                    key: 'subClassCode',
                    align: 'center',
                    width: 150,
                    render: (props, row) => (
                        props && `${props} - ${row.subClassDescription}`
                    ),
                },
                {
                    title: 'สินค้า',
                    dataIndex: 'productId',
                    key: 'productId',
                    align: 'center',
                    width: 150,
                },
                {
                    title: 'Percent Sharing',
                    dataIndex: 'percentSharing',
                    key: 'percentSharing',
                    align: 'center',
                    render: (props) => (
                        <div className="text-right">{props}</div>
                    ),
                },
                {
                    title: 'ยกเลิก',
                    dataIndex: 'deleteFlag',
                    key: 'deleteFlag',
                    align: 'center',
                    width: 50,
                    render: (props, row, index) => (
                        row.outletId && <CheckboxCustom
                            style={{ paddingLeft: '10px' }}
                            checked={props}
                            disabled
                        />
                    ),
                },
            ],
            selectedRowKeys: [],
        }
    }

    componentDidMount() {
        const { products } = this.props
        const selectedRowKeys = products.map((product, index) => (index))
        this.setState({ selectedRowKeys })
    }

    onSelectedRows = (selectedRowKeys) => {
        this.setState({ selectedRowKeys })
    }

    onConfirm = () => {
        const { selectedRowKeys } = this.state
        const products = this.props.products.map((product, index) => {
            const isUpdate = selectedRowKeys.includes(index)
            return {
                ...product,
                updateFlag: isUpdate,
                duplicateFlag: isUpdate,
            }
        })
        this.props.onSelected(products)
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            products,
        } = this.props
        const {
            columns,
            selectedRowKeys,
        } = this.state

        const rowSelection = {
            onChange: this.onSelectedRows,
            selectedRowKeys,
        }

        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                title="รายการสินค้าซ้ำ"
            >
                <div>
                    <div style={{ paddingTop: '15px' }}>
                        <TableCustom
                            height="calc(100vh - 411px)"
                            columns={columns}
                            data={products}
                            small
                            isFullContent
                            pagination={false}
                            rowKey={(row, index) => index}
                            rowSelection={rowSelection}
                        />
                    </div>
                    <div className="text-right" style={{ paddingTop: '15px' }}>
                        <ButtonCustom
                            text="ตกลง"
                            green
                            onClick={this.onConfirm}
                        />
                    </div>
                </div>
            </ModalCustom>
        )
    }
}
