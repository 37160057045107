import React, { Component } from 'react'
import { formatNumber } from 'helpers'
import { uniqBy } from 'lodash'
import {
    TableCustom,
    ButtonCustom,
    ModalCustom
} from 'components/common'

export class SaleUnitModal extends Component {
    state = {
        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: 'action',
                key: 'action',
                ellipsis: true,
                render: (props, full) => (
                    <div className="text-center">
                        <ButtonCustom
                            onClick={() => this.props.onSelected(full)}
                            small
                            text="เลือก"
                            disabled={!(full.pricePerUnit || full.pricePerUnit == 0) && full.productType !== 'service'}
                        />
                    </div>
                )
            },
            {
                title: 'จำนวน',
                align: 'center',
                dataIndex: 'denominator',
                key: 'denominator',
                ellipsis: true,
            },
            {
                title: 'หน่วยนับ',
                align: 'center',
                dataIndex: 'unitId',
                key: 'unitId',
                ellipsis: true,
            },
            {
                title: 'สัดส่วน/หน่วยนับ',
                align: 'center',
                dataIndex: 'numerator',
                key: 'numerator',
                ellipsis: true,
            },
            {
                title: 'หน่วยนับ',
                align: 'center',
                dataIndex: 'baseUnit',
                key: 'baseUnit',
                ellipsis: true,
            },
            {
                title: 'ราคา/หน่วย',
                align: 'center',
                dataIndex: 'pricePerUnit',
                key: 'pricePerUnit',
                ellipsis: true,
                render: (props, row) => (
                    <div className="text-right">
                        {formatNumber(props, 2)}
                    </div>
                )
            },
        ],
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            units,
        } = this.props

        const {
            columns,
        } = this.state

        return (
            <ModalCustom
                title="หน่วยขาย"
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                width="60%"
                footer={false}
            >
                <div className="store-location-search-modal">
                    <div className="content">
                        <TableCustom
                            height="calc(100vh - 311px)"
                            columns={columns}
                            // data={units}
                            data={uniqBy(units, 'unitId')}
                            pagination={true}
                            rowKey={(row, i) => i}
                        />
                    </div>
                </div>
            </ModalCustom>
        )
    }
}
