import React, { Component } from 'react'
import { Table } from "antd"
import { TableCustomStyled } from "./styled"

export class TableCustom extends Component {
    render() {
        const {
            data,
            columns,
            loading,
            height,
            small,
            pagination,
            onChange,
            rowKey,
            rowSelection,
            scroll,
            expandedRowRender,
            isStripedRow,
            ...Attrs
        } = this.props
        return (
            <TableCustomStyled height={height} small={small}>
                <Table
                    className={`${isStripedRow ? 'table-striped-rows' : ''}`}
                    rowKey={rowKey}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    onChange={onChange}
                    rowSelection={rowSelection}
                    bordered
                    size="middle"
                    tableLayout="auto"
                    size={small ? 'small' : 'middle'}
                    pagination={pagination}
                    scroll={scroll}
                    expandedRowRender={expandedRowRender}
                    {...Attrs}
                />
            </TableCustomStyled>
        )
    }
}