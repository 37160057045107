import React, { Component } from 'react'
import { get, flatten, isEqual, groupBy } from 'lodash'
import {
    ButtonCustom,
    TableCustom,
    ModalCustom,
    SelectCustom,
    InputSearchCustom,
} from 'components/common'
import swal from '@sweetalert/with-react'
import { Row, Col } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { formatNumber } from 'helpers'
import moment from 'moment'

export default class PromotionModal extends Component {
    state = {
        columns: [
            {
                title: '#',
                align: 'center',
                dataIndex: 'htf',
                key: 'htf',
                ellipsis: true
            },
            {
                title: 'รหัสโปรโมชั่น',
                align: 'center',
                dataIndex: 'code',
                key: 'code',
                ellipsis: true,
            },
            {
                title: 'ชื่อโปรโมชั่น',
                align: 'center',
                dataIndex: 'campaignName',
                key: 'campaignName',
                ellipsis: true,
            },
            {
                title: 'รายละเอียดโปรโมชั่น',
                align: 'center',
                dataIndex: 'targetSales',
                key: 'targetSales',
                ellipsis: true,
            },
            {
                title: 'วันที่เริ่มต้น',
                align: 'center',
                dataIndex: 'effectiveDate',
                key: 'effectiveDate',
                ellipsis: true,
                render: (props) => <div className="text-right">
                    {moment(props).format('DD-MM-YYYY HH:mm')}
                </div>
            },
            {
                title: 'วันที่สิ้นสุด',
                align: 'center',
                dataIndex: 'expireDate',//'baseUnit',
                key: 'expireDate',//'baseUnit',
                ellipsis: true,
                render: (props) => <div className="text-right">
                    {moment(props).format('DD-MM-YYYY HH:mm')}
                </div>
            }
        ],
        loading: false,
        promotions: {
            received: [],
            suggestion: []
        }
    }
    componentDidMount() {
        this.promotionType()
    }
    promotionType() {
        let promotions = groupBy(this.props.promotions, 'type')
        promotions = Object.keys(promotions).reduce((result, key) => {
            let item = promotions[key].map((data, index) => {
                data.htf = index + 1
                return data
            })
            result[key] = item
            return result
        }, { received: [], suggestion: [] })
        this.setState({ promotions })
    }
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.promotions, this.props.promotions)) {
            this.promotionType()
        }
    }

    render() {
        const {
            isShowModal,
            onCloseModal,
            width,
            loading,
            className,
            title,
        } = this.props
        const {
            columns,
            keyword,
            keywordType,
            keywordTypes,
            loading: thisLoading,
            promotions
        } = this.state
        return (
            <ModalCustom
                isShowModal={isShowModal}
                onCloseModal={onCloseModal}
                width={width}
                title={title || 'ตรวจสอบรายการส่งเสริมการขาย'}
                className={`${className || ''}`}
            >
                <div className='sale-order-info-form'>
                    <div className="title">โปรโมชั่นที่ได้รับ</div>
                    <Row style={{ paddingTop: 20 }}>
                        <Col sm={24}>
                            <TableCustom
                                height="calc(50% - 411px)"
                                columns={columns}
                                data={promotions.received}
                                loading={loading || thisLoading}
                                small
                                pagination={true}
                                rowKey={(row, index) => index}
                            />
                        </Col>
                    </Row>
                </div>
                <br />
                <div className='sale-order-info-form'>
                    <div className="title">โปรโมชั่นที่แนะนำ</div>
                    <Row style={{ paddingTop: 20 }}>
                        <Col sm={24}>
                            <TableCustom
                                height="calc(50% - 411px)"
                                columns={columns}
                                data={promotions.suggestion}
                                loading={loading || thisLoading}
                                small
                                pagination={true}
                                rowKey={(row, index) => index}
                            />
                        </Col>
                    </Row>
                </div>

            </ModalCustom>
        )
    }
}
