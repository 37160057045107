import React, { Component } from 'react'
import './style.css'

export class FooterButton extends Component {
    render() {
        const { nodeLeft, nodeRight } = this.props
        return (
            <div className="footer-button-content">
                <div className="left-zone">
                    {
                        nodeLeft && nodeLeft.map((data, i) => (
                            <div key={i} className="button">
                                {data.button}
                            </div>
                        ))
                    }
                </div>
                <div className="right-zone">
                    {
                        nodeRight && nodeRight.map((data, i) => (
                            <div key={i} className="button">
                                {data.button}
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}
