import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Divider } from "antd"
import {
    TitleHead,
    InputFormRedux,
    SelectRedux,
    MultiSelectedRedux,
} from 'components'
import { VALIDATE } from "helpers"
import './style.css'

class SaleEmployeerMaintainForm extends React.Component {
    state = {

    }
    render() {
        const {
            submitViewError,
            dataOutlet,
            dataCustomerTitles,
            dataChannels,

            dataRegionCountries,
            dataProvices,
            dataDistricts,
            dataSubDistrict,

            handleChangeSelectProvicne,
            handleChangeDistricts,
        } = this.props
        return (
            <div className="customer-maintain-form">

                <div>
                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={SelectRedux}
                                name="saleEmployeeOutletId"
                                label="ร้าน"
                                options={dataOutlet}
                                pleaseChoose={true}
                            />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={SelectRedux}
                                name="saleEmployeeTitle"
                                label="คำนำหน้าชื่อ"
                                options={dataCustomerTitles}
                                pleaseChoose={true}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={MultiSelectedRedux}
                                name="saleEmployeeChannelCode"
                                label="ช่องทางการขาย"
                                pleaseChoose={true}
                                options={dataChannels}
                            />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="saleEmployeeName"
                                label="ชื่อ-นามสกุล"
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                maxLength={13}
                                name="saleEmployeeTaxId"
                                label="เลขที่ประจำตัวผู้เสียภาษี"
                            />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="saleEmployeeAddress"
                                label="ที่อยู่"
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={InputFormRedux}
                                name="saleEmployeeEmail"
                                label="อีเมล์"
                            />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={InputFormRedux}
                                name="saleEmployeePostalCode"
                                label="รหัสไปรษณี"
                                maxLength={5}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={InputFormRedux}
                                name="saleEmployeeLineId"
                                label="LINE ID"
                            />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={SelectRedux}
                                name="saleEmployeeSubDistrict"
                                label="แขวง/ตำบล"
                                pleaseChoose={true}
                                options={dataSubDistrict}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                component={InputFormRedux}
                                name="saleEmployeePhone"
                                label="โทรศัพท์"
                            />
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={SelectRedux}
                                name="saleEmployeeDistrict"
                                label="เขต/อำเภอ"
                                pleaseChoose={true}
                                options={dataDistricts}
                                onChangeHero={handleChangeDistricts}
                            />
                        </Col>
                        <Col sm={12}>
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                validate={VALIDATE.REQUIRE}
                                submitViewError={submitViewError}
                                component={SelectRedux}
                                name="saleEmployeeProvince"
                                label="จังหวัด"
                                pleaseChoose={true}
                                options={dataProvices}
                                onChangeHero={handleChangeSelectProvicne}
                            />
                        </Col>
                        <Col sm={12}>
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col sm={12}>
                            <Field
                                component={SelectRedux}
                                name="saleEmployeeCountry"
                                label="ประเทศ"
                                options={dataRegionCountries}
                            />
                        </Col>
                        <Col sm={12}>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default reduxForm({
    form: 'SaleEmployeerMaintainForm',  // a unique identifier for this form
    enableReinitialize: true
})(SaleEmployeerMaintainForm)
