import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'
import { sortDataColumn } from '../../../../helpers';
const widthDefault = 100;

export const allTableColumns = {
  "SaleOrder": [{
    title: 'เลขที่เอกสารใบสั่งขาย',
    dataIndex: 'saleOrderNumber',
    key: 'saleOrderNumber',
    sorter: (a, b) => sortDataColumn(a, b, 'saleOrderNumber'),
    width: widthDefault,
  },
  {
    title: 'คำอธิบายประเภทเอกสาร',
    dataIndex: 'documentTypeDescription',
    key: 'documentTypeDescription',
    sorter: (a, b) => sortDataColumn(a, b, 'documentTypeDescription'),
    width: widthDefault,
  },
  {
    title: 'วันที่เอกสาร',
    dataIndex: 'documentDate',
    key: 'documentDate',
    align: 'center',
    sorter: (a, b) => sortDataColumn(a, b, 'documentDate'),
    render: (value) => (
      <div className="align-left">
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
    width: widthDefault,
  },
  {
    title: 'ช่องทาง',
    dataIndex: 'saleChannelCode',
    key: 'saleChannelCode',
    sorter: (a, b) => sortDataColumn(a, b, 'saleChannelCode'),
    width: widthDefault,
  },
  {
    title: 'ชื่อพนักงาน',
    dataIndex: 'saleEmpName',
    key: 'saleEmpName',
    sorter: (a, b) => sortDataColumn(a, b, 'saleEmpName'),
    width: widthDefault,
  },
  {
    title: 'กลุ่มลูกค้า',
    dataIndex: 'customerId',
    key: 'customerId',
    sorter: (a, b) => sortDataColumn(a, b, 'customerId'),
    width: widthDefault,
  },
  {
    title: 'จำนวนเงิน',
    dataIndex: 'netAmount',
    key: 'netAmount',
    align: 'left',
    sorter: (a, b) => sortDataColumn(a, b, 'netAmount'),
    width: widthDefault,
  },
  {
    title: 'เงื่อนไขการชำระเงิน',
    dataIndex: 'paymentTermDescription',
    key: 'paymentTermDescription',
    sorter: (a, b) => sortDataColumn(a, b, 'paymentTermDescription'),
    width: widthDefault,
  },
  {
    title: 'สร้างเมื่อ',
    dataIndex: 'createdDate',
    key: 'createdDate',
    align: 'center',
    sorter: (a, b) => sortDataColumn(a, b, 'createdDate'),
    render: (value) => (
      <div className="align-left">
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
    width: widthDefault,
  },
  ],
  "Summary": [{
    title: 'เลขที่เอกสารใบสั่งขาย',
    dataIndex: 'saleOrderNumber',
    key: 'saleOrderNumber',
    width: widthDefault,
  },
  {
    title: 'ประเภท',
    dataIndex: 'documentTypeCode',
    key: 'documentTypeCode',
    width: widthDefault,
  },
  {
    title: 'คำอธิบายประเภทเอกสาร',
    dataIndex: 'documentTypeDescription',
    key: 'documentTypeDescription',
    width: widthDefault,
  },
  {
    title: 'เลขที่เอกสารอ้างอิง',
    dataIndex: 'referenceDocNumber',
    key: 'referenceDocNumber',
    width: widthDefault,
  },
  {
    title: 'วันที่เอกสาร',
    dataIndex: 'documentDate',
    key: 'documentDate',
    align: 'center',
    render: (value) => (
      <div className="align-left">
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
    width: widthDefault,
  },
  {
    title: 'องค์กร',
    dataIndex: 'outletId',
    key: 'outletId',
    width: widthDefault,
  },
  {
    title: 'ช่องทาง',
    dataIndex: 'saleChannelCode',
    key: 'saleChannelCode',
    width: widthDefault,
  },
  {
    title: 'ผู้รับ',
    dataIndex: 'shipToId',
    key: 'shipToId',
    width: widthDefault,
  },
  {
    title: 'ชื่อผู้รับ',
    dataIndex: 'shipToName',
    key: 'shipToName',
    width: widthDefault,
  },
  {
    title: 'พนักงานขาย',
    dataIndex: 'saleEmpCode',
    key: 'saleEmpCode',
    width: widthDefault,
  },
  {
    title: 'ชื่อพนักงานขาย',
    dataIndex: 'saleEmpName',
    key: 'saleEmpName',
    width: widthDefault,
  },
  {
    title: 'ผู้ซื้อ',
    dataIndex: 'customerId',
    key: 'customerId',
    width: widthDefault,
  },
  {
    title: 'ชื่อผู้สั่ง',
    dataIndex: 'customerName',
    key: 'customerName',
    width: widthDefault,
  },
  {
    title: 'Job ID',
    dataIndex: 'jobId',
    key: 'jobId',
    width: widthDefault,
  },
  {
    title: 'กลุ่มลูกค้า',
    dataIndex: 'customerGroupDescription',
    key: 'customerGroupDescription',
    width: widthDefault,
  },
  {
    title: 'SCG Family ID',
    dataIndex: 'scgFamilyId',
    key: 'scgFamilyId',
    width: widthDefault,
  },
  {
    title: 'Member ID',
    dataIndex: 'memberId',
    key: 'memberId',
    width: widthDefault,
  },
  {
    title: 'การ ชรง.',
    dataIndex: 'paymentTermCode',
    key: 'paymentTermCode',
    width: widthDefault,
  },
  {
    title: 'คำอธิบาย ชรง.',
    dataIndex: 'paymentTermDescription',
    key: 'paymentTermDescription',
    width: widthDefault,
  },
  {
    title: 'จำนวนเงิน',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    align: 'left',
    width: widthDefault,
  },
  {
    title: 'ส่วนลดรวม',
    dataIndex: 'totalDiscAmount',
    key: 'totalDiscAmount',
    align: 'left',
    width: widthDefault,
  },
  {
    title: 'ภาษีรวม',
    dataIndex: 'vatAmount',
    key: 'vatAmount',
    align: 'left',
    width: widthDefault,
  },
  {
    title: 'ยอดสุทธิใบสั่งขายไม่รวมภาษี',
    dataIndex: 'totalAmountNoVat',
    key: 'totalAmountNoVat',
    align: 'left',
    width: widthDefault,
  },
  {
    title: 'ยอดสุทธิใบสั่งขายรวมภาษี',
    dataIndex: 'netAmount',
    key: 'netAmount',
    align: 'left',
    width: widthDefault,
  },
  {
    title: 'หมายเหตุ',
    dataIndex: 'remark',
    key: 'remark',
    width: widthDefault,
  },
  {
    title: 'สถานะรวม',
    dataIndex: 'documentStatus',
    key: 'documentStatus',
    width: widthDefault,
  },
  {
    title: 'สร้างเมื่อ',
    dataIndex: 'createdDate',
    key: 'createdDate',
    align: 'center',
    render: (value) => (
      <div className="align-left">
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
    width: widthDefault,
  },
  {
    title: 'สร้างโดย',
    dataIndex: 'createdBy',
    key: 'createdBy',
    align: 'center',
    width: widthDefault,
  }
  ],
  "SummaryWithDetail": [{
    title: 'ร้านค้า',
    dataIndex: 'outletId',
    key: 'outletId',
    width: widthDefault,
  },
  {
    title: 'เลขที่เอกสารใบสั่งขาย',
    dataIndex: 'saleOrderNumber',
    key: 'saleOrderNumber',
    width: widthDefault,
  },
  {
    title: 'คำอธิบายประเภทเอกสาร',
    dataIndex: 'documentTypeDescription',
    key: 'documentTypeDescription',
    width: widthDefault,
  },
  {
    title: 'วันที่เอกสาร',
    dataIndex: 'documentDate',
    key: 'documentDate',
    align: 'center',
    render: (value) => (
      <div className="align-left">
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
    width: widthDefault,
  },
  {
    title: 'ช่องทางการจัดจำหน่าย',
    dataIndex: 'saleChannelCode',
    key: 'saleChannelCode',
    width: widthDefault,
  },
  {
    title: 'รหัสผู้สั่งซื้อ',
    dataIndex: 'customerId',
    key: 'customerId',
    width: widthDefault,
  },
  {
    title: 'ชื่อผู้สั่งซื้อ',
    dataIndex: 'customerName',
    key: 'customerName',
    width: widthDefault,
  },
  {
    title: 'ชื่อพนักงานขาย',
    dataIndex: 'saleEmpName',
    key: 'saleEmpName',
    width: widthDefault,
  },
  {
    title: 'กลุ่มลูกค้า',
    dataIndex: 'customerGroupDescription',
    key: 'customerGroupDescription',
    width: widthDefault,
  },
  {
    title: 'การชำระเงิน',
    dataIndex: 'paymentTermCode',
    key: 'paymentTermCode',
    width: widthDefault,
  },
  {
    title: 'รหัสสินค้า',
    dataIndex: 'productCode',
    key: 'productCode',
    width: widthDefault,
  },
  {
    title: 'ชื่อสินค้า',
    dataIndex: 'productName',
    key: 'productName',
    width: 350,
  },
  {
    title: 'รหัสกลุ่มสินค้า',
    dataIndex: 'productClassCode',
    key: 'productClassCode',
    width: widthDefault,
  },
  {
    title: 'ชื่อกลุ่มสินค้า',
    dataIndex: 'productClassName',
    key: 'productClassName',
    width: widthDefault,
  },
  {
    title: 'ลำดับ',
    dataIndex: 'saleOrderItem',
    key: 'saleOrderItem',
    width: widthDefault,
  },
  /*{
    title: 'ปริมาณที่สั่ง',
    dataIndex: 'qty',
    key: 'qty',
    width: widthDefault,
  },*/
  {
    title: 'หน่วยนับการขาย',
    dataIndex: 'unit',
    key: 'unit',
    width: widthDefault,
  },
  {
    title: 'คำอธิบายการชำระเงิน',
    dataIndex: 'paymentTermDescription',
    key: 'paymentTermDescription',
    width: widthDefault,
  },
  {
    title: 'ที่เก็บสินค้า',
    dataIndex: 'slocId',
    key: 'slocId',
    width: widthDefault,
  },
  {
    title: 'จำนวน',
    dataIndex: 'orderQuantity',
    key: 'orderQuantity',
    width: widthDefault,
  },
  {
    title: 'จำนวนที่ยืนยัน',
    dataIndex: 'confirmQuantity',
    key: 'confirmQuantity',
    width: widthDefault,
  },
  {
    title: 'ปริมาณรับแล้ว',
    dataIndex: 'receivedQuantity',
    key: 'receivedQuantity',
    width: widthDefault,
  },
  {
    title: 'ปริมาณค้างรับ',
    dataIndex: 'remainQuantity',
    key: 'remainQuantity',
    width: widthDefault,
  },
  {
    title: 'ปริมาณที่ส่งมอบ',
    dataIndex: 'quantity',
    key: 'quantity',
    width: widthDefault,
  },
  {
    title: 'วันที่ส่งมอบ',
    dataIndex: 'deliveryDateString',//'deliveryDate',
    key: 'deliveryDateString',//'deliveryDate',
    align: 'center',
    /*render: (value) => (
      <div className="align-left">
        {moment().format('DD/MM/YYYY')}
      </div>
    ),*/
    width: widthDefault,
  },
  {
    title: 'ราคาต่อหน่วย',
    dataIndex: 'pricePerUnit',
    key: 'pricePerUnit',
    width: widthDefault,
  },
  {
    title: 'ราคาขายสุทธิ',
    dataIndex: 'totalPricePerProduct',
    key: 'totalPricePerProduct',
    width: widthDefault,
  },
  {
    title: 'ส่วนลดกำหนดเอง',
    dataIndex: 'discountAmount',
    key: 'discountAmount',
    width: widthDefault,
  },
  {
    title: 'ส่วนลดอื่นๆ',
    dataIndex: 'discountOther',
    key: 'discountOther',
    width: widthDefault,
  },
  {
    title: 'ส่วนลดโปรโมชั่น',
    dataIndex: 'discountPromotion',
    key: 'discountPromotion',
    width: widthDefault,
  },
  {
    title: 'คำอธิบายเหตุผลการปฏิเสธ',
    dataIndex: 'rejectDescription',
    key: 'rejectDescription',
    width: widthDefault,
  },
  {
    title: 'ผู้รับ',
    dataIndex: 'shipmentShipToId',
    key: 'shipmentShipToId',
    width: widthDefault,
  },
  {
    title: 'ชื่อผู้รับ',
    dataIndex: 'shipmentShipToName',
    key: 'shipmentShipToName',
    width: widthDefault,
  },
  {
    title: 'เลขที่เอกสารใบส่งสินค้า',
    dataIndex: 'deliveryOrderNumber',
    key: 'deliveryOrderNumber',
    width: widthDefault,
  },
  {
    title: 'ปริมาณที่หยิบ',
    dataIndex: 'quantity',
    key: 'quantityDO',
    width: widthDefault,
    render: (value) => (value || 0),
  },
  {
    title: 'ปริมาณที่ตัดจ่าย',
    dataIndex: 'goodsIssueQuantity',
    key: 'goodsIssueQuantity',
    width: widthDefault,
    render: (value) => (value || 0),
  },
  /*{
    title: 'สถานะการหยิบสินค้า (GI)',
    dataIndex: 'gIStatus',
    key: 'gIStatus',
    width: widthDefault,
  },*/
  {
    title: 'เลขที่เอกสารวางบิล',
    dataIndex: 'billingNo',
    key: 'billingNo',
    width: widthDefault,
  },
  {
    title: 'วันที่สร้างเอกสารวางบิล',
    dataIndex: 'salesBillingCreatedDateString',
    key: 'salesBillingCreatedDateString',
    align: 'center',
    render: (value) => (
      <div className="align-left">
        {value /*moment(value).format('DD/MM/YYYY')*/}
      </div>
    ),
    width: widthDefault,
  },
  {
    title: 'สร้างโดย (User Web)',
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: widthDefault,
  },
  {
    title: 'สร้างเมื่อ',
    dataIndex: 'createdDate',
    key: 'createdDate',
    align: 'center',
    render: (value) => (
      <div className="align-left">
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
    width: widthDefault,
  },
  {
    title: 'อ้างอิงจากใบเสนอราคา',
    dataIndex: 'quotationNumber',
    key: 'quotationNumber',
    width: widthDefault,
  },
  {
    title: 'Job ID',
    dataIndex: 'jobId',
    key: 'jobId',
    width: widthDefault,
  },
  ]
}