import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty, map } from 'lodash'
import { bindActionCreators } from 'redux'
import { authData } from 'actions/actionsCreator'
import FileSaver from 'file-saver'
import {
    formatNumber,
    getPermissionPage,
} from 'helpers'
import {
    TitleHead,
    ButtonCustom,
    TableCustom,
    DatePickerCustom,
    SelectCustom,
} from 'components/common'
import { Row, Col, Spin } from 'antd'
import { apiServices, API_PATH } from 'apiServices'
import { ROUTH_PATH, functionAlias } from 'routes'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import './style.css'

const formatDateAPI = 'YYYY-MM-DD HH:mm:ss'
const widthLabel = '100px'

class ReportS4InterfaceLogContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: 'date',
                    dataIndex: 'date',
                    key: 'date',
                    align: 'center',
                    render: (props) => (
                        props && <div className="text-left">
                            {moment(props).format('YYYY-MM-DD')}
                        </div>
                    ),
                },
                {
                    title: 'time',
                    dataIndex: 'time',
                    key: 'time',
                    align: 'center',
                    // render: (props) => (
                    //     props && <div className="text-left">
                    //         {moment(props).format('HH:mm:ss')}
                    //     </div>
                    // ),
                },
                {
                    title: 'fc_po',
                    dataIndex: 'fcPo',
                    key: 'fcPo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'fc_so',
                    dataIndex: 'fcSo',
                    key: 'fcSo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'fc_so_itm',
                    dataIndex: 'fcSoItem',
                    key: 'fcSoItem',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'reser_no',
                    dataIndex: 'reserNo',
                    key: 'reserNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'reser_itm_no',
                    dataIndex: 'reserItemNo',
                    key: 'reserItemNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'reser_session_id',
                    dataIndex: 'reserSessionId',
                    key: 'reserSessionId',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'so_no',
                    dataIndex: 'soNo',
                    key: 'soNo',
                    align: 'center', render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'so_itm_no',
                    dataIndex: 'soItemNo',
                    key: 'soItemNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'so_itm_qty',
                    dataIndex: 'soItemQty',
                    key: 'soItemQty',
                    align: 'center',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 3)}</div>
                    ),
                },
                {
                    title: 'so_itm_unit',
                    dataIndex: 'soItemUnit',
                    key: 'soItemUnit',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'so_reject_status',
                    dataIndex: 'soRejectStatus',
                    key: 'soRejectStatus',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'dn_no',
                    dataIndex: 'dnNo',
                    key: 'dnNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'dn_itm_no',
                    dataIndex: 'dnItemNo',
                    key: 'dnItemNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'dn_itm_qty',
                    dataIndex: 'dnItemQty',
                    key: 'dnItemQty',
                    align: 'center',
                    render: (props) => (
                        <div className="text-right">{formatNumber(props, 3)}</div>
                    ),
                },
                {
                    title: 'dn_itm_unit',
                    dataIndex: 'dnItemUnit',
                    key: 'dnItemUnit',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'gi_status',
                    dataIndex: 'giStatus',
                    key: 'giStatus',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'bill_no',
                    dataIndex: 'billNo',
                    key: 'billNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'bill_itm_no',
                    dataIndex: 'billItemNo',
                    key: 'billItemNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'bill_acct_doc',
                    dataIndex: 'billAcctDoc',
                    key: 'billAcctDoc',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'bill_acct_year',
                    dataIndex: 'billAcctYear',
                    key: 'billAcctYear',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'bill_acct_comp',
                    dataIndex: 'billAcctComp',
                    key: 'billAcctComp',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'gi_billing_date',
                    dataIndex: 'giBillingDate',
                    key: 'giBillingDate',
                    align: 'center',
                    render: (props) => (
                        props && <div className="text-left">
                            {moment(props).format('YYYY-MM-DD')}
                        </div>
                    ),
                },
                {
                    title: 'bill_create_date',
                    dataIndex: 'billingCreateDate',
                    key: 'billingCreateDate',
                    align: 'center',
                    render: (props) => (
                        props && <div className="text-left">
                            {moment(props).format('YYYY-MM-DD')}
                        </div>
                    ),
                },
                {
                    title: 'plant',
                    dataIndex: 'plant',
                    key: 'plant',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'web_billing_no',
                    dataIndex: 'webBillingNo',
                    key: 'webBillingNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'web_billing_itm_no',
                    dataIndex: 'webBillingItemNo',
                    key: 'webBillingItemNo',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'status_itm',
                    dataIndex: 'statusItem',
                    key: 'statusItem',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'message_itm',
                    dataIndex: 'messageItem',
                    key: 'messageItem',
                    align: 'center',
                    render: (props) => (
                        <div className="text-left">{props}</div>
                    ),
                },
                {
                    title: 'load_date',
                    dataIndex: 'loadDate',
                    key: 'loadDate',
                    align: 'center',
                    render: (props) => (
                        props && <div className="text-left">
                            {moment(props).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    ),
                },
            ],
            loading: false,
            documents: [],
            outlets: [],
            documentStatuses: [
                { label: 'Complete - สร้างเอกสารสำเร็จ', value: 'S' },
                { label: 'Error - ไม่สามารถสร้างเอกสารได้', value: 'E' },
            ],
            filter: {
                documentDateFrom: moment(),
                documentDateTo: moment(),
                outletIds: [],
                documentStatuses: [],
            },
            permissionPage: getPermissionPage(functionAlias.reportS4InterfaceLog),
        }
    }

    componentDidMount() {
        if (!this.state.permissionPage.authDisplay) {
            swal('Error', 'คุณไม่มีสิทธิ์ในการเข้าถึงข้อมูล', 'error').then(() => {
                this.props.history.push(ROUTH_PATH.ACCESS_DENIED)
            })
            return
        }
        this.fetchDataDefault()
    }

    fetchDataDefault = async () => {
        this.setState({ loading: true })

        const { auth } = this.props

        await apiServices.callApi('post', API_PATH.GET_MASTER_OUTLET, {
            outletIds: auth.arrayOutlet,
        }).then(res => {
            let outlets = get(res, 'data.results')
            if (res.status === 200 && !isEmpty(outlets)) {
                outlets = outlets.map(data => (
                    { ...data, label: `${data.outletId} - ${data.outletName}`, value: data.outletId }
                ))
                this.setState({ outlets })
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'ไม่สามารถดึงข้อมูลร้านค้าได้ กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        })

        this.setState({ loading: false })
    }

    changeFilter = (key, val) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, [key]: val } })
    }

    validateFilter = () => {
        const { filter } = this.state
        if (filter.documentDateTo && filter.documentDateFrom && (filter.documentDateTo).isBefore(filter.documentDateFrom)) return { isError: true, message: 'รูปแบบวันที่เอกสารไม่ถูกต้อง' }
        return { isError: false }
    }

    getBody = (filter) => {
        const { outlets } = this.state
        return {
            documentDateFrom: filter.documentDateFrom && filter.documentDateFrom.startOf('day').format(formatDateAPI),
            documentDateTo: filter.documentDateTo && filter.documentDateTo.endOf('day').format(formatDateAPI),
            outletIds: isEmpty(filter.outletIds) ? map(outlets, 'outletId') : filter.outletIds,
            interfaceStatuses: filter.documentStatuses,
        }
    }

    searchDocument = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        await apiServices.callApi('post', API_PATH.POST_GET_INTERFACE_S4_LOG_REPORT, body).then(res => {
            let documents = get(res, 'data.results', [])
            if (res.status === 200) {
                this.setState({ documents })
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการค้นหาข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    exportDocument = async () => {
        const { filter } = this.state
        const valid = this.validateFilter()
        if (valid.isError) {
            swal('Warning', valid.message, 'warning')
            return
        }

        this.setState({ loading: true })
        const body = this.getBody(filter)
        await apiServices.callApi('post', API_PATH.POST_EXPORT_INTERFACE_S4_LOG, body, { responseType: 'arraybuffer' }).then(res => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `ReportS4InterfaceLogFile-${moment().format('DD-MM-YYYY')}.xlsx`)
            } else {
                throw res
            }
        }).catch(error => {
            const message = get(error, 'response.data.message', 'เกิดข้อผิดพลาดในการ Export ข้อมูล กรุณาลองใหม่อีกครั้ง')
            swal('Error', message, 'error')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            columns,
            outlets,
            loading,
            filter,
            documents,
            permissionPage,
            documentStatuses,
        } = this.state

        return (
            <div className="report-s4-interface-log-container">
                <Spin spinning={loading}>
                    <TitleHead
                        text="รายงานตรวจสอบ S4 Interface Log"
                        icon="file"
                    />
                    <div className="middle-content">
                        <Row gutter={15}>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="วันที่เอกสาร"
                                    widthLabel={widthLabel}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('documentDateFrom', val)}
                                    value={filter.documentDateFrom}
                                />
                            </Col>
                            <Col sm={7}>
                                <DatePickerCustom
                                    label="ถึงวันที่เอกสาร"
                                    widthLabel={widthLabel}
                                    small
                                    allowClear={false}
                                    onChange={(val) => this.changeFilter('documentDateTo', val)}
                                    value={filter.documentDateTo}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={7}>
                                <SelectCustom
                                    label="ร้านค้า"
                                    widthLabel={widthLabel}
                                    options={outlets}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    small
                                    onChange={(val) => this.changeFilter('outletIds', val)}
                                    value={filter.outletIds}
                                    placeholder='ทั้งหมด'
                                />
                            </Col>
                            <Col sm={7}>
                                <SelectCustom
                                    label="สถานะเอกสาร"
                                    widthLabel={widthLabel}
                                    options={documentStatuses}
                                    mode="multiple"
                                    isValueAllClear
                                    maxTagCount={3}
                                    optionsDefault={[{ label: 'ทั้งหมด', value: 'all' }]}
                                    small
                                    onChange={(val) => this.changeFilter('documentStatuses', val)}
                                    value={filter.documentStatuses}
                                    placeholder='ทั้งหมด'
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col sm={24} className="search-box text-right">
                                <div className="action-box">
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.searchDocument}
                                            small
                                            icon="search"
                                            text="ค้นหา"
                                            disabled={!permissionPage.authCreate}
                                        />
                                    </div>
                                    <div className="button">
                                        <ButtonCustom
                                            onClick={this.exportDocument}
                                            small
                                            icon="file-excel"
                                            green
                                            text="Export Excel"
                                            disabled={isEmpty(documents) || !permissionPage.authExport}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-layout">
                            <TableCustom
                                columns={columns}
                                data={documents}
                                isFullContent
                                rowKey={(row, index) => index}
                                small
                                pagination={{ pageSize: 30 }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        authData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportS4InterfaceLogContainer)