import React, { Component } from 'react'
import { Input } from 'antd'
import { InputCustomStyled } from './styled'

export class InputCustom extends Component {
    render() {
        const {
            type,
            className,
            onChange,
            onSubmit,
            placeholder,
            name,
            disabled,
            isError,
            errorMsg,
            small,
            label,
            labelAlign,
            widthLabel,
            labelColor,
            placeholderDefault,
            onPressEnter,
            input = {},
            meta = {},

            // rest parameters
            ...rest
        } = this.props
        const { touched, error } = meta

        // touched and error from redux form
        // error and errorMsg from props
        const isErrorForm = (touched || isError) && (error || errorMsg)

        return (
            <InputCustomStyled
                isError={isErrorForm}
                small={small}
                labelColor={labelColor}
                label={label}
                widthLabel={widthLabel}
                labelAlign={labelAlign}
            >
                <div className="input-form form-input-custom">
                    <div className="input-zone-label">
                        {
                            label && <div className="label-style">
                                {label}
                            </div>
                        }
                        {
                            <Input
                                {...input}
                                {...rest}
                                className={`input-style input-custom ${className || ''}`}
                                size={small ? 'small' : 'default'}
                                name={name}
                                type={type}
                                disabled={disabled}
                                placeholder={placeholder ? placeholder : placeholderDefault ? label : ''}
                                onChange={e => {
                                    onChange && onChange(e)
                                    input.onChange && input.onChange(e)
                                }}
                                onPressEnter={onPressEnter ? onPressEnter : onSubmit ? onSubmit : null}
                            />
                        }
                    </div>
                    {
                        isErrorForm && <div className="error-message-bottom">
                            {error || errorMsg}
                        </div>
                    }
                </div>
            </InputCustomStyled>
        )
    }
}
