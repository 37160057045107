import styled from 'styled-components'

export const DatePickerCustomStyled = styled.div`

.ant-input-affix-wrapper .ant-input {
    border-color: ${props => props.error ? "red" : ''};
}
.label-style {
    font-size: ${props => props.small ? '12px' : ''};
    width: ${props => props.small ? props.widthSmall ? props.widthSmall : '120px' : props.widthDefault ? props.widthDefault : '150px'};
}
.input-form, .ant-input {
    margin-bottom: ${props => props.small ? '3px' : ''};
}
.ant-calendar-picker .input-style, .ant-input {
    font-size: ${props => props.small ? '12px' : ''};
    height: ${props => props.small ? '26px' : ''};
}
.ant-calendar-picker .ant-calendar-picker-clear, .ant-calendar-picker-icon {
    margin-top: ${props => props.small ? '-9px' : ''};
}
`