export const allTableColumns = {
    102: {
        matnr: {},//productCode
        maktx: {},//productName
        lgort: {},//storageLocation
        meins: {},//unit
        menge: {},//จำนวนสั่งซื้อ
    },
    123: {
        matnr: {},//productCode
        maktx: {},//productName
        lgort: {},//storageLocation
        meins: {},//unit
        menge: {},//จำนวนสั่งซื้อ
    },
    162: {
        matnr: {},//productCode
        maktx: {},//productName
        lgort: {},//storageLocation
        meins: {},//unit
        menge: {},//จำนวนสั่งซื้อ
    },
    122: {
        matnr: {},//productCode
        maktx: {},//productName
        lgort: {},//storageLocation
        meins: {},//unit
        menge: {},//จำนวนสั่งซื้อ
        returnQty: {},//จำนวนส่งคื่นครั้งนี้
        reason: {},//เหตุผล
    },
    161: {
        material: {}, // productCode
        eaN11: {}, // barcode
        materiaL_TX: {}, //productName
        plant: {}, // plant
        stgE_LOC: {}, // storageLocation
        pO_UNIT: {}, // unit
        quantitY_ORD: {}, // จำนวนส่งคืน
        quantitY03: {}, // จำนวนคงค้าง
        returnQty: {}, // จำนวนส่งคืนครั้งนี้
    },
}

export const modes = {
    102: {
        mode: "D",
        isRequiredDocType: false
    },
    123: {
        mode: "D",
        isRequiredDocType: false
    },
    162: {
        mode: "D",
        isRequiredDocType: false
    },
    122: {
        mode: "C",
        isRequiredDocType: true
    },
    161: {
        mode: "C",
        isRequiredDocType: true
    },
}